import { BehaviorSubject } from 'rxjs';
import { memoizeLatest } from '../../../lib/util/misc';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { HTE2MSApi } from '../api';
import {
    HTEISampleLocation,
    HTEIVialRack,
    HTEInventory,
    HTEPCrudePlate,
    HTEPOT2InstrumentInfo,
    HTEPReservoirInfo,
    HTEPTransferMethod,
    HTEPWorklistKeyT,
    HTEProduction,
    HTEProtocol,
    HTERReactantKindT,
} from '../data-model';
import type { HTE2MSModel } from '../model';
import { WorkflowStatus } from '../utils/workflow-status';
import { HTE2MSCrudePlateModel } from './crude-plate';

// NOTE: keeping it here on purpose for testing
// const TestProduction: HTEProduction = {
//     crude_rack: {
//         label: 'Product',
//         wells: {},
//         position: '5',
//     },
//     worklist_options: {
//         liquid: {
//             source_racks: [
//                 {
//                     label: 'ENR1',
//                     labware_id: '3.6ml_tubes',
//                     wells: { A1: 'TESTV1234' },
//                     position: '1',
//                 },
//             ],
//             reservoirs: {
//                 Through: {
//                     labware_id: '73ml_reservoir',
//                     position: '4',
//                 },
//             },
//             solvents: {
//                 DMSO: {
//                     container_label: 'Through',
//                     container_well: 'A1',
//                 },
//             },
//         },
//     },
//     ot2_instruments: {
//         left: { instrument: 'P300', tip_rack_positions: ['6'] },
//         right: { instrument: 'P1000', tip_rack_positions: ['9'] },
//     },
//     transfer_methods: {
//         DMSO: {
//             method: 'distribute',
//             ot2_instrument: 'P1000',
//         },
//         MeOH: {
//             method: 'cherrypick',
//             ot2_instrument: 'P300',
//             by_kind: {
//                 catalyst: { ot2_aspirate_rate: 33 },
//                 msd: { ot2_aspirate_rate: 22 },
//             },
//         },
//     },
// };

const EmptyProduction: HTEProduction = {
    crude_rack: {
        label: 'Product',
        wells: {},
        position: '5',
    },
    worklist_options: {},
    ot2_instruments: {
        left: {},
        right: {},
    },
    transfer_methods: {},
};

export interface WorklistInfo {
    nonEmpty: {
        key: HTEPWorklistKeyT;
        title: string;
        backfillSolvents: string[];
        reservoirs: string[];
    }[];
    solvents: string[];
    reactantKinds: HTERReactantKindT[];
    groups: Record<
        string,
        {
            worklistKey: HTEPWorklistKeyT;
            index: number;
        }
    >;
    groupOptions: [string, string][];
}

export class HTE2MSProductionModel extends ReactiveModel {
    state = {
        production: new BehaviorSubject<HTEProduction>(EmptyProduction),
        status: new BehaviorSubject<WorkflowStatus>('blank'),
    };

    crudePlate = new HTE2MSCrudePlateModel(this);

    get data() {
        return this.state.production.value;
    }

    private _worklistInfo = memoizeLatest((protocol: HTEProtocol, inventory: HTEInventory) => {
        const worklists: WorklistInfo['nonEmpty'] = [];
        const groups: WorklistInfo['groups'] = {};
        const groupOptions: WorklistInfo['groupOptions'] = [];
        const solvents = new Set<string>();
        const reactant_kinds = new Set<HTERReactantKindT>();
        const reagentMap = new Map(protocol.reagents.map((r) => [r.key, r]));
        const solutionMap = new Map(protocol.solutions?.map((r) => [r.key, r]));
        const instructionMap = this.model.design.addInstructionMap;

        for (const wl of protocol.worklists) {
            const backfillSolvents = new Set<string>();
            const reservoirs = new Set<string>();
            let empty = true;
            for (let gI = 0; gI < wl.groups.length; gI++) {
                const group = wl.groups[gI];

                let groupEmpty = true;
                for (const instr of group.instructions) {
                    if (instr.kind === 'backfill') {
                        groupEmpty = false;
                        solvents.add(instr.solvent);
                        backfillSolvents.add(instr.solvent);
                    } else if (instr.kind === 'transfer') {
                        const r = reagentMap.get(instr.reagent_key);
                        if (!r?.solvent) continue;

                        for (const use of r.uses) {
                            const useInstr = instructionMap.get(use.instruction_id);
                            if (useInstr?.reactant_kind) reactant_kinds.add(useInstr.reactant_kind);
                        }

                        const inv = inventory.liquid[instr.reagent_key];
                        const loc = inventory.reservoir_locations[instr.reagent_key];
                        const labware = this.model.design.labwareMap.get(inv?.labware_id!);
                        if (labware?.is_reservoir && loc?.container_label) {
                            reservoirs.add(loc.container_label);
                        }
                        groupEmpty = false;
                        solvents.add(r.solvent);
                    } else if (instr.kind === 'solution-transfer') {
                        const solution = solutionMap.get(instr.solution_key);
                        if (!solution) continue;

                        for (const c of solution.components) {
                            reactant_kinds.add(c.reactant_kind);
                        }

                        const inv = inventory.solutions?.[instr.solution_key];
                        const loc = inv?.location;
                        const labware = this.model.design.labwareMap.get(inv?.labware_id!);
                        if (labware?.is_reservoir && loc?.container_label) {
                            reservoirs.add(loc.container_label);
                        }
                        groupEmpty = false;
                        const solvent = solution.components.find((c) => c.solvent)?.solvent ?? solution.solvent;
                        if (solvent) solvents.add(solvent);
                    }
                }

                if (!groupEmpty) {
                    empty = false;
                    const groupId = `${wl.key}-${gI}`;
                    groups[groupId] = {
                        worklistKey: wl.key,
                        index: gI,
                    };
                    groupOptions.push([groupId, `${group.title} (${wl.title})`]);
                }
            }
            if (!empty)
                worklists.push({
                    key: wl.key,
                    title: wl.title,
                    backfillSolvents: Array.from(backfillSolvents).sort(),
                    reservoirs: Array.from(reservoirs),
                });
        }

        return {
            nonEmpty: worklists,
            solvents: Array.from(solvents).sort(),
            reactantKinds: Array.from(reactant_kinds).sort(),
            groups,
            groupOptions,
        } satisfies WorklistInfo;
    });
    get worklistInfo() {
        return this._worklistInfo(this.model.protocol.data, this.model.inventory.data);
    }

    getReservoirLabels(worklistKey: HTEPWorklistKeyT) {
        const solvents = this.data.worklist_options[worklistKey]?.solvents ?? {};
        const labels: string[] = [];
        for (const loc of Array.from(Object.values(solvents))) {
            if (loc.container_label) labels.push(loc.container_label);
        }
        let others: string[] = [];
        for (const worklist of this.worklistInfo.nonEmpty) {
            if (worklist.key === worklistKey) {
                others = worklist.reservoirs;
            }
        }
        return Array.from(new Set([...labels, ...others])).sort();
    }

    setCrudePlate(plate: HTEPCrudePlate) {
        this.crudePlate.setPlate(plate);
        this.syncStatus();
    }

    private syncStatus() {
        const { crudePlate } = this;

        if (crudePlate.data.errors.length) return this.state.status.next('danger');
        if (crudePlate.data.worklists.some((w) => w.errors.length)) return this.state.status.next('danger');
        if (crudePlate.data.warnings.length) return this.state.status.next('warning');
        if (crudePlate.data.worklists.length === 0) return this.state.status.next('blank');
        return this.state.status.next('success');
    }

    uploadCrudeRack = async (files: File[]) => {
        const file = files[0];
        if (!file) return;

        const racks = await HTE2MSApi.parseRacks(file);
        const rack = racks[0];
        if (!rack) {
            throw new Error('No racks found');
        }

        const current = this.data.crude_rack;
        const crude_rack: HTEIVialRack = {
            ...current,
            label: rack.label || current.label,
            wells: rack.wells,
        };

        this.state.production.next({ ...this.data, crude_rack });
    };

    async uploadSourceRacks(worklistKey: HTEPWorklistKeyT, files: File[]) {
        const results = await Promise.all(files.map((f) => HTE2MSApi.parseRacks(f)));
        const racks = results.flat();
        if (!racks) {
            throw new Error('No racks found');
        }

        const nextRacks = [...(this.data.worklist_options[worklistKey]?.source_racks ?? [])];
        const barcodeIndices = new Map(nextRacks.map((r, i) => [r.label, i]) || []);

        for (const rack of racks) {
            const idx = barcodeIndices.get(rack.label) ?? nextRacks.length;
            const current = nextRacks[idx];
            nextRacks[idx] = {
                ...rack,
                labware_id: current?.labware_id,
                position: current?.position,
            };
        }

        this.state.production.next({
            ...this.data,
            worklist_options: {
                ...this.data.worklist_options,
                [worklistKey]: { ...this.data.worklist_options[worklistKey], source_racks: nextRacks },
            },
        });
    }

    updateSourceRack(worklistKey: HTEPWorklistKeyT, rack: HTEIVialRack, update: Partial<HTEIVialRack>) {
        const nextRacks = [...(this.data.worklist_options[worklistKey]?.source_racks ?? [])];
        const idx = nextRacks.findIndex((r) => r.label === rack.label);
        if (idx === -1) return;
        nextRacks[idx] = { ...rack, ...update };
        this.state.production.next({
            ...this.data,
            worklist_options: {
                ...this.data.worklist_options,
                [worklistKey]: { ...this.data.worklist_options[worklistKey], source_racks: nextRacks },
            },
        });
    }

    updateBackfillSolvent(worklistKey: HTEPWorklistKeyT, solvent: string, update: Partial<HTEISampleLocation>) {
        const nextSolvents = { ...this.data.worklist_options[worklistKey]?.solvents };
        nextSolvents[solvent] = { ...nextSolvents[solvent], ...update };
        this.state.production.next({
            ...this.data,
            worklist_options: {
                ...this.data.worklist_options,
                [worklistKey]: { ...this.data.worklist_options[worklistKey], solvents: nextSolvents },
            },
        });
    }

    removeSourceRack(worklistKey: HTEPWorklistKeyT, rack: HTEIVialRack) {
        const nextRacks = this.data.worklist_options[worklistKey]?.source_racks?.filter((r) => r.label !== rack.label);
        this.state.production.next({
            ...this.data,
            worklist_options: {
                ...this.data.worklist_options,
                [worklistKey]: { ...this.data.worklist_options[worklistKey], source_racks: nextRacks },
            },
        });
    }

    updateReservoir(worklistKey: HTEPWorklistKeyT, reservoirLabel: string, update: Partial<HTEPReservoirInfo>) {
        const nextReservoirs = { ...this.data.worklist_options[worklistKey]?.reservoirs };
        nextReservoirs[reservoirLabel] = { ...nextReservoirs[reservoirLabel], ...update };
        this.state.production.next({
            ...this.data,
            worklist_options: {
                ...this.data.worklist_options,
                [worklistKey]: { ...this.data.worklist_options[worklistKey], reservoirs: nextReservoirs },
            },
        });
    }

    updateMethod(solvent: string, reactant_kind: string | undefined, update: Partial<HTEPTransferMethod>) {
        const nextMethods = { ...this.data.transfer_methods };
        if (reactant_kind) {
            nextMethods[solvent] = {
                ...nextMethods[solvent],
                by_kind: {
                    ...nextMethods[solvent]?.by_kind,
                    [reactant_kind]: {
                        ...nextMethods[solvent]?.by_kind?.[reactant_kind],
                        ...update,
                    },
                },
            };
        } else {
            nextMethods[solvent] = { ...nextMethods[solvent], ...update };
        }
        this.state.production.next({ ...this.data, transfer_methods: nextMethods });
    }

    updateGroupMethod(worklistKey: HTEPWorklistKeyT, index: number, update: Partial<HTEPTransferMethod>) {
        const nextOptions: HTEProduction['worklist_options'] = {
            ...this.data.worklist_options,
            [worklistKey]: {
                ...this.data.worklist_options[worklistKey],
                group_transfer_methods: {
                    ...this.data.worklist_options[worklistKey]?.group_transfer_methods,
                    [index]: { ...this.data.worklist_options[worklistKey]?.group_transfer_methods?.[index], ...update },
                },
            },
        };

        this.state.production.next({ ...this.data, worklist_options: nextOptions });
    }

    updateInstrument(mount: 'left' | 'right', update: Partial<HTEPOT2InstrumentInfo>) {
        const nextInstruments = { ...this.data.ot2_instruments };
        nextInstruments[mount] = { ...nextInstruments[mount], ...update };
        this.state.production.next({ ...this.data, ot2_instruments: nextInstruments });
    }

    clear() {
        this.state.production.next(EmptyProduction);
        this.syncStatus();
    }

    mount() {}

    init(options: { production: HTEProduction; crude_plate?: HTEPCrudePlate }) {
        this.state.production.next(options.production);
        if (options.crude_plate) {
            this.crudePlate.setPlate(options.crude_plate);
        }
        this.syncStatus();
    }

    constructor(public model: HTE2MSModel) {
        super();
    }
}
