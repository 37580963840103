import { saveAs } from 'file-saver';
import { BehaviorSubject, combineLatest, throttleTime } from 'rxjs';
import { ToastService } from '../../../lib/services/toast';
import { normalizeFilename } from '../../../lib/util/misc';
import { ModelAction, ReactiveModel } from '../../../lib/util/reactive-model';
import { HTE2Api } from '../api';
import {
    HTEEVialRack,
    HTEExecution,
    HTEPInventoryItemIdT,
    HTEPMosquitoProductPlate,
    HTEPMosquitoProtocol,
    HTEPMosquitoRun,
    HTEPRobotRunIdT,
    HTEPSourcePlateIdT,
    HTEProtocolIdT,
} from '../data-model';
import { HTEEInventoryModel } from '../inventory';
import { type HTEWModel } from '../model';
import { HTEMosquitoProtocolModel } from '../protocol/mosquito';
import { ExecutionInfo, getExecutionInfo } from './utils';
import { HTEESolubilizeModel } from './solubilize';

export type HTEExecutionTab =
    | 'plates'
    | 'inventory-search'
    | 'transfers'
    | 'verso'
    | 'picklists'
    | 'combi'
    | 'mosquito'
    | 'solubilize'
    | 'observations';

export class HTEMosquitoExecutionModel extends ReactiveModel {
    readonly protocol: HTEPMosquitoProtocol;
    readonly protocolModel: HTEMosquitoProtocolModel;
    readonly inventory: HTEEInventoryModel;
    readonly solubilize = new HTEESolubilizeModel(this);

    state = {
        tab: new BehaviorSubject<HTEExecutionTab>('plates'),

        plate: new BehaviorSubject<HTEPMosquitoProductPlate | undefined>(undefined),
        run: new BehaviorSubject<HTEPMosquitoRun | undefined>(undefined),

        data: new BehaviorSubject<HTEExecution>(undefined as any),
        info: new BehaviorSubject<ExecutionInfo>(undefined as any),

        rackFiles: new BehaviorSubject<File[]>([]),
    };

    get data() {
        return this.state.data.value;
    }

    get plate() {
        return this.state.plate.value;
    }

    get run() {
        return this.state.run.value;
    }

    get currentPlate() {
        const { data } = this;
        return data.products[this.plate?.id!];
    }

    get currentRun() {
        const { data } = this;
        return data.products[this.plate?.id!]?.robot_runs[this.run?.id!];
    }

    actions = {
        uploadRack: new ModelAction<Record<string, HTEEVialRack>[]>({
            applyResult: (uploadedRacks) => {
                const plate = this.currentPlate;
                const run = this.currentRun;
                const racks: Record<string, HTEEVialRack> = { ...run.racks };
                for (const rack of uploadedRacks) {
                    Object.assign(racks, rack);
                }

                this.state.data.next({
                    ...this.data,
                    products: {
                        ...this.data.products,
                        [plate.product_protocol_id]: {
                            ...plate,
                            robot_runs: {
                                ...plate.robot_runs,
                                [run.run_id]: {
                                    ...run,
                                    racks,
                                },
                            },
                        },
                    },
                });
                this.state.rackFiles.next([]);

                ToastService.show({
                    type: 'success',
                    message: 'Racks uploaded',
                    timeoutMs: 1500,
                });
            },
            onError: 'toast',
        }),
    };

    removeRack(barcode: string) {
        const plate = this.currentPlate;
        const run = this.currentRun;
        const racks: Record<string, HTEEVialRack> = { ...run.racks };
        delete racks[barcode];

        this.state.data.next({
            ...this.data,
            products: {
                ...this.data.products,
                [plate.product_protocol_id]: {
                    ...plate,
                    robot_runs: {
                        ...plate.robot_runs,
                        [run.run_id]: {
                            ...run,
                            racks,
                        },
                    },
                },
            },
        });
    }

    updateProductPlateBarcode(barcode: string | undefined) {
        const { currentPlate } = this;

        this.state.data.next({
            ...this.data,
            products: {
                ...this.data.products,
                [currentPlate.product_protocol_id]: {
                    ...this.currentPlate,
                    product_plate_barcode: barcode || undefined,
                },
            },
        });
    }

    updateSourcePlateBarcode(id: HTEPSourcePlateIdT, barcode: string | undefined) {
        const plate = this.currentPlate;
        const run = this.currentRun;
        this.state.data.next({
            ...this.data,
            products: {
                ...this.data.products,
                [plate.product_protocol_id]: {
                    ...plate,
                    robot_runs: {
                        ...plate.robot_runs,
                        [run.run_id]: {
                            ...run,
                            barcodes: {
                                ...run.barcodes,
                                source_plates: {
                                    ...run.barcodes.source_plates,
                                    [id]: barcode || undefined,
                                },
                            },
                        },
                    },
                },
            },
        });
    }

    updateBarcode(
        protocolId: HTEProtocolIdT,
        runId: HTEPRobotRunIdT,
        itemId: HTEPInventoryItemIdT,
        barcode: string | undefined,
        kind: 'source_vials' | 'transfer_vials'
    ) {
        const plate = this.data.products[protocolId];
        const run = plate.robot_runs[runId];
        this.state.data.next({
            ...this.data,
            products: {
                ...this.data.products,
                [plate.product_protocol_id]: {
                    ...plate,
                    robot_runs: {
                        ...plate.robot_runs,
                        [run.run_id]: {
                            ...run,
                            barcodes: {
                                ...run.barcodes,
                                [kind]: {
                                    ...run.barcodes[kind],
                                    [itemId]: barcode || undefined,
                                },
                            },
                        },
                    },
                },
            },
        });
    }

    excludeInventoryItem(protocolId: HTEProtocolIdT, itemId: HTEPInventoryItemIdT, exluded: boolean) {
        const plate = this.data.products[protocolId];
        this.state.data.next({
            ...this.data,
            products: {
                ...this.data.products,
                [plate.product_protocol_id]: {
                    ...plate,
                    excluded_inventory_ids: exluded
                        ? plate.excluded_inventory_ids.includes(itemId)
                            ? plate.excluded_inventory_ids
                            : [...plate.excluded_inventory_ids, itemId]
                        : plate.excluded_inventory_ids.filter((id) => id !== itemId),
                },
            },
        });
    }

    saveProtocol({ value, filename }: { value: string; filename: string }) {
        const blob = new Blob([value], { type: 'text/plain' });
        const { dataSource } = this.model;
        const saveFilename = dataSource.kind === 'foundry' ? `HTE${dataSource.info.id}_${filename}` : filename;
        saveAs(blob, normalizeFilename(saveFilename));
    }

    mount() {
        this.subscribe(this.state.rackFiles, (files) => {
            if (!files.length) return;

            const racks = Promise.all(files.map((f) => HTE2Api.parseRacks(f)));
            this.actions.uploadRack.run(racks);
        });

        this.subscribe(
            combineLatest([this.state.data, this.inventory.state.inventoryRefreshed]).pipe(
                throttleTime(1000, undefined, { leading: false, trailing: true })
            ),
            ([data, refreshed]) => {
                if (refreshed) {
                    this.state.info.next(getExecutionInfo(this.model.hteId, this.protocol, data));
                }
            }
        );
    }

    constructor(
        public model: HTEWModel,
        { protocol, execution }: { protocol: HTEPMosquitoProtocol; execution?: HTEExecution }
    ) {
        super();

        this.protocol = protocol;

        const data = execution ?? protocol.initial_execution;

        this.state.data.next(data);
        this.state.plate.next(protocol.product_plates[0]);
        this.state.run.next(protocol.product_plates[0]?.runs[0]);

        this.protocolModel = new HTEMosquitoProtocolModel(model, { protocol, customPlateColoring: true });
        this.inventory = new HTEEInventoryModel(this);
    }
}
