import {
    faAt,
    faDroplet,
    faFan,
    faFireBurner,
    faHourglassHalf,
    faPercent,
    faPlus,
    faRefresh,
    faTools,
    faVial,
    faWater,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { AsyncButton } from '../../../components/common/AsyncButton';
import { IconButton } from '../../../components/common/IconButton';
import { SimpleSelectOptionInput, TextInput } from '../../../components/common/Inputs';
import { useModelAction } from '../../../lib/util/reactive-model';
import { roundValue } from '../../../lib/util/roundValues';
import { formatWithUnit, prefixedUnitValue } from '../../../lib/util/units';
import {
    HTEDReaction,
    HTEPProductSample,
    HTEPReagentUse,
    HTEPlateLikeLabware,
    HTERAddReactant,
    HTERInstructionT,
    KnownReactantTypes,
} from '../data-model';
import type { HTE2MSModel } from '../model';
import { ExtendedWellLayouts, WellLayouts } from '../../HTE/experiment-data';

export function toUnit(value: string | number | null | undefined, f: number, unit: string) {
    if (typeof value === 'string') return value;
    if (typeof value === 'number') return `${roundValue(5, value * f)}${unit}`;
    return '';
}

export function getProductSample(reaction: HTEDReaction): HTEPProductSample {
    let productEq = Number.POSITIVE_INFINITY;

    let xfer_L = 0;
    let solvent_L = 0;
    for (const instr of reaction.template.instructions) {
        if (instr.kind === 'add') {
            const [xfer, solvent] = getAddReactantVolumesInL(reaction, instr);
            if (Number.isFinite(xfer)) xfer_L += xfer;
            solvent_L += solvent;
            if (instr.reactant_kind === 'msd' || instr.reactant_kind === 'bb') {
                productEq = Math.min(instr.equivalence ?? 1, productEq);
            }
        } else if (instr.kind === 'evaporate') {
            xfer_L = 0;
            solvent_L = 0;
        }
    }

    if (!reaction.scale || !Number.isFinite(productEq) || !xfer_L) {
        return { total_volume: 0, extra_solvent_volume: 0, concentration: 0 };
    }

    const n_mols = productEq * reaction.scale;
    let concentration = n_mols / (xfer_L + solvent_L);

    if (reaction.template.target_concentration && concentration > reaction.template.target_concentration) {
        concentration = reaction.template.target_concentration;
    }
    const total_L = n_mols / concentration;
    const extra_solvent_L = total_L - xfer_L;

    let standard_volume: number | undefined;
    if (reaction.template.standard_concentration) {
        standard_volume = (n_mols / reaction.template.standard_concentration) * 1e-3;
    }

    return {
        standard_volume,
        total_volume: total_L * 1e-3,
        extra_solvent_volume: extra_solvent_L * 1e-3,
        concentration,
    };
}

function getAddReactantVolumesInL(reaction: HTEDReaction, instr: HTERAddReactant): [xfer: number, solvent: number] {
    const { scale } = reaction;
    const rSample = instr;

    if (typeof rSample.dose_volume === 'number') {
        return [1e3 * rSample.dose_volume, 0];
    }

    const conc = rSample.concentration;
    const eq = rSample.equivalence;
    const neat = rSample.neat_concentration || conc;

    if (typeof eq !== 'number' || typeof conc !== 'number' || typeof neat !== 'number' || typeof scale !== 'number') {
        return [Number.NaN, 0];
    }

    const n_mols = eq * scale;

    const xfer = n_mols / neat;
    const total = (eq * scale!) / conc;
    return [xfer, total - xfer];
}

const ReactantKindOptions = KnownReactantTypes.map((v) => [v, v] as [string, string]);

export function SelectReactantKind({
    value,
    setValue,
    size,
}: {
    value: string;
    setValue: (v: string) => void;
    size?: 'sm';
}) {
    return (
        <SimpleSelectOptionInput
            allowEmpty
            options={ReactantKindOptions}
            value={value}
            setValue={setValue}
            size={size}
        />
    );
}

export function collectReactionIdentifiers(reactions: HTEDReaction[]) {
    const identifiers = new Set<string>();
    const substanceIds = new Set<number>();

    for (const r of reactions) {
        if (r.product_identifier) identifiers.add(r.product_identifier);
        for (const instr of r.template.instructions) {
            if (instr.kind === 'add') {
                if (instr.identifier) identifiers.add(instr.identifier!);
            }
        }
        if (r.product_enumeration) {
            if (typeof r.product_enumeration.substance_id === 'number')
                substanceIds.add(r.product_enumeration.substance_id);
            if (r.product_enumeration.substance_ids) {
                for (const id of r.product_enumeration.substance_ids) {
                    substanceIds.add(id);
                }
            }
        }
    }

    return { identifiers: Array.from(identifiers), substanceIds: Array.from(substanceIds) };
}

export function ProductSampleUI({
    sample,
    inline,
    wellVolume,
}: {
    sample?: HTEPProductSample;
    inline?: boolean;
    wellVolume?: number;
}) {
    const wellRatio = sample?.total_volume && wellVolume ? sample.total_volume / wellVolume : undefined;

    return (
        <div className={inline ? 'hstack gap-2' : undefined}>
            <div>
                <FontAwesomeIcon icon={faVial} className='me-1' size='sm' fixedWidth title='Total / Std Volume' />
                {sample?.total_volume ? (
                    Formatters.siVolume(sample.total_volume)
                ) : (
                    <span className='text-secondary'>-</span>
                )}
                {' / '}
                {sample?.standard_volume ? (
                    Formatters.siVolume(sample.standard_volume)
                ) : (
                    <span className='text-secondary'>-</span>
                )}
            </div>
            <div>
                <FontAwesomeIcon icon={faAt} className='me-1' size='sm' fixedWidth title='Concentration' />
                {sample?.concentration ? (
                    Formatters.concentration(sample.concentration)
                ) : (
                    <span className='text-secondary'>-</span>
                )}
            </div>
            <div>
                <FontAwesomeIcon icon={faDroplet} className='me-1' size='sm' fixedWidth title='Dynamic Solvent' />
                {sample?.extra_solvent_volume ? (
                    Formatters.siVolume(sample.extra_solvent_volume)
                ) : (
                    <span className='text-secondary'>-</span>
                )}
            </div>
            <div>
                <FontAwesomeIcon icon={faPercent} className='me-1' size='sm' fixedWidth title='Well Volume Usage' />
                {wellRatio ? roundValue(0, 100 * wellRatio) : <span className='text-secondary'>-</span>}
            </div>
        </div>
    );
}

export const Unset = '«unset»';

export const Formatters = {
    identity(v: any) {
        if (typeof v === 'string' && !v) return '«unset»';
        return v;
    },
    chemistry(v: string | undefined) {
        return v?.replaceAll('_', ' ') ?? '';
    },
    concentration(v: number | string | undefined) {
        return prefixedUnitValue(v, 'M');
    },
    useOverage(v: number | string | undefined) {
        return typeof v === 'number' ? `${roundValue(2, v)}` : '';
    },
    pauseDuration(v: number | string | undefined) {
        return formatWithUnit(v, 1, ' s');
    },
    cookDuration(v: number | string | undefined) {
        return formatWithUnit(v, 1 / 3600, ' h');
    },
    cookTemperature(v: number | string | undefined) {
        return formatWithUnit(typeof v === 'number' ? v - 273.15 : v, 1, ' °C');
    },
    amount(v: number | string | undefined) {
        if (typeof v === 'number') return `${roundValue(2, 1e3 * v)} mg`;
        return v ?? '';
    },
    volumeInL(v: number | string | undefined) {
        if (typeof v === 'number') {
            if (v < 1e-6) return `${roundValue(0, 1e9 * v)} nL`;
            if (v < 1e-3) return `${roundValue(2, 1e6 * v)} μL`;
            return `${roundValue(3, 1e3 * v)} mL`;
        }
        return v ?? '';
    },
    siVolume(v: number | string | undefined) {
        if (typeof v === 'number') return Formatters.volumeInL(v * 1e3);
        return v ?? '';
    },
    rxnScale(v: number | string | undefined) {
        if (typeof v === 'number') return `${roundValue(2, v * 1e6)} μmol`;
        return v ?? '';
    },
    percent(v: number | string | undefined) {
        if (typeof v === 'number') return `${roundValue(0, 100 * v)}%`;
        return v ?? '';
    },
};

export function InlineInstructionBadge({
    model,
    instruction,
    smaller,
    use,
    fw,
}: {
    model: HTE2MSModel;
    instruction: HTERInstructionT;
    smaller?: boolean;
    use?: HTEPReagentUse;
    fw?: number;
}) {
    let className = `hte2ms-instruction-badge hte2ms-instruction-bg-${instruction.kind}`;
    if (instruction.kind === 'add') {
        className += ` hte2ms-reactant-bg-${instruction.reactant_kind?.toLowerCase()}`;
    }

    let label: string | undefined = '';
    if (instruction.kind === 'add') {
        if (use && fw) {
            if (typeof instruction.concentration === 'number') {
                label += ` ${Formatters.volumeInL(use.n_mols / instruction.concentration)} @ ${Formatters.concentration(
                    instruction.concentration
                )}`;
            } else {
                label += ` ${Formatters.amount(fw * use.n_mols)}`;
            }
        }
        label += ` ${instruction.reactant_kind.toUpperCase()} ${
            model.assets.entities.getIdentifier(instruction.identifier!) || Unset
        }`;
        label += ` = ${instruction.equivalence ?? Unset}`;
        if (
            instruction.neat_concentration &&
            instruction.concentration &&
            instruction.neat_concentration !== instruction.concentration
        ) {
            label += ` @ ${Formatters.concentration(instruction.neat_concentration)} → ${Formatters.concentration(
                instruction.concentration
            )}`;
        } else if (instruction.concentration) {
            label += ` @ ${Formatters.concentration(instruction.concentration)}`;
        } else if (instruction.dose_volume) {
            label += ` ~ ${Formatters.siVolume(instruction.dose_volume)}`;
        }
    } else if (instruction.kind === 'pause') {
        label = ` ${Formatters.pauseDuration(instruction.duration)}`;
    } else if (instruction.kind === 'cook') {
        label = ` ${Formatters.cookDuration(instruction.duration)} @ ${Formatters.cookTemperature(
            instruction.temperature
        )}`;
    } else if (instruction.kind === 'evaporate') {
        label = ` Evaporate`;
    }

    return (
        <div className={className} style={smaller ? { fontSize: 10 } : undefined}>
            <FontAwesomeIcon icon={ReactantKindToIcon[instruction.kind]} fixedWidth size='sm' />
            {label}
        </div>
    );
}

export const ReactantKindToIcon = {
    add: faPlus,
    backfill: faWater,
    pause: faHourglassHalf,
    cook: faFireBurner,
    evaporate: faFan,
};

export function HTEPanel({
    title,
    controls,
    children,
    className,
    slim,
    noBorder,
}: {
    children?: ReactNode;
    title: ReactNode;
    controls?: ReactNode;
    className?: string;
    slim?: boolean;
    noBorder?: boolean;
}) {
    return (
        <div className={`d-flex flex-column h-100 w-100${className ? ` ${className}` : ''}`}>
            <div className={`px-2 py-1 hstack${noBorder ? '' : ' border-bottom'}`}>
                <h6 className='m-0'>{title}</h6>
                <div className='m-auto' />
                <div>
                    {controls ?? (
                        <IconButton
                            icon={faTools}
                            className={slim ? 'invisible py-0' : 'invisible'}
                            onClick={() => {}}
                        />
                    )}
                </div>
            </div>
            <div className='flex-grow-1 position-relative'>{children}</div>
        </div>
    );
}

export function RefreshInventoryButton({ model }: { model: HTE2MSModel }) {
    const status = useModelAction(model.actions.refreshInventory);
    return (
        <AsyncButton
            onClick={model.refreshInventory}
            title='Refresh Inventory'
            icon={faRefresh}
            size='sm'
            variant='link'
            state={{ isLoading: status.kind === 'loading' }}
            disabled={model.readOnlyDesignAndProduction}
        >
            Refresh
        </AsyncButton>
    );
}

export function UsesMarkdown() {
    return (
        <div className='font-body-xsmall text-secondary'>
            Uses Markdown. See a{' '}
            <a href='https://www.markdownguide.org/basic-syntax/' target='_blank' rel='noreferrer'>
                guide
            </a>
            . Ctrl+Enter to confirm.
        </div>
    );
}

export function EditLabwareOption({
    value,
    setValue,
    foundryLayouts,
}: {
    value: HTEPlateLikeLabware;
    setValue: (labware: HTEPlateLikeLabware) => void;
    foundryLayouts?: boolean;
}) {
    return (
        <div className='hstack gap-1'>
            <TextInput
                className='flex-grow-1'
                value={value.name}
                size='sm'
                setValue={(v) => setValue({ ...value, name: v })}
            />
            <SimpleSelectOptionInput
                style={{ width: 100 }}
                options={foundryLayouts ? WellLayouts : ExtendedWellLayouts}
                size='sm'
                value={value.layout}
                setValue={(v) => setValue({ ...value, layout: v })}
            />
        </div>
    );
}
