/* eslint-disable no-param-reassign */

function tokenize(input: string): string[] {
    return input
        .replace(/[\n\r]/g, ' ')
        .replace(/\s+/g, ' ')
        .split(' ')
        .filter((s) => s.length > 0);
}

export interface HTEDCommand {
    name: string;
    args: Record<string | number, string | undefined>;
}

export function parseCommands(input: string): HTEDCommand[] {
    const tokens = tokenize(input);
    const commands: HTEDCommand[] = [];

    for (let i = 0; i < tokens.length; i++) {
        if (tokens[i][0] === '!') {
            const args: Record<string | number, string | undefined> = {};
            let j = i + 1;
            let o = 0;
            for (; j < tokens.length; j++) {
                const t = tokens[j];
                if (t[0] === '!') {
                    break;
                } else if (t[0] === ':') {
                    const next = tokens[j + 1];
                    if (!next || next[0] === ':' || next[0] === '!') {
                        args[t.slice(1)] = undefined;
                    } else {
                        args[t.slice(1)] = next;
                        j++;
                    }
                } else {
                    args[o++] = t;
                }
            }
            commands.push({
                name: tokens[i].slice(1),
                args,
            });
            i = j - 1;
        }
    }

    return commands;
}

export function formatCommands(commands: HTEDCommand[]): string {
    const lines: string[] = [];
    for (const c of commands) formatCommand(c, lines);
    return lines.join('\n');
}

function formatCommand(command: HTEDCommand, lines: string[]) {
    const params: string[] = [];
    for (const [k, v] of Object.entries(command.args)) {
        if (Number.isNaN(+k)) {
            if (v === undefined) params.push(`:${k}`);
            else if (v) params.push(`:${k} ${v}`);
        } else if (v) {
            params.push(`${v}`);
        }
    }
    if (params.length === 0) {
        lines.push(`!${command.name}`);
        return;
    }

    const ps = params.join(' ');
    if (ps.length < 60) {
        lines.push(`!${command.name} ${ps}`);
    } else {
        lines.push(`!${command.name}`);
        lines.push(`  ${params.join(' ')}`);
    }
}
