import { faArrowUpRightFromSquare, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import saveAs from 'file-saver';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, ButtonGroup, Dropdown, Form, Modal, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Split from 'react-split-it';
import { useBlockLayout, useGlobalFilter, useRowSelect, useSortBy, useTable } from 'react-table';
import { BehaviorSubject, distinctUntilChanged, map, throttleTime } from 'rxjs';
import { columnDataTableStore, objectsToColumnTableData } from '../../../components/DataTable';
import { ReactTableGlobalFilter } from '../../../components/ReactTable/Filters';
import { FlexibleVirtualizedTable } from '../../../components/ReactTable/VirtualizedTable';
import { ReactTableModel } from '../../../components/ReactTable/model';
import ReactTableSchema from '../../../components/ReactTable/schema';
import { ErrorMessage } from '../../../components/common/Error';
import { IconButton } from '../../../components/common/IconButton';
import { LabeledInput, TextInput } from '../../../components/common/Inputs';
import Loading from '../../../components/common/Loading';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import useBehaviorSubject from '../../../lib/hooks/useBehaviorSubject';
import { useStateModel } from '../../../lib/hooks/useStateModel';
import { ToastService } from '../../../lib/services/toast';
import { executeConcurrentTasks } from '../../../lib/util/async-queue';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { capitalizeFirst, splitInput } from '../../../lib/util/misc';
import { ModelAction, useModelAction } from '../../../lib/util/reactive-model';
import { getUnitFormatter } from '../../../lib/util/units';
import { Batch, Sample } from '../../Compounds/compound-api';
import { PlateDimensions, formatHTEId } from '../../HTE/experiment-data';
import { PlateVisual, PlateVisualModel } from '../../HTE/plate/PlateVisual';
import { getFirstSelectedIndex, getWellCoords, getWellIndexLabel, plateRowLabel } from '../../HTE/plate/utils';
import { CompoundIdentifier } from '../../HTE/steps/reagents-model';
import { ECMApi, PlateDetails, PlateRow, VirscidianExportOptions } from '../ecm-api';
import {
    ECMPageTemplate,
    ECMPlateWellContents,
    colorPlateByConcentrations,
    labelPlateByControlKinds,
} from '../ecm-common';
import { createPlatemapCSV } from '../utils/platemap';
import { ProjectSelect } from '../../../components/common/ProjectSelect';

export function ECMPlates() {
    return (
        <ECMPageTemplate page='plates'>
            <TableWrapper />
        </ECMPageTemplate>
    );
}

class PlatesModel {
    state = {
        currentPlateIds: new BehaviorSubject<number[]>([]),
    };

    get currentPlate() {
        return this.actions.details.currentResult;
    }

    get plates() {
        return this.actions.plates.currentResult;
    }

    actions = {
        plates: new ModelAction<ReactTableModel<PlateRow>>(),
        details: new ModelAction<PlateDetails>(),
        export: new ModelAction({ onError: 'toast' }),
    };

    private cache = new Map<number, Promise<PlateDetails>>();

    private async _loadPlate(id: number) {
        try {
            const details = await ECMApi.getPlateDetails(id);
            this.cache.set(id, Promise.resolve(details));
            return details;
        } catch (err) {
            this.cache.delete(id);
            throw err;
        }
    }

    loadPlate(id: number) {
        let promise: Promise<PlateDetails>;
        if (!this.cache.has(id)) {
            promise = this._loadPlate(id);
            this.cache.set(id, promise);
        } else {
            promise = this.cache.get(id)!;
        }
        return promise;
    }

    async exportVisiblePlateMaps() {
        const currentPlateIds = this.state.currentPlateIds.value;
        if (currentPlateIds.length === 0) return;

        const maxCount = 16;
        if (currentPlateIds.length > maxCount) {
            throw new Error(
                `Too many plates selected, max is ${maxCount}. Please use 'Filter by barcode...' to narrow down the selection.`
            );
        }

        const plates: PlateDetails[] = [];
        const { failedIndices } = await executeConcurrentTasks({
            tasks: currentPlateIds.map((id) => () => this.loadPlate(id)),
            maxConcurrent: 6,
            onExecuted: (r, i) => {
                plates[i] = r;
            },
        });

        if (failedIndices.length > 0) {
            throw new Error(`Failed to load ${failedIndices.length} plates. Please try again.`);
        }

        const csv = createPlatemapCSV(
            plates.map((p) => ({
                plate: p.plate,
                batches: columnDataTableStore<Batch>(p.batches.dataframe),
                libraryIdentifier: p.experiment && formatHTEId(p.experiment.id),
            }))
        );
        saveAs(new Blob([csv], { type: 'text/csv' }), `platemaps-${Date.now()}.csv`);

        ToastService.show({
            type: 'success',
            message: `Plate map for ${plates.length} plates created.`,
            timeoutMs: 3500,
        });
    }

    saveCurrentPlateMap = () => {
        const { currentPlate } = this;
        if (!currentPlate) return;

        const batches = columnDataTableStore<Batch>(currentPlate.batches.dataframe);
        const csv = createPlatemapCSV([
            {
                plate: currentPlate.plate,
                batches,
                libraryIdentifier: currentPlate.experiment && formatHTEId(currentPlate.experiment.id),
            },
        ]);
        saveAs(new Blob([csv], { type: 'text/csv' }), `${currentPlate.plate.barcode}-platemap.csv`);
    };

    saveFreebaseSDFZip = () => {
        this.actions.export.run(this._saveSDFZip());
    };

    private async _saveSDFZip() {
        const { currentPlate } = this;
        if (!currentPlate) return;

        try {
            const data = await ECMApi.freebaseSDFPlateExport(currentPlate.plate.id);
            saveAs(new Blob([data], { type: 'application/zip' }), `${currentPlate.plate.barcode}-freebase-sdf.zip`);
            ToastService.show({
                type: 'success',
                message: `Freebase SDF ZIP for ${currentPlate.plate.barcode} created.`,
                timeoutMs: 3500,
            });
        } catch (err) {
            reportErrorAsToast('Export SDF ZIP', err);
        }
    }

    constructor() {
        this.actions.plates.run(ECMApi.listPlates());
    }
}

function TableWrapper() {
    const model = useStateModel(() => new PlatesModel());
    const state = useModelAction(model.actions.plates);

    const { id } = useParams();
    const navigate = useNavigate();

    const [initialFilter, initialBarcodes] = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const barcodes = splitInput(decodeURIComponent(urlParams.get('barcodes') ?? '').toUpperCase());
        return [barcodes.join(' '), barcodes.length ? new Set(barcodes) : undefined] as const;
    }, []);

    const table = state.kind === 'result' ? state.result : undefined;

    useEffect(() => {
        if (!table || !initialBarcodes?.size) return;

        const index = table.getColumnValues('barcode').findIndex((b) => initialBarcodes.has(b));
        if (index >= 0) {
            table.setSelection([index]);
            navigate(`/ecm/plates/${table.getValue('id', index)}`);
        } else {
            navigate('/ecm/plates');
            table.setSelection([]);
        }
    }, [table]);

    useEffect(() => {
        if (!table || !id) return;
        const index = table.findValueIndex('id', +id!);
        if (index >= 0) table.setSelection([index]);
        model.actions.details.run(model.loadPlate(+id!));
    }, [model, id, table]);

    if (state.kind === 'loading') return <Loading />;
    if (state.kind === 'error') return <ErrorMessage header='Error Loading Plates' message={state.error} />;
    if (!table) return null;

    return (
        <Split direction='vertical' sizes={[0.5, 0.5]}>
            <PlateTable model={model} table={table} initialFilter={initialFilter} initialBarcodes={initialBarcodes} />
            <PlateDetailsUI model={model} />
        </Split>
    );
}

const TableSearchFields: (keyof PlateRow)[] = [
    'barcode',
    'kind',
    'status',
    'description',
    'created_by',
    'purpose',
    'id',
];

function PlateTable({
    model,
    table,
    initialFilter,
    initialBarcodes,
}: {
    model: PlatesModel;
    table: ReactTableModel<PlateRow>;
    initialFilter: string;
    initialBarcodes: Set<string> | undefined;
}) {
    const selection = useBehavior(table.selectedRowIndices);
    const filterSubject = useBehaviorSubject<PlateFilter>(
        initialBarcodes?.size ? { kind: 'barcodes', barcodes: initialBarcodes } : undefined
    );
    const filter = useBehavior(filterSubject);
    const navigate = useNavigate();

    const { headerGroups, rows, prepareRow } = useTable(
        {
            columns: table.allColumns as any,
            data: table.rows,
            initialState: table.state.value,
        },
        useSortBy,
        useRowSelect,
        useBlockLayout
    );

    const filteredRows = useMemo(() => {
        if (!filter) return rows;

        let filtered = rows;
        if (filter.kind === 'barcodes') {
            filtered = !filter?.barcodes.size
                ? rows
                : rows.filter((r) => filter.barcodes.has(table.getValue('barcode', (r.original as any).index)));
        } else {
            // adapted from https://stackoverflow.com/a/35478115
            const regex = new RegExp(filter.value.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&'), 'ig');
            filtered = rows.filter((r) => {
                const i = (r.original as any).index;
                for (const f of TableSearchFields) {
                    let v: any = table.getValue(f, i);
                    if (typeof v !== 'string') v = `${v}`;
                    if (regex.test(v)) return true;
                }
                return false;
            });
        }

        model.state.currentPlateIds.next(filtered.map((r) => table.getValue('id', (r.original as any).index)));
        return filtered;
    }, [model, rows, filter]);

    return (
        <div className='d-flex flex-column h-100'>
            <div className='w-100 mb-2 position-relative'>
                <PlateFilters filterSubject={filterSubject} initial={initialFilter} />
            </div>
            <div className='flex-grow-1 position-relative'>
                <FlexibleVirtualizedTable
                    headerGroups={headerGroups as any}
                    rows={filteredRows as any}
                    rowSelectionMode='single'
                    selectedRows={selection}
                    prepareRow={prepareRow as any}
                    table={table}
                    onRowSelect={(row) => navigate(`/ecm/plates/${table.getValue('id', row.original.index)}`)}
                />
            </div>
        </div>
    );
}

type PlateFilter = { kind: 'barcodes'; barcodes: Set<string> } | { kind: 'any'; value: string } | undefined;

function updatePlateFilter(filterSubject: BehaviorSubject<PlateFilter>, value: string, barcodesOnly: boolean) {
    if (barcodesOnly) {
        const barcodes = new Set(splitInput(value));
        filterSubject.next(barcodes.size ? { kind: 'barcodes', barcodes } : undefined);
    } else {
        filterSubject.next(value ? { kind: 'any', value } : undefined);
    }
}

function PlateFilters({ filterSubject, initial }: { filterSubject: BehaviorSubject<PlateFilter>; initial: string }) {
    const [barcodesOnly, setBarcodesOnly] = useState(true);
    const _barcodesOnly = useRef(barcodesOnly);
    _barcodesOnly.current = barcodesOnly;

    const [current, setCurrent] = useState<string>(initial);
    const updates = useBehaviorSubject(initial);

    useEffect(() => {
        const sub = updates
            .pipe(
                map((v) => v.toUpperCase().trim()),
                distinctUntilChanged(),
                throttleTime(350, undefined, { leading: false, trailing: true })
            )
            .subscribe((v) => {
                updatePlateFilter(filterSubject, v, _barcodesOnly.current);
            });
        return () => sub.unsubscribe();
    }, [filterSubject]);

    return (
        <>
            <TextInput
                textarea
                immediate
                selectOnFocus
                value={current}
                rows={2}
                placeholder={barcodesOnly ? 'Filter by barcode(s)...' : 'Filter plates...'}
                setValue={(v) => {
                    setCurrent(v);
                    updates.next(v);
                }}
            />
            <IconButton
                onClick={() => {
                    setCurrent('');
                    updates.next('');
                }}
                title='Clear Filter'
                icon={faRemove}
                style={{ right: 0, top: 0, visibility: current ? 'visible' : 'hidden' }}
                className='position-absolute text-danger'
            />
            <Form.Switch
                id='query-plates-switch'
                label='Barcodes Only'
                title='Only filter by barcodes'
                className='ecm-search-query-plates position-absolute end-0 bottom-0 pe-2'
                checked={barcodesOnly}
                onChange={(e) => {
                    setBarcodesOnly(e.target.checked);
                    updatePlateFilter(filterSubject, current.toUpperCase().trim(), e.target.checked);
                }}
            />
        </>
    );
}

function PlateDetailsUI({ model }: { model: PlatesModel }) {
    const state = useModelAction(model.actions.details);
    const details = model.actions.details.currentResult;

    return (
        <Split direction='horizontal' sizes={[0.6, 0.4]}>
            <div className='h-100'>
                {state.kind === 'loading' && <Loading />}
                {state.kind === 'error' && <ErrorMessage header='Error Loading Details' message={state.error} />}
                {state.kind !== 'loading' && state.kind !== 'error' && !details && (
                    <div className='mt-2 text-secondary'>Click a row to view details.</div>
                )}
                {!!details && <PlateDetailsPane details={details} model={model} />}
            </div>
            {details && <PlateDetailsVisual details={details} />}
        </Split>
    );
}

function PlateDetailsPane({ details, model }: { details: PlateDetails; model: PlatesModel }) {
    const table = useMemo(() => getSamplesTable(details), [details]);
    const selection = useBehavior(table.selectedRowIndices);

    const {
        headerGroups,
        rows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { globalFilter },
    } = useTable(
        {
            columns: table.allColumns as any,
            data: table.rows,
            initialState: {
                ...table.state.value,
            },
        },
        useGlobalFilter,
        useSortBy,
        useRowSelect,
        useBlockLayout
    );

    return (
        <div className='d-flex flex-column h-100'>
            <div className='my-2 d-flex justify-content-between align-items-center'>
                <h6 className='fw-bold m-0'>
                    Plate {details.plate.barcode} ({details.plate.size})
                    {!!details.experiment && (
                        <>
                            {' - '}
                            <Link to={`/hte/${details.experiment.id}/details`} target='_blank'>
                                {formatHTEId(details.experiment.id)}
                                <FontAwesomeIcon size='sm' className='ms-1' icon={faArrowUpRightFromSquare} />
                            </Link>
                        </>
                    )}
                </h6>
                <div className='m-auto' />
                <div style={{ maxWidth: 280 }}>
                    <ReactTableGlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        size='sm'
                    />
                </div>
                <ExportUI details={details} model={model} />
            </div>
            <div className='flex-grow-1 position-relative'>
                <FlexibleVirtualizedTable
                    headerGroups={headerGroups as any}
                    rows={rows as any}
                    rowSelectionMode='single'
                    selectedRows={selection}
                    prepareRow={prepareRow as any}
                    table={table}
                />
            </div>
        </div>
    );
}

function ExportUI({ details, model }: { details: PlateDetails; model: PlatesModel }) {
    const isVirscidianOpen = useBehaviorSubject(false);
    const exportState = useModelAction(model.actions.export);
    const currentPlateIds = useBehavior(model.state.currentPlateIds);

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle
                    variant='outline-primary'
                    className='ms-2'
                    size='sm'
                    disabled={exportState.kind === 'loading'}
                >
                    {exportState.kind === 'loading' && <Spinner className='me-2' size='sm' animation='border' />}
                    Export
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={model.saveCurrentPlateMap}>Plate Map CSV</Dropdown.Item>
                    <Dropdown.Item onClick={() => isVirscidianOpen.next(true)}>Virscidian</Dropdown.Item>
                    <Dropdown.Item onClick={model.saveFreebaseSDFZip}>Freebase SDF ZIP</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => model.actions.export.run(model.exportVisiblePlateMaps())}>
                        Selected Plate Maps CSV ({currentPlateIds.length})
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <ExportVirscidianModal details={details} isOpen={isVirscidianOpen} />
        </>
    );
}

function PlateDetailsVisual({ details }: { details: PlateDetails }) {
    const visual = useRef<PlateVisualModel>();
    if (!visual.current) {
        visual.current = new PlateVisualModel(details.plate.size, { singleSelect: true });
    }
    useEffect(() => {
        visual.current!.state.colors.next(colorPlateByConcentrations(details.plate.samples));
        const labels = labelPlateByControlKinds(details.plate.control_kinds);
        if (labels) {
            visual.current!.state.labels.next(labels);
        }
    }, [details]);

    return (
        <div className='d-flex flex-column h-100 mx-2 pb-2'>
            <div className='flex-grow-1 my-2'>
                <PlateVisual model={visual.current} />
            </div>
            <PlateDetailsSelection details={details} visual={visual.current} />
        </div>
    );
}

function PlateDetailsSelection({ details, visual }: { details: PlateDetails; visual: PlateVisualModel }) {
    const selection = useBehavior(visual.state.selection);
    const fst = getFirstSelectedIndex(selection);
    const sample = details.plate.samples[fst];
    const controlKind = details.plate.control_kinds?.[fst];
    const label = getWellIndexLabel(details.plate.size, fst);
    const batchIndex = sample ? details.batches.findValueIndex('id', sample.batch_id) : -1;
    const batchIdentifier = details.batches.getValue('identifier', batchIndex);

    if (fst < 0) {
        return <div className='hte-details-plate-selection-info ms-4'>(Nothing Selected)</div>;
    }

    return (
        <div className='hte-details-plate-selection-info ms-4'>
            {!sample && '(Empty Well)'}
            {sample && (
                <>
                    ({label}) {batchIdentifier && <CompoundIdentifier value={batchIdentifier} />}
                    <ECMPlateWellContents sample={sample} />
                </>
            )}
            {controlKind ? (
                <>
                    <span className='text-secondary mx-1'>[</span>
                    {capitalizeFirst(controlKind)} control
                    <span className='text-secondary mx-1'>]</span>
                </>
            ) : undefined}
        </div>
    );
}

interface PlateSampleRow extends Sample {
    batch_identifier: string;
    well: number;
    row: number;
    col: number;
}

function getSamplesTable(details: PlateDetails) {
    const rows: PlateSampleRow[] = [];
    const [w] = PlateDimensions[details.plate.size];
    for (let i = 0; i < details.plate.size; i++) {
        const sample = details.plate.samples[i];
        if (!sample) continue;

        const batchIdx = details.batches.findValueIndex('id', sample.batch_id);
        const [row, col] = getWellCoords(w, i);
        rows.push({
            ...sample,
            well: i,
            row,
            col: col + 1,
            batch_identifier: details.batches.getValue('identifier', batchIdx),
        });
    }

    const df = objectsToColumnTableData(rows, [
        'batch_identifier',
        'well',
        'row',
        'col',
        'solute_mass',
        'solvent_volume',
        'concentration',
        'solvent',
    ]);

    const table = new ReactTableModel<PlateSampleRow>(df, {
        well: {
            ...ReactTableSchema.int(),
            format: (v) => getWellIndexLabel(details.plate.size, v),
        } as ReactTableSchema.Element,
        row: {
            ...ReactTableSchema.int(),
            format: (v) => plateRowLabel(v),
        } as ReactTableSchema.Element,
        col: ReactTableSchema.int(),
        solute_mass: ReactTableSchema.optionalFloat(),
        solvent_volume: ReactTableSchema.optionalFloat(),
        concentration: ReactTableSchema.optionalFloat(),
    });

    const { formatValue: formatAmount, csvUnit: amountUnit } = getUnitFormatter('g', 'm');
    const { formatValue: formatVolume, csvUnit: volumeUnit } = getUnitFormatter('L', 'u', { factor: 1e3 });
    const { formatValue: formatConcentration, csvUnit: concentrationUnit } = getUnitFormatter('M', 'm');

    table.getColumn('solute_mass').Header = `Amount [${amountUnit}]`;
    table.getColumn('solvent_volume').Header = `Volume [${volumeUnit}]`;
    table.getColumn('concentration').Header = `Conc. [${concentrationUnit}]`;

    table.addColumns(
        [
            ['solute_mass', table.getColumnValues('solute_mass').map(formatAmount)],
            ['solvent_volume', table.getColumnValues('solvent_volume').map(formatVolume)],
            ['concentration', table.getColumnValues('concentration').map(formatConcentration)],
        ],
        { updateData: true }
    );
    table.setColumnWidth('batch_identifier', 160);
    table.setColumnWidth('well', 70);
    table.setColumnWidth('row', 70);
    table.setColumnWidth('col', 70);
    table.setColumnWidth('solute_mass', 120);
    table.setColumnWidth('solvent_volume', 120);
    table.setColumnWidth('concentration', 120);
    table.setColumnWidth('solvent', 120);

    return table;
}

function ExportVirscidianModal({ details, isOpen }: { details: PlateDetails; isOpen: BehaviorSubject<boolean> }) {
    const open = useBehavior(isOpen);
    const [kind, setKind] = useState<'csv' | 'json'>('json');
    const [exportData, applyExportData] = useAsyncAction({ rethrowError: true });
    const [options, setOptions] = useState<VirscidianExportOptions>({
        rack_code: '',
        preset: 'PreQC',
        chemical_stability: 'acid',
        salt: '',
        project: undefined,
    });

    useEffect(() => {
        if (open) {
            setOptions({ rack_code: '', preset: 'PreQC', chemical_stability: 'acid', salt: '', project: undefined });
        }
    }, [open]);

    async function exportCsv() {
        try {
            const isCsv = kind === 'csv';
            const formattedOptions: VirscidianExportOptions = {
                rack_code: options.rack_code?.trim() || undefined,
                preset: options.preset,
                chemical_stability: options.chemical_stability,
                salt: options.salt?.trim() || undefined,
                project: options.project,
            };

            let data: Blob | undefined;
            let warnings: string[] = [];

            if (isCsv) {
                const exported = await applyExportData(
                    ECMApi.virscidianPlateExportCSV(details.plate.id, formattedOptions)
                );
                if (exported?.warnings) warnings = exported.warnings;
                if (exported?.csv) data = new Blob([exported.csv], { type: 'text/csv' });
            } else {
                const exported = await applyExportData(
                    ECMApi.virscidianPlateExportJSON(details.plate.id, formattedOptions)
                );
                if (exported?.warnings) warnings = exported.warnings;
                if (exported?.submission)
                    data = new Blob(
                        [
                            JSON.stringify(
                                {
                                    submission: exported.submission,
                                },
                                null,
                                2
                            ),
                        ],
                        { type: 'application/json' }
                    );
            }

            if (!isOpen.value) return;
            if (!data) {
                reportErrorAsToast('Export', 'Failed to obtain export data');
                return;
            }

            let filename = `${details.plate.barcode}-${options.preset}`;
            if (options.preset === 'PreQC') filename += `_${options.chemical_stability}`;
            filename += isCsv ? '.csv' : '.json';

            saveAs(data, filename);
            isOpen.next(false);

            if (warnings.length > 0) {
                ToastService.show({
                    type: 'warning',
                    message: `Exported with warnings:\n${warnings.map((w) => `- ${w}`).join('\n')}`,
                    timeoutMs: false,
                });
            } else {
                ToastService.show({
                    type: 'success',
                    message: 'Exported',
                });
            }
        } catch (err) {
            reportErrorAsToast('Export', err);
        }
    }

    return (
        <Modal
            backdrop={exportData.isLoading ? 'static' : true}
            centered
            scrollable
            show={open}
            size='lg'
            onHide={() => isOpen.next(false)}
            keyboard={!exportData.isLoading}
        >
            <Modal.Header closeButton>
                <h4>Export Virscidian</h4>
            </Modal.Header>
            <Modal.Body>
                <div className='ecm-plates-export-virscidian-form vstack gap-2'>
                    <LabeledInput label='Format'>
                        <ButtonGroup size='sm'>
                            <Button
                                onClick={() => setKind('json')}
                                variant={kind === 'json' ? 'primary' : 'outline-primary'}
                            >
                                Submission JSON
                            </Button>
                            <Button
                                onClick={() => setKind('csv')}
                                variant={kind === 'csv' ? 'primary' : 'outline-primary'}
                            >
                                CSV
                            </Button>
                        </ButtonGroup>
                    </LabeledInput>
                    {kind === 'json' && (
                        <LabeledInput label='Project'>
                            <div className='w-50'>
                                <ProjectSelect
                                    placeholder='Select Project...'
                                    value={options.project}
                                    setValue={(p) => setOptions((prev) => ({ ...prev, project: p }))}
                                />
                            </div>
                        </LabeledInput>
                    )}
                    <LabeledInput label='Rack Code' tooltip='Optional'>
                        <TextInput
                            value={options.rack_code}
                            setValue={(rack_code) => setOptions((old) => ({ ...old, rack_code }))}
                            immediate
                        />
                    </LabeledInput>
                    <LabeledInput label='Preset'>
                        <ButtonGroup size='sm'>
                            <Button
                                onClick={() => setOptions((old) => ({ ...old, preset: 'PreQC' }))}
                                variant={options.preset === 'PreQC' ? 'primary' : 'outline-primary'}
                            >
                                PreQC
                            </Button>
                            <Button
                                onClick={() =>
                                    setOptions((old) => ({ ...old, preset: 'FinalQC', chemical_stability: 'acid' }))
                                }
                                variant={options.preset === 'FinalQC' ? 'primary' : 'outline-primary'}
                            >
                                FinalQC
                            </Button>
                        </ButtonGroup>
                    </LabeledInput>
                    <LabeledInput label='Chemical Stability'>
                        <ButtonGroup size='sm'>
                            <Button
                                onClick={() => setOptions((old) => ({ ...old, chemical_stability: 'acid' }))}
                                variant={options.chemical_stability === 'acid' ? 'primary' : 'outline-primary'}
                            >
                                Acid
                            </Button>
                            <Button
                                disabled={options.preset === 'FinalQC'}
                                onClick={() => setOptions((old) => ({ ...old, chemical_stability: 'base' }))}
                                variant={
                                    options.chemical_stability === 'base'
                                        ? 'primary'
                                        : options.preset === 'FinalQC'
                                        ? 'outline-secondary'
                                        : 'outline-primary'
                                }
                            >
                                Base
                            </Button>
                        </ButtonGroup>
                    </LabeledInput>
                    <LabeledInput label='Salt' tooltip='Optional'>
                        <TextInput
                            value={options.salt}
                            setValue={(salt) => setOptions((old) => ({ ...old, salt }))}
                            immediate
                        />
                    </LabeledInput>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='link' className='me-3' onClick={() => isOpen.next(false)}>
                    Cancel
                </Button>
                <Button variant='primary' onClick={() => exportCsv()} disabled={exportData.isLoading}>
                    {exportData.isLoading && <Spinner className='me-2' size='sm' animation='border' />}Export
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
