import React, { useEffect, useMemo, useRef } from 'react';
import { DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { SelectOption } from '../../components/common/formatSelectOptionLabel';
import { ErrorMessage } from '../../components/common/Error';
import Loading from '../../components/common/Loading';
import { CustomSelectClassNames } from '../../components/common/selectStyles';
import { SubstructureSearchInput } from '../../components/common/SubstructureSearch';
import { PageTemplate } from '../../components/Layout/Layout';
import useBehavior from '../../lib/hooks/useBehavior';
import { AsyncMoleculeDrawer } from '../../lib/util/draw-molecules';
import { formatDateAsYYYY_MM_DD } from '../../lib/util/dates';
import {
    Column,
    ColumnsFor,
    DefaultRowHeight,
    DataTableGlobalFilter,
    DataTableControl,
    DataTableModel,
    DataTableStore,
    tableRowNavigation,
} from '../../components/DataTable';
import { HoverBatchLink, SmilesColumn } from '../../components/DataTable/common';
import { SearchByIdentifier } from './Common';
import { CompoundListModel } from './compound-list-model';
import { UploadBatchesModal, UploadSamplesModal } from './upload/UploadModals';
import { BatchUploadModel } from './upload/batch-upload-model';
import { MultipleBatchReview } from './upload/MultipleBatchUpload';
import { SingleBatchSelection } from './upload/SingleBatchUpload';
import { SampleUpload } from './upload/SampleUpload';
import { CompoundFromDF } from './compound-api';

const SmilesRowHeightFactor = 2;
const rowHeight = DefaultRowHeight;

const drawer = new AsyncMoleculeDrawer();

const CompoundTableSchema: ColumnsFor<CompoundFromDF> = {
    structure: SmilesColumn(drawer, SmilesRowHeightFactor, {
        width: 200,
        identifierPadding: 18,
        getIdentifierElement: ({ rowIndex, table }) => {
            const identifier = table.store.getValue('identifier', rowIndex);
            if (!identifier) return '-';
            return <HoverBatchLink identifier={identifier} withQuery />;
        },
    }),
    identifier: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        filterType: false,
        width: 135,
    }),
    molecular_weight: {
        ...Column.float({ defaultFormatting: { significantDigits: 5, scientific: false, decimalPlaces: 2 } }),
        align: 'right',
        noHeaderTooltip: true,
    },
    project: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
    }),
    created_by: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
    }),
    created_on: Column.create({
        kind: 'datetime',
        format: () => '<unused>',
        render: ({ value }) => formatDateAsYYYY_MM_DD(value),
        noHeaderTooltip: true,
    }),
    common_name: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        width: 145,
    }),
    aliases: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        width: 200,
        format: () => '<unused>',
        globalFilterFn: (table) => (v: string[], test: string) =>
            v.some((alias) => alias.toLowerCase().includes(test.toLowerCase())),
        render: ({ value }) => <div className='entos-compound-aliases-cell'>{value.join('; ')}</div>,
    }),
};

function createTable(compounds: DataTableStore<CompoundFromDF>) {
    const table = new DataTableModel(compounds, {
        columns: CompoundTableSchema,
        rowHeight: SmilesRowHeightFactor * rowHeight,
        hideNonSchemaColumns: true,
        customState: { 'show-smiles': true },
        globalFilterHiddenColumns: true,
    });

    table.setHiddenColumns(['identifier']);

    return table;
}

export default function Compounds() {
    const [searchParams] = useSearchParams();

    const modelRef = useRef<CompoundListModel>();
    if (!modelRef.current) {
        modelRef.current = new CompoundListModel();
    }
    const model = modelRef.current;
    const batchUploadStep = useBehavior(model.batchUploadModel.state.step);
    const sampleUploadStep = useBehavior(model.sampleUploadModel.state.step);

    useEffect(() => {
        if (model) model.mount();
        return () => model.dispose();
    }, [model]);

    useEffect(() => {
        const eid = searchParams.get('__eid');
        if (eid) {
            model.loadFromSignals(eid);
        }
    }, [searchParams]);

    if (batchUploadStep !== 'settings') return <BatchUpload model={model.batchUploadModel} />;
    if (sampleUploadStep !== 'settings') return <SampleUpload model={model.sampleUploadModel} />;

    return <CompoundList model={model} />;
}

function CompoundList({ model }: { model: CompoundListModel }) {
    const isLoading = useBehavior(model.state.isLoading);
    const store = useBehavior(model.state.store);
    const table = useMemo(() => (store ? createTable(store) : undefined), [store]);

    return (
        <PageTemplate title='Compounds' button={<SearchByIdentifier />}>
            <div className='position-relative h-100 d-flex flex-column'>
                <div className='flex-0 d-flex justify-content-between m-3'>
                    <h4>{isLoading || !store ? '' : store.rowCount} Compounds</h4>
                </div>
                <div className='d-flex justify-content-between align-items-end mx-3'>
                    <div className='flex-grow-1 my-2 me-2'>{table && <DataTableGlobalFilter table={table} />}</div>
                    <SelectProject model={model} />
                    <SelectIndex model={model} />
                    <SubstructureSearch model={model} />
                    <DropdownButton title='Import' variant='primary' disabled={isLoading} className='my-2'>
                        <Dropdown.Item onClick={() => model.batchUploadModel.state.modalOpen.next(true)}>
                            Batches
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => model.sampleUploadModel.state.modalOpen.next(true)}>
                            Samples
                        </Dropdown.Item>
                    </DropdownButton>
                </div>
                {isLoading && <Loading message='Loading compounds...' />}
                {!isLoading && table && <CompoundTable table={table} />}
            </div>
            <UploadBatchesModal model={model.batchUploadModel} />
            <UploadSamplesModal model={model.sampleUploadModel} />
        </PageTemplate>
    );
}

function SelectProject({ model }: { model: CompoundListModel }) {
    const options = model.projectOptions;
    const query = useBehavior(model.state.query);
    const isLoading = useBehavior(model.state.isLoading);

    return (
        <div className='entos-compounds-query me-2 my-2 flex-grow-0'>
            <Select
                options={options as any}
                placeholder='All Projects'
                classNames={CustomSelectClassNames}
                menuPosition='fixed'
                isSearchable
                isClearable
                isDisabled={isLoading}
                defaultValue={query.project ? { label: query.project, value: query.project } : undefined}
                onChange={(option: unknown) =>
                    model.changeQuery('project', option ? (option as SelectOption).value : null)
                }
            />
        </div>
    );
}

function SelectIndex({ model }: { model: CompoundListModel }) {
    const options = model.indexOptions;
    const query = useBehavior(model.state.query);
    const isLoading = useBehavior(model.state.isLoading);

    return (
        <div className='entos-compounds-query me-2 my-2 flex-grow-0'>
            <Select
                options={options as any}
                placeholder='Index'
                classNames={CustomSelectClassNames}
                menuPosition='fixed'
                isSearchable
                isDisabled={isLoading}
                defaultValue={query.index ? { label: query.index, value: query.index } : options[0]}
                onChange={(option: unknown) =>
                    model.changeQuery('index', option ? (option as SelectOption).value : null)
                }
            />
        </div>
    );
}

function SubstructureSearch({ model }: { model: CompoundListModel }) {
    const query = useBehavior(model.state.query);
    const isLoading = useBehavior(model.state.isLoading);

    return (
        <div className='entos-compounds-query me-2 my-2 position-relative flex-grow-0'>
            <div className='d-flex align-items-center'>
                <SubstructureSearchInput
                    smiles={query.substructure ?? ''}
                    setSmiles={(smiles: string) => model.changeQuery('substructure', smiles || undefined)}
                    disabled={isLoading}
                    onEnter={(smiles?: string) => model.changeQuery('substructure', smiles || undefined)}
                />
                <Form.Check
                    className='mx-2 flex-grow-0'
                    id='toggle-substructure_exact'
                    type='checkbox'
                    label='Exact match'
                    onChange={(evt) => {
                        if (query.substructure) {
                            // if there is a substructure already defined, re-run the compound query
                            model.changeQuery('substructure_exact', !!evt.target.checked);
                        } else {
                            // if the substructure field is empty, don't re-run the query when
                            // this field is toggled
                            model.state.query.next({ ...query, substructure_exact: !!evt.target.checked });
                        }
                    }}
                />
            </div>
        </div>
    );
}

function CompoundTable({ table }: { table: DataTableModel<CompoundFromDF> }) {
    const navigate = useNavigate();
    useBehavior(table.version);

    const onRowDoubleClick = (rowIndex: number, tbl: DataTableModel<CompoundFromDF>, e: React.MouseEvent) => {
        tableRowNavigation(e, `/compounds/${tbl.store.getValue('identifier', rowIndex)}`, navigate);
    };

    return (
        <div className='h-100 position-relative'>
            {table && (
                <DataTableControl
                    height='flex'
                    table={table}
                    headerSize='sm'
                    rowSelectionMode='single'
                    onRowDoubleClick={onRowDoubleClick}
                />
            )}
        </div>
    );
}

function BatchUpload({ model }: { model: BatchUploadModel }) {
    const type = useBehavior(model.state.type);
    const singleBatchUploadModel = useBehavior(model.state.singleBatchUploadModel);
    const multipleBatchUploadModel = useBehavior(model.state.multipleBatchUploadModel);

    if (type === 'single') {
        if (!singleBatchUploadModel)
            return <ErrorMessage header='Error uploading batch.' message='Something went wrong.' />;
        return <SingleBatchSelection model={singleBatchUploadModel!} />;
    }

    if (!multipleBatchUploadModel)
        return <ErrorMessage header='Error uploading batches.' message='Something went wrong.' />;
    return <MultipleBatchReview model={multipleBatchUploadModel!} />;
}
