export const ANA_1536_768c_1pt_4empty = `ID,Point,Row,Column,IsControl
Control1,1,1,1,True
Control1,2,1,2,True
Control1,3,1,3,True
Control1,4,1,4,True
Control33,1,1,5,True
Control33,2,1,6,True
Control33,3,1,7,True
Control33,4,1,8,True
Control65,1,1,9,True
Control65,2,1,10,True
Control65,3,1,11,True
Control65,4,1,12,True
Control97,1,1,13,True
Control97,2,1,14,True
Control97,3,1,15,True
Control97,4,1,16,True
1,1,1,17,False
33,1,1,18,False
65,1,1,19,False
97,1,1,20,False
129,1,1,21,False
161,1,1,22,False
193,1,1,23,False
225,1,1,24,False
257,1,1,25,False
289,1,1,26,False
321,1,1,27,False
353,1,1,28,False
385,1,1,29,False
417,1,1,30,False
449,1,1,31,False
481,1,1,32,False
513,1,1,33,False
545,1,1,34,False
577,1,1,35,False
609,1,1,36,False
641,1,1,37,False
673,1,1,38,False
705,1,1,39,False
737,1,1,40,False
769,1,1,41,False
801,1,1,42,False
833,1,1,43,False
865,1,1,44,False
897,1,1,45,False
929,1,1,46,False
961,1,1,47,False
Control2,1,2,1,True
Control2,2,2,2,True
Control2,3,2,3,True
Control2,4,2,4,True
Control34,1,2,5,True
Control34,2,2,6,True
Control34,3,2,7,True
Control34,4,2,8,True
Control66,1,2,9,True
Control66,2,2,10,True
Control66,3,2,11,True
Control66,4,2,12,True
Control98,1,2,13,True
Control98,2,2,14,True
Control98,3,2,15,True
Control98,4,2,16,True
2,1,2,17,False
34,1,2,18,False
66,1,2,19,False
98,1,2,20,False
130,1,2,21,False
162,1,2,22,False
194,1,2,23,False
226,1,2,24,False
258,1,2,25,False
290,1,2,26,False
322,1,2,27,False
354,1,2,28,False
386,1,2,29,False
418,1,2,30,False
450,1,2,31,False
482,1,2,32,False
514,1,2,33,False
546,1,2,34,False
578,1,2,35,False
610,1,2,36,False
642,1,2,37,False
674,1,2,38,False
706,1,2,39,False
738,1,2,40,False
770,1,2,41,False
802,1,2,42,False
834,1,2,43,False
866,1,2,44,False
898,1,2,45,False
930,1,2,46,False
962,1,2,47,False
Control3,1,3,1,True
Control3,2,3,2,True
Control3,3,3,3,True
Control3,4,3,4,True
Control35,1,3,5,True
Control35,2,3,6,True
Control35,3,3,7,True
Control35,4,3,8,True
Control67,1,3,9,True
Control67,2,3,10,True
Control67,3,3,11,True
Control67,4,3,12,True
Control99,1,3,13,True
Control99,2,3,14,True
Control99,3,3,15,True
Control99,4,3,16,True
3,1,3,17,False
35,1,3,18,False
67,1,3,19,False
99,1,3,20,False
131,1,3,21,False
163,1,3,22,False
195,1,3,23,False
227,1,3,24,False
259,1,3,25,False
291,1,3,26,False
323,1,3,27,False
355,1,3,28,False
387,1,3,29,False
419,1,3,30,False
451,1,3,31,False
483,1,3,32,False
515,1,3,33,False
547,1,3,34,False
579,1,3,35,False
611,1,3,36,False
643,1,3,37,False
675,1,3,38,False
707,1,3,39,False
739,1,3,40,False
771,1,3,41,False
803,1,3,42,False
835,1,3,43,False
867,1,3,44,False
899,1,3,45,False
931,1,3,46,False
963,1,3,47,False
Control4,1,4,1,True
Control4,2,4,2,True
Control4,3,4,3,True
Control4,4,4,4,True
Control36,1,4,5,True
Control36,2,4,6,True
Control36,3,4,7,True
Control36,4,4,8,True
Control68,1,4,9,True
Control68,2,4,10,True
Control68,3,4,11,True
Control68,4,4,12,True
Control100,1,4,13,True
Control100,2,4,14,True
Control100,3,4,15,True
Control100,4,4,16,True
4,1,4,17,False
36,1,4,18,False
68,1,4,19,False
100,1,4,20,False
132,1,4,21,False
164,1,4,22,False
196,1,4,23,False
228,1,4,24,False
260,1,4,25,False
292,1,4,26,False
324,1,4,27,False
356,1,4,28,False
388,1,4,29,False
420,1,4,30,False
452,1,4,31,False
484,1,4,32,False
516,1,4,33,False
548,1,4,34,False
580,1,4,35,False
612,1,4,36,False
644,1,4,37,False
676,1,4,38,False
708,1,4,39,False
740,1,4,40,False
772,1,4,41,False
804,1,4,42,False
836,1,4,43,False
868,1,4,44,False
900,1,4,45,False
932,1,4,46,False
964,1,4,47,False
Control5,1,5,1,True
Control5,2,5,2,True
Control5,3,5,3,True
Control5,4,5,4,True
Control37,1,5,5,True
Control37,2,5,6,True
Control37,3,5,7,True
Control37,4,5,8,True
Control69,1,5,9,True
Control69,2,5,10,True
Control69,3,5,11,True
Control69,4,5,12,True
Control101,1,5,13,True
Control101,2,5,14,True
Control101,3,5,15,True
Control101,4,5,16,True
5,1,5,17,False
37,1,5,18,False
69,1,5,19,False
101,1,5,20,False
133,1,5,21,False
165,1,5,22,False
197,1,5,23,False
229,1,5,24,False
261,1,5,25,False
293,1,5,26,False
325,1,5,27,False
357,1,5,28,False
389,1,5,29,False
421,1,5,30,False
453,1,5,31,False
485,1,5,32,False
517,1,5,33,False
549,1,5,34,False
581,1,5,35,False
613,1,5,36,False
645,1,5,37,False
677,1,5,38,False
709,1,5,39,False
741,1,5,40,False
773,1,5,41,False
805,1,5,42,False
837,1,5,43,False
869,1,5,44,False
901,1,5,45,False
933,1,5,46,False
965,1,5,47,False
Control6,1,6,1,True
Control6,2,6,2,True
Control6,3,6,3,True
Control6,4,6,4,True
Control38,1,6,5,True
Control38,2,6,6,True
Control38,3,6,7,True
Control38,4,6,8,True
Control70,1,6,9,True
Control70,2,6,10,True
Control70,3,6,11,True
Control70,4,6,12,True
Control102,1,6,13,True
Control102,2,6,14,True
Control102,3,6,15,True
Control102,4,6,16,True
6,1,6,17,False
38,1,6,18,False
70,1,6,19,False
102,1,6,20,False
134,1,6,21,False
166,1,6,22,False
198,1,6,23,False
230,1,6,24,False
262,1,6,25,False
294,1,6,26,False
326,1,6,27,False
358,1,6,28,False
390,1,6,29,False
422,1,6,30,False
454,1,6,31,False
486,1,6,32,False
518,1,6,33,False
550,1,6,34,False
582,1,6,35,False
614,1,6,36,False
646,1,6,37,False
678,1,6,38,False
710,1,6,39,False
742,1,6,40,False
774,1,6,41,False
806,1,6,42,False
838,1,6,43,False
870,1,6,44,False
902,1,6,45,False
934,1,6,46,False
966,1,6,47,False
Control7,1,7,1,True
Control7,2,7,2,True
Control7,3,7,3,True
Control7,4,7,4,True
Control39,1,7,5,True
Control39,2,7,6,True
Control39,3,7,7,True
Control39,4,7,8,True
Control71,1,7,9,True
Control71,2,7,10,True
Control71,3,7,11,True
Control71,4,7,12,True
Control103,1,7,13,True
Control103,2,7,14,True
Control103,3,7,15,True
Control103,4,7,16,True
7,1,7,17,False
39,1,7,18,False
71,1,7,19,False
103,1,7,20,False
135,1,7,21,False
167,1,7,22,False
199,1,7,23,False
231,1,7,24,False
263,1,7,25,False
295,1,7,26,False
327,1,7,27,False
359,1,7,28,False
391,1,7,29,False
423,1,7,30,False
455,1,7,31,False
487,1,7,32,False
519,1,7,33,False
551,1,7,34,False
583,1,7,35,False
615,1,7,36,False
647,1,7,37,False
679,1,7,38,False
711,1,7,39,False
743,1,7,40,False
775,1,7,41,False
807,1,7,42,False
839,1,7,43,False
871,1,7,44,False
903,1,7,45,False
935,1,7,46,False
967,1,7,47,False
Control8,1,8,1,True
Control8,2,8,2,True
Control8,3,8,3,True
Control8,4,8,4,True
Control40,1,8,5,True
Control40,2,8,6,True
Control40,3,8,7,True
Control40,4,8,8,True
Control72,1,8,9,True
Control72,2,8,10,True
Control72,3,8,11,True
Control72,4,8,12,True
Control104,1,8,13,True
Control104,2,8,14,True
Control104,3,8,15,True
Control104,4,8,16,True
8,1,8,17,False
40,1,8,18,False
72,1,8,19,False
104,1,8,20,False
136,1,8,21,False
168,1,8,22,False
200,1,8,23,False
232,1,8,24,False
264,1,8,25,False
296,1,8,26,False
328,1,8,27,False
360,1,8,28,False
392,1,8,29,False
424,1,8,30,False
456,1,8,31,False
488,1,8,32,False
520,1,8,33,False
552,1,8,34,False
584,1,8,35,False
616,1,8,36,False
648,1,8,37,False
680,1,8,38,False
712,1,8,39,False
744,1,8,40,False
776,1,8,41,False
808,1,8,42,False
840,1,8,43,False
872,1,8,44,False
904,1,8,45,False
936,1,8,46,False
968,1,8,47,False
Control9,1,9,1,True
Control9,2,9,2,True
Control9,3,9,3,True
Control9,4,9,4,True
Control41,1,9,5,True
Control41,2,9,6,True
Control41,3,9,7,True
Control41,4,9,8,True
Control73,1,9,9,True
Control73,2,9,10,True
Control73,3,9,11,True
Control73,4,9,12,True
Control105,1,9,13,True
Control105,2,9,14,True
Control105,3,9,15,True
Control105,4,9,16,True
9,1,9,17,False
41,1,9,18,False
73,1,9,19,False
105,1,9,20,False
137,1,9,21,False
169,1,9,22,False
201,1,9,23,False
233,1,9,24,False
265,1,9,25,False
297,1,9,26,False
329,1,9,27,False
361,1,9,28,False
393,1,9,29,False
425,1,9,30,False
457,1,9,31,False
489,1,9,32,False
521,1,9,33,False
553,1,9,34,False
585,1,9,35,False
617,1,9,36,False
649,1,9,37,False
681,1,9,38,False
713,1,9,39,False
745,1,9,40,False
777,1,9,41,False
809,1,9,42,False
841,1,9,43,False
873,1,9,44,False
905,1,9,45,False
937,1,9,46,False
969,1,9,47,False
Control10,1,10,1,True
Control10,2,10,2,True
Control10,3,10,3,True
Control10,4,10,4,True
Control42,1,10,5,True
Control42,2,10,6,True
Control42,3,10,7,True
Control42,4,10,8,True
Control74,1,10,9,True
Control74,2,10,10,True
Control74,3,10,11,True
Control74,4,10,12,True
Control106,1,10,13,True
Control106,2,10,14,True
Control106,3,10,15,True
Control106,4,10,16,True
10,1,10,17,False
42,1,10,18,False
74,1,10,19,False
106,1,10,20,False
138,1,10,21,False
170,1,10,22,False
202,1,10,23,False
234,1,10,24,False
266,1,10,25,False
298,1,10,26,False
330,1,10,27,False
362,1,10,28,False
394,1,10,29,False
426,1,10,30,False
458,1,10,31,False
490,1,10,32,False
522,1,10,33,False
554,1,10,34,False
586,1,10,35,False
618,1,10,36,False
650,1,10,37,False
682,1,10,38,False
714,1,10,39,False
746,1,10,40,False
778,1,10,41,False
810,1,10,42,False
842,1,10,43,False
874,1,10,44,False
906,1,10,45,False
938,1,10,46,False
970,1,10,47,False
Control11,1,11,1,True
Control11,2,11,2,True
Control11,3,11,3,True
Control11,4,11,4,True
Control43,1,11,5,True
Control43,2,11,6,True
Control43,3,11,7,True
Control43,4,11,8,True
Control75,1,11,9,True
Control75,2,11,10,True
Control75,3,11,11,True
Control75,4,11,12,True
Control107,1,11,13,True
Control107,2,11,14,True
Control107,3,11,15,True
Control107,4,11,16,True
11,1,11,17,False
43,1,11,18,False
75,1,11,19,False
107,1,11,20,False
139,1,11,21,False
171,1,11,22,False
203,1,11,23,False
235,1,11,24,False
267,1,11,25,False
299,1,11,26,False
331,1,11,27,False
363,1,11,28,False
395,1,11,29,False
427,1,11,30,False
459,1,11,31,False
491,1,11,32,False
523,1,11,33,False
555,1,11,34,False
587,1,11,35,False
619,1,11,36,False
651,1,11,37,False
683,1,11,38,False
715,1,11,39,False
747,1,11,40,False
779,1,11,41,False
811,1,11,42,False
843,1,11,43,False
875,1,11,44,False
907,1,11,45,False
939,1,11,46,False
971,1,11,47,False
Control12,1,12,1,True
Control12,2,12,2,True
Control12,3,12,3,True
Control12,4,12,4,True
Control44,1,12,5,True
Control44,2,12,6,True
Control44,3,12,7,True
Control44,4,12,8,True
Control76,1,12,9,True
Control76,2,12,10,True
Control76,3,12,11,True
Control76,4,12,12,True
Control108,1,12,13,True
Control108,2,12,14,True
Control108,3,12,15,True
Control108,4,12,16,True
12,1,12,17,False
44,1,12,18,False
76,1,12,19,False
108,1,12,20,False
140,1,12,21,False
172,1,12,22,False
204,1,12,23,False
236,1,12,24,False
268,1,12,25,False
300,1,12,26,False
332,1,12,27,False
364,1,12,28,False
396,1,12,29,False
428,1,12,30,False
460,1,12,31,False
492,1,12,32,False
524,1,12,33,False
556,1,12,34,False
588,1,12,35,False
620,1,12,36,False
652,1,12,37,False
684,1,12,38,False
716,1,12,39,False
748,1,12,40,False
780,1,12,41,False
812,1,12,42,False
844,1,12,43,False
876,1,12,44,False
908,1,12,45,False
940,1,12,46,False
972,1,12,47,False
Control13,1,13,1,True
Control13,2,13,2,True
Control13,3,13,3,True
Control13,4,13,4,True
Control45,1,13,5,True
Control45,2,13,6,True
Control45,3,13,7,True
Control45,4,13,8,True
Control77,1,13,9,True
Control77,2,13,10,True
Control77,3,13,11,True
Control77,4,13,12,True
Control109,1,13,13,True
Control109,2,13,14,True
Control109,3,13,15,True
Control109,4,13,16,True
13,1,13,17,False
45,1,13,18,False
77,1,13,19,False
109,1,13,20,False
141,1,13,21,False
173,1,13,22,False
205,1,13,23,False
237,1,13,24,False
269,1,13,25,False
301,1,13,26,False
333,1,13,27,False
365,1,13,28,False
397,1,13,29,False
429,1,13,30,False
461,1,13,31,False
493,1,13,32,False
525,1,13,33,False
557,1,13,34,False
589,1,13,35,False
621,1,13,36,False
653,1,13,37,False
685,1,13,38,False
717,1,13,39,False
749,1,13,40,False
781,1,13,41,False
813,1,13,42,False
845,1,13,43,False
877,1,13,44,False
909,1,13,45,False
941,1,13,46,False
973,1,13,47,False
Control14,1,14,1,True
Control14,2,14,2,True
Control14,3,14,3,True
Control14,4,14,4,True
Control46,1,14,5,True
Control46,2,14,6,True
Control46,3,14,7,True
Control46,4,14,8,True
Control78,1,14,9,True
Control78,2,14,10,True
Control78,3,14,11,True
Control78,4,14,12,True
Control110,1,14,13,True
Control110,2,14,14,True
Control110,3,14,15,True
Control110,4,14,16,True
14,1,14,17,False
46,1,14,18,False
78,1,14,19,False
110,1,14,20,False
142,1,14,21,False
174,1,14,22,False
206,1,14,23,False
238,1,14,24,False
270,1,14,25,False
302,1,14,26,False
334,1,14,27,False
366,1,14,28,False
398,1,14,29,False
430,1,14,30,False
462,1,14,31,False
494,1,14,32,False
526,1,14,33,False
558,1,14,34,False
590,1,14,35,False
622,1,14,36,False
654,1,14,37,False
686,1,14,38,False
718,1,14,39,False
750,1,14,40,False
782,1,14,41,False
814,1,14,42,False
846,1,14,43,False
878,1,14,44,False
910,1,14,45,False
942,1,14,46,False
974,1,14,47,False
Control15,1,15,1,True
Control15,2,15,2,True
Control15,3,15,3,True
Control15,4,15,4,True
Control47,1,15,5,True
Control47,2,15,6,True
Control47,3,15,7,True
Control47,4,15,8,True
Control79,1,15,9,True
Control79,2,15,10,True
Control79,3,15,11,True
Control79,4,15,12,True
Control111,1,15,13,True
Control111,2,15,14,True
Control111,3,15,15,True
Control111,4,15,16,True
15,1,15,17,False
47,1,15,18,False
79,1,15,19,False
111,1,15,20,False
143,1,15,21,False
175,1,15,22,False
207,1,15,23,False
239,1,15,24,False
271,1,15,25,False
303,1,15,26,False
335,1,15,27,False
367,1,15,28,False
399,1,15,29,False
431,1,15,30,False
463,1,15,31,False
495,1,15,32,False
527,1,15,33,False
559,1,15,34,False
591,1,15,35,False
623,1,15,36,False
655,1,15,37,False
687,1,15,38,False
719,1,15,39,False
751,1,15,40,False
783,1,15,41,False
815,1,15,42,False
847,1,15,43,False
879,1,15,44,False
911,1,15,45,False
943,1,15,46,False
975,1,15,47,False
Control16,1,16,1,True
Control16,2,16,2,True
Control16,3,16,3,True
Control16,4,16,4,True
Control48,1,16,5,True
Control48,2,16,6,True
Control48,3,16,7,True
Control48,4,16,8,True
Control80,1,16,9,True
Control80,2,16,10,True
Control80,3,16,11,True
Control80,4,16,12,True
Control112,1,16,13,True
Control112,2,16,14,True
Control112,3,16,15,True
Control112,4,16,16,True
16,1,16,17,False
48,1,16,18,False
80,1,16,19,False
112,1,16,20,False
144,1,16,21,False
176,1,16,22,False
208,1,16,23,False
240,1,16,24,False
272,1,16,25,False
304,1,16,26,False
336,1,16,27,False
368,1,16,28,False
400,1,16,29,False
432,1,16,30,False
464,1,16,31,False
496,1,16,32,False
528,1,16,33,False
560,1,16,34,False
592,1,16,35,False
624,1,16,36,False
656,1,16,37,False
688,1,16,38,False
720,1,16,39,False
752,1,16,40,False
784,1,16,41,False
816,1,16,42,False
848,1,16,43,False
880,1,16,44,False
912,1,16,45,False
944,1,16,46,False
976,1,16,47,False
Control17,1,17,1,True
Control17,2,17,2,True
Control17,3,17,3,True
Control17,4,17,4,True
Control49,1,17,5,True
Control49,2,17,6,True
Control49,3,17,7,True
Control49,4,17,8,True
Control81,1,17,9,True
Control81,2,17,10,True
Control81,3,17,11,True
Control81,4,17,12,True
Control113,1,17,13,True
Control113,2,17,14,True
Control113,3,17,15,True
Control113,4,17,16,True
17,1,17,17,False
49,1,17,18,False
81,1,17,19,False
113,1,17,20,False
145,1,17,21,False
177,1,17,22,False
209,1,17,23,False
241,1,17,24,False
273,1,17,25,False
305,1,17,26,False
337,1,17,27,False
369,1,17,28,False
401,1,17,29,False
433,1,17,30,False
465,1,17,31,False
497,1,17,32,False
529,1,17,33,False
561,1,17,34,False
593,1,17,35,False
625,1,17,36,False
657,1,17,37,False
689,1,17,38,False
721,1,17,39,False
753,1,17,40,False
785,1,17,41,False
817,1,17,42,False
849,1,17,43,False
881,1,17,44,False
913,1,17,45,False
945,1,17,46,False
977,1,17,47,False
Control18,1,18,1,True
Control18,2,18,2,True
Control18,3,18,3,True
Control18,4,18,4,True
Control50,1,18,5,True
Control50,2,18,6,True
Control50,3,18,7,True
Control50,4,18,8,True
Control82,1,18,9,True
Control82,2,18,10,True
Control82,3,18,11,True
Control82,4,18,12,True
Control114,1,18,13,True
Control114,2,18,14,True
Control114,3,18,15,True
Control114,4,18,16,True
18,1,18,17,False
50,1,18,18,False
82,1,18,19,False
114,1,18,20,False
146,1,18,21,False
178,1,18,22,False
210,1,18,23,False
242,1,18,24,False
274,1,18,25,False
306,1,18,26,False
338,1,18,27,False
370,1,18,28,False
402,1,18,29,False
434,1,18,30,False
466,1,18,31,False
498,1,18,32,False
530,1,18,33,False
562,1,18,34,False
594,1,18,35,False
626,1,18,36,False
658,1,18,37,False
690,1,18,38,False
722,1,18,39,False
754,1,18,40,False
786,1,18,41,False
818,1,18,42,False
850,1,18,43,False
882,1,18,44,False
914,1,18,45,False
946,1,18,46,False
978,1,18,47,False
Control19,1,19,1,True
Control19,2,19,2,True
Control19,3,19,3,True
Control19,4,19,4,True
Control51,1,19,5,True
Control51,2,19,6,True
Control51,3,19,7,True
Control51,4,19,8,True
Control83,1,19,9,True
Control83,2,19,10,True
Control83,3,19,11,True
Control83,4,19,12,True
Control115,1,19,13,True
Control115,2,19,14,True
Control115,3,19,15,True
Control115,4,19,16,True
19,1,19,17,False
51,1,19,18,False
83,1,19,19,False
115,1,19,20,False
147,1,19,21,False
179,1,19,22,False
211,1,19,23,False
243,1,19,24,False
275,1,19,25,False
307,1,19,26,False
339,1,19,27,False
371,1,19,28,False
403,1,19,29,False
435,1,19,30,False
467,1,19,31,False
499,1,19,32,False
531,1,19,33,False
563,1,19,34,False
595,1,19,35,False
627,1,19,36,False
659,1,19,37,False
691,1,19,38,False
723,1,19,39,False
755,1,19,40,False
787,1,19,41,False
819,1,19,42,False
851,1,19,43,False
883,1,19,44,False
915,1,19,45,False
947,1,19,46,False
979,1,19,47,False
Control20,1,20,1,True
Control20,2,20,2,True
Control20,3,20,3,True
Control20,4,20,4,True
Control52,1,20,5,True
Control52,2,20,6,True
Control52,3,20,7,True
Control52,4,20,8,True
Control84,1,20,9,True
Control84,2,20,10,True
Control84,3,20,11,True
Control84,4,20,12,True
Control116,1,20,13,True
Control116,2,20,14,True
Control116,3,20,15,True
Control116,4,20,16,True
20,1,20,17,False
52,1,20,18,False
84,1,20,19,False
116,1,20,20,False
148,1,20,21,False
180,1,20,22,False
212,1,20,23,False
244,1,20,24,False
276,1,20,25,False
308,1,20,26,False
340,1,20,27,False
372,1,20,28,False
404,1,20,29,False
436,1,20,30,False
468,1,20,31,False
500,1,20,32,False
532,1,20,33,False
564,1,20,34,False
596,1,20,35,False
628,1,20,36,False
660,1,20,37,False
692,1,20,38,False
724,1,20,39,False
756,1,20,40,False
788,1,20,41,False
820,1,20,42,False
852,1,20,43,False
884,1,20,44,False
916,1,20,45,False
948,1,20,46,False
980,1,20,47,False
Control21,1,21,1,True
Control21,2,21,2,True
Control21,3,21,3,True
Control21,4,21,4,True
Control53,1,21,5,True
Control53,2,21,6,True
Control53,3,21,7,True
Control53,4,21,8,True
Control85,1,21,9,True
Control85,2,21,10,True
Control85,3,21,11,True
Control85,4,21,12,True
Control117,1,21,13,True
Control117,2,21,14,True
Control117,3,21,15,True
Control117,4,21,16,True
21,1,21,17,False
53,1,21,18,False
85,1,21,19,False
117,1,21,20,False
149,1,21,21,False
181,1,21,22,False
213,1,21,23,False
245,1,21,24,False
277,1,21,25,False
309,1,21,26,False
341,1,21,27,False
373,1,21,28,False
405,1,21,29,False
437,1,21,30,False
469,1,21,31,False
501,1,21,32,False
533,1,21,33,False
565,1,21,34,False
597,1,21,35,False
629,1,21,36,False
661,1,21,37,False
693,1,21,38,False
725,1,21,39,False
757,1,21,40,False
789,1,21,41,False
821,1,21,42,False
853,1,21,43,False
885,1,21,44,False
917,1,21,45,False
949,1,21,46,False
981,1,21,47,False
Control22,1,22,1,True
Control22,2,22,2,True
Control22,3,22,3,True
Control22,4,22,4,True
Control54,1,22,5,True
Control54,2,22,6,True
Control54,3,22,7,True
Control54,4,22,8,True
Control86,1,22,9,True
Control86,2,22,10,True
Control86,3,22,11,True
Control86,4,22,12,True
Control118,1,22,13,True
Control118,2,22,14,True
Control118,3,22,15,True
Control118,4,22,16,True
22,1,22,17,False
54,1,22,18,False
86,1,22,19,False
118,1,22,20,False
150,1,22,21,False
182,1,22,22,False
214,1,22,23,False
246,1,22,24,False
278,1,22,25,False
310,1,22,26,False
342,1,22,27,False
374,1,22,28,False
406,1,22,29,False
438,1,22,30,False
470,1,22,31,False
502,1,22,32,False
534,1,22,33,False
566,1,22,34,False
598,1,22,35,False
630,1,22,36,False
662,1,22,37,False
694,1,22,38,False
726,1,22,39,False
758,1,22,40,False
790,1,22,41,False
822,1,22,42,False
854,1,22,43,False
886,1,22,44,False
918,1,22,45,False
950,1,22,46,False
982,1,22,47,False
Control23,1,23,1,True
Control23,2,23,2,True
Control23,3,23,3,True
Control23,4,23,4,True
Control55,1,23,5,True
Control55,2,23,6,True
Control55,3,23,7,True
Control55,4,23,8,True
Control87,1,23,9,True
Control87,2,23,10,True
Control87,3,23,11,True
Control87,4,23,12,True
Control119,1,23,13,True
Control119,2,23,14,True
Control119,3,23,15,True
Control119,4,23,16,True
23,1,23,17,False
55,1,23,18,False
87,1,23,19,False
119,1,23,20,False
151,1,23,21,False
183,1,23,22,False
215,1,23,23,False
247,1,23,24,False
279,1,23,25,False
311,1,23,26,False
343,1,23,27,False
375,1,23,28,False
407,1,23,29,False
439,1,23,30,False
471,1,23,31,False
503,1,23,32,False
535,1,23,33,False
567,1,23,34,False
599,1,23,35,False
631,1,23,36,False
663,1,23,37,False
695,1,23,38,False
727,1,23,39,False
759,1,23,40,False
791,1,23,41,False
823,1,23,42,False
855,1,23,43,False
887,1,23,44,False
919,1,23,45,False
951,1,23,46,False
983,1,23,47,False
Control24,1,24,1,True
Control24,2,24,2,True
Control24,3,24,3,True
Control24,4,24,4,True
Control56,1,24,5,True
Control56,2,24,6,True
Control56,3,24,7,True
Control56,4,24,8,True
Control88,1,24,9,True
Control88,2,24,10,True
Control88,3,24,11,True
Control88,4,24,12,True
Control120,1,24,13,True
Control120,2,24,14,True
Control120,3,24,15,True
Control120,4,24,16,True
24,1,24,17,False
56,1,24,18,False
88,1,24,19,False
120,1,24,20,False
152,1,24,21,False
184,1,24,22,False
216,1,24,23,False
248,1,24,24,False
280,1,24,25,False
312,1,24,26,False
344,1,24,27,False
376,1,24,28,False
408,1,24,29,False
440,1,24,30,False
472,1,24,31,False
504,1,24,32,False
536,1,24,33,False
568,1,24,34,False
600,1,24,35,False
632,1,24,36,False
664,1,24,37,False
696,1,24,38,False
728,1,24,39,False
760,1,24,40,False
792,1,24,41,False
824,1,24,42,False
856,1,24,43,False
888,1,24,44,False
920,1,24,45,False
952,1,24,46,False
984,1,24,47,False
Control25,1,25,1,True
Control25,2,25,2,True
Control25,3,25,3,True
Control25,4,25,4,True
Control57,1,25,5,True
Control57,2,25,6,True
Control57,3,25,7,True
Control57,4,25,8,True
Control89,1,25,9,True
Control89,2,25,10,True
Control89,3,25,11,True
Control89,4,25,12,True
Control121,1,25,13,True
Control121,2,25,14,True
Control121,3,25,15,True
Control121,4,25,16,True
25,1,25,17,False
57,1,25,18,False
89,1,25,19,False
121,1,25,20,False
153,1,25,21,False
185,1,25,22,False
217,1,25,23,False
249,1,25,24,False
281,1,25,25,False
313,1,25,26,False
345,1,25,27,False
377,1,25,28,False
409,1,25,29,False
441,1,25,30,False
473,1,25,31,False
505,1,25,32,False
537,1,25,33,False
569,1,25,34,False
601,1,25,35,False
633,1,25,36,False
665,1,25,37,False
697,1,25,38,False
729,1,25,39,False
761,1,25,40,False
793,1,25,41,False
825,1,25,42,False
857,1,25,43,False
889,1,25,44,False
921,1,25,45,False
953,1,25,46,False
985,1,25,47,False
Control26,1,26,1,True
Control26,2,26,2,True
Control26,3,26,3,True
Control26,4,26,4,True
Control58,1,26,5,True
Control58,2,26,6,True
Control58,3,26,7,True
Control58,4,26,8,True
Control90,1,26,9,True
Control90,2,26,10,True
Control90,3,26,11,True
Control90,4,26,12,True
Control122,1,26,13,True
Control122,2,26,14,True
Control122,3,26,15,True
Control122,4,26,16,True
26,1,26,17,False
58,1,26,18,False
90,1,26,19,False
122,1,26,20,False
154,1,26,21,False
186,1,26,22,False
218,1,26,23,False
250,1,26,24,False
282,1,26,25,False
314,1,26,26,False
346,1,26,27,False
378,1,26,28,False
410,1,26,29,False
442,1,26,30,False
474,1,26,31,False
506,1,26,32,False
538,1,26,33,False
570,1,26,34,False
602,1,26,35,False
634,1,26,36,False
666,1,26,37,False
698,1,26,38,False
730,1,26,39,False
762,1,26,40,False
794,1,26,41,False
826,1,26,42,False
858,1,26,43,False
890,1,26,44,False
922,1,26,45,False
954,1,26,46,False
986,1,26,47,False
Control27,1,27,1,True
Control27,2,27,2,True
Control27,3,27,3,True
Control27,4,27,4,True
Control59,1,27,5,True
Control59,2,27,6,True
Control59,3,27,7,True
Control59,4,27,8,True
Control91,1,27,9,True
Control91,2,27,10,True
Control91,3,27,11,True
Control91,4,27,12,True
Control123,1,27,13,True
Control123,2,27,14,True
Control123,3,27,15,True
Control123,4,27,16,True
27,1,27,17,False
59,1,27,18,False
91,1,27,19,False
123,1,27,20,False
155,1,27,21,False
187,1,27,22,False
219,1,27,23,False
251,1,27,24,False
283,1,27,25,False
315,1,27,26,False
347,1,27,27,False
379,1,27,28,False
411,1,27,29,False
443,1,27,30,False
475,1,27,31,False
507,1,27,32,False
539,1,27,33,False
571,1,27,34,False
603,1,27,35,False
635,1,27,36,False
667,1,27,37,False
699,1,27,38,False
731,1,27,39,False
763,1,27,40,False
795,1,27,41,False
827,1,27,42,False
859,1,27,43,False
891,1,27,44,False
923,1,27,45,False
955,1,27,46,False
987,1,27,47,False
Control28,1,28,1,True
Control28,2,28,2,True
Control28,3,28,3,True
Control28,4,28,4,True
Control60,1,28,5,True
Control60,2,28,6,True
Control60,3,28,7,True
Control60,4,28,8,True
Control92,1,28,9,True
Control92,2,28,10,True
Control92,3,28,11,True
Control92,4,28,12,True
Control124,1,28,13,True
Control124,2,28,14,True
Control124,3,28,15,True
Control124,4,28,16,True
28,1,28,17,False
60,1,28,18,False
92,1,28,19,False
124,1,28,20,False
156,1,28,21,False
188,1,28,22,False
220,1,28,23,False
252,1,28,24,False
284,1,28,25,False
316,1,28,26,False
348,1,28,27,False
380,1,28,28,False
412,1,28,29,False
444,1,28,30,False
476,1,28,31,False
508,1,28,32,False
540,1,28,33,False
572,1,28,34,False
604,1,28,35,False
636,1,28,36,False
668,1,28,37,False
700,1,28,38,False
732,1,28,39,False
764,1,28,40,False
796,1,28,41,False
828,1,28,42,False
860,1,28,43,False
892,1,28,44,False
924,1,28,45,False
956,1,28,46,False
988,1,28,47,False
Control29,1,29,1,True
Control29,2,29,2,True
Control29,3,29,3,True
Control29,4,29,4,True
Control61,1,29,5,True
Control61,2,29,6,True
Control61,3,29,7,True
Control61,4,29,8,True
Control93,1,29,9,True
Control93,2,29,10,True
Control93,3,29,11,True
Control93,4,29,12,True
Control125,1,29,13,True
Control125,2,29,14,True
Control125,3,29,15,True
Control125,4,29,16,True
29,1,29,17,False
61,1,29,18,False
93,1,29,19,False
125,1,29,20,False
157,1,29,21,False
189,1,29,22,False
221,1,29,23,False
253,1,29,24,False
285,1,29,25,False
317,1,29,26,False
349,1,29,27,False
381,1,29,28,False
413,1,29,29,False
445,1,29,30,False
477,1,29,31,False
509,1,29,32,False
541,1,29,33,False
573,1,29,34,False
605,1,29,35,False
637,1,29,36,False
669,1,29,37,False
701,1,29,38,False
733,1,29,39,False
765,1,29,40,False
797,1,29,41,False
829,1,29,42,False
861,1,29,43,False
893,1,29,44,False
925,1,29,45,False
957,1,29,46,False
989,1,29,47,False
diluent,0,29,48,True
Control30,1,30,1,True
Control30,2,30,2,True
Control30,3,30,3,True
Control30,4,30,4,True
Control62,1,30,5,True
Control62,2,30,6,True
Control62,3,30,7,True
Control62,4,30,8,True
Control94,1,30,9,True
Control94,2,30,10,True
Control94,3,30,11,True
Control94,4,30,12,True
Control126,1,30,13,True
Control126,2,30,14,True
Control126,3,30,15,True
Control126,4,30,16,True
30,1,30,17,False
62,1,30,18,False
94,1,30,19,False
126,1,30,20,False
158,1,30,21,False
190,1,30,22,False
222,1,30,23,False
254,1,30,24,False
286,1,30,25,False
318,1,30,26,False
350,1,30,27,False
382,1,30,28,False
414,1,30,29,False
446,1,30,30,False
478,1,30,31,False
510,1,30,32,False
542,1,30,33,False
574,1,30,34,False
606,1,30,35,False
638,1,30,36,False
670,1,30,37,False
702,1,30,38,False
734,1,30,39,False
766,1,30,40,False
798,1,30,41,False
830,1,30,42,False
862,1,30,43,False
894,1,30,44,False
926,1,30,45,False
958,1,30,46,False
990,1,30,47,False
Control31,1,31,1,True
Control31,2,31,2,True
Control31,3,31,3,True
Control31,4,31,4,True
Control63,1,31,5,True
Control63,2,31,6,True
Control63,3,31,7,True
Control63,4,31,8,True
Control95,1,31,9,True
Control95,2,31,10,True
Control95,3,31,11,True
Control95,4,31,12,True
Control127,1,31,13,True
Control127,2,31,14,True
Control127,3,31,15,True
Control127,4,31,16,True
31,1,31,17,False
63,1,31,18,False
95,1,31,19,False
127,1,31,20,False
159,1,31,21,False
191,1,31,22,False
223,1,31,23,False
255,1,31,24,False
287,1,31,25,False
319,1,31,26,False
351,1,31,27,False
383,1,31,28,False
415,1,31,29,False
447,1,31,30,False
479,1,31,31,False
511,1,31,32,False
543,1,31,33,False
575,1,31,34,False
607,1,31,35,False
639,1,31,36,False
671,1,31,37,False
703,1,31,38,False
735,1,31,39,False
767,1,31,40,False
799,1,31,41,False
831,1,31,42,False
863,1,31,43,False
895,1,31,44,False
927,1,31,45,False
959,1,31,46,False
991,1,31,47,False
Control32,1,32,1,True
Control32,2,32,2,True
Control32,3,32,3,True
Control32,4,32,4,True
Control64,1,32,5,True
Control64,2,32,6,True
Control64,3,32,7,True
Control64,4,32,8,True
Control96,1,32,9,True
Control96,2,32,10,True
Control96,3,32,11,True
Control96,4,32,12,True
Control128,1,32,13,True
Control128,2,32,14,True
Control128,3,32,15,True
Control128,4,32,16,True
32,1,32,17,False
64,1,32,18,False
96,1,32,19,False
128,1,32,20,False
160,1,32,21,False
192,1,32,22,False
224,1,32,23,False
256,1,32,24,False
288,1,32,25,False
320,1,32,26,False
352,1,32,27,False
384,1,32,28,False
416,1,32,29,False
448,1,32,30,False
480,1,32,31,False
512,1,32,32,False
544,1,32,33,False
576,1,32,34,False
608,1,32,35,False
640,1,32,36,False
672,1,32,37,False
704,1,32,38,False
736,1,32,39,False
768,1,32,40,False
800,1,32,41,False
832,1,32,42,False
864,1,32,43,False
896,1,32,44,False
928,1,32,45,False
960,1,32,46,False
992,1,32,47,False
`;
