import { BehaviorSubject, combineLatest } from 'rxjs';
import { ModelAction, ReactiveModel } from '../../../lib/util/reactive-model';
import { roundValue } from '../../../lib/util/roundValues';
import { HTEDAssets } from '../assets';
import { HTEEVialRack, HTEPRobotRunIdT, HTEProtocolIdT } from '../data-model';
import { ExecutionInfo, RunInventoryMap } from './utils';
import type { HTEMosquitoExecutionModel } from './mosquito';
import { HTE2Api } from '../api';
import { Sample } from '../../Compounds/compound-api';

export class HTEESolubilizeModel extends ReactiveModel {
    state = {
        files: new BehaviorSubject<File[]>([]),
        racks: new BehaviorSubject<Record<string, HTEEVialRack>>({}),
    };

    actions = {
        data: new ModelAction<SolubilizeInfo>({ onError: 'toast', toastErrorLabel: 'Solubilize' }),
        parseRacks: new ModelAction<Record<string, HTEEVialRack>>({
            onError: 'toast',
            toastErrorLabel: 'Solubilize',
            applyResult: (racks) => this.state.racks.next({ ...this.state.racks.value, ...racks }),
        }),
    };

    private async parseRacks(files: File[]): Promise<Record<string, HTEEVialRack>> {
        const parsed = await Promise.all(files.map((f) => HTE2Api.parseRacks(f)));
        const racks: Record<string, HTEEVialRack> = {};
        for (const r of parsed) {
            Object.assign(racks, r);
        }
        return racks;
    }

    removeRack(barcode: string) {
        const racks = { ...this.state.racks.value };
        delete racks[barcode];
        this.state.racks.next(racks);
    }

    mount() {
        this.subscribe(combineLatest([this.state.racks, this.execution.state.info]), ([rackMap, info]) => {
            const { hteId } = this.execution.model;
            const racks = Array.from(Object.values(rackMap));
            this.actions.data.run(buildSolubilize(hteId, this.execution.model.assets, info, racks));
        });

        this.subscribe(this.state.files, (files) => {
            this.actions.parseRacks.run(this.parseRacks(files));
        });
    }

    constructor(public execution: HTEMosquitoExecutionModel) {
        super();
    }
}

export interface SolubilizeData {
    ot2: string;
    solvents: string[];
    tecanCsv: Record<string, string>;
    missingItems: string[];
    missingVials: string[];
    missingSamples: string[];
    genericErrors: string[];
}

type SolubilizeEntry = [
    rack_barcode: string,
    well: string,
    volume_ul: number,
    vial_barcode: string,
    batch_identifier: string,
    concentration: number,
    sample: Sample
];

export type SolubilizeInfo = Record<HTEProtocolIdT, Record<HTEPRobotRunIdT, SolubilizeData>>;

async function buildSolubilize(
    hteId: string,
    assets: HTEDAssets,
    info: ExecutionInfo,
    racks: HTEEVialRack[]
): Promise<SolubilizeInfo> {
    const data: SolubilizeInfo = {};
    for (const [protocolId, protocolInfo] of Object.entries(info)) {
        data[protocolId] = {};
        for (const [runId, runInfo] of Object.entries(protocolInfo)) {
            // eslint-disable-next-line no-await-in-loop
            data[protocolId][runId] = await buildSolubilizeEntry(hteId, assets, runInfo.inventory, racks);
        }
    }
    return data;
}

async function buildSolubilizeEntry(
    hteId: string,
    assets: HTEDAssets,
    inventory: RunInventoryMap,
    racks: HTEEVialRack[]
): Promise<SolubilizeData> {
    const missingItems: string[] = [];
    const missingVials: string[] = [];
    const missingSamples: string[] = [];
    const genericErrors: string[] = [];

    const solvents: string[] = [];
    const targets: Record<string, SolubilizeEntry[]> = {};

    const vialBarcodes: string[] = [];
    for (const rack of racks) {
        for (const barcode of Object.values(rack.wells)) {
            const item = inventory.vialBarcodeToItem.get(barcode);
            if (item) vialBarcodes.push(barcode);
        }
    }
    await assets.syncHolders(vialBarcodes, true);

    for (const rack of racks) {
        for (const [well, vialBarcode] of Object.entries(rack.wells)) {
            const item = inventory.vialBarcodeToItem.get(vialBarcode);
            if (!item) {
                missingItems.push(`${vialBarcode} in ${rack.barcode}@${well}`);
                continue;
            }

            if (!item.concentration) {
                genericErrors.push(
                    `${vialBarcode} for ${assets.entities.getIdentifier(item.identifier!)} in ${
                        rack.barcode
                    }@${well}: no concentration assigned`
                );
                continue;
            }

            const vial = assets.vials.get(vialBarcode);
            if (!vial) {
                missingVials.push(`${vialBarcode} in ${rack.barcode}@${well}`);
                continue;
            }

            if (!vial.sample) {
                missingSamples.push(`${vialBarcode} in ${rack.barcode}@${well}`);
                continue;
            }

            const batch = assets.entities.batchesById.get(vial.sample.batch_id);
            if (!batch) {
                genericErrors.push(
                    `${vialBarcode} for ${assets.entities.getIdentifier(item.identifier!)} in ${
                        rack.barcode
                    }@${well}: could not find batch`
                );
                continue;
            }

            const solvent = item.solvent;
            if (!targets[solvent]) {
                targets[solvent] = [];
                solvents.push(solvent);
            }

            const amount_g = vial.sample.solute_mass!;
            const conc = item.concentration;
            const volume_ul = roundValue(3, (amount_g / (conc * batch.formula_weight)) * 1e6);

            targets[solvent].push([
                rack.barcode,
                well,
                volume_ul,
                vialBarcode,
                assets.entities.getIdentifierForBatchId(batch.id) ?? '',
                conc,
                vial.sample,
            ]);
        }
    }

    const ot2 = buildOT2Solubilize({ hteId, solvents, targets, racks });
    const tecan: Record<string, string> = {};
    for (const solvent of solvents) {
        tecan[solvent] = tecanCsv(targets[solvent]);
    }

    return {
        solvents,
        ot2,
        tecanCsv: tecan,
        missingItems,
        missingVials,
        missingSamples,
        genericErrors,
    };
}

function tecanCsv(entries: SolubilizeEntry[]) {
    const rows = entries.map(
        ([rack_barcode, well, volume_ul, vial_barcode, batch_identifier]) =>
            `${rack_barcode},${vial_barcode},${well},${volume_ul},${batch_identifier}`
    );

    return `Rack Barcode,Barcode,Well,Solvent To Add,Batch Identifier\n${rows.join('\n')}`;
}

interface OT2SolubilizeInput {
    hteId: string;
    solvents: string[];
    racks: HTEEVialRack[];
    targets: Record<string, SolubilizeEntry[]>;
}

function buildOT2Solubilize(input: OT2SolubilizeInput) {
    const solvents = input.solvents.map((s, i) => `  "${s}": "A${i + 1}",`).join('\n');
    const labware = input.racks.map((r, i) => `  "${r.barcode}": ("thomson_24_tuberack_3600ul", ${i + 3}),`).join('\n');

    const solubilize: string[] = [];
    for (const sol of input.solvents) {
        const solventRows = input.targets[sol];

        solubilize.push(`  ("${sol}", [`);
        for (const [barcode, well, volume, vial_barcode, batch_identifier, conc, sample] of solventRows) {
            solubilize.push(
                `    ("${barcode}", "${well}", ${volume}),  # ${roundValue(
                    2,
                    sample.solute_mass! * 1000
                )}mg of ${batch_identifier} in ${vial_barcode} at ${roundValue(2, 1000 * conc)} mM`
            );
        }
        solubilize.push('  ]),');
    }

    return OT2Template.replace('{{HTEID}}', input.hteId)
        .replace('{{SOLVENTS}}', solvents)
        .replace('{{LABWARE}}', labware)
        .replace('{{SOLUBILIZE}}', solubilize.join('\n'));
}

const OT2Template = `import math
from opentrons.protocol_api import ProtocolContext, Well, labware

metadata = {
  "protocolName": "HTE Solubilization",
  "author": "HTE Wizard",
  "source": "{{HTEID}}",
  "apiLevel": "2.13"
}

PARAMS = {
  "instrument": ("p300_single_gen2", "right"),  # kind, mount
  "default_speed": 400,  # comment out for built-in default
  "aspirate_rate": 100,  # comment out for built-in default
  "mix": (3, 250),  # repetitions, volume; comment out to disable
  "transfer_volume": 275,  # uL
  "air_gap": 25,  # uL
}

TIPS = ("opentrons_96_tiprack_300ul", 1)
SOLVENT_LABWARE = ("analyticalsales_4_reservoir_73000ul", 2)
SOLVENTS = {
{{SOLVENTS}}
}

LABWARE = {
  # labware, position
{{LABWARE}}
}


def run(protocol: ProtocolContext):
    solvent_wells = protocol.load_labware(SOLVENT_LABWARE[0], SOLVENT_LABWARE[1]).wells_by_name()
    solvents = {k: solvent_wells[v] for k, v in SOLVENTS.items()}

    plates = {
        barcode: protocol.load_labware(labware, slot).wells_by_name()
        for barcode, (labware, slot) in LABWARE.items()
    }

    tip_racks = [protocol.load_labware(t, p) for t, p in [TIPS]]
    instr, mount = PARAMS["instrument"]
    pipette = protocol.load_instrument(instr, mount=mount, tip_racks=tip_racks)

    if "aspirate_rate" in PARAMS:
        pipette.flow_rate.aspirate = PARAMS["aspirate_rate"]
    if "default_speed" in PARAMS:
        pipette.default_speed = PARAMS["default_speed"]

    def tip_pick_up():
        try:
            pipette.pick_up_tip()
        except labware.OutOfTipsError:
            protocol.set_rail_lights(False)
            protocol.pause("Replace the tips")
            pipette.reset_tipracks()
            protocol.set_rail_lights(True)
            pipette.pick_up_tip()

    xfer_vol = PARAMS["transfer_volume"]
    air_gap = PARAMS.get("air_gap")

    def dispense(src: Well, dest: Well, volume: float):
        pipette.aspirate(volume, src)
        if air_gap:
          protocol.max_speeds['a'] = 10
          pipette.air_gap(air_gap)
          protocol.max_speeds['a'] = None

        pipette.dispense(volume, dest.top(-4))
        pipette.blow_out(dest) 

    protocol.set_rail_lights(True)

    for solvent_name, targets in SOLUBILIZE:
        src = solvents[solvent_name]

        tip_pick_up()

        if "mix" in PARAMS:
            repetitions, volume = PARAMS["mix"]
            pipette.mix(repetitions, volume, src)

        for barcode, well, volume in targets:
            disp_count = math.ceil(volume / xfer_vol)
            disp_volume = volume / disp_count
            for _ in range(disp_count):
                dispense(src, plates[barcode][well], disp_volume)

        pipette.drop_tip()


# Solvent -> list[(Barcode, Well, Volume)]
SOLUBILIZE = [
{{SOLUBILIZE}}
]`;
