import { Batch, CompoundDetail, Substance } from '../../pages/Compounds/compound-api';
import { ECMApi, ECMSearchResult, Vial } from '../../pages/ECM/ecm-api';
import { addCompoundEntityToMap, normalizeEntityIdentifier } from '../util/identifiers';

export class CompoundAssets {
    readonly entities = new Map<string, Batch | CompoundDetail>();
    readonly batchesById = new Map<number, Batch>();
    readonly batchesByCompoundId = new Map<number, Batch[]>();
    readonly compoundById = new Map<number, CompoundDetail>();
    readonly substancesById = new Map<number, Substance>();

    private universalIdentifierToLookup = new Map<string, string>();

    add(entity: Batch | CompoundDetail, force = false) {
        if (isBatchEntity(entity)) {
            if (this.batchesById.has(entity.id) && !force) return;

            addCompoundEntityToMap(entity, this.entities);
            this.batchesById.set(entity.id, entity);
            let xs = this.batchesByCompoundId.get(entity.compound_id);
            if (!xs) {
                xs = [];
                this.batchesByCompoundId.set(entity.compound_id, xs);
            }
            if (!xs.find((x) => x.id === entity.id)) {
                xs.push(entity);
            }
        } else {
            if (this.compoundById.has(entity.id) && !force) return;

            addCompoundEntityToMap(entity, this.entities);
            this.compoundById.set(entity.id, entity);
        }

        if (typeof entity.structure.id === 'number') {
            this.substancesById.set(entity.structure.id, entity.structure);
        }
    }

    getEntity(identifier: string | undefined) {
        return identifier ? this.entities.get(identifier) : undefined;
    }

    getEntityByNormalizedIdentifier(identifier: string | undefined) {
        return identifier ? this.entities.get(normalizeEntityIdentifier(identifier)) : undefined;
    }

    getBatch(id: number | string) {
        if (typeof id === 'number') return this.batchesById.get(id);
        const ent = this.entities.get(id);
        return isBatchEntity(ent) ? ent : undefined;
    }

    getCompound(id: number | string) {
        if (typeof id === 'number') return this.compoundById.get(id);
        const ent = this.entities.get(id);
        return !isBatchEntity(ent) ? ent : undefined;
    }

    getCompoundFromIdentifier(identifier: string) {
        const ent = this.entities.get(identifier);
        if (isBatchEntity(ent)) return this.compoundById.get(ent.compound_id);
        return ent;
    }

    getCompoundId(identifier: string) {
        const ent = this.entities.get(identifier);
        return isBatchEntity(ent) ? ent.compound_id : ent?.id;
    }

    getStructure(identifer: string) {
        return this.entities.get(identifer)?.structure.smiles;
    }

    getSubstanceId(identifer: string) {
        return this.entities.get(identifer)?.structure.id;
    }

    getFW(identifer: string) {
        const ent = this.entities.get(identifer);
        if (!ent) return undefined;
        return isBatchEntity(ent) ? ent.formula_weight : ent.molecular_weight;
    }

    getIdentifier(universal_identifier: string) {
        const ent = this.entities.get(universal_identifier);
        if (ent) return ent.entos_identifier ?? ent.universal_identifier ?? universal_identifier;
        return universal_identifier;
    }

    getIdentifierForBatchId(id: number) {
        const ent = this.batchesById.get(id);
        if (ent) return ent.entos_identifier ?? ent.universal_identifier;
        return `Batch ID ${id}`;
    }

    getIdentifiersLookup(universal_identifier: string | undefined) {
        if (!universal_identifier) return '';

        // Cached table lookup string for identifiers
        if (this.universalIdentifierToLookup.has(universal_identifier)) {
            return this.universalIdentifierToLookup.get(universal_identifier)!;
        }

        const ent = this.entities.get(universal_identifier);
        const parts: string[] = [universal_identifier];
        if (ent?.entos_identifier) parts.push(ent.entos_identifier);

        const lookup = parts.join('\n');
        this.universalIdentifierToLookup.set(universal_identifier, lookup);
        return lookup;
    }

    async syncIdentifiers(identifiers: string[]) {
        const missingEntities = identifiers.filter((b) => !this.entities.has(b));
        if (missingEntities.length === 0) return;

        const entities = await ECMApi.queryIdentifiers({
            identifiers: Array.from(new Set(missingEntities)),
        });

        for (const batch of entities.batches) this.add(batch);
        for (const compound of entities.compounds) this.add(compound);
    }

    async syncBatchIds(ids: number[]) {
        const missingIds = ids.filter((id) => !this.batchesById.has(id));
        if (missingIds.length === 0) return;

        const entities = await ECMApi.queryBatches({
            ids: missingIds,
            query_compounds: false,
            query_all: true,
        });

        for (const batch of entities.batches) this.add(batch);
    }

    async syncSubstanceIds(ids: number[]) {
        const missingIds = ids.filter((id) => !this.substancesById.has(id));
        if (missingIds.length === 0) return;

        const substances = await ECMApi.querySubstances({ ids: missingIds });
        for (const s of substances) this.substancesById.set(s.id!, s);
    }
}

export function isBatchEntity(e: any): e is Batch {
    return !!e && 'compound_id' in e;
}

export class VialInventoryAssets {
    constructor(public entities: CompoundAssets) {}

    private compoundToResults = new Map<string, ECMSearchResult[]>();
    private resultByBarcode = new Map<string, ECMSearchResult>();
    private vials = new Map<string, Vial>();

    private getInventoryKey(identifier: string) {
        return identifier.split('-', 1)[0];
    }

    getByIdentifier(identifier: string) {
        return this.compoundToResults.get(this.getInventoryKey(identifier));
    }

    getByBarcode(barcode: string) {
        return this.resultByBarcode.get(barcode);
    }

    getVialBatch(barcode: string) {
        const vial = this.vials.get(barcode) ?? this.resultByBarcode.get(barcode);
        return this.entities.batchesById.get(vial?.sample?.batch_id!);
    }

    getVialSample(barcode: string) {
        return this.resultByBarcode.get(barcode)?.sample ?? this.vials.get(barcode)?.sample;
    }

    async syncInventory(identifiers: string[], refresh = false) {
        const keys = Array.from(new Set(identifiers)).map((e) => this.getInventoryKey(e));
        const missing = refresh ? keys : keys.filter((e) => !this.compoundToResults.has(e));

        if (missing.length === 0) return;

        const query = await ECMApi.query({ identifiers: missing, query_plates: false, skip_not_found: true });
        const results = query.results.toObjects();

        const map = this.compoundToResults;
        const addToMap = (id: string, r: ECMSearchResult) => {
            if (!map.has(id)) {
                map.set(id, [r]);
            } else {
                map.get(id)!.push(r);
            }
        };

        for (const e of missing) {
            this.compoundToResults.delete(e);
        }

        for (const r of results) {
            this.resultByBarcode.set(r.barcode, r);
            if (!r.sample) continue;

            const batch = query.batches[r.sample.batch_id];
            const compound = query.compounds[batch.compound_id];

            this.entities.add(compound);
            this.entities.add(batch);

            addToMap(compound.universal_identifier!, r);
            if (r.barcode) this.resultByBarcode.set(r.barcode, r);
        }
    }

    async syncHolders(barcodes: string[], refresh = false) {
        const missing = refresh ? barcodes : barcodes.filter((b) => !this.vials.has(b));
        if (!missing.length) return;

        const { vials } = await ECMApi.queryHolders({ barcodes: missing });
        const batchIdSet = new Set<number>();
        for (const v of vials) {
            this.vials.set(v.barcode, v);
            if (v.sample) batchIdSet.add(v.sample?.batch_id);
        }

        await this.entities.syncBatchIds(Array.from(batchIdSet));
    }

    getExistingVial(barcode: string) {
        return this.vials.get(barcode);
    }

    async getVial(barcode: string, refresh = false) {
        await this.syncHolders([barcode], refresh);
        return this.vials.get(barcode);
    }
}
