import log from 'loglevel';

export class AsyncQueue {
    static CancelError: unknown = {};

    private readonly maxExecuting: number = 1;
    private queue: [() => Promise<any>, (ret: any) => void, (e: any) => void][] = [];
    private executingCount = 0;

    get isEmpty() {
        return this.queue.length === 0;
    }

    private async next() {
        if (!this.queue.length || this.executingCount >= this.maxExecuting) {
            return;
        }

        const [f, resolve, reject] = this.queue.shift()!;
        this.executingCount++;
        try {
            const ret = await f();
            resolve(ret);
        } catch (e) {
            reject(e);
        } finally {
            this.executingCount--;
            if (this.queue.length > 0) this.next();
        }
    }

    cancel<T>(f: () => Promise<T>): boolean {
        const e = this.queue.find((q) => q[0] === f);
        if (e) {
            const idx = this.queue.indexOf(e);
            if (idx >= 0) {
                if (this.queue[idx]) this.queue[idx][2](AsyncQueue.CancelError);
                this.queue.splice(idx, 1);
                return true;
            }
        }
        return false;
    }

    execute<T>(f: () => Promise<T>) {
        return new Promise<T>((res, rej) => {
            if (this.options?.singleItem) {
                if (this.queue[0]) this.queue[0][2](AsyncQueue.CancelError);
                this.queue[0] = [f, res, rej];
            } else {
                this.queue.push([f, res, rej]);
            }
            this.next();
        });
    }

    // eslint-disable-next-line
    constructor(private options?: { singleItem?: boolean; maxConcurrent?: number }) {
        if (options?.maxConcurrent) {
            this.maxExecuting = options.maxConcurrent;
        }
    }
}

export async function executeConcurrentTasks<T>(options: {
    tasks: (() => Promise<T>)[];
    maxConcurrent: number;
    retryCount?: number;
    onExecuted?: (v: T, index: number) => void;
}) {
    const queue = new AsyncQueue({ maxConcurrent: options.maxConcurrent });

    const maxRetryCount = options.retryCount ?? 1;
    let toRun = options.tasks.map((p, i) => [p, i] as const);
    let retryNumber = 0;
    while (toRun.length > 0 && retryNumber < maxRetryCount) {
        if (retryNumber > 0) {
            log.debug(`Retrying ${retryNumber}`);
        }
        const failed: any[] = [];
        const promises = toRun.map(async (r) => {
            try {
                const t = await queue.execute(r[0]);
                options.onExecuted?.(t, r[1]);
            } catch (err) {
                log.error(r[1], err);
                failed.push(r);
            }
        });
        await Promise.allSettled(promises); // eslint-disable-line
        retryNumber++;
        toRun = failed;
    }

    return {
        failedIndices: toRun.map((r) => r[1]),
    };
}
