import { ReactNode, useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import { decodeEntosMsgpack } from '../../lib/util/serialization';
import { HTE2Root, HTE2UI } from '../HTEWizard';
import { HTEWModel } from '../HTEWizard/model';
import { HTEDetailsUI } from './Details';
import { HTEExperimentUI } from './Experiment';
import { HTEOverview } from './Overview';
import { ExperimentBatchManager } from './batch-manager';
import { HTEEnumerationUI } from './enumeration/Enumeration';
import { EnumerationAPI } from './enumeration/enumeration-api';
import { HTEApi } from './experiment-api';
import { HTEExperiment, formatHTEId, isHTESolvent } from './experiment-data';
import { HTEExperimentModel } from './experiment-model';
import { HTESignUI } from './sign/Sign';
import { createHTEReagents } from './steps/reagents-model';
import { PageTemplate } from '../../components/Layout/Layout';
import { ErrorMessage } from '../../components/common/Error';
import Loading from '../../components/common/Loading';
import { useAsyncAction } from '../../lib/hooks/useAsyncAction';
import useBehaviorSubject from '../../lib/hooks/useBehaviorSubject';
import { HTEGeneralManageUI } from './GeneralManage';
import { HTE2MSRoot, HTE2MSUI } from '../HTEMicroscale';
import { HTE2MSModel } from '../HTEMicroscale/model';

export function HTERouter() {
    // TODO (separate PR): lazy load
    return (
        <Routes>
            <Route path='/enumeration' element={<HTEEnumerationUI />} />
            <Route path='/wizard/draft/:id' element={<HTE2Root draft />} />
            <Route path='/wizard' element={<HTE2Root />} />
            <Route path='/uscale/draft/:id' element={<HTE2MSRoot draft />} />
            <Route path='/uscale' element={<HTE2MSRoot />} />
            <Route path='/:id/transfers' element={<DesignLoader transferMode />} />
            <Route path='/:id/design' element={<DesignLoader />} />
            <Route path='/:id/details' element={<HTEDetailsUI />} />
            <Route path='/:id/sign' element={<HTESignUI />} />
            <Route path='/:id/manage' element={<HTEGeneralManageUI />} />
            <Route path='/' element={<HTEOverview />} />
        </Routes>
    );
}

function DesignLoader({ transferMode }: { transferMode?: boolean }) {
    const { id } = useParams();
    const status = useBehaviorSubject<ReactNode>('');
    const [model, _loadModel] = useAsyncAction<HTEExperimentModel | HTEWModel | HTE2MSModel>();

    useEffect(() => {
        if (!id) return;
        _loadModel(loadModel(id, status, { transferMode }));
    }, [id, transferMode]);

    if (model.isLoading || model.error || !model.result) {
        return (
            <PageTemplate
                title='HTE'
                breadcrumb={
                    typeof id === 'string'
                        ? { title: formatHTEId(id as any), href: '#' }
                        : { title: 'Loading...', href: '#' }
                }
            >
                {model.isLoading && <Loading message={status} />}
                {model.error && <ErrorMessage header='Load Error' message='Failed to load the experiment.' />}
            </PageTemplate>
        );
    }

    if (model.result instanceof HTEWModel) {
        return <HTE2UI _model={model as any} />;
    }
    if (model.result instanceof HTE2MSModel) {
        return <HTE2MSUI _model={model as any} />;
    }

    return <HTEExperimentUI model={model.result as any} id={id} />;
}

async function loadModel(id: string, status: BehaviorSubject<ReactNode>, options?: { transferMode?: boolean }) {
    status.next('Loading data...');
    const [info, chemistry] = await Promise.all([HTEApi.get(+id), EnumerationAPI.reactionsInfo()]);
    const experimentData = decodeEntosMsgpack(info.experiment.design_user_state!.data, { eoi: 'strip' });

    if (info.experiment.design_user_state?.version === 2 && experimentData?.kind === 'microscale_library') {
        status.next('Go Speed Racer, Go!');
        const model = new HTE2MSModel(options);
        await model.initFoundry(info.experiment, experimentData as any);
        return model;
    }

    if (info.experiment.design_user_state?.version === 2) {
        status.next('Yer a wizard, Harry.');
        const model = new HTEWModel();
        await model.init(experimentData as any, { info: info.experiment });
        return model;
    }

    const experimentV1 = experimentData as HTEExperiment;
    status.next('Fetching a shrubbery...');
    const batches = new ExperimentBatchManager();
    await batches.init(experimentV1.design.reactants, experimentV1.design.reactions);

    const reagentReactants = (experimentV1 as HTEExperiment).design.reactants.filter(
        (r) => r.type === 'reagent' && !isHTESolvent(r.identifier)
    );

    return new HTEExperimentModel(+id, {
        experiment: experimentV1,
        design_info: info,
        batches,
        reagents: createHTEReagents(reagentReactants, batches),
        chemistry,
    });
}
