import {
    faArrowRight,
    faArrowRightArrowLeft,
    faFlaskVial,
    faMagnifyingGlass,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Spinner } from 'react-bootstrap';
import Select, { createFilter } from 'react-select';
import Split from 'react-split-it';
import { useBlockLayout, useSortBy, useTable } from 'react-table';
import { CustomSelectClassNames, DefaultSelectStyles } from '../../../components/common/selectStyles';
import Pane from '../../../components/Pane/Pane';
import { defaultColumn } from '../../../components/ReactTable/Controls';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import { DialogService } from '../../../lib/services/dialog';
import { Reactant } from '../experiment-data';

import { ReactionOption } from '../enumeration/enumeration-model';
import { HTEExperimentModel } from '../experiment-model';
import { HTEExperimentTable } from './common';
import { AddOrReplaceReactantDialogControls } from './enumeration-model';
import { FindSimilarCompoundsModal } from '../similar-compounds/FindSimilarCompounds';

export function EnumerationStep({ model }: { model: HTEExperimentModel }) {
    return (
        <div className='hte-experiment-enumeration-step'>
            <div className='hte-experiment-enumeration-content'>
                <Split direction='horizontal' gutterSize={6} sizes={[0.6, 0.4]}>
                    <div className='d-flex h-100 w-100 flex-column'>
                        <div className='flex-0 border-bottom'>
                            <Pane
                                title='Enumeration'
                                paddingIndex={2}
                                topRightContent={<EnumerateButton model={model} />}
                            >
                                <EnumerationUI model={model} />
                            </Pane>
                        </div>
                        <div className='flex-grow-1 position-relative'>
                            <Split direction='horizontal' gutterSize={6} sizes={[0.5, 0.5]}>
                                <Pane
                                    title='MSDs'
                                    paddingIndex={2}
                                    topRightContent={<ManageReactants model={model} kind='msd' />}
                                >
                                    <MSDTable model={model} />
                                </Pane>
                                <Pane
                                    title='BBs'
                                    paddingIndex={2}
                                    topRightContent={<ManageReactants model={model} kind='bb' />}
                                >
                                    <BBTable model={model} />
                                </Pane>
                            </Split>
                        </div>
                    </div>
                    <Pane
                        title='Enumerated products'
                        paddingIndex={2}
                        topRightContent={<ManageReactions model={model} />}
                    >
                        <ReactionsTable model={model} />
                    </Pane>
                </Split>
            </div>
        </div>
    );
}

function ManageReactants({ model, kind }: { model: HTEExperimentModel; kind: 'msd' | 'bb' }) {
    return (
        <div className='hstack gap-2'>
            <SearchReactantsButton model={model} kind={kind} />
            <ReorderReactantsButton model={model} kind={kind} />
            <AddReactantsButton model={model} kind={kind} />
        </div>
    );
}

function SearchReactantsButton({ model, kind }: { model: HTEExperimentModel; kind: 'msd' | 'bb' }) {
    const onClick = () => {
        if (kind === 'bb') {
            model.enumeration.bbSimilarCompounds.state.showSimilarCompounds.next(true);
        } else if (kind === 'msd') {
            model.enumeration.msdSimilarCompounds.state.showSimilarCompounds.next(true);
        }
    };

    return (
        <Button size='sm' variant='outline-primary' onClick={onClick}>
            <FontAwesomeIcon size='sm' className='me-2' icon={faMagnifyingGlass} />
            Search
        </Button>
    );
}

function AddReactantsButton({ model, kind }: { model: HTEExperimentModel; kind: 'msd' | 'bb' }) {
    const isLoading = useBehavior(model.enumeration.state.loadingReactants) === kind;
    const info = useBehavior(model.state.info);
    const isDisabled = info.status !== 'Planning' || isLoading;

    const onClick = () => {
        DialogService.open({
            type: 'generic',
            title: `Add ${kind === 'bb' ? 'BBs' : 'MSDs'}`,
            model: { kind: 'add' },
            defaultState: { input: '' },
            content: AddOrReplaceReactantDialogControls,
            onOk: (state) => model.enumeration.loadReactants(state.input, kind),
        });
    };
    return (
        <Button size='sm' variant='outline-primary' onClick={onClick} disabled={isDisabled}>
            {isLoading && <Spinner animation='border' size='sm' role='status' className='me-2' />}
            {!isLoading && <FontAwesomeIcon size='sm' className='me-2' icon={faPlus} />}
            Add
        </Button>
    );
}

function ReorderReactantsButton({
    model,
    kind,
    disabled,
}: {
    model: HTEExperimentModel;
    kind: 'msd' | 'bb';
    disabled?: boolean;
}) {
    const [reorderState, applyReorder] = useAsyncAction();
    const info = useBehavior(model.state.info);
    const isDisabled = info.status !== 'Planning' || disabled;

    const onClick = () => {
        DialogService.open({
            type: 'generic',
            title: `Reorder ${kind.toUpperCase()}s`,
            model: { kind: 'reorder' },
            defaultState: { input: '' },
            content: AddOrReplaceReactantDialogControls,
            onOk: (state) => applyReorder(model.enumeration.reorderReactants(state.input, kind)),
        });
    };
    return (
        <Button variant='outline-primary' size='sm' onClick={onClick} disabled={isDisabled}>
            {reorderState.isLoading && <Spinner animation='border' size='sm' role='status' className='me-2' />}
            {!reorderState.isLoading && <FontAwesomeIcon size='sm' className='me-2' icon={faArrowRightArrowLeft} />}
            Reorder
        </Button>
    );
}

function MSDTable({ model }: { model: HTEExperimentModel }) {
    const reactants = useBehavior(model.reactants.state.groups).msds;
    const table = useTable<Reactant>(
        {
            columns: model.enumeration.msdColumns,
            data: reactants,
            defaultColumn,
        },
        useSortBy,
        useBlockLayout
    );

    return <HTEExperimentTable table={table} lineHeight={94} noPadding />;
}

function BBTable({ model }: { model: HTEExperimentModel }) {
    const reactants = useBehavior(model.reactants.state.groups).bbs;
    const table = useTable<Reactant>(
        {
            columns: model.enumeration.bbColumns,
            data: reactants,
            defaultColumn,
        },
        useSortBy,
        useBlockLayout
    );

    return <HTEExperimentTable table={table} lineHeight={94} noPadding />;
}

function ReactionsTable({ model }: { model: HTEExperimentModel }) {
    const reactions = useBehavior(model.enumeration.state.reactions);
    const table = useTable(
        {
            columns: model.enumeration.reactionsColumns,
            data: reactions,
            defaultColumn,
        },
        useSortBy,
        useBlockLayout
    );

    return <HTEExperimentTable table={table} lineHeight={94} noPadding />;
}

function formatReactionOption({ label }: ReactionOption) {
    return <div>{label}</div>;
}

function EnumerationUI({ model }: { model: HTEExperimentModel }) {
    const settings = useBehavior(model.state.enumeration);
    const [updateState, applyUpdate] = useAsyncAction();

    const reactionValue = model.enumeration.reactionOptions.find((r) => r.value?.id === settings?.reaction_id);

    return (
        <div className='hte-enumeration-settings-form'>
            <div className='position-relative w-100 overflow-hidden'>
                <Select
                    options={model.enumeration.reactionOptions}
                    value={reactionValue}
                    isSearchable
                    formatOptionLabel={formatReactionOption}
                    filterOption={createFilter({ ignoreAccents: false, stringify: (o) => o.label })}
                    menuPortalTarget={document.body}
                    menuPlacement='top'
                    placeholder='Select reaction chemistry...'
                    classNames={CustomSelectClassNames}
                    styles={DefaultSelectStyles}
                    onChange={(o) => {
                        model.state.enumeration.next({ reaction_id: o?.value?.id });
                        if (o?.value?.id) applyUpdate(model.enumeration.syncReactionSites());
                    }}
                    isDisabled={updateState.isLoading}
                />
                {updateState.isLoading && (
                    <Spinner
                        animation='border'
                        size='sm'
                        className='ms-2'
                        role='status'
                        style={{ position: 'absolute', top: 11, right: 32 }}
                    />
                )}
            </div>
            <FindSimilarCompoundsModal model={model.enumeration.msdSimilarCompounds} />
            <FindSimilarCompoundsModal model={model.enumeration.bbSimilarCompounds} />
        </div>
    );
}

function EnumerateButton({ model }: { model: HTEExperimentModel }) {
    const info = useBehavior(model.state.enumeration);
    const [state, apply] = useAsyncAction();
    const experimentInfo = useBehavior(model.state.info);
    const isDisabled = !info?.reaction_id || state.isLoading || experimentInfo.status !== 'Planning';

    return (
        <Button onClick={() => apply(model.enumeration.enumerate())} disabled={isDisabled} size='sm' variant='primary'>
            Enumerate
            {state.isLoading && <Spinner animation='border' size='sm' className='ms-2' role='status' />}
            {!state.isLoading && <FontAwesomeIcon size='sm' className='ms-2' icon={faArrowRight} />}
        </Button>
    );
}

function ManageReactions({ model }: { model: HTEExperimentModel }) {
    const reactions = useBehavior(model.enumeration.state.reactions);
    const [addState, applyAdd] = useAsyncAction();
    const info = useBehavior(model.state.info);
    const isDisabled = reactions.length === 0 || info.status !== 'Planning';

    const onClick = () => {
        const numNone = reactions.filter((r) => r.products.length === 0).length;
        const numNotSelected = reactions.filter((r) => !r.current_product).length;

        DialogService.open({
            type: 'confirm',
            title: 'Use as Reactions',
            text: (
                <>
                    <p>Use the current enumeration as the Reactions?</p>
                    {numNone > 0 && (
                        <p className='text-danger fw-bold'>
                            {numNone} reaction{numNone !== 1 ? 's' : ''} do not have a product available. These will be
                            skipped.
                        </p>
                    )}
                    {numNotSelected > 0 && (
                        <p className='text-danger fw-bold'>
                            {numNotSelected} reaction{numNotSelected !== 1 ? 's' : ''} do not have a product selected.
                            These will be skipped.
                        </p>
                    )}
                    <p className='text-warning'>Existing reactions will be replaced!</p>
                </>
            ),
            confirmText: 'Use Reactions',
            onConfirm: () => applyAdd(model.enumeration.useAsReactions()),
        });
    };
    return (
        <div className='hstack'>
            <Button
                size='sm'
                variant='outline-primary'
                className='me-2'
                onClick={() => model.enumeration.exportEnumeratedCSV()}
                disabled={reactions.length === 0}
            >
                Export CSV
            </Button>
            <Button size='sm' variant='primary' onClick={onClick} disabled={isDisabled}>
                {addState.isLoading && <Spinner animation='border' size='sm' role='status' className='me-2' />}
                {!addState.isLoading && <FontAwesomeIcon size='sm' className='me-2' icon={faFlaskVial} />}
                Use as Reactions
            </Button>
        </div>
    );
}
