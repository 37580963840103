import { CSSObjectWithLabel } from 'react-select';

export const CustomSelectClassNames = {
    control: (state: any) => `entos-themed-select-control${state.isFocused ? ' focus' : ''}`,
    menu: (state: any) => 'entos-themed-select-menu',
    placeholder: (state: any) => `entos-themed-select-placeholder${state.isFocused ? ' opacity-0' : ''}`,
    option: (state: any) =>
        `entos-themed-select-option${state.isDisabled ? ' text-muted' : ' text-body'}${
            state.isFocused ? ' focus' : ' bg-transparent'
        }`,
    singleValue: (state: any) => 'text-body',
    multiValue: (state: any) => 'bg-primary',
    multiValueRemove: (state: any) => 'text-body',
    input: (state: any) => 'text-body',
    indicatorSeparator: (state: any) => 'bg-transparent',
};

export const CustomSmallSelectClassNames = {
    ...CustomSelectClassNames,
    control: (state: any) =>
        `entos-themed-select-control${state.isFocused ? ' focus' : ''} entos-themed-select-control-sm`,
    valueContainer: (state: any) => 'h-100 px-2 py-0',
    indicatorsContainer: (state: any) => 'entos-themed-select-sm-indicators-container',
    menu: (state: any) => 'entos-themed-select-menu width-big',
    option: (state: any) =>
        `entos-themed-select-option${state.isDisabled ? ' text-muted' : ' text-body'} entos-select-option-sm p-1 ${
            state.isFocused ? ' focus' : ' bg-transparent'
        }`,
};

export const CustomInvisibleSelectClassNames = {
    ...CustomSelectClassNames,
    control: (state: any) =>
        `entos-themed-select-control${state.isFocused ? ' focus' : ''} entos-themed-select-control-invisible`,
    valueContainer: (state: any) => 'p-1',
    indicatorsContainer: (state: any) => 'entos-themed-select-sm-indicators-container',
    menu: (state: any) => 'entos-themed-select-menu width-big',
    option: (state: any) =>
        `entos-themed-select-option${state.isDisabled ? ' text-muted' : ' text-body'} entos-select-option-sm p-1 ${
            state.isFocused ? ' focus' : ' bg-transparent'
        }`,
};

export const DefaultSelectStyles = {
    menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 9999 }),
};

export const CustomInvisibleSelectStyles = {
    ...DefaultSelectStyles,
    option: (base: CSSObjectWithLabel) => ({
        ...base,
        '&:hover': { fontSize: '14px !important', padding: '2px !important' },
    }),
};

const smallHeight = '31px';
export const SmallSelectStyles = {
    ...DefaultSelectStyles,
    control: (provided: any, state: any) => ({
        ...provided,
        minHeight: smallHeight,
        height: smallHeight,
        boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided: any, state: any) => ({
        ...provided,
        height: smallHeight,
        padding: '0 6px',
    }),

    input: (provided: any, state: any) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: (state: any) => ({
        display: 'none',
    }),
    indicatorsContainer: (provided: any, state: any) => ({
        ...provided,
        height: smallHeight,
    }),
};
