export const ANA_1532c_1pt = `ID,Point,Row,Column,IsControl
1,1,1,1,False
33,1,1,2,False
65,1,1,3,False
97,1,1,4,False
129,1,1,5,False
161,1,1,6,False
193,1,1,7,False
225,1,1,8,False
257,1,1,9,False
289,1,1,10,False
321,1,1,11,False
353,1,1,12,False
385,1,1,13,False
417,1,1,14,False
449,1,1,15,False
481,1,1,16,False
513,1,1,17,False
545,1,1,18,False
577,1,1,19,False
609,1,1,20,False
641,1,1,21,False
673,1,1,22,False
705,1,1,23,False
737,1,1,24,False
769,1,1,25,False
801,1,1,26,False
833,1,1,27,False
865,1,1,28,False
897,1,1,29,False
929,1,1,30,False
961,1,1,31,False
993,1,1,32,False
1025,1,1,33,False
1057,1,1,34,False
1089,1,1,35,False
1121,1,1,36,False
1153,1,1,37,False
1185,1,1,38,False
1217,1,1,39,False
1249,1,1,40,False
1281,1,1,41,False
1313,1,1,42,False
1345,1,1,43,False
1377,1,1,44,False
1409,1,1,45,False
1441,1,1,46,False
1473,1,1,47,False
1505,1,1,48,False
2,1,2,1,False
34,1,2,2,False
66,1,2,3,False
98,1,2,4,False
130,1,2,5,False
162,1,2,6,False
194,1,2,7,False
226,1,2,8,False
258,1,2,9,False
290,1,2,10,False
322,1,2,11,False
354,1,2,12,False
386,1,2,13,False
418,1,2,14,False
450,1,2,15,False
482,1,2,16,False
514,1,2,17,False
546,1,2,18,False
578,1,2,19,False
610,1,2,20,False
642,1,2,21,False
674,1,2,22,False
706,1,2,23,False
738,1,2,24,False
770,1,2,25,False
802,1,2,26,False
834,1,2,27,False
866,1,2,28,False
898,1,2,29,False
930,1,2,30,False
962,1,2,31,False
994,1,2,32,False
1026,1,2,33,False
1058,1,2,34,False
1090,1,2,35,False
1122,1,2,36,False
1154,1,2,37,False
1186,1,2,38,False
1218,1,2,39,False
1250,1,2,40,False
1282,1,2,41,False
1314,1,2,42,False
1346,1,2,43,False
1378,1,2,44,False
1410,1,2,45,False
1442,1,2,46,False
1474,1,2,47,False
1506,1,2,48,False
3,1,3,1,False
35,1,3,2,False
67,1,3,3,False
99,1,3,4,False
131,1,3,5,False
163,1,3,6,False
195,1,3,7,False
227,1,3,8,False
259,1,3,9,False
291,1,3,10,False
323,1,3,11,False
355,1,3,12,False
387,1,3,13,False
419,1,3,14,False
451,1,3,15,False
483,1,3,16,False
515,1,3,17,False
547,1,3,18,False
579,1,3,19,False
611,1,3,20,False
643,1,3,21,False
675,1,3,22,False
707,1,3,23,False
739,1,3,24,False
771,1,3,25,False
803,1,3,26,False
835,1,3,27,False
867,1,3,28,False
899,1,3,29,False
931,1,3,30,False
963,1,3,31,False
995,1,3,32,False
1027,1,3,33,False
1059,1,3,34,False
1091,1,3,35,False
1123,1,3,36,False
1155,1,3,37,False
1187,1,3,38,False
1219,1,3,39,False
1251,1,3,40,False
1283,1,3,41,False
1315,1,3,42,False
1347,1,3,43,False
1379,1,3,44,False
1411,1,3,45,False
1443,1,3,46,False
1475,1,3,47,False
1507,1,3,48,False
4,1,4,1,False
36,1,4,2,False
68,1,4,3,False
100,1,4,4,False
132,1,4,5,False
164,1,4,6,False
196,1,4,7,False
228,1,4,8,False
260,1,4,9,False
292,1,4,10,False
324,1,4,11,False
356,1,4,12,False
388,1,4,13,False
420,1,4,14,False
452,1,4,15,False
484,1,4,16,False
516,1,4,17,False
548,1,4,18,False
580,1,4,19,False
612,1,4,20,False
644,1,4,21,False
676,1,4,22,False
708,1,4,23,False
740,1,4,24,False
772,1,4,25,False
804,1,4,26,False
836,1,4,27,False
868,1,4,28,False
900,1,4,29,False
932,1,4,30,False
964,1,4,31,False
996,1,4,32,False
1028,1,4,33,False
1060,1,4,34,False
1092,1,4,35,False
1124,1,4,36,False
1156,1,4,37,False
1188,1,4,38,False
1220,1,4,39,False
1252,1,4,40,False
1284,1,4,41,False
1316,1,4,42,False
1348,1,4,43,False
1380,1,4,44,False
1412,1,4,45,False
1444,1,4,46,False
1476,1,4,47,False
1508,1,4,48,False
5,1,5,1,False
37,1,5,2,False
69,1,5,3,False
101,1,5,4,False
133,1,5,5,False
165,1,5,6,False
197,1,5,7,False
229,1,5,8,False
261,1,5,9,False
293,1,5,10,False
325,1,5,11,False
357,1,5,12,False
389,1,5,13,False
421,1,5,14,False
453,1,5,15,False
485,1,5,16,False
517,1,5,17,False
549,1,5,18,False
581,1,5,19,False
613,1,5,20,False
645,1,5,21,False
677,1,5,22,False
709,1,5,23,False
741,1,5,24,False
773,1,5,25,False
805,1,5,26,False
837,1,5,27,False
869,1,5,28,False
901,1,5,29,False
933,1,5,30,False
965,1,5,31,False
997,1,5,32,False
1029,1,5,33,False
1061,1,5,34,False
1093,1,5,35,False
1125,1,5,36,False
1157,1,5,37,False
1189,1,5,38,False
1221,1,5,39,False
1253,1,5,40,False
1285,1,5,41,False
1317,1,5,42,False
1349,1,5,43,False
1381,1,5,44,False
1413,1,5,45,False
1445,1,5,46,False
1477,1,5,47,False
1509,1,5,48,False
6,1,6,1,False
38,1,6,2,False
70,1,6,3,False
102,1,6,4,False
134,1,6,5,False
166,1,6,6,False
198,1,6,7,False
230,1,6,8,False
262,1,6,9,False
294,1,6,10,False
326,1,6,11,False
358,1,6,12,False
390,1,6,13,False
422,1,6,14,False
454,1,6,15,False
486,1,6,16,False
518,1,6,17,False
550,1,6,18,False
582,1,6,19,False
614,1,6,20,False
646,1,6,21,False
678,1,6,22,False
710,1,6,23,False
742,1,6,24,False
774,1,6,25,False
806,1,6,26,False
838,1,6,27,False
870,1,6,28,False
902,1,6,29,False
934,1,6,30,False
966,1,6,31,False
998,1,6,32,False
1030,1,6,33,False
1062,1,6,34,False
1094,1,6,35,False
1126,1,6,36,False
1158,1,6,37,False
1190,1,6,38,False
1222,1,6,39,False
1254,1,6,40,False
1286,1,6,41,False
1318,1,6,42,False
1350,1,6,43,False
1382,1,6,44,False
1414,1,6,45,False
1446,1,6,46,False
1478,1,6,47,False
1510,1,6,48,False
7,1,7,1,False
39,1,7,2,False
71,1,7,3,False
103,1,7,4,False
135,1,7,5,False
167,1,7,6,False
199,1,7,7,False
231,1,7,8,False
263,1,7,9,False
295,1,7,10,False
327,1,7,11,False
359,1,7,12,False
391,1,7,13,False
423,1,7,14,False
455,1,7,15,False
487,1,7,16,False
519,1,7,17,False
551,1,7,18,False
583,1,7,19,False
615,1,7,20,False
647,1,7,21,False
679,1,7,22,False
711,1,7,23,False
743,1,7,24,False
775,1,7,25,False
807,1,7,26,False
839,1,7,27,False
871,1,7,28,False
903,1,7,29,False
935,1,7,30,False
967,1,7,31,False
999,1,7,32,False
1031,1,7,33,False
1063,1,7,34,False
1095,1,7,35,False
1127,1,7,36,False
1159,1,7,37,False
1191,1,7,38,False
1223,1,7,39,False
1255,1,7,40,False
1287,1,7,41,False
1319,1,7,42,False
1351,1,7,43,False
1383,1,7,44,False
1415,1,7,45,False
1447,1,7,46,False
1479,1,7,47,False
1511,1,7,48,False
8,1,8,1,False
40,1,8,2,False
72,1,8,3,False
104,1,8,4,False
136,1,8,5,False
168,1,8,6,False
200,1,8,7,False
232,1,8,8,False
264,1,8,9,False
296,1,8,10,False
328,1,8,11,False
360,1,8,12,False
392,1,8,13,False
424,1,8,14,False
456,1,8,15,False
488,1,8,16,False
520,1,8,17,False
552,1,8,18,False
584,1,8,19,False
616,1,8,20,False
648,1,8,21,False
680,1,8,22,False
712,1,8,23,False
744,1,8,24,False
776,1,8,25,False
808,1,8,26,False
840,1,8,27,False
872,1,8,28,False
904,1,8,29,False
936,1,8,30,False
968,1,8,31,False
1000,1,8,32,False
1032,1,8,33,False
1064,1,8,34,False
1096,1,8,35,False
1128,1,8,36,False
1160,1,8,37,False
1192,1,8,38,False
1224,1,8,39,False
1256,1,8,40,False
1288,1,8,41,False
1320,1,8,42,False
1352,1,8,43,False
1384,1,8,44,False
1416,1,8,45,False
1448,1,8,46,False
1480,1,8,47,False
1512,1,8,48,False
9,1,9,1,False
41,1,9,2,False
73,1,9,3,False
105,1,9,4,False
137,1,9,5,False
169,1,9,6,False
201,1,9,7,False
233,1,9,8,False
265,1,9,9,False
297,1,9,10,False
329,1,9,11,False
361,1,9,12,False
393,1,9,13,False
425,1,9,14,False
457,1,9,15,False
489,1,9,16,False
521,1,9,17,False
553,1,9,18,False
585,1,9,19,False
617,1,9,20,False
649,1,9,21,False
681,1,9,22,False
713,1,9,23,False
745,1,9,24,False
777,1,9,25,False
809,1,9,26,False
841,1,9,27,False
873,1,9,28,False
905,1,9,29,False
937,1,9,30,False
969,1,9,31,False
1001,1,9,32,False
1033,1,9,33,False
1065,1,9,34,False
1097,1,9,35,False
1129,1,9,36,False
1161,1,9,37,False
1193,1,9,38,False
1225,1,9,39,False
1257,1,9,40,False
1289,1,9,41,False
1321,1,9,42,False
1353,1,9,43,False
1385,1,9,44,False
1417,1,9,45,False
1449,1,9,46,False
1481,1,9,47,False
1513,1,9,48,False
10,1,10,1,False
42,1,10,2,False
74,1,10,3,False
106,1,10,4,False
138,1,10,5,False
170,1,10,6,False
202,1,10,7,False
234,1,10,8,False
266,1,10,9,False
298,1,10,10,False
330,1,10,11,False
362,1,10,12,False
394,1,10,13,False
426,1,10,14,False
458,1,10,15,False
490,1,10,16,False
522,1,10,17,False
554,1,10,18,False
586,1,10,19,False
618,1,10,20,False
650,1,10,21,False
682,1,10,22,False
714,1,10,23,False
746,1,10,24,False
778,1,10,25,False
810,1,10,26,False
842,1,10,27,False
874,1,10,28,False
906,1,10,29,False
938,1,10,30,False
970,1,10,31,False
1002,1,10,32,False
1034,1,10,33,False
1066,1,10,34,False
1098,1,10,35,False
1130,1,10,36,False
1162,1,10,37,False
1194,1,10,38,False
1226,1,10,39,False
1258,1,10,40,False
1290,1,10,41,False
1322,1,10,42,False
1354,1,10,43,False
1386,1,10,44,False
1418,1,10,45,False
1450,1,10,46,False
1482,1,10,47,False
1514,1,10,48,False
11,1,11,1,False
43,1,11,2,False
75,1,11,3,False
107,1,11,4,False
139,1,11,5,False
171,1,11,6,False
203,1,11,7,False
235,1,11,8,False
267,1,11,9,False
299,1,11,10,False
331,1,11,11,False
363,1,11,12,False
395,1,11,13,False
427,1,11,14,False
459,1,11,15,False
491,1,11,16,False
523,1,11,17,False
555,1,11,18,False
587,1,11,19,False
619,1,11,20,False
651,1,11,21,False
683,1,11,22,False
715,1,11,23,False
747,1,11,24,False
779,1,11,25,False
811,1,11,26,False
843,1,11,27,False
875,1,11,28,False
907,1,11,29,False
939,1,11,30,False
971,1,11,31,False
1003,1,11,32,False
1035,1,11,33,False
1067,1,11,34,False
1099,1,11,35,False
1131,1,11,36,False
1163,1,11,37,False
1195,1,11,38,False
1227,1,11,39,False
1259,1,11,40,False
1291,1,11,41,False
1323,1,11,42,False
1355,1,11,43,False
1387,1,11,44,False
1419,1,11,45,False
1451,1,11,46,False
1483,1,11,47,False
1515,1,11,48,False
12,1,12,1,False
44,1,12,2,False
76,1,12,3,False
108,1,12,4,False
140,1,12,5,False
172,1,12,6,False
204,1,12,7,False
236,1,12,8,False
268,1,12,9,False
300,1,12,10,False
332,1,12,11,False
364,1,12,12,False
396,1,12,13,False
428,1,12,14,False
460,1,12,15,False
492,1,12,16,False
524,1,12,17,False
556,1,12,18,False
588,1,12,19,False
620,1,12,20,False
652,1,12,21,False
684,1,12,22,False
716,1,12,23,False
748,1,12,24,False
780,1,12,25,False
812,1,12,26,False
844,1,12,27,False
876,1,12,28,False
908,1,12,29,False
940,1,12,30,False
972,1,12,31,False
1004,1,12,32,False
1036,1,12,33,False
1068,1,12,34,False
1100,1,12,35,False
1132,1,12,36,False
1164,1,12,37,False
1196,1,12,38,False
1228,1,12,39,False
1260,1,12,40,False
1292,1,12,41,False
1324,1,12,42,False
1356,1,12,43,False
1388,1,12,44,False
1420,1,12,45,False
1452,1,12,46,False
1484,1,12,47,False
1516,1,12,48,False
13,1,13,1,False
45,1,13,2,False
77,1,13,3,False
109,1,13,4,False
141,1,13,5,False
173,1,13,6,False
205,1,13,7,False
237,1,13,8,False
269,1,13,9,False
301,1,13,10,False
333,1,13,11,False
365,1,13,12,False
397,1,13,13,False
429,1,13,14,False
461,1,13,15,False
493,1,13,16,False
525,1,13,17,False
557,1,13,18,False
589,1,13,19,False
621,1,13,20,False
653,1,13,21,False
685,1,13,22,False
717,1,13,23,False
749,1,13,24,False
781,1,13,25,False
813,1,13,26,False
845,1,13,27,False
877,1,13,28,False
909,1,13,29,False
941,1,13,30,False
973,1,13,31,False
1005,1,13,32,False
1037,1,13,33,False
1069,1,13,34,False
1101,1,13,35,False
1133,1,13,36,False
1165,1,13,37,False
1197,1,13,38,False
1229,1,13,39,False
1261,1,13,40,False
1293,1,13,41,False
1325,1,13,42,False
1357,1,13,43,False
1389,1,13,44,False
1421,1,13,45,False
1453,1,13,46,False
1485,1,13,47,False
1517,1,13,48,False
14,1,14,1,False
46,1,14,2,False
78,1,14,3,False
110,1,14,4,False
142,1,14,5,False
174,1,14,6,False
206,1,14,7,False
238,1,14,8,False
270,1,14,9,False
302,1,14,10,False
334,1,14,11,False
366,1,14,12,False
398,1,14,13,False
430,1,14,14,False
462,1,14,15,False
494,1,14,16,False
526,1,14,17,False
558,1,14,18,False
590,1,14,19,False
622,1,14,20,False
654,1,14,21,False
686,1,14,22,False
718,1,14,23,False
750,1,14,24,False
782,1,14,25,False
814,1,14,26,False
846,1,14,27,False
878,1,14,28,False
910,1,14,29,False
942,1,14,30,False
974,1,14,31,False
1006,1,14,32,False
1038,1,14,33,False
1070,1,14,34,False
1102,1,14,35,False
1134,1,14,36,False
1166,1,14,37,False
1198,1,14,38,False
1230,1,14,39,False
1262,1,14,40,False
1294,1,14,41,False
1326,1,14,42,False
1358,1,14,43,False
1390,1,14,44,False
1422,1,14,45,False
1454,1,14,46,False
1486,1,14,47,False
1518,1,14,48,False
15,1,15,1,False
47,1,15,2,False
79,1,15,3,False
111,1,15,4,False
143,1,15,5,False
175,1,15,6,False
207,1,15,7,False
239,1,15,8,False
271,1,15,9,False
303,1,15,10,False
335,1,15,11,False
367,1,15,12,False
399,1,15,13,False
431,1,15,14,False
463,1,15,15,False
495,1,15,16,False
527,1,15,17,False
559,1,15,18,False
591,1,15,19,False
623,1,15,20,False
655,1,15,21,False
687,1,15,22,False
719,1,15,23,False
751,1,15,24,False
783,1,15,25,False
815,1,15,26,False
847,1,15,27,False
879,1,15,28,False
911,1,15,29,False
943,1,15,30,False
975,1,15,31,False
1007,1,15,32,False
1039,1,15,33,False
1071,1,15,34,False
1103,1,15,35,False
1135,1,15,36,False
1167,1,15,37,False
1199,1,15,38,False
1231,1,15,39,False
1263,1,15,40,False
1295,1,15,41,False
1327,1,15,42,False
1359,1,15,43,False
1391,1,15,44,False
1423,1,15,45,False
1455,1,15,46,False
1487,1,15,47,False
1519,1,15,48,False
16,1,16,1,False
48,1,16,2,False
80,1,16,3,False
112,1,16,4,False
144,1,16,5,False
176,1,16,6,False
208,1,16,7,False
240,1,16,8,False
272,1,16,9,False
304,1,16,10,False
336,1,16,11,False
368,1,16,12,False
400,1,16,13,False
432,1,16,14,False
464,1,16,15,False
496,1,16,16,False
528,1,16,17,False
560,1,16,18,False
592,1,16,19,False
624,1,16,20,False
656,1,16,21,False
688,1,16,22,False
720,1,16,23,False
752,1,16,24,False
784,1,16,25,False
816,1,16,26,False
848,1,16,27,False
880,1,16,28,False
912,1,16,29,False
944,1,16,30,False
976,1,16,31,False
1008,1,16,32,False
1040,1,16,33,False
1072,1,16,34,False
1104,1,16,35,False
1136,1,16,36,False
1168,1,16,37,False
1200,1,16,38,False
1232,1,16,39,False
1264,1,16,40,False
1296,1,16,41,False
1328,1,16,42,False
1360,1,16,43,False
1392,1,16,44,False
1424,1,16,45,False
1456,1,16,46,False
1488,1,16,47,False
1520,1,16,48,False
17,1,17,1,False
49,1,17,2,False
81,1,17,3,False
113,1,17,4,False
145,1,17,5,False
177,1,17,6,False
209,1,17,7,False
241,1,17,8,False
273,1,17,9,False
305,1,17,10,False
337,1,17,11,False
369,1,17,12,False
401,1,17,13,False
433,1,17,14,False
465,1,17,15,False
497,1,17,16,False
529,1,17,17,False
561,1,17,18,False
593,1,17,19,False
625,1,17,20,False
657,1,17,21,False
689,1,17,22,False
721,1,17,23,False
753,1,17,24,False
785,1,17,25,False
817,1,17,26,False
849,1,17,27,False
881,1,17,28,False
913,1,17,29,False
945,1,17,30,False
977,1,17,31,False
1009,1,17,32,False
1041,1,17,33,False
1073,1,17,34,False
1105,1,17,35,False
1137,1,17,36,False
1169,1,17,37,False
1201,1,17,38,False
1233,1,17,39,False
1265,1,17,40,False
1297,1,17,41,False
1329,1,17,42,False
1361,1,17,43,False
1393,1,17,44,False
1425,1,17,45,False
1457,1,17,46,False
1489,1,17,47,False
1521,1,17,48,False
18,1,18,1,False
50,1,18,2,False
82,1,18,3,False
114,1,18,4,False
146,1,18,5,False
178,1,18,6,False
210,1,18,7,False
242,1,18,8,False
274,1,18,9,False
306,1,18,10,False
338,1,18,11,False
370,1,18,12,False
402,1,18,13,False
434,1,18,14,False
466,1,18,15,False
498,1,18,16,False
530,1,18,17,False
562,1,18,18,False
594,1,18,19,False
626,1,18,20,False
658,1,18,21,False
690,1,18,22,False
722,1,18,23,False
754,1,18,24,False
786,1,18,25,False
818,1,18,26,False
850,1,18,27,False
882,1,18,28,False
914,1,18,29,False
946,1,18,30,False
978,1,18,31,False
1010,1,18,32,False
1042,1,18,33,False
1074,1,18,34,False
1106,1,18,35,False
1138,1,18,36,False
1170,1,18,37,False
1202,1,18,38,False
1234,1,18,39,False
1266,1,18,40,False
1298,1,18,41,False
1330,1,18,42,False
1362,1,18,43,False
1394,1,18,44,False
1426,1,18,45,False
1458,1,18,46,False
1490,1,18,47,False
1522,1,18,48,False
19,1,19,1,False
51,1,19,2,False
83,1,19,3,False
115,1,19,4,False
147,1,19,5,False
179,1,19,6,False
211,1,19,7,False
243,1,19,8,False
275,1,19,9,False
307,1,19,10,False
339,1,19,11,False
371,1,19,12,False
403,1,19,13,False
435,1,19,14,False
467,1,19,15,False
499,1,19,16,False
531,1,19,17,False
563,1,19,18,False
595,1,19,19,False
627,1,19,20,False
659,1,19,21,False
691,1,19,22,False
723,1,19,23,False
755,1,19,24,False
787,1,19,25,False
819,1,19,26,False
851,1,19,27,False
883,1,19,28,False
915,1,19,29,False
947,1,19,30,False
979,1,19,31,False
1011,1,19,32,False
1043,1,19,33,False
1075,1,19,34,False
1107,1,19,35,False
1139,1,19,36,False
1171,1,19,37,False
1203,1,19,38,False
1235,1,19,39,False
1267,1,19,40,False
1299,1,19,41,False
1331,1,19,42,False
1363,1,19,43,False
1395,1,19,44,False
1427,1,19,45,False
1459,1,19,46,False
1491,1,19,47,False
1523,1,19,48,False
20,1,20,1,False
52,1,20,2,False
84,1,20,3,False
116,1,20,4,False
148,1,20,5,False
180,1,20,6,False
212,1,20,7,False
244,1,20,8,False
276,1,20,9,False
308,1,20,10,False
340,1,20,11,False
372,1,20,12,False
404,1,20,13,False
436,1,20,14,False
468,1,20,15,False
500,1,20,16,False
532,1,20,17,False
564,1,20,18,False
596,1,20,19,False
628,1,20,20,False
660,1,20,21,False
692,1,20,22,False
724,1,20,23,False
756,1,20,24,False
788,1,20,25,False
820,1,20,26,False
852,1,20,27,False
884,1,20,28,False
916,1,20,29,False
948,1,20,30,False
980,1,20,31,False
1012,1,20,32,False
1044,1,20,33,False
1076,1,20,34,False
1108,1,20,35,False
1140,1,20,36,False
1172,1,20,37,False
1204,1,20,38,False
1236,1,20,39,False
1268,1,20,40,False
1300,1,20,41,False
1332,1,20,42,False
1364,1,20,43,False
1396,1,20,44,False
1428,1,20,45,False
1460,1,20,46,False
1492,1,20,47,False
1524,1,20,48,False
21,1,21,1,False
53,1,21,2,False
85,1,21,3,False
117,1,21,4,False
149,1,21,5,False
181,1,21,6,False
213,1,21,7,False
245,1,21,8,False
277,1,21,9,False
309,1,21,10,False
341,1,21,11,False
373,1,21,12,False
405,1,21,13,False
437,1,21,14,False
469,1,21,15,False
501,1,21,16,False
533,1,21,17,False
565,1,21,18,False
597,1,21,19,False
629,1,21,20,False
661,1,21,21,False
693,1,21,22,False
725,1,21,23,False
757,1,21,24,False
789,1,21,25,False
821,1,21,26,False
853,1,21,27,False
885,1,21,28,False
917,1,21,29,False
949,1,21,30,False
981,1,21,31,False
1013,1,21,32,False
1045,1,21,33,False
1077,1,21,34,False
1109,1,21,35,False
1141,1,21,36,False
1173,1,21,37,False
1205,1,21,38,False
1237,1,21,39,False
1269,1,21,40,False
1301,1,21,41,False
1333,1,21,42,False
1365,1,21,43,False
1397,1,21,44,False
1429,1,21,45,False
1461,1,21,46,False
1493,1,21,47,False
1525,1,21,48,False
22,1,22,1,False
54,1,22,2,False
86,1,22,3,False
118,1,22,4,False
150,1,22,5,False
182,1,22,6,False
214,1,22,7,False
246,1,22,8,False
278,1,22,9,False
310,1,22,10,False
342,1,22,11,False
374,1,22,12,False
406,1,22,13,False
438,1,22,14,False
470,1,22,15,False
502,1,22,16,False
534,1,22,17,False
566,1,22,18,False
598,1,22,19,False
630,1,22,20,False
662,1,22,21,False
694,1,22,22,False
726,1,22,23,False
758,1,22,24,False
790,1,22,25,False
822,1,22,26,False
854,1,22,27,False
886,1,22,28,False
918,1,22,29,False
950,1,22,30,False
982,1,22,31,False
1014,1,22,32,False
1046,1,22,33,False
1078,1,22,34,False
1110,1,22,35,False
1142,1,22,36,False
1174,1,22,37,False
1206,1,22,38,False
1238,1,22,39,False
1270,1,22,40,False
1302,1,22,41,False
1334,1,22,42,False
1366,1,22,43,False
1398,1,22,44,False
1430,1,22,45,False
1462,1,22,46,False
1494,1,22,47,False
1526,1,22,48,False
23,1,23,1,False
55,1,23,2,False
87,1,23,3,False
119,1,23,4,False
151,1,23,5,False
183,1,23,6,False
215,1,23,7,False
247,1,23,8,False
279,1,23,9,False
311,1,23,10,False
343,1,23,11,False
375,1,23,12,False
407,1,23,13,False
439,1,23,14,False
471,1,23,15,False
503,1,23,16,False
535,1,23,17,False
567,1,23,18,False
599,1,23,19,False
631,1,23,20,False
663,1,23,21,False
695,1,23,22,False
727,1,23,23,False
759,1,23,24,False
791,1,23,25,False
823,1,23,26,False
855,1,23,27,False
887,1,23,28,False
919,1,23,29,False
951,1,23,30,False
983,1,23,31,False
1015,1,23,32,False
1047,1,23,33,False
1079,1,23,34,False
1111,1,23,35,False
1143,1,23,36,False
1175,1,23,37,False
1207,1,23,38,False
1239,1,23,39,False
1271,1,23,40,False
1303,1,23,41,False
1335,1,23,42,False
1367,1,23,43,False
1399,1,23,44,False
1431,1,23,45,False
1463,1,23,46,False
1495,1,23,47,False
1527,1,23,48,False
24,1,24,1,False
56,1,24,2,False
88,1,24,3,False
120,1,24,4,False
152,1,24,5,False
184,1,24,6,False
216,1,24,7,False
248,1,24,8,False
280,1,24,9,False
312,1,24,10,False
344,1,24,11,False
376,1,24,12,False
408,1,24,13,False
440,1,24,14,False
472,1,24,15,False
504,1,24,16,False
536,1,24,17,False
568,1,24,18,False
600,1,24,19,False
632,1,24,20,False
664,1,24,21,False
696,1,24,22,False
728,1,24,23,False
760,1,24,24,False
792,1,24,25,False
824,1,24,26,False
856,1,24,27,False
888,1,24,28,False
920,1,24,29,False
952,1,24,30,False
984,1,24,31,False
1016,1,24,32,False
1048,1,24,33,False
1080,1,24,34,False
1112,1,24,35,False
1144,1,24,36,False
1176,1,24,37,False
1208,1,24,38,False
1240,1,24,39,False
1272,1,24,40,False
1304,1,24,41,False
1336,1,24,42,False
1368,1,24,43,False
1400,1,24,44,False
1432,1,24,45,False
1464,1,24,46,False
1496,1,24,47,False
1528,1,24,48,False
25,1,25,1,False
57,1,25,2,False
89,1,25,3,False
121,1,25,4,False
153,1,25,5,False
185,1,25,6,False
217,1,25,7,False
249,1,25,8,False
281,1,25,9,False
313,1,25,10,False
345,1,25,11,False
377,1,25,12,False
409,1,25,13,False
441,1,25,14,False
473,1,25,15,False
505,1,25,16,False
537,1,25,17,False
569,1,25,18,False
601,1,25,19,False
633,1,25,20,False
665,1,25,21,False
697,1,25,22,False
729,1,25,23,False
761,1,25,24,False
793,1,25,25,False
825,1,25,26,False
857,1,25,27,False
889,1,25,28,False
921,1,25,29,False
953,1,25,30,False
985,1,25,31,False
1017,1,25,32,False
1049,1,25,33,False
1081,1,25,34,False
1113,1,25,35,False
1145,1,25,36,False
1177,1,25,37,False
1209,1,25,38,False
1241,1,25,39,False
1273,1,25,40,False
1305,1,25,41,False
1337,1,25,42,False
1369,1,25,43,False
1401,1,25,44,False
1433,1,25,45,False
1465,1,25,46,False
1497,1,25,47,False
1529,1,25,48,False
26,1,26,1,False
58,1,26,2,False
90,1,26,3,False
122,1,26,4,False
154,1,26,5,False
186,1,26,6,False
218,1,26,7,False
250,1,26,8,False
282,1,26,9,False
314,1,26,10,False
346,1,26,11,False
378,1,26,12,False
410,1,26,13,False
442,1,26,14,False
474,1,26,15,False
506,1,26,16,False
538,1,26,17,False
570,1,26,18,False
602,1,26,19,False
634,1,26,20,False
666,1,26,21,False
698,1,26,22,False
730,1,26,23,False
762,1,26,24,False
794,1,26,25,False
826,1,26,26,False
858,1,26,27,False
890,1,26,28,False
922,1,26,29,False
954,1,26,30,False
986,1,26,31,False
1018,1,26,32,False
1050,1,26,33,False
1082,1,26,34,False
1114,1,26,35,False
1146,1,26,36,False
1178,1,26,37,False
1210,1,26,38,False
1242,1,26,39,False
1274,1,26,40,False
1306,1,26,41,False
1338,1,26,42,False
1370,1,26,43,False
1402,1,26,44,False
1434,1,26,45,False
1466,1,26,46,False
1498,1,26,47,False
1530,1,26,48,False
27,1,27,1,False
59,1,27,2,False
91,1,27,3,False
123,1,27,4,False
155,1,27,5,False
187,1,27,6,False
219,1,27,7,False
251,1,27,8,False
283,1,27,9,False
315,1,27,10,False
347,1,27,11,False
379,1,27,12,False
411,1,27,13,False
443,1,27,14,False
475,1,27,15,False
507,1,27,16,False
539,1,27,17,False
571,1,27,18,False
603,1,27,19,False
635,1,27,20,False
667,1,27,21,False
699,1,27,22,False
731,1,27,23,False
763,1,27,24,False
795,1,27,25,False
827,1,27,26,False
859,1,27,27,False
891,1,27,28,False
923,1,27,29,False
955,1,27,30,False
987,1,27,31,False
1019,1,27,32,False
1051,1,27,33,False
1083,1,27,34,False
1115,1,27,35,False
1147,1,27,36,False
1179,1,27,37,False
1211,1,27,38,False
1243,1,27,39,False
1275,1,27,40,False
1307,1,27,41,False
1339,1,27,42,False
1371,1,27,43,False
1403,1,27,44,False
1435,1,27,45,False
1467,1,27,46,False
1499,1,27,47,False
1531,1,27,48,False
28,1,28,1,False
60,1,28,2,False
92,1,28,3,False
124,1,28,4,False
156,1,28,5,False
188,1,28,6,False
220,1,28,7,False
252,1,28,8,False
284,1,28,9,False
316,1,28,10,False
348,1,28,11,False
380,1,28,12,False
412,1,28,13,False
444,1,28,14,False
476,1,28,15,False
508,1,28,16,False
540,1,28,17,False
572,1,28,18,False
604,1,28,19,False
636,1,28,20,False
668,1,28,21,False
700,1,28,22,False
732,1,28,23,False
764,1,28,24,False
796,1,28,25,False
828,1,28,26,False
860,1,28,27,False
892,1,28,28,False
924,1,28,29,False
956,1,28,30,False
988,1,28,31,False
1020,1,28,32,False
1052,1,28,33,False
1084,1,28,34,False
1116,1,28,35,False
1148,1,28,36,False
1180,1,28,37,False
1212,1,28,38,False
1244,1,28,39,False
1276,1,28,40,False
1308,1,28,41,False
1340,1,28,42,False
1372,1,28,43,False
1404,1,28,44,False
1436,1,28,45,False
1468,1,28,46,False
1500,1,28,47,False
1532,1,28,48,False
29,1,29,1,False
61,1,29,2,False
93,1,29,3,False
125,1,29,4,False
157,1,29,5,False
189,1,29,6,False
221,1,29,7,False
253,1,29,8,False
285,1,29,9,False
317,1,29,10,False
349,1,29,11,False
381,1,29,12,False
413,1,29,13,False
445,1,29,14,False
477,1,29,15,False
509,1,29,16,False
541,1,29,17,False
573,1,29,18,False
605,1,29,19,False
637,1,29,20,False
669,1,29,21,False
701,1,29,22,False
733,1,29,23,False
765,1,29,24,False
797,1,29,25,False
829,1,29,26,False
861,1,29,27,False
893,1,29,28,False
925,1,29,29,False
957,1,29,30,False
989,1,29,31,False
1021,1,29,32,False
1053,1,29,33,False
1085,1,29,34,False
1117,1,29,35,False
1149,1,29,36,False
1181,1,29,37,False
1213,1,29,38,False
1245,1,29,39,False
1277,1,29,40,False
1309,1,29,41,False
1341,1,29,42,False
1373,1,29,43,False
1405,1,29,44,False
1437,1,29,45,False
1469,1,29,46,False
1501,1,29,47,False
diluent,0,29,48,True
30,1,30,1,False
62,1,30,2,False
94,1,30,3,False
126,1,30,4,False
158,1,30,5,False
190,1,30,6,False
222,1,30,7,False
254,1,30,8,False
286,1,30,9,False
318,1,30,10,False
350,1,30,11,False
382,1,30,12,False
414,1,30,13,False
446,1,30,14,False
478,1,30,15,False
510,1,30,16,False
542,1,30,17,False
574,1,30,18,False
606,1,30,19,False
638,1,30,20,False
670,1,30,21,False
702,1,30,22,False
734,1,30,23,False
766,1,30,24,False
798,1,30,25,False
830,1,30,26,False
862,1,30,27,False
894,1,30,28,False
926,1,30,29,False
958,1,30,30,False
990,1,30,31,False
1022,1,30,32,False
1054,1,30,33,False
1086,1,30,34,False
1118,1,30,35,False
1150,1,30,36,False
1182,1,30,37,False
1214,1,30,38,False
1246,1,30,39,False
1278,1,30,40,False
1310,1,30,41,False
1342,1,30,42,False
1374,1,30,43,False
1406,1,30,44,False
1438,1,30,45,False
1470,1,30,46,False
1502,1,30,47,False
31,1,31,1,False
63,1,31,2,False
95,1,31,3,False
127,1,31,4,False
159,1,31,5,False
191,1,31,6,False
223,1,31,7,False
255,1,31,8,False
287,1,31,9,False
319,1,31,10,False
351,1,31,11,False
383,1,31,12,False
415,1,31,13,False
447,1,31,14,False
479,1,31,15,False
511,1,31,16,False
543,1,31,17,False
575,1,31,18,False
607,1,31,19,False
639,1,31,20,False
671,1,31,21,False
703,1,31,22,False
735,1,31,23,False
767,1,31,24,False
799,1,31,25,False
831,1,31,26,False
863,1,31,27,False
895,1,31,28,False
927,1,31,29,False
959,1,31,30,False
991,1,31,31,False
1023,1,31,32,False
1055,1,31,33,False
1087,1,31,34,False
1119,1,31,35,False
1151,1,31,36,False
1183,1,31,37,False
1215,1,31,38,False
1247,1,31,39,False
1279,1,31,40,False
1311,1,31,41,False
1343,1,31,42,False
1375,1,31,43,False
1407,1,31,44,False
1439,1,31,45,False
1471,1,31,46,False
1503,1,31,47,False
32,1,32,1,False
64,1,32,2,False
96,1,32,3,False
128,1,32,4,False
160,1,32,5,False
192,1,32,6,False
224,1,32,7,False
256,1,32,8,False
288,1,32,9,False
320,1,32,10,False
352,1,32,11,False
384,1,32,12,False
416,1,32,13,False
448,1,32,14,False
480,1,32,15,False
512,1,32,16,False
544,1,32,17,False
576,1,32,18,False
608,1,32,19,False
640,1,32,20,False
672,1,32,21,False
704,1,32,22,False
736,1,32,23,False
768,1,32,24,False
800,1,32,25,False
832,1,32,26,False
864,1,32,27,False
896,1,32,28,False
928,1,32,29,False
960,1,32,30,False
992,1,32,31,False
1024,1,32,32,False
1056,1,32,33,False
1088,1,32,34,False
1120,1,32,35,False
1152,1,32,36,False
1184,1,32,37,False
1216,1,32,38,False
1248,1,32,39,False
1280,1,32,40,False
1312,1,32,41,False
1344,1,32,42,False
1376,1,32,43,False
1408,1,32,44,False
1440,1,32,45,False
1472,1,32,46,False
1504,1,32,47,False
`;
