export const M_384_13c_10pt_dup_1ctrl = `ID,Point,Row,Column,IsControl
1,1,2,2,False
1,2,2,3,False
1,3,2,4,False
1,4,2,5,False
1,5,2,6,False
1,6,2,7,False
1,7,2,8,False
1,8,2,9,False
1,9,2,10,False
1,10,2,11,False
2,1,2,13,False
2,2,2,14,False
2,3,2,15,False
2,4,2,16,False
2,5,2,17,False
2,6,2,18,False
2,7,2,19,False
2,8,2,20,False
2,9,2,21,False
2,10,2,22,False
1,1,3,2,False
1,2,3,3,False
1,3,3,4,False
1,4,3,5,False
1,5,3,6,False
1,6,3,7,False
1,7,3,8,False
1,8,3,9,False
1,9,3,10,False
1,10,3,11,False
2,1,3,13,False
2,2,3,14,False
2,3,3,15,False
2,4,3,16,False
2,5,3,17,False
2,6,3,18,False
2,7,3,19,False
2,8,3,20,False
2,9,3,21,False
2,10,3,22,False
3,1,4,2,False
3,2,4,3,False
3,3,4,4,False
3,4,4,5,False
3,5,4,6,False
3,6,4,7,False
3,7,4,8,False
3,8,4,9,False
3,9,4,10,False
3,10,4,11,False
4,1,4,13,False
4,2,4,14,False
4,3,4,15,False
4,4,4,16,False
4,5,4,17,False
4,6,4,18,False
4,7,4,19,False
4,8,4,20,False
4,9,4,21,False
4,10,4,22,False
3,1,5,2,False
3,2,5,3,False
3,3,5,4,False
3,4,5,5,False
3,5,5,6,False
3,6,5,7,False
3,7,5,8,False
3,8,5,9,False
3,9,5,10,False
3,10,5,11,False
4,1,5,13,False
4,2,5,14,False
4,3,5,15,False
4,4,5,16,False
4,5,5,17,False
4,6,5,18,False
4,7,5,19,False
4,8,5,20,False
4,9,5,21,False
4,10,5,22,False
5,1,6,2,False
5,2,6,3,False
5,3,6,4,False
5,4,6,5,False
5,5,6,6,False
5,6,6,7,False
5,7,6,8,False
5,8,6,9,False
5,9,6,10,False
5,10,6,11,False
6,1,6,13,False
6,2,6,14,False
6,3,6,15,False
6,4,6,16,False
6,5,6,17,False
6,6,6,18,False
6,7,6,19,False
6,8,6,20,False
6,9,6,21,False
6,10,6,22,False
5,1,7,2,False
5,2,7,3,False
5,3,7,4,False
5,4,7,5,False
5,5,7,6,False
5,6,7,7,False
5,7,7,8,False
5,8,7,9,False
5,9,7,10,False
5,10,7,11,False
6,1,7,13,False
6,2,7,14,False
6,3,7,15,False
6,4,7,16,False
6,5,7,17,False
6,6,7,18,False
6,7,7,19,False
6,8,7,20,False
6,9,7,21,False
6,10,7,22,False
7,1,8,2,False
7,2,8,3,False
7,3,8,4,False
7,4,8,5,False
7,5,8,6,False
7,6,8,7,False
7,7,8,8,False
7,8,8,9,False
7,9,8,10,False
7,10,8,11,False
8,1,8,13,False
8,2,8,14,False
8,3,8,15,False
8,4,8,16,False
8,5,8,17,False
8,6,8,18,False
8,7,8,19,False
8,8,8,20,False
8,9,8,21,False
8,10,8,22,False
7,1,9,2,False
7,2,9,3,False
7,3,9,4,False
7,4,9,5,False
7,5,9,6,False
7,6,9,7,False
7,7,9,8,False
7,8,9,9,False
7,9,9,10,False
7,10,9,11,False
8,1,9,13,False
8,2,9,14,False
8,3,9,15,False
8,4,9,16,False
8,5,9,17,False
8,6,9,18,False
8,7,9,19,False
8,8,9,20,False
8,9,9,21,False
8,10,9,22,False
9,1,10,2,False
9,2,10,3,False
9,3,10,4,False
9,4,10,5,False
9,5,10,6,False
9,6,10,7,False
9,7,10,8,False
9,8,10,9,False
9,9,10,10,False
9,10,10,11,False
10,1,10,13,False
10,2,10,14,False
10,3,10,15,False
10,4,10,16,False
10,5,10,17,False
10,6,10,18,False
10,7,10,19,False
10,8,10,20,False
10,9,10,21,False
10,10,10,22,False
9,1,11,2,False
9,2,11,3,False
9,3,11,4,False
9,4,11,5,False
9,5,11,6,False
9,6,11,7,False
9,7,11,8,False
9,8,11,9,False
9,9,11,10,False
9,10,11,11,False
10,1,11,13,False
10,2,11,14,False
10,3,11,15,False
10,4,11,16,False
10,5,11,17,False
10,6,11,18,False
10,7,11,19,False
10,8,11,20,False
10,9,11,21,False
10,10,11,22,False
11,1,12,2,False
11,2,12,3,False
11,3,12,4,False
11,4,12,5,False
11,5,12,6,False
11,6,12,7,False
11,7,12,8,False
11,8,12,9,False
11,9,12,10,False
11,10,12,11,False
12,1,12,13,False
12,2,12,14,False
12,3,12,15,False
12,4,12,16,False
12,5,12,17,False
12,6,12,18,False
12,7,12,19,False
12,8,12,20,False
12,9,12,21,False
12,10,12,22,False
11,1,13,2,False
11,2,13,3,False
11,3,13,4,False
11,4,13,5,False
11,5,13,6,False
11,6,13,7,False
11,7,13,8,False
11,8,13,9,False
11,9,13,10,False
11,10,13,11,False
12,1,13,13,False
12,2,13,14,False
12,3,13,15,False
12,4,13,16,False
12,5,13,17,False
12,6,13,18,False
12,7,13,19,False
12,8,13,20,False
12,9,13,21,False
12,10,13,22,False
13,1,14,2,False
13,2,14,3,False
13,3,14,4,False
13,4,14,5,False
13,5,14,6,False
13,6,14,7,False
13,7,14,8,False
13,8,14,9,False
13,9,14,10,False
13,10,14,11,False
Control,1,14,13,True
Control,2,14,14,True
Control,3,14,15,True
Control,4,14,16,True
Control,5,14,17,True
Control,6,14,18,True
Control,7,14,19,True
Control,8,14,20,True
Control,9,14,21,True
Control,10,14,22,True
13,1,15,2,False
13,2,15,3,False
13,3,15,4,False
13,4,15,5,False
13,5,15,6,False
13,6,15,7,False
13,7,15,8,False
13,8,15,9,False
13,9,15,10,False
13,10,15,11,False
Control,1,15,13,True
Control,2,15,14,True
Control,3,15,15,True
Control,4,15,16,True
Control,5,15,17,True
Control,6,15,18,True
Control,7,15,19,True
Control,8,15,20,True
Control,9,15,21,True
Control,10,15,22,True
`;
