/* eslint-disable react/no-unused-prop-types */
import { IconDefinition, faUpload } from '@fortawesome/free-solid-svg-icons';
import { ReactNode } from 'react';
import { BehaviorSubject } from 'rxjs';
import { MultiFileUpload, SingleFileUpload } from '.';
import { IconButton } from '../IconButton';
import { DialogService } from '../../../lib/services/dialog';

export interface UploadDialogOptions {
    uploadLabel?: string;
    onUpload: (files: File[]) => Promise<any>;
    title: string;
    content?: ReactNode;
    extensions: string[];
    multiple?: boolean;
}

export interface UploadDialogButtonProps extends UploadDialogOptions {
    icon?: IconDefinition;
    buttonLabel?: string;
    content?: ReactNode;
    className?: string;
    disabled?: boolean;
}

export function uploadFileDialog({ title, content, extensions, multiple, uploadLabel, onUpload }: UploadDialogOptions) {
    DialogService.open({
        type: 'generic',
        title,
        confirmButtonContent: 'Upload',
        model: { content, extensions, multiple, uploadLabel: uploadLabel ?? title },
        defaultState: [],
        wrapOk: true,
        content: FileUploadDialogContent,
        onOk: async (files: File | File[] | null) => {
            if (!files) {
                await onUpload([]);
            } else {
                await onUpload(files instanceof File ? [files] : Array.from(files));
            }
        },
    });
}

export function UploadFileButton(props: UploadDialogButtonProps) {
    const { className, icon, buttonLabel, title, disabled } = props;

    return (
        <IconButton
            icon={icon ?? faUpload}
            onClick={() => uploadFileDialog(props)}
            className={className}
            disabled={disabled}
        >
            {buttonLabel ?? title}
        </IconButton>
    );
}

function FileUploadDialogContent({
    model,
    stateSubject,
}: {
    model: Partial<UploadDialogOptions>;
    stateSubject: BehaviorSubject<File | File[] | null>;
}) {
    return (
        <div className='vstack gap-2'>
            {model.content}
            {!!model.multiple && (
                <MultiFileUpload
                    filesSubject={stateSubject as any}
                    label={model.uploadLabel!}
                    extensions={model.extensions!}
                    numFiles={999}
                />
            )}
            {!model.multiple && (
                <SingleFileUpload
                    fileSubject={stateSubject as any}
                    label={model.uploadLabel!}
                    extensions={model.extensions!}
                />
            )}
        </div>
    );
}
