import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, Modal, Spinner } from 'react-bootstrap';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import { ChemDrawExportType, ChemDrawService } from '../../../lib/services/chemdraw';
import { ChemDrawModalService } from '../../../lib/services/chemdraw-modal';
import { ClipboardService } from '../../../lib/services/clipboard';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { ChemDraw } from '../../ChemDraw';

export function ChemDrawModal() {
    const showChemDraw = useBehavior(ChemDrawModalService.options);

    return (
        <Modal
            backdrop
            centered
            scrollable
            dialogClassName='chem-draw-modal'
            show={!!showChemDraw}
            onHide={() => ChemDrawModalService.close()}
        >
            <Modal.Header closeButton>
                <h4>ChemDraw</h4>
            </Modal.Header>
            <Modal.Body>
                <div className='m-auto' style={{ width: '780px', height: '480px' }}>
                    <ChemDraw initialSMILES={showChemDraw?.initialSMILES} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='w-100 hstack gap-2'>
                    <CopyButton />
                    <div className='m-auto' />
                    <Button variant='link' onClick={() => ChemDrawModalService.close()}>
                        {showChemDraw?.onSMILESDrawn ? 'Cancel' : 'Close'}
                    </Button>
                    {!!showChemDraw?.onSMILESDrawn && (
                        <Button variant='primary' onClick={() => ChemDrawModalService.confirm()}>
                            Load
                        </Button>
                    )}
                </div>
            </Modal.Footer>
        </Modal>
    );
}

async function copyChemDraw(type: ChemDrawExportType) {
    try {
        const value = await ChemDrawService.export(type);
        await ClipboardService.copyText(value, 'Copy ChemDraw', 'Copy SMILES');
    } catch (err) {
        reportErrorAsToast('Copy', err);
    }
}

function CopyButton() {
    const [copyState, applyCopy] = useAsyncAction();

    return (
        <Dropdown>
            <Dropdown.Toggle variant='outline-primary'>
                {copyState.isLoading && <Spinner animation='border' className='me-2' size='sm' role='status' />}
                {!copyState.isLoading && <FontAwesomeIcon icon={faCopy} className='me-2' size='sm' />}
                Copy
            </Dropdown.Toggle>
            <Dropdown.Menu className='insight-chemdraw-modal-copy-dropdown'>
                <Dropdown.Item onClick={() => applyCopy(copyChemDraw('smiles'))}>SMILES</Dropdown.Item>
                <Dropdown.Item onClick={() => applyCopy(copyChemDraw('mol'))}>MOL</Dropdown.Item>
                <Dropdown.Item onClick={() => applyCopy(copyChemDraw('mol-v3000'))}>MOL V3</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}
