export interface Gene {
    id: number;
    symbol: string;
    symbol_aliases: string[];
    full_name?: string;
    mutant: string;
    mutant_aliases: string[];
    organism: string;
    ncbi_gene_id?: number;
    hgnc_id?: string;
}

export interface Protein {
    schema_name: 'protein';
    id?: number;
    eoi: string;
    short_name: string;
    full_name: string;
    gene: Gene;
    components: (string | null)[];
    uniprot_id?: string;
    chembl_id?: string;
    bindingdb_id?: string;
}

export interface ProteinComplex {
    schema_name: 'protein_complex';
    full_name: string;
    id?: number;
    eoi: string;
    protein_components: (string | null)[];
    proteins: Protein[];
    chembl_id: string;
    complex_portal_id: string;
}

export type ProteinType = Protein | ProteinComplex;

function _getProteinShortName(p: Protein) {
    if (p.gene.mutant === 'WT') return p.short_name;
    return `${p.short_name} - ${p.gene.mutant}`;
}

export function getProteinShortName(p?: ProteinType) {
    if (!p) return '';

    return p.schema_name === 'protein'
        ? _getProteinShortName(p)
        : p.proteins.map((x) => _getProteinShortName(x)).join('/');
}

export function getProteinKeys(p: ProteinType) {
    if (typeof p.id !== 'number') throw new Error('Protein/Complex must have the id field specified.');
    return [p.id];
}

export interface EnvironmentDetailsBase {
    name: string;
    [extra: string]: any;
}

export type EnvironmentEnum =
    | 'Unknown'
    | 'In silico'
    | 'Analytical'
    | 'In vitro - biochemical'
    | 'In vitro - microsomes'
    | 'In vitro - hepatocytes'
    | 'In vitro - S9'
    | 'In vitro - cell';

export interface AssayProperty {
    category: 'Potency' | 'ADME' | 'Toxicity' | 'Physical chemistry';
    description: string;
    environment: EnvironmentEnum;
    environment_details?: EnvironmentDetailsBase;
    id: number;
    kind: string;
    measurement: string;
    objective?: string;
    protein?: ProteinType;
    shorthand?: string;
    common_unit_shorthand?: string;
    common_unit: string;
}

export function canConvertPropertyUnits(property: AssayProperty) {
    return property.measurement === 'IC50' || property.measurement === 'Kd' || property.measurement === 'Ki';
}
