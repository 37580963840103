import { getProductSample } from '.';
import { isRelativelyClose } from '../../../lib/util/math';
import { Sample } from '../../Compounds/compound-api';
import { WellLayout } from '../../HTE/experiment-data';
import { wellLabelToIndex } from '../../HTE/plate/utils';
import {
    HTEDReaction,
    HTEPProductSample,
    HTEPReagent,
    HTEPReagentUse,
    HTEPSolution,
    HTERInstructionT,
    KnownReactantTypes,
} from '../data-model';
import type { HTE2MSModel } from '../model';
import { getDryUseAmount } from './inventory';

export type ReactionValidation = [['danger' | 'warning' | 'info', string][], HTEPProductSample];

export interface LayoutValidation {
    labelToReactions: Map<string, HTEDReaction[]>;
    invalidLabels: Set<string>;
}

export function validateLayout(layout: WellLayout, reactions: HTEDReaction[]): LayoutValidation {
    const labelToReactions = new Map<string, HTEDReaction[]>();
    const invalidLabels = new Set<string>();

    for (const r of reactions) {
        if (!r.well_label) continue;
        const index = wellLabelToIndex(layout, r.well_label);
        if (index < 0 || index >= layout) {
            invalidLabels.add(r.well_label);
        }

        if (labelToReactions.has(r.well_label)) {
            labelToReactions.get(r.well_label)!.push(r);
        } else {
            labelToReactions.set(r.well_label, [r]);
        }
    }

    return { labelToReactions, invalidLabels };
}

export function validateReaction(
    model: HTE2MSModel,
    r: HTEDReaction,
    layoutValidation: LayoutValidation
): ReactionValidation {
    const errors: string[] = [];
    const warnings: string[] = [];
    const infos: string[] = [];

    if (typeof r.scale !== 'number') {
        errors.push('Scale not assigned');
    }
    if (!r.template.solvent) {
        errors.push('Solvent not assigned');
    }
    if (!r.template.reaction_chemistry) {
        errors.push('Chemistry not assigned');
    }
    if (r.template.instructions.some((i, idx) => !validateInstruction(i, idx))) {
        errors.push('Incomplete instructions');
    }
    const missingEntities: string[] = [];
    if (r.product_identifier && !model.assets.entities.getEntity(r.product_identifier)) {
        missingEntities.push(r.product_identifier);
    }
    if (!r.product_identifier && r.product_enumeration?.errors?.length) {
        (typeof r.product_enumeration.substance_id === 'number' ? infos : warnings).push('Enumeration has errors');
    }
    const warnedReactantTypes = new Set<string>();
    let nCooks = 0;
    for (const instr of r.template.instructions) {
        if (instr.kind === 'add' && instr.identifier && !model.assets.entities.getEntity(instr.identifier)) {
            if (!missingEntities.includes(instr.identifier)) {
                missingEntities.push(instr.identifier);
            }
        }
        if (
            instr.kind === 'add' &&
            !warnedReactantTypes.has(instr.reactant_kind) &&
            !KnownReactantTypeSet.has(instr.reactant_kind)
        ) {
            warnedReactantTypes.add(instr.reactant_kind);
            warnings.push(`Unknown reactant type: ${instr.reactant_kind}`);
        }
        if (instr.kind === 'cook') {
            nCooks++;
        }
    }
    if (nCooks > 1) {
        errors.push('Only one cook allowed');
    }
    for (const id of missingEntities) {
        errors.push(`${id} not found`);
    }
    if (!r.product_identifier) {
        if (!r.project) {
            errors.push('Project not assigned');
        }
        if (r.product_enumeration && typeof r.product_enumeration.substance_id !== 'number') {
            errors.push('Product not assigned');
        } else {
            errors.push('Product not registered');
        }
    } else {
        const productCompound = model.assets.entities.getCompoundFromIdentifier(r.product_identifier);
        if (r.project && productCompound?.project && productCompound?.project !== r.project) {
            infos.push('Product registered for different project');
        }
        if (!productCompound?.project) {
            infos.push('Product not registered to any project');
        }
    }

    const msd = r.template.instructions.filter((i) => i.kind === 'add' && i.reactant_kind === 'msd');
    const bb = r.template.instructions.filter((i) => i.kind === 'add' && i.reactant_kind === 'bb');
    if (msd.length !== 1) {
        errors.push('Exactly one MSD must be added');
    }
    if (bb.length !== 1) {
        errors.push('Exactly one BB must be added');
    }
    const volumeInfo = getProductSample(r);

    const maxVolume = model.design.state.labware.value.product.volume;
    if (volumeInfo.total_volume > maxVolume) {
        errors.push('Total volume exceeds labware capacity');
    }
    if (volumeInfo.total_volume < volumeInfo.standard_volume! * 0.975) {
        warnings.push('Standard volume underflow');
    } else if (volumeInfo.total_volume > volumeInfo.standard_volume! * 1.025) {
        warnings.push('Standard volume overflow');
    }

    if (r.template.target_concentration && volumeInfo.concentration! > r.template.target_concentration) {
        warnings.push('Target concentration exceeded');
    }

    let prevSolutionId: string | undefined;
    const seenSolutionIds = new Set<string>();
    for (const instr of r.template.instructions) {
        if (instr.kind !== 'add' || !instr.solution_id) {
            if (prevSolutionId) seenSolutionIds.add(prevSolutionId);
            prevSolutionId = undefined;
            continue;
        }

        if (instr.solution_id && seenSolutionIds.has(instr.solution_id)) {
            errors.push('Same solution ID can only be used by consecutive add instructions');
            break;
        }

        prevSolutionId = instr.solution_id;
    }

    for (const instr of r.template.instructions) {
        if (instr.kind === 'add' && instr.solution_id && !instr.identifier?.includes('-')) {
            errors.push('Solution reagents must use batch identifiers');
            break;
        }
    }

    if (r.well_label) {
        if (layoutValidation.invalidLabels.has(r.well_label)) {
            errors.push('Invalid well label');
        }
        if (layoutValidation.labelToReactions.get(r.well_label)?.length! > 1) {
            errors.push(`Multiple reactions in ${r.well_label}`);
        }
    } else if (layoutValidation.labelToReactions.size > 0) {
        warnings.push('Well label not assigned, implicit location will be used');
    }

    const messages = [
        ...errors.map((m) => ['danger', m]),
        ...warnings.map((m) => ['warning', m]),
        ...infos.map((m) => ['info', m]),
    ];

    return [messages as any, volumeInfo];
}

const KnownReactantTypeSet = new Set<string>(KnownReactantTypes);

function validateInstruction(instruction: HTERInstructionT, index: number) {
    switch (instruction.kind) {
        case 'pause':
            return typeof instruction.duration === 'number';
        case 'cook':
            return typeof instruction.duration === 'number' && typeof instruction.temperature === 'number';
        case 'add': {
            return typeof instruction.equivalence === 'number' && typeof instruction.identifier === 'string';
        }
        default:
            return true;
    }
}

export function invalidateReactionInstructions(r: HTEDReaction): HTEDReaction {
    const msd = r.template.instructions.find((i) => i.kind === 'add' && i.reactant_kind === 'msd');
    const bb = r.template.instructions.find((i) => i.kind === 'add' && i.reactant_kind === 'bb');
    if (msd && bb) return r;

    const ret = { ...r };
    delete ret.product_enumeration;
    delete ret.product_identifier;
    return ret;
}

export type ReagentValidation = [kind: 'info' | 'danger' | 'warning' | 'success', message: string];

const Tolerance = 0.025;

export function validateLiquidReagent(model: HTE2MSModel, reagent: HTEPReagent): ReagentValidation {
    const inv = model.inventory.getLiquid(reagent);
    const labware = model.design.labwareMap.get(inv?.labware_id!);
    const { addInstructionMap } = model.design;
    const isManual = reagent.uses.some((use) => addInstructionMap.get(use.instruction_id)?.manual_handling);

    if (isManual) {
        return ['info', 'Manual handling'];
    }

    if (!inv?.source_barcode) {
        return ['danger', 'Source Barcode not assigned'];
    }
    if (!labware) {
        return ['danger', 'Labware not assigned'];
    }

    const finalRequired = model.protocol.reagents.finalRequired.get(reagent);
    const reqVolume = 1e-3 * (finalRequired?.volume_l ?? 0);
    const reqAmount = finalRequired?.amount_g ?? 0;

    if (labware.is_reservoir) {
        const loc = model.inventory.getReservoirLocation(reagent);
        if (!loc?.container_label || !loc?.container_well) {
            return ['danger', 'Reservoir sample location not assigned'];
        }
        if (model.inventory.getLocationTargetCount(loc) > 1) {
            return ['danger', 'Reservoir location assigned to multiple reagents'];
        }
    } else if (!labware.no_transfer) {
        const srcSample = model.assets.inventory.getVialSample(inv.source_barcode!);

        if (!inv?.transfer_barcode && srcSample && reqAmount > (1 + Tolerance) * srcSample.solute_mass!) {
            return ['danger', 'Low source amount'];
        }

        if (!inv?.transfer_barcode) {
            return ['danger', 'Transfer Barcode not assigned'];
        }
    }

    if (inv.transfer_barcode) {
        if (model.inventory.getBarcodeTransferTargetCount(inv.transfer_barcode) > 1) {
            return ['danger', 'Transfer Barcode assigned to multiple reagents'];
        }
    }

    const xferSample = model.assets.inventory.getVialSample(inv.transfer_barcode!);
    const srcSample = model.assets.inventory.getVialSample(inv.source_barcode!);
    const batchValidation = validateSampleBatches(model, srcSample, xferSample);
    if (batchValidation) return batchValidation;

    const sample = xferSample || srcSample;

    if (!sample) {
        return ['danger', 'Empty labware'];
    }

    if (typeof sample.concentration !== 'number') {
        if (reqAmount > (1 + Tolerance) * sample.solute_mass!) {
            return ['warning', 'Not solubilized (low amount)'];
        }
        return ['warning', 'Not solubilized'];
    }

    if (!isRelativelyClose(reagent.concentration!, sample.concentration!, Tolerance)) {
        return ['danger', 'Incorrect sample concentration'];
    }

    if (reqVolume > (1 + Tolerance) * sample.solvent_volume!) {
        return ['warning', 'Low sample volume'];
    }

    return ['success', ''];
}

export function validateDryReagent(model: HTE2MSModel, use: HTEPReagentUse): ReagentValidation {
    const reagent = model.protocol.reagents.getByKey(use.reagent_key);
    if (!reagent) {
        return ['danger', 'Could not find base reagent. This is a bug, contact your nearest developer.'];
    }

    const { addInstructionMap } = model.design;
    const isManual = addInstructionMap.get(use.instruction_id)?.manual_handling;
    if (isManual) {
        return ['info', 'Manual handling'];
    }

    const inv = model.inventory.getDryUse(use);
    if (!inv?.source_barcode && !inv?.transfer_barcode) {
        return ['danger', 'Source Barcode not assigned'];
    }

    const reqAmount = getDryUseAmount(model, use) ?? 0;

    const xferSample = model.assets.inventory.getVialSample(inv.transfer_barcode!);
    const srcSample = model.assets.inventory.getVialSample(inv.source_barcode!);
    const batchValidation = validateSampleBatches(model, srcSample, xferSample);
    if (batchValidation) return batchValidation;

    if (inv.transfer_barcode) {
        if (!xferSample) {
            return ['danger', 'Empty transfer labware'];
        }
        if (typeof xferSample.concentration === 'number') {
            return ['danger', 'Dry transfer sample expected'];
        }
        if (model.inventory.getBarcodeTransferTargetCount(inv.transfer_barcode) > 1) {
            return ['danger', 'Transfer Barcode assigned to multiple reagents'];
        }
        if (reqAmount > (1 + Tolerance) * xferSample.solute_mass!) {
            return ['warning', 'Low transfer amount'];
        }
        return ['success', ''];
    }

    if (!srcSample) {
        return ['danger', 'Empty source labware'];
    }
    if (typeof srcSample.concentration === 'number') {
        return ['danger', 'Dry source sample expected'];
    }
    if (reqAmount > (1 + Tolerance) * srcSample.solute_mass!) {
        return ['warning', 'Low source amount'];
    }

    if (!inv.transfer_barcode) {
        // NOTE: This key needs to be kept in sync with _step4_build_worklists in protocol.py
        if (!model.design.labware.product.no_transfer && reagent.worklist_key === 'dry') {
            return ['danger', 'Transfer Barcode not assigned'];
        }

        if (reagent.worklist_key !== 'dry') {
            return ['info', 'Dry transfer, handle manually'];
        }
    }

    return ['success', ''];
}

export function validateSolutionReagent(model: HTE2MSModel, solution: HTEPSolution): ReagentValidation {
    const inv = model.inventory.getSolution(solution);
    const hasBarcode = !!inv?.source_barcode;
    const hasLocation = !!inv?.location?.container_label && !!inv?.location?.container_well;

    let isManual = false;
    const { addInstructionMap } = model.design;
    for (const use of solution.uses) {
        for (const instr of use.instruction_uses) {
            if (addInstructionMap.get(instr.instruction_id)?.manual_handling) {
                isManual = true;
                break;
            }
        }
        if (isManual) break;
    }

    if (isManual) {
        return ['info', 'Manual handling'];
    }

    if (solution.components.find((c) => !c.identifier.includes('-'))) {
        return ['danger', 'Use batch identifiers for solution components to ensure correct formula weight with salts'];
    }

    if (!hasBarcode && !hasLocation) {
        return ['danger', 'Provide source barcode or location'];
    }

    const labware = model.design.labwareMap.get(inv?.labware_id!);
    if (!labware) {
        return ['danger', 'Labware not assigned'];
    }

    if (labware.is_reservoir && !hasLocation) {
        return ['danger', 'Reservoir location not assigned'];
    }

    return ['success', ''];
}

function validateSampleBatches(
    model: HTE2MSModel,
    a: Sample | undefined,
    b: Sample | undefined
): ReagentValidation | undefined {
    if (!a || !b) return;

    const batchA = model.assets.entities.getBatch(a.batch_id!);
    const batchB = model.assets.entities.getBatch(b.batch_id!);
    if (batchA?.compound_id !== batchB?.compound_id) {
        return ['danger', 'Source/Target compound mismatch'];
    }
    if ((batchA?.salt || '') !== (batchB?.salt || '')) {
        return ['danger', 'Source/Target salt mismatch'];
    }
}
