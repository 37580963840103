import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Loading from '../../components/common/Loading';
import { PageTemplate } from '../../components/Layout/Layout';

const LayoutRoot = lazy(() => import(/* webpackChunkName: "dataviz" */ './ui/Layout'));
const ListRoot = lazy(() => import(/* webpackChunkName: "dataviz" */ './ui/List'));

function LoadingUI({ app }: { app: string }) {
    return (
        <PageTemplate title='Workspaces' breadcrumb={{ title: app, href: '#' }}>
            <Loading />
        </PageTemplate>
    );
}

function App({ app, UI }: { app: string; UI: any }) {
    return (
        <Suspense fallback={<LoadingUI app={app} />}>
            <UI />
        </Suspense>
    );
}

const LayoutDev = <App app='Workspaces' UI={LayoutRoot} />;
const List = <App app='Workspaces' UI={ListRoot} />;

export function DataVizRouter() {
    return (
        <Routes>
            <Route path='/list' element={List} />
            <Route path='/new' element={LayoutDev} />
            <Route path='/:state_id' element={LayoutDev} />
            <Route path='/' element={<Navigate to='/workspaces/list' replace />} />
        </Routes>
    );
}
