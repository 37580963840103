import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { Column } from 'react-table';
import { BehaviorSubject } from 'rxjs';
import { AsyncMoleculeDrawing } from '../../../components/common/AsyncMoleculeDrawing';
import { TextInput } from '../../../components/common/Inputs';
import useBehavior from '../../../lib/hooks/useBehavior';
import { ToastService } from '../../../lib/services/toast';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { splitInput } from '../../../lib/util/misc';
import { ExperimentBatchManager } from '../batch-manager';
import { HTEApi } from '../experiment-api';
import { HTEEntityWrapper, HTESolvents, isHTESolvent, Reactant } from '../experiment-data';
import { ReactantTypeBadge } from './common';

export class ReagentsModel {
    state = {
        all: new BehaviorSubject<HTEEntityWrapper[]>([]),
    };

    readonly columns: Column<HTEEntityWrapper>[] = [
        {
            Header: 'Structure',
            id: 'structure',
            accessor: (row) => this.experiment.batches.getSmiles(row.identifier),
            width: 120,
            disableGlobalFilter: true,
            Cell: ({ value }: { value?: string }) => (
                <>
                    {value && (
                        <AsyncMoleculeDrawing smiles={value} height={50} drawer={this.experiment.drawer} autosize />
                    )}
                </>
            ),
        },
        {
            Header: 'Common Name',
            accessor: 'common_name',
            width: 180,
        },
        {
            Header: 'Identifier',
            accessor: 'identifier',
            width: 200,
            Cell: ({ value }) => <CompoundIdentifier value={value} />,
        },
        {
            Header: 'Universal Identifier',
            accessor: 'universal_identifier',
            width: 200,
            Cell: ({ value }) => <CompoundIdentifier value={value} />,
        },
        {
            Header: 'Barcode',
            accessor: 'barcode',
            width: 200,
            Cell: ({ value }) => (value ?? '') as any,
        },
        {
            Header: 'Type',
            accessor: 'reagent_type',
            width: 80,
            Cell: ({ value }) => <ReactantTypeBadge type={value ?? 'reagent'} />,
        },
        {
            Header: '',
            id: 'action',
            accessor: 'identifier',
            width: 160,
            disableSortBy: true,
            disableGlobalFilter: true,
            Cell: ({ value, row }) => {
                const isAdded = this.experiment.reactants.getReactant(value);
                return (
                    <Button
                        variant={isAdded ? 'outline-danger' : `outline-primary`}
                        size='sm'
                        onClick={() =>
                            !isAdded ? this.addReagentToReactants([row.original]) : this.removeReagent(value)
                        }
                        disabled={this.experiment.stateInfo.status !== 'Planning'}
                    >
                        <FontAwesomeIcon icon={isAdded ? faMinus : faPlus} size='sm' className='me-2' />
                        Reagent
                    </Button>
                );
            },
        },
    ];

    addReagentToReactants(entities: HTEEntityWrapper[]) {
        const reactants: Reactant[] = [];

        for (const entity of entities) {
            const defaults: Reactant['defaults'] = {
                concentration: null,
                equivalence: 1.0,
                overage: 1.2,
            };

            reactants.push({
                barcode: entity.barcode,
                identifier: entity.identifier,
                molecular_weight: this.experiment.batches.getMolecularWeight(entity.identifier),
                defaults,
                ...defaults,
                stock_amount: null,
                stock_volume: null,
                type: 'reagent',
                reagent_type: entity.reagent_type ?? 'reagent',
                solvent: typeof defaults.concentration === 'number' ? null : 'DMSO',
            });
        }

        this.experiment.addReactants(reactants);
    }

    removeReagent(id: string) {
        this.experiment.removeReactant(id);
        if (!isHTESolvent(id)) {
            this.state.all.next(this.state.all.value.filter((r) => r.identifier !== id));
        }
    }

    async addReagents(input: string | string[]) {
        try {
            const identifiers = typeof input === 'string' ? splitInput(input) : input;
            const loaded = await HTEApi.loadReactants({ identifiers });
            const existingKeys = new Set(
                this.state.all.value
                    .filter((r) => !isHTESolvent(r.identifier))
                    .map((r) => this.experiment.batches.getEntityKey(r.identifier))
            );

            this.experiment.batches.addBatches(loaded.flatMap((r) => r.batches));
            this.experiment.batches.addCompounds(loaded.map((r) => r.compound));
            const unique = loaded.filter(
                (r) => !existingKeys.has(this.experiment.batches.getEntityKey(r.pivot_identifier))
            );

            if (unique.length === 0) {
                ToastService.show({
                    type: 'info',
                    message: 'No new reagents added',
                    timeoutMs: 3500,
                });
                return;
            }

            const existingMsdBbCompoundIds = new Set(
                this.experiment.design.reactants
                    .filter((r) => r.type === 'msd' || r.type === 'bb')
                    .map((r) => this.experiment.batches.getCompound(r.identifier)?.id)
            );

            const added: HTEEntityWrapper[] = [];
            for (const r of unique) {
                const entity = this.experiment.batches.getEntity(r.pivot_identifier)!;
                const compoundId = this.experiment.batches.getCompound(r.pivot_identifier)?.id;

                if (existingMsdBbCompoundIds.has(compoundId)) {
                    ToastService.show({
                        type: 'warning',
                        message: `${r.pivot_identifier} already added as MSD/BB`,
                        timeoutMs: 10000,
                    });
                } else {
                    added.push({
                        identifier: entity.reagent_identifier ?? entity.identifier!,
                        universal_identifier: entity.universal_identifier,
                        barcode: r.vial_barcode,
                        common_name: r.compound?.common_name,
                        reagent_type: 'reagent',
                    });
                }
            }

            if (added.length === 0) {
                return;
            }

            this.state.all.next([...added, ...this.state.all.value]);
            this.addReagentToReactants(added);

            ToastService.show({
                type: 'success',
                message: `Added ${added.length} reagent${added.length !== 1 ? 's' : ''}`,
                timeoutMs: 5000,
            });
        } catch (err) {
            reportErrorAsToast('Add Reagents', err);
        }
    }

    constructor(public experiment: import('../experiment-model').HTEExperimentModel, list?: HTEEntityWrapper[]) {
        this.state.all.next([
            ...(list?.length! > 0 ? list! : []),
            ...HTESolvents.map((s) => ({
                common_name: s,
                identifier: s,
                reagent_type: 'solvent' as const,
            })),
        ]);
    }
}

export function CompoundIdentifier({
    value,
    idColor,
    className,
}: {
    value?: string;
    idColor?: string;
    className?: string;
}) {
    if (!value) return null;

    const type = value.match(/^\D+/);
    return (
        <span className={className}>
            <span className={`hte-experiment-id-type-${type?.[0]?.toLowerCase()}`}>{type?.[0]}</span>
            <span style={{ color: idColor }}>{value.substring(type?.[0]?.length ?? 0)}</span>
        </span>
    );
}

export function createHTEReagents(reagents: Reactant[], manager: ExperimentBatchManager): HTEEntityWrapper[] {
    const ret: HTEEntityWrapper[] = [];
    for (const r of reagents) {
        const entity = manager.getEntity(r.identifier);
        if (!entity) continue;

        ret.push({
            identifier: entity.reagent_identifier ?? r.identifier!,
            universal_identifier: entity.universal_identifier,
            barcode: r.barcode,
            common_name: manager.getCompound(r.identifier)?.common_name,
            reagent_type: 'reagent',
        });
    }
    return ret;
}

export function AddReagentDialogControls({ stateSubject }: { stateSubject: BehaviorSubject<{ input: string }> }) {
    const state = useBehavior(stateSubject);
    return (
        <div>
            <TextInput
                textarea
                value={state.input}
                setValue={(input) => stateSubject.next({ input })}
                placeholder='Enter a list of barcodes or compound/batch identifiers'
                autoFocus
            />
        </div>
    );
}
