import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';
import api from '../../api';
import { ClipboardService } from '../../lib/services/clipboard';
import { useAsyncAction } from '../../lib/hooks/useAsyncAction';
import { ChemDrawModalService } from '../../lib/services/chemdraw-modal';
import { iiChemDrawLogo } from './Icons';
import { reportErrorAsToast } from '../../lib/util/errors';

const kekulizeCache: Map<string, Promise<string> | string> = new Map();
async function kekulize(smiles: string) {
    const cached = kekulizeCache.get(smiles);
    if (cached) {
        return cached;
    }
    try {
        const promise = api.utils.kekulizeSmiles(smiles);
        kekulizeCache.set(smiles, promise);
        const ret = await promise;
        kekulizeCache.set(smiles, ret);
        return ret;
    } catch (err) {
        kekulizeCache.delete(smiles);
        reportErrorAsToast('Kekulize', err);
        return smiles;
    }
}

export function CopySmilesButton({ smiles }: { smiles: string }) {
    const [copy, loadCopy] = useAsyncAction();

    async function kekulizeSmilesAndCopy() {
        await ClipboardService.copyText(await kekulize(smiles), 'Copy SMILES');
    }

    if (!smiles) return null;
    return (
        <button
            type='button'
            title='Click to copy SMILES'
            onClick={() => loadCopy(kekulizeSmilesAndCopy())}
            className='bg-transparent border-0'
            disabled={copy.isLoading}
        >
            {!copy.isLoading && <FontAwesomeIcon icon={faCopy} fixedWidth color='var(--bs-secondary)' />}
            {copy.isLoading && <Spinner animation='border' size='sm' role='status' />}
        </button>
    );
}

export function OpenInChemdrawButton({
    smiles,
    onSMILESDrawn,
    showIfEmpty = false,
}: {
    smiles: string;
    onSMILESDrawn?: (sm: string) => any;
    showIfEmpty?: boolean;
}) {
    const [state, apply] = useAsyncAction<string>();

    if (!showIfEmpty && !smiles) return null;

    const open = async () => {
        if (smiles) {
            const kekulized = await apply(kekulize(smiles));
            ChemDrawModalService.show({ initialSMILES: kekulized, onSMILESDrawn });
        } else {
            ChemDrawModalService.show({ onSMILESDrawn });
        }
    };

    return (
        <button
            type='button'
            title='Open in ChemDraw'
            onClick={open}
            className='bg-transparent border-0'
            disabled={state.isLoading}
        >
            {!state.isLoading && <FontAwesomeIcon icon={iiChemDrawLogo} fixedWidth color='var(--bs-secondary)' />}
            {state.isLoading && <Spinner animation='border' size='sm' role='status' />}
        </button>
    );
}

export function OverflowSmilesDisplay({ smiles }: { smiles: string }) {
    return (
        <div className='d-flex'>
            <div className='entos-smiles-overflow'>{smiles}</div>
            <CopySmilesButton smiles={smiles} />
            <OpenInChemdrawButton smiles={smiles} />
        </div>
    );
}
