import { PillNav, PillNavStep } from '../../../components/common/Nav';
import useBehavior from '../../../lib/hooks/useBehavior';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { HTE2MSModel } from '../model';
import { HTE2MSInventoryModel, InventoryTab } from './model';
import { DryTransfersTab, LiquidTransfersTab } from './ui';

export function HTE2MSTransferModeUI({ model }: { model: HTE2MSModel }) {
    const { inventory } = model;
    useMountedModel(inventory);
    useBehavior(model.state.readOnlyDesignAndProduction);

    return <TransferModeInventoryTabsUI model={inventory} />;
}

const InventoryNavTabs: PillNavStep<InventoryTab>[] = [
    { name: 'dry', label: 'Dry Reagents' },
    { name: 'liquid', label: 'Liquid Reagents' },
];

function TransferModeInventoryTabsUI({ model }: { model: HTE2MSInventoryModel }) {
    const tab = useBehavior(model.state.tab);

    return (
        <div className='d-flex flex-column h-100 ps-2'>
            <div className='hstack gap-2 me-2 pt-2 mb-2'>
                <PillNav
                    steps={InventoryNavTabs}
                    currentStep={tab}
                    onSetStep={(s) => model.state.tab.next(s)}
                    containerClassName='hte2ms-inline-tab-nav-buttons'
                />
            </div>
            <div className='flex-grow-1 position-relative'>
                {tab === 'liquid' && <LiquidTransfersTab model={model} />}
                {tab === 'dry' && <DryTransfersTab model={model} />}
            </div>
        </div>
    );
}
