import { useEffect } from 'react';
import { ReactiveModel } from '../util/reactive-model';

export default function useMountedModel<T extends ReactiveModel>(model: T | null | undefined): T | null | undefined {
    useEffect(() => {
        if (!model) return;
        model.mount();
        return () => model.dispose();
    }, [model]);

    return model;
}
