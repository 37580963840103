import { BehaviorSubject } from 'rxjs';
import {
    Column,
    columnDataTableStore,
    ColumnsFor,
    ColumnTableData,
    DataTableModel,
    DataTableStore,
    DefaultRowHeight,
} from '../../components/DataTable';
import {
    BooleanBadge,
    DownloadArtifactsCell,
    HoverBatchLink,
    HoverLink,
    SmilesColumn,
} from '../../components/DataTable/common';
import { AssayValueTypeColumn } from '../../lib/assays/table';
import { assayValueTypeCompare, assayValueTypeToString, tryGetAssayValueGuess } from '../../lib/assays/util';
import { interpolateHSLtoRGB, rgb2hsl, toRGBString } from '../../lib/util/colors';
import { AsyncMoleculeDrawer } from '../../lib/util/draw-molecules';
import { reportErrorAsToast } from '../../lib/util/errors';
import { groupBy } from '../../lib/util/misc';
import { ReactiveModel } from '../../lib/util/reactive-model';
import { getLinkToAssayFromCompound } from '../Assays/assay-api';
import { PlateRow } from '../ECM/ecm-api';
import { ExperimentBatchManager } from './batch-manager';
import { AssayArtifactValue, HTEApi, HTEDetailsInfo, HTEPlateDetailsData } from './experiment-api';
import { HTEExperimentInfo, PlateDimensions, WellLayout } from './experiment-data';
import { PlateMaxColorHSL, PlateMinColorHSL, PlateVisualModel, PlateWellColoring } from './plate/PlateVisual';
import { getWellCoords, getWellIndexLabel } from './plate/utils';
import { downloadFile } from '../../lib/util/downloadFile';

const SmilesRowHeightFactor = 2.5;
const rowHeight = DefaultRowHeight;

export function HTEDetailsTableSchema(drawer: AsyncMoleculeDrawer): ColumnsFor<any> {
    return {
        smiles: SmilesColumn(drawer, SmilesRowHeightFactor, {
            width: 150,
            identifierPadding: 18,
            getIdentifierElement: ({ rowIndex, table }) => {
                const identifier = table.store.getValue('identifier', rowIndex);
                if (!identifier) return '-';
                return <HoverBatchLink identifier={identifier} withQuery />;
            },
        }),
        identifier: Column.str(),
        well: Column.create<[label: string, row: number, col: number]>({
            kind: 'obj',
            noHeaderTooltip: true,
            label: 'Well',
            width: 65,
            compare: (a, b) => {
                if (a[2] === b[2]) return a[1] - b[1];
                return a[2] - b[2];
            },
            alwaysVisible: true,
            csvHeader: 'Well Location',
            format: () => '<unused>',
            csvFormat: (v) => v[0],
            render: ({ value }) => value[0],
        }),
        '% purity': Column.float(),
    };
}

export interface PlateOption {
    label: string;
    plate: PlateRow;
}

export class HTEDetailsModel {
    plateCache = new Map<number, HTEPlateDetailsWrapper>();
    plateOptions: PlateOption[] = [];

    state = {
        tab: new BehaviorSubject<string>('plate'),
        currentPlate: new BehaviorSubject<PlateOption | undefined>(undefined),
        details: new BehaviorSubject<HTEPlateDetailsWrapper | undefined>(undefined),
        experiment: new BehaviorSubject<HTEExperimentInfo | undefined>(undefined),
    };

    async analyticalExport() {
        try {
            const id = this.info.experiment.id;
            const csv = await HTEApi.analyticalExportCSV(id);
            downloadFile({ data: new Blob([csv]), filename: `hte-${id}-analytical-export.csv` });
        } catch (e) {
            reportErrorAsToast('Analytical Export', e);
            throw e;
        }
    }

    async loadPlate(current: PlateOption) {
        if (this.plateCache.has(current.plate.id)) {
            this.state.details.next(this.plateCache.get(current.plate.id));
            this.state.currentPlate.next(current);
            return;
        }

        try {
            this.state.details.next(undefined);

            const details = await HTEApi.detailsPlate({
                plate_id: current.plate.id,
            });

            const model = new HTEPlateDetailsWrapper(details);
            this.plateCache.set(current.plate.id, model);
            this.state.details.next(model);
            this.state.currentPlate.next(current);
        } catch (err) {
            reportErrorAsToast('Error loading details', err);
        }
    }

    dispose() {
        this.plateCache.forEach((p) => p.dispose());
    }

    constructor(public info: HTEDetailsInfo) {
        this.state.experiment.next(info.experiment);

        const plates = columnDataTableStore(info.plates).toObjects();

        let defaultPlate: PlateOption | undefined;
        for (const plate of plates) {
            let purpose = plate.purpose;
            if (plate.id === info.experiment.crude_product_plate_id) {
                purpose = plate.purpose ?? 'Crude Product Plate';
            } else if (plate.id === info.experiment.purified_product_plate_id) {
                purpose = plate.purpose ?? 'Purified Product Plate';
            }

            this.plateOptions.push({
                label: `${plate.barcode}: ${purpose ?? ''}${plate.description ? ` (${plate.description})` : ''}`,
                plate,
            });

            if (purpose?.toLowerCase().includes('purified') && !defaultPlate) {
                defaultPlate = this.plateOptions[this.plateOptions.length - 1];
            }
        }

        defaultPlate = defaultPlate ?? this.plateOptions[0];

        if (defaultPlate) {
            this.loadPlate(defaultPlate);
        }
    }
}

export function AssayArtifactColumn(): Column<AssayArtifactValue, {}> {
    return {
        ...Column.obj({
            format: (v) => {
                if (v === undefined || v === null || Number.isNaN(v)) return '';
                const formatted = assayValueTypeToString(v?.value);
                if (v['uv %']) {
                    if (v['tic %']) return `${formatted} UV${v['uv %']}% TIC${v['tic %']}%`;
                    return `${formatted} UV${v['uv %']}%`;
                }
                if (v['tic %']) return `${formatted} TIC${v['tic %']}%`;
                return formatted;
            },
            compare: (a, b) => {
                const undefA = a === undefined || a === null;
                const undefB = b === undefined || b === null;
                if (undefA && undefB) return 0;
                if (undefA) return 1;
                if (undefB) return -1;

                return assayValueTypeCompare(a.value, b.value);
            },
        }),
        render: ({ value, rowIndex, columnName, table }) => (
            <ArtifactValue value={value} rowIndex={rowIndex} columnName={columnName} table={table} />
        ),
        width: 150,
    };
}

const FalseColorHSL = rgb2hsl([234, 134, 143]);
const TrueColorHSL = rgb2hsl([25, 135, 84]);

const NoColoringLabel = 'No Coloring';

export class HTEPlateDetailsWrapper extends ReactiveModel {
    readonly table: DataTableModel<any, any>;

    readonly smiles = {
        showDrawings: new BehaviorSubject<boolean>(true),
        moleculeDrawer: new AsyncMoleculeDrawer(),
    };

    state = {
        colorBy: new BehaviorSubject<string>(NoColoringLabel),
    };

    readonly batches = new ExperimentBatchManager();
    readonly visual = new PlateVisualModel(this.details.plate.samples.length as WellLayout, {
        disableMultiselect: true,
        singleSelect: true,
    });
    readonly coloringOptions = [NoColoringLabel];

    findReaction(wellIndex: number) {
        if (wellIndex < 0) return;

        const sample = this.details.plate.samples[wellIndex];
        const reaction = this.details.reactions.find((r) => r.batch_id === sample?.batch_id);
        if (!reaction) return;

        const msdBatchId = reaction.msd_sample?.batch_id ?? reaction.msd_batch_id;
        const bbBatchId = reaction.bb_sample?.batch_id ?? reaction.bb_batch_id;

        return {
            product: this.batches.getBatchFromId(reaction.batch_id),
            msd: typeof msdBatchId === 'number' ? this.batches.getBatchFromId(msdBatchId) : undefined,
            bb: typeof bbBatchId === 'number' ? this.batches.getBatchFromId(bbBatchId) : undefined,
            reactants: reaction.reactants.map((r) => this.batches.getBatchFromId(r.batch_id)!).filter((r) => !!r),
        };
    }

    syncColor() {
        const { details } = this;
        const layout = this.details.plate.samples.length as WellLayout;
        const colors: (string | string[])[] = [];
        const colorBy = this.state.colorBy.value;

        if (!colorBy || colorBy === NoColoringLabel) {
            const batchSampleKey = new Map<string, number>();
            for (let i = 0; i < layout; i++) {
                const sample = details.plate.samples[i];
                if (sample) {
                    const key = `${sample.batch_id}#${sample.sample_number}`;
                    if (!batchSampleKey.has(key)) {
                        batchSampleKey.set(key, batchSampleKey.size);
                    }
                }
            }

            const sizeDenom = batchSampleKey.size - 1 || 1;

            for (let i = 0; i < layout; i++) {
                const sample = details.plate.samples[i];
                if (sample) {
                    const idx = batchSampleKey.get(`${sample.batch_id}#${sample.sample_number}`) || 0;
                    const color = interpolateHSLtoRGB(PlateMinColorHSL, PlateMaxColorHSL, idx / sizeDenom);
                    colors.push(toRGBString(color));
                } else {
                    colors.push(PlateWellColoring.NoColor);
                }
            }
        } else {
            const allData = this.table.store.toObjects({ columns: ['identifier', colorBy] });

            const data: any[] = [];

            let minColor = PlateMinColorHSL;
            let maxColor = PlateMaxColorHSL;

            for (const row of allData) {
                let v = row[colorBy];
                if (Number.isNaN(v) || v === undefined || v === null) continue;
                if ((v as AssayArtifactValue).artifacts) v = v.value;

                if (typeof v === 'boolean') {
                    minColor = FalseColorHSL;
                    maxColor = TrueColorHSL;
                    v = +v;
                } else {
                    v = tryGetAssayValueGuess(v);
                }

                if (v !== undefined) {
                    row.batch_id = this.batches.getBatch(row.identifier)?.id;
                    row[colorBy] = v;
                    data.push(row);
                }
            }

            let min = Number.MAX_VALUE;
            let max = Number.MIN_VALUE;

            for (const row of data) {
                const v = row[colorBy];
                if (v < min) min = v;
                if (v > max) max = v;
            }

            const groups = groupBy(data, (v) => v.batch_id);

            let delta = max - min;
            if (!delta) delta = 1;

            for (let i = 0; i < layout; i++) {
                const sample = details.plate.samples[i];
                if (sample) {
                    const group = groups.get(sample.batch_id);
                    if (!group) {
                        colors.push(PlateWellColoring.NonEmptyColor);
                    } else if (group.length === 1) {
                        const color = interpolateHSLtoRGB(minColor, maxColor, (group[0][colorBy] - min) / delta);
                        colors.push(toRGBString(color));
                    } else {
                        const wellColors: string[] = [];
                        for (const g of group) {
                            const color = toRGBString(
                                interpolateHSLtoRGB(minColor, maxColor, (g[colorBy] - min) / delta)
                            );
                            wellColors.push(color);
                        }
                        colors.push(wellColors);
                    }
                } else {
                    colors.push(PlateWellColoring.NoColor);
                }
            }
        }
        this.visual.state.colors.next(colors);
    }

    highlightBatchIdentifier(identifier?: string) {
        if (!identifier) {
            this.visual.state.highlight.next([]);
            return;
        }

        const id = this.batches.getBatch(identifier)?.id;
        if (typeof id !== 'number') {
            this.visual.state.highlight.next([]);
            return;
        }

        const { details } = this;
        const layout = this.details.plate.samples.length as WellLayout;
        const highlight: number[] = [];

        for (let i = 0; i < layout; i++) {
            const sample = details.plate.samples[i];
            if (sample) {
                if (sample.batch_id === id) highlight.push(i);
            }
        }

        this.visual.state.highlight.next(highlight);
    }

    private noWell = ['', -1, -1];
    private getWellInfo(identifier: string | undefined) {
        const batch = this.batches.getBatch(identifier);
        if (!batch) return this.noWell;
        const wellIndex = this.details.plate.samples.findIndex((s) => s?.batch_id === batch.id);
        if (wellIndex < 0) return this.noWell;

        const [w] = PlateDimensions[this.details.plate.size];
        const [row, col] = getWellCoords(w, wellIndex);
        const label = getWellIndexLabel(this.details.plate.size, wellIndex);
        return [label, row, col];
    }

    constructor(public details: HTEPlateDetailsData) {
        super();

        const data = details.assay_values;

        this.batches.addBatches(details.batches);

        const initialData: ColumnTableData<any, number> = {
            columns: ['smiles', 'identifier', 'well', '% purity'],
            data: [
                data.index.map((id) => this.batches.getSmiles(id)),
                data.index,
                data.index.map((id) => this.getWellInfo(id)),
                data.index.map((id) => this.batches.getBatch(id)?.submitted_purity),
            ],
            index: data.index.map((_, i) => i), // can't handle duplicated values in indices here.
        };
        const store: DataTableStore<any> = columnDataTableStore(initialData);
        const drawer = new AsyncMoleculeDrawer();
        this.table = new DataTableModel(store, {
            columns: HTEDetailsTableSchema(drawer),
            hideNonSchemaColumns: false,
            rowHeight: SmilesRowHeightFactor * rowHeight,
            customState: {
                'show-smiles': true,
                'show-assay-links': true,
            },
            globalFilterHiddenColumns: true,
        });

        this.table.setHiddenColumns(['identifier']);

        this.table.setColumnStickiness('smiles', true);

        this.table.addOrUpdateColumns(
            details.artifact_assays.map((id) => [
                details.assay_shorthands[id],
                data.data[data.columns.indexOf(id)],
                AssayArtifactColumn(),
            ])
        );

        for (const assay of details.artifact_assays) {
            this.coloringOptions.push(details.assay_shorthands[assay]);
        }

        const withArtifact = new Set(details.artifact_assays);

        const noArtifact = data.columns.filter((id) => typeof id === 'number' && !withArtifact.has(id));

        this.table.addOrUpdateColumns(
            noArtifact.map((id) => [
                details.assay_shorthands[id],
                data.data[data.columns.indexOf(id)],
                AssayValueTypeColumn({ columnName: details.assay_shorthands[id] }),
            ])
        );

        for (const assay of noArtifact) {
            this.coloringOptions.push(details.assay_shorthands[assay]);
        }

        this.subscribe(this.state.colorBy, () => this.syncColor());
    }
}

function ArtifactValue({
    value: _value,
    rowIndex,
    columnName,
    table,
}: {
    value: AssayArtifactValue;
    rowIndex: number;
    columnName: string;
    table: DataTableModel<any>;
}) {
    const { value, artifacts } = _value;
    const uv = _value['uv %'];
    const tic = _value['tic %'];

    let assayLink: string = '';
    if (value !== null && value !== undefined) {
        const identifier: string = table.store.columnNames.includes('identifier')
            ? table.store.getValue('identifier', rowIndex)
            : '';
        assayLink = getLinkToAssayFromCompound(identifier, columnName);
    }

    let valueEl;
    if (typeof value === 'boolean') {
        let inner_;
        if (uv) {
            if (tic) {
                inner_ = `${value ? 'True' : 'False'} UV${_value['uv %']}% TIC${_value['tic %']}%`;
            } else {
                inner_ = `${value ? 'True' : 'False'} UV${_value['uv %']}%`;
            }
        } else if (tic) {
            inner_ = `${value ? 'True' : 'False'} TIC${_value['tic %']}%`;
        } else {
            const valueString = `${value}`;
            inner_ = `${valueString.charAt(0).toUpperCase()}${valueString.slice(1)}`;
        }
        valueEl = <BooleanBadge value={value} inner={inner_} />;
    } else if (uv) {
        if (tic) {
            valueEl = `${assayValueTypeToString(value)} UV${uv}% TIC${tic}%`;
        } else {
            valueEl = `${assayValueTypeToString(value)} UV${uv}%`;
        }
    } else if (tic) {
        valueEl = `${assayValueTypeToString(value)} TIC${tic}%`;
    } else {
        valueEl = assayValueTypeToString(value);
    }

    return (
        <HoverLink
            href={assayLink}
            fill
            hoverClass='hte-artifact-cell-hover'
            content={valueEl}
            endContent={<>{artifacts && <DownloadArtifactsCell artifacts={artifacts} size='sm' />}</>}
        />
    );
}
