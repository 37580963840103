import { IconName, IconDefinition } from '@fortawesome/fontawesome-svg-core';

// This is a way to make a new icon to be treated like icons in the
// Font Awesome set - structure kind of copied from their icon definitions.
// see node_modules/@fortawesome/node_modules/@fortawesome/fontawesome-common-types/index.d.ts
// for the IconDefinition type definition

const iconName = 'ii-chemdraw-logo' as IconName;
const prefix = 'fas';
const width: number = 14;
const height: number = 16;
const aliases: string[] = [];
const unicode: string = '';
const svgPathData: string = [
    'M13.8606 12.0055L13.0054 11.5111V4.49603L13.8606 4.00165V12.0055Z',
    'M11.2859 10.5263V5.48664Z',
    'M13.871 4.00087L13.0157 4.49524L6.94037 0.98666V0L13.871 4.00087Z',
    'M6.93165 0V0.98666L0.855245 4.49524L0 4.00087L6.93165 0Z',
    'M6.92948 2.95435L2.56356 5.47523Z',
    'M0 4.00087L0.855245 4.49524L0.855245 11.5111L0 12.0055L0 4.00087Z',
    'M0 11.9991L0.855245 11.5048L6.93165 15.0133V16L0 11.9991Z',
    'M2.56356 10.52L6.92948 13.0409Z',
    'M6.94037 16V15.0133L13.0157 11.5048L13.871 11.9991L6.94037 16Z',
    'M4.81771 9.57713C4.38707 9.57713 4.05482 9.47161 3.82097 9.26057C3.58711 9.0481 3.47018 8.74438 3.47018 8.34939C3.47018 8.03711 3.52152 7.75406 3.62419 7.50024C3.72828 7.24642 3.87088 7.02682 4.05197 6.84145C4.22879 6.66178 4.44054 6.52132 4.68723 6.42008C4.93535 6.31884 5.19558 6.26822 5.46794 6.26822C5.68753 6.26822 5.89144 6.29103 6.07967 6.33666C6.26789 6.38087 6.43758 6.44646 6.58874 6.53344L6.40051 7.30559H6.3064C6.17093 7.16015 6.03618 7.0532 5.90214 6.98476C5.7681 6.91631 5.61053 6.88209 5.42944 6.88209C5.11145 6.88209 4.84908 7.00971 4.64231 7.26496C4.43698 7.5202 4.33431 7.84532 4.33431 8.2403C4.33431 8.49412 4.39135 8.67807 4.50542 8.79215C4.6195 8.90622 4.78633 8.96326 5.00593 8.96326C5.20271 8.96326 5.38737 8.92405 5.55991 8.84562C5.73245 8.76577 5.89572 8.66453 6.04973 8.54189H6.13528L5.9492 9.30335C5.88503 9.32902 5.81017 9.36039 5.72461 9.39746C5.63905 9.43311 5.55706 9.46234 5.47863 9.48516C5.37596 9.5151 5.27971 9.53792 5.18988 9.5536C5.10147 9.56929 4.97741 9.57713 4.81771 9.57713Z',
    'M9.93349 7.57361C9.93349 7.93437 9.83866 8.26733 9.64902 8.57248C9.45936 8.87764 9.21624 9.11149 8.91964 9.27405C8.73997 9.37102 8.55246 9.4359 8.35711 9.46869C8.16175 9.50006 7.92789 9.51575 7.65554 9.51575H6.50266L7.23845 6.33089H8.26941C8.57313 6.3309 8.81769 6.35442 9.00306 6.40148C9.18843 6.44854 9.33816 6.51199 9.45223 6.59184C9.60908 6.70022 9.72815 6.83568 9.80943 6.99824C9.89214 7.15937 9.93349 7.35116 9.93349 7.57361ZM9.07364 7.64847C9.07364 7.50302 9.04655 7.37825 8.99237 7.27416C8.93818 7.16864 8.85547 7.0838 8.74425 7.01963C8.69291 6.99111 8.64015 6.96972 8.58597 6.95546C8.53321 6.93977 8.4726 6.92837 8.40416 6.92124C8.33857 6.91553 8.2637 6.91268 8.17957 6.91268C8.09544 6.91268 8.02201 6.91268 7.95927 6.91268H7.93146L7.4609 8.93183H7.53576C7.61846 8.93183 7.70687 8.93183 7.80098 8.93183C7.8951 8.93183 7.97138 8.92826 8.02985 8.92113C8.10542 8.914 8.17316 8.9026 8.23305 8.88691C8.29436 8.87123 8.36138 8.84484 8.43411 8.80777C8.63944 8.70225 8.79701 8.54468 8.90681 8.33507C9.01803 8.12545 9.07364 7.89659 9.07364 7.64847Z',
    'M2.37135 5.09846L6.75076 2.57001L7.19539 3.34013L2.81598 5.86858L2.37135 5.09846Z',
    'M2.81598 10.0782L7.19539 12.6067L6.75076 13.3768L2.37135 10.8484L2.81598 10.0782Z',
    'M10.86 10.5524V5.49549H11.7492V10.5524H10.86Z',
].join('');

export const iiChemDrawLogo: IconDefinition = {
    prefix,
    iconName,
    icon: [width, height, aliases, unicode, svgPathData],
};
