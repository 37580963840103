// source: Envision
// https://github.com/EntosAI/envision/blob/master/envision/src/hooks/use-behavior-subject.ts
import { useRef } from 'react';
import { BehaviorSubject } from 'rxjs';

export default function useBehaviorSubject<T>(initialValue: T) {
    const subject = useRef<BehaviorSubject<T>>();
    if (!subject.current) {
        subject.current = new BehaviorSubject(initialValue);
    }
    return subject.current;
}
