export interface EntrySummary {
    numErrors: number;
    numWarnings: number;
    numReady: number;
}

export function EntrySummaryComponent({ summary, kind }: { summary: EntrySummary | undefined; kind: string }) {
    if (!summary) return null;

    const { numReady, numWarnings, numErrors } = summary;

    return (
        <div className='flex-grow-1 text-secondary'>
            {`${numReady} ${kind}${numReady !== 1 ? 's' : ''} ready`}
            {numWarnings > 0 && (
                <>
                    ,
                    <span className='text-warning text-bold'>
                        {` ${numWarnings} warning${numWarnings > 1 ? 's' : ''}`}
                    </span>
                </>
            )}
            {numErrors > 0 && (
                <>
                    ,<span className='text-danger text-bold'>{` ${numErrors} error${numErrors > 1 ? 's' : ''}`}</span>
                </>
            )}
        </div>
    );
}
