import {
    IconDefinition,
    faAnglesUp,
    faCheck,
    faEraser,
    faExclamationCircle,
    faExclamationTriangle,
    faEyeDropper,
    faFileImport,
    faGrip,
    faHand,
    faPencil,
    faRightLeft,
    faTrash,
    faVial,
    faWater,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Split from 'react-split-it';
import { UploadFileButton } from '../../../components/common/FileUpload/upload-button';
import { IconButton } from '../../../components/common/IconButton';
import { InlineLabeledInput, SimpleSelectOptionInput, TextInput } from '../../../components/common/Inputs';
import { ProtocolText } from '../../../components/common/ProtocolText';
import { ScrollBox } from '../../../components/common/ScrollBox';
import useBehavior from '../../../lib/hooks/useBehavior';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { capitalizeFirst } from '../../../lib/util/misc';
import { asNumberOrNull } from '../../../lib/util/validators';
import { PlateVisual } from '../../HTE/plate/PlateVisual';
import { getFirstSelectedIndex } from '../../HTE/plate/utils';
import {
    HTEISampleLocation,
    HTEIVialRack,
    HTEPCompilerMessage,
    HTEPReagentUse,
    HTEPReservoirInfo,
    HTEPTransferMethod,
    HTEPWorklistKeyT,
    HTERInstructionT,
} from '../data-model';
import { HTE2MSModel } from '../model';
import { Formatters, HTEPanel, ReactantKindToIcon, Unset } from '../utils';
import { transfersToCSV } from '../utils/picklist';
import { CrudePlateTab, HTE2MSCrudePlateModel } from './crude-plate';
import { HTE2MSProductionModel } from './model';
import { InlineAlert } from '../../../components/common/Alert';

export function HTE2MSCrudePlateUI({ model }: { model: HTE2MSModel }) {
    const { production } = model;
    useMountedModel(production);
    useBehavior(model.state.readOnlyDesignAndProduction);

    return (
        <Split direction='horizontal' gutterSize={6} sizes={[1 / 2, 1 / 2]}>
            <CrudePlate model={production} />
            <ControlPanels model={production} />
        </Split>
    );
}

function CrudePlate({ model }: { model: HTE2MSProductionModel }) {
    useMountedModel(model.crudePlate);
    useBehavior(model.crudePlate.state.plate);

    const hasPlate = typeof model.model.experiment?.crude_product_plate_id === 'number';

    return (
        <HTEPanel
            title='Protocols & Crude Plate'
            controls={
                <>
                    {hasPlate && (
                        <div className='text-success font-body-small'>
                            <FontAwesomeIcon icon={faCheck} fixedWidth size='sm' className='me-2' />
                            Crude Plate Uploaded
                        </div>
                    )}
                    {!hasPlate && (
                        <IconButton
                            icon={faAnglesUp}
                            size='sm'
                            variant='link'
                            onClick={model.crudePlate.confirmUploadPlate}
                        >
                            Upload Crude Plate
                        </IconButton>
                    )}
                </>
            }
            noBorder
        >
            <div className='d-flex h-100 flex-column'>
                <LayoutAndProtocols model={model.crudePlate} />
                <div className='position-relative border-top' style={{ minHeight: 200, maxHeight: 200 }}>
                    <ErrorsAndWarnings model={model} />
                </div>
            </div>
        </HTEPanel>
    );
}

function LayoutAndProtocols({ model }: { model: HTE2MSCrudePlateModel }) {
    const tab = useBehavior(model.state.currentTab);

    return (
        <div className='d-flex h-100 flex-column'>
            <div className='mx-2 hte2ms-inline-tab-nav-buttons font-body-xsmall'>
                <LayoutTabButton current={tab} model={model} layout />
                {model.data.worklists.map(({ key }) => (
                    <Fragment key={key}>
                        <LayoutTabButton current={tab} model={model} worklistKey={key} protocol='OT-2' />
                        <LayoutTabButton current={tab} model={model} worklistKey={key} protocol='Picklists' />
                    </Fragment>
                ))}
            </div>
            <div className='flex-grow-1 position-relative d-flex'>
                {tab === 'layout' && <CrudePlateLayout model={model} />}
                {tab !== 'layout' && tab[1] === 'OT-2' && <OT2ProtocolTab model={model} worklistKey={tab[0]} />}
                {tab !== 'layout' && tab[1] === 'Picklists' && <PicklistsTab model={model} worklistKey={tab[0]} />}
            </div>
        </div>
    );
}

function LayoutTabButton({
    model,
    layout,
    worklistKey,
    protocol,
    current,
}: {
    model: HTE2MSCrudePlateModel;
    layout?: boolean;
    worklistKey?: HTEPWorklistKeyT;
    protocol?: 'OT-2' | 'Picklists';
    current: CrudePlateTab;
}) {
    const isCurrent = layout ? current === 'layout' : current[0] === worklistKey && current[1] === protocol;
    const worklistTitle = worklistKey
        ? model.production.model.protocol.worklistMap.get(worklistKey)?.title ?? worklistKey
        : undefined;
    return (
        <Button
            onClick={() => !isCurrent && model.state.currentTab.next(layout ? 'layout' : [worklistKey!, protocol!])}
            variant={isCurrent ? 'primary' : 'secondary'}
            size='sm'
        >
            {layout && 'Layout'}
            {!!worklistTitle && `${worklistTitle} (${protocol})`}
        </Button>
    );
}

function OT2ProtocolTab({ model, worklistKey }: { model: HTE2MSCrudePlateModel; worklistKey: HTEPWorklistKeyT }) {
    const protocol = model.data.worklists?.find((wl) => wl.key === worklistKey)?.ot2_protocol ?? '';
    return (
        <ProtocolText
            value={protocol}
            className='m-2 flex-grow-1'
            save={() =>
                model.saveProtocol({
                    protocol,
                    kind: 'OT-2',
                })
            }
        />
    );
}

function PicklistsTab({ model, worklistKey }: { model: HTE2MSCrudePlateModel; worklistKey: HTEPWorklistKeyT }) {
    const picklists = model.data.worklists?.find((wl) => wl.key === worklistKey)?.picklists ?? [];
    const [selection, setSelection] = useState<string[]>([]);
    const filtered = picklists.filter((p) => p.transfers.length);

    const protocol = useMemo(() => {
        const xfers = picklists.filter((p) => selection.includes(p.title)).flatMap((p) => p.transfers);
        return transfersToCSV(model.production.model, xfers);
    }, [picklists, selection]);

    useEffect(() => {
        setSelection(filtered.map((p) => p.title));
    }, [picklists]);

    return (
        <>
            <ProtocolText
                value={protocol}
                className='m-2 flex-grow-1'
                save={() =>
                    model.saveProtocol({
                        protocol,
                        kind: 'Picklists',
                    })
                }
            />
            <div className='position-absolute font-body-small end-0 bottom-0 me-3 mb-3 alert alert-secondary p-2 vstack'>
                <ScrollBox maxHeight={100}>
                    <Form.Switch
                        label='[All]'
                        checked={selection.length === filtered.length}
                        onChange={() =>
                            setSelection((s) => (s.length === filtered.length ? [] : filtered.map((p) => p.title)))
                        }
                        id='select-all'
                    />
                    {filtered.map((p) => (
                        <Form.Switch
                            label={p.title}
                            checked={selection.includes(p.title)}
                            onChange={() =>
                                setSelection((s) =>
                                    s.includes(p.title) ? s.filter((t) => t !== p.title) : [...s, p.title]
                                )
                            }
                            id={`select-${p.title}`}
                            key={p.title}
                        />
                    ))}
                </ScrollBox>
            </div>
        </>
    );
}

function CrudePlateLayout({ model }: { model: HTE2MSCrudePlateModel }) {
    const visual = useBehavior(model.state.plateVisual);
    return (
        <div className='d-flex w-100'>
            <div className='flex-grow-1 position-relative m-2 ms-0'>
                <PlateVisual model={visual} />
            </div>
            <div className='flex-shrink-0' style={{ maxWidth: 230, minWidth: 230 }}>
                <WellContents model={model} />
            </div>
        </div>
    );
}

function formatDetailedMessage(message: HTEPCompilerMessage) {
    if (message.detail) return `${message.message}: ${message.detail}`;
    return message.message;
}

function WellContents({ model }: { model: HTE2MSCrudePlateModel }) {
    const selection = useBehavior(model.plateVisual.state.selection);
    const wI = getFirstSelectedIndex(selection);
    const reaction = model.wellToReaction.get(wI);
    const errors = model.plateInfo.errorsByReaction.get(reaction?.id!);
    const warnings = model.plateInfo.warningsByReaction.get(reaction?.id!);

    const viewInDesign = (
        <IconButton
            icon={faPencil}
            size='sm'
            className='py-0'
            disabled={!reaction}
            title='View in Design'
            onClick={() => model.production.model.showReactionDesign([reaction!.id], { onlySelected: true })}
        />
    );

    if (!reaction) return <HTEPanel title='Reaction' controls={viewInDesign} className='mt-4 pe-2' />;

    const generalErrors = errors?.filter((e) => !e.use && !e.solution).map((e) => formatDetailedMessage(e.message));
    const generalWarnings = warnings?.filter((e) => !e.use && !e.solution).map((e) => formatDetailedMessage(e.message));
    const { instructionIdToUse } = model.production.model.protocol.reagents;

    return (
        <HTEPanel title='Reaction' controls={viewInDesign} className='pt-4 pe-2'>
            <ScrollBox>
                <div className='py-2 font-body-small vstack gap-2'>
                    {!!generalErrors?.length && <MessageList messages={generalErrors} kind='danger' inline />}
                    {!!generalWarnings?.length && <MessageList messages={generalWarnings} kind='warning' inline />}
                    {reaction.template.instructions.map((instruction, i) => {
                        const useErrors =
                            instruction.kind === 'add'
                                ? errors
                                      ?.filter(
                                          (e) =>
                                              e.use?.instruction_id === instruction.id ||
                                              (e.solution?.solution_id === instruction.solution_id &&
                                                  e.solution?.uses.some((u) => u.reaction_id === reaction.id))
                                      )
                                      .map((e) => formatDetailedMessage(e.message))
                                : undefined;
                        const useWarnings =
                            instruction.kind === 'add'
                                ? warnings
                                      ?.filter((e) => e.use?.instruction_id === instruction.id)
                                      .map((e) => formatDetailedMessage(e.message))
                                : undefined;

                        let icon;
                        if (useErrors?.length) icon = faExclamationCircle;
                        else if (useWarnings?.length) icon = faExclamationTriangle;
                        else if (instruction.kind !== 'add') icon = undefined;
                        else if (instruction.manual_handling) icon = faHand;
                        else icon = faCheck;

                        const use = instruction.kind === 'add' ? instructionIdToUse.get(instruction.id!) : undefined;

                        return (
                            <div key={i}>
                                <InstructionBadge
                                    model={model.production.model}
                                    instruction={instruction}
                                    use={use}
                                    icon={icon}
                                />
                                {!!useErrors?.length && (
                                    <MessageList messages={useErrors} kind='danger' className='ms-1' inline />
                                )}
                                {!!useWarnings?.length && (
                                    <MessageList messages={useWarnings} kind='warning' className='ms-1' inline />
                                )}
                            </div>
                        );
                    })}
                </div>
            </ScrollBox>
        </HTEPanel>
    );
}

function InstructionBadge({
    model,
    instruction,
    use,
    icon,
}: {
    model: HTE2MSModel;
    instruction: HTERInstructionT;
    use?: HTEPReagentUse;
    icon?: IconDefinition;
}) {
    let className = `hte2ms-instruction-badge hte2ms-instruction-bg-${instruction.kind}`;
    if (instruction.kind === 'add') {
        className += ` hte2ms-reactant-bg-${instruction.reactant_kind?.toLowerCase()}`;
    }

    let label: string | undefined = '';
    if (instruction.kind === 'add') {
        if (!!use && typeof instruction.concentration === 'number') {
            label += ` ${Formatters.volumeInL(use.n_mols / instruction.concentration)}`;
        }
        label += ` ${instruction.reactant_kind.toUpperCase()} ${
            model.assets.entities.getIdentifier(instruction.identifier!) || Unset
        }`;
    } else if (instruction.kind === 'pause') {
        label = ` ${Formatters.pauseDuration(instruction.duration)}`;
    } else if (instruction.kind === 'cook') {
        label = ` ${Formatters.cookDuration(instruction.duration)} @ ${Formatters.cookTemperature(
            instruction.temperature
        )}`;
    } else if (instruction.kind === 'evaporate') {
        label = ` Evaporate`;
    }

    return (
        <div className={className}>
            <FontAwesomeIcon
                icon={icon ?? ReactantKindToIcon[instruction.kind]}
                fixedWidth
                size='sm'
                className='me-1'
            />
            {label}
        </div>
    );
}

function ErrorsAndWarnings({ model }: { model: HTE2MSProductionModel }) {
    const info = model.crudePlate.plateInfo;

    return (
        <div className='d-flex h-100'>
            <HTEPanel title='Errors' slim>
                <MessageList messages={info.errors} kind='danger' />
            </HTEPanel>
            <HTEPanel title='Warnigs' className='border-start' slim>
                <MessageList messages={info.warnings} kind='warning' />
            </HTEPanel>
        </div>
    );
}

function MessageList({
    messages,
    kind,
    className,
    inline,
}: {
    messages: string[];
    kind: 'danger' | 'warning';
    className?: string;
    inline?: boolean;
}) {
    if (messages.length === 0) return <div className='text-secondary p-2 font-body-small'>None</div>;

    const inner = (
        <ul className={`text-danger font-body-small ps-3 m-0 ${className || ''}`}>
            {messages.map((e, i) => (
                <li key={i} className={`text-${kind}`}>
                    {e}
                </li>
            ))}
        </ul>
    );
    if (inline) return inner;
    return <ScrollBox className='p-2'>{inner}</ScrollBox>;
}

function ControlPanels({ model }: { model: HTE2MSProductionModel }) {
    useBehavior(model.model.protocol.state.protocol);
    useBehavior(model.state.production);

    return (
        <div className='d-flex h-100'>
            <HTEPanel title='Racks' className='flex-grow-1 border-end'>
                <RackControls model={model} />
            </HTEPanel>
            <HTEPanel title='Solvents & Reservoirs' className='flex-grow-1 border-end'>
                <SolventAndReservoirControls model={model} />
            </HTEPanel>
            <HTEPanel title='Methods' className='flex-grow-1'>
                <MethodsControls model={model} />
            </HTEPanel>
        </div>
    );
}

function RackControls({ model }: { model: HTE2MSProductionModel }) {
    const worklists = model.worklistInfo;
    const readOnly = model.model.readOnlyDesignAndProduction;

    return (
        <ScrollBox>
            <div className='vstack gap-2 p-2 font-body-small'>
                <div className='hstack'>
                    <h6 className='m-0'>Product</h6>
                    <div className='m-auto' />
                    <UploadFileButton
                        icon={faFileImport}
                        title='Load Rack'
                        onUpload={model.uploadCrudeRack}
                        uploadLabel='Upload a Crude Rack (one .csv)'
                        extensions={['.csv']}
                        className='py-0'
                        disabled={readOnly}
                    />
                </div>

                <ProductRack model={model} />

                {worklists.nonEmpty.map(({ key, title, backfillSolvents }) => (
                    <Fragment key={key}>
                        <div className='hstack mt-2'>
                            <h6 className='m-0'>{title}</h6>
                            <div className='m-auto' />
                            <UploadFileButton
                                icon={faFileImport}
                                title='Load Racks'
                                onUpload={(files) => model.uploadSourceRacks(key, files)}
                                uploadLabel='Upload a Source Racks (one or more .csv)'
                                extensions={['.csv']}
                                className='py-0'
                                disabled={readOnly}
                                multiple
                            />
                        </div>
                        {model.data.worklist_options[key]?.source_racks?.map((rack, i) => (
                            <SourceRack key={i} model={model} worklistKey={key} rack={rack} />
                        ))}
                        {!model.data.worklist_options[key]?.source_racks?.length && (
                            <div className='text-secondary'>No racks uploaded</div>
                        )}
                    </Fragment>
                ))}
            </div>
        </ScrollBox>
    );
}

function SolventAndReservoirControls({ model }: { model: HTE2MSProductionModel }) {
    const worklists = model.worklistInfo;

    return (
        <ScrollBox>
            <div className='vstack gap-4 p-2 font-body-small'>
                {worklists.nonEmpty.map(({ key, title, backfillSolvents }) => (
                    <div className='vstack gap-2' key={key}>
                        <div className='hstack'>
                            <h6 className='m-0'>{title}</h6>
                            <div className='m-auto' />
                        </div>
                        {backfillSolvents.map((solvent) => (
                            <BackfillSolvent key={solvent} model={model} worklistKey={key} solvent={solvent} />
                        ))}
                        {!backfillSolvents.length && (
                            <div className='text-secondary'>No backfill solvents required</div>
                        )}
                        <Reserviors model={model} worklistKey={key} />
                    </div>
                ))}
            </div>
        </ScrollBox>
    );
}

function MethodsControls({ model }: { model: HTE2MSProductionModel }) {
    const worklists = model.worklistInfo;

    return (
        <ScrollBox>
            <div className='vstack gap-2 p-2 font-body-small'>
                <OT2Instrument mount='left' model={model} />
                <OT2Instrument mount='right' model={model} />
                {worklists.solvents.map((solvent) => (
                    <SolventTransferMethods key={solvent} model={model} solvent={solvent} />
                ))}
                <GroupTransferMethods model={model} />
            </div>
        </ScrollBox>
    );
}

function ProductRack({ model }: { model: HTE2MSProductionModel }) {
    const { data: production } = model;
    const wells = Object.keys(production.crude_rack.wells ?? {}).length;
    const readOnly = model.model.readOnlyDesignAndProduction;

    return (
        <div className='alert alert-secondary vstack gap-1 p-2 m-0'>
            {wells === 0 && <span className='text-secondary'>Not scanned</span>}
            {wells > 0 && !!model.model.design.labware.product.no_transfer && (
                <InlineAlert variant='warning' iconTopLeft>
                    No transfer option on product labware is set, crude rack scan is ignored
                </InlineAlert>
            )}
            {wells > 0 && (
                <div className='hstack w-100'>
                    <span>
                        {wells} scanned well{wells === 1 ? '' : 's'}
                    </span>
                    <div className='m-auto' />
                    <IconButton
                        icon={faEraser}
                        size='sm'
                        title='Clear Wells'
                        className='py-0'
                        onClick={() =>
                            model.state.production.next({
                                ...production,
                                crude_rack: { ...production.crude_rack, wells: {} },
                            })
                        }
                        disabled={readOnly}
                    />
                </div>
            )}
            <InlineLabeledInput label='Label'>
                <TextInput
                    size='sm'
                    value={production.crude_rack.label ?? ''}
                    setValue={(v) =>
                        model.state.production.next({
                            ...production,
                            crude_rack: { ...production.crude_rack, label: v.trim() || 'Product' },
                        })
                    }
                    readOnly={readOnly}
                />
            </InlineLabeledInput>
            <InlineLabeledInput label='Labware'>{model.model.design.labware.product.label}</InlineLabeledInput>
            <InlineLabeledInput label='Position'>
                <TextInput
                    size='sm'
                    value={production.crude_rack.position ?? ''}
                    setValue={(v) =>
                        model.state.production.next({
                            ...production,
                            crude_rack: { ...production.crude_rack, position: v.trim() || undefined },
                        })
                    }
                    readOnly={readOnly}
                />
            </InlineLabeledInput>
        </div>
    );
}

function SourceRack({
    model,
    worklistKey,
    rack,
}: {
    model: HTE2MSProductionModel;
    worklistKey: HTEPWorklistKeyT;
    rack: HTEIVialRack;
}) {
    const wells = Object.keys(rack.wells ?? {}).length;
    const readOnly = model.model.readOnlyDesignAndProduction;

    return (
        <div className='alert alert-secondary vstack gap-1 p-2 m-0'>
            <div className='hstack w-100'>
                <h6 className='m-0'>
                    <FontAwesomeIcon className='me-2' icon={faGrip} size='sm' fixedWidth />
                    {rack.label}
                </h6>
                <div className='m-auto' />
                <IconButton
                    icon={faTrash}
                    size='sm'
                    title='Remove Rack'
                    className='py-0'
                    onClick={() => model.removeSourceRack(worklistKey, rack)}
                    disabled={readOnly}
                />
            </div>
            <span className='text-secondary'>
                {wells} assigned well{wells === 1 ? '' : 's'}
            </span>
            <InlineLabeledInput label='Labware'>
                <SimpleSelectOptionInput
                    value={rack.labware_id ?? ''}
                    size='sm'
                    allowEmpty
                    options={model.model.protocol.model.design.labwareOptions}
                    setValue={(v) => model.updateSourceRack(worklistKey, rack, { labware_id: v || undefined })}
                    disabled={readOnly}
                />
            </InlineLabeledInput>
            <InlineLabeledInput label='Position'>
                <TextInput
                    size='sm'
                    value={rack.position ?? ''}
                    setValue={(v) => model.updateSourceRack(worklistKey, rack, { position: v.trim() || undefined })}
                    readOnly={readOnly}
                />
            </InlineLabeledInput>
            <InlineLabeledInput label='Description'>
                <TextInput
                    size='sm'
                    value={rack?.description ?? ''}
                    setValue={(v) => model.updateSourceRack(worklistKey, rack, { description: v.trim() || undefined })}
                    readOnly={readOnly}
                />
            </InlineLabeledInput>
        </div>
    );
}

function BackfillSolvent({
    model,
    solvent,
    worklistKey,
}: {
    model: HTE2MSProductionModel;
    worklistKey: HTEPWorklistKeyT;
    solvent: string;
}) {
    const options: HTEISampleLocation | undefined = model.data.worklist_options?.[worklistKey]?.solvents?.[solvent];
    const readOnly = model.model.readOnlyDesignAndProduction;

    return (
        <div className='alert alert-secondary vstack gap-1 p-2 m-0'>
            <h6 className='m-0'>
                <FontAwesomeIcon className='me-2' icon={faVial} size='sm' fixedWidth />
                {solvent}
            </h6>
            <InlineLabeledInput label='Label'>
                <TextInput
                    size='sm'
                    value={options?.container_label ?? ''}
                    setValue={(v) =>
                        model.updateBackfillSolvent(worklistKey, solvent, { container_label: v.trim() || undefined })
                    }
                    readOnly={readOnly}
                />
            </InlineLabeledInput>
            <InlineLabeledInput label='Well'>
                <TextInput
                    size='sm'
                    value={options?.container_well ?? ''}
                    setValue={(v) =>
                        model.updateBackfillSolvent(worklistKey, solvent, { container_well: v.trim() || undefined })
                    }
                    readOnly={readOnly}
                />
            </InlineLabeledInput>
        </div>
    );
}

function Reserviors({ model, worklistKey }: { model: HTE2MSProductionModel; worklistKey: HTEPWorklistKeyT }) {
    const labels = model.getReservoirLabels(worklistKey);
    if (!labels.length) return <div className='text-secondary'>No reservoir referenced</div>;
    return (
        <>
            {labels.map((label) => (
                <Reservior key={label} model={model} reservoirLabel={label} worklistKey={worklistKey} />
            ))}
        </>
    );
}

function Reservior({
    model,
    reservoirLabel,
    worklistKey,
}: {
    model: HTE2MSProductionModel;
    worklistKey: HTEPWorklistKeyT;
    reservoirLabel: string;
}) {
    const options: HTEPReservoirInfo | undefined =
        model.data.worklist_options?.[worklistKey]?.reservoirs?.[reservoirLabel];
    const readOnly = model.model.readOnlyDesignAndProduction;

    return (
        <div className='alert alert-secondary vstack gap-1 p-2 m-0'>
            <h6 className='m-0'>
                <FontAwesomeIcon className='me-2' icon={faWater} size='sm' fixedWidth />
                {reservoirLabel}
            </h6>
            <InlineLabeledInput label='Labware'>
                <SimpleSelectOptionInput
                    value={options?.labware_id ?? ''}
                    size='sm'
                    allowEmpty
                    options={model.model.protocol.model.design.labwareOptions}
                    setValue={(v) => model.updateReservoir(worklistKey, reservoirLabel, { labware_id: v || undefined })}
                    disabled={readOnly}
                />
            </InlineLabeledInput>
            <InlineLabeledInput label='Position'>
                <TextInput
                    size='sm'
                    value={options?.position ?? ''}
                    setValue={(v) =>
                        model.updateReservoir(worklistKey, reservoirLabel, { position: v.trim() || undefined })
                    }
                    readOnly={readOnly}
                />
            </InlineLabeledInput>
            <InlineLabeledInput label='Description'>
                <TextInput
                    size='sm'
                    value={options?.description ?? ''}
                    setValue={(v) =>
                        model.updateReservoir(worklistKey, reservoirLabel, { description: v.trim() || undefined })
                    }
                    readOnly={readOnly}
                />
            </InlineLabeledInput>
        </div>
    );
}

function SolventTransferMethods({ model, solvent }: { model: HTE2MSProductionModel; solvent: string }) {
    const info = model.worklistInfo;
    const [current, setCurrent] = useState('<all>');
    const method =
        current === '<all>'
            ? model.data.transfer_methods[solvent] ?? {}
            : model.data.transfer_methods[solvent]?.by_kind?.[current] ?? ({} as HTEPTransferMethod);

    const options = useMemo(() => {
        const ret = [['<all>', '<All Reagents>']];
        for (const kind of info.reactantKinds) {
            ret.push([kind, kind.toUpperCase()]);
        }
        return ret as [string, string][];
    }, [info.reactantKinds.join('-')]);

    return (
        <div className='alert alert-secondary vstack gap-1 p-2 m-0'>
            <h6 className='m-0'>
                <FontAwesomeIcon className='me-2' icon={faRightLeft} size='sm' fixedWidth />
                {solvent}
            </h6>
            <SimpleSelectOptionInput value={current} size='sm' options={options} setValue={setCurrent} />
            {current && (
                <TransferMethod
                    model={model}
                    method={method}
                    update={(key, value) => {
                        model.updateMethod(solvent, current === '<all>' ? undefined : current, { [key]: value });
                    }}
                />
            )}
        </div>
    );
}

function GroupTransferMethods({ model }: { model: HTE2MSProductionModel }) {
    const info = model.worklistInfo;
    const defaultKey = info.groupOptions[0]?.[0];
    const [current, setCurrent] = useState(defaultKey);
    const group = info.groups[current] ?? info.groups[defaultKey];
    const method =
        model.data.worklist_options[group?.worklistKey]?.group_transfer_methods?.[group?.index] ??
        ({} as HTEPTransferMethod);

    useEffect(() => {
        if (current in info.groups) return;
        setCurrent(defaultKey);
    }, [current, info.groups, defaultKey]);

    if (info.groupOptions.length === 0) return null;

    return (
        <div className='alert alert-secondary vstack gap-1 p-2 m-0'>
            <h6 className='m-0'>
                <FontAwesomeIcon className='me-2' icon={faRightLeft} size='sm' fixedWidth />
                Transfer Groups
            </h6>
            <SimpleSelectOptionInput value={current} size='sm' options={info.groupOptions} setValue={setCurrent} />
            {current && (
                <TransferMethod
                    model={model}
                    method={method}
                    update={(key, value) => {
                        model.updateGroupMethod(group.worklistKey, group.index, { [key]: value });
                    }}
                    allowMultiChannel
                />
            )}
        </div>
    );
}

const TransferMethodOptions = [
    ['distribute', 'Distribute'],
    ['cherrypick', 'Cherrypick'],
] as const;

const OT2InstrumentOptions = [
    ['P300', 'P300'],
    ['P1000', 'P1000'],
] as const;

const OT2MultiInstrumentOptions = [
    ...OT2InstrumentOptions,
    ['P20Multi', 'P20Multi'],
    ['P300Multi', 'P300Multi'],
] as const;

function TransferMethod({
    model,
    method,
    update,
    allowMultiChannel,
}: {
    model: HTE2MSProductionModel;
    method: HTEPTransferMethod;
    update: (key: keyof HTEPTransferMethod, value: any) => void;
    allowMultiChannel?: boolean;
}) {
    const readOnly = model.model.readOnlyDesignAndProduction;

    return (
        <div className='vstack gap-1'>
            <InlineLabeledInput label='Method'>
                <SimpleSelectOptionInput
                    value={method.method ?? ''}
                    size='sm'
                    allowEmpty
                    options={TransferMethodOptions}
                    setValue={(v) => update('method', v || undefined)}
                    disabled={readOnly}
                />
            </InlineLabeledInput>
            <InlineLabeledInput label='Instrument'>
                <SimpleSelectOptionInput
                    value={method.ot2_instrument ?? ''}
                    size='sm'
                    allowEmpty
                    options={allowMultiChannel ? OT2MultiInstrumentOptions : OT2InstrumentOptions}
                    setValue={(v) => update('ot2_instrument', v || undefined)}
                    disabled={readOnly}
                />
            </InlineLabeledInput>
            <InlineLabeledInput label='Default Speed'>
                <TextInput
                    size='sm'
                    value={method?.ot2_default_speed}
                    tryUpdateValue={asNumberOrNull}
                    setValue={(v) => update('ot2_default_speed', v ?? undefined)}
                    readOnly={readOnly}
                />
            </InlineLabeledInput>
            <InlineLabeledInput label='Aspirate Rate'>
                <TextInput
                    size='sm'
                    value={method?.ot2_aspirate_rate}
                    tryUpdateValue={asNumberOrNull}
                    setValue={(v) => update('ot2_aspirate_rate', v ?? undefined)}
                    readOnly={readOnly}
                />
            </InlineLabeledInput>
            <InlineLabeledInput label='Air Gap'>
                <TextInput
                    size='sm'
                    value={method?.ot2_air_gap}
                    tryUpdateValue={asNumberOrNull}
                    setValue={(v) => update('ot2_air_gap', v ?? undefined)}
                    readOnly={readOnly}
                />
            </InlineLabeledInput>
            <InlineLabeledInput label='Top Offset'>
                <TextInput
                    size='sm'
                    value={method?.ot2_dest_top_offset}
                    tryUpdateValue={asNumberOrNull}
                    setValue={(v) => update('ot2_dest_top_offset', v ?? undefined)}
                    readOnly={readOnly}
                />
            </InlineLabeledInput>
        </div>
    );
}

function OT2Instrument({ model, mount }: { model: HTE2MSProductionModel; mount: 'left' | 'right' }) {
    const info = model.data.ot2_instruments[mount] ?? {};
    const readOnly = model.model.readOnlyDesignAndProduction;

    return (
        <div className='alert alert-secondary vstack gap-1 p-2 m-0'>
            <h6 className='m-0'>
                <FontAwesomeIcon className='me-2' icon={faEyeDropper} size='sm' fixedWidth />
                {capitalizeFirst(mount)}
            </h6>
            <InlineLabeledInput label='Instrument'>
                <SimpleSelectOptionInput
                    value={info.instrument ?? ''}
                    size='sm'
                    allowEmpty
                    options={OT2MultiInstrumentOptions}
                    emptyPlaceholder='None'
                    setValue={(v) => model.updateInstrument(mount, { instrument: v || undefined })}
                    disabled={readOnly}
                />
            </InlineLabeledInput>
            <InlineLabeledInput label='Tip Positions'>
                <TextInput
                    size='sm'
                    value={info.tip_rack_positions}
                    tryUpdateValue={parseTipPositions}
                    formatValue={(v) => v?.join(', ') ?? ''}
                    placeholder='e.g. 5, 6'
                    setValue={(v) => model.updateInstrument(mount, { tip_rack_positions: v?.length ? v : undefined })}
                    readOnly={readOnly}
                />
            </InlineLabeledInput>
        </div>
    );
}

function parseTipPositions(v: string) {
    return Array.from(
        new Set(
            v
                .split(',')
                .map((s) => s.trim())
                .filter((s) => s.length)
        )
    );
}
