import { faFilter, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { CSSProperties, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Column, Row } from 'react-table';
import { BehaviorSubject, distinctUntilKeyChanged } from 'rxjs';
import { AsyncMoleculeDrawing } from '../../../components/common/AsyncMoleculeDrawing';
import { SimpleSelectOptionInput, TextInput } from '../../../components/common/Inputs';
import useBehavior from '../../../lib/hooks/useBehavior';
import { darkenColor, getCachedPallete, toRGBString } from '../../../lib/util/colors';
import { groupBy } from '../../../lib/util/misc';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { roundValue, roundValueDigits } from '../../../lib/util/roundValues';
import { asNumber, asNumberOrNull, trimValue } from '../../../lib/util/validators';
import {
    formatUnitPrefix,
    getReactantLayerType,
    HTESolvent,
    HTESolvents,
    isHTESolvent,
    PlateInfo,
    Reactant,
    ReagentTypes,
    WellLayerType,
} from '../experiment-data';
import { updateWells } from '../plate/utils';
import { ReactantTypeBadge } from './common';
import { CompoundIdentifier } from './reagents-model';

export interface BBGroupsInfo {
    colorMap: Map<string, { rgb: string; raw: number[] }>;
    groupNames: string[];
}

export interface ReactantGroupInfo {
    colorMap: Map<string, { rgb: string; raw: number[] }>;
    compounds: string[];
}

export interface ReactantGroups {
    msds: Reactant[];
    bbs: Reactant[];
    byType: Map<WellLayerType, ReactantGroupInfo>;
    byGroup: Map<string, ReactantGroupInfo>;
    bbGroups: BBGroupsInfo;
}

const GROUP_COLORS = [
    [0xff, 0xc1, 0x07],
    [0x0d, 0xca, 0xf0],
    // [0xef, 0xad, 0xce],
    // [0x9e, 0xc5, 0xfe],
];

const BY_TYPE_COLORS: Record<WellLayerType, [start: number[], end: number[]]> = {
    msd: [
        [0xef, 0xad, 0xce],
        [0x9e, 0xc5, 0xfe],
    ],
    bb: [
        [0xef, 0xad, 0xce],
        [0x9e, 0xc5, 0xfe],
    ],
    reagent: [
        [0xef, 0xad, 0xce],
        [0x9e, 0xc5, 0xfe],
    ],
    catalyst: [
        [0xef, 0xad, 0xce],
        [0x9e, 0xc5, 0xfe],
    ],
    solvent: [
        [0xef, 0xad, 0xce],
        [0x9e, 0xc5, 0xfe],
    ],
    unknown: [
        [0xef, 0xad, 0xce],
        [0x9e, 0xc5, 0xfe],
    ],
};

export interface ReplaceReactantInfo {
    reactant: Reactant;
    newIdentifier: string;
    options?: { barcode?: string; transfer_barcode?: string };
}

export class ReactantsModel extends ReactiveModel {
    private _map: Map<string, Reactant> = new Map();
    private _indexMap: Map<string, number> = new Map();

    get map(): ReadonlyMap<string, Reactant> {
        return this._map;
    }

    state = {
        groups: new BehaviorSubject<ReactantGroups>({
            msds: [],
            bbs: [],
            byType: new Map(),
            byGroup: new Map(),
            bbGroups: { colorMap: new Map(), groupNames: [] },
        }),
        reactantsFilter: new BehaviorSubject<ReactantFilter>({ kind: 'all' }),
        currentRows: [] as Row<Reactant>[],
    };

    get bbGroupColorMap() {
        return this.state.groups.value.bbGroups.colorMap;
    }

    get groupNames() {
        return this.state.groups.value.bbGroups.groupNames;
    }

    get groups() {
        return this.state.groups.value;
    }

    readonly scaling = PlateInfo[this.experiment.layout];
    readonly columns: Column<Reactant>[] = [
        {
            Header: 'Structure',
            id: 'structure',
            accessor: (row) => this.experiment.batches.getSmiles(row.identifier),
            width: 120,
            Cell: ({ value }: { value?: string }) => (
                <>{value && <AsyncMoleculeDrawing smiles={value} height={59} drawer={this.experiment.drawer} />}</>
            ),
        },
        {
            Header: 'Identifier',
            accessor: 'identifier',
            Cell: ({ value }) => <CompoundIdentifier value={value} />,
            width: 180,
        },
        {
            Header: 'Barcode',
            accessor: 'barcode',
            Cell: ({ value }) => (value ?? '-') as any,
            width: 180,
        },
        {
            Header: 'MW',
            id: 'mw',
            accessor: 'molecular_weight',
            width: 100,
            Cell: ({ value }) => value?.toFixed(2) ?? ('-' as any),
        },
        {
            Header: () => (
                <EditableNumericHeader
                    header='Equiv.'
                    readOnly={this.experiment.stateInfo.status !== 'Planning'}
                    update={(v) => this.editCurrentRows('equivalence', v)}
                />
            ),
            accessor: 'equivalence',
            width: 90,
            Cell: ({ value, row }) => (
                <TextInput
                    value={value}
                    readOnly={
                        this.experiment.stateInfo.status !== 'Planning' || this.isStockAmountSpecified(row.original)
                    }
                    className={classNames({
                        'hte-experiment-edited-value':
                            !this.isStockAmountSpecified(row.original) && row.original.defaults.equivalence !== value,
                        'hte-experiment-computed-value': this.isStockAmountSpecified(row.original),
                    })}
                    tryUpdateValue={asNumber}
                    setValue={(v) => this.editValue('equivalence', row.original.identifier, v)}
                />
            ),
        },
        {
            Header: () => (
                <EditableNumericHeader
                    header='Conc. (M)'
                    readOnly={this.experiment.stateInfo.status !== 'Planning'}
                    update={(v) => this.editCurrentRows('concentration', v)}
                />
            ),
            id: 'concentration',
            accessor: (row) => this.calcConcentration(row),
            width: 120,
            Cell: ({ value, row }: { value: number | null; row: Row<Reactant> }) => (
                <TextInput
                    value={value}
                    formatValue={this.formatConcentration}
                    readOnly={
                        this.experiment.stateInfo.status !== 'Planning' ||
                        this.isStockAmountSpecified(row.original) ||
                        typeof row.original.density === 'number'
                    }
                    className={classNames({
                        'hte-experiment-edited-value':
                            !this.isStockAmountSpecified(row.original) &&
                            typeof row.original.density !== 'number' &&
                            row.original.defaults.concentration !== value,
                        'hte-experiment-computed-value':
                            this.isStockAmountSpecified(row.original) || typeof row.original.density === 'number',
                    })}
                    tryUpdateValue={asNumberOrNull}
                    setValue={(v) => this.editValue('concentration', row.original.identifier, v)}
                />
            ),
        },
        {
            Header: () => (
                <EditableNumericHeader
                    header='Over.'
                    readOnly={this.experiment.stateInfo.status !== 'Planning'}
                    update={(v) => this.editCurrentRows('overage', v)}
                />
            ),
            accessor: 'overage',
            width: 90,
            Cell: ({ value, row }) => (
                <TextInput
                    value={value}
                    readOnly={
                        this.experiment.stateInfo.status !== 'Planning' || this.isStockAmountSpecified(row.original)
                    }
                    className={classNames({
                        'hte-experiment-edited-value':
                            !this.isStockAmountSpecified(row.original) && row.original.defaults.overage !== value,
                        'hte-experiment-computed-value': this.isStockAmountSpecified(row.original),
                    })}
                    tryUpdateValue={asNumber}
                    setValue={(v) => this.editValue('overage', row.original.identifier, v)}
                />
            ),
        },
        {
            Header: `${formatUnitPrefix(this.scaling.amount)}g/rxn`,
            id: 'amount_rxn',
            accessor: (row) => this.formatAmountValue(this.calcRxnAmount(row)) ?? '-',
            width: 85,
            Cell: ({ value }: { value: number }) => value as any,
        },
        {
            Header: `${formatUnitPrefix(this.scaling.volume)}L/rxn`,
            id: 'volume_rxn',
            accessor: (row) => this.formatVolumeValue(this.calcRxnVolume(row)) ?? '-',
            width: 85,
            Cell: ({ value }: { value: number }) => value as any,
        },
        {
            Header: `${formatUnitPrefix(this.scaling.totalAmount)}g tot.`,
            id: 'amount_total',
            accessor: (row) => this.calcTotalStockAmount(row),
            width: 100,
            Cell: ({ value, row }: { value: number | null; row: Row<Reactant> }) => (
                <TextInput
                    value={value}
                    formatValue={this.formatTotalAmountValue}
                    readOnly={this.experiment.stateInfo.status !== 'Planning'}
                    className={row.original.stock_amount !== null ? 'hte-experiment-edited-value' : undefined}
                    tryUpdateValue={asNumberOrNull}
                    setValue={(v) =>
                        this.editValue(
                            'stock_amount',
                            row.original.identifier,
                            typeof v === 'number' ? v * this.scaling.totalAmount : null
                        )
                    }
                />
            ),
        },
        {
            Header: `${formatUnitPrefix(this.scaling.totalVolume)}L tot.`,
            id: 'volume_total',
            accessor: (row) => this.calcTotalStockVolume(row),
            width: 100,
            Cell: ({ value }: { value: number | null }) => this.formatTotalVolumeValue(value) as any,
        },
        {
            Header: '# Uses',

            id: 'number_of_uses',
            accessor: (row) => this.experiment.productPlate.getNumberOfUses(row.identifier),
            width: 90,
            Cell: ({ value }: { value: number }) => value as any,
        },
        {
            Header: `ρ g/mL`,
            id: 'density',
            accessor: (row) => row.density ?? null,
            width: 100,
            Cell: ({ value, row }: { value: number | null; row: Row<Reactant> }) => (
                <TextInput
                    value={value}
                    formatValue={this.formatDensityValue}
                    readOnly={this.experiment.stateInfo.status !== 'Planning'}
                    className={value !== null ? 'hte-experiment-edited-value' : undefined}
                    tryUpdateValue={asNumberOrNull}
                    setValue={(v) =>
                        this.editValue('density', row.original.identifier, typeof v === 'number' ? v * 1000 : null)
                    }
                />
            ),
        },
        {
            Header: 'Type',
            id: 'type',
            accessor: (row) => {
                if (row.type === 'reagent') {
                    return row.reagent_type ?? 'reagent';
                }
                if (row.type === 'bb') {
                    return row.group_name ?? '';
                }
                return row.type;
            },
            width: 100,
            Cell: ({ row }: { row: Row<Reactant> }) => {
                if (row.original.type === 'reagent') {
                    return (
                        <SimpleSelectOptionInput
                            className={`hte-experiment-badge-like hte-experiment-badge-like-select hte-experiment-type-current-${
                                row.original.reagent_type ?? 'reagent'
                            }`}
                            disabled={
                                this.experiment.stateInfo.status !== 'Planning' || isHTESolvent(row.original.identifier)
                            }
                            value={row.original.reagent_type ?? ''}
                            options={ReagentTypes}
                            setValue={(v) => this.editValue('reagent_type', row.original.identifier, v)}
                        />
                    );
                }
                if (row.original.type === 'bb') {
                    const groupName = row.original.group_name ?? '';
                    return (
                        <TextInput
                            value={groupName}
                            className='hte-experiment-badge-like hte-experiment-badge-like-input'
                            tryUpdateValue={trimValue}
                            readOnly={this.experiment.stateInfo.status !== 'Planning'}
                            style={{
                                background: this.bbGroupColorMap.get(groupName)?.rgb,
                                border: this.bbGroupColorMap.get(groupName)
                                    ? `1px solid ${toRGBString(
                                          darkenColor(this.bbGroupColorMap.get(groupName)!.raw, 0.9)
                                      )}`
                                    : undefined,
                            }}
                            setValue={(v) => this.editValue('group_name', row.original.identifier, v)}
                        />
                    );
                }

                return <ReactantTypeBadge type={row.original.type} />;
            },
        },
        // {
        //     Header: 'Solvent',
        //     accessor: 'solvent',
        //     width: 120,
        //     Cell: ({ value, row }) =>
        //         isSolutableReactant(row.original) ? (
        //             <SelectSolvent
        //                 setValue={(sol) => this.editValue('solvent', row.original.identifier, sol || null)}
        //                 value={value}
        //                 disabled={this.experiment.stateInfo.status !== 'Planning'}
        //                 allowEmpty
        //             />
        //         ) : null,
        // },
        // {
        //     Header: 'Barcode',
        //     accessor: 'barcode',
        //     width: 200,
        //     Cell: ({ value }) => value,
        // },
    ];

    readonly formatConcentration = (v: number | null) => (typeof v === 'number' ? roundValue(2, v)?.toString() : '-');
    readonly formatAmountValue = (v: number | null) =>
        typeof v === 'number' ? roundValueDigits(4, v / this.scaling.amount).toString() : '-';
    readonly formatVolumeValue = (v: number | null) =>
        typeof v === 'number' ? roundValueDigits(4, v / this.scaling.volume).toString() : '-';
    readonly formatTotalAmountValue = (v: number | null) =>
        typeof v === 'number' ? roundValueDigits(4, v / this.scaling.totalAmount).toString() : '-';
    readonly formatTotalVolumeValue = (v: number | null) =>
        typeof v === 'number' ? roundValueDigits(4, v / this.scaling.totalVolume).toString() : '-';
    readonly formatDensityValue = (v: number | null) =>
        typeof v === 'number' ? roundValueDigits(4, v / 1e3).toString() : '-';

    editValue(key: keyof Reactant, compoundId: string, value: any) {
        const reactants = [...this.experiment.design.reactants];
        const row = this.getReactantIndex(compoundId);
        if (row >= 0) {
            reactants[row] = { ...reactants[row], [key]: value };
            this.experiment.setReactants(reactants);
        }
    }

    editCurrentRows(key: keyof Reactant, value: any | null) {
        if (value === null) return;
        const current = new Set(this.state.currentRows.map((r) => r.original));
        if (current.size === 0) return;

        const all = this.experiment.design.reactants;
        const updated: Reactant[] = [];
        for (const r of all) {
            if (current.has(r)) {
                updated.push({ ...r, [key]: value });
            } else {
                updated.push(r);
            }
        }

        this.experiment.setReactants(updated);
    }

    getReactant(id: string) {
        return this._map.get(id)!;
    }

    getReactantIndex(id: string) {
        return this._indexMap.get(id) ?? -1;
    }

    getReactionSite(id: string) {
        const r = this._map.get(id)!;
        if (typeof r.enumeration?.site_index === 'number') {
            return r.enumeration.reaction_sites[r.enumeration.site_index];
        }
        return undefined;
    }

    isStockAmountSpecified(reactant: Reactant) {
        return reactant.stock_amount !== null;
    }

    calcConcentration(reactant: Reactant, mw?: number) {
        const molWeight = mw ?? reactant.molecular_weight;
        if (typeof reactant.density === 'number') {
            if (typeof molWeight !== 'number') return null;
            return reactant.density / molWeight;
        }
        return reactant.concentration ?? null;
    }

    calcRxnAmount(reactant: Reactant) {
        if (typeof reactant.molecular_weight !== 'number') return null;
        const nmol = this.experiment.reactionScale * reactant.equivalence;
        return nmol * reactant.molecular_weight;
    }

    calcRxnVolume(reactant: Reactant) {
        if (typeof reactant.density === 'number') {
            const amount = this.calcRxnAmount(reactant);
            if (amount === null) return null;
            return amount / reactant.density;
        }
        if (!reactant.concentration) return null;
        const concentration = this.calcConcentration(reactant);
        if (typeof concentration !== 'number') return null;
        const nmol = this.experiment.reactionScale * reactant.equivalence;
        return nmol / concentration;
    }

    calcTotalStockAmountToPrepare(reactant: Reactant, mw?: number) {
        const molWeight = mw ?? reactant.molecular_weight;
        if (typeof molWeight !== 'number') return null;
        const nUses = this.experiment.productPlate.getNumberOfUses(reactant.identifier);
        if (nUses === 0) return null;
        const nmol = this.experiment.reactionScale * reactant.equivalence;
        return nmol * molWeight * reactant.overage * nUses;
    }

    calcTotalStockAmount(reactant: Reactant, mw?: number) {
        return reactant.stock_amount !== null
            ? reactant.stock_amount
            : this.calcTotalStockAmountToPrepare(reactant, mw);
    }

    calcRequiredTotalStockVolumeToPrepare(reactant: Reactant, concentration: number | null) {
        if (typeof concentration !== 'number') return null;

        const nUses = this.experiment.productPlate.getNumberOfUses(reactant.identifier);
        if (nUses === 0) return null;
        const nmol = this.experiment.reactionScale * reactant.equivalence;
        return (nmol / concentration) * reactant.overage * nUses;
    }

    calcTotalStockVolumeToPrepareAfterWeight(reactant: Reactant, concentration: number | null) {
        if (typeof concentration !== 'number') return null;
        if (typeof reactant.stock_amount === 'number') {
            return reactant.stock_amount / (reactant.molecular_weight ?? 1) / concentration;
        }
        return null;
    }

    calcTotalStockVolumeToPrepare(reactant: Reactant, concentration: number | null) {
        if (typeof concentration !== 'number') return null;

        if (typeof reactant.stock_amount === 'number') {
            return reactant.stock_amount / (reactant.molecular_weight ?? 1) / concentration;
        }

        const nUses = this.experiment.productPlate.getNumberOfUses(reactant.identifier);
        if (nUses === 0) return null;
        const nmol = this.experiment.reactionScale * reactant.equivalence;
        return (nmol / concentration) * reactant.overage * nUses;
    }

    calcTotalStockVolume(reactant: Reactant, mw?: number) {
        return reactant.stock_volume !== null
            ? reactant.stock_volume
            : this.calcTotalStockVolumeToPrepare(reactant, this.calcConcentration(reactant, mw));
    }

    replaceReactantEntities(updates: ReplaceReactantInfo[]) {
        const { design } = this.experiment;
        const reactants = [...design.reactants];
        const replaceMap = new Map<string, string | null>();
        const reagents = this.experiment.reagents.state.all.value;

        const reagentMap = new Map(reagents.map((r) => [r.identifier, r]));

        for (const { reactant, newIdentifier, options } of updates) {
            const idx = this.getReactantIndex(reactant.identifier);
            if (idx >= 0) {
                reactants[idx] = {
                    ...reactant,
                    identifier: newIdentifier,
                    molecular_weight: this.experiment.batches.getMolecularWeight(newIdentifier),
                    barcode: options && 'barcode' in options ? options.barcode : reactant.barcode,
                    transfer_barcode:
                        options && 'transfer_barcode' in options ? options.transfer_barcode : reactant.transfer_barcode,
                };
                replaceMap.set(reactant.identifier, newIdentifier);
            }

            const reagent = reagentMap.get(reactant.identifier);
            if (reagent) {
                reagent.identifier = newIdentifier;
                reagent.universal_identifier = this.experiment.batches.getEntity(newIdentifier)?.universal_identifier;
            }
        }

        const newPlate = updateWells(design.plate, new Array<0 | 1>(design.plate.layout).fill(1), 'replace', {
            replaceMap,
        });

        if (this.experiment.enumeration.state.reactions.value.length > 0) {
            this.experiment.enumeration.state.reactions.next([]);
        }

        const newReactions = [...design.reactions];
        for (let i = 0; i < newReactions.length; i++) {
            const r = newReactions[i];
            if (replaceMap.has(r.msd_identifier) || replaceMap.has(r.bb_identifier)) {
                newReactions[i] = {
                    ...r,
                    msd_identifier: replaceMap.get(r.msd_identifier) ?? r.msd_identifier,
                    bb_identifier: replaceMap.get(r.bb_identifier) ?? r.bb_identifier,
                };
            }
        }

        this.experiment.state.design.next({
            ...design,
            plate: newPlate ?? design.plate,
            reactants,
            reactions: newReactions,
        });
    }

    constructor(public experiment: import('../experiment-model').HTEExperimentModel) {
        super();

        this.subscribe(experiment.state.design.pipe(distinctUntilKeyChanged('reactants')), (design) => {
            this._map.clear();
            this._indexMap.clear();

            let index = 0;
            for (const r of design.reactants) {
                this._map.set(r.identifier, r);
                this._indexMap.set(r.identifier, index++);
            }

            this.state.groups.next(getReactantGroupsInfoAndNormalizeOrder(design.reactants));
        });
    }
}

export function isSolutableReactant(r: Reactant) {
    const type = getReactantLayerType(r);
    return typeof r.defaults.concentration !== 'number' && type !== 'solvent' && type !== 'bb';
}

const ReactantFilterKinds = ['all', 'reagent', 'catalyst', 'solvent', 'MSD', 'BB'] as const;
export interface ReactantFilter {
    kind?: (typeof ReactantFilterKinds)[number];
    unsolvated?: boolean;
    groupName?: string;
    test?: (r: Reactant) => boolean;
}

export function filterReactants(reactants: Reactant[], filter: ReactantFilter) {
    const { kind, groupName, unsolvated, test } = filter;
    let current = unsolvated ? reactants.filter((r) => typeof r.defaults.concentration !== 'number') : reactants;
    if (test) {
        current = current.filter((r) => test(r));
    }
    if (!kind || kind === 'all') return current;
    if (kind === 'MSD') return current.filter((r) => r.type === 'msd');
    if (kind === 'BB') {
        if (!groupName) return current.filter((r) => r.type === 'bb');
        return current.filter((r) => r.type === 'bb' && r.group_name === groupName);
    }
    return current.filter((r) => r.reagent_type === kind);
}

export function useFilterReactants(reactants: Reactant[], filter: ReactantFilter) {
    const { kind, groupName, unsolvated } = filter;

    return useMemo(() => filterReactants(reactants, filter), [reactants, kind, groupName, unsolvated]);
}

function EditableNumericHeader({
    header,
    update,
    readOnly,
}: {
    header: string;
    update: (v: number | null) => void;
    readOnly?: boolean;
}) {
    if (readOnly) return <>{header}</>;
    return (
        <>
            <FontAwesomeIcon icon={faPenToSquare} style={{ position: 'absolute', right: 12, top: 12 }} size='sm' />
            <TextInput
                value={null}
                placeholder={header}
                tryUpdateValue={asNumberOrNull}
                setValue={update}
                emptyOnBlur
                className='me-2'
            />
        </>
    );
}

function ReactantFilterButton({
    filter,
    current,
    kind,
}: {
    filter: BehaviorSubject<ReactantFilter>;
    current: ReactantFilter['kind'];
    kind: ReactantFilter['kind'];
}) {
    return (
        <Button
            onClick={
                kind === 'BB'
                    ? () => filter.next({ ...filter.value, kind, groupName: undefined })
                    : () => filter.next({ ...filter.value, kind })
            }
            variant={current === kind ? 'primary' : 'outline-primary'}
            className={
                current === kind
                    ? `hte-experiment-type-current-${(kind ?? 'all').toLowerCase()}`
                    : `hte-experiment-type-${(kind ?? 'all').toLowerCase()}`
            }
        >
            {kind}
        </Button>
    );
}

export function ReactantsFilter({
    filter,
    reactants,
    unsolvatedButton,
}: {
    filter: BehaviorSubject<ReactantFilter>;
    reactants: ReactantsModel;
    unsolvatedButton?: boolean;
}) {
    const current = useBehavior(filter);
    const {
        bbGroups: { groupNames, colorMap },
    } = useBehavior(reactants.state.groups);

    const groupColor = colorMap.get(current.groupName!);

    return (
        <div className='hte-experiment-reactants-filter ps-2'>
            <span className='me-2 text-secondary'>
                <FontAwesomeIcon icon={faFilter} className='me-1' /> Filter by:
            </span>
            {ReactantFilterKinds.map((k) => (
                <ReactantFilterButton filter={filter} kind={k} current={current.kind} key={k} />
            ))}
            {current.kind === 'BB' && (
                <SimpleSelectOptionInput
                    className='hte-experiment-badge-like hte-experiment-badge-like-select me-2'
                    value={current.groupName ?? ''}
                    allowEmpty
                    emptyPlaceholder='BB Group'
                    options={groupNames.map((g) => [g, g])}
                    style={{
                        backgroundColor: groupColor?.rgb,
                        border: groupColor ? `1px solid ${toRGBString(darkenColor(groupColor.raw, 0.9))}` : undefined,
                    }}
                    setValue={(v) => filter.next({ ...current, groupName: v })}
                />
            )}
            {unsolvatedButton && (
                <Button
                    onClick={() => filter.next({ ...filter.value, unsolvated: !current.unsolvated })}
                    variant={current.unsolvated ? 'primary' : 'outline-primary'}
                    className={
                        current.unsolvated ? 'hte-experiment-type-current-unsolvated' : 'hte-experiment-type-unsolvated'
                    }
                >
                    Unsolvated
                </Button>
            )}
        </div>
    );
}

function getReactantGroupsInfoAndNormalizeOrder(reactants: Reactant[]): ReactantGroups {
    const typeGroups = groupBy(reactants, getReactantLayerType);
    const byType: ReactantGroups['byType'] = new Map();
    const byGroup: ReactantGroups['byGroup'] = new Map();

    typeGroups.forEach((xs, type) => {
        const [s, e] = BY_TYPE_COLORS[type];
        const palette = getCachedPallete(s, e, xs.length);
        const compounds = xs.map((x) => x.identifier);
        compounds.sort();
        const colorMap: ReactantGroupInfo['colorMap'] = new Map();
        for (let i = 0; i < compounds.length; i++) colorMap.set(compounds[i], palette[i]);
        byType.set(type, { colorMap, compounds });
    });

    const bbs = typeGroups.get('bb');
    const groupNames = Array.from(new Set(bbs?.map((x) => x.group_name ?? '') ?? []));
    const bbPalette = getCachedPallete(GROUP_COLORS[0], GROUP_COLORS[1], groupNames.length);
    groupNames.sort((a, b) => {
        const x = +a;
        const y = +b;
        if (Number.isNaN(x) || Number.isNaN(y)) return a === b ? 0 : a < b ? -1 : 1;
        return x - y;
    });
    const bbColorMap: BBGroupsInfo['colorMap'] = new Map();
    for (let i = 0; i < groupNames.length; i++) bbColorMap.set(groupNames[i], bbPalette[i]);

    const bbGroups = groupBy(bbs ?? [], (r) => r.group_name ?? '');
    bbGroups.forEach((xs, group) => {
        const [s, e] = BY_TYPE_COLORS.bb;
        const palette = getCachedPallete(s, e, xs.length);
        const compounds = xs.map((x) => x.identifier);
        compounds.sort();
        const colorMap: ReactantGroupInfo['colorMap'] = new Map();
        for (let i = 0; i < compounds.length; i++) colorMap.set(compounds[i], palette[i]);
        byGroup.set(group, { colorMap, compounds });
    });

    const allMsds: Reactant[] = [];
    const allBbs: Reactant[] = [];
    for (const r of reactants) {
        if (r.type === 'bb') allBbs.push(r);
        else if (r.type === 'msd') allMsds.push(r);
    }

    if (allBbs.some((bb) => typeof bb.bb_order !== 'number')) {
        for (let i = 0; i < allBbs.length; i++) {
            allBbs[i].bb_order = i + 1;
        }
    }

    if (allMsds.some((msd) => typeof msd.msd_order !== 'number')) {
        for (let i = 0; i < allMsds.length; i++) {
            allMsds[i].msd_order = i + 1;
        }
    }

    return { msds: allMsds, bbs: allBbs, byType, byGroup, bbGroups: { groupNames, colorMap: bbColorMap } };
}

const solventOptions = HTESolvents.map((s) => [s, s] as const);
export function SelectSolvent({
    value,
    setValue,
    allowEmpty,
    disabled,
    style,
    className,
    size,
}: {
    value?: HTESolvent | null;
    setValue: (v: HTESolvent) => void;
    allowEmpty?: boolean;
    disabled?: boolean;
    style?: CSSProperties;
    className?: string;
    size?: 'sm' | 'lg';
}) {
    return (
        <SimpleSelectOptionInput
            value={value ?? (allowEmpty ? (undefined as any) : 'DMSO')}
            options={solventOptions}
            setValue={setValue}
            allowEmpty={allowEmpty}
            className={`hte-experiment-select-solvent ${className ?? ''}`}
            emptyPlaceholder='<None>'
            disabled={disabled}
            style={style}
            size={size}
        />
    );
}
