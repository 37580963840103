import {
    faCheck,
    faChevronDown,
    faChevronRight,
    faExclamation,
    faFlagCheckered,
    faInfoCircle,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useState } from 'react';
import { Alert, Badge, Button } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import Split from 'react-split-it';
import { AsyncButton } from '../../../components/common/AsyncButton';
import { MultiFileUpload } from '../../../components/common/FileUpload';
import { IconButton } from '../../../components/common/IconButton';
import { LabeledInput, TextInput } from '../../../components/common/Inputs';
import Loading from '../../../components/common/Loading';
import { PillNav, PillNavStep } from '../../../components/common/Nav';
import { ScrollBox } from '../../../components/common/ScrollBox';
import useBehavior from '../../../lib/hooks/useBehavior';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { DialogService } from '../../../lib/services/dialog';
import { useModelAction } from '../../../lib/util/reactive-model';
import { prefixedUnitValue } from '../../../lib/util/units';
import { BatchLink } from '../../ECM/ecm-common';
import { ECMSearchResultsUI } from '../../ECM/workflows/Search';
import { TransferManualInput, TransferManualStatus } from '../../ECM/workflows/Transfer';
import { ECMManualWorkflowWrapper } from '../../ECM/workflows/common';
import {
    HTEEVialRack,
    HTEPInventoryItem,
    HTEPMosquitoProductPlate,
    HTEPRobotRunIdT,
    HTEPSourcePlate,
} from '../data-model';
import { CombiMaps, CombiTable, TransferInfo, TransferTable } from '../inventory/inventory-ui';
import { HTEWModel } from '../model';
import { UsesMarkdown } from '../utils';
import { HTEExecutionTab, HTEMosquitoExecutionModel } from './mosquito';
import { SolubilizeUI } from './solubilize-ui';
import { ProtocolText } from '../../../components/common/ProtocolText';

export function HTEDExecutionUI({ model }: { model: HTEWModel }) {
    const execution = useBehavior(model.state.execution);
    useMountedModel(execution);

    if (!execution) {
        return <div className='m-2 text-secondary'>Experiment not submitted</div>;
    }

    return <Tabs model={execution} />;
}

const NavTabs: PillNavStep<HTEExecutionTab>[] = [
    { name: 'plates', label: 'Plate Barcodes' },
    { name: 'inventory-search', label: 'Inv. Search' },
    { name: 'transfers', label: 'Transfers' },
    { name: 'verso', label: 'Verso' },
    { name: 'picklists', label: 'Picklists' },
    { name: 'solubilize', label: 'Solubilize' },
    { name: 'combi', label: 'Combi' },
    { name: 'mosquito', label: 'Mosquito' },
];

const OtherNavTabs: PillNavStep<HTEExecutionTab>[] = [{ name: 'observations', label: 'Observations' }];

function Tabs({ model }: { model: HTEMosquitoExecutionModel }) {
    const tab = useBehavior(model.state.tab);

    return (
        <div className='d-flex flex-column h-100'>
            <div className='hstack gap-2 me-3 mb-2'>
                <SelectRun model={model} />
                <PillNav steps={NavTabs} currentStep={tab} onSetStep={(s) => model.state.tab.next(s)} />
                <PillNav steps={OtherNavTabs} currentStep={tab} onSetStep={(s) => model.state.tab.next(s)} />
                <div className='m-auto' />
                <ReadyForFinalizationButton model={model.model} />
            </div>
            <div className='flex-grow-1 position-relative mb-2 ms-2'>
                {tab === 'plates' && <Plates model={model} />}
                {tab === 'verso' && <Verso model={model} />}
                {tab === 'mosquito' && <Mosquito model={model} />}
                {tab === 'picklists' && <Picklists model={model} />}
                {tab === 'solubilize' && <SolubilizeUI model={model.solubilize} />}
                {tab === 'combi' && <Combi model={model} />}
                {tab === 'transfers' && <Transfers model={model} />}
                {tab === 'observations' && <Observations model={model} />}
                {tab === 'inventory-search' && <Search model={model} />}
            </div>
        </div>
    );
}

function ReadyForFinalizationButton({ model }: { model: HTEWModel }) {
    const state = useModelAction(model.actions.submit);
    useBehavior(model.state.dataSource);

    if (!model.canEdit) return null;

    const submit = () => {
        DialogService.open({
            type: 'generic',
            wrapOk: true,
            onOk: () => model.actions.submit.run(model.readyToFinalize(), { onError: 'eat', rethrowError: true }),
            title: 'Prepare for Finalization',
            confirmButtonContent: 'Submit',
            content: PrepareForFinalizationContent,
        });
    };

    return (
        <AsyncButton
            onClick={submit}
            state={{ isLoading: state.kind === 'loading' }}
            variant='primary'
            size='sm'
            icon={faFlagCheckered}
            title='Prepare for Finalization'
        >
            Submit
        </AsyncButton>
    );
}

function PrepareForFinalizationContent() {
    return (
        <>
            <p>
                Do you want to prepare the experiment for finalization?
                <br />
                This will:
            </p>
            <ul className='ps-4 text-warning'>
                <li>Upload product and source plate maps</li>
                <li>Upload reactions</li>
                <li>
                    Apply wet inventory transfers (unless already done manually from the <b>Transfers</b> tab)
                </li>
                <li>
                    Dispose vials used from the <b>Transfer</b> tab
                </li>
                <li>Notify the author that the experiment is ready for finalization</li>
            </ul>
            <p className='text-info'>
                This step usually takes a bit of time (30-120s) and can time out due to batch registration of up to 1536
                new batches. If this happens, please try again.
            </p>
        </>
    );
}

function SelectRun({ model }: { model: HTEMosquitoExecutionModel }) {
    const plate = useBehavior(model.state.plate);
    const run = useBehavior(model.state.run);

    if (!plate) {
        return null;
    }

    return (
        <div className='hstack ms-2 my-2 gap-2'>
            {plate.runs.map((r) => (
                <Button
                    variant={r === run ? 'primary' : 'outline-primary'}
                    key={r.id}
                    onClick={() => model.state.run.next(r)}
                    size='sm'
                >
                    {r.label}
                </Button>
            ))}
        </div>
    );
}

function Mosquito({ model }: { model: HTEMosquitoExecutionModel }) {
    const run = useBehavior(model.state.run);

    if (!run) {
        return <div className='m-4 text-secondary'>No data available</div>;
    }

    return (
        <div className='d-flex h-100 me-2'>
            <ExecutionProtocolText
                model={model}
                value={run.worklist}
                filename={`mosquito-${run.label}.csv`}
                className='flex-grow-1'
            />
        </div>
    );
}

function Verso({ model }: { model: HTEMosquitoExecutionModel }) {
    const plate = useBehavior(model.state.plate);
    const run = useBehavior(model.state.run);
    const info = useBehavior(model.state.info);

    if (!plate || !info || !run) {
        return <div className='m-4 text-secondary'>No data available</div>;
    }

    return (
        <div className='d-flex me-2 h-100 flex-column'>
            <Alert variant='info' className='mb-2'>
                <div className='hstack gap-2'>
                    <FontAwesomeIcon icon={faInfoCircle} className='mx-1' />
                    <span>
                        Unless manually done using the <b>Transfers</b> tab, the transfers for the barcodes bellow will
                        be automatically applied after the
                        <FontAwesomeIcon icon={faFlagCheckered} className='mx-2' />
                        <b>Ready for Finalization</b> step.
                    </span>
                </div>
            </Alert>
            <ExecutionProtocolText
                model={model}
                value={info[plate.id][run.id].versoPicklist}
                filename={`verso-${run.label}.csv`}
                className='flex-grow-1'
            />
        </div>
    );
}

function Picklists({ model }: { model: HTEMosquitoExecutionModel }) {
    return (
        <div className='d-flex w-100 h-100 align-items-stretch'>
            <div style={{ flexBasis: '50%' }} className='pe-2 d-flex flex-column'>
                <UploadRacks model={model} />
                <div className='position-relative flex-grow-1 mt-2'>
                    <RackList model={model} />
                </div>
                <SourcePlateValidation model={model} />
            </div>
            <div style={{ flexBasis: '50%' }} className='d-flex align-items-stretch'>
                <Picklist model={model} />
            </div>
        </div>
    );
}

function Picklist({ model }: { model: HTEMosquitoExecutionModel }) {
    const plate = useBehavior(model.state.plate);
    const run = useBehavior(model.state.run);
    const info = useBehavior(model.state.info);

    if (!plate || !info || !run) {
        return <div className='m-4 text-secondary'>No data available</div>;
    }

    const inv = info[plate.id][run.id];

    return (
        <div className='d-flex w-100 flex-column me-3'>
            <h5>CSV</h5>
            <ExecutionProtocolText
                model={model}
                value={inv.sourcePlatesCsvPicklist}
                filename={`source-plate-${run.label}.csv`}
                className='flex-grow-1'
            />
            <h5 className='mt-2'>OT2</h5>
            <ExecutionProtocolText
                model={model}
                value={inv.sourcePlatesOT2Picklist}
                filename={`ot2-source-plate-${run.label}.py`}
                className='flex-grow-1'
            />
        </div>
    );
}

function UploadRacks({ model }: { model: HTEMosquitoExecutionModel }) {
    const state = useModelAction(model.actions.uploadRack);
    return (
        <div className='position-relative'>
            <div style={{ visibility: state.kind === 'loading' ? 'hidden' : undefined }}>
                <MultiFileUpload
                    extensions={['.csv']}
                    filesSubject={model.state.rackFiles}
                    label='Upload CSV file(s) with Rack Barcode, Barcode, and Well columns...'
                    numFiles={50}
                    hideFilename
                />
            </div>
            {state.kind === 'loading' && (
                <div className='position-absolute' style={{ inset: 0 }}>
                    <Loading message='Parsing racks...' />
                </div>
            )}
        </div>
    );
}

function RackList({ model }: { model: HTEMosquitoExecutionModel }) {
    const plate = useBehavior(model.state.plate);
    const run = useBehavior(model.state.run);
    const data = useBehavior(model.state.data);

    if (!plate || !run) {
        return <div className='m-4 text-secondary'>No data available</div>;
    }

    const { racks } = data.products[plate.id].robot_runs[run.id];

    return (
        <ScrollBox>
            {Object.values(racks).map((rack) => (
                <RackView model={model} rack={rack} key={rack.barcode} />
            ))}
        </ScrollBox>
    );
}

function SourcePlateValidation({ model }: { model: HTEMosquitoExecutionModel }) {
    const plate = useBehavior(model.state.plate);
    const run = useBehavior(model.state.run);
    const info = useBehavior(model.state.info);

    if (!plate || !info || !run) {
        return null;
    }

    const item = (e: HTEPInventoryItem) => (
        <div className='hstack gap-2' key={e.id}>
            {e.reactant_names.map((v) => (
                <Badge key={v} bg='info' style={{ fontSize: 8 }}>
                    {v}
                </Badge>
            ))}
            <span>
                <BatchLink identifier={e.identifier} withQuery />
            </span>
            <span>
                {prefixedUnitValue(e.volume, 'L', { factor: 1e3 })} @ {prefixedUnitValue(e.concentration, 'M')} in{' '}
                {e.solvent}
            </span>
        </div>
    );

    const { inventory } = info[plate.id][run.id];
    const numIssues =
        inventory.unassignedBarcode.length + inventory.missingInRack.length + inventory.unassignedPlateBarcodes.length;

    return (
        <>
            <h5 className='mt-2'>
                Validation ({numIssues} issue{numIssues === 1 ? '' : 's'})
            </h5>
            <div className='position-relative flex-grow-1'>
                <ScrollBox>
                    {inventory.unassignedPlateBarcodes.length > 0 && (
                        <h6 className='my-1 text-danger'>Source Plate Barcode not assigned:</h6>
                    )}
                    {inventory.unassignedPlateBarcodes.map((e) => (
                        <div key={e.id}>
                            #{e.tag! + 1}: {e.label ?? '-'}
                        </div>
                    ))}
                    {inventory.unassignedBarcode.length > 0 && (
                        <h6 className='my-1 text-danger'>No barcode assigned:</h6>
                    )}
                    {inventory.unassignedBarcode.map((e) => item(e))}
                    {inventory.missingInRack.length > 0 && <h6 className='my-1 text-danger'>Not found in any rack:</h6>}
                    {inventory.missingInRack.map((e) => item(e))}
                    {inventory.unassignedBarcode.length === 0 && inventory.missingInRack.length === 0 && (
                        <div className='text-success'>
                            <FontAwesomeIcon icon={faCheck} className='me-2' />
                            All inventory entries accounted for
                        </div>
                    )}
                </ScrollBox>
            </div>
        </>
    );
}

function RackView({ model, rack }: { model: HTEMosquitoExecutionModel; rack: HTEEVialRack }) {
    const wells = useMemo(() => Object.entries(rack.wells).sort(([a], [b]) => a.localeCompare(b)), [rack]);
    const [expanded, setExpanded] = useState(false);
    const plate = useBehavior(model.state.plate);
    const run = useBehavior(model.state.run);
    const info = useBehavior(model.state.info);

    if (!plate || !info || !run) {
        return <div className='m-4 text-secondary'>No data available</div>;
    }

    const { inventory } = info[plate.id][run.id];
    const stats = inventory.rackStats.get(rack.barcode);

    return (
        <div className='border-bottom'>
            <div className='d-flex'>
                <Button
                    variant='link'
                    className='px-0 py-1 flex-grow-1 text-start'
                    onClick={() => setExpanded((prev) => !prev)}
                >
                    <FontAwesomeIcon
                        icon={expanded ? faChevronDown : faChevronRight}
                        size='sm'
                        fixedWidth
                        className='me-2'
                    />
                    <b>{rack.barcode}</b>{' '}
                    {stats?.present! > 0 && (
                        <>
                            {' '}
                            <span className='text-success'>{stats!.present} ok</span>
                        </>
                    )}
                    {stats?.extra! > 0 && (
                        <>
                            {' '}
                            <span className='text-warning'>{stats!.extra} extra</span>
                        </>
                    )}
                </Button>
                <IconButton
                    icon={faTrash}
                    onClick={() => model.removeRack(rack.barcode)}
                    className='py-1 text-secondary'
                    title='Remove Rack'
                />
            </div>
            {expanded && (
                <div className='mx-2 mb-2'>
                    {wells.map(([well, barcode]) => {
                        const isPresent = inventory.vialBarcodeToItem.has(barcode);
                        return (
                            <div
                                key={well}
                                title={!isPresent ? 'Barcode not associated with any inventory' : undefined}
                            >
                                <FontAwesomeIcon
                                    size='sm'
                                    fixedWidth
                                    icon={isPresent ? faCheck : faExclamation}
                                    className={isPresent ? 'text-success me-2' : 'text-warning me-2'}
                                />
                                {well}: <span className={!isPresent ? 'text-warning' : 'text-success'}>{barcode}</span>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

function Combi({ model }: { model: HTEMosquitoExecutionModel }) {
    const run = useBehavior(model.state.run);

    if (!run) return <div className='m-4 text-secondary'>No data available</div>;

    return (
        <Split direction='vertical' gutterSize={6} sizes={[0.5, 0.5]}>
            <CombiTable model={model.inventory} />
            <CombiMaps model={model.inventory} />
        </Split>
    );
}

function Plates({ model }: { model: HTEMosquitoExecutionModel }) {
    return (
        <ScrollBox className='pe-3 pt-4 pb-4'>
            <div className='vstack gap-4' style={{ width: 600, margin: '0 auto' }}>
                {model.protocol.product_plates.map((p, i) => (
                    <ProtocolBarcodes model={model} protocol={p} index={i} key={i} />
                ))}
            </div>
        </ScrollBox>
    );
}

const LabelWidth = 220;

function ProtocolBarcodes({
    model,
    protocol,
    index,
}: {
    model: HTEMosquitoExecutionModel;
    protocol: HTEPMosquitoProductPlate;
    index: number;
}) {
    const plate = useBehavior(model.state.plate);
    const data = useBehavior(model.state.data);
    const run = useBehavior(model.state.run);

    if (!plate || !run) {
        return <div className='m-4 text-secondary'>No data available</div>;
    }

    const plateBarcode = (kind: string, run_id: HTEPRobotRunIdT, sp: HTEPSourcePlate, isSolvent = false) => (
        <LabeledInput
            label={`#${sp.tag! + 1}: ${sp.label ?? `${kind} Plate`}`}
            labelClassName='pe-2 text-nowrap overflow-hidden'
            labelWidth={LabelWidth}
            key={sp.id}
        >
            {isSolvent && (
                <div className='text-secondary ms-2' style={{ lineHeight: '38px' }}>
                    <i>Solvent plate, barcode not required</i>
                </div>
            )}
            {!isSolvent && (
                <TextInput
                    value={data.products[plate.id].robot_runs[run_id].barcodes.source_plates[sp.id] ?? ''}
                    placeholder='Enter barcode...'
                    setValue={(v) => model.updateSourcePlateBarcode(sp.id, v.trim())}
                />
            )}
        </LabeledInput>
    );

    return (
        <div className='vstack gap-2'>
            <h3>Product Plate</h3>

            <LabeledInput label='Crude Plate' labelWidth={LabelWidth}>
                <TextInput
                    value={data.products[plate.id].product_plate_barcode ?? ''}
                    placeholder='Enter barcode...'
                    setValue={(v) => model.updateProductPlateBarcode(v.trim())}
                />
            </LabeledInput>

            <h5 className='mt-4'>Source Plates for {run.label}</h5>

            {run.source_plates.map((sp) => plateBarcode('Reactant', run.id, sp))}
            {run.solvent_plates.map((sp) => plateBarcode('Solvent', run.id, sp, true))}
        </div>
    );
}

function Transfers({ model }: { model: HTEMosquitoExecutionModel }) {
    return (
        <Split direction='horizontal' gutterSize={6} sizes={[0.4, 0.6]}>
            <div className='h-100'>
                <TransferTable model={model.inventory} />
            </div>
            <div className='h-100'>
                <ECMManualWorkflowWrapper
                    model={model.inventory.transfer}
                    input={
                        <TransferManualInput
                            model={model.inventory.transfer}
                            extra={<TransferInfo model={model.inventory} />}
                        />
                    }
                    status={<TransferManualStatus model={model.inventory.transfer} showSubmit />}
                    inputsRatio={0.45}
                />
            </div>
        </Split>
    );
}

function ExecutionProtocolText({
    model,
    value,
    filename,
    className,
}: {
    model: HTEMosquitoExecutionModel;
    value: string;
    filename: string;
    className?: string;
}) {
    return <ProtocolText value={value} className={className} save={() => model.saveProtocol({ value, filename })} />;
}

function Search({ model }: { model: HTEMosquitoExecutionModel }) {
    useMountedModel(model.inventory);
    const state = useModelAction(model.inventory.actions.load);
    useEffect(() => {
        model.inventory.syncSearch();
    }, [model]);

    if (state?.kind === 'loading') return <Loading />;

    return (
        <div className='d-flex h-100 htew-search-wrapper'>
            <ECMSearchResultsUI model={model.inventory.search} />
        </div>
    );
}

function Observations({ model }: { model: HTEMosquitoExecutionModel }) {
    const data = useBehavior(model.state.data);

    return (
        <div className='d-flex h-100'>
            <div className='flex-grow-1 d-flex flex-column' style={{ flexBasis: '50%' }}>
                <div className='flex-grow-1 position-relative'>
                    <TextInput
                        textarea
                        autoFocus
                        value={data.observations ?? ''}
                        style={{ fontFamily: 'monospace', resize: 'none' }}
                        className='w-100 h-100 font-body-small'
                        setValue={(v) => model.state.data.next({ ...data, observations: v })}
                    />
                </div>
                <div className='pt-1 text-center'>
                    <UsesMarkdown />
                </div>
            </div>
            <div className='px-2' style={{ flexBasis: '50%' }}>
                <ReactMarkdown>{data.observations}</ReactMarkdown>
            </div>
        </div>
    );
}
