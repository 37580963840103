import {
    Chart,
    type ChartConfiguration,
    type ChartDataset,
    type ChartType,
    type ScaleOptions,
    type ChartTypeRegistry,
    type Plugin,
    type PluginOptionsByType,
    type PointStyle,
} from 'chart.js';
import type { AnnotationOptions } from 'chartjs-plugin-annotation';
import { saveAs } from 'file-saver';
import log from 'loglevel';
import { BehaviorSubject } from 'rxjs';
import type { AssayValueType } from '../../lib/assays/models';
import { DragPlugin } from '../../lib/plot/plugins/dragger';
import { LassoSelectPluginModel } from '../../lib/plot/plugins/lassoSelection';
import { ReactiveModel } from '../../lib/util/reactive-model';
import { DateLike } from '../../lib/util/dates';

export interface CompoundPoint {
    x: number | boolean | DateLike | string | null | undefined;
    y: number | boolean | DateLike | string | null | undefined;
    identifier?: string;
    SMILES?: string;
    index?: number;
    backgroundColor?: string;
    pointStyle?: PointStyle;
}

export interface RichAssayValuePoint extends CompoundPoint {
    richX?: AssayValueType;
    richY?: AssayValueType;
}

export interface ChartPlugin {
    name: string;
    plugin: Plugin<keyof ChartTypeRegistry, any>;
    pluginOptions: PluginOptionsByType<ChartType>;
}

export class ChartModel extends ReactiveModel {
    public chart?: Chart = undefined;
    public lassoSelectionModel = new LassoSelectPluginModel();
    public dragModel: DragPlugin = new DragPlugin();
    private config: ChartConfiguration | undefined = undefined;

    state = {
        initialized: new BehaviorSubject(false),
        selecting: new BehaviorSubject(false),
    };

    get panEnabled() {
        return this.chart?.options.plugins?.zoom?.pan?.enabled ?? false;
    }

    get richDataEnabled() {
        return this.chart?.options.plugins?.richData?.enabled ?? false;
    }

    get tooltipEnabled() {
        return this.chart?.options.plugins?.tooltip?.enabled ?? false;
    }

    toggleRichData(enabled: boolean) {
        if (this.config?.options?.plugins?.richData) this.config.options.plugins.richData.enabled = enabled;
        if (this.chart?.options.plugins?.richData) {
            this.chart.options.plugins.richData.enabled = enabled;
            this.chart.update('none');
        }
    }

    toggleLasso(enabled: boolean) {
        if (this.config?.options?.plugins?.lassoSelection) this.config.options.plugins.lassoSelection.enabled = enabled;
        if (this.chart?.options.plugins?.lassoSelection) {
            this.chart.options.plugins.lassoSelection.enabled = enabled;
            this.chart.update('none');
            this.state.selecting.next(enabled);
        }
    }

    resetZoom = () => {
        this.chart?.resetZoom();
    };

    resize = () => {
        this.chart?.resize();
    };

    downloadImage = () => {
        if (!this.chart) return;
        saveAs(this.chart.toBase64Image('image/png', 1), `chart-${new Date().toISOString()}.png`);
    };

    updateScales(scales: { [axisName: string]: ScaleOptions }) {
        if (this.config?.options) this.config.options.scales = scales;
        if (this.chart) {
            this.chart.options.scales = scales;
            this.chart.update('none');
        }
    }

    updateData(datasets: ChartDataset[]) {
        if (this.config?.data) this.config.data = { datasets };
        if (this.chart) {
            this.chart.data = {
                datasets,
            };
            this.chart.update('none');
        }
    }

    updateChartType(type: ChartType) {
        if (!this.config) throw new Error('Chart not initialized');
        this.config.type = type;
        if (this.chart) {
            const ctx = this.chart.ctx;
            this.chart.destroy();
            this.chart = createChartJSChart(ctx, this.config);
            this.resize();
        }
    }

    updateLegendPosition(position?: 'top' | 'right' | 'bottom' | 'left' | 'none') {
        if (this.config?.options?.plugins?.legend) {
            if (position === 'none') {
                this.config.options.plugins.legend.display = false;
            } else {
                this.config.options.plugins.legend.display = true;
                this.config.options.plugins.legend.position = position ?? 'bottom';
            }
        }
        if (this.chart?.options.plugins?.legend) {
            if (position === 'none') {
                this.chart.options.plugins.legend.display = false;
            } else {
                this.chart.options.plugins.legend.display = true;
                this.chart.options.plugins.legend.position = position ?? 'bottom';
            }
            this.chart.update('none');
        }
    }

    updateAnnotationList(annotations: AnnotationOptions[]) {
        if (this.config?.options?.plugins?.annotation) {
            this.config.options.plugins.annotation.annotations = annotations;
        }
        if (this.chart?.options.plugins?.annotation) {
            this.chart.options.plugins.annotation.annotations = annotations;
            this.chart.update('none');
        }
    }

    mount(ctx: CanvasRenderingContext2D | null, config: ChartConfiguration) {
        if (!ctx) {
            log.error('Could not get canvas context');
            return;
        }
        this.config = config;
        this.chart = createChartJSChart(ctx, config);
        this.state.initialized.next(true);
    }

    dispose() {
        super.dispose();
        this.chart?.destroy();
        this.chart = undefined;
        this.state.initialized.next(false);
    }

    constructor() {
        super();
    }
}

function createChartJSChart(ctx: CanvasRenderingContext2D, config: ChartConfiguration): Chart {
    return new Chart(ctx, config);
}
