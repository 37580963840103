import { roundValue } from '../../../lib/util/roundValues';
import { PlateDimensions, WellLayout } from '../../HTE/experiment-data';
import { HTEPInventoryItem, HTEPProductPlate, HTEPSourcePlate } from '../data-model';

export interface CombiMap {
    header: string;
    item: HTEPInventoryItem;
    barcode?: string;
    map: string;
}

export function buildCombiMaps({
    productPlate,
    solventPlates,
    item,
    barcode,
}: {
    productPlate: HTEPProductPlate;
    solventPlates: HTEPSourcePlate[];
    item: HTEPInventoryItem;
    barcode?: string;
}): CombiMap[] {
    if (!item.identifier) {
        return [
            buildSolventProductPlateMap(productPlate, item, barcode),
            ...solventPlates.map((p) => buildSolventSourcePlateMap(p, item, barcode)),
        ].filter((m) => !!m) as CombiMap[];
    }

    const product = buildProductPlateMap(productPlate, item, barcode);
    return product ? [product] : [];
}

// nL
const UnitFactor = 1e9 * 1e3; // 1e3 comes from base unit being m**3

function buildSolventSourcePlateMap(
    plate: HTEPSourcePlate,
    item: HTEPInventoryItem,
    barcode?: string
): CombiMap | undefined {
    const volumes = plate.wells.map((w) =>
        w?.volume && w?.solvent === item.solvent ? roundValue(2, w.volume * UnitFactor) : 0
    );
    if (volumes.every((v) => v === 0)) return undefined;
    return {
        header: `Source Plate #${plate.tag! + 1}: ${plate.label ?? ''}`,
        item,
        barcode,
        map: prepareMap(volumes, plate.layout),
    };
}

function buildSolventProductPlateMap(plate: HTEPProductPlate, item: HTEPInventoryItem, barcode?: string) {
    const volumes: number[] = [];

    for (const well of plate.wells) {
        if (item.solvent !== well?.solvent || !well.product) {
            volumes.push(0);
            continue;
        }

        let vol = 0;
        for (const sample of Object.values(well.samples)) vol += sample.volume;
        vol = Math.max(well.product.volume - vol, 0);
        volumes.push(roundValue(2, vol * UnitFactor));
    }
    if (volumes.every((v) => v === 0)) return undefined;

    return { header: `Product Plate`, item, barcode, map: prepareMap(volumes, plate.layout) };
}

function buildProductPlateMap(plate: HTEPProductPlate, item: HTEPInventoryItem, barcode?: string) {
    const volumes: number[] = [];

    for (const well of plate.wells) {
        if (!well) {
            volumes.push(0);
            continue;
        }

        let vol = 0;
        for (const sample of Object.values(well.samples)) {
            if (
                sample.identifier !== item.identifier ||
                sample.solvent !== item.solvent ||
                sample.concentration !== item.concentration
            )
                continue;
            vol = sample.volume;
            break;
        }
        volumes.push(roundValue(2, vol * UnitFactor));
    }
    if (volumes.every((v) => v === 0)) return undefined;

    return { header: `Product Plate`, item, barcode, map: prepareMap(volumes, plate.layout) };
}

function prepareMap(wells: number[], layout: WellLayout) {
    const [w, h] = PlateDimensions[layout];
    const lines: string[] = [];
    for (let r = 0; r < h; r++) {
        lines.push(wells.slice(r * w, (r + 1) * w).join('\t'));
    }
    return lines.join('\n');
}
