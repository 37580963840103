import { prefixedUnitValue } from '../../../lib/util/units';
import { Sample } from '../../Compounds/compound-api';
import { HTEDAssets } from '../assets';
import { HTEPInventoryItem } from '../data-model';

export type HTEValidationEntry = ['warn' | 'error', string];

export function validateInventory(
    e: HTEPInventoryItem,
    barcode: string | undefined,
    assets: HTEDAssets
): HTEValidationEntry[] {
    const ret: HTEValidationEntry[] = [];
    if (!e.identifier) return ret;
    if (!barcode) {
        ret.push(['warn', 'No barcode assigned']);
        return ret;
    }

    const vial = assets.inventoryByBarcode.get(barcode!);

    if (!vial) {
        ret.push(['error', 'Vial not found, try to blame devs']);
        return ret;
    }

    return validateInventorySample(e, vial.sample, assets);
}

export function validateInventorySample(
    e: HTEPInventoryItem,
    sample: Sample | undefined,
    assets: HTEDAssets
): HTEValidationEntry[] {
    const ret: HTEValidationEntry[] = [];
    if (!e.identifier) return ret;

    if (!sample) {
        ret.push(['error', 'Vial is empty']);
        return ret;
    }

    const vialBatch = assets.entities.batchesById.get(sample?.batch_id!);
    if (!vialBatch) {
        ret.push(['error', 'Could not find vial batch, try to blame devs']);
        return ret;
    }

    const compoundId = assets.entities.getCompoundId(e.identifier);
    if (vialBatch.compound_id !== compoundId) {
        ret.push(['error', 'Vial contains different compound!']);
        return ret;
    }

    const batch = assets.entities.getBatch(e.identifier);

    let concTooLow = false;
    if (typeof sample.concentration === 'number' && e.concentration! > sample.concentration * 1.01) {
        ret.push(['error', 'Concentration too low']);
        concTooLow = true;
    }

    if (typeof sample.solvent_volume !== 'number') {
        const mass_g = e.volume * 1e3 * e.concentration! * vialBatch.formula_weight;
        if (sample.solute_mass! < mass_g) {
            ret.push(['warn', `Low inventory, req. ${prefixedUnitValue(mass_g, 'g')}`]);
        } else {
            ret.push(['warn', `Needs solubilization of ${prefixedUnitValue(mass_g, 'g')}`]);
        }
    } else if (sample.solvent_volume < e.volume) {
        ret.push(['warn', 'Low inventory']);
    }

    if (batch && batch.salt?.smiles !== vialBatch.salt?.smiles) {
        ret.push(['warn', 'Salt mismatch']);
    }

    if (
        typeof sample.concentration === 'number' &&
        e.solvent.toLowerCase() !== (sample.solvent?.toLowerCase() ?? 'dmso')
    ) {
        ret.push(['warn', 'Solvent mismatch']);
    }

    if (
        !concTooLow &&
        typeof sample.concentration === 'number' &&
        Math.abs(e.concentration! - sample.concentration!) > 1e-9
    ) {
        const ratio = e.concentration! / sample.concentration!;
        const reqVolume = e.volume * ratio;
        const solventVolume = e.volume - reqVolume;

        ret.push([
            'warn',
            `Concentrations do not match. Can combine with ${prefixedUnitValue(reqVolume, 'L', {
                factor: 1e3,
            })} with ${prefixedUnitValue(solventVolume, 'L', { factor: 1e3 })} of solvent to match.`,
        ]);
    }

    // TODO: are the other criteria to check for?

    return ret;
}
