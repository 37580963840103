const _BaseColorCache = new Map();

function getCSSVariableValue(name: string) {
    if (!_BaseColorCache.has(name)) {
        _BaseColorCache.set(name, getComputedStyle(document.body).getPropertyValue(name));
    }
    return _BaseColorCache.get(name);
}

export const BaseColors = {
    // NOTE: computed styles are not accessible until the document body is loaded
    // otherwise they will return empty string
    get body() {
        return getCSSVariableValue('--bs-body-color');
    },
    get primary() {
        return getCSSVariableValue('--bs-primary');
    },
    get secondary() {
        return getCSSVariableValue('--bs-secondary');
    },
    get danger() {
        return getCSSVariableValue('--bs-danger');
    },
    get gray100() {
        return getCSSVariableValue('--bs-gray-100');
    },
    get gray500() {
        return getCSSVariableValue('--bs-gray-500');
    },
    get gray900() {
        return getCSSVariableValue('--bs-gray-900');
    },
    get bodyBg() {
        return getCSSVariableValue('--bs-body-bg');
    },
    get bodyBgTransparent() {
        return getCSSVariableValue('--bs-body-bg-transparent');
    },
};
