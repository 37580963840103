import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { DataTableControl, DataTableGlobalFilter } from '../../../components/DataTable';
import { IconButton } from '../../../components/common/IconButton';
import useBehavior from '../../../lib/hooks/useBehavior';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { EnumerateButton } from './enumeration';
import {
    AddProductBlockButton,
    AddReactantListButton,
    AddReactantsButton,
    ClearReactantListButton,
    HTEDEnumerationModel,
    HTEDProductBlockModel,
    HTEDProductBlocksModel,
    HTEDReactantListModel,
    ImportProductBlockButton,
    RemoveBlockButton,
    RemoveReactantListButton,
} from './product-blocks';

export function ProductBlocks({ model }: { model: HTEDProductBlocksModel }) {
    useMountedModel(model);
    const current = useBehavior(model.state.current);

    return (
        <div className='d-flex h-100 flex-column me-3'>
            <SelectBlock model={model} current={current} />
            <div className='flex-grow-1 position-relative d-flex' style={{ overflow: 'hidden', overflowX: 'auto' }}>
                {current && <ProductBlock model={current} />}
            </div>
        </div>
    );
}

function SelectBlock({ model, current }: { model: HTEDProductBlocksModel; current?: HTEDProductBlockModel }) {
    const blocks = useBehavior(model.state.blocks);
    const readOnly = model.design.isSnapshot;

    return (
        <div className='hstack gap-2 mb-2'>
            {blocks.map((b, i) => (
                <Button
                    key={b.id}
                    onClick={() => model.state.current.next(b)}
                    size='sm'
                    variant={b === current ? 'primary' : 'outline-primary'}
                >
                    {b.label ?? `Block ${i + 1}`}
                </Button>
            ))}
            {!readOnly && <AddProductBlockButton model={model} />}
            <div className='m-auto' />
            {!readOnly && current && <RemoveBlockButton model={current} />}
            {!readOnly && current && <AddReactantListButton model={current} />}
            {!readOnly && current && <ImportProductBlockButton model={current} />}
            {!readOnly && current && <EnumerateButton model={current} />}
        </div>
    );
}

function ProductBlock({ model }: { model: HTEDProductBlockModel }) {
    useMountedModel(model);
    const lists = useBehavior(model.state.lists);
    const enumeation = useBehavior(model.state.enumeration);

    return (
        <div className='d-flex h-100 w-100 flex-column'>
            <div className='flex-grow-1 position-relative d-flex mb-2'>
                {lists.map((l) => (
                    <ReactantList key={l.reactantName} model={l} />
                ))}
                {enumeation && <EnumerationReactantList model={enumeation} />}
            </div>
        </div>
    );
}

function ReactantList({ model }: { model: HTEDReactantListModel }) {
    useMountedModel(model);
    useBehavior(model.table.version);
    const readOnly = model.block.products.design.isSnapshot;

    return (
        <div style={{ flexBasis: '33%' }} className='d-flex flex-column h-100 border-right border rounded me-2'>
            <div className='hstack gap-2 rounded-top p-1 ps-2 font-body-small htew-panel-header'>
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {model.reactantName}
                </div>
                <div className='m-auto' />
                {!readOnly && model.canRemove && <RemoveReactantListButton model={model} />}
                {!readOnly && <ClearReactantListButton model={model} />}
                {!readOnly && <AddReactantsButton model={model} />}
            </div>
            <div>
                <DataTableGlobalFilter
                    table={model.table}
                    selectOnFocus
                    controlClassName='font-body-small py-1 px-2'
                    controlStyle={{ borderLeft: 'none', borderBottom: 'none', borderRight: 'none', borderRadius: 0 }}
                />
            </div>
            <div className='position-relative flex-grow-1'>
                <DataTableControl height='flex' table={model.table} headerSize='xsm' className='rounded-bottom' />
            </div>
        </div>
    );
}

function EnumerationReactantList({ model }: { model: HTEDEnumerationModel }) {
    useMountedModel(model);
    useBehavior(model.table.version);
    const readOnly = model.block.products.design.isSnapshot;

    const { label, reaction_chemistry_id } = model.enumeration;

    return (
        <div style={{ flexBasis: '40%' }} className='d-flex flex-column h-100 border rounded'>
            <div className='hstack gap-2 rounded-top p-1 ps-2 font-body-small htew-panel-header'>
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {!!label && !!reaction_chemistry_id && `${label} (${reaction_chemistry_id})`}
                    {!!label && !reaction_chemistry_id && label}
                    {!label && !reaction_chemistry_id && 'Enumeration'}
                </div>
                <div className='m-auto' />
                {!readOnly && <IconButton icon={faTrash} onClick={model.remove} />}
            </div>
            <div>
                <DataTableGlobalFilter
                    table={model.table}
                    selectOnFocus
                    controlClassName='font-body-small py-1 px-2'
                    controlStyle={{ borderLeft: 'none', borderBottom: 'none', borderRight: 'none', borderRadius: 0 }}
                />
            </div>
            <div className='position-relative flex-grow-1'>
                <DataTableControl height='flex' table={model.table} headerSize='xsm' className='rounded-bottom' />
            </div>
        </div>
    );
}
