import { saveAs } from 'file-saver';
import log from 'loglevel';
import { BehaviorSubject, skip, Subject, Subscription } from 'rxjs';
import { DefaultFigureLayout, PlotlyFigure, PlotlyPointClickEventData } from '../../../components/Plot';
import { EcosystemService } from '../../../lib/services/ecosystem';
import { ToastService } from '../../../lib/services/toast';
import {
    assayValueTypeToString,
    isComboCGIAssay,
    isGaussianUncertaintyValue,
    isInequalityValue,
    isRangeValue,
    isUncertaintyValue,
    tryGetAssayValueGuess,
} from '../../../lib/assays/util';
import type {
    AssayCreateDetails,
    AssayGaussianUncertaintyValue,
    AssayGraph,
    AssayInequalityValue,
    AssayRangeValue,
    AssayUncertaintyValue,
    AssayValueCreate,
    AssayValueDetails,
    AssayValueFitSource,
    AssayValueType,
    BayesSigmoidFitDetails,
} from '../../../lib/assays/models';
import { AsyncQueue } from '../../../lib/util/async-queue';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { arrayMinMax } from '../../../lib/util/misc';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { Batch } from '../../Compounds/compound-api';
import { plateRowLabelToIndex } from '../../HTE/plate/utils';
import { AssayAPI, AssayDataPoints, AssayDetail, getAssayValueRetiredComment } from '../assay-api';
import { getAssayPlotYMinMaxValue, updateAssayPlot } from '../assay-common';
import {
    AssayValueCreateBayesian,
    BayesAPI,
    BayesIC50Result,
    BayesWorkflowError,
    getBayesCurves,
    getCustomFits,
    isBayes,
    isBayesSuccess,
    ReviewData,
} from './bayes-api';

export type AssayUploadSortBy = 'id' | 'value-asc' | 'value-desc' | 'bayes-failed' | 'plate-barcode' | 'assay';

export interface AssayUploadSummary {
    selected: number;
    notDetermined: number;
    total: number;
    done: number;
}

const PLOTS_PER_PAGE = 20;

export class AssayCurveQCModel {
    public data: ReviewData;

    readonly batches: AssayUploadBatchModel[];

    concentrationsNM: number[] = [];
    hasCRO = false;

    numPages: number = -1;

    readonly state = {
        currentPage: new BehaviorSubject<number>(0),
        view: new BehaviorSubject<AssayUploadBatchModel[]>([]),
        sortBy: new BehaviorSubject<AssayUploadSortBy | undefined>('id'),
        isBusy: new BehaviorSubject(false),
        summary: new BehaviorSubject<AssayUploadSummary>({ selected: 0, notDetermined: 0, total: 0, done: 0 }),
        canReview: new BehaviorSubject(false),
        comboCGIMax: new BehaviorSubject<number | null>(null),
        appliedComboCGIMax: new BehaviorSubject<boolean>(false),
    };

    get dataPoints(): AssayDataPoints[] {
        const assayIds = this.batches.map((b) => b.assay.id);
        const normalizedValues = this.batches.map((c) => c.normalizedData);
        return this.data.uploadData.map((d) => ({
            info: d.info,
            values: normalizedValues.filter((_, idx) => assayIds[idx] === d.info.id),
        }));
    }

    get assays(): AssayDetail[] {
        return this.data.uploadData.map((d) => d.info);
    }

    private async automateFit(action: (batch: AssayUploadBatchModel) => AssayValueCreate | undefined) {
        const updates: [AssayUploadBatchModel, AssayValueCreate][] = [];

        for (const b of this.batches) {
            // Skip for bayes
            if (b.assay_value.value_details.source === 'bayes_sigmoid_fit') continue;
            // Only apply automation to unselected and not done batches
            if (b.state.isDone.value || !b.state.isSelected.value) continue;

            const value = action(b);
            if (value) updates.push([b, value]);
        }

        if (updates.length === 0) {
            ToastService.show({
                type: 'info',
                message: 'Nothing to update',
                timeoutMs: 2500,
                id: 'qc-automation',
            });
            return;
        }

        const data = await AssayAPI.refit({ assay_values: updates.map(([_, v]) => v) });
        for (let i = 0; i < updates.length; i++) {
            updates[i][0].state.assay_value.next(data[i]);
        }

        ToastService.show({
            type: 'success',
            message: 'Automation applied',
            timeoutMs: 2500,
            id: 'qc-automation',
        });
    }

    applyGlobalSigmoidMinMax(options: { minValue: number | null; maxValue: number | null }) {
        return this.automateFit((batch) => ({
            ...batch.assay_value,
            details: {
                ...batch.assay_value.details,
                insight_sigmoid_fit_fix_min: options.minValue ?? undefined,
                insight_sigmoid_fit_fix_max: options.maxValue ?? undefined,
            },
        }));
    }

    applyAdjustSigmoidBounds({ minValue, maxValue }: { minValue: number | null; maxValue: number | null }) {
        return this.automateFit((batch) => {
            if (!batch.assay_value.graph || (minValue === null && maxValue === null)) return;

            const value = {
                ...batch.assay_value,
                details: { ...batch.assay_value.details },
            } satisfies AssayValueCreate;

            let [yMin, yMax] = getAssayPlotYMinMaxValue(batch.assay_value);

            // Use current manual min/max values if specified
            if (typeof value.details.insight_sigmoid_fit_fix_min === 'number') {
                yMin = value.details.insight_sigmoid_fit_fix_min;
            }
            if (typeof value.details.insight_sigmoid_fit_fix_max === 'number') {
                yMax = value.details.insight_sigmoid_fit_fix_max;
            }

            if (minValue !== null) {
                value.details.insight_sigmoid_fit_fix_min =
                    clampFitBound(minValue > yMin ? yMin : minValue) ?? undefined;
            }
            if (maxValue !== null) {
                value.details.insight_sigmoid_fit_fix_max =
                    clampFitBound(maxValue > yMax ? maxValue : yMax) ?? undefined;
            }

            return value;
        });
    }

    applyAdjustFittedValue({ minValueNM, maxValueNM }: { minValueNM: number | null; maxValueNM: number | null }) {
        // Unselect points if the predicted values falls outside the provided bounds and if switch to LT/GT mode
        const minValue = minValueNM !== null ? minValueNM * 1e-9 : null;
        const maxValue = maxValueNM !== null ? maxValueNM * 1e-9 : null;

        return this.automateFit((batch) => {
            if (
                !batch.assay_value.graph ||
                typeof batch.assay_value.value !== 'number' ||
                (minValue === null && maxValue === null)
            )
                return;

            let thresholdLow = Number.NEGATIVE_INFINITY;
            let thresholdHigh = Number.POSITIVE_INFINITY;

            if (minValue !== null && batch.assay_value.value < minValue) {
                thresholdLow = minValue;
            }
            if (maxValue !== null && batch.assay_value.value > maxValue) {
                thresholdHigh = maxValue;
            }

            const value = {
                ...batch.assay_value,
                graph: { ...batch.assay_value.graph, data: [] as AssayGraph[] },
                details: { ...batch.assay_value.details },
            } satisfies AssayValueCreate;

            let changed = false;
            for (const data of batch.assay_value.graph.data) {
                const mask = data.mask ? [...data.mask] : new Array(data.x.length).fill(true);
                for (let i = 0; i < data.x.length; i++) {
                    if (data.x[i] < thresholdLow || data.x[i] > thresholdHigh) {
                        if (mask[i]) changed = true;
                        mask[i] = false;
                    }
                }
                if (mask.every((v) => !v)) {
                    // Ignore changes if it would result in an empty curve
                    value.graph.data.push(data);
                } else {
                    value.graph.data.push({ ...data, mask });
                }
            }

            if (!changed) return;

            value.details.insight_value_source = 'lt_gt';
            return value;
        });
    }

    applyComboCGIMax() {
        const comboCGIMax = this.state.comboCGIMax.value;
        if (comboCGIMax !== null) this.state.appliedComboCGIMax.next(true);
        // NOTE: always use 0 for min for combo CGI calculations if we are actually
        // setting values and not null
        return this.applyGlobalSigmoidMinMax({
            minValue: comboCGIMax === null ? null : 0,
            maxValue: comboCGIMax,
        });
    }

    applyAdjustSlopeBounds(options: { minValue: number | null; maxValue: number | null }) {
        return this.automateFit((batch) => ({
            ...batch.assay_value,
            details: {
                ...batch.assay_value.details,
                insight_sigmoid_slope_fix_min: options.minValue ?? undefined,
                insight_sigmoid_slope_fix_max: options.maxValue ?? undefined,
            },
        }));
    }

    resetCurves = () => {
        for (const b of this.batches) {
            if (b.state.isSelected && !b.state.isDone.value) {
                b.reset();
            }
        }
        this.state.appliedComboCGIMax.next(false);
    };

    async finalizedDataPoints(): Promise<AssayDataPoints[]> {
        try {
            await EcosystemService.getEnvironment();
        } catch (err) {
            reportErrorAsToast('Could not determine Foundry environment', err);
        }

        const assayIds = this.batches.map((b) => b.assay.id);
        const finalizedValues = await AssayAPI.finalizeUpload({
            assay_values: this.batches.map((c) => c.normalizedData),
        });

        const data = this.data.uploadData.map((d) => ({
            info: d.info,
            values: finalizedValues.filter((_, idx) => assayIds[idx] === d.info.id),
            foundry_env: EcosystemService.environment.value?.name,
        }));

        for (const d of data) {
            delete d.info.shorthand;
            delete d.info.property_shorthand;
        }

        return data;
    }

    async uploadValues(): Promise<boolean> {
        try {
            const data = await this.finalizedDataPoints();
            const results = await Promise.all(data.map((d) => AssayAPI.create(d)));
            const allSuccess = results.every((r) => r);
            if (allSuccess && this.data.computation_id) await BayesAPI.done(this.data.computation_id);
            return allSuccess;
        } catch (err) {
            reportErrorAsToast('Error uploading values', err);
            return false;
        }
    }

    async saveJSON() {
        try {
            const data = await this.finalizedDataPoints();
            // NOTE: for now, only saving JSON if there is a single assay
            // multi-assay JSON formatting not supported
            if (data?.length !== 1) return;
            const json = JSON.stringify(data[0], null, 2);
            const blob = new Blob([json], { type: 'application/json' });
            saveAs(blob, `${this.assays.map((a) => a.shorthand).join('+')}-upload-${Date.now()}.json`);
        } catch (err) {
            log.error(err);
            ToastService.show({
                type: 'danger',
                message: 'Failed to finalize assay values',
            });
        }
    }

    syncView() {
        const next = [...this.batches];
        // if the sorting is by Bayes failure, we don't want to
        // re-sort when changing pages because this will move
        // plots the users have switched to LTGT or sigmoid, which is confusing
        const sortBy = this.state.sortBy.value;
        if (sortBy && sortBy !== 'bayes-failed') next.sort(SortFns[sortBy]);
        const currentPage = this.state.currentPage.value;
        const start = currentPage * PLOTS_PER_PAGE;
        const end = start + PLOTS_PER_PAGE;
        this.numPages = Math.ceil(this.batches.length / PLOTS_PER_PAGE);
        this.state.view.next(next.slice(start, end));
    }

    syncSummary() {
        this.state.summary.next({
            selected: this.batches.reduce((v, t) => v + (t.state.isSelected.value ? 1 : 0), 0),
            notDetermined: this.batches.reduce(
                (v, t) => v + (t.state.isSelected.value && t.isValueUndetermined ? 1 : 0),
                0
            ),
            total: this.batches.length,
            done: this.batches.reduce((v, t) => v + (t.state.isDone.value ? 1 : 0), 0),
        });
    }

    calculateComboCGIMax() {
        if (this.data.uploadData.length !== 1 || !isComboCGIAssay(this.data.uploadData[0].info)) return null;
        const maxOfAll = this.batches.reduce((maxY, batchModel) => {
            // don't consider assay values which have been deselected
            // for retirement
            if (!batchModel.state.isSelected.value) return maxY;
            const v = batchModel.state.assay_value.value;
            let newMax = maxY;
            if (v.graph) {
                // for this batch, build a distribution of y points
                // for each x-coordinate (they will not necessarily be
                // in order)
                // to be averaged, x-coordinates will be exactly the same
                const distribution: Record<string, number[]> = {};
                for (const { x, y, mask } of v.graph.data) {
                    for (let i = 0; i < x.length; i++) {
                        if (mask && !mask[i]) continue;
                        const xi = x[i];
                        const yi = y[i];
                        const key = `${xi}`;
                        if (!distribution[key]) distribution[key] = [];
                        distribution[key].push(yi);
                    }
                }
                // we want to average the y values of each x coordinate and
                // take the max of all of those averages
                for (const xCoord of Object.keys(distribution)) {
                    const yValues = distribution[xCoord];
                    const yAvg = yValues.reduce((a, b) => a + b, 0) / yValues.length;
                    if (yAvg > newMax) newMax = yAvg;
                }
            }
            return newMax;
        }, Number.NEGATIVE_INFINITY);
        return maxOfAll;
    }

    private subs: Subscription[] = [];
    dispose() {
        this.subs.forEach((s) => s.unsubscribe());
        this.subs = [];
        this.batches.forEach((c) => c.dispose());
    }

    constructor(reviewData: ReviewData) {
        this.data = reviewData;
        const valuesFlat = this.data.uploadData.flatMap((v) => v.values);
        this.hasCRO = valuesFlat.some((v) => !!v.value_details.cro_sigmoid_fit_details);
        const batches: AssayUploadBatchModel[] = [];
        let index = 0;
        for (const d of this.data.uploadData) {
            for (const v of d.values) {
                let bayes_result: AssayValueCreateBayesian | BayesWorkflowError | undefined;
                if (this.data.bayes_results) {
                    if (this.data.uploadData.length === 1) {
                        // only get Bayes results if we are uploading a single assay
                        bayes_result = this.data.bayes_results[index];
                    } else if (this.data.uploadData.length > 1) {
                        throw new Error('Multi-assay Bayes results not supported');
                    }
                }
                batches.push(
                    new AssayUploadBatchModel(this, d.info, v, index, this.data.identifierToBatchMap, bayes_result)
                );
                index++;
            }
        }
        this.batches = batches;
        this.syncView();

        if (reviewData.bayes_results) {
            this.state.sortBy.next('bayes-failed');
        } else if (reviewData.uploadData.length > 1) {
            this.state.sortBy.next('assay');
        }

        this.state.comboCGIMax.next(this.calculateComboCGIMax());

        this.subs.push(this.state.sortBy.subscribe(() => this.syncView()));
        this.subs.push(this.state.view.subscribe(() => this.syncSummary()));

        this.subs.push(
            this.state.currentPage.subscribe((p) => {
                if (p >= this.numPages - 1 && !this.state.canReview.value) {
                    this.state.canReview.next(true);
                }
            })
        );
    }
}

function compareId(a: AssayUploadBatchModel, b: AssayUploadBatchModel) {
    const x = a.assay_value.batch_identifier;
    const y = b.assay_value.batch_identifier;
    if (x === y) return 0;
    return x < y ? -1 : 1;
}

function _compareValue(a: AssayUploadBatchModel, b: AssayUploadBatchModel, sign: number) {
    const x = a.currentValue;
    const y = b.currentValue;
    const xOk = typeof x === 'number';
    const yOk = typeof y === 'number';
    if (xOk && yOk) {
        if (x === y) return sign * compareId(a, b);
        return sign * (x - y);
    }
    if (!xOk) return 1;
    if (!yOk) return -1;
    return sign * compareId(a, b);
}

function compareValueAsc(a: AssayUploadBatchModel, b: AssayUploadBatchModel) {
    return _compareValue(a, b, 1);
}

function compareValueDesc(a: AssayUploadBatchModel, b: AssayUploadBatchModel) {
    return _compareValue(a, b, -1);
}

function compareBayesFailed(a: AssayUploadBatchModel, b: AssayUploadBatchModel) {
    if (a.bayes_result && b.bayes_result) {
        if (!isBayesSuccess(a.bayes_result) && isBayesSuccess(b.bayes_result)) return -1;
        if (isBayesSuccess(a.bayes_result) && !isBayesSuccess(b.bayes_result)) return 1;
        return 0;
    }
    if (a.bayes_result && !b.bayes_result) return -1;
    if (!a.bayes_result && b.bayes_result) return 1;
    return 0;
}

const WellLocationRegex = /([A-Z]+)(\d+)/;
function _compareWellLocation(aWellLocation: string | undefined, bWellLocation: string | undefined) {
    if (aWellLocation === bWellLocation) return 0;
    if (aWellLocation === undefined) return 1;
    if (bWellLocation === undefined) return -1;
    const aWellLocationParts = aWellLocation.match(WellLocationRegex);
    if (!aWellLocationParts || aWellLocationParts.length < 3) {
        console.warn(`Invalid well location: ${aWellLocation}`);
        return aWellLocation < bWellLocation ? -1 : 1;
    }
    const bWellLocationParts = bWellLocation.match(WellLocationRegex);
    if (!bWellLocationParts || bWellLocationParts.length < 3) {
        console.warn(`Invalid well location: ${bWellLocation}`);
        return aWellLocation < bWellLocation ? -1 : 1;
    }
    const aRow = aWellLocationParts[1];
    const aNumber = +aWellLocationParts[2];
    const bRow = bWellLocationParts[1];
    const bNumber = +bWellLocationParts[2];
    if (aRow === bRow) return aNumber < bNumber ? -1 : 1;
    const aRowIndex = plateRowLabelToIndex(aRow);
    const bRowIndex = plateRowLabelToIndex(bRow);
    return aRowIndex < bRowIndex ? -1 : 1;
}

function comparePlateBarcode(a: AssayUploadBatchModel, b: AssayUploadBatchModel) {
    const aBarcode = a.state.assay_value.value.details.plate_barcode;
    const bBarcode = b.state.assay_value.value.details.plate_barcode;
    const aWellLocation = a.state.assay_value.value.details.min_well_location;
    const bWellLocation = b.state.assay_value.value.details.min_well_location;
    if (aBarcode === bBarcode) return _compareWellLocation(aWellLocation, bWellLocation);
    if (aBarcode === undefined) return 1;
    if (bBarcode === undefined) return -1;
    return aBarcode < bBarcode ? -1 : 1;
}

function compareAssayId(a: AssayUploadBatchModel, b: AssayUploadBatchModel) {
    return a.assay.id - b.assay.id;
}

const SortFns: Record<AssayUploadSortBy, (a: AssayUploadBatchModel, b: AssayUploadBatchModel) => number> = {
    id: compareId,
    'value-asc': compareValueAsc,
    'value-desc': compareValueDesc,
    'bayes-failed': compareBayesFailed,
    'plate-barcode': comparePlateBarcode,
    assay: compareAssayId,
};

export class AssayUploadBatchModel extends ReactiveModel {
    readonly batch: Batch | undefined;
    readonly assay: AssayDetail;
    readonly state = {
        assay_value: undefined as any as BehaviorSubject<AssayValueCreate>,
        figure: new BehaviorSubject<PlotlyFigure>({
            data: [],
            layout: DefaultFigureLayout,
        }),
        isSelected: new BehaviorSubject(true),
        isDone: new BehaviorSubject(false),
    };
    readonly historicValue: AssayValueType | undefined;

    private initialBayesResult: AssayValueCreateBayesian | BayesWorkflowError | undefined;

    readonly events = {
        pointClick: new Subject<PlotlyPointClickEventData>(),
    };

    get key() {
        return `${this.assay_value.batch_identifier}-${this.index}`;
    }

    get currentValue() {
        return this.getNumericValue(this.assay_value.value);
    }

    get xBoundsNM() {
        const { assay_value } = this;
        if (!assay_value.graph) return undefined;

        const xBounds: number[] = [];
        for (const { x: xs } of assay_value.graph.data) {
            xBounds.push(...arrayMinMax(xs));
        }

        const [min, max] = arrayMinMax(xBounds);
        return [this.convertToNM(min), this.convertToNM(max)];
    }

    private toNMConstant = 1;

    get batchId() {
        return this.assay_value.batch_identifier;
    }

    get assay_value() {
        return this.state.assay_value.value;
    }

    get allDatapointsSelected() {
        const { assay_value } = this;
        let allSelected = true;
        if (assay_value.graph) {
            for (const { mask } of assay_value.graph.data) {
                if (mask?.some((v) => !v)) {
                    allSelected = false;
                    break;
                }
            }
        }
        return allSelected;
    }

    get isValueUndetermined() {
        const { assay_value } = this;

        return (
            !this.state.isSelected.value ||
            assay_value.value_details.source === 'not_determined' ||
            (typeof assay_value.value === 'number' && assay_value.value < 0)
        );
    }

    get retiredComment() {
        return getAssayValueRetiredComment(this.assay_value, this.state.isSelected.value);
    }

    get normalizedData(): AssayValueCreate {
        const { assay_value } = this;
        return {
            ...assay_value,
            details: { ...assay_value.details, insight_is_selected: this.state.isSelected.value },
            retired_message: this.retiredComment,
        };
    }

    convertValueToNM(v?: AssayValueType) {
        if (typeof v === 'number') {
            if (v < 0) return undefined;
            return this.convertToNM(v);
        }
        if (typeof v === 'boolean') {
            return v;
        }
        if (isInequalityValue(v)) {
            return {
                ...v,
                value: this.convertToNM(v.value),
            } as AssayInequalityValue;
        }
        if (isRangeValue(v)) {
            return {
                ...v,
                lower_limit: this.convertToNM(v.lower_limit),
                upper_limit: this.convertToNM(v.upper_limit),
            } as AssayRangeValue;
        }
        if (isGaussianUncertaintyValue(v)) {
            return {
                value: this.convertToNM(v.value),
                lower_bound: this.convertToNM(v.lower_bound),
                upper_bound: this.convertToNM(v.upper_bound),
            } as AssayGaussianUncertaintyValue;
        }
        if (isUncertaintyValue(v)) {
            return {
                value: this.convertToNM(v.value),
                lower_bounds: v.lower_bounds.map((i) => this.convertToNM(i)),
                upper_bounds: v.upper_bounds.map((i) => this.convertToNM(i)),
            } as AssayUncertaintyValue;
        }
        return undefined;
    }

    formatValue(v?: AssayValueType) {
        const converted = this.convertValueToNM(v);
        return assayValueTypeToString(converted);
    }

    getNumericValue(v?: AssayValueType) {
        return tryGetAssayValueGuess(v);
    }

    convertToNM(v: number) {
        return this.toNMConstant * v;
    }

    get canReset() {
        const { assay_value } = this;
        return (
            !this.allDatapointsSelected ||
            typeof assay_value.details.insight_sigmoid_fit_fix_min === 'number' ||
            typeof assay_value.details.insight_sigmoid_fit_fix_max === 'number' ||
            assay_value.value_details.min_y_threshold !== 30 ||
            assay_value.value_details.max_y_threshold !== 70 ||
            (this.initialBayesResult && !this.bayes_result)
        );
    }

    reset() {
        this.bayes_result = this.initialBayesResult;
        const bayesAssayValue = getBayesAssayValue(this.bayes_result);
        if (bayesAssayValue) {
            this.state.assay_value.next(bayesAssayValue);
        } else {
            this.state.assay_value.next(this.initialData);
        }
    }

    private updateMask = (idx?: PlotlyPointClickEventData) => {
        const { assay_value } = this;
        if (!assay_value.graph || !idx || idx.curveNumber >= assay_value.graph.data.length) return;

        const data = assay_value.graph.data.map((g) => ({
            ...g,
            mask: g.mask ? [...g.mask] : g.x.map((x) => true),
        }));
        data[idx.curveNumber].mask[idx.pointIndex] = !data[idx.curveNumber].mask[idx.pointIndex];

        this.state.assay_value.next({
            ...assay_value,
            graph: {
                ...assay_value.graph,
                data,
            },
        });

        // if we edited the mask, we need to re-calculate the combo CGI maximum
        const comboCGIMax = this.model.state.comboCGIMax.value;
        if (comboCGIMax) {
            const newMax = this.model.calculateComboCGIMax();
            if (comboCGIMax !== newMax) {
                this.model.state.comboCGIMax.next(newMax);
                // if we previously applied the combo CGI max to the cards, update it
                const appliedComboCGIMax = this.model.state.appliedComboCGIMax.value;
                if (appliedComboCGIMax) this.model.applyComboCGIMax();
            }
        }
        this.updateFit();
    };

    setSigmoidFitBound(key: keyof AssayCreateDetails, value: string | undefined) {
        const { assay_value } = this;

        const bound = clampFitBound(value);

        this.state.assay_value.next({
            ...assay_value,
            details: {
                ...assay_value.details,
                [key]: bound,
            },
        });

        this.updateFit();
    }

    setLTGTBound(key: keyof AssayValueDetails, value: string | undefined) {
        const { assay_value } = this;

        const bound = clampFitBound(value);

        this.state.assay_value.next({
            ...assay_value,
            value_details: {
                ...assay_value.value_details,
                [key]: bound,
            },
        });

        this.updateFit();
    }

    updateFit() {
        this.updateFitQueue.execute(() => this._updateFit());
    }

    private updateFitQueue = new AsyncQueue({ singleItem: true });
    private async _updateFit() {
        try {
            this.model.state.isBusy.next(true);
            const data = await AssayAPI.refit({ assay_values: [this.assay_value] });
            if (data.length) this.state.assay_value.next(data[0]);
        } catch (err) {
            log.error(err);
            ToastService.show({
                id: 'assay-fit-error',
                type: 'danger',
                message: 'Failed to re-fit assay data',
            });
        } finally {
            this.model.state.isBusy.next(false);
        }
    }

    setValueSource(source: AssayValueFitSource) {
        const { assay_value } = this;
        if (assay_value.details.insight_value_source === source) return;

        let value: AssayValueType | undefined; // eslint-disable-line
        if (source === 'sigmoid_fit') value = assay_value.details.insight_sigmoid_fit_value;
        else if (source === 'cro') value = assay_value.details.insight_cro_value;
        else if (source === 'lt_gt') value = assay_value.details.insight_lt_gt_value;

        this.state.assay_value.next({
            ...assay_value,
            value: value ?? -1,
            details: {
                ...assay_value.details,
                insight_value_source: source,
            },
        });
        this.updateFit();
    }

    rejectBayesFit() {
        if (!this.bayes_result) return;
        this.bayes_result = undefined;
        this.subscribe(this.events.pointClick, this.updateMask);
        this.state.isSelected.next(true);
        this.updateFit();
    }

    constructor(
        public model: AssayCurveQCModel,
        assay: AssayDetail,
        private initialData: AssayValueCreate,
        private index: number,
        identifierToBatchMap: Record<string, Batch>,
        public bayes_result?: AssayValueCreateBayesian | BayesWorkflowError
    ) {
        super();

        this.initialBayesResult = this.bayes_result;
        if (this.bayes_result && isBayes(this.bayes_result)) {
            const { assay_value, bayesian_result } = this.bayes_result as AssayValueCreateBayesian;
            if (isBayesSuccess(this.bayes_result)) {
                this.state.assay_value = new BehaviorSubject(getBayesSuccessAssayValue(assay_value, bayesian_result));
            } else if (assay_value) {
                this.state.assay_value = new BehaviorSubject(getBayesFailAssayValue(assay_value));
                // if source is 'not_determined', deselect so the value
                // gets retired by default
                if (this.state.assay_value.value.details.insight_value_source === 'not_determined') {
                    this.state.isSelected.next(false);
                }
            } else {
                this.state.assay_value = new BehaviorSubject(this.initialData);
                this.state.isSelected.next(false);
            }
        } else {
            this.state.assay_value = new BehaviorSubject(this.initialData);
            if (this.initialData.retired_on) {
                this.state.isSelected.next(false);
            }
        }
        this.assay = assay;
        this.batch = identifierToBatchMap[initialData.batch_identifier];

        const historicStore = model.data.historicValues;
        const rowIdx = historicStore.findValueIndex('identifier', this.initialData.batch_identifier.split('-')[0]);
        if (historicStore.hasColumn(this.assay.id)) {
            this.historicValue = historicStore.getValue(this.assay.id, rowIdx);
        }

        if (initialData.graph?.x_units === 'M') {
            this.toNMConstant = 1e9;
        } else if (initialData.graph?.x_units === 'mM') {
            this.toNMConstant = 1e6;
        } else if (initialData.graph?.x_units === 'uM') {
            this.toNMConstant = 1e3;
        }

        this.subscribe(this.state.assay_value, () => {
            const bayesCurves = getBayesCurves([this.bayes_result]);
            updateAssayPlot(this.assay, [this.assay_value], this.state.figure, {
                offsetCloseValues: true,
                showAlternateFit: true,
                customFits: this.bayes_result ? getCustomFits(bayesCurves) : undefined,
            });
        });

        if (!this.bayes_result) this.subscribe(this.events.pointClick, this.updateMask);

        this.subscribe(this.state.assay_value.pipe(skip(1)), () => model.syncSummary());
        this.subscribe(this.state.isSelected.pipe(skip(1)), () => model.syncSummary());
        this.subscribe(this.state.isDone.pipe(skip(1)), () => model.syncSummary());
    }
}

export function clampFitBound(value: string | number | null | undefined) {
    let bound: number | null = +value!;

    if (typeof value !== 'number' && (!value || value.trim().length === 0)) {
        bound = null;
    }
    if (!Number.isFinite(bound)) {
        bound = null;
    }

    if (bound! < -40) bound = -40;
    else if (bound! > 300) bound = 300;

    return bound;
}

function getBayesAssayValue(bayesResult?: AssayValueCreateBayesian | BayesWorkflowError): AssayValueCreate | undefined {
    if (!bayesResult) return;
    const { assay_value, bayesian_result } = bayesResult as AssayValueCreateBayesian;
    if (isBayesSuccess(bayesResult)) return getBayesSuccessAssayValue(assay_value, bayesian_result);
    if (assay_value) return getBayesFailAssayValue(assay_value);
}

function getBayesSuccessAssayValue(assayValue: AssayValueCreate, bayesIC50Result?: BayesIC50Result): AssayValueCreate {
    // a successful Bayesian result will have curve data
    return {
        ...assayValue,
        value: bayesIC50Result!.curve.value,
        value_details: {
            source: 'bayes_sigmoid_fit',
            bayes_sigmoid_fit_details: {
                success: bayesIC50Result!.success,
                min_y: bayesIC50Result!.curve.min_y,
                max_y: bayesIC50Result!.curve.max_y,
                slope: bayesIC50Result!.curve.slope,
                value: bayesIC50Result!.curve.value,
            } as BayesSigmoidFitDetails,
        },
    } as AssayValueCreate;
}

function getBayesFailAssayValue(assayValue: AssayValueCreate): AssayValueCreate {
    // an unsuccessful Bayesian result could still return an assay value
    // if it failed to find a fit but didn't explicitly error
    // or not, if it errored out
    const isLTGT = isInequalityValue(assayValue.value);
    const valueSource = isLTGT ? 'lt_gt' : 'not_determined';
    return {
        ...assayValue,
        details: {
            ...assayValue.details,
            insight_value_source: valueSource,
        },
    } as AssayValueCreate;
}
