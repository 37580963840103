import api from '../../../api';
import { DataTableStore } from '../../../components/DataTable';
import type {
    AssayUncertaintyValue,
    AssayValueCreate,
    AssayValueType,
    BayesSigmoidFitDetails,
} from '../../../lib/assays/models';
import { AssayFormattingOptions, DefaultConfidenceInterval, UQConfidenceIntervals } from '../../../lib/assays/util';
import { decodeEntosMsgpack } from '../../../lib/util/serialization';
import { WorkflowComputation } from '../../../lib/models/computation-data';
import { Batch } from '../../Compounds/compound-api';
import type { AssayDataPoints, AssayDetail } from '../assay-api';

interface BayesIC50Curve {
    value: AssayUncertaintyValue;
    min_y: AssayUncertaintyValue;
    max_y: AssayUncertaintyValue;
    slope: AssayUncertaintyValue;
}

export function bayesIC50toNM(value: AssayUncertaintyValue): AssayUncertaintyValue {
    return {
        value: value.value * 1e9,
        lower_bounds: value.lower_bounds.map((v) => v * 1e9),
        upper_bounds: value.upper_bounds.map((v) => v * 1e9),
    };
}

export interface BayesWorkflowError {
    type: 'err';
    message: string;
}

export interface BayesIC50Result {
    curve: BayesIC50Curve;
    success: boolean;
    details: Record<string, any>;
}

export interface AssayValueCreateBayesian {
    assay_value: AssayValueCreate;
    bayesian_result?: BayesIC50Result;
    // NOTE: Currently, this field seems to always be false
    // The AssayValueCreateBayesian model initializes it to false,
    // and it might never be properly updated.
    // Ignore, do not use to determine validity of results.
    valid: boolean;
}

export interface ReviewData {
    uploadData: AssayDataPoints[];
    identifierToBatchMap: Record<string, Batch>;
    computation_id?: string;
    bayes_results?: (AssayValueCreateBayesian | BayesWorkflowError)[];
    historicValues: DataTableStore<{ identifier: string; [assay_id: number]: AssayValueType }>;
}

interface BayesComputationResult {
    assay: AssayDetail;
    assay_shorthand: string;
    assay_property_shorthand: string;
    bayes_results: (AssayValueCreateBayesian | BayesWorkflowError)[];
    values: AssayValueCreate[];
}

export function isBayesError(bayes_result?: AssayValueCreateBayesian | BayesWorkflowError) {
    if (!bayes_result) return false;
    return (bayes_result as BayesWorkflowError).type !== undefined;
}

export function isBayes(bayes_result?: AssayValueCreateBayesian | BayesWorkflowError) {
    if (!bayes_result) return false;
    if ((bayes_result as BayesWorkflowError).message) return true;
    return (bayes_result as AssayValueCreateBayesian).assay_value !== undefined;
}

export function isBayesFail(bayes_result?: AssayValueCreateBayesian | BayesWorkflowError) {
    if (!bayes_result) return false;
    if (isBayesError(bayes_result)) return false;
    const result = bayes_result as AssayValueCreateBayesian;
    if (!result.bayesian_result) return true;
    return result.bayesian_result?.success === false;
}

export function isBayesSuccess(bayes_result?: AssayValueCreateBayesian | BayesWorkflowError) {
    if (!bayes_result) return false;
    if (isBayesError(bayes_result)) return false;
    const result = bayes_result as AssayValueCreateBayesian;
    if (!result.bayesian_result) return false;
    return result.bayesian_result?.success === true;
}

export function getBayesCurves(bayes_results?: (AssayValueCreateBayesian | BayesWorkflowError | undefined)[]) {
    if (!bayes_results) return undefined;
    return bayes_results
        .filter((result) => isBayesSuccess(result))
        .map(
            (result?: AssayValueCreateBayesian | BayesWorkflowError) =>
                (result as AssayValueCreateBayesian).bayesian_result!.curve
        );
}

export function getCustomFits(
    bayes_curves?: (BayesIC50Curve | BayesSigmoidFitDetails)[],
    options?: AssayFormattingOptions
) {
    if (!bayes_curves) return undefined;
    const confidenceIdx = UQConfidenceIntervals.indexOf(options?.confidenceInterval ?? DefaultConfidenceInterval);
    return bayes_curves.map((curve) => [
        {
            fit: {
                min: curve.min_y.value,
                max: curve.max_y.value,
                slope: curve.slope.value,
                value: curve.value.value,
                r2: 0,
            },
            width: 1.5,
            label: 'Average',
            isMain: true,
        },
        {
            fit: {
                min: curve.min_y.lower_bounds[confidenceIdx],
                max: curve.max_y.lower_bounds[confidenceIdx],
                slope: curve.slope.lower_bounds[confidenceIdx],
                value: curve.value.lower_bounds[confidenceIdx],
                r2: 0,
            },
            width: 1,
            label: 'Lower',
            color: '#17b9d5',
        },
        {
            fit: {
                min: curve.min_y.upper_bounds[confidenceIdx],
                max: curve.max_y.upper_bounds[confidenceIdx],
                slope: curve.slope.upper_bounds[confidenceIdx],
                value: curve.value.upper_bounds[confidenceIdx],
                r2: 0,
            },
            width: 1,
            label: 'Upper',
            color: '#17b9d5',
        },
    ]);
}

export const BayesAPI = {
    computations: async (): Promise<WorkflowComputation[]> => {
        const { data } = await api.client.get('assays/bayes/computations', {
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data, { eoi: 'strip' });
    },
    result: async (id: string): Promise<BayesComputationResult> => {
        const { data } = await api.client.get(`assays/bayes/computations/${id}`, {
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data, { eoi: 'strip' });
    },
    cancel: async (id: string) => {
        await api.client.post(`assays/bayes/computations/${id}/cancel`, {
            responseType: 'arraybuffer',
        });
    },
    remove: async (id: string) => {
        await api.client.delete(`assays/bayes/computations/${id}`, {
            responseType: 'arraybuffer',
        });
    },
    done: async (id: string) => {
        await api.client.post(`assays/bayes/computations/${id}/done`, {
            responseType: 'arraybuffer',
        });
    },
};
