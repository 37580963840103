import { faInfoCircle, faCheckCircle, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { LabeledInput, TextInput } from '../../../components/common/Inputs';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import useBehaviorSubject from '../../../lib/hooks/useBehaviorSubject';
import { AuthService } from '../../../lib/services/auth';
import { DialogService } from '../../../lib/services/dialog';
import { parseFileSystemDate, parseYYYY_MM_DDDate } from '../../../lib/util/dates';
import { trimValue } from '../../../lib/util/validators';
import { HTEFinalizeFormOptions } from '../experiment-api';
import { EXPERIMENT_ACKNOWLEDGEMENT, HTEFinalization } from '../experiment-data';
import { HTEExperimentModel } from '../experiment-model';

export function FinalizeStep({ model }: { model: HTEExperimentModel }) {
    return (
        <div className='hte-experiment-finalize-step mt-3 mb-3'>
            <div className='insight-page-header p-4 m-auto'>
                <h2>
                    Finalize experiment
                    <br />
                </h2>
                <span className='text-secondary'>
                    Upload a crude plate barcode to finalize this experiment and proceed to signing. Only experiment
                    settings, procedure, and observations will be editable after finalization.
                </span>
                <Button
                    onClick={() => model.finalize.saveJSON()}
                    size='sm'
                    variant='link'
                    title='Download JSON file with information about the experiment, crude plate, and reactions.'
                >
                    <FontAwesomeIcon icon={faFileDownload} className='me-1' /> Download JSON
                </Button>
            </div>

            <div className='insight-page-content p-4 m-auto'>
                <FinalizeView model={model} />
            </div>
        </div>
    );
}

function FinalizeView({ model }: { model: HTEExperimentModel }) {
    const unassignedBatches = model.finalize.getUnassignedBatches();
    if (unassignedBatches.size > 0) {
        return (
            <div className='hte-experiment-form'>
                <Alert key='signature-warning' variant='dark' className='mt-2'>
                    <FontAwesomeIcon icon={faInfoCircle} className='me-2' />
                    The experiment can only be finalized after all reactants have a batch identifier assigned. This can
                    be done in the ECM step.
                </Alert>
            </div>
        );
    }

    return (
        <div className='vstack gap-2'>
            <Finalize model={model} />
            <UploadPurifiedProductPlate model={model} />
        </div>
    );
}

function ExecutedOnInput({ model }: { model: HTEExperimentModel }) {
    const executedOn = useBehavior(model.finalize.state.executedOn);
    const date = parseYYYY_MM_DDDate(executedOn);

    return (
        <TextInput
            value={executedOn}
            tryUpdateValue={trimValue}
            setValue={(v) => model.finalize.state.executedOn.next(v)}
            validation={!date ? 'Invalid date' : ''}
            immediate
        />
    );
}

function CrudePlateBarcode({ model }: { model: HTEExperimentModel }) {
    const barcode = useBehavior(model.finalize.state.crudePlateBarcode);

    return (
        <TextInput
            value={barcode}
            tryUpdateValue={trimValue}
            setValue={(v) => model.finalize.state.crudePlateBarcode.next(v)}
            validation={barcode.length === 0 ? 'Enter plate barcode' : ''}
            immediate
        />
    );
}

function SyncFoundryUpload({
    model,
    finalization,
    formInvalid,
}: {
    model: HTEExperimentModel;
    finalization: HTEFinalizeFormOptions;
    formInvalid: boolean;
}) {
    const progressSubject = useBehaviorSubject('');
    const progress = useBehavior(progressSubject);
    const [state, upload] = useAsyncAction();
    const barcode = useBehavior(model.finalize.state.crudePlateBarcode);
    const executedOn = useBehavior(model.finalize.state.executedOn);

    const isDisabled = formInvalid || state.isLoading || barcode.length === 0 || !parseYYYY_MM_DDDate(executedOn);

    const onClick = () => {
        DialogService.open({
            type: 'confirm',
            onConfirm: () => upload(model.finalize.finalize(finalization, progressSubject)),
            title: 'Confirm Upload',
            text: (
                <>
                    <p>
                        Are you sure you want to upload the crude product plate to Foundry and finalize the experiment?
                    </p>
                    <p className='text-warning'>
                        This action is irreversible and the experiment can no longer be edited after this step!
                    </p>
                    <p>
                        Executed On: <b>{executedOn}</b>
                        <br />
                        Crude Plate Barcode: <b>{barcode}</b>
                    </p>
                </>
            ),
            confirmText: 'Upload',
        });
    };

    return (
        <div className='text-end'>
            <Button onClick={onClick} disabled={isDisabled}>
                {state.isLoading && (
                    <>
                        <Spinner className='me-3' size='sm' animation='border' role='status' />
                        {progress}
                    </>
                )}
                {!state.isLoading && 'Finalize Experiment'}
            </Button>
        </div>
    );
}

export function Finalize({ model }: { model: HTEExperimentModel }) {
    const info = useBehavior(model.state.info);

    return (
        <div className='hte-experiment-form vstack gap-2'>
            <h5>{info.finalization ? 'Finalized by' : 'Upload crude plate and lock the experiment'}</h5>
            {!info.finalization && <CreateFinalization model={model} />}
            {info.finalization && <FinalizationInfo model={model} finalization={info.finalization} />}
        </div>
    );
}

function FinalizationInfo({ model, finalization }: { model: HTEExperimentModel; finalization: HTEFinalization }) {
    const info = useBehavior(model.state.info);
    const plate = useBehavior(model.finalize.state.crudePlate);

    return (
        <div className='hte-experiment-form vstack gap-2 position-relative'>
            <LabeledInput label='Finalized by'>
                {finalization.full_name} <span className='text-secondary'>({finalization.finalized_by})</span>
            </LabeledInput>
            <LabeledInput label='Comment'>
                <span className='text-secondary'>{finalization.comment ?? ''}</span>
            </LabeledInput>
            <LabeledInput key='executed-on' label='Executed On'>
                {info.executed_on ? parseFileSystemDate(info.executed_on).toDateString() : 'n/a'}
            </LabeledInput>
            <LabeledInput key='plate' label='Crude Plate Barcode'>
                {plate?.barcode ?? '-'}
            </LabeledInput>
            <LabeledInput label='' key='upload'>
                <div>
                    <FontAwesomeIcon size='sm' icon={faCheckCircle} className='me-2' />
                    Plate has been successfully uploaded to Foundry.
                </div>
            </LabeledInput>
            <LabeledInput label='Date'>
                {parseFileSystemDate(finalization.finalized_on).toLocaleDateString()}
            </LabeledInput>
        </div>
    );
}

function validateFinalizationData(data: HTEFinalizeFormOptions): {
    [K in keyof HTEFinalizeFormOptions]: string | undefined;
} {
    return {
        full_name:
            data.full_name.length > 0 && data.full_name.split(' ').length < 2
                ? 'Invalid Name'
                : data.full_name.length === 0
                ? 'Enter Name'
                : '',
        acknowledged_policy: undefined,
        comment: undefined,
    };
}

function CreateFinalization({ model }: { model: HTEExperimentModel }) {
    const info = useBehavior(model.state.info);
    const [data, setData] = useState<HTEFinalizeFormOptions>({
        full_name: '',
        acknowledged_policy: false,
        comment: undefined,
    });
    const createdBy = info.created_by.toLowerCase();
    const account = useBehavior(AuthService.account);
    const username = account?.username.toLowerCase();

    const validation = validateFinalizationData(data);
    const formInvalid = Object.values(validation).some((v) => !!v) || !data.acknowledged_policy;

    if (username !== createdBy) {
        return (
            <Alert key='signature-warning' variant='dark' className='mt-2'>
                <FontAwesomeIcon icon={faInfoCircle} className='me-2' />
                This experiment was created by {createdBy} and only they can finalize it.
            </Alert>
        );
    }

    return (
        <>
            <LabeledInput label='Logged in User'>{account?.username}</LabeledInput>
            <LabeledInput label='Date'>{new Date().toLocaleDateString()}</LabeledInput>
            <LabeledInput label='Full Name'>
                <TextInput
                    value={data.full_name}
                    tryUpdateValue={trimValue}
                    placeholder={account?.name ?? 'Enter your full name to finalize the experiment'}
                    setValue={(v) => setData({ ...data, full_name: v })}
                    validation={validation.full_name}
                />
            </LabeledInput>
            <LabeledInput label='Comment'>
                <TextInput
                    textarea
                    value={data.comment}
                    tryUpdateValue={trimValue}
                    placeholder='Enter any comments about the experiment'
                    setValue={(v) => setData({ ...data, comment: v })}
                    validation={validation.comment}
                />
            </LabeledInput>
            <LabeledInput key='executed-on' label='Executed On (yyyy/mm/dd)'>
                <ExecutedOnInput model={model} />
            </LabeledInput>
            <LabeledInput key='plate' label='Crude Plate Barcode'>
                <CrudePlateBarcode model={model} />
            </LabeledInput>
            <LabeledInput label=''>
                <Form.Check
                    id='hte-signature-finalize'
                    label={EXPERIMENT_ACKNOWLEDGEMENT}
                    type='checkbox'
                    checked={data.acknowledged_policy}
                    onChange={() => setData({ ...data, acknowledged_policy: !data.acknowledged_policy })}
                />
            </LabeledInput>
            <SyncFoundryUpload model={model} finalization={data} formInvalid={formInvalid} />
        </>
    );
}

function PurifiedPlateBarcode({ model }: { model: HTEExperimentModel }) {
    const isLocked = useBehavior(model.state.isLocked);
    const barcode = useBehavior(model.finalize.state.purifiedPlateBarcode);

    return (
        <TextInput
            value={barcode}
            tryUpdateValue={trimValue}
            setValue={(v) => model.finalize.state.purifiedPlateBarcode.next(v)}
            validation={barcode.length === 0 ? 'Enter plate barcode' : ''}
            immediate
            disabled={isLocked}
        />
    );
}

function PurifiedPlateUpload({ model }: { model: HTEExperimentModel }) {
    const [state, upload] = useAsyncAction();
    const barcode = useBehavior(model.finalize.state.purifiedPlateBarcode);
    const isLocked = useBehavior(model.state.isLocked);

    const isDisabled = state.isLoading || barcode.length === 0 || isLocked;

    const onClick = () => {
        DialogService.open({
            type: 'confirm',
            onConfirm: () => upload(model.finalize.uploadPurifiedProductPlateBarcode()),
            title: 'Confirm Upload',
            text: (
                <>
                    <p>Are you sure you want to upload the purified product plate to Foundry?</p>
                    <p>
                        Purified Plate Barcode: <b>{barcode}</b>
                    </p>
                </>
            ),
            confirmText: 'Upload',
        });
    };

    return (
        <div className='text-end'>
            <Button onClick={onClick} disabled={isDisabled}>
                {state.isLoading && <Spinner className='me-3' size='sm' animation='border' role='status' />}
                {!state.isLoading && 'Upload Purified Plate'}
            </Button>
        </div>
    );
}

function UploadPurifiedProductPlate({ model }: { model: HTEExperimentModel }) {
    const info = useBehavior(model.state.info);
    const purifiedPlateBarcode = useBehavior(model.finalize.state.purifiedPlateBarcode);

    return (
        <div className='hte-experiment-form vstack gap-2'>
            <h5>{info.purified_product_plate_id ? 'Purified Product Plate' : 'Upload purified product plate'}</h5>
            {!info.finalization && (
                <Alert key='signature-warning' variant='dark' className='mt-2'>
                    <FontAwesomeIcon icon={faInfoCircle} className='me-2' />
                    Finalize experiment before uploading a purified product plate.
                </Alert>
            )}
            {info.finalization && !info.purified_product_plate_id && (
                <>
                    <LabeledInput key='purified-plate' label='Purified Plate Barcode'>
                        <PurifiedPlateBarcode model={model} />
                    </LabeledInput>
                    <PurifiedPlateUpload model={model} />
                </>
            )}
            {info.purified_product_plate_id && (
                <LabeledInput label='Purified Product Plate'>{purifiedPlateBarcode}</LabeledInput>
            )}
        </div>
    );
}
