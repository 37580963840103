import { BehaviorSubject } from 'rxjs';
import { groupByPreserveOrder, memoizeLatest } from '../../../lib/util/misc';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { PlateVisualLabels, PlateVisualModel } from '../../HTE/plate/PlateVisual';
import { columnMajorIndexToRowMajorIndex } from '../../HTE/plate/utils';
import {
    HTEDReaction,
    HTEDReactionIdT,
    HTEPInstructionT,
    HTEPMessage,
    HTEProtocol,
    HTEPWorklist,
    HTEPWorklistKeyT,
    HTERAddReactant,
} from '../data-model';
import type { HTE2MSModel } from '../model';
import {
    DefaultPlateColoringOptions,
    getPlateColoring,
    getPlateColoringOptions,
    PlateColoringOptions,
} from '../utils/plate-coloring';
import { WorkflowStatus } from '../utils/workflow-status';
import { HTE2MSReagentsModel } from './reagents';

export interface ProtocolMessageGroup {
    message: string;
    reactionIds: HTEDReactionIdT[];
    group: HTEPMessage[];
}

export class HTE2MSProtocolModel extends ReactiveModel {
    reagents = new HTE2MSReagentsModel(this);
    wellToReaction = new Map<number, HTEDReaction | undefined>();

    state = {
        protocol: new BehaviorSubject<HTEProtocol>(EmptyProtocol),
        messages: new BehaviorSubject<{ warnings: ProtocolMessageGroup[]; errors: ProtocolMessageGroup[] }>({
            warnings: [],
            errors: [],
        }),
        plateVisual: new BehaviorSubject<PlateVisualModel>(new PlateVisualModel(96, { singleSelect: true })),
        coloring: new BehaviorSubject<[current: string, options: PlateColoringOptions]>([
            DefaultPlateColoringOptions.options[0][0],
            DefaultPlateColoringOptions,
        ]),
        status: new BehaviorSubject<WorkflowStatus>('blank'),
    };

    private reactionToWell = new Map<HTEDReactionIdT, number>();

    get plateVisual() {
        return this.state.plateVisual.value;
    }

    get data() {
        return this.state.protocol.value;
    }

    get layout() {
        return this.model.design.labware.product.layout ?? 96;
    }

    private _worklistMap = memoizeLatest(
        (protocol: HTEProtocol) => new Map<HTEPWorklistKeyT, HTEPWorklist>(protocol.worklists.map((w) => [w.key, w]))
    );
    get worklistMap() {
        return this._worklistMap(this.data);
    }

    private initDefaultLayout() {
        const reactions = this.model.design.reactionMap;
        const { layout } = this;
        for (let i = 0; i < this.data.reaction_ids.length; i++) {
            if (i >= layout) break;

            const id = this.data.reaction_ids[i];
            const wellIdx = columnMajorIndexToRowMajorIndex(layout, i);
            this.reactionToWell.set(id, wellIdx);
            this.wellToReaction.set(wellIdx, reactions.get(id));
        }
    }

    private initLayout() {
        const reactions = this.model.design.reactionMap;

        this.reactionToWell.clear();
        this.wellToReaction.clear();

        const plate = this.data.plates?.[0];
        if (!plate) {
            this.initDefaultLayout();
            return;
        }

        for (const [rid, wellIdx] of Object.entries(plate.reaction_wells)) {
            const id = rid as HTEDReactionIdT;
            this.reactionToWell.set(id, wellIdx);
            this.wellToReaction.set(wellIdx, reactions.get(id));
        }
    }

    private initPlate() {
        const { layout } = this;
        if (this.plateVisual.layout !== layout) {
            this.state.plateVisual.next(new PlateVisualModel(layout, { singleSelect: true }));
        }

        this.initLayout();

        const labels: PlateVisualLabels = new Array(this.plateVisual.layout).fill(null);

        const coloring = getPlateColoringOptions(this.model.design.all);
        const currentColoring = this.state.coloring.value;
        this.state.coloring.next([
            currentColoring[0] in coloring.definitions ? currentColoring[0] : coloring.options[0][0],
            coloring,
        ]);

        if (!this.data.reaction_ids) {
            this.plateVisual.state.labels.next(labels);
            return;
        }

        const withError = new Set(this.data.errors.map((m) => m.reaction_id));
        const withWarning = new Set(this.data.warnings.map((m) => m.reaction_id));

        const labelColor = 'rgba(255, 255, 255, 0.75)';
        for (const id of this.data.reaction_ids) {
            const wellIdx = this.reactionToWell.get(id)!;

            const hasError = withError.has(id);
            const hasWarning = withWarning.has(id);

            if (hasError) labels[wellIdx] = { color: labelColor, text: '!' };
            else if (hasWarning) labels[wellIdx] = { color: labelColor, text: '?' };
            else labels[wellIdx] = { color: labelColor, text: '✓' };
        }

        this.plateVisual.state.labels.next(labels);
    }

    private syncColoring() {
        const currentColoring = this.state.coloring.value;
        const option = currentColoring[1].definitions[currentColoring[0]];
        const colors = getPlateColoring({
            reactions: this.data.reaction_ids ? this.model.design.all : [],
            layout: this.plateVisual.layout,
            reactionToWell: this.reactionToWell,
            option,
        });
        this.plateVisual.state.colors.next(colors);
    }

    highlightMessageGroup(group?: ProtocolMessageGroup) {
        if (!group) {
            this.plateVisual.state.highlight.next([]);
            return;
        }

        const wells = this.reactionToWell;
        const set = new Set<number | undefined>();
        for (const id of group.reactionIds) {
            set.add(wells.get(id));
        }
        set.delete(undefined);
        this.plateVisual.state.highlight.next(Array.from(set) as number[]);
    }

    highlight(instructions?: HTEPInstructionT[]) {
        if (!instructions?.length || !this.data.reaction_ids.length) {
            this.plateVisual.state.highlight.next([]);
            return;
        }

        const wells = this.reactionToWell;
        const set = new Set<number | undefined>();
        for (const i of instructions) {
            if (i.kind === 'transfer' || i.kind === 'solution-transfer' || i.kind === 'backfill') {
                set.add(wells.get(i.reaction_id));
            }
        }
        set.delete(undefined);
        this.plateVisual.state.highlight.next(Array.from(set) as number[]);
    }

    select(instruction: HTEPInstructionT) {
        if (
            instruction.kind !== 'transfer' &&
            instruction.kind !== 'solution-transfer' &&
            instruction.kind !== 'backfill'
        )
            return;
        const well = this.reactionToWell.get(instruction.reaction_id);
        if (typeof well === 'number') this.plateVisual.selectIndices([well]);
    }

    getReactionInfo(index: number) {
        const r = this.wellToReaction.get(index);
        if (!r) return;

        const msd = r.template.instructions.find((i) => i.kind === 'add' && i.reactant_kind === 'msd') as
            | HTERAddReactant
            | undefined;
        const bb = r.template.instructions.find((i) => i.kind === 'add' && i.reactant_kind === 'bb') as
            | HTERAddReactant
            | undefined;
        const reagents = r.template.instructions.filter(
            (i) => i.kind === 'add' && i !== msd && i !== bb
        ) as HTERAddReactant[];
        const product = r.product_enumeration?.substance_id
            ? this.model.assets.entities.substancesById.get(r.product_enumeration?.substance_id!)?.smiles
            : this.model.assets.entities.getStructure(r.product_identifier!);

        const msdSmiles = this.model.assets.entities.getStructure(msd?.identifier!);
        const bbSmiles = this.model.assets.entities.getStructure(bb?.identifier!);
        const rSmiles = reagents
            .map((x) => this.model.assets.entities.getStructure(x.identifier!))
            .filter((x) => !!x)
            .join('.');

        const main = [msdSmiles, bbSmiles].filter((s) => !!s).join('.');
        const smiles = `${main}>${rSmiles}>${product ?? ''}`;

        return {
            reaction: r,
            smiles: smiles !== '>>' ? smiles : undefined,
        };
    }

    clear() {
        this.setProtocol(EmptyProtocol);
    }

    setProtocol(protocol: HTEProtocol) {
        this.reagents.update(protocol);
        this.state.protocol.next(protocol);
        this.initPlate();

        this.state.messages.next({
            warnings: groupMessages(protocol.warnings),
            errors: groupMessages(protocol.errors),
        });

        if (protocol.errors.length) this.state.status.next('danger');
        else if (protocol.warnings.length) this.state.status.next('warning');
        else this.state.status.next(protocol.reagents.length > 0 ? 'success' : 'blank');
    }

    mount() {
        this.subscribe(this.state.coloring, () => this.syncColoring());
    }

    constructor(public model: HTE2MSModel) {
        super();
    }
}

const EmptyProtocol: HTEProtocol = {
    errors: [],
    warnings: [],
    worklists: [],
    reaction_ids: [],
    product_samples: {},
    reagents: [],
};

function groupMessages(messages: HTEPMessage[]): ProtocolMessageGroup[] {
    return groupByPreserveOrder(messages, (m) => m.message).map((g) => ({
        message: g[0].message,
        reactionIds: Array.from(new Set(g.map((m) => m.reaction_id).filter((id) => !!id))) as string[],
        group: g,
    }));
}
