import React, { ReactNode } from 'react';
import { DateLike, formatDatetime } from '../../lib/util/dates';
import { isBlank } from '../../lib/util/misc';
import { roundValueDigits } from '../../lib/util/roundValues';
import { type DataTableModel } from './model';

export type ColumnFilterType = 'includes' | 'between' | 'boolean';
export type ColumnFilterControl = React.FC<{ table: DataTableModel; columnName: string }>;

export type ColumnKind = 'int' | 'float' | 'bool' | 'str' | 'datetime' | 'obj' | 'action' | 'enum' | 'generic';

export interface ColumnBase {
    kind?: ColumnKind;
    header?: ((table: DataTableModel, options: { columnName: string }) => ReactNode) | ReactNode;
    /* A version of the header which is always string; used in case of custom controls and CSV export */
    label?: string;
    noHeaderTooltip?: boolean;
    sortButtonSeparate?: boolean;
    noResize?: boolean;
    disableGlobalFilter?: boolean;
    width?: number;
    headerAlign?: 'left' | 'right' | 'center';
    align?: 'left' | 'right' | 'center';
    alwaysVisible?: boolean;
    /* Positive is static order from start, negative is static order from back */
    position?: number;
    filterControl?: ColumnFilterControl;
}

export interface Column<T = any, F = {}, Row extends {} = any> extends ColumnBase {
    kind: ColumnKind;
    filterType?: ColumnFilterType | false;
    filterFn?: (table: DataTableModel) => (v: any, test: any) => boolean;
    /* If specified, overrides disable global filter */
    globalFilterFn?: (table: DataTableModel) => (v: T, test: any) => boolean;
    compare?: ((a: T, b: T) => number) | false; // eslint-disable-line
    /* Format the value, separate from render for CSV export support */
    format?: (v: T, options: F | undefined, table: DataTableModel, columnName: string) => string; // eslint-disable-line
    csvHeader?: ((table: DataTableModel, options: { columnName: string }) => string) | string;
    csvFormat?: (v: T, options: F | undefined, table: DataTableModel, columnName: string) => string; // eslint-disable-line
    render?: (props: {
        value: T;
        formatted: string;
        rowIndex: number;
        columnName: string;
        table: DataTableModel<Row>;
    }) => ReactNode;
    defaultFormatting?: F;
    allowedValues?: readonly T[];
}

export type ColumnsType<T extends object> = {
    [K in keyof T]: T[K] extends Column<infer E, infer _> ? E : any;
};

export type ColumnsFor<T extends {}> = {
    [K in keyof T]?: Column<T[K], any, T>;
};

export interface FloatColumnFormatOptions {
    significantDigits?: number;
    scientific?: boolean;
    decimalPlaces?: number;
    custom?: (v: number | undefined | null) => string;
    // TODO: padding support
}

export const DefaultFloatColumnFormatOptions: FloatColumnFormatOptions = {
    significantDigits: 4,
    scientific: false,
};

export interface ObjColumnOptions<T, F> {
    compare: Column<T, F>['compare'];
    format: Column<T, F>['format'];
    csvFormat?: Column<T, F>['csvFormat'];
}

export function floatFormatColumnOptionsEqual(a?: FloatColumnFormatOptions, b?: FloatColumnFormatOptions) {
    return (
        a?.scientific === b?.scientific &&
        a?.significantDigits === b?.significantDigits &&
        a?.decimalPlaces === b?.decimalPlaces &&
        a?.custom === b?.custom
    );
}

export const Column = {
    isCategorical(e: ColumnBase | undefined) {
        const k = e?.kind;
        if (!k) return false;
        return k === 'str' || k === 'enum' || k === 'bool';
    },
    isNumeric(e: ColumnBase | undefined) {
        const k = e?.kind;
        if (!k) return false;
        return k === 'int' || k === 'float';
    },
    create<T, F = any>(def: Column<T, F>) {
        return def;
    },
    generic<T extends any = any>(): Column<T> {
        return {
            kind: 'generic',
            compare: comparerWithBlanks(compareGeneric),
            format: (v) => (typeof v === 'string' ? `${v}` : formatGeneric(v)),
        };
    },
    int<T extends number | undefined = number>(): Column<T> {
        return {
            kind: 'int',
            filterType: 'between',
            compare: comparerWithBlanks((a: number, b: number) => a - b),
            format: (v) => (typeof v === 'number' && !Number.isNaN(v) ? `${v}` : formatGeneric(v)),
        };
    },
    bool<T extends boolean | undefined = boolean>(): Column<T> {
        return {
            kind: 'bool',
            filterType: 'boolean',
            compare: comparerWithBlanks((a: boolean, b: boolean) => +a - +b),
            format: (v) => {
                if (typeof v === 'boolean') return `${v}`;
                if (typeof v === 'number' && !Number.isNaN(v)) return `${v === 1}`;
                return formatGeneric(v);
            },
        };
    },
    float<T extends number | undefined = number>(options?: {
        defaultFormatting?: FloatColumnFormatOptions;
    }): Column<T, FloatColumnFormatOptions> {
        return {
            kind: 'float',
            filterType: 'between',
            compare: comparerWithBlanks((a: number, b: number) => a - b),
            format: formatFloat,
            defaultFormatting: { ...DefaultFloatColumnFormatOptions, ...options?.defaultFormatting },
        };
    },
    str<T extends string | undefined = string>(options?: { caseSensitive?: boolean }): Column<T> {
        return {
            kind: 'str',
            filterType: 'includes',
            compare: options?.caseSensitive
                ? comparerWithBlanks(compareGeneric)
                : comparerWithBlanks(compareStringsCaseSensitive),
            format: (v) => (typeof v === 'string' ? `${v}` : formatGeneric(v)),
        };
    },
    enumStr<T extends string | undefined>(values: ReadonlyArray<T>): Column<T> {
        return {
            kind: 'enum',
            filterType: 'includes',
            compare: comparerWithBlanks(compareGeneric),
            format: (v) => (typeof v === 'string' ? `${v}` : formatGeneric(v)),
            allowedValues: values,
        };
    },
    datetime<T extends DateLike | undefined = DateLike>(options?: { format?: 'date' | 'full' }): Column<T> {
        return {
            kind: 'datetime',
            filterType: false,
            disableGlobalFilter: true,
            compare: comparerWithBlanks(compareGeneric),
            format: (v) => (v ? formatDatetime(v, options?.format ?? 'date') : ''),
        };
    },
    obj<T, F>(options: ObjColumnOptions<T, F>): Column<T, F> {
        return {
            kind: 'obj',
            filterType: false,
            disableGlobalFilter: true,
            compare: options.compare,
            format: options.format,
            csvFormat: options.csvFormat,
        };
    },
};

function formatGeneric(v: any) {
    if (isBlank(v)) return '';
    return v;
}

export function formatFloat<T extends number | undefined = number>(v: T, o?: FloatColumnFormatOptions): string {
    if (o?.custom) return o.custom(v);
    if (typeof v !== 'number') return formatGeneric(v);
    if (Number.isNaN(v)) return '';
    let f: any = v;
    if (o?.significantDigits) {
        f = roundValueDigits(o.significantDigits, f);
    }
    if (o?.scientific) return f.toExponential();
    return o?.decimalPlaces !== undefined ? `${f.toFixed(o.decimalPlaces)}` : `${f}`;
}

function comparerWithBlanks(sortFn: (a: any, b: any) => number) {
    return (a: any, b: any) => {
        const aIsBlank = isBlank(a);
        const bIsBlank = isBlank(b);
        if (aIsBlank && bIsBlank) return 0;
        if (aIsBlank) return 1;
        if (bIsBlank) return -1;
        return sortFn(a, b);
    };
}

function compareGeneric(a: any, b: any) {
    if (a === b) return 0;
    return a < b ? -1 : 1;
}

export const DefaultCompareWithBlanks = comparerWithBlanks(compareGeneric);

function compareStringsCaseSensitive(a: string, b: string) {
    const x = typeof a === 'string' ? a.toLowerCase() : a;
    const y = typeof b === 'string' ? b.toLowerCase() : b;
    if (x < y) return -1;
    if (x > y) return 1;
    return 0;
}

export const ColumnSorting = {
    comparerWithBlanks,
    DefaultCompareWithBlanks: comparerWithBlanks(compareGeneric),
    compareStringsCaseSensitive,
    compareGeneric,
};

export const ColumnFilters = {
    caseInsensitive(v: any, test: string) {
        if (isBlank(v)) return false;
        if (typeof v === 'object') return false;
        return String(v).toLowerCase().includes(test.toLowerCase());
    },
    caseInsensitiveArray(v: any[] | undefined, test: string) {
        if (!v) return false;
        if (!test) return true;
        const filter = test.toLowerCase();
        for (const x of v) {
            if (String(x).toLowerCase().includes(filter)) return true;
        }
        return false;
    },
    between(v: any, [a, b]: [a: number | boolean, b: number | boolean]) {
        if (typeof v !== 'number') return false;
        if (a !== undefined && b !== undefined) return v >= (a as number) && v <= (b as number);
        if (a !== undefined) return v >= (a as number);
        if (b !== undefined) return v <= (b as number);
        return true;
    },
    boolean(v: any, test: string) {
        if (isBlank(v)) return false;
        if (test === '1' && v === 1) return true;
        if (test === '0' && v === 0) return true;
        const vString = v === 1 ? 'true' : 'false';
        return vString.toLowerCase().includes(test.toLowerCase());
    },
};

export const ColumnFilterTypeMap: Record<ColumnFilterType, (v: any, test: any) => boolean> = {
    between: ColumnFilters.between,
    includes: ColumnFilters.caseInsensitive,
    boolean: ColumnFilters.boolean,
};

const intArrays = [Int8Array, Uint8Array, Uint8Array, Int8Array, Uint16Array, Int16Array, Uint32Array, Int32Array];

export function determineColumnType(data: any) {
    for (const arr of intArrays) {
        if (data instanceof arr) {
            return Column.int();
        }
    }
    if (data instanceof Float64Array || data instanceof Float32Array) {
        return Column.float();
    }

    let numInt = 0;
    let numFloat = 0;
    let numStr = 0;
    let numDate = 0;
    let numBool = 0;
    for (let i = 0; i < data.length; i++) {
        const v = data[i];
        if (isBlank(v)) {
            continue;
        }
        const t = typeof v;
        if (t === 'number') {
            if (Number.isInteger(v)) numInt++;
            else numFloat++;
        } else if (t === 'string') {
            numStr++;
        } else if (t === 'boolean') {
            numBool++;
        } else if (v instanceof Date) {
            numDate++;
        }
    }

    if (numStr > 0) return Column.str();
    if (numFloat > 0) return Column.float();
    if (numInt > 0) return Column.int();
    if (numBool > 0) return Column.bool();
    if (numDate > 0) return Column.datetime();
}

export function determineColumnNamesFromObjects<T>(xs: ArrayLike<T>): (keyof T)[] {
    const columnSet = new Set();
    for (let i = 0; i < xs.length; i++) {
        const o = xs[i];
        if (!o) continue;
        const keys = Object.keys(o);
        for (const key of keys) {
            if (!columnSet.has(key)) {
                columnSet.add(key);
            }
        }
    }
    return Array.from(columnSet) as (keyof T)[];
}
