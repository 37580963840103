import log from 'loglevel';
import api from '../../api';
import { mutationEventsPolyfill } from '../util/mutation-events-polyfill';

declare const perkinelmer: any;

export type ChemDrawExportType = 'smiles' | 'mol' | 'mol-v3000';

export interface ChemDrawInstance {
    getSMILES(cb: (smiles: string, err: any) => void): any;
    loadSMILES(smiles: string, cb: (smi: string, err: any) => void): any;
    getMOL(cb: (smiles: string, err: any) => void): any;
    getMOLV3000(cb: (smiles: string, err: any) => void): any;
    getImgUrl(options: { transparentBackground: boolean; borderSize: number }): string;
    clear(): any;
}

const rawXMLHttpRequestOpen = XMLHttpRequest.prototype.open;

function XMLHttpRequest_open_chemdraw_hack(this: XMLHttpRequest, ...args: any[]) {
    rawXMLHttpRequestOpen.apply(this, args as any);

    try {
        if (args[1] && args[1].includes('chemdrawweb')) {
            const token = localStorage.getItem('ad_access_token');
            if (token) {
                this.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('ad_access_token')}`);
            }
        }
    } catch (err) {
        log.error('ChemDraw auth header hack error', err);
    }
}

// A hack to get auth working
function applyXMLHttpRequestHack() {
    XMLHttpRequest.prototype.open = XMLHttpRequest_open_chemdraw_hack;
}

function removeXMLHttpRequestHack() {
    XMLHttpRequest.prototype.open = rawXMLHttpRequestOpen;
}

class _ChemDrawService {
    private loaded = false;
    private loading: Promise<void> | undefined = undefined;
    private serialId = 0;
    private useCount = 0;
    private currentInstace: ChemDrawInstance | undefined = undefined;

    // TODO: perhaps this should integrate with a dialog-like service
    // so that we can keep a single instance mounted in the background
    // for better performance?
    // If this happens the "useCount" logic needs to be updated

    private raiseNotAvailable(rej: (err: any) => void) {
        if (!this.currentInstace) {
            rej(new Error('ChemDraw not available'));
            return true;
        }
        return false;
    }

    clear() {
        return new Promise<void>((res, rej) => {
            if (this.raiseNotAvailable(rej)) return;
            // ref: https://chemdrawdirect.perkinelmer.cloud/js/docs/API%20Reference/API%20Reference%20Guide.htm#API%20Reference.html#clear--void%3FTocPath%3DClasses%7CChemDrawDirect%7C_____4
            this.currentInstace?.clear();
        });
    }

    loadSMILES(smiles: string) {
        return new Promise<string>((res, rej) => {
            if (this.raiseNotAvailable(rej)) return;

            const cb = (v: string, err: any) => {
                if (err) rej(err);
                else res(v);
            };

            // ref: https://chemdrawdirect.perkinelmer.cloud/js/docs/API%20Reference/API%20Reference%20Guide.htm#API%20Reference.html#loadsmilessmiles-callback--void%3FTocPath%3DClasses%7CChemDrawDirect%7C_____36
            this.currentInstace?.loadSMILES(smiles, cb);
        });
    }

    getImage() {
        return new Promise<string>((res, rej) => {
            if (this.raiseNotAvailable(rej)) return;
            // An SVG would be preferable, however trying to use .getSVG
            // results in an error "Web Service is disabled"
            // because generating an SVG apparently makes a request, which we have disabled
            // in order to avoid sending Entos IP
            // ref: https://chemdrawdirect.perkinelmer.cloud/js/docs/API%20Reference/API%20Reference%20Guide.htm#API%20Reference.html#getsvgcallback--void%3FTocPath%3DClasses%7CChemDrawDirect%7C_____25

            // ref: https://chemdrawdirect.perkinelmer.cloud/js/docs/API%20Reference/API%20Reference%20Guide.htm#API%20Reference.html#getimgurl--string%3FTocPath%3DClasses%7CChemDrawDirect%7C_____15
            res(this.currentInstace?.getImgUrl({ transparentBackground: false, borderSize: 0 }) ?? '');
        });
    }

    export(kind: ChemDrawExportType) {
        return new Promise<string>((res, rej) => {
            if (this.raiseNotAvailable(rej)) return;

            const cb = (v: string, err: any) => {
                if (err) rej(err);
                else res(v);
            };

            if (kind === 'smiles') this.currentInstace?.getSMILES(cb);
            else if (kind === 'mol') this.currentInstace?.getMOL(cb);
            else if (kind === 'mol-v3000') this.currentInstace?.getMOLV3000(cb);
            else rej(new Error(`Unknown export kind: ${kind}`));
        });
    }

    load(): Promise<void> {
        if (this.loaded) return Promise.resolve();
        if (this.loading) return this.loading;

        // Workaround for https://developer.chrome.com/blog/mutation-events-deprecation
        mutationEventsPolyfill();

        this.loading = new Promise<void>((res, rej) => {
            const script = document.createElement('script');
            script.src = `${api.serverURL}/chemdrawweb/chemdrawweb.js`;
            script.type = 'text/javascript';
            script.addEventListener('load', () => {
                if (typeof perkinelmer === 'undefined') {
                    this.loaded = false;
                    this.loading = undefined;
                    document.head.removeChild(script);
                    rej(new Error('ChemDraw not available.'));
                } else {
                    this.loaded = true;
                    this.loading = undefined;
                    res();
                }
            });
            script.addEventListener('error', () => {
                this.loaded = false;
                this.loading = undefined;
                document.head.removeChild(script);
                rej(new Error('ChemDraw not available.'));
            });
            document.head.appendChild(script);
        });

        return this.loading;
    }

    mount(parent: HTMLElement, options?: { initialSMILES?: string }) {
        let disposed = false;
        let instance: any;
        const id = `chemdraw-${this.serialId++}`;
        const target = document.createElement('div');
        target.className = 'chemdraw-wrapper';
        target.id = id;
        parent.appendChild(target);

        const cleanup = () => {
            parent.removeChild(target);
            if (this.useCount === 0) {
                removeXMLHttpRequestHack();
            }
        };

        this.useCount++;
        return {
            instance: new Promise<ChemDrawInstance | undefined>((res, rej) => {
                requestAnimationFrame(async () => {
                    if (disposed) {
                        cleanup();
                        res(undefined);
                        return;
                    }

                    applyXMLHttpRequestHack();
                    try {
                        await this.load();
                    } catch (err) {
                        rej(err);
                        return;
                    }

                    perkinelmer.ChemdrawWebManager.attach({
                        id,
                        callback: (inst: any, error: any) => {
                            instance = inst;

                            if (disposed) {
                                cleanup();
                                inst?.dispose();
                                res(undefined);
                            } else if (inst) {
                                this.currentInstace = inst;
                                if (options?.initialSMILES) inst.loadSMILES(options.initialSMILES);
                                res(inst);
                            } else {
                                rej(new Error(error));
                            }
                        },
                    });
                });
            }),
            dispose: () => {
                if (disposed) return;

                disposed = true;
                this.useCount--;

                if (this.useCount === 0) {
                    removeXMLHttpRequestHack();
                }

                if (!instance) return;

                if (this.currentInstace === instance) {
                    this.currentInstace = undefined;
                }

                instance?.dispose();
                instance = undefined;

                try {
                    parent.removeChild(target);
                } catch {
                    log.error('Failed to remove chemdraw target');
                }
            },
        };
    }
}

export const ChemDrawService = new _ChemDrawService();
