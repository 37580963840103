import { ReactNode, useState, useRef, useLayoutEffect, CSSProperties } from 'react';

const autoSizeBoxStyle: CSSProperties = { position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 };

export function AutoSizeBox({
    children,
    className,
}: {
    children: (width: number, height: number) => ReactNode;
    className?: string;
}) {
    const [size, setSize] = useState([800, 600]);
    const wrapper = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const target = wrapper.current!;
        let frameHandle: any;
        const resizeObserver = new ResizeObserver(() => {
            if (frameHandle !== undefined) cancelAnimationFrame(frameHandle);
            frameHandle = requestAnimationFrame(() => setSize([target.clientWidth, target.clientHeight]));
        });
        resizeObserver.observe(target);

        setSize([target.clientWidth, target.clientHeight]);

        return () => {
            resizeObserver.unobserve(target);
        };
    }, []);

    return (
        <div ref={wrapper} className={className} style={autoSizeBoxStyle}>
            {children(size[0], size[1])}
        </div>
    );
}
