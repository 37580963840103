import { useEffect, useMemo } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useParams, useSearchParams } from 'react-router-dom';
import Split from 'react-split-it';
import {
    Column,
    ColumnsFor,
    DataTableControl,
    DataTableGlobalFilter,
    DataTableModel,
    DataTableStore,
    DefaultRowHeight,
} from '../../components/DataTable';
import { SmilesColumn } from '../../components/DataTable/common';
import { ErrorMessage } from '../../components/common/Error';
import { formatDictionary } from '../../components/common/formatDictionary';
import { PageTemplate } from '../../components/Layout/Layout';
import Loading from '../../components/common/Loading';
import { PillNav } from '../../components/common/Nav';
import { Plot } from '../../components/Plot';
import { PropertyNameValue } from '../../components/common/PropertyNameValue';
import { AssayValueTypeColumn } from '../../lib/assays/table';
import { AsyncState, useAsyncAction } from '../../lib/hooks/useAsyncAction';
import useBehavior from '../../lib/hooks/useBehavior';
import useMountedModel from '../../lib/hooks/useMountedModel';
import { formatDateAsYYYY_MM_DD } from '../../lib/util/dates';
import { AsyncMoleculeDrawer } from '../../lib/util/draw-molecules';
import { tryGetErrorMessage } from '../../lib/util/errors';
import { useModelAction } from '../../lib/util/reactive-model';
import { BatchLink } from '../ECM/ecm-common';
import { BoxDetailsData, BoxTrainingDataResult, BoxTrainingDataRow } from './box-api';
import { BOX_INFO_STEPS, BoxInfoModel, BoxVersionTable } from './box-info-model';

async function createModel(kind: string, name: string, id?: number) {
    const model = new BoxInfoModel(kind, name);
    await model.init(id);
    return model;
}

export default function BoxInformation() {
    const { kind, name } = useParams();
    const [searchParams, _] = useSearchParams();
    const id = searchParams.get('id');
    const [model, applyModel] = useAsyncAction<BoxInfoModel>();
    useEffect(() => {
        if (kind && name) {
            applyModel(createModel(kind, name, id ? +id : undefined));
        }
    }, [kind, name]);

    return <BoxInformationPage _model={model} />;
}

function BoxInformationPage({ _model }: { _model: AsyncState<BoxInfoModel> }) {
    const model = useMountedModel(_model.result);

    return (
        <PageTemplate
            title='HTE Wizard'
            breadcrumb={
                _model.isLoading || !model
                    ? [{ href: window.location.pathname, title: 'Loading...' }]
                    : [{ href: window.location.pathname, title: `${model.kind} / ${model.name}` }]
            }
        >
            {_model.isLoading && <Loading message='Loading box...' />}
            {_model.error && <ErrorMessage message={_model.error} />}
            {model && <Layout model={model} />}
        </PageTemplate>
    );
}

function Layout({ model }: { model: BoxInfoModel }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const currentBoxId = useBehavior(model.state.currentBoxId);

    useEffect(() => {
        const current = searchParams.get('id');
        if (current && +current === currentBoxId) return;

        if (currentBoxId) {
            setSearchParams({ id: currentBoxId.toString() }, { replace: true });
        }
    }, [currentBoxId]);

    return (
        <Split direction='vertical' gutterSize={6} sizes={[0.5, 0.5]}>
            <TableWrapper table={model.table} />
            <BoxInformationPane model={model} />
        </Split>
    );
}

function TableWrapper({ table }: { table: DataTableModel<BoxVersionTable> }) {
    useBehavior(table.version);
    return (
        <div className='d-flex flex-column h-100'>
            <div className='mx-3 my-3 w-50'>
                <DataTableGlobalFilter table={table} />
            </div>
            <div className='position-relative flex-grow-1'>
                <DataTableControl height='flex' table={table} headerSize='sm' rowSelectionMode='single' />
            </div>
        </div>
    );
}

function BoxInformationPane({ model }: { model: BoxInfoModel }) {
    return (
        <Split direction='horizontal' gutterSize={6} sizes={[0.65, 0.35]}>
            <BoxDetailsPane model={model} />
            <HistogramPane model={model} />
        </Split>
    );
}

const PILL_NAV_STEPS = BOX_INFO_STEPS.map((step) => ({
    name: step,
    label: step,
    icon: undefined,
}));

function BoxDetailsPane({ model }: { model: BoxInfoModel }) {
    const step = useBehavior(model.state.step);
    useBehavior(model.table.version);
    const selected = model.table.getSelectedRowIndices();

    if (selected.length === 0) return <p className='m-2'>No boxes selected.</p>;

    const selectedId = model.table.store.getValue('id', selected[0]);
    const currentBox = model.boxes.find((b) => b.id === selectedId);

    if (!currentBox) return <p className='m-2 text-warning'>Could not find details for box {selectedId}</p>;

    return (
        <div className='d-flex flex-column p-2 h-100 position-relative'>
            <div className='d-flex mb-2'>
                <PillNav steps={PILL_NAV_STEPS} currentStep={step} onSetStep={(s) => model.state.step.next(s)} />
            </div>
            <div className='flex-grow-1 position-relative h-100 overflow-auto'>
                {step === 'Box info' && <BoxInfoContent box={currentBox} />}
                {step === 'Metadata' && <BoxMetadataContent box={currentBox} />}
                {step === 'Training data' && <TrainingDataContent model={model} />}
                {step === 'Conda environment' && <CondaEnvironmentContent box={currentBox} />}
                {step === 'Input and result structures' && <InputContent box={currentBox} />}
                {step === 'Sample code' && <SampleCodeContent box={currentBox} />}
            </div>
        </div>
    );
}

function BoxInfoContent({ box }: { box: BoxDetailsData }) {
    return (
        <div className='font-body-small'>
            <PropertyNameValue field='Name' value={box.identifier.name} />
            <PropertyNameValue field='Kind' value={box.identifier.kind} />
            <PropertyNameValue field='Shorthand' value={box.shorthand} />
            <PropertyNameValue field='Description' value={box.description} />
            <PropertyNameValue field='Created On' value={formatDateAsYYYY_MM_DD(box.created_on, { full: true })} />
            <PropertyNameValue field='Modified On' value={formatDateAsYYYY_MM_DD(box.modified_on, { full: true })} />
            <PropertyNameValue
                field='Repository'
                value={
                    <a href={box.repository} title='GitHub Repository' target='_blank' rel='noopener noreferrer'>
                        {box.repository}
                    </a>
                }
            />
            <PropertyNameValue field='Author' value={box.author} />
            <PropertyNameValue field='Changelog' value={box.changelog} />
        </div>
    );
}

function BoxMetadataContent({ box }: { box: BoxDetailsData }) {
    if (Object.keys(box.metadata).length === 0) return <p>Box has no metadata.</p>;
    return <>{formatDictionary(box.metadata)}</>;
}

function TrainingDataContent({ model }: { model: BoxInfoModel }) {
    const state = useModelAction(model.actions.loadTrainingData);

    return (
        <div className='position-relative h-100 pt-2'>
            {state.kind === 'loading' && <Loading message='Loading training data...' />}
            {state.kind === 'error' && (
                <p className='text-danger'>Error fetching training data: {tryGetErrorMessage(state.error)}</p>
            )}
            {state.kind === 'result' && <TrainingDataWrapper model={model} data={state.result} />}
        </div>
    );
}

function TrainingDataWrapper({ model, data }: { model: BoxInfoModel; data: BoxTrainingDataResult }) {
    const state = useModelAction(model.actions.querySubstances);
    const currentBoxId = useBehavior(model.state.currentBoxId);

    useEffect(() => {
        if (currentBoxId && state.kind === 'idle')
            model.actions.querySubstances.run(model.querySubstances(currentBoxId, data));
    }, []);

    return (
        <>
            {state.kind === 'loading' && <Loading message='Matching training set to Foundry compounds...' />}
            {state.kind === 'error' && (
                <p className='text-danger'>Error matching Foundry compounds: {tryGetErrorMessage(state.error)}</p>
            )}
            {state.kind === 'result' && <TrainingDataTable data={data} compounds={state.result} />}
        </>
    );
}

function TrainingDataTable({
    data,
    compounds,
}: {
    data: BoxTrainingDataResult;
    compounds: Record<string, string | undefined>;
}) {
    const table = useMemo(() => createTable(data.dataframe, compounds, data.units), [data]);
    useBehavior(table.version);

    return (
        <div className='position-relative h-100 d-flex flex-column'>
            <div className='pb-2'>
                <DataTableGlobalFilter table={table} />
            </div>
            <div className='position-relative flex-grow-1'>
                <DataTableControl height='flex' table={table} headerSize='sm' />
            </div>
        </div>
    );
}

function CondaEnvironmentContent({ box }: { box: BoxDetailsData }) {
    return (
        <pre>
            <code>{box.conda_environment}</code>
        </pre>
    );
}

function InputContent({ box }: { box: BoxDetailsData }) {
    return (
        <>
            {box.results.map((result: any) => {
                if (result.description) {
                    return (
                        <ReactMarkdown
                            key={result.name}
                        >{`**${result.name}** - ${result.type} - *${result.description}*`}</ReactMarkdown>
                    );
                }
                return <ReactMarkdown key={result.name}>{`**${result.name}** - ${result.type}`}</ReactMarkdown>;
            })}
        </>
    );
}

function SampleCodeContent({ box }: { box: BoxDetailsData }) {
    return (
        <pre>
            <code>{box.sample_code}</code>
        </pre>
    );
}

function HistogramPane({ model }: { model: BoxInfoModel }) {
    const id = useBehavior(model.state.currentBoxId);
    const state = useModelAction(model.actions.loadTrainingData);

    return (
        <div className='position-relative h-100 p-2'>
            {!id && <p>Choose a box version.</p>}
            {state.kind === 'loading' && <Loading message='Loading training data...' />}
            {state.kind === 'error' && <p className='text-danger'>{state.error}</p>}
            {state.kind === 'result' && <Histogram model={model} data={state.result} />}
        </div>
    );
}

function Histogram({ model, data }: { model: BoxInfoModel; data: BoxTrainingDataResult }) {
    const figure = model.getHistogram(data);
    return <Plot figure={figure} />;
}

const SmilesRowHeightFactor = 2.5;
const rowHeight = DefaultRowHeight;

const drawer = new AsyncMoleculeDrawer();

const BoxTrainingDataTableSchema = (units: string): ColumnsFor<BoxTrainingDataRow> => ({
    SMILES: SmilesColumn(drawer, SmilesRowHeightFactor),
    y: AssayValueTypeColumn({ columnName: `Value (${units})` }),
});

function createTable(
    store: DataTableStore<BoxTrainingDataRow>,
    compounds: Record<string, string | undefined>,
    units: string
) {
    const table = new DataTableModel(store, {
        columns: BoxTrainingDataTableSchema(units),
        hideNonSchemaColumns: true,
        rowHeight: SmilesRowHeightFactor * rowHeight,
        customState: { 'show-smiles': true },
    });

    const smilesToIdentifierColumnName = store.hasColumn('STANDARDIZED_SMILES') ? 'STANDARDIZED_SMILES' : 'SMILES';
    const identifiers: string[] = store
        .getColumnValues(smilesToIdentifierColumnName)
        .map((smiles) => compounds[smiles ?? ''] ?? '');
    table.addOrUpdateColumns([
        [
            'identifier',
            identifiers,
            Column.create({
                kind: 'str',
                noHeaderTooltip: true,
                render: ({ value }) => <BatchLink identifier={value} withQuery />,
            }),
        ],
    ]);

    table.setColumnStickiness('SMILES', true);
    table.setColumnStickiness('identifier', true);

    return table;
}
