import { PlotlyFigure } from '../../components/Plot';

// grabbed from https://leonardocolor.io/scales.html
export const PALETTE = [
    '#ff7d67',
    '#e83326',
    '#ac0000',
    '#680000',
    '#f98517',
    '#c85b00',
    '#903900',
    '#561e01',
    '#c6a000',
    '#997600',
    '#6c4f00',
    '#412c00',
    '#72b622',
    '#4f890e',
    '#355e07',
    '#1e3604',
    '#33b983',
    '#008c5c',
    '#00603d',
    '#0e3724',
    '#5ba8f7',
    '#1077f3',
    '#0050ae',
    '#002f64',
    '#bf8cfc',
    '#9b54f3',
    '#7018d3',
    '#3d137d',
    '#ee74ee',
    '#cc34cd',
    '#970098',
    '#551153',
    '#ff7d67',
    '#e83326',
    '#ac0000',
    '#680000',
    '#f98517',
    '#c85b00',
    '#903900',
    '#561e01',
    '#c6a000',
    '#997600',
    '#6c4f00',
    '#412c00',
    '#72b622',
    '#4f890e',
    '#355e07',
    '#1e3604',
    '#33b983',
    '#008c5c',
    '#00603d',
    '#0e3724',
    '#5ba8f7',
    '#1077f3',
    '#0050ae',
    '#002f64',
    '#bf8cfc',
    '#9b54f3',
    '#7018d3',
    '#3d137d',
    '#ee74ee',
    '#cc34cd',
    '#970098',
    '#551153',
];

export const PLOT_COLORS = {
    bg: 'transparent',
    info: '#0DCAF0',
    pink: '#EA868F',
    yellow: '#FFDA6A', // theme warning
    richData: 'rgba(234, 134, 143, 0.5)', // theme danger
    curves: 'rgba(234, 134, 143, 0.3)', // theme danger
    curveAvg: 'rgba(234, 134, 143, 1)', // theme danger
    curveAvgCorrected: 'rgba(204, 97, 109, 1)',
    grid: 'rgba(180, 183, 185, 0.1)',
    zeroLine: 'rgba(180, 183, 185, 0.5)',
};

export const DefaultPlotlyFigureLayout: Partial<PlotlyFigure['layout']> = {
    autosize: true,
    uirevision: 'true',
    paper_bgcolor: PLOT_COLORS.bg,
    plot_bgcolor: PLOT_COLORS.bg,
    xaxis: {
        gridcolor: PLOT_COLORS.grid,
        zerolinecolor: PLOT_COLORS.zeroLine,
    },
    yaxis: {
        gridcolor: PLOT_COLORS.grid,
        zerolinecolor: PLOT_COLORS.zeroLine,
    },
    margin: {
        t: 30,
        b: 45,
        l: 40,
        r: 20,
    },
};

export interface PlotOffsetLine {
    offset: string;
    symmetric: boolean;
    text: string;
    color: string;
    width: number;
    dash: string;
}

export const DefaultOffsetTraces: PlotOffsetLine[] = [
    {
        dash: 'solid',
        offset: '0',
        symmetric: false,
        text: 'y=x',
        width: 1,
        color: '#237523',
    },
    {
        dash: 'dash',
        offset: '1',
        symmetric: true,
        text: 'y=x±1',
        width: 1,
        color: '#9CE7F4',
    },
];

export function newPlotOffsetLine(): PlotOffsetLine {
    return {
        dash: 'solid',
        offset: '0',
        symmetric: false,
        text: 'y=x',
        width: 1,
        color: PALETTE[Math.floor(Math.random() * PALETTE.length)],
    };
}

// for display on the edit offset line modal
// visually shows the user the meaning of the 'symmetric' checkbox by
// showing ± vs just + or - for a single equation
export function getDisplayText(offset: string, symmetric: boolean) {
    const offsetAsNum = parseFloat(offset);
    if (Number.isNaN(offsetAsNum)) return 'y=x';
    if (offsetAsNum === 0) return 'y=x';
    const posOffset = Math.abs(offsetAsNum);
    if (symmetric) {
        return `y=x±${posOffset}`;
    }
    return `y=x${offsetAsNum < 0 ? '-' : '+'}${posOffset}`;
}
