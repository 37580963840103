import { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import AuthenticatedApp from './AuthenticatedApp';
import { ErrorMessage } from './components/common/Error';
import { useAsyncAction } from './lib/hooks/useAsyncAction';
import useBehavior from './lib/hooks/useBehavior';
import { AuthService, AuthState } from './lib/services/auth';
import { tryGetErrorMessage } from './lib/util/errors';
import { clearLocalStorage } from './lib/util/local-storage';

function ErrorWrapper({ error }: { error: any }) {
    const [auth, applyAuth] = useAsyncAction();
    const login = () => {
        clearLocalStorage();
        applyAuth(AuthService.tryLoginPopup('login'));
    };
    const refresh = () => {
        clearLocalStorage();
        window.location.assign('/');
    };

    return (
        <>
            <ErrorMessage header='Authentication Error' message={tryGetErrorMessage(auth.error ?? error)} />
            <div className='hstack gap-2 ms-4'>
                <Button variant='primary' onClick={login} disabled={auth.isLoading}>
                    {auth.isLoading && <Spinner animation='border' size='sm' role='status' className='me-2' />}
                    Login with Popup
                </Button>
                <Button variant='outline-primary' onClick={refresh}>
                    Refresh
                </Button>
            </div>
        </>
    );
}

function Splash({ message, allowRetry }: { message?: string; allowRetry?: boolean }) {
    const [showClear, setShowClear] = useState(false);
    useEffect(() => {
        if (!allowRetry) return;
        const timeout = setTimeout(() => setShowClear(true), 3000);
        return () => clearTimeout(timeout);
    }, [allowRetry]);

    const refresh = showClear && (
        <div className='mt-4 d-flex justify-content-center'>
            <Button
                size='sm'
                onClick={() => {
                    clearLocalStorage();
                    window.location.assign('/');
                }}
                variant='outline-primary'
            >
                Clear Login Data &amp; Retry
            </Button>
        </div>
    );

    return (
        <>
            <div className='insight-splash-wrapper'>
                <img src='/logo192.png' alt='logo' className='insight-fade-in-splash' />
            </div>
            <div className='insight-splash-wrapper'>
                <div style={{ position: 'absolute', top: 'calc(50% + 130px)' }}>
                    <div className='d-flex align-items-center text-secondary justify-content-center'>{message}</div>
                    {refresh}
                </div>
            </div>
        </>
    );
}

function Unauthenticated() {
    return <p className='m-4'>Unauthenticated</p>;
}

function App() {
    const authState = useBehavior(AuthService.state);
    return authState.kind === 'authenticated' ? <AuthenticatedApp /> : <LoginFlow authState={authState} />;
}

function LoginFlow({ authState }: { authState: AuthState }) {
    if (authState.kind === 'authenticated') {
        return <AuthenticatedApp />;
    }
    if (authState.kind === 'error') {
        return <ErrorWrapper error={authState.error} />;
    }
    if (authState.kind === 'interaction-required') {
        return <Splash allowRetry message='Redirecting...' />;
    }
    if (authState.kind === 'in-progress') {
        return <Splash message='Authenticating...' />;
    }
    return <Unauthenticated />;
}

export default App;
