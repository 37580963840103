import { faCircleCheck, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AsyncMoleculeDrawing } from '../../../components/common/AsyncMoleculeDrawing';
import { Card } from '../../../components/common/Card/Card';
import { OverflowSmilesDisplay } from '../../../components/common/CopySmilesButton';
import useBehavior from '../../../lib/hooks/useBehavior';
import { CompoundSummary } from '../compound-api';
import { CompoundDetailsSection, StereochemistrySection } from '../CompoundInformation';
import { CompoundComparisonModel } from './batch-upload-model';

export function SelectedCompoundHeader({ model, title }: { model: CompoundComparisonModel; title: string }) {
    const summary = useBehavior(model.state.selected);
    return (
        <div className='p-3 border-bottom me-3'>
            <h4>{title}</h4>
            <div className='hstack gap-2'>
                <span className='text-secondary'>ENT Number:</span>
                <span className='fw-bold'>{summary.identifier ?? '-'}</span>
                <span className='text-secondary'>SMILES:</span>
                <div className='fw-bold'>
                    <OverflowSmilesDisplay smiles={summary.batch_smiles} />
                </div>
                <span className='text-secondary'>Salt:</span>
                <span className='fw-bold'>{summary.salt ?? '-'}</span>
                <span className='text-secondary'>Common name:</span>
                <span className='fw-bold'>{summary.common_name ?? '-'}</span>
                <span className='text-secondary'>Formula weight:</span>
                <span className='fw-bold'>
                    {summary.formula_weight ? `${summary.formula_weight?.toFixed(2)} g/mol` : '-'}
                </span>
            </div>
        </div>
    );
}

export function CompoundInfoPanel({
    model,
    showSelected = false,
}: {
    model: CompoundComparisonModel;
    showSelected?: boolean;
}) {
    const submitted = model.comparison.submitted;
    const selected = useBehavior(model.state.selected);
    const summary = showSelected ? selected : submitted;
    return (
        <div className='h-100 overflow-auto'>
            <div className='p-3'>
                <p className='fw-bold'>Your submitted compound</p>
                <p className='text-secondary'>Image shows drawn structure after standardization.</p>
                <CompoundCard model={model} summary={summary} selected={selected === summary} />
                {!summary.identifier && (
                    <div className='mt-3 entos-novel-compound-notice'>
                        <FontAwesomeIcon icon={faCircleInfo} fixedWidth color='var(--bs-gray-500)' />
                        <span className='ms-2'>Your submitted compound is novel</span>
                    </div>
                )}
                {summary.identifier && (
                    <div>
                        <p className='fw-bold mb-2'>Compound details</p>
                        <CompoundDetailsSection compound={summary} />
                        <StereochemistrySection compound={summary} />
                    </div>
                )}
            </div>
        </div>
    );
}

export function SimilarCompounds({ model }: { model: CompoundComparisonModel }) {
    const selected = useBehavior(model.state.selected);
    const count = model.comparison.similar.length;
    return (
        <div className='p-3 h-100 overflow-auto'>
            <p className='fw-bold'>
                Found {count} similar compound{count > 1 ? 's' : ''} in database
            </p>
            <p className='text-secondary'>
                Similar compounds include stereoisomers, diastereomers, enantiomers, atropisomers, tautomers, and
                compounds with differing CSTs.
            </p>
            <div className='d-flex flex-wrap'>
                {model.comparison.similar.map((summary) => (
                    <CompoundCard
                        key={summary.identifier}
                        model={model}
                        summary={summary}
                        selected={selected === summary}
                    />
                ))}
            </div>
        </div>
    );
}

function CompoundCard({
    model,
    summary,
    selected = false,
}: {
    model: CompoundComparisonModel;
    summary: CompoundSummary;
    selected?: boolean;
}) {
    const title = summary.identifier ? `${summary.identifier}` : 'New compound';
    return (
        <button
            type='button'
            className='entos-card-button-wrapper'
            title={title}
            onClick={() => model.state.selected.next(summary)}
        >
            <Card title={title} className='entos-compound-card me-2 mb-2' selected={selected}>
                <div>
                    <span className='badge entos-primary-badge me-1'>{summary.stereochemistry_label}</span>
                    <span className='badge entos-secondary-badge'>
                        {summary.stereochemistry_enantiomeric_ratio ?? ''}
                    </span>
                </div>
                <div>
                    <AsyncMoleculeDrawing smiles={summary.batch_smiles} width='100%' autosize />
                </div>
                <div>
                    <FontAwesomeIcon
                        icon={faCircleCheck}
                        fixedWidth
                        color={selected ? 'var(--bs-primary)' : 'var(--bs-gray-500)'}
                    />
                    <span className='text-secondary ms-2'>
                        {summary.identifier ? `New batch of ${summary.identifier}` : 'New compound'}
                    </span>
                </div>
            </Card>
        </button>
    );
}
