import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageTemplate } from '../../components/Layout/Layout';
import * as Icons from '../../components/common/Icons';

export function IconTestPage() {
    return (
        <PageTemplate title='Custom Icon Test Page'>
            <div className='d-flex flex-wrap'>
                {Object.entries(Icons).map(([name, Icon]) => (
                    <div key={name} className='d-flex flex-column align-items-center m-2'>
                        <FontAwesomeIcon icon={Icon} size='2x' className='m-2' fixedWidth />
                        <span className='font-body-small text-secondary'>{name}</span>
                    </div>
                ))}
            </div>
        </PageTemplate>
    );
}
