import { ReactNode } from 'react';
import { Spinner } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import useBehavior from '../../lib/hooks/useBehavior';
import { ErrorMessage } from './Error';

export default function Loading({
    message,
    inline,
}: {
    message?: BehaviorSubject<ReactNode> | string;
    inline?: boolean;
}) {
    const msg = useBehavior(typeof message === 'string' ? undefined : message) ?? (message as string);
    return (
        <div className={`flex text-4xl ${inline ? 'm-1' : 'justify-center items-center w-full h-full m-4'}`}>
            <div className={`d-flex text-secondary align-items-center justify-content-center ${inline ? '' : 'p-4'}`}>
                <Spinner
                    className={inline ? 'me-2' : 'me-3'}
                    size={inline ? 'sm' : undefined}
                    animation='border'
                    role='status'
                />{' '}
                {msg ?? 'Loading...'}
            </div>
        </div>
    );
}

export function AsyncLoading({ state }: { state: { isLoading?: boolean; error?: any } }) {
    if (state.isLoading) return <Loading />;
    if (state.error) return <ErrorMessage header='Error' message={state.error} />;
    return null;
}
