import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
    faBoxOpen,
    faDatabase,
    faGripHorizontal,
    faInfoCircle,
    faProjectDiagram,
    faQuestionCircle,
    faVials,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { AssayDetail } from '../../pages/Assays/assay-api';
import { AssayDetails, AssayMetadata } from '../../pages/Assays/AssayDetailPanel';

type OptionType = 'dataset' | 'assay' | 'box' | 'descriptor' | 'info' | 'substructure-search' | 'library';

export interface Option {
    value: any;
    label: string;
}

export interface SelectOption extends Option {
    type: 'dataset' | 'assay' | 'box' | 'library';
    [key: string]: any;
}

export interface FormatOptionLabelProps {
    label: string;
    type: OptionType;
    isDisabled?: boolean;
    isError?: boolean;
    assay?: AssayDetail;
}

function getOptionIcon(type: OptionType) {
    let icon: IconDefinition | null = null;
    if (type === 'dataset') {
        icon = faDatabase;
    } else if (type === 'assay') {
        icon = faVials;
    } else if (type === 'box') {
        icon = faBoxOpen;
    } else if (type === 'descriptor') {
        icon = faProjectDiagram;
    } else if (type === 'info') {
        icon = faInfoCircle;
    } else if (type === 'substructure-search') {
        icon = faQuestionCircle;
    } else if (type === 'library') {
        icon = faGripHorizontal;
    }
    return icon;
}

export default function formatOptionLabel({ label, type, isDisabled, isError, assay }: FormatOptionLabelProps) {
    const icon = getOptionIcon(type);
    return (
        <div
            className={classnames('d-flex justify-content-between align-items-center', {
                'text-danger': isError,
                'text-muted': isDisabled && !isError,
            })}
        >
            <div className='d-flex'>
                <div className='me-2'>{icon && <FontAwesomeIcon icon={icon} size='xs' fixedWidth />}</div>
                <div>{label}</div>
            </div>
            {assay && <AssayPopover assay={assay} />}
        </div>
    );
}

export function AssayPopover({ assay }: { assay?: AssayDetail }) {
    if (!assay) return null;
    const shorthand = assay.common_unit_shorthand ?? assay.shorthand;
    return (
        <OverlayTrigger
            key={shorthand}
            placement='auto'
            overlay={
                <Popover id={shorthand}>
                    <Popover.Header>
                        {shorthand}
                        {assay.value_count && (
                            <p className='font-body-small text-secondary mb-0'>
                                ({assay.value_count} value{assay.value_count === 1 ? '' : 's'})
                            </p>
                        )}
                    </Popover.Header>
                    <Popover.Body>
                        <AssayDetails assay={assay} />
                        <AssayMetadata assay={assay} />
                    </Popover.Body>
                </Popover>
            }
        >
            <FontAwesomeIcon icon={faInfoCircle} className='pe-2' color='var(--bs-gray-500)' size='xs' fixedWidth />
        </OverlayTrigger>
    );
}
