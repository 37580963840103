import ReactTableSchema from '../components/ReactTable/schema';
import { type FoundryEnvironmentName } from '../lib/services/ecosystem';

export interface FoundryEnvironmentInfo {
    name: FoundryEnvironmentName;
    foundry_version: string;
    projects: string[];
}

export const DatasetPermission = {
    permission: ReactTableSchema.enumStr(['READ', 'WRITE', 'ADMIN'] as const),
    group_user_name: ReactTableSchema.str(),
    is_group: ReactTableSchema.bool(),
};
export type DatasetPermission = ReactTableSchema.Type<typeof DatasetPermission>;
export type Permission = DatasetPermission['permission'];

export type DatasetKind = 'substance' | 'molecule';

export const Dataset = {
    created_on: ReactTableSchema.datetime({ format: 'date' }),
    modified_on: ReactTableSchema.datetime({ format: 'date' }),
    description: ReactTableSchema.str(),
    id: ReactTableSchema.int(),
    kind: ReactTableSchema.enumStr(['substance', 'molecule'] as const),
    name: ReactTableSchema.str(),
    private: ReactTableSchema.bool(),
};
export type Dataset = ReactTableSchema.Type<typeof Dataset>;

export function columnNameToHeader(name: string) {
    if (name.toLowerCase() === 'smiles') return 'SMILES';
    if (name.toLowerCase() === 'molecular_weight') return 'MW (g/mol)';
    let original = name;
    if (name.includes('ic50')) original = name.replace('ic50', 'IC50');
    if (name.includes('ic90')) original = name.replace('ic90', 'IC90');
    return `${original.charAt(0).toUpperCase()}${original.substring(1).replaceAll('_', ' ')}`;
}

export interface PlotAxisOption {
    label: string;
    value: any;
}

export type ProviderId = string;

export interface FoundryUser {
    display_name: string;
    email: string;
    is_group: boolean;
    username: string;
    uuid: string;
    provider_id?: ProviderId;
}

export interface UserOrGroupDisplayModel {
    display_name: string;
    email: string;
    is_group: boolean;
    username: string;
    uuid: string;
}

export interface FoundryUserWithGroups {
    username: string;
    email: string;
    full_name: string;
    provider_type: string;
    provider_id?: string;
    groups: string[];
}
