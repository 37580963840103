import saveAs from 'file-saver';
import api from '../../api';
import { columnDataTableStore, DataTableStore } from '../../components/DataTable';
import { BioPolymerMoleculeAnnotation, BioPolymerMoleculeSchema, GraphMoleculeSchema } from '../../lib/models/3d';
import { AssayProperty } from '../../lib/models/biology';
import { reportErrorAsToast } from '../../lib/util/errors';
import { decodeEntosMsgpack } from '../../lib/util/serialization';

export interface GraphMoleculeUploadData {
    filename: string;
    index: number;
    molecule: GraphMoleculeSchema;
    props: Record<string, any>;
    name?: string;
    target_eoi?: string;
    smiles: string;
    compound_identifier?: string;
}

export interface ExportSDFEntry {
    graphmolecule_eoi: string;
    props?: Record<string, string>;
}

export const EBAPI = {
    getBPM: async (eoi: string): Promise<BioPolymerMoleculeSchema> => {
        const { data } = await api.client.post('envision-bio/structure/bpm', { eoi }, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data, { eoi: 'hex' });
    },
    getBPMAnnotations: async (eois?: string[]): Promise<BioPolymerMoleculeAnnotation[]> => {
        const { data } = await api.client.post(
            'envision-bio/annotations/bpm',
            { eois },
            { responseType: 'arraybuffer' }
        );
        return decodeEntosMsgpack(data, { eoi: 'hex', eoiPattern: 'substr' });
    },
    getGraphMolecules: async (options: {
        eois: string[];
        include_smiles?: boolean;
    }): Promise<{ molecules: GraphMoleculeSchema[]; smiles?: Record<string, string> }> => {
        const { data } = await api.client.post('envision-bio/structure/graphs', options, {
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data, { eoi: 'hex' });
    },
    uploadGraphMolecules: async (files: File[]): Promise<GraphMoleculeUploadData[]> => {
        const formData = new FormData();
        files.forEach((file) => formData.append('files', file));

        const { data } = await api.client.post('envision-bio/structure/graph/upload', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(new Uint8Array(data), { eoi: 'hex', eoiPattern: 'substr' });
    },
    getGraphMoleculeMocks: async (eois: string[]): Promise<GraphMoleculeSchema[]> => {
        const { data } = await api.client.post(
            'envision-bio/structure/graph-mocks',
            { eois },
            { responseType: 'arraybuffer' }
        );
        return decodeEntosMsgpack(data, { eoi: 'hex' });
    },
    properties: async (): Promise<AssayProperty[]> => {
        const { data } = await api.client.get('envision-bio/properties', { responseType: 'arraybuffer' });
        const tableData = decodeEntosMsgpack(data, { eoi: 'hex' });
        const store: DataTableStore<AssayProperty> = columnDataTableStore(tableData);
        return store.toObjects();
    },
    exportSDF: async (entries: ExportSDFEntry[]): Promise<string> => {
        const { data } = await api.client.post('envision-bio/export/sdf', { entries }, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
    exportPDB: async (biopolymer_eoi: string): Promise<string> => {
        const { data } = await api.client.post(
            'envision-bio/export/pdb',
            { biopolymer_eoi },
            { responseType: 'arraybuffer' }
        );
        return decodeEntosMsgpack(data);
    },
};

export async function downloadPDB(eoi: string, options?: { filename?: string }) {
    try {
        const pdb = await EBAPI.exportPDB(eoi);
        saveAs(new Blob([pdb], { type: 'chemical/pdb' }), options?.filename ?? `bpm_${eoi}.pdb`);
    } catch (err) {
        reportErrorAsToast('Download PDB', err);
    }
}
