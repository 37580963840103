import React, { useEffect, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Page, PageTemplate } from '../../../components/Layout/Layout';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import { ErrorMessage } from '../../../components/common/Error';
import {
    Column,
    ColumnsFor,
    DataTableControl,
    DataTableModel,
    DefaultRowHeight,
    tableRowNavigation,
} from '../../../components/DataTable';
import { ChemCartAPI, ChemcartOrderItems } from './chemcart-api';
import Loading from '../../../components/common/Loading';
import useBehavior from '../../../lib/hooks/useBehavior';

const ChemcartOrdersPage: Page = {
    href: '/ecm/orders/chemcart',
    title: 'ChemCart Orders',
};

const AllChemCartOrderTableSchema = (): ColumnsFor<ChemcartOrderItems> => ({
    order_id: Column.create({
        ...Column.int(),
        header: 'Chemcart Order Id',
        width: 150,
    }),
    order_item_id: Column.int(),
    supplier: Column.str(),
    catalog_number: Column.str(),
    number_ordered: Column.int(),
    product_name: Column.create({
        ...Column.str(),
        header: 'Product Name',
        width: 400,
    }),
    size: Column.str(),
    units: Column.str(),
    price: Column.str(),
    currency: Column.str(),
    status: Column.str(),
    ordered_by: Column.str(),
});

async function loadAllChemcartOrdersTable() {
    const [orders] = await Promise.all([ChemCartAPI.getAllOrders()]);
    const table = new DataTableModel(orders, {
        columns: AllChemCartOrderTableSchema(),
        hideNonSchemaColumns: true,
    });
    table.setRowHeight(2 * DefaultRowHeight, { silent: true });
    table.sortBy('order_id', true);

    return table;
}

async function loadChemcartSpecificOrderTableAndCurrentUser(chemcart_order_id: number) {
    const [orders] = await Promise.all([ChemCartAPI.getChemcartOrder(chemcart_order_id)]);
    const table = new DataTableModel(orders, {
        columns: AllChemCartOrderTableSchema(),
        hideNonSchemaColumns: true,
    });
    table.setRowHeight(2 * DefaultRowHeight, { silent: true });
    table.sortBy('order_item_id', true);

    return table;
}

export function ChemCartOrdersLayoutRoot() {
    return (
        <PageTemplate title={ChemcartOrdersPage.title} breadcrumb={ChemcartOrdersPage} button={<SearchOrderButton />}>
            <div className='vstack h-100'>
                <div className='flex-0 mx-3 mt-3'>
                    <h4>All ChemCart Orders</h4>
                </div>
                <AllOrders />
            </div>
        </PageTemplate>
    );
}

export function ChemCartOrderDetails() {
    const { id: _id } = useParams();
    const chemcart_order_id = Number.isInteger(+_id!) ? +_id! : null;

    return (
        <PageTemplate
            title={`Order #${chemcart_order_id}`}
            breadcrumb={[ChemcartOrdersPage, { title: `Order #${chemcart_order_id}`, href: window.location.pathname }]}
            button={<SearchOrderButton />}
        >
            <div className='vstack h-100'>
                <div className='flex-0 mx-3 mt-3'>
                    <h4>ChemCart Order {chemcart_order_id} Details</h4>
                </div>
                <OrderDetails chemcart_order_id={chemcart_order_id} />
            </div>
        </PageTemplate>
    );
}

function SearchOrderButton() {
    const navigate = useNavigate();
    const inputRef = useRef<HTMLInputElement>();
    const [search, loadSearch] = useAsyncAction<string>();

    async function handleSubmit() {
        if (inputRef.current) {
            navigate(`/ecm/orders/chemcart/${+inputRef.current.value}`);
        }
    }

    return (
        <div className='entos-identifier-search flex-grow-1'>
            <div className='text-button-combo d-flex'>
                <Form.Control
                    ref={inputRef as any}
                    type='text'
                    placeholder='Chemcart Order #'
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') handleSubmit();
                    }}
                    onBlur={() => loadSearch(undefined)}
                />
                <Button variant='primary' disabled={search.isLoading} onClick={() => handleSubmit()}>
                    Submit
                </Button>
            </div>
        </div>
    );
}

function AllOrders() {
    const [_data, loadData] = useAsyncAction<DataTableModel<ChemcartOrderItems>>();
    const table = _data.result;
    const currentTable = useRef<DataTableModel<ChemcartOrderItems>>();
    currentTable.current = table;

    useEffect(() => {
        loadData(loadAllChemcartOrdersTable());
    }, []);

    return (
        <div className='flex-grow-1 position-relative mt-2'>
            {_data.isLoading && <Loading />}
            {_data.error && <ErrorMessage message={_data.error} />}
            {table && <ChemcartAllOrdersListTableWrapper table={table} />}
        </div>
    );
}

function OrderDetails({ chemcart_order_id }: { chemcart_order_id: number | null }) {
    const [_data, loadData] = useAsyncAction<DataTableModel<ChemcartOrderItems>>();
    const table = _data.result;
    const currentTable = useRef<DataTableModel<ChemcartOrderItems>>();
    currentTable.current = table;

    useEffect(() => {
        if (chemcart_order_id !== null) {
            loadData(loadChemcartSpecificOrderTableAndCurrentUser(chemcart_order_id));
        }
    }, [chemcart_order_id]);

    return (
        <div className='flex-grow-1 position-relative mt-2'>
            {_data.isLoading && <Loading />}
            {_data.error && <ErrorMessage message={_data.error} />}
            {table && <ChemcartOrderDetailsTableWrapper table={table} />}
        </div>
    );
}

function ChemcartAllOrdersListTableWrapper({ table }: { table: DataTableModel<ChemcartOrderItems> }) {
    const navigate = useNavigate();
    useBehavior(table.version);

    const onRowDoubleClick = (rowIndex: number, tbl: DataTableModel<ChemcartOrderItems>, e: React.MouseEvent) => {
        tableRowNavigation(e, `/ecm/orders/chemcart/${tbl.store.getValue('order_id', rowIndex)}`, navigate);
    };

    return (
        <DataTableControl
            height='flex'
            table={table}
            headerSize='sm'
            rowSelectionMode='single'
            onRowDoubleClick={onRowDoubleClick}
        />
    );
}

function ChemcartOrderDetailsTableWrapper({ table }: { table: DataTableModel<ChemcartOrderItems> }) {
    useBehavior(table.version);

    return <DataTableControl height='flex' table={table} headerSize='sm' rowSelectionMode='single' />;
}
