import { ColumnTableData } from '../../../components/DataTable';
import { DateLike } from '../../../lib/util/dates';
import { HTESolvent, WellLayout } from '../../HTE/experiment-data';
import { ECMVialRack } from '../ecm-data';

export type ECMRequestStatus = 'open' | 'submitted' | 'in progress' | 'fulfilled' | 'closed';
export type ECMRequestKind = 'arp' | 'wet' | 'dry';

export interface ECMRequestCreate {
    kind: ECMRequestKind;
    arp_bucket_id?: number;
    labware_kind?: string;
    labware_layout?: string;
    shipping_info?: ECMShippingInfo;
    needed_by?: DateLike;
    comment?: string;
    n_copies?: number; // currently used only by ARP
}

export interface ECMRequestUpdate {
    id: number;
    labware_kind?: string;
    labware_layout?: string;
    needed_by?: DateLike;
    comment?: string;
    plate_barcodes?: string[];

    arp_conditions?: ECMARPConditions;
    arp_automation_state?: ECMARPAutomationState;
    arp_automation_status?: ECMARPAutomationStatus;

    wet_automation_state?: ECMWRAutomationState;
    wet_automation_status?: ECMWetAutomationStatus;
}

export interface ECMRequest extends ECMRequestCreate {
    id: number;
    status: ECMRequestStatus;

    arp_bucket_id?: number;
    arp_conditions?: ECMARPConditions;
    arp_automation_state?: ECMARPAutomationState;
    arp_automation_status?: ECMARPAutomationStatus;

    wet_automation_state?: ECMWRAutomationState;
    wet_automation_status?: ECMWetAutomationStatus;

    plate_barcodes?: string[];
    created_by: string;
    created_on: DateLike;
    modified_by?: string;
    modified_on?: DateLike;
    handled_by?: string;
    submitted_on?: DateLike;
    fulfilled_on?: DateLike;
}

export interface ECMRequestLogEntry {
    id: number;
    request_id: number;
    message: string;
    identifiers?: string[];
    created_by: string;
    created_on: DateLike;
}

export interface ECMShippingInfo {
    to: string;
    via: string;
    recipient: string;
    temperature: string;
    comment: string;
}

export interface ECMRequestedSample {
    amount_g?: number;
    volume_l?: number;
    concentration_M?: number;
    solvent?: HTESolvent | null;
}

export interface ECMRequestedBatch {
    id: number;
    identifier: string;
    request_id: number;
    order: number;
    sample: ECMRequestedSample;
    is_rejected: boolean;
    handled_by: string;
    handled_on: DateLike;
    barcode: string;
    comment: string;
    created_by: string;
    created_on: DateLike;
    modified_by?: string;
    modified_on?: DateLike;
}

export const ECMARPDilutionFactor = {
    half_log: 'half_log',
    log: 'log',
    one_to_three: '1 to 3',
    one_to_two: '1 to 2',
} as const;

export const ARP_DEFAULT_NARP_DEAD_VOLUME_L = 2.5e-6;

export type ECMARPDilutionFactor = (typeof ECMARPDilutionFactor)[keyof typeof ECMARPDilutionFactor];

export interface ECMControlBatch {
    identifier?: string;
    sample?: ECMRequestedSample;
    dilution_curve?: ECMARPDilutionCurve;
}

export function isControlBatchEmpty(batch: ECMControlBatch): boolean {
    return !batch.identifier && !batch.sample?.solvent;
}

export interface ECMARPConditions {
    labware_kind: string;
    layout_template: string;
    template?: ECMARPTemplate;
    assay_volume_l: number;
    narp_volume_l?: number;
    narp_dead_volume_l?: number;
    arp_purpose?: string;
    top_assay_concentration_M: number;
    percentage_dmso: number;
    normalize_solvent?: boolean;
    dilution_factor: ECMARPDilutionFactor;
    dilution_curve?: ECMARPDilutionCurve;

    number_of_doses: number;
    number_of_replicates: number;
    number_of_copies: number;

    control_batches: ECMControlBatch[];
    sample_usage: ECMRequestedSample;
    capacity?: number | null;
}

export interface ECMARPBucketCreate {
    name: string;
    description: string;
    conditions: ECMARPConditions;
    projects: string[];
    related_assays: number[];
}

export interface ECMARPBucket extends ECMARPBucketCreate {
    id: number;
    is_hidden: boolean;
    created_by: string;
    created_on: DateLike;
    modified_by?: string;
    modified_on?: DateLike;
}

export function invalidateBucketCreate(create: ECMARPBucketCreate): string | undefined {
    if (!create.name.trim()) return 'Enter name';
    return undefined;
}

export function arpConditionsNoARP(conditions?: ECMARPConditions) {
    return !!conditions?.dilution_curve && conditions.dilution_curve.points.length === 0;
}

export type ECMARPTemplateControlKind = 'positive' | 'negative' | 'rest' | 'reference' | 'unknown';

export interface ECMARPTemplateWell {
    sample_index: number;
    point_index: number;
    is_control: boolean;
    control_kind?: ECMARPTemplateControlKind;
}

export interface ECMARPTemplate {
    well_layout: WellLayout;
    wells: (ECMARPTemplateWell | null)[];
}

export interface ECMARPDilutionTransfer {
    concentration: number;
    volume: number;
}

export interface ECMARPDilutionPoint {
    target_concentration: number;
    transfers: ECMARPDilutionTransfer[];
}

export interface ECMARPDilutionCurve {
    points: ECMARPDilutionPoint[];
    intermediates: ECMARPDilutionPoint[][];

    assay_volume: number;
    intermediate_volume?: number;
    source_concentrations: number[];
}

export interface ECMARPSampleSource {
    barcode?: string;
    well_location?: string;
    concentration?: number;
}

export interface ECMARPAutomationState {
    version: 1;
    source_map: Record<number, ECMARPSampleSource>;
    plate_barcodes: Record<string, string | undefined>;
    solvent_barcode?: string;
    sample_solvent_from_intermediates?: boolean;
    source_racks: ECMVialRack[];
}

export interface ECMARPAutomationConfig {
    intermediate_layout: WellLayout;
    intermediate_dead_volume: number;
    source_rack_layout: WellLayout;
    min_droplet_volume: number;
    min_narp_transfer_volume: number;
    solvent: string;

    normalize_solvent: boolean;
    narp_volume: number;
}

export interface ECMARPAutomationInput {
    curve: ECMARPDilutionCurve;
    template: ECMARPTemplate;
    config: ECMARPAutomationConfig;

    n_copies: number;
    controls: ECMARPAutomationControl;
    samples: ECMARPAutomationRequestedSample[];
}

export interface ECMARPAutomationControl {
    id?: number;
    identifier: string;
    curve?: ECMARPDilutionCurve;
}

export interface ECMARPAutomationRequestedSample {
    id: number;
    identifier: string;
}

export interface ECMARPAutomationProtocols {
    narp_solvent_volumes?: number[][];
    picklists?: Record<string, ColumnTableData>;
}

export interface ECMARPAutomationInstance {
    input: any;
    state: ECMARPAutomationState;

    plates: ECMARPPlateInstance[];
    errors: string[];
    warnings: string[];
    protocols: ECMARPAutomationProtocols;
}

export interface ECMARPSampleLocation {
    source_vial_barcode?: string;
    source_plate_barcode?: string;
    source_plate_label?: string;
    source_plate_layout?: string;
    source_well_location?: string;
}

export interface ECMARPTransferInstance {
    location: ECMARPSampleLocation;
    volume: number;
    concentration?: number;
}

export interface ECMARPWellInstance {
    sample_id?: number;
    identifier?: string;
    control_kind?: ECMARPTemplateControlKind;
    final_volume: number;
    concentration?: number;
    transfers: ECMARPTransferInstance[];
}

export interface ECMARPPlateInstance {
    label: string;
    barcode: string;
    kind: 'nARP' | 'intermediate' | 'destination';
    layout: WellLayout;
    wells: (ECMARPWellInstance | null)[];
}

export interface ECMARPAutomationStatus {
    instance_kv_key?: string;
    finalized_on?: DateLike;
}

export interface ECMWRSampleSource {
    barcode?: string;
    concentration?: number;
}

export interface ECMWRAutomationState {
    source_map: Record<number, ECMWRSampleSource>;

    source_racks: ECMVialRack[];
    target_plate_barcodes: string[];
    target_racks: ECMVialRack[];
}

export interface ECMWRSampleLocation {
    container_label?: string;
    container_layout?: WellLayout;
    well_label?: string;

    vial_barcode?: string;
}

export interface ECMWRTransfer {
    location: ECMWRSampleLocation;
    volume: number;
    concentration: number;
}

export interface ECMWRSampleInstance {
    sample_id: number;
    identifier: string;

    volume: number;
    concentration: number;
    solvent: string;
    transfers: ECMWRTransfer[];

    vial_barcode?: string;
}

export interface ECMWRTargetInstance {
    label: string;
    layout: WellLayout;
    wells: (ECMWRSampleInstance | undefined | null)[];
}

export interface ECMWRAutomationProtocols {
    picklists: Record<string, ColumnTableData>;
}

export interface ECMWRAutomationInstance {
    input: any;
    state: ECMWRAutomationState;

    targets: ECMWRTargetInstance[];
    protocols: ECMWRAutomationProtocols;

    errors: string[];
    warnings: string[];
}

export interface ECMWetAutomationStatus {
    instance_kv_key?: string;
    finalized_on?: DateLike;
}
