import { CompoundAssets } from '../../../lib/services/assets';
import { EnumerationAPI, SandwichEnumerationResult } from '../../HTE/enumeration/enumeration-api';
import { HTE2MSAssets } from '../assets';
import { HTEDReaction, HTERAddReactant } from '../data-model';

export async function enumerateReactions(reactions: HTEDReaction[], assets: HTE2MSAssets) {
    const toCompute: [string, number, number][] = [];

    for (const reaction of reactions) {
        // No need to enumerate if product is already registered
        if (reaction.product_identifier) continue;

        const info = getInfo(reaction, assets.entities);
        if (!info) continue;

        const [cacheKey, reaction_chemistry, msdSubstanceId, bbSubstanceId] = info;
        if (
            reaction.product_enumeration?.reaction_chemistry === reaction_chemistry &&
            reaction.product_enumeration?.msd_substance_id === msdSubstanceId &&
            reaction.product_enumeration?.bb_substance_id === bbSubstanceId
        ) {
            // No need to re-compute
            continue;
        }

        const cached = assets.enumerationCache.get(cacheKey);
        if (!cached) {
            toCompute.push([reaction_chemistry, msdSubstanceId, bbSubstanceId]);
        }
    }

    if (toCompute.length > 0) {
        const { enumeration, product_substances } = await EnumerationAPI.sandwich({ reaction_list: toCompute });

        for (const p of product_substances) assets.entities.substancesById.set(p.id!, p);
        for (const result of enumeration) {
            const key = `${result.reaction_id}:${result.msd_substance_id}+${result.bb_substance_id}`;
            assets.enumerationCache.set(key, result);
        }
    }

    const updates = new Map<HTEDReaction, HTEDReaction>();

    for (const reaction of reactions) {
        const info = getInfo(reaction, assets.entities);
        if (!info) continue;

        const cached = assets.enumerationCache.get(info[0]);
        if (cached) {
            const updated = getReactionEnumeration(reaction, cached);
            if (updated) {
                updates.set(reaction, updated);
            }
        }
    }

    return updates;
}

function getReactionEnumeration(
    reaction: HTEDReaction,
    result: SandwichEnumerationResult['enumeration'][number]
): HTEDReaction | undefined {
    const { product_enumeration } = reaction;
    if (
        product_enumeration &&
        product_enumeration.msd_substance_id === result.msd_substance_id &&
        product_enumeration.bb_substance_id === result.bb_substance_id &&
        product_enumeration.reaction_chemistry === result.reaction_id
    ) {
        return;
    }

    return {
        ...reaction,
        product_enumeration: {
            msd_substance_id: result.msd_substance_id,
            bb_substance_id: result.bb_substance_id,
            reaction_chemistry: result.reaction_id,
            substance_id: result.product_substance_ids.length === 1 ? result.product_substance_ids[0] : undefined,
            substance_ids: result.product_substance_ids,
            errors: result.errors,
            // For testing:
            // substance_ids: [],
            // errors: ['test error'],
        },
    };
}

function getInfo(reaction: HTEDReaction, assets: CompoundAssets) {
    if (!reaction.template.reaction_chemistry) return;
    const msd = reaction.template.instructions.find((i) => i.kind === 'add' && i.reactant_kind === 'msd') as
        | HTERAddReactant
        | undefined;
    const bb = reaction.template.instructions.find((i) => i.kind === 'add' && i.reactant_kind === 'bb') as
        | HTERAddReactant
        | undefined;
    if (!msd?.identifier || !bb?.identifier) return;

    const msdSubstanceId = assets.getSubstanceId(msd.identifier);
    const bbSubstanceId = assets.getSubstanceId(bb.identifier);
    if (typeof msdSubstanceId !== 'number' || typeof bbSubstanceId !== 'number') return;

    const cacheKey = `${reaction.template.reaction_chemistry}:${msdSubstanceId}+${bbSubstanceId}`;

    return [cacheKey, reaction.template.reaction_chemistry!, msdSubstanceId, bbSubstanceId] as const;
}
