import {
    faAdd,
    faExclamationCircle,
    faExclamationTriangle,
    faSquareCheck,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { BehaviorSubject } from 'rxjs';
import { DataTableControl, DataTableModel } from '../../../components/DataTable';
import { AsyncActionButton } from '../../../components/common/AsyncButton';
import { SingleFileUploadV2 } from '../../../components/common/FileUpload';
import { IconButton, IconDropdownButton } from '../../../components/common/IconButton';
import { TextInput } from '../../../components/common/Inputs';
import { ScrollBox } from '../../../components/common/ScrollBox';
import useBehavior from '../../../lib/hooks/useBehavior';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { DialogService } from '../../../lib/services/dialog';
import { EcosystemService } from '../../../lib/services/ecosystem';
import { objectIsEmpty } from '../../../lib/util/misc';
import { KnownReactantTypes } from '../data-model';
import { HTEPanel } from '../utils';
import { EditConditionsButton } from './conditions';
import { HTE2MSFiltersUI } from './filters';
import { EditLabwareButton } from './labware';
import { PlateLayoutUI } from './layout';
import { HTE2MSDesignModel } from './model';
import { HTE2MSProductActions, HTE2MSProductUI } from './product';
import { HTE2MSReactionUI } from './reaction';

export function HTE2MSDesignUI({ model }: { model: HTE2MSDesignModel }) {
    useMountedModel(model);
    useBehavior(model.model.state.readOnlyDesignAndProduction);

    return (
        <div className='d-flex w-100 h-100 flex-column'>
            <div style={{ minHeight: 300, maxHeight: 300 }} className='position-relative'>
                <div className='d-flex w-100 h-100'>
                    <div className='flex-grow-1 position-relative' style={{ minWidth: 0 }}>
                        <ReactionEditor model={model} />
                    </div>
                    <div className='border-start position-relative' style={{ minWidth: 360, maxWidth: 360 }}>
                        <Product model={model} />
                    </div>
                </div>
            </div>
            <div className='flex-grow-1 position-relative'>
                <div className='d-flex w-100 h-100'>
                    <div className='border-end' style={{ minWidth: 400, maxWidth: 400 }}>
                        <PlateLayoutUI model={model} />
                    </div>
                    <div className='flex-grow-1 position-relative' style={{ minWidth: 0 }}>
                        <Reactions model={model} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function Reactions({ model }: { model: HTE2MSDesignModel }) {
    return (
        <>
            <div className='d-flex w-100 h-100'>
                <div className='flex-grow-1 position-relative'>
                    <ReactionsTable model={model} />
                </div>
                <div className='border-start position-relative' style={{ minWidth: 360, maxWidth: 360 }}>
                    <HTE2MSFiltersUI model={model} />
                </div>
            </div>
        </>
    );
}

function Summary({ model }: { model: HTE2MSDesignModel }) {
    useBehavior(model.table.version);
    const summary = useBehavior(model.state.summary);
    const selectedRowCount = Object.keys(model.table.selectedRows).length;

    if (summary.total === 0) return <div className='ms-2 font-body-small text-secondary'>No reactions</div>;

    return (
        <div className='ms-2 hstack gap-2 font-body-small'>
            {summary.error > 0 && (
                <div className='text-danger' title='Errors'>
                    <FontAwesomeIcon icon={faExclamationCircle} size='sm' className='me-1' />
                    {summary.error}
                </div>
            )}
            {summary.warning > 0 && (
                <div className='text-warning' title='Warnings'>
                    <FontAwesomeIcon icon={faExclamationTriangle} size='sm' className='me-1' />
                    {summary.warning}
                </div>
            )}
            {summary.info > 0 && (
                <div className='text-info' title='Infos'>
                    <FontAwesomeIcon icon={faExclamationTriangle} size='sm' className='me-1' />
                    {summary.info}
                </div>
            )}
            <div>
                <FontAwesomeIcon icon={faSquareCheck} size='sm' className='me-1' /> {selectedRowCount} of{' '}
                {summary.total} selected
            </div>
        </div>
    );
}

function ReactionEditor({ model }: { model: HTE2MSDesignModel }) {
    return (
        <HTEPanel
            title={<Summary model={model} />}
            controls={
                <div className='hstack gap-2'>
                    <UseExampleButton model={model} />

                    <EditLabwareButton model={model} />
                    <EditConditionsButton model={model} />
                    <div />
                    <RemoveSelected model={model} />
                    <AddReactionsButton model={model} />
                </div>
            }
            noBorder
        >
            <HTE2MSReactionUI model={model} />
        </HTEPanel>
    );
}

function UseExampleButton({ model }: { model: HTE2MSDesignModel }) {
    const env = useBehavior(EcosystemService.environment);
    if (env?.name !== 'dev') return null;

    return (
        <AsyncActionButton
            action={() => model.useExample()}
            variant='link'
            size='sm'
            className='text-secondary me-2'
            style={{ opacity: 0.15 }}
        >
            Use Example
        </AsyncActionButton>
    );
}

function RemoveSelected({ model }: { model: HTE2MSDesignModel }) {
    useBehavior(model.table.version);
    const disabled = objectIsEmpty(model.table.selectedRows) || model.model.readOnlyDesignAndProduction;

    const remove = () => {
        DialogService.open({
            type: 'confirm',
            title: 'Remove Selected Reactions',
            text: 'Are you sure you want to remove all selected reactions?',
            confirmText: 'Remove',
            onConfirm: () => model.removeSelectedReactions(),
        });
    };

    return <IconButton icon={faTrash} disabled={disabled} onClick={remove} variant='link' />;
}

function Product({ model }: { model: HTE2MSDesignModel }) {
    return (
        <HTEPanel title='Product' controls={<HTE2MSProductActions model={model} />}>
            <HTE2MSProductUI model={model} />
        </HTEPanel>
    );
}

function ReactionsTable({ model }: { model: HTE2MSDesignModel }) {
    useBehavior(model.table.version);

    return (
        <>
            <DataTableControl
                table={model.table}
                height='flex'
                headerSize='xxsm'
                onRowDoubleClick={(idx) => model.table.setSelection([idx])}
                onRowMouseEnter={(idx) => model.layout.highlightReactionIds([model.all[idx].id])}
                onRowMouseLeave={() => model.layout.highlightReactionIds([])}
            />
            <WarnEmptySelection table={model.table} />
        </>
    );
}

function WarnEmptySelection({ table }: { table: DataTableModel }) {
    if (table.rows.length > 0 || !table.state.showSelectedRowsOnly) return null;

    return (
        <div className='p-2 d-flex align-items-center justify-content-center text-center w-100 h-100'>
            <span className='text-secondary font-body-small'>
                <b>Show Only Selected Reactions</b> mode is on and no reactions are selected
            </span>
        </div>
    );
}

const _DEBUG_CSV = `MSD Identifier,BB Identifier,Catalyst Identifier,Solvent,Reaction Chemistry,Product,Group Name
CMPD2019874-005,CMPD2039484-002,CMPD2030024,DMSO,amide_coupling,CMPD2024144,1
CMPD2042741-001,CMPD2042783-001,CMPD2030024,MeOH,,,1
CMPD2042742-001,CMPD2019876-002,CMPD2030024,DMSO,,,2`;

function AddReactionsButton({ model }: { model: HTE2MSDesignModel }) {
    const upload = () =>
        DialogService.open({
            type: 'generic',
            title: `Upload Reaction List`,
            confirmButtonContent: 'Apply',
            model,
            defaultState: { csv: EcosystemService.environment.value?.name === 'dev' ? _DEBUG_CSV : '' },
            content: UploadReactionsDialogContent,
            wrapOk: true,
            options: { size: 'lg' },
            onOk: (state) => model.uploadReactions(state.file || state.csv),
        });

    return (
        <IconDropdownButton
            icon={faAdd}
            size='sm'
            variant='link'
            disabled={model.model.readOnlyDesignAndProduction}
            label='Add Reactions'
        >
            <Dropdown.Item onClick={upload}>Upload CSV</Dropdown.Item>
            <Dropdown.Item onClick={() => model.addEmptyReactions()}>Create Empty</Dropdown.Item>
        </IconDropdownButton>
    );
}

const RULES_MARKDOWN = `- All parameters can be tweaked later
- Optional columns:
    - \`Product\`/\`Product Identifier\`, \`Reaction Chemistry\`/\`Rxn\`, \`Group Name\`/\`Group\`, \`Solvent\`, \`Scale\`, \`Project\`, \`Concentration\`/\`Target Concentration\`, \`Std Concentration\`/\`Standard Concentration\`, \`Well\`/\`Well Label\`
    - \`Concentration\` values should contain a unit, e.g., \`25 mM\`
- \`{KIND} {PARAM}\` to add instructions. Supported parameters are:
    - \`Kind\`, \`Name\`, \`Identifier\`, \`Equivalence\`, \`Concentration\`, \`Neat Concentration\`, \`Dose Volume\`, \`Neat Solvent\`, \`Use Overage\`, \`Solution ID\`
    - \`Kind\` should be one of ${KnownReactantTypes.map((r) => `_${r}_`).join(', ')}
    - \`Identifier\` can be implicit (e.g., \`MSD\` is same as \`MSD Identifier\`), but it's recommended to be explicit
    - \`Concentration\`, \`Neat Concentration\` values should contain a unit, e.g., \`10 mM\`
- Supported identifiers:
  - Compound Identifier (matches any salt when filtering/assigning inventory)
  - Batch Identifier (matches specific salt)
  - Vial Barcode (will be converted to Batch Identifier)
  - CAS Number (will be converted to Batch Identifier; if multiple batches match, lowest batch number is picked)
- Example columns:
    - \`MSD,BB,Catalyst Identifier,Solvent,Reaction Chemistry,Product,Group Name\`

Example input template:
\`\`\`
MSD,BB,Group
CMPD2042741-001,HDP24326002,grp 1
1807939-04-3,TESTV23147001,grp 2
\`\`\``;

function UploadReactionsDialogContent({
    stateSubject,
}: {
    stateSubject: BehaviorSubject<{ csv: string; file?: File }>;
}) {
    const [showRules, setShowRules] = useState(false);
    const state = useBehavior(stateSubject);

    return (
        <div className='vstack gap-2'>
            <h6 className='m-0'>Upload CSV/XLS or paste columns from Excel</h6>
            <Button variant='link' className='p-0 fw-bold text-start' onClick={() => setShowRules((v) => !v)}>
                {showRules ? 'Hide' : 'View'} Input Rules
            </Button>
            {showRules && (
                <ScrollBox maxHeight={200}>
                    <ReactMarkdown className='hte2ms-add-instructions font-body-small'>{RULES_MARKDOWN}</ReactMarkdown>
                </ScrollBox>
            )}
            {!state.file && (
                <TextInput
                    textarea
                    rows={3}
                    placeholder='Paste CSV with reactions...'
                    value={state.csv}
                    setValue={(csv) => stateSubject.next({ ...state, csv: csv.replaceAll('\t', ',') })}
                />
            )}
            <SingleFileUploadV2
                file={state.file}
                onDrop={(acceptedFiles: any[]) =>
                    stateSubject.next({ ...state, file: acceptedFiles.length ? acceptedFiles[0] : undefined })
                }
                label='CSV/XLS/XLSX file with reactions...'
                inline
                extensions={['.csv', '.xls', '.xlsx']}
            />
        </div>
    );
}
