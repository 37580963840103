/* eslint no-param-reassign: 0 */
import type { Chart, ChartDataset, ChartType, Plugin, PluginOptionsByType } from 'chart.js';
import { drawPoint, DrawPointOptions, getRelativePosition, isArray } from 'chart.js/helpers';
import { BehaviorSubject, Subject } from 'rxjs';
import type { CompoundPoint } from '../../../components/Chart/model';
import { getCurrentTheme } from '../../../components/theme';
import { debounce, isBlank } from '../../util/misc';
import { PLOT_COLORS } from '../../util/plot';
import { EventPlugin } from './eventPlugin';

type LassoPluginOptions = PluginOptionsByType<ChartType>['lassoSelection'];

export const getLassoSelectPlugin = (model: LassoSelectPluginModel): Plugin => ({
    id: 'lassoSelection',
    defaults: {
        enabled: false,
        lasso: {
            backgroundColor: 'rgba(128,128,128,0.1)',
            borderColor: getCurrentTheme() === 'dark' ? 'white' : 'black',
            borderWidth: 2,
            borderDash: [5, 5],
            borderDashOffset: 2,
        },
    },
    start: (chart, _args, options) => {
        model.chart = chart;
        model.currentLasso.options = options as LassoPluginOptions;
    },
    beforeEvent: () => {
        if (model.state.dragging.value) {
            // cancel any event handling while dragging
            return false;
        }
    },
    beforeUpdate: (_chart, _args, options) => {
        model.currentLasso.options = options as LassoPluginOptions;
        model.addListeners(options.enabled);
    },
    afterDatasetsDraw: (chart, _args) => {
        // we want to draw the selection information
        // on the layer after the datasets are drawn
        draw(model, chart);
    },
    stop: (_chart: Chart) => {
        model.removeListeners();
        model.chart = null;
    },
});

interface LassoSelectionState {
    options: LassoPluginOptions;
    dragStart: Event | null;
    dragEnd: Event | null;
    dragPoints: { x: number; y: number }[];
    dragBounds: [[number, number], [number, number]]; // bounding box of drag shape
}

export class LassoSelectPluginModel extends EventPlugin {
    public currentLasso: LassoSelectionState = {
        options: { enabled: false },
        dragStart: null,
        dragEnd: null,
        dragPoints: [],
        dragBounds: [
            [Infinity, Infinity],
            [-Infinity, -Infinity],
        ],
    };

    state = {
        dragging: new BehaviorSubject<boolean>(false),
        selectedPoints: new BehaviorSubject<Record<number, number[]>>({}), // key is dataset index
    };

    events = {
        lasso: new Subject<void>(),
    };

    updateSelection(indices: Record<number, number[]>) {
        this.state.selectedPoints.next(indices);
        this.chart?.update('none');
    }

    clearSelection() {
        this.updateSelection({});
    }

    addListeners(enabled: boolean) {
        if (!this.chart) return;
        const canvas = this.chart.canvas;

        if (enabled && !this.hasHandler('mousedown')) {
            this.addHandler(canvas, 'mousedown', this.mouseDown);
            this.addHandler(canvas.ownerDocument, 'mouseup', this.mouseUp);
        } else if (!enabled && this.hasHandler('mousedown')) {
            this.removeHandler('mousedown');
            this.removeHandler('mousemove');
            this.removeHandler('mouseup');
            this.removeHandler('keydown');
        }
    }

    private mouseDown(event: Event) {
        // shift+drag for lasso selection
        if (!(event as MouseEvent).shiftKey) return;
        this.currentLasso.dragStart = event;
        this.state.selectedPoints.next({});

        if (this.chart) {
            const debouncedMouseMove = debounce((e: Event) => this.mouseMove(e), 33);
            this.addHandler(this.chart.canvas, 'mousemove', debouncedMouseMove);
        }
    }

    private mouseMove(event: Event) {
        const { dragStart, dragPoints, dragBounds } = this.currentLasso;

        if (dragStart) {
            const point = getRelativePosition(event, this.chart as any);
            this.state.dragging.next(true);
            this.currentLasso.dragPoints = dragPoints ? [...dragPoints, point] : [point];
            this.currentLasso.dragBounds = [
                [Math.min(dragBounds[0][0], point.x), Math.min(dragBounds[0][1], point.y)],
                [Math.max(dragBounds[1][0], point.x), Math.max(dragBounds[1][1], point.y)],
            ];
            this.currentLasso.dragEnd = event;
            this.chart?.update('none');
        }
    }

    private mouseUp(_event: Event) {
        const { chart } = this;
        const { dragStart, dragPoints, dragBounds } = this.currentLasso;
        const selectedPoints = this.state.selectedPoints.value;
        if (!dragStart || !chart) return;

        this.removeHandler('mousemove');

        const numPoints = dragPoints?.length ?? 0;
        const numDatasets = chart.data.datasets.length;

        const point: [number, number] = [Number.NaN, Number.NaN];
        const newSelected = { ...selectedPoints };
        const polygon: [number, number][] = dragPoints?.map((p) => [p.x, p.y]) ?? [];
        for (let datasetIdx = 0; datasetIdx < numDatasets; datasetIdx++) {
            const dataset = chart.data.datasets[datasetIdx];
            for (let dataIdx = 0; dataIdx < dataset.data.length; dataIdx++) {
                getPixelValuesForPoint(chart, dataset, dataIdx, point);
                if (
                    point[0] > dragBounds[0][0] &&
                    point[0] < dragBounds[1][0] &&
                    point[1] > dragBounds[0][1] &&
                    point[1] < dragBounds[1][1] &&
                    pointInPolygon(point, polygon, dragBounds[0])
                ) {
                    if (!newSelected[datasetIdx]) newSelected[datasetIdx] = [];
                    newSelected[datasetIdx].push(dataIdx);
                }
            }
        }

        this.state.selectedPoints.next(newSelected);
        this.events.lasso.next();
        this.currentLasso.dragStart = null;
        this.currentLasso.dragEnd = null;
        this.currentLasso.dragPoints = [];
        this.currentLasso.dragBounds = [
            [Infinity, Infinity],
            [-Infinity, -Infinity],
        ];

        highlightSelected(chart, newSelected);

        if (numPoints < 3 || Object.keys(newSelected).length === 0) {
            this.state.dragging.next(false);
            chart.update('none');
            return;
        }

        setTimeout(() => {
            this.state.dragging.next(false);
            chart.update('none');
        }, 500);
    }

    constructor() {
        super();
    }
}

function getPixelValuesForPoint(
    chart: Chart,
    dataset: ChartDataset,
    index: number,
    target: [number, number]
): [number, number] {
    if (!dataset) {
        target[0] = Number.NaN;
        target[1] = Number.NaN;
        return target;
    }
    const point = dataset.data[index];
    if (typeof point === 'number') {
        target[0] = point;
        target[1] = point;
        return target;
    }
    const px = isArray(point) ? point[0] : point?.x;
    const py = isArray(point) ? point[1] : point?.y;
    const x = px !== undefined ? chart.scales.x.getPixelForValue(px) : 0;
    const y = py !== undefined ? chart.scales.y.getPixelForValue(py) : 0;
    target[0] = x;
    target[1] = y;
    return target;
}

const OVERLAP_PADDING = 5;
function highlightSelected(chart: Chart, selectedPoints: Record<number, number[]>) {
    if (!chart.scales.x) return;

    const ctx = chart.ctx;

    const point: [number, number] = [Number.NaN, Number.NaN];
    const datasetIndices = Object.keys(selectedPoints).map((i) => +i);

    if (datasetIndices.length) {
        // dim the background if there is an active selection
        // use bs-body-bg colors but in rgb format
        ctx.save();
        ctx.fillStyle = getCurrentTheme() === 'dark' ? 'rgba(35,36,39,0.2)' : 'rgba(249,249,249,0.2)';
        ctx.fillRect(
            chart.chartArea.left - OVERLAP_PADDING,
            chart.chartArea.top - OVERLAP_PADDING,
            chart.chartArea.width + 2 * OVERLAP_PADDING,
            chart.chartArea.height + 2 * OVERLAP_PADDING
        );
        ctx.restore();
    }

    const options: DrawPointOptions = {
        pointStyle: 'circle',
        radius: 5,
        borderWidth: 1,
    };

    for (const datasetIdx of datasetIndices) {
        const dataset = chart.data.datasets[datasetIdx];
        if (!dataset) continue;
        for (const idx of selectedPoints[datasetIdx]) {
            const dataPoint = dataset.data[idx] as CompoundPoint;
            if (!dataPoint || isBlank(dataPoint.x) || isBlank(dataPoint.y)) continue;
            getPixelValuesForPoint(chart, dataset, idx, point);
            if (
                point[0] < chart.chartArea.left ||
                point[0] > chart.chartArea.right ||
                point[1] < chart.chartArea.top ||
                point[1] > chart.chartArea.bottom
            )
                continue;
            ctx.fillStyle = (dataPoint.backgroundColor as any) ?? (dataset.backgroundColor as any) ?? PLOT_COLORS.pink;
            ctx.strokeStyle =
                (dataPoint.backgroundColor as any) ?? (dataset.backgroundColor as any) ?? PLOT_COLORS.pink;
            ctx.save();
            const el = chart.getDatasetMeta(datasetIdx).data[idx];
            options.pointStyle = dataPoint.pointStyle ?? 'circle';
            options.radius = el.options.radius + 3;
            options.borderWidth = el.options.borderWidth;
            drawPoint(ctx, options, point[0], point[1]);
            ctx.restore();
        }
    }
}

function draw(model: LassoSelectPluginModel, chart: Chart) {
    const { dragPoints, dragEnd, options } = model.currentLasso;
    const selectedPoints = model.state.selectedPoints.value;
    const dragOptions = options?.lasso;

    highlightSelected(chart, selectedPoints);

    if (!dragEnd) {
        return;
    }
    const ctx = chart.ctx;
    ctx.save();
    ctx.fillStyle = (dragOptions?.backgroundColor as any) || 'rgba(225,225,225,0.3)';
    ctx.beginPath();
    for (let i = 0; i < dragPoints.length; i++) {
        const point = dragPoints[i];
        if (i === 0) {
            ctx.moveTo(point.x, point.y);
        } else {
            ctx.lineTo(point.x, point.y);
        }
    }

    if (dragOptions?.borderWidth && dragOptions.borderWidth > 0) {
        ctx.lineWidth = dragOptions.borderWidth;
        ctx.strokeStyle = (dragOptions.borderColor as any) ?? 'rgba(225,225,225)';
        ctx.setLineDash(dragOptions.borderDash ?? [5, 5]);
        ctx.lineDashOffset = dragOptions.borderDashOffset ?? 2;
        ctx.stroke();
    }
    ctx.closePath();
    ctx.fill();
    ctx.restore();
}

// test for intersection of two 2d line segments https://stackoverflow.com/a/24392281
// returns true if the line from (a,b)->(c,d) intersects with (p,q)->(r,s)
function intersects(a: number, b: number, c: number, d: number, p: number, q: number, r: number, s: number) {
    const det = (c - a) * (s - q) - (r - p) * (d - b);
    if (det === 0) return false;
    const lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
    const gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
    return lambda > 0 && lambda < 1 && gamma > 0 && gamma < 1;
}

function pointInPolygon(point: [number, number], polygon: [number, number][], pointOutside: [number, number]) {
    let intersections = 0;
    const [pointX, pointY] = point;

    for (let i = 0; i < polygon.length; i++) {
        const [prevX, prevY] = i > 0 ? polygon[i - 1] : polygon[polygon.length - 1];
        const [currentX, currentY] = polygon[i];
        if (intersects(pointOutside[0], pointOutside[1], pointX, pointY, prevX, prevY, currentX, currentY)) {
            intersections++;
        }
    }

    return (intersections & 1) === 1;
}
