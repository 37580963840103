import { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { LabeledInput } from '../../../components/common/Inputs';
import { LogModel } from '../../../components/common/Log';
import { PropertyNameValue } from '../../../components/common/PropertyNameValue';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import { ToastService } from '../../../lib/services/toast';
import { reportErrorAsToast, tryGetErrorMessage } from '../../../lib/util/errors';
import { formatLocation, Plate, Vial } from '../ecm-api';
import { ECMCommonUploadInfo, ECMPageTemplate, ECMWorkflowMode, ECMWorkflowModeSelect } from '../ecm-common';
import {
    ecmAPITemplate,
    ECMBatchWorkflowBase,
    ECMBatchWorkflowWrapper,
    ecmGatherMessages,
    ECMManualWorkflowBase,
    ECMManualWorkflowInputHelper,
    ECMManualWorkflowStatus,
    ECMManualWorkflowWrapper,
    ecmWorkflowEntrySummary,
    uploadECMCSV,
} from './common';

interface PrepareDisposeInput {
    row_index?: number;
    barcode?: string;
    action?: string;
}

interface PrepareDisposeEntry {
    input: PrepareDisposeInput;

    errors: string[];
    warnings: string[];

    vial?: Vial;
    plate?: Plate;
    action?: 'Dispose' | 'Undo Dispose';
}

type PrepareDisposeEntries = PrepareDisposeEntry[];

const DisposeAPI = ecmAPITemplate<PrepareDisposeInput[], PrepareDisposeEntries>('dispose');

interface ManualDisposeWorkflowInput {
    action: 'Dispose' | 'Undo Dispose';
    barcode: string;
}

class ManualDisposeWorkflow implements ECMManualWorkflowBase<PrepareDisposeEntry> {
    inputs = {
        barcode: { current: null } as MutableRefObject<HTMLInputElement | null>,
    };

    state = {
        input: new BehaviorSubject<ManualDisposeWorkflowInput>({ barcode: '', action: 'Dispose' }),
        entry: new BehaviorSubject<PrepareDisposeEntry | undefined>(undefined),
        autosubmit: new BehaviorSubject<boolean>(false),
        preparing: new BehaviorSubject<boolean>(false),
        submitting: new BehaviorSubject<boolean>(false),
    };

    log = new LogModel();

    get autosubmit() {
        return this.state.autosubmit.value;
    }

    get isInputValid(): boolean {
        // TODO: this could be improved
        const input = this.state.input.value;
        return input.barcode.length > 0;
    }

    async prepare() {
        ToastService.remove('warn-autosubmit');

        if (!this.isInputValid) {
            return;
        }

        try {
            this.state.preparing.next(true);
            const input = this.state.input.value;
            const entries = await DisposeAPI.prepare([
                {
                    barcode: input.barcode,
                    action: input.action,
                },
            ]);

            const entry = entries[0];
            this.state.entry.next(entry);
            if (!entry) return;

            if (!entry.vial && !entry.plate) {
                requestAnimationFrame(() => this.inputs.barcode.current?.focus());
                return;
            }

            if (this.autosubmit && entry.errors.length === 0 && entry.warnings.length === 0) {
                this.submit();
                return;
            }

            if (this.autosubmit && entry.errors.length === 0 && entry.warnings.length > 0) {
                ToastService.show({
                    id: 'warn-autosubmit',
                    type: 'warning',
                    message: 'Auto-submit paused due to a warning.\nPlease fix the issue or submit manually.',
                });
            }
        } catch (err) {
            reportErrorAsToast('Dispose', err);
            this.state.entry.next(undefined);
        } finally {
            this.state.preparing.next(false);
        }
    }

    async submit() {
        const entry = this.state.entry.value;
        if (!entry) return;

        try {
            this.state.submitting.next(true);

            await DisposeAPI.upload([entry]);
            // NOTE: For testing to fake upload
            // await new Promise((res) => {
            //     setTimeout(res, 500);
            // });

            this.log.message(
                'success',
                `${entry.vial ? 'Vial' : 'Plate'} ${entry.input.barcode} ${
                    entry.action === 'Dispose' ? 'disposed' : 'undisposed'
                }.`
            );
            this.state.input.next({ barcode: '', action: this.state.input.value.action });
            this.state.entry.next(undefined);
            requestAnimationFrame(() => this.inputs.barcode.current?.focus());
        } catch (err) {
            this.log.message('danger', `${entry?.input.barcode} error: ${tryGetErrorMessage(err)}`);
        } finally {
            this.state.submitting.next(false);
        }
    }
}

class BatchDisposeWorkflow implements ECMBatchWorkflowBase<PrepareDisposeEntries> {
    state = {
        submitting: new BehaviorSubject<boolean>(false),
    };
    entries: PrepareDisposeEntries;
    summary: ECMBatchWorkflowBase['summary'];
    messages: ECMBatchWorkflowBase['messages'];

    async submit() {
        if (this.summary.numErrors > 0 || !this.entries.length) {
            return;
        }

        try {
            this.state.submitting.next(true);

            await DisposeAPI.upload(this.entries);
            // NOTE: For testing to fake upload
            // await new Promise((res) => {
            //     setTimeout(res, 500);
            // });

            this.fileSubject.next(null);
            ToastService.show({
                type: 'success',
                message: `Successfully disposed ${this.entries.length} vials/plates.`,
            });
        } catch (err) {
            reportErrorAsToast('Dispose', err);
        } finally {
            this.state.submitting.next(false);
        }
    }

    constructor(
        public files: [filename: string, entries: PrepareDisposeEntries][],
        private fileSubject: BehaviorSubject<File[] | null>
    ) {
        this.entries = files.flatMap((f) => f[1]);
        this.summary = ecmWorkflowEntrySummary(this.entries);
        this.messages = ecmGatherMessages(files);
    }
}

export function ECMDispose() {
    const [mode, setMode] = useState<ECMWorkflowMode>('manual');

    return (
        <ECMPageTemplate page='dispose' withFooter>
            <ECMWorkflowModeSelect mode={mode} setMode={setMode} />
            {mode === 'manual' && <ManualRoot />}
            {mode === 'batch' && <BatchRoot />}
        </ECMPageTemplate>
    );
}

const ManualModel = new ManualDisposeWorkflow();

function ManualRoot() {
    const model = ManualModel;

    return (
        <ECMManualWorkflowWrapper
            model={model}
            input={<ManualInput model={model} />}
            status={<ManualStatus model={model} />}
        />
    );
}

function ManualInput({ model }: { model: ManualDisposeWorkflow }) {
    const preparing = useBehavior(model.state.preparing);
    const submitting = useBehavior(model.state.submitting);
    const disabled = preparing || submitting;

    const input = useBehavior(model.state.input);

    return (
        <ECMManualWorkflowInputHelper model={model} disabled={disabled} preparing={preparing}>
            <LabeledInput label='Action' className='ecm-manual-inputs-row'>
                <ButtonGroup className='w-100'>
                    <Button
                        variant={input.action === 'Dispose' ? 'primary' : 'outline-primary'}
                        onClick={() => {
                            model.state.input.next({ ...input, action: 'Dispose' });
                            model.inputs.barcode.current?.focus();
                        }}
                    >
                        Dispose
                    </Button>
                    <Button
                        variant={input.action === 'Undo Dispose' ? 'primary' : 'outline-primary'}
                        onClick={() => {
                            model.state.input.next({ ...input, action: 'Undo Dispose' });
                            model.inputs.barcode.current?.focus();
                        }}
                    >
                        Undo Dispose
                    </Button>
                </ButtonGroup>
            </LabeledInput>
            <LabeledInput label='Barcode' className='ecm-manual-inputs-row'>
                <Form.Control
                    value={input.barcode}
                    ref={model.inputs.barcode}
                    onChange={(e) => model.state.input.next({ ...input, barcode: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.barcode) model.prepare();
                    }}
                    disabled={disabled}
                    autoFocus
                />
            </LabeledInput>
        </ECMManualWorkflowInputHelper>
    );
}

function ManualStatus({ model }: { model: ManualDisposeWorkflow }) {
    return (
        <ECMManualWorkflowStatus model={model}>
            {(entry) => (
                <>
                    <PropertyNameValue field='Barcode' value={entry.input.barcode} />
                    {!!entry.vial && <PropertyNameValue field='Vial Status' value={entry.vial.status} />}
                    {!!entry.vial && <PropertyNameValue field='Location' value={formatLocation(entry.vial.location)} />}
                    {!!entry.plate && <PropertyNameValue field='Plate Status' value={entry.plate.status} />}
                    <PropertyNameValue field='Action' value={entry.action} />
                </>
            )}
        </ECMManualWorkflowStatus>
    );
}

function uploadCSV(files: File[] | null, fileSubject: BehaviorSubject<File[] | null>) {
    return uploadECMCSV(DisposeAPI, BatchDisposeWorkflow, files, fileSubject);
}

function BatchRoot() {
    const [model, loadModel] = useAsyncAction<BatchDisposeWorkflow | undefined>();

    const upload = useCallback(
        (files: File[] | null, fileSubject: BehaviorSubject<File[] | null>) => loadModel(uploadCSV(files, fileSubject)),
        [loadModel]
    );
    const info = useMemo(() => <BatchUploadInfo />, []);

    return <ECMBatchWorkflowWrapper model={model} upload={upload} info={info} />;
}

function BatchUploadInfo() {
    return (
        <ECMCommonUploadInfo
            required={['Barcode (header can be omitted if only one column is present)']}
            optional={['Action (=dispose/undispose)']}
        />
    );
}
