import { BehaviorSubject } from 'rxjs';
import { ChemDrawService } from './chemdraw';

interface ChemDrawModalOptions {
    initialSMILES?: string;
    onSMILESDrawn?: (smiles: string) => void;
}

class _ChemDrawModalService {
    readonly options = new BehaviorSubject<ChemDrawModalOptions | undefined>(undefined);

    show(options: ChemDrawModalOptions) {
        if (options === this.options.value) return;
        this.options.next(options);
    }

    close() {
        this.options.next(undefined);
    }

    async confirm() {
        const smiles = await ChemDrawService.export('smiles');
        if (this.options.value) {
            const { onSMILESDrawn } = this.options.value;
            onSMILESDrawn?.(smiles);
        }
        this.close();
    }
}

export const ChemDrawModalService = new _ChemDrawModalService();
