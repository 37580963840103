import saveAs from 'file-saver';
import { BehaviorSubject } from 'rxjs';
import { Column, DataTableModel, ObjectDataTableStore } from '../../../components/DataTable';
import { SelectionColumn } from '../../../components/DataTable/common';
import { InlineAlert } from '../../../components/common/Alert';
import { LabeledInput, TextInput } from '../../../components/common/Inputs';
import useBehavior from '../../../lib/hooks/useBehavior';
import { ClipboardService } from '../../../lib/services/clipboard';
import { DialogService } from '../../../lib/services/dialog';
import { ToastService } from '../../../lib/services/toast';
import { groupByPreserveOrder, memoizeLatest } from '../../../lib/util/misc';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { roundValue } from '../../../lib/util/roundValues';
import { asNumber } from '../../../lib/util/validators';
import { HTEDReaction, HTEIDry, HTEInventory, HTEPReagentUse, HTEProtocol, HTERInstructionIdT } from '../data-model';
import type { HTE2MSModel } from '../model';
import { getDryReagentBarcode, getDryUseAmount } from '../utils/inventory';
import {
    SelectBarcodeCell,
    commonDryReagentsColumnSchema,
    renderBarcode,
    viewDryReagentDesignAction,
} from '../utils/reagent-table';
import { ReagentValidation, validateDryReagent } from '../utils/validation';
import type { HTE2MSReagentsModel, ReagentRow } from './reagents';
import { arrayToCsv } from '../../../lib/util/arrayToCsv';
import api from '../../../api';
import { uploadFileDialog } from '../../../components/common/FileUpload/upload-button';

export interface DryReagentRow extends ReagentRow {
    ratio?: number;
    well_utilization?: number;
}

function getDryUseRatio(model: HTE2MSModel, use: HTEPReagentUse) {
    const barcode = model.inventory.getDryUse(use)?.transfer_barcode;
    if (!barcode) return;
    const sample = model.assets.inventory.getVialSample(barcode);
    if (!sample) return;
    const dryUse = getDryUseAmount(model, use);
    if (!dryUse) return;
    return sample.solute_mass! / dryUse;
}

export class HTE2MSDryReagentsModel extends ReactiveModel {
    store: ObjectDataTableStore<DryReagentRow, HTEPReagentUse> = new ObjectDataTableStore<
        DryReagentRow,
        HTEPReagentUse
    >([
        { name: 'identifier', getter: (v) => this.reagents.getByKey(v.reagent_key)?.identifier },
        {
            name: 'batch_identifier',
            getter: (v) => {
                const barcode = getDryReagentBarcode(this.mainModel, v);
                if (barcode) {
                    const batch = this.reagents.protocol.model.assets.inventory.getVialBatch(barcode);
                    if (batch) return batch.universal_identifier!;
                }
            },
        },
        { name: 'validation', getter: (v) => this.validation.get(v) },
        { name: 'reactant_kinds', getter: (v) => this.getInfo(v)?.reactant_kinds },
        {
            name: 'worklist_title',
            getter: (v) => {
                const reagent = this.reagents.getByKey(v.reagent_key);
                return this.reagents.protocol.worklistMap.get(reagent?.worklist_key!)?.title ?? reagent?.worklist_key;
            },
        },
        { name: 'amount_g', getter: (v) => getDryUseAmount(this.mainModel, v) },
        { name: 'source_barcode', getter: (v) => this.inventory.getDryUse(v)?.source_barcode },
        { name: 'transfer_barcode', getter: (v) => this.inventory.getDryUse(v)?.transfer_barcode },
        { name: 'ratio', getter: (v) => getDryUseRatio(this.mainModel, v) },
        {
            name: 'well_utilization',
            getter: (v) => {
                const maxVolume = this.mainModel.design.labware.product.volume;
                const rxnVolume = this.reagents.protocol.data.product_samples[v.reaction_id]?.total_volume;
                if (!maxVolume || !rxnVolume) return;
                return rxnVolume / maxVolume;
            },
        },
    ]);
    table: DataTableModel<ReagentRow>;

    getInfo(use: HTEPReagentUse) {
        return this.reagents.getInfo(this.reagents.getByKey(use.reagent_key)!);
    }

    get mainModel() {
        return this.reagents.protocol.model;
    }

    get all() {
        return this.store.rawRows;
    }

    private _useValidation = memoizeLatest((all: HTEPReagentUse[], inventory: HTEInventory) => {
        const ret = new Map<HTEPReagentUse, ReagentValidation>();
        for (const use of all) {
            ret.set(use, validateDryReagent(this.mainModel, use));
        }
        return ret;
    });
    get validation() {
        return this._useValidation(this.store.rawRows, this.inventory.data);
    }

    get inventory() {
        return this.mainModel.inventory;
    }

    update(data: HTEProtocol) {
        const uses: HTEPReagentUse[] = [];

        for (const r of data.reagents) {
            if (r.solvent) continue;
            for (const u of r.uses) {
                uses.push(u);
            }
        }

        this.store.setRows(uses);
        this.table.dataChanged();
    }

    get selectedAndFilteredRows() {
        let selectedRows = this.table.getSelectedAndFilteredRowIndices();
        if (!selectedRows.length) selectedRows = this.table.rows as any;
        return selectedRows.map((i) => this.all[i]);
    }

    public autoAssign(options: { kind: 'transfer' | 'source' }) {
        const selected = this.selectedAndFilteredRows;

        const dry: [HTEPReagentUse, Partial<HTEIDry>][] = [];

        const bestBarcodes = new Map<HTERInstructionIdT, string>();
        const transferSamples = new Map<HTERInstructionIdT, [barcode: string, ratioOffset: number][]>();
        const trasferPrefixes = this.mainModel.design.labware.product.barcode_prefixes;

        const assignedTransferBarcodes = new Set<string>();
        for (const use of this.all) {
            const inv = this.inventory.getDryUse(use);
            if (inv?.transfer_barcode) assignedTransferBarcodes.add(inv.transfer_barcode);
        }

        for (const use of selected) {
            const info = this.getInfo(use);
            if (!info) continue;

            if (info.best_barcode) {
                bestBarcodes.set(use.instruction_id, info.best_barcode);
            }

            const invSearch = info.searchResults;
            if (!invSearch?.length || !trasferPrefixes?.length) continue;

            const req = getDryUseAmount(this.mainModel, use);
            if (!req) continue;

            for (const entry of invSearch) {
                if (assignedTransferBarcodes.has(entry.barcode)) continue;

                let hasPrefix = false;
                for (const barcodePrefix of trasferPrefixes) {
                    if (!entry.barcode.startsWith(barcodePrefix)) continue;
                    hasPrefix = true;
                    break;
                }

                if (!hasPrefix) continue;
                if (entry.sample?.solvent_volume || !entry.sample?.solute_mass) continue;

                const sampleAmount = entry.sample.solute_mass!;
                const ratio = sampleAmount / req;
                if (ratio < 0.5 * req || ratio > 2.0) continue;
                if (!transferSamples.has(use.instruction_id)) {
                    transferSamples.set(use.instruction_id, []);
                }
                transferSamples.get(use.instruction_id)!.push([entry.barcode, Math.abs(ratio - 1)]);
            }
        }

        transferSamples.forEach((xs) => {
            xs.sort((a, b) => a[1] - b[1]);
        });

        for (const use of selected) {
            const inv = this.inventory.getDryUse(use);
            if (inv?.transfer_barcode || inv?.source_barcode) continue;

            const xferSample = transferSamples
                .get(use.instruction_id)
                ?.find((x) => !assignedTransferBarcodes.has(x[0]));
            if (options.kind === 'transfer' && xferSample) {
                dry.push([use, { source_barcode: undefined, transfer_barcode: xferSample[0] }]);
                assignedTransferBarcodes.add(xferSample[0]);
                continue;
            }

            if (options.kind === 'source' && bestBarcodes.has(use.instruction_id)) {
                dry.push([use, { source_barcode: bestBarcodes.get(use.instruction_id) }]);
            }
        }

        if (dry.length) {
            this.inventory.update({ dry });
            ToastService.show({
                message: `Auto-assigned ${dry.length} barcode(s)`,
                type: 'info',
                id: 'hte2ms-reagents-auto-assign',
                timeoutMs: 2500,
            });
        } else {
            ToastService.show({
                message: 'Nothing changed',
                type: 'info',
                id: 'hte2ms-reagents-auto-assign',
                timeoutMs: 2500,
            });
        }
    }

    confirmClear = () => {
        DialogService.open({
            type: 'confirm',
            title: 'Clear Inventory',
            confirmText: 'Clear',
            text: 'Are you sure you want to clear Source and Transfer Barcodes?',
            onConfirm: this.clear,
        });
    };

    private clear = () => {
        const selected = this.selectedAndFilteredRows;

        const dry: [HTEPReagentUse, Partial<HTEIDry>][] = [];
        for (const use of selected) {
            const inv = this.inventory.getDryUse(use);
            if (inv?.source_barcode || inv?.transfer_barcode) {
                dry.push([use, { source_barcode: undefined, transfer_barcode: undefined }]);
            }
        }
        this.inventory.update({ dry });

        ToastService.show({
            message: 'Inventory cleared',
            type: 'info',
            id: 'hte2ms-reagents-clear',
            timeoutMs: 2500,
        });
    };

    confirmAutoScale = () => {
        DialogService.open({
            type: 'generic',
            title: 'Auto-scale Reaction Scale',
            confirmButtonContent: 'Apply',
            wrapOk: true,
            defaultState: { low: 25, high: 90 },
            content: AutoAdjustDialogContent,
            onOk: (state: { low: number; high: number }) => this.autoAdjust(state),
        });
    };

    private async autoAdjust(bounds: { low: number; high: number }) {
        const _maxVolume = this.mainModel.design.labware.product.volume ?? 1e-6; // 1 mL in m**3

        const min = (bounds.low * _maxVolume) / 100;
        const max = (bounds.high * _maxVolume) / 100;

        const selected = this.selectedAndFilteredRows;

        const updates: [HTEDReaction, HTEDReaction][] = [];

        for (const use of selected) {
            const ratio = getDryUseRatio(this.mainModel, use);
            const currentVolume = this.reagents.protocol.data.product_samples[use.reaction_id]?.total_volume;
            const reaction = this.mainModel.design.getById(use.reaction_id);
            if (!ratio || !currentVolume || !reaction?.scale) continue;
            if (Math.abs(ratio - 1) < 0.01) continue;

            if (ratio < 1) {
                if (currentVolume < min) continue;
                const newVolume = Math.max(min, ratio * currentVolume);
                const scale = newVolume / currentVolume;
                updates.push([reaction, { ...reaction, scale: scale * reaction.scale! }]);
            } else {
                if (currentVolume > max) continue;
                const newVolume = Math.min(max, ratio * currentVolume);
                const scale = newVolume / currentVolume;
                updates.push([reaction, { ...reaction, scale: scale * reaction.scale! }]);
            }
        }

        if (updates.length === 0) {
            throw new Error('Nothing to adjust');
        }

        await this.mainModel.design.modifyReactions(updates, { doNotRequestBuild: true });
        await this.mainModel.build('protocol');
        // wait a bit in the dialog until the UI updates
        await new Promise((resolve) => {
            setTimeout(resolve, 250);
        });
    }

    exportBarcodes(kind: 'source_barcodes' | 'transfer_barcodes', how: 'copy' | 'save') {
        let selectedRows = this.table.getSelectedAndFilteredRowIndices();
        if (!selectedRows.length) selectedRows = this.table.rows as any;

        let barcodes: string;

        if (kind === 'transfer_barcodes') {
            barcodes = Array.from(
                new Set(
                    selectedRows
                        .map((r) => {
                            const src = this.store.getValue('source_barcode', r);
                            const dest = this.store.getValue('transfer_barcode', r);
                            return !src || src === dest ? dest : undefined;
                        })
                        .filter((r) => r)
                )
            ).join('\n');
        } else {
            barcodes = Array.from(
                new Set(
                    selectedRows
                        .map((r) => {
                            const src = this.store.getValue('source_barcode', r);
                            const dest = this.store.getValue('transfer_barcode', r);
                            return src !== dest ? src : undefined;
                        })
                        .filter((r) => r)
                )
            ).join('\n');
        }

        if (!barcodes) {
            return ToastService.warning('No barcodes to copy');
        }

        if (how === 'copy') {
            ClipboardService.copyText(barcodes, 'Copy Barcodes');
        } else {
            saveAs(
                new Blob([barcodes], { type: 'text/csv' }),
                `${this.reagents.protocol.model.libraryId}-dry-${kind}.csv`
            );
        }
    }

    exportOrderList(how: 'copy' | 'save') {
        let selectedRows = this.table.getSelectedAndFilteredRowIndices();
        if (!selectedRows.length) selectedRows = this.table.rows as any;

        const rows: string[][] = [['Identifier', 'Amount', 'Amount Unit']];

        for (const r of selectedRows) {
            const amount = this.store.getValue('amount_g', r);
            if (!amount) continue;
            const amount_mg = `${roundValue(2, 1e3 * amount)}`;
            const identifier = this.mainModel.assets.entities.getIdentifier(this.store.getValue('identifier', r));
            rows.push([identifier ?? '', amount_mg, 'mg']);
        }

        const csv = arrayToCsv(rows);

        if (how === 'copy') {
            ClipboardService.copyText(csv, 'Copy Order list');
        } else {
            saveAs(new Blob([csv], { type: 'text/csv' }), `${this.reagents.protocol.model.libraryId}-order-list.csv`);
        }
    }

    loadOrderList = () => {
        uploadFileDialog({
            title: 'Load Order List',
            extensions: ['.csv', '.xls', '.xlsx'],
            uploadLabel: 'Upload Order List (.csv, .xls, .xlsx)',
            onUpload: this.applyLoadOrderList,
            content: (
                <p>
                    Populates unassigned <b>Transfer Barcodes</b> from the provided file. The file should have columns{' '}
                    <b>Identifier</b> and <b>Barcode</b>.
                </p>
            ),
        });
    };

    private applyLoadOrderList = async (files: File[]) => {
        const file = files[0];
        if (!file) return;

        const csv = await api.utils.parseTable<{ identifier: string; barcode: string }>(file, {
            lowerCaseColumns: true,
        });
        const rows = csv.toObjects();
        const entities = this.mainModel.assets.entities;
        const groupedBarcodes = new Map(
            groupByPreserveOrder(
                rows,
                (r) => entities.getEntity(r.identifier)?.universal_identifier ?? r.identifier
            ).map((g) => [g[0].identifier, g])
        );
        const used = new Set<string>();

        const dry: [HTEPReagentUse, Partial<HTEIDry>][] = [];

        for (const use of this.all) {
            const inv = this.inventory.getDryUse(use);
            if (inv?.transfer_barcode || inv?.source_barcode) continue;

            const reagent = this.reagents.getByKey(use.reagent_key);
            const barcodes = groupedBarcodes.get(reagent?.identifier!);
            if (!barcodes) continue;

            const barcode = barcodes.find((b) => !used.has(b.barcode))?.barcode;
            if (!barcode) continue;
            used.add(barcode);

            dry.push([use, { source_barcode: undefined, transfer_barcode: barcode }]);
        }

        if (dry.length) {
            this.inventory.update({ dry });
            ToastService.success(`Assigned ${dry.length} barcode(s)`);
        } else {
            ToastService.info('Nothing changed');
        }
    };

    mount() {
        this.subscribe(this.inventory.state.inventory, () => this.table.dataChanged());
    }

    constructor(public reagents: HTE2MSReagentsModel) {
        super();

        const columns = commonDryReagentsColumnSchema(reagents.protocol.model, this.store);
        const designAction = viewDryReagentDesignAction(reagents.protocol.model, this.store);

        this.table = new DataTableModel<DryReagentRow>(this.store, {
            columns: {
                identifier: columns.identifier,
                reactant_kinds: columns.reactant_kinds,
                worklist_title: columns.worklist_title,
                amount_g: columns.amount_g,
                source_barcode: {
                    ...Column.str(),
                    header: 'Source Barcode',
                    render: ({ value, rowIndex }) => {
                        const readOnly = this.mainModel.readOnlyDesignAndProduction;
                        if (readOnly) return renderBarcode(this.mainModel, value);

                        const use = this.store.rawRows[rowIndex];
                        const options = this.getInfo(use)?.inventoryOptions;
                        return (
                            <SelectBarcodeCell
                                model={this.inventory.model}
                                value={value}
                                options={options}
                                setValue={(v) =>
                                    this.inventory.update({ dry: [[use, { source_barcode: v || undefined }]] })
                                }
                            />
                        );
                    },
                    width: 250,
                },
                batch_identifier: columns.batch_identifier,
                transfer_barcode: columns.transfer_barcode,
                ratio: {
                    ...Column.float(),
                    header: 'Amount ÷',
                    align: 'right',
                    render: ({ value }) => {
                        if (value === undefined) return '';
                        return `${roundValue(2, value)}`;
                    },
                    width: 100,
                },
                validation: columns.validation,
                well_utilization: {
                    ...Column.float(),
                    header: 'Well %',
                    align: 'right',
                    render: ({ value }) => {
                        if (value === undefined) return '';
                        return `${roundValue(0, value * 100)}%`;
                    },
                    width: 100,
                },
            },
            actions: [designAction, SelectionColumn({ width: 30 })],
            hideNonSchemaColumns: true,
        });

        this.table.setColumnStickiness(designAction.id, true);
        this.table.setColumnStickiness('selection', true);
        this.table.setColumnStickiness('identifier', true);
    }
}

function AutoAdjustDialogContent({ stateSubject }: { stateSubject: BehaviorSubject<{ low: number; high: number }> }) {
    const current = useBehavior(stateSubject);
    return (
        <div className='vstack gap-2'>
            <InlineAlert>
                This will adjust the reaction scale so the product volume falls between {current.low}% and{' '}
                {current.high}% of the crude well volume (specified in <b>Design→Labware</b>) based on the{' '}
                <i>current</i> ratio of <i>required/transferred</i> amount.
            </InlineAlert>
            <LabeledInput label='Low (%)' labelWidth={120}>
                <TextInput
                    value={current.low}
                    tryUpdateValue={asNumber}
                    setValue={(v) => stateSubject.next({ ...current, low: v })}
                />
            </LabeledInput>
            <LabeledInput label='High (%)' labelWidth={120}>
                <TextInput
                    value={current.high}
                    tryUpdateValue={asNumber}
                    setValue={(v) => stateSubject.next({ ...current, high: v })}
                />
            </LabeledInput>
        </div>
    );
}
