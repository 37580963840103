import React, { ReactNode } from 'react';
import Pane from '../../components/Pane/Pane';
import useBehavior from '../../lib/hooks/useBehavior';
import {
    DataTableGlobalFilter,
    DataTableRowSelectionMode,
    DataTableControl,
    DataTableModel,
} from '../../components/DataTable';
import { DownloadCSVButton, SelectionInfo } from '../../components/DataTable/common';
import { AssayValueDetail } from './assay-api';

interface IProps {
    source: string;
    table: DataTableModel<AssayValueDetail>;
    rowSelectionMode?: DataTableRowSelectionMode;
    downloadCSVAs?: string;
    headerContent?: ReactNode;
}

export default function AssayDetailTable({
    source,
    table,
    rowSelectionMode = 'multi',
    downloadCSVAs,
    headerContent,
}: IProps) {
    useBehavior(table.version);

    const onRowDoubleClick = (rowIndex: number, tbl: DataTableModel<AssayValueDetail>, e: React.MouseEvent) => {
        tbl.setSelection({ [rowIndex]: true });
    };

    const tableHeader = (
        <div className='flex-grow-1 d-flex align-items-center justify-content-between text-secondary ms-2'>
            <div className='d-flex align-items-center flex-grow-1'>
                <SelectionInfo table={table} />
                <div className='mx-2'>
                    <DataTableGlobalFilter table={table} />
                </div>
                {headerContent}
            </div>
            {downloadCSVAs && (
                <DownloadCSVButton table={table} isGOSTAR={source === 'GOSTAR'} filename={downloadCSVAs} />
            )}
        </div>
    );

    return (
        <Pane title='Table' headerInfo={tableHeader} contentPaddingIndex={0}>
            <DataTableControl
                height='flex'
                table={table}
                headerSize='sm'
                rowSelectionMode={rowSelectionMode}
                onRowDoubleClick={onRowDoubleClick}
            />
        </Pane>
    );
}
