import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, OverlayTrigger, Popover } from 'react-bootstrap';

const PotensToggleOptions = [
    {
        label: (
            <>
                pIC<sub>50</sub> / pK<sub>i</sub> / pK<sub>d</sub>
            </>
        ),
        value: 'pIC50/pKi/pKd',
    },
    {
        label: (
            <>
                IC<sub>50</sub> / K<sub>i</sub> / K<sub>d</sub> (nM)
            </>
        ),
        value: 'IC50/Ki/Kd (nM)',
    },
];

export function PotensNMToggle({
    units,
    onToggle,
}: {
    units: { use_potens: boolean };
    onToggle: (value: 'potens' | 'nM') => void;
}) {
    return (
        <>
            <ButtonGroup size='sm'>
                <Button
                    className='py-0 text-body-small'
                    variant={units.use_potens ? 'secondary' : 'outline-secondary'}
                    onClick={() => onToggle('potens')}
                >
                    potens
                </Button>
                <Button
                    className='py-0 text-body-small'
                    variant={!units.use_potens ? 'secondary' : 'outline-secondary'}
                    onClick={() => onToggle('nM')}
                >
                    nM
                </Button>
            </ButtonGroup>
            <OverlayTrigger
                placement='bottom'
                overlay={
                    <Popover>
                        <Popover.Body>
                            {units.use_potens ? PotensToggleOptions[0].label : PotensToggleOptions[1].label}
                        </Popover.Body>
                    </Popover>
                }
            >
                <FontAwesomeIcon
                    icon={faInfoCircle}
                    className='cursor-pointer text-secondary ps-1 me-2'
                    size='xs'
                    fixedWidth
                />
            </OverlayTrigger>
        </>
    );
}
