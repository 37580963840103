import { BehaviorSubject } from 'rxjs';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { HTEPMosquitoProtocol } from '../data-model';
import { HTEDesignModel } from '../design';
import { type HTEWModel } from '../model';
import { HTEPPlatesModel } from './plates';

export type HTEProtocolTab = 'settings' | 'product-blocks' | 'reactions' | 'procedure';

export class HTEMosquitoProtocolModel extends ReactiveModel {
    state = {
        tab: new BehaviorSubject<HTEProtocolTab>('reactions'),
        plate: new BehaviorSubject<HTEPPlatesModel | undefined>(undefined),
        procedure: new BehaviorSubject<string>(''),
    };

    protocol: HTEPMosquitoProtocol;
    design: HTEDesignModel;
    plates: HTEPPlatesModel[];

    get protocolData(): HTEPMosquitoProtocol {
        return {
            ...this.protocol,
            procedure: this.state.procedure.value,
        };
    }

    constructor(
        public model: HTEWModel,
        { protocol, customPlateColoring }: { protocol: HTEPMosquitoProtocol; customPlateColoring?: boolean }
    ) {
        super();

        this.protocol = protocol;
        this.design = new HTEDesignModel(model, { design: protocol.design, isSnapshot: true });
        this.plates = protocol.product_plates.map(
            (p) =>
                new HTEPPlatesModel(
                    this,
                    { design: protocol.design, protocol: p },
                    { customColoring: customPlateColoring }
                )
        );

        this.state.plate.next(this.plates[0]);
        this.state.procedure.next(protocol.procedure);
    }
}
