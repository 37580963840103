export interface WorkflowComputationProgressEntry {
    desc: string;
    n: number;
    total: number;
    start_t: number;
    display_t: number;
}

export type WorkflowComputationStatus = 'created' | 'scheduled' | 'running' | 'success' | 'error' | 'cancelled';

export interface WorkflowComputation {
    id: string;
    namespace: string;
    created_on: string;
    status: WorkflowComputationStatus;
    name: string;
    tag?: string;
    metadata?: Record<string, any>;
    error_message?: string;
    progress: WorkflowComputationProgressEntry[];

    start_t: number;
    end_t: number;
    update_t: number;
}

export function computationDurationString(computation: WorkflowComputation) {
    // TODO (emma) what units are end_t & start_t in?
    const roundedTime = Math.round(computation.end_t - computation.start_t);
    return `${roundedTime} minute${roundedTime === 1 ? '' : 's'}`;
}
