import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';
import { Button } from 'react-bootstrap';
import { Column, Row } from 'react-table';
import { BehaviorSubject, distinctUntilKeyChanged, skip } from 'rxjs';
import { AsyncMoleculeDrawing } from '../../../components/common/AsyncMoleculeDrawing';
import { TextInput } from '../../../components/common/Inputs';
import ReactTableSchema from '../../../components/ReactTable/schema';
import { ToastService } from '../../../lib/services/toast';
import { createPalleteRGB, darkenColor, toRGBString } from '../../../lib/util/colors';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { roundValue } from '../../../lib/util/roundValues';
import {
    formatHTEId,
    formatUnitPrefix,
    getReactantLayerType,
    isHTESolvent,
    Plate,
    Reactant,
    Reaction,
    WellLayerType,
    WellSelection,
} from '../experiment-data';
import { productPlateToAnalyticalCSV } from '../plate/export-analytical';
import { productPlateToECMCSV } from '../plate/export-ecm';
import { PlateVisualModel } from '../plate/PlateVisual';
import {
    fillWells,
    findWellLayer,
    forEachWell,
    pastePlateSelection,
    PlateCopy,
    selectionSingleton,
    updateWells,
} from '../plate/utils';
import { ReactantTypeBadge } from './common';
import { ReactantFilter } from './reactants-model';
import { CompoundIdentifier } from './reagents-model';
import { getCurrentTheme } from '../../../components/theme';

export interface ProductPlateStats {
    numberOfUses: Map<string, number>;
    wellVolumes: number[];
    wellVolumeRange: [number, number];
    reactionCounts: Map<Reaction, number>;
    numNonEmpty: number;
    numDisabled: number;
    numOverflowing: number;
    maxWellCount: number;
}

const theme = getCurrentTheme();
const WellColoring = {
    NoColor: theme === 'dark' ? '#121212' : '#f9f9f9',
    DisabledColor: '#b4b7b9',
    NonEmptyColor: '#2f2f2f',
    OverflowColor: '#dc3545',
    HasBBColor: '#032830',
};

export class ProductPlateModel extends ReactiveModel {
    private reactants: import('./reactants-model').ReactantsModel;
    private isMounted = false;

    state = {
        reactantsFilter: new BehaviorSubject<ReactantFilter>({ kind: 'all' }),
        currentReactants: new BehaviorSubject<Reactant[]>([]),
        stats: new BehaviorSubject<ProductPlateStats>({
            numberOfUses: new Map(),
            reactionCounts: new Map(),
            numDisabled: 0,
            numNonEmpty: 0,
            numOverflowing: 0,
            wellVolumes: [],
            wellVolumeRange: [0, 0],
            maxWellCount: 0,
        }),
        showOverflowWells: new BehaviorSubject<boolean>(false),
        copy: new BehaviorSubject<PlateCopy | undefined>(undefined),
    };

    visual = new PlateVisualModel(this.experiment.design.plate.layout, { clearCopy: () => this.clearCopy() });

    getNumberOfUses(identifier: string) {
        return this.state.stats.value.numberOfUses.get(identifier) ?? 0;
    }

    getReactionOccurenceCount(reaction: Reaction) {
        return this.state.stats.value.reactionCounts.get(reaction) ?? 0;
    }

    readonly columns: Column<Reactant>[] = [
        {
            Header: '',
            id: 'action',
            accessor: 'identifier',
            width: 40,
            disableSortBy: true,
            disableGlobalFilter: true,
            Cell: ({ row }) => (
                <Button
                    variant='outline-primary'
                    size='sm'
                    onClick={() => this.addReactant(row.original)}
                    disabled={this.experiment.stateInfo.status !== 'Planning'}
                >
                    <FontAwesomeIcon icon={faArrowLeft} size='sm' />
                </Button>
            ),
        },
        {
            Header: 'Structure',
            id: 'structure',
            accessor: (row) => this.experiment.batches.getSmiles(row.identifier),
            width: 120,
            Cell: ({ value }: { value?: string }) => (
                <>{value && <AsyncMoleculeDrawing smiles={value} height={59} drawer={this.experiment.drawer} />}</>
            ),
        },
        {
            Header: 'Identifier',
            accessor: 'identifier',
            Cell: ({ value, row }: { value: string; row: Row<Reactant> }) => {
                const idColor =
                    row.original.type === 'bb'
                        ? this.reactants.state.groups.value.byGroup
                              .get(row.original.group_name ?? '')
                              ?.colorMap.get(row.original.identifier)?.rgb
                        : this.reactants.state.groups.value.byType
                              .get(getReactantLayerType(row.original))
                              ?.colorMap.get(row.original.identifier)?.rgb;
                return <CompoundIdentifier value={value} idColor={idColor} />;
            },
            width: 160,
        },
        {
            Header: 'Equiv.',
            id: 'equiv',
            accessor: 'equivalence',
            width: 80,
            Cell: ({ value }) => value.toString() as any,
        },
        {
            Header: '# Uses',
            id: 'number_of_uses',
            accessor: (row) => this.getNumberOfUses(row.identifier),
            width: 90,
            Cell: ({ value }: { value: number }) => value as any,
        },
        {
            Header: 'Type',
            accessor: 'type',
            width: 100,
            Cell: ({ value, row }) => {
                if (row.original.type === 'reagent') {
                    return <ReactantTypeBadge type={row.original.reagent_type ?? 'reagent'} />;
                }
                if (row.original.type === 'bb') {
                    const groupName = row.original.group_name!;
                    const color = this.reactants.bbGroupColorMap.get(groupName);
                    return (
                        <TextInput
                            value={groupName}
                            className='hte-experiment-badge-like hte-experiment-badge-like-input'
                            readOnly
                            style={{
                                background: color?.rgb,
                                border: color ? `1px solid ${toRGBString(darkenColor(color.raw, 0.9))}` : undefined,
                            }}
                        />
                    );
                }
                return <ReactantTypeBadge type={value} />;
            },
        },
        {
            Header: 'BB Order',
            id: 'bb_order',
            accessor: (row) => row.bb_order,
            width: 110,
            Cell: ({ value }: { value: number }) => (Number.isNaN(value) ? '' : (value as any)),
            sortType: (a, b) => ReactTableSchema.DefaultCompareWithBlanks(a.original.bb_order, b.original.bb_order),
        },
    ];

    mount() {
        this.isMounted = true;
        this.syncPlateColors();
    }

    unmount() {
        this.isMounted = false;
    }

    clearCopy() {
        this.state.copy.next(undefined);
    }

    copy() {
        this.state.copy.next({
            selection: this.visual.state.selection.value,
            plate: this.experiment.design.plate,
        });
        this.visual.clearSelection();
    }

    paste() {
        const copy = this.state.copy.value;
        if (!copy) return;
        const filter = this.state.reactantsFilter.value;
        const result = pastePlateSelection(
            copy,
            this.experiment.design.plate,
            this.visual.state.selection.value,
            this.reactants.map,
            {
                layer: !filter.kind || filter.kind === 'all' ? undefined : (filter.kind.toLowerCase() as WellLayerType),
            }
        );
        if (result?.plate) {
            this.experiment.setPlate(result.plate);
            this.visual.state.selection.next(result.selection);
        }
    }

    highlightCompound(id?: string) {
        if (typeof id !== 'string') {
            this.visual.state.highlight.next([]);
            return;
        }

        const toHighlight = [] as number[];

        const hasCompound = (c: string) => c === id;
        const { wells, layout } = this.experiment.design.plate;
        for (let i = 0; i < layout; i++) {
            if (wells[i]?.some(hasCompound)) {
                toHighlight.push(i);
            }
        }

        this.visual.state.highlight.next(toHighlight);
    }

    private addReactant(reactant: Reactant) {
        const newPlate = updateWells(this.experiment.design.plate, this.visual.state.selection.value, 'add', {
            reactant,
            reactants: this.reactants.map,
        });
        if (newPlate) this.experiment.setPlate(newPlate);
    }

    disableWells(disable = true) {
        const newPlate = updateWells(
            this.experiment.design.plate,
            this.visual.state.selection.value,
            disable ? 'disable' : 'enable'
        );
        if (newPlate) this.experiment.setPlate(newPlate);
    }

    fillWells(direction: 'row' | 'col') {
        const newPlate = fillWells(
            this.experiment.design.plate,
            this.visual.state.selection.value,
            this.state.currentReactants.value,
            direction,
            this.reactants.map
        );
        if (newPlate) this.experiment.setPlate(newPlate);
    }

    clearLayer(layer: WellLayerType) {
        const newPlate = updateWells(this.experiment.design.plate, this.visual.state.selection.value, 'clear-layer', {
            layer,
            reactants: this.reactants.map,
        });
        if (newPlate) this.experiment.setPlate(newPlate);
    }

    clearWells() {
        const filter = this.state.reactantsFilter.value;
        let newPlate: Plate | undefined;
        if (filter.kind === 'all') {
            newPlate = updateWells(this.experiment.design.plate, this.visual.state.selection.value, 'clear');
        } else {
            newPlate = updateWells(this.experiment.design.plate, this.visual.state.selection.value, 'clear-layer', {
                layer: (filter.kind ?? 'all').toLowerCase() as WellLayerType,
                reactants: this.reactants.map,
            });
        }
        if (newPlate) this.experiment.setPlate(newPlate);
        this.state.copy.next(undefined);
    }

    saveECMCSV() {
        try {
            const csv = productPlateToECMCSV(this.experiment);
            saveAs(new Blob([csv], { type: 'text/csv' }), `${formatHTEId(this.experiment.id)}-product-plate.csv`);
        } catch (err) {
            reportErrorAsToast('Export ECM', err);
        }
    }

    async saveAnalyticalCSV() {
        try {
            const csv = await productPlateToAnalyticalCSV(this.experiment);
            saveAs(new Blob([csv], { type: 'text/csv' }), `${formatHTEId(this.experiment.id)}-analytical.csv`);
            const isFinalized = !!this.experiment.state.info.value.finalization;
            if (!isFinalized) {
                ToastService.show({
                    type: 'info',
                    message:
                        'Exported CSV does not contain Product Batch Identifiers. Finalize the experiment to make these available.',
                    timeoutMs: 15000,
                });
            }
        } catch (err) {
            reportErrorAsToast('Export Analytical', err);
        }
    }

    private syncStats() {
        const numberOfUses = new Map<string, number>();
        const reactionCounts = new Map<Reaction, number>();
        let numNonEmpty = 0;
        let numDisabled = 0;
        let numOverflowing = 0;

        const {
            plate: { layout, wells, well_volume_range },
        } = this.experiment.design;

        const { reactants } = this;
        const wellVolumes = new Array<number>(layout);

        let minVolume = Infinity;
        let maxVolume = 0;

        let maxWellCount = 0;

        for (let wI = 0; wI < layout; wI++) {
            const well = wells[wI];
            wellVolumes[wI] = 0;

            if (!well) {
                numDisabled++;
                continue;
            }

            maxWellCount = Math.max(well.length, maxWellCount);

            const bb = findWellLayer(well, 'bb', reactants.map);
            const msd = findWellLayer(well, 'msd', reactants.map);

            if (bb && msd) {
                const reaction = this.experiment.reactions.findReaction(msd.identifier, bb.identifier, wI);
                if (reaction) {
                    const count = reactionCounts.get(reaction) ?? 0;
                    reactionCounts.set(reaction, count + 1);
                }
            }

            let volume = 0;
            let nonEmpty = false;
            for (const identifier of well) {
                nonEmpty = true;
                if (numberOfUses.has(identifier)) {
                    numberOfUses.set(identifier, numberOfUses.get(identifier)! + 1);
                } else {
                    numberOfUses.set(identifier, 1);
                }

                volume += reactants.calcRxnVolume(reactants.getReactant(identifier)) ?? 0;
            }
            wellVolumes[wI] = volume;

            // 1% tolerance to avoid rounding errors
            if (volume > 1.01 * well_volume_range[1]) {
                numOverflowing++;
            }

            if (nonEmpty) {
                minVolume = Math.min(volume, minVolume);
                maxVolume = Math.max(volume, maxVolume);
            }

            if (nonEmpty) numNonEmpty++;
        }

        if (!Number.isFinite(minVolume)) minVolume = 0;

        this.state.stats.next({
            numberOfUses,
            reactionCounts,
            numNonEmpty,
            numDisabled,
            numOverflowing,
            wellVolumes,
            wellVolumeRange: [minVolume, maxVolume],
            maxWellCount,
        });
    }

    private colorOverflowWells() {
        const {
            plate: { layout, wells, well_volume_range },
        } = this.experiment.design;

        const { wellVolumes: wellVolumesInUL } = this.state.stats.value;
        const colors = new Array<string>(layout);

        for (let i = 0; i < layout; i++) {
            // 1% tolerance to avoid rounding errors
            if (wellVolumesInUL[i] > 1.01 * well_volume_range[1]) {
                colors[i] = WellColoring.OverflowColor;
            } else if (!wells[i]) {
                colors[i] = WellColoring.DisabledColor;
            } else {
                colors[i] = wells[i]!.some((e) => e) ? WellColoring.NonEmptyColor : WellColoring.NoColor;
            }
        }

        this.visual.state.colors.next(colors);
    }

    private syncPlateColors() {
        if (!this.isMounted) return;

        if (this.state.showOverflowWells.value && this.state.stats.value.numOverflowing > 0) {
            this.colorOverflowWells();
            return;
        }

        const { NoColor, DisabledColor, NonEmptyColor, HasBBColor } = WellColoring;

        const { reactants } = this;
        const groupsInfo = reactants.state.groups.value;
        const reactantMap = reactants.map;

        const {
            plate: { layout, wells },
        } = this.experiment.design;
        const { kind, groupName } = this.state.reactantsFilter.value;

        const fallbackColor = (i: number) => (wells[i]?.some((e) => e) ? NonEmptyColor : NoColor);
        const fallbackColorHasBB = (i: number) =>
            findWellLayer(wells[i], 'bb', reactantMap) ? HasBBColor : wells[i]?.length ? NonEmptyColor : NoColor;

        const colors = new Array<string>(layout);
        if (!kind || kind === 'all') {
            const palette = createPalleteRGB(
                [0xef, 0xad, 0xce],
                [0x9e, 0xc5, 0xfe],
                Math.max(5, this.state.stats.value.maxWellCount)
            ).map((c) => toRGBString(c));
            for (let i = 0; i < layout; i++) {
                if (!wells[i]) {
                    colors[i] = DisabledColor;
                } else {
                    const idx = wells[i]!.reduce((a, b) => a + (b ? 1 : 0), 0) ?? 0;
                    colors[i] = idx > 0 ? palette[idx - 1] : NoColor;
                }
            }
        } else if (kind === 'BB' && !groupName) {
            for (let i = 0; i < layout; i++) {
                if (!wells[i]) {
                    colors[i] = DisabledColor;
                } else {
                    const bb = findWellLayer(wells[i], 'bb', reactantMap);
                    if (!bb) {
                        colors[i] = fallbackColor(i);
                    } else {
                        colors[i] = groupsInfo.bbGroups.colorMap.get(bb.group_name ?? '')?.rgb ?? fallbackColor(i);
                    }
                }
            }
        } else {
            const k = kind.toLowerCase() as WellLayerType;
            const group = k === 'bb' && groupName ? groupsInfo.byGroup.get(groupName) : groupsInfo.byType.get(k);
            const fallback = k === 'bb' && groupName ? fallbackColorHasBB : fallbackColor;
            for (let i = 0; i < layout; i++) {
                if (!wells[i]) {
                    colors[i] = DisabledColor;
                } else {
                    const r = findWellLayer(wells[i], k, reactantMap);
                    if (!r) {
                        colors[i] = fallback(i);
                    } else {
                        colors[i] = group?.colorMap.get(r.identifier)?.rgb ?? fallback(i);
                    }
                }
            }
        }
        this.visual.state.colors.next(colors);
    }

    constructor(public experiment: import('../experiment-model').HTEExperimentModel) {
        super();

        this.reactants = experiment.reactants;

        this.subscribe(this.state.reactantsFilter.pipe(skip(1)), () => this.syncPlateColors());
        this.subscribe(this.experiment.state.design, () => {
            this.syncStats();
            this.syncPlateColors();
        });
        this.subscribe(this.experiment.state.settings.pipe(distinctUntilKeyChanged('reaction_scale')), () => {
            this.syncStats();
            this.syncPlateColors();
        });
        this.subscribe(this.state.showOverflowWells.pipe(skip(1)), () => this.syncPlateColors());
        this.subscribe(this.state.copy, (copy) => this.visual.state.copy.next(copy?.selection));
    }
}

export interface PlateSelectionSummary {
    reactantsByType: Map<WellLayerType, Reactant[]>;
    productId?: string;
    reactionSMILES?: string;
    reactantSMILES?: string;
    volumeLabel?: string;
    singleton: number;
}

export function summarizePlateSelection(
    model: import('../experiment-model').HTEExperimentModel,
    selection: WellSelection
): PlateSelectionSummary {
    const reactantsByType: PlateSelectionSummary['reactantsByType'] = new Map();
    const added = new Map<WellLayerType, Set<string>>();
    const { plate } = model.design;

    forEachWell(plate, selection, (well) => {
        if (!well) return;

        for (const cid of well) {
            if (cid) {
                const r = model.reactants.getReactant(cid);
                const type = getReactantLayerType(r);
                if (added.get(type)?.has(cid)) {
                    continue; // eslint-disable-line
                }

                if (reactantsByType.has(type)) {
                    reactantsByType.get(type)!.push(r);
                } else {
                    reactantsByType.set(type, [r]);
                }

                if (!added.has(type)) added.set(type, new Set([cid]));
                else added.get(type)!.add(cid);
            }
        }
    });

    let reactionSMILES: string | undefined;
    let reactantSMILES: string | undefined;
    let volumeLabel: string | undefined;
    let productId: string | undefined;
    const singletonSelection = selectionSingleton(selection);
    if (singletonSelection >= 0) {
        const well = plate.wells[singletonSelection];
        if (well) {
            const msd = findWellLayer(well, 'msd', model.reactants.map);
            const bb = findWellLayer(well, 'bb', model.reactants.map);
            const reaction =
                msd && bb ? model.reactions.findReaction(msd.identifier, bb.identifier, singletonSelection) : undefined;

            const reagents = [] as string[];
            for (const identifier of well) {
                const r = model.reactants.getReactant(identifier);
                const layer = getReactantLayerType(r);
                if (!isHTESolvent(r) && layer !== 'msd' && layer !== 'bb') {
                    reagents.push(r.identifier);
                }
            }

            if (reaction) {
                reactionSMILES = model.reactions.getReactionSMILES(reaction, reagents);
                productId = reaction.product_identifier;
            } else if (well.length === 1 && !isHTESolvent(well[0])) {
                const reactant = model.reactants.getReactant(well[0]);
                reactantSMILES = model.batches.getSmiles(reactant.identifier)!;
            }
        }

        const stats = model.productPlate.state.stats.value;
        volumeLabel = formatWellVolume(model, plate, stats, singletonSelection);
    }

    return { reactantsByType, productId, reactionSMILES, reactantSMILES, volumeLabel, singleton: singletonSelection };
}

export function formatWellVolume(
    model: import('../experiment-model').HTEExperimentModel,
    plate: Plate,
    stats: ProductPlateStats,
    index: number
) {
    let volume = stats.wellVolumes[index] / model.reactants.scaling.volume;
    const limit = plate.well_volume_range[1] / model.reactants.scaling.volume;
    // 1% tolerance to avoid rounding errors
    if (volume <= 1.01 * limit) volume = Math.min(volume, limit);

    return `${roundValue(1, volume)}/${roundValue(1, limit)} ${formatUnitPrefix(model.reactants.scaling.volume)}L`;
}
