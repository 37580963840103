import { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { LabeledInput } from '../../../components/common/Inputs';
import { LogModel } from '../../../components/common/Log';
import { PropertyNameValue } from '../../../components/common/PropertyNameValue';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import { ToastService } from '../../../lib/services/toast';
import { reportErrorAsToast, tryGetErrorMessage } from '../../../lib/util/errors';
import { roundValue } from '../../../lib/util/roundValues';
import { Vial } from '../ecm-api';
import { ECMCommonUploadInfo, ECMPageTemplate, ECMWorkflowMode, ECMWorkflowModeSelect } from '../ecm-common';
import {
    ecmAPITemplate,
    ECMBatchWorkflowBase,
    ECMBatchWorkflowWrapper,
    ecmGatherMessages,
    ECMManualWorkflowBase,
    ECMManualWorkflowInputHelper,
    ECMManualWorkflowStatus,
    ECMManualWorkflowWrapper,
    ecmWorkflowEntrySummary,
    uploadECMCSV,
} from './common';

interface PrepareWeighInput {
    row_index?: number;
    barcode?: string;
    current_mass?: number;
    current_mass_unit?: string;
}

interface PrepareWeighEntry {
    input: PrepareWeighInput;

    errors: string[];
    warnings: string[];

    vial?: Vial;
    barcode?: string;
    current_mass?: number;
}

type PrepareWeighEntries = PrepareWeighEntry[];

const WeighAPI = ecmAPITemplate<PrepareWeighInput[], PrepareWeighEntries>('vials/weigh');

interface ManualVialWeighWorkflowInput {
    barcode: string;
    current_mass: string;
}

class ManualVialWeighWorkflow implements ECMManualWorkflowBase<PrepareWeighEntry> {
    inputs = {
        barcode: { current: null } as MutableRefObject<HTMLInputElement | null>,
        current_mass: { current: null } as MutableRefObject<HTMLInputElement | null>,
    };

    state = {
        input: new BehaviorSubject<ManualVialWeighWorkflowInput>({ barcode: '', current_mass: '' }),
        entry: new BehaviorSubject<PrepareWeighEntry | undefined>(undefined),
        autosubmit: new BehaviorSubject<boolean>(false),
        preparing: new BehaviorSubject<boolean>(false),
        submitting: new BehaviorSubject<boolean>(false),
    };

    log = new LogModel();

    get autosubmit() {
        return this.state.autosubmit.value;
    }

    get isInputValid(): boolean {
        // TODO: this could be improved
        const input = this.state.input.value;
        return input.barcode.length > 0 && input.current_mass.length > 0;
    }

    async prepare() {
        ToastService.remove('warn-autosubmit');

        if (!this.isInputValid) {
            return;
        }

        try {
            this.state.preparing.next(true);
            const input = this.state.input.value;
            const entries = await WeighAPI.prepare([
                {
                    barcode: input.barcode,
                    current_mass: +input.current_mass,
                    current_mass_unit: 'mg',
                },
            ]);

            const entry = entries[0];
            this.state.entry.next(entry);
            if (!entry) return;

            if (!entry.barcode) {
                requestAnimationFrame(() => this.inputs.barcode.current?.focus());
                return;
            }
            if (typeof entry.current_mass !== 'number') {
                requestAnimationFrame(() => this.inputs.current_mass.current?.focus());
                return;
            }

            if (this.autosubmit && entry.errors.length === 0 && entry.warnings.length === 0) {
                this.submit();
                return;
            }

            if (this.autosubmit && entry.errors.length === 0 && entry.warnings.length > 0) {
                ToastService.show({
                    id: 'warn-autosubmit',
                    type: 'warning',
                    message: 'Auto-submit paused due to a warning.\nPlease fix the issue or submit manually.',
                });
            }
        } catch (err) {
            reportErrorAsToast('Receive/Weigh', err);
            this.state.entry.next(undefined);
        } finally {
            this.state.preparing.next(false);
        }
    }

    async submit() {
        const entry = this.state.entry.value;
        if (!entry) return;

        try {
            this.state.submitting.next(true);

            await WeighAPI.upload([entry]);
            // NOTE: For testing to fake upload
            // await new Promise((res) => {
            //     setTimeout(res, 500);
            // });

            this.log.message(
                'success',
                `Vial ${entry.barcode} weighed to ${roundValue(
                    0,
                    entry.current_mass! * 1000
                )} mg (sample amount ${roundValue(2, (entry.current_mass! - (entry.vial?.tare_mass ?? 0)) * 1000)} mg).`
            );
            this.state.input.next({ barcode: '', current_mass: '' });
            this.state.entry.next(undefined);
            requestAnimationFrame(() => this.inputs.barcode.current?.focus());
        } catch (err) {
            this.log.message('danger', `Vial ${entry?.barcode} error: ${tryGetErrorMessage(err)}`);
        } finally {
            this.state.submitting.next(false);
        }
    }
}

class BatchVialWeighWorkflow implements ECMBatchWorkflowBase<PrepareWeighEntries> {
    state = {
        submitting: new BehaviorSubject<boolean>(false),
    };
    entries: PrepareWeighEntries;
    summary: ECMBatchWorkflowBase['summary'];
    messages: ECMBatchWorkflowBase['messages'];

    async submit() {
        if (this.summary.numErrors > 0 || !this.entries.length) {
            return;
        }

        try {
            this.state.submitting.next(true);

            await WeighAPI.upload(this.entries);
            // NOTE: For testing to fake upload
            // await new Promise((res) => {
            //     setTimeout(res, 500);
            // });

            this.fileSubject.next(null);
            ToastService.show({
                type: 'success',
                message: `Successfully updated ${this.entries.length} vial${
                    this.entries.length === 1 ? '' : 's'
                } in Foundry.`,
            });
        } catch (err) {
            reportErrorAsToast('Receive/Weigh', err);
        } finally {
            this.state.submitting.next(false);
        }
    }

    constructor(
        public files: [filename: string, entries: PrepareWeighEntries][],
        private fileSubject: BehaviorSubject<File[] | null>
    ) {
        this.entries = files.flatMap((f) => f[1]);
        this.summary = ecmWorkflowEntrySummary(this.entries);
        this.messages = ecmGatherMessages(files);
    }
}

export function ECMWeigh() {
    const [mode, setMode] = useState<ECMWorkflowMode>('manual');

    return (
        <ECMPageTemplate page='weigh' withFooter>
            <ECMWorkflowModeSelect mode={mode} setMode={setMode} />
            {mode === 'manual' && <ManualRoot />}
            {mode === 'batch' && <BatchRoot />}
        </ECMPageTemplate>
    );
}

const ManualModel = new ManualVialWeighWorkflow();

function ManualRoot() {
    const model = ManualModel;

    return (
        <ECMManualWorkflowWrapper
            model={model}
            input={<ManualInput model={model} />}
            status={<ManualStatus model={model} />}
        />
    );
}

function ManualInput({ model }: { model: ManualVialWeighWorkflow }) {
    const preparing = useBehavior(model.state.preparing);
    const submitting = useBehavior(model.state.submitting);
    const disabled = preparing || submitting;

    const input = useBehavior(model.state.input);

    return (
        <ECMManualWorkflowInputHelper model={model} disabled={disabled} preparing={preparing}>
            <LabeledInput label='Barcode' className='ecm-manual-inputs-row'>
                <Form.Control
                    value={input.barcode}
                    ref={model.inputs.barcode}
                    onChange={(e) => model.state.input.next({ ...input, barcode: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.barcode) model.inputs.current_mass.current?.focus();
                    }}
                    disabled={disabled}
                    autoFocus
                />
            </LabeledInput>
            <LabeledInput label='Current Mass (mg)' className='ecm-manual-inputs-row'>
                <Form.Control
                    value={input.current_mass}
                    ref={model.inputs.current_mass}
                    onChange={(e) => model.state.input.next({ ...input, current_mass: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.current_mass) model.prepare();
                    }}
                    disabled={disabled}
                />
            </LabeledInput>
        </ECMManualWorkflowInputHelper>
    );
}

function ManualStatus({ model }: { model: ManualVialWeighWorkflow }) {
    return (
        <ECMManualWorkflowStatus model={model}>
            {(entry) => {
                const mass = entry.current_mass
                    ? `${roundValue(1, entry.current_mass * 1000)} mg`
                    : entry.input.current_mass;
                const tareMass = entry.vial ? `${roundValue(0, entry.vial.tare_mass * 1000)} mg` : 'n/a';
                const sampleAmount =
                    entry.current_mass && entry.vial
                        ? `${roundValue(2, (entry.current_mass - entry.vial.tare_mass) * 1000)} mg`
                        : 'n/a';
                return (
                    <>
                        <PropertyNameValue field='Barcode' value={entry.barcode ?? entry.input.barcode} />
                        <PropertyNameValue field='Vial Status' value={entry.vial?.status ?? 'n/a'} />
                        <PropertyNameValue field='Current Mass' value={mass} />
                        <PropertyNameValue field='Tare Mass' value={tareMass} />
                        <PropertyNameValue field='Sample Amount' value={sampleAmount} />
                        <PropertyNameValue
                            field='Event Kind'
                            title='Based on current status (Transit => Receive, other => Reweigh)'
                            value={entry.vial?.status === 'Transit' ? 'Receive' : 'Reweigh'}
                        />
                    </>
                );
            }}
        </ECMManualWorkflowStatus>
    );
}

async function uploadCSV(files: File[] | null, fileSubject: BehaviorSubject<File[] | null>) {
    return uploadECMCSV(WeighAPI, BatchVialWeighWorkflow, files, fileSubject);
}

function BatchRoot() {
    const [model, loadModel] = useAsyncAction<BatchVialWeighWorkflow | undefined>();

    const upload = useCallback(
        (files: File[] | null, fileSubject: BehaviorSubject<File[] | null>) => loadModel(uploadCSV(files, fileSubject)),
        [loadModel]
    );
    const info = useMemo(() => <BatchUploadInfo />, []);

    return <ECMBatchWorkflowWrapper model={model} upload={upload} info={info} />;
}

function BatchUploadInfo() {
    return <ECMCommonUploadInfo required={['Barcode', 'Current Mass']} optional={['Current Mass Unit (=mg)']} />;
}
