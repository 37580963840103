import {
    faAt,
    faAtom,
    faCaretDown,
    faCaretRight,
    faCube,
    faDroplet,
    faFileExport,
    faFolderOpen,
    faObjectGroup,
    faPencil,
    faScaleUnbalanced,
    faTriangleExclamation,
    faVials,
    faWandSparkles,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { DataTableControl, DataTableGlobalFilter, DataTableModel } from '../../../components/DataTable';
import { AsyncMoleculeDrawing } from '../../../components/common/AsyncMoleculeDrawing';
import { IconButton, IconDropdownButton } from '../../../components/common/IconButton';
import { ScrollBox } from '../../../components/common/ScrollBox';
import useBehavior from '../../../lib/hooks/useBehavior';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { EcosystemService } from '../../../lib/services/ecosystem';
import { PlateVisual } from '../../HTE/plate/PlateVisual';
import { getFirstSelectedIndex } from '../../HTE/plate/utils';
import { HTEDReaction, HTEPInstructionT, HTEPWorklist, HTEPWorklistGroup } from '../data-model';
import { HTE2MSModel } from '../model';
import { Formatters, HTEPanel, InlineInstructionBadge, ProductSampleUI, RefreshInventoryButton, Unset } from '../utils';
import { HTE2MSDryReagentsModel } from './dry-reagents';
import { HTE2MSLiquidReagentsModel } from './liquid-reagents';
import { HTE2MSProtocolModel, ProtocolMessageGroup } from './model';
import { HTE2MSSolutionReagentsModel } from './solution-reagents';
import { getSolutionUseVolumeL } from '../utils/inventory';
import { SimpleSelectOptionInput } from '../../../components/common/Inputs';

export function HTE2MSProtocolUI({ model }: { model: HTE2MSModel }) {
    const { protocol } = model;
    useMountedModel(protocol);

    return (
        <div className='d-flex w-100 h-100'>
            <div className='border-end flex-shrink-0 position-relative' style={{ minWidth: 320, maxWidth: 320 }}>
                <div className='d-flex w-100 h-100 flex-column'>
                    <div className='position-relative' style={{ flex: 2 }}>
                        <Worklists model={protocol} />
                    </div>
                    <div className='position-relative border-top' style={{ flex: 1, minHeight: 220 }}>
                        <WarningList model={protocol} />
                    </div>
                </div>
            </div>
            <div className='flex-grow-1 position-relative' style={{ minWidth: 0 }}>
                <Platemap model={protocol} />
            </div>
            <div style={{ minWidth: 320, maxWidth: 320 }} className='position-relative flex-shrink-0 border-start'>
                <WellContents model={protocol} />
            </div>
        </div>
    );
}

export function HTE2MSReagentsUI({ model }: { model: HTE2MSProtocolModel }) {
    useMountedModel(model.reagents);
    const kind = useBehavior(model.reagents.state.kind);

    if (kind === 'dry') return <DryReagentsUI model={model.reagents.dry} />;
    if (kind === 'liquid') return <LiquidReagentsUI model={model.reagents.liquid} />;
    return <SolutionReagentsUI model={model.reagents.solution} />;
}

function LiquidReagentsUI({ model }: { model: HTE2MSLiquidReagentsModel }) {
    useMountedModel(model);

    return (
        <div className='d-flex flex-column h-100'>
            <div className='hstack gap-2 p-2'>
                <IconButton
                    onClick={() => model.reagents.state.kind.next('dry')}
                    icon={faCube}
                    size='sm'
                    variant='outline-primary'
                >
                    Dry
                </IconButton>
                <IconButton onClick={() => {}} icon={faDroplet} size='sm' variant='primary'>
                    Liquid
                </IconButton>
                <IconButton
                    onClick={() => model.reagents.state.kind.next('solution')}
                    icon={faVials}
                    size='sm'
                    variant='outline-primary'
                >
                    Solutions
                </IconButton>
                <div className='flex-grow-1'>
                    <DataTableGlobalFilter table={model.table} size='sm' />
                </div>
                <div className='flex-grow-1' />
                <RefreshInventoryButton model={model.reagents.protocol.model} />
                <IconDropdownButton
                    icon={faWandSparkles}
                    label='Automation'
                    size='sm'
                    variant='link'
                    disabled={model.inventory.model.readOnlyDesignAndProduction}
                >
                    <Dropdown.Item
                        title='Only consider dry samples when auto-assigning source barcode'
                        onClick={() => model.reagents.liquid.autoAssign({ dryOnly: true })}
                    >
                        Assign Source Samples (dry stock only)
                    </Dropdown.Item>
                    <Dropdown.Item
                        title='Include liquid samples if there is enough available volume'
                        onClick={() => model.reagents.liquid.autoAssign()}
                    >
                        Assign Source Samples
                    </Dropdown.Item>
                    <Dropdown.Item
                        title='Assign labware based on required volume'
                        onClick={() => model.reagents.liquid.autoAssignLabware()}
                    >
                        Assign Labware
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={model.confirmClear}>Clear Source Barcodes and Labware</Dropdown.Item>
                </IconDropdownButton>
                <IconDropdownButton icon={faFileExport} label='Export' size='sm' variant='link'>
                    <Dropdown.Item onClick={() => model.reagents.liquid.exportBarcodes('copy')}>
                        Copy Source Barcodes
                    </Dropdown.Item>
                    {EcosystemService.environment.value?.name === 'dev' && (
                        <Dropdown.Item onClick={() => model.reagents.liquid.devExportOrderList('copy')}>
                            [DEV] Copy Order List
                        </Dropdown.Item>
                    )}
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => model.reagents.liquid.exportBarcodes('save')}>
                        Save Source Barcodes (.csv)
                    </Dropdown.Item>
                </IconDropdownButton>
            </div>
            <div className='flex-grow-1 position-relative'>
                <InventoryTable table={model.table} />
            </div>
        </div>
    );
}

function DryReagentsUI({ model }: { model: HTE2MSDryReagentsModel }) {
    useMountedModel(model);

    return (
        <div className='d-flex flex-column h-100'>
            <div className='hstack gap-2 p-2'>
                <IconButton onClick={() => {}} icon={faCube} size='sm' variant='primary'>
                    Dry
                </IconButton>
                <IconButton
                    onClick={() => model.reagents.state.kind.next('liquid')}
                    icon={faDroplet}
                    size='sm'
                    variant='outline-primary'
                >
                    Liquid
                </IconButton>
                <IconButton
                    onClick={() => model.reagents.state.kind.next('solution')}
                    icon={faVials}
                    size='sm'
                    variant='outline-primary'
                >
                    Solutions
                </IconButton>
                <div className='flex-grow-1'>
                    <DataTableGlobalFilter table={model.table} size='sm' />
                </div>
                <div className='flex-grow-1' />
                <RefreshInventoryButton model={model.reagents.protocol.model} />
                <IconDropdownButton
                    icon={faWandSparkles}
                    label='Automation'
                    size='sm'
                    variant='link'
                    disabled={model.inventory.model.readOnlyDesignAndProduction}
                >
                    <Dropdown.Item
                        title='Only assign Source Barcode to trasnfer later using the Inventory tab, does not ovewrite existing values'
                        onClick={() => model.reagents.dry.autoAssign({ kind: 'source' })}
                    >
                        Assign Source Barcodes
                    </Dropdown.Item>
                    <Dropdown.Item
                        title='Find inventory that matches Product Labware Vial Prefix and is within reasonable amount ratio, does not ovewrite existing values if either source or transfer barcode is assigned'
                        onClick={() => model.reagents.dry.autoAssign({ kind: 'transfer' })}
                    >
                        Assign Transfer Barcodes
                    </Dropdown.Item>
                    <Dropdown.Item onClick={model.reagents.dry.loadOrderList}>Load Order List</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={model.confirmAutoScale}>Auto-scale</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={model.confirmClear}>Clear Source and Transfer Barcodes</Dropdown.Item>
                </IconDropdownButton>
                <IconDropdownButton icon={faFileExport} label='Export' size='sm' variant='link'>
                    <Dropdown.Item onClick={() => model.reagents.dry.exportBarcodes('source_barcodes', 'copy')}>
                        Copy Source Barcodes for Transfer
                    </Dropdown.Item>
                    <Dropdown.Item
                        title='Transfer barcodes that are same as corresponding source barcode or with unassigned source'
                        onClick={() => model.reagents.dry.exportBarcodes('transfer_barcodes', 'copy')}
                    >
                        Copy Pre-weighted Transfer Barcodes
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => model.reagents.dry.exportOrderList('copy')}>
                        Copy Order List
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => model.reagents.dry.exportBarcodes('source_barcodes', 'save')}>
                        Save Source Barcodes for Transfer (.csv)
                    </Dropdown.Item>
                    <Dropdown.Item
                        title='Transfer barcodes that are same as corresponding source barcode or with unassigned source'
                        onClick={() => model.reagents.dry.exportBarcodes('transfer_barcodes', 'save')}
                    >
                        Save Pre-weighted Transfer Barcodes (.csv)
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => model.reagents.dry.exportOrderList('save')}>
                        Save Order List (.csv)
                    </Dropdown.Item>
                </IconDropdownButton>
            </div>
            <div className='flex-grow-1 position-relative'>
                <InventoryTable table={model.reagents.dry.table} />
            </div>
        </div>
    );
}

function SolutionReagentsUI({ model }: { model: HTE2MSSolutionReagentsModel }) {
    useMountedModel(model);

    return (
        <div className='d-flex flex-column h-100'>
            <div className='hstack gap-2 p-2'>
                <IconButton
                    onClick={() => model.reagents.state.kind.next('dry')}
                    icon={faCube}
                    size='sm'
                    variant='outline-primary'
                >
                    Dry
                </IconButton>
                <IconButton
                    onClick={() => model.reagents.state.kind.next('liquid')}
                    icon={faDroplet}
                    size='sm'
                    variant='outline-primary'
                >
                    Liquid
                </IconButton>
                <IconButton onClick={() => {}} icon={faVials} size='sm' variant='primary'>
                    Solutions
                </IconButton>
                <div className='flex-grow-1'>
                    <DataTableGlobalFilter table={model.table} size='sm' />
                </div>
                <div className='flex-grow-1' />
                <IconDropdownButton
                    icon={faWandSparkles}
                    label='Automation'
                    size='sm'
                    variant='link'
                    disabled={model.inventory.model.readOnlyDesignAndProduction}
                >
                    <Dropdown.Item onClick={model.confirmClear}>Clear Source Barcodes and Labware</Dropdown.Item>
                </IconDropdownButton>
            </div>
            <div className='flex-grow-1 position-relative'>
                <InventoryTable table={model.table} />
            </div>
        </div>
    );
}

function InventoryTable({ table }: { table: DataTableModel }) {
    useBehavior(table.version);
    return <DataTableControl table={table} height='flex' headerSize='xxsm' className='hte2ms-reagent-table' />;
}

function WarningList({ model }: { model: HTE2MSProtocolModel }) {
    const messages = useBehavior(model.state.messages);
    let label;
    if (messages.errors.length > 0 && messages.warnings.length > 0) {
        label = `Errors (${messages.errors.length}) & Warnings (${messages.warnings.length})`;
    } else if (messages.errors.length > 0) {
        label = `Errors (${messages.errors.length})`;
    } else if (messages.warnings.length > 0) {
        label = `Warnings (${messages.warnings.length})`;
    } else {
        label = 'Warnings';
    }

    return (
        <HTEPanel title={label}>
            <MessageList model={model} messages={messages} />
        </HTEPanel>
    );
}

function MessageList({
    model,
    messages,
    noScroll,
}: {
    model: HTE2MSProtocolModel;
    messages: { warnings: ProtocolMessageGroup[]; errors: ProtocolMessageGroup[] };
    noScroll?: boolean;
}) {
    if (messages.warnings.length + messages.errors.length === 0)
        return <div className='px-2 py-1 text-secondary font-body-small'>None</div>;

    const message = (g: ProtocolMessageGroup, kind: 'danger' | 'warning', key: any) => (
        <li key={key}>
            <Button
                variant='link'
                className={`p-0 text-${kind} font-body-small text-start`}
                title='Click to show in Design tab'
                onClick={() => model.model.showReactionDesign(g.reactionIds, { onlySelected: true })}
                onMouseEnter={() => model.highlightMessageGroup(g)}
                onMouseLeave={() => model.highlightMessageGroup()}
            >
                {g.reactionIds.length > 1 && <>({g.reactionIds.length}) </>}
                {g.message}
            </Button>
        </li>
    );

    const content = (
        <>
            <ul className='text-danger font-body-small ps-3 m-0'>
                {messages.errors.map((e, i) => message(e, 'danger', i))}
            </ul>
            <ul className='text-warning font-body-small ps-3 m-0'>
                {messages.warnings.map((e, i) => message(e, 'warning', i))}
            </ul>
        </>
    );

    if (noScroll) return content;

    return <ScrollBox className='p-2'>{content}</ScrollBox>;
}

function Platemap({ model }: { model: HTE2MSProtocolModel }) {
    const plateVisual = useBehavior(model.state.plateVisual);

    return (
        <div className='d-flex flex-grow-1 h-100 flex-column'>
            <div className='hstack gap-1 mt-2 px-2'>
                <div className='m-auto' />
                <div className='fw-bold font-body-small text-secondary'>Color by:</div>
                <div style={{ width: 200 }}>
                    <SelectColoring model={model} />
                </div>
            </div>
            <div className='flex-grow-1 position-relative m-4 ms-2 mb-1'>
                <div className='position-relative h-100 m-auto'>
                    <PlateVisual model={plateVisual} />
                </div>
            </div>
            <div className='m-auto' />
            <div className='position-relative p-2' style={{ minHeight: 220, maxHeight: 220 }}>
                <WellReaction model={model} />
            </div>
            <div className='m-auto' />
        </div>
    );
}

function SelectColoring({ model }: { model: HTE2MSProtocolModel }) {
    const coloring = useBehavior(model.state.coloring);

    return (
        <SimpleSelectOptionInput
            options={coloring[1].options}
            value={coloring[0]}
            setValue={(v) => model.state.coloring.next([v, coloring[1]])}
            size='sm'
        />
    );
}

function WellContents({ model }: { model: HTE2MSProtocolModel }) {
    const selection = useBehavior(model.plateVisual.state.selection);
    const wI = getFirstSelectedIndex(selection);
    const reaction = model.wellToReaction.get(wI);

    const messages = useMemo(() => {
        if (!reaction) return { warnings: [], errors: [] };
        return {
            warnings: model.data.warnings
                .filter((m) => m.reaction_id === reaction.id)
                .map(
                    (m) =>
                        ({
                            message: m.message,
                            reactionIds: [m.reaction_id!],
                            group: [m],
                        } satisfies ProtocolMessageGroup)
                ),
            errors: model.data.errors
                .filter((m) => m.reaction_id === reaction.id)
                .map(
                    (m) =>
                        ({
                            message: m.message,
                            reactionIds: [m.reaction_id!],
                            group: [m],
                        } satisfies ProtocolMessageGroup)
                ),
        };
    }, [reaction]);

    const viewInDesign = (
        <IconButton
            icon={faPencil}
            size='sm'
            disabled={!reaction}
            title='View in Design'
            onClick={() => model.model.showReactionDesign([reaction!.id], { onlySelected: true })}
        />
    );

    if (!reaction) return <HTEPanel title='Reaction' controls={viewInDesign} />;

    return (
        <HTEPanel title='Reaction' controls={viewInDesign}>
            <ScrollBox>
                <div className='p-2 font-body-small'>
                    <div className='font-body-small'>
                        <ProductSampleUI
                            sample={model.data.product_samples[reaction.id]}
                            inline
                            wellVolume={model.model.design.labware.product.volume}
                        />
                    </div>
                    <ReactionInfo model={model} reaction={reaction} />
                    <div className='vstack gap-1 mt-2' style={{ overflow: 'hidden' }}>
                        {reaction.template.instructions.map((instruction, i) => (
                            <InlineInstructionBadge
                                key={i}
                                model={model.reagents.protocol.model}
                                use={
                                    instruction.kind === 'add'
                                        ? model.reagents.instructionIdToUse.get(instruction.id!)
                                        : undefined
                                }
                                fw={
                                    instruction.kind === 'add'
                                        ? model.model.assets.entities.getFW(instruction.identifier!)
                                        : undefined
                                }
                                instruction={instruction}
                            />
                        ))}
                    </div>
                    {messages.errors.length + messages.warnings.length > 0 && (
                        <>
                            <div className='mt-2'>
                                <FontAwesomeIcon icon={faTriangleExclamation} className='me-1' size='sm' fixedWidth />
                                <b className='text-secondary'>Errors/Warnings:</b>
                            </div>
                            <div className='position-relative'>
                                <MessageList model={model} messages={messages} noScroll />
                            </div>
                        </>
                    )}
                </div>
            </ScrollBox>
        </HTEPanel>
    );
}

function WellReaction({ model }: { model: HTE2MSProtocolModel }) {
    const selection = useBehavior(model.plateVisual.state.selection);
    const wI = getFirstSelectedIndex(selection);
    const info = model.getReactionInfo(wI);

    if (!info?.smiles) return null;

    return (
        <AsyncMoleculeDrawing
            showChemDraw={false}
            showCopy={false}
            drawer={model.model.drawer}
            smiles={info.smiles}
            autosize
            asBackground
            width='100%'
            height='100%'
        />
    );
}

function Worklists({ model }: { model: HTE2MSProtocolModel }) {
    useBehavior(model.state.protocol);

    return (
        <HTEPanel title='Worklist'>
            <ScrollBox>
                <div className='vstack gap-1 font-body-xsmall mt-2 mb-2'>
                    {model.data.worklists.map((worklist, i) => (
                        <Worklist model={model} worklist={worklist} key={i} />
                    ))}
                </div>
            </ScrollBox>
        </HTEPanel>
    );
}

function Worklist({ model, worklist }: { model: HTE2MSProtocolModel; worklist: HTEPWorklist }) {
    const [isExpanded, setIsExpanded] = useState(true);
    return (
        <>
            <IconButton
                icon={isExpanded ? faCaretDown : faCaretRight}
                size='sm'
                onClick={() => setIsExpanded((prev) => !prev)}
                className='hte2ms-protocol-worklist-group'
            >
                {worklist.title} ({worklist.groups.length})
            </IconButton>
            {isExpanded && worklist.groups.map((group, i) => <WorklistGroup model={model} group={group} key={i} />)}
        </>
    );
}

function WorklistGroup({ model, group }: { model: HTE2MSProtocolModel; group: HTEPWorklistGroup }) {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
        <>
            <IconButton
                icon={isExpanded ? faCaretDown : faCaretRight}
                size='sm'
                onClick={() => setIsExpanded((prev) => !prev)}
                className='hte2ms-protocol-worklist-group ms-2'
                onMouseEnter={() => model.highlight(group.instructions)}
                onMouseLeave={() => model.highlight()}
            >
                {group.title} ({group.instructions.length})
            </IconButton>
            {isExpanded &&
                group.instructions.map((instruction, i) => (
                    <WorklistInstructions model={model} instruction={instruction} key={i} />
                ))}
        </>
    );
}

function WorklistInstructions({ model, instruction }: { model: HTE2MSProtocolModel; instruction: HTEPInstructionT }) {
    let inner: string | undefined;
    const entities = model.model.assets.entities;
    if (instruction.kind === 'transfer') {
        const reagent = model.reagents.byKey.get(instruction.reagent_key);
        const use = model.reagents.instructionIdToUse.get(instruction.instruction_id);
        const identifier = entities.getIdentifier(reagent?.identifier!);

        if (!use || !reagent) {
            inner = 'Unknown';
        } else if (!reagent.concentration) {
            inner = `${identifier}: ${Formatters.amount(
                use.n_mols * model.model.assets.entities.getFW(reagent.identifier)!
            )}`;
        } else {
            inner = `${identifier}: ${Formatters.volumeInL(
                use.n_mols / reagent.concentration
            )} @ ${Formatters.concentration(reagent.concentration)}`;
        }
    } else if (instruction.kind === 'solution-transfer') {
        const solution = model.reagents.solution.byKey.get(instruction.solution_key);
        if (!solution) {
            inner = 'Unknown';
        } else {
            const use = solution.uses.find(
                (u) => u.reaction_id === instruction.reaction_id && u.solution_id === instruction.solution_id
            );
            const identifiers = solution?.components.map((c) => entities.getIdentifier(c.identifier!))?.join('+');
            const volumeL = use ? getSolutionUseVolumeL(solution, use) : 0;
            const solvent = solution.components.find((c) => c.solvent)?.solvent;
            inner = `${identifiers}${solvent ? ` (${solvent})` : ''}: ${
                volumeL ? Formatters.volumeInL(volumeL) : 'Unknown'
            }`;
        }
    } else if (instruction.kind === 'backfill') {
        inner = `${Formatters.siVolume(instruction.volume)} of ${instruction.solvent}`;
    } else if (instruction.kind === 'pause') {
        inner = `Pause for ${instruction.duration}s`;
    }

    return (
        <Button
            className='hte2ms-protocol-worklist-instruction p-0 font-body-xsmall'
            onMouseEnter={() => model.highlight([instruction])}
            onMouseLeave={() => model.highlight()}
            onClick={() => model.select(instruction)}
            variant='link'
            title={inner}
        >
            {inner}
        </Button>
    );
}

function ReactionInfo({ model, reaction }: { model: HTE2MSProtocolModel; reaction: HTEDReaction }) {
    return (
        <div className='font-body-small'>
            <div>
                <FontAwesomeIcon icon={faAtom} className='me-1' size='sm' fixedWidth title='Reaction Chemistry' />
                <b className='text-secondary'>Chemistry:</b>{' '}
                {Formatters.chemistry(reaction.template.reaction_chemistry) || Unset}
            </div>
            <div>
                <FontAwesomeIcon icon={faAt} className='me-1' size='sm' fixedWidth title='Target Concentration' />
                <b className='text-secondary'>Target Conc.:</b>{' '}
                {Formatters.concentration(reaction.template.target_concentration) || Unset}
            </div>
            <div>
                <FontAwesomeIcon icon={faScaleUnbalanced} className='me-1' size='sm' fixedWidth title='Scale' />
                <b className='text-secondary'>Scale:</b> {Formatters.rxnScale(reaction.scale) || Unset}
            </div>
            <div>
                <FontAwesomeIcon icon={faDroplet} className='me-1' size='sm' fixedWidth title='Solvent' />
                <b className='text-secondary'>Solvent:</b> {reaction.template.solvent || Unset}
            </div>
            <div>
                <FontAwesomeIcon icon={faObjectGroup} className='me-1' size='sm' fixedWidth title='Group' />
                <b className='text-secondary'>Group:</b> {reaction.group_name || Unset}
            </div>
            <div>
                <FontAwesomeIcon icon={faFolderOpen} className='me-1' size='sm' fixedWidth title='Project' />
                <b className='text-secondary'>Project:</b> {reaction.project || Unset}
            </div>
        </div>
    );
}
