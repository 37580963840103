import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxisType, PlotData } from 'plotly.js-dist';
import { ReactNode } from 'react';
import { BehaviorSubject, pairwise } from 'rxjs';
import { InfoTooltip } from '../../components/common/Tooltips';
import { Column, ColumnsFor, DataTableModel, DataTableStore } from '../../components/DataTable';
import {
    DownloadArtifactButton,
    HoverLink,
    ListColumnSchema,
    SelectionColumn,
} from '../../components/DataTable/common';
import { DefaultFigureLayout, PlotlyFigure } from '../../components/Plot';
import { AssayUncertaintyValue, AssayValueGraph } from '../../lib/assays/models';
import { AssayValueView } from '../../lib/assays/display';
import { AssayValueTypeColumn } from '../../lib/assays/table';
import { AssayFormattingOptions, isUncertaintyValue } from '../../lib/assays/util';
import { BaseColors } from '../../lib/services/theme';
import { ToastService } from '../../lib/services/toast';
import { isBlank } from '../../lib/util/misc';
import { PALETTE, PLOT_COLORS } from '../../lib/util/plot';
import { ReactiveModel } from '../../lib/util/reactive-model';
import { getAssayLink } from '../Assays/assay-api';
import {
    CompoundDetail,
    PKData,
    PKDataWrapper,
    PercentUnbound,
    PercentUnboundErrors,
    PercentUnboundMethod,
    PhysicalChemProperties,
    getPKKey,
    getRowIndexFromPKKey,
} from './compound-api';

const PKDataTableSchema: ColumnsFor<PKData> = {
    batch_identifier: { ...Column.str(), width: 150, noHeaderTooltip: true },
    group_name: { ...Column.str(), width: 120, noHeaderTooltip: true },
    strain: { ...Column.str(), noHeaderTooltip: true },
    sex: { ...Column.str(), noHeaderTooltip: true },
    formulation: { ...Column.str(), width: 300, noHeaderTooltip: true },
    dosage: { ...Column.str(), noHeaderTooltip: true },
    dosing_route: { ...Column.str(), noHeaderTooltip: true },
    graph: {
        ...Column.obj({
            format: () => '-',
            compare: false,
        }),
        noHeaderTooltip: true,
    },
    report: {
        ...Column.obj({
            format: () => '-',
            compare: false,
        }),
        noHeaderTooltip: true,
        render: ({ value }) => <DownloadArtifactButton artifacts={value} />,
    },
    cassette_batches: ListColumnSchema(),
    study_protocol_number: {
        ...Column.str(),
        noHeaderTooltip: true,
        header: 'Study protocol #',
        width: 200,
    },
};

function createTable(
    store: DataTableStore<PKData>,
    { unboundSpeciesErrors }: { unboundSpeciesErrors: PercentUnboundErrors | undefined }
) {
    const schemas: Record<string, Column> = {};
    for (const col of store.columnNames) {
        const columnName = String(col);
        if (!PKDataTableSchema[columnName]) {
            schemas[columnName] = {
                ...AssayValueTypeColumn({ columnName }),
                render: ({ value, rowIndex, table }) => {
                    if (isBlank(value)) return null;
                    const options: AssayFormattingOptions = {
                        asNM: false,
                        formatting: table.state.columnFormatting[columnName],
                    };
                    if (typeof value === 'number') {
                        let missingBioavailability: ReactNode = null;
                        if (columnName.startsWith('%F')) {
                            missingBioavailability = (
                                <InfoTooltip tooltip='Bioavailability was calculated from reference data' />
                            );
                        }
                        return (
                            <>
                                <AssayValueView value={value} options={options} />
                                {missingBioavailability}
                            </>
                        );
                    }

                    const identifier = value.identifiers?.length
                        ? value.identifiers[0].split('-')[0] // use compound identifier if the value is imputed
                        : table.store.getValue('batch_identifier', rowIndex);
                    const assayLink = getAssayLink(value.assay_id, identifier);
                    return (
                        <HoverLink
                            href={assayLink}
                            content={<AssayValueView value={value.value} options={options} />}
                            fill
                        />
                    );
                },
            };
        }
    }

    const table = new DataTableModel(store, {
        columns: {
            ...PKDataTableSchema,
            ...schemas,
            species: {
                ...Column.str(),
                render: ({ value, rowIndex }) => {
                    if (!unboundSpeciesErrors?.[value]) return <span className='text-capitalize'>{value}</span>;

                    return (
                        <span className='text-capitalize text-warning' title={unboundSpeciesErrors[value]}>
                            <FontAwesomeIcon
                                size='sm'
                                fixedWidth
                                className='me-1 text-warning'
                                icon={faExclamationTriangle}
                            />
                            {value}
                        </span>
                    );
                },
                width: 150,
                noHeaderTooltip: true,
            },
        },
        actions: [SelectionColumn()],
    });

    table.setColumnVisibility('graph', false);
    table.setSelected(0, true);

    return table;
}

const PKPhysicalChemPropertiesSchema: ColumnsFor<PhysicalChemProperties> = {
    batch_identifier: { ...Column.str(), width: 150, noHeaderTooltip: true },
    MW: { ...Column.float(), noHeaderTooltip: true },
    TPSA: { ...Column.float(), noHeaderTooltip: true },
    LogP: { ...Column.float(), noHeaderTooltip: true },
};

function createPhysicalChemPropertiesTable(store: DataTableStore<PhysicalChemProperties>) {
    return new DataTableModel(store, {
        columns: PKPhysicalChemPropertiesSchema,
    });
}

const MaxLegendTitleLength = 47;

function getTruncatedTraceTitle(name: string | number | symbol) {
    const title = String(name);
    return title.length > MaxLegendTitleLength ? `${title.slice(0, MaxLegendTitleLength)}...` : title;
}

const defaultFigure: PlotlyFigure = {
    data: [],
    layout: {
        ...DefaultFigureLayout,
        font: { size: 12 },
        paper_bgcolor: PLOT_COLORS.bg,
        plot_bgcolor: PLOT_COLORS.bg,
        xaxis: {
            tickfont: { size: 10 },
            gridcolor: PLOT_COLORS.grid,
            zerolinecolor: PLOT_COLORS.zeroLine,
            range: [0, 24],
            tick0: 0,
            dtick: 4,
        },
        yaxis: {
            tickfont: { size: 10 },
            gridcolor: PLOT_COLORS.grid,
            zerolinecolor: PLOT_COLORS.zeroLine,
            type: 'log',
        },
        legend: {
            font: {
                size: 10,
            },
        },
        margin: {
            t: 30,
            b: 45,
            l: 48,
            r: 20,
        },
    },
};

const CONCENTRATION_UNITS = ['ng/mL', 'M', 'nmol/mL'];
type ConcentrationUnits = typeof CONCENTRATION_UNITS;
type ConcentrationUnit = ConcentrationUnits[number];

const Y_AXIS_TYPES = ['log', 'linear'];
type YAxisTypes = typeof Y_AXIS_TYPES;
type YAxisType = YAxisTypes[number];

const BOUND_UNBOUND = ['bound', 'unbound', 'both'];
type BoundUnboundTypes = typeof BOUND_UNBOUND;
type BoundUnboundType = BoundUnboundTypes[number];

interface FigureOptions {
    concentrationUnit: ConcentrationUnit;
    yAxisType: YAxisType;
    showBoundUnbound: BoundUnboundType;
    preferredPercentUnboundMethod: PercentUnboundMethod;
}

export const concentrationUnitOptions = [
    { label: 'ng/mL', value: 'ng/mL' },
    { label: 'M', value: 'M' },
    // { label: 'nmol/mL', value: 'nmol/mL' } // TODO (emma)
];
export const yAxisOptions = [
    { label: 'log y-axis', value: 'log' },
    { label: 'linear y-axis', value: 'linear' },
];

export const boundUnboundOptions = [
    { label: 'Bound', value: 'bound' },
    { label: 'Unbound', value: 'unbound' },
    { label: 'Both', value: 'both' },
];

interface LoadState extends FigureOptions {
    visibleIC50Traces: string[];
    selected: string[];
}

export class PKModel extends ReactiveModel {
    public table: DataTableModel<PKData>;
    public percentUnbound: PercentUnbound;
    public percentUnboundMethods: string[];
    public percentUnboundErrors: PercentUnboundErrors;
    public physicalChemPropertiesTable: DataTableModel<PhysicalChemProperties>;

    state = {
        error: new BehaviorSubject<any>(null),
        figureOptions: new BehaviorSubject<FigureOptions>({
            concentrationUnit: 'ng/mL',
            yAxisType: 'log',
            showBoundUnbound: 'bound',
            preferredPercentUnboundMethod: 'Ultracentrifugation (UC)',
        }),
        visibleIC50Traces: new BehaviorSubject<Set<string>>(new Set()),
        figure: new BehaviorSubject<PlotlyFigure>(defaultFigure),
        canShowCorrectedCurve: new BehaviorSubject<boolean>(false),
    };

    onLegendClick = (evtData: any) => {
        const trace = evtData.data[evtData.curveNumber];
        // if the trace isn't one of the IC50 traces, ignore and
        // let plotly handle it
        if (!trace?.name.startsWith('A(')) return;
        // otherwise, we want to keep track of which IC50 traces are visible
        // and redraw the plot to make sure the accessory curves (IC90, unbound) display correctly
        this.updateVisibleIC50s(trace);
        return false;
    };

    private updateVisibleIC50s(trace: any) {
        const visibleIC50Traces = this.state.visibleIC50Traces.value;
        if (trace?.visible === true) {
            if (trace) visibleIC50Traces.delete(trace.name);
            this.state.visibleIC50Traces.next(visibleIC50Traces);
        } else if (trace?.visible === 'legendonly') {
            visibleIC50Traces.add(trace.name);
            this.state.visibleIC50Traces.next(visibleIC50Traces);
        }
        this.updatePlot();
    }

    loadFromQueryState(state: LoadState) {
        const { concentrationUnit, yAxisType, showBoundUnbound, preferredPercentUnboundMethod } =
            this.state.figureOptions.value;
        const visibleIC50Traces = this.state.visibleIC50Traces.value;

        this.state.figureOptions.next({
            concentrationUnit: state.concentrationUnit ?? concentrationUnit,
            yAxisType: state.yAxisType ?? yAxisType,
            showBoundUnbound: state.showBoundUnbound ?? showBoundUnbound,
            preferredPercentUnboundMethod: state.preferredPercentUnboundMethod
                ? state.preferredPercentUnboundMethod
                : preferredPercentUnboundMethod,
        });
        const newVisibleIC50Traces =
            state.visibleIC50Traces && state.visibleIC50Traces.length > 0
                ? new Set(state.visibleIC50Traces)
                : undefined;
        this.state.visibleIC50Traces.next(newVisibleIC50Traces ?? visibleIC50Traces);

        const selected = state.selected;
        if (selected) {
            const newSelection: Record<number, boolean> = {};
            for (const key of selected) {
                const rowIndex = getRowIndexFromPKKey(this.table.store, key);
                newSelection[rowIndex] = true;
            }
            this.table.setSelection(newSelection);
        }
    }

    getQueryState(): LoadState {
        const { concentrationUnit, yAxisType, showBoundUnbound, preferredPercentUnboundMethod } =
            this.state.figureOptions.value;
        const visibleIC50Traces = Array.from(this.state.visibleIC50Traces.value);
        const selected: string[] = Object.keys(this.table.selectedRows)
            .filter((rowIdx) => !!this.table.selectedRows[rowIdx])
            .map((i) => getPKKey(this.table.store, +i));

        return {
            concentrationUnit,
            yAxisType,
            showBoundUnbound,
            preferredPercentUnboundMethod,
            visibleIC50Traces,
            selected,
        };
    }

    private convertYUnits(graph: AssayValueGraph, yData: number[][]) {
        const { concentrationUnit } = this.state.figureOptions.value;
        const molecularWeight = this.compound.molecular_weight;

        const yConverted: number[][] = [];
        if (graph.y_units === 'nmol/mL') {
            for (const y of yData) {
                if (concentrationUnit === 'ng/mL') {
                    // nmol/mL -> ng/mL
                    yConverted.push(y.map((v) => v * molecularWeight));
                } else if (concentrationUnit === 'M') {
                    // nmol/mL -> M
                    yConverted.push(y.map((v) => v / 1e6));
                } else if (concentrationUnit === 'nmol/mL') {
                    yConverted.push(y);
                }
            }
            return yConverted;
        }
        ToastService.error(
            `Concentration unit (${graph.y_units}) of pk curve not supported. Check that the unit is in nmol/mL or request additional support for different units.`
        );
        return [];
    }

    private getAveragedValues(
        graph: AssayValueGraph,
        xData: number[][],
        yData: number[][],
        species: string,
        method: PercentUnboundMethod
    ) {
        const speciesMultiplier = this.getSpeciesMultiplier(species, method);
        const showCorrected = typeof speciesMultiplier === 'number';

        const xAvg: number[] = new Array(xData[0].length);
        const yAvg: number[] = new Array(xData[0].length);
        const yAvgCorrected: number[] = showCorrected ? new Array(xData[0].length) : [];
        for (let i = 0; i < xData[0].length; i++) {
            let x = 0;
            let y = 0;
            const curvesInAverage = graph.data.filter((d) => !d.name.toLowerCase().startsWith('metabolite'));
            for (let curveIdx = 0; curveIdx < curvesInAverage.length; curveIdx++) {
                x += xData[curveIdx][i];
                y += yData[curveIdx][i];
            }
            xAvg[i] = x / curvesInAverage.length;
            yAvg[i] = y / curvesInAverage.length;
            if (showCorrected) yAvgCorrected[i] = (y / curvesInAverage.length) * speciesMultiplier;
        }

        return { xAvg, yAvg, yAvgCorrected };
    }

    private getBoundIC50IC90Traces(
        ic50Values: readonly any[],
        ic90Values: readonly any[],
        identifiers: readonly string[],
        name: string,
        shorterAssayTitle: string,
        color: string,
        xMax: number
    ): Partial<PlotData>[] {
        const visibleIC50Traces = this.state.visibleIC50Traces.value;
        const { showBoundUnbound } = this.state.figureOptions.value;

        const ic50X = [];
        const ic50Y = [];
        const ic50Titles = [];
        const ic90X = [];
        const ic90Y = [];
        const ic90Titles = [];

        const labelPosition = showBoundUnbound === 'both' ? 0.25 : 0.5;

        for (let i = 0; i < ic50Values.length; i++) {
            const value = ic50Values[i];
            const identifier = identifiers[i];
            ic50X.push(0, labelPosition * xMax, xMax, null);
            ic50Y.push(value, value, value, null);
            ic50Titles.push('', `IC50 ${shorterAssayTitle} ${identifier})`, '', '');
        }
        for (let i = 0; i < ic90Values.length; i++) {
            const value = ic90Values[i];
            const identifier = identifiers[i];
            ic90X.push(0, labelPosition * xMax, xMax, null);
            ic90Y.push(value, value, value, null);
            ic90Titles.push('', `IC90 ${shorterAssayTitle} ${identifier})`, '', '');
        }

        return [
            {
                x: ic50X,
                y: ic50Y,
                text: ic50Titles,
                name,
                mode: 'text+lines',
                line: {
                    color,
                    width: 1,
                    dash: 'dash',
                },
                visible: visibleIC50Traces.has(name) ? true : 'legendonly',
                hoverinfo: 'skip',
            },
            {
                x: ic90X,
                y: ic90Y,
                text: ic90Titles,
                name,
                mode: 'text+lines',
                line: {
                    color,
                    width: 1,
                    dash: 'longdash',
                },
                showlegend: false,
                visible: visibleIC50Traces.has(name),
                hoverinfo: 'skip',
            },
        ];
    }

    private getUnboundIC50IC90Traces(
        ic50FBSValues: readonly any[],
        ic90FBSValues: readonly any[],
        identifiers: readonly string[],
        name: string,
        shorterAssayTitle: string,
        color: string,
        xMax: number
    ): Partial<PlotData>[] {
        const visibleIC50Traces = this.state.visibleIC50Traces.value;
        const { showBoundUnbound } = this.state.figureOptions.value;

        const unboundIC50X = [];
        const unboundIC50Y = [];
        const unboundIC50Titles = [];
        const unboundIC90X = [];
        const unboundIC90Y = [];
        const unboundIC90Titles = [];

        const labelPosition = showBoundUnbound === 'both' ? 0.75 : 0.5;

        for (let i = 0; i < ic50FBSValues.length; i++) {
            const value = ic50FBSValues[i];
            const identifier = identifiers[i];
            unboundIC50X.push(0, labelPosition * xMax, xMax, null);
            unboundIC50Y.push(value, value, value, null);
            unboundIC50Titles.push('', `Unbound IC50 ${shorterAssayTitle} ${identifier})`, '', '');
        }

        for (let i = 0; i < ic90FBSValues.length; i++) {
            const value = ic90FBSValues[i];
            const identifier = identifiers[i];
            unboundIC90X.push(0, labelPosition * xMax, xMax, null);
            unboundIC90Y.push(value, value, value, null);
            unboundIC90Titles.push('', `Unbound IC90 ${shorterAssayTitle} ${identifier})`, '', '');
        }

        return [
            {
                x: unboundIC50X,
                y: unboundIC50Y,
                text: unboundIC50Titles,
                name,
                mode: 'text+lines',
                line: {
                    color,
                    width: 1,
                    dash: 'dashdot',
                },
                showlegend: showBoundUnbound === 'unbound',
                visible: visibleIC50Traces.has(name) ? true : showBoundUnbound === 'unbound' ? 'legendonly' : false,
                hoverinfo: 'skip',
            },
            {
                x: unboundIC90X,
                y: unboundIC90Y,
                text: unboundIC90Titles,
                name,
                mode: 'text+lines',
                line: {
                    color,
                    width: 1,
                    dash: 'longdashdot',
                },
                showlegend: false,
                visible: visibleIC50Traces.has(name),
                hoverinfo: 'skip',
            },
        ];
    }

    private getIC50IC90Traces(xMax: number) {
        const { concentrationUnit, showBoundUnbound } = this.state.figureOptions.value;
        const molecularWeight = this.compound.molecular_weight;

        const ic50Store = this.pkData.ic50;
        const ic90Store = this.pkData.ic90;
        const ic50FbsStore = this.pkData.ic50_fbs;
        const ic90FbsStore = this.pkData.ic90_fbs;
        const plotTraces: Partial<PlotData>[] = [];

        if (ic50Store.rowCount === 0) return plotTraces;

        const identifierSet: Set<string> = new Set(
            this.table.getSelectedRowIndices().map((rowIdx) => this.table.store.getValue('batch_identifier', +rowIdx))
        );
        let identifiers: string[] = Array.from(identifierSet);
        let selectedIndices = identifiers
            .map((identifier) => ic50Store.findValueIndex('identifier', identifier))
            .filter((i) => i > -1);

        // if the index array is empty, that means we have no IC50 data
        // for the selected batches
        // but the users still want to see IC50s, so find the first available
        if (selectedIndices.length === 0) {
            selectedIndices = [0];
            identifiers = [ic50Store.getValue('identifier', 0)];
        }

        for (let i = 1; i < ic50Store.columnNames.length; i++) {
            const columnName = ic50Store.columnNames[i];
            // try to get better color differences on small data
            let color;
            if (i < 11) {
                color = PALETTE[(5 * i) % PALETTE.length];
            } else if (i < 21) {
                color = PALETTE[(3 * i) % PALETTE.length];
            } else {
                color = PALETTE[i % PALETTE.length];
            }

            const shorthand = String(columnName);
            let ic50Values = ic50Store.getColumnValues(shorthand, selectedIndices);
            let ic90Values = ic90Store.getColumnValues(shorthand, selectedIndices);
            const ic50FbsIndices = identifiers.map((identifier) =>
                ic50FbsStore.findValueIndex('identifier', identifier)
            );
            const ic90FbsIndices = identifiers.map((identifier) =>
                ic90FbsStore.findValueIndex('identifier', identifier)
            );
            let ic50FBSValues = ic50FbsStore.hasColumn(shorthand)
                ? ic50FbsStore.getColumnValues(shorthand, ic50FbsIndices)
                : [];
            let ic90FBSValues = ic90FbsStore.hasColumn(shorthand)
                ? ic90FbsStore.getColumnValues(shorthand, ic90FbsIndices)
                : [];
            if (concentrationUnit === 'ng/mL') {
                ic50Values = ic50Values.map((v) => (v * molecularWeight) / 1e-6);
                ic90Values = ic90Values.map((v) => (v * molecularWeight) / 1e-6);
                ic50FBSValues = ic50FBSValues.map((v) => (v * molecularWeight) / 1e-6);
                ic90FBSValues = ic90FBSValues.map((v) => (v * molecularWeight) / 1e-6);
            }

            // for plot text just annotate as "A(id, batch_identifier)""
            const shorterAssayTitle = `${shorthand.substring(0, shorthand.indexOf(','))}, `;
            const name = getTruncatedTraceTitle(columnName);

            if (showBoundUnbound === 'both' || showBoundUnbound === 'bound') {
                plotTraces.push(
                    ...this.getBoundIC50IC90Traces(
                        ic50Values,
                        ic90Values,
                        identifiers,
                        name,
                        shorterAssayTitle,
                        color,
                        xMax
                    )
                );
            }

            if (showBoundUnbound === 'both' || showBoundUnbound === 'unbound') {
                plotTraces.push(
                    ...this.getUnboundIC50IC90Traces(
                        ic50FBSValues,
                        ic90FBSValues,
                        identifiers,
                        name,
                        shorterAssayTitle,
                        color,
                        xMax
                    )
                );
            }
        }

        return plotTraces;
    }

    private getRawPKCurves(graph: AssayValueGraph, xData: number[][], yData: number[][]): Partial<PlotData>[] {
        const { yAxisType } = this.state.figureOptions.value;

        const selectedRowIndices = this.getSelectedRowIndices();

        // don't show raw curves if more than one batch is selected
        if (selectedRowIndices.length > 1) return [];

        return graph.data.map((d, idx) => ({
            x: yAxisType === 'log' ? xData[idx].filter((_, i) => yData[idx][i] !== 0) : xData[idx],
            y: yAxisType === 'log' ? yData[idx].filter((v) => v !== 0) : yData[idx],
            name: d.name,
            mode: 'lines',
            line: {
                width: 1,
                color: PLOT_COLORS.curves,
            },
        }));
    }

    private getAveragedPKCurve(title: string, rowIndex: number, xAvg: number[], yAvg: number[]): Partial<PlotData> {
        const { yAxisType } = this.state.figureOptions.value;

        const selectedRowIndices = this.getSelectedRowIndices();

        return {
            x: yAxisType === 'log' ? xAvg.filter((_, i) => yAvg[i] !== 0) : xAvg,
            y: yAxisType === 'log' ? yAvg.filter((v) => v !== 0) : yAvg,
            name: title,
            mode: 'lines+markers',
            marker: {
                color: selectedRowIndices.length > 1 ? PALETTE[rowIndex] : PLOT_COLORS.curveAvg,
            },
        };
    }

    private getUnboundAveragedPKCurve(
        title: string,
        rowIndex: number,
        xAvg: number[],
        yAvg: number[]
    ): Partial<PlotData> {
        const { yAxisType } = this.state.figureOptions.value;

        const selectedRowIndices = this.getSelectedRowIndices();

        return {
            x: yAxisType === 'log' ? xAvg.filter((_, i) => yAvg[i] !== 0) : xAvg,
            y: yAxisType === 'log' ? yAvg.filter((v) => v !== 0) : yAvg,
            name: title,
            mode: 'lines+markers',
            marker: {
                color:
                    selectedRowIndices.length > 1
                        ? PALETTE[(3 * (rowIndex + 1)) % PALETTE.length]
                        : PLOT_COLORS.curveAvgCorrected,
            },
        };
    }

    private updatePlot() {
        const error = this.state.error.value;
        if (error) return;

        const { concentrationUnit, yAxisType, showBoundUnbound, preferredPercentUnboundMethod } =
            this.state.figureOptions.value;

        const selectedRowIndices = this.getSelectedRowIndices();

        const figure = this.state.figure.value;
        const newFigure: PlotlyFigure = {
            data: [],
            layout: { ...figure.layout },
        };

        if (selectedRowIndices.length === 0) {
            this.state.figure.next(newFigure);
            return;
        }

        const titles: string[] = [];

        let xMax = 24;
        let xTitle = '';
        let yTitle = '';

        const graphs = this.table.store.getColumnValues('graph', selectedRowIndices);
        const selectionHasBlankGraphs = graphs.some((g) => !g);

        // All the graphs should have the same axes and units, but let's double check just in case
        const nonEmptyGraphs = graphs.filter((g) => !!g) as AssayValueGraph[];
        const firstNonEmptyGraph = nonEmptyGraphs[0];
        const xAxesEqual = nonEmptyGraphs.every(
            (g) => g.x_axis === firstNonEmptyGraph.x_axis && g.x_units === firstNonEmptyGraph.x_units
        );
        const yAxesEqual = nonEmptyGraphs.every(
            (g) => g.y_axis === firstNonEmptyGraph.y_axis && g.y_units === firstNonEmptyGraph.y_units
        );

        if (!xAxesEqual) {
            ToastService.error('Some selected assay values have differing X axis information', {
                id: 'pk-error-x-axis',
            });
            return;
        }
        if (!yAxesEqual) {
            ToastService.error('Some selected assay values have differing Y axis information', {
                id: 'pk-error-y-axis',
            });
            return;
        }
        if (selectionHasBlankGraphs) {
            ToastService.info('Some selected assay values do not have graph data', { id: 'pk-info-empty-graphs' });
        }

        const plotTraces: Partial<PlotData>[] = [];
        for (const rowIndex of selectedRowIndices) {
            const graph = this.table.store.getValue('graph', rowIndex);
            if (!graph) continue;
            const species = this.table.store.getValue('species', rowIndex);
            const dosing_route = this.table.store.getValue('dosing_route', rowIndex);
            const dosage = this.table.store.getValue('dosage', rowIndex);
            const percentUnboundMethod = this.getPercentUnboundMethod(species, preferredPercentUnboundMethod);
            const avgCurveTitle = `${species} ${dosing_route} ${dosage}`;
            const avgCurveTitleCorrected = `Unbound ${species} ${dosing_route} ${dosage} (${percentUnboundMethod})`;

            if (showBoundUnbound === 'both') {
                titles.push(avgCurveTitle, avgCurveTitleCorrected);
            } else if (showBoundUnbound === 'bound') {
                titles.push(avgCurveTitle);
            } else if (showBoundUnbound === 'unbound') {
                titles.push(avgCurveTitleCorrected);
            }

            xTitle = `${graph.x_axis} (${graph.x_units})`;
            yTitle = `${graph.y_axis} (${concentrationUnit})`;

            const xData = graph.data.map((p) => p.x);
            const yData = graph.data.map((p) => p.y);
            const yConverted: number[][] = this.convertYUnits(graph, yData);
            const { xAvg, yAvg, yAvgCorrected } = this.getAveragedValues(
                graph,
                xData,
                yConverted,
                species,
                preferredPercentUnboundMethod
            );

            const rawCurves: Partial<PlotData>[] = this.getRawPKCurves(graph, xData, yConverted);
            const averageCurves: Partial<PlotData> = this.getAveragedPKCurve(avgCurveTitle, rowIndex, xAvg, yAvg);
            const averageCurvesCorrected: Partial<PlotData> = this.getUnboundAveragedPKCurve(
                avgCurveTitleCorrected,
                rowIndex,
                xAvg,
                yAvgCorrected
            );

            // x range is 0-24 or max of x data if >24
            xMax = xAvg.reduce((previousMax, v) => Math.max(previousMax, v), 24);

            if (!['ng/mL', 'M'].includes(concentrationUnit)) {
                ToastService.show({
                    type: 'danger',
                    message: `Concentration unit (${concentrationUnit}) not supported. Check that the unit is "ng/mL", "M", or request additional support for different units.`,
                });
            }

            if (showBoundUnbound === 'both') {
                plotTraces.push(...rawCurves, averageCurves, averageCurvesCorrected);
            } else if (showBoundUnbound === 'bound') {
                plotTraces.push(...rawCurves, averageCurves);
            } else if (showBoundUnbound === 'unbound') {
                plotTraces.push(averageCurvesCorrected);
            }
        }

        plotTraces.push(...this.getIC50IC90Traces(xMax));

        newFigure.data = plotTraces;

        newFigure.layout = {
            ...defaultFigure.layout,
            font: {
                ...defaultFigure.layout.font,
                color: BaseColors.body,
            },
            title: {
                text: `Single Dose PK: ${titles.join(' + ')}`,
                font: { size: 12 },
            },
            xaxis: {
                ...defaultFigure.layout.xaxis,
                title: xTitle,
                range: [0, xMax],
            },
            yaxis: {
                ...defaultFigure.layout.yaxis,
                title: yTitle,
                type: yAxisType as AxisType,
            },
        };

        this.state.figure.next(newFigure);
        return newFigure;
    }

    private getPercentUnboundMethod(
        species: string,
        preferredMethod: PercentUnboundMethod
    ): PercentUnboundMethod | undefined {
        const percentUnboundOptions = this.percentUnbound[species];
        if (percentUnboundOptions?.some((p) => p.method === preferredMethod)) return preferredMethod;
        return percentUnboundOptions?.find((p) => !!p.method)?.method;
    }

    private getSpeciesMultiplier(species: string, preferredMethod: PercentUnboundMethod) {
        const percentUnboundOptions = this.percentUnbound[species];
        const method = this.getPercentUnboundMethod(species, preferredMethod);
        const value = percentUnboundOptions?.find((p) => p.method === method)?.value;
        if (typeof value === 'number') return value;
        if (typeof value === 'object' && isUncertaintyValue(value)) return (value as AssayUncertaintyValue).value;
        return undefined;
    }

    private setCanShowUnboundData() {
        const selectedRowIndices = this.getSelectedRowIndices();
        const { preferredPercentUnboundMethod } = this.state.figureOptions.value;
        let canShowCorrected = false;
        for (const rowIndex of selectedRowIndices) {
            const species = this.table.store.getValue('species', rowIndex);
            const speciesMultiplier = this.getSpeciesMultiplier(species, preferredPercentUnboundMethod);
            if (typeof speciesMultiplier === 'number') canShowCorrected = true;
        }
        this.state.canShowCorrectedCurve.next(canShowCorrected);
    }

    private setBoundUnboundState(selectedRowIndices: number[]) {
        const canShowUnbound = this.state.canShowCorrectedCurve.value;

        let showBoundUnbound = this.state.figureOptions.value.showBoundUnbound;
        if (canShowUnbound && selectedRowIndices.length === 1) {
            showBoundUnbound = 'both';
        } else if (canShowUnbound) {
            showBoundUnbound = 'unbound';
        } else {
            showBoundUnbound = 'bound';
        }

        this.state.figureOptions.next({
            ...this.state.figureOptions.value,
            showBoundUnbound,
        });
    }

    private getSelectedRowIndices() {
        const selectedRowIndices = Object.keys(this.table.selectedRows)
            .filter((rowIdx) => !!this.table.selectedRows[rowIdx])
            .map((i) => +i);
        return selectedRowIndices;
    }

    mount() {
        this.subscribe(
            this.state.figureOptions,
            (options) => {
                this.updatePlot();
            },
            { skipFirst: true }
        );

        this.subscribe(
            this.table.version,
            () => {
                this.setCanShowUnboundData();
                this.updatePlot();
            },
            { skipFirst: true }
        );

        this.subscribe(this.state.canShowCorrectedCurve.pipe(pairwise()), ([prev, next]) => {
            if (prev !== next && !next) {
                this.setBoundUnboundState(this.getSelectedRowIndices());
            }
        });

        this.setBoundUnboundState(this.getSelectedRowIndices());
        this.setCanShowUnboundData();
        this.updatePlot();
    }

    constructor(public compound: CompoundDetail, private pkData: PKDataWrapper) {
        super();

        // For testing of the error display:
        // this.table = createTable(pkData.values, { unboundSpeciesErrors: { Mouse: 'a test error' } });
        this.table = createTable(pkData.values, { unboundSpeciesErrors: pkData.percent_unbound_errors });
        this.percentUnbound = pkData.percent_unbound;
        this.percentUnboundMethods = pkData.percent_unbound_methods;
        this.percentUnboundErrors = pkData.percent_unbound_errors;
        this.physicalChemPropertiesTable = createPhysicalChemPropertiesTable(pkData.physical_chem_properties);

        if (this.table.store.rowCount === 0) {
            this.state.error.next('No PK data for this compound.');
        }
    }
}
