import { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { LabeledInput, TextInput } from '../../../components/common/Inputs';
import { LogModel } from '../../../components/common/Log';
import { PropertyNameValue } from '../../../components/common/PropertyNameValue';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import { ToastService } from '../../../lib/services/toast';
import { reportErrorAsToast, tryGetErrorMessage } from '../../../lib/util/errors';
import { trimValue } from '../../../lib/util/validators';
import { formatLocation, Plate, PlateLocation, Vial, VialLocation } from '../ecm-api';
import { ECMCommonUploadInfo, ECMPageTemplate, ECMWorkflowMode, ECMWorkflowModeSelect } from '../ecm-common';
import {
    ecmAPITemplate,
    ECMBatchWorkflowBase,
    ECMBatchWorkflowWrapper,
    ecmGatherMessages,
    ECMManualWorkflowBase,
    ECMManualWorkflowInputHelper,
    ECMManualWorkflowStatus,
    ECMManualWorkflowWrapper,
    ecmWorkflowEntrySummary,
    uploadECMCSV,
} from './common';

interface PrepareLocationInput {
    row_index?: number;
    kind?: 'plate' | 'vial';
    barcode?: string;
    location?: string;
    custom_location?: string;
    freezer?: string;
    cabinet?: string;
    shelf?: string;
    rack?: string;
    well?: string;
    slot?: string;
}

interface PrepareLocationEntry {
    input: PrepareLocationInput;

    errors: string[];
    warnings: string[];

    vial?: Vial;
    plate?: Plate;
    vial_location?: VialLocation;
    plate_location?: PlateLocation;
}

type PrepareLocationEntries = PrepareLocationEntry[];

const LocationAPI = ecmAPITemplate<PrepareLocationInput[], PrepareLocationEntries>('location');

interface ManualLocationWorkflowInput {
    barcode: string;
    kind: 'vial' | 'plate';
    vialKind: 'lab' | 'verso' | 'specific' | 'custom';
    plateKind: 'none' | 'specific';

    custom_location?: string;
    freezer?: string;
    cabinet?: string;
    shelf?: string;
    rack?: string;
    well?: string;
    slot?: string;
}

const ClearLocation: Partial<ManualLocationWorkflowInput> = {
    custom_location: '',
    barcode: '',
    freezer: '',
    cabinet: '',
    shelf: '',
    rack: '',
    well: '',
    slot: '',
};

const ClearLocationPartial: Partial<ManualLocationWorkflowInput> = {
    custom_location: '',
    barcode: '',
    well: '',
};

class ManualLocationWorkflow implements ECMManualWorkflowBase<PrepareLocationEntry> {
    inputs = {
        barcode: { current: null } as MutableRefObject<HTMLInputElement | null>,
        custom_location: { current: null } as MutableRefObject<HTMLInputElement | null>,
        cabinet: { current: null } as MutableRefObject<HTMLInputElement | null>,
        freezer: { current: null } as MutableRefObject<HTMLInputElement | null>,
        shelf: { current: null } as MutableRefObject<HTMLInputElement | null>,
        rack: { current: null } as MutableRefObject<HTMLInputElement | null>,
        well: { current: null } as MutableRefObject<HTMLInputElement | null>,
        slot: { current: null } as MutableRefObject<HTMLInputElement | null>,
    };

    state = {
        input: new BehaviorSubject<ManualLocationWorkflowInput>({
            barcode: '',
            kind: 'vial',
            vialKind: 'lab',
            plateKind: 'none',
            ...ClearLocation,
        }),
        entry: new BehaviorSubject<PrepareLocationEntry | undefined>(undefined),
        autosubmit: new BehaviorSubject<boolean>(false),
        preparing: new BehaviorSubject<boolean>(false),
        submitting: new BehaviorSubject<boolean>(false),
        keepLocationFields: new BehaviorSubject<boolean>(true),
    };

    log = new LogModel();

    get autosubmit() {
        return this.state.autosubmit.value;
    }

    get isInputValid(): boolean {
        // TODO: this could be improved
        const input = this.state.input.value;

        const validVial =
            input.vialKind === 'lab' ||
            input.vialKind === 'verso' ||
            (input.vialKind === 'custom' && input.custom_location) ||
            (input.cabinet && input.shelf && input.rack);
        const validPlate = input.plateKind === 'none' || (input.freezer && input.shelf && input.rack && input.slot);

        return (
            input.barcode.length > 0 &&
            ((input.kind === 'vial' && !!validVial) || (input.kind === 'plate' && !!validPlate))
        );
    }

    private get vialInput(): PrepareLocationInput {
        const input = this.state.input.value;

        if (input.vialKind === 'lab' || input.vialKind === 'verso') {
            return {
                kind: 'vial',
                barcode: input.barcode,
                location: input.vialKind,
            };
        }

        if (input.vialKind === 'custom') {
            return {
                kind: 'vial',
                custom_location: input.custom_location,
            };
        }

        return {
            kind: 'vial',
            barcode: input.barcode,
            cabinet: input.cabinet,
            shelf: input.shelf,
            rack: input.rack,
            well: input.well,
        };
    }

    private get plateInput(): PrepareLocationInput {
        const input = this.state.input.value;

        if (input.plateKind === 'none')
            return {
                kind: 'plate',
                barcode: input.barcode,
            };

        return {
            kind: 'plate',
            barcode: input.barcode,
            freezer: input.freezer,
            shelf: input.shelf,
            rack: input.rack,
            slot: input.slot,
        };
    }

    async prepare() {
        ToastService.remove('warn-autosubmit');

        if (!this.isInputValid) {
            return;
        }

        try {
            this.state.preparing.next(true);
            const input = this.state.input.value;
            const entries = await LocationAPI.prepare([
                {
                    barcode: input.barcode,
                    ...(input.kind === 'vial' ? this.vialInput : this.plateInput),
                },
            ]);

            const entry = entries[0];
            this.state.entry.next(entry);
            if (!entry) return;

            if (!entry.vial && !entry.plate) {
                requestAnimationFrame(() => this.inputs.barcode.current?.focus());
                return;
            }

            if (this.autosubmit && entry.errors.length === 0 && entry.warnings.length === 0) {
                this.submit();
                return;
            }

            if (this.autosubmit && entry.errors.length === 0 && entry.warnings.length > 0) {
                ToastService.show({
                    id: 'warn-autosubmit',
                    type: 'warning',
                    message: 'Auto-submit paused due to a warning.\nPlease fix the issue or submit manually.',
                });
            }
        } catch (err) {
            reportErrorAsToast('Location', err);
            this.state.entry.next(undefined);
        } finally {
            this.state.preparing.next(false);
        }
    }

    async submit() {
        const entry = this.state.entry.value;
        if (!entry) return;

        try {
            this.state.submitting.next(true);

            await LocationAPI.upload([entry]);
            // NOTE: For testing to fake upload
            // await new Promise((res) => {
            //     setTimeout(res, 500);
            // });

            this.log.message(
                'success',
                `${entry.vial ? 'Vial' : 'Plate'} ${entry.input.barcode} location updated to ${
                    entry.vial_location
                        ? formatLocation(entry.vial_location)
                        : formatLocation(entry.plate_location!) || 'None'
                }.`
            );
            this.state.input.next({
                ...this.state.input.value,
                ...(this.state.keepLocationFields.value ? ClearLocationPartial : ClearLocation),
            });
            this.state.entry.next(undefined);
            requestAnimationFrame(() => this.inputs.barcode.current?.focus());
        } catch (err) {
            this.log.message('danger', `${entry?.input.barcode} error: ${tryGetErrorMessage(err)}`);
        } finally {
            this.state.submitting.next(false);
        }
    }
}

class BatchLocationWorkflow implements ECMBatchWorkflowBase<PrepareLocationEntries> {
    state = {
        submitting: new BehaviorSubject<boolean>(false),
    };
    entries: PrepareLocationEntries;
    summary: ECMBatchWorkflowBase['summary'];
    messages: ECMBatchWorkflowBase['messages'];

    async submit() {
        if (this.summary.numErrors > 0 || !this.entries.length) {
            return;
        }

        try {
            this.state.submitting.next(true);

            await LocationAPI.upload(this.entries);
            // NOTE: For testing to fake upload
            // await new Promise((res) => {
            //     setTimeout(res, 500);
            // });

            this.fileSubject.next(null);
            ToastService.show({
                type: 'success',
                message: `Successfully updated locations of ${this.entries.length} vials/plates.`,
            });
        } catch (err) {
            reportErrorAsToast('Location', err);
        } finally {
            this.state.submitting.next(false);
        }
    }

    constructor(
        public files: [filename: string, entries: PrepareLocationEntries][],
        private fileSubject: BehaviorSubject<File[] | null>
    ) {
        this.entries = files.flatMap((f) => f[1]);
        this.summary = ecmWorkflowEntrySummary(this.entries);
        this.messages = ecmGatherMessages(files);
    }
}

export function ECMLocation() {
    const [mode, setMode] = useState<ECMWorkflowMode>('manual');

    return (
        <ECMPageTemplate page='location' withFooter>
            <ECMWorkflowModeSelect mode={mode} setMode={setMode} />
            {mode === 'manual' && <ManualRoot />}
            {mode === 'batch' && <BatchRoot />}
        </ECMPageTemplate>
    );
}

const ManualModel = new ManualLocationWorkflow();

function ManualRoot() {
    const model = ManualModel;

    return (
        <ECMManualWorkflowWrapper
            model={model}
            input={<ManualInput model={model} />}
            status={<ManualStatus model={model} />}
        />
    );
}

function ManualInput({ model }: { model: ManualLocationWorkflow }) {
    const preparing = useBehavior(model.state.preparing);
    const submitting = useBehavior(model.state.submitting);
    const disabled = preparing || submitting;

    const input = useBehavior(model.state.input);
    const keepLocation = useBehavior(model.state.keepLocationFields);

    return (
        <ECMManualWorkflowInputHelper model={model} disabled={disabled} preparing={preparing}>
            <LabeledInput label='Kind' className='ecm-manual-inputs-row'>
                <ButtonGroup className='w-100'>
                    <Button
                        variant={input.kind === 'vial' ? 'primary' : 'outline-primary'}
                        onClick={() => {
                            model.state.input.next({ ...input, kind: 'vial' });
                            model.inputs.barcode.current?.focus();
                        }}
                    >
                        Vial
                    </Button>
                    <Button
                        variant={input.kind === 'plate' ? 'primary' : 'outline-primary'}
                        onClick={() => {
                            model.state.input.next({ ...input, kind: 'plate' });
                            model.inputs.barcode.current?.focus();
                        }}
                    >
                        Plate
                    </Button>
                </ButtonGroup>
            </LabeledInput>
            {input.kind === 'vial' && <VialInputKind input={input} disabled={disabled} model={model} />}
            {input.kind === 'plate' && <PlateInputKind input={input} disabled={disabled} model={model} />}
            <LabeledInput label='Barcode' className='ecm-manual-inputs-row'>
                <Form.Control
                    value={input.barcode}
                    ref={model.inputs.barcode}
                    onChange={(e) => model.state.input.next({ ...input, barcode: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.barcode) {
                            if (input.kind === 'vial') {
                                if (input.vialKind === 'custom') {
                                    model.inputs.custom_location.current?.focus();
                                } else if (input.vialKind !== 'specific') {
                                    model.prepare();
                                } else {
                                    model.inputs.cabinet.current?.focus();
                                }
                            } else if (input.plateKind === 'none' || (input.freezer && keepLocation)) {
                                model.prepare();
                            } else {
                                model.inputs.freezer.current?.focus();
                            }
                        }
                    }}
                    disabled={disabled}
                    autoFocus
                />
            </LabeledInput>
            {input.kind === 'vial' && input.vialKind === 'specific' && (
                <VialInputFields input={input} disabled={disabled} model={model} />
            )}
            {input.kind === 'vial' && input.vialKind === 'custom' && (
                <VialCustomInputFields input={input} disabled={disabled} model={model} />
            )}
            {input.kind === 'plate' && input.plateKind === 'specific' && (
                <PlateInputFields input={input} disabled={disabled} model={model} />
            )}
        </ECMManualWorkflowInputHelper>
    );
}

function VialInputKind({
    input,
    disabled,
    model,
}: {
    input: ManualLocationWorkflowInput;
    disabled: boolean;
    model: ManualLocationWorkflow;
}) {
    return (
        <LabeledInput label='Location' className='ecm-manual-inputs-row'>
            <ButtonGroup className='w-100'>
                <Button
                    variant={input.vialKind === 'lab' ? 'primary' : 'outline-primary'}
                    onClick={() => {
                        model.state.input.next({ ...input, vialKind: 'lab' });
                        model.inputs.barcode.current?.focus();
                    }}
                    disabled={disabled}
                >
                    Lab
                </Button>
                <Button
                    variant={input.vialKind === 'verso' ? 'primary' : 'outline-primary'}
                    onClick={() => {
                        model.state.input.next({ ...input, vialKind: 'verso' });
                        model.inputs.barcode.current?.focus();
                    }}
                    disabled={disabled}
                >
                    Verso
                </Button>
                <Button
                    variant={input.vialKind === 'specific' ? 'primary' : 'outline-primary'}
                    onClick={() => {
                        model.state.input.next({ ...input, vialKind: 'specific' });
                        model.inputs.barcode.current?.focus();
                    }}
                    disabled={disabled}
                >
                    Spec.
                </Button>
                <Button
                    variant={input.vialKind === 'custom' ? 'primary' : 'outline-primary'}
                    onClick={() => {
                        model.state.input.next({ ...input, vialKind: 'custom' });
                        model.inputs.barcode.current?.focus();
                    }}
                    disabled={disabled}
                >
                    Custom
                </Button>
            </ButtonGroup>
        </LabeledInput>
    );
}

function VialCustomInputFields({
    input,
    disabled,
    model,
}: {
    input: ManualLocationWorkflowInput;
    disabled: boolean;
    model: ManualLocationWorkflow;
}) {
    return (
        <>
            <LabeledInput label='Custom Location' className='ecm-manual-inputs-row'>
                <Form.Control
                    value={input.custom_location}
                    ref={model.inputs.custom_location}
                    onChange={(e) => model.state.input.next({ ...input, custom_location: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.custom_location) model.prepare();
                    }}
                    disabled={disabled}
                />
            </LabeledInput>
        </>
    );
}

function VialInputFields({
    input,
    disabled,
    model,
}: {
    input: ManualLocationWorkflowInput;
    disabled: boolean;
    model: ManualLocationWorkflow;
}) {
    const keepLocation = useBehavior(model.state.keepLocationFields);

    return (
        <>
            <LabeledInput label='' className='ecm-manual-inputs-row'>
                <Form.Check
                    type='switch'
                    id='keep-location-switch'
                    label='Remember location'
                    checked={keepLocation}
                    disabled={disabled}
                    onChange={(e) => model.state.keepLocationFields.next(!!e.target.checked)}
                />
            </LabeledInput>
            <LabeledInput label='Cab/Frzr/Fridge' className='ecm-manual-inputs-row' tooltip='CAB/FRZ/RFG prefix'>
                <Form.Control
                    value={input.cabinet}
                    placeholder='e.g. CAB01 or FRZ01 or RFG01'
                    ref={model.inputs.cabinet}
                    onChange={(e) => model.state.input.next({ ...input, cabinet: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.cabinet) model.inputs.shelf.current?.focus();
                    }}
                    disabled={disabled}
                />
            </LabeledInput>
            <LabeledInput label='Shelf/Frzr Rack' className='ecm-manual-inputs-row' tooltip='SHE/FRZR prefix'>
                <Form.Control
                    value={input.shelf}
                    placeholder='e.g. SHE01 or FRZR01'
                    ref={model.inputs.shelf}
                    onChange={(e) => model.state.input.next({ ...input, shelf: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.shelf) model.inputs.rack.current?.focus();
                    }}
                    disabled={disabled}
                />
            </LabeledInput>
            <LabeledInput label='Rack' className='ecm-manual-inputs-row' tooltip='BIN/ENR prefix'>
                <Form.Control
                    value={input.rack}
                    placeholder='e.g. BIN01 or ENR0000002'
                    ref={model.inputs.rack}
                    onChange={(e) => model.state.input.next({ ...input, rack: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.rack) model.inputs.well.current?.focus();
                    }}
                    disabled={disabled}
                />
            </LabeledInput>
            <LabeledInput label='Well' className='ecm-manual-inputs-row' tooltip='Optional rack well (e.g. A1)'>
                <Form.Control
                    value={input.well}
                    placeholder='e.g. A1'
                    ref={model.inputs.well}
                    onChange={(e) => model.state.input.next({ ...input, well: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') model.prepare();
                    }}
                    disabled={disabled}
                />
            </LabeledInput>
        </>
    );
}

function PlateInputKind({
    input,
    disabled,
    model,
}: {
    input: ManualLocationWorkflowInput;
    disabled: boolean;
    model: ManualLocationWorkflow;
}) {
    return (
        <LabeledInput label='Location' className='ecm-manual-inputs-row'>
            <ButtonGroup className='w-100'>
                <Button
                    variant={input.plateKind === 'none' ? 'primary' : 'outline-primary'}
                    onClick={() => {
                        model.state.input.next({ ...input, plateKind: 'none' });
                        model.inputs.barcode.current?.focus();
                    }}
                    disabled={disabled}
                >
                    None
                </Button>
                <Button
                    variant={input.plateKind === 'specific' ? 'primary' : 'outline-primary'}
                    onClick={() => {
                        model.state.input.next({ ...input, plateKind: 'specific' });
                        model.inputs.barcode.current?.focus();
                    }}
                    disabled={disabled}
                >
                    Specific
                </Button>
            </ButtonGroup>
        </LabeledInput>
    );
}

function PlateInputFields({
    input,
    disabled,
    model,
}: {
    input: ManualLocationWorkflowInput;
    disabled: boolean;
    model: ManualLocationWorkflow;
}) {
    const keepLocation = useBehavior(model.state.keepLocationFields);

    return (
        <>
            <LabeledInput label='' className='ecm-manual-inputs-row'>
                <Form.Check
                    type='switch'
                    id='keep-location-switch'
                    label='Remember location'
                    checked={keepLocation}
                    disabled={disabled}
                    onChange={(e) => model.state.keepLocationFields.next(!!e.target.checked)}
                />
            </LabeledInput>
            <LabeledInput label='Freezer/Fridge' className='ecm-manual-inputs-row' tooltip='FRZ/RFG prefix'>
                <Form.Control
                    value={input.freezer}
                    placeholder='e.g. FRZ01 or RFG01'
                    ref={model.inputs.freezer}
                    onChange={(e) => model.state.input.next({ ...input, freezer: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.freezer) model.inputs.shelf.current?.focus();
                    }}
                    disabled={disabled}
                />
            </LabeledInput>
            <LabeledInput label='Shelf' className='ecm-manual-inputs-row' tooltip='SHE prefix'>
                <Form.Control
                    value={input.shelf}
                    placeholder='e.g. SHE01'
                    ref={model.inputs.shelf}
                    onChange={(e) => model.state.input.next({ ...input, shelf: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.shelf) model.inputs.rack.current?.focus();
                    }}
                    disabled={disabled}
                />
            </LabeledInput>
            <LabeledInput label='Rack' className='ecm-manual-inputs-row' tooltip='ENR prefix'>
                <Form.Control
                    value={input.rack}
                    placeholder='e.g. ENR01'
                    ref={model.inputs.rack}
                    onChange={(e) => model.state.input.next({ ...input, rack: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.rack) model.inputs.well.current?.focus();
                    }}
                    disabled={disabled}
                />
            </LabeledInput>
            <LabeledInput label='Slot' className='ecm-manual-inputs-row' tooltip='Single letter A-E'>
                <Form.Control
                    value={input.slot}
                    ref={model.inputs.slot}
                    placeholder='e.g. A'
                    onChange={(e) => model.state.input.next({ ...input, slot: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.slot) model.prepare();
                    }}
                    disabled={disabled}
                />
            </LabeledInput>
        </>
    );
}

function ManualStatus({ model }: { model: ManualLocationWorkflow }) {
    return (
        <ECMManualWorkflowStatus model={model}>
            {(entry) => (
                <>
                    <PropertyNameValue field='Barcode' value={entry.input.barcode} />
                    {!!entry.vial && (
                        <>
                            <PropertyNameValue field='Vial Status' value={entry.vial.status} />
                            <PropertyNameValue field='Old Location' value={formatLocation(entry.vial.location)} />
                            <PropertyNameValue
                                field='New Location'
                                value={entry.vial_location ? formatLocation(entry.vial_location) : 'n/a'}
                            />
                        </>
                    )}
                    {!!entry.plate && (
                        <>
                            <PropertyNameValue field='Plate Status' value={entry.plate.status} />
                            <PropertyNameValue field='Old Location' value={formatLocation(entry.plate.location)} />
                            <PropertyNameValue
                                field='New Location'
                                value={entry.plate_location ? formatLocation(entry.plate_location) : ''}
                            />
                        </>
                    )}
                </>
            )}
        </ECMManualWorkflowStatus>
    );
}

async function uploadCSV(
    files: File[] | null,
    fileSubject: BehaviorSubject<File[] | null>,
    overridesSubject?: BehaviorSubject<Record<string, any>>
) {
    return uploadECMCSV(LocationAPI, BatchLocationWorkflow, files, fileSubject, overridesSubject);
}

function BatchRoot() {
    const [model, loadModel] = useAsyncAction<BatchLocationWorkflow | undefined>();

    const upload = useCallback(
        (
            files: File[] | null,
            fileSubject: BehaviorSubject<File[] | null>,
            overridesSubject?: BehaviorSubject<Record<string, any>>
        ) => loadModel(uploadCSV(files, fileSubject, overridesSubject)),
        [loadModel]
    );
    const info = useMemo(() => <BatchUploadInfo />, []);

    return <ECMBatchWorkflowWrapper model={model} upload={upload} info={info} Overrides={BatchUploadOverrides} />;
}

function BatchUploadOverrides({ overridesSubject }: { overridesSubject: BehaviorSubject<Record<string, any>> }) {
    const overrides = useBehavior(overridesSubject);

    return (
        <div className='ecm-overrides-panel m-auto mt-4'>
            <h5>Global Location Values</h5>
            <span className='text-secondary'>
                Any values uploaded from a file will be overwritten by the following entries.
            </span>
            <div className='ecm-manual-inputs mt-1'>
                <LabeledInput label='Location' className='ecm-manual-inputs-row'>
                    <ButtonGroup className='w-100'>
                        <Button
                            variant={overrides.Location === 'lab' ? 'primary' : 'outline-primary'}
                            onClick={() => overridesSubject.next({ Location: 'lab' })}
                        >
                            Lab
                        </Button>
                        <Button
                            variant={overrides.Location === 'verso' ? 'primary' : 'outline-primary'}
                            onClick={() => overridesSubject.next({ Location: 'verso' })}
                        >
                            Verso
                        </Button>
                        <Button
                            variant={!overrides.Location ? 'primary' : 'outline-primary'}
                            onClick={() => overridesSubject.next({})}
                        >
                            Specific
                        </Button>
                    </ButtonGroup>
                </LabeledInput>
                {!overrides.Location && (
                    <LabeledInput label='Cab/Frzr/Fridge' className='ecm-manual-inputs-row' tooltip='Optional'>
                        <TextInput
                            value={overrides.Cabinet ?? ''}
                            formatValue={trimValue}
                            placeholder='e.g. CAB01 or FRZ01 or RFG01'
                            setValue={(v) => {
                                const next = { ...overrides, Cabinet: v || undefined };
                                if (!v) delete next.Cabinet;
                                overridesSubject.next(next);
                            }}
                        />
                    </LabeledInput>
                )}
                {!overrides.Location && (
                    <LabeledInput label='Shelf/Frzr Rack' className='ecm-manual-inputs-row' tooltip='Optional'>
                        <TextInput
                            value={overrides.Shelf ?? ''}
                            formatValue={trimValue}
                            placeholder='e.g. SHE01 or FRZR01'
                            setValue={(v) => {
                                const next = { ...overrides, Shelf: v || undefined };
                                if (!v) delete next.Shelf;
                                overridesSubject.next(next);
                            }}
                        />
                    </LabeledInput>
                )}
                {!overrides.Location && (
                    <LabeledInput label='Rack' className='ecm-manual-inputs-row' tooltip='Optional'>
                        <TextInput
                            value={overrides.Rack ?? ''}
                            formatValue={trimValue}
                            placeholder='e.g. BIN01 or ENR0000002'
                            setValue={(v) => {
                                const next = { ...overrides, Rack: v || undefined };
                                if (!v) delete next.Rack;
                                overridesSubject.next(next);
                            }}
                        />
                    </LabeledInput>
                )}
            </div>
        </div>
    );
}

function BatchUploadInfo() {
    return (
        <>
            <ECMCommonUploadInfo
                title='Vials CSV/XLS Upload'
                required={['Barcode']}
                optional={[
                    'Location (lab/verso/<optional>)',
                    'Cabinet (CAB/FRZ/RFG prefix)',
                    'Shelf (SHE/FRZR prefix)',
                    'Rack OR Rack Barcode (ENR/BIN prefix)',
                    'Well (rack well/<optional>)',
                    'Custom Location',
                ]}
            />
            <ECMCommonUploadInfo
                title='Plates CSV/XLS Upload'
                required={['Barcode']}
                optional={[
                    'Freezer (FRZ/RFG prefix)',
                    'Shelf (SHE prefix)',
                    'Rack OR Rack Barcode (ENR/BIN prefix)',
                    'Slot (=A-F)',
                ]}
            />
        </>
    );
}
