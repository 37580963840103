import { IconName, IconDefinition } from '@fortawesome/fontawesome-svg-core';

// This is a way to make a new icon to be treated like icons in the
// Font Awesome set - structure kind of copied from their icon definitions.
// see node_modules/@fortawesome/node_modules/@fortawesome/fontawesome-common-types/index.d.ts
// for the IconDefinition type definition

const iconName = 'ii-lasso' as IconName;
const prefix = 'fas';
const width: number = 25;
const height: number = 25;
const aliases: string[] = [];
const unicode: string = '';
const svgPathData: string =
    'M12.5957 2.05859C18.0957 2.05859 22.5957 5.18859 22.5957 9.05859C22.5957 12.3186 19.4057 15.0586 15.0957 15.8386V15.5586C15.0957 14.9686 14.9957 14.3986 14.8057 13.8686C18.1457 13.2686 20.5957 11.3386 20.5957 9.05859C20.5957 6.29859 17.0157 4.05859 12.5957 4.05859C8.1757 4.05859 4.5957 6.29859 4.5957 9.05859C4.5957 10.2486 5.2657 11.3486 6.3857 12.2086C5.9457 12.6986 5.5957 13.2686 5.3757 13.9086C3.6557 12.6486 2.5957 10.9386 2.5957 9.05859C2.5957 5.18859 7.0957 2.05859 12.5957 2.05859ZM10.0957 12.0586C12.0257 12.0586 13.5957 13.6286 13.5957 15.5586C13.5957 17.4586 12.0957 19.0086 10.1957 19.0586C9.9857 19.4186 9.7757 20.0586 10.4257 20.7386C11.5957 21.9386 13.8757 19.7786 16.9857 19.7686C19.0257 19.7586 20.6257 20.0286 20.6257 20.0286C20.6257 20.0286 21.6757 20.1586 21.5657 21.0986C21.4557 22.0286 20.5057 22.0286 20.5057 22.0286C20.1257 21.9886 18.6257 21.6386 16.8157 21.7386C15.0057 21.8286 14.0657 22.4686 13.1557 22.7486C12.2557 23.0286 10.5057 23.4386 8.8957 22.1086C7.5657 21.0186 8.0557 19.1686 8.2657 18.5586C7.2657 17.9286 6.5957 16.8186 6.5957 15.5586C6.5957 13.6286 8.1657 12.0586 10.0957 12.0586ZM10.0957 14.0586C9.2657 14.0586 8.5957 14.7286 8.5957 15.5586C8.5957 16.3886 9.2657 17.0586 10.0957 17.0586C10.9257 17.0586 11.5957 16.3886 11.5957 15.5586C11.5957 14.7286 10.9257 14.0586 10.0957 14.0586Z';

export const iiLasso: IconDefinition = {
    prefix,
    iconName,
    icon: [width, height, aliases, unicode, svgPathData],
};
