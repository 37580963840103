/* eslint-disable react/function-component-definition */
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useMemo } from 'react';
import { Popover } from 'react-bootstrap';
import { columnNameToHeader } from '../../api/data';
import {
    Column,
    ColumnFilterControl,
    ColumnFilterTypeMap,
    DataTableModel,
    DataTableStore,
    determineColumnType,
} from '../../components/DataTable';
import { BaseBetweenColumnFilter, DataTableValueColumnFilter } from '../../components/DataTable/Filters';
import { HoverLink, TableCellPopover } from '../../components/DataTable/common';
import { getAssayIdFromShorthand, getLinkToAssayFromCompound } from '../../pages/Assays/assay-api';
import { arrayMinMax, isBlank } from '../util/misc';
import { AssayValueView, formatPotensName } from './display';
import type { AssayValueType } from './models';
import {
    AssayFormattingOptions,
    assayValueTypeCompare,
    assayValueTypeToCsvString,
    assayValueTypeToString,
    isInequalityValue,
    tryGetAssayValueGuess,
} from './util';
import { ErrorIconWithMessage, ErrorWithMessage, isErrorWithMessage } from '../../components/common/Error';

export function AssayValueTypeColumn(options: { columnName: string }): Column<AssayValueType, {}> {
    const isPotens = isPotensColumn(options.columnName);
    return {
        ...Column.obj({
            // NOTE: format function is currently unused as the values are converted and rendered inside AssayValueCell
            format: (v) => '<unused>',
            compare: assayValueTypeCompare,
            // TODO: should we support potens formatting here?
            //       if so, also need dynamically customizable headers
            csvFormat: assayValueTypeToCsvString,
        }),
        filterFn: (table) => (v: AssayValueType, test: any) => {
            const asNM = isPotens && !!table.state.customState[PotensAsNmProp];
            if (typeof v === 'boolean') {
                let boolTest;
                if (test === '0') boolTest = 'false';
                else if (test === '1') boolTest = 'true';
                else boolTest = test;
                return ColumnFilterTypeMap.includes(v, boolTest);
            }
            const value = tryGetAssayValueGuess(v, asNM);
            if (typeof value === 'number') {
                return ColumnFilterTypeMap.between(value, test);
            }
            return false;
        },
        width: 175,
        filterControl: AssayValueFilter,
        header: (tbl, { columnName }) => (
            <AssayValueHeader table={tbl} columnName={options?.columnName ?? columnName} columnId={columnName} />
        ),
        render: AssayValueCell,
    };
}

export function AssayValueHeader({
    table,
    columnName,
    columnId,
}: {
    table: DataTableModel;
    columnName: string;
    columnId: string;
}) {
    const units = table.state.customState.units?.[columnId];
    const isPotens = isPotensColumn(columnName) || units === 'potens';
    const convertPotensToNm = table.state.customState[PotensAsNmProp];
    const displayUnits = units === 'potens' && convertPotensToNm ? 'nM' : units;

    let displayName: ReactNode;

    if (isPotens) {
        displayName = formatPotensName(columnName, { asNM: convertPotensToNm });
    } else {
        displayName = columnNameToHeader(columnName);
    }

    return (
        <span className='ps-1 overflow-hidden table-control-header-title'>
            {displayName} {units && `(${displayUnits})`}
        </span>
    );
}

function AssayValueCell({
    value,
    rowIndex,
    columnName,
    table,
}: {
    value: AssayValueType | ErrorWithMessage;
    rowIndex: number;
    columnName: string;
    table: DataTableModel<any, any>;
}) {
    if (isBlank(value)) return null;
    if (isErrorWithMessage(value)) return <ErrorIconWithMessage value={value as ErrorWithMessage} />;
    const assayValue = value as AssayValueType;

    let averaged = false;
    let indices: number[] = [];
    let values: any[] = [];
    const assayDataStore: DataTableStore = table.context['assay-data-store'];
    const valueIndices = table.context['assay-data-store-value-indices'];
    const hasAssayShorthandColumn = table.store.hasColumn(AssayShorthandColumnName);
    const assayShorthand = hasAssayShorthandColumn
        ? table.store.getValue(AssayShorthandColumnName, rowIndex)
        : String(columnName);
    const isPotens = isPotensColumn(assayShorthand);
    const asNM = isPotens && table.state.customState[PotensAsNmProp];
    // Note: If the table has an assay shorthand column (compound details assays tab),
    // the column names are not just batch identifiers, but "CMPDXXXX batch_kind" -
    // so to get just the identifier we need only the first part of the column name
    const identifier: string = hasAssayShorthandColumn
        ? columnName.split(' ')[0]
        : table.store.tryGetValue('batch_identifier', rowIndex);
    const assayLink: string =
        table.state.customState['show-assay-links'] && Number.isFinite(getAssayIdFromShorthand(assayShorthand))
            ? getLinkToAssayFromCompound(identifier, assayShorthand)
            : '';

    if (assayDataStore?.hasColumn(assayShorthand)) {
        if (identifier) {
            if (valueIndices.has(identifier)) {
                indices = valueIndices.get(identifier);
                values = assayDataStore
                    .getColumnValues(assayShorthand, indices)
                    .filter((v) => !Number.isNaN(v) && v !== null && v !== undefined);
                averaged = values.length > 1;
            }
        }
    }

    const options: AssayFormattingOptions = { asNM, formatting: table.state.columnFormatting[columnName] };
    const inner: ReactNode = <AssayValueView value={assayValue} options={options} />;

    if (!averaged || !assayDataStore) {
        return <HoverLink href={assayLink} content={inner} fill />;
    }

    return (
        <ImputedValuePopover
            columnName={assayShorthand}
            rowIndex={rowIndex}
            values={values}
            identifier={identifier}
            buttonContent={<span>{inner}</span>}
            assayLink={assayLink}
            options={options}
        />
    );
}

function ImputedValuePopover({
    columnName,
    rowIndex,
    values,
    identifier,
    buttonContent,
    assayLink,
    options,
}: {
    columnName: string;
    rowIndex: number;
    values: readonly any[];
    identifier: string;
    buttonContent: ReactNode;
    assayLink: string;
    options: AssayFormattingOptions;
}) {
    // Current logic for whether a value was included in the average or not is:
    // If ALL values are InequalityValues, no values are excluded
    // Otherwise, inequalities are excluded
    const inequalities = values.filter((v) => isInequalityValue(v));
    const allInequalities = values.length === inequalities.length;
    const numIncluded = inequalities.length === values.length ? values.length : values.length - inequalities.length;

    return (
        <HoverLink
            href={assayLink}
            fill
            content={
                <TableCellPopover
                    id={`${columnName}-${rowIndex}`}
                    buttonClassName='entos-averaged-assay-value'
                    buttonContent={buttonContent}
                    popoverHeader={
                        <Popover.Header>
                            <div className='font-body-small'>
                                <div>{identifier} imputation details</div>
                                <div className='text-secondary font-body-xsmall'>
                                    {numIncluded} of {values.length} values included in average
                                </div>
                                <ImputationInfoLink />
                            </div>
                        </Popover.Header>
                    }
                    popoverBody={
                        <Popover.Body className='entos-imputation-popover'>
                            {values.map((v, i) => (
                                <p
                                    key={`${columnName}-${rowIndex}-${i}`}
                                    className={`${!allInequalities && isInequalityValue(v) ? 'text-secondary' : ''}`}
                                >
                                    {assayValueTypeToString(v, options)}
                                    {!allInequalities && isInequalityValue(v) && (
                                        <FontAwesomeIcon icon={faEyeSlash} className='ms-2' fixedWidth />
                                    )}
                                </p>
                            ))}
                        </Popover.Body>
                    }
                />
            }
        />
    );
}

const AssayValueFilter: ColumnFilterControl = ({ table, columnName }) => {
    const col = useMemo(() => determineColumnType(table.store.getColumnValues(columnName)), [columnName]);

    if (col?.kind === 'bool') return <DataTableValueColumnFilter table={table} columnName={columnName} />;

    return <DataTableAssayValueBetweenColumnFilter table={table} columnName={columnName} />;
};

const DataTableAssayValueBetweenColumnFilter: ColumnFilterControl = ({ table, columnName }) => {
    const [min, max] = useMemo(() => {
        const col = table.store.getColumnValues(columnName);
        const isPotens = isPotensColumn(columnName);
        const asNM = isPotens && !!table.state.customState[PotensAsNmProp];
        const valueGuess = col
            .map((v) => {
                // tryGetAssayValueGuess returns booleans as undefined
                // which matches Foundry behavior, but we want to treat
                // them as 0/1 here
                if (typeof v === 'boolean') return v ? 1 : 0;
                return tryGetAssayValueGuess(v, asNM);
            })
            .filter((v) => typeof v === 'number');
        return arrayMinMax(valueGuess as number[]);
    }, [table, table.version.value, columnName]);

    return (
        <BaseBetweenColumnFilter
            table={table}
            columnName={columnName}
            min={`${min.toFixed(2)}`}
            max={`${max.toFixed(2)}`}
        />
    );
};

export const AssayShorthandColumnName = 'assay_shorthand';
export const PotensAsNmProp = 'potens-as-nm';

const PotensShorthands = [', pIC50', ', pEC50', ', pKi', ', pKd'];

export function isPotensColumn(columnName: string | undefined) {
    if (!columnName) return false;

    // hack to prevent ligand efficiency being converted
    // in the transposed assay table on the assays tab
    // of the compound details page
    if (columnName.startsWith('LE:')) return false;

    // NOTE: compute endpoint passes the unit type for Box results
    //       we should improve how unit info is passed from the backend
    //       but this hack should do for most use-cases currently
    for (const sh of PotensShorthands) {
        if (columnName.includes(sh)) return true;
    }
    return false;
}

export function ImputationInfoLink() {
    return (
        <div>
            <a
                href='https://entos.atlassian.net/wiki/spaces/DDP/pages/2087846212/UQ+Data+Imputation#Imputation'
                rel='noopener noreferrer'
                target='_blank'
            >
                How do we do imputation?
            </a>
        </div>
    );
}
