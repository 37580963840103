import { Route, Routes } from 'react-router-dom';
import { ChemCartOrdersLayoutRoot, ChemCartOrderDetails } from './chemcart';

export function ECMOrdersRoot() {
    return (
        <Routes>
            <Route path='/chemcart/:id' element={<ChemCartOrderDetails />} />
            <Route path='/chemcart' element={<ChemCartOrdersLayoutRoot />} />
        </Routes>
    );
}
