import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Spinner, Button } from 'react-bootstrap';
import { formatDictionary } from '../../../components/common/formatDictionary';
import {
    Column,
    columnDataTableStore,
    columnDataTableStoreFromObjects,
    ColumnsFor,
    DataTableControl,
    DataTableModel,
} from '../../../components/DataTable';
import { TableCellPopover } from '../../../components/DataTable/common';
import useBehavior from '../../../lib/hooks/useBehavior';
import { DateLike, formatDatetime, parseFileSystemDate } from '../../../lib/util/dates';
import { isBlank } from '../../../lib/util/misc';
import { HTESignature } from '../experiment-data';
import { HTEDiffRow, HTEDiffSnapshot, HTEHistoryEntry, HTEReview } from './sign-data';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { HTEApi } from '../experiment-api';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';

export interface HTEHistoryRow {
    snapshot_id: number;
    review?: HTEReview;
    created_on: DateLike;
    diffs: HTEDiffRow[] | 'initial';
    signature?: HTESignature;
    counter_signature?: HTESignature;
    diff?: HTEDiffSnapshot;
    pdf: number[];
}
export function DownloadPDFButton({ ids }: { ids: any }) {
    const [state, load] = useAsyncAction();
    const id = ids[0];
    const experiment_id = ids[1];
    async function download() {
        try {
            await HTEApi.downloadPDF(id, experiment_id);
        } catch (err) {
            reportErrorAsToast('Failed to download PDF report', err);
        }
    }

    return (
        <Button
            variant='link'
            className='me-1'
            title='Download PDF'
            disabled={state.isLoading}
            onClick={() => load(download())}
        >
            {!state.isLoading && <FontAwesomeIcon className='me-1' icon={faDownload} fixedWidth />}
            {state.isLoading && <Spinner animation='border' size='sm' className='text-primary me-1' role='status' />}
            Download PDF
        </Button>
    );
}

function getHistoryRows(history: HTEHistoryEntry[], experiment_id: number) {
    const rows: HTEHistoryRow[] = history.map(
        (e) =>
            ({
                snapshot_id: e.snapshot.id,
                created_on: parseFileSystemDate(e.snapshot.created_on),
                diffs: e.diff ? columnDataTableStore(e.diff.diff).toObjects() : 'initial',
                review: e.review,
                // NOTE: for testing
                // diff: new Array(20).fill(9).map((v, i) => ({ changed_field: 'test > me', changed_from: null, changed_to: { x: i } })),
                signature: e.review?.signature,
                // NOTE: for testing
                // signature: { signed_by: 'david@entos.ai', full_name: 'David Sehnal', acknowledged_policy: true, signed_on: new Date()  },
                counter_signature: e.review?.counter_signature,
                diff: e.diff,
                pdf: [e.snapshot.id, experiment_id],
            } satisfies HTEHistoryRow)
    );
    rows.sort((a, b) => +b.created_on - +a.created_on);
    return rows;
}

function historyChange(title: string, value: any, isTo = false) {
    if (isBlank(value)) return <span className={isTo ? undefined : 'text-secondary'}>null</span>;
    if (typeof value === 'string' && !value)
        return <span className={isTo ? undefined : 'text-secondary'}>«empty»</span>;
    if (typeof value === 'object') {
        return (
            <TableCellPopover
                id={title}
                buttonClassName='btn btn-link p-0 m-0 font-body-small'
                buttonContent='Click for details'
                popoverHeader={<Popover.Header>{title}</Popover.Header>}
                popoverBody={<Popover.Body>{formatDictionary(value)}</Popover.Body>}
                className='d-inline-block'
                inBody
            />
        );
    }
    return <span className={isTo ? undefined : 'text-secondary'}>{value}</span>;
}

function renderSignature(signature?: HTESignature) {
    if (!signature) return <span className='text-secondary'>Not signed</span>;

    return (
        <div className='vstack gap-1 justify-content-center'>
            <div className='fw-bold'>{signature.full_name}</div>
            <div>{formatDatetime(signature.signed_on, 'full')}</div>
        </div>
    );
}

const ExperimentHistorySchema: ColumnsFor<HTEHistoryRow> = {
    created_on: { ...Column.datetime({ format: 'full' }), compare: false, width: 200 },
    diffs: {
        ...Column.obj({
            compare: false,
            format: (_: any) => 'unused',
        }),
        header: 'Changes',
        width: 600,
        render: ({ value }) => {
            if (!Array.isArray(value) && value === 'initial') {
                return 'Initial snapshot';
            }
            if (value.length === 0) {
                return <span className='text-secondary'>No recorded changes</span>;
            }

            return (
                <div className='hte-sign-history-cell vstack gap-1 p-2'>
                    <b>
                        {value.length} change{value.length !== 1 ? 's' : ''}
                    </b>
                    {value.map((v, i) => (
                        <div key={i} className='border-top pt-1 font-body-small' style={{ whiteSpace: 'break-spaces' }}>
                            <div>{v.changed_field}</div>
                            {historyChange('Changed from', v.changed_from)} →{' '}
                            {historyChange('Changed to', v.changed_to, true)}
                        </div>
                    ))}
                </div>
            );
        },
    },
    signature: {
        ...Column.obj({
            compare: false,
            format: (_: any) => 'unused',
        }),
        width: 250,
        render: ({ value }) => renderSignature(value),
    },
    counter_signature: {
        ...Column.obj({
            compare: false,
            format: (_: any) => 'unused',
        }),
        width: 250,
        render: ({ value }) => renderSignature(value),
    },
    pdf: {
        ...Column.obj({
            format: () => '-',
            compare: false,
        }),
        width: 200,
        noHeaderTooltip: true,
        render: ({ value }) => <DownloadPDFButton ids={value} />,
    },
};

export function createHTEHistoryTable(data: HTEHistoryEntry[], experiment_id: number) {
    const rows = getHistoryRows(data, experiment_id);
    const store = columnDataTableStoreFromObjects(rows, [
        'snapshot_id',
        'created_on',
        'diffs',
        'signature',
        'counter_signature',
        'pdf',
    ]);
    const table = new DataTableModel(store, {
        columns: ExperimentHistorySchema,
        hideNonSchemaColumns: true,
    });
    table.setRowHeight(200);
    return [rows[0], table] as const;
}

export function HTEHistoryTable({ table }: { table: DataTableModel }) {
    useBehavior(table.version);

    return <DataTableControl table={table} height='flex' headerSize='sm' />;
}
