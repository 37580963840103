/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { AxiosInstance } from 'axios';
import { saveAs } from 'file-saver';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { createClient } from '../../../api';
import { EcosystemService } from '../../../lib/services/ecosystem';
import { arrayToCsv } from '../../../lib/util/arrayToCsv';
import { AsyncMoleculeDrawer } from '../../../lib/util/draw-molecules';
import { splitInput } from '../../../lib/util/misc';
import { ModelAction, ReactiveModel } from '../../../lib/util/reactive-model';
import { prefixedUnitValue } from '../../../lib/util/units';
import { RDBBatchInfo, RDBInventoryItem, RDBSearchEntry, RDBSimilarityAlgorithm, RDBSupplierBatch } from './data-model';

interface APIFilterOptions {
    catalog_name: string;
    supplier_name: string[];
    cas?: string[];
    similarity_smiles?: string;
    source_supplier_id?: string;
    substructure_smiles?: string;
    order_by_similarity?: boolean;
    similarity_algorithm?: RDBSimilarityAlgorithm;
    tanimoto_threshold?: number;
    molecular_weight_less_than_eq?: number;
    order_by?: 'molecular_weight' | 'id';
}

const RDBApi = {
    vendors: async (client: AxiosInstance): Promise<Record<string, string[]>> => {
        const { data } = await client.get('/vendors/v1');
        return data;
    },
    filter: async (
        client: AxiosInstance,
        options: APIFilterOptions,
        params: { page_size: number; offset: number }
    ): Promise<RDBSearchEntry[]> => {
        const { data } = await client.post('/batches/v1/filter', options, { params });
        return data;
    },
    count: async (client: AxiosInstance, options: APIFilterOptions): Promise<{ count: number }> => {
        const { data } = await client.post('/batches/v1/filter/count', options, { params: { limit: RDB_COUNT_LIMIT } });
        return data;
    },
};

export type ShoppingCartItem = {
    batch: RDBBatchInfo;
    supplier: RDBSupplierBatch;
    item: RDBInventoryItem;
};

export interface FilterOptions {
    catalog: string;
    suppliers: string[];
    cas: string;
    substructure_smiles: string;
    source_supplier_id: string;
    similarity_smiles: string;
    similarity_algorithm: RDBSimilarityAlgorithm;
    tanimoto_threshold: number;
    max_fw: number | null;
}

const DefaultFilterOptions: FilterOptions = {
    catalog: '',
    suppliers: [],
    cas: '',
    substructure_smiles: '',
    similarity_smiles: '',
    source_supplier_id: '',
    similarity_algorithm: 'morgan_fp',
    tanimoto_threshold: 0.7,
    max_fw: null,
};

export const RDB_PAGE_SIZE = 24;
export const RDB_COUNT_LIMIT = 1000;

const LOCAL_DEV = false;

export class ReagentDBModel extends ReactiveModel {
    client: AxiosInstance = undefined as any;
    drawer = new AsyncMoleculeDrawer();

    catalogOptions: [string, string][] = [];
    vendors: Record<string, string[]> = {};

    state = {
        filter: new BehaviorSubject<FilterOptions>(DefaultFilterOptions),
        selected: new BehaviorSubject<RDBSearchEntry | undefined>(undefined),
        highlighted: new BehaviorSubject<RDBSearchEntry | undefined>(undefined),
        shoppingCart: new BehaviorSubject<ShoppingCartItem[]>([]),
        results: new BehaviorSubject<RDBSearchEntry[][]>([]),
    };

    actions = {
        search: new ModelAction<['replace' | 'append', RDBSearchEntry[]]>({
            onError: 'toast',
            applyResult: ([action, xs]) => {
                if (action === 'replace') {
                    this.state.results.next([xs]);
                } else {
                    this.state.results.next([...this.state.results.value, xs]);
                }
            },
        }),
        count: new ModelAction<{ count: number }>({ onError: 'toast', toastErrorLabel: 'Count' }),
    };

    async init() {
        const env = await EcosystemService.getEnvironment();

        if (env.name !== 'dev') {
            throw new Error(
                'Reagent DB is currently only available in the dev environment (https://insight.dev.themachine.entos-foundry.ai/)'
            );
        }

        if (LOCAL_DEV) {
            this.client = createClient('http://127.0.0.1:8008/api');
        } else {
            this.client = createClient(
                `https://vendor-catalog.${env.name.toLowerCase()}.themachine.entos-foundry.ai/api`
            );
        }

        const vendors = await RDBApi.vendors(this.client);
        const defaultVendor = Object.entries(vendors)[0];

        this.catalogOptions = Object.keys(vendors).map((name) => [name, name]);
        this.vendors = vendors;
        this.state.filter.next({
            ...this.state.filter.value,
            catalog: defaultVendor[0],
            suppliers: defaultVendor[1],
        });
    }

    addToCart(batch: RDBSearchEntry, supplier: RDBSupplierBatch, item: RDBInventoryItem) {
        this.state.shoppingCart.next([...this.state.shoppingCart.value, { batch: batch.batch, supplier, item }]);
    }

    saveCart(csv: string) {
        saveAs(new Blob([csv], { type: 'text/csv' }), 'order.csv');
    }

    cartToCSV() {
        const rows: string[][] = [['Catalog', 'Supplier', 'Supplier ID', 'CAS', 'Amount', 'Price']];
        const cart = this.state.shoppingCart.value;

        for (const item of cart) {
            rows.push([
                item.supplier.catalog_name,
                item.supplier.supplier_name,
                item.supplier.source_supplier_id,
                item.supplier.cas ?? '',
                `${formatAmount(item.item)}`,
                `${item.item.price ?? ''} ${item.item.currency}`,
            ]);
        }

        return arrayToCsv(rows);
    }

    private getAPIFilterOptions(options: FilterOptions): APIFilterOptions {
        const cas = splitInput(options.cas);
        return {
            catalog_name: options.catalog,
            supplier_name: options.suppliers,
            cas: cas.length > 0 ? cas : undefined,
            similarity_smiles: options.similarity_smiles || undefined,
            substructure_smiles: options.substructure_smiles || undefined,
            source_supplier_id: options.source_supplier_id || undefined,
            order_by_similarity: options.similarity_smiles ? true : undefined,
            similarity_algorithm: options.similarity_algorithm,
            tanimoto_threshold: options.tanimoto_threshold,
            molecular_weight_less_than_eq: options.max_fw ?? undefined,
            order_by: 'molecular_weight',
        };
    }

    private async filter(options: APIFilterOptions, offset: number = 0) {
        const results = await RDBApi.filter(this.client, options, { page_size: RDB_PAGE_SIZE, offset });
        return [offset > 0 ? 'append' : 'replace', results] as ['append' | 'replace', RDBSearchEntry[]];
    }

    loadMore = () => {
        const offset = this.state.results.value.reduce((acc, xs) => acc + xs.length, 0);
        const options = this.getAPIFilterOptions(this.state.filter.value);
        return this.actions.search.run(this.filter(options, offset), { runningMessage: 'more' });
    };

    mount() {
        this.subscribe(this.state.filter.pipe(debounceTime(500)), (filter) => {
            this.state.selected.next(undefined);
            this.state.highlighted.next(undefined);
            const options = this.getAPIFilterOptions(filter);
            this.actions.search.run(this.filter(options));
            this.actions.count.run(RDBApi.count(this.client, options));
        });
    }
}

export function formatAmount(item: RDBInventoryItem) {
    if (item.amount_g) {
        return prefixedUnitValue(item.amount_g, 'g');
    }
    if (item.volume_l) {
        return prefixedUnitValue(item.volume_l, 'L');
    }
    return item.generic ?? 'n/a';
}
