export const K_BC_384_168c_1pt_dup_1ctrl_2pt = `ID,Point,Row,Column,IsControl
DMSO,1,1,1,True
DMSO,1,1,2,True
DMSO,1,1,3,True
DMSO,1,1,4,True
DMSO,1,1,5,True
DMSO,1,1,6,True
DMSO,1,1,7,True
DMSO,1,1,8,True
Control1,2,1,9,True
Control1,2,1,10,True
Control1,2,1,11,True
Control1,2,1,12,True
Control1,2,1,13,True
Control1,2,1,14,True
Control1,2,1,15,True
Control1,2,1,16,True
Control2,2,1,17,True
Control2,2,1,18,True
Control2,2,1,19,True
Control2,2,1,20,True
Control2,2,1,21,True
Control2,2,1,22,True
Control2,2,1,23,True
Control2,2,1,24,True
1,1,2,1,False
1,1,2,2,False
15,1,2,3,False
15,1,2,4,False
29,1,2,5,False
29,1,2,6,False
43,1,2,7,False
43,1,2,8,False
57,1,2,9,False
57,1,2,10,False
71,1,2,11,False
71,1,2,12,False
85,1,2,13,False
85,1,2,14,False
99,1,2,15,False
99,1,2,16,False
113,1,2,17,False
113,1,2,18,False
127,1,2,19,False
127,1,2,20,False
141,1,2,21,False
141,1,2,22,False
155,1,2,23,False
155,1,2,24,False
2,1,3,1,False
2,1,3,2,False
16,1,3,3,False
16,1,3,4,False
30,1,3,5,False
30,1,3,6,False
44,1,3,7,False
44,1,3,8,False
58,1,3,9,False
58,1,3,10,False
72,1,3,11,False
72,1,3,12,False
86,1,3,13,False
86,1,3,14,False
100,1,3,15,False
100,1,3,16,False
114,1,3,17,False
114,1,3,18,False
128,1,3,19,False
128,1,3,20,False
142,1,3,21,False
142,1,3,22,False
156,1,3,23,False
156,1,3,24,False
3,1,4,1,False
3,1,4,2,False
17,1,4,3,False
17,1,4,4,False
31,1,4,5,False
31,1,4,6,False
45,1,4,7,False
45,1,4,8,False
59,1,4,9,False
59,1,4,10,False
73,1,4,11,False
73,1,4,12,False
87,1,4,13,False
87,1,4,14,False
101,1,4,15,False
101,1,4,16,False
115,1,4,17,False
115,1,4,18,False
129,1,4,19,False
129,1,4,20,False
143,1,4,21,False
143,1,4,22,False
157,1,4,23,False
157,1,4,24,False
4,1,5,1,False
4,1,5,2,False
18,1,5,3,False
18,1,5,4,False
32,1,5,5,False
32,1,5,6,False
46,1,5,7,False
46,1,5,8,False
60,1,5,9,False
60,1,5,10,False
74,1,5,11,False
74,1,5,12,False
88,1,5,13,False
88,1,5,14,False
102,1,5,15,False
102,1,5,16,False
116,1,5,17,False
116,1,5,18,False
130,1,5,19,False
130,1,5,20,False
144,1,5,21,False
144,1,5,22,False
158,1,5,23,False
158,1,5,24,False
5,1,6,1,False
5,1,6,2,False
19,1,6,3,False
19,1,6,4,False
33,1,6,5,False
33,1,6,6,False
47,1,6,7,False
47,1,6,8,False
61,1,6,9,False
61,1,6,10,False
75,1,6,11,False
75,1,6,12,False
89,1,6,13,False
89,1,6,14,False
103,1,6,15,False
103,1,6,16,False
117,1,6,17,False
117,1,6,18,False
131,1,6,19,False
131,1,6,20,False
145,1,6,21,False
145,1,6,22,False
159,1,6,23,False
159,1,6,24,False
6,1,7,1,False
6,1,7,2,False
20,1,7,3,False
20,1,7,4,False
34,1,7,5,False
34,1,7,6,False
48,1,7,7,False
48,1,7,8,False
62,1,7,9,False
62,1,7,10,False
76,1,7,11,False
76,1,7,12,False
90,1,7,13,False
90,1,7,14,False
104,1,7,15,False
104,1,7,16,False
118,1,7,17,False
118,1,7,18,False
132,1,7,19,False
132,1,7,20,False
146,1,7,21,False
146,1,7,22,False
160,1,7,23,False
160,1,7,24,False
7,1,8,1,False
7,1,8,2,False
21,1,8,3,False
21,1,8,4,False
35,1,8,5,False
35,1,8,6,False
49,1,8,7,False
49,1,8,8,False
63,1,8,9,False
63,1,8,10,False
77,1,8,11,False
77,1,8,12,False
91,1,8,13,False
91,1,8,14,False
105,1,8,15,False
105,1,8,16,False
119,1,8,17,False
119,1,8,18,False
133,1,8,19,False
133,1,8,20,False
147,1,8,21,False
147,1,8,22,False
161,1,8,23,False
161,1,8,24,False
8,1,9,1,False
8,1,9,2,False
22,1,9,3,False
22,1,9,4,False
36,1,9,5,False
36,1,9,6,False
50,1,9,7,False
50,1,9,8,False
64,1,9,9,False
64,1,9,10,False
78,1,9,11,False
78,1,9,12,False
92,1,9,13,False
92,1,9,14,False
106,1,9,15,False
106,1,9,16,False
120,1,9,17,False
120,1,9,18,False
134,1,9,19,False
134,1,9,20,False
148,1,9,21,False
148,1,9,22,False
162,1,9,23,False
162,1,9,24,False
9,1,10,1,False
9,1,10,2,False
23,1,10,3,False
23,1,10,4,False
37,1,10,5,False
37,1,10,6,False
51,1,10,7,False
51,1,10,8,False
65,1,10,9,False
65,1,10,10,False
79,1,10,11,False
79,1,10,12,False
93,1,10,13,False
93,1,10,14,False
107,1,10,15,False
107,1,10,16,False
121,1,10,17,False
121,1,10,18,False
135,1,10,19,False
135,1,10,20,False
149,1,10,21,False
149,1,10,22,False
163,1,10,23,False
163,1,10,24,False
10,1,11,1,False
10,1,11,2,False
24,1,11,3,False
24,1,11,4,False
38,1,11,5,False
38,1,11,6,False
52,1,11,7,False
52,1,11,8,False
66,1,11,9,False
66,1,11,10,False
80,1,11,11,False
80,1,11,12,False
94,1,11,13,False
94,1,11,14,False
108,1,11,15,False
108,1,11,16,False
122,1,11,17,False
122,1,11,18,False
136,1,11,19,False
136,1,11,20,False
150,1,11,21,False
150,1,11,22,False
164,1,11,23,False
164,1,11,24,False
11,1,12,1,False
11,1,12,2,False
25,1,12,3,False
25,1,12,4,False
39,1,12,5,False
39,1,12,6,False
53,1,12,7,False
53,1,12,8,False
67,1,12,9,False
67,1,12,10,False
81,1,12,11,False
81,1,12,12,False
95,1,12,13,False
95,1,12,14,False
109,1,12,15,False
109,1,12,16,False
123,1,12,17,False
123,1,12,18,False
137,1,12,19,False
137,1,12,20,False
151,1,12,21,False
151,1,12,22,False
165,1,12,23,False
165,1,12,24,False
12,1,13,1,False
12,1,13,2,False
26,1,13,3,False
26,1,13,4,False
40,1,13,5,False
40,1,13,6,False
54,1,13,7,False
54,1,13,8,False
68,1,13,9,False
68,1,13,10,False
82,1,13,11,False
82,1,13,12,False
96,1,13,13,False
96,1,13,14,False
110,1,13,15,False
110,1,13,16,False
124,1,13,17,False
124,1,13,18,False
138,1,13,19,False
138,1,13,20,False
152,1,13,21,False
152,1,13,22,False
166,1,13,23,False
166,1,13,24,False
13,1,14,1,False
13,1,14,2,False
27,1,14,3,False
27,1,14,4,False
41,1,14,5,False
41,1,14,6,False
55,1,14,7,False
55,1,14,8,False
69,1,14,9,False
69,1,14,10,False
83,1,14,11,False
83,1,14,12,False
97,1,14,13,False
97,1,14,14,False
111,1,14,15,False
111,1,14,16,False
125,1,14,17,False
125,1,14,18,False
139,1,14,19,False
139,1,14,20,False
153,1,14,21,False
153,1,14,22,False
167,1,14,23,False
167,1,14,24,False
14,1,15,1,False
14,1,15,2,False
28,1,15,3,False
28,1,15,4,False
42,1,15,5,False
42,1,15,6,False
56,1,15,7,False
56,1,15,8,False
70,1,15,9,False
70,1,15,10,False
84,1,15,11,False
84,1,15,12,False
98,1,15,13,False
98,1,15,14,False
112,1,15,15,False
112,1,15,16,False
126,1,15,17,False
126,1,15,18,False
140,1,15,19,False
140,1,15,20,False
154,1,15,21,False
154,1,15,22,False
168,1,15,23,False
168,1,15,24,False
Control2,2,16,1,True
Control2,2,16,2,True
Control2,2,16,3,True
Control2,2,16,4,True
Control2,2,16,5,True
Control2,2,16,6,True
Control2,2,16,7,True
Control2,2,16,8,True
Control1,2,16,9,True
Control1,2,16,10,True
Control1,2,16,11,True
Control1,2,16,12,True
Control1,2,16,13,True
Control1,2,16,14,True
Control1,2,16,15,True
Control1,2,16,16,True
DMSO,1,16,17,True
DMSO,1,16,18,True
DMSO,1,16,19,True
DMSO,1,16,20,True
DMSO,1,16,21,True
DMSO,1,16,22,True
DMSO,1,16,23,True
DMSO,1,16,24,True
`;
