import { faDownload, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import saveAs from 'file-saver';
import { useEffect, useState } from 'react';
import { Alert, Button, ButtonGroup } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import api from '../../../api';
import { Column, DataTableControl, DataTableModel, saveDataTableAsCSV } from '../../../components/DataTable';
import { AsyncActionButton } from '../../../components/common/AsyncButton';
import { ErrorMessage } from '../../../components/common/Error';
import { SimpleMultiFileUploadV2 } from '../../../components/common/FileUpload';
import { IconButton } from '../../../components/common/IconButton';
import { LabeledInput } from '../../../components/common/Inputs';
import Loading from '../../../components/common/Loading';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import { useStateModel } from '../../../lib/hooks/useStateModel';
import { ToastService } from '../../../lib/services/toast';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { ECMApi } from '../ecm-api';
import { ECMCommonUploadInfo, ECMPageTemplate } from '../ecm-common';
import { AdjustAPI, BatchVialAdjustWorkflow } from './Adjust';
import { ECMBatchWorkflowFooter, ECMBatchWorkflowSummary } from './common';
import { formatUnit } from '../../../lib/util/units';

export function ECMVolumeCheck() {
    const model = useStateModel(() => new VolumeCheckAdjustModel());
    const [mode, setMode] = useState<'update' | 'check' | 'dispose'>('update');

    return (
        <ECMPageTemplate page='volume-check' withFooter={mode === 'update'}>
            <ButtonGroup size='sm' className='w-100 mb-2'>
                <Button
                    variant={mode === 'update' ? 'primary' : 'outline-primary'}
                    onClick={() => {
                        model.reset();
                        if (mode !== 'update') setMode('update');
                    }}
                >
                    Adjust Volume
                </Button>
                <Button
                    variant={mode === 'check' ? 'primary' : 'outline-primary'}
                    onClick={() => mode !== 'check' && setMode('check')}
                >
                    Check List
                </Button>
                <Button
                    variant={mode === 'dispose' ? 'primary' : 'outline-primary'}
                    onClick={() => mode !== 'dispose' && setMode('dispose')}
                >
                    Dispose List
                </Button>
            </ButtonGroup>
            {mode === 'update' && <UpdateRoot model={model} />}
            {mode === 'check' && <VolumeCheckWrapper />}
            {mode === 'dispose' && <VolumeDisposeWrapper />}
        </ECMPageTemplate>
    );
}

class VolumeCheckAdjustModel {
    state = {
        input: new BehaviorSubject<{
            measurements: File[];
            rackscans: File[];
        }>({ measurements: [], rackscans: [] }),
        workflow: new BehaviorSubject<BatchVialAdjustWorkflow | undefined>(undefined),
    };

    reset = () => {
        this.state.input.next({ measurements: [], rackscans: [] });
        this.state.workflow.next(undefined);
    };

    submit = async () => {
        try {
            const input = this.state.input.value;
            if (!input.measurements || input.rackscans.length === 0)
                return ToastService.info('Please upload measurements and rack scans');

            const adjusts = await api.postMsgpackForm(
                '/ecm/adjust-from-check',
                {},
                {
                    files: {
                        measurements: input.measurements,
                        rackscans: input.rackscans,
                    },
                }
            );
            const entries = await AdjustAPI.prepare(adjusts);

            const workflow = new BatchVialAdjustWorkflow(
                [['adjusts', entries]],
                new BehaviorSubject<File[] | null>(null),
                {
                    onSubmit: this.reset,
                }
            );

            this.state.workflow.next(workflow);
        } catch (err) {
            reportErrorAsToast('Adjust', err);
        }
    };
}

function UpdateRoot({ model }: { model: VolumeCheckAdjustModel }) {
    const workflow = useBehavior(model.state.workflow);
    return (
        <>
            {!workflow && <UpdateInput model={model} />}
            {!!workflow && <WorkflowUpload workflow={workflow} />}
        </>
    );
}

function WorkflowUpload({ workflow }: { workflow: BatchVialAdjustWorkflow }) {
    return (
        <div className='flex-grow-1 position-relative'>
            <div className='d-flex flex-column position-absolute' style={{ inset: 0 }}>
                <div className='flex-grow-1' style={{ overflow: 'hidden', overflowY: 'auto' }}>
                    <ECMBatchWorkflowSummary model={workflow} />
                </div>
            </div>
            <ECMBatchWorkflowFooter model={workflow} />
        </div>
    );
}

const LabelWidth = 160;

function UpdateInput({ model }: { model: VolumeCheckAdjustModel }) {
    const input = useBehavior(model.state.input);
    return (
        <>
            <div className='vstack gap-2 m-auto mt-4' style={{ width: 800 }}>
                <span className='text-secondary'>
                    Upload a file with measurements and one or more files with rack scans to adjust volumes
                </span>

                <ECMCommonUploadInfo
                    title='Measurements'
                    required={['RACKID', 'TUBE', 'VOLAVG', 'STATUS']}
                    className='ecm-adjust-from-check-section'
                />
                <LabeledInput label='Measurements' labelWidth={LabelWidth}>
                    <div className='ecm-inline-upload-wrapper'>
                        <SimpleMultiFileUploadV2
                            files={input.measurements}
                            onDrop={(files) => model.state.input.next({ ...input, measurements: files })}
                            label='One or more .csv, .xls, .xlsx'
                            extensions={['.csv', '.xls', '.xlsx']}
                            inline
                        />
                    </div>
                </LabeledInput>

                <ECMCommonUploadInfo
                    title='Rack Scans'
                    required={['Rack Barcode', 'Well', 'Barcode']}
                    className='ecm-adjust-from-check-section'
                />
                <LabeledInput label='Rack Scans' labelWidth={LabelWidth}>
                    <div className='ecm-inline-upload-wrapper'>
                        <SimpleMultiFileUploadV2
                            files={input.rackscans}
                            onDrop={(files) => model.state.input.next({ ...input, rackscans: files })}
                            label='One or more .csv, .xls, .xlsx'
                            extensions={['.csv', '.xls', '.xlsx']}
                            inline
                        />
                    </div>
                </LabeledInput>
            </div>
            <div className='entos-footer justify-content-between hstack gap-2 border-top'>
                <div className='m-auto' />
                <AsyncActionButton
                    size='sm'
                    action={model.submit}
                    variant='primary'
                    disabled={!input.measurements || input.rackscans.length === 0}
                >
                    Submit
                </AsyncActionButton>
            </div>
        </>
    );
}

async function loadCheckTable() {
    const data = await ECMApi.volumeCheck();
    const table = new DataTableModel(data, {
        columns: {
            barcode: Column.str(),
            kind: {
                ...Column.str(),
                header: 'Event',
            },
            description: {
                ...Column.str(),
                width: 300,
            },
            created_by: {
                ...Column.str(),
                width: 220,
            },
            created_on: {
                ...Column.datetime({ format: 'full' }),
                width: 200,
            },
        },
    });
    return table;
}

function VolumeCheckWrapper() {
    const [table, _loadTable] = useAsyncAction<DataTableModel>();
    useEffect(() => {
        _loadTable(loadCheckTable());
    }, []);

    if (table.isLoading) return <Loading />;
    if (table.error) return <ErrorMessage header='Error Loading Events' message={`${table.error}`} />;
    if (!table.result) return null;

    return <VolumeCheckRoot table={table.result} />;
}

function VolumeCheckRoot({ table }: { table: DataTableModel }) {
    return (
        <div className='d-flex flex-column h-100'>
            <div className='hstack gap-2 mb-2'>
                <Alert variant='info' className='p-2 pe-3 mb-0 font-body-small'>
                    <div className='hstack gap-2'>
                        <FontAwesomeIcon icon={faInfoCircle} className='mx-1' />
                        <span>
                            A list of latest events for inventory tubes (<b>HG</b>, <b>HB</b>) that had contents changed
                            since creation or last <i>Adjust</i>
                        </span>
                    </div>
                </Alert>
                <div className='m-auto' />
                <IconButton
                    variant='outline-primary'
                    onClick={() => saveDataTableAsCSV('tube-volume-check', table)}
                    icon={faDownload}
                    size='sm'
                >
                    Download CSV
                </IconButton>
                <IconButton
                    variant='primary'
                    onClick={() => downloadVersoPicklist(table, 'volume-check')}
                    icon={faDownload}
                    size='sm'
                >
                    Verso Picklist
                </IconButton>
            </div>
            <div className='mb-2 flex-grow-1 position-relative'>
                <VialTable table={table} />
            </div>
        </div>
    );
}

async function loadDisposeTable() {
    const data = await ECMApi.disposeCheck();
    const table = new DataTableModel(data, {
        columns: {
            barcode: {
                ...Column.str(),
                width: 220,
            },
            volume: {
                ...Column.float(),
                header: 'Volume (μL)',
                render: ({ value }) => formatUnit(value * 1e3, 'L', 'u'),
                width: 200,
            },
        },
    });
    return table;
}

function VolumeDisposeWrapper() {
    const [table, _loadTable] = useAsyncAction<DataTableModel>();
    useEffect(() => {
        _loadTable(loadDisposeTable());
    }, []);

    if (table.isLoading) return <Loading />;
    if (table.error) return <ErrorMessage header='Error Loading Dispose List' message={`${table.error}`} />;
    if (!table.result) return null;

    return <VolumeDisposeRoot table={table.result} />;
}

function VolumeDisposeRoot({ table }: { table: DataTableModel }) {
    return (
        <div className='d-flex flex-column h-100'>
            <div className='hstack gap-2 mb-2'>
                <Alert variant='info' className='p-2 pe-3 mb-0 font-body-small'>
                    <div className='hstack gap-2'>
                        <FontAwesomeIcon icon={faInfoCircle} className='mx-1' />
                        <span>
                            A list of inventory tubes (<b>HG</b>, <b>HB</b>) with current volume ≤20 μL
                        </span>
                    </div>
                </Alert>
                <div className='m-auto' />
                <IconButton
                    variant='outline-primary'
                    onClick={() => saveDataTableAsCSV('tube-dispose', table)}
                    icon={faDownload}
                    size='sm'
                >
                    Download CSV
                </IconButton>
                <IconButton
                    variant='primary'
                    onClick={() => downloadVersoPicklist(table, 'dispose')}
                    icon={faDownload}
                    size='sm'
                >
                    Verso Picklist
                </IconButton>
            </div>
            <div className='mb-2 flex-grow-1 position-relative'>
                <VialTable table={table} />
            </div>
        </div>
    );
}

function downloadVersoPicklist(table: DataTableModel, kind: 'volume-check' | 'dispose') {
    const barcodes = table.store.getColumnValues('barcode');
    const blob = new Blob([barcodes.join('\n')], { type: 'text/csv' });
    saveAs(blob, `tube-${kind}-verso.csv`);
}

function VialTable({ table }: { table: DataTableModel }) {
    useBehavior(table.version);

    return <DataTableControl table={table} height='flex' headerSize='xsm' />;
}
