import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp, faSort } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Column, HeaderGroup, Row } from 'react-table';
import { BehaviorSubject } from 'rxjs';
import { columnNameToHeader } from '../../api/data';
import useBehavior from '../../lib/hooks/useBehavior';
import { ReactTableModel } from './model';
import { ReactTableDefaultColumnFilter, ReactTableSelectedColumnFilter } from './Filters';

// any type because TS 4.7 went haywire
export const defaultColumn = { Filter: ReactTableDefaultColumnFilter } as Partial<Column<any>> as any;

export function reactTableFilterSelectedFn(table: ReactTableModel<any>) {
    return function filterSelected(rows: Row<{ index: number }>[], _id: string, filterValue: boolean) {
        const selectedRowIds = table.selectedRowIndices.value;
        return rows.filter((row) => !!filterValue === !!selectedRowIds[row.original.index]);
    };
}

export function reactTableBuildSelectionColumn(table: ReactTableModel) {
    const onCheck = reactTableGetToggleCheckedFn(table, 'multi');
    const onCheckAll = reactTableGetToggleAllSelectedFn(table);

    return {
        id: SelectionColumnId,
        Header: () => {
            const selectionSize = Object.keys(table.selectedRowIndices.value).length;
            // Using normal checkbox here because the "indeterminate" state wasn't working anyway...
            return (
                <div>
                    <Form.Check
                        type='checkbox'
                        onChange={onCheckAll as any}
                        checked={selectionSize >= table.visibleRows.value.length}
                    />
                </div>
            );
        },
        Cell: ({ row }: any) => (
            <Form.Check
                type='checkbox'
                data-index={row.original.index}
                checked={!!table.selectedRowIndices.value[row.original.index]}
                onChange={onCheck as any}
            />
        ),
        defaultCanFilter: true,
        Filter: ReactTableSelectedColumnFilter,
        filter: reactTableFilterSelectedFn(table) as any,
        width: 40,
        minWidth: 40,
        maxWidth: 40,
    };
}

const SelectionColumnId = '__selection__' as const;

export function ReactTableColumnHeader({ column }: { column: HeaderGroup<any> }) {
    if (column.id === SelectionColumnId || column.disableSortBy) return <>{column.render('Header')}</>;
    const title = columnNameToHeader(column.id);
    let columnIcon = faSort;
    if (column.isSorted) columnIcon = column.isSortedDesc ? faSortDown : faSortUp;

    return (
        <OverlayTrigger placement='auto' overlay={<Tooltip>{title}</Tooltip>} delay={{ show: 500, hide: 0 }}>
            {(props) => (
                <button
                    type='button'
                    className='entos-dataviz-sort-button bg-transparent border-0 position-relative pe-3'
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title=''
                    style={{ fontSize: title.length > 16 ? '0.75rem' : '0.85rem' }}
                    {...props}
                >
                    <span className='entos-dataviz-header-title'>{column.render('Header')}</span>
                    <FontAwesomeIcon
                        className={`position-absolute ${column.isSorted ? ' text-primary' : ''}`}
                        style={{ top: '50%', right: 0, transform: 'translateY(-50%)' }}
                        icon={columnIcon}
                    />
                </button>
            )}
        </OverlayTrigger>
    );
}

export type RowSelectionMode = 'none' | 'single' | 'multi';

export function reactTableGetToggleCheckedFn(table: ReactTableModel<any>, rowSelectionMode: RowSelectionMode) {
    return function toggleChecked(e: React.ChangeEvent<HTMLElement>) {
        if (rowSelectionMode === 'none') return;
        const index = +e.target.getAttribute('data-index')!;
        if (rowSelectionMode === 'single') {
            table.setSelection({ [index]: true });
        } else if (rowSelectionMode === 'multi') {
            const newSelectedRowIds: Record<string, boolean> = { ...table.selectedRowIndices.value };
            if (newSelectedRowIds[index]) {
                delete newSelectedRowIds[index];
            } else {
                newSelectedRowIds[index] = true;
            }
            table.setSelection(newSelectedRowIds);
        }
    };
}

export function reactTableGetToggleAllSelectedFn(table: ReactTableModel<any>) {
    return function toggleAllSelected() {
        const visibleRows = table.visibleRows.value;
        const currentSelection = table.selectedRowIndices.value;
        if (!currentSelection[visibleRows[0]]) {
            const allRowsSelected: Record<string, boolean> = {};
            for (const rowIndex of visibleRows) {
                allRowsSelected[rowIndex] = true;
            }
            table.setSelection(allRowsSelected);
        } else {
            table.setSelection({});
        }
    };
}

function SmilesColumnHeader({
    column,
    showDrawings,
}: {
    column: HeaderGroup<any>;
    showDrawings: BehaviorSubject<boolean>;
}) {
    const show = useBehavior(showDrawings);
    return (
        <div className='d-flex justify-space-between align-items-center'>
            <ReactTableColumnHeader column={column} />
            <Form.Check
                title='Show SMILES Drawings'
                className='ms-1'
                type='switch'
                checked={show}
                onChange={() => showDrawings.next(!show)}
            />
        </div>
    );
}

export type ReactTableHeaderSize = 'sm' | 'lg';

export function ReactTableHeader({
    headerGroups,
    headerSize = 'sm',
    smiles,
    showColumnFilters = false,
}: {
    headerGroups: HeaderGroup<any>[];
    headerSize?: ReactTableHeaderSize;
    smiles?: {
        showDrawings: BehaviorSubject<boolean>;
        columnId?: string;
    };
    showColumnFilters?: boolean;
}) {
    const smilesColumn = smiles ? smiles.columnId ?? 'smiles' : '';
    return (
        <div className='entos-dataviz-table-header'>
            {/* NOTE it is tempting to handle the header height with inline styling for more flexibility 
            but since we are using block layout, headerGroup.getHeaderGroupProps() conflicts with manually setting the style={...} attribute */}
            {headerGroups.map((headerGroup) => (
                <div
                    {...headerGroup.getHeaderGroupProps()}
                    className={classNames('entos-dataviz-table-header-row', {
                        'entos-sm-header-row': headerSize === 'sm',
                        'entos-lg-header-row': headerSize === 'lg',
                    })}
                >
                    {headerGroup.headers.map((column) => (
                        <div {...column.getHeaderProps()} className='entos-dataviz-table-header-cell d-flex'>
                            {smiles && column.id === smilesColumn && (
                                <SmilesColumnHeader column={column} showDrawings={smiles.showDrawings} />
                            )}
                            {(!smiles || column.id !== smilesColumn) && <ReactTableColumnHeader column={column} />}
                            {showColumnFilters && column.Filter && (
                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                            )}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}
