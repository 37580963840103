import saveAs from 'file-saver';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { SimpleMultiFileUploadV2 } from '../../../components/common/FileUpload';
import { LabeledInput, TextInput } from '../../../components/common/Inputs';
import useBehavior from '../../../lib/hooks/useBehavior';
import { DialogService } from '../../../lib/services/dialog';
import { ToastService } from '../../../lib/services/toast';
import { memoizeLatest } from '../../../lib/util/misc';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { formatWithUnit, parseWithUnit } from '../../../lib/util/units';
import { HTE2MSApi } from '../api';
import { HTEFinalQCIdT, HTEFinalQCOptions, HTEQualityControl } from '../data-model';
import { EditLabwareOption } from '../utils';
import type { HTE2MSPostProductionModel } from './model';
import { reportErrorAsToast } from '../../../lib/util/errors';

const EmptyQC: HTEQualityControl = {
    finalqcs: [],
    registered_finalqcs: {},
};

export class HTE2MSQualityControlModel extends ReactiveModel {
    state = {
        data: new BehaviorSubject<HTEQualityControl>(EmptyQC),
        currentFinalQCId: new BehaviorSubject<HTEFinalQCIdT | undefined>(undefined),
    };

    get mainModel() {
        return this.postProduction.model;
    }

    get data() {
        return this.state.data.value;
    }

    get assets() {
        return this.mainModel.assets;
    }

    get currentFinalQCId() {
        return this.state.currentFinalQCId.value;
    }

    private _currentFinalQC = memoizeLatest((data: HTEQualityControl, id: HTEFinalQCIdT | undefined) => {
        if (!id) return undefined;
        for (const p of data.finalqcs) {
            if (p.id === id) return p;
        }
        return undefined;
    });

    get currentFinalQC() {
        return this._currentFinalQC(this.data, this.currentFinalQCId);
    }

    addFinalQC = () => {
        DialogService.open({
            type: 'generic',
            title: 'Add FinalQC',
            confirmButtonContent: 'Apply',
            model: this,
            wrapOk: true,
            defaultState: {
                files: {
                    rack_files: [],
                },
                options: this.mainModel.assets.defaults.default_finalqc_options,
            } satisfies AddFinalQCState,
            content: AddFinalQCDialogContent,
            onOk: (state: AddFinalQCState) => this._applyAdd(state),
        });
    };

    private async _applyAdd(state: AddFinalQCState) {
        if (!this.mainModel.experiment) return;

        if (!state.files.rack_files.length) {
            throw new Error('No racks provided');
        }

        if (!state.options.target_barcode) {
            throw new Error('No target barcode provided');
        }

        const result = await HTE2MSApi.addFinalQC(
            this.mainModel.experiment?.id!,
            {
                rack_files: state.files.rack_files,
            },
            {
                options: state.options,
                last_modified_on: this.mainModel.last_modified_on!,
            }
        );

        this.mainModel.updateFoundryInfo({ experiment: result.experiment });
        this.setData(result.qc);
        this.state.currentFinalQCId.next(result.qc.finalqcs[result.qc.finalqcs.length - 1].id);
    }

    confirmRemoveFinalQC(id: HTEFinalQCIdT) {
        DialogService.open({
            type: 'generic',
            title: 'Remove FinalQC',
            confirmButtonContent: 'Remove',
            model: this,
            wrapOk: true,
            content: RemoveFinalQCDialogContent,
            onOk: () => this._applyRemove(id),
        });
    }

    private async _applyRemove(id: HTEFinalQCIdT) {
        if (!this.mainModel.experiment) return;

        const result = await HTE2MSApi.removeFinalQC(this.mainModel.experiment?.id!, id, {
            last_modified_on: this.mainModel.last_modified_on!,
        });

        this.mainModel.updateFoundryInfo({ experiment: result.experiment });
        this.setData(result.qc);

        if (this.state.currentFinalQCId.value === id) {
            this.state.currentFinalQCId.next(undefined);
        }

        ToastService.info('FinalQC removed');
    }

    confirmRegisterFinalQC(id: HTEFinalQCIdT) {
        DialogService.open({
            type: 'generic',
            title: 'Register FinalQC',
            confirmButtonContent: 'Apply',
            model: this,
            wrapOk: true,
            content: ConfirmRegistrationDialogContent,
            onOk: () => this._applyRegister(id),
        });
    }

    private async _applyRegister(id: HTEFinalQCIdT) {
        if (!this.mainModel.experiment) return;

        const result = await HTE2MSApi.registerFinalQC(this.mainModel.experiment?.id!, id, {
            last_modified_on: this.mainModel.last_modified_on!,
        });

        this.mainModel.updateFoundryInfo({ experiment: result.experiment });
        this.setData(result.qc);

        ToastService.success('FinalQC registered');

        const qc = this.data.finalqcs.find((p) => p.id === id);
        try {
            await HTE2MSApi.notifySlack(this.mainModel.experiment?.id!, 'finalqc', {
                lcms_barcode: qc?.options.target_barcode,
                echoms_barcode: qc?.options.echoms_target_barcode,
            });
        } catch (e) {
            reportErrorAsToast('Notify Slack', e);
        }
    }

    saveProtocol(options: { protocol: string; name: string }) {
        const filename = `${this.mainModel.libraryId}-${options.name}.gwl`;
        saveAs(new Blob([options.protocol], { type: 'text/plain' }), filename);
    }

    setData(data: HTEQualityControl) {
        this.state.data.next(data);
    }

    constructor(public postProduction: HTE2MSPostProductionModel) {
        super();
    }
}

interface AddFinalQCState {
    files: {
        rack_files: File[];
    };
    options: HTEFinalQCOptions;
}

const LabelWidth = 160;

function AddFinalQCDialogContent({ stateSubject }: { stateSubject: BehaviorSubject<AddFinalQCState> }) {
    const state = useBehavior(stateSubject);
    const [showOptions, setShowOptions] = useState(false);

    const updateFiles = (key: keyof AddFinalQCState['files'], files: File | File[] | undefined) =>
        stateSubject.next({ ...state, files: { ...state.files, [key]: files as any } });

    return (
        <div className='vstack gap-1 font-body-small'>
            <LabeledInput label='Racks' labelWidth={LabelWidth}>
                <div className='hte2ms-inline-upload-wrapper'>
                    <SimpleMultiFileUploadV2
                        files={state.files.rack_files}
                        onDrop={(files) => updateFiles('rack_files', files)}
                        label='One or more .csv, .xls, .xlsx'
                        extensions={['.csv', '.xls', '.xlsx']}
                        inline
                    />
                </div>
            </LabeledInput>
            <LabeledInput label='LCMS Plate Barcode' labelWidth={LabelWidth}>
                <TextInput
                    value={state.options.target_barcode}
                    size='sm'
                    setValue={(v) =>
                        stateSubject.next({ ...state, options: { ...state.options, target_barcode: v.trim() } })
                    }
                />
            </LabeledInput>
            <LabeledInput label='EchoMS Plate Barcode' labelWidth={LabelWidth}>
                <TextInput
                    value={state.options.echoms_target_barcode ?? ''}
                    size='sm'
                    setValue={(v) =>
                        stateSubject.next({
                            ...state,
                            options: { ...state.options, echoms_target_barcode: v?.trim() || undefined },
                        })
                    }
                />
            </LabeledInput>

            <Button
                variant='link'
                className='p-0 font-body-small fw-bold text-start'
                onClick={() => setShowOptions((v) => !v)}
            >
                {showOptions ? 'Hide' : 'Show'} Options
            </Button>

            {showOptions && (
                <div className='vstack gap-1'>
                    <LabeledInput label='Source Labware' labelWidth={LabelWidth}>
                        <EditLabwareOption
                            value={state.options.source_labware}
                            setValue={(l) =>
                                stateSubject.next({
                                    ...state,
                                    options: { ...state.options, source_labware: l },
                                })
                            }
                        />
                    </LabeledInput>
                    <LabeledInput label='LCMS Labware' labelWidth={LabelWidth}>
                        <EditLabwareOption
                            value={state.options.target_labware}
                            setValue={(l) =>
                                stateSubject.next({
                                    ...state,
                                    options: { ...state.options, target_labware: l },
                                })
                            }
                            foundryLayouts
                        />
                    </LabeledInput>
                    <LabeledInput label='EchoMS Labware' labelWidth={LabelWidth}>
                        <EditLabwareOption
                            value={state.options.echoms_target_labware}
                            setValue={(l) =>
                                stateSubject.next({
                                    ...state,
                                    options: { ...state.options, echoms_target_labware: l },
                                })
                            }
                            foundryLayouts
                        />
                    </LabeledInput>
                    <LabeledInput label='Solvent Labware' labelWidth={LabelWidth}>
                        <EditLabwareOption
                            value={state.options.solvent_labware}
                            setValue={(l) =>
                                stateSubject.next({
                                    ...state,
                                    options: { ...state.options, solvent_labware: l },
                                })
                            }
                        />
                    </LabeledInput>
                    <LabeledInput label='LCMS Assay Volume' labelWidth={LabelWidth}>
                        <TextInput
                            value={formatWithUnit(state.options.assay_volume, 1e9, 'uL')}
                            size='sm'
                            tryUpdateValue={(v) => parseWithUnit(v, 'uL', true)}
                            setValue={(v) =>
                                stateSubject.next({ ...state, options: { ...state.options, assay_volume: v } })
                            }
                        />
                    </LabeledInput>
                    <LabeledInput label='LCMS Assay Concentration' labelWidth={LabelWidth}>
                        <TextInput
                            value={formatWithUnit(state.options.assay_concentration, 1e3, 'mM')}
                            size='sm'
                            tryUpdateValue={(v) => parseWithUnit(v, 'mM', true)}
                            setValue={(v) =>
                                stateSubject.next({
                                    ...state,
                                    options: { ...state.options, assay_concentration: v },
                                })
                            }
                        />
                    </LabeledInput>

                    <LabeledInput label='EchoMS Assay Volume' labelWidth={LabelWidth}>
                        <TextInput
                            value={formatWithUnit(state.options.echoms_assay_volume, 1e9, 'uL')}
                            size='sm'
                            tryUpdateValue={(v) => parseWithUnit(v, 'uL', true)}
                            setValue={(v) =>
                                stateSubject.next({ ...state, options: { ...state.options, echoms_assay_volume: v } })
                            }
                        />
                    </LabeledInput>
                    <LabeledInput label='EchoMS Assay Concentration' labelWidth={LabelWidth}>
                        <TextInput
                            value={formatWithUnit(state.options.echoms_assay_concentration, 1e3, 'mM')}
                            size='sm'
                            tryUpdateValue={(v) => parseWithUnit(v, 'mM', true)}
                            setValue={(v) =>
                                stateSubject.next({
                                    ...state,
                                    options: { ...state.options, echoms_assay_concentration: v },
                                })
                            }
                        />
                    </LabeledInput>

                    <LabeledInput label='Tecan Piston Volume' labelWidth={LabelWidth}>
                        <TextInput
                            value={formatWithUnit(state.options.tecan_piston_volume, 1e6, 'mL')}
                            size='sm'
                            tryUpdateValue={(v) => parseWithUnit(v, 'mL', true)}
                            setValue={(v) =>
                                stateSubject.next({ ...state, options: { ...state.options, tecan_piston_volume: v } })
                            }
                        />
                    </LabeledInput>
                </div>
            )}
        </div>
    );
}

function RemoveFinalQCDialogContent() {
    return <div>Are you sure you want to remove this FinalQC entry?</div>;
}

function ConfirmRegistrationDialogContent() {
    return (
        <>
            <div>Do you want to register the selected FinalQC? This will:</div>
            <ul className='ps-3 mt-1'>
                <li>Register LCMS (and EchoMS if specified) plate in Foundy and associate it with the library</li>
                <li>Apply vial trasfers</li>
                <li>Notify Analytical via Slack</li>
            </ul>
        </>
    );
}
