import log from 'loglevel';
import api from '../../api';
import { BoxAPI } from '../../pages/Boxes/box-api';
import { AssayValueType } from '../assays/models';
import { reportErrorAsToast } from './errors';
import { decodeEntosMsgpack } from './serialization';

interface BoxComputeInput {
    boxes: number[];
    smiles: string[];
}

export type ScoreBoxComputeResult = AssayValueType | { kind: 'error'; message: string };

export function isScoreBoxComputeError(
    result: ScoreBoxComputeResult | undefined
): result is { kind: 'error'; message: string } {
    return typeof result === 'object' && (result as any).kind === 'error';
}

export class ScoreBoxSocketCompute {
    private sockets: Map<number, WebSocket> = new Map();
    private webSocketId = 0;
    private cache = new Map<string, number>();
    private initializingPromise: Promise<number> | undefined = undefined;
    private computing = new Set<string>();

    private init() {
        if (this.initializingPromise) return this.initializingPromise!;

        // eslint-disable-next-line no-async-promise-executor
        this.initializingPromise = new Promise<number>(async (res, rej) => {
            try {
                const boxesDF = await BoxAPI.list({ latest: true, name: this.options.name, kind: 'substance_score' });
                const boxes = boxesDF.toObjects();
                if (boxes.length === 0) {
                    rej(new Error(`Box ${this.options.name} not found`));
                } else {
                    res(boxes[0].id);
                }
            } catch (err) {
                rej(err);
                this.initializingPromise = undefined;
            }
        });
        return this.initializingPromise;
    }

    async computeBoxValues(smiles: string[]) {
        const unique = Array.from(new Set(smiles));

        const cached = unique.filter((s) => this.cache.has(s));
        const notComputedOrComputing = unique.filter((s) => !this.cache.has(s) && !this.computing.has(s));

        if (cached.length) {
            const results: Record<string, ScoreBoxComputeResult> = {};
            for (const s of cached) {
                results[s] = this.cache.get(s)!;
            }
            this.options.onResult(results);
        }

        if (notComputedOrComputing.length === 0) {
            return;
        }

        let boxId: number;

        try {
            boxId = await this.init();
        } catch (err) {
            reportErrorAsToast('Box compute initialization failure', err);
            return;
        }

        const computeData: BoxComputeInput = {
            boxes: [boxId],
            smiles: notComputedOrComputing,
        };

        let socket: WebSocket;
        const socketId = ++this.webSocketId;

        try {
            socket = new WebSocket(api.compute.socketEndpoint);
            this.sockets.set(socketId, socket);
        } catch (err) {
            reportErrorAsToast('Box compute connection failure', err);
            return;
        }

        for (const s of notComputedOrComputing) {
            this.computing.add(s);
        }

        socket.onopen = () => {
            socket.send(JSON.stringify(computeData));
        };

        socket.onerror = (err) => {
            reportErrorAsToast('Box compute connection failure', err);
        };

        socket.onclose = () => {
            for (const s of notComputedOrComputing) {
                this.computing.delete(s);
            }
            this.sockets.delete(socketId);
        };

        socket.onmessage = async (evt) => {
            try {
                let msg: any;
                if (evt.data instanceof Blob) {
                    const buff = new Uint8Array(await evt.data.arrayBuffer());
                    msg = decodeEntosMsgpack(buff, { eoi: 'hex' });
                } else if (typeof evt.data === 'string') {
                    msg = JSON.parse(evt.data);
                }
                switch (msg.type) {
                    case 'partial-result':
                        await this.assignResults(msg);
                        break;
                    case 'error':
                        this.reportError(msg, notComputedOrComputing);
                        socket.close();
                        break;
                    case 'finished':
                        socket.close();
                        break;
                    case 'progress':
                    case 'box_queue_lengths':
                        log.info(msg);
                        break;
                    default:
                        log.warn(`Box compute: unexpected message type ${msg.type}`);
                        break;
                }
            } catch (err) {
                reportErrorAsToast('Error parsing box compute results', err);
            }
        };
    }

    private reportError(msg: any, smiles: string[]) {
        reportErrorAsToast('Box compute error', msg);
        const results: Record<string, ScoreBoxComputeResult> = {};
        for (const s of smiles) {
            results[s] = { kind: 'error', message: `${msg}` };
        }
        this.options.onResult(results);
    }

    cancel() {
        for (const socket of Array.from(this.sockets.values())) {
            socket.close();
        }
    }

    private async assignResults(msg: any) {
        const { boxes, box_errors } = msg;

        const results: Record<string, ScoreBoxComputeResult> = {};

        const index = boxes.index as string[];
        const data = boxes.data[0] as number[];

        for (let i = 0; i < index.length; i++) {
            results[index[i]] = data[i];
            this.cache.set(index[i], data[i]);
        }

        // For testing:
        // box_errors[0] = { smiles: index[0], error: 'test' };

        for (const boxError of box_errors) {
            const { smiles, error } = boxError;
            results[smiles] = { kind: 'error', message: error };
            this.cache.delete(smiles);
        }

        this.options.onResult(results);
    }

    constructor(
        public options: {
            // NOTE: only supports latest version for now
            name: string;
            onResult: (results: Record<string, ScoreBoxComputeResult>) => any;
        }
    ) {}
}
