/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faArrowRightArrowLeft, faCheck, faQuestion, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import log from 'loglevel';
import { Button, Spinner } from 'react-bootstrap';
import { Column, Row } from 'react-table';
import { BehaviorSubject } from 'rxjs';
import saveAs from 'file-saver';
import { AsyncMoleculeDrawing } from '../../../components/common/AsyncMoleculeDrawing';
import { TextInput } from '../../../components/common/Inputs';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import { DialogService } from '../../../lib/services/dialog';
import { ToastService } from '../../../lib/services/toast';
import { darkenColor, toRGBString } from '../../../lib/util/colors';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { arrayEqualWithBlanks, splitInput } from '../../../lib/util/misc';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { trimValue } from '../../../lib/util/validators';
import { BatchLink } from '../../ECM/ecm-common';
import { EnumerationAPI, HTEEnumerationReactionInfoEntry } from '../enumeration/enumeration-api';
import { ReactionOption } from '../enumeration/enumeration-model';
import { HTEApi, HTEExperimentLoadReactantEntry } from '../experiment-api';
import { formatHTEId, Reactant, ReactantType, Reaction } from '../experiment-data';
import { updateWells } from '../plate/utils';
import { createReactantFromEntity } from './reactions-model';
import { arrayToCsv, objectsToRowArrays } from '../../../lib/util/arrayToCsv';
import { SimilarCompoundsService } from '../similar-compounds/similar-compounds';

interface EnumeratedReaction {
    reaction_id: string;
    msd_site: number[] | undefined;
    bb_site: number[] | undefined;
    msd_identifier: string;
    bb_identifier: string;
    products: string[];
    current_product?: string;
    error?: string;
}

export class EnumerationModel extends ReactiveModel {
    readonly msdSimilarCompounds: SimilarCompoundsService = new SimilarCompoundsService((input: string[]) =>
        this.loadReactants(input, 'msd')
    );
    readonly bbSimilarCompounds: SimilarCompoundsService = new SimilarCompoundsService((input: string[]) =>
        this.loadReactants(input, 'bb')
    );

    readonly reactionOptions: ReactionOption[];

    readonly state = {
        reactions: new BehaviorSubject<EnumeratedReaction[]>([]),
        loadingReactants: new BehaviorSubject<ReactantType | undefined>(undefined),
    };

    readonly commonColumns = {
        actions: {
            Header: '',
            disableSortBy: true,
            id: 'actions',
            width: 58,
            Cell: ({ row }: { row: Row<Reactant> }) => (
                <div className='hte-experiment-enumeration-step-actions-cell'>
                    <Button
                        variant='link'
                        size='sm'
                        onClick={() => this.removeReactant(row.original.identifier)}
                        disabled={this.experiment.stateInfo.status !== 'Planning'}
                        className='mb-1'
                    >
                        <FontAwesomeIcon size='sm' className='text-danger' icon={faRemove} />
                    </Button>
                    <br />
                    <ReplaceReactantButton
                        model={this}
                        reactant={row.original}
                        disabled={this.experiment.stateInfo.status !== 'Planning'}
                    />
                </div>
            ),
        } as Column<Reactant>,
        site: {
            Header: 'Site',
            id: 'site',
            width: 200,
            Cell: ({ row }: { row: Row<Reactant> }) => (
                <SiteSelect
                    model={this.experiment}
                    reactant={row.original}
                    disabled={this.experiment.stateInfo.status !== 'Planning'}
                />
            ),
        } as Column<Reactant>,
        order: {
            Header: '#',
            id: '_order_',
            width: 50,
            accessor: (r: Reactant) => (r.type === 'msd' ? r.msd_order : r.bb_order),
            Cell: ({ value }: { value: number }) => `${value ?? ''}`,
        } as any as Column<Reactant>,
        identifier: {
            Header: 'Identifier',
            id: 'msd_identifier',
            accessor: (row) => row.identifier,
            Cell: ({ value }: { value: string }) => <BatchLink identifier={value} withQuery />,
            width: 190,
        } as Column<Reactant>,
    };

    readonly msdColumns: Column<Reactant>[] = [
        this.commonColumns.actions,
        this.commonColumns.order,
        this.commonColumns.site,
        this.commonColumns.identifier,
    ];

    readonly bbColumns: Column<Reactant>[] = [
        this.commonColumns.actions,
        this.commonColumns.order,
        this.commonColumns.site,
        {
            Header: 'Group',
            id: 'group_name',
            accessor: (r) => r.group_name ?? '',
            width: 100,
            Cell: ({ row }: { row: Row<Reactant> }) => {
                const groupName = row.original.group_name ?? '';
                return (
                    <TextInput
                        value={groupName}
                        className='hte-experiment-badge-like hte-experiment-badge-like-input'
                        tryUpdateValue={trimValue}
                        readOnly={this.experiment.isLocked}
                        style={{
                            background: this.experiment.reactants.bbGroupColorMap.get(groupName)?.rgb,
                            border: this.experiment.reactants.bbGroupColorMap.get(groupName)
                                ? `1px solid ${toRGBString(
                                      darkenColor(this.experiment.reactants.bbGroupColorMap.get(groupName)!.raw, 0.9)
                                  )}`
                                : undefined,
                        }}
                        setValue={(v) => this.experiment.reactants.editValue('group_name', row.original.identifier, v)}
                    />
                );
            },
        },
        this.commonColumns.identifier,
    ];

    readonly reactionsColumns: Column<EnumeratedReaction>[] = [
        {
            Header: 'Product',
            id: 'product',
            width: 200,
            accessor: (r: EnumeratedReaction) => r.products.length,
            Cell: ({ row }: { row: Row<EnumeratedReaction> }) => (
                <ProductSelect model={this.experiment} reaction={row.original} />
            ),
        },
        {
            Header: 'MSD',
            id: 'msd',
            width: 140,
            Cell: ({ row }: { row: Row<EnumeratedReaction> }) => (
                <div className='position-relative w-100 h-100'>
                    <div className='text-secondary hte-experiment-enumeration-step-products-label'>
                        #{this.experiment.reactants.getReactant(row.original.msd_identifier)?.msd_order ?? ''}
                    </div>
                    <AsyncMoleculeDrawing
                        smiles={this.experiment.batches.getSmiles(row.original.msd_identifier)!}
                        height='100%'
                        drawer={this.experiment.drawer}
                        atomHighlights={row.original.msd_site ? [row.original.msd_site] : []}
                        autosize
                        width='100%'
                    />
                </div>
            ),
        },
        {
            Header: 'BB',
            id: 'bb',
            width: 140,
            Cell: ({ row }: { row: Row<EnumeratedReaction> }) => (
                <div className='position-relative w-100 h-100'>
                    <div className='text-secondary hte-experiment-enumeration-step-products-label'>
                        #{this.experiment.reactants.getReactant(row.original.bb_identifier)?.bb_order ?? ''}
                    </div>
                    <AsyncMoleculeDrawing
                        smiles={this.experiment.batches.getSmiles(row.original.bb_identifier)!}
                        height='100%'
                        drawer={this.experiment.drawer}
                        atomHighlights={row.original.bb_site ? [row.original.bb_site] : []}
                        autosize
                        width='100%'
                    />
                </div>
            ),
        },
        {
            Header: 'Error',
            id: 'error',
            accessor: (row) => row.error ?? '',
            Cell: ({ value }: { value: string }) => (
                <div className='hte-experiment-enumeration-step-error-cell text-warning' title={value}>
                    {value}
                </div>
            ),
            width: 190,
        },
        {
            Header: '',
            disableSortBy: true,
            id: 'actions',
            width: 58,
            Cell: ({ row }: { row: Row<EnumeratedReaction> }) => (
                <div className='hte-experiment-enumeration-step-actions-cell'>
                    <Button variant='link' size='sm' onClick={() => this.removeEnumeratedReaction(row.original)}>
                        <FontAwesomeIcon size='sm' className='text-danger' icon={faRemove} />
                    </Button>
                </div>
            ),
        },
    ];

    exportEnumeratedCSV() {
        const csv = createEnumeratedReactionsCSV(this.state.reactions.value, this);
        saveAs(new Blob([csv], { type: 'text/csv' }), `${formatHTEId(this.experiment.id)}-reactions-${Date.now()}.csv`);
    }

    async loadReactants(input: string | string[], type: ReactantType) {
        try {
            this.state.loadingReactants.next(type);
            const identifiers = typeof input === 'string' ? splitInput(input) : input;
            const loadedReactants = await this._loadReactants(identifiers, type);
            if (loadedReactants.length === 0) return;

            const existing = new Set<string>(this.experiment.design.reactants.map((r) => r.identifier));
            const reactants: Reactant[] = [];
            const notAdded = new Set<string>();
            for (const r of loadedReactants) {
                if (existing.has(r.identifier)) {
                    notAdded.add(r.identifier);
                    continue;
                }
                existing.add(r.identifier);
                reactants.push(r);
            }

            if (!reactants.length) {
                ToastService.show({
                    type: 'warning',
                    message: 'No new reactants added!',
                    timeoutMs: 5000,
                });
                return;
            }

            const { msds, bbs } = this.experiment.reactants.groups;
            const count = type === 'msd' ? msds.length : bbs.length;
            const field: keyof Reactant = type === 'msd' ? 'msd_order' : 'bb_order';
            for (let i = 0; i < reactants.length; i++) {
                reactants[i][field] = count + i + 1;
            }
            this.experiment.addReactants(reactants);

            ToastService.show({
                type: 'success',
                message: `Added ${reactants.length} new reactant(s)`,
                timeoutMs: 5000,
            });
            if (notAdded.size > 0) {
                ToastService.show({
                    type: 'warning',
                    message: `Already present: ${Array.from(notAdded).join(', ')}`,
                    timeoutMs: 15000,
                });
            }
        } catch (err) {
            reportErrorAsToast('Add Reactants', err);
        } finally {
            this.state.loadingReactants.next(undefined);
        }
    }

    async reorderReactants(input: string, type: ReactantType) {
        try {
            const identifiers = splitInput(input);
            const loadedReactants = await this._loadReactants(identifiers, type);
            if (loadedReactants.length === 0) return;

            const { msds, bbs } = this.experiment.reactants.groups;
            const all = type === 'msd' ? msds : bbs;

            if (loadedReactants.length !== all.length) {
                throw new Error('The number of new reactants does not match the existing one');
            }

            const existingKeySet = new Set(all.map((r) => this.experiment.batches.getEntityKey(r.identifier)));
            const newKeySet = new Set(loadedReactants.map((r) => this.experiment.batches.getEntityKey(r.identifier)));

            const union = new Set([...Array.from(existingKeySet), ...Array.from(newKeySet)]);

            if (existingKeySet.size !== union.size || newKeySet.size !== union.size) {
                throw new Error('New reactants do not match the existing ones');
            }

            const replacements: Record<string, Reactant> = {};
            for (let i = 0; i < loadedReactants.length; i++) {
                // Need a clone here
                replacements[all[i].identifier] = { ...loadedReactants[i] };
            }

            this.applyReplaceReactants(replacements, 'reorder');

            ToastService.show({
                type: 'success',
                message: `Reactants re-ordered`,
                timeoutMs: 2500,
            });
        } catch (err) {
            reportErrorAsToast('Add Reactants', err);
        }
    }

    async replaceSingleReactant(reactant: Reactant, input: string) {
        try {
            const reactants = await this._loadReactants([input.trim()], reactant.type);
            const existing = new Set<string>(this.experiment.design.reactants.map((r) => r.identifier));
            for (const r of reactants) {
                if (existing.has(r.identifier)) {
                    throw new Error(`Batch ${r.identifier} already added.`);
                }
            }

            const oldCompoundId = this.experiment.batches.getCompound(reactant.identifier)?.id;
            const newCompoundId = this.experiment.batches.getCompound(reactants[0].identifier)?.id;
            this.applyReplaceReactants(
                { [reactant.identifier]: reactants[0] },
                oldCompoundId !== newCompoundId ? 'full-replace' : 'batch-change'
            );
        } catch (err) {
            reportErrorAsToast('Replace', err);
        }
    }

    private async _loadReactants(identifiers: string[], type: ReactantType) {
        const loaded = await HTEApi.loadReactants({ identifiers });

        this.experiment.batches.addBatches(loaded.flatMap((r) => r.batches));
        this.experiment.batches.addCompounds(loaded.map((r) => r.compound));

        const { enumerationInfo } = this.experiment;
        let reactionSites: number[][][] | undefined;
        if (enumerationInfo?.reaction_id) {
            for (const r of loaded) {
                if (!this.experiment.batches.getSmiles(r.pivot_identifier)) {
                    throw new Error(`Missing smiles for ${r.pivot_identifier}`);
                }
            }
            reactionSites = await EnumerationAPI.reactionSitesSimple({
                reaction_id: enumerationInfo.reaction_id,
                inputs: loaded.map((r) => ({ smiles: this.experiment.batches.getSmiles(r.pivot_identifier)! })),
            });
        }

        return loaded.map<Reactant>((r, i) => this.createReactant(r, type, reactionSites?.[i]));
    }

    private createReactant(r: HTEExperimentLoadReactantEntry, type: ReactantType, reactionSites?: number[][]) {
        const { enumerationInfo } = this.experiment;

        const entity = this.experiment.batches.getEntity(r.pivot_identifier);
        if (!entity) {
            throw new Error(`${r.pivot_identifier} is missing.`);
        }

        let identifier;
        if (r.input_identifier) {
            identifier = r.input_identifier;
        } else if (type === 'msd' && entity.msd_identifier) {
            identifier = entity.msd_identifier;
        } else if (type === 'bb' && entity.bb_identifier) {
            identifier = entity.bb_identifier;
        } else {
            identifier = entity.identifier!;
        }

        return createReactantFromEntity({
            entity,
            identifier,
            barcode: r.vial_barcode,
            type,
            group: type === 'bb' ? '1' : undefined,
            enumeration:
                reactionSites && enumerationInfo?.reaction_id
                    ? {
                          reaction_id: enumerationInfo.reaction_id,
                          reaction_sites: reactionSites,
                          site_index: reactionSites.length === 1 ? 0 : undefined,
                      }
                    : undefined,
        });
    }

    async syncReactionSites() {
        const { enumerationInfo } = this.experiment;
        if (!enumerationInfo?.reaction_id) return;
        const reactants = [...this.experiment.design.reactants];

        const inputs = reactants
            .map((r, i) => [r, i] as [Reactant, number])
            .filter((r) => r[0].type === 'msd' || r[0].type === 'bb');

        if (inputs.length === 0) return;

        try {
            const reactionSites = await EnumerationAPI.reactionSitesSimple({
                reaction_id: enumerationInfo.reaction_id,
                inputs: inputs.map((r) => ({ smiles: this.experiment.batches.getSmiles(r[0].identifier)! })),
            });

            for (let i = 0; i < inputs.length; i++) {
                reactants[inputs[i][1]] = {
                    ...inputs[i][0],
                    enumeration: {
                        reaction_id: enumerationInfo.reaction_id,
                        reaction_sites: reactionSites![i],
                        site_index: reactionSites![i].length === 1 ? 0 : undefined,
                    },
                };
            }

            this.experiment.setReactants(reactants);
        } catch (err) {
            reportErrorAsToast('Reaction Sites', err);
        }
    }

    async enumerate() {
        try {
            await this._enumerate();
        } catch (err) {
            reportErrorAsToast('Enumeration', err);
        }
    }

    private async _enumerate() {
        if (!this.experiment.enumerationInfo?.reaction_id) return;

        const { msds, bbs } = this.experiment.reactants.groups;

        if (msds.length === 0 || bbs.length === 0) {
            return;
        }

        const msdMap = new Map(msds.map((r) => [r.msd_order!, r]));
        const bbMap = new Map(bbs.map((r) => [r.bb_order, r]));

        const enumeration = await EnumerationAPI.enumerate({
            reaction_id: this.experiment.enumerationInfo.reaction_id!,
            msds_inputs: msds.map((r) => ({
                order: r.msd_order!,
                smiles: this.experiment.batches.getSmiles(r.identifier)!,
                included_sites: getReactantSites(r),
            })),
            bbs_inputs: bbs.map((r) => ({
                order: r.bb_order!,
                smiles: this.experiment.batches.getSmiles(r.identifier)!,
                included_sites: getReactantSites(r),
            })),
        });

        const { reactions: existingReactions } = this.experiment.design;
        const existingMap = new Map(existingReactions.map((r) => [`${r.msd_identifier}.+.${r.bb_identifier}`, r]));

        const reactions = enumeration.map<EnumeratedReaction>(([msd, bb, p]) => ({
            reaction_id: this.experiment.enumerationInfo!.reaction_id!,
            msd_site: this.experiment.reactants.getReactionSite(msdMap.get(msd)!.identifier),
            bb_site: this.experiment.reactants.getReactionSite(bbMap.get(bb)!.identifier),
            msd_identifier: msdMap.get(msd)!.identifier,
            bb_identifier: bbMap.get(bb)!.identifier,
            // NOTE: for debugging
            // products: [...p.products, 'CCC'],
            // products: [...p.products, 'CN(C)'], // To test failed CMPD registration since salts are removed
            products: p.products,
            current_product: undefined,
            error: p.error?.join('; '),
        }));

        for (const r of reactions) {
            const key = `${r.msd_identifier}.+.${r.bb_identifier}`;
            const existing = existingMap.get(key);
            const existingSmiles = existing ? this.experiment.batches.getSmiles(existing.product_identifier) : '';

            const isSameSite =
                existing?.enumeration &&
                existing.enumeration.reaction_id === r.reaction_id &&
                arrayEqualWithBlanks(existing.enumeration.bb_site, r.bb_site) &&
                arrayEqualWithBlanks(existing.enumeration.msd_site, r.msd_site);

            if (r.products.length === 0 && existingSmiles) {
                if (isSameSite || !existing?.enumeration) {
                    r.products = [existingSmiles];
                    r.current_product = existingSmiles;
                }
            } else if (isSameSite) {
                if (!r.products.includes(existingSmiles!)) r.products.push(existingSmiles!);
                r.current_product = existingSmiles!;
            } else if (r.products.length === 1) {
                r.current_product = r.products[0];
            }
        }

        this.state.reactions.next(reactions);
    }

    async useAsReactions() {
        try {
            const enumerated = this.state.reactions.value;
            const smiles = Array.from(
                new Set(enumerated.filter((r) => r.current_product).map((r) => r.current_product!))
            );
            const compounds = await HTEApi.registerCompounds({
                smiles,
                project: this.experiment.state.details.value.project,
            });

            const missingRegistrations = Array.from(Object.entries(compounds)).filter(([_, c]) => !c);
            if (missingRegistrations.length) {
                const missingSet = new Set(missingRegistrations.map(([s]) => s));

                const missingReactions = enumerated.filter((r) => missingSet.has(r.current_product!));
                const errors: string[] = [];

                for (const r of missingReactions) {
                    errors.push(
                        `- ${r.current_product} [#${
                            this.experiment.reactants.getReactant(r.msd_identifier).msd_order
                        } + #${this.experiment.reactants.getReactant(r.bb_identifier).bb_order}]`
                    );
                }

                ToastService.show({
                    type: 'danger',
                    timeoutMs: 45000,
                    message: `Failed to register ${missingRegistrations.length} CMPD:\n${errors.join('\n')}`,
                });
                return;
            }

            this.experiment.batches.addCompounds(Array.from(Object.values(compounds)));

            const reactions: Reaction[] = [];
            for (const r of enumerated) {
                if (!r.current_product) continue;

                const compound = compounds[r.current_product];
                reactions.push({
                    msd_identifier: r.msd_identifier,
                    bb_identifier: r.bb_identifier,
                    product_identifier: compound.identifier,
                    product_molecular_weight: compound.molecular_weight,
                    enumeration: {
                        reaction_id: r.reaction_id,
                        msd_site: this.experiment.reactants.getReactionSite(r.msd_identifier),
                        bb_site: this.experiment.reactants.getReactionSite(r.bb_identifier),
                    },
                });
            }

            this.experiment.setReactions(reactions);

            ToastService.show({
                type: 'success',
                message: 'Reactions created',
                timeoutMs: 2500,
            });

            this.experiment.state.step.next('reactions');
        } catch (err) {
            reportErrorAsToast('Compound Registration', err);
        }
    }

    private applyReplaceReactants(
        mapping: Record<string, Reactant>,
        kind: 'batch-change' | 'reorder' | 'full-replace'
    ) {
        const { design } = this.experiment;
        const reactants = [...design.reactants];
        const replaceMap = new Map<string, string | null>();

        for (const [identifier, replacement] of Array.from(Object.entries(mapping))) {
            const idx = this.experiment.reactants.getReactantIndex(identifier);
            if (idx >= 0) {
                replacement.msd_order = reactants[idx].msd_order;
                replacement.bb_order = reactants[idx].bb_order;

                if (kind !== 'reorder') {
                    replacement.concentration = reactants[idx].concentration;
                    replacement.density = reactants[idx].density;
                    replacement.equivalence = reactants[idx].equivalence;
                    replacement.overage = reactants[idx].overage;
                    replacement.group_name = reactants[idx].group_name;
                }

                reactants[idx] = replacement;
                replaceMap.set(identifier, replacement.identifier);
            }
        }

        const newPlate = updateWells(design.plate, new Array<0 | 1>(design.plate.layout).fill(1), 'replace', {
            replaceMap,
        });

        if (this.state.reactions.value.length > 0) {
            this.state.reactions.next([]);
        }

        let newReactions = design.reactions;
        if (kind === 'batch-change') {
            newReactions = [...design.reactions];
            for (let i = 0; i < newReactions.length; i++) {
                const r = newReactions[i];
                if (replaceMap.has(r.msd_identifier) || replaceMap.has(r.bb_identifier)) {
                    newReactions[i] = {
                        ...r,
                        msd_identifier: replaceMap.get(r.msd_identifier) ?? r.msd_identifier,
                        bb_identifier: replaceMap.get(r.bb_identifier) ?? r.bb_identifier,
                    };
                }
            }
        }

        this.experiment.state.design.next({
            ...design,
            plate: newPlate ?? design.plate,
            reactants,
            reactions: newReactions,
        });

        if (kind === 'full-replace' && design.reactions.length > 0) {
            ToastService.show({
                type: 'warning',
                message: 'Reactant(s) replated, re-enumeration required!',
                timeoutMs: 5000,
            });
        }
    }

    removeReactant(identifier: string) {
        const { design } = this.experiment;
        const reactant = this.experiment.reactants.getReactant(identifier);

        if (reactant.type !== 'bb' && reactant.type !== 'msd') {
            log.warn('Only msd/bb reactatnts can be removed using EnumerationModel.removeReactant.');
            return;
        }

        const newPlate = updateWells(design.plate, new Array<0 | 1>(design.plate.layout).fill(1), 'remove', {
            reactant,
        });
        const reactants = [...design.reactants];
        const all =
            reactant.type === 'msd' ? this.experiment.reactants.groups.msds : this.experiment.reactants.groups.bbs;
        const orderKey: keyof Reactant = reactant.type === 'msd' ? 'msd_order' : 'bb_order';

        for (const r of all) {
            const idx = this.experiment.reactants.getReactantIndex(r.identifier);
            if (idx >= 0 && r[orderKey]! > reactant[orderKey]!) {
                reactants[idx] = { ...r, [orderKey]: r[orderKey]! - 1 };
            }
        }
        const rIdx = this.experiment.reactants.getReactantIndex(reactant.identifier);
        if (rIdx >= 0) {
            reactants.splice(rIdx, 1);
        }

        if (this.state.reactions.value.length > 0) {
            this.state.reactions.next([]);
        }

        const newReactions: Reaction[] = [];
        for (const r of design.reactions) {
            if (r.bb_identifier !== identifier && r.msd_identifier !== identifier) {
                newReactions.push(r);
            }
        }

        this.experiment.state.design.next({
            ...design,
            plate: newPlate ?? design.plate,
            reactants,
            reactions: newReactions,
        });
    }

    removeEnumeratedReaction(reaction: EnumeratedReaction) {
        const reactions = this.state.reactions.value.filter((r) => r !== reaction);
        this.state.reactions.next(reactions);
    }

    constructor(
        public experiment: import('../experiment-model').HTEExperimentModel,
        public chemistry: HTEEnumerationReactionInfoEntry[]
    ) {
        super();

        this.reactionOptions = chemistry.map((r) => ({
            label: `${r.name} (${r.kind})`,
            value: r,
        }));
    }
}

function getReactantSites(reactant: Reactant) {
    const hasSiteIndex = typeof reactant.enumeration?.site_index === 'number';
    const siteCount = reactant.enumeration?.reaction_sites.length ?? 0;

    return hasSiteIndex
        ? [reactant.enumeration!.reaction_sites[reactant.enumeration!.site_index!]]
        : siteCount > 0
        ? reactant.enumeration!.reaction_sites
        : undefined;
}

function SiteDrawing({
    reactant,
    smiles,
    model,
}: {
    reactant: Reactant;
    smiles: string;
    model: import('../experiment-model').HTEExperimentModel;
}) {
    return (
        <AsyncMoleculeDrawing
            smiles={smiles}
            height='100%'
            drawer={model.drawer}
            atomHighlights={getReactantSites(reactant)}
            autosize
            width='100%'
        />
    );
}

function SiteSelect({
    reactant,
    model,
    disabled,
}: {
    reactant: Reactant;
    model: import('../experiment-model').HTEExperimentModel;
    disabled?: boolean;
}) {
    const smiles = model.batches.getSmiles(reactant.identifier);
    if (!smiles) return null;
    if (disabled) return <SiteDrawing smiles={smiles} reactant={reactant} model={model} />;

    const select = () => {
        DialogService.open({
            type: 'generic',
            content: SiteSelectDialogContent,
            model: { experiment: model, smiles, sites: reactant.enumeration?.reaction_sites },
            defaultState: reactant.enumeration!.site_index,
            title: 'Select Reaction Site',
            onOk: (state) => {
                model.reactants.editValue('enumeration', reactant.identifier, {
                    ...reactant.enumeration!,
                    site_index: state,
                });
            },
        });
    };

    const hasSiteIndex = typeof reactant.enumeration?.site_index === 'number';
    const siteCount = reactant.enumeration?.reaction_sites.length ?? 0;
    // NOTE: Using div with onClick to avoid button nesting since react complains about that
    return (
        <div className='w-100 h-100 btn btn-link p-0 position-relative' onClick={select}>
            <SiteDrawing smiles={smiles} reactant={reactant} model={model} />
            {hasSiteIndex && siteCount > 1 && (
                <div style={{ position: 'absolute', left: 0, top: 8, fontSize: '0.75rem' }} className='text-secondary'>
                    {reactant.enumeration!.site_index! + 1}/{reactant.enumeration?.reaction_sites.length}
                </div>
            )}
        </div>
    );
}

function SiteSelectDialogContent({
    stateSubject,
    model: { experiment, smiles, sites },
}: {
    stateSubject: BehaviorSubject<number | undefined>;
    model: {
        experiment: import('../experiment-model').HTEExperimentModel;
        smiles: string;
        sites: number[][];
    };
}) {
    const current = useBehavior(stateSubject);

    return (
        <div>
            {sites.length > 1 &&
                sites.map((site, i) => (
                    <Button
                        variant={i === current ? 'outline-primary' : 'link'}
                        onClick={() => stateSubject.next(i)}
                        key={i}
                        style={{ width: 160, height: 120, borderWidth: i === current ? 4 : 1 }}
                        className='me-2 mb-2'
                    >
                        <AsyncMoleculeDrawing
                            smiles={smiles}
                            drawer={experiment.drawer}
                            atomHighlights={[site]}
                            autosize
                            width='100%'
                            showChemDraw={false}
                            showCopy={false}
                        />
                    </Button>
                ))}
            <Button
                variant={typeof current !== 'number' || sites.length === 0 ? 'outline-primary' : 'link'}
                onClick={() => stateSubject.next(undefined)}
                style={{ width: 160, height: 120, borderWidth: typeof current !== 'number' ? 4 : 1 }}
                className='me-2 mb-2'
            >
                <AsyncMoleculeDrawing
                    smiles={smiles}
                    drawer={experiment.drawer}
                    atomHighlights={sites}
                    autosize
                    width='100%'
                    showChemDraw={false}
                    showCopy={false}
                />
            </Button>
        </div>
    );
}

function ProductSelect({
    reaction,
    model,
}: {
    reaction: EnumeratedReaction;
    model: import('../experiment-model').HTEExperimentModel;
}) {
    const status = (
        <div className='text-secondary hte-experiment-enumeration-step-products-label'>
            {!!reaction.current_product && <FontAwesomeIcon size='sm' className='text-success' icon={faCheck} />}
            {!reaction.current_product && reaction.products.length > 1 && (
                <FontAwesomeIcon size='sm' className='text-warning' icon={faQuestion} />
            )}
            {reaction.products.length === 0 && <FontAwesomeIcon size='sm' className='text-danger' icon={faRemove} />}
        </div>
    );

    const inner = reaction.current_product ? (
        <AsyncMoleculeDrawing
            smiles={reaction.current_product}
            drawer={model.drawer}
            autosize
            height='100%'
            width='100%'
            showChemDraw={false}
            showCopy={false}
        />
    ) : (
        <span className='text-secondary'>Not selected</span>
    );

    const select = () => {
        DialogService.open({
            type: 'generic',
            content: ProductSelectDialogContent,
            model: { experiment: model, reaction },
            defaultState: reaction.current_product,
            title: 'Select Product',
            onOk: (state) => {
                let reactions = model.enumeration.state.reactions.value;
                const idx = reactions.findIndex(
                    (r) => r.msd_identifier === reaction.msd_identifier && r.bb_identifier === reaction.bb_identifier
                );
                if (idx < 0) return;
                reactions = [...reactions];
                const current = reactions[idx];
                const products =
                    current.products.includes(state) || !state ? current.products : [...current.products, state];
                reactions[idx] = { ...current, products, current_product: state || undefined };
                model.enumeration.state.reactions.next(reactions);
            },
        });
    };

    return (
        <Button
            variant='link'
            className='w-100 h-100 position-relative d-flex justify-content-center align-items-center'
            onClick={select}
        >
            {inner}
            {status}
        </Button>
    );
}

function ProductSelectDialogContent({
    stateSubject,
    model: { experiment, reaction },
}: {
    stateSubject: BehaviorSubject<string | undefined>;
    model: {
        experiment: import('../experiment-model').HTEExperimentModel;
        reaction: EnumeratedReaction;
    };
}) {
    const current = useBehavior(stateSubject);

    return (
        <div>
            {reaction.products.map((product, i) => (
                <Button
                    variant={product === current ? 'outline-primary' : 'link'}
                    onClick={() => stateSubject.next(product)}
                    key={i}
                    style={{ width: 160, height: 120, borderWidth: product === current ? 4 : 1 }}
                    className='me-2 mb-2'
                >
                    <AsyncMoleculeDrawing
                        smiles={product}
                        drawer={experiment.drawer}
                        autosize
                        width='100%'
                        showChemDraw={false}
                        showCopy={false}
                    />
                </Button>
            ))}
            <div className='mt-2'>
                <TextInput
                    placeholder='Enter SMILES ...'
                    value={current}
                    setValue={(v) => stateSubject.next(v?.trim())}
                />
            </div>
        </div>
    );
}

export function AddOrReplaceReactantDialogControls({
    stateSubject,
    model,
}: {
    stateSubject: BehaviorSubject<{ input: string }>;
    model: { kind: 'replace' | 'add' | 'reorder' };
}) {
    const state = useBehavior(stateSubject);
    return (
        <div>
            <TextInput
                textarea={model.kind !== 'replace'}
                value={state.input}
                setValue={(input) => stateSubject.next({ input })}
                placeholder={
                    model.kind === 'replace'
                        ? 'Enter a barcode, batch or compound identifier'
                        : model.kind === 'add'
                        ? 'Enter a list of barcodes, batch or compound identifiers'
                        : 'Enter a list of barcodes, batch or compound identifiers for the same compounds as currently present in the list'
                }
                autoFocus
            />
        </div>
    );
}

function ReplaceReactantButton({
    model,
    reactant,
    disabled,
}: {
    model: EnumerationModel;
    reactant: Reactant;
    disabled?: boolean;
}) {
    const [replaceState, applyReplace] = useAsyncAction();

    const onClick = () => {
        DialogService.open({
            type: 'generic',
            title: `Replace ${reactant.identifier} #${reactant.bb_order ?? reactant.msd_order}`,
            model: { kind: 'replace' },
            defaultState: { input: '' },
            content: AddOrReplaceReactantDialogControls,
            onOk: (state) => applyReplace(model.replaceSingleReactant(reactant, state.input)),
        });
    };
    return (
        <Button variant='link' size='sm' onClick={onClick} disabled={disabled}>
            {replaceState.isLoading && <Spinner animation='border' size='sm' role='status' />}
            {!replaceState.isLoading && (
                <FontAwesomeIcon size='sm' className='text-info' icon={faArrowRightArrowLeft} />
            )}
        </Button>
    );
}

const HTEEnumerationReactionCSVColumns = [
    'MSD SMILES',
    'MSD Identifier',
    'MSD Order',
    'BB SMILES',
    'BB Identifier',
    'BB Order',
    'BB Group',
    'Product SMILES',
    'Product Count',
    'Products',
    'Error',
] as const;

type HTEEnumerationReactionCSVRow = Record<(typeof HTEEnumerationReactionCSVColumns)[number], string | number>;

function createEnumeratedReactionsCSV(reactions: EnumeratedReaction[], model: EnumerationModel) {
    const rows: HTEEnumerationReactionCSVRow[] = [];
    const { experiment } = model;

    for (const r of reactions) {
        const msd = experiment.reactants.getReactant(r.msd_identifier);
        const bb = experiment.reactants.getReactant(r.bb_identifier);

        rows.push({
            'MSD SMILES': experiment.batches.getSmiles(r.msd_identifier) ?? '',
            'MSD Identifier': r.msd_identifier,
            'MSD Order': msd.msd_order ?? '',
            'BB SMILES': experiment.batches.getSmiles(r.bb_identifier) ?? '',
            'BB Identifier': r.bb_identifier,
            'BB Order': bb.bb_order ?? '',
            'BB Group': bb.group_name ?? '',
            'Product SMILES': r.current_product ?? '',
            'Product Count': r.products.length,
            Products: r.products.join(' | '),
            Error: r.error ?? '',
        });
    }

    const csvRows = objectsToRowArrays(rows, HTEEnumerationReactionCSVColumns);
    return arrayToCsv(csvRows);
}
