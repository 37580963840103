import { faSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useId, useMemo } from 'react';
import { Button, Form, Spinner, Tab, Tabs } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Select, { createFilter } from 'react-select';
import Split from 'react-split-it';
import { AsyncMoleculeDrawing } from '../../components/common/AsyncMoleculeDrawing';
import { ErrorMessage } from '../../components/common/Error';
import { SimpleSelectOptionInput } from '../../components/common/Inputs';
import Loading from '../../components/common/Loading';
import { PropertyNameValue } from '../../components/common/PropertyNameValue';
import { CustomSelectClassNames, DefaultSelectStyles } from '../../components/common/selectStyles';
import { PotensNMToggle } from '../../components/common/UnitToggle';
import { DataTableControl, DataTableGlobalFilter, saveDataTableAsCSV } from '../../components/DataTable';
import { PageTemplate } from '../../components/Layout/Layout';
import Pane from '../../components/Pane/Pane';
import { PotensAsNmProp } from '../../lib/assays/table';
import { useAsyncAction } from '../../lib/hooks/useAsyncAction';
import useBehavior from '../../lib/hooks/useBehavior';
import { formatDatetime, parseFileSystemDate } from '../../lib/util/dates';
import { tryGetErrorMessage } from '../../lib/util/errors';
import { roundValue } from '../../lib/util/roundValues';
import { HTEDetailsModel, HTEPlateDetailsWrapper, PlateOption } from './details-model';
import { HTEApi } from './experiment-api';
import { formatHTEId } from './experiment-data';
import { PlateVisual } from './plate/PlateVisual';
import { getFirstSelectedIndex, getWellIndexLabel } from './plate/utils';
import { createHTEHistoryTable, HTEHistoryTable } from './sign/history';
import { CompoundIdentifier } from './steps/reagents-model';
import { AsyncActionButton } from '../../components/common/AsyncButton';

async function loadModel(id: string, tab: string) {
    const details = await HTEApi.details(+id);
    const model = new HTEDetailsModel(details);
    model.state.tab.next(tab);
    return model;
}

export function HTEDetailsUI() {
    const { id } = useParams();
    const location = useLocation();
    const [searchParams, _] = useSearchParams();
    const defaultTab = searchParams.get('tab');
    const [_model, _loadModel] = useAsyncAction<HTEDetailsModel>();

    useEffect(() => {
        if (id && !Number.isNaN(+id)) _loadModel(loadModel(id, defaultTab ?? 'plate'));
    }, [id]);

    const model = _model.result;

    useEffect(() => {
        if (!model) return;
        const m = model;
        return () => m.dispose();
    }, [model]);

    return (
        <PageTemplate
            title='HTE'
            breadcrumb={{
                title: id ? formatHTEId(+id) : '',
                href: location.pathname,
            }}
        >
            <div className='d-flex h-100'>
                {_model.isLoading && (
                    <div className='w-100'>
                        <Loading />
                    </div>
                )}
                {_model.error && (
                    <ErrorMessage header='Experiment Load Error' message='Something went terribly wrong.' />
                )}
                {model && (
                    <Split direction='horizontal' gutterSize={6} sizes={[0.35, 0.65]}>
                        <Split direction='vertical' gutterSize={6} sizes={[0.5, 0.25, 0.25]}>
                            <Pane
                                title='Plate'
                                topRightContent={
                                    <Button variant='link' className='invisible'>
                                        &nbsp;
                                    </Button>
                                }
                            >
                                <Plate model={model} />
                            </Pane>
                            <Pane title='Reaction'>
                                <CurrrentReaction model={model} />
                            </Pane>
                            <Pane title='Details'>
                                <ExperimentInfoPanel model={model} />
                            </Pane>
                        </Split>
                        <Pane
                            title='Library Overview'
                            className='hte-details-tabs-pane'
                            topRightContent={
                                <>
                                    <SigningButton model={model} />
                                </>
                            }
                        >
                            <OverviewTabs model={model} />
                        </Pane>
                    </Split>
                )}
            </div>
        </PageTemplate>
    );
}

export function SigningButton({ model }: { model?: HTEDetailsModel }) {
    const navigate = useNavigate();

    if (!model) return null;

    return (
        <Button variant='link' className='me-1' onClick={() => navigate(`/hte/${model.info.experiment.id}/sign`)}>
            <FontAwesomeIcon className='me-1' icon={faSignature} fixedWidth />
            Signing
        </Button>
    );
}

function EmptyPlateNotice() {
    return <p className='text-secondary'>No plates associated with this experiment.</p>;
}

function Plate({ model }: { model: HTEDetailsModel }) {
    const details = useBehavior(model.state.details);
    const numPlates = model.info.plates.index.length;

    if (numPlates === 0) return <EmptyPlateNotice />;
    if (!details) return <Loading />;

    return (
        <div className='d-flex flex-column w-100 h-100'>
            <div className='mb-2'>
                <PlateColoring details={details} />
            </div>
            <div className='flex-grow-1'>
                <PlateVisual model={details.visual} />
            </div>
            <PlateSelection details={details} />
        </div>
    );
}

function PlateColoring({ details }: { details: HTEPlateDetailsWrapper }) {
    const colorBy = useBehavior(details.state.colorBy);

    return (
        <div>
            <SimpleSelectOptionInput<string>
                options={details.coloringOptions.map((o) => [o, o])}
                value={colorBy}
                setValue={(v) => details.state.colorBy.next(v)}
            />
        </div>
    );
}

function CurrrentReaction({ model }: { model: HTEDetailsModel }) {
    const details = useBehavior(model.state.details);

    if (!details) return null;

    return <CurrentReactionDrawing details={details} />;
}

function CurrentReactionDrawing({ details }: { details: HTEPlateDetailsWrapper }) {
    const selection = useBehavior(details.visual.state.selection);

    const fst = getFirstSelectedIndex(selection);

    const smiles = useMemo(() => {
        const reaction = details.findReaction(fst);
        if (!reaction || !reaction.bb || !reaction.msd) return undefined;
        return `${reaction.bb.structure.smiles}.${reaction.msd.structure.smiles}>${reaction.reactants
            .map((r) => r.structure.smiles)
            .join('.')}>${reaction.product?.structure.smiles}`;
    }, [fst, details]);

    if (!smiles) {
        return <div className='text-secondary'>No reaction available.</div>;
    }

    return <AsyncMoleculeDrawing width='100%' drawer={details.smiles.moleculeDrawer} smiles={smiles} />;
}

function PlateSelection({ details }: { details: HTEPlateDetailsWrapper }) {
    const selection = useBehavior(details.visual.state.selection);

    const fst = getFirstSelectedIndex(selection);
    const sample = details.details.plate?.samples[fst];
    const label = getWellIndexLabel(details.details.plate.size, fst);
    return (
        <div className='mt-2 ms-2 me-2 hte-details-plate-selection-info'>
            {fst < 0 || (!details.details.plate && 'No Selection')}
            {sample && (
                <>
                    ({label}) <CompoundIdentifier value={details.batches.getBatchFromId(sample.batch_id)?.identifier} />{' '}
                    {!!sample.solute_mass && (
                        <>
                            <small>Amount</small>
                            {` ${roundValue(3, sample.solute_mass * 1000)} mg `}
                        </>
                    )}
                    {!!sample.solvent_volume && (
                        <>
                            <small>Vol.</small>
                            {` ${roundValue(3, sample.solvent_volume * 1e6)} mL `}
                        </>
                    )}
                    {!!sample.concentration && (
                        <>
                            <small>Conc</small>
                            {` ${roundValue(3, sample.concentration)} M `}
                        </>
                    )}
                    {!!sample.solvent && `(${sample.solvent})`}
                </>
            )}
            {!sample && <div>{label || '(Nothing Selected)'}</div>}
        </div>
    );
}

function OverviewTabs({ model }: { model: HTEDetailsModel }) {
    const tab = useBehavior(model.state.tab);
    const [_, setSearchParams] = useSearchParams();

    return (
        <Tabs
            id='hte-details-tabs'
            activeKey={tab}
            onSelect={(eventKey: string | null) => {
                model.state.tab.next(eventKey as any);
                setSearchParams({ tab: eventKey ?? '' });
            }}
            transition={false}
        >
            <Tab title='Plates' eventKey='plate' className='my-3'>
                <AssaysTab model={model} />
            </Tab>
            <Tab title='Procedure' eventKey='procedure' className='my-3'>
                <ProcedureTab model={model} />
            </Tab>
            <Tab title='Observations' eventKey='observations' className='my-3'>
                <ObservationsTab model={model} />
            </Tab>
            <Tab title='History' eventKey='history' className='my-3 h-100'>
                <HistoryTab model={model} />
            </Tab>
        </Tabs>
    );
}

function AssaysTab({ model }: { model: HTEDetailsModel }) {
    const details = useBehavior(model.state.details);
    const numPlates = model.info.plates.index.length;

    return (
        <div className='d-flex w-100 h-100 flex-column'>
            <div className='flex-0 mb-2'>
                <div className='position-relative w-100 overflow-hidden'>
                    <SelectPlate model={model} details={details} />
                </div>
            </div>
            {!!details && <AssayTabTable details={details} />}
            {!details && numPlates > 0 && <Loading />}
            {!details && numPlates === 0 && <EmptyPlateNotice />}
        </div>
    );
}

function formatPlateOption({ label }: PlateOption) {
    return <>{label}</>;
}

function SelectPlate({ model, details }: { model: HTEDetailsModel; details?: HTEPlateDetailsWrapper }) {
    const value = useBehavior(model.state.currentPlate);
    const numPlates = model.info.plates.index.length;

    return (
        <>
            <Select
                options={model.plateOptions}
                value={value}
                isSearchable
                formatOptionLabel={formatPlateOption}
                filterOption={createFilter({ ignoreAccents: false, stringify: (o) => o.label })}
                menuPortalTarget={document.body}
                placeholder='Select plate...'
                classNames={CustomSelectClassNames}
                styles={DefaultSelectStyles}
                onChange={(o) => model.loadPlate(o as any)}
            />
            {!details && numPlates > 0 && (
                <Spinner
                    animation='border'
                    size='sm'
                    className='ms-2'
                    role='status'
                    style={{ position: 'absolute', top: 11, right: 32 }}
                />
            )}
        </>
    );
}

function AssayTabTable({ details }: { details: HTEPlateDetailsWrapper }) {
    const { table } = details;
    useBehavior(table.version);
    const selectAllId = useId();

    const potensAsNM = !!table.state.customState[PotensAsNmProp];

    return (
        <>
            <div className='d-flex mb-1 flex-0 justify-content-between'>
                <div className='d-flex align-items-center me-2 mb-2'>
                    <div className='me-2'>
                        <DataTableGlobalFilter table={table} />
                    </div>
                    <div>
                        <PotensNMToggle
                            units={{ use_potens: !potensAsNM }}
                            onToggle={(value: 'potens' | 'nM') =>
                                table.setCustomState(
                                    { [PotensAsNmProp]: value === 'nM' },
                                    { silent: false, clearRenderCache: true }
                                )
                            }
                        />
                    </div>
                </div>
                <div className='me-2 mb-2 w-50 d-flex justify-content-end'>
                    <Button
                        className='ms-2'
                        variant='outline-primary'
                        disabled={table.store.rowCount === 0}
                        onClick={() =>
                            saveDataTableAsCSV(details.details.plate.barcode, table, {
                                includeIfHidden: ['identifier'],
                            })
                        }
                    >
                        Download as CSV
                    </Button>
                </div>
            </div>
            <div className='flex-grow-1 position-relative'>
                <DataTableControl
                    table={table}
                    height='flex'
                    headerSize='lg'
                    onRowMouseEnter={(rowIndex, tbl) =>
                        details.highlightBatchIdentifier(tbl.store.getValue('identifier', rowIndex))
                    }
                    onRowMouseLeave={() => details.highlightBatchIdentifier()}
                />
            </div>
            <div className='mt-2 hte-details-table-column-select'>
                <span className='me-2'>Column Visibility:</span>
                <div className='d-inline-block me-2'>
                    <Form.Check
                        id={selectAllId}
                        label='All'
                        type='checkbox'
                        checked={table.state.hiddenColumns.length === 0}
                        onChange={(e) => table.setAllColumnsVisibility(e.target.checked)}
                    />{' '}
                </div>
                {table.allColumns
                    .filter((column) => !column.alwaysVisible && column.header)
                    .map((column) => (
                        <div key={column.id} className='d-inline-block me-2'>
                            <Form.Check
                                id={`toggle-selected-${column.id}-${selectAllId}`}
                                label={table.getColumnLabel(column.id)}
                                type='checkbox'
                                checked={!table.state.hiddenColumns.includes(column.id)}
                                onChange={(e) =>
                                    table.setColumnVisibility(column.id, e.target.checked, { updateOrder: true })
                                }
                                className='d-inline-block'
                            />
                        </div>
                    ))}
            </div>
        </>
    );
}

function ExperimentInfoPanel({ model }: { model: HTEDetailsModel }) {
    const {
        info: { experiment },
    } = model;

    return (
        <div>
            <div className='mb-4 mt-n2 font-body-small'>
                <PropertyNameValue field='Library identifier' value={`${formatHTEId(experiment.id)}`} />
                <PropertyNameValue field='Library name' value={experiment.name} />
                <PropertyNameValue field='Description' value={experiment.description} />
                <PropertyNameValue field='Project' value={experiment.project} />
                <PropertyNameValue field='Well layout' value={experiment.well_layout} />
                <PropertyNameValue field='Created by' value={experiment.created_by} />
                <PropertyNameValue
                    field='Created on'
                    value={formatDatetime(parseFileSystemDate(experiment.created_on), 'full')}
                />
                <PropertyNameValue
                    field='Last modified'
                    value={formatDatetime(parseFileSystemDate(experiment.modified_on), 'full')}
                />
                <PropertyNameValue
                    field='Executed on'
                    value={
                        experiment.executed_on
                            ? formatDatetime(parseFileSystemDate(experiment.executed_on), 'full')
                            : 'n/a'
                    }
                />
                {/* TODO: pending removal or display from snapshot history (separate PR) */}
                {/* <PropertyNameValue field='Signed by' value={experiment.signature?.signed_by ?? 'n/a'} />
                <PropertyNameValue
                    field='Signed on'
                    value={
                        experiment.signature?.signed_on
                            ? formatDatetime(parseFileSystemDate(experiment.signature?.signed_on), 'full')
                            : 'n/a'
                    }
                />
                <PropertyNameValue field='Counter signed by' value={experiment.counter_signature?.signed_by ?? 'n/a'} />
                <PropertyNameValue
                    field='Counter signed on'
                    value={
                        experiment.counter_signature?.signed_on
                            ? formatDatetime(parseFileSystemDate(experiment.counter_signature?.signed_on), 'full')
                            : 'n/a'
                    }
                /> */}
            </div>
            <div className='hstack gap-2'>
                <Link to={`/hte/${model.info.experiment.id}/design`} className='btn btn-outline-primary'>
                    View Experiment Design
                </Link>
                <AsyncActionButton action={() => model.analyticalExport()} variant='outline-primary'>
                    Analytical Export (CSV)
                </AsyncActionButton>
            </div>
        </div>
    );
}

function ProcedureTab({ model }: { model: HTEDetailsModel }) {
    const { procedure } = model.info.experiment;

    if (!procedure) {
        return (
            <div>
                <i>Procedure not entered.</i>
            </div>
        );
    }

    return (
        <div className='hte-details-markdown-wrapper'>
            <ReactMarkdown skipHtml>{procedure}</ReactMarkdown>
        </div>
    );
}

function ObservationsTab({ model }: { model: HTEDetailsModel }) {
    const { observations } = model.info.experiment;

    if (!observations) {
        return (
            <div>
                <i>No observations entered.</i>
            </div>
        );
    }

    return (
        <div className='hte-details-markdown-wrapper'>
            <ReactMarkdown skipHtml>{observations}</ReactMarkdown>
        </div>
    );
}

function HistoryTab({ model }: { model: HTEDetailsModel }) {
    const [history, loadHistory] = useAsyncAction();

    useEffect(() => {
        loadHistory(HTEApi.history(model.info.experiment.id));
    }, [model]);

    const table = useMemo(
        () => (history.result ? createHTEHistoryTable(history.result, model.info.experiment.id)[1] : undefined),
        [history.result]
    );

    return (
        <div className='d-flex w-100 h-100 flex-column'>
            {history.isLoading && <Loading />}
            {history.error && (
                <ErrorMessage header='Error loading experiment history' message={tryGetErrorMessage(history.error)} />
            )}
            {table && (
                <div className='flex-grow-1 position-relative'>
                    <HTEHistoryTable table={table} />
                </div>
            )}
        </div>
    );
}
