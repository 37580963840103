import { IconName, IconDefinition } from '@fortawesome/fontawesome-svg-core';

// This is a way to make a new icon to be treated like icons in the
// Font Awesome set - structure kind of copied from their icon definitions.
// see node_modules/@fortawesome/node_modules/@fortawesome/fontawesome-common-types/index.d.ts
// for the IconDefinition type definition

const iconName = 'ii-analytical' as IconName;
const prefix = 'fas';
const width: number = 16;
const height: number = 16;
const aliases: string[] = [];
const unicode: string = '';
const svgPathData: string = [
    'M8.58489 1.21965C8.4671 0.924193 8.2299 0.92931 8.08232 1.21987C7.54673 2.50723 7.03186 3.74264 6.5 5.01883L7.58804 5.63901L8.30624 4.08814C8.35554 4.19702 8.40277 4.29828 8.44784 4.39489C8.53868 4.58961 8.62069 4.76542 8.69291 4.94651C9.82621 7.78803 10.9571 10.6309 12.0863 13.4746C12.6196 14.8175 13.2383 15.0039 14.5 15.0039V13.5628C14.125 13.543 14.2458 13.5478 14.125 13.543C13.7193 13.5266 13.3458 13.4996 13.1145 12.8647C12.476 11.1117 11.7927 9.38073 11.1095 7.6498C10.8516 6.99661 10.5938 6.34341 10.3383 5.68904L10.0439 4.93427C9.56476 3.70564 9.0583 2.40696 8.58489 1.21965Z',
    'M4.5 8C4.87149 7.70057 5.1859 7.44715 5.5 7.1571C5.45037 7.07829 5.39606 6.99497 5.35252 7.00024C4.89617 7.05546 4.57736 7.40208 4.5 8Z',
    'M4.5 8C4.87149 7.70057 5.1859 7.44715 5.5 7.1571C5.45037 7.07829 5.39606 6.99497 5.35252 7.00024C4.89617 7.05546 4.57736 7.40208 4.5 8Z',
    'M7.83641 9.66663C8.4534 8.21647 8.08984 6.94658 7.09073 6.34271C7.05429 6.32466 7.02182 6.30821 6.99205 6.29314C6.92086 6.25708 6.8651 6.22884 6.8075 6.20515C5.72962 5.76165 4.62522 6.05 3.96275 6.94658C3.3156 7.82242 3.35025 9.00629 4.04768 9.84856C4.75056 10.6974 5.88162 10.9233 6.9349 10.4284C6.97951 10.4075 6.97096 10.4111 6.96085 10.4153C6.94566 10.4217 6.92696 10.4295 7.07951 10.3579L7.17543 10.4556L9.67913 13L10.5 12.1244C10.3508 11.9782 10.2076 11.8359 10.0679 11.6971C9.75935 11.3905 9.46769 11.1007 9.1659 10.8223C8.87183 10.5509 8.56713 10.2912 8.25579 10.0259C8.1172 9.90777 7.97727 9.78851 7.83641 9.66663ZM7.48282 8.30884C7.49777 9.1255 7.05894 9.67992 6.15633 9.98471C5.25056 10.0795 4.65985 9.76023 4.31413 9.04446C4.00267 8.3996 4.11796 7.77209 4.58064 7.24883C5.04402 6.72477 5.6463 6.55506 6.32821 6.76534C6.99825 6.97195 7.47001 7.60879 7.48282 8.30884Z',
    'M2.32512 15H1.5L1.50003 13.5995C2.91724 13.5995 3.11122 13.1544 3.41685 12.4164C3.51522 12.0931 3.65618 11.7809 3.8021 11.4576C3.86957 11.3082 3.93811 11.1563 4.00398 11L5.5 11.5668C5.34425 11.8419 5.19719 12.1189 5.05116 12.3941C4.69598 13.0632 4.34685 13.721 3.89355 14.3104C3.60825 14.6814 2.91978 15 2.32512 15Z',
].join('');

export const iiAnalytical: IconDefinition = {
    prefix,
    iconName,
    icon: [width, height, aliases, unicode, svgPathData],
};
