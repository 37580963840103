import { IconName, IconDefinition } from '@fortawesome/fontawesome-svg-core';

// This is a way to make a new icon to be treated like icons in the
// Font Awesome set - structure kind of copied from their icon definitions.
// see node_modules/@fortawesome/node_modules/@fortawesome/fontawesome-common-types/index.d.ts
// for the IconDefinition type definition

const iconName = 'ii-chart-size' as IconName;
const prefix = 'fas';
const width: number = 16;
const height: number = 16;
const aliases: string[] = [];
const unicode: string = '';
const svgPathData: string = [
    'M15.5 12H2V2.5C2 2.22375 1.77625 2 1.5 2H0.5C0.22375 2 0 2.22375 0 2.5V13C0 13.5522 0.447812 14 1 14H15.5C15.7762 14 16 13.7762 16 13.5V12.5C16 12.2238 15.7762 12 15.5 12Z',
    generateCirclePath(6, 9, 2),
    generateCirclePath(12, 6, 3),
].join('');

export const iiChartSize: IconDefinition = {
    prefix,
    iconName,
    icon: [width, height, aliases, unicode, svgPathData],
};

function generateCirclePath(cx: number, cy: number, r: number) {
    return `M ${cx} ${cy} m ${-r}, 0 a ${r},${r} 0 1,0 ${r * 2},0 a ${r},${r} 0 1,0 ${-r * 2},0Z`;
}
