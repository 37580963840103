import {
    faCheckSquare,
    faChevronLeft,
    faChevronRight,
    faGears,
    faInfoCircle,
    faRedo,
    faSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as d3 from 'd3-scale-chromatic';
import { memo, useEffect, useRef, useState } from 'react';
import { Alert, Button, Dropdown, Form } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { Plot } from '../../../components/Plot';
import { AsyncActionButton, AsyncButton } from '../../../components/common/AsyncButton';
import { LabeledInput, TextInput } from '../../../components/common/Inputs';
import { InfoTooltip } from '../../../components/common/Tooltips';
import { AssayValueView, UncertaintyValue } from '../../../lib/assays/display';
import type {
    AssayCreateDetails,
    AssayValueCreate,
    AssayValueDetails,
    AssayValueType,
} from '../../../lib/assays/models';
import { isComboCGIAssay, isInequalityValue } from '../../../lib/assays/util';
import useBehavior from '../../../lib/hooks/useBehavior';
import { DialogService } from '../../../lib/services/dialog';
import { parseFileSystemDate } from '../../../lib/util/dates';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { roundValueDigits } from '../../../lib/util/roundValues';
import { asNumberOrNull } from '../../../lib/util/validators';
import { resolvePlateBarcodesLink } from '../../ECM/ecm-common';
import type { AssayDetail } from '../assay-api';
import { AssayCurveQCModel, AssayUploadBatchModel, AssayUploadSortBy, clampFitBound } from './assay-curve-qc-model';
import { AssayUploadModel } from './assay-upload-model';
import {
    AssayValueCreateBayesian,
    BayesAPI,
    BayesIC50Result,
    BayesWorkflowError,
    bayesIC50toNM,
    isBayes,
    isBayesError,
    isBayesSuccess,
} from './bayes-api';
import { AssayPopover } from '../../../components/common/formatSelectOptionLabel';
import { isBlank } from '../../../lib/util/misc';

interface AssayUploadProps {
    uploadModel: AssayUploadModel;
    model: AssayCurveQCModel;
    onConfirm: () => void | Promise<any>;
    onCancel: () => void;
}

export function AssayUpload({ uploadModel, model, onConfirm, onCancel }: AssayUploadProps) {
    return (
        <div>
            <Content
                uploadModel={uploadModel}
                model={model}
                onCancel={() => onCancel()}
                onConfirm={() => onConfirm()}
            />
        </div>
    );
}

function Content({
    uploadModel,
    model,
    onCancel,
    onConfirm,
}: {
    uploadModel: AssayUploadModel;
    model: AssayCurveQCModel;
    onCancel: () => void;
    onConfirm: () => void;
}) {
    return (
        <div>
            <Header model={model} />
            <div className='clearfix assay-curve-fit-cards'>
                <Cards model={model} />
            </div>
            <Footer uploadModel={uploadModel} model={model} onCancel={onCancel} onConfirm={onConfirm} />
        </div>
    );
}

function SingleAssayUploadHeaderContent({ model }: { model: AssayCurveQCModel }) {
    if (model.data.uploadData.length !== 1) return null;
    const dataPoints = model.data.uploadData[0];
    const assay = dataPoints.info;
    const values = dataPoints.values;
    let experimentDate = null;
    if (values.length > 0 && values[0].performed_on) {
        experimentDate = parseFileSystemDate(values[0].performed_on).toLocaleString();
    }
    return (
        <>
            <span className='prop-label'>Assay:</span>
            <span className='prop-value'>{assay.shorthand}</span>
            <span className='prop-label'>Cell Line:</span>
            <span className='prop-value'>{`${assay.property?.environment_details?.name ?? '-'}`}</span>
            <span className='prop-label'>Performed on:</span>
            <span className='prop-value'>{experimentDate ?? '-'}</span>
            <span className='prop-label'>Plate ID:</span>
            <span className='prop-value'>-</span>
        </>
    );
}

function MultiAssayUploadHeaderContent({ model }: { model: AssayCurveQCModel }) {
    return <span>Uploading values to {model.assays.length} assays</span>;
}

function Header({ model }: { model: AssayCurveQCModel }) {
    return (
        <div className='assay-curve-fit-header p-2 border-bottom'>
            <div className='hstack gap-2'>
                {model.assays.length === 1 && <SingleAssayUploadHeaderContent model={model} />}
                {model.assays.length > 1 && <MultiAssayUploadHeaderContent model={model} />}
                <div className='m-auto' />
                <SortBy model={model} />
                <CurveFitAutomationSelect model={model} />
            </div>
        </div>
    );
}

interface CurveFitAutomationDefinition<T = any> {
    name: string;
    info: string;
    initialState: any;
    ui: React.FC<{ model: { model: AssayCurveQCModel; info: string }; stateSubject: BehaviorSubject<T> }>;
    onApply: (model: AssayCurveQCModel, options: T) => Promise<any>;
}

const CurveFitAutomations: CurveFitAutomationDefinition[] = [
    {
        name: 'Set Sigmoid Min/Max',
        info: 'Apply the provided sigmoid fit min/max values. Curves marked as Done will be skipped.',
        initialState: { minValue: null, maxValue: null },
        ui: MinMaxObservedValueControls,
        onApply: (model, options) => model.applyGlobalSigmoidMinMax(options),
    } satisfies CurveFitAutomationDefinition<{ minValue: number | null; maxValue: number | null }>,
    {
        name: 'Adjust Sigmoid Min/Max',
        info: 'Apply provided min/max value if the observed value falls outside the specified threshold. Curves marked as Done will be skipped.',
        initialState: { minValue: null, maxValue: null },
        ui: MinMaxObservedValueControls,
        onApply: (model, options) => model.applyAdjustSigmoidBounds(options),
    } satisfies CurveFitAutomationDefinition<{ minValue: number | null; maxValue: number | null }>,
    {
        name: 'Adjust Fitted Value',
        info: 'Unselect points and switch to LT/GT mode if fitted value falls outside the provided range. Curves marked as Done will be skipped.',
        initialState: { minValue: null, maxValue: null },
        ui: MinMaxFittedValueControls,
        onApply: (model, options) => model.applyAdjustFittedValue(options),
    } satisfies CurveFitAutomationDefinition<{ minValueNM: number | null; maxValueNM: number | null }>,
    {
        name: 'Set Slope Min/Max',
        info: 'Set the bounds of the hill slope for fitting. Curves marked as Done will be skipped.',
        initialState: { minValue: null, maxValue: null },
        ui: MinMaxSlopeControls,
        onApply: (model, options) => model.applyAdjustSlopeBounds(options),
    } satisfies CurveFitAutomationDefinition<{ minValue: number | null; maxValue: number | null }>,
    {
        name: 'Combo CGI Max',
        info: 'Set the max value for combo CGI as the average of the maximum points of all curves. Curves marked as Done will be skipped.',
        initialState: { minValue: null, maxValue: null },
        ui: MinMaxComboCGIValueControls,
        onApply: (model) => model.applyComboCGIMax(),
    } satisfies CurveFitAutomationDefinition<{ minValue: number | null; maxValue: number | null }>,
];

const LabelWidth = 160;

function CurveFitAutomationSelect({ model }: { model: AssayCurveQCModel }) {
    const isComboCGI = model.data.uploadData.length === 1 && isComboCGIAssay(model.data.uploadData[0].info);
    const show = (automation: CurveFitAutomationDefinition) =>
        DialogService.open({
            type: 'generic',
            title: `Apply ${automation.name}`,
            confirmButtonContent: 'Apply',
            model: { model, info: automation.info },
            defaultState: automation.initialState,
            content: automation.ui,
            wrapOk: true,
            onOk: (state) => automation.onApply(model, state),
        });

    const resetCurves = () =>
        DialogService.open({
            type: 'confirm',
            onConfirm: model.resetCurves,
            title: 'Reset Curves',
            text: <p>Do you want to reset all selected curves NOT marked as Done?</p>,
            confirmText: 'Apply',
        });

    return (
        <Dropdown>
            <Dropdown.Toggle variant='outline-primary' size='sm'>
                <FontAwesomeIcon icon={faGears} className='me-2' size='sm' />
                Automate
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {CurveFitAutomations.map((automation) => (
                    <Dropdown.Item
                        key={automation.name}
                        onClick={() => show(automation)}
                        disabled={!isComboCGI && automation.name === 'Combo CGI Max'}
                    >
                        {automation.name}
                    </Dropdown.Item>
                ))}
                <Dropdown.Item onClick={resetCurves}>Reset Curves</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

function MinMaxObservedValueControls({
    model,
    stateSubject,
}: {
    model: { info: string };
    stateSubject: BehaviorSubject<{ minValue: number | null; maxValue: number | null }>;
}) {
    const state = useBehavior(stateSubject);

    return (
        <div className='vstack gap-2'>
            {!!model.info && (
                <Alert variant='info' className='p-2 m-0 mb-2'>
                    <div className='hstack gap-2'>
                        <FontAwesomeIcon icon={faInfoCircle} className='mx-1' />
                        <span>{model.info}</span>
                    </div>
                </Alert>
            )}
            <LabeledInput label='Min Value' labelWidth={LabelWidth}>
                <TextInput
                    value={state.minValue}
                    tryUpdateValue={clampFitBound}
                    setValue={(minValue) => stateSubject.next({ ...state, minValue })}
                    autoFocus
                />
            </LabeledInput>
            <LabeledInput label='Max Value' labelWidth={LabelWidth}>
                <TextInput
                    value={state.maxValue}
                    tryUpdateValue={clampFitBound}
                    setValue={(maxValue) => stateSubject.next({ ...state, maxValue })}
                    autoFocus
                />
            </LabeledInput>
        </div>
    );
}

function MinMaxFittedValueControls({
    model,
    stateSubject,
}: {
    model: { info: string };
    stateSubject: BehaviorSubject<{ minValueNM: number | null; maxValueNM: number | null }>;
}) {
    const state = useBehavior(stateSubject);

    return (
        <div className='vstack gap-2'>
            {!!model.info && (
                <Alert variant='info' className='p-2 m-0 mb-2'>
                    <div className='hstack gap-2'>
                        <FontAwesomeIcon icon={faInfoCircle} className='mx-1' />
                        <span>{model.info}</span>
                    </div>
                </Alert>
            )}
            <LabeledInput label='Min Value (nM)' labelWidth={LabelWidth}>
                <TextInput
                    value={state.minValueNM}
                    tryUpdateValue={asNumberOrNull}
                    setValue={(minValueNM) => stateSubject.next({ ...state, minValueNM })}
                    autoFocus
                />
            </LabeledInput>
            <LabeledInput label='Max Value (nM)' labelWidth={LabelWidth}>
                <TextInput
                    value={state.maxValueNM}
                    tryUpdateValue={asNumberOrNull}
                    setValue={(maxValueNM) => stateSubject.next({ ...state, maxValueNM })}
                    autoFocus
                />
            </LabeledInput>
        </div>
    );
}

function MinMaxComboCGIValueControls({ model }: { model: { model: AssayCurveQCModel; info: string } }) {
    const comboCGIMax = useBehavior(model.model.state.comboCGIMax);
    return (
        <div className='vstack gap-2'>
            {!!model.info && (
                <Alert variant='info' className='p-2 m-0 mb-2'>
                    <div className='hstack gap-2'>
                        <FontAwesomeIcon icon={faInfoCircle} className='mx-1' />
                        <span>{model.info}</span>
                    </div>
                </Alert>
            )}
            <LabeledInput label='Min Value' labelWidth={LabelWidth}>
                <TextInput value={0} readOnly />
            </LabeledInput>
            <LabeledInput label='Max Value' labelWidth={LabelWidth}>
                <TextInput value={comboCGIMax} readOnly />
            </LabeledInput>
        </div>
    );
}

function MinMaxSlopeControls({
    model,
    stateSubject,
}: {
    model: { model: AssayCurveQCModel; info: string };
    stateSubject: BehaviorSubject<{ minValue: number | null; maxValue: number | null }>;
}) {
    const state = useBehavior(stateSubject);
    return (
        <div className='vstack gap-2'>
            {!!model.info && (
                <Alert variant='info' className='p-2 m-0 mb-2'>
                    <div className='hstack gap-2'>
                        <FontAwesomeIcon icon={faInfoCircle} className='mx-1' />
                        <span>{model.info}</span>
                    </div>
                </Alert>
            )}
            <LabeledInput label='Min Value' labelWidth={LabelWidth}>
                <TextInput
                    value={state.minValue}
                    tryUpdateValue={asNumberOrNull}
                    setValue={(minValue) => stateSubject.next({ ...state, minValue })}
                    autoFocus
                />
            </LabeledInput>
            <LabeledInput label='Max Value' labelWidth={LabelWidth}>
                <TextInput
                    value={state.maxValue}
                    tryUpdateValue={asNumberOrNull}
                    setValue={(maxValue) => stateSubject.next({ ...state, maxValue })}
                    autoFocus
                />
            </LabeledInput>
        </div>
    );
}

function Footer({
    uploadModel,
    model,
    onCancel,
    onConfirm,
}: {
    uploadModel: AssayUploadModel;
    model: AssayCurveQCModel;
    onCancel: () => void;
    onConfirm: () => void;
}) {
    const summary = useBehavior(model.state.summary);
    const currentPage = useBehavior(model.state.currentPage);
    const canReview = useBehavior(model.state.canReview);
    const currentComputation = useBehavior(uploadModel.state.selectedComputation);

    function previousPage() {
        model.state.currentPage.next(Math.max(0, currentPage - 1));
        model.syncView();
        window.scrollTo(0, 0);
    }

    function nextPage() {
        model.state.currentPage.next(Math.min(currentPage + 1, model.numPages));
        model.syncView();
        window.scrollTo(0, 0);
    }

    async function removeComputation(id: string) {
        try {
            await BayesAPI.remove(id);
            uploadModel.syncComputations();
            uploadModel.cancel();
        } catch (e) {
            reportErrorAsToast('Error discarding Bayesian results:', e);
        }
    }

    return (
        <div className='entos-footer justify-content-between hstack gap-2 border-top'>
            <div>
                <span className='text-secondary me-2'>{summary.total} assay values imported.</span>
                <span className='text-primary'>{summary.selected} selected</span>
                {summary.done > 0 && (
                    <>
                        , <span className='text-success'>{summary.done} done</span>
                    </>
                )}
                {summary.notDetermined > 0 && (
                    <>
                        , <span className='text-warning'>{summary.notDetermined} undetermined</span>
                    </>
                )}
            </div>
            <div className='d-flex align-items-center'>
                <Button className='me-2' variant='link' onClick={() => previousPage()} disabled={currentPage === 0}>
                    <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
                </Button>
                <span className='text-primary fw-bold me-2'>{currentPage + 1}</span>
                <Button
                    className='me-2'
                    variant='link'
                    onClick={() => nextPage()}
                    disabled={currentPage === model.numPages - 1}
                >
                    <FontAwesomeIcon icon={faChevronRight} fixedWidth />
                </Button>
                ({model.numPages} page{model.numPages > 1 ? 's' : ''})
            </div>
            <div>
                <Button variant='link' onClick={() => onCancel()} size='sm'>
                    Cancel
                </Button>
                {!model.data.bayes_results && (
                    <Button
                        variant='link'
                        onClick={() => model.saveJSON()}
                        disabled={model.assays.length !== 1}
                        size='sm'
                        className='me-1'
                    >
                        Save JSON
                    </Button>
                )}
                {currentComputation && (
                    <Button
                        variant='link'
                        onClick={() => removeComputation(currentComputation.id)}
                        size='sm'
                        className='me-1'
                    >
                        Discard Results
                    </Button>
                )}
                <AsyncActionButton variant='primary' action={onConfirm} size='sm' disabled={!canReview}>
                    Review and Confirm
                </AsyncActionButton>
            </div>
        </div>
    );
}

function SortBy({ model }: { model: AssayCurveQCModel }) {
    const sortBy = useBehavior(model.state.sortBy);
    return (
        <>
            <Form.Label>Sort By</Form.Label>
            <Form.Select
                value={sortBy}
                size='sm'
                onChange={(e) => model.state.sortBy.next((e.target.value as AssayUploadSortBy) ?? undefined)}
            >
                <option value=''>None</option>
                <option value='id'>ID</option>
                <option value='value-asc'>IC50 Asc</option>
                <option value='value-desc'>IC50 Desc</option>
                {model.data.bayes_results && <option value='bayes-failed'>Bayesian Failures First</option>}
                <option value='plate-barcode'>Plate Barcode and Well Location</option>
                {model.assays.length > 1 && <option value='assay'>Assay ID</option>}
            </Form.Select>
        </>
    );
}

function Cards({ model }: { model: AssayCurveQCModel }) {
    const view = useBehavior(model.state.view);
    return (
        <div className='clearfix assay-curve-fit-cards pt-2'>
            {view.map((c, idx) => (
                // NOTE: using idx here instead of a truly unique key
                // so the rendered plots will be reused & updated instead of rebuilt
                <CompoundCard key={idx} model={c} />
            ))}
        </div>
    );
}

const CompoundCard = memo(
    ({ model }: { model: AssayUploadBatchModel }) => {
        const figure = useBehavior(model.state.figure);
        const isSelected = useBehavior(model.state.isSelected);
        const isDone = useBehavior(model.state.isDone);
        const isBayesResult = isBayes(model.bayes_result);

        const width = model.model.hasCRO ? 280 : 200;

        return (
            <div className='float-start ms-2 mb-2 position-relative' style={{ width: 'calc(50% - 1.25rem)' }}>
                <div className={`border${isDone ? ' border-success' : ''}`} style={{ opacity: isSelected ? 1 : 0.5 }}>
                    <div className='ps-2 pe-2 assay-compound-header'>
                        <CardHeader model={model} isSelected={isSelected} isDone={isDone} />
                    </div>
                    <div className='w-100 position-relative p-2' style={{ height: 260 }}>
                        <div style={{ position: 'absolute', right: width + 10, left: 0, top: 0, bottom: 8 }}>
                            <Plot
                                figure={figure}
                                modeBarButtons={[
                                    ['zoom2d', 'pan2d', 'toImage', 'zoomIn2d', 'zoomOut2d', 'resetScale2d'],
                                ]}
                                clickIndex={model.events.pointClick}
                            />
                        </div>
                        <div className='assay-curve-fit-table-wrapper' style={{ width }}>
                            {!isBayesResult && <FitTable model={model} />}
                            {isBayesResult && <BayesTable model={model} />}
                        </div>
                        <div className='assay-curve-value-wrapper d-flex justify-content-center' style={{ width }}>
                            <div className='assay-curve-historic-value me-2'>
                                <HistoricFitValue model={model} />
                            </div>
                            <div className='assay-curve-fit-value'>
                                <FitValue model={model} />
                            </div>
                        </div>
                    </div>
                    <ResetButton model={model} />
                </div>
                {isBayesResult && (
                    <div className='position-absolute top-0 end-0 d-flex align-items-end justify-content-end'>
                        <Button
                            title='Manual QC'
                            variant='primary'
                            className='mt-2 me-2'
                            onClick={() => model.rejectBayesFit()}
                        >
                            Manual QC
                        </Button>
                    </div>
                )}
            </div>
        );
    },
    (prev, next) => prev.model === next.model
);

function getComboCGIString(assay: AssayDetail, assayValue: AssayValueCreate) {
    const isComboCGI = isComboCGIAssay(assay);
    if (!isComboCGI) return null;
    if (assayValue.details.combo_batch_identifier)
        return `Combo CGI ratio ${assayValue.batch_identifier} : ${assayValue.details.combo_batch_identifier} is ${assayValue.details.ratio}`;
    return 'Single Agent CGI';
}

function getPlateBarcodeEl(assayValue: AssayValueCreate) {
    const barcode = assayValue.details.plate_barcode;
    const wellLocation = assayValue.details.min_well_location;
    if (!barcode && !wellLocation) return null;
    return (
        <>
            {barcode && (
                <a href={resolvePlateBarcodesLink([barcode])} target='_blank' rel='noreferrer' className='me-1'>
                    {barcode}
                </a>
            )}
            {wellLocation}
        </>
    );
}

function CardHeader({
    model,
    isSelected,
    isDone,
}: {
    model: AssayUploadBatchModel;
    isSelected: boolean;
    isDone: boolean;
}) {
    const assayValue = useBehavior(model.state.assay_value);
    const supplierId = model.batch?.supplier_id;
    const supplierIdStr = supplierId ? ` (${supplierId})` : '';
    let errorStr = '';
    let warningStr = '';
    let selectionStr = '';
    const comboCGIStr = getComboCGIString(model.assay, assayValue);
    const plateBarcodeEl = getPlateBarcodeEl(assayValue);
    let height = 46;
    let assayColor: string | undefined;
    if (assayValue.graph && !isBayes(model.bayes_result)) {
        let total = 0;
        let unselected = 0;
        for (const trace of assayValue.graph.data) {
            const { mask, x: xs } = trace;
            const selData = mask?.map((v) => (v ? 0 : 1)) ?? xs.map(() => 0);
            unselected += selData.filter((v) => v === 1).length;
            total += xs.length;
        }
        selectionStr = `${total - unselected} of ${total} points selected`;
        if (comboCGIStr || plateBarcodeEl) height += 23;
    } else if (isBayes(model.bayes_result)) {
        if (isBayesError(model.bayes_result)) {
            errorStr = `${(model.bayes_result as BayesWorkflowError).message}. Assay value will be retired.`;
        } else if (!isBayesSuccess(model.bayes_result)) {
            warningStr = 'Bayesian fitting failed.';
        }
        if (comboCGIStr || plateBarcodeEl) height += 23;
    }
    if (model.model.assays.length > 1) {
        const assayIds = model.model.assays.map((a) => a.id);
        assayColor = d3.schemeCategory10[assayIds.indexOf(model.assay.id) % 10];
        height += 23;
    }

    const label = (
        <>
            <div title={`${assayValue.batch_identifier} ${supplierIdStr}`}>
                <span className='fw-bold'>{assayValue.batch_identifier}</span>
                {supplierIdStr}
            </div>
            {model.model.assays.length > 1 && (
                <div className='fw-normal' style={{ color: assayColor }}>
                    {model.assay.shorthand}
                    <AssayPopover assay={model.assay} />
                </div>
            )}
            {comboCGIStr && <div className='text-body fw-normal'>{comboCGIStr}</div>}
            {plateBarcodeEl && <div className='text-body fw-normal'>{plateBarcodeEl}</div>}
            <div className='text-secondary fw-normal'>{selectionStr}</div>
            {warningStr && <div className='text-warning fw-normal'>{warningStr}</div>}
            {errorStr && <div className='text-danger fw-normal'>{errorStr}</div>}
        </>
    );

    return (
        <div className='hstack align-items-start w-100'>
            <Form.Check
                className={`form-check-input d-inline me-2 assay-curve-fit-card-header-label flex-grow-1${
                    model.isValueUndetermined && isSelected ? ' text-warning' : ''
                }`}
                style={{ height }}
                type='checkbox'
                id={`sel-${model.key}`}
                checked={isSelected}
                disabled={!!errorStr}
                label={label}
                onChange={() => model.state.isSelected.next(!isSelected)}
            />
            <AsyncButton
                variant='link'
                size='sm'
                icon={isDone ? faCheckSquare : faSquare}
                iconSpacing={1}
                className={isDone ? 'text-success' : 'text-secondary'}
                onClick={() => model.state.isDone.next(!isDone)}
            >
                Done
            </AsyncButton>
        </div>
    );
}

function HistoricFitValue({ model }: { model: AssayUploadBatchModel }) {
    const isComboCGI = isComboCGIAssay(model.assay);

    // if this is a combo batch, we do not want to show any historic value
    // as it would be misleading
    if (model.assay_value.details.combo_batch_identifier) return null;

    if (isComboCGI && model.assay_value.details.assumed_ec50 !== undefined) {
        // for singleton Combo CGI values, we will display
        // an assumed EC50 value
        return (
            <div>
                <AssayValueView value={model.assay_value.details.assumed_ec50} />
                <InfoTooltip tooltip='Assumed EC50' />
            </div>
        );
    }

    if (isBlank(model.historicValue)) return null;
    const formatted = <AssayValueView value={model.historicValue} />;

    return (
        <div>
            {formatted} nM
            <InfoTooltip tooltip='Historic value (compound aggregated)' />
        </div>
    );
}

function FitValue({ model }: { model: AssayUploadBatchModel }) {
    const assayValue = useBehavior(model.state.assay_value);
    const isInequality = isInequalityValue(assayValue.value);
    const isBayesResult = isBayes(model.bayes_result);
    const formatted = formatIC50Value(model, assayValue.value);

    if (isBayesResult && isBayesSuccess(model.bayes_result)) return null;
    if (isBayesResult && !isInequality) return null;

    return (
        <div>
            {formatted === '-' ? <span className='text-warning'>-</span> : formatted}
            {assayValue.details.insight_value_source === 'sigmoid_fit' &&
                assayValue.details.insight_sigmoid_fit_message && (
                    <InfoTooltip tooltip={assayValue.details.insight_sigmoid_fit_message} />
                )}
        </div>
    );
}

function formatIC50Value(model: AssayUploadBatchModel, v?: AssayValueType) {
    const formatted = model.formatValue(v);
    if (formatted === undefined || formatted === '') return '-';
    return (
        <>
            {formatted} <span className='unit'>nM</span>
        </>
    );
}

const BAYES_TOOLTIP_MESSAGE = 'Uncertainty values are within a 1\u03C3 confidence interval.';
function EnigmaResultTable({ result }: { result: BayesIC50Result }) {
    return (
        <table className='assay-curve-fit-table w-100'>
            <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th>
                        Enigma <InfoTooltip tooltip={BAYES_TOOLTIP_MESSAGE} />
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        IC<sub>50</sub>
                    </td>
                    <td>
                        <UncertaintyValue value={bayesIC50toNM(result.curve.value)} options={{ asNM: false }} />
                        <span className='text-secondary ms-2'>nM</span>
                    </td>
                </tr>
                <tr>
                    <td>HS</td>
                    <td>
                        <UncertaintyValue value={result.curve.slope} options={{ asNM: false }} />
                    </td>
                </tr>
                <tr>
                    <td>Fit min</td>
                    <td>
                        <UncertaintyValue value={result.curve.min_y} options={{ asNM: false }} />
                    </td>
                </tr>
                <tr>
                    <td>Fit max</td>
                    <td>
                        <UncertaintyValue value={result.curve.max_y} options={{ asNM: false }} />
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

function LTGTOnlyTable({ model }: { model: AssayUploadBatchModel }) {
    const assayValue = useBehavior(model.state.assay_value);

    return (
        <table className='assay-curve-fit-table w-100'>
            <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th>LTGT</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        IC<sub>50</sub>
                    </td>
                    <td>{formatIC50Value(model, assayValue.details.insight_lt_gt_value)}</td>
                </tr>
                <tr>
                    <td>
                        R<sup>2</sup>
                    </td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>HS</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>Fit min</td>
                    <td>{assayValue.value_details.min_y_threshold ?? 30}</td>
                </tr>
                <tr>
                    <td>Fit max</td>
                    <td>{assayValue.value_details.max_y_threshold ?? 70}</td>
                </tr>
            </tbody>
        </table>
    );
}

function BayesTable({ model }: { model: AssayUploadBatchModel }) {
    const assayValue = useBehavior(model.state.assay_value);
    const isInequality = isInequalityValue(assayValue.value);

    if (!model.bayes_result || isBayesError(model.bayes_result)) return null;

    const result = (model.bayes_result as AssayValueCreateBayesian).bayesian_result;
    if (isBayesSuccess(model.bayes_result) && result) return <EnigmaResultTable result={result} />;
    if (isInequality) return <LTGTOnlyTable model={model} />;

    return <span className='font-body-small text-muted'>Could not calculate fit</span>;
}

function FitTable({ model }: { model: AssayUploadBatchModel }) {
    const assayValue = useBehavior(model.state.assay_value);

    const { hasCRO } = model.model;
    const entosFit = assayValue.value_details.sigmoid_fit_details;
    const croFit = assayValue.value_details.cro_sigmoid_fit_details;

    const format = (v?: number, f = 2) => {
        if (typeof v !== 'number') return '-';
        if (v > 1000 || v < -1000) return roundValueDigits(3, v).toFixed(f);
        return v.toFixed(f);
    };

    const valueSource = assayValue.details.insight_value_source;

    const entosProps = {
        className: valueSource === 'sigmoid_fit' ? 'current' : undefined,
        onClick: () => model.setValueSource('sigmoid_fit'),
    };
    const croProps = {
        className: valueSource === 'cro' ? 'current' : undefined,
        onClick: () => model.setValueSource('cro'),
    };
    const croPropsMinMax = {
        ...entosProps,
        className: valueSource === 'cro' ? 'current text-warning' : undefined,
    };
    const ltgtProps = {
        className: valueSource === 'lt_gt' ? 'current' : undefined,
        onClick: () => model.setValueSource('lt_gt'),
    };

    return (
        <table className='assay-curve-fit-table'>
            <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th {...entosProps}>Sigmoid</th>
                    <th {...ltgtProps}>LT/GT</th>
                    {hasCRO && <th {...croProps}>CRO</th>}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        IC<sub>50</sub>
                    </td>
                    <td {...entosProps}>
                        {formatIC50Value(model, assayValue.value_details.sigmoid_fit_details?.value)}
                    </td>
                    <td {...ltgtProps}>{formatIC50Value(model, assayValue.details.insight_lt_gt_value)}</td>
                    {hasCRO && (
                        <td {...croProps}>
                            {formatIC50Value(model, assayValue.value_details.cro_sigmoid_fit_details?.value)}
                        </td>
                    )}
                </tr>
                <tr>
                    <td>
                        R<sup>2</sup>
                    </td>
                    <td {...entosProps}>{format(entosFit?.r2)}</td>
                    <td {...ltgtProps}>-</td>
                    {hasCRO && <td {...croProps}>{format(croFit?.r2)}</td>}
                </tr>
                <tr>
                    <td>HS</td>
                    <td {...entosProps}>{format(entosFit?.slope)}</td>
                    <td {...ltgtProps}>-</td>
                    {hasCRO && <td {...croProps}>{format(croFit?.slope)}</td>}
                </tr>
                <tr>
                    <td className={valueSource === 'lt_gt' ? 'assay-curve-fit-table-limit-label' : undefined}>
                        {valueSource === 'lt_gt' ? (
                            <>
                                LT
                                <br />
                                Limit
                            </>
                        ) : (
                            'Min'
                        )}
                    </td>
                    <td {...entosProps}>
                        <EditableSigmoidFitBound
                            assayValue={assayValue}
                            model={model}
                            kind='insight_sigmoid_fit_fix_min'
                            defaultValue={assayValue.value_details.sigmoid_fit_details?.min}
                            current={valueSource === 'sigmoid_fit'}
                        />
                    </td>
                    <td {...ltgtProps}>
                        <EditableLTGTBound
                            assayValue={assayValue}
                            model={model}
                            kind='min_y_threshold'
                            defaultValue={assayValue.value_details.min_y_threshold ?? 30}
                            editedIfNotEqual={30}
                        />
                    </td>
                    {hasCRO && <td {...croPropsMinMax}>{format(croFit?.min, 0)}</td>}
                </tr>
                <tr>
                    <td className={valueSource === 'lt_gt' ? 'assay-curve-fit-table-limit-label' : undefined}>
                        {valueSource === 'lt_gt' ? (
                            <>
                                GT
                                <br />
                                Limit
                            </>
                        ) : (
                            'Max'
                        )}
                    </td>
                    <td {...entosProps}>
                        <EditableSigmoidFitBound
                            assayValue={assayValue}
                            model={model}
                            kind='insight_sigmoid_fit_fix_max'
                            defaultValue={assayValue.value_details.sigmoid_fit_details?.max}
                            current={valueSource === 'sigmoid_fit'}
                        />
                    </td>
                    <td {...ltgtProps}>
                        <EditableLTGTBound
                            assayValue={assayValue}
                            model={model}
                            kind='max_y_threshold'
                            defaultValue={assayValue.value_details.max_y_threshold ?? 70}
                            editedIfNotEqual={70}
                        />
                    </td>
                    {hasCRO && <td {...croPropsMinMax}>{format(croFit?.max, 0)}</td>}
                </tr>
            </tbody>
        </table>
    );
}

function EditableSigmoidFitBound({
    model,
    assayValue,
    defaultValue,
    kind,
    current,
}: {
    model: AssayUploadBatchModel;
    assayValue: AssayValueCreate;
    defaultValue: number | undefined;
    kind: keyof AssayCreateDetails;
    current?: boolean;
}) {
    const [value, setValue] = useState('');
    const changed = useRef<boolean>(false);

    useEffect(() => {
        changed.current = false;
        if (typeof assayValue.details[kind] === 'number') {
            setValue((assayValue.details[kind] as number).toFixed(0));
        } else {
            setValue('');
        }
    }, [assayValue, kind]);

    const placeholder = typeof defaultValue === 'number' ? defaultValue.toFixed(0) : '-';

    return (
        <Form.Control
            className={classNames('assay-curve-fit-bound', {
                'assay-curve-fit-bound-fixed': typeof assayValue.details[kind] === 'number',
                'assay-curve-fit-bound-current': !!current,
            })}
            value={value}
            placeholder={placeholder}
            onChange={(e) => {
                changed.current = true;
                setValue(e.target.value);
            }}
            onFocus={(e) => e.target.setSelectionRange(0, e.target.value.length)}
            onBlur={() => {
                if (changed.current) model.setSigmoidFitBound(kind, value);
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter') (e.target as HTMLInputElement).blur();
            }}
        />
    );
}

function EditableLTGTBound({
    model,
    assayValue,
    defaultValue,
    editedIfNotEqual,
    kind,
}: {
    model: AssayUploadBatchModel;
    assayValue: AssayValueCreate;
    defaultValue: number | undefined;
    editedIfNotEqual?: number;
    kind: keyof AssayValueDetails;
}) {
    const [value, setValue] = useState('');
    const changed = useRef<boolean>(false);

    useEffect(() => {
        changed.current = false;
        if (typeof assayValue.value_details[kind] === 'number') {
            setValue((assayValue.value_details[kind] as number).toFixed(0));
        } else {
            setValue('');
        }
    }, [assayValue, kind]);

    const placeholder = typeof defaultValue === 'number' ? defaultValue.toFixed(0) : '-';

    return (
        <Form.Control
            className={`assay-curve-fit-bound ${
                typeof assayValue.value_details[kind] === 'number' &&
                (typeof editedIfNotEqual === 'undefined' || editedIfNotEqual !== defaultValue)
                    ? ' assay-curve-fit-bound-fixed'
                    : ''
            }`}
            value={value}
            placeholder={placeholder}
            onChange={(e) => {
                changed.current = true;
                setValue(e.target.value);
            }}
            onFocus={(e) => e.target.setSelectionRange(0, e.target.value.length)}
            onBlur={() => {
                if (changed.current) model.setLTGTBound(kind, value);
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter') (e.target as HTMLInputElement).blur();
            }}
        />
    );
}

function ResetButton({ model }: { model: AssayUploadBatchModel }) {
    useBehavior(model.state.assay_value);
    if (!model.canReset) return null;

    return (
        <button
            type='button'
            className='bg-transparent border-0 assay-curve-reset-mask'
            onClick={() => model.reset()}
            title='Reset Selection and Bounds'
        >
            <FontAwesomeIcon icon={faRedo} fixedWidth />
        </button>
    );
}
