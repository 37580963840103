export const M_384_20c_7pt_dup_1ctrl = `ID,Point,Row,Column,IsControl
1,1,2,2,False
1,2,2,3,False
1,3,2,4,False
1,4,2,5,False
1,5,2,6,False
1,6,2,7,False
1,7,2,8,False
8,1,2,9,False
8,2,2,10,False
8,3,2,11,False
8,4,2,12,False
8,5,2,13,False
8,6,2,14,False
8,7,2,15,False
15,1,2,17,False
15,2,2,18,False
15,3,2,19,False
15,4,2,20,False
15,5,2,21,False
15,6,2,22,False
15,7,2,23,False
1,1,3,2,False
1,2,3,3,False
1,3,3,4,False
1,4,3,5,False
1,5,3,6,False
1,6,3,7,False
1,7,3,8,False
8,1,3,9,False
8,2,3,10,False
8,3,3,11,False
8,4,3,12,False
8,5,3,13,False
8,6,3,14,False
8,7,3,15,False
15,1,3,17,False
15,2,3,18,False
15,3,3,19,False
15,4,3,20,False
15,5,3,21,False
15,6,3,22,False
15,7,3,23,False
2,1,4,2,False
2,2,4,3,False
2,3,4,4,False
2,4,4,5,False
2,5,4,6,False
2,6,4,7,False
2,7,4,8,False
9,1,4,9,False
9,2,4,10,False
9,3,4,11,False
9,4,4,12,False
9,5,4,13,False
9,6,4,14,False
9,7,4,15,False
16,1,4,17,False
16,2,4,18,False
16,3,4,19,False
16,4,4,20,False
16,5,4,21,False
16,6,4,22,False
16,7,4,23,False
2,1,5,2,False
2,2,5,3,False
2,3,5,4,False
2,4,5,5,False
2,5,5,6,False
2,6,5,7,False
2,7,5,8,False
9,1,5,9,False
9,2,5,10,False
9,3,5,11,False
9,4,5,12,False
9,5,5,13,False
9,6,5,14,False
9,7,5,15,False
16,1,5,17,False
16,2,5,18,False
16,3,5,19,False
16,4,5,20,False
16,5,5,21,False
16,6,5,22,False
16,7,5,23,False
3,1,6,2,False
3,2,6,3,False
3,3,6,4,False
3,4,6,5,False
3,5,6,6,False
3,6,6,7,False
3,7,6,8,False
10,1,6,9,False
10,2,6,10,False
10,3,6,11,False
10,4,6,12,False
10,5,6,13,False
10,6,6,14,False
10,7,6,15,False
17,1,6,17,False
17,2,6,18,False
17,3,6,19,False
17,4,6,20,False
17,5,6,21,False
17,6,6,22,False
17,7,6,23,False
3,1,7,2,False
3,2,7,3,False
3,3,7,4,False
3,4,7,5,False
3,5,7,6,False
3,6,7,7,False
3,7,7,8,False
10,1,7,9,False
10,2,7,10,False
10,3,7,11,False
10,4,7,12,False
10,5,7,13,False
10,6,7,14,False
10,7,7,15,False
17,1,7,17,False
17,2,7,18,False
17,3,7,19,False
17,4,7,20,False
17,5,7,21,False
17,6,7,22,False
17,7,7,23,False
4,1,8,2,False
4,2,8,3,False
4,3,8,4,False
4,4,8,5,False
4,5,8,6,False
4,6,8,7,False
4,7,8,8,False
11,1,8,9,False
11,2,8,10,False
11,3,8,11,False
11,4,8,12,False
11,5,8,13,False
11,6,8,14,False
11,7,8,15,False
18,1,8,17,False
18,2,8,18,False
18,3,8,19,False
18,4,8,20,False
18,5,8,21,False
18,6,8,22,False
18,7,8,23,False
4,1,9,2,False
4,2,9,3,False
4,3,9,4,False
4,4,9,5,False
4,5,9,6,False
4,6,9,7,False
4,7,9,8,False
11,1,9,9,False
11,2,9,10,False
11,3,9,11,False
11,4,9,12,False
11,5,9,13,False
11,6,9,14,False
11,7,9,15,False
18,1,9,17,False
18,2,9,18,False
18,3,9,19,False
18,4,9,20,False
18,5,9,21,False
18,6,9,22,False
18,7,9,23,False
5,1,10,2,False
5,2,10,3,False
5,3,10,4,False
5,4,10,5,False
5,5,10,6,False
5,6,10,7,False
5,7,10,8,False
12,1,10,9,False
12,2,10,10,False
12,3,10,11,False
12,4,10,12,False
12,5,10,13,False
12,6,10,14,False
12,7,10,15,False
19,1,10,17,False
19,2,10,18,False
19,3,10,19,False
19,4,10,20,False
19,5,10,21,False
19,6,10,22,False
19,7,10,23,False
5,1,11,2,False
5,2,11,3,False
5,3,11,4,False
5,4,11,5,False
5,5,11,6,False
5,6,11,7,False
5,7,11,8,False
12,1,11,9,False
12,2,11,10,False
12,3,11,11,False
12,4,11,12,False
12,5,11,13,False
12,6,11,14,False
12,7,11,15,False
19,1,11,17,False
19,2,11,18,False
19,3,11,19,False
19,4,11,20,False
19,5,11,21,False
19,6,11,22,False
19,7,11,23,False
6,1,12,2,False
6,2,12,3,False
6,3,12,4,False
6,4,12,5,False
6,5,12,6,False
6,6,12,7,False
6,7,12,8,False
13,1,12,9,False
13,2,12,10,False
13,3,12,11,False
13,4,12,12,False
13,5,12,13,False
13,6,12,14,False
13,7,12,15,False
20,1,12,17,False
20,2,12,18,False
20,3,12,19,False
20,4,12,20,False
20,5,12,21,False
20,6,12,22,False
20,7,12,23,False
6,1,13,2,False
6,2,13,3,False
6,3,13,4,False
6,4,13,5,False
6,5,13,6,False
6,6,13,7,False
6,7,13,8,False
13,1,13,9,False
13,2,13,10,False
13,3,13,11,False
13,4,13,12,False
13,5,13,13,False
13,6,13,14,False
13,7,13,15,False
20,1,13,17,False
20,2,13,18,False
20,3,13,19,False
20,4,13,20,False
20,5,13,21,False
20,6,13,22,False
20,7,13,23,False
7,1,14,2,False
7,2,14,3,False
7,3,14,4,False
7,4,14,5,False
7,5,14,6,False
7,6,14,7,False
7,7,14,8,False
14,1,14,9,False
14,2,14,10,False
14,3,14,11,False
14,4,14,12,False
14,5,14,13,False
14,6,14,14,False
14,7,14,15,False
Control1,1,14,17,True
Control1,2,14,18,True
Control1,3,14,19,True
Control1,4,14,20,True
Control1,5,14,21,True
Control1,6,14,22,True
Control1,7,14,23,True
7,1,15,2,False
7,2,15,3,False
7,3,15,4,False
7,4,15,5,False
7,5,15,6,False
7,6,15,7,False
7,7,15,8,False
14,1,15,9,False
14,2,15,10,False
14,3,15,11,False
14,4,15,12,False
14,5,15,13,False
14,6,15,14,False
14,7,15,15,False
Control1,1,15,17,True
Control1,2,15,18,True
Control1,3,15,19,True
Control1,4,15,20,True
Control1,5,15,21,True
Control1,6,15,22,True
Control1,7,15,23,True
`;
