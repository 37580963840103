import { useCallback, useSyncExternalStore } from 'react';
import { BehaviorSubject, skip } from 'rxjs';

function useBehavior<T>(s: BehaviorSubject<T>): T;
function useBehavior<T>(s: BehaviorSubject<T> | undefined): T | undefined;
function useBehavior<T>(s: BehaviorSubject<T> | undefined): T | undefined {
    const subscribe = useCallback(
        (fn: () => void) => {
            const sub = s?.pipe(skip(1)).subscribe(fn)!;
            return () => sub?.unsubscribe();
        },
        [s]
    );
    const getValue = useCallback(() => s?.value, [s]);
    return useSyncExternalStore(subscribe, getValue);
}

export default useBehavior;

// Pre react 18 implementation, keeping this around in case something goes wrong with React 18

// // source: Envision
// // https://github.com/EntosAI/envision/blob/master/envision/src/hooks/use-behavior.ts
// import { useEffect, useRef, useState } from 'react';

// interface Behavior<T> {
//     value: T;
//     subscribe(f: (v: T) => void): { unsubscribe(): void };
// }

// function useBehavior<T>(s: Behavior<T>): T;
// // eslint-disable-next-line
// function useBehavior<T>(s: Behavior<T> | undefined): T | undefined;
// // eslint-disable-next-line
// function useBehavior<T>(s: Behavior<T> | undefined): T | undefined {
//     const [, next] = useState({});
//     const value = useRef<T>();
//     value.current = s?.value;

//     useEffect(() => {
//         if (!s) {
//             return;
//         }
//         const sub = s.subscribe((v) => {
//             if (value.current !== v) next({});
//         });

//         // eslint-disable-next-line
//         return () => {
//             sub.unsubscribe();
//         };
//     }, [s]);

//     return s?.value;
// }
