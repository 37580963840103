import {
    IconDefinition,
    faAdd,
    faArrowLeft,
    faArrowRight,
    faCode,
    faCopy,
    faFireBurner,
    faHourglassHalf,
    faInfoCircle,
    faPencil,
    faTrash,
    faWater,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Form } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { IconButton } from '../../../components/common/IconButton';
import { LabeledInput, TextInput } from '../../../components/common/Inputs';
import useBehavior from '../../../lib/hooks/useBehavior';
import { DialogService } from '../../../lib/services/dialog';
import { ToastService } from '../../../lib/services/toast';
import { capitalizeFirst } from '../../../lib/util/misc';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { roundValue } from '../../../lib/util/roundValues';
import { formatWithUnit, parseWithUnit } from '../../../lib/util/units';
import { guid4 } from '../../../lib/util/uuid';
import { asNumberOrNull } from '../../../lib/util/validators';
import { HTE2Api } from '../api';
import {
    HTEDReaction,
    HTEDReactionTemplate,
    HTERInstructionT,
    HTERReactantInstance,
    HTERReactantNameT,
    HTERReactantSample,
} from '../data-model';
import { GetNameDialogContent, getReactantInstances, toUnit } from '../utils';
import { HTEDCommand, formatCommands, parseCommands } from '../utils/dsl';
import { type HTEDesignModel } from '.';

export class HTEReactionModel extends ReactiveModel {
    state = {
        current: new BehaviorSubject<HTEDReaction>(undefined as any),
    };

    get reaction() {
        return this.state.current.value;
    }

    get reactantNames() {
        const { instructions } = this.reaction.template.reaction;
        const ret: HTERReactantNameT[] = [];
        for (const i of instructions) {
            if (i.kind === 'add') {
                ret.push(i.name);
            }
        }
        return ret;
    }

    async syncTemplate(template: HTEDReactionTemplate) {
        const validated = (await HTE2Api.validate({
            kind: 'reaction-template',
            data: template,
        })) as HTEDReactionTemplate;
        const identifiers = Object.values(validated.reactants)
            .map((r) => r.identifier)
            .filter((id) => id);
        await this.design.model.assets.entities.syncIdentifiers(Array.from(new Set(identifiers)));
        this.updateTemplateData(validated);
    }

    toggleCurrentInstruction(instruction: HTERInstructionT) {
        const current = this.design.state.currentInstruction;
        if (
            current.value &&
            current.value.reaction_id === this.reaction.id &&
            current.value.instruction === instruction
        ) {
            this.design.state.currentInstruction.next(undefined);
        } else {
            this.design.state.currentInstruction.next({
                reaction_id: this.reaction.id,
                instruction,
            });
        }
    }

    clone(newName?: string) {
        const { reaction } = this;
        const clone = new HTEReactionModel(this.design, {
            reaction: {
                ...reaction,
                id: guid4(),
                name: newName ?? `Copy of ${reaction.name}`,
                template: {
                    ...reaction.template,
                    reaction: {
                        ...reaction.template.reaction,
                    },
                },
            },
        });
        this.design.reactions.next([...this.design.reactions.value, clone]);
    }

    updateTemplateData(template: HTEDReactionTemplate) {
        this.state.current.next({ ...this.reaction, template });
    }

    async addOrEditInstruction(state: EditInstructionDialogContentState, index: number) {
        const { template } = this.reaction;

        const next: HTEDReactionTemplate = {
            ...template,
            reaction: { ...template.reaction, instructions: [...template.reaction.instructions] },
            reactants: { ...template.reactants },
        };

        if (index >= 0) {
            next.reaction.instructions[index] = state.instruction;
        } else {
            next.reaction.instructions.push(state.instruction);
        }

        if (state.instruction.kind === 'add') {
            if (state.identifier) {
                const instances = await getReactantInstances([state.identifier]);
                next.reactants[state.instruction.name] = instances[0];
            } else {
                delete next.reactants[state.instruction.name];
            }
        }

        await this.syncTemplate(next);

        ToastService.show({
            type: 'success',
            message: index >= 0 ? 'Instruction updated' : 'Instruction added',
            timeoutMs: 1500,
        });
    }

    removeInstruction(instr: HTERInstructionT) {
        const { template } = this.reaction;
        const index = template.reaction.instructions.indexOf(instr);
        if (index < 0) return;
        const next: HTEDReactionTemplate = {
            ...template,
            reaction: { ...template.reaction, instructions: [...template.reaction.instructions] },
        };
        next.reaction.instructions.splice(index, 1);
        this.updateTemplateData(next);
    }

    moveInstruction(instr: HTERInstructionT, dir: number) {
        const { template } = this.reaction;
        const index = template.reaction.instructions.indexOf(instr);
        if (index < 0) return;

        const next: HTEDReactionTemplate = {
            ...template,
            reaction: { ...template.reaction, instructions: [...template.reaction.instructions] },
        };

        const other = (index + dir + next.reaction.instructions.length) % next.reaction.instructions.length;
        const swap = next.reaction.instructions[other];
        next.reaction.instructions[other] = instr;
        next.reaction.instructions[index] = swap;

        this.updateTemplateData(next);
    }

    constructor(
        public design: HTEDesignModel,
        {
            reaction,
        }: {
            reaction: HTEDReaction;
        }
    ) {
        super();
        this.state.current.next(reaction);
    }
}

export function emptyReaction(options: { name: string; product_block_id?: string }): HTEDReaction {
    return {
        id: guid4(),
        name: options.name,
        template: emptyReactionTemplate(),
        product_block_id: options.product_block_id,
    };
}

function emptyReactionTemplate(options?: { notes?: string }): HTEDReactionTemplate {
    return {
        reaction: {
            notes: options?.notes ?? '',
            instructions: [],
        },
        scale: 200e-9,
        reactants: {},
        solvent: 'dmso',
    };
}

function toCommands(template: HTEDReactionTemplate) {
    const commands: HTEDCommand[] = [];

    commands.push({
        name: 'params',
        args: {
            scale: roundValue(3, template.scale * 1e9).toString(),
            sol: template.solvent,
        },
    });

    for (const instr of template.reaction.instructions) {
        if (instr.kind === 'add') {
            const args: any = {};

            if (instr.name) args[0] = instr.name;
            if (instr.sample.concentration) args.conc = toUnit(instr.sample.concentration, 1e3, 'mM');
            if (instr.sample.equivalence) args.eq = instr.sample.equivalence;
            if (instr.sample.volume) args.vol = toUnit(instr.sample.volume, 1e9 * 1e3, 'nL');
            if (instr.sample.neat_solvent) args.sol = instr.sample.neat_solvent;
            if (instr.sample.neat_concentration) args['neat-conc'] = toUnit(instr.sample.neat_concentration, 1e3, 'mM');
            if (instr.sample.use_overage) args['use-overage'] = instr.sample.use_overage;
            if (instr.multi_aspiration_group) args.multi = instr.multi_aspiration_group;
            if (instr.conserve_volume) args.conserve = undefined;
            if (typeof instr.mosquito_copy_options === 'string')
                args['copy-options'] = instr.mosquito_copy_options.replace(/\s/g, '');
            commands.push({ name: 'add', args });
        } else if (instr.kind === 'pause') {
            commands.push({
                name: 'pause',
                args: {
                    0: toUnit(instr.duration, 1, 's'),
                },
            });
        } else if (instr.kind === 'cook') {
            commands.push({
                name: 'cook',
                args: {
                    duration: toUnit(instr.duration, 1 / 3600, 'hour'),
                    temperature: toUnit(instr.temperature, 1, 'K'),
                },
            });
        } else if (instr.kind === 'backfill') {
            const args: any = { 0: toUnit(instr.volume, 1e9 * 1e3, 'nL') };
            commands.push({ name: 'backfill', args });
        }
    }

    for (const [k, r] of Object.entries(template.reactants)) {
        const args: any = {};

        args[0] = k;
        args[1] = r.identifier;
        if (r.sample?.concentration) args.conc = toUnit(r.sample.concentration, 1e3, 'mM');
        if (r.sample?.equivalence) args.eq = r.sample.equivalence;
        if (r.sample?.volume) args.vol = toUnit(r.sample.volume, 1e9 * 1e3, 'nL');
        if (r.sample?.neat_solvent) args.sol = r.sample.neat_solvent;
        if (r.sample?.neat_concentration) args['neat-conc'] = toUnit(r.sample.neat_concentration, 1e3, 'mM');
        if (r.sample?.use_overage) args['use-overage'] = r.sample.use_overage;
        if (r.barcode) args.barcode = r.barcode;

        commands.push({ name: 'use', args });
    }

    return formatCommands(commands);
}

function executeCommands(input: string, options: { name: string; notes?: string }) {
    const commands = parseCommands(input);
    const template = emptyReactionTemplate(options);

    for (const c of commands) {
        switch (c.name) {
            case 'add': {
                template.reaction.instructions!.push({
                    kind: 'add',
                    name: c.args[0]! as HTERReactantNameT,
                    multi_aspiration_group: 'multi' in c.args ? c.args.multi || '1' : undefined,
                    conserve_volume: 'conserve' in c.args,
                    mosquito_copy_options: c.args['copy-options'] || '',
                    sample: {
                        equivalence: +c.args.eq!,
                        volume: c.args.vol,
                        concentration: c.args.conc,
                        neat_concentration: c.args['neat-conc'],
                        neat_solvent: c.args.sol,
                        use_overage: +(c.args['use-overage'] ?? 1.0),
                    },
                });
                break;
            }
            case 'backfill': {
                template.reaction.instructions!.push({
                    kind: 'backfill',
                    volume: c.args[0]!,
                });
                break;
            }
            case 'pause': {
                template.reaction.instructions!.push({
                    kind: 'pause',
                    duration: c.args[0]!,
                });
                break;
            }
            case 'cook': {
                template.reaction.instructions!.push({
                    kind: 'cook',
                    duration: c.args.duration!,
                    temperature: c.args.temperature!,
                });
                break;
            }
            case 'params': {
                if (c.args.scale) template.scale = +c.args.scale * 1e-9;
                if (c.args.sol) template.solvent = c.args.sol;
                break;
            }
            case 'use': {
                const name = c.args[0]! as HTERReactantNameT;
                const id = c.args[1]!;
                const sample: HTERReactantSample = {};
                if (c.args.conc) sample.concentration = c.args.conc!;
                if (c.args.eq) sample.equivalence = +c.args.eq;
                if (c.args.vol) sample.volume = c.args.vol!;
                if (c.args.sol) sample.neat_solvent = c.args.sol;
                if (c.args['neat-conc']) sample.neat_concentration = c.args['neat-conc']!;
                if (c.args['use-overage']) sample.use_overage = +c.args['use-overage'];
                const instance: HTERReactantInstance = { identifier: id, sample };
                if (c.args.barcode) instance.barcode = c.args.barcode;
                template.reactants[name] = instance;
                break;
            }
            default:
                break;
        }
    }

    return template;
}

export function EditReactionTemplateButton({ model }: { model: HTEReactionModel }) {
    const editTemplate = () => {
        DialogService.open({
            type: 'generic',
            title: 'Edit Reaction Template',
            confirmButtonContent: 'Apply',
            model,
            defaultState: { input: toCommands(model.reaction.template) },
            options: { size: 'lg' },
            wrapOk: true,
            content: EditReactionDialogContent,
            onOk: async (state: { input: string }) => {
                const block = executeCommands(state.input, {
                    name: model.reaction.name,
                    notes: model.reaction.template.reaction.notes,
                });
                await model.syncTemplate(block);
                ToastService.show({
                    type: 'success',
                    message: 'Reaction template updated',
                    timeoutMs: 1500,
                });
            },
        });
    };

    return <IconButton onClick={editTemplate} variant='link' title='Edit Reaction Template' icon={faCode} />;
}

function EditReactionDialogContent({ stateSubject }: { stateSubject: BehaviorSubject<{ input: string }> }) {
    const current = useBehavior(stateSubject);

    return (
        <div className='vstack gap-2'>
            <TextInput
                value={current.input}
                placeholder='Enter command...'
                className='font-body-small'
                style={{ fontFamily: 'monospace' }}
                setValue={(v) => stateSubject.next({ ...current, input: v.trim() })}
                autoFocus
                textarea
                rows={12}
            />
        </div>
    );
}

export function RemoveReactionButton({ model }: { model: HTEReactionModel }) {
    const remove = () => {
        DialogService.open({
            type: 'confirm',
            onConfirm: () => model.design.removeReaction(model),
            title: 'Remove Reaction',
            text: <p>Are you sure you want to remove {model.reaction.name}?</p>,
            confirmText: 'Remove',
        });
    };

    return <IconButton onClick={remove} variant='link' icon={faTrash} title='Remove reaction' />;
}

export function CloneReactionButton({ model }: { model: HTEReactionModel }) {
    const addBatch = () => {
        DialogService.open({
            type: 'generic',
            title: 'Copy Reaction',
            confirmButtonContent: 'Copy',
            model,
            defaultState: { name: `Copy of ${model.reaction.name}` },
            wrapOk: true,
            content: GetNameDialogContent,
            onOk: (state: { name: string }) => model.clone(state.name),
        });
    };

    return <IconButton onClick={addBatch} variant='link' icon={faCopy} title='Copy reaction' />;
}

export function MoveInstructionButton({
    model,
    instruction,
    dir,
}: {
    model: HTEReactionModel;
    instruction: HTERInstructionT;
    dir: number;
}) {
    const apply = () => model.moveInstruction(instruction, dir);

    const icon: IconDefinition = dir < 0 ? faArrowLeft : faArrowRight;
    return <IconButton onClick={apply} variant='link' icon={icon} title={dir < 0 ? 'Move left' : 'Move right'} />;
}

export function RemoveInstructionButton({
    model,
    instruction,
}: {
    model: HTEReactionModel;
    instruction: HTERInstructionT;
}) {
    const apply = () => model.removeInstruction(instruction);
    return <IconButton onClick={apply} variant='link' icon={faTrash} title='Remove' />;
}

export function EditReactionInstructionButton({
    model,
    instruction,
}: {
    model: HTEReactionModel;
    instruction: HTERInstructionT;
}) {
    const { template } = model.reaction;
    const index = template.reaction.instructions.indexOf(instruction);

    const apply = () => {
        const instance = instruction.kind === 'add' ? template.reactants[instruction.name] : undefined;
        DialogService.open({
            type: 'generic',
            title:
                index < 0
                    ? `New ${capitalizeFirst(instruction.kind)} Instruction`
                    : `Edit ${capitalizeFirst(instruction.kind)}`,
            confirmButtonContent: 'Apply',
            model,
            defaultState: {
                instruction,
                identifier: instance?.barcode ?? instance?.identifier,
            } satisfies EditInstructionDialogContentState,
            wrapOk: true,
            content: EditInstructionDialogContent,
            onOk: (state: EditInstructionDialogContentState) => model.addOrEditInstruction(state, index),
        });
    };

    let icon: IconDefinition = faPencil;
    if (index < 0) {
        if (instruction.kind === 'add') icon = faAdd;
        else if (instruction.kind === 'backfill') icon = faWater;
        else if (instruction.kind === 'pause') icon = faHourglassHalf;
        else if (instruction.kind === 'cook') icon = faFireBurner;
    }

    return (
        <IconButton onClick={apply} variant='link' icon={icon} title={index < 0 ? `New ${instruction.kind}` : 'Edit'} />
    );
}

interface EditInstructionDialogContentState {
    instruction: HTERInstructionT;
    identifier?: string;
}

const LabelWidth = 160;

function EditInstructionDialogContent({
    stateSubject,
}: {
    stateSubject: BehaviorSubject<EditInstructionDialogContentState>;
}) {
    const current = useBehavior(stateSubject);
    const { instruction, identifier } = current;

    if (instruction.kind === 'backfill') {
        return (
            <div className='vstack gap-2'>
                <LabeledInput label='Volume' labelWidth={LabelWidth}>
                    <TextInput
                        value={formatWithUnit(instruction.volume, 1e9 * 1e3, 'nL')}
                        tryUpdateValue={(v) => parseWithUnit(v, 'nL')}
                        setValue={(v) => stateSubject.next({ ...current, instruction: { ...instruction, volume: v } })}
                        autoFocus
                    />
                </LabeledInput>
            </div>
        );
    }

    if (instruction.kind === 'pause') {
        return (
            <div className='vstack gap-2'>
                <LabeledInput label='Duration' labelWidth={LabelWidth}>
                    <TextInput
                        value={formatWithUnit(instruction.duration, 1, 's')}
                        tryUpdateValue={(v) => parseWithUnit(v, 's')}
                        setValue={(v) =>
                            stateSubject.next({ ...current, instruction: { ...instruction, duration: v } })
                        }
                        autoFocus
                    />
                </LabeledInput>
            </div>
        );
    }

    if (instruction.kind === 'cook') {
        return (
            <div className='vstack gap-2'>
                <LabeledInput label='Duration' labelWidth={LabelWidth}>
                    <TextInput
                        value={formatWithUnit(instruction.duration, 1 / 3600, 'hour')}
                        tryUpdateValue={(v) => parseWithUnit(v, 'hour')}
                        setValue={(v) =>
                            stateSubject.next({ ...current, instruction: { ...instruction, duration: v } })
                        }
                        autoFocus
                    />
                </LabeledInput>
                <LabeledInput label='Temperature' labelWidth={LabelWidth}>
                    <TextInput
                        value={formatWithUnit(instruction.temperature, (v) => v - 273.15, 'celsius')}
                        tryUpdateValue={(v) => parseWithUnit(v, 'celsius')}
                        setValue={(v) =>
                            stateSubject.next({ ...current, instruction: { ...instruction, temperature: v } })
                        }
                    />
                </LabeledInput>
            </div>
        );
    }

    if (instruction.kind === 'add') {
        const updateSample = (f: keyof HTERReactantSample, v: any): EditInstructionDialogContentState => ({
            ...current,
            instruction: { ...instruction, sample: { ...instruction.sample, [f]: v } },
        });
        return (
            <div className='vstack gap-2'>
                <Alert variant='info' className='mb-2 p-2'>
                    <div className='hstack gap-2'>
                        <FontAwesomeIcon icon={faInfoCircle} className='mx-1' />
                        <span className='font-body-small'>
                            Reactant names are case sensitive and if no identifier/barcode is provided, their name
                            should match a list in the <b>Reactants</b> tab
                        </span>
                    </div>
                </Alert>
                <LabeledInput label='Name' labelWidth={LabelWidth}>
                    <TextInput
                        value={instruction.name}
                        setValue={(v) =>
                            stateSubject.next({
                                ...current,
                                instruction: { ...instruction, name: v.trim() as HTERReactantNameT },
                            })
                        }
                        autoFocus
                    />
                </LabeledInput>
                <LabeledInput label='Identifier/Barcode' labelWidth={LabelWidth}>
                    <TextInput
                        value={identifier ?? ''}
                        setValue={(v) => stateSubject.next({ ...current, identifier: v.trim() })}
                    />
                </LabeledInput>
                <LabeledInput label='Equivalence' labelWidth={LabelWidth}>
                    <TextInput
                        value={
                            typeof instruction.sample.equivalence === 'number'
                                ? roundValue(3, instruction.sample.equivalence)
                                : instruction.sample.equivalence ?? ''
                        }
                        tryUpdateValue={asNumberOrNull}
                        setValue={(v) => stateSubject.next(updateSample('equivalence', v ? +v : undefined))}
                    />
                </LabeledInput>
                <LabeledInput label='Concentration' labelWidth={LabelWidth}>
                    <TextInput
                        value={formatWithUnit(instruction.sample.concentration, 1e3, 'mM')}
                        tryUpdateValue={(v) => parseWithUnit(v, 'mM', true)}
                        setValue={(v) => stateSubject.next(updateSample('concentration', v))}
                    />
                </LabeledInput>
                <LabeledInput label='Neat Conc.' labelWidth={LabelWidth}>
                    <TextInput
                        value={formatWithUnit(instruction.sample.neat_concentration, 1e3, 'mM')}
                        tryUpdateValue={(v) => parseWithUnit(v, 'mM', true)}
                        setValue={(v) => stateSubject.next(updateSample('neat_concentration', v))}
                    />
                </LabeledInput>
                <LabeledInput
                    label='Use Overage'
                    labelWidth={LabelWidth}
                    tooltip='Per use volume multiplier to put into source plate (e.g. 1.1 for 10% overage). Applied to volume required in the product plate.'
                >
                    <TextInput
                        value={
                            typeof instruction.sample.use_overage === 'number'
                                ? roundValue(3, instruction.sample.use_overage)
                                : instruction.sample.use_overage ?? ''
                        }
                        tryUpdateValue={asNumberOrNull}
                        setValue={(v) => stateSubject.next(updateSample('use_overage', v ? +v : undefined))}
                    />
                </LabeledInput>
                <LabeledInput
                    label='Multi Aspiration'
                    labelWidth={LabelWidth}
                    tooltip='Consecutive instructions that should be multi-apirated should get the same value'
                >
                    <TextInput
                        value={instruction.multi_aspiration_group ?? ''}
                        setValue={(v) =>
                            stateSubject.next({
                                ...current,
                                instruction: { ...instruction, multi_aspiration_group: v.trim() || undefined },
                            })
                        }
                    />
                </LabeledInput>
                <LabeledInput
                    label='Copy Options'
                    labelWidth={LabelWidth}
                    tooltip='Options appended to the corresponding Mosquito COPY instruction. This will not be applied to MULTI_ASPIRATE instruction.'
                >
                    <TextInput
                        value={instruction.mosquito_copy_options ?? ''}
                        setValue={(v) =>
                            stateSubject.next({
                                ...current,
                                instruction: { ...instruction, mosquito_copy_options: v.replace(/\s/g, '') },
                            })
                        }
                    />
                </LabeledInput>
                <LabeledInput
                    label='Conserve Volume'
                    labelWidth={LabelWidth}
                    tooltip='Attempts to reduce the usage of this reagent at the cost of potentially using more source columns'
                >
                    <Form.Switch
                        checked={!!instruction.conserve_volume}
                        onChange={(e) =>
                            stateSubject.next({
                                ...current,
                                instruction: { ...instruction, conserve_volume: e.target.checked || undefined },
                            })
                        }
                    />
                </LabeledInput>
            </div>
        );
    }

    return null;
}
