import { ReactNode } from 'react';
import { columnNameToHeader } from '../../api/data';

export function formatDictionary(dict: any): ReactNode | ReactNode[] {
    if (!dict) return '';
    if (typeof dict === 'object') {
        const keys = Object.keys(dict);
        const result: ReactNode[] = [];
        for (const key of keys) {
            result.push(
                <div className='ms-2' key={key}>
                    <span className='text-secondary'>{columnNameToHeader(key)}</span>: {formatDictionary(dict[key])}
                </div>
            );
        }
        return result;
    }
    if (typeof dict === 'string' && dict.startsWith('http')) {
        return (
            <a href={dict} rel='noopener noreferrer' target='_blank'>
                {dict}
            </a>
        );
    }
    return <span className='fw-bold'>{`${dict}`}</span>;
}
