import { faSliders } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';
import { IconButton } from '../../../components/common/IconButton';
import { LabeledInput, SimpleSelectOptionInput, TextInput } from '../../../components/common/Inputs';
import useBehavior from '../../../lib/hooks/useBehavior';
import { DialogService } from '../../../lib/services/dialog';
import { formatWithUnit, parseWithUnit } from '../../../lib/util/units';
import { HTEDConditions } from '../data-model';
import type { HTE2MSDesignModel } from './model';
import { InlineAlert } from '../../../components/common/Alert';
import { HTE2MSApi } from '../api';

export function EditConditionsButton({ model }: { model: HTE2MSDesignModel }) {
    const show = () => {
        DialogService.open({
            type: 'generic',
            title: 'Edit Reaction Conditions',
            confirmButtonContent: 'Apply',
            model,
            defaultState: model.state.conditions.value,
            wrapOk: true,
            content: ConditionsDialogContent,
            options: { hideFooter: model.model.readOnlyDesignAndProduction },
            onOk: async (state: HTEDConditions) =>
                model.state.conditions.next(await HTE2MSApi.validateConditions(state)),
        });
    };

    return (
        <IconButton icon={faSliders} variant='link' size='sm' onClick={show}>
            Conditions
        </IconButton>
    );
}

const LabelWidth = 160;

function ConditionsDialogContent({
    model,
    stateSubject,
}: {
    model: HTE2MSDesignModel;
    stateSubject: BehaviorSubject<HTEDConditions>;
}) {
    const readOnly = model.model.readOnlyDesignAndProduction;
    const rpmToRadS = (2 * Math.PI) / 60;
    const settings = useBehavior(stateSubject);

    return (
        <div className='vstack gap-2'>
            <InlineAlert>Applies to all reactions in the library</InlineAlert>
            <LabeledInput label='Stir Rate' labelWidth={LabelWidth}>
                <TextInput
                    value={formatWithUnit(settings.stir_rate, 1 / rpmToRadS, 'rpm')}
                    tryUpdateValue={(v) => parseWithUnit(v, 'rpm')}
                    setValue={(v) => stateSubject.next({ ...settings, stir_rate: v })}
                    readOnly={readOnly}
                />
            </LabeledInput>
            <LabeledInput label='Temperature' labelWidth={LabelWidth}>
                <TextInput
                    value={formatWithUnit(settings.temperature, (v) => v - 273.15, 'celsius')}
                    tryUpdateValue={(v) => parseWithUnit(v, 'celsius')}
                    setValue={(v) => stateSubject.next({ ...settings, temperature: v })}
                    readOnly={readOnly}
                />
            </LabeledInput>
            <LabeledInput label='Duration' labelWidth={LabelWidth}>
                <TextInput
                    value={formatWithUnit(settings.duration, 1 / 3600, 'hours')}
                    tryUpdateValue={(v) => parseWithUnit(v, 'hours')}
                    setValue={(v) => stateSubject.next({ ...settings, duration: v })}
                    readOnly={readOnly}
                />
            </LabeledInput>
            <LabeledInput label='Atmosphere' labelWidth={LabelWidth}>
                <SimpleSelectOptionInput
                    value={settings.atmosphere}
                    options={model.model.assets.atmosphereOptions}
                    setValue={(v) => stateSubject.next({ ...settings, atmosphere: v })}
                    disabled={readOnly}
                />
            </LabeledInput>
            <LabeledInput label='Pressure' labelWidth={LabelWidth}>
                <TextInput
                    value={formatWithUnit(settings.pressure, 1, 'atm')}
                    tryUpdateValue={(v) => (v.trim() ? parseWithUnit(v, 'atm') : '')}
                    setValue={(v) => stateSubject.next({ ...settings, pressure: v.trim() || null })}
                    readOnly={readOnly}
                />
            </LabeledInput>
        </div>
    );
}
