import React, { lazy, Suspense } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import Loading from '../../components/common/Loading';
import { PageTemplate } from '../../components/Layout/Layout';
import { ECMPages, ECMPage } from './ecm-common';
import { ECMDispose } from './workflows/Dispose';
import { ECMFill } from './workflows/Fill';
import { ECMLocation } from './workflows/Location';
import { ECMPlates } from './workflows/Plates';
import { ECMPlateUpload } from './workflows/PlateUpload';
import { ECMSearch } from './workflows/Search';
import { ECMSolubilize } from './workflows/Solubilize';
import { ECMTare } from './workflows/Tare';
import { ECMTransfer } from './workflows/Transfer';
import { ECMVials } from './workflows/Vials';
import { ECMWeigh } from './workflows/Weigh';
import { ECMPlateFill } from './workflows/PlateFill';
import { ECMOrdersRoot } from './orders';
import { ECMAdjust } from './workflows/Adjust';
import { ECMVolumeCheck } from './workflows/VolumeCheck';
import { ReagentDBUI } from './reagent-db';

const Components: Record<ECMPage, React.FC> = {
    vials: ECMVials,
    plates: ECMPlates,
    'volume-check': ECMVolumeCheck,
    dispose: ECMDispose,
    fill: ECMFill,
    adjust: ECMAdjust,
    search: ECMSearch,
    solubilize: ECMSolubilize,
    'plate-upload': ECMPlateUpload,
    'plate-fill': ECMPlateFill,
    location: ECMLocation,
    tare: ECMTare,
    weigh: ECMWeigh,
    transfer: ECMTransfer,
};

export function ECMRouter() {
    return (
        <Routes>
            {Object.entries(ECMPages).flatMap(([name, page]) => {
                const Element = Components[name as keyof typeof Components];
                const routes = [<Route key={name} element={<Element />} path={page.path} />];
                if ((page as any).supportsId) {
                    routes.push(<Route key={`${name}::id`} element={<Element />} path={`${page.path}/:id`} />);
                }
                return routes;
            })}
            <Route element={<RequestSystem />} path='rs/*' />
            <Route element={<ReagentDBUI />} path='reagent-db/*' />
            <Route element={<ECMOrdersRoot />} path='orders/*' />
            <Route element={<Landing />} path='/' />
        </Routes>
    );
}

function LoadingUI({ app }: { app: string }) {
    return (
        <PageTemplate title='Compond Management' breadcrumb={{ title: app, href: '#' }}>
            <Loading />
        </PageTemplate>
    );
}

const RequestSystemRoot = lazy(() => import(/* webpackChunkName: "ecm" */ './requests'));

function RequestSystem() {
    return (
        <Suspense fallback={<LoadingUI app='Request System' />}>
            <RequestSystemRoot />
        </Suspense>
    );
}

function Landing() {
    return (
        <PageTemplate title='Compond Management'>
            <div className='d-flex align-items-center justify-content-center w-100 h-100'>
                <div style={{ width: 200 }}>
                    <Link to='search' className='btn btn-outline-primary w-100'>
                        Search
                    </Link>
                    <Link to='workflows/tare' className='btn btn-outline-primary w-100 mt-2'>
                        Workflows
                    </Link>
                    <Link to='rs' className='btn btn-outline-primary w-100 mt-2'>
                        Requests
                    </Link>
                </div>
            </div>
        </PageTemplate>
    );
}
