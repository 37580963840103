/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Color } from 'molstar/lib/mol-util/color';
import { Lab } from 'molstar/lib/mol-util/color/spaces/lab';
import { toRGBString } from '../../../lib/util/colors';
import { PlateDimensions } from '../../HTE/experiment-data';
import { PlateVisualModel, PlateWellColoring } from '../../HTE/plate/PlateVisual';
import { HTEPProductPlate, HTEPProductWell, HTEPSourcePlate, HTEPSourceWell, HTERReactantNameT } from '../data-model';

export interface PlateColorContext {
    keyIndex: Map<string, number>;
    getKey: (well: any) => string | null | undefined;
    getColor: (idx: number) => string;
}

export function productPlateColorContext(
    data: HTEPProductPlate,
    { reaction_id }: { reaction_id?: string } = {}
): PlateColorContext {
    const getKey: (well: HTEPProductWell | null) => string | null | undefined = (well: HTEPProductWell | null) => {
        if (!well?.product) return null;
        if (reaction_id && well.reaction_id !== reaction_id) return null;
        return `${well.reaction_id}:${well.product.identifier}`;
    };

    const uniqueWells = new Set(data.wells.map((w) => getKey(w as any)));
    uniqueWells.delete(null);
    const keyIndex = new Map<string, number>();
    const s = uniqueWells.size - 1 || 1;

    const lab = Lab.fromColor(Lab(), Color(0));
    const tmp = [0, 0, 0];
    const getColor = (k: number) => {
        lab[0] = 60;
        lab[1] = 75 - (150 * k) / s;
        lab[2] = 50 - (150 * k) / s;
        return toRGBString(Color.toArray(Lab.toColor(lab), tmp, 0) as number[]);
    };

    return {
        keyIndex,
        getKey,
        getColor,
    };
}

export function sourcePlateColorContext(data: HTEPSourcePlate[]): PlateColorContext {
    const getKey = (well: HTEPSourceWell | null) => (well ? `${well?.identifier}:${well.concentration ?? 0}` : null);
    const uniqueWells = new Set(data.flatMap((p) => p.wells.map((w) => getKey(w as any))));
    uniqueWells.delete(null);
    const s = uniqueWells.size - 1 || 1;
    const keyIndex = new Map<string, number>();

    const lab = Lab.fromColor(Lab(), Color(0));
    const tmp = [0, 0, 0];
    const getColor = (k: number) => {
        lab[0] = 80;
        lab[1] = 0 + (100 * k) / s;
        lab[2] = 60;
        return toRGBString(Color.toArray(Lab.toColor(lab), tmp, 0) as number[]);
    };

    return {
        keyIndex,
        getKey,
        getColor,
    };
}

function isProductWell(w: any): w is HTEPProductWell {
    return !!w?.reaction_id;
}

export function reactantNameColorContext(
    productPlate: HTEPProductPlate,
    sourcePlates: HTEPSourcePlate[],
    {
        reaction_id,
        reactant_name,
        solvent,
        identifierSet,
    }: { reaction_id?: string; reactant_name?: HTERReactantNameT; solvent?: string; identifierSet?: Set<string> }
): PlateColorContext {
    let getKey: (well: any) => string | null | undefined;

    if (reactant_name) {
        getKey = (well: HTEPProductWell | HTEPSourceWell | null) => {
            if (!well) return null;
            if (isProductWell(well)) {
                const sample = well.samples[reactant_name];
                return sample && well.reaction_id === reaction_id
                    ? `${sample.identifier}:${sample.concentration}`
                    : null;
            }
            if (identifierSet && well.identifier && !identifierSet.has(well.identifier)) {
                return null;
            }
            return well?.reactant_names?.includes(reactant_name) && well.solvent === solvent
                ? `${well.identifier}:${well.concentration}`
                : null;
        };
    } else if (solvent) {
        getKey = (well: HTEPProductWell | HTEPSourceWell | null) => {
            if (!well) return null;
            if (isProductWell(well)) {
                return well.reaction_id === reaction_id && well.solvent === solvent ? '1' : null;
            }
            return !well.identifier && well.solvent === solvent ? '1' : null;
        };
    } else {
        getKey = (well: HTEPProductWell | HTEPSourceWell | null) => null;
    }
    const uniqueWells = new Set([
        ...productPlate.wells.map((w) => getKey(w)),
        ...sourcePlates.flatMap((p) => p.wells.map((w) => getKey(w as any))),
    ]);
    uniqueWells.delete(null);
    const s = uniqueWells.size - 1 || 1;
    const keyIndex = new Map<string, number>();

    const lab = Lab.fromColor(Lab(), Color(0));
    const tmp = [0, 0, 0];
    const singleColor = toRGBString(Color.toArray(Lab.toColor([60, -75, -100] as unknown as Lab), tmp, 0) as number[]);
    const getColor =
        s === 1
            ? (k: number) => singleColor
            : (k: number) => {
                  lab[0] = 60;
                  lab[1] = 75 - (150 * k) / s;
                  lab[2] = 50 - (150 * k) / s;
                  return toRGBString(Color.toArray(Lab.toColor(lab), tmp, 0) as number[]);
              };

    return {
        keyIndex,
        getKey,
        getColor,
    };
}

export function colorPlate(
    plate: PlateVisualModel,
    data: HTEPProductPlate | HTEPSourcePlate,
    colorContext: PlateColorContext
) {
    const { getKey, keyIndex, getColor } = colorContext;
    const colors: (string | string[])[] = [];

    const [w, h] = PlateDimensions[plate.layout];

    for (let c = 0; c < w; c++) {
        for (let r = 0; r < h; r++) {
            const wI = r * w + c;
            if (!data.wells[wI]) {
                colors[wI] = PlateWellColoring.NoColor;
                continue;
            }

            const key = getKey(data.wells[wI] as any)!;
            if (!key) {
                colors[wI] = !data.wells[wI] ? PlateWellColoring.NoColor : PlateWellColoring.NonEmptyColor;
                continue;
            }

            let idx;
            if (!keyIndex.has(key)) {
                idx = keyIndex.size;
                keyIndex.set(key, idx);
            } else {
                idx = keyIndex.get(key)!;
            }

            colors[wI] = getColor(idx);
        }
    }

    plate.state.colors.next(colors);
}
