/* eslint-disable react/function-component-definition */
import { DefaultFloatColumnFormatOptions, formatFloat } from '../../components/DataTable';
import { BooleanBadge } from '../../components/DataTable/common';
import { CanvasTextRenderingOptions, renderCanvasSubscript, renderCanvasSuperscript } from '../util/canvas';
import { isBlank } from '../util/misc';
import { getDecimalPlaces } from '../util/roundValues';
import {
    type AssayFormattingOptions,
    assayValueTypeToString,
    gaussianPotensToNM,
    getUncertaintyValueBounds,
    isGaussianUncertaintyValue,
    isUncertaintyValue,
    uncertaintyPotensToNM,
    DefaultConfidenceInterval,
} from './util';
import type { AssayGaussianUncertaintyValue, AssayUncertaintyValue, AssayValueType } from './models';

const E_ICXX_REGEX = /(.*)([EI]C)([0-9]{2})(.*)/i;

export function formatPotensName(columnName: string, options: { asNM: boolean; asString?: boolean }) {
    const prefix = options.asNM ? '' : 'p';
    const idx = columnName.search(E_ICXX_REGEX);
    if (idx === -1) {
        const re = /(.*)(K)([di])(.*)/i;
        const parts = columnName.match(re);
        if (!parts) return columnName;
        let first = parts[1];
        if (first[first.length - 1] === 'p') first = first.slice(0, first.length - 1);
        const type = `${parts[2]}${parts[3]}`;
        const last = parts[4];
        return `${first}${prefix}${type}${last}`;
    }
    const parts = columnName.match(E_ICXX_REGEX);
    if (!parts) return columnName;
    let first = parts[1];
    if (first[first.length - 1] === 'p') first = first.slice(0, first.length - 1);
    const icOrEC = columnName.indexOf('IC') > -1 ? 'IC' : 'EC';
    const type = parts[3]; // 50, 75, 90 for example (will be a subscript)
    const last = parts[4]; // anything after IC50 or IC90

    if (options?.asString) {
        return `${first}${prefix}${icOrEC}${type}${last}`;
    }

    return (
        <>
            {first}
            {prefix}
            {icOrEC}
            <sub>{type}</sub>
            {last}
        </>
    );
}

function UncertaintyDisplay({
    value,
    upper,
    lower,
}: {
    value: number | string;
    upper: number | string;
    lower: number | string;
}) {
    return (
        <div className='d-inline-flex align-items-center'>
            {value}
            <div className='ps-1 d-flex flex-column font-body-xsmall'>
                <div>+{upper}</div>
                <div>-{lower}</div>
            </div>
        </div>
    );
}

function BasicValue({ value }: { value: number | string }) {
    return <div className='d-inline-flex align-items-center'>{value}</div>;
}

export function UncertaintyValue({
    value,
    options,
}: {
    value: AssayUncertaintyValue;
    options?: AssayFormattingOptions;
}) {
    const assayValue = options?.asNM ? uncertaintyPotensToNM(value) : value;
    const [_interval, lowerBound, upperBound] = getUncertaintyValueBounds(
        assayValue,
        options?.confidenceInterval ?? DefaultConfidenceInterval
    );

    const format = options?.formatting ?? DefaultFloatColumnFormatOptions;

    const formattedValue = formatFloat(assayValue.value, format);
    if (options?.hideUQ) return <BasicValue value={formattedValue} />;

    // regardless of how many significant figures are selected, we want to show the same number
    // of decimal places for the error values as the main value has
    const decimalPlaces = getDecimalPlaces(formattedValue);
    const upperError = formatFloat(upperBound - assayValue.value, { ...format, decimalPlaces });
    const lowerError = formatFloat(assayValue.value - lowerBound, { ...format, decimalPlaces });
    return <UncertaintyDisplay value={formattedValue} upper={upperError} lower={lowerError} />;
}

function renderUncertaintyValueToCanvas(
    value: AssayUncertaintyValue,
    units: string,
    ctx: CanvasRenderingContext2D,
    renderingOptions: CanvasTextRenderingOptions,
    options?: AssayFormattingOptions
) {
    const { x, y, fontSize } = renderingOptions;

    const assayValue = options?.asNM ? uncertaintyPotensToNM(value) : value;
    const [_interval, lowerBound, upperBound] = getUncertaintyValueBounds(
        assayValue,
        options?.confidenceInterval ?? DefaultConfidenceInterval
    );

    const format = options?.formatting ?? DefaultFloatColumnFormatOptions;

    // render the base value
    const formattedValue = formatFloat(assayValue.value, format);
    ctx.font = `${fontSize}px sans-serif`;
    ctx.fillText(formattedValue, x, y);

    // if the error bounds are hidden, we're done
    if (options?.hideUQ) return;

    // regardless of how many significant figures are selected, we want to show the same number
    // of decimal places for the error values as the main value has
    const decimalPlaces = getDecimalPlaces(formattedValue);
    const upperError = formatFloat(upperBound - assayValue.value, { ...format, decimalPlaces });
    const lowerError = formatFloat(assayValue.value - lowerBound, { ...format, decimalPlaces });

    // render the uncertainty bounds
    const offset = ctx.measureText(formattedValue).width;
    renderCanvasSuperscript(ctx, upperError, { x: x + offset, y, fontSize });
    renderCanvasSubscript(ctx, lowerError, { x: x + offset, y, fontSize });
    const errorWidth = Math.max(ctx.measureText(upperError).width, ctx.measureText(lowerError).width);
    ctx.font = `${fontSize}px sans-serif`;
    ctx.fillText(` ${units}`, x + offset + errorWidth, y);
}

export function GaussianUncertaintyValue({
    value,
    options,
}: {
    value: AssayGaussianUncertaintyValue;
    options?: AssayFormattingOptions;
}) {
    const assayValue = options?.asNM ? gaussianPotensToNM(value) : value;

    const format = options?.formatting ?? DefaultFloatColumnFormatOptions;

    const formattedValue = formatFloat(assayValue.value, format);
    if (options?.hideUQ) return <BasicValue value={formattedValue} />;

    // regardless of how many significant figures are selected, we want to show the same number
    // of decimal places for the error values as the main value has
    const decimalPlaces = getDecimalPlaces(formattedValue);
    const upperError = formatFloat((assayValue.upper_bound - assayValue.value) / assayValue.interval, {
        ...format,
        decimalPlaces,
    });
    const lowerError = formatFloat((assayValue.value - assayValue.lower_bound) / value.interval, {
        ...format,
        decimalPlaces,
    });
    return <UncertaintyDisplay value={formattedValue} upper={upperError} lower={lowerError} />;
}

function renderGaussianUncertaintyValueToCanvas(
    value: AssayGaussianUncertaintyValue,
    units: string,
    ctx: CanvasRenderingContext2D,
    renderingOptions: CanvasTextRenderingOptions,
    options?: AssayFormattingOptions
) {
    const { x, y, fontSize } = renderingOptions;

    const assayValue = options?.asNM ? gaussianPotensToNM(value) : value;

    const format = options?.formatting ?? DefaultFloatColumnFormatOptions;

    // render the base value
    const formattedValue = formatFloat(assayValue.value, format);
    ctx.font = `${fontSize}px sans-serif`;
    ctx.fillText(formattedValue, x, y);

    // if the error bounds are hidden, we're done
    if (options?.hideUQ) return;

    // regardless of how many significant figures are selected, we want to show the same number
    // of decimal places for the error values as the main value has
    const decimalPlaces = getDecimalPlaces(formattedValue);
    const upperError = formatFloat((assayValue.upper_bound - assayValue.value) / assayValue.interval, {
        ...format,
        decimalPlaces,
    });
    const lowerError = formatFloat((assayValue.value - assayValue.lower_bound) / value.interval, {
        ...format,
        decimalPlaces,
    });

    // render the uncertainty bounds
    const offset = ctx.measureText(formattedValue).width;
    renderCanvasSuperscript(ctx, upperError, { x: x + offset, y, fontSize });
    renderCanvasSubscript(ctx, lowerError, { x: x + offset, y, fontSize });
    const errorWidth = Math.max(ctx.measureText(upperError).width, ctx.measureText(lowerError).width);
    ctx.font = `${fontSize}px sans-serif`;
    ctx.fillText(` ${units}`, x + offset + errorWidth, y);
}

export function AssayValueView({ value, options }: { value?: AssayValueType; options?: AssayFormattingOptions }) {
    if (isBlank(value)) return null;
    if (typeof value === 'boolean') return <BooleanBadge value={value} />;
    if (isUncertaintyValue(value)) return <UncertaintyValue value={value} options={options} />;
    if (isGaussianUncertaintyValue(value)) return <GaussianUncertaintyValue value={value} options={options} />;
    return <>{assayValueTypeToString(value, options)}</>;
}

export function renderAssayValueToCanvas(
    value: AssayValueType,
    units: string,
    ctx: CanvasRenderingContext2D,
    renderingOptions: CanvasTextRenderingOptions,
    options?: AssayFormattingOptions
) {
    if (isUncertaintyValue(value)) return renderUncertaintyValueToCanvas(value, units, ctx, renderingOptions, options);
    if (isGaussianUncertaintyValue(value))
        return renderGaussianUncertaintyValueToCanvas(value, units, ctx, renderingOptions, options);
    const valueString = assayValueTypeToString(value, options);
    const { x, y, fontSize } = renderingOptions;
    ctx.font = `${fontSize}px sans-serif`;
    ctx.fillText(`${valueString} ${valueString.length ? units : ''}`, x, y);
}
