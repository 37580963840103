/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { IconButton, IconDropdownButton } from '../../../components/common/IconButton';
import { iiIntersection, iiSelectThis, iiSubtraction, iiUnion } from '../../../components/common/Icons';
import { TextInput } from '../../../components/common/Inputs';
import { ScrollBox } from '../../../components/common/ScrollBox';
import useBehavior from '../../../lib/hooks/useBehavior';
import { HTEDReaction } from '../data-model';
import { Formatters } from '../utils';
import type { HTE2MSDesignModel } from './model';
import { DialogService } from '../../../lib/services/dialog';
import { splitInput } from '../../../lib/util/misc';
import { AsyncMoleculeDrawing } from '../../../components/common/AsyncMoleculeDrawing';

export class HTE2MSDesignFiltersModel {
    state = {
        filter: new BehaviorSubject<string>(''),
        groups: new BehaviorSubject<FilterGroup[]>([]),
    };

    get groups() {
        return this.state.groups.value;
    }

    toggleExpanded(group: FilterGroup) {
        const index = this.groups.indexOf(group);
        if (index < 0) return;
        const next = [...this.groups];
        next[index] = { ...group, expanded: !group.expanded };
        this.state.groups.next(next);
    }

    select(filter: FilterItem, action: 'this' | 'union' | 'intersect' | 'subtract') {
        const indexMap = new Map(this.model.all.map((r, i) => [r.id, i]));
        const indices = Array.from(filter.reaction_ids)
            .map((id) => indexMap.get(id))
            .filter((i) => i !== undefined) as number[];

        if (action === 'this') {
            this.model.table.setSelection(indices);
            return;
        }

        const selected = this.model.table.getSelectedRowIndices();
        if (action === 'union') {
            this.model.table.setSelection(Array.from(new Set([...selected, ...indices])));
        } else if (action === 'intersect') {
            this.model.table.setSelection(selected.filter((i) => indices.includes(i)));
        } else if (action === 'subtract') {
            this.model.table.setSelection(selected.filter((i) => !indices.includes(i)));
        }
    }

    inputProductIdentifiers = () => {
        DialogService.open({
            type: 'generic',
            title: `Select Product Identifiers`,
            confirmButtonContent: 'Apply',
            defaultState: { input: '' },
            content: SelectIdentifiersDialogContent,
            wrapOk: true,
            onOk: (state) => this.model.selectProductIdentifiers(splitInput(state.input)),
        });
    };

    constructor(public model: HTE2MSDesignModel) {
        this.state.groups.next(buildGroups(model, model.all, model.state?.filters.value.groups ?? []));
    }
}

function buildGroups(model: HTE2MSDesignModel, reactions: HTEDReaction[], previous: FilterGroup[]): FilterGroup[] {
    if (reactions.length === 0) return [];

    const mainGroups = new Map<string, FilterGroupBuild>();
    const addGroups = new Map<string, FilterGroupBuild>();

    const addGroupValue = (
        groups: Map<string, FilterGroupBuild>,
        header: string,
        reaction: HTEDReaction,
        value: any,
        options?: {
            label?: string;
            identifier?: string;
        }
    ) => {
        if (!groups.has(header)) {
            groups.set(header, { header, values: new Map() });
        }

        const g = groups.get(header)!;
        const key = value ?? '«unset»';
        if (!g.values.has(key)) {
            g.values.set(key, {
                value,
                label: `${options?.label ?? key}`,
                reaction_ids: new Set([reaction.id]),
                identifier: options?.identifier,
            });
        } else {
            g.values.get(key)?.reaction_ids.add(reaction.id);
        }
    };

    const { entities } = model.model.assets;

    for (const r of reactions) {
        const validation = model.validations.get(r.id)?.[0];
        if (validation) {
            for (const [type, message] of validation) {
                if (type === 'danger') addGroupValue(mainGroups, 'Error', r, message);
                if (type === 'warning') addGroupValue(mainGroups, 'Warning', r, message);
                if (type === 'info') addGroupValue(mainGroups, 'Info', r, message);
            }
        }

        addGroupValue(mainGroups, 'Reaction Chemistry', r, r.template.reaction_chemistry);
        addGroupValue(mainGroups, 'Solvent', r, r.template.solvent);
        addGroupValue(mainGroups, 'Scale', r, Formatters.rxnScale(r.scale));
        addGroupValue(mainGroups, 'Target Concentration', r, r.template.target_concentration, {
            label: r.template.target_concentration
                ? `${Formatters.concentration(r.template.target_concentration)}`
                : undefined,
        });
        addGroupValue(mainGroups, 'Group', r, r.group_name);
        addGroupValue(mainGroups, 'Project', r, r.project);

        for (const instr of r.template.instructions) {
            if (instr.kind !== 'add') continue;

            const label: string[] = [instr.equivalence ? `= ${instr.equivalence}` : '= «unset»'];
            if (instr.neat_concentration && instr.concentration && instr.neat_concentration !== instr.concentration) {
                label.push(
                    `@ ${Formatters.concentration(instr.neat_concentration)} → ${Formatters.concentration(
                        instr.concentration
                    )}`
                );
            } else if (instr.concentration) {
                label.push(`@ ${Formatters.concentration(instr.concentration)}`);
            } else if (instr.dose_volume) {
                label.push(`~ ${Formatters.siVolume(instr.dose_volume)}`);
            }
            if (instr.use_overage) label.push(`* ${instr.use_overage}`);
            label.push(instr.identifier ? entities.getIdentifier(instr.identifier) : '«unset»');
            if (instr.neat_solvent) label.push(`in ${instr.neat_solvent}`);

            let header = `Add ${instr.reactant_kind.toUpperCase()}`;
            if (instr.reactant_name) {
                header += ` (${instr.reactant_name})`;
            }

            addGroupValue(addGroups, header, r, label.join(' '), { identifier: instr.identifier });
        }
    }

    const prevMap = new Map<string, FilterGroup>(previous.map((g) => [g.header, g]));
    const order = [
        'Error',
        'Warning',
        'Info',
        'Reaction Chemistry',
        'Solvent',
        'Scale',
        'Target Concentration',
        'Group',
        'Project',
    ];
    const ret: FilterGroup[] = [];
    for (const header of order) {
        if (!mainGroups.has(header)) continue;
        const { values } = mainGroups.get(header)!;
        ret.push({
            header,
            expanded: !!prevMap.get(header)?.expanded,
            items: Array.from(values.values()).sort((a, b) => (a.value < b.value ? -1 : 1)),
        });
    }
    for (const [header, { values }] of Array.from(addGroups.entries()).sort()) {
        ret.push({
            header,
            expanded: !!prevMap.get(header)?.expanded,
            items: Array.from(values.values()).sort((a, b) => (a.value < b.value ? -1 : 1)),
        });
    }

    return ret;
}

interface FilterGroupBuild {
    header: string;
    values: Map<any, FilterItem>;
}

interface FilterGroup {
    expanded: boolean;
    header: string;
    items: FilterItem[];
}

interface FilterItem {
    value: any;
    label: string;
    identifier?: string;
    reaction_ids: Set<string>;
}

export function HTE2MSFiltersUI({ model }: { model: HTE2MSDesignModel }) {
    return (
        <div className='d-flex flex-column w-100 h-100'>
            <div className='hte2ms-reactions-header font-body-small ps-2 hstack'>
                Filters
                <div className='m-auto' />
                <SelectedOnlyButton model={model} />
            </div>
            <div className='flex-grow-1 position-relative vstack h-100'>
                <div className='flex-shrink-0 m-2 mb-0'>
                    <FiltersFilter model={model} />
                </div>
                <div className='flex-grow-1 position-relative'>
                    <ScrollBox className='mt-2 p-2 pt-0'>
                        <FilterGroups model={model} />
                    </ScrollBox>
                </div>
            </div>
        </div>
    );
}

function FiltersFilter({ model }: { model: HTE2MSDesignModel }) {
    const filters = useBehavior(model.state.filters);
    const filter = useBehavior(filters.state.filter);

    return (
        <div className='hstack'>
            <TextInput
                size='sm'
                className='flex-grow-1'
                value={filter}
                placeholder='Filter categories...'
                setValue={(v) => filters.state.filter.next(v)}
                immediate
                selectOnFocus
            />
            <IconDropdownButton icon={iiSelectThis} size='sm' className='py-0 ms-2'>
                <Dropdown.Item onClick={filters.inputProductIdentifiers}>Select Product Identifiers</Dropdown.Item>
                <Dropdown.Item onClick={model.invertSelection}>Invert Selection</Dropdown.Item>
            </IconDropdownButton>
            <IconButton icon={iiUnion} title='Select All' onClick={model.selectAll} />
        </div>
    );
}

function SelectedOnlyButton({ model }: { model: HTE2MSDesignModel }) {
    useBehavior(model.table.version);
    return (
        <Form.Switch
            checked={model.table.state.showSelectedRowsOnly || false}
            onChange={(e) => model.table.setSelectedRowsOnly(e.target.checked)}
            id='filters-selected-rows-only'
            label='Show Only Selected Reactions'
            className='me-2 inline-switch font-body-xsmall'
        />
    );
}

function FilterGroups({ model }: { model: HTE2MSDesignModel }) {
    const filters = useBehavior(model.state.filters);
    const groups = useBehavior(filters.state.groups);

    return (
        <div className='vstack gap-1'>
            {groups.map((group) => (
                <Group key={group.header} model={filters} group={group} />
            ))}
        </div>
    );
}

function Group({ model, group }: { model: HTE2MSDesignFiltersModel; group: FilterGroup }) {
    const filter = useBehavior(model.state.filter);

    const filtered = useMemo(() => {
        if (!filter?.trim()) return group.items;
        const f = filter.toLowerCase().trim();
        return group.items.filter((i) => i.label.toLowerCase().includes(f));
    }, [filter.trim(), group]);

    if (filtered.length === 0) return null;

    return (
        <div className='hte2ms-design-filter-group font-body-xsmall vstack gap-1'>
            <IconButton
                icon={group.expanded ? faCaretDown : faCaretRight}
                size='sm'
                onClick={() => model.toggleExpanded(group)}
            >
                {group.header} (
                {filtered.length !== group.items.length
                    ? `${filtered.length}/${group.items.length}`
                    : `${group.items.length}`}
                )
            </IconButton>
            {group.expanded && filtered.map((item) => <Item key={item.label} model={model} item={item} />)}
        </div>
    );
}

function Item({ model, item }: { model: HTE2MSDesignFiltersModel; item: FilterItem }) {
    const version = useBehavior(model.model.table.version);
    const count = useMemo(() => {
        const {
            table: { selectedRows },
            all,
        } = model.model;
        let ret = 0;
        for (let i = 0; i < all.length; i++) {
            if (selectedRows[i] && item.reaction_ids.has(all[i].id)) ret++;
        }
        return ret;
    }, [version, item]);

    const smiles = model.model.model.assets.entities.getStructure(item.identifier!);

    const onEnter = () => model.model.layout.highlightReactionIds(Array.from(item.reaction_ids));
    const onLeave = () => model.model.layout.highlightReactionIds([]);

    if (!smiles) {
        return (
            <div className='hte2ms-design-filter-item hstack gap-2' onMouseEnter={onEnter} onMouseLeave={onLeave}>
                <Button
                    className='hte2ms-design-filter-item-label text-start font-body-small p-0'
                    title={item.label}
                    variant='link'
                    onClick={() => model.select(item, 'this')}
                >
                    {item.label}
                </Button>
                <div className='hte2ms-design-filter-item-counts text-secondary'>
                    {count}/{item.reaction_ids.size}
                </div>
                <div className='hte2ms-design-filter-item-actions hstack gap-2'>
                    <IconButton icon={iiUnion} size='sm' title='Union' onClick={() => model.select(item, 'union')} />
                    <IconButton
                        icon={iiIntersection}
                        size='sm'
                        title='Intersect'
                        onClick={() => model.select(item, 'intersect')}
                    />
                    <IconButton
                        icon={iiSubtraction}
                        size='sm'
                        title='Subtract'
                        onClick={() => model.select(item, 'subtract')}
                    />
                </div>
            </div>
        );
    }

    return (
        <div
            className='hte2ms-design-filter-item hte2ms-design-filter-structure d-flex'
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
        >
            <div className='position-relative hte2ms-design-filter-drawing' onClick={() => model.select(item, 'this')}>
                <AsyncMoleculeDrawing
                    drawer={model.model.model.drawer}
                    smiles={smiles}
                    asBackground
                    autosize
                    width='100%'
                    height='100%'
                    showChemDraw={false}
                />
            </div>
            <div className='vstack gap-1 ps-2' style={{ flexBasis: '60%' }}>
                <div className='m-auto' />
                <div>{item.label}</div>
                <div className='hstack gap-2'>
                    <div className='hte2ms-design-filter-item-counts text-secondary'>
                        {count}/{item.reaction_ids.size}
                    </div>
                    <div className='hte2ms-design-filter-item-actions hstack gap-2'>
                        <IconButton
                            icon={iiSelectThis}
                            size='sm'
                            title='Select this'
                            onClick={() => model.select(item, 'this')}
                        />
                        <IconButton
                            icon={iiUnion}
                            size='sm'
                            title='Union'
                            onClick={() => model.select(item, 'union')}
                        />
                        <IconButton
                            icon={iiIntersection}
                            size='sm'
                            title='Intersect'
                            onClick={() => model.select(item, 'intersect')}
                        />
                        <IconButton
                            icon={iiSubtraction}
                            size='sm'
                            title='Subtract'
                            onClick={() => model.select(item, 'subtract')}
                        />
                    </div>
                </div>
                <div className='m-auto' />
            </div>
        </div>
    );
}

function SelectIdentifiersDialogContent({ stateSubject }: { stateSubject: BehaviorSubject<{ input: string }> }) {
    const state = useBehavior(stateSubject);

    return (
        <div className='vstack gap-2'>
            <TextInput
                textarea
                rows={3}
                placeholder='List of identifiers...'
                autoFocus
                value={state.input}
                setValue={(input) => stateSubject.next({ ...state, input: input.trim() })}
            />
        </div>
    );
}
