export const K_BC_384_56c_3pt_dup_2ctrl = `ID,Point,Row,Column,IsControl
DMSO,1,1,1,True
DMSO,1,1,2,True
DMSO,1,1,3,True
DMSO,1,1,4,True
DMSO,1,1,5,True
DMSO,1,1,6,True
DMSO,1,1,7,True
DMSO,1,1,8,True
Control1,2,1,9,True
Control1,2,1,10,True
Control1,2,1,11,True
Control1,2,1,12,True
Control1,2,1,13,True
Control1,2,1,14,True
Control1,2,1,15,True
Control1,2,1,16,True
Control2,2,1,17,True
Control2,2,1,18,True
Control2,2,1,19,True
Control2,2,1,20,True
Control2,2,1,21,True
Control2,2,1,22,True
Control2,2,1,23,True
Control2,2,1,24,True
1,1,2,1,False
1,1,2,2,False
1,2,2,3,False
1,2,2,4,False
1,3,2,5,False
1,3,2,6,False
2,1,2,7,False
2,1,2,8,False
2,2,2,9,False
2,2,2,10,False
2,3,2,11,False
2,3,2,12,False
3,1,2,13,False
3,1,2,14,False
3,2,2,15,False
3,2,2,16,False
3,3,2,17,False
3,3,2,18,False
4,1,2,19,False
4,1,2,20,False
4,2,2,21,False
4,2,2,22,False
4,3,2,23,False
4,3,2,24,False
5,1,3,1,False
5,1,3,2,False
5,2,3,3,False
5,2,3,4,False
5,3,3,5,False
5,3,3,6,False
6,1,3,7,False
6,1,3,8,False
6,2,3,9,False
6,2,3,10,False
6,3,3,11,False
6,3,3,12,False
7,1,3,13,False
7,1,3,14,False
7,2,3,15,False
7,2,3,16,False
7,3,3,17,False
7,3,3,18,False
8,1,3,19,False
8,1,3,20,False
8,2,3,21,False
8,2,3,22,False
8,3,3,23,False
8,3,3,24,False
9,1,4,1,False
9,1,4,2,False
9,2,4,3,False
9,2,4,4,False
9,3,4,5,False
9,3,4,6,False
10,1,4,7,False
10,1,4,8,False
10,2,4,9,False
10,2,4,10,False
10,3,4,11,False
10,3,4,12,False
11,1,4,13,False
11,1,4,14,False
11,2,4,15,False
11,2,4,16,False
11,3,4,17,False
11,3,4,18,False
12,1,4,19,False
12,1,4,20,False
12,2,4,21,False
12,2,4,22,False
12,3,4,23,False
12,3,4,24,False
13,1,5,1,False
13,1,5,2,False
13,2,5,3,False
13,2,5,4,False
13,3,5,5,False
13,3,5,6,False
14,1,5,7,False
14,1,5,8,False
14,2,5,9,False
14,2,5,10,False
14,3,5,11,False
14,3,5,12,False
15,1,5,13,False
15,1,5,14,False
15,2,5,15,False
15,2,5,16,False
15,3,5,17,False
15,3,5,18,False
16,1,5,19,False
16,1,5,20,False
16,2,5,21,False
16,2,5,22,False
16,3,5,23,False
16,3,5,24,False
17,1,6,1,False
17,1,6,2,False
17,2,6,3,False
17,2,6,4,False
17,3,6,5,False
17,3,6,6,False
18,1,6,7,False
18,1,6,8,False
18,2,6,9,False
18,2,6,10,False
18,3,6,11,False
18,3,6,12,False
19,1,6,13,False
19,1,6,14,False
19,2,6,15,False
19,2,6,16,False
19,3,6,17,False
19,3,6,18,False
20,1,6,19,False
20,1,6,20,False
20,2,6,21,False
20,2,6,22,False
20,3,6,23,False
20,3,6,24,False
21,1,7,1,False
21,1,7,2,False
21,2,7,3,False
21,2,7,4,False
21,3,7,5,False
21,3,7,6,False
22,1,7,7,False
22,1,7,8,False
22,2,7,9,False
22,2,7,10,False
22,3,7,11,False
22,3,7,12,False
23,1,7,13,False
23,1,7,14,False
23,2,7,15,False
23,2,7,16,False
23,3,7,17,False
23,3,7,18,False
24,1,7,19,False
24,1,7,20,False
24,2,7,21,False
24,2,7,22,False
24,3,7,23,False
24,3,7,24,False
25,1,8,1,False
25,1,8,2,False
25,2,8,3,False
25,2,8,4,False
25,3,8,5,False
25,3,8,6,False
26,1,8,7,False
26,1,8,8,False
26,2,8,9,False
26,2,8,10,False
26,3,8,11,False
26,3,8,12,False
27,1,8,13,False
27,1,8,14,False
27,2,8,15,False
27,2,8,16,False
27,3,8,17,False
27,3,8,18,False
28,1,8,19,False
28,1,8,20,False
28,2,8,21,False
28,2,8,22,False
28,3,8,23,False
28,3,8,24,False
29,1,9,1,False
29,1,9,2,False
29,2,9,3,False
29,2,9,4,False
29,3,9,5,False
29,3,9,6,False
30,1,9,7,False
30,1,9,8,False
30,2,9,9,False
30,2,9,10,False
30,3,9,11,False
30,3,9,12,False
31,1,9,13,False
31,1,9,14,False
31,2,9,15,False
31,2,9,16,False
31,3,9,17,False
31,3,9,18,False
32,1,9,19,False
32,1,9,20,False
32,2,9,21,False
32,2,9,22,False
32,3,9,23,False
32,3,9,24,False
33,1,10,1,False
33,1,10,2,False
33,2,10,3,False
33,2,10,4,False
33,3,10,5,False
33,3,10,6,False
34,1,10,7,False
34,1,10,8,False
34,2,10,9,False
34,2,10,10,False
34,3,10,11,False
34,3,10,12,False
35,1,10,13,False
35,1,10,14,False
35,2,10,15,False
35,2,10,16,False
35,3,10,17,False
35,3,10,18,False
36,1,10,19,False
36,1,10,20,False
36,2,10,21,False
36,2,10,22,False
36,3,10,23,False
36,3,10,24,False
37,1,11,1,False
37,1,11,2,False
37,2,11,3,False
37,2,11,4,False
37,3,11,5,False
37,3,11,6,False
38,1,11,7,False
38,1,11,8,False
38,2,11,9,False
38,2,11,10,False
38,3,11,11,False
38,3,11,12,False
39,1,11,13,False
39,1,11,14,False
39,2,11,15,False
39,2,11,16,False
39,3,11,17,False
39,3,11,18,False
40,1,11,19,False
40,1,11,20,False
40,2,11,21,False
40,2,11,22,False
40,3,11,23,False
40,3,11,24,False
41,1,12,1,False
41,1,12,2,False
41,2,12,3,False
41,2,12,4,False
41,3,12,5,False
41,3,12,6,False
42,1,12,7,False
42,1,12,8,False
42,2,12,9,False
42,2,12,10,False
42,3,12,11,False
42,3,12,12,False
43,1,12,13,False
43,1,12,14,False
43,2,12,15,False
43,2,12,16,False
43,3,12,17,False
43,3,12,18,False
44,1,12,19,False
44,1,12,20,False
44,2,12,21,False
44,2,12,22,False
44,3,12,23,False
44,3,12,24,False
45,1,13,1,False
45,1,13,2,False
45,2,13,3,False
45,2,13,4,False
45,3,13,5,False
45,3,13,6,False
46,1,13,7,False
46,1,13,8,False
46,2,13,9,False
46,2,13,10,False
46,3,13,11,False
46,3,13,12,False
47,1,13,13,False
47,1,13,14,False
47,2,13,15,False
47,2,13,16,False
47,3,13,17,False
47,3,13,18,False
48,1,13,19,False
48,1,13,20,False
48,2,13,21,False
48,2,13,22,False
48,3,13,23,False
48,3,13,24,False
49,1,14,1,False
49,1,14,2,False
49,2,14,3,False
49,2,14,4,False
49,3,14,5,False
49,3,14,6,False
50,1,14,7,False
50,1,14,8,False
50,2,14,9,False
50,2,14,10,False
50,3,14,11,False
50,3,14,12,False
51,1,14,13,False
51,1,14,14,False
51,2,14,15,False
51,2,14,16,False
51,3,14,17,False
51,3,14,18,False
52,1,14,19,False
52,1,14,20,False
52,2,14,21,False
52,2,14,22,False
52,3,14,23,False
52,3,14,24,False
53,1,15,1,False
53,1,15,2,False
53,2,15,3,False
53,2,15,4,False
53,3,15,5,False
53,3,15,6,False
54,1,15,7,False
54,1,15,8,False
54,2,15,9,False
54,2,15,10,False
54,3,15,11,False
54,3,15,12,False
55,1,15,13,False
55,1,15,14,False
55,2,15,15,False
55,2,15,16,False
55,3,15,17,False
55,3,15,18,False
56,1,15,19,False
56,1,15,20,False
56,2,15,21,False
56,2,15,22,False
56,3,15,23,False
56,3,15,24,False
Control2,2,16,1,True
Control2,2,16,2,True
Control2,2,16,3,True
Control2,2,16,4,True
Control2,2,16,5,True
Control2,2,16,6,True
Control2,2,16,7,True
Control2,2,16,8,True
Control1,2,16,9,True
Control1,2,16,10,True
Control1,2,16,11,True
Control1,2,16,12,True
Control1,2,16,13,True
Control1,2,16,14,True
Control1,2,16,15,True
Control1,2,16,16,True
DMSO,1,16,17,True
DMSO,1,16,18,True
DMSO,1,16,19,True
DMSO,1,16,20,True
DMSO,1,16,21,True
DMSO,1,16,22,True
DMSO,1,16,23,True
DMSO,1,16,24,True
`;
