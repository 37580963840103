import { faKeyboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef } from 'react';
import { BehaviorSubject, combineLatest, distinctUntilChanged, Subject, throttleTime } from 'rxjs';
import { TooltipWrapper } from '../../../components/common/Tooltips';
import useBehavior from '../../../lib/hooks/useBehavior';
import { rgb2hsl } from '../../../lib/util/colors';
import { arrayEqual } from '../../../lib/util/misc';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { guid4 } from '../../../lib/util/uuid';
import { WellLayout, WellRect, WellSelection, PlateDimensions } from '../experiment-data';
import {
    createCheckers,
    getFirstSelectedIndex,
    getWellCoords,
    getWellIndex,
    plateRowLabel,
    selectionFromRect,
    selectionSingleton,
} from './utils';
import { getCurrentTheme } from '../../../components/theme';

const FontSizes: Record<WellLayout, number> = {
    24: 10,
    96: 10,
    384: 10,
    1536: 8,
};

const SolidBorderPeriods: Record<WellLayout, [number, number]> = {
    24: [0, 0],
    96: [0, 0],
    384: [2, 2],
    1536: [4, 4],
};

export const PlateMinColorHSL = rgb2hsl([0xef, 0xad, 0xce]);
export const PlateMaxColorHSL = rgb2hsl([0x9e, 0xc5, 0xfe]);
const theme = getCurrentTheme();
export const PlateWellColoring = {
    NoColor: theme === 'dark' ? '#121212' : '#f9f9f9',
    NonEmptyColor: '#2f2f2f',
};

const LastInteractedPlateKey = new BehaviorSubject<string | undefined>(undefined);

export type PlateVisualColors = (string | string[])[];
export type PlateVisualLabels = ({ color: string; text: string; scale?: number } | null | undefined)[];

export class PlateVisualModel extends ReactiveModel {
    readonly key = guid4();

    readonly state = {
        colors: undefined as any as BehaviorSubject<PlateVisualColors>,
        labels: undefined as any as BehaviorSubject<PlateVisualLabels>,
        highlight: new BehaviorSubject<number[]>([]),
        selection: undefined as any as BehaviorSubject<WellSelection>,
        copy: new BehaviorSubject<WellSelection | undefined>(undefined),
        throttledSelection: new BehaviorSubject<WellSelection>([]),
    };

    readonly events = {
        select: new Subject<WellRect | undefined>(),
    };

    get selection() {
        return this.state.selection.value;
    }

    readonly emptySelection: WellSelection;
    private uncheckeredSelection: WellSelection | undefined = undefined;
    private parentElement: HTMLDivElement | undefined = undefined;
    private currentElement: HTMLDivElement | undefined = undefined;
    private plateCanvas: HTMLCanvasElement | undefined = undefined;
    private plateCtx2d: CanvasRenderingContext2D | undefined = undefined;
    private selectionCanvas: HTMLCanvasElement | undefined = undefined;
    private selectionCtx2d: CanvasRenderingContext2D | undefined = undefined;
    private resizeObserver = new ResizeObserver(() => {
        this.renderPlate();
        this.renderSelection();
    });

    mount(element: HTMLDivElement, parentElement: HTMLDivElement) {
        this.currentElement = element;
        this.parentElement = parentElement;
        const [w, h] = PlateDimensions[this.layout];

        const fontSize = `${(this.options?.fontScale ?? 1) * FontSizes[this.layout]} px`;

        for (let u = 0; u < h; u++) {
            const cell = document.createElement('div');
            cell.style.position = 'absolute';
            cell.className = 'hte-experiment-plate-label';
            cell.style.left = '-24px';
            cell.style.top = `calc(${(100 * u) / h}% + 0.5px)`;
            cell.style.width = '24px';
            cell.style.paddingRight = '6px';
            cell.style.height = `calc(${100 / h}%)`;
            cell.style.justifyContent = 'right';
            cell.style.alignItems = 'center';
            cell.style.fontSize = fontSize;
            cell.innerHTML = plateRowLabel(u);
            element.appendChild(cell);
        }

        for (let v = 0; v < w; v++) {
            const cell = document.createElement('div');
            cell.style.position = 'absolute';
            cell.className = 'hte-experiment-plate-label';
            cell.style.left = `calc(${(100 * v) / w}% + 0.5px)`;
            cell.style.top = `-14px`;
            cell.style.width = `${100 / w}%`;
            cell.style.height = `14px`;
            cell.style.paddingBottom = `2px`;
            cell.style.justifyContent = 'center';
            cell.style.alignItems = 'center';
            cell.style.fontSize = fontSize;
            cell.innerHTML = `${v + 1}`;
            element.appendChild(cell);
        }

        const plateCanvas = document.createElement('canvas');
        plateCanvas.className = 'hte-experiment-plate-selection-canvas';
        this.plateCanvas = plateCanvas;
        element.appendChild(plateCanvas);
        this.plateCtx2d = plateCanvas.getContext('2d')!;

        const selectionCanvas = document.createElement('canvas');
        selectionCanvas.className = 'hte-experiment-plate-selection-canvas';
        this.selectionCanvas = selectionCanvas;
        element.appendChild(selectionCanvas);
        this.selectionCtx2d = selectionCanvas.getContext('2d')!;

        this.resizeObserver.observe(element);

        window.addEventListener('keydown', this.keyDown);

        if (this.options?.singleSelect) {
            parentElement.addEventListener('mousedown', this.mouseSingleSelect);
        } else {
            parentElement.addEventListener('mousemove', this.mouseMove);
            parentElement.addEventListener('mouseout', this.mouseOut);
            parentElement.addEventListener('mousedown', this.mouseDown);
            parentElement.addEventListener('mouseup', this.mouseUp);
            parentElement.addEventListener('dblclick', this.dblClick);
            document.addEventListener('mouseup', this.mouseUp);
        }
    }

    unmount() {
        if (!this.parentElement) return;

        if (LastInteractedPlateKey.value === this.key) {
            LastInteractedPlateKey.next(undefined);
        }

        const { parentElement, currentElement } = this;
        currentElement!.innerHTML = '';
        if (this.options?.singleSelect) {
            parentElement.removeEventListener('click', this.mouseSingleSelect);
        } else {
            parentElement.removeEventListener('mousemove', this.mouseMove);
            parentElement.removeEventListener('mouseout', this.mouseOut);
            parentElement.removeEventListener('mousedown', this.mouseDown);
            parentElement.removeEventListener('mouseup', this.mouseUp);
            parentElement.removeEventListener('dblclick', this.dblClick);
            document.removeEventListener('mouseup', this.mouseUp);
        }
        window.removeEventListener('keydown', this.keyDown);
        this.resizeObserver.unobserve(currentElement!);

        this.currentElement = undefined;
        this.parentElement = undefined;
        this.plateCanvas = undefined;
        this.plateCtx2d = undefined;
        this.selectionCanvas = undefined;
        this.selectionCtx2d = undefined;
    }

    dispose() {
        super.dispose();
        this.unmount();
    }

    checkerizeSelection(quadrant: number) {
        if (!this.uncheckeredSelection) {
            this.uncheckeredSelection = this.state.selection.value;
        }
        const newSel = createCheckers(this.layout, this.uncheckeredSelection, quadrant);
        this.state.selection.next(newSel);
    }

    uncheckerizeSelection() {
        if (!this.uncheckeredSelection) return;
        this.state.selection.next(this.uncheckeredSelection);
        this.uncheckeredSelection = undefined;
    }

    clearSelection() {
        if (this.state.selection.value.some((i) => i)) {
            this.state.selection.next(this.emptySelection);
        }
    }

    selectIndices(indices: number[]) {
        const selection = new Array(this.layout).fill(0);
        for (const i of indices) selection[i] = 1;
        this.state.selection.next(selection);
    }

    private getIndex(ev: MouseEvent): CellIndex {
        if (!this.currentElement) return NoneCellIndex;

        const bounds = this.currentElement.getBoundingClientRect();
        const x = ev.clientX - bounds.left;
        const y = ev.clientY - bounds.top;

        if (x > bounds.width || y > bounds.height) return NoneCellIndex;
        if (x <= 0 && y <= 0) return NoneCellIndex;

        const [w, h] = PlateDimensions[this.layout];
        const dx = this.currentElement.clientWidth / w;
        const dy = this.currentElement.clientHeight / h;
        const row = Math.floor(y / dy - 0.05);
        const col = Math.floor(x / dx - 0.05);

        if (x <= 0 && row < h && row >= 0) return { type: 'row', index: row };
        if (y <= 0 && col < w && col >= 0) return { type: 'col', index: col };

        const idx = row * w + col;
        if (idx < 0 || idx >= this.layout) return NoneCellIndex;
        return { type: 'cell', index: idx };
    }

    private singletonMouseDownSelection = -1;
    private mouseDownStart: CellIndex = NoneCellIndex;
    private unionSelection: WellSelection | undefined;

    private dblClick = () => {
        this.options?.clearCopy?.();
    };

    private keyDown = (ev: KeyboardEvent) => {
        const tagName = (ev.target as Element)?.tagName.toUpperCase();
        if (LastInteractedPlateKey.value !== this.key || tagName === 'TEXTAREA' || tagName === 'INPUT') {
            return;
        }

        const firstIndex = getFirstSelectedIndex(this.state.selection.value);
        if (firstIndex < 0) {
            return;
        }

        let direction: [number, number] | undefined;
        switch (ev.key.toLowerCase()) {
            case 'arrowup':
            case 'w':
                direction = [-1, 0];
                break;
            case 'arrowdown':
            case 's':
                direction = [1, 0];
                break;
            case 'arrowleft':
            case 'a':
                direction = [0, -1];
                break;
            case 'arrowright':
            case 'd':
                direction = [0, 1];
                break;
            default:
                break;
        }

        if (!direction) return;
        ev.preventDefault();

        const [w, h] = PlateDimensions[this.layout];
        let [r, c] = getWellCoords(w, firstIndex);
        r = (r + direction[0] + h) % h;
        c = (c + direction[1] + w) % w;
        const nextIdx = getWellIndex(w, r, c);
        this.events.select.next([nextIdx, nextIdx]);
        this.singletonMouseDownSelection = nextIdx;
    };

    private mouseDown = (ev: MouseEvent) => {
        LastInteractedPlateKey.next(this.key);

        this.state.highlight.next([]);
        const index = this.getIndex(ev);

        if (index.type === 'none') return;

        this.unionSelection =
            !this.options?.disableMultiselect && (ev.ctrlKey || ev.metaKey || ev.shiftKey)
                ? this.state.selection.value
                : undefined;
        this.singletonMouseDownSelection = selectionSingleton(this.state.selection.value);
        this.state.highlight.next([]);
        this.mouseDownStart = index;

        if (index.type === 'cell') {
            this.events.select.next([index.index, index.index]);
        } else if (index.type === 'row') {
            this.selectRowRange(index.index, index.index);
        } else if (index.type === 'col') {
            this.selectColRange(index.index, index.index);
        }
    };

    private mouseSingleSelect = (ev: MouseEvent) => {
        LastInteractedPlateKey.next(this.key);

        const index = this.getIndex(ev);
        if (index.type === 'cell' && index.index >= 0 && this.singletonMouseDownSelection === index.index) {
            this.events.select.next(undefined);
            this.singletonMouseDownSelection = -1;
        } else if (index.type === 'cell') {
            this.singletonMouseDownSelection = index.index;
            this.events.select.next([index.index, index.index]);
        }
    };

    private mouseUp = (ev: MouseEvent) => {
        const index = this.getIndex(ev);
        if (index.type === 'cell' && index.index >= 0 && this.singletonMouseDownSelection === index.index) {
            this.events.select.next(undefined);
        }
        this.unionSelection = undefined;
        this.mouseDownStart = NoneCellIndex;
    };

    private mouseMove = (ev: MouseEvent) => {
        const index = this.getIndex(ev);
        if (index.type === 'none') return;

        if (this.mouseDownStart.type === 'none') {
            this.highlight(index);
        } else if (index.type === 'cell') {
            if (this.mouseDownStart.type === 'cell') {
                this.events.select.next([
                    Math.min(this.mouseDownStart.index, index.index),
                    Math.max(this.mouseDownStart.index, index.index),
                ]);
            } else if (this.mouseDownStart.type === 'row') {
                const [w, _] = PlateDimensions[this.layout];
                this.selectRowRange(this.mouseDownStart.index, Math.floor(index.index / w));
            } else if (this.mouseDownStart.type === 'col') {
                const [w, _] = PlateDimensions[this.layout];
                this.selectColRange(this.mouseDownStart.index, index.index % w);
            }
        } else if (index.type === 'row' && this.mouseDownStart.type === 'row') {
            this.selectRowRange(index.index, this.mouseDownStart.index);
        } else if (index.type === 'col' && this.mouseDownStart.type === 'col') {
            this.selectColRange(index.index, this.mouseDownStart.index);
        }
    };

    toIndices(index: CellIndex) {
        if (index.type === 'cell') {
            return [index.index];
        }
        if (index.type === 'row') {
            const row = index.index;
            const [w, _] = PlateDimensions[this.layout];
            const indices = [] as number[];
            const o = index.offset ?? 0;
            const s = index.stride ?? 1;
            for (let i = o; i < w; i += s) indices.push(row * w + i);
            return indices;
        }
        if (index.type === 'col') {
            const col = index.index;
            const [w, h] = PlateDimensions[this.layout];
            const indices = [] as number[];
            const o = index.offset ?? 0;
            const s = index.stride ?? 1;
            for (let i = o; i < h; i += s) indices.push(i * w + col);
            return indices;
        }
        return [];
    }

    highlight(index: CellIndex) {
        const indices = this.toIndices(index);
        if (indices.length) this.state.highlight.next(indices);
        else if (this.state.highlight.value.length > 0) {
            this.state.highlight.next([]);
        }
    }

    private selectRowRange(a: number, b: number) {
        const l = Math.min(a, b);
        const r = Math.max(a, b);
        const [w, _] = PlateDimensions[this.layout];
        this.events.select.next([l * w, (r + 1) * w - 1]);
    }

    private selectColRange(a: number, b: number) {
        const l = Math.min(a, b);
        const r = Math.max(a, b);
        const [w, h] = PlateDimensions[this.layout];
        this.events.select.next([l, w * (h - 1) + r]);
    }

    private mouseOut = () => {
        this.state.highlight.next([]);
    };

    private renderPlate() {
        const ctx = this.plateCtx2d;
        if (!ctx) return;
        const { clientWidth: width, clientHeight: height } = this.currentElement!;
        if (this.plateCanvas!.width !== width) this.plateCanvas!.width = width;
        if (this.plateCanvas!.height !== height) this.plateCanvas!.height = height;

        ctx.clearRect(0, 0, width, height);

        const colors = this.state.colors.value;
        const labels = this.state.labels.value;
        const [w, h] = PlateDimensions[this.layout];
        const dx = width / w;
        const dy = height / h;

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        for (let row = 0; row < h; row++) {
            for (let col = 0; col < w; col++) {
                const o = row * w + col;

                const x = col * dx - 0.5;
                const y = row * dy - 0.5;
                assignFillColor(ctx, colors[o], x, y, dx, dy);
                ctx.fillRect(x, y, dx + 1, dy + 1);

                if (labels[o]) {
                    const { color, text, scale } = labels[o]!;
                    ctx.fillStyle = color;
                    ctx.font = `${(1 / 2) * (scale ?? 1) * dy}px monospace`;
                    ctx.fillText(text, x + dx / 2, y + dy / 2 + 1);
                }
            }
        }

        ctx.setLineDash([]);
        ctx.strokeStyle = 'rgba(108, 117, 125, 0.5)'; // gray-600 base
        ctx.lineWidth = 0.5;
        const [pRow, pCol] = SolidBorderPeriods[this.layout];
        if (pRow > 0) {
            for (let row = pRow; row < h; row += pRow) {
                ctx.beginPath();
                ctx.moveTo(0, row * dy);
                ctx.lineTo(width, row * dy);
                ctx.stroke();
            }
        }
        if (pCol > 0) {
            for (let col = pCol; col < w; col += pCol) {
                ctx.beginPath();
                ctx.moveTo(col * dx, 0);
                ctx.lineTo(col * dx, height);
                ctx.stroke();
            }
        }

        ctx.strokeStyle = 'rgba(108, 117, 125, 1.0)'; // gray-600 base
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(1, 1);
        ctx.lineTo(1, height);
        ctx.lineTo(width, height);
        ctx.lineTo(width, 1);
        ctx.lineTo(1, 1);
        ctx.stroke();

        ctx.lineWidth = 0.5;
        ctx.strokeStyle = 'rgba(108, 117, 125, 0.75)'; // gray-600 base
        ctx.setLineDash([1, 2]);

        for (let row = 1; row < h; row++) {
            if (row % pRow === 0) continue;

            ctx.beginPath();
            ctx.moveTo(0, row * dy);
            ctx.lineTo(width, row * dy);
            ctx.stroke();
        }
        for (let col = 1; col < w; col++) {
            if (col % pCol === 0) continue;

            ctx.beginPath();
            ctx.moveTo(col * dx, 0);
            ctx.lineTo(col * dx, height);
            ctx.stroke();
        }
    }

    private isDarkTheme = getCurrentTheme() === 'dark';
    private renderSelection() {
        const ctx = this.selectionCtx2d;
        if (!ctx) return;
        const { clientWidth: width, clientHeight: height } = this.currentElement!;
        if (this.selectionCanvas!.width !== width) this.selectionCanvas!.width = width;
        if (this.selectionCanvas!.height !== height) this.selectionCanvas!.height = height;

        ctx.clearRect(0, 0, width, height);

        ctx.setLineDash([]);
        ctx.fillStyle = this.options?.selectionColor?.[0] ?? 'rgba(12, 95, 111, 0.2)';
        ctx.strokeStyle = this.options?.selectionColor?.[1] ?? 'rgba(12, 95, 111, 1.0)';
        ctx.lineWidth = 2;
        drawSelection(ctx, this.state.selection.value, { layout: this.layout, width, height });

        if (this.state.copy.value) {
            ctx.fillStyle = 'rgba(255, 193, 7, 0.33)';
            ctx.strokeStyle = 'rgba(255, 193, 7, 1.0)';
            ctx.lineWidth = 4;
            ctx.setLineDash([10, 5]);
            drawSelection(ctx, this.state.copy.value, { layout: this.layout, width, height });
        }

        const highlight = this.state.highlight.value;
        if (highlight.length > 0) {
            ctx.setLineDash([]);
            ctx.fillStyle = this.isDarkTheme ? 'rgba(18, 142, 164, 0.8)' : 'rgba(18, 142, 164, 0.2)';
            ctx.strokeStyle = this.isDarkTheme ? 'white' : 'black'; // 'rgba(18, 142, 164, 1.0)';
            ctx.lineWidth = 2;
            const selection = new Array(this.layout).fill(0);
            for (const index of this.state.highlight.value) selection[index] = 1;
            drawSelection(ctx, selection, { layout: this.layout, width, height });
        }
    }

    constructor(
        public layout: WellLayout,
        public options?: {
            disableMultiselect?: boolean;
            defaultColor?: string;
            selectionColor?: [main: string, outline: string];
            clearCopy?: () => void;
            singleSelect?: boolean;
            fontScale?: number;
        }
    ) {
        super();

        this.emptySelection = new Array(layout).fill(0);
        this.state.colors = new BehaviorSubject(
            new Array(layout).fill(options?.defaultColor ?? PlateWellColoring.NoColor)
        );
        this.state.labels = new BehaviorSubject(new Array(layout).fill(null));
        this.state.selection = new BehaviorSubject<WellSelection>(this.emptySelection);
        this.subscribe(combineLatest([this.state.colors, this.state.labels]), () => this.renderPlate());

        this.subscribe(this.state.highlight.pipe(distinctUntilChanged(arrayEqual)), () => this.renderSelection());
        this.subscribe(this.state.selection.pipe(distinctUntilChanged(arrayEqual)), () => this.renderSelection());
        this.subscribe(this.state.copy, () => this.renderSelection());

        this.subscribe(
            this.state.selection.pipe(throttleTime(33, undefined, { leading: true, trailing: true })),
            (sel) => this.state.throttledSelection.next(sel)
        );

        this.subscribe(this.events.select, (sel) => {
            this.uncheckeredSelection = undefined;

            if (!sel) {
                this.state.selection.next(this.emptySelection);
            } else {
                this.state.selection.next(selectionFromRect(layout, sel, this.unionSelection));
            }
        });
    }
}

type CellIndex =
    | {
          type: 'cell' | 'col' | 'row';
          index: number;
          offset?: number;
          stride?: number;
      }
    | { type: 'none' };

const NoneCellIndex: CellIndex = { type: 'none' };

export function PlateVisual({ model }: { model: PlateVisualModel }) {
    const parentRef = useRef<HTMLDivElement | null>(null);
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        model.mount(ref.current!, parentRef.current!);
        return () => model.unmount();
    }, [model]);

    return (
        <div className='hte-experiment-plate-visual' ref={parentRef}>
            {/* NOTE: it is possible to set width/height to >100% here
             and overflow: auto on parent for zoom-like functionality */}
            <div className='hte-experiment-plate-visual-wrapper' ref={ref} />
            <PlateNavigationKeyboard model={model} />
        </div>
    );
}

function assignFillColor(
    ctx: CanvasRenderingContext2D,
    color: string | string[],
    x: number,
    y: number,
    dx: number,
    dy: number
) {
    if (typeof color === 'string') {
        ctx.fillStyle = color;
    } else if (color.length === 0) {
        ctx.fillStyle = 'black';
    } else if (color.length === 1) {
        ctx.fillStyle = color[0];
    } else {
        const grad = ctx.createLinearGradient(x, y, x + dx, y + dy);
        const l = color.length;
        for (let i = 0; i < l; i++) {
            grad.addColorStop(i / l, color[i]);
            grad.addColorStop((i + 1) / l, color[i]);
        }
        ctx.fillStyle = grad;
    }
}

function drawSelection(
    ctx: CanvasRenderingContext2D,
    selection: WellSelection,
    options: { layout: WellLayout; width: number; height: number }
) {
    const [w, h] = PlateDimensions[options.layout];

    const dx = options.width / w;
    const dy = options.height / h;

    for (let row = 0; row < h; row++) {
        for (let col = 0; col < w; col++) {
            const o = row * w + col;
            if (!selection[o]) continue;

            const x = col * dx;
            const y = row * dy;
            ctx.fillRect(x, y, dx, dy);

            // left border
            if (col === 0 || !selection[row * w + col - 1]) {
                ctx.beginPath();
                ctx.moveTo(x + 1, y);
                ctx.lineTo(x + 1, y + dy);
                ctx.stroke();
            }
            // right border
            if (col === w - 1 || !selection[row * w + col + 1]) {
                ctx.beginPath();
                ctx.moveTo(x + dx - 1, y);
                ctx.lineTo(x + dx - 1, y + dy);
                ctx.stroke();
            }
            // top border
            if (row === 0 || !selection[(row - 1) * w + col]) {
                ctx.beginPath();
                ctx.moveTo(x, y + 1);
                ctx.lineTo(x + dx, y + 1);
                ctx.stroke();
            }
            // bottom border
            if (row === h - 1 || !selection[(row + 1) * w + col]) {
                ctx.beginPath();
                ctx.moveTo(x, y + dy - 1);
                ctx.lineTo(x + dx, y + dy - 1);
                ctx.stroke();
            }
        }
    }
}

function PlateNavigationKeyboard({ model }: { model: PlateVisualModel }) {
    const lastPlateKey = useBehavior(LastInteractedPlateKey);
    const selection = useBehavior(model.state.selection);

    if (lastPlateKey !== model.key || getFirstSelectedIndex(selection) < 0) {
        return null;
    }

    return (
        <div className='hte-experiment-plate-visual-keyboard'>
            <TooltipWrapper tooltip='Use W/S/A/D or arrow keys to navigate the plate'>
                {(props) => <FontAwesomeIcon icon={faKeyboard} size='sm' {...props} />}
            </TooltipWrapper>
        </div>
    );
}
