import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { ErrorMessage } from '../../../components/common/Error';
import { Footer, PageTemplate } from '../../../components/Layout/Layout';
import useBehavior from '../../../lib/hooks/useBehavior';
import { DialogService } from '../../../lib/services/dialog';
import { Column, ColumnsFor, DataTableControl, DataTableModel, DataTableStore } from '../../../components/DataTable';
import { SampleUploadModel } from './sample-upload-model';
import { SampleReview } from '../compound-api';
import { EntrySummary, EntrySummaryComponent } from '../../../components/common/EntrySummary';
import { SelectionColumn } from '../../../components/DataTable/common';

function createTable(sampleReview: DataTableStore<SampleReview>) {
    const SampleTableSchema: ColumnsFor<SampleReview> = {
        batch: Column.create({
            kind: 'str',
            noHeaderTooltip: true,
            filterType: false,
            position: 0,
            width: 135,
        }),
        supplier_id: Column.create({
            kind: 'str',
            noHeaderTooltip: true,
            position: 1,
        }),
        sanitized_supplier_id: Column.create({
            kind: 'str',
            noHeaderTooltip: true,
            position: 2,
            width: 175,
        }),
        barcode: Column.create({
            kind: 'str',
            noHeaderTooltip: true,
            position: 3,
            width: 150,
        }),
        amount: Column.create({
            kind: 'str',
            noHeaderTooltip: true,
            position: 4,
        }),
        error: Column.create({
            kind: 'str',
            noHeaderTooltip: true,
            render: ({ value }) =>
                value ? (
                    <div className='entos-error-cell'>
                        <FontAwesomeIcon icon={faBug} fixedWidth color='var(--bs-danger)' className='me-1' />
                        {value}
                    </div>
                ) : (
                    value
                ),
            width: 200,
            position: 5,
        }),
        warning: Column.create({
            kind: 'str',
            noHeaderTooltip: true,
            render: ({ value }) =>
                value ? (
                    <div className='entos-warning-cell'>
                        <FontAwesomeIcon
                            icon={faCircleExclamation}
                            fixedWidth
                            color='var(--bs-warning)'
                            className='me-1'
                        />
                        {value}
                    </div>
                ) : (
                    value
                ),
            width: 200,
            position: 6,
        }),
    };

    const table = new DataTableModel<SampleReview, 'selection'>(sampleReview, {
        columns: SampleTableSchema,
        actions: [SelectionColumn()],
        hideNonSchemaColumns: true,
    });

    table.setColumnStickiness('selection', true);
    table.toggleSelectAll();

    return table;
}

export function SampleUpload({ model }: { model: SampleUploadModel }) {
    const error = useBehavior(model.state.error);
    const store = useBehavior(model.state.sampleReviewStore);
    const table = useMemo(() => (store ? createTable(store) : undefined), [store]);

    function onNext() {
        if (!table) return;

        const numSelected = Object.keys(table.selectedRows).length;

        DialogService.open({
            type: 'confirm',
            onConfirm,
            title: 'Add to Foundry',
            text: (
                <p>
                    Are you sure you want to add {numSelected} sample{numSelected > 1 ? 's' : ''} to Entos Foundry? This
                    action cannot easily be undone.
                </p>
            ),
            confirmText: 'Add',
        });
    }

    async function onConfirm() {
        if (table) await model.upload(table);
    }

    return (
        <PageTemplate title='Compounds' breadcrumb={{ title: 'Register samples', href: '', icon: undefined }}>
            {error && <ErrorMessage header='Failed to load samples' message={error} />}
            {!error && table && <SampleReviewTable table={table} />}
            {table && <ReviewFooter model={model} table={table} onSubmit={() => onNext()} />}
        </PageTemplate>
    );
}

function SampleReviewTable({ table }: { table: DataTableModel<SampleReview> }) {
    useBehavior(table.version);

    return (
        <div className='h-100 position-relative ms-3'>
            <DataTableControl height='flex' table={table} headerSize='sm' rowSelectionMode='multi' />
        </div>
    );
}

function ReviewFooter({
    model,
    table,
    onSubmit,
}: {
    model: SampleUploadModel;
    table: DataTableModel<SampleReview>;
    onSubmit: () => void;
}) {
    const isLoading = useBehavior(model.state.isLoading);
    useBehavior(table.version);
    const numSelected = Object.keys(table.selectedRows).length;

    const summary: EntrySummary = useMemo(() => {
        const numErrors = table.store.getColumnValues('error').filter((v, i) => !!v && !!table.selectedRows[i]).length;
        return {
            numErrors,
            numWarnings: table.store.getColumnValues('warning').filter((v, i) => !!v && !!table.selectedRows[i]).length,
            numReady: Object.keys(table.selectedRows).length - numErrors,
        };
    }, [table.selectedRows, table.store.version]);

    return (
        <Footer>
            <div className='flex-grow-1 text-secondary'>
                <EntrySummaryComponent kind='row' summary={summary} />
            </div>
            <div className='d-flex align-items-center'>
                <Button className='me-2' variant='link' onClick={() => model.cancel()}>
                    Cancel
                </Button>
                {summary.numErrors > 0 && (
                    <OverlayTrigger
                        placement='auto'
                        overlay={<Tooltip>Fix errors before adding to library</Tooltip>}
                        delay={{ show: 500, hide: 0 }}
                    >
                        {(props) => (
                            <div {...props}>
                                <Button variant='primary' disabled>
                                    Add to Library
                                </Button>
                            </div>
                        )}
                    </OverlayTrigger>
                )}
                {summary.numErrors === 0 && (
                    <Button variant='primary' onClick={onSubmit} disabled={numSelected === 0}>
                        {isLoading && <Spinner animation='border' role='status' size='sm' />}
                        {!isLoading && <>Add to Library</>}
                    </Button>
                )}
            </div>
        </Footer>
    );
}
