import { faBug, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import api from '../../../api';
import { ErrorMessage } from '../../../components/common/Error';
import { SingleFileUpload } from '../../../components/common/FileUpload';
import Loading from '../../../components/common/Loading';
import { PropertyNameValue } from '../../../components/common/PropertyNameValue';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import useBehaviorSubject from '../../../lib/hooks/useBehaviorSubject';
import { DialogService } from '../../../lib/services/dialog';
import { ToastService } from '../../../lib/services/toast';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { decodeEntosMsgpack } from '../../../lib/util/serialization';
import { PlateVisual, PlateVisualModel } from '../../HTE/plate/PlateVisual';
import { getFirstSelectedIndex, getWellIndexLabel } from '../../HTE/plate/utils';
import { CompoundIdentifier } from '../../HTE/steps/reagents-model';
import { Plate, SampleCreate } from '../ecm-api';
import { ECMPageTemplate, ECMPlateWellContents, colorPlateByConcentrations } from '../ecm-common';

export interface PreparePlateFillEntry {
    input_barcode: string;

    errors: string[];
    warnings: string[];

    plate_id?: number;
    barcode?: string;
    sample_updates: Record<number, SampleCreate>;
}

export interface PreparePlateFillData {
    entries: PreparePlateFillEntry[];
    plates: Plate[];
    batch_identifiers: Record<number, string>;
}

export function ECMPlateFill() {
    return (
        <ECMPageTemplate page='plate-fill' withFooter>
            <PlateUploadrapper />
        </ECMPageTemplate>
    );
}

export const ECMFillPlateAPI = {
    prepare: async (file: File): Promise<PreparePlateFillData> => {
        const formData = new FormData();
        formData.append('file', file);
        const { data } = await api.client.post('ecm/plates/prepare-fill', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data, { eoi: 'hex' });
    },
    upload: async (entries: PreparePlateFillEntry[]) => {
        await api.client.post(`ecm/plates/fill`, { entries }, { responseType: 'arraybuffer' });
    },
};

class ECMFillPlateModel {
    state = {
        data: new BehaviorSubject<PreparePlateFillData>(undefined as any),
        entries: new BehaviorSubject<FillEntryWrapper[]>([]),
    };

    get data() {
        return this.state.data.value;
    }

    get entries() {
        return this.state.entries.value;
    }

    async submit() {
        try {
            for (const e of this.entries) e.state.isUploading.next(true);
            await ECMFillPlateAPI.upload(this.entries.map((e) => e.data));
            ToastService.show({
                type: 'success',
                message: `${this.entries.length} plate${this.entries.length > 1 ? 's' : ''} updated`,
                timeoutMs: 2500,
            });
            this.fileSubject.next(null);
        } catch (err) {
            reportErrorAsToast('Plate Fill', err);
        } finally {
            for (const e of this.entries) e.state.isUploading.next(false);
        }
    }

    constructor(data: PreparePlateFillData, private fileSubject: BehaviorSubject<File | null>) {
        this.state.data.next(data);
        this.state.entries.next(data.entries.map((e, i) => new FillEntryWrapper(i, e, this)));
    }
}

class FillEntryWrapper {
    plate?: Plate;

    state = {
        data: new BehaviorSubject<PreparePlateFillEntry>(undefined as any),
        isUploading: new BehaviorSubject(false),
    };

    get data(): PreparePlateFillEntry {
        return this.state.data.value;
    }

    // eslint-disable-next-line
    constructor(public key: number, data: PreparePlateFillEntry, public model: ECMFillPlateModel) {
        this.state.data.next(data);
        this.plate = model.data.plates.find((p) => p.id === data.plate_id);
    }
}

async function uploadFile(file: File, fileSubject: BehaviorSubject<File | null>) {
    try {
        const upload = await ECMFillPlateAPI.prepare(file);
        return new ECMFillPlateModel(upload, fileSubject);
    } catch (err) {
        reportErrorAsToast('Plate Fill', err);
    }
}

function PlateUploadrapper() {
    const [model, loadModel] = useAsyncAction<ECMFillPlateModel>();

    return (
        <>
            <div className='d-flex flex-column h-100'>
                <UploadControls load={loadModel} inline />
                <div className='flex-grow-1' style={{ overflow: 'hidden', overflowY: 'auto' }}>
                    {model.isLoading && <Loading />}
                    {model.error && <ErrorMessage header='Error Uploading Plates' message={`${model.error}`} />}
                    {model.result && <Entries model={model.result} />}
                    {!model.result && !model.isLoading && !model.error && <UploadInfo />}
                </div>
            </div>
            <Footer model={model.result} />
        </>
    );
}

const BASIC_COLUMNS = ['Plate Barcode', 'Well'];

const FILL_COLUMNS = ['Volume', 'Volume Unit (=uL)', 'Concentration', 'Concentration Unit (=mM)'];

const SUBTRACT_COLUMNS = ['Subtract Volume', 'Subtract Volume Unit (=uL)'];

function UploadInfo() {
    return (
        <div className='ecm-plate-upload-info-panel m-auto mt-4'>
            <h5 className='mt-2'>Plate Fill</h5>

            <dl className='row'>
                <dt className='col-sm-3'>Required columns</dt>
                <dd className='col-sm-9'>
                    <code className='code'>{BASIC_COLUMNS.join(', ')}</code>
                </dd>
                <dt className='col-sm-3'>Fill columns</dt>
                <dd className='col-sm-9'>
                    <code className='code'>{FILL_COLUMNS.join(', ')}</code>
                    <div className='text-secondary'>
                        Used to set volume/concentration of non-empty wells (partial updates are supported). If
                        concentration is not set, the existing value will be used.
                    </div>
                </dd>
                <dt className='col-sm-3'>Subtract columns</dt>
                <dd className='col-sm-9'>
                    <code className='code'>{SUBTRACT_COLUMNS.join(', ')}</code>
                    <div className='text-secondary'>
                        Used to substract volume from a subset of wells. Negative value will result in adding volume to
                        a well.
                    </div>
                </dd>
            </dl>
        </div>
    );
}

function UploadControls({ load, inline }: { load: (p: any) => any; inline?: boolean }) {
    const fileSubject = useBehaviorSubject<File | null>(null);
    const file = useBehavior(fileSubject);
    useEffect(() => {
        if (file) load(uploadFile(file, fileSubject));
        else load(undefined);
    }, [file, load]);

    return (
        <div className='hstack gap-2'>
            <div className='flex-grow-1' style={{ marginTop: '-0.5rem' }}>
                <SingleFileUpload
                    fileSubject={fileSubject}
                    label='Drop/Select CSV/XLS to Upload'
                    extensions={['.csv', '.xls', '.xlsx']}
                    inline={inline}
                />
            </div>
            {!!file && (
                <Button variant='outline' onClick={() => fileSubject.next(null)} style={{ marginTop: '-0.5rem' }}>
                    Clear File
                </Button>
            )}
        </div>
    );
}

function Entries({ model }: { model: ECMFillPlateModel }) {
    const entries = useBehavior(model.state.entries);
    return (
        <div className='pb-1'>
            <div className='hstack d-flex flex-direction-column align-items-center ecm-plate-upload-header'>
                <h5 className='py-2'>
                    Fill Details ({entries.length} plate{entries.length !== 1 ? 's' : ''})
                </h5>
                <div className='m-auto' />
            </div>
            {entries.map((e) => (
                <PlateEntry entry={e} key={e.key} />
            ))}
        </div>
    );
}

function PlateEntry({ entry }: { entry: FillEntryWrapper }) {
    return (
        <div className='mt-2 border'>
            <PlateEntryHeader entry={entry} />
            <div className='d-flex'>
                <PlateEntryDetails entry={entry} />
                {!!entry.plate?.size && <PlateMap entry={entry} />}
            </div>
        </div>
    );
}

function PlateEntryHeader({ entry: { state, model, plate } }: { entry: FillEntryWrapper }) {
    useBehavior(model.state.data);

    const data = useBehavior(state.data);
    const isUploading = useBehavior(state.isUploading);

    let statusClass = 'success';

    if (data.errors.length) {
        statusClass = 'danger';
    } else if (data.warnings.length) {
        statusClass = 'warning';
    }

    const statusOffset = 42;

    return (
        <div className='p-2 ecm-plate-upload-card-header'>
            <div className='d-flex align-items-center'>
                <div className='ps-1' style={{ width: statusOffset }}>
                    {isUploading && (
                        <Spinner animation='border' size='sm' className='text-primary me-2' role='status' />
                    )}
                    {!isUploading && (
                        <FontAwesomeIcon
                            icon={statusClass === 'success' ? faCheck : faBug}
                            size='lg'
                            className={`me-2 text-${statusClass}`}
                        />
                    )}
                </div>
                <h6 className='m-0'>Plate {data.barcode || data.input_barcode}</h6>
            </div>
            <div style={{ marginLeft: statusOffset }}>
                <PropertyNameValue field='Size' value={plate?.size ?? 'unknown'} inline />
                <PropertyNameValue
                    field='Non Empty Well Count'
                    value={plate?.samples.filter((s) => !!s).length ?? 'n/a'}
                    inline
                />
                <PropertyNameValue
                    field='Well Update Count'
                    value={Object.keys(data.sample_updates).length || 'n/a'}
                    inline
                />
            </div>
        </div>
    );
}

function PlateEntryDetails({ entry }: { entry: FillEntryWrapper }) {
    const data = useBehavior(entry.state.data);

    return (
        <div className={classNames('flex-grow-1 p-2', entry.plate?.size ? 'border-end' : undefined)}>
            <div className='d-flex ecm-plate-upload-card-content' style={{ height: 210 }}>
                <div className='ecm-plate-upload-details pe-3'>
                    <h6 className='text-secondary mb-1'>Details</h6>
                    <div>
                        <small>Kind:</small>
                    </div>
                    <div>{entry.plate?.kind ?? '-'}</div>
                    <div>
                        <small>Purpose:</small>
                    </div>
                    <div>{entry.plate?.purpose ?? '-'}</div>
                    <div>
                        <small>Description:</small>
                    </div>
                    <div>{entry.plate?.description ?? '-'}</div>
                </div>
                <div className='pe-3'>
                    <h6 className='text-secondary mb-1'>Errors</h6>
                    <MessageList messages={data.errors} kind='danger' />
                </div>
                <div>
                    <h6 className='text-secondary mb-1'>Warnings</h6>
                    <MessageList messages={data.warnings} kind='warning' />
                </div>
            </div>
        </div>
    );
}

function MessageList({ messages, kind }: { messages: string[]; kind: 'danger' | 'warning' }) {
    return (
        <>
            {!messages.length && <div className='text-secondary'>None</div>}
            {messages.length > 0 && (
                <ul className={`text-${kind} ecm-plate-upload-message-list`}>
                    {messages.map((e, i) => (
                        <li key={i}>{e}</li>
                    ))}
                </ul>
            )}
        </>
    );
}

function Footer({ model }: { model?: ECMFillPlateModel }) {
    const [uploadState, upload] = useAsyncAction();
    const entries = useBehavior(model?.state.entries);

    const canSubmit = entries?.every((e) => !e.data.errors.length);

    const numReady = entries?.filter((e) => e.data.warnings.length === 0 && e.data.errors.length === 0).length ?? 0;
    const numErrors = entries?.filter((e) => e.data.errors.length > 0)?.length ?? 0;
    const numWarnings = entries?.filter((e) => e.data.warnings.length > 0)?.length ?? 0;

    const onSubmit = () => {
        DialogService.open({
            type: 'confirm',
            onConfirm: () => upload(model?.submit()),
            title: 'Confirm Upload',
            text: (
                <>
                    <p>Are you sure you want to upload the plate(s) to Foundry?</p>
                    <p className='text-warning'>This action cannot easily be undone/updated.</p>
                </>
            ),
            confirmText: 'Upload',
        });
    };

    return (
        <div className='entos-footer justify-content-between hstack gap-2 border-top'>
            {!!entries && (
                <div className='flex-grow-1 text-secondary'>
                    {`${numReady} plate${numReady !== 1 ? 's' : ''} ready`}
                    {numWarnings > 0 && (
                        <>
                            ,
                            <span className='text-warning text-bold'>
                                {` ${numWarnings} warning${numWarnings > 1 ? 's' : ''}`}
                            </span>
                        </>
                    )}
                    {numErrors > 0 && (
                        <>
                            ,
                            <span className='text-danger text-bold'>
                                {` ${numErrors} error${numErrors > 1 ? 's' : ''}`}
                            </span>
                        </>
                    )}
                </div>
            )}
            <div className='m-auto' />
            {!!entries?.length && !canSubmit && (
                <span className='text-danger me-2'>
                    Fix all errors in the input CSV/XLS and re-upload it before submitting
                </span>
            )}
            {!!model && (
                <Button size='sm' disabled={!canSubmit || uploadState.isLoading} variant='primary' onClick={onSubmit}>
                    {uploadState.isLoading && <Spinner animation='border' size='sm' className='me-2' role='status' />}
                    Submit
                </Button>
            )}
        </div>
    );
}

function PlateMap({ entry }: { entry: FillEntryWrapper }) {
    const plate = entry.plate!;
    const layout = plate.size!;
    const visual = useRef<PlateVisualModel>();
    if (!visual.current) {
        visual.current = new PlateVisualModel(layout, { singleSelect: true });
    }
    useEffect(() => {
        const samples = [...plate.samples];
        for (const [k, v] of Array.from(Object.entries(entry.data.sample_updates))) {
            samples[+k] = v as any;
        }
        const colors = colorPlateByConcentrations(samples);
        visual.current!.state.colors.next(colors);
    }, [entry]);

    return (
        <div
            className='d-flex flex-column position-relative p-2 pe-4'
            style={{ width: 340, minWidth: 340, height: 200 }}
        >
            <div className='flex-grow-1'>
                <PlateVisual model={visual.current} />
            </div>
            <PlateDetailsSelection entry={entry} visual={visual.current} />
        </div>
    );
}

function PlateDetailsSelection({ entry, visual }: { entry: FillEntryWrapper; visual: PlateVisualModel }) {
    const selection = useBehavior(visual.state.selection);
    const fst = getFirstSelectedIndex(selection);
    const sample = entry.data.sample_updates[fst] ?? entry.plate?.samples[fst];
    const label = getWellIndexLabel(entry.plate!.size, fst);
    const batchIdentifier = sample ? entry.model.data.batch_identifiers[sample.batch_id] : undefined;

    return (
        <div className='mt-1 ecm-plate-upload-plate-selection-info'>
            {(fst < 0 || !sample) && <span className='text-secondary'>(Nothing Selected)</span>}
            {!!sample && (
                <>
                    ({label}) {batchIdentifier && <CompoundIdentifier value={batchIdentifier} />}
                    <ECMPlateWellContents sample={sample} />
                </>
            )}
        </div>
    );
}
