import { IconName, IconDefinition } from '@fortawesome/fontawesome-svg-core';

// This is a way to make a new icon to be treated like icons in the
// Font Awesome set - structure kind of copied from their icon definitions.
// see node_modules/@fortawesome/node_modules/@fortawesome/fontawesome-common-types/index.d.ts
// for the IconDefinition type definition

const iconName = 'ii-chart-group' as IconName;
const prefix = 'fas';
const width: number = 16;
const height: number = 16;
const aliases: string[] = [];
const unicode: string = '';
const svgPathData: string = [
    'M15.5 12H2V2.5C2 2.22375 1.77625 2 1.5 2H0.5C0.22375 2 0 2.22375 0 2.5V13C0 13.5522 0.447812 14 1 14H15.5C15.7762 14 16 13.7762 16 13.5V12.5C16 12.2238 15.7762 12 15.5 12Z',
    'M7.60979 6.83344C6.98293 6.87375 6.41404 7.1125 6.09123 7.96313C6.05451 8.06016 5.96623 8.11906 5.86326 8.11906C5.68967 8.11906 5.15297 7.68672 5 7.58234C5.00016 8.86906 5.5928 10 7.00012 10C8.18541 10 9.00008 9.31609 9.00008 8.12203C9.00008 8.07344 8.98992 8.02703 8.98492 7.97937L7.60979 6.83344ZM12.1545 2C11.9177 2 11.6957 2.10484 11.5263 2.25703C8.33243 5.11016 8.0001 5.17719 8.0001 6.01703C8.0001 6.23109 8.05088 6.43516 8.1365 6.62172L9.13367 7.45266C9.24632 7.48078 9.36241 7.5 9.48351 7.5C10.454 7.5 11.0164 6.78953 12.7828 3.49281C12.8981 3.26859 13 3.02641 13 2.77422C13 2.3225 12.5938 2 12.1545 2Z',
].join('');

export const iiChartGroup: IconDefinition = {
    prefix,
    iconName,
    icon: [width, height, aliases, unicode, svgPathData],
};
