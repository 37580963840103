import { BehaviorSubject } from 'rxjs';
import { AsyncMoleculeDrawing } from '../../../components/common/AsyncMoleculeDrawing';
import { formatDatetime } from '../../../lib/util/dates';
import { AsyncMoleculeDrawer } from '../../../lib/util/draw-molecules';
import { groupBy } from '../../../lib/util/misc';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { Option } from '../../../components/common/formatSelectOptionLabel';
import {
    Column,
    columnDataTableStore,
    DefaultRowHeight,
    DataTableModel,
    DataTableStore,
} from '../../../components/DataTable';
import { formatMolecularFormula, SelectionColumn } from '../../../components/DataTable/common';
import { BatchLink } from '../../ECM/ecm-common';
import { CompoundDetailWithFilters, EnumerationAPI, PropertyFilter } from '../enumeration/enumeration-api';

const SmilesRowHeightFactor = 3;
const rowHeight = DefaultRowHeight;

function SimilarCompoundTableSchema(
    drawer: AsyncMoleculeDrawer
): [colName: keyof CompoundDetailWithFilters, column: Column][] {
    return [
        // @ts-ignore
        [
            'structure',
            Column.create({
                kind: 'str',
                label: 'SMILES',
                header: 'SMILES',
                render: ({ value, table, rowIndex }) =>
                    table.state.customState['show-smiles'] ? (
                        <AsyncMoleculeDrawing
                            smiles={value}
                            height='100%'
                            width='100%'
                            drawer={drawer}
                            atomHighlights={table.store.tryGetValue('substructure_matches', rowIndex)}
                            autosize
                        />
                    ) : (
                        value
                    ),
                compare: false,
                noHeaderTooltip: true,
                filterType: false,
                alwaysVisible: true,
                width: 220,
            }),
        ],
        [
            'identifier',
            Column.create({
                kind: 'str',
                noHeaderTooltip: true,
                filterType: false,
                width: 135,
                render: ({ value }) => <BatchLink identifier={value} />,
            }),
        ],
        [
            'substructure_matches',
            Column.create<number[][] | undefined>({
                kind: 'str',
                header: 'Num Substr',
                noHeaderTooltip: true,
                width: 100,
                compare: (a, b) => (a?.length ?? 0) - (b?.length ?? 0),
                render: ({ value }) => value?.length.toString() ?? '',
            }),
        ],
        ['num_h', Column.int()],
        ['num_acceptors', Column.int()],
        ['num_donors', Column.int()],
        [
            'molecular_formula',
            {
                ...Column.float({ defaultFormatting: { significantDigits: 5, scientific: false, decimalPlaces: 2 } }),
                align: 'right',
                noHeaderTooltip: true,
                render: ({ value }) => formatMolecularFormula(value),
            },
        ],
        [
            'molecular_weight',
            {
                ...Column.float({ defaultFormatting: { significantDigits: 5, scientific: false, decimalPlaces: 2 } }),
                align: 'right',
                noHeaderTooltip: true,
            },
        ],
        [
            'project',
            Column.create({
                kind: 'str',
                noHeaderTooltip: true,
            }),
        ],
        [
            'created_by',
            Column.create({
                kind: 'str',
                noHeaderTooltip: true,
            }),
        ],
        [
            'created_on',
            Column.create({
                kind: 'datetime',
                render: ({ value }) => formatDatetime(value, 'date'),
                noHeaderTooltip: true,
            }),
        ],
        [
            'common_name',
            Column.create({
                kind: 'str',
                noHeaderTooltip: true,
                width: 145,
            }),
        ],
        [
            'aliases',
            Column.create({
                kind: 'str',
                noHeaderTooltip: true,
                width: 200,
                render: ({ value }) => <div className='entos-compound-aliases-cell'>{value.join('; ')}</div>,
            }),
        ],
    ];
}

function createTable(compounds: DataTableStore<CompoundDetailWithFilters>) {
    const drawer = new AsyncMoleculeDrawer();
    const table = new DataTableModel(compounds, {
        columns: SimilarCompoundTableSchema(drawer),
        actions: [SelectionColumn()],
        hideNonSchemaColumns: true,
        rowHeight: SmilesRowHeightFactor * rowHeight,
        customState: { 'show-smiles': true },
    });
    table.setColumnStickiness('selection', true);
    table.setColumnStickiness('structure', true);
    return table;
}

type SimilarCompoundsStep = 'input' | 'compounds' | 'batches';

export interface CompoundBatchMap {
    selectedIndices: number[];
    groupedBatches: Map<number, { compound_id: number; identifier: string }[]>;
}

export class SimilarCompoundsService extends ReactiveModel {
    state = {
        showSimilarCompounds: new BehaviorSubject<boolean>(false),
        substructure: new BehaviorSubject<string>(''),
        properties: new BehaviorSubject<PropertyFilter[]>([]),
        results: new BehaviorSubject<DataTableModel<CompoundDetailWithFilters> | null>(null),
        batchSelection: new BehaviorSubject<CompoundBatchMap | undefined>(undefined),
        selections: new BehaviorSubject<Record<number, string[]>>({}),
        step: new BehaviorSubject<SimilarCompoundsStep>('input'),
        status: new BehaviorSubject<{ isLoading: boolean; error: any }>({
            isLoading: false,
            error: undefined,
        }),
    };

    cancel() {
        this.reset();
        this.state.showSimilarCompounds.next(false);
    }

    reset() {
        this.state.results.next(null);
        this.state.substructure.next('');
        this.state.properties.next([]);
        this.state.step.next('input');
    }

    back() {
        const step = this.state.step.value;
        if (step === 'batches') {
            this.state.step.next('compounds');
        } else if (step === 'compounds') {
            this.state.step.next('input');
        }
    }

    async next() {
        if (this.options?.compoundsOnly) {
            const table = this.state.results.value;
            if (!table) return;
            const selectedIndices = Object.keys(table.selectedRows).map((idx) => +idx);
            const compound_identifiers = table.store.getColumnValues('identifier', selectedIndices);
            this.submitResults(compound_identifiers as string[]);
            this.state.showSimilarCompounds.next(false);
            return;
        }

        let hasError = false;
        try {
            this.state.status.next({ isLoading: true, error: undefined });
            const step = this.state.step.value;
            if (step !== 'compounds') return;
            const table = this.state.results.value;
            if (!table) return;
            const selectedIndices = Object.keys(table.selectedRows).map((idx) => +idx);
            const compound_ids = table.store.getColumnValues('id', selectedIndices);
            const compoundBatches = columnDataTableStore(
                await EnumerationAPI.compoundBatches({ compound_ids })
            ).toObjects();
            const groupedBatches = groupBy(compoundBatches, (c) => c.compound_id);
            const selection: Record<number, string[]> = {};
            for (const [cid, xs] of Array.from(groupedBatches.entries())) {
                xs.sort((a, b) => (a.identifier === b.identifier ? 0 : a.identifier < b.identifier ? -1 : 1));
                selection[cid] = [];
            }
            this.state.batchSelection.next({ selectedIndices, groupedBatches });
            this.state.selections.next(selection);
            this.state.step.next('batches');
        } catch (err) {
            hasError = true;
            this.state.status.next({ isLoading: false, error: `${err}` });
        } finally {
            if (!hasError) this.state.status.next({ isLoading: false, error: undefined });
        }
    }

    onSelect(compoundId: number, options: Option[]) {
        const choices: string[] = options.map((option) => option.value);
        this.state.selections.next({
            ...this.state.selections.value,
            [compoundId]: choices,
        });
    }

    async submit() {
        const resultsForCompounds = this.state.batchSelection.value;
        if (!resultsForCompounds) return;

        const inputs: string[] = [];
        for (const [cid, batches] of Array.from(Object.entries(this.state.selections.value))) {
            if (batches.length > 0) inputs.push(...batches);
            else {
                const table = this.state.results.value;
                const rowIndex = table?.store.findValueIndex('id', +cid);
                const identifier =
                    typeof rowIndex === 'number' && rowIndex >= 0
                        ? table?.store.getValue('identifier', rowIndex)
                        : undefined;
                if (identifier) inputs.push(identifier);
            }
        }

        this.submitResults(inputs);
        this.state.showSimilarCompounds.next(false);
        this.reset();
    }

    async findSimilarCompounds() {
        const step = this.state.step.value;
        if (step !== 'input') return;
        this.state.status.next({
            isLoading: true,
            error: undefined,
        });
        try {
            const result = await EnumerationAPI.findSimilarCompounds({
                substructure: this.state.substructure.value,
                properties: this.state.properties.value,
            });
            if (result.index.length === 0) {
                this.state.status.next({
                    isLoading: false,
                    error: 'No results',
                });
            } else {
                const store = columnDataTableStore(result);
                const table = createTable(store);
                this.state.results.next(table);

                this.state.status.next({
                    isLoading: false,
                    error: undefined,
                });
                this.state.step.next('compounds');
            }
        } catch (e) {
            this.state.status.next({
                isLoading: false,
                error: e,
            });
        }
    }

    show() {
        this.state.showSimilarCompounds.next(true);
    }

    constructor(private submitResults: (input: string[]) => void, public options?: { compoundsOnly?: boolean }) {
        super();
    }
}
