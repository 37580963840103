import { faCheck, faPlus, faRemove, faThumbTack } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import saveAs from 'file-saver';
import { useId, useMemo, useRef } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import {
    Column,
    columnDataTableStore,
    DataTableGlobalFilter,
    DataTableControl,
    DataTableModel,
    DefaultRowHeight,
    DataTableSnapshot,
    objectsToColumnTableData,
} from '../../components/DataTable';
import { AsyncMoleculeDrawing } from '../../components/common/AsyncMoleculeDrawing';
import { TextInput } from '../../components/common/Inputs';
import { PageTemplate } from '../../components/Layout/Layout';
import useBehavior from '../../lib/hooks/useBehavior';
import { AsyncMoleculeDrawer } from '../../lib/util/draw-molecules';
import { PinColumn } from '../../components/DataTable/common';

export function TableTestPage() {
    return (
        <PageTemplate title='Table Prototype'>
            <div
                style={{ top: 20, left: '0.5rem', right: '0.5rem', bottom: '1rem' }}
                className='ms-2 position-absolute'
            >
                <TableWrapper />
            </div>
        </PageTemplate>
    );
}

type TestObj = {
    smiles: string;
    str: string;
    num: number;
    status: 'ok' | 'busy';
} & { [key: string]: any };

function createTestTable() {
    const drawer = new AsyncMoleculeDrawer();

    const objs: TestObj[] = [];

    const extraColCount = 4;
    const schemas: Record<string, Column> = {};
    for (let i = 1; i <= extraColCount; i++) {
        schemas[`col ${i}`] = Column.str();
    }

    for (let i = 0; i < 10000; i++) {
        const obj: TestObj = {
            smiles: new Array((i % 5) + 3).fill('C').join('C'),
            str: `str${i + 1}`,
            num: i,
            status: i % 2 ? 'ok' : 'busy',
        };
        for (let j = 1; j <= extraColCount; j++) {
            obj[`col ${j}`] = `v${j}:${i}`;
        }
        objs.push(obj);
    }

    let newColumnIndex = 0;

    const SmilesRowHeightFactor = 2;
    const rowHeight = DefaultRowHeight;

    const data = objectsToColumnTableData(objs, ['smiles', 'str', 'num', ...Object.keys(schemas), 'status']);
    const table = new DataTableModel<TestObj, 'delete-action' | 'finish-action' | 'selection' | 'add-column'>(
        columnDataTableStore(data),
        {
            columns: {
                smiles: Column.create({
                    kind: 'str',
                    label: 'SMILES',
                    header: (tbl) => (
                        <Form.Switch
                            checked={!!tbl.state.customState['show-smiles']}
                            className='ms-1 inline-switch'
                            onChange={(e) => {
                                e.stopPropagation();
                                tbl.setCustomState({ 'show-smiles': e.target.checked }, { silent: true });
                                tbl.setRowHeight(e.target.checked ? SmilesRowHeightFactor * rowHeight : rowHeight, {
                                    silent: true,
                                });
                                tbl.updated({ clearRenderCache: true });
                            }}
                            label='SMILES'
                            id='smiles-column'
                        />
                    ),
                    render: ({ value, table: tbl }) =>
                        tbl.state.customState['show-smiles'] ? (
                            <AsyncMoleculeDrawing smiles={value} height='100%' width='100%' drawer={drawer} />
                        ) : (
                            value
                        ),
                    compare: false,
                    noHeaderTooltip: true,
                    filterType: false,
                    width: 160,
                    position: 2,
                }),
                str: Column.create({
                    kind: 'str',
                    render: ({ value, rowIndex }) => (
                        <TextInput
                            style={{ padding: 0, background: 'transparent', maxWidth: 100 }}
                            value={value}
                            setValue={(val) => {
                                table.store.setValue('str', rowIndex, val);
                                table.dataChanged();
                            }}
                        />
                    ),
                    noHeaderTooltip: true,
                    width: 120,
                }),
                num: { ...Column.float(), align: 'right', width: 160 },
                status: Column.create({
                    kind: 'str',
                    compare: false,
                    render: ({ value }) =>
                        value === 'ok' ? (
                            <FontAwesomeIcon size='sm' icon={faCheck} />
                        ) : (
                            <Spinner size='sm' animation='border' />
                        ),
                    width: 80,
                    disableGlobalFilter: true,
                    noResize: true,
                    align: 'center',
                }),
                ...(schemas as any),
            },
            actions: [
                {
                    id: 'delete-action',
                    width: 40,
                    position: 1,
                    alwaysVisible: true,
                    noHeaderTooltip: true,
                    noResize: true,
                    cell: (rowIndex, tbl) => (
                        <Button
                            size='sm'
                            variant='outline'
                            onClick={(e) => {
                                e.stopPropagation();
                                tbl.setSelected(rowIndex, false);
                                tbl.store.deleteRow(rowIndex);
                                tbl.dataChanged();
                            }}
                        >
                            <FontAwesomeIcon size='sm' className='text-danger' icon={faRemove} />
                        </Button>
                    ),
                },
                {
                    id: 'selection',
                    width: 40,
                    position: 0,
                    alwaysVisible: true,
                    noHeaderTooltip: true,
                    noResize: true,
                    align: 'center',
                    headerAlign: 'center',
                    header: (tbl) => (
                        <Form.Switch
                            checked={table.allSelected}
                            onChange={(e) => {
                                e.stopPropagation();
                                tbl.toggleSelectAll();
                            }}
                            title='Toggle Selection'
                        />
                    ),
                    cell: (rowIndex, tbl) => (
                        <Form.Check
                            checked={!!tbl.selectedRows[rowIndex]}
                            onChange={(e) => {
                                e.stopPropagation();
                                tbl.setSelected(rowIndex, e.target.checked);
                            }}
                        />
                    ),
                },
                {
                    id: 'add-column',
                    width: 40,
                    position: -1,
                    alwaysVisible: true,
                    noHeaderTooltip: true,
                    noResize: true,
                    align: 'center',
                    headerAlign: 'center',
                    header: (tbl) => (
                        <Button
                            size='sm'
                            variant='outline'
                            onClick={() => {
                                const newValues = new Array(tbl.store.rowCount).fill('new column');
                                const index = ++newColumnIndex;
                                const columnName = `new col ${index}`;
                                tbl.addOrUpdateColumns([
                                    [
                                        columnName,
                                        newValues,
                                        {
                                            ...Column.str(),
                                            label: `Custom ${index}`,
                                            header: (
                                                <>
                                                    Custom {index}
                                                    <Button
                                                        size='sm'
                                                        variant='outline'
                                                        style={{ padding: 0, paddingLeft: 5, lineHeight: 'unset' }}
                                                        onClick={(ev) => {
                                                            ev.stopPropagation();
                                                            tbl.removeColumn(columnName);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon size='sm' icon={faRemove} />
                                                    </Button>
                                                </>
                                            ),
                                            width: 200,
                                        },
                                    ],
                                ]);
                            }}
                        >
                            <FontAwesomeIcon size='sm' className='text-primary' icon={faPlus} />
                        </Button>
                    ),
                    cell: () => '',
                },
                PinColumn({ position: 0 }),
            ],
            rowHeight: SmilesRowHeightFactor * rowHeight,
            customState: { 'show-smiles': true },
        }
    );

    table.addActionColumns([
        {
            id: 'finish-action',
            width: 40,
            position: -2,
            // alwaysVisible: true,
            noHeaderTooltip: true,
            cell: (rowIndex, tbl) => (
                <Button
                    size='sm'
                    variant='outline'
                    onClick={() => {
                        tbl.store.setValue('status', rowIndex, 'ok');
                        tbl.dataChanged();
                    }}
                    style={{ visibility: table.store.getValue('status', rowIndex) === 'ok' ? 'hidden' : undefined }}
                >
                    <FontAwesomeIcon className='text-success' icon={faCheck} />
                </Button>
            ),
        },
    ]);

    table.setColumnStickiness('selection', true);
    table.setColumnStickiness('<pin>', true);
    table.setColumnStickiness('delete-action', true);

    return table;
}

function TableWrapper() {
    const table = useMemo(() => createTestTable(), []);
    const snapshot = useRef<DataTableSnapshot | undefined>(undefined);
    useBehavior(table.version);

    return (
        <div className='d-flex flex-column h-100'>
            <DataTableGlobalFilter table={table} />

            <div className='hstack gap-2 my-2'>
                <ShowSelectedOnly table={table} />
                <Button
                    size='sm'
                    variant='outline-primary'
                    onClick={() => {
                        table.store.insertRow({ smiles: 'CCC', str: 'first', num: 1, status: 'busy' }, 0);
                        table.dataChanged();
                    }}
                >
                    Add row
                </Button>
                <Button
                    size='sm'
                    variant='outline-primary'
                    onClick={() => {
                        const str = table.toCsvString();
                        const blob = new Blob([str], { type: 'text/csv' });
                        saveAs(blob, `test-table-${Date.now()}.csv`);
                    }}
                >
                    Save CSV
                </Button>
                <div className='m-auto' />
                <Button
                    size='sm'
                    variant='outline-warning'
                    onClick={() => {
                        snapshot.current = table.getSnapshot();
                    }}
                >
                    Save Snapshot
                </Button>
                <Button
                    size='sm'
                    variant='outline-warning'
                    onClick={() => {
                        if (snapshot.current) {
                            table.setSnapshot(snapshot.current);
                        }
                    }}
                >
                    Restore Snapshot
                </Button>
            </div>

            <div className='flex-grow-1 position-relative'>
                <DataTableControl
                    height='flex'
                    table={table}
                    headerSize='lg'
                    showColumnFilters
                    // rowSelectionMode='single'
                />
            </div>
            <ColumnVisibility table={table} />
        </div>
    );
}

function ShowSelectedOnly({ table }: { table: DataTableModel }) {
    useBehavior(table.version);

    return (
        <Form.Switch
            id='selected-only'
            label='Show Selected Rows Only'
            checked={!!table.state.showSelectedRowsOnly}
            onChange={(e) => table.setSelectedRowsOnly(e.target.checked)}
        />
    );
}

function ColumnVisibility({ table }: { table: DataTableModel }) {
    const selectAllId = useId();
    useBehavior(table.version);

    return (
        <div className='mt-2 hte-details-table-column-select'>
            <span className='me-2'>Column Visibility:</span>
            <div className='d-inline-block me-2'>
                <Form.Check
                    id={selectAllId}
                    label='All'
                    type='checkbox'
                    checked={table.state.hiddenColumns.length === 0}
                    onChange={(e) => table.setAllColumnsVisibility(e.target.checked)}
                />{' '}
            </div>
            {table.allColumns
                .filter((column) => !column.alwaysVisible && column.header)
                .map((column) => (
                    <div key={column.id} className='d-inline-block me-2'>
                        <Form.Check
                            id={`toggle-selected-${column.id}-${selectAllId}`}
                            label={table.getColumnLabel(column.id)}
                            type='checkbox'
                            checked={!table.state.hiddenColumns.includes(column.id)}
                            onChange={(e) =>
                                table.setColumnVisibility(column.id, e.target.checked, { updateOrder: true })
                            }
                            className='d-inline-block'
                        />
                        <Button
                            size='sm'
                            variant='outline'
                            className={table.state.stickyColumns.includes(column.id) ? 'text-primary' : undefined}
                            onClick={() =>
                                table.setColumnStickiness(column.id, !table.state.stickyColumns.includes(column.id))
                            }
                        >
                            <FontAwesomeIcon icon={faThumbTack} size='sm' />
                        </Button>{' '}
                    </div>
                ))}
        </div>
    );
}
