export function nonEmpty(v: string) {
    return v.trim().length === 0 ? 'Field cannot be empty' : '';
}

export function trimValue(v: string) {
    return v.trim();
}

export function asNumber(v: string) {
    if (!v.trim()) return undefined;
    const n = +v;
    if (Number.isNaN(n)) return undefined;
    return n;
}

export function asNumberOrNull(v: string) {
    if (!v.trim()) return null;
    const n = +v;
    if (Number.isNaN(n)) return null;
    return n;
}

export function asInteger(v: string) {
    if (!v.trim()) return undefined;
    const n = +v;
    if (Number.isNaN(n)) return undefined;
    return Math.round(n);
}

export function asIntegerOrNull(v: string) {
    if (!v.trim()) return null;
    const n = +v;
    if (Number.isNaN(n)) return null;
    return Math.round(n);
}

export function asStringOrNull(v: string) {
    if (!v.trim()) return null;
    return v;
}

export function asIntMinMax(v: string, min: number, max: number) {
    if (!v.trim()) return undefined;
    let n = +v;
    if (Number.isNaN(n)) return undefined;
    if (n < min) n = min;
    if (n > max) n = max;
    return Math.round(n);
}
