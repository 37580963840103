import { IconName, IconDefinition } from '@fortawesome/fontawesome-svg-core';

// This is a way to make a new icon to be treated like icons in the
// Font Awesome set - structure kind of copied from their icon definitions.
// see node_modules/@fortawesome/node_modules/@fortawesome/fontawesome-common-types/index.d.ts
// for the IconDefinition type definition

const iconName = 'ii-intersection' as IconName;
const prefix = 'fas';
const width: number = 16;
const height: number = 16;
const aliases: string[] = [];
const unicode: string = '';
const svgPathData: string = [
    'M 2 1 C 1.4477206 1 1 1.4477206 1 2 L 1 10 C 1 10.552299 1.4477206 11 2 11 L 5 11 L 5 14 C 5 14.552299 5.4477206 15 6 15 L 14 15 C 14.552299 15 15 14.552299 15 14 L 15 6 C 15 5.4477206 14.552299 5 14 5 L 11 5 L 11 2 C 11 1.4477206 10.552299 1 10 1 L 2 1 z M 3 3 L 9 3 L 9 5 L 6 5 C 5.4477206 5 5 5.4477206 5 6 L 5 9 L 3 9 L 3 3 z M 11 7 L 13 7 L 13 13 L 7 13 L 7 11 L 10 11 C 10.552299 11 11 10.552299 11 10 L 11 7 z ',
].join('');

export const iiIntersection: IconDefinition = {
    prefix,
    iconName,
    icon: [width, height, aliases, unicode, svgPathData],
};
