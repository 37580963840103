/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useId } from 'react';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import useBehavior from '../../lib/hooks/useBehavior';
import { AsyncMoleculeDrawer, DrawResult, DrawSmilesInput } from '../../lib/util/draw-molecules';
import { CopySmilesButton, OpenInChemdrawButton } from './CopySmilesButton';
import { getCurrentTheme } from '../theme';

const DEFAULT_DRAWER = new AsyncMoleculeDrawer();

const color_mode = getCurrentTheme();

export function AsyncMoleculeDrawing({
    smiles,
    templateSmiles,
    drawer = DEFAULT_DRAWER,
    height,
    width,
    autosize,
    atomHighlights,
    showChemDraw = true,
    showCopy = true,
    asBackground,
    paddingTop,
    paddingBottom,
    onClick,
    onSMILESDrawn,
}: {
    smiles: string;
    templateSmiles?: string;
    drawer?: AsyncMoleculeDrawer;
    height?: number | string;
    width?: number | string;
    autosize?: boolean;
    atomHighlights?: number[][];
    showChemDraw?: boolean;
    showCopy?: boolean;

    asBackground?: boolean; // This fixes some image scaling issues with the img tag, onClick doesn't work if this is on
    paddingTop?: number | string;
    paddingBottom?: number | string;
    onClick?: () => any;
    onSMILESDrawn?: (sm: string) => any;
}) {
    const input: DrawSmilesInput = {
        smiles,
        autosize,
        atom_highlights: atomHighlights,
        color_mode,
        template_smiles: templateSmiles,
    };
    const svgResult = drawer.tryGetDrawing(input);
    useBehavior(svgResult ? undefined : drawer.version);
    useEffect(() => {
        if (drawer.request(input)) {
            return () => drawer.cancelRequest(input);
        }
    }, [smiles, templateSmiles, autosize, atomHighlights]);

    if (svgResult?.kind === 'result') {
        return (
            <div
                className={`d-inline-flex position-relative insight-smiles-drawing-buttons${
                    asBackground ? ' insight-smiles-drawing-background' : ''
                }`}
                style={{
                    width,
                    height,
                    paddingTop,
                    paddingBottom,
                    backgroundImage: asBackground ? `url(${svgResult.content})` : undefined,
                }}
            >
                {!asBackground && <img height='100%' width='100%' alt='' src={svgResult.content} onClick={onClick} />}
                {(showChemDraw || showCopy) && (
                    <div className='insight-smiles-drawing-button-container'>
                        {showChemDraw && <OpenInChemdrawButton smiles={smiles} onSMILESDrawn={onSMILESDrawn} />}
                        {showCopy && <CopySmilesButton smiles={smiles} />}
                    </div>
                )}
            </div>
        );
    }
    return (
        <div
            className='d-inline-flex'
            style={{ width, height, paddingTop, paddingBottom, alignItems: 'center', justifyContent: 'space-around' }}
        >
            {svgResult?.kind === 'error' && <DrawMoleculeError drawResult={svgResult} />}
            {!svgResult && <Spinner className='me-3 text-primary' size='sm' animation='border' role='status' />}
        </div>
    );
}

export function DrawMoleculeError({ drawResult }: { drawResult: DrawResult }) {
    const errorId = useId();

    if (drawResult.kind !== 'error') return null;

    return (
        <OverlayTrigger overlay={<Tooltip id={errorId}>{drawResult.content}</Tooltip>} placement='auto'>
            <button title='' type='button' className='insight-smiles-drawing-error'>
                <FontAwesomeIcon icon={faX} size='sm' fixedWidth color='var(--bs-danger)' className='me-1' />
                <span className='text-danger'>Error drawing structure</span>
            </button>
        </OverlayTrigger>
    );
}
