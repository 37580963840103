/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faArrowUpRightFromSquare, faCartPlus, faEraser, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, ReactNode, useMemo } from 'react';
import Select from 'react-select';
import Split from 'react-split-it';
import { Spinner } from 'react-bootstrap';
import { PageTemplate } from '../../../components/Layout/Layout';
import { AsyncMoleculeDrawing } from '../../../components/common/AsyncMoleculeDrawing';
import { IconButton } from '../../../components/common/IconButton';
import { LabeledInput, SimpleSelectOptionInput, TextInput } from '../../../components/common/Inputs';
import Loading, { AsyncLoading } from '../../../components/common/Loading';
import { ProtocolText } from '../../../components/common/ProtocolText';
import { ScrollBox } from '../../../components/common/ScrollBox';
import { CustomSmallSelectClassNames, SmallSelectStyles } from '../../../components/common/selectStyles';
import useAsyncModel from '../../../lib/hooks/useAsyncModel';
import useBehavior from '../../../lib/hooks/useBehavior';
import { useModelAction } from '../../../lib/util/reactive-model';
import { roundValue } from '../../../lib/util/roundValues';
import { asNumber, asNumberOrNull } from '../../../lib/util/validators';
import { RDBSimilarityOptions } from './data-model';
import { RDB_COUNT_LIMIT, RDB_PAGE_SIZE, ReagentDBModel, formatAmount } from './model';
import { ChemDrawButton } from '../../../components/ChemDraw';

async function createModel() {
    const model = new ReagentDBModel();
    await model.init();
    return model;
}

export function ReagentDBUI() {
    const [model, state] = useAsyncModel(createModel);

    return (
        <PageTemplate
            title='Compound Management'
            breadcrumb={[
                {
                    href: window.location.pathname,
                    titleString: 'ReagentDB',
                    title: (
                        <>
                            ReagentDB<sup>Prototype</sup>
                        </>
                    ),
                },
            ]}
        >
            <AsyncLoading state={state} />
            {model && <OrderingRoot model={model} />}
        </PageTemplate>
    );
}

function OrderingRoot({ model }: { model: ReagentDBModel }) {
    return (
        <Split direction='horizontal' gutterSize={6} sizes={[1 / 2, 1 / 2]}>
            <Search model={model} />
            <Order model={model} />
        </Split>
    );
}

function Search({ model }: { model: ReagentDBModel }) {
    return (
        <div className='d-flex flex-column w-100 h-100'>
            <div className='flex-grow-0'>
                <SearchInput model={model} />
            </div>
            <div className='flex-grow-1 position-relative'>
                <SearchResults model={model} />
            </div>
        </div>
    );
}

function Order({ model }: { model: ReagentDBModel }) {
    return (
        <div className='d-flex flex-column h-100'>
            <div className='flex-grow-1' style={{ maxHeight: '50%', minHeight: '50%' }}>
                <Current model={model} />
            </div>
            <div className='flex-grow-1' style={{ maxHeight: '50%', minHeight: '50%' }}>
                <Cart model={model} />
            </div>
        </div>
    );
}

function Current({ model }: { model: ReagentDBModel }) {
    const selected = useBehavior(model.state.selected);
    const highlighed = useBehavior(model.state.highlighted);

    const current = highlighed || selected;

    let smiles = current?.batch.compound_info.standardized_smiles;
    if (current?.batch.salt_smiles && smiles) {
        smiles += `.${current.batch.salt_smiles}`;
    }

    if (!current) return null;

    return (
        <div className='d-flex font-body-small h-100'>
            <div className='d-flex p-2 flex-column flex-grow-1 position-relative'>
                <div className='flex-grow-1 position-relative'>
                    {!!smiles && (
                        <AsyncMoleculeDrawing
                            drawer={model.drawer}
                            smiles={smiles}
                            asBackground
                            showChemDraw={false}
                            showCopy
                            width='100%'
                            height='100%'
                            autosize
                        />
                    )}
                </div>
            </div>
            <div className='flex-grow-1 position-relative flex-shrink-0' style={{ minWidth: 330, maxWidth: 330 }}>
                <ScrollBox className='p-2'>
                    {current?.suppliers.map((s) => (
                        <>
                            <h6 className='border-bottom'>{s.supplier_name}</h6>
                            <div>
                                <b>ID:</b> {s.source_supplier_id}
                            </div>
                            {!!s.url && (
                                <div>
                                    <b>URL:</b>{' '}
                                    <a href={s.url} target='_blank' rel='noreferrer'>
                                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} size='sm' />
                                    </a>
                                </div>
                            )}
                            <div>
                                <b>Availability:</b> {s.availability ?? 'unknown'}
                            </div>
                            <div>
                                <b>Purity:</b> {s.purity ?? '?'}
                            </div>
                            {!!s.cas && (
                                <div>
                                    <b>CAS:</b> {s.cas}
                                </div>
                            )}
                            {!!s.mdl && (
                                <div>
                                    <b>MDL:</b> {s.mdl}
                                </div>
                            )}
                            {!!s.sds_url && (
                                <div>
                                    <b>SDS:</b>{' '}
                                    <a href={s.sds_url} target='_blank' rel='noreferrer'>
                                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} size='sm' />
                                    </a>
                                </div>
                            )}
                            {s.inventory_items.map((item, i) => (
                                <div className='hstack gap-1' key={`${s.catalog_name}-${s.supplier_name}-${i}`}>
                                    <span>
                                        <IconButton
                                            icon={faCartPlus}
                                            onClick={() => model.addToCart(current, s, item)}
                                            className='p-0'
                                        />
                                    </span>
                                    <span className='ecm-rdb-overflow' style={{ minWidth: '40%', maxWidth: '40%' }}>
                                        {formatAmount(item)}
                                    </span>
                                    <span className='ecm-rdb-overflow' style={{ minWidth: '40%', maxWidth: '40%' }}>
                                        ${item.price ?? '-'}
                                    </span>
                                </div>
                            ))}
                        </>
                    ))}
                </ScrollBox>
            </div>
        </div>
    );
}

function Cart({ model }: { model: ReagentDBModel }) {
    useBehavior(model.state.shoppingCart);
    const csv = model.cartToCSV();

    return (
        <div className='d-flex position-relative w-100 h-100'>
            <ProtocolText value={csv} save={() => model.saveCart(csv)} className='flex-grow-1 m-2' />
            <IconButton
                icon={faEraser}
                variant='danger'
                onClick={() => model.state.shoppingCart.next([])}
                className='position-absolute bottom-0 end-0 m-3'
            >
                Clear
            </IconButton>
        </div>
    );
}

function SearchInput({ model }: { model: ReagentDBModel }) {
    const filter = useBehavior(model.state.filter);
    const [vendorOptions, optionsMap] = useMemo(() => {
        const options = model.vendors[filter.catalog].map((v) => ({ label: v, value: v }));
        return [options, new Map(options.map((o) => [o.value, o]))];
    }, [filter.catalog]);
    const vendorsValue = filter.suppliers.map((s) => optionsMap.get(s)).filter(Boolean);

    return (
        <div className='vstack gap-1 m-2 font-body-small'>
            <LabeledInput label='Catalog' labelWidth={120}>
                <SimpleSelectOptionInput
                    options={model.catalogOptions}
                    value={filter.catalog}
                    setValue={(v) => model.state.filter.next({ ...filter, catalog: v })}
                    size='sm'
                />
            </LabeledInput>
            <LabeledInput label={`Vendors (${vendorsValue.length}/${vendorOptions.length})`} labelWidth={120}>
                <div className='w-100'>
                    {/* TODO: improve styling */}
                    <Select
                        options={vendorOptions}
                        placeholder='Select vendors'
                        classNames={CustomSmallSelectClassNames}
                        styles={SmallSelectStyles}
                        menuPortalTarget={document.body}
                        menuPosition='fixed'
                        className='w-100'
                        isClearable
                        isMulti
                        closeMenuOnSelect={false}
                        value={vendorsValue}
                        onChange={(options) =>
                            model.state.filter.next({
                                ...filter,
                                suppliers: (options?.map((o) => o?.value).filter(Boolean) as any) ?? [],
                            })
                        }
                    />
                </div>
            </LabeledInput>
            <LabeledInput label='CAS' labelWidth={120}>
                <TextInput
                    value={filter.cas}
                    setValue={(v) => model.state.filter.next({ ...filter, cas: v.trim() })}
                    placeholder='CAS'
                    size='sm'
                    selectOnFocus
                />
            </LabeledInput>
            <LabeledInput label='Supplier ID' labelWidth={120}>
                <TextInput
                    value={filter.source_supplier_id}
                    setValue={(v) => model.state.filter.next({ ...filter, source_supplier_id: v.trim() })}
                    placeholder='Supplier ID'
                    size='sm'
                    selectOnFocus
                />
            </LabeledInput>
            <LabeledInput label='Max. FW' labelWidth={120}>
                <TextInput
                    value={filter.max_fw}
                    tryUpdateValue={asNumberOrNull}
                    setValue={(v) => model.state.filter.next({ ...filter, max_fw: v })}
                    placeholder='Maximum Formula Weight'
                    size='sm'
                    selectOnFocus
                />
            </LabeledInput>
            <LabeledInput label='Substructure' labelWidth={120}>
                <div className='w-100 position-relative'>
                    <TextInput
                        value={filter.substructure_smiles}
                        setValue={(v) => model.state.filter.next({ ...filter, substructure_smiles: v.trim() })}
                        placeholder='Substructure SMILES'
                        size='sm'
                        selectOnFocus
                    />
                    <ChemDrawButton
                        onDrawn={(smiles) => model.state.filter.next({ ...filter, substructure_smiles: smiles })}
                        className='position-absolute p-0 end-0 top-0 p-1'
                    />
                </div>
            </LabeledInput>
            <LabeledInput label='Similarity' labelWidth={120}>
                <div className='hstack gap-1 w-100'>
                    <div className='flex-grow-1 position-relative'>
                        <TextInput
                            value={filter.similarity_smiles}
                            setValue={(v) => model.state.filter.next({ ...filter, similarity_smiles: v.trim() })}
                            placeholder='Similarity SMILES'
                            size='sm'
                            className='flex-grow-1'
                            selectOnFocus
                        />
                        <ChemDrawButton
                            onDrawn={(smiles) => model.state.filter.next({ ...filter, similarity_smiles: smiles })}
                            className='position-absolute p-0 end-0 top-0 p-1'
                        />
                    </div>
                    <SimpleSelectOptionInput
                        options={RDBSimilarityOptions}
                        value={filter.similarity_algorithm}
                        setValue={(v) => model.state.filter.next({ ...filter, similarity_algorithm: v })}
                        style={{ width: 100 }}
                        size='sm'
                    />
                    <TextInput
                        value={filter.tanimoto_threshold}
                        tryUpdateValue={asNumber}
                        setValue={(v) => model.state.filter.next({ ...filter, tanimoto_threshold: v })}
                        placeholder='Threshold'
                        size='sm'
                        style={{ width: 60, maxWidth: 60 }}
                        selectOnFocus
                    />
                </div>
            </LabeledInput>
        </div>
    );
}

function ResultCount({ model }: { model: ReagentDBModel }) {
    const count = useModelAction(model.actions.count);
    if (count.kind === 'loading') return <Spinner animation='grow' size='sm' />;
    if (count.kind !== 'result') return null;
    return (
        <>
            ({count.result.count >= RDB_COUNT_LIMIT ? '≥' : ''}
            {count.result.count} total)
        </>
    );
}

function SearchResults({ model }: { model: ReagentDBModel }) {
    const search = useModelAction(model.actions.search);
    const results = useBehavior(model.state.results);
    const selected = useBehavior(model.state.selected);

    if (search.kind === 'loading' && search.message !== 'more') return <Loading />;
    if (search.kind === 'error') return <div>Error: {search.error}</div>;

    let offset = 0;
    const pages: ReactNode[] = [];
    for (const page of results) {
        if (page.length === 0) continue;

        pages.push(
            <Fragment key={offset}>
                <h6 className='mb-2'>
                    Results {offset + 1}—{offset + page.length} <ResultCount model={model} />
                </h6>
                <div className='d-flex flex-wrap mb-2'>
                    {page.map((res, i) => (
                        <div
                            key={i}
                            className={`ecm-rdb-result ${res === selected ? ' ecm-rdb-result-selected' : ''}`}
                            onMouseEnter={() => model.state.highlighted.next(res)}
                            onMouseLeave={() => model.state.highlighted.next(undefined)}
                            onClick={() => model.state.selected.next(res)}
                        >
                            <AsyncMoleculeDrawing
                                drawer={model.drawer}
                                smiles={
                                    res.batch.salt_smiles
                                        ? `${res.batch.compound_info.standardized_smiles}.${res.batch.salt_smiles}`
                                        : res.batch.compound_info.standardized_smiles
                                }
                                asBackground
                                showChemDraw={false}
                                showCopy
                                width='80%'
                                height='70%'
                                autosize
                            />
                            <div className='ecm-rdb-result-score font-body-xsmall'>
                                {roundValue(2, res.batch.formula_weight)} g/mol
                                <br />
                                <span title='Similarity Score'>
                                    {!!res.similarity_score && roundValue(2, res.similarity_score)}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
                <div style={{ clear: 'both' }} />
            </Fragment>
        );
        offset += page.length;
    }

    return (
        <ScrollBox className='px-2'>
            {pages}
            {results.length > 0 && results[results.length - 1].length === RDB_PAGE_SIZE && (
                <IconButton
                    size='sm'
                    onClick={model.loadMore}
                    className='w-100 mb-2'
                    disabled={search.kind === 'loading'}
                    icon={faPlus}
                    variant='outline-primary'
                >
                    {search.kind === 'loading' ? 'Loading...' : 'Load More'}
                </IconButton>
            )}
        </ScrollBox>
    );
}
