import { formatDatetime } from '../../../lib/util/dates';
import { roundValueDigits } from '../../../lib/util/roundValues';

type ElementCommon<T = any, F = {}> = {
    compare: (a: T, b: T) => number; // eslint-disable-line
    format: (v: T, options?: F) => string; // eslint-disable-line
    defaultFormatting?: F;
};

export type Element<T = any, F = any> =
    | ({
          kind: 'int' | 'float' | 'bool' | 'str' | 'datetime' | 'obj';
      } & ElementCommon<T, F>)
    | ({
          kind: 'enum';
          values: ReadonlyArray<T>;
      } & ElementCommon<T, F>);

export type Type<T extends object> = {
    [K in keyof T]: T[K] extends Element<infer E, infer _> ? E : any;
};

export type For<T> = {
    [K in keyof T]?: Element<T[K], any>;
};

export function isCategoricalElement(e: Element | undefined) {
    const k = e?.kind;
    if (!k) return false;
    return k === 'str' || k === 'enum' || k === 'bool';
}

export function isNumericElement(e: Element | undefined) {
    const k = e?.kind;
    if (!k) return false;
    return k === 'int' || k === 'float';
}

export function int(): Element<number> {
    return {
        kind: 'int',
        compare: compareWithBlanks((a: number, b: number) => a - b),
        format: (v) => (typeof v === 'number' ? `${v}` : formatGeneric(v)),
    };
}

export function optionalInt(): Element<number | undefined> {
    return int() as any;
}

export function bool(): Element<boolean> {
    return {
        kind: 'bool',
        compare: compareWithBlanks((a: boolean, b: boolean) => +a - +b),
        format: (v) => {
            if (typeof v === 'boolean') return `${v}`;
            if (typeof v === 'number' && !Number.isNaN(v)) return `${v === 1}`;
            return formatGeneric(v);
        },
    };
}

export interface FloatFormatOptions {
    significantDigits?: number;
    scientific?: boolean;
    decimalPlaces?: number;
    custom?: (v: number | undefined | null) => string;
    // TODO: padding support
}

export const DefaultFloatFormatOptions: FloatFormatOptions = {
    significantDigits: 4,
    scientific: false,
};

export function floatFormatOptionsEqual(a?: FloatFormatOptions, b?: FloatFormatOptions) {
    return (
        a?.scientific === b?.scientific &&
        a?.significantDigits === b?.significantDigits &&
        a?.decimalPlaces === b?.decimalPlaces &&
        a?.custom === b?.custom
    );
}

export function float(options?: { defaultFormatting?: FloatFormatOptions }): Element<number, FloatFormatOptions> {
    return {
        kind: 'float',
        compare: compareWithBlanks((a: number, b: number) => a - b),
        format: (v, o) => {
            if (o?.custom) return o.custom(v);
            if (typeof v !== 'number') return formatGeneric(v);
            if (Number.isNaN(v)) return '';
            let f = v;
            if (o?.significantDigits) {
                f = roundValueDigits(o.significantDigits, f);
            }
            if (o?.scientific) return f.toExponential();
            return o?.decimalPlaces !== undefined ? `${f.toFixed(o.decimalPlaces)}` : `${f}`;
        },
        defaultFormatting: { ...DefaultFloatFormatOptions, ...options?.defaultFormatting },
    };
}

export function optionalFloat(options?: {
    defaultFormatting?: FloatFormatOptions;
}): Element<number | undefined, FloatFormatOptions> {
    return float(options) as any;
}

export function str(options?: { caseSensitive?: boolean }): Element<string> {
    return {
        kind: 'str',
        compare: options?.caseSensitive ? compareWithBlanks(sortGeneric) : compareWithBlanks(sortStringsCaseSensitive),
        format: (v) => (typeof v === 'string' ? `${v}` : formatGeneric(v)),
    };
}

export function optionalStr(options?: { caseSensitive?: boolean }): Element<string | undefined> {
    return str(options) as any;
}

export function enumStr<T extends string>(values: ReadonlyArray<T>): Element<T> {
    return {
        kind: 'enum',
        compare: compareWithBlanks(sortGeneric),
        format: (v) => (typeof v === 'string' ? `${v}` : formatGeneric(v)),
        values,
    };
}

export function datetime(options?: { format?: 'date' | 'full' }): Element<Date | number | string> {
    return {
        kind: 'datetime',
        compare: compareWithBlanks(sortGeneric),
        format: (v) => formatDatetime(v, options?.format ?? 'date'),
    };
}

export interface ObjSchemaOptions<T, F> {
    compare: Element<T, F>['compare'];
    format: Element<T, F>['format'];
}

export function obj<T, F>(options: ObjSchemaOptions<T, F>): Element<T, F> {
    return {
        kind: 'obj',
        compare: options.compare,
        format: options.format,
    };
}

function formatGeneric(v: any) {
    if (v === null || v === undefined || Number.isNaN(v)) return '';
    return v;
}

function compareWithBlanks(sortFn: (a: any, b: any) => number) {
    return (a: any, b: any) => {
        const aIsBlank = a === undefined || a === null || Number.isNaN(a);
        const bIsBlank = b === undefined || b === null || Number.isNaN(b);
        if (aIsBlank && bIsBlank) return 0;
        if (aIsBlank) return -1;
        if (bIsBlank) return 1;
        return sortFn(a, b);
    };
}

function sortGeneric(a: any, b: any) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}

export const DefaultCompareWithBlanks = compareWithBlanks(sortGeneric);

function sortStringsCaseSensitive(a: string, b: string) {
    const x = typeof a === 'string' ? a.toLowerCase() : a;
    const y = typeof b === 'string' ? b.toLowerCase() : b;
    if (x < y) return -1;
    if (x > y) return 1;
    return 0;
}

const intArrays = [Int8Array, Uint8Array, Uint8Array, Int8Array, Uint16Array, Int16Array, Uint32Array, Int32Array];

export function determineSchema(data: any) {
    for (const arr of intArrays) {
        if (data instanceof arr) {
            return int();
        }
    }
    if (data instanceof Float64Array || data instanceof Float32Array) {
        return float();
    }

    let numInt = 0;
    let numFloat = 0;
    let numStr = 0;
    let numDate = 0;
    let numBool = 0;
    for (let i = 0; i < data.length; i++) {
        const v = data[i];
        if (v === undefined || v === null || Number.isNaN(v)) {
            continue;
        }
        const t = typeof v;
        if (t === 'number') {
            if (Number.isInteger(v)) numInt++;
            else numFloat++;
        } else if (t === 'string') {
            numStr++;
        } else if (t === 'boolean') {
            numBool++;
        } else if (v instanceof Date) {
            numDate++;
        }
    }

    if (numStr > 0) return str();
    if (numFloat > 0) return float();
    if (numInt > 0) return int();
    if (numBool > 0) return bool();
    if (numDate > 0) return datetime();
}
