import { faLock, faRotateLeft, faRotateRight, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useContext, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import { PageTemplate } from '../../components/Layout/Layout';
import { PillNav } from '../../components/common/Nav';
import { TooltipWrapper } from '../../components/common/Tooltips';
import useBehavior from '../../lib/hooks/useBehavior';
import { AuthService } from '../../lib/services/auth';
import { parseFileSystemDate } from '../../lib/util/dates';
import { formatHTEId } from './experiment-data';
import { HTEExperimentModel, HTEExperimentStep } from './experiment-model';
import { ECMStep } from './steps/ECM';
import { EnumerationStep } from './steps/Enumeration';
import { FinalizeStep } from './steps/Finalize';
import { ProcedureStep } from './steps/Procedure';
import { ProductPlateStep } from './steps/ProductPlate';
import { ReactantsStep } from './steps/Reactants';
import { ReactionsStep } from './steps/Reactions';
import { ReagentsStep } from './steps/Reagents';
import { SettingsStep } from './steps/Settings';

export function HTEExperimentUI({ model, id }: { model: HTEExperimentModel; id?: string }) {
    const { navigator } = useContext(UNSAFE_NavigationContext);
    const currentModel = useRef<HTEExperimentModel | undefined>(undefined);
    currentModel.current = model;

    useEffect(() => {
        const msg = 'There are unsaved changed. Do you want to leave this page?';

        const beforeUnload = (e: BeforeUnloadEvent) => {
            currentModel.current?.lockOnAway();

            if (!currentModel.current?.hasUnsavedChanges) {
                return undefined;
            }

            e.preventDefault();
            e.returnValue = msg;
            return e.returnValue;
        };
        window.addEventListener('beforeunload', beforeUnload, { capture: true });

        // hacked together based on https://github.com/remix-run/react-router/issues/8139#issuecomment-1023105785
        let blocked = true;
        const unblock = (navigator as any)?.block((tx: any) => {
            // eslint-disable-next-line
            if (!currentModel.current?.hasUnsavedChanges || window.confirm(msg)) {
                unblock();
                blocked = false;
                tx.retry();
            }
        });

        return () => {
            window.removeEventListener('beforeunload', beforeUnload, { capture: true });
            if (blocked) unblock();
        };
    }, [navigator]);

    useEffect(() => () => currentModel.current?.lockOnAway(), []);

    return (
        <PageTemplate
            title='HTE'
            breadcrumb={{ title: id ? formatHTEId(id as any) : '', href: '#' }}
            button={<SaveButton model={model} />}
        >
            <Steps model={model} />
        </PageTemplate>
    );
}

function UnlockButton({ model }: { model?: HTEExperimentModel }) {
    const info = useBehavior(model?.state.info);
    const createdBy = info?.created_by.toLowerCase();
    const username = useBehavior(AuthService.account)?.username.toLowerCase();

    return (
        <div className='text-secondary'>
            Experiment is read-only (changes disabled)
            {username === createdBy && (
                <Button title='Unlock experiment' variant='link' onClick={() => model?.finalize.unlock()}>
                    <FontAwesomeIcon icon={faLock} fixedWidth />
                </Button>
            )}
            {username !== createdBy && (
                <TooltipWrapper
                    tooltip={`This experiment was created by ${info?.created_by} and only they can unlock it.`}
                >
                    {(props) => (
                        <div {...props}>
                            <Button title='Unlock experiment' variant='link' disabled>
                                <FontAwesomeIcon icon={faLock} fixedWidth />
                            </Button>
                        </div>
                    )}
                </TooltipWrapper>
            )}
        </div>
    );
}

function LockButton({ model }: { model?: HTEExperimentModel }) {
    const info = useBehavior(model?.state.info);
    const createdBy = info?.created_by.toLowerCase();
    const username = useBehavior(AuthService.account)?.username.toLowerCase();

    return (
        <div className='text-secondary'>
            {username === createdBy && (
                <Button title='Lock experiment' variant='link' onClick={() => model?.finalize.lock()}>
                    <FontAwesomeIcon icon={faUnlock} fixedWidth />
                </Button>
            )}
            {username !== createdBy && (
                <TooltipWrapper
                    tooltip={`This experiment was created by ${info?.created_by} and only they can lock it.`}
                >
                    {(props) => (
                        <div {...props}>
                            <Button title='Lock experiment' variant='link' disabled>
                                <FontAwesomeIcon icon={faUnlock} fixedWidth />
                            </Button>
                        </div>
                    )}
                </TooltipWrapper>
            )}
        </div>
    );
}

function SaveButton({ model }: { model?: HTEExperimentModel }) {
    const info = useBehavior(model?.state.info);
    const isSaving = useBehavior(model?.state.isSaving);
    useBehavior(model?.state.lastSavedState);
    useBehavior(model?.state.changed);
    const isLocked = useBehavior(model?.state.isLocked);

    if (!info) return null;

    const isUnsaved = !!model?.hasUnsavedChanges;

    if (isLocked) {
        return <UnlockButton model={model} />;
    }

    return (
        <div className='hstack gap-2'>
            {!!info.modified_on && isUnsaved && !isSaving && (
                <span className='text-secondary'>
                    <small>Unsaved changes since {parseFileSystemDate(info.modified_on).toLocaleString()}</small>
                </span>
            )}
            {isSaving && (
                <span className='text-secondary'>
                    <small>Saving...</small>
                </span>
            )}
            {!isSaving && !isUnsaved && (
                <span className='text-secondary'>
                    <small>No unsaved changes</small>
                </span>
            )}
            <Button size='sm' onClick={() => model!.save()} disabled={isSaving || !isUnsaved}>
                Save
            </Button>
            {info.status === 'Editing' && <LockButton model={model} />}
        </div>
    );
}

function Steps({ model }: { model: HTEExperimentModel }) {
    const step = useBehavior(model.state.step);

    return (
        <>
            {step === 'settings' && <SettingsStep model={model} />}
            {step === 'enumeration' && <EnumerationStep model={model} />}
            {step === 'reactions' && <ReactionsStep model={model} />}
            {step === 'reagents' && <ReagentsStep model={model} />}
            {step === 'reactants' && <ReactantsStep model={model} />}
            {step === 'product_plate' && <ProductPlateStep model={model} />}
            {step === 'ecm' && <ECMStep model={model} />}
            {step === 'procedure' && <ProcedureStep model={model} />}
            {step === 'observations' && <ProcedureStep model={model} type='observations' />}
            {step === 'finalize' && <FinalizeStep model={model} />}

            <div className='hte-experiment-nav'>
                <History model={model} step={step} />
                <PillNav
                    steps={[
                        { name: 'settings', label: 'Settings' },
                        { name: 'enumeration', label: 'Enumeration' },
                        { name: 'reactions', label: 'Reactions' },
                        { name: 'reagents', label: 'Reagents' },
                        { name: 'reactants', label: 'Reactants' },
                        { name: 'product_plate', label: 'Product Plate' },
                        { name: 'ecm', label: 'ECM' },
                        { name: 'procedure', label: 'Procedure' },
                        { name: 'observations', label: 'Observations' },
                        { name: 'finalize', label: 'Finalize' },
                    ]}
                    currentStep={model.state.step.value}
                    onSetStep={(s) => model.state.step.next(s as HTEExperimentStep)}
                />
            </div>
        </>
    );
}

function History({ model, step }: { model: HTEExperimentModel; step: HTEExperimentStep }) {
    const history = useBehavior(model.state.history);
    return (
        <div className={classNames('me-2', 'hte-experiment-history', { invisible: step === 'settings' })}>
            <Button
                onClick={() => model.undo()}
                variant={history.cursor <= 0 ? 'outline-secondary' : 'outline-primary'}
                size='sm'
                disabled={history.cursor <= 0}
            >
                <FontAwesomeIcon size='sm' className='me-1' icon={faRotateLeft} />
                Undo
            </Button>
            <Button
                onClick={() => model.redo()}
                variant={history.cursor >= history.stack.length - 1 ? 'outline-secondary' : 'outline-primary'}
                size='sm'
                title='Redo'
                disabled={history.cursor >= history.stack.length - 1}
            >
                <FontAwesomeIcon size='sm' icon={faRotateRight} />
            </Button>
        </div>
    );
}
