import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BehaviorSubject, distinctUntilChanged, map, throttleTime } from 'rxjs';
import { Column, DataTableModel, DefaultRowHeight, ObjectDataTableStore } from '../../../components/DataTable';
import { SelectionColumn, SmilesColumn } from '../../../components/DataTable/common';
import { ToastService } from '../../../lib/services/toast';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { arrayEqual, memoizeLatest } from '../../../lib/util/misc';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { BatchLink } from '../../ECM/ecm-common';
import { HTE2MSApi } from '../api';
import {
    HTEDConditions,
    HTEDLabware,
    HTEDReaction,
    HTEDReactionIdT,
    HTEDesign,
    HTEPProductSample,
    HTERAddReactant,
    HTEReactionTemplate,
} from '../data-model';
import type { HTE2MSModel } from '../model';
import { Formatters, InlineInstructionBadge, ProductSampleUI, collectReactionIdentifiers } from '../utils';
import { enumerateReactions } from '../utils/enumeration';
import {
    ReactionValidation,
    invalidateReactionInstructions,
    validateLayout,
    validateReaction,
} from '../utils/validation';
import { WorkflowStatus } from '../utils/workflow-status';
import { HTE2MSDesignFiltersModel } from './filters';
import { HTE2MSDesignProductModel } from './product';
import { HTE2MSDesignReactionModel } from './reaction';
import { HTE2MSPlateLayoutModel } from './layout';
import { DialogService } from '../../../lib/services/dialog';
import useBehavior from '../../../lib/hooks/useBehavior';
import { LabeledInput, TextInput } from '../../../components/common/Inputs';
import { asNumber } from '../../../lib/util/validators';
import { InlineAlert } from '../../../components/common/Alert';
import { WellLayout } from '../../HTE/experiment-data';

export interface ReactionRow {
    original: HTEDReaction;
    product_identifier: HTEDReaction;
    reaction_chemistry: string | undefined;
    sample: HTEPProductSample | undefined;
    target_concentration: number | undefined;
    standard_concentration: number | undefined;
    group_name: string | undefined;
    scale: number | undefined;
    project: string | undefined;
    solvent: string;
    instructions: HTEReactionTemplate['instructions'];
    validation?: ['danger' | 'warning' | 'info', string][];
}

export interface AddEmptyReactionsOptions {
    from_layout: boolean;
    count: number;
    group_name?: string;
}

export class HTE2MSDesignModel extends ReactiveModel {
    private reactionStore: ObjectDataTableStore<ReactionRow, HTEDReaction> = new ObjectDataTableStore<
        ReactionRow,
        HTEDReaction
    >([
        { name: 'original', getter: (v) => v },
        { name: 'product_identifier', getter: (v) => v },
        { name: 'validation', getter: (v) => this.validations.get(v.id)?.[0] },
        { name: 'sample', getter: (v) => this.validations.get(v.id)?.[1] },
        { name: 'reaction_chemistry', getter: (v) => v.template.reaction_chemistry },
        { name: 'scale', getter: (v) => v.scale },
        { name: 'target_concentration', getter: (v) => v.template.target_concentration },
        { name: 'standard_concentration', getter: (v) => v.template.standard_concentration },
        { name: 'solvent', getter: (v) => v.template.solvent },
        { name: 'project', getter: (v) => v.project },
        { name: 'group_name', getter: (v) => v.group_name },
        { name: 'instructions', getter: (v) => v.template.instructions },
    ]);
    table: DataTableModel<ReactionRow>;
    validations: Map<HTEDReactionIdT, ReactionValidation> = new Map();
    product = new HTE2MSDesignProductModel(this);
    layout = new HTE2MSPlateLayoutModel(this);

    state = {
        reaction: new BehaviorSubject<HTE2MSDesignReactionModel>(new HTE2MSDesignReactionModel(this, [])),
        filters: new BehaviorSubject<HTE2MSDesignFiltersModel>(new HTE2MSDesignFiltersModel(this)),
        conditions: new BehaviorSubject<HTEDConditions>({} as HTEDConditions),
        labware: new BehaviorSubject<HTEDLabware>({} as HTEDLabware),
        summary: new BehaviorSubject<HTE2MSDesignSummary>({ total: 0, error: 0, warning: 0, info: 0 }),
        status: new BehaviorSubject<WorkflowStatus>('blank'),
    };

    private _getReactionMap = memoizeLatest((reactions: HTEDReaction[]) => new Map(reactions.map((r) => [r.id, r])));
    get reactionMap() {
        return this._getReactionMap(this.all);
    }

    get all() {
        return this.reactionStore.rawRows;
    }

    getById(id: HTEDReactionIdT) {
        return this.reactionMap.get(id);
    }

    private _getAddInstructionMap = memoizeLatest(
        (reactions: HTEDReaction[]) =>
            new Map(
                reactions.flatMap((r) =>
                    (r.template.instructions.filter((i) => i.kind === 'add') as HTERAddReactant[]).map((i) => [i.id, i])
                )
            )
    );
    get addInstructionMap() {
        return this._getAddInstructionMap(this.all);
    }

    get labware() {
        return this.state.labware.value;
    }

    private _labwareMap = memoizeLatest(
        (labware: HTEDLabware) => new Map([labware.product, ...labware.sources].map((l) => [l.id, l]))
    );
    get labwareMap() {
        return this._labwareMap(this.labware);
    }

    private _labwareOptions = memoizeLatest((labware: HTEDLabware) =>
        labware.sources.map((s) => [s.id, s.label] as [string, string])
    );
    get labwareOptions() {
        return this._labwareOptions(this.labware);
    }

    private _reservoirOptions = memoizeLatest((labware: HTEDLabware) =>
        labware.sources.filter((s) => s.is_reservoir).map((s) => [s.id, s.label] as [string, string])
    );
    get reservoirOptions() {
        return this._reservoirOptions(this.labware);
    }

    async useExample() {
        try {
            const design = await HTE2MSApi.exampleDesign();
            this.state.conditions.next(design.conditions);
            await this.modifyReactions(design.reactions.map((r) => [undefined, r]));
        } catch (err) {
            reportErrorAsToast('Use example', err);
        }
    }

    toModel(): HTEDesign {
        return {
            reactions: this.all,
            conditions: this.state.conditions.value,
            labware: this.state.labware.value,
        };
    }

    selectReactionIds(ids: HTEDReactionIdT[], options?: { onlySelected?: boolean }) {
        const indexMap = new Map(this.all.map((r, i) => [r.id, i]));
        const unique = Array.from(new Set(ids));
        const indices = unique.map((id) => indexMap.get(id)).filter((i) => i !== undefined) as number[];

        const selectedId = new Set(this.table.getSelectedRowIndices().map((i) => this.all[i].id));
        let equal = ids.length === selectedId.size;
        if (equal) {
            for (const id of ids) {
                if (!selectedId.has(id)) {
                    equal = false;
                    break;
                }
            }
        }

        if (!equal) {
            this.table.setSelection(indices);
        }
        if (options?.onlySelected) {
            this.table.setSelectedRowsOnly(true);
        }
    }

    removeSelectedReactions() {
        const selection = new Set(this.table.getSelectedRowIndices());
        this.table.setSelection([]);
        const next = this.all.filter((_, i) => !selection.has(i));
        this.syncValidation(next);
        this.reactionStore.setRows(next);
        this.table.dataChanged();

        ToastService.show({
            message: `${selection.size} reaction${selection.size === 1 ? '' : 's'} removed`,
            type: 'info',
            timeoutMs: 3500,
        });

        this.state.filters.next(new HTE2MSDesignFiltersModel(this));
        this.syncSummary();
        this.syncStatus();

        this.model.events.requestBuild.next('protocol');
    }

    selectAll = () => {
        const all = this.all.map((_, i) => i);
        this.table.setSelection(all);
    };

    invertSelection = () => {
        const selected = new Set(this.table.getSelectedRowIndices());
        const all = this.all.map((_, i) => i);
        this.table.setSelection(all.filter((i) => !selected.has(i)));
    };

    selectProductIdentifiers(xs: string[]) {
        const universalIds = new Set(
            xs.map((x) => this.model.assets.entities.getEntity(x.toUpperCase())?.universal_identifier || '')
        );
        universalIds.delete('');
        const indices = this.all
            .map((r, i) => (universalIds.has(r.product_identifier!) ? i : undefined))
            .filter((i) => i !== undefined) as number[];
        this.table.setSelection(indices);

        ToastService.info(`Selected ${indices.length} reaction${indices.length === 1 ? '' : 's'}`);
    }

    async modifyReactions(
        update: [prev: HTEDReaction | undefined, next: HTEDReaction][],
        options?: { cosmetic?: boolean; doNotRequestBuild?: boolean }
    ) {
        const changed = update.filter(([prev, next]) => prev !== next);
        if (changed.length === 0) return;

        this.state.status.next('pending');

        let validated = changed.map((r) => invalidateReactionInstructions(r[1]));
        for (let i = 0; i < validated.length; i++) {
            changed[i][1] = validated[i];
        }

        if (!options?.cosmetic) {
            validated = await HTE2MSApi.validateReactions(validated);
            for (let i = 0; i < validated.length; i++) {
                changed[i][1] = validated[i];
            }

            await this.syncAssets({ reactions: validated });

            const enumeration = await enumerateReactions(validated, this.model.assets);

            for (let i = 0; i < validated.length; i++) {
                const enumerated = enumeration.get(validated[i]);
                if (enumerated) {
                    changed[i][1] = enumerated;
                }
            }
        }

        const indices = new Map<HTEDReaction, number>(this.all.map((r, i) => [r, i]));
        const reactions = [...this.all];
        const addedIndices: number[] = [];
        for (const [prev, next] of changed) {
            if (indices.has(prev!)) {
                reactions[indices.get(prev!)!] = next;
            } else {
                addedIndices.push(reactions.length);
                reactions.push(next);
            }
        }

        this.syncValidation(reactions);

        this.reactionStore.setRows(reactions);
        this.table.dataChanged();

        this.state.filters.next(new HTE2MSDesignFiltersModel(this));
        this.syncSummary();

        if (addedIndices.length) {
            this.table.setSelection(addedIndices);
        }

        if (!options?.cosmetic && !update[0][0]) {
            ToastService.show({
                id: 'design-modify-reactions',
                message: `Added ${changed.length} reaction${changed.length === 1 ? '' : 's'}`,
                type: 'info',
                timeoutMs: 3500,
            });
        }

        this.syncStatus();
        if (!options?.doNotRequestBuild) {
            this.model.events.requestBuild.next('protocol');
        }
    }

    private syncSummary() {
        const summary: HTE2MSDesignSummary = { total: 0, error: 0, warning: 0, info: 0 };
        for (const v of this.all) {
            summary.total++;
            const validation = this.validations.get(v.id)?.[0];
            if (!validation) continue;
            let hasError = false;
            let hasWarning = false;
            let hasInfo = false;
            for (const [kind] of validation) {
                if (kind === 'danger') hasError = true;
                if (kind === 'warning') hasWarning = true;
                if (kind === 'info') hasInfo = true;
            }
            if (hasError) summary.error++;
            if (hasWarning) summary.warning++;
            if (hasInfo) summary.info++;
        }
        this.state.summary.next(summary);
    }

    async uploadReactions(data: string | File) {
        const file = data instanceof File ? data : new File([data], 'reactions.csv', { type: 'text/csv' });
        const reactions = await HTE2MSApi.parseReactions(file);
        await this.modifyReactions(reactions.map((r) => [undefined, r]));
    }

    addEmptyReactions(options?: { fromLayout?: boolean }) {
        DialogService.open({
            type: 'generic',
            title: `Add Empty Reactions`,
            confirmButtonContent: 'Apply',
            defaultState: {
                from_layout: !!options?.fromLayout,
                count: this.layout.emptySelectionSize || 1,
                group_name: '',
            } satisfies AddEmptyReactionsOptions,
            content: CreateEmptyReactionsDialogContent,
            wrapOk: true,
            onOk: (state) => this.applyAddEmptyReactions(state),
        });
    }

    private applyAddEmptyReactions(options: AddEmptyReactionsOptions) {
        const wells = options.from_layout ? this.layout.emptySelectedWellLabels : [];
        const reactions = new Array(options.count).fill({}).map(
            (_, i) =>
                ({
                    id: undefined as any,
                    group_name: options.group_name ?? undefined,
                    project: this.model.experiment?.project,
                    template: {
                        instructions: [],
                    },
                    well_label: wells[i],
                } satisfies HTEDReaction)
        );
        return this.modifyReactions(reactions.map((r) => [undefined, r]));
    }

    async syncAssets(options?: { reactions?: HTEDReaction[]; refresh?: boolean }) {
        try {
            const { identifiers, substanceIds } = collectReactionIdentifiers(options?.reactions ?? this.all);
            await Promise.all([
                this.model.assets.entities.syncIdentifiers(identifiers),
                this.model.assets.entities.syncSubstanceIds(substanceIds),
                this.model.assets.inventory.syncInventory(identifiers, options?.refresh),
            ]);
        } catch (err) {
            reportErrorAsToast('Sync Design Assets', err);
        }
    }

    async updateLabware(labware: HTEDLabware) {
        const validated = await HTE2MSApi.validateLabware(labware);
        this.state.labware.next(validated);
        this.syncValidation(this.all);
        this.table.dataChanged();
        this.state.filters.next(new HTE2MSDesignFiltersModel(this));
        this.model.events.requestBuild.next('protocol');
    }

    private syncValidation(reactions: HTEDReaction[]) {
        this.validations.clear();
        const layoutValidation = validateLayout(this.labware.product.layout as WellLayout, reactions);
        for (const r of reactions) {
            this.validations.set(r.id, validateReaction(this.model, r, layoutValidation));
        }
    }

    private syncStatus() {
        const reactions = this.all;
        let hasWarning = false;
        let hasInfo = false;
        for (const r of reactions) {
            const validation = this.validations.get(r.id);
            if (!validation) continue;

            for (const [kind] of validation[0]) {
                if (kind === 'danger') {
                    this.state.status.next('danger');
                    return;
                }
                if (kind === 'warning') {
                    hasWarning = true;
                } else if (kind === 'info') {
                    hasInfo = true;
                }
            }
        }

        if (hasWarning) {
            this.state.status.next('warning');
        } else if (hasInfo) {
            this.state.status.next('info');
        } else if (reactions.length > 0) {
            this.state.status.next('success');
        } else {
            this.state.status.next('blank');
        }
    }

    async init(design: HTEDesign) {
        await this.syncAssets({ reactions: design.reactions });
        this.state.conditions.next(design.conditions);
        this.state.labware.next(design.labware);
        this.modifyReactions(
            design.reactions.map((r) => [undefined, r]),
            { cosmetic: true }
        );
    }

    get reactionsChanged() {
        return this.table.version.pipe(
            map(() => this.all),
            distinctUntilChanged((a, b) => a === b)
        );
    }

    mount(): void {
        const instructions = this.table.version.pipe(
            map(() => [this.all, this.table.getSelectedRowIndices()] as [HTEDReaction[], number[]]),
            distinctUntilChanged(([a, b], [x, y]) => a === x && arrayEqual(b, y)),
            throttleTime(33, undefined, { leading: true, trailing: true })
        );

        this.subscribe(instructions, ([, rows]) => {
            const reactions = rows.map((i) => this.all[i]);
            this.state.reaction.next(new HTE2MSDesignReactionModel(this, reactions));
        });

        this.subscribe(this.model.inventory.state.inventory, () => this.table.dataChanged());
    }

    constructor(public model: HTE2MSModel) {
        super();

        this.table = new DataTableModel<ReactionRow>(this.reactionStore, {
            columns: {
                product_identifier: {
                    ...SmilesColumn(this.model.drawer, 2.5, {
                        width: 160,
                        getIdentifierElement: ({ rowIndex, table, showSMILES }) => {
                            const reaction = this.reactionStore.getValue('original', rowIndex);
                            const order = (
                                <b>
                                    [{rowIndex + 1}
                                    {reaction.well_label ? `:${reaction.well_label}` : ''}]{' '}
                                </b>
                            );
                            if (reaction.product_identifier) {
                                return (
                                    <span className={showSMILES ? 'font-body-xsmall' : undefined}>
                                        {order}
                                        <BatchLink
                                            identifier={this.model.assets.entities.getIdentifier(
                                                reaction.product_identifier
                                            )}
                                        />
                                    </span>
                                );
                            }
                            if (typeof reaction.product_enumeration?.substance_id === 'number') {
                                return (
                                    <span className={showSMILES ? 'font-body-xsmall' : undefined}>
                                        {order}
                                        <i className='text-info'>To be registered</i>
                                    </span>
                                );
                            }
                            return (
                                <span className={showSMILES ? 'font-body-xsmall' : undefined}>
                                    {order}Not enumerated
                                </span>
                            );
                        },
                        identifierPadding: 20,
                        getSMILES: (reaction) => this.model.assets.getStructure(reaction) ?? '',
                        autosize: true,
                        hideToggle: false,
                        disableChemDraw: true,
                        header: 'Product',
                    }),
                    disableGlobalFilter: true,
                } as any,
                validation: {
                    kind: 'generic',
                    format: (v) => '<unused>',
                    render: ({ value }) =>
                        value?.length ? (
                            <div className='hte2ms-table-scroll-cell' style={{ whiteSpace: 'pre' }}>
                                <div style={{ whiteSpace: 'break-spaces' }}>
                                    {value.map((v, i) => (
                                        <div key={i} className={`text-${v[0]}`}>
                                            {v[1]}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <span className='text-secondary'>-</span>
                        ),
                    width: 200,
                    compare: false,
                    disableGlobalFilter: true,
                },
                sample: {
                    kind: 'generic',
                    format: (v) => '<unused>',
                    render: ({ value, table }) => {
                        if (!value) return null;

                        if (table.state.customState['show-smiles']) {
                            return (
                                <div className='hte2ms-table-scroll-cell'>
                                    <ProductSampleUI
                                        sample={value}
                                        wellVolume={this.model.design.labware.product.volume}
                                    />
                                </div>
                            );
                        }

                        return (
                            <ProductSampleUI
                                sample={value}
                                wellVolume={this.model.design.labware.product.volume}
                                inline
                            />
                        );
                    },
                    width: 120,
                    compare: false,
                    disableGlobalFilter: true,
                },
                reaction_chemistry: {
                    ...Column.str(),
                    header: 'Chemistry',
                    render: ({ value }) => Formatters.chemistry(value) || WarnIcon,
                    width: 140,
                },
                target_concentration: {
                    ...Column.float(),
                    header: 'Tar. Conc.',
                    align: 'right',
                    render: ({ value }) => Formatters.concentration(value),
                    width: 100,
                },
                standard_concentration: {
                    ...Column.float(),
                    header: 'Std. Conc.',
                    align: 'right',
                    render: ({ value }) => Formatters.concentration(value),
                    width: 100,
                },
                scale: {
                    ...Column.float(),
                    header: 'Scale',
                    align: 'right',
                    render: ({ value }) => Formatters.rxnScale(value),
                    width: 100,
                },
                solvent: {
                    ...Column.str(),
                    header: 'Solvent',
                    render: ({ value }) => value || WarnIcon,
                    width: 100,
                },
                group_name: {
                    ...Column.str(),
                    header: 'Group',
                    render: ({ value }) => value,
                    width: 100,
                },
                project: {
                    ...Column.str(),
                    header: 'Project',
                    render: ({ value, rowIndex }) => {
                        const reaction = this.all[rowIndex];
                        const productCompound = model.assets.entities.getCompoundFromIdentifier(
                            reaction.product_identifier!
                        );
                        if (value && productCompound && productCompound?.project !== value) {
                            return (
                                <span className='text-info' title='Desired and registered projects differ'>
                                    {value} (reg. {productCompound?.project ?? 'not assigned'})
                                </span>
                            );
                        }
                        return (
                            value ?? (
                                <span className='text-warning' title='Not assigned'>
                                    {WarnIcon}
                                </span>
                            )
                        );
                    },
                    width: 100,
                },
                instructions: {
                    kind: 'obj',
                    header: 'Instructions',
                    filterType: false,
                    disableGlobalFilter: true,
                    compare: false,
                    width: 750,
                    format: (v) => '',
                    render: ({ value, table }) => (
                        <div
                            className={`hstack gap-2 w-100 h-100 align-content-center ${
                                table.state.customState['show-smiles'] ? ' flex-wrap' : ''
                            }`}
                        >
                            {value.map((instruction, i) => (
                                <InlineInstructionBadge model={model} key={i} instruction={instruction} smaller />
                            ))}
                        </div>
                    ),
                },
            },
            actions: [SelectionColumn()],
            hideNonSchemaColumns: true,
        });

        this.table.setCustomState({ 'show-smiles': true });
        this.table.setRowHeight(DefaultRowHeight * 2.5);
        this.table.setColumnStickiness('selection', true);
        this.table.setColumnStickiness('product_identifier', true);
    }
}

const WarnIcon = <FontAwesomeIcon icon={faExclamationTriangle} className='text-warning' />;

export interface HTE2MSDesignSummary {
    total: number;
    error: number;
    warning: number;
    info: number;
}

function CreateEmptyReactionsDialogContent({
    stateSubject,
}: {
    stateSubject: BehaviorSubject<AddEmptyReactionsOptions>;
}) {
    const state = useBehavior(stateSubject);
    return (
        <div className='vstack gap-2'>
            {state.from_layout && <InlineAlert>Add empty reactions to selected wells</InlineAlert>}
            <LabeledInput label='Count' labelWidth={120}>
                <TextInput
                    tryUpdateValue={asNumber}
                    autoFocus
                    value={state.count}
                    setValue={(count) => stateSubject.next({ ...state, count })}
                    disabled={state.from_layout}
                />
            </LabeledInput>
            <LabeledInput label='Group' labelWidth={120}>
                <TextInput
                    value={state.group_name}
                    setValue={(v) => stateSubject.next({ ...state, group_name: v?.trim() })}
                    autoFocus={state.from_layout}
                />
            </LabeledInput>
        </div>
    );
}
