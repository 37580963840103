import { ReactNode } from 'react';
import { PageTemplate } from '../../components/Layout/Layout';
import { LinkPillNav } from '../../components/common/Nav';
import { ScrollBox } from '../../components/common/ScrollBox';
import { capitalizeFirst, splitInput } from '../../lib/util/misc';

export const ANAPages = {
    metid: { title: 'Metabolite ID', path: 'met-id' },
    metstab: { title: 'Metabolite Stability', path: 'met-stab' },
    'lcuv-qc-data': { title: 'LCUV QC Data', path: 'lcuv-qc-data' },
};

export type ANAPage = keyof typeof ANAPages;

const WorkflowPages = (['metid', 'metstab', 'lcuv-qc-data'] satisfies ANAPage[]).map((p) => ({
    label: ANAPages[p].title,
    name: p,
    link: `/analytical/${ANAPages[p].path}`,
}));

export function ANANav({ current }: { current: ANAPage }) {
    return (
        <div className='p-2 hstack gap-2'>
            <LinkPillNav steps={WorkflowPages} currentStep={current} />
        </div>
    );
}

export function ANAPageTemplate({ page, children }: { page: ANAPage; children: ReactNode }) {
    const { title } = ANAPages[page];
    return (
        <PageTemplate title='Analytical' breadcrumb={{ href: window.location.pathname, title }}>
            <ANANav current={page} />
            <div className='ana-content'>{children}</div>
        </PageTemplate>
    );
}

export function ANAMessageList({
    messages,
    kind,
    className,
}: {
    messages: string[];
    kind: 'danger' | 'warning';
    className?: string;
}) {
    return (
        <ScrollBox className={`pb-2 ${className ?? ''}`}>
            {!messages.length && <div className='text-secondary'>None</div>}
            {messages.length > 0 && (
                <ul className={`text-${kind} font-body-small ps-4 flex-grow-1`}>
                    {messages.map((e, i) => (
                        <li key={i}>{e}</li>
                    ))}
                </ul>
            )}
        </ScrollBox>
    );
}

export function parseOrganisms(input: string) {
    const organisms = splitInput(input, ',').map((o) => capitalizeFirst(o.toLowerCase()));
    return organisms;
}
