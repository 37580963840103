import { ReactNode, useEffect, useRef } from 'react';

export function ScrollBox({
    children,
    className,
    maxHeight,
    scrollX,
}: {
    children: ReactNode;
    className?: string;
    maxHeight?: number;
    scrollX?: boolean;
}) {
    if (maxHeight) {
        return (
            <div className={className} style={{ overflow: scrollX ? 'auto' : 'hidden', overflowY: 'auto', maxHeight }}>
                {children}
            </div>
        );
    }
    return (
        <div
            className={`position-absolute ${className ?? ''}`}
            style={{ inset: 0, overflow: scrollX ? 'auto' : 'hidden', overflowY: 'auto' }}
        >
            {children}
        </div>
    );
}

export function AutoScrollBox({
    children,
    className,
    scrollToTopKey,
}: {
    children: ReactNode;
    className?: string;
    scrollToTopKey: any;
}) {
    // Some browsers remember scroll position of divs on refresh and
    // that is not always desirable
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (scrollToTopKey) ref.current?.scrollTo({ top: 0, behavior: 'auto' });
    }, [scrollToTopKey]);
    return (
        <div
            ref={ref}
            className={`position-absolute ${className ?? ''}`}
            style={{ inset: 0, overflow: 'hidden', overflowY: 'auto' }}
        >
            {children}
        </div>
    );
}
