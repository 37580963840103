import { faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { IconButton } from '../../../components/common/IconButton';
import { MultiFileUpload } from '../../../components/common/FileUpload';
import Loading from '../../../components/common/Loading';
import { ScrollBox } from '../../../components/common/ScrollBox';
import useBehavior from '../../../lib/hooks/useBehavior';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { useModelAction } from '../../../lib/util/reactive-model';
import { HTEEVialRack, HTEPMosquitoRun } from '../data-model';
import { HTEESolubilizeModel, SolubilizeData } from './solubilize';
import { ErrorMessage } from '../../../components/common/Error';
import { ProtocolText } from '../../../components/common/ProtocolText';

export function SolubilizeUI({ model }: { model: HTEESolubilizeModel }) {
    useMountedModel(model);

    return (
        <div className='d-flex w-100 h-100 align-items-stretch'>
            <div style={{ flexBasis: '50%' }} className='pe-2 d-flex flex-column'>
                <UploadRacks model={model} />
                <Alert variant='info' className='mb-2 p-2'>
                    <div className='hstack gap-2'>
                        <FontAwesomeIcon icon={faInfoCircle} className='mx-1' />
                        <span className='font-body-small'>
                            Racks are not saved and will only persist in this browser session
                        </span>
                    </div>
                </Alert>
                <div className='position-relative flex-grow-1'>
                    <RackList model={model} />
                </div>
                <Validation model={model} />
            </div>
            <div style={{ flexBasis: '50%' }} className='d-flex align-items-stretch'>
                <Protocols model={model} />
            </div>
        </div>
    );
}

function Validation({ model }: { model: HTEESolubilizeModel }) {
    const action = useModelAction(model.actions.data);
    const plate = useBehavior(model.execution.state.plate);
    const run = useBehavior(model.execution.state.run);

    if (action.kind !== 'result') return null;
    const data = action.result[plate?.id!]?.[run?.id!];
    if (!data) return null;

    const { genericErrors, missingItems, missingSamples, missingVials } = data;

    const numIssues = genericErrors.length + missingItems.length + missingSamples.length + missingVials.length;

    const section = (title: string, items: string[]) => {
        if (items.length === 0) return null;

        return (
            <>
                <h6 className='my-1 text-danger'>{title}:</h6>
                {items.map((item, i) => (
                    <div key={i}>{item}</div>
                ))}
            </>
        );
    };

    return (
        <>
            <h5 className='mt-2'>
                Validation ({numIssues} issue{numIssues === 1 ? '' : 's'})
            </h5>
            <div className='position-relative flex-grow-1'>
                <ScrollBox>
                    {section('Generic Errors', genericErrors)}
                    {section('Missing Vial Data', missingVials)}
                    {section('Missing Vial Samples', missingSamples)}
                    {section('Unmatched Barcodes', missingItems)}
                </ScrollBox>
            </div>
        </>
    );
}

function Protocols({ model }: { model: HTEESolubilizeModel }) {
    const action = useModelAction(model.actions.data);
    const plate = useBehavior(model.execution.state.plate);
    const run = useBehavior(model.execution.state.run);

    if (action.kind === 'loading')
        return (
            <div className='w-100'>
                <Loading />
            </div>
        );
    if (action.kind === 'error') return <ErrorMessage header='Error' message={action.error} />;
    if (action.kind !== 'result') return null;

    const data = action.result[plate?.id!]?.[run?.id!];
    if (!data || !run || !plate) return null;

    return (
        <div className='d-flex w-100 flex-column me-3'>
            <h5>CSV</h5>
            <CSVProtocol model={model} data={data} run={run} />
            <h5 className='mt-2'>OT2</h5>
            <SolubilizeProtocolText
                model={model}
                value={data.ot2}
                filename={`ot2-solubilize-${run.label}.py`}
                className='flex-grow-1'
            />
        </div>
    );
}

function CSVProtocol({ data, model, run }: { data: SolubilizeData; model: HTEESolubilizeModel; run: HTEPMosquitoRun }) {
    const [solvent, setSolvent] = useState(data.solvents[0]);

    return (
        <>
            <div className='hstack gap-2 mb-2'>
                {data.solvents.map((s) => (
                    <Button
                        key={s}
                        size='sm'
                        onClick={() => setSolvent(s)}
                        variant={s === solvent ? 'primary' : 'outline-primary'}
                    >
                        {s}
                    </Button>
                ))}
            </div>
            <SolubilizeProtocolText
                model={model}
                value={data.tecanCsv[solvent] ?? ''}
                filename={`solubilize-${solvent}-${run.label}.csv`}
                className='flex-grow-1'
            />
        </>
    );
}

function UploadRacks({ model }: { model: HTEESolubilizeModel }) {
    const state = useModelAction(model.actions.parseRacks);
    return (
        <div className='position-relative'>
            <div style={{ visibility: state.kind === 'loading' ? 'hidden' : undefined }}>
                <MultiFileUpload
                    extensions={['.csv']}
                    filesSubject={model.state.files}
                    label='Upload CSV file(s) with Rack Barcode, Barcode, and Well columns...'
                    numFiles={50}
                    hideFilename
                />
            </div>
            {state.kind === 'loading' && (
                <div className='position-absolute' style={{ inset: 0 }}>
                    <Loading message='Parsing racks...' />
                </div>
            )}
        </div>
    );
}

function RackList({ model }: { model: HTEESolubilizeModel }) {
    const racks = useBehavior(model.state.racks);

    return (
        <ScrollBox>
            {Object.values(racks).map((rack) => (
                <RackView model={model} rack={rack} key={rack.barcode} />
            ))}
        </ScrollBox>
    );
}

function RackView({ model, rack }: { model: HTEESolubilizeModel; rack: HTEEVialRack }) {
    const wellCount = Object.keys(rack.wells).length;

    return (
        <div className='border-bottom'>
            <div className='d-flex'>
                <div className='d-flex ps-2 flex-grow-1 align-items-center'>
                    <span>
                        <b>{rack.barcode}</b> ({wellCount} vial{wellCount === 1 ? '' : 's'})
                    </span>
                </div>
                <IconButton
                    icon={faTrash}
                    onClick={() => model.removeRack(rack.barcode)}
                    className='py-1 text-secondary'
                    title='Remove Rack'
                />
            </div>
        </div>
    );
}

function SolubilizeProtocolText({
    model,
    value,
    filename,
    className,
}: {
    model: HTEESolubilizeModel;
    value: string;
    filename: string;
    className?: string;
}) {
    return (
        <ProtocolText
            value={value}
            className={className}
            save={() => model.execution.saveProtocol({ value, filename })}
        />
    );
}
