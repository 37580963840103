/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/destructuring-assignment */
import {
    faArrowUpRightFromSquare,
    faDownLeftAndUpRightToCenter,
    faDownload,
    faThumbTack,
    faUpRightAndDownLeftFromCenter,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { Badge, Button, Dropdown, Form, Overlay, OverlayTrigger, Popover, Spinner, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import api from '../../api';
import { useAsyncAction } from '../../lib/hooks/useAsyncAction';
import { type ArtifactDB } from '../../lib/models/artifacts';
import { ToastService } from '../../lib/services/toast';
import { downloadFile } from '../../lib/util/downloadFile';
import { AsyncMoleculeDrawer } from '../../lib/util/draw-molecules';
import { reportErrorAsToast } from '../../lib/util/errors';
import { isBlank } from '../../lib/util/misc';
import { splitMolecularFormula } from '../../pages/Compounds/compound-api';
import { BatchLink } from '../../pages/ECM/ecm-common';
import { CompoundIdentifier } from '../../pages/HTE/steps/reagents-model';
import { AsyncMoleculeDrawing } from '../common/AsyncMoleculeDrawing';
import { IconButton } from '../common/IconButton';
import { Column, DefaultCompareWithBlanks, FloatColumnFormatOptions, formatFloat } from './column';
import { ColumnInstance, DataTableModel, DefaultRowHeight, saveDataTableAsCSV } from './model';

const rowHeight = DefaultRowHeight;

export function SmilesColumnHeader(
    table: DataTableModel<any, any>,
    columnName: string,
    smilesRowHeightFactor: number,
    {
        width = 160,
        hideToggle,
        header,
    }: {
        width?: number;
        hideToggle?: boolean;
        header?: string;
    } = {}
) {
    return (
        <div className='hstack flex-grow-1'>
            {!hideToggle && (
                <Form.Switch
                    checked={!!table.state.customState['show-smiles']}
                    className='ms-1 inline-switch d-inline-block'
                    onChange={(e) => {
                        e.stopPropagation();
                        table.setCustomState({ 'show-smiles': e.target.checked }, { silent: true });
                        table.setRowHeight(e.target.checked ? smilesRowHeightFactor * rowHeight : rowHeight, {
                            silent: true,
                        });
                        table.updated({ clearRenderCache: true });
                    }}
                    label={header ?? 'SMILES'}
                    id='smiles-column'
                />
            )}
            {hideToggle && <span className='ps-2'>{header ?? 'SMILES'}</span>}
            <div className='m-auto' />
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    if (table.state.customState.expandedSMILES) {
                        table.setRowHeight(smilesRowHeightFactor * rowHeight, { silent: true });
                        table.setCustomState({ expandedSMILES: false }, { silent: true });
                        table.updateColumnWidth(columnName, { width });
                    } else {
                        table.setRowHeight(2 * smilesRowHeightFactor * rowHeight, { silent: true });
                        table.setCustomState({ expandedSMILES: true }, { silent: true });
                        table.updateColumnWidth(columnName, { width: 1.61 * width });
                    }
                }}
                className='me-1'
                style={{
                    height: 20,
                    visibility: !table.state.customState['show-smiles'] ? 'hidden' : undefined,
                    cursor: 'pointer',
                }}
            >
                <FontAwesomeIcon
                    className='text-secondary'
                    size='sm'
                    icon={
                        !table.state.customState.expandedSMILES
                            ? faUpRightAndDownLeftFromCenter
                            : faDownLeftAndUpRightToCenter
                    }
                />
            </div>
        </div>
    );
}

export function SmilesColumn(
    drawer: AsyncMoleculeDrawer,
    smilesRowHeightFactor: number,
    {
        width = 160,
        position = 0,
        onSMILESDrawn,
        getSMILES,
        identifierPadding,
        getIdentifierElement,
        getExtraElement,
        hideToggle,
        header,
        disableChemDraw,
        autosize,
        drawingHeight,
        compare,
    }: {
        width?: number;
        position?: number | null;
        onSMILESDrawn?: (smiles: string) => any;
        getSMILES?: (v: any) => string;
        identifierPadding?: number | string;
        getIdentifierElement?: (options: {
            rowIndex: number;
            table: DataTableModel;
            showSMILES?: boolean;
        }) => ReactNode;
        getExtraElement?: (options: { rowIndex: number; table: DataTableModel; showSMILES?: boolean }) => ReactNode;
        hideToggle?: boolean;
        header?: string;
        disableChemDraw?: boolean;
        autosize?: boolean;
        drawingHeight?: string | number;
        compare?: boolean | ((a: any, b: any) => number);
    } = {}
): Column<string, any> {
    return Column.create({
        kind: 'str',
        label: 'SMILES',
        header: (tbl, { columnName }) =>
            SmilesColumnHeader(tbl, columnName, smilesRowHeightFactor, { width, hideToggle, header }),
        format: (value) => getSMILES?.(value) ?? value,
        render: ({ value, table: tbl, rowIndex }) => {
            const showSMILES = tbl.state.customState['show-smiles'];
            const props = { rowIndex, table: tbl, showSMILES };
            const identifier: ReactNode | undefined = getIdentifierElement?.(props);
            const smiles: string = getSMILES?.(value) ?? value;

            if (!showSMILES) {
                return identifier ?? smiles;
            }

            if (identifier) {
                return (
                    <>
                        <AsyncMoleculeDrawing
                            smiles={smiles}
                            height={drawingHeight ?? '100%'}
                            width='100%'
                            drawer={drawer}
                            paddingTop={autosize ? 4 : undefined}
                            paddingBottom={identifierPadding}
                            onSMILESDrawn={onSMILESDrawn}
                            showChemDraw={!disableChemDraw}
                            autosize={autosize}
                        />
                        <div className='table-smiles-column-identifier'>{identifier}</div>
                        {getExtraElement?.(props)}
                    </>
                );
            }

            return (
                <AsyncMoleculeDrawing
                    smiles={smiles}
                    height={drawingHeight ?? '100%'}
                    width='100%'
                    drawer={drawer}
                    showChemDraw={!disableChemDraw}
                    paddingTop={autosize ? 4 : undefined}
                    paddingBottom={autosize ? 4 : undefined}
                    autosize={autosize}
                />
            );
        },
        compare: compare === true ? undefined : compare ?? false,
        noHeaderTooltip: true,
        filterType: false,
        alwaysVisible: true,
        width,
        position: typeof position === 'number' ? position : undefined,
    });
}

export function StructureColumn(
    drawer: AsyncMoleculeDrawer,
    label: string,
    stateName: string,
    smilesRowHeightFactor: number,
    width = 160,
    toggleable = true
): Column<string, any> {
    return Column.create({
        kind: 'str',
        label,
        noHeaderTooltip: true,
        header: (tbl) =>
            toggleable ? (
                <Form.Switch
                    checked={!!tbl.state.customState[stateName]}
                    className='ms-1 inline-switch'
                    onChange={(e) => {
                        e.stopPropagation();
                        tbl.setCustomState({ [stateName]: e.target.checked }, { silent: true });
                        tbl.setRowHeight(
                            e.target.checked || tbl.state.customState['show-smiles']
                                ? smilesRowHeightFactor * rowHeight
                                : rowHeight,
                            {
                                silent: true,
                            }
                        );
                        tbl.updated({ clearRenderCache: true });
                    }}
                    label={label}
                    id={`${label}-column`}
                />
            ) : (
                label
            ),
        render: ({ value, table: tbl }) =>
            tbl.state.customState[stateName] && value ? (
                <AsyncMoleculeDrawing smiles={value} height='100%' width='100%' autosize drawer={drawer} />
            ) : (
                value
            ),
        compare: false,
        filterType: false,
        alwaysVisible: false,
        width,
    });
}

const blockTextSelection = () => false;

export function SelectionColumn(props?: { width?: number }): ColumnInstance {
    return {
        id: 'selection',
        width: props?.width ?? 40,
        position: 0,
        alwaysVisible: true,
        noHeaderTooltip: true,
        noResize: true,
        align: 'center',
        headerAlign: 'center',
        header: (tbl) => (
            <Form.Check
                checked={tbl.allSelected}
                onChange={(e) => {
                    e.stopPropagation();
                    tbl.toggleSelectAll();
                }}
                title='Toggle Selection'
            />
        ),
        cell: (rowIndex, tbl) => (
            <Form.Check
                checked={!!tbl.selectedRows[rowIndex]}
                onMouseDown={(e) => {
                    // prevent text selection
                    // while shift-clicking to select a range
                    if (e.nativeEvent.shiftKey) {
                        e.stopPropagation();
                        e.preventDefault();
                        document.addEventListener('selectstart', blockTextSelection);
                    }
                }}
                onMouseUp={(e) => {
                    document.getSelection()?.removeAllRanges();
                    document.removeEventListener('selectstart', blockTextSelection);
                }}
                onChange={(e) => {
                    e.stopPropagation();
                    if ((e.nativeEvent as unknown as MouseEvent).shiftKey) {
                        tbl.setSelectedRange(rowIndex);
                    } else {
                        tbl.setSelected(rowIndex, e.target.checked);
                    }
                }}
            />
        ),
    };
}

export function PinColumn(props?: { position?: number }): ColumnInstance {
    return {
        id: '<pin>',
        width: 20,
        position: props?.position ?? 0,
        alwaysVisible: true,
        noHeaderTooltip: true,
        noResize: true,
        align: 'center',
        headerAlign: 'center',
        header: () => <></>,
        cell: (rowIndex, tbl) => (
            <IconButton
                icon={faThumbTack}
                className={
                    tbl.pinnedRowSet.has(rowIndex) ? 'text-primary p-0' : 'text-secondary p-0 table-pin-button-inactive'
                }
                style={{ width: 20 }}
                onClick={(e) => {
                    e.stopPropagation();
                    tbl.setPinned(rowIndex, !tbl.pinnedRowSet.has(rowIndex), '<pin>');
                }}
            />
        ),
    };
}

export function SelectionInfo({ table }: { table: DataTableModel<any> }) {
    const selectionSize = Object.keys(table.selectedRows).length;
    return (
        <div className='d-flex align-items-center text-secondary ms-2'>
            {`${selectionSize} selected of ${table.preGlobalFilterRowCount} rows (${table.rows.length} visible)`}
        </div>
    );
}

export function DownloadArtifactColumn(): Column<Record<string, ArtifactDB>, {}> {
    return Column.create({
        ...Column.obj({
            format: (v) => '<unused>',
            compare: false,
        }),
        width: 160,
        render: ({ value }: { value: Record<string, ArtifactDB> }) => <DownloadArtifactsCell artifacts={value} />,
    });
}

export function BooleanBadge({ value, inner }: { value: any; inner?: any }) {
    if (isBlank(value)) return null;
    if (value) return <Badge bg='success'>{inner ?? 'True'}</Badge>;
    return <Badge bg='danger'>{inner ?? 'False'}</Badge>;
}

export function ListColumnSchema(): Column<string[] | undefined, {}> {
    const _empty: any[] = [];
    return {
        ...Column.obj({
            compare: (a, b) => DefaultCompareWithBlanks((a ?? _empty).join(', '), (b ?? _empty).join(', ')),
            format: (value?: string[]) => (value ?? _empty).join(', '),
        }),
        width: 200,
        filterType: 'includes',
        render: ({ value }: { value?: string[] }) => (
            <div className='entos-list-cell'>{(value ?? _empty).join(', ')}</div>
        ),
    };
}

export function NumberListColumnSchema(options?: {
    defaultFormatting?: FloatColumnFormatOptions;
}): Column<number[] | undefined, {}> {
    const _empty: any[] = [];
    return {
        ...Column.obj({
            compare: (a, b) => DefaultCompareWithBlanks((a ?? _empty).join(', '), (b ?? _empty).join(', ')),
            format: (value?: number[]) =>
                value ? value.map((v) => formatFloat(v, options?.defaultFormatting)).join(', ') : '',
        }),
        width: 200,
        filterType: 'includes',
        render: ({ value }: { value?: number[] }) => (
            <div className='entos-list-cell'>
                {value?.map((v) => formatFloat(v, options?.defaultFormatting)).join(', ')}
            </div>
        ),
    };
}

export function HoverLink({
    href,
    content,
    endContent = undefined,
    fill = false,
    hoverClass,
}: {
    href: string;
    content: ReactNode;
    endContent?: ReactNode;
    fill?: boolean;
    hoverClass?: string;
}) {
    if (!href) {
        return (
            <>
                {content}
                <div className={classNames('hover-link-wrapper', hoverClass)}>{endContent}</div>
            </>
        );
    }

    return (
        <div className={`hover-link ${fill ? 'fill-cell' : ''}`}>
            {content}
            <div className={classNames('hover-link-wrapper', hoverClass)}>
                <Link to={href} target='_blank' rel='noopener noreferrer' onClick={(e) => e.stopPropagation()}>
                    <FontAwesomeIcon size='sm' className='ms-1' icon={faArrowUpRightFromSquare} />
                </Link>
                {endContent}
            </div>
        </div>
    );
}

export function HoverBatchLink({
    identifier,
    withQuery = false,
    fill = false,
}: {
    identifier: string;
    withQuery?: boolean;
    fill?: boolean;
}) {
    if (!identifier) return <>-</>;

    const query = withQuery ? `?batch=${identifier}` : '';
    const href = `/compounds/${identifier.split('-')[0]}${query}`;

    return <HoverLink href={href} content={<CompoundIdentifier value={identifier} />} fill={fill} />;
}

export function TablePopoverWrapper({
    inactiveContent,
    activeContent,
}: {
    inactiveContent: ReactNode;
    activeContent: ReactNode;
}) {
    const [isActive, setIsActive] = useState(false);

    return (
        <div onMouseEnter={() => setIsActive(true)} onMouseLeave={() => setIsActive(false)}>
            {/* using a button for consistent styling, but if not active the button does nothing */}
            {!isActive && (
                <button type='button' className='entos-averaged-assay-value'>
                    {inactiveContent}
                </button>
            )}
            {isActive && <>{activeContent}</>}
        </div>
    );
}

export function TableCellPopover({
    id,
    buttonClassName,
    buttonContent,
    popoverHeader,
    popoverBody,
    className,
    inBody,
}: {
    id: string;
    buttonClassName: string;
    buttonContent: ReactNode;
    popoverHeader: ReactNode;
    popoverBody: ReactNode;
    className?: string;
    inBody?: boolean;
}) {
    const ref = useRef<HTMLDivElement>(null);
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState<EventTarget | null>(null);
    const [container, setContainer] = useState<Element | null>(null);

    function handleClick(e: MouseEvent) {
        setShow(true);
        setTarget(e.target);
    }

    useEffect(() => {
        if (ref.current) {
            const tableControl = ref.current.closest('.table-control-flex-wrapper');
            setContainer(tableControl);
        } else {
            setContainer(null);
        }
    }, [ref.current]);

    return (
        <div ref={ref as any} className={className}>
            <button className={buttonClassName} type='button' onClick={(e: MouseEvent) => handleClick(e)}>
                {buttonContent}
            </button>
            <Overlay
                show={show}
                target={target as any}
                placement='auto'
                container={inBody ? document.body : (container as any) ?? ref}
                containerPadding={20}
                rootClose
                flip
                onHide={() => setShow(false)}
            >
                <Popover id={id}>
                    {popoverHeader}
                    {popoverBody}
                </Popover>
            </Overlay>
        </div>
    );
}

export function formatMolecularFormula(formula: string): ReactNode {
    const split = splitMolecularFormula(formula);
    return <div>{split?.map((e, idx) => (idx % 2 === 0 ? e : <sub key={idx}>{e}</sub>))}</div>;
}

export function DownloadCSVButton({
    table,
    filename,
    isGOSTAR = false,
    ignoreSelection = false,
}: {
    table: DataTableModel<any>;
    filename: string;
    isGOSTAR?: boolean;
    ignoreSelection?: boolean;
}) {
    return (
        <>
            {isGOSTAR && (
                <OverlayTrigger
                    placement='auto'
                    overlay={<Tooltip>GOSTAR data cannot be downloaded</Tooltip>}
                    delay={{ show: 500, hide: 0 }}
                >
                    {(props) => (
                        <div {...props}>
                            <Button variant='outline-primary' disabled onClick={() => {}}>
                                Download as CSV
                            </Button>
                        </div>
                    )}
                </OverlayTrigger>
            )}
            {!isGOSTAR && (
                <Button
                    variant='outline-primary'
                    disabled={table.rows.length === 0}
                    onClick={() =>
                        saveDataTableAsCSV(filename, table, { ignoreSelection, includeIfHidden: ['identifier'] })
                    }
                >
                    Download as CSV
                </Button>
            )}
        </>
    );
}

export function DownloadArtifactsCell({
    artifacts,
    size = 'lg',
}: {
    artifacts?: Record<string, ArtifactDB>;
    size?: 'sm' | 'lg';
}) {
    if (!artifacts) return null;
    const keys = Object.keys(artifacts);
    if (keys.length === 0) return null;
    if (keys.length === 1) return <DownloadArtifactButton artifacts={artifacts} name={keys[0]} size={size} />;
    return <DownloadArtifactsDropdown artifacts={artifacts} size={size} />;
}

export function DownloadArtifactsDropdown({
    artifacts,
    size = 'lg',
    inline,
}: {
    artifacts: Record<string, any>;
    size?: 'sm' | 'lg';
    inline?: boolean;
}) {
    const [state, load] = useAsyncAction();
    const ref = useRef<HTMLDivElement>(null);
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState<EventTarget | null>(null);
    const [container, setContainer] = useState<Element | null>(null);

    function handleClick(e: MouseEvent) {
        setShow(true);
        setTarget(e.target);
    }

    useEffect(() => {
        if (ref.current) {
            const tableControl = ref.current.closest('.table-control-flex-wrapper');
            setContainer(tableControl);
        } else {
            setContainer(null);
        }
    }, [ref.current]);

    const title = (
        <>
            {!state.isLoading && <FontAwesomeIcon icon={faDownload} fixedWidth className='me-1' />}
            {state.isLoading && <Spinner animation='border' size='sm' className='text-primary me-1' role='status' />}
            {size === 'lg' && 'Download'}
        </>
    );

    return (
        <div ref={ref as any} className={inline ? 'd-inline-block' : undefined}>
            <Button variant='link' size='sm' className='dropdown-toggle' onClick={(e: MouseEvent) => handleClick(e)}>
                {title}
            </Button>
            <Overlay
                show={show}
                target={target as any}
                placement='bottom-end'
                container={(container as any) ?? ref}
                containerPadding={20}
                rootClose
                flip
                onHide={() => setShow(false)}
            >
                <ul className='dropdown-menu'>
                    {Object.keys(artifacts).map((k: string) => (
                        <Dropdown.Item
                            key={k}
                            title={artifacts[k]?.name}
                            onClick={() => load(downloadArtifact(artifacts, k))}
                        >
                            {k}
                        </Dropdown.Item>
                    ))}
                </ul>
            </Overlay>
        </div>
    );
}

async function downloadArtifact(artifacts: Record<string, any>, name?: string) {
    const key = name ?? 'default';
    if (!artifacts[key]) {
        ToastService.show({
            type: 'danger',
            message: `Could not find artifact with key ${key}`,
        });
        return;
    }
    try {
        const link = await api.utils.getArtifactDownloadLink(artifacts[key]);
        downloadFile({
            url: link,
            filename: artifacts[key].name,
        });
    } catch (err) {
        reportErrorAsToast('Error obtaining artifact link', err);
    }
}

export function DownloadArtifactButton({
    artifacts,
    name,
    size = 'lg',
}: {
    artifacts: Record<string, any>;
    name?: string;
    size?: 'sm' | 'lg';
}) {
    const [state, load] = useAsyncAction();
    const key = name ?? 'default';

    return (
        <Button
            size='sm'
            variant='link'
            className={size === 'lg' ? 'me-1' : 'hover-button me-1'}
            title={artifacts[key]?.name}
            disabled={!artifacts[key] || state.isLoading}
            onClick={() => load(downloadArtifact(artifacts, key))}
        >
            {!state.isLoading && <FontAwesomeIcon icon={faDownload} fixedWidth className='me-1' />}
            {state.isLoading && <Spinner animation='border' size='sm' className='text-primary me-1' role='status' />}
            {size === 'lg' && 'Download'}
        </Button>
    );
}

export function BatchOrCompoundLink({ identifier, batches }: { identifier?: string; batches?: string[] }) {
    if (!identifier) return null;
    const compoundBatchBadge = batches?.length ? (
        <Badge bg='secondary' className='align-self-center mx-1'>
            {batches.length}
        </Badge>
    ) : undefined;

    return <BatchLink identifier={identifier} withQuery extra={compoundBatchBadge} />;
}
