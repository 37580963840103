import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/common/Loading';
import { PageTemplate } from '../components/Layout/Layout';
import useBehavior from '../lib/hooks/useBehavior';
import { EcosystemService } from '../lib/services/ecosystem';
import {
    Column,
    ColumnsFor,
    DataTableControl,
    DataTableGlobalFilter,
    DataTableModel,
    DataTableStore,
    tableRowNavigation,
} from '../components/DataTable';
import { Dataset } from '../api/data';
import { formatDateAsYYYY_MM_DD } from '../lib/util/dates';

const DatasetTableSchema: ColumnsFor<Dataset> = {
    id: Column.create({
        kind: 'int',
        noHeaderTooltip: true,
        width: 80,
    }),
    name: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        width: 300,
    }),
    kind: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
    }),
    description: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        width: 500,
    }),
    created_on: Column.create({
        kind: 'datetime',
        noHeaderTooltip: true,
        format: () => '<unused>',
        render: ({ value }) => formatDateAsYYYY_MM_DD(value),
    }),
    modified_on: Column.create({
        kind: 'datetime',
        noHeaderTooltip: true,
        format: () => '<unused>',
        render: ({ value }) => formatDateAsYYYY_MM_DD(value),
    }),
};

function createTable(boxes: DataTableStore<Dataset>) {
    const table = new DataTableModel(boxes, {
        columns: DatasetTableSchema,
        hideNonSchemaColumns: true,
    });

    return table;
}

export default function Datasets() {
    const datasets = useBehavior(EcosystemService.datasets);
    const table = useMemo(() => (datasets ? createTable(datasets) : undefined), [datasets]);

    useEffect(() => {
        EcosystemService.syncDatasets();
    }, []);

    return (
        <PageTemplate title='Datasets'>
            <div className='position-relative h-100 d-flex flex-column overflow-hidden'>
                {!table && <Loading />}
                {table && <TableWrapper table={table} />}
            </div>
        </PageTemplate>
    );
}

function TableWrapper({ table }: { table: DataTableModel<Dataset> }) {
    const navigate = useNavigate();
    useBehavior(table.version);

    const onRowDoubleClick = (rowIndex: number, tbl: DataTableModel<Dataset>, e: React.MouseEvent) => {
        if (!tbl) return;
        tableRowNavigation(e, `/datasets/${tbl.store.getValue('id', rowIndex)}`, navigate);
    };

    return (
        <div className='d-flex flex-column h-100'>
            <div className='flex-0 mx-3 mt-3'>
                <h4>{table.rows.length} Datasets</h4>
            </div>
            <div className='mx-3 mb-3 w-50'>
                <DataTableGlobalFilter table={table} />
            </div>
            <div className='position-relative flex-grow-1'>
                <DataTableControl
                    height='flex'
                    table={table}
                    headerSize='sm'
                    onRowDoubleClick={onRowDoubleClick}
                    rowSelectionMode='single'
                />
            </div>
        </div>
    );
}
