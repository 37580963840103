import { roundValue } from '../../../lib/util/roundValues';
import { isTubeBarcode } from '../../ECM/ecm-api';
import {
    HTEExecution,
    HTEPInventoryItem,
    HTEPInventoryItemIdT,
    HTEPMosquitoProtocol,
    HTEPMosquitoRun,
    HTEPRobotRunIdT,
    HTEPSourcePlate,
    HTEProductProtocolIdT,
    HTEProtocolIdT,
} from '../data-model';
import { buildOT2Protocol } from './ot2-source-plates';

export interface ExecutionRunData {
    inventory: RunInventoryMap;
    sourcePlatesCsvPicklist: string;
    sourcePlatesOT2Picklist: string;
    versoPicklist: string;
    mosquitoWorklist: string;
}

export type ExecutionInfo = Record<HTEProtocolIdT, Record<HTEPRobotRunIdT, ExecutionRunData>>;

export function getExecutionInfo(
    hteId: string,
    protocol: HTEPMosquitoProtocol,
    execution: HTEExecution
): ExecutionInfo {
    const data: ExecutionInfo = {};
    for (const p of protocol.product_plates) {
        data[p.id] = {};
        for (const r of p.runs) {
            const inventory = buildRunInventoryMap(r, p.id, execution);
            const sourcePlatesPicklist = buildSourcePicklist(hteId, r, p.id, execution, inventory);
            const versoPicklist = buildVersoPicklist(r, p.id, execution, inventory);
            data[p.id][r.id] = {
                inventory,
                sourcePlatesCsvPicklist: sourcePlatesPicklist.csv,
                sourcePlatesOT2Picklist: sourcePlatesPicklist.ot2,
                versoPicklist,
                mosquitoWorklist: r.worklist,
            };
        }
    }
    return data;
}

export interface RunInventoryMap {
    vialBarcodeToRackWell: Map<string, [rack_barcode: string, well: string]>;
    items: Map<HTEPInventoryItemIdT, HTEPInventoryItem>;
    vialBarcodeToItem: Map<string, HTEPInventoryItem>;
    itemIdToBarcode: Map<HTEPInventoryItemIdT, string>;
    rackStats: Map<string, { present: number; extra: number }>;
    unassignedBarcode: HTEPInventoryItem[];
    missingInRack: HTEPInventoryItem[];
    unassignedPlateBarcodes: HTEPSourcePlate[];
    excludedSet: Set<HTEPInventoryItemIdT>;
}

function buildRunInventoryMap(
    run: HTEPMosquitoRun,
    product_id: HTEProductProtocolIdT,
    execution: HTEExecution
): RunInventoryMap {
    const { excluded_inventory_ids, robot_runs } = execution.products[product_id];
    const { racks, barcodes } = robot_runs[run.id];

    const vialBarcodeToRackWell = new Map<string, [rack_barcode: string, well: string]>();
    for (const rack of Array.from(Object.values(racks))) {
        for (const [well, barcode] of Array.from(Object.entries(rack.wells))) {
            vialBarcodeToRackWell.set(barcode, [rack.barcode, well]);
        }
    }

    const items = new Map(run.inventory.map((e) => [e.id, e]));

    const vialBarcodeToItem = new Map<string, HTEPInventoryItem>();
    const itemIdToBarcode = new Map<string, string>();

    for (const [id, barcode] of Object.entries(barcodes.source_vials)) {
        if (!barcode) continue;
        vialBarcodeToItem.set(barcode, items.get(id)!);
        itemIdToBarcode.set(id, barcode);
    }

    for (const [id, barcode] of Object.entries(barcodes.transfer_vials)) {
        if (!barcode) continue;
        vialBarcodeToItem.set(barcode, items.get(id)!);
        itemIdToBarcode.set(id, barcode);
    }

    const unassignedBarcode: HTEPInventoryItem[] = [];
    const missingInRack: HTEPInventoryItem[] = [];

    const excludedSet = new Set(excluded_inventory_ids);

    for (const e of run.inventory) {
        if (!e.identifier || excludedSet.has(e.id)) continue;

        const barcode = itemIdToBarcode.get(e.id);
        if (!barcode) {
            unassignedBarcode.push(e);
        } else if (!vialBarcodeToRackWell.has(barcode)) {
            missingInRack.push(e);
        }
    }

    const rackStats = new Map<string, { present: number; extra: number }>();
    for (const rack of Array.from(Object.values(racks))) {
        let present = 0;
        let extra = 0;

        for (const barcode of Array.from(Object.values(rack.wells))) {
            if (vialBarcodeToItem.has(barcode)) present++;
            else extra++;
        }

        rackStats.set(rack.barcode, { present, extra });
    }

    const unassignedPlateBarcodes: HTEPSourcePlate[] = [];
    for (const p of run.source_plates) {
        if (!barcodes.source_plates[p.id]) {
            unassignedPlateBarcodes.push(p);
        }
    }

    return {
        vialBarcodeToRackWell,
        items,
        vialBarcodeToItem,
        unassignedBarcode,
        missingInRack,
        rackStats,
        itemIdToBarcode,
        excludedSet,
        unassignedPlateBarcodes,
    };
}

export interface PicklistRow {
    vial_barcode?: string;

    source_barcode?: string;
    source_well?: string;
    source_coords: [number, number] | null;
    source_label: string;

    target_barcode?: string;
    target_well: string;
    target_coords: [number, number];

    volume_ul: number;
}

function buildSourcePicklist(
    hteId: string,
    run: HTEPMosquitoRun,
    product_id: HTEProductProtocolIdT,
    execution: HTEExecution,
    inventoryMap: RunInventoryMap
): { csv: string; ot2: string } {
    const { robot_runs } = execution.products[product_id];
    const { barcodes } = robot_runs[run.id];
    const { source_plates } = barcodes;
    const { inventory } = run;
    const rows: PicklistRow[] = [];

    for (const e of inventory) {
        if (!e.identifier || inventoryMap.excludedSet.has(e.id)) {
            continue;
        }

        const vial_barcode = inventoryMap.itemIdToBarcode.get(e.id);
        const src = inventoryMap.vialBarcodeToRackWell.get(vial_barcode!);

        for (const t of e.targets) {
            const target_barcode = source_plates[t.source_plate_id];
            const srcPlate = run.source_plates.find((p) => p.id === t.source_plate_id);

            rows.push({
                vial_barcode,

                source_barcode: src?.[0],
                source_well: src?.[1],
                source_coords: src ? parseCoord(src[1]) : null,
                source_label: `Source Plate #${(srcPlate?.tag! as any) + 1}: ${srcPlate?.label! ?? '-'}`,

                target_barcode: target_barcode || undefined,
                target_well: t.well_label,
                target_coords: [t.row, t.col],

                volume_ul: roundValue(2, t.volume * 1e9),
            });
        }
    }

    rows.sort((a, b) => {
        if (a.source_barcode && !b.source_barcode) return -1;
        if (!a.source_barcode && b.source_barcode) return 1;

        if (a.source_barcode && b.source_barcode && a.source_barcode !== b.source_barcode) {
            return a.source_barcode < b.source_barcode ? -1 : 1;
        }

        if (a.source_coords && b.source_coords) {
            const cs = tryCompareWellCoords(a.source_coords, b.source_coords);
            if (cs !== 0) return cs;
        } else if (a.source_well && b.source_well && a.source_well !== b.source_well) {
            return a.source_well < b.source_well ? -1 : 1;
        }

        if (a.target_barcode && b.target_barcode && a.target_barcode !== b.target_barcode) {
            return a.target_barcode < b.target_barcode ? -1 : 1;
        }

        const ct = tryCompareWellCoords(a.target_coords, b.target_coords);
        if (ct !== 0) return ct;

        return a.volume_ul - b.volume_ul;
    });

    const definedRows = rows.filter((r) => r.source_barcode && r.source_well && r.target_barcode);
    const ot2 = buildOT2Protocol(hteId, definedRows);

    const csvLines = ['Source Barcode,Source Well,Target Barcode,Target Well,Volume (ul)'];
    for (const row of rows) {
        csvLines.push(
            `${row.source_barcode ?? '<rack barcode>'},${row.source_well ?? '<rack well>'},${
                row.target_barcode ?? '<source plate barcode>'
            },${row.target_well},${row.volume_ul}`
        );
    }
    return {
        csv: csvLines.join('\n'),
        ot2,
    };
}

function tryCompareWellCoords(a: [number, number], b: [number, number]) {
    if (a[1] !== b[1]) return a[1] - b[1];
    return a[0] - b[0];
}

const CoordRegex = /^([A-Z]+)(\d+)$/;
const RowRange = 'Z'.charCodeAt(0) - 'A'.charCodeAt(0) + 1;

function parseCoord(well: string) {
    const match = CoordRegex.exec(well.toUpperCase());

    if (!match) return null;

    const rowLabel = match[1];
    const colLabel = match[2];

    let row = 0;
    for (let i = 0; i < rowLabel.length; i++) {
        row += (rowLabel.charCodeAt(i) - 'A'.charCodeAt(0) + 1) * RowRange ** (rowLabel.length - i - 1);
    }

    return [row - 1, +colLabel - 1] as [number, number];
}

function buildVersoPicklist(
    run: HTEPMosquitoRun,
    product_id: HTEProductProtocolIdT,
    execution: HTEExecution,
    inventoryMap: RunInventoryMap
) {
    const { inventory } = run;
    const { source_vials } = execution.products[product_id].robot_runs[run.id].barcodes;

    const barcodes = new Set<string>();
    for (const e of inventory) {
        if (inventoryMap.excludedSet.has(e.id)) continue;

        const srcBarcode = source_vials[e.id];
        if (isTubeBarcode(srcBarcode)) barcodes.add(srcBarcode!);
    }

    const lines = Array.from(barcodes).sort((a, b) => (a < b ? -1 : 1));
    return lines.join('\n');
}
