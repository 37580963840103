export const K_384_42c_3pt_dup_2ctrl_7pt = `ID,Point,Row,Column,IsControl
1,1,2,2,False
1,1,2,3,False
1,2,2,4,False
1,2,2,5,False
1,3,2,6,False
1,3,2,7,False
15,1,2,8,False
15,1,2,9,False
15,2,2,10,False
15,2,2,11,False
15,3,2,12,False
15,3,2,13,False
Control1,1,2,14,True
Control1,1,2,15,True
29,1,2,17,False
29,1,2,18,False
29,2,2,19,False
29,2,2,20,False
29,3,2,21,False
29,3,2,22,False
2,1,3,2,False
2,1,3,3,False
2,2,3,4,False
2,2,3,5,False
2,3,3,6,False
2,3,3,7,False
16,1,3,8,False
16,1,3,9,False
16,2,3,10,False
16,2,3,11,False
16,3,3,12,False
16,3,3,13,False
Control1,2,3,14,True
Control1,2,3,15,True
30,1,3,17,False
30,1,3,18,False
30,2,3,19,False
30,2,3,20,False
30,3,3,21,False
30,3,3,22,False
3,1,4,2,False
3,1,4,3,False
3,2,4,4,False
3,2,4,5,False
3,3,4,6,False
3,3,4,7,False
17,1,4,8,False
17,1,4,9,False
17,2,4,10,False
17,2,4,11,False
17,3,4,12,False
17,3,4,13,False
Control1,3,4,14,True
Control1,3,4,15,True
31,1,4,17,False
31,1,4,18,False
31,2,4,19,False
31,2,4,20,False
31,3,4,21,False
31,3,4,22,False
4,1,5,2,False
4,1,5,3,False
4,2,5,4,False
4,2,5,5,False
4,3,5,6,False
4,3,5,7,False
18,1,5,8,False
18,1,5,9,False
18,2,5,10,False
18,2,5,11,False
18,3,5,12,False
18,3,5,13,False
Control1,4,5,14,True
Control1,4,5,15,True
32,1,5,17,False
32,1,5,18,False
32,2,5,19,False
32,2,5,20,False
32,3,5,21,False
32,3,5,22,False
5,1,6,2,False
5,1,6,3,False
5,2,6,4,False
5,2,6,5,False
5,3,6,6,False
5,3,6,7,False
19,1,6,8,False
19,1,6,9,False
19,2,6,10,False
19,2,6,11,False
19,3,6,12,False
19,3,6,13,False
Control1,5,6,14,True
Control1,5,6,15,True
33,1,6,17,False
33,1,6,18,False
33,2,6,19,False
33,2,6,20,False
33,3,6,21,False
33,3,6,22,False
6,1,7,2,False
6,1,7,3,False
6,2,7,4,False
6,2,7,5,False
6,3,7,6,False
6,3,7,7,False
20,1,7,8,False
20,1,7,9,False
20,2,7,10,False
20,2,7,11,False
20,3,7,12,False
20,3,7,13,False
Control1,6,7,14,True
Control1,6,7,15,True
34,1,7,17,False
34,1,7,18,False
34,2,7,19,False
34,2,7,20,False
34,3,7,21,False
34,3,7,22,False
7,1,8,2,False
7,1,8,3,False
7,2,8,4,False
7,2,8,5,False
7,3,8,6,False
7,3,8,7,False
21,1,8,8,False
21,1,8,9,False
21,2,8,10,False
21,2,8,11,False
21,3,8,12,False
21,3,8,13,False
Control1,7,8,14,True
Control1,7,8,15,True
35,1,8,17,False
35,1,8,18,False
35,2,8,19,False
35,2,8,20,False
35,3,8,21,False
35,3,8,22,False
8,1,9,2,False
8,1,9,3,False
8,2,9,4,False
8,2,9,5,False
8,3,9,6,False
8,3,9,7,False
22,1,9,8,False
22,1,9,9,False
22,2,9,10,False
22,2,9,11,False
22,3,9,12,False
22,3,9,13,False
Control2,1,9,14,True
Control2,1,9,15,True
36,1,9,17,False
36,1,9,18,False
36,2,9,19,False
36,2,9,20,False
36,3,9,21,False
36,3,9,22,False
9,1,10,2,False
9,1,10,3,False
9,2,10,4,False
9,2,10,5,False
9,3,10,6,False
9,3,10,7,False
23,1,10,8,False
23,1,10,9,False
23,2,10,10,False
23,2,10,11,False
23,3,10,12,False
23,3,10,13,False
Control2,2,10,14,True
Control2,2,10,15,True
37,1,10,17,False
37,1,10,18,False
37,2,10,19,False
37,2,10,20,False
37,3,10,21,False
37,3,10,22,False
10,1,11,2,False
10,1,11,3,False
10,2,11,4,False
10,2,11,5,False
10,3,11,6,False
10,3,11,7,False
24,1,11,8,False
24,1,11,9,False
24,2,11,10,False
24,2,11,11,False
24,3,11,12,False
24,3,11,13,False
Control2,3,11,14,True
Control2,3,11,15,True
38,1,11,17,False
38,1,11,18,False
38,2,11,19,False
38,2,11,20,False
38,3,11,21,False
38,3,11,22,False
11,1,12,2,False
11,1,12,3,False
11,2,12,4,False
11,2,12,5,False
11,3,12,6,False
11,3,12,7,False
25,1,12,8,False
25,1,12,9,False
25,2,12,10,False
25,2,12,11,False
25,3,12,12,False
25,3,12,13,False
Control2,4,12,14,True
Control2,4,12,15,True
39,1,12,17,False
39,1,12,18,False
39,2,12,19,False
39,2,12,20,False
39,3,12,21,False
39,3,12,22,False
12,1,13,2,False
12,1,13,3,False
12,2,13,4,False
12,2,13,5,False
12,3,13,6,False
12,3,13,7,False
26,1,13,8,False
26,1,13,9,False
26,2,13,10,False
26,2,13,11,False
26,3,13,12,False
26,3,13,13,False
Control2,5,13,14,True
Control2,5,13,15,True
40,1,13,17,False
40,1,13,18,False
40,2,13,19,False
40,2,13,20,False
40,3,13,21,False
40,3,13,22,False
13,1,14,2,False
13,1,14,3,False
13,2,14,4,False
13,2,14,5,False
13,3,14,6,False
13,3,14,7,False
27,1,14,8,False
27,1,14,9,False
27,2,14,10,False
27,2,14,11,False
27,3,14,12,False
27,3,14,13,False
Control2,6,14,14,True
Control2,6,14,15,True
41,1,14,17,False
41,1,14,18,False
41,2,14,19,False
41,2,14,20,False
41,3,14,21,False
41,3,14,22,False
14,1,15,2,False
14,1,15,3,False
14,2,15,4,False
14,2,15,5,False
14,3,15,6,False
14,3,15,7,False
28,1,15,8,False
28,1,15,9,False
28,2,15,10,False
28,2,15,11,False
28,3,15,12,False
28,3,15,13,False
Control2,7,15,14,True
Control2,7,15,15,True
42,1,15,17,False
42,1,15,18,False
42,2,15,19,False
42,2,15,20,False
42,3,15,21,False
42,3,15,22,False
`;
