export const Z_384_12c_7pt_trip_2ctrl = `ID,Point,Row,Column,IsControl
1,1,2,2,False
1,2,2,3,False
1,3,2,4,False
1,4,2,5,False
1,5,2,6,False
1,6,2,7,False
1,7,2,8,False
5,1,2,9,False
5,2,2,10,False
5,3,2,11,False
5,4,2,12,False
5,5,2,13,False
5,6,2,14,False
5,7,2,15,False
9,1,2,17,False
9,2,2,18,False
9,3,2,19,False
9,4,2,20,False
9,5,2,21,False
9,6,2,22,False
9,7,2,23,False
1,1,3,2,False
1,2,3,3,False
1,3,3,4,False
1,4,3,5,False
1,5,3,6,False
1,6,3,7,False
1,7,3,8,False
5,1,3,9,False
5,2,3,10,False
5,3,3,11,False
5,4,3,12,False
5,5,3,13,False
5,6,3,14,False
5,7,3,15,False
9,1,3,17,False
9,2,3,18,False
9,3,3,19,False
9,4,3,20,False
9,5,3,21,False
9,6,3,22,False
9,7,3,23,False
1,1,4,2,False
1,2,4,3,False
1,3,4,4,False
1,4,4,5,False
1,5,4,6,False
1,6,4,7,False
1,7,4,8,False
5,1,4,9,False
5,2,4,10,False
5,3,4,11,False
5,4,4,12,False
5,5,4,13,False
5,6,4,14,False
5,7,4,15,False
9,1,4,17,False
9,2,4,18,False
9,3,4,19,False
9,4,4,20,False
9,5,4,21,False
9,6,4,22,False
9,7,4,23,False
2,1,5,2,False
2,2,5,3,False
2,3,5,4,False
2,4,5,5,False
2,5,5,6,False
2,6,5,7,False
2,7,5,8,False
6,1,5,9,False
6,2,5,10,False
6,3,5,11,False
6,4,5,12,False
6,5,5,13,False
6,6,5,14,False
6,7,5,15,False
10,1,5,17,False
10,2,5,18,False
10,3,5,19,False
10,4,5,20,False
10,5,5,21,False
10,6,5,22,False
10,7,5,23,False
2,1,6,2,False
2,2,6,3,False
2,3,6,4,False
2,4,6,5,False
2,5,6,6,False
2,6,6,7,False
2,7,6,8,False
6,1,6,9,False
6,2,6,10,False
6,3,6,11,False
6,4,6,12,False
6,5,6,13,False
6,6,6,14,False
6,7,6,15,False
10,1,6,17,False
10,2,6,18,False
10,3,6,19,False
10,4,6,20,False
10,5,6,21,False
10,6,6,22,False
10,7,6,23,False
2,1,7,2,False
2,2,7,3,False
2,3,7,4,False
2,4,7,5,False
2,5,7,6,False
2,6,7,7,False
2,7,7,8,False
6,1,7,9,False
6,2,7,10,False
6,3,7,11,False
6,4,7,12,False
6,5,7,13,False
6,6,7,14,False
6,7,7,15,False
10,1,7,17,False
10,2,7,18,False
10,3,7,19,False
10,4,7,20,False
10,5,7,21,False
10,6,7,22,False
10,7,7,23,False
3,1,8,2,False
3,2,8,3,False
3,3,8,4,False
3,4,8,5,False
3,5,8,6,False
3,6,8,7,False
3,7,8,8,False
7,1,8,9,False
7,2,8,10,False
7,3,8,11,False
7,4,8,12,False
7,5,8,13,False
7,6,8,14,False
7,7,8,15,False
11,1,8,17,False
11,2,8,18,False
11,3,8,19,False
11,4,8,20,False
11,5,8,21,False
11,6,8,22,False
11,7,8,23,False
3,1,9,2,False
3,2,9,3,False
3,3,9,4,False
3,4,9,5,False
3,5,9,6,False
3,6,9,7,False
3,7,9,8,False
7,1,9,9,False
7,2,9,10,False
7,3,9,11,False
7,4,9,12,False
7,5,9,13,False
7,6,9,14,False
7,7,9,15,False
11,1,9,17,False
11,2,9,18,False
11,3,9,19,False
11,4,9,20,False
11,5,9,21,False
11,6,9,22,False
11,7,9,23,False
3,1,10,2,False
3,2,10,3,False
3,3,10,4,False
3,4,10,5,False
3,5,10,6,False
3,6,10,7,False
3,7,10,8,False
7,1,10,9,False
7,2,10,10,False
7,3,10,11,False
7,4,10,12,False
7,5,10,13,False
7,6,10,14,False
7,7,10,15,False
11,1,10,17,False
11,2,10,18,False
11,3,10,19,False
11,4,10,20,False
11,5,10,21,False
11,6,10,22,False
11,7,10,23,False
4,1,11,2,False
4,2,11,3,False
4,3,11,4,False
4,4,11,5,False
4,5,11,6,False
4,6,11,7,False
4,7,11,8,False
8,1,11,9,False
8,2,11,10,False
8,3,11,11,False
8,4,11,12,False
8,5,11,13,False
8,6,11,14,False
8,7,11,15,False
12,1,11,17,False
12,2,11,18,False
12,3,11,19,False
12,4,11,20,False
12,5,11,21,False
12,6,11,22,False
12,7,11,23,False
4,1,12,2,False
4,2,12,3,False
4,3,12,4,False
4,4,12,5,False
4,5,12,6,False
4,6,12,7,False
4,7,12,8,False
8,1,12,9,False
8,2,12,10,False
8,3,12,11,False
8,4,12,12,False
8,5,12,13,False
8,6,12,14,False
8,7,12,15,False
12,1,12,17,False
12,2,12,18,False
12,3,12,19,False
12,4,12,20,False
12,5,12,21,False
12,6,12,22,False
12,7,12,23,False
4,1,13,2,False
4,2,13,3,False
4,3,13,4,False
4,4,13,5,False
4,5,13,6,False
4,6,13,7,False
4,7,13,8,False
8,1,13,9,False
8,2,13,10,False
8,3,13,11,False
8,4,13,12,False
8,5,13,13,False
8,6,13,14,False
8,7,13,15,False
12,1,13,17,False
12,2,13,18,False
12,3,13,19,False
12,4,13,20,False
12,5,13,21,False
12,6,13,22,False
12,7,13,23,False
Control1,1,14,2,True
Control1,2,14,3,True
Control1,3,14,4,True
Control1,4,14,5,True
Control1,5,14,6,True
Control1,6,14,7,True
Control1,7,14,8,True
Control1,1,14,9,True
Control1,2,14,10,True
Control1,3,14,11,True
Control1,4,14,12,True
Control1,5,14,13,True
Control1,6,14,14,True
Control1,7,14,15,True
Control1,1,14,17,True
Control1,2,14,18,True
Control1,3,14,19,True
Control1,4,14,20,True
Control1,5,14,21,True
Control1,6,14,22,True
Control1,7,14,23,True
Control2,1,15,2,True
Control2,2,15,3,True
Control2,3,15,4,True
Control2,4,15,5,True
Control2,5,15,6,True
Control2,6,15,7,True
Control2,7,15,8,True
Control2,1,15,9,True
Control2,2,15,10,True
Control2,3,15,11,True
Control2,4,15,12,True
Control2,5,15,13,True
Control2,6,15,14,True
Control2,7,15,15,True
Control2,1,15,17,True
Control2,2,15,18,True
Control2,3,15,19,True
Control2,4,15,20,True
Control2,5,15,21,True
Control2,6,15,22,True
Control2,7,15,23,True
`;
