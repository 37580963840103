import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';
import Split from 'react-split-it';
import { ErrorMessage } from '../../../components/common/Error';
import { iiSimilarCompounds } from '../../../components/common/Icons';
import { Footer, PageTemplate } from '../../../components/Layout/Layout';
import useBehavior from '../../../lib/hooks/useBehavior';
import { DialogService } from '../../../lib/services/dialog';
import { AsyncMoleculeDrawer } from '../../../lib/util/draw-molecules';
import {
    Column,
    ColumnsFor,
    DefaultRowHeight,
    DataTableControl,
    DataTableModel,
    DataTableStore,
} from '../../../components/DataTable';
import { SelectionColumn, SmilesColumn } from '../../../components/DataTable/common';
import { BatchReview } from '../compound-api';
import { MultipleBatchUploadModel } from './batch-upload-model';
import { CompoundInfoPanel, SelectedCompoundHeader, SimilarCompounds } from './CompoundComparison';
import { EntrySummary, EntrySummaryComponent } from '../../../components/common/EntrySummary';
import { TooltipWrapper } from '../../../components/common/Tooltips';

const SmilesRowHeightFactor = 2.5;
const rowHeight = DefaultRowHeight;

const drawer = new AsyncMoleculeDrawer();

const BatchTableSchema: ColumnsFor<BatchReview> = {
    structure: SmilesColumn(drawer, SmilesRowHeightFactor),
    compound_identifier: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        filterType: false,
        width: 150,
        position: 1,
    }),
    common_name: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        width: 175,
        position: 2,
    }),
    supplier: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        position: 3,
    }),
    supplier_id: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        position: 4,
    }),
    supplier_notebook: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        width: 150,
        position: 5,
    }),
    supplier_notebook_page: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        width: 165,
        position: 6,
    }),
    submitted_purity: Column.create({
        kind: 'float',
        noHeaderTooltip: true,
        width: 150,
        position: 7,
    }),
    error: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        render: ({ value }) =>
            value ? (
                <div className='entos-error-cell'>
                    <FontAwesomeIcon icon={faBug} fixedWidth color='var(--bs-danger)' className='me-1' />
                    {value.split('\n').map((v) => (
                        <span key={v}>
                            {v}
                            <br />
                        </span>
                    ))}
                </div>
            ) : (
                value
            ),
        width: 200,
        position: 8,
    }),
    warning: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        render: ({ value }) =>
            value ? (
                <div className='entos-warning-cell'>
                    <FontAwesomeIcon icon={faCircleExclamation} fixedWidth color='var(--bs-warning)' className='me-1' />
                    {value.split('\n').map((v) => (
                        <span key={v}>
                            {v}
                            <br />
                        </span>
                    ))}
                </div>
            ) : (
                value
            ),
        width: 200,
        position: 9,
    }),
    project: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        position: 10,
    }),
    stereochemistry_label: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        width: 150,
        position: 11,
    }),
    stereochemistry_enantiomeric_ratio: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        width: 230,
        position: 12,
    }),
    stereochemistry_comment: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        width: 175,
        position: 13,
    }),
    ambiguous_stereochemistry_v3k: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        width: 150,
        position: 14,
    }),
};

function createTable(model: MultipleBatchUploadModel, batchReview: DataTableStore<BatchReview>) {
    const table = new DataTableModel<BatchReview, 'selection' | 'review'>(batchReview, {
        columns: BatchTableSchema,
        actions: [
            SelectionColumn(),
            {
                id: 'review',
                width: 50,
                position: 2,
                alwaysVisible: true,
                noHeaderTooltip: true,
                noResize: true,
                align: 'left',
                cell: (rowIndex) =>
                    model.compoundComparisons.get(rowIndex) && (
                        <Button
                            variant={model.state.editedRows.value[rowIndex] ? 'primary' : 'outline-primary'}
                            title='Similar compounds found'
                            size='sm'
                            onClick={() => model.state.editingRowIndex.next(rowIndex)}
                        >
                            <FontAwesomeIcon icon={iiSimilarCompounds} fixedWidth color='var(--bs-primary)' />
                        </Button>
                    ),
            },
        ],
        hideNonSchemaColumns: true,
        rowHeight: SmilesRowHeightFactor * rowHeight,
        customState: { 'show-smiles': true },
    });

    table.setColumnStickiness('selection', true);
    table.setColumnStickiness('structure', true);
    table.setColumnStickiness('review', true);

    table.toggleSelectAll();

    return table;
}

function ConfirmationContent({
    model,
    table,
}: {
    model: MultipleBatchUploadModel;
    table: DataTableModel<BatchReview>;
}) {
    const numSelected = Object.keys(table.selectedRows).length;
    const shouldPromoteToENT = useBehavior(model.state.entosCompound);
    const compoundIdentifiers = table.store.getColumnValues('compound_identifier');
    const numExistingCompounds = compoundIdentifiers.filter((v) => !!v).length;
    const numNewCompounds = compoundIdentifiers.length - numExistingCompounds;

    return (
        <>
            <p>
                Are you sure you want to add {numSelected} batch{numSelected > 1 ? 'es' : ''} to Entos Foundry? This
                action cannot easily be undone.
            </p>
            <Form.Check
                type='switch'
                id='entos-compound-switch'
                label='Promote Compounds to ENT'
                checked={shouldPromoteToENT}
                onChange={(e) => model.state.entosCompound.next(!!e.target.checked)}
                className='d-inline-block me-2'
            />
            {!shouldPromoteToENT && (
                <Alert variant='warning' className='mt-3'>
                    {numNewCompounds > 0 &&
                        `${numNewCompounds} batch${numNewCompounds !== 1 ? 'es' : ''} will be registered under 
                        ${numNewCompounds !== 1 ? 'new CMPD compounds' : 'a new CMPD compound'}`}
                    {numNewCompounds > 0 && numExistingCompounds > 0 && <br />}
                    {numExistingCompounds > 0 &&
                        `${numExistingCompounds} batch${
                            numExistingCompounds !== 1 ? 'es' : ''
                        } will be registered under 
                        ${numExistingCompounds !== 1 ? 'existing compounds' : 'an existing compound'}`}
                    <br />
                    No compounds will be promoted from CMPD to ENT
                </Alert>
            )}
            {shouldPromoteToENT && (
                <Alert variant='info' className='mt-3'>
                    {numNewCompounds > 0 &&
                        `${numNewCompounds} batch${numNewCompounds !== 1 ? 'es' : ''} will be registered under 
                        ${numNewCompounds !== 1 ? 'new ENT compounds' : 'a new ENT compound'}`}
                    {numNewCompounds > 0 && numExistingCompounds > 0 && <br />}
                    {numExistingCompounds > 0 &&
                        `${numExistingCompounds} batch${
                            numExistingCompounds !== 1 ? 'es' : ''
                        } will be registered under 
                        ${numExistingCompounds !== 1 ? 'existing compounds' : 'an existing compound'}`}
                    <br />
                    Any CMPD compounds will be promoted to ENT
                </Alert>
            )}
        </>
    );
}

export function MultipleBatchReview({ model }: { model: MultipleBatchUploadModel }) {
    const error = useBehavior(model.error);
    const store = model.batchReviewStore;
    const table = useMemo(() => createTable(model, store), [store]);

    function onNext() {
        if (!store) return;

        DialogService.open({
            type: 'confirm',
            onConfirm,
            title: 'Add to Foundry',
            text: <ConfirmationContent model={model} table={table} />,
            confirmText: 'Add',
        });
    }

    async function onConfirm() {
        await model.upload(table);
    }

    return (
        <PageTemplate title='Compounds' breadcrumb={{ title: 'Register multiple batches', href: '', icon: undefined }}>
            {error && <ErrorMessage header='Failed to load batches' message={error} />}
            {!error && store && <MultipleBatchReviewTable table={table} />}
            <ReviewFooter model={model} table={table} onSubmit={() => onNext()} />
            <ExamineStereochemistryModal model={model} table={table} />
        </PageTemplate>
    );
}

function MultipleBatchReviewTable({ table }: { table: DataTableModel<BatchReview> }) {
    useBehavior(table.version);

    return (
        <div className='h-100 position-relative ms-3'>
            <DataTableControl height='flex' table={table} headerSize='sm' rowSelectionMode='multi' />
        </div>
    );
}

function ReviewFooter({
    model,
    table,
    onSubmit,
}: {
    model: MultipleBatchUploadModel;
    table: DataTableModel<BatchReview>;
    onSubmit: () => void;
}) {
    const isLoading = useBehavior(model.isLoading);
    const editedRows = useBehavior(model.state.editedRows);
    const allEdited = useMemo(() => Object.values(editedRows).every((v) => !!v), [editedRows]);
    useBehavior(table.version);
    const numSelected = Object.keys(table.selectedRows).length;

    const summary: EntrySummary = useMemo(() => {
        const numErrors = table.store.getColumnValues('error').filter((v, i) => !!v && !!table.selectedRows[i]).length;
        return {
            numErrors,
            numWarnings: table.store.getColumnValues('warning').filter((v, i) => !!v && !!table.selectedRows[i]).length,
            numReady: Object.keys(table.selectedRows).length - numErrors,
        };
    }, [table.selectedRows, table.store.version]);

    return (
        <Footer>
            <div className='flex-grow-1 text-secondary'>
                <EntrySummaryComponent kind='row' summary={summary} />
            </div>
            <div className='d-flex align-items-center'>
                <Button className='me-2' variant='link' onClick={() => model.cancel()}>
                    Cancel
                </Button>
                {summary.numErrors > 0 && (
                    <TooltipWrapper tooltip='Fix errors before adding to library'>
                        {(props) => (
                            <div {...props}>
                                <Button variant='primary' disabled>
                                    Add to Foundry
                                </Button>
                            </div>
                        )}
                    </TooltipWrapper>
                )}
                {summary.numErrors === 0 && !allEdited && (
                    <TooltipWrapper tooltip='Review stereochemistry before adding compounds to library'>
                        {(props) => (
                            <div {...props}>
                                <Button variant='primary' disabled>
                                    Add to Foundry
                                </Button>
                            </div>
                        )}
                    </TooltipWrapper>
                )}
                {summary.numErrors === 0 && allEdited && (
                    <Button variant='primary' onClick={onSubmit} disabled={numSelected === 0}>
                        {isLoading && <Spinner animation='border' role='status' size='sm' />}
                        {!isLoading && <>Add to Foundry</>}
                    </Button>
                )}
            </div>
        </Footer>
    );
}

function ExamineStereochemistryModal({
    model,
    table,
}: {
    model: MultipleBatchUploadModel;
    table: DataTableModel<BatchReview>;
}) {
    const editingRowIndex = useBehavior(model.state.editingRowIndex);
    const compoundComparison = useMemo(() => model.compoundComparisons.get(editingRowIndex), [editingRowIndex]);

    async function updateRow() {
        await model.updateRow();
        table.updated({ clearRenderCache: true });
        cancel();
    }

    function cancel() {
        model.state.editingRowIndex.next(-1);
    }

    return (
        <Modal backdrop centered scrollable size='xl' show={editingRowIndex > -1} onHide={() => cancel()}>
            <Modal.Header closeButton>
                {compoundComparison && (
                    <SelectedCompoundHeader model={compoundComparison} title='Similar compounds found' />
                )}
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-column'>
                    {compoundComparison && (
                        <Split direction='horizontal' gutterSize={6} sizes={[0.4, 0.6]}>
                            <CompoundInfoPanel model={compoundComparison} />
                            <SimilarCompounds model={compoundComparison} />
                        </Split>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='link' onClick={() => cancel()}>
                    Cancel
                </Button>
                {compoundComparison && (
                    <Button variant='primary' onClick={() => updateRow()}>
                        Save
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}
