import { useEffect } from 'react';
import { useBlockLayout, useGlobalFilter, useRowSelect, useSortBy, useTable } from 'react-table';
import { ErrorMessage } from '../../../components/common/Error';
import Loading from '../../../components/common/Loading';
import { ReactTableGlobalFilter } from '../../../components/ReactTable/Filters';
import { FlexibleVirtualizedTable } from '../../../components/ReactTable/VirtualizedTable';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import { ReactTableModel } from '../../../components/ReactTable/model';
import { ECMApi, Vial } from '../ecm-api';
import { ECMPageTemplate } from '../ecm-common';

export function ECMVials() {
    return (
        <ECMPageTemplate page='vials'>
            <TableWrapper />
        </ECMPageTemplate>
    );
}

function TableWrapper() {
    const [table, loadTable] = useAsyncAction();
    useEffect(() => {
        loadTable(ECMApi.listVials());
    }, []);

    if (table.isLoading) return <Loading />;
    if (table.error) return <ErrorMessage header='Error Loading Vials' message={`${table.error}`} />;
    if (!table.result) return null;

    return <VialTable table={table.result} />;
}

function VialTable({ table }: { table: ReactTableModel<Vial> }) {
    const {
        headerGroups,
        rows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { globalFilter },
    } = useTable(
        {
            columns: table.allColumns as any,
            data: table.rows,
            initialState: {
                ...table.state.value,
            },
        },
        useGlobalFilter,
        useSortBy,
        useRowSelect,
        useBlockLayout
    );

    return (
        <div className='d-flex flex-column h-100'>
            <div className='w-50 ms-3 mb-3'>
                <ReactTableGlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
            </div>
            <div className='flex-grow-1 position-relative'>
                <FlexibleVirtualizedTable
                    headerGroups={headerGroups as any}
                    rows={rows as any}
                    selectedRows={{}}
                    prepareRow={prepareRow as any}
                    table={table}
                />
            </div>
        </div>
    );
}
