export function createDeviceResolutionCanvas(w: number, h: number) {
    const ratio = window.devicePixelRatio || 1;
    const canvas = document.createElement('canvas');
    canvas.width = w * ratio;
    canvas.height = h * ratio;
    canvas.style.width = `${w}px`;
    canvas.style.height = `${h}px`;
    canvas.getContext('2d')?.scale(ratio, ratio);
    return canvas;
}

export function canvasToImage(canvas: HTMLCanvasElement) {
    const ratio = window.devicePixelRatio || 1;
    const img = new Image();
    img.width = canvas.width / ratio;
    img.height = canvas.height / ratio;
    img.src = canvas.toDataURL();
    return img;
}

export interface CanvasTextRenderingOptions {
    x: number;
    y: number;
    fontSize: number;
}

export function renderCanvasSuperscript(
    ctx: CanvasRenderingContext2D,
    text: string,
    renderOptions: CanvasTextRenderingOptions
) {
    const { x, y, fontSize } = renderOptions;
    ctx.font = `${fontSize - 3}px sans-serif`;
    ctx.fillText(text, x, y - fontSize / 2);
}

export function renderCanvasSubscript(
    ctx: CanvasRenderingContext2D,
    text: string,
    renderOptions: CanvasTextRenderingOptions
) {
    const { x, y, fontSize } = renderOptions;
    ctx.font = `${fontSize - 3}px sans-serif`;
    ctx.fillText(text, x, y + fontSize / 3);
}
