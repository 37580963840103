import { faCopy, faEdit, faFlaskVial, faList, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { Button, Spinner } from 'react-bootstrap';
import { HTEEInventoryModel, validationEntry } from '.';
import { DataTableControl, DataTableGlobalFilter } from '../../../components/DataTable';
import { AsyncButton } from '../../../components/common/AsyncButton';
import { LabeledInput, SimpleSelectOptionInput } from '../../../components/common/Inputs';
import Loading from '../../../components/common/Loading';
import useBehavior from '../../../lib/hooks/useBehavior';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { useModelAction } from '../../../lib/util/reactive-model';
import { BatchLink } from '../../ECM/ecm-common';
import { HTEWModel } from '../model';
import { DialogService } from '../../../lib/services/dialog';
import { ProtocolText } from '../../../components/common/ProtocolText';

export function HTEInventoryUI({ model }: { model: HTEWModel }) {
    const execution = useBehavior(model.state.execution);
    const inventory = useMountedModel(execution?.inventory);

    if (!inventory) return <div className='m-2 text-secondary'>Design not built</div>;

    return <Inventory model={inventory} />;
}

function Inventory({ model }: { model: HTEEInventoryModel }) {
    useBehavior(model.inventoryTable.version);
    const state = useModelAction(model.actions.load);

    if (state?.kind === 'loading') return <Loading />;

    return (
        <div className='d-flex flex-column h-100 p-2 position-relative'>
            <div className='mb-2 hstack gap-2'>
                <div className='w-50'>
                    <DataTableGlobalFilter
                        table={model.inventoryTable}
                        selectOnFocus
                        controlClassName='font-body-small py-1 px-2'
                    />
                </div>
                <div className='m-auto' />
                <AsyncButton
                    size='sm'
                    icon={faRefresh}
                    title='Refresh inventory'
                    onClick={model.refreshInventory}
                    variant='outline-primary'
                >
                    Refresh
                </AsyncButton>
                <AsyncButton
                    size='sm'
                    icon={faCopy}
                    title='Copy Source Barcodes'
                    onClick={model.copySourceBarcodes}
                    variant='outline-primary'
                >
                    Copy Barcodes
                </AsyncButton>
                {model.canEdit && (
                    <AsyncButton
                        size='sm'
                        icon={faList}
                        title='Auto-assign empty barcodes'
                        onClick={model.autoAssignInventory}
                        variant='outline-primary'
                    >
                        Auto-assign
                    </AsyncButton>
                )}
                <SubmitForExecutionButton model={model.execution.model} />
            </div>
            <div className='position-relative flex-grow-1'>
                <DataTableControl
                    height='flex'
                    table={model.inventoryTable}
                    headerSize='xsm'
                    getAdditionalRowClasses={(row, table) =>
                        table.store.getValue('excluded', row) ? 'htew-excluded-inventory-row' : ''
                    }
                />
            </div>
        </div>
    );
}

function SubmitForExecutionButton({ model }: { model: HTEWModel }) {
    const state = useModelAction(model.actions.submit);
    useBehavior(model.state.dataSource);

    if (model.inProgress) {
        const makeEditable = () => {
            DialogService.open({
                type: 'generic',
                wrapOk: true,
                onOk: () => model.actions.submit.run(model.makeEditable(), { onError: 'eat', rethrowError: true }),
                title: 'Make Editable',
                content: MakeEditableContent,
                confirmButtonContent: 'Apply',
            });
        };

        return (
            <AsyncButton
                onClick={makeEditable}
                state={{ isLoading: state.kind === 'loading' }}
                variant='outline-primary'
                size='sm'
                icon={faEdit}
            >
                Make Editable
            </AsyncButton>
        );
    }

    if (model.inProgress || model.isLocked || !model.canEdit) return null;

    const submit = () => {
        if (!model.checkCanSubmit()) return;

        DialogService.open({
            type: 'generic',
            wrapOk: true,
            onOk: () => model.actions.submit.run(model.submitForExecution(), { onError: 'eat', rethrowError: true }),
            title: 'Submit for Execution',
            content: SubmitForExecutionContent,
            confirmButtonContent: 'Submit',
        });
    };

    return (
        <AsyncButton
            onClick={submit}
            state={{ isLoading: state.kind === 'loading' }}
            variant='primary'
            size='sm'
            icon={faFlaskVial}
        >
            Submit
        </AsyncButton>
    );
}

function SubmitForExecutionContent() {
    return (
        <>
            <p>
                Do you want to save the protocol and notify Compound Management the experiment is ready for execution?
            </p>
            <p className='text-warning'>It will no longer be possible to edit the design, protocol, or procedure.</p>
            <p className='text-info'>
                Before submitting, please carefully review the batch/barcode assignment for each reactant.
            </p>
        </>
    );
}

function MakeEditableContent() {
    return (
        <>
            <p>Do you want to make the protocol editable again?</p>
            <p className='text-warning'>
                Re-building the design will erase all changes made in the <b>Execution</b> tab (e.g., assigned plate
                barcodes or inventory transfers) and will clear inventory assignment in this tab.
            </p>
            <p className='text-info'>After required changes have been made, please re-submit for execution.</p>
        </>
    );
}

export function TransferInfo({ model }: { model: HTEEInventoryModel }) {
    const transfer = useBehavior(model.state.transferTargets);
    useBehavior(model.state.targetBarcode); // required to refresh target overage
    const applyState = useModelAction(model.actions.applyTransfer);

    if (transfer?.isLoading || applyState.kind === 'loading') {
        return (
            <div style={{ height: 80 }} className='d-flex align-items-center justify-content-center text-secondary'>
                <Spinner animation='border' role='status' />
            </div>
        );
    }
    if (!transfer) return null;

    if (transfer.error) {
        return (
            <LabeledInput label='' className='ecm-manual-inputs-row'>
                <div className='text-warning'>{transfer.error}</div>
            </LabeledInput>
        );
    }

    const info = model.getCurrentTransferInfo();
    if (!info) return null;

    return (
        <>
            {transfer.options && (
                <LabeledInput label='Target' className='ecm-manual-inputs-row'>
                    <SimpleSelectOptionInput
                        options={transfer.options}
                        value={transfer.rowIndex!}
                        setValue={(v) => model.state.transferTargets.next({ ...transfer, rowIndex: v })}
                    />
                </LabeledInput>
            )}
            {!!info.validation && (
                <LabeledInput label='' className='ecm-manual-inputs-row'>
                    <ul className='list-group'>{info.validation.map((e, i) => validationEntry(e, undefined, i))}</ul>
                </LabeledInput>
            )}
            <LabeledInput label='Batch' className='ecm-manual-inputs-row'>
                <BatchLink identifier={info.identifier} withQuery />
            </LabeledInput>
            <LabeledInput label='Req. Amount' className='ecm-manual-inputs-row'>
                {info.formattedAmount && <b>{info.formattedAmount}</b>}
                {!info.formattedAmount && <span className='text-secondary ps-2'>-</span>}
            </LabeledInput>
            <LabeledInput label='Req. Volume' className='ecm-manual-inputs-row'>
                {info.formattedVolume && <b>{info.formattedVolume}</b>}
                {!info.formattedVolume && <span className='text-secondary ps-2'>-</span>}
            </LabeledInput>
            <LabeledInput label='Req. Concentration' className='ecm-manual-inputs-row'>
                {info.formattedConcentration && <b>{info.formattedConcentration}</b>}
                {!info.formattedConcentration && <span className='text-secondary ps-2'>-</span>}
            </LabeledInput>
        </>
    );
}

function TransferFilters({ model }: { model: HTEEInventoryModel }) {
    const showTubes = useBehavior(model.state.showTubes);

    return (
        <div className='htew-transfer-filters hstack gap-2'>
            <Button
                onClick={() => model.state.showTubes.next(!showTubes)}
                variant={showTubes ? 'dark' : 'outline'}
                className={showTubes ? 'htew-transfer-filter-type-current-tubes' : 'htew-transfer-filter-type-tubes'}
            >
                Tubes
            </Button>
        </div>
    );
}

export function TransferTable({ model }: { model: HTEEInventoryModel }) {
    const table = model.transferTable;
    useMountedModel(model);
    useBehavior(table.version);
    const state = useModelAction(model.actions.load);

    if (state?.kind === 'loading') return <Loading />;

    return (
        <div className='d-flex flex-column h-100 position-relative'>
            <div className='mb-2 hstack gap-2 me-2'>
                <div className='w-50'>
                    <DataTableGlobalFilter table={table} selectOnFocus controlClassName='font-body-small py-1 px-2' />
                </div>
                <TransferFilters model={model} />
                <div className='m-auto' />
                <AsyncButton
                    size='sm'
                    icon={faRefresh}
                    title='Refresh inventory'
                    onClick={model.refreshInventory}
                    variant='outline-primary'
                >
                    Refresh
                </AsyncButton>
            </div>
            <div className='position-relative flex-grow-1'>
                <DataTableControl height='flex' table={table} headerSize='xsm' rowSelectionMode='single' />
            </div>
        </div>
    );
}

export function CombiTable({ model }: { model: HTEEInventoryModel }) {
    const table = model.combiTable;
    useMountedModel(model);
    useBehavior(table.version);
    const state = useModelAction(model.actions.load);

    if (state?.kind === 'loading') return <Loading />;

    return (
        <div className='position-relative h-100'>
            <DataTableControl height='flex' table={table} headerSize='xsm' rowSelectionMode='single' />
        </div>
    );
}

export function CombiMaps({ model }: { model: HTEEInventoryModel }) {
    const table = model.combiTable;
    useBehavior(table.version);
    const state = useModelAction(model.actions.load);
    if (state?.kind === 'loading') return <Loading />;

    const maps = model.getCombiMaps();

    return (
        <div className='hstack gap-2 align-items-stretch h-100 me-2'>
            {maps.map((m, i) => (
                <div key={i} className='flex-grow-1 d-flex flex-column'>
                    <h5 className='mt-2'>{m.header}</h5>
                    <ProtocolText
                        value={m.map}
                        save={() => model.execution.saveProtocol({ value: m.map, filename: `combi-${m.header}` })}
                        className='flex-grow-1'
                    />
                </div>
            ))}
        </div>
    );
}
