import { faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import * as d3colors from 'd3-scale-chromatic';
import { saveAs } from 'file-saver';
import { BehaviorSubject } from 'rxjs';
import { InlineAlert } from '../../../components/common/Alert';
import { TextInput } from '../../../components/common/Inputs';
import { ScrollBox } from '../../../components/common/ScrollBox';
import useBehavior from '../../../lib/hooks/useBehavior';
import { DialogService } from '../../../lib/services/dialog';
import { ToastService } from '../../../lib/services/toast';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { arrayMapAdd, groupByPreserveOrder, memoizeLatest, objectIsEmpty } from '../../../lib/util/misc';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { PlateVisualColors, PlateVisualLabels, PlateVisualModel, PlateWellColoring } from '../../HTE/plate/PlateVisual';
import { rowMajorIndexToColumnMajorIndex } from '../../HTE/plate/utils';
import { HTE2MSApi } from '../api';
import {
    HTEDReaction,
    HTEPCompilerMessage,
    HTEPCrudePlate,
    HTEPReagentUse,
    HTEPSolution,
    HTEPWorklistKeyT,
} from '../data-model';
import type { HTE2MSProductionModel } from './model';

const EmptyCrudePlate: HTEPCrudePlate = {
    reaction_well_indices: {},
    worklists: [],
    errors: [],
    warnings: [],
};

export interface CrudePlateInfo {
    errors: string[];
    warnings: string[];
    errorsByReaction: Map<string, PlateWellMessage[]>;
    warningsByReaction: Map<string, PlateWellMessage[]>;
}

export interface PlateWellMessage {
    message: HTEPCompilerMessage;
    use?: HTEPReagentUse;
    solution?: HTEPSolution;
}

export type CrudePlateTab = [worklist: HTEPWorklistKeyT, kind: 'OT-2' | 'Picklists'] | 'layout';

export class HTE2MSCrudePlateModel extends ReactiveModel {
    state = {
        plate: new BehaviorSubject<HTEPCrudePlate>(EmptyCrudePlate),
        currentTab: new BehaviorSubject<CrudePlateTab>('layout'),
        plateVisual: new BehaviorSubject<PlateVisualModel>(new PlateVisualModel(96, { singleSelect: true })),
    };
    wellToReaction = new Map<number, HTEDReaction>();

    get data() {
        return this.state.plate.value;
    }

    private _plateInfo = memoizeLatest((plate: HTEPCrudePlate) => {
        const errors = new Map<string, number>();
        const warnings = new Map<string, number>();
        const errorsByReaction = new Map<string, PlateWellMessage[]>();
        const warningsByReaction = new Map<string, PlateWellMessage[]>();

        const addByReaction = (target: Map<string, PlateWellMessage[]>, message: HTEPCompilerMessage) => {
            if (message.reaction_id && !message.reagent_key) {
                arrayMapAdd(target, message.reaction_id, { message });
            }

            if (message.solution_key) {
                const solution = this.production.model.protocol.reagents.solution.byKey.get(message.solution_key);
                if (solution) {
                    for (const use of solution.uses) {
                        if (message.reaction_id && use.reaction_id !== message.reaction_id) continue;
                        arrayMapAdd(target, use.reaction_id, { message, solution });
                    }
                }
            }

            if (message.reagent_key) {
                const reagent = this.production.model.protocol.reagents.getByKey(message.reagent_key);
                if (reagent) {
                    for (const use of reagent.uses) {
                        if (message.reaction_id && use.reaction_id !== message.reaction_id) continue;
                        arrayMapAdd(target, use.reaction_id, { message, use });
                    }
                }
            }
        };

        for (const error of plate.errors) {
            errors.set(error.message, (errors.get(error.message) || 0) + 1);
            addByReaction(errorsByReaction, error);
        }
        for (const warning of plate.warnings) {
            warnings.set(warning.message, (warnings.get(warning.message) || 0) + 1);
            addByReaction(warningsByReaction, warning);
        }
        for (const wl of plate.worklists) {
            const worklistTitle = this.production.model.protocol.worklistMap.get(wl.key)?.title ?? wl.key;
            for (const error of wl.errors) {
                errors.set(`${worklistTitle}: ${error.message}`, (errors.get(error.message) || 0) + 1);
                addByReaction(errorsByReaction, error);
            }
        }

        return {
            errors: getMessages(errors),
            warnings: getMessages(warnings),
            errorsByReaction,
            warningsByReaction,
        } satisfies CrudePlateInfo;
    });

    get plateInfo() {
        return this._plateInfo(this.data);
    }

    get plateVisual() {
        return this.state.plateVisual.value;
    }

    saveProtocol(options: { protocol: string; kind: 'OT-2' | 'Picklists' }) {
        const filename = `${this.production.model.libraryId}-${options.kind}.${options.kind === 'OT-2' ? 'py' : 'csv'}`;
        saveAs(
            new Blob([options.protocol], { type: options.kind === 'OT-2' ? 'text/x-python' : 'text/csv' }),
            filename
        );
    }

    private syncPlateVisual() {
        const layout = this.production.model.design.labware.product.layout ?? 96;

        if (this.plateVisual.layout !== layout) {
            this.state.plateVisual.next(new PlateVisualModel(layout, { singleSelect: true }));
        }

        const colors: PlateVisualColors = new Array(this.plateVisual.layout).fill(PlateWellColoring.NoColor);
        const labels: PlateVisualLabels = new Array(this.plateVisual.layout).fill(null);

        if (objectIsEmpty(this.data.reaction_well_indices)) {
            this.plateVisual.state.colors.next(colors);
            this.plateVisual.state.labels.next(labels);
            return;
        }

        const info = this.plateInfo;

        const labelColor = 'rgba(255, 255, 255, 0.75)';

        for (const [rid, wellIdx] of Array.from(Object.entries(this.data.reaction_well_indices))) {
            if (wellIdx >= layout) break;

            const c = d3colors.interpolateWarm(rowMajorIndexToColumnMajorIndex(layout, wellIdx) / (layout - 1));
            colors[wellIdx] = c;

            const hasError = info.errorsByReaction.get(rid)?.length;
            const hasWarning = info.warningsByReaction.get(rid)?.length;

            if (hasError) labels[wellIdx] = { color: labelColor, text: '!' };
            else if (hasWarning) labels[wellIdx] = { color: labelColor, text: '?' };
            else labels[wellIdx] = { color: labelColor, text: '✓' };
        }

        this.plateVisual.state.colors.next(colors);
        this.plateVisual.state.labels.next(labels);
    }

    confirmUploadPlate = () => {
        DialogService.open({
            type: 'generic',
            title: `Upload Crude Plate`,
            confirmButtonContent: 'Upload',
            model: this,
            defaultState: { barcode: '', errors: [] } satisfies UploadCrudePlateDialogState,
            content: UploadCrudePlateDialogContent,
            wrapOk: true,
            onOk: async (state: UploadCrudePlateDialogState, subject: BehaviorSubject<UploadCrudePlateDialogState>) => {
                const info = this.production.model.info;
                if (info.kind !== 'foundry') {
                    throw new Error('Crude plate can only be uploaded for Foundry libraries');
                }

                subject.next({ ...state, errors: [] });

                const result = await HTE2MSApi.uploadCrudePlate(info.experiment.id, {
                    last_modified_on: this.production.model.last_modified_on!,
                    library: this.production.model.toLibrary(),
                    plate_barcode: state.barcode,
                });

                if (result.errors?.length) {
                    const errors = groupByPreserveOrder(result.errors, (e) => e.message).map(
                        (group) => `${group[0].message} [${group.length}]`
                    );
                    subject.next({ ...state, errors });
                    throw new Error(''); // errors will be shown in the dialog body
                }

                try {
                    await HTE2MSApi.notifySlack(info.experiment.id, 'purify');
                } catch (e) {
                    reportErrorAsToast('Notify Slack', e);
                }

                ToastService.success('Plate uploaded');
                this.production.model.updateFoundryInfo({
                    experiment: result.experiment,
                    ui_state: { ...info.ui_state, workflow_state: 'purification' },
                });
                this.production.model.state.tab.next('post-production');
            },
        });
    };

    setPlate(plate: HTEPCrudePlate) {
        this.wellToReaction.clear();
        for (const [rid, wellIdx] of Array.from(Object.entries(plate.reaction_well_indices))) {
            this.wellToReaction.set(wellIdx, this.production.model.design.getById(rid)!);
        }
        this.state.plate.next(plate);
    }

    clear() {
        this.setPlate(EmptyCrudePlate);
        this.state.currentTab.next('layout');
    }

    mount() {
        this.subscribe(this.state.plate, (plate) => {
            const currentTab = this.state.currentTab.value;
            if (!plate.worklists.length) {
                return this.state.currentTab.next('layout');
            }
            if (currentTab !== 'layout' && !plate.worklists.some((wl) => wl.key === currentTab[0])) {
                return this.state.currentTab.next('layout');
            }

            this.syncPlateVisual();
        });
    }

    constructor(public production: HTE2MSProductionModel) {
        super();
    }
}

function getMessages(messages: Map<string, number>) {
    return Array.from(messages.entries())
        .map(([message, count]) => `${message}${count > 1 ? ` [${count}]` : ''}`)
        .sort();
}

type UploadCrudePlateDialogState = { barcode: string; errors: string[] };

function UploadCrudePlateDialogContent({
    stateSubject,
}: {
    stateSubject: BehaviorSubject<UploadCrudePlateDialogState>;
}) {
    const state = useBehavior(stateSubject);

    return (
        <div className='vstack gap-2'>
            <InlineAlert className='font-body-small'>
                This will upload the crude plate to Foundry and notify Analytical that the plate is ready for
                purification.
            </InlineAlert>

            <InlineAlert variant='warning' icon={faExclamationTriangle} className='font-body-small'>
                Once uploaded, it will no longer be possible to edit any values in the <b>Design</b>, <b>Reagents</b>,{' '}
                <b>Inventory</b> and <b>Production</b> tabs.
            </InlineAlert>

            <TextInput
                placeholder='Enter plate barcode...'
                value={state.barcode}
                setValue={(v) => stateSubject.next({ ...state, barcode: v.trim() })}
                className='fw-bold'
                style={{ fontSize: 'larger' }}
            />

            {state.errors.length > 0 && (
                <InlineAlert variant='danger' icon={faExclamationCircle} className='font-body-small'>
                    Crude plate upload failed. Error summary:
                    <ScrollBox maxHeight={120} className='mt-2'>
                        {state.errors.map((s, i) => (
                            <div key={i} className='list-group-item'>
                                {s}
                            </div>
                        ))}
                    </ScrollBox>
                </InlineAlert>
            )}
        </div>
    );
}
