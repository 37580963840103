import { saveAs } from 'file-saver';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from '../../../lib/services/toast';
import { reportErrorAsToast, tryGetErrorMessage } from '../../../lib/util/errors';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { ECMApi, SolubilizeVialRacksInput, SolubilizeVialRacksOutput } from '../../ECM/ecm-api';
import { HTE2MSApi } from '../api';
import { HTEDReactionIdT, HTEIVialRack, HTEPMessage, HTEMaterializedTransfer } from '../data-model';
import { transfersToCSV } from '../utils/picklist';
import type { HTE2MSInventoryModel } from './model';
import { arrayToCsv } from '../../../lib/util/arrayToCsv';

export interface ProtocolMessageGroup {
    message: string;
    reactionIds: HTEDReactionIdT[];
    group: HTEPMessage[];
}

interface SolubilizationInfo {
    racks: HTEIVialRack[];
    not_found: string[];
    solvent_locations: { solvent: string; label: string; well: string }[];
    transfers: HTEMaterializedTransfer[];
}

const EmptySolubilization: SolubilizationInfo = { racks: [], not_found: [], solvent_locations: [], transfers: [] };

export class HTE2MSSolubilizeModel extends ReactiveModel {
    state = {
        info: new BehaviorSubject<SolubilizationInfo>(EmptySolubilization),
        picklist: new BehaviorSubject<string>(''),
        solubilization: new BehaviorSubject<SolubilizeVialRacksOutput | undefined>(undefined),
    };

    oneOff(barcode: string) {
        const csv = arrayToCsv([
            ['Rack Barcode', 'Well', 'Barcode'],
            ['Rack', 'A1', barcode],
        ]);
        const file = new File([csv], `one-off.csv`, { type: 'text/csv' });
        return this.uploadRacks([file]);
    }

    uploadRacks = async (files: File[]) => {
        const results = await Promise.all(files.map((f) => HTE2MSApi.parseRacks(f)));
        const racks = results.flat();
        const vialToLocation = new Map<string, [barcode: string, well: string]>();

        for (const rack of racks) {
            for (const [well, barcode] of Array.from(Object.entries(rack.wells))) {
                vialToLocation.set(barcode, [rack.label!, well]);
            }
        }

        const assets = this.inventory.model.assets;
        await assets.inventory.syncHolders(Array.from(vialToLocation.keys()));

        const production = this.inventory.data;
        const reagents = this.inventory.model.protocol.reagents.all;
        const transfers: HTEMaterializedTransfer[] = [];
        const uniqueSolvents = new Set<string>();
        const foundBarcodes = new Set<string>();

        for (let i = 0; i < reagents.length; i++) {
            const r = reagents[i];
            if (!r.solvent) continue;

            const xferBarcode = production.liquid[r.key]?.transfer_barcode;
            const loc = vialToLocation.get(xferBarcode!);
            if (!loc) continue;

            // eslint-disable-next-line no-await-in-loop
            const sample = assets.inventory.getVialSample(xferBarcode!);
            if (!sample) {
                throw new Error(
                    `Sample not found for barcode ${xferBarcode}. Try refreshing the inventory / reloading the page.`
                );
            }

            const batch = assets.inventory.entities.batchesById.get(sample?.batch_id!);
            const fw = batch?.formula_weight!;

            const n_mols = (sample?.solute_mass ?? 0) / fw;
            const volume_l = n_mols / r.concentration!;
            uniqueSolvents.add(r.solvent);

            transfers.push({
                source_barcode: '',
                source_well: '',
                identifier: batch?.universal_identifier!,
                solvent: r.solvent,
                volume: 1e-3 * volume_l,
                concentration: r.concentration!,
                target_barcode: loc[0],
                target_well: loc[1],
                reference_barcode: xferBarcode!,
            });

            foundBarcodes.add(xferBarcode!);
        }

        const not_found: string[] = [];
        for (const [barcode, loc] of Array.from(vialToLocation.entries())) {
            if (!foundBarcodes.has(barcode)) not_found.push(`${barcode} in ${loc[0]}:${loc[1]}`);
        }

        transfers.sort((a, b) => {
            if (a.solvent !== b.solvent) return a.solvent < b.solvent ? -1 : 1;
            if (a.target_barcode !== b.target_barcode) return a.target_barcode < b.target_barcode ? -1 : 1;
            if (a.target_well === b.target_well) return 0;
            return a.target_well < b.target_well ? -1 : 1;
        });

        this.state.info.next({
            racks,
            not_found,
            solvent_locations: Array.from(uniqueSolvents)
                .sort()
                .map((s, i) => ({ solvent: s, label: 'Through', well: `A${i + 1}` })),
            transfers,
        });
        this.state.solubilization.next(undefined);
    };

    compile(info: SolubilizationInfo) {
        const solventMap = new Map(info.solvent_locations.map((s) => [s.solvent, s]));

        const finalTransfers = this.state.info.value.transfers.map((t) => ({
            ...t,
            source_barcode: solventMap.get(t.solvent)!.label,
            source_well: solventMap.get(t.solvent)!.well,
        }));

        const csv = transfersToCSV(this.inventory.model, finalTransfers);
        this.state.picklist.next(csv);
    }

    async applySolubilize(allow_update: boolean) {
        const inputs = this.state.info.value.transfers.map(
            (a) =>
                ({
                    barcode: a.reference_barcode!,
                    rack_barcode: a.target_barcode,
                    rack_well: a.target_well,
                    concentration: a.concentration!,
                    solvent: a.solvent,
                } satisfies SolubilizeVialRacksInput)
        );

        let output: SolubilizeVialRacksOutput | undefined;
        try {
            output = await ECMApi.solubilizeVialRacks({
                inputs,
                event_context: this.inventory.model.libraryId,
                allow_update,
            });
            this.state.solubilization.next(output);
            if (output.errors.length) {
                ToastService.show({
                    message: 'Solubilization not applied, see errors',
                    type: 'warning',
                    timeoutMs: 3500,
                });
            } else if (output.solubilized_vials.length > 0) {
                ToastService.show({ message: 'Solubilization applied', type: 'success', timeoutMs: 3500 });
            } else {
                ToastService.show({ message: 'Nothing solubilized', type: 'info', timeoutMs: 3500 });
            }
        } catch (err) {
            reportErrorAsToast('Solubilize', err);
            this.state.solubilization.next(undefined);
        }

        try {
            if (output?.solubilized_vials.length! > 0) {
                const assets = this.inventory.model.assets.inventory;
                const identifiers = this.state.info.value.transfers.map((a) => a.identifier!);
                this.state.info.next(EmptySolubilization);
                await Promise.all([
                    assets.syncHolders(
                        output!.solubilized_vials.map((v) => v.barcode),
                        true
                    ),
                    assets.syncInventory(identifiers, true),
                ]);
                this.inventory.refresh();
            }
        } catch (err) {
            reportErrorAsToast(
                'Solubilize',
                `Solubilization applied, but failed to sync inventory. Please refresh manually. ${tryGetErrorMessage(
                    err
                )}`
            );
        }
    }

    save = () => {
        const racks = this.state.info.value.racks
            .map((r) => r.label)
            .filter((r) => r)
            .join('_');
        saveAs(
            new Blob([this.state.picklist.value], { type: 'text/csv' }),
            `${this.inventory.model.libraryId}-${racks}-solubilization.csv`
        );
    };

    mount() {
        this.subscribe(this.state.info, (info) => this.compile(info));
    }

    constructor(public inventory: HTE2MSInventoryModel) {
        super();
    }
}
