import { faArrowUpRightFromSquare, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import { Button, ButtonGroup, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import { SimpleMultiFileUpload } from '../../components/common/FileUpload';
import { LogMessages, LogModel } from '../../components/common/Log';
import { LinkPillNav } from '../../components/common/Nav';
import { PageTemplate } from '../../components/Layout/Layout';
import Pane from '../../components/Pane/Pane';
import { useAsyncAction } from '../../lib/hooks/useAsyncAction';
import useBehavior from '../../lib/hooks/useBehavior';
import useBehaviorSubject from '../../lib/hooks/useBehaviorSubject';
import { AuthService } from '../../lib/services/auth';
import { createPalleteRGB, toRGBString } from '../../lib/util/colors';
import { prefixedUnitValue } from '../../lib/util/units';
import { Sample } from '../Compounds/compound-api';
import { CompoundIdentifier } from '../HTE/steps/reagents-model';
import { Plate, SampleCreate } from './ecm-api';
import { getCurrentTheme } from '../../components/theme';
import { PlateVisualLabels } from '../HTE/plate/PlateVisual';
import { BaseColors } from '../../lib/services/theme';

export const ECMPages = {
    search: { title: 'Search Inventory', path: 'search' },
    vials: { title: 'All Vials', path: 'vials' },
    plates: { title: 'All Plates', supportsId: true, path: 'plates' },
    tare: { title: 'Tare', path: 'workflows/tare' },
    fill: { title: 'Fill', path: 'workflows/fill' },
    adjust: { title: 'Adjust', path: 'workflows/adjust' },
    'volume-check': { title: 'Volume Check', path: 'workflows/volume-check' },
    weigh: { title: 'Receive/Weigh', path: 'workflows/weigh' },
    transfer: { title: 'Transfer', path: 'workflows/transfer' },
    solubilize: { title: 'Solubilize', path: 'workflows/solubilize' },
    'plate-upload': { title: 'Plate Upload', path: 'workflows/plate-upload' },
    'plate-fill': { title: 'Plate Fill', path: 'workflows/plate-fill' },
    location: { title: 'Location', path: 'workflows/location' },
    dispose: { title: 'Dispose', path: 'workflows/dispose' },
};
export type ECMPage = keyof typeof ECMPages;
export type ECMUploadCallback = (
    files: File[] | null,
    fileSubject: BehaviorSubject<File[] | null>,
    overrides?: BehaviorSubject<Record<string, any>>
) => any;

const SearchPages = (['search', 'vials', 'plates'] satisfies ECMPage[]).map((p) => ({
    label: ECMPages[p].title,
    name: p,
    link: `/ecm/${ECMPages[p].path}`,
}));
const WorkflowPages = (
    [
        'tare',
        'fill',
        'adjust',
        'volume-check',
        'weigh',
        'transfer',
        'solubilize',
        'plate-upload',
        'plate-fill',
        'location',
        'dispose',
    ] satisfies ECMPage[]
).map((p) => ({ label: ECMPages[p].title, name: p, link: `/ecm/${ECMPages[p].path}` }));

export function ECMPageTemplate({
    page,
    children,
    withFooter,
}: {
    page: ECMPage;
    children: ReactNode;
    withFooter?: boolean;
}) {
    const { title } = ECMPages[page];
    return (
        <PageTemplate
            title='Compound Management'
            breadcrumb={{ href: window.location.pathname, title }}
            button={<ECMCurrentUser />}
        >
            <ECMNav current={page} />
            <div className={classNames('ecm-content mx-2', { 'ecm-content-footer': withFooter })}>{children}</div>
        </PageTemplate>
    );
}

export function ECMNav({ current }: { current: ECMPage }) {
    return (
        <div className='p-2 hstack gap-2'>
            <LinkPillNav steps={SearchPages} currentStep={current} />
            <LinkPillNav steps={WorkflowPages} currentStep={current} />
        </div>
    );
}
export type VialActionStatus =
    | { kind: 'ready' }
    | { kind: 'pending' }
    | { kind: 'submitted' }
    | { kind: 'error'; message?: string };

export type VialActionSummary = Record<VialActionStatus['kind'], number>;

export const EmptyVialActionSummary: VialActionSummary = {
    ready: 0,
    pending: 0,
    error: 0,
    submitted: 0,
};

export type ECMWorkflowMode = 'manual' | 'batch';
export function ECMWorkflowModeSelect({
    mode,
    setMode,
}: {
    mode: ECMWorkflowMode;
    setMode: (newMode: ECMWorkflowMode) => void;
}) {
    return (
        <ButtonGroup size='sm' className='w-100 mb-2'>
            <Button
                variant={mode === 'manual' ? 'primary' : 'outline-primary'}
                onClick={() => mode !== 'manual' && setMode('manual')}
            >
                Individual Upload
            </Button>
            <Button
                variant={mode === 'batch' ? 'primary' : 'outline-primary'}
                onClick={() => mode !== 'batch' && setMode('batch')}
            >
                Bulk Upload
            </Button>
        </ButtonGroup>
    );
}

export function ECMEventLog({ log, paddingIndex = 0 }: { log: LogModel; paddingIndex?: number }) {
    return (
        <Pane title='Event Log' paddingIndex={paddingIndex} className='ecm-event-log'>
            <LogMessages log={log} />
        </Pane>
    );
}

export function ECMCommonUploadInfo({
    required,
    optional,
    title = 'CSV/XLS Upload',
    className,
}: {
    required: string[];
    optional?: string[];
    title?: string;
    className?: string;
}) {
    return (
        <div className={className ?? 'ecm-info-panel m-auto mt-4'}>
            <h5 className='mt-2'>{title}</h5>

            <dl className='row'>
                <dt className='col-sm-3'>Required columns</dt>
                <dd className='col-sm-9'>
                    <code className='code'>{required.join(', ')}</code>
                </dd>
                {!!optional?.length && (
                    <>
                        <dt className='col-sm-3'>Optional columns</dt>
                        <dd className='col-sm-9'>
                            <code className='code'>{optional.join(', ')}</code>
                        </dd>
                    </>
                )}
            </dl>
        </div>
    );
}

export function ECMCommonUploadControls({
    types = 'CSV/XLS',
    extensions = ['.csv', '.xls', '.xlsx'],
    upload,
    showClear,
    overridesSubject,
}: {
    types?: string;
    extensions?: string[];
    upload: ECMUploadCallback;
    showClear?: boolean;
    overridesSubject?: BehaviorSubject<Record<string, any>>;
}) {
    const fileSubject = useBehaviorSubject<File[] | null>(null);
    const file = useBehavior(fileSubject);
    useEffect(() => {
        upload(file, fileSubject, overridesSubject);
    }, [file, upload]);

    return (
        <div className='hstack gap-2'>
            <div className='flex-grow-1'>
                <SimpleMultiFileUpload
                    fileSubject={fileSubject}
                    label={`Drop/Select one or more ${types} to Upload`}
                    extensions={extensions}
                    inline
                />
            </div>
            {showClear && !!file && (
                <Button variant='outline' onClick={() => fileSubject.next(null)}>
                    Clear File
                </Button>
            )}
        </div>
    );
}

export function ECMMessageList({
    messages,
    kind,
    length = 4,
}: {
    messages: string[];
    kind: 'danger' | 'warning';
    length?: number;
}) {
    const [showAll, setShowAll] = useState(false);

    const visible = showAll ? messages : messages.slice(0, messages.length > length ? length - 1 : length);

    return (
        <>
            {!messages.length && <div className='text-secondary'>None</div>}
            {messages.length > 0 && (
                <ul className={`text-${kind} ecm-message-list`}>
                    {visible.map((e, i) => (
                        <li key={i}>{e}</li>
                    ))}
                </ul>
            )}
            {!showAll && messages.length > length && (
                <Button size='sm' variant='link' className='p-0' onClick={() => setShowAll(true)}>
                    Show all ({messages.length})
                </Button>
            )}
            {showAll && messages.length > length && (
                <Button size='sm' variant='link' className='p-0' onClick={() => setShowAll(false)}>
                    Show less
                </Button>
            )}
        </>
    );
}

export function OpenBatchIcon({
    identifier,
    withQuery,
    className,
}: {
    identifier: string;
    withQuery?: boolean;
    className?: string;
}) {
    const query = withQuery && identifier.includes('-') ? `?batch=${identifier}` : '';
    const href = `/compounds/${identifier.split('-')[0]}${query}`;
    return (
        <Link to={href} target='_blank' rel='noopener noreferrer' className='entos-open-batch-icon'>
            <FontAwesomeIcon
                size='sm'
                className={className}
                color='var(--bs-primary)'
                icon={faArrowUpRightFromSquare}
            />
        </Link>
    );
}

export function BatchLink({
    identifier,
    withQuery,
    extra,
}: {
    identifier?: string;
    withQuery?: boolean;
    extra?: ReactNode;
}) {
    if (!identifier) return <>-</>;

    return (
        <>
            <CompoundIdentifier value={identifier} />
            {extra}
            <OpenBatchIcon identifier={identifier} withQuery={withQuery} className='ms-1' />
        </>
    );
}

export function ECMPlateWellContents({ sample, noBrackets }: { sample?: Sample | SampleCreate; noBrackets?: boolean }) {
    if (!sample) return null;

    const contents: ReactNode[] = [];
    let sepKey = 0;
    const sep = () => (
        <span key={sepKey++} className='text-secondary mx-1'>
            /
        </span>
    );
    if (typeof sample?.solute_mass === 'number' && typeof sample?.solvent_volume !== 'number') {
        contents.push(prefixedUnitValue(sample.solute_mass as number, 'g'));
    }
    if (typeof sample?.solvent_volume === 'number') {
        if (contents.length) contents.push(sep());
        contents.push(prefixedUnitValue(sample.solvent_volume, 'L', { factor: 1e3 }));
    }
    if (typeof sample?.concentration === 'number') {
        if (contents.length) contents.push(sep());
        contents.push(prefixedUnitValue(sample.concentration as number, 'M'));
    }
    if (sample?.solvent) {
        if (contents.length) contents.push(sep());
        contents.push(sample.solvent);
    }
    if (noBrackets) return <>{contents}</>;
    return (
        <>
            <span className='text-secondary mx-1'>[</span>
            {contents}
            <span className='text-secondary mx-1'>]</span>
        </>
    );
}

const theme = getCurrentTheme();
export function colorPlateByConcentrations(
    samples: (Sample | SampleCreate | null | undefined)[],
    options?: { control_kinds?: Plate['control_kinds'] }
) {
    const colors: string[] = [];

    const concentrations = samples
        .filter((s) => !!s && typeof s.concentration === 'number')
        .map((s) => s!.concentration! as number)
        .sort((a, b) => b - a);
    const palette = createPalleteRGB([0x9e, 0xc5, 0xfe], [0xef, 0xad, 0xce], concentrations.length);

    for (const sample of samples) {
        if (sample) {
            if (typeof sample.concentration === 'number') {
                colors.push(toRGBString(palette[concentrations.indexOf(sample.concentration)] ?? [0xef, 0xad, 0xce]));
            } else {
                colors.push('#b4b7b9');
            }
        } else {
            colors.push(theme === 'dark' ? '#121212' : '#f9f9f9');
        }
    }

    return colors;
}

export function labelPlateByControlKinds(control_kinds: Plate['control_kinds'] | undefined) {
    if (!control_kinds) return undefined;

    const color = BaseColors.body;
    const labels: PlateVisualLabels = [];
    for (const kind of control_kinds) {
        if (kind === 'positive') {
            labels.push({ color, text: '+' });
        } else if (kind === 'negative') {
            labels.push({ color, text: '-' });
        } else if (kind === 'reference') {
            labels.push({ color, text: 'Rf' });
        } else if (kind === 'rest') {
            labels.push({ color, text: 'Rst' });
        } else {
            labels.push(null);
        }
    }

    return labels;
}

export function ECMCurrentUser({ variant, title }: { variant?: string; title?: string }) {
    const [state, applyChange] = useAsyncAction();
    const account = useBehavior(AuthService.account);

    return (
        <Button
            onClick={() => applyChange(AuthService.tryLoginPopup('change'))}
            title='Click to change account.'
            variant={variant ?? 'outline-secondary'}
        >
            {state.isLoading && (
                <>
                    <Spinner animation='border' size='sm' role='status' className='me-2' />
                    Changing User ...
                </>
            )}
            {!state.isLoading && (
                <>
                    <FontAwesomeIcon className='me-2' icon={faUserCircle} fixedWidth />
                    {title ?? account?.name ?? account?.username ?? 'Unknown User'}
                </>
            )}
        </Button>
    );
}

export function resolvePlateBarcodesLink(barcodes: string[]) {
    if (barcodes.length === 0) return '/ecm/plates';
    return `/ecm/plates?barcodes=${barcodes.join(',')}`;
}
