import { ReactNode } from 'react';

interface CardProps {
    title?: string;
    selected?: boolean;
    className?: string;
    children?: ReactNode;
}

export function Card({ title = '', selected = false, className = '', children = <></> }: CardProps) {
    return (
        <div className={`entos-card ${selected ? 'card-selected' : ''} ${className} d-flex flex-column`}>
            <div className='entos-card-header px-3 py-1'>
                <h6 className='m-0'>{title}</h6>
            </div>
            <div className='flex-grow-1 d-flex flex-column p-3'>{children}</div>
        </div>
    );
}
