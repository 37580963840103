import { isBlank } from '../../../lib/util/misc';
import { ColumnTableData, DataTableStore, MultiSortColumn } from './base';

export function objectDataTableStore<T, D extends Record<string, any>>(
    columns: { name: keyof T; getter?: (v: D) => any; setter?: (row: D, v: any) => D }[],
    initialRows: D[] = []
): ObjectDataTableStore<T, D> {
    return new ObjectDataTableStore(columns, initialRows);
}

export interface ObjectDataTableColumn<T, D extends Record<string, any>> {
    name: keyof T;
    getter?: (v: D) => any;
    setter?: (row: D, v: any) => D;
}

export class ObjectDataTableStore<T, D extends Record<string, any>> implements DataTableStore<T> {
    private columnGetters = new Map<keyof T, (v: D) => any>();
    private columnSetters = new Map<keyof T, (row: D, v: any) => D>();
    private columns: (keyof T)[] = [];
    private rows: D[] = [];

    version = 0;

    get rowCount() {
        return this.rows.length;
    }

    get columnNames() {
        return this.columns;
    }

    get rawRows() {
        return this.rows;
    }

    getValue<K extends keyof T>(columnName: K, rowIndex: number): T[K] {
        return this.columnGetters.get(columnName as any)!(this.rows[rowIndex]);
    }

    tryGetValue<K extends keyof T>(columnName: K, rowIndex: number): T[K] | undefined {
        if (rowIndex >= this.rowCount) return undefined;
        return this.columnGetters.get(columnName as any)?.(this.rows[rowIndex]);
    }

    setValue(columnName: keyof T, rowIndex: number, value: any): void {
        const row = this.columnSetters.get(columnName)!(this.rows[rowIndex], value);
        this.rows[rowIndex] = row;
        this.version++;
    }

    hasColumn(columnName: keyof T): boolean {
        return this.columnGetters.has(columnName);
    }

    filter(columnName: keyof T, test: (v: any, value: any) => boolean, value: any, indices: number[]): number[] {
        const proj = this.columnGetters.get(columnName)!;
        const ret: number[] = [];
        for (const i of indices) {
            const v = proj(this.rows[i]);
            if (test(v, value)) ret.push(i);
        }
        return ret;
    }

    filterAny(tests: [keyof T, (v: any, value: any) => boolean][], value: any, indices: number[]): number[] {
        const cols = tests.map((c) => [this.columnGetters.get(c[0])!, c[1]] as const);
        const ret: number[] = [];
        for (const i of indices) {
            const x = this.rows[i];
            for (const col of cols) {
                if (col[1](col[0](x), value)) {
                    ret.push(i);
                    break;
                }
            }
        }
        return ret;
    }

    sort(columnName: keyof T, compare: (a: any, b: any) => number, desc: boolean, indices: number[]): void {
        const proj = this.columnGetters.get(columnName)!;
        const sign = desc ? -1 : 1;

        indices.sort((a, b) => {
            // TODO: optimize for larger datasets
            const x = proj(this.rows[a]);
            const y = proj(this.rows[b]);
            const xIsBlank = isBlank(x);
            const yIsBlank = isBlank(y);
            if (xIsBlank && yIsBlank) return 0;
            if (xIsBlank) return 1;
            if (yIsBlank) return -1;
            const cmp = sign * compare(x, y);
            // compare indicies if equal so the sort is stable
            if (!cmp) return a - b;
            return cmp;
        });
    }

    multiSort<K extends keyof T>(columns: MultiSortColumn<T, K>[], indices: number[]): void {
        const count = columns.length;

        if (count === 0) {
            return;
        }
        if (count === 1) {
            this.sort(columns[0][0], columns[0][1], columns[0][2], indices);
            return;
        }

        const projs = columns.map((c) => this.columnGetters.get(c[0])!);
        const compares = columns.map((c) => c[1]);
        const signs = columns.map((c) => (c[2] ? -1 : 1));

        indices.sort((a, b) => {
            for (let i = 0; i < count; i++) {
                const proj = projs[i];
                const compare = compares[i];

                const x = proj(this.rows[a]);
                const y = proj(this.rows[b]);
                const xIsBlank = isBlank(x);
                const yIsBlank = isBlank(y);
                if (xIsBlank && yIsBlank) continue;
                if (xIsBlank) return 1;
                if (yIsBlank) return -1;
                const cmp = signs[i] * compare(x, y);
                if (cmp) return cmp;
            }
            return a - b;
        });
    }

    getRow(rowIndex: number): T {
        const ret = Object.create(null);
        for (const c of this.columns) {
            ret[c] = this.getValue(c, rowIndex);
        }
        return ret;
    }

    getColumnValues(columnName: keyof T, indices?: number[]): readonly any[] {
        const ret: any[] = [];
        const proj = this.columnGetters.get(columnName)!;

        if (indices) {
            for (const i of indices) ret.push(proj(this.rows[i]));
        } else {
            for (let i = 0; i < this.rowCount; i++) ret.push(proj(this.rows[i]));
        }

        return ret;
    }

    findValueIndex(columnName: keyof T, value: any): number {
        const proj = this.columnGetters.get(columnName)!;
        for (let i = 0, _i = this.rowCount; i < _i; i++) {
            if (proj(this.rows[i]) === value) return i;
        }
        return -1;
    }

    insertRow(row: any, index?: number | undefined): void {
        this.rows.splice(index ?? this.rowCount, 0, row);
        this.version++;
    }

    appendRows(rows: D[]): void {
        for (const r of rows) this.rows.push(r);
        this.version++;
    }

    updateRow(row: Record<string, any>, index: number, options?: { type?: 'partial' | 'full' }): void {
        throw new Error('Not supported');
    }

    deleteRow(index: number): void {
        this.rows.splice(index, 1);
        this.version++;
    }

    addOrUpdateColumn(columnName: keyof T, values: ArrayLike<any>): void {
        throw new Error('Not supported');
    }

    addColumn(column: ObjectDataTableColumn<T, D>) {
        this.columns.push(column.name);
        const n = column.name;
        this.columnGetters.set(column.name, column.getter ?? ((v) => (v as any)[n]));
        if (column.setter) this.columnSetters.set(column.name, column.setter);
    }

    removeColumn(columnName: keyof T): void {
        this.columns = this.columns.filter((c) => c !== columnName);
        this.columnGetters.delete(columnName);
        this.columnSetters.delete(columnName);
        this.version++;
    }

    toColumnTableData(): ColumnTableData<T, number, keyof T> {
        throw new Error('Not supported');
    }

    filterIndices(filter: (table: DataTableStore<T>, index: number) => boolean): number[] {
        const ret: number[] = [];
        for (let rowIdx = 0; rowIdx < this.rowCount; rowIdx++) {
            if (filter(this, rowIdx)) {
                ret.push(rowIdx);
            }
        }
        return ret;
    }

    toObjects(options?: {
        indices?: ArrayLike<number>;
        filter?: (table: DataTableStore<T>, index: number) => boolean;
        columns?: (keyof T)[];
    }): T[] {
        throw new Error('Not supported');
    }

    setRows(rows: any[]): void {
        this.rows = rows;
        this.version++;
    }

    clearColumns() {
        this.columnGetters.clear();
        this.columns = [];
    }

    clear() {
        this.rows = [];
        this.version++;
    }

    getSnapshot(): any {
        return this.rows.map((r) => ({ ...r }));
    }

    setSnapshot(data: D[]) {
        this.rows = data.map((r) => ({ ...r }));
        this.version++;
    }

    constructor(columns: ObjectDataTableColumn<T, D>[], initialRows: D[] = []) {
        for (const c of columns) {
            this.addColumn(c);
        }
        this.rows = initialRows;
    }
}
