import { BehaviorSubject } from 'rxjs';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { HTERInstructionT, HTEPProductWell, HTEDReaction, HTEDesign } from '../data-model';
import { type HTEWModel } from '../model';
import { HTEDSettingsModel } from './settings';
import { HTEDProductBlocksModel } from './product-blocks';
import { HTEReactionModel, emptyReaction } from './reactions';
import { ReactionInfo } from '../utils';

export type HTEDesignTab = 'settings' | 'product-blocks' | 'reactions';

export interface CurrentInstruction {
    reaction_id: string;
    instruction: HTERInstructionT;
}

export class HTEDesignModel extends ReactiveModel {
    readonly isSnapshot: boolean;
    state = {
        tab: new BehaviorSubject<HTEDesignTab>('reactions'),
        currentInstruction: new BehaviorSubject<CurrentInstruction | undefined>(undefined),
    };
    reactions = new BehaviorSubject<HTEReactionModel[]>([]);
    productBlocks = new HTEDProductBlocksModel(this);
    settings: HTEDSettingsModel;

    findReaction(id?: string) {
        if (!id) return undefined;
        return this.reactions.value.find((r) => r.reaction.id === id);
    }

    getDesign(): HTEDesign {
        return {
            reactions: this.reactions.value.map(
                (r) =>
                    ({
                        id: r.reaction.id,
                        name: r.reaction.name,
                        product_block_id: r.reaction.product_block_id,
                        template: r.reaction.template,
                    } satisfies HTEDReaction)
            ),
            product_blocks: this.productBlocks.state.blocks.value.map((b) => b.toBlock()),
            global_conditions: this.settings.state.globalConditions.value,
            layout_algorithm: this.settings.state.layoutAlgorithm.value,
        };
    }

    addReaction() {
        const current = this.reactions.value;
        const name = `Reaction ${current.length + 1}`;
        const product_block_id = this.productBlocks.state.blocks.value[0]?.id;
        const reaction = emptyReaction({ name, product_block_id });
        this.reactions.next([...current, new HTEReactionModel(this, { reaction })]);
    }

    removeReaction(r: HTEReactionModel) {
        this.reactions.next(this.reactions.value.filter((v) => v !== r));
    }

    clearReactions() {
        this.reactions.next([]);
    }

    getReaction(well: HTEPProductWell, wellLabel: string): ReactionInfo | undefined {
        const reaction = this.reactions.value.find((r) => r.reaction.id === well.reaction_id);
        if (!reaction) return undefined;
        const block = this.productBlocks.findBlock(reaction.reaction.product_block_id);
        if (!block) return undefined;
        const { enumeration } = block;
        const mainReactants = enumeration?.enumeration.reactant_names ?? [];
        const { reactantNames } = reaction;
        const reagents = reactantNames.filter((r) => !mainReactants.includes(r));

        const { entities } = this.model.assets;

        const mainSmiles = mainReactants
            .map((r) => entities.getStructure(well.samples[r]?.identifier ?? '')!)
            .filter((r) => r)
            .join('.');
        const reagentsSmiles = reagents
            .map((r) => entities.getStructure(well.samples[r]?.identifier ?? '')!)
            .filter((r) => r)
            .join('.');
        const productSmiles = entities.getStructure(well.product?.identifier ?? '') ?? '';

        const smiles = `${mainSmiles || '.'}>${reagentsSmiles}>${productSmiles || '.'}`;

        return {
            wellLabel,
            well,
            reaction: reaction.reaction,
            smiles,
            mainReactants,
            reagents,
        };
    }

    constructor(
        public model: HTEWModel,
        {
            design,
            defaultTab,
            readOnly,
            isSnapshot,
        }: {
            design: HTEDesign;
            defaultTab?: HTEDesignTab;
            readOnly?: boolean;
            isSnapshot?: boolean;
        }
    ) {
        super();

        this.isSnapshot = !!isSnapshot;
        this.settings = new HTEDSettingsModel(this, { initialDesign: design });
        const reactions = design.reactions.map((rxn) => new HTEReactionModel(this, { reaction: rxn }));
        this.reactions.next(reactions);
        this.productBlocks = new HTEDProductBlocksModel(this, { initial: design.product_blocks });

        if (defaultTab) {
            this.state.tab.next(defaultTab);
        }
    }
}
