import {
    faAnglesUp,
    faEraser,
    faExclamationCircle,
    faFileExport,
    faSmileWink,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import saveAs from 'file-saver';
import { Button, Dropdown, Spinner } from 'react-bootstrap';
import { BehaviorSubject, throttleTime } from 'rxjs';
import { AsyncMoleculeDrawing } from '../../../components/common/AsyncMoleculeDrawing';
import { IconButton, IconDropdownButton } from '../../../components/common/IconButton';
import { TextInput } from '../../../components/common/Inputs';
import { TooltipWrapper } from '../../../components/common/Tooltips';
import useBehavior from '../../../lib/hooks/useBehavior';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { ClipboardService } from '../../../lib/services/clipboard';
import { DialogService } from '../../../lib/services/dialog';
import { ToastService } from '../../../lib/services/toast';
import { arrayToCsv } from '../../../lib/util/arrayToCsv';
import { tryGetErrorMessage } from '../../../lib/util/errors';
import { ModelAction, ReactiveModel, useModelAction } from '../../../lib/util/reactive-model';
import { CompoundDetail } from '../../Compounds/compound-api';
import { ECMApi } from '../../ECM/ecm-api';
import { BatchLink } from '../../ECM/ecm-common';
import { CompoundOrSubstanceResult, HTE2MSApi } from '../api';
import { HTERAddReactant } from '../data-model';
import { ProductSampleUI } from '../utils';
import type { HTE2MSDesignModel } from './model';
import { ChemDrawModalService } from '../../../lib/services/chemdraw-modal';
import { iiChemDrawLogo } from '../../../components/common/Icons';

export class HTE2MSDesignProductModel {
    setSubstance(substance_id: number) {
        const reaction = this.design.state.reaction.value.reactions[0];
        if (!reaction?.product_enumeration) return;

        this.design.modifyReactions(
            [
                [
                    reaction,
                    {
                        ...reaction,
                        product_enumeration: {
                            ...reaction.product_enumeration,
                            substance_id,
                        },
                    },
                ],
            ],
            { cosmetic: true }
        );
    }

    setProductIdentifier(product_identifier: string) {
        const reaction = this.design.state.reaction.value.reactions[0];
        if (!reaction) return;

        return this.design.modifyReactions(
            [
                [
                    reaction,
                    {
                        ...reaction,
                        product_identifier,
                    },
                ],
            ],
            { cosmetic: true }
        );
    }

    get nAssignedProductIdentifiers() {
        return this.design.state.reaction.value.reactions.filter((r) => r.product_identifier).length;
    }

    get nProductsToRegister() {
        return this.design.state.reaction.value.reactions.filter((r) => !r.product_identifier).length;
    }

    confirmClearProductIdentifier = () => {
        const count = this.nAssignedProductIdentifiers;
        DialogService.open({
            type: 'confirm',
            onConfirm: this.clearProductIdentifier,
            title: 'Clear Product Identifier',
            text: `Are you sure you want to clear the assigned Compound Identifier from ${count} reaction${
                count > 1 ? 's' : ''
            }? This will revert the reaction to enumeration mode.`,
            confirmText: 'Clear',
        });
    };

    private clearProductIdentifier = () => {
        this.design.modifyReactions(
            this.design.state.reaction.value.reactions.map((r) => [
                r,
                {
                    ...r,
                    product_identifier: undefined,
                },
            ]),
            { cosmetic: true }
        );
    };

    setCustomProduct = () => {
        const model = new AssignCustomProductModel(this);
        DialogService.open({
            type: 'generic',
            title: 'Assign Custom Product',
            confirmButtonContent: 'Apply',
            model,
            wrapOk: true,
            content: AssignCustomProductDialogContent,
            onOk: () => model.assign(),
        });
    };

    confirmRegisterProducts = () => {
        DialogService.open({
            type: 'generic',
            title: 'Register Products',
            confirmButtonContent: 'Register',
            model: this,
            wrapOk: true,
            content: RegisterProductsDialogContent,
            onOk: () => this.registerProducts(),
        });
    };

    exportProducts(what: 'simple' | 'rich', how: 'copy' | 'save') {
        const rows: string[][] = [];
        rows.push(['Product', 'MSD', 'BB', 'Reaction Chemistry']);
        const identifiers: string[] = [];

        let skipped = 0;
        for (const r of this.design.state.reaction.value.reactions) {
            if (!r.product_identifier) {
                skipped++;
                continue;
            }
            const msd = r.template.instructions.find((i) => i.kind === 'add' && i.reactant_kind === 'msd') as
                | HTERAddReactant
                | undefined;
            const bb = r.template.instructions.find((i) => i.kind === 'add' && i.reactant_kind === 'bb') as
                | HTERAddReactant
                | undefined;

            identifiers.push(r.product_identifier);
            rows.push([
                r.product_identifier,
                msd?.identifier || '',
                bb?.identifier || '',
                r.template.reaction_chemistry || '',
            ]);
        }

        if (rows.length === 1) {
            return ToastService.info('No registered products to copy');
        }

        if (skipped) {
            ToastService.warning(`${skipped} selected reaction(s) have no product registered`);
        }

        const ret = what === 'rich' ? arrayToCsv(rows) : identifiers.join('\n');

        if (how === 'copy') {
            ClipboardService.copyText(ret, 'Copy Products');
        } else {
            saveAs(new Blob([ret], { type: 'text/csv' }), `${this.design.model.libraryId}-products.csv`);
        }
    }

    async registerProducts() {
        const reactions = this.design.state.reaction.value.reactions;
        const toRegister = reactions.filter((r) => !r.product_identifier);
        if (!toRegister.length) throw new Error('Nothing to register');

        const smiles = toRegister.map((r) => this.design.model.assets.getStructure(r));
        if (smiles.some((s) => !s)) throw new Error('Some reactions do not have a product assigned');

        const projects = new Set(toRegister.map((r) => r.project || undefined));
        if (projects.has(undefined)) throw new Error('All reactions must have a project assigned');

        if (projects.size > 1)
            throw new Error(
                'All reactions must have the same project assigned. To register products from different projects, use Filters to resiter them separately.'
            );

        const project = Array.from(projects)[0]!;

        let registered: CompoundDetail[];
        try {
            registered = await ECMApi.registerCompounds({ smiles: smiles as string[], project });
        } catch (err) {
            throw new Error(
                `Failed to register products. This might be caused by a server timeout, please try again later. Base error: ${tryGetErrorMessage(
                    err
                )}`
            );
        }

        for (const c of registered) this.design.model.assets.entities.add(c);
        await this.design.modifyReactions(
            toRegister.map((r, idx) => [
                r,
                {
                    ...r,
                    product_identifier: registered[idx].universal_identifier,
                },
            ]),
            { cosmetic: true }
        );

        ToastService.show({
            type: 'success',
            message: 'Products registered',
            timeoutMs: 2500,
        });
    }

    constructor(public design: HTE2MSDesignModel) {}
}

class AssignCustomProductModel extends ReactiveModel {
    state = {
        input: new BehaviorSubject<string>(''),
    };
    current = new ModelAction<CompoundOrSubstanceResult | undefined>({ onError: 'state' });

    private async query(input: string) {
        if (!input) return undefined;

        const result = await HTE2MSApi.queryCompoundOrRegisterSubstance(input);
        if (result.type === 'compound') this.product.design.model.assets.entities.add(result.data);
        else this.product.design.model.assets.entities.substancesById.set(result.data.id!, result.data);
        return result;
    }

    private update = (input: string) => {
        this.current.run(this.query(input));
    };

    // This needs to be async for dialog error catching to work
    async assign() {
        const state = this.current.state.value;
        // NOTE: This is a shortcut to avoid having to write custom footer for the dialog
        // TODO: Improve if needed
        if (state.kind === 'loading') throw new Error('Please wait until the compound is loaded');
        if (state.kind !== 'result' || !state.result) throw new Error('Enter valid input');

        if (state.result.type === 'compound') {
            this.product.setProductIdentifier(state.result.data.universal_identifier!);
        } else {
            this.product.setSubstance(state.result.data.id!);
        }
    }

    chemDraw = () => {
        const reaction = this.product.design.state.reaction.value.reactions[0];
        if (!reaction) return;

        let initialSMILES;

        if (reaction.product_enumeration?.substance_id) {
            initialSMILES = this.product.design.model.assets.entities.substancesById.get(
                reaction.product_enumeration?.substance_id
            )?.smiles;
        } else {
            const msd = reaction.template.instructions.find((i) => i.kind === 'add' && i.reactant_kind === 'msd') as
                | HTERAddReactant
                | undefined;
            const bb = reaction.template.instructions.find((i) => i.kind === 'add' && i.reactant_kind === 'bb') as
                | HTERAddReactant
                | undefined;

            const a = this.product.design.model.assets.entities.getStructure(msd?.identifier!);
            const b = this.product.design.model.assets.entities.getStructure(bb?.identifier!);

            initialSMILES = [a, b].filter((s) => !!s).join('.');
        }

        ChemDrawModalService.show({
            initialSMILES,
            onSMILESDrawn: (smiles) => this.state.input.next(smiles),
        });
    };

    mount() {
        this.subscribe(
            this.state.input.pipe(throttleTime(250, undefined, { leading: false, trailing: true })),
            this.update
        );
    }

    constructor(public product: HTE2MSDesignProductModel) {
        super();
    }
}

export function HTE2MSProductUI({ model }: { model: HTE2MSDesignModel }) {
    const reactions = useBehavior(model.state.reaction);

    if (reactions.reactions.length !== 1) {
        return (
            <div className='p-2 d-flex align-items-center justify-content-center text-center w-100 h-100'>
                <span className='text-secondary font-body-small'>
                    Select a single reaction (double-click a row)
                    <br />
                    to view or adjust product
                </span>
            </div>
        );
    }

    const r = reactions.reactions[0];
    const smiles = model.model.assets.getStructure(r);
    const sample = model.validations.get(r.id)?.[1];

    const drawing = !smiles ? (
        <div className='p-2 text-center vstack gap-2'>
            <span className='text-warning font-body-small'>Product not available</span>
            {!r.template.reaction_chemistry && (
                <span className='text-warning font-body-small'>Reaction chemistry not assigned</span>
            )}
            {!r.product_identifier && typeof r.product_enumeration?.substance_id !== 'number' && (
                <span className='text-warning font-body-small'>Product not assigned</span>
            )}
            {r.product_identifier && <span className='text-warning font-body-small'>Product not found</span>}
        </div>
    ) : (
        <AsyncMoleculeDrawing
            autosize
            smiles={smiles}
            showChemDraw={false}
            drawer={model.model.drawer}
            asBackground
            width='100%'
            height='100%'
        />
    );

    const sampleUI = sample ? (
        <ProductSampleUI sample={sample} inline wellVolume={model.labware.product.volume} />
    ) : (
        <div className='p-2'>
            <i className='text-warning font-body-small'>Sample not available</i>
        </div>
    );

    let enumeration;
    if (!r.product_identifier && r.product_enumeration) {
        enumeration = r.product_enumeration
            .substance_ids!.map((id) => model.model.assets.entities.substancesById.get(id))
            .filter((s) => s)
            .map((s, idx) => (
                <Button
                    variant={r.product_enumeration?.substance_id === s!.id ? 'outline-primary' : 'link'}
                    key={idx}
                    title='Click to select this product'
                    className='ms-2 position-relative p-1'
                    onClick={() => model.product.setSubstance(s!.id!)}
                >
                    <AsyncMoleculeDrawing
                        autosize
                        smiles={s!.smiles}
                        showChemDraw={false}
                        showCopy={false}
                        drawer={model.model.drawer}
                        width='95%'
                        height='95%'
                    />
                </Button>
            ));
    }

    const isCustomEnumeration =
        typeof r.product_enumeration?.substance_id === 'number' &&
        !r.product_enumeration?.substance_ids?.includes(r.product_enumeration?.substance_id!);

    let productInfo;
    if (r.product_identifier) {
        productInfo = (
            <div className='hstack gap-2 m-2 font-body-small'>
                <BatchLink identifier={model.model.assets.entities.getIdentifier(r.product_identifier)} />
                <div className='m-auto' />
                <IconButton
                    icon={faEraser}
                    size='sm'
                    className='py-0'
                    title='Clear identifier and go back to enumeration mode'
                    onClick={model.product.confirmClearProductIdentifier}
                    disabled={model.model.readOnlyDesignAndProduction}
                />
            </div>
        );
    }

    return (
        <div className='d-flex h-100 flex-column'>
            <div className='flex-shrink-0 font-body-small p-2 d-flex justify-content-center'>{sampleUI}</div>
            <div className='position-relative flex-grow-1'>{drawing}</div>
            {enumeration && (
                <div className='hstack mx-2 gap-2 font-body-small'>
                    <span className='text-secondary'>Enumeration</span>
                    {!!r.product_enumeration?.errors?.length && (
                        <span className='text-danger'>
                            <TooltipWrapper
                                tooltip={
                                    <span style={{ whiteSpace: 'pre' }}>
                                        {r.product_enumeration?.errors?.join('\n')}
                                    </span>
                                }
                            >
                                {(props) => (
                                    <span {...props}>
                                        <FontAwesomeIcon icon={faExclamationCircle} size='sm' className='me-1' />
                                        {r.product_enumeration?.errors?.length}
                                    </span>
                                )}
                            </TooltipWrapper>
                        </span>
                    )}
                </div>
            )}
            {enumeration && (
                <div
                    className='flex-shrink-0 d-flex m-2 mt-1 ms-0 position-relative'
                    style={{ minHeight: 60, maxHeight: 60 }}
                >
                    {enumeration}
                    <Button
                        variant={isCustomEnumeration ? 'outline-primary' : 'link'}
                        title='Assign Custom product'
                        className='ms-2 position-relative d-flex justify-content-center font-body-small flex-column'
                        onClick={model.product.setCustomProduct}
                    >
                        <FontAwesomeIcon icon={faSmileWink} />
                        <div className='mt-1'>Custom</div>
                    </Button>
                </div>
            )}
            {productInfo}
        </div>
    );
}

export function HTE2MSProductActions({ model }: { model: HTE2MSDesignModel }) {
    useBehavior(model.state.reaction);

    return (
        <div className='hstack gap-2'>
            <IconButton
                icon={faEraser}
                size='sm'
                className='py-0'
                title='Clear identifiers and go back to enumeration mode'
                onClick={model.product.confirmClearProductIdentifier}
                disabled={model.model.readOnlyDesignAndProduction || !model.product.nAssignedProductIdentifiers}
            />
            <IconDropdownButton
                icon={faFileExport}
                label='Export'
                size='sm'
                variant='link'
                title='Export registered products'
            >
                <Dropdown.Item onClick={() => model.product.exportProducts('simple', 'copy')}>
                    Copy Product Identifiers
                </Dropdown.Item>
                <Dropdown.Item onClick={() => model.product.exportProducts('rich', 'copy')}>
                    Copy Products + MSD/BB/Chem
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => model.product.exportProducts('rich', 'save')}>
                    Save Products + MSD/BB/Chem (.csv)
                </Dropdown.Item>
                <Dropdown.Item onClick={() => model.product.exportProducts('simple', 'copy')}>
                    Save Product Identifiers (.csv)
                </Dropdown.Item>
            </IconDropdownButton>
            <IconButton
                icon={faAnglesUp}
                disabled={model.product.nProductsToRegister === 0 || model.model.readOnlyDesignAndProduction}
                onClick={model.product.confirmRegisterProducts}
                variant='link'
            >
                Register
            </IconButton>
        </div>
    );
}

function AssignCustomProductDialogContent({ model }: { model: AssignCustomProductModel }) {
    useMountedModel(model);
    const input = useBehavior(model.state.input);
    const current = useModelAction(model.current);

    let smiles: string | undefined;
    if (current.kind === 'result' && current.result) {
        smiles = current.result.type === 'compound' ? current.result.data.structure.smiles : current.result.data.smiles;
    }

    return (
        <div className='vstack gap-2'>
            <div className='d-flex align-items-center justify-content-center position-relative' style={{ height: 140 }}>
                {current.kind === 'loading' && <Spinner animation='border' />}
                {current.kind === 'error' && <i className='text-danger'>{tryGetErrorMessage(current.error)}</i>}
                {current.kind === 'result' && smiles && (
                    <AsyncMoleculeDrawing
                        autosize
                        smiles={smiles}
                        showChemDraw={false}
                        drawer={model.product.design.model.drawer}
                        width='50%'
                        height='100%'
                        asBackground
                    />
                )}
                {current.kind === 'result' && current.result && !smiles && (
                    <i className='text-warning'>SMILES not available</i>
                )}
                {current.kind === 'result' && !current.result && (
                    <i className='text-warning'>Enter (or draw) SMILES or Compound Identifier...</i>
                )}
            </div>
            <div className='hstack gap-2'>
                <TextInput
                    placeholder='SMILES or Compound Identifier...'
                    autoFocus
                    value={input}
                    immediate
                    setValue={(v) => model.state.input.next(v)}
                    className='flex-grow-1'
                />
                <IconButton icon={iiChemDrawLogo} onClick={model.chemDraw} />
            </div>
        </div>
    );
}

function RegisterProductsDialogContent({ model }: { model: HTE2MSDesignProductModel }) {
    const N = model.nProductsToRegister;
    return (
        <p>
            Do you want to register {N} product{N > 1 ? 's' : ''}?
        </p>
    );
}
