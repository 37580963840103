import { BehaviorSubject } from 'rxjs';
import { type HTEDesignModel } from '.';
import { HTEDGlobalConditions, HTEDLayoutAlgorithmT, HTEDesign } from '../data-model';

export class HTEDSettingsModel {
    state = {
        globalConditions: new BehaviorSubject<HTEDGlobalConditions>(undefined as any),
        layoutAlgorithm: new BehaviorSubject<HTEDLayoutAlgorithmT>(undefined as any),
    };

    constructor(public design: HTEDesignModel, options: { initialDesign: HTEDesign }) {
        this.state.globalConditions.next(options.initialDesign.global_conditions);
        this.state.layoutAlgorithm.next(options.initialDesign.layout_algorithm);
    }
}
