import axios from 'axios';
import log from 'loglevel';
import { Subject } from 'rxjs';
import { columnDataTableStore, ColumnTableData, DataTableStore } from '../components/DataTable';
import ReactTableSchema from '../components/ReactTable/schema';
import { ReactTableModel } from '../components/ReactTable/model';
import { NotAuthorizedError, tryParseAxiosError } from '../lib/util/errors';
import { decodeEntosMsgpack, DecodeOptions } from '../lib/util/serialization';
import { type FoundryString } from '../pages/Dataviz/state/data-model';
import { type BoxIdentifier } from '../pages/Boxes/box-api';
import {
    Dataset,
    DatasetKind,
    DatasetPermission,
    FoundryEnvironmentInfo,
    FoundryUser,
    FoundryUserWithGroups,
    UserOrGroupDisplayModel,
} from './data';

// NOTE: for tests to work on Mac, the dev url needs to an IP, not localhost as that resolves to
//       IPv6 ::1, which doesn't seem currently to work with Node.js https://stackoverflow.com/a/58242900
const serverURL = process.env.NODE_ENV === 'production' ? '' : 'http://127.0.0.1:9000';

export function createClient(baseURL: string) {
    const ret = axios.create({ baseURL });

    ret.interceptors.request.use(
        async (config) => {
            if (config.headers.get('No-Authorization')) {
                config.headers.delete('No-Authorization');
                return config;
            }

            if (!config.headers.Authorization) {
                const token = auth.tryAcquireToken ? await auth.tryAcquireToken() : undefined;
                if (token) {
                    config.headers.set('Authorization', `Bearer ${token}`);
                    return config;
                }
            }

            return config;
        },
        (error) => {
            log.error(error);
            return Promise.reject(error);
        }
    );

    ret.interceptors.response.use(
        (response) => {
            if (response.status === 200) {
                return response;
            }
            log.warn(response.status, response.statusText);
            return response;
        },
        (error) => {
            const message = tryParseAxiosError(error);
            const isExpiredToken =
                message.includes('Token is expired') || error.message?.includes?.('Token is expired');
            if (error.response?.status === 401 || isExpiredToken) {
                auth.unauthorized.next({});
                return Promise.reject(new NotAuthorizedError(message, isExpiredToken));
            }
            return Promise.reject(message);
        }
    );

    return ret;
}

const client = createClient(`${serverURL}/api/`);

if (window.location.hostname === 'localhost') {
    (window as any).testAuthOverlay = () => {
        client.get('utils/raise401');
    };
}

const getMsgpack = async (url: string, options?: { decode?: DecodeOptions; params?: Record<string, any> }) => {
    const { data: responseData } = await client.get(url, { responseType: 'arraybuffer', params: options?.params });
    return decodeEntosMsgpack(responseData, options?.decode);
};
const postMsgpack = async (
    url: string,
    data: any,
    options?: { decode?: DecodeOptions; params?: Record<string, any> }
) => {
    const { data: responseData } = await client.post(url, data, {
        responseType: 'arraybuffer',
        params: options?.params,
    });
    return decodeEntosMsgpack(responseData, options?.decode);
};
const postMsgpackForm = async (
    url: string,
    data: Record<string, any>,
    options?: { files: Record<string, File | File[] | undefined>; decode?: DecodeOptions; params?: Record<string, any> }
) => {
    const formData = new FormData();

    for (const [key, value] of Array.from(Object.entries(options?.files ?? {}))) {
        if (Array.isArray(value)) {
            for (const v of value) {
                formData.append(key, v);
            }
        } else if (value) {
            formData.append(key, value);
        }
    }
    for (const [key, value] of Array.from(Object.entries(data))) {
        if (value !== null && value !== undefined) {
            formData.append(key, JSON.stringify(value));
        }
    }

    const { data: responseData } = await client.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        responseType: 'arraybuffer',
        params: options?.params,
    });
    return decodeEntosMsgpack(responseData, options?.decode);
};

const auth = {
    testToken: async (token: string | null): Promise<boolean> => {
        const { data } = await client.get('auth/test-token', {
            headers: {
                Authorization: token ? `Bearer ${token}` : undefined,
            },
        });
        return data;
    },
    info: async (): Promise<{ client_id: string; tenant_id: string }> => {
        const { data } = await client.get('auth/info', { headers: { 'No-Authorization': true } });
        return data;
    },
    me: (): Promise<FoundryUserWithGroups> => getMsgpack('auth/me'),
    unauthorized: new Subject<{}>(),
    tryAcquireToken: undefined as (() => Promise<string | undefined>) | undefined,
};

const links = {
    create: async (json: any): Promise<string> => {
        const { data } = await client.post('/links', { json });
        return data;
    },
    get: async (id: string) => {
        const { data } = await client.get(`/links/${id}`);
        const state = data.json;
        // Dates saved in links load as ISO strings and need to be converted back to Date objects
        if (state.source.query && state.source.query.date) {
            state.source.query.date = state.source.query.date.map((d: string) => new Date(d));
        }
        return state;
    },
};

const datasets = {
    list: async (kind?: DatasetKind): Promise<DataTableStore<Dataset>> => {
        const query = kind ? `?kind=${kind}` : '';
        const data = await getMsgpack(`datasets${query}`);
        return columnDataTableStore(data);
    },
    get: async (id: number): Promise<Dataset> => {
        const { data } = await client.get(`datasets/${id}`);
        return data;
    },
    getBoxes: async (id: number) => {
        const { data } = await client.get(`datasets/${id}/boxes`);
        return data;
    },
    getPermissions: async (id: number): Promise<ReactTableModel<DatasetPermission>> => {
        const data = await getMsgpack(`datasets/${id}/permissions`);
        return new ReactTableModel(data, DatasetPermission);
    },
    setPermissions: async (id: number, updated: DatasetPermission, addPermission: boolean) => {
        const { data } = await client.post(`datasets/${id}/permissions`, { ...updated, addPermission });
        return data;
    },
    setVisibility: async (id: number, makePrivate: boolean) => {
        const { data } = await client.post(`datasets/${id}/visibility`, { makePrivate });
        return data;
    },
    compute: async (id: number, boxIds: number[]): Promise<any> => {
        const boxResults = await Promise.all(boxIds.map((boxId) => client.get(`boxes/${boxId}`)));
        const boxes: BoxIdentifier[] = boxResults.map((r) => r.data.identifier);
        const { data } = await client.post(`datasets/${id}/compute`, { boxes });
        return data;
    },
};

const compute = {
    get socketEndpoint() {
        const baseUrl = `${process.env.NODE_ENV === 'production' ? window.location.host : 'localhost:9000'}`;
        const protocol = window.location.protocol === 'http:' ? 'ws://' : 'wss://';
        return `${protocol}${baseUrl}/api/compute/socket`;
    },
};

export interface DescriptorAndSubstanceInfo {
    descriptors: Record<string, ['str' | 'num' | 'bool', string | null]>;
    substance_info: Record<string, 'str' | 'num' | 'bool'>;
}

const descriptors = {
    list: async (): Promise<DescriptorAndSubstanceInfo> => {
        const { data } = await client.get('compute/descriptors_and_substance_info');
        return data;
    },
    compute: async (input: {
        smiles: string[];
        descriptors: string[];
    }): Promise<ReactTableModel<Record<string, number | string>>> => {
        const data = await postMsgpack('compute/descriptors', input);
        return new ReactTableModel(data);
    },
};

const permissions = {
    users: async (): Promise<FoundryUser[]> => {
        const { data } = await client.get('permissions/users');
        return data;
    },
};

const utils = {
    // TODO: refactor this to use the new Data Table models
    parseTable: async <T extends object>(
        file: File,
        options?: { schema?: ReactTableSchema.For<T>; lowerCaseColumns?: boolean }
    ): Promise<DataTableStore<T>> => {
        const formData = new FormData();
        formData.append('file', file);
        const { data } = await client.post('utils/parse-table', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            responseType: 'arraybuffer',
        });
        const parsed = decodeEntosMsgpack(new Uint8Array(data)) as ColumnTableData;
        if (options?.lowerCaseColumns) {
            parsed.columns = parsed.columns.map((c) => (typeof c === 'string' ? c.toLowerCase() : c));
        }
        return columnDataTableStore(parsed);
    },
    getEnvironmentInfo: async (): Promise<FoundryEnvironmentInfo> => {
        const { data } = await client.get('utils/environment-info');
        return data;
    },
    getArtifactDownloadLink: async (options: { name: string; folder_name: string; version?: number }) => {
        const { data } = await client.post('utils/artifact-link', options);
        return data;
    },
    listUsersAndGroups: async (): Promise<UserOrGroupDisplayModel[]> => {
        const { data } = await client.get('utils/users_and_groups');
        return data;
    },
    kekulizeSmiles: async (smiles: string): Promise<string> => {
        const { data } = await client.post('utils/kekulize-smiles', smiles);
        return data;
    },
    getOrCreateFoundryStrings: async (foundryStrings: FoundryString[]): Promise<FoundryString[]> => {
        const { data } = await client.post('utils/get-foundry-strings', foundryStrings);
        return data;
    },
    queryFoundryStrings: async (ids: number[]): Promise<FoundryString[]> => {
        const { data } = await client.post('utils/query-foundry-strings', ids);
        return data;
    },
    getKVMsgPack: async <T = any>(key: string): Promise<T> => getMsgpack('utils/kv-get', { params: { key } }),
};

export default {
    serverURL,
    client,
    getMsgpack,
    postMsgpack,
    postMsgpackForm,
    get accessToken() {
        return localStorage.getItem('ad_access_token')!;
    },
    auth,
    links,
    datasets,
    compute,
    descriptors,
    permissions,
    utils,
};
