import { faFlagCheckered, faPenRuler, faRobot, faSave } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Badge, Button, ButtonGroup, Dropdown, Form, Spinner } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { Page, PageTemplate } from '../../components/Layout/Layout';
import { InlineAlert } from '../../components/common/Alert';
import { AsyncActionButton } from '../../components/common/AsyncButton';
import { ErrorMessage } from '../../components/common/Error';
import { IconButton, ThreeDotsMenu } from '../../components/common/IconButton';
import { LabeledInput, TextInput } from '../../components/common/Inputs';
import Loading from '../../components/common/Loading';
import { PillNavStep } from '../../components/common/Nav';
import { ProjectSelect } from '../../components/common/ProjectSelect';
import { AsyncState, useAsyncAction } from '../../lib/hooks/useAsyncAction';
import useBehavior from '../../lib/hooks/useBehavior';
import useMountedModel from '../../lib/hooks/useMountedModel';
import { DialogService } from '../../lib/services/dialog';
import { formatDatetime } from '../../lib/util/dates';
import { HTE2MicroscaleLibraryOptions } from './data-model';
import { HTE2MSDesignUI } from './design/ui';
import { HTE2MSInventoryUI } from './inventory/ui';
import { DraftLibraryInfo, HTE2MSModel, HTE2MSTab } from './model';
import { HTE2MSCrudePlateUI } from './production/ui';
import { HTE2MSProtocolUI, HTE2MSReagentsUI } from './protocol/ui';
import { WorkflowStatus, WorkflowStatusIndicator } from './utils/workflow-status';
import { HTE2MSPostProductionUI } from './post-production/ui';
import { UsesMarkdown } from './utils';
import { ScrollBox } from '../../components/common/ScrollBox';
import { HTE2MSFinalizeUI } from './finalize/ui';
import { HTE2MSPromotionUI } from './promotion/ui';
import { HTE2MSTransferModeUI } from './inventory/transfer-mode';

async function createModel({ draftId }: { draftId?: string } = {}) {
    const model = new HTE2MSModel();
    if (draftId) {
        await model.initDraft(draftId);
    } else {
        await model.initNew();
    }
    return model;
}

export function HTE2MSRoot({ draft }: { draft?: boolean }) {
    const { id } = useParams();
    const [model, applyModel] = useAsyncAction<HTE2MSModel>();
    useEffect(() => {
        applyModel(createModel(draft ? { draftId: id } : undefined));
    }, [draft, id]);

    return <HTE2MSUI _model={model} />;
}

const uScaleBreadcrumb = { href: '', title: 'μScale 2.0' };

export function HTE2MSUI({ _model }: { _model: AsyncState<HTE2MSModel> }) {
    const model = useMountedModel(_model.result);
    useEffect(() => {
        if (!model || model.transferMode) return;

        const sub = model?.state.info
            .pipe(
                distinctUntilChanged((a, b) => {
                    if (a.kind !== b.kind) return false;
                    if (a.kind === 'foundry' && b.kind === 'foundry') return a.experiment.id === b.experiment.id;
                    if (a.kind === 'draft' && b.kind === 'draft') return a.id === b.id;
                    return true;
                })
            )
            .subscribe((info) => {
                if (info.kind === 'foundry') {
                    window.history.replaceState({}, '', `/hte/${info.experiment.id}/design`);
                } else if (info.kind === 'draft') {
                    window.history.replaceState({}, '', `/hte/uscale/draft/${info.id}`);
                } else {
                    window.history.replaceState({}, '', `/hte/uscale`);
                }
            });
        return () => sub?.unsubscribe();
    }, [model]);

    let breadcrumb: Page[];
    if (_model.isLoading || !model) {
        breadcrumb = [uScaleBreadcrumb, { href: window.location.pathname, title: 'Loading...' }];
    } else if (model.transferMode) {
        breadcrumb = [
            uScaleBreadcrumb,
            { href: '', title: 'Transfers' },
            { href: window.location.pathname, disableLink: true, title: <Breadcrumb model={model} /> },
        ];
    } else {
        breadcrumb = [
            uScaleBreadcrumb,
            { href: window.location.pathname, disableLink: true, title: <Breadcrumb model={model} /> },
        ];
    }

    return (
        <PageTemplate
            title='HTE'
            breadcrumb={breadcrumb}
            button={model && !model.transferMode ? <NavButtons model={model} /> : undefined}
        >
            {_model.isLoading && <Loading message='Go Speed Racer, Go!' />}
            {_model.error && <ErrorMessage message={_model.error} />}
            {model && model.transferMode && <HTE2MSTransferModeUI model={model} />}
            {model && !model.transferMode && <Layout model={model} />}
        </PageTemplate>
    );
}

interface TabSpec extends PillNavStep<HTE2MSTab> {
    status?: (model: HTE2MSModel) => BehaviorSubject<WorkflowStatus>;
    hide?: (model: HTE2MSModel) => boolean;
}

const NavTabs: TabSpec[] = [
    { name: 'reactions', label: 'Reactions', status: (model) => model.design.state.status },

    { name: 'protocol', label: 'Protocol', separator: true, status: (model) => model.protocol.state.status },
    { name: 'reagents', label: 'Reagents', status: (model) => model.protocol.reagents.state.status },

    { name: 'inventory', label: 'Inventory', separator: true, hide: (model) => model.hideProduction },
    {
        name: 'production',
        label: 'Production',
        status: (model) => model.production.state.status,
        hide: (model) => model.hideProduction,
    },
    { name: 'post-production', label: 'Post-production', hide: (model) => model.hideProduction },
    { name: 'promotion', label: 'Promotion', separator: true, hide: (model) => model.hideProduction },

    { name: 'procedure', label: 'Procedure', separator: true, hide: (model) => model.hideDescriptions },
    { name: 'observations', label: 'Observations', hide: (model) => model.hideDescriptions },

    { name: 'finalize', label: 'Finalize', separator: true, hide: (model) => model.hideProduction },
];

function Layout({ model }: { model: HTE2MSModel }) {
    const tab = useBehavior(model.state.tab);

    return (
        <>
            <Nav model={model} />
            <div className='position-absolute' style={{ inset: 0, top: 31 }}>
                {tab === 'reactions' && <HTE2MSDesignUI model={model.design} />}
                {tab === 'protocol' && <HTE2MSProtocolUI model={model} />}
                {tab === 'reagents' && <HTE2MSReagentsUI model={model.protocol} />}
                {tab === 'inventory' && <HTE2MSInventoryUI model={model} />}
                {tab === 'production' && <HTE2MSCrudePlateUI model={model} />}
                {tab === 'post-production' && <HTE2MSPostProductionUI model={model} />}
                {tab === 'promotion' && <HTE2MSPromotionUI model={model} />}
                {tab === 'finalize' && <HTE2MSFinalizeUI model={model} />}
                {tab === 'procedure' && <Procedure model={model} kind='procedure' />}
                {tab === 'observations' && <Procedure model={model} kind='observations' />}
            </div>
        </>
    );
}

function Breadcrumb({ model }: { model: HTE2MSModel }) {
    const info = useBehavior(model.state.info);

    if (info.kind === 'new') {
        return (
            <div className='hte2ms-breadcrumb-wrapper hstack'>
                <span>New Library</span>
                <Badge bg='success' className='ms-2 font-body-xsmall px-2 py-0'>
                    <b>NEW</b>
                </Badge>
            </div>
        );
    }
    if (info.kind === 'draft') {
        return (
            <div className='hte2ms-breadcrumb-wrapper hstack'>
                <span>{info.name}</span>
                <Badge bg='warning' className='ms-2 font-body-xsmall px-2 py-0'>
                    <b>DRAFT</b>
                </Badge>
            </div>
        );
    }
    if (info.kind === 'foundry') {
        let status;
        if (info.experiment.status === 'Planning' && info.experiment.finalization) {
            status = 'FINALIZED';
        } else if (
            info.experiment.status === 'Planning' &&
            !info.experiment.finalization &&
            info.ui_state.workflow_state === 'needs-finalization'
        ) {
            status = 'NEEDS FINALIZATION';
        } else if (info.experiment.status === 'Planning' && info.ui_state.workflow_state) {
            status = info.ui_state.workflow_state === 'purification' ? 'POST-PRODUCTION' : info.ui_state.workflow_state;
        } else if (info.experiment.status === 'Planning') {
            status = 'DESIGN';
        } else {
            status = info.experiment.status;
        }

        return (
            <div className='hte2ms-breadcrumb-wrapper hstack'>
                <span>
                    <b>HTE{info.experiment.id}:</b> {info.experiment.name}
                </span>
                <Badge bg='info' className='ms-2 font-body-xsmall px-2 py-0 fw-bold'>
                    <b>{status.toUpperCase()}</b>
                </Badge>
                <Badge bg='secondary' className='ms-2 font-body-xsmall px-2 py-0'>
                    PROJECT: <b>{info.experiment.project}</b>
                </Badge>
                {info.experiment.hidden && (
                    <Badge bg='warning' className='ms-2 font-body-xsmall px-2 py-0'>
                        HIDDEN
                    </Badge>
                )}
            </div>
        );
    }
    return null;
}

function NavButtons({ model }: { model: HTE2MSModel }) {
    const info = useBehavior(model.state.info);
    const busy = useBehavior(model.state.busy);

    if (info.kind === 'new') {
        const save = () => {
            DialogService.open({
                type: 'generic',
                title: 'Save Library',
                confirmButtonContent: 'Save',
                defaultState: {
                    kind: 'draft',
                    draftOptions: { name: '' },
                    foundryOptions: {
                        name: '',
                        description: '',
                        hidden: false,
                        project: model.design.all[0]?.project,
                    },
                } satisfies SaveOrEditState,
                wrapOk: true,
                model: {},
                content: SaveDialogContent,
                onOk: (state: SaveOrEditState) => {
                    if (state.kind === 'draft') return model.saveDraft(state.draftOptions);
                    return model.saveToFoundry(state.foundryOptions);
                },
            });
        };

        return (
            <IconButton onClick={save} icon={faSave} variant='link' disabled={busy}>
                Save
            </IconButton>
        );
    }

    const edit = () => {
        DialogService.open({
            type: 'generic',
            title: 'Edit Options',
            confirmButtonContent: 'Save',
            model: { readOnly: model.fullReadOnly },
            options: { hideFooter: model.fullReadOnly },
            defaultState: {
                editing: true,
                kind: info.kind,
                draftOptions: info.kind === 'draft' ? { name: info.name } : { name: '' },
                foundryOptions:
                    info.kind === 'foundry'
                        ? {
                              name: info.experiment.name,
                              description: info.experiment.description,
                              hidden: !!info.experiment.hidden,
                              project: info.experiment.project,
                          }
                        : {
                              name: '',
                              description: '',
                              hidden: false,
                              project: model.design.all[0]?.project,
                          },
            } satisfies SaveOrEditState,
            wrapOk: true,
            content: SaveDialogContent,
            onOk: (state: SaveOrEditState) => {
                if (state.kind === 'draft')
                    return Promise.resolve(
                        model.state.info.next({ ...info, name: state.draftOptions.name } as DraftLibraryInfo)
                    );
                return model.saveFoundryOptions(state.foundryOptions);
            },
        });
    };

    const threeDots = (
        <ThreeDotsMenu drop='down' size='lg'>
            <Dropdown.Item onClick={edit} disabled={busy}>
                Edit Options
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={model.confirmClone} disabled={busy}>
                Clone
            </Dropdown.Item>
        </ThreeDotsMenu>
    );

    let buttons;

    if (info.kind === 'draft') {
        const saveToFoundry = () => {
            DialogService.open({
                type: 'generic',
                title: 'Save Library to Foundry',
                confirmButtonContent: 'Save',
                defaultState: {
                    kind: 'only-foundry',
                    draftOptions: { name: info.name },
                    foundryOptions: {
                        name: info.name,
                        description: '',
                        hidden: false,
                        project: model.design.all[0]?.project,
                    },
                } satisfies SaveOrEditState,
                wrapOk: true,
                model: {},
                content: SaveDialogContent,
                onOk: (state: SaveOrEditState) => model.saveToFoundry(state.foundryOptions),
            });
        };

        buttons = (
            <>
                <AsyncActionButton action={model.saveDraft} variant='link' size='sm' icon={faPenRuler} disabled={busy}>
                    Save Draft
                </AsyncActionButton>
                <IconButton onClick={saveToFoundry} icon={faSave} disabled={busy}>
                    Save to Foundry
                </IconButton>
            </>
        );
    }

    if (info.kind === 'foundry') {
        const lastSaved = (
            <i className='text-secondary font-body-small'>
                <Spinner size='sm' className={busy ? 'me-2' : 'invisible me-2'} animation='grow' />
                Last modified on {formatDatetime(info.experiment.modified_on, 'full')}
            </i>
        );
        let actionButton;

        if (info.experiment.status === 'Planning' && !info.ui_state.workflow_state) {
            const submitForProduction = () => {
                if (busy) return;
                DialogService.open({
                    type: 'generic',
                    title: 'Submit for Production',
                    confirmButtonContent: 'Submit',
                    wrapOk: true,
                    content: SubmitForProductionContent,
                    onOk: () => model.submitForProduction(),
                });
            };

            actionButton = (
                <IconButton variant='link' onClick={submitForProduction} icon={faRobot}>
                    Submit for Production
                </IconButton>
            );
        }
        if (
            info.experiment.status === 'Planning' &&
            typeof info.experiment.crude_product_plate_id === 'number' &&
            info.ui_state.workflow_state !== 'needs-finalization' &&
            !info.experiment.locked_on
        ) {
            const submitForProduction = () => {
                DialogService.open({
                    type: 'generic',
                    title: 'Submit for Finalization',
                    confirmButtonContent: 'Submit',
                    wrapOk: true,
                    content: SubmitForFinalizationContent,
                    onOk: () => model.submitForFinalization(),
                });
            };

            actionButton = (
                <IconButton variant='link' onClick={submitForProduction} icon={faFlagCheckered} disabled={busy}>
                    Submit for Finalization
                </IconButton>
            );
        }

        buttons = (
            <>
                {actionButton}
                {lastSaved}
            </>
        );
    }

    return (
        <div className='hstack gap-2' style={{ marginRight: '-1rem' }}>
            {buttons}
            {threeDots}
        </div>
    );
}

function Nav({ model }: { model: HTE2MSModel }) {
    const tab = useBehavior(model.state.tab);
    useBehavior(model.state.info);

    return (
        <div className='px-2 entos-tab-nav-buttons font-body-xsmall'>
            {NavTabs.map((s) => (
                <TabButton key={s.name} step={s} currentStep={tab} model={model} />
            ))}
        </div>
    );
}

function TabButton({ step, currentStep, model }: { step: TabSpec; currentStep: any; model: HTE2MSModel }) {
    if (step.hide?.(model)) return null;

    return (
        <Button
            onClick={() => currentStep !== step.name && model.state.tab.next(step.name)}
            variant={currentStep === step.name ? 'primary' : 'secondary'}
            className={step.separator ? 'ms-4 d-flex align-items-center' : 'd-flex align-items-center'}
            size='sm'
            title={step.label}
        >
            {step.status && <WorkflowStatusIndicator subject={step.status(model)} />}
            <span className={step.status ? 'ms-2' : undefined}>{step.label}</span>
        </Button>
    );
}

interface SaveOrEditState {
    editing?: boolean;
    kind: 'draft' | 'foundry' | 'only-foundry';
    draftOptions: { name: string };
    foundryOptions: HTE2MicroscaleLibraryOptions;
}

function SaveDialogContent({
    model,
    stateSubject,
}: {
    model?: { readOnly?: boolean };
    stateSubject: BehaviorSubject<SaveOrEditState>;
}) {
    const state = useBehavior(stateSubject);

    return (
        <div className='vstack gap-2'>
            {state.kind !== 'only-foundry' && !state.editing && (
                <ButtonGroup size='sm'>
                    <Button
                        onClick={() => stateSubject.next({ ...state, kind: 'draft' })}
                        variant={state.kind === 'draft' ? 'primary' : 'outline-primary'}
                    >
                        Draft
                    </Button>
                    <Button
                        onClick={() => stateSubject.next({ ...state, kind: 'foundry' })}
                        variant={state.kind === 'foundry' ? 'primary' : 'outline-primary'}
                    >
                        Foundry
                    </Button>
                </ButtonGroup>
            )}
            {state.kind === 'draft' && !state.editing && (
                <InlineAlert>
                    Saving the library as a draft will generate a link that can be used to access and edit it later
                    without publishing it to Foundry. Drafts can be saved to Foundry at any time and are not visible to
                    other users unless shared via the generated link.
                </InlineAlert>
            )}
            {state.kind === 'draft' && (
                <EditNameControls
                    options={state.draftOptions}
                    update={(next) => stateSubject.next({ ...state, draftOptions: { ...state.draftOptions, ...next } })}
                />
            )}
            {state.kind !== 'draft' && (
                <EditLibraryOptionsControls
                    readOnly={model?.readOnly}
                    options={state.foundryOptions}
                    update={(next) =>
                        stateSubject.next({ ...state, foundryOptions: { ...state.foundryOptions, ...next } })
                    }
                />
            )}
        </div>
    );
}

const LabelWidth = 120;

function EditNameControls({
    options,
    update,
}: {
    options: { name: string };
    update: (next: Partial<{ name: string }>) => any;
}) {
    return (
        <LabeledInput label='Name' labelWidth={LabelWidth}>
            <TextInput value={options.name} setValue={(name) => update({ name: name.trim() })} />
        </LabeledInput>
    );
}

function EditLibraryOptionsControls({
    readOnly,
    options,
    update,
}: {
    options: HTE2MicroscaleLibraryOptions;
    update: (next: Partial<HTE2MicroscaleLibraryOptions>) => any;
    readOnly?: boolean;
}) {
    return (
        <>
            <LabeledInput label='Name' labelWidth={LabelWidth}>
                <TextInput value={options.name} setValue={(v) => update({ name: v.trim() })} readOnly={readOnly} />
            </LabeledInput>
            <LabeledInput label='Project' labelWidth={LabelWidth}>
                <div className='w-100'>
                    <ProjectSelect
                        value={options.project}
                        setValue={(v) => update({ project: v })}
                        placeholder='Select project...'
                        disabled={readOnly}
                    />
                </div>
            </LabeledInput>
            <LabeledInput label='Description' labelWidth={LabelWidth} labelAlign='flex-start'>
                <TextInput
                    value={options.description}
                    textarea
                    rows={3}
                    setValue={(v) => update({ description: v.trim() })}
                    readOnly={readOnly}
                />
            </LabeledInput>
            <LabeledInput label='Hidden' labelWidth={LabelWidth} tooltip='Hide the experiment from the overview list'>
                <Form.Switch
                    checked={!!options?.hidden}
                    onChange={(e) => update({ hidden: e.target.checked })}
                    disabled={readOnly}
                />
            </LabeledInput>
        </>
    );
}

function SubmitForProductionContent() {
    return (
        <div className='vstack gap-2 font-body-small'>
            <InlineAlert>
                Submitting the experiment for production will notify Compound Management via Slack that the library is
                ready for production.
            </InlineAlert>
            <div className='text-secondary'>
                It will still be possible to edit the <b>Reactions</b> and <b>Reagents</b> afterwards.
            </div>
        </div>
    );
}

function SubmitForFinalizationContent() {
    return (
        <InlineAlert>
            This action will assign the library&apos;s <b>Executed On</b> field and notify the author via Slack that it
            is ready for finalization.
        </InlineAlert>
    );
}

function Procedure({ model, kind }: { model: HTE2MSModel; kind: 'observations' | 'procedure' }) {
    const info = useBehavior(model.state.info);
    const busy = useBehavior(model.state.busy);
    const readOnly = useBehavior(model.state.fullReadOnly);
    const src = info.kind === 'foundry' ? info.experiment[kind] ?? '' : '';
    const [current, setCurrent] = useState(src);
    useEffect(() => {
        setCurrent(src);
    }, [src]);
    if (info.kind !== 'foundry') return null;

    return (
        <div className='d-flex h-100 p-2'>
            <div className='flex-grow-1 d-flex flex-column' style={{ flexBasis: '50%' }}>
                <div className='flex-grow-1 position-relative'>
                    <TextInput
                        textarea
                        autoFocus
                        value={current}
                        style={{ fontFamily: 'monospace', resize: 'none' }}
                        className='w-100 h-100 font-body-small'
                        readOnly={busy || readOnly}
                        immediate
                        setValue={setCurrent}
                        onBlur={() => model.saveProcedure(kind, current)}
                    />
                </div>
                <div className='pt-1 text-center'>
                    <UsesMarkdown />
                </div>
            </div>
            <div className='ms-4 position-relative' style={{ flexBasis: '50%' }}>
                <ScrollBox className='pe-2'>
                    <ReactMarkdown skipHtml>{current}</ReactMarkdown>
                </ScrollBox>
            </div>
        </div>
    );
}
