import {
    faAngleDoubleUp,
    faCheck,
    faFileExport,
    faFileImport,
    faMagnifyingGlass,
    faPlus,
    faRobot,
    faTrash,
    faUpRightFromSquare,
    faVials,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Split from 'react-split-it';
import { DataTableControl, DataTableGlobalFilter } from '../../../components/DataTable';
import { InlineAlert } from '../../../components/common/Alert';
import { AsyncButton } from '../../../components/common/AsyncButton';
import { IconButton, IconDropdownButton } from '../../../components/common/IconButton';
import { TextInput } from '../../../components/common/Inputs';
import { PillNav, PillNavStep } from '../../../components/common/Nav';
import { ProtocolText } from '../../../components/common/ProtocolText';
import { ScrollBox } from '../../../components/common/ScrollBox';
import useBehavior from '../../../lib/hooks/useBehavior';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { DateLike, formatDatetime } from '../../../lib/util/dates';
import { objectIsEmpty } from '../../../lib/util/misc';
import { useModelAction } from '../../../lib/util/reactive-model';
import { HTE2MSModel } from '../model';
import { Formatters, HTEPanel } from '../utils';
import { HTE2MSDistributionModel } from './distribution';
import { HTE2MSPostProductionModel, PostProductionTab } from './model';
import { HTE2MSPurificationModel } from './purification';
import { HTE2MSQualityControlModel } from './qc';
import Loading from '../../../components/common/Loading';
import { HTE2MSPostProductionSummaryModel } from './summary';

export function HTE2MSPostProductionUI({ model }: { model: HTE2MSModel }) {
    useMountedModel(model.postProduction);

    return <PostProductionTabsUI model={model.postProduction} />;
}

const GeneralPostProdNavTabs: PillNavStep<PostProductionTab>[] = [{ name: 'general', label: 'General' }];

const PostProductionNavTabs: PillNavStep<PostProductionTab>[] = [
    { name: 'pooling', label: 'Pooling' },
    { name: 'distribution', label: 'Distribution' },
    { name: 'finalqc', label: 'FinalQC' },
];

const AdditionalPostProdNavTabs: PillNavStep<PostProductionTab>[] = [{ name: 'summary', label: 'Summary' }];

function PostProductionTabsUI({ model }: { model: HTE2MSPostProductionModel }) {
    const tab = useBehavior(model.state.tab);

    return (
        <div className='d-flex flex-column h-100 ps-2'>
            <div className='hstack gap-2 me-2 pt-2 mb-2'>
                <PillNav
                    steps={GeneralPostProdNavTabs}
                    currentStep={tab}
                    onSetStep={(s) => model.state.tab.next(s)}
                    containerClassName='hte2ms-inline-tab-nav-buttons'
                />
                <PillNav
                    steps={PostProductionNavTabs}
                    currentStep={tab}
                    onSetStep={(s) => model.state.tab.next(s)}
                    containerClassName='hte2ms-inline-tab-nav-buttons'
                />
                <PillNav
                    steps={AdditionalPostProdNavTabs}
                    currentStep={tab}
                    onSetStep={(s) => model.state.tab.next(s)}
                    containerClassName='hte2ms-inline-tab-nav-buttons'
                />
                <div className='m-auto' />
            </div>
            <div className='flex-grow-1 position-relative'>
                {tab === 'general' && <GeneralTab model={model} />}
                {tab === 'pooling' && <PoolingTab model={model} />}
                {tab === 'distribution' && <DistributionTab model={model} />}
                {tab === 'finalqc' && <FinalQCTab model={model} />}
                {tab === 'summary' && <ChecklistTab model={model} />}
            </div>
        </div>
    );
}

function GeneralTab({ model }: { model: HTE2MSPostProductionModel }) {
    const readOnly = useBehavior(model.model.state.fullReadOnly);
    const info = useBehavior(model.model.state.info);
    if (info.kind !== 'foundry') return null;

    return (
        <ScrollBox>
            <div className='p-2 vstack gap-2 m-auto' style={{ width: 800 }}>
                <h5 className='mb-0'>Crude plate</h5>
                <PlateInfo model={model} id={info.experiment.crude_product_plate_id} />
                {!info.experiment.crude_product_plate_id && (
                    <div className='text-secondary'>No crude plate uploaded</div>
                )}

                <h5 className='mt-4 mb-0'>Associated Plates</h5>
                {!readOnly && <AddPlate model={model} />}
                <div>
                    {info.experiment.plate_ids.map((id) => (
                        <PlateInfo key={id} model={model} id={id} showRemove={!readOnly} />
                    ))}
                </div>

                <h5 className='mt-4 mb-0'>Salts</h5>
                {!readOnly && <AddSalt model={model} />}
                <div>
                    {Array.from(Object.entries(model.purification.data.salt_name_to_smiles)).map(([name, smiles]) => (
                        <SaltInfo key={name} model={model} name={name} smiles={smiles} showRemove={!readOnly} />
                    ))}
                </div>
            </div>
        </ScrollBox>
    );
}

function AddPlate({ model }: { model: HTE2MSPostProductionModel }) {
    const addAction = useModelAction(model.actions.addPlate);
    const barcode = useBehavior(model.state.addBarcode);

    return (
        <div className='hstack'>
            <TextInput
                value={barcode}
                setValue={(v) => model.state.addBarcode.next(v.trim())}
                placeholder='Plate Barcode'
                disabled={addAction.kind === 'loading'}
                style={{ width: 248 }}
                size='sm'
                immediate
            />
            <AsyncButton
                icon={faPlus}
                onClick={() => model.actions.addPlate.run(model.addPlate())}
                state={{ isLoading: addAction.kind === 'loading' }}
                size='sm'
                variant='link'
                disabled={!barcode}
            >
                Add Plate
            </AsyncButton>
        </div>
    );
}

function PlateInfo({ model, id, showRemove }: { model: HTE2MSPostProductionModel; id?: number; showRemove?: boolean }) {
    const invSync = useModelAction(model.actions.syncPlates);

    if (typeof id !== 'number') return null;
    const plate = model.model.assets.plateInfoById.get(id);

    if (!plate && invSync.kind === 'loading') return null;
    if (!plate)
        return (
            <div>
                <Link to={`/ecm/plates/${id}`} target='_blank'>
                    <b>Plate {id}</b>
                    <FontAwesomeIcon className='ms-1' icon={faUpRightFromSquare} />
                </Link>{' '}
                Info not found
            </div>
        );

    return (
        <div className='hstack gap-2'>
            {showRemove && (
                <IconButton icon={faTrash} onClick={() => model.confirmRemove(id)} className='text-danger px-0' />
            )}
            <Link to={`/ecm/plates/${id}`} target='_blank'>
                <b>{plate.barcode}</b>
                <FontAwesomeIcon className='ms-1' icon={faUpRightFromSquare} />
            </Link>
            <span className='text-secondary'>Purpose:</span>
            <b>{plate.purpose || '-'}</b>
            <span className='text-secondary'>Description:</span>
            <b>{plate.description || '-'}</b>
        </div>
    );
}

function AddSalt({ model }: { model: HTE2MSPostProductionModel }) {
    const addAction = useModelAction(model.purification.actions.addSalt);
    const [name, smiles] = useBehavior(model.purification.state.addSalt);

    return (
        <div className='hstack'>
            <TextInput
                value={name}
                setValue={(v) => model.purification.state.addSalt.next([v.trim(), smiles])}
                placeholder='Name'
                disabled={addAction.kind === 'loading'}
                style={{ width: 120 }}
                size='sm'
                immediate
            />
            <TextInput
                value={smiles}
                setValue={(v) => model.purification.state.addSalt.next([name, v.trim()])}
                placeholder='SMILES'
                disabled={addAction.kind === 'loading'}
                style={{ width: 120 }}
                size='sm'
                className='ms-2'
                immediate
            />
            <AsyncButton
                icon={faPlus}
                onClick={() => model.purification.actions.addSalt.run(model.purification.addSalt())}
                state={{ isLoading: addAction.kind === 'loading' }}
                size='sm'
                variant='link'
                disabled={!name || !smiles}
            >
                Add Salt
            </AsyncButton>
        </div>
    );
}

function SaltInfo({
    model,
    name,
    smiles,
    showRemove,
}: {
    model: HTE2MSPostProductionModel;
    name: string;
    smiles: string;
    showRemove?: boolean;
}) {
    return (
        <div className='hstack gap-2'>
            {showRemove && (
                <IconButton
                    icon={faTrash}
                    onClick={() => model.purification.confirmRemoveSalt(name)}
                    className='text-danger px-0'
                />
            )}
            <span className='text-secondary'>Name:</span>
            <b>{name}</b>
            <span className='text-secondary'>SMILES:</span>
            <b>{smiles}</b>
        </div>
    );
}

interface TabTemplateProps {
    title: string;
    listControls: ReactNode;
    current: ReactNode;
    list: ReactNode;
}

function TabTemplate({ title, listControls, list, current }: TabTemplateProps) {
    return (
        <div className='d-flex flex-column h-100'>
            <div className='flex-grow-1 position-relative'>
                <div className='d-flex w-100 h-100'>
                    <div
                        className='flex-shrink-0 position-relative border-start border-end border-top me-2'
                        style={{ minWidth: 360, maxWidth: 360 }}
                    >
                        <HTEPanel title={title} controls={listControls}>
                            {list}
                        </HTEPanel>
                    </div>
                    <div className='d-flex flex-grow-1 position-relative h-100'>{current}</div>
                </div>
            </div>
        </div>
    );
}

interface ListItemProps {
    processed: boolean;
    uploaded?: boolean;
    selected: boolean;
    label: string;
    timestamp: DateLike;
    onSelect: () => any;
    onRegister: () => any;
    onUploadBarcodes?: () => any;
    onRemove: () => any;
}

function ListItem({
    processed,
    uploaded,
    selected,
    label,
    timestamp,
    onSelect,
    onUploadBarcodes,
    onRemove,
    onRegister,
}: ListItemProps) {
    return (
        <div className='hstack ps-3'>
            <FontAwesomeIcon
                icon={processed ? faCheck : faRobot}
                className={processed ? 'text-success' : 'text-warning'}
                fixedWidth
            />
            <Button
                onClick={onSelect}
                className='hte2ms-post-production-list-item text-start py-1 flex-grow-1 ps-1'
                variant='link'
            >
                <div className='flex-grow-1 ms-2'>
                    <div className={selected ? 'text-primary' : 'text-secondary'}>
                        <b>{label}</b>
                    </div>
                    <div className='text-secondary font-body-xsmall'>{formatDatetime(timestamp, 'full')}</div>
                </div>
            </Button>
            {!processed && selected && (
                <IconButton icon={faTrash} onClick={onRemove} disabled={processed} className='text-danger' />
            )}
            {!processed && selected && !!onUploadBarcodes && (
                <IconButton icon={faVials} onClick={onUploadBarcodes} variant='link' disabled={processed} />
            )}
            {!processed && selected && (
                <IconButton
                    icon={faAngleDoubleUp}
                    onClick={onRegister}
                    variant='link'
                    disabled={processed || uploaded === false}
                    className='me-1'
                />
            )}
        </div>
    );
}

function PoolingTab({ model }: { model: HTE2MSPostProductionModel }) {
    useMountedModel(model.purification);

    return (
        <TabTemplate
            title='Pooling'
            listControls={<UploadPoolingButton model={model.purification} />}
            list={<PoolingList model={model.purification} />}
            current={<CurrentPooling model={model.purification} />}
        />
    );
}

function UploadPoolingButton({ model }: { model: HTE2MSPurificationModel }) {
    const readOnly = useBehavior(model.postProduction.model.state.fullReadOnly);
    if (readOnly) return null;

    return (
        <IconButton icon={faFileImport} size='sm' variant='link' className='px-0' onClick={model.uploadPooling}>
            Add Pooling
        </IconButton>
    );
}

function PoolingList({ model }: { model: HTE2MSPurificationModel }) {
    const data = useBehavior(model.state.data);
    const currentId = useBehavior(model.state.currentId);

    return (
        <ScrollBox>
            <div className='vstack w-100'>
                {data.poolings.map((p) => (
                    <ListItem
                        key={p.id}
                        uploaded={!objectIsEmpty(data.states[p.id].pooled_fraction_barcodes)}
                        processed={model.isPoolingRegistered(p.id)}
                        timestamp={p.timestamp}
                        label={`${p.fractions.length} fraction${p.fractions.length === 1 ? '' : 's'}`}
                        selected={currentId === p.id}
                        onSelect={() => model.state.currentId.next(p.id)}
                        onRemove={() => model.confirmRemove(p.id)}
                        onUploadBarcodes={() => model.confirmUploadBarcodes(p.id)}
                        onRegister={() => model.confirmRegister(p.id)}
                    />
                ))}
            </div>
        </ScrollBox>
    );
}

function CurrentPooling({ model }: { model: HTE2MSPurificationModel }) {
    useBehavior(model.state.currentId);
    const { current } = model;

    if (!current) return null;

    const scripts = model.data.states[current.id].scripts;
    const options = model.data.states[current.id].script_options;
    const vialCount = scripts?.fraction_order?.reduce((acc, f) => acc + f[1], 0) ?? 0;

    return (
        <div className='w-100 h-100 position-relative'>
            <Split direction='vertical'>
                <div className='position-relative w-100 h-100 d-flex flex-column'>
                    <div className='pb-2 me-2'>
                        <DataTableGlobalFilter table={model.table} size='sm' />
                    </div>
                    <div className='flex-grow-1 position-relative me-2'>
                        <PoolingTable model={model} />
                    </div>
                </div>
                <div className='d-flex flex-column w-100 h-100'>
                    <InlineAlert className='mb-2 me-2 font-body-small' iconTopLeft>
                        <div>
                            <div>
                                Prepare{' '}
                                <b>
                                    {vialCount} vial{vialCount === 1 ? '' : 's'}
                                </b>{' '}
                                for pooling.
                            </div>
                            <div>
                                <b>Fraction labware:</b> {options?.fraction_labware.name ?? 'Unknown'} (
                                {options?.fraction_labware.layout ?? '? layout'})
                            </div>
                            <div>
                                <b>Pooled labware:</b> {options?.pooled_labware.name ?? 'Unknown'} (
                                {options?.pooled_labware.layout ?? '? layout'}), max volume{' '}
                                {Formatters.siVolume(options?.max_pooled_volume)} (
                                {Formatters.siVolume(options?.pooled_tolerance)} tolerance),{' '}
                                {Formatters.siVolume(options?.tecan_piston_volume)} Tecan piston volume
                            </div>
                        </div>
                    </InlineAlert>
                    <ProtocolText
                        value={scripts?.fraction_tecan_worklist ?? ''}
                        className='flex-grow-1 mb-2 me-2'
                        save={() =>
                            model.saveProtocol({
                                protocol: scripts?.fraction_tecan_worklist ?? '',
                            })
                        }
                    />
                </div>
            </Split>
        </div>
    );
}

function PoolingTable({ model }: { model: HTE2MSPurificationModel }) {
    useBehavior(model.table.version);
    return (
        <DataTableControl
            table={model.table}
            height='flex'
            rowSelectionMode='single'
            headerSize='xxsm'
            className='hte2ms-hidden-button-table'
        />
    );
}

function DistributionTab({ model }: { model: HTE2MSPostProductionModel }) {
    useMountedModel(model.distribution);

    return (
        <TabTemplate
            title='Distribution'
            listControls={<DistributionButtons model={model.distribution} />}
            list={<DistributionList model={model.distribution} />}
            current={<CurrentDistribution model={model.distribution} />}
        />
    );
}

function DistributionButtons({ model }: { model: HTE2MSDistributionModel }) {
    const readOnly = useBehavior(model.mainModel.state.fullReadOnly);
    if (readOnly) return null;

    return (
        <div className='hstack gap-2'>
            <IconButton
                icon={faMagnifyingGlass}
                size='sm'
                variant='link'
                className='px-0'
                onClick={model.validateDistribution}
            >
                Validate
            </IconButton>
            <IconButton icon={faFileImport} size='sm' variant='link' className='px-0' onClick={model.addDistibution}>
                Add
            </IconButton>
        </div>
    );
}

function DistributionList({ model }: { model: HTE2MSDistributionModel }) {
    const data = useBehavior(model.state.data);
    const currentId = useBehavior(model.state.currentId);

    return (
        <ScrollBox>
            <div className='vstack w-100'>
                {data.distributions.map((d) => (
                    <ListItem
                        key={d.id}
                        processed={!!data.registered[d.id]}
                        timestamp={d.timestamp}
                        label={`${d.compounds.length} compound${d.compounds.length === 1 ? '' : 's'}`}
                        selected={currentId === d.id}
                        onSelect={() => model.state.currentId.next(d.id)}
                        onRemove={() => model.confirmRemove(d.id)}
                        onRegister={() => model.confirmRegister(d.id)}
                    />
                ))}
            </div>
        </ScrollBox>
    );
}

function CurrentDistribution({ model }: { model: HTE2MSDistributionModel }) {
    useBehavior(model.state.currentId);
    useBehavior(model.state.data);

    const { current } = model;
    if (!current) return null;

    const liquidCount = current.compounds.length;
    const dryCount = current.compounds.reduce((acc, c) => acc + (c.dry_transfer_volume ? 1 : 0), 0);
    const { options } = current;

    return (
        <div className='w-100 h-100 position-relative'>
            <Split direction='vertical'>
                <div className='position-relative w-100 h-100 d-flex flex-column'>
                    <div className='hstack gap-2 p-2 ps-1 pt-0'>
                        <div className='flex-grow-1'>
                            <DataTableGlobalFilter table={model.table} size='sm' />
                        </div>
                        <div className='flex-grow-1' />
                        <IconDropdownButton icon={faFileExport} label='Export' size='sm' variant='link'>
                            <Dropdown.Item onClick={() => model.exportLiquidBarcodes('copy')}>
                                Copy Liquid Barcodes
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => model.exportLiquidBarcodes('save')}>
                                Save Liquid Barcodes (.csv)
                            </Dropdown.Item>
                        </IconDropdownButton>
                    </div>
                    <div className='flex-grow-1 position-relative ms-1 me-2'>
                        <DistributionTable model={model} />
                    </div>
                </div>
                <div className='d-flex flex-column w-100 h-100'>
                    <InlineAlert className='mb-2 me-2 font-body-small' iconTopLeft>
                        <div>
                            <div>
                                Prepare{' '}
                                <b>
                                    {liquidCount} tube{liquidCount === 1 ? '' : 's'}
                                </b>{' '}
                                for liquid and{' '}
                                <b>
                                    {dryCount} vial{dryCount === 1 ? '' : 's'}
                                </b>{' '}
                                for dry stock to distribute {Formatters.siVolume(options.liquid_vial_volume)} @{' '}
                                {Formatters.concentration(options.liquid_vial_concentration)} (max.{' '}
                                {Formatters.concentration(options.max_solubilize_concentration)}) liquid stock using{' '}
                                {Formatters.siVolume(options?.tecan_piston_volume)} Tecan piston volume, discard dry
                                stock with volume less than {Formatters.siVolume(options.dry_vial_discard_volume)} or
                                over {Formatters.siVolume(options.dry_vial_volume)}.
                            </div>
                            <div>
                                <b>Purified (pooled) labware:</b> {options.pooled_labware.name ?? 'Unknown'} (
                                {options.pooled_labware.layout ?? '? layout'}) <b>Dry labware:</b>{' '}
                                {options.dry_labware.name ?? 'Unknown'} ({options.dry_labware.layout ?? '? layout'})
                            </div>
                            <div>
                                <b>Liquid labware:</b> {options.liquid_labware.name ?? 'Unknown'} (
                                {options.liquid_labware.layout ?? '? layout'}) <b>Solvent labware:</b>{' '}
                                {options.solvent_labware.name ?? 'Unknown'} (
                                {options.solvent_labware.layout ?? '? layout'})
                            </div>
                        </div>
                    </InlineAlert>
                    <div className='hstack gap-2 me-2 h-100'>
                        <div className='vstack'>
                            <h6 className='mb-2'>Solubilization Tecan Worklist</h6>
                            <ProtocolText
                                value={current.scripts.solubilization_tecan_worklist ?? ''}
                                className='flex-grow-1 mb-2'
                                save={() =>
                                    model.saveProtocol({
                                        name: 'solubilize',
                                        protocol: current.scripts.solubilization_tecan_worklist ?? '',
                                    })
                                }
                            />
                        </div>
                        <div className='vstack'>
                            <h6 className='mb-2'>Distribution Tecan Worklist</h6>
                            <ProtocolText
                                value={current.scripts.distribution_tecan_worklist ?? ''}
                                className='flex-grow-1 mb-2'
                                save={() =>
                                    model.saveProtocol({
                                        name: 'distribute',
                                        protocol: current.scripts.distribution_tecan_worklist ?? '',
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
            </Split>
        </div>
    );
}

function DistributionTable({ model }: { model: HTE2MSDistributionModel }) {
    useBehavior(model.table.version);
    return (
        <DataTableControl
            table={model.table}
            height='flex'
            rowSelectionMode='single'
            headerSize='xxsm'
            className='hte2ms-hidden-button-table'
        />
    );
}

function FinalQCTab({ model }: { model: HTE2MSPostProductionModel }) {
    useMountedModel(model.distribution);

    return (
        <TabTemplate
            title='FinalQC'
            listControls={<AddFinalQCButton model={model.qc} />}
            list={<FinalQCList model={model.qc} />}
            current={<CurrentFinalQC model={model.qc} />}
        />
    );
}

function AddFinalQCButton({ model }: { model: HTE2MSQualityControlModel }) {
    const readOnly = useBehavior(model.mainModel.state.fullReadOnly);
    if (readOnly) return null;

    return (
        <IconButton icon={faFileImport} size='sm' variant='link' className='px-0' onClick={model.addFinalQC}>
            Add FinalQC
        </IconButton>
    );
}

function FinalQCList({ model }: { model: HTE2MSQualityControlModel }) {
    const data = useBehavior(model.state.data);
    const currentId = useBehavior(model.state.currentFinalQCId);

    return (
        <ScrollBox>
            <div className='vstack w-100'>
                {data.finalqcs.map((qc) => (
                    <ListItem
                        key={qc.id}
                        processed={!!data.registered_finalqcs[qc.id]}
                        timestamp={qc.timestamp}
                        label={`${qc.input.racks.length} rack${qc.input.racks.length === 1 ? '' : 's'}, ${
                            qc.scripts.transfers.length
                        } sample${qc.scripts.transfers.length === 1 ? '' : 's'}`}
                        selected={currentId === qc.id}
                        onSelect={() => model.state.currentFinalQCId.next(qc.id)}
                        onRemove={() => model.confirmRemoveFinalQC(qc.id)}
                        onRegister={() => model.confirmRegisterFinalQC(qc.id)}
                    />
                ))}
            </div>
        </ScrollBox>
    );
}

function CurrentFinalQC({ model }: { model: HTE2MSQualityControlModel }) {
    useBehavior(model.state.currentFinalQCId);
    useBehavior(model.state.data);

    const { currentFinalQC: current } = model;
    if (!current) return null;

    const sampleCount = current.scripts.transfers.length;
    const { options } = current;

    return (
        <div className='d-flex flex-column w-100 h-100'>
            <InlineAlert className='mb-2 me-1 font-body-small' iconTopLeft>
                <div>
                    <div>
                        <b>LCMS:</b> {sampleCount}× {Formatters.siVolume(options.assay_volume)}@
                        {Formatters.concentration(options.assay_concentration)} sample{sampleCount === 1 ? '' : 's'} for
                        in{' '}
                        <Link to={`/ecm/plates?barcodes=${options.target_barcode}`} target='_blank'>
                            <b>{options.target_barcode}</b>
                            <FontAwesomeIcon className='ms-1' icon={faUpRightFromSquare} />
                        </Link>
                        <b className='ms-2'>Labware:</b> {options.target_labware.name ?? 'Unknown'} (
                        {options.target_labware.layout ?? '? layout'})
                    </div>
                    {!!options.echoms_target_barcode && (
                        <div>
                            <b>EchoMS:</b> {sampleCount}× {Formatters.siVolume(options.echoms_assay_volume)}@
                            {Formatters.concentration(options.echoms_assay_concentration)} sample
                            {sampleCount === 1 ? '' : 's'} for in{' '}
                            <Link to={`/ecm/plates?barcodes=${options.echoms_target_barcode}`} target='_blank'>
                                <b>{options.echoms_target_barcode}</b>
                                <FontAwesomeIcon className='ms-1' icon={faUpRightFromSquare} />
                            </Link>
                            <b className='ms-2'>Labware:</b> {options.echoms_target_labware.name ?? 'Unknown'} (
                            {options.echoms_target_labware.layout ?? '? layout'})
                        </div>
                    )}
                    <div>
                        <b>Source labware:</b> {options.source_labware.name ?? 'Unknown'} (
                        {options.source_labware.layout ?? '? layout'})
                    </div>
                    <div>
                        <b>Solvent labware:</b> {options.solvent_labware.name ?? 'Unknown'} (
                        {options.solvent_labware.layout ?? '? layout'})
                    </div>
                </div>
            </InlineAlert>
            <ProtocolText
                value={current.scripts.tecan_worklist ?? ''}
                className='flex-grow-1 mb-2 me-1'
                save={() =>
                    model.saveProtocol({
                        name: `finalqc-${current.options.target_barcode}`,
                        protocol: current.scripts.tecan_worklist,
                    })
                }
            />
        </div>
    );
}

function ChecklistTab({ model }: { model: HTE2MSPostProductionModel }) {
    useMountedModel(model.summary);
    const sync = useModelAction(model.summary.actions.sync);

    if (sync.kind === 'loading') return <Loading />;

    return (
        <div className='position-relative w-100 h-100 d-flex flex-column'>
            <InlineAlert className='font-body-small me-2 mb-2'>
                A summary of Pooling, Distribution and FinalQC workflows
            </InlineAlert>
            <div className='pb-2 me-2'>
                <div className='hstack gap-2'>
                    <div className='flex-grow-1'>
                        <DataTableGlobalFilter table={model.summary.table} size='sm' controlClassName='flex-grow-1' />
                    </div>
                    <IconDropdownButton icon={faFileExport} label='Export' size='sm' variant='link'>
                        <Dropdown.Item
                            title='Copy distributed liquid stock barcodes that do not have an active or resolved FinalQC entry'
                            onClick={() => model.summary.exportQCBarcodes('copy')}
                        >
                            Copy Pending FinalQC Barcodes
                        </Dropdown.Item>
                        <Dropdown.Item
                            title='Save distributed liquid stock barcodes that do not have an active or resolved FinalQC entry'
                            onClick={() => model.summary.exportQCBarcodes('save')}
                        >
                            Save Pending FinalQC Barcodes (.csv)
                        </Dropdown.Item>
                    </IconDropdownButton>
                </div>
            </div>
            <div className='flex-grow-1 position-relative me-2'>
                <ChecklistTable model={model.summary} />
            </div>
        </div>
    );
}

function ChecklistTable({ model }: { model: HTE2MSPostProductionSummaryModel }) {
    useBehavior(model.table.version);
    return (
        <DataTableControl
            table={model.table}
            height='flex'
            rowSelectionMode='single'
            headerSize='xxsm'
            className='hte2ms-hidden-button-table'
        />
    );
}
