import api from '../../api';
import { columnDataTableStore, DataTableStore } from '../../components/DataTable';
import { ReactTableModel } from '../../components/ReactTable/model';
import ReactTableSchema from '../../components/ReactTable/schema';
import { DateLike } from '../../lib/util/dates';
import { roundValue } from '../../lib/util/roundValues';
import { decodeEntosMsgpack } from '../../lib/util/serialization';
import { formatUnit, prefixedUnitValue } from '../../lib/util/units';
import {
    Batch,
    batchFromDFToBatch,
    BatchIdentifiers,
    CompoundDetail,
    CompoundFromDF,
    compoundFromDFToCompound,
    createBatchArray,
    createCompoundArray,
    Sample,
    SampleContents,
    Substance,
} from '../Compounds/compound-api';
import { HTEExperimentInfo, HTESolvent, WellLayout } from '../HTE/experiment-data';
import { ECMVialRack } from './ecm-data';

export interface SampleCreate {
    barcode?: string | null;
    batch_id: number;
    solute_mass?: number | string | null;
    solvent?: HTESolvent | null;
    solvent_volume?: number | string | null;
    concentration?: number | string | null;
}

export interface BigVialLocation {
    cabinet: string;
    shelf: string;
    rack: string;
}

export interface SmallVialLocation extends BigVialLocation {
    well: string;
}

export interface CustomVialLocation {
    custom_location: string;
}

export type VialLocation = 'verso' | 'lab' | 'transit' | BigVialLocation | SmallVialLocation | CustomVialLocation;

function isSmallVialLocation(loc: any): loc is SmallVialLocation {
    return !!loc.well;
}

function isPlateLocation(loc: any): loc is PlateLocation {
    return !!loc.freezer;
}

function isBigVialLocation(loc: any): loc is BigVialLocation {
    return !!loc.cabinet || !!loc.shelf || !!loc.rack;
}

function isCustomVialLocation(loc: any): loc is CustomVialLocation {
    return typeof loc.custom_location === 'string';
}

export function formatLocation(loc: VialLocation | PlateLocation | undefined) {
    if (!loc) return '';
    if (typeof loc === 'string') return loc;
    if (isPlateLocation(loc)) {
        return `${loc.freezer}-${loc.shelf}-${loc.rack}-${loc.slot}`;
    }
    if (isSmallVialLocation(loc)) {
        return `${loc.cabinet}-${loc.shelf}-${loc.rack}-${loc.well}`;
    }
    if (isBigVialLocation(loc)) {
        return `${loc.cabinet}-${loc.shelf}-${loc.rack}`;
    }
    if (isCustomVialLocation(loc)) {
        return loc.custom_location;
    }
    try {
        return JSON.stringify(loc);
    } catch {
        console.warn('Unexpected location', loc);
        return '<unknown location>';
    }
}

export type FormattableSample =
    | Pick<SampleCreate, 'solute_mass' | 'solvent_volume' | 'solvent' | 'concentration'>
    | Pick<Sample, 'solute_mass' | 'solvent_volume' | 'solvent' | 'concentration'>;

// amount / volume / concentration / solvent
// skips undefined values
export function formatSampleContentInline(sample: FormattableSample | null | undefined) {
    if (!sample) return '';
    return [
        formatUnit(sample.solute_mass, 'g', 'm'),
        formatUnit(
            typeof sample.solvent_volume === 'number' ? sample.solvent_volume * 1000 : sample.solvent_volume,
            'L',
            'u'
        ),
        formatUnit(sample.concentration, 'M', 'm'),
        sample.solvent,
    ]
        .filter((v) => !!v)
        .join(' / ');
}

export function compareSamples(a: SampleContents | undefined, b: SampleContents | undefined) {
    if (!a && !b) return 0;
    if (a && !b) return -1;
    if (!a && b) return 1;

    const isAwet = typeof a!.solvent_volume === 'number';
    const isBwet = typeof b!.solvent_volume === 'number';

    if (isAwet && isBwet) return a!.solvent_volume! - b!.solvent_volume!;
    if (isAwet) return -1;
    if (isBwet) return 1;
    return a!.solute_mass! - b!.solute_mass!;
}

export const Vial = {
    schema_name: ReactTableSchema.enumStr(['vial']),
    id: {
        ...ReactTableSchema.int(),
        format: (v: number) => (v < 0 ? '' : `${v}`),
    },
    barcode: {
        ...ReactTableSchema.str(),
        format: (v: string) => (!v ? '<not available>' : v),
    },
    created_by: ReactTableSchema.str(),
    batch_identifier: ReactTableSchema.optionalStr(),
    modified_on: ReactTableSchema.datetime({ format: 'full' }),
    created_on: ReactTableSchema.datetime({ format: 'date' }),
    status: ReactTableSchema.enumStr(['Tared', 'Inventory', 'Transit', 'Disposed']),
    // kind: Schema.enumStr(['generic']),
    tare_mass: ReactTableSchema.float({
        defaultFormatting: {
            custom: (v) => {
                if (typeof v !== 'number' || Number.isNaN(v)) return '';
                return `${roundValue(1, v * 1e3)} mg`;
            },
        },
    }),
    location: ReactTableSchema.obj<VialLocation, any>({
        format: formatLocation,
        compare: (a, b) => {
            const strA = formatLocation(a);
            const strB = formatLocation(b);
            if (strA === strB) return 0;
            return strA < strB ? -1 : 1;
        },
    }),
    sample: ReactTableSchema.obj<Sample | undefined, any>({
        format: formatSampleContentInline,
        compare: compareSamples,
    }),
};
export type Vial = ReactTableSchema.Type<typeof Vial>;
// export type VialKind = Vial['kind'];

export function isSampleEmpty(sample?: Sample) {
    if (!sample) return true;

    return (
        (!sample.solute_mass || sample.solute_mass < 1e-8) && (!sample.solvent_volume || sample.solvent_volume < 1e-8)
    );
}

export function isVersoTubeBarcode(barcode: string | undefined): barcode is string {
    return !!barcode && (barcode.startsWith('HG') || barcode.startsWith('HB'));
}

export function isTubeBarcode(barcode: string | undefined): barcode is string {
    return !!barcode && (barcode.startsWith('TT') || barcode.startsWith('HG') || barcode.startsWith('HB'));
}

export function formatSampleContentInSearch(
    sample: SampleContents | SampleCreate | Sample | null | undefined,
    alwaysIncludeMass = false
) {
    if (!sample) return '';

    const fields: string[] = [];
    if (typeof sample.solvent_volume === 'number' || sample.solvent) {
        if (alwaysIncludeMass) {
            fields.push(formatUnit(sample.solute_mass, 'g', 'm'));
        }
        fields.push(
            prefixedUnitValue(sample.solvent_volume, 'L', { factor: 1e3, strict: true }),
            prefixedUnitValue(sample.concentration, 'M', { strict: true })
        );
        if (sample.solvent && sample.solvent !== 'DMSO') {
            fields.push(sample.solvent);
        }
    } else {
        fields.push(formatUnit(sample.solute_mass, 'g', 'm'));
    }

    return fields.filter((v) => !!v).join(' / ');
}

export const ECMSearchResult = {
    ...Vial,
    location: ReactTableSchema.obj<VialLocation | PlateLocation, any>({
        format: formatLocation,
        compare: (a, b) => {
            const strA = formatLocation(a);
            const strB = formatLocation(b);
            if (strA === strB) return 0;
            return strA < strB ? -1 : 1;
        },
    }),
    well: ReactTableSchema.optionalStr(),
    plate_purpose: ReactTableSchema.optionalStr(),
    plate_description: ReactTableSchema.optionalStr(),
    project: ReactTableSchema.optionalStr(),
    batch_purity: ReactTableSchema.optionalFloat(),
    sample: ReactTableSchema.obj<Sample | undefined, any>({
        format: formatSampleContentInSearch,
        compare: compareSamples,
    }),
    total_requested: ReactTableSchema.obj<SampleContents | undefined, any>({
        format: (v) => formatSampleContentInSearch(v, true),
        compare: compareSamples,
    }),
    wizard: ReactTableSchema.bool(),
};
export type ECMSearchResult = ReactTableSchema.Type<typeof ECMSearchResult>;

export function isInventorySearchResult(res: ECMSearchResult) {
    if (res.well?.length) {
        return res.status === 'Inventory' && !!res.location && res.plate_purpose?.toLowerCase() === 'library stock';
    }
    return (
        res.status === 'Inventory' &&
        (res.location === 'verso' ||
            res.location === 'lab' ||
            isSmallVialLocation(res.location) ||
            isBigVialLocation(res.location))
    );
}

export function isLabwareAtEntosSite(res: ECMSearchResult) {
    if (res.well?.length) {
        return !!res.location;
    }
    if (res.location === 'lab' || res.location === 'verso' || (res.location && typeof res.location === 'object')) {
        return true;
    }
    return false;
}

interface SampleHolderEvent {
    id: number;

    sample_holder_id: number;
    update: Record<string, any>;

    created_by: string;
    created_on: DateLike;

    description?: string;
}

export interface VialEvent extends SampleHolderEvent {
    kind: string;
}

export interface PlateEvent extends SampleHolderEvent {
    kind: string;
}

export const PlateKinds = ['source', 'product', 'downstream'] as const;
export type PlateKind = (typeof PlateKinds)[number];

export interface PlateLocation {
    freezer: string;
    shelf: string;
    rack: string;
    slot: string;
}

export type PlateControlKind = 'positive' | 'negative' | 'reference' | 'rest';

export const PlateRow = {
    id: ReactTableSchema.int(),
    barcode: ReactTableSchema.str(),
    kind: ReactTableSchema.enumStr(PlateKinds),
    status: ReactTableSchema.enumStr(['Inventory', 'Disposed']),
    location: ReactTableSchema.obj({
        format: formatLocation,
        compare: (a, b) => {
            const sa = formatLocation(a);
            const sb = formatLocation(b);
            if (sa === sb) return 0;
            return sa < sb ? -1 : 1;
        },
    }),
    size: ReactTableSchema.int(),
    purpose: ReactTableSchema.optionalStr(),
    description: ReactTableSchema.optionalStr(),
    created_by: ReactTableSchema.str(),
    created_on: ReactTableSchema.datetime({ format: 'date' }),
    modified_on: ReactTableSchema.datetime({ format: 'full' }),
};
export type PlateRow = ReactTableSchema.Type<typeof PlateRow>;

export interface PlateCreate {
    barcode: string;
    kind: PlateKind;
    location: PlateLocation;
    size: WellLayout;
    status: 'Inventory' | 'Disposed';
    samples: (Sample | null | undefined)[];
    control_kinds?: (PlateControlKind | null | undefined)[];
    parent_plate_ids?: number[];
    purpose?: string;
    description?: string;
}

export interface Plate extends PlateCreate {
    schema_name: 'plate';
    id: number;
    created_by: string;
    created_on: DateLike;
    modified_on: DateLike;
}

export interface ECMQueryResult {
    results: ReactTableModel<ECMSearchResult>;
    batches: Record<number, Batch>;
    compounds: Record<number, CompoundDetail>;
    invalid_identifiers: string[];
    not_found_vial_identifiers: string[];
}

export function formatVialsTableColumns(
    table: ReactTableModel<Vial> | ReactTableModel<ECMSearchResult>,
    isSearch = false
) {
    table.setColumnWidth('id', 80);
    table.setColumnWidth('batch_identifier', 180);
    table.setColumnWidth('barcode', 140);
    if (isSearch) {
        table.setColumnWidth('well' as any, 80);
        table.setHiddenColumns(['plate_purpose' as any]);
    }
    table.setColumnWidth('status', 120);
    table.setColumnWidth('location', isSearch ? 180 : 300);
    // table.setColumnWidth('kind', 120);
    table.setColumnWidth('sample', 240);
    table.setColumnWidth('batch_purity' as any, 125);
    table.setColumnWidth('project' as any, 160);
    table.setColumnWidth('tare_mass', 120);
    table.setColumnWidth('created_by', 200);
    table.setColumnWidth('modified_on', 200);

    const columns = (table as ReactTableModel<ECMSearchResult>).dataframe.columns;

    if (columns.includes('total_requested')) {
        table.setColumnWidth('total_requested' as any, 240);
    }
    if (columns.includes('wizard')) {
        table.setHiddenColumns(isSearch ? (['plate_purpose', 'wizard'] as any) : (['wizard'] as any));
    }

    table.setHiddenColumns(['plate_description' as any]);
}

export interface PlateDetails {
    plate: Plate;
    batches: ReactTableModel<Batch>;
    compounds: CompoundFromDF[];
    experiment?: HTEExperimentInfo;
}

export function formatPlatesTableColumns(table: ReactTableModel<PlateRow>) {
    table.setColumnWidth('id', 80);
    table.setColumnWidth('barcode', 170);
    table.setColumnWidth('status', 120);
    table.setColumnWidth('location', 240);
    table.setColumnWidth('size', 90);
    table.setColumnWidth('kind', 120);
    table.setColumnWidth('purpose', 160);
    table.setColumnWidth('description', 280);
    table.setColumnWidth('created_by', 200);
    table.setColumnWidth('created_on', 120);
    table.setColumnWidth('modified_on', 200);

    table.reorderColumns([
        'id',
        'barcode',
        'kind',
        'size',
        'location',
        'status',
        'purpose',
        'created_on',
        'description',
        'created_by',
        'modified_on',
    ]);
}

export interface PreparePlateUploadEntry {
    input_barcode: string;
    source_barcodes: string[];
    well_batch_identifiers: string[];

    errors: string[];
    warnings: string[];

    barcode?: string;
    kind: Plate['kind'];
    size?: WellLayout;
    parent_plate_ids?: number[];
    samples: (SampleCreate | null)[];
    control_kinds?: (PlateControlKind | null | undefined)[];
    purpose?: string;
    description?: string;
    request_identifier?: string;
    transfers: { barcode: string; well?: string; volume_l: number }[];
}

export interface PreparePlateUploadData {
    plates: PreparePlateUploadEntry[];

    experiment_id?: number;

    // This data is not used in the frontend
    plate_transfers?: any[];
    vial_transfers?: any[];
}

export interface VirscidianExportOptions {
    rack_code?: string;
    preset: 'PreQC' | 'FinalQC';
    chemical_stability: 'acid' | 'base';
    salt?: string;
    project?: string;
}

export interface BatchIdentifierInvalidation {
    existing: string[];
    non_existing: string[];
    invalid: string[];
    all_identifiers: Record<string, BatchIdentifiers>;
}

export interface IdentifierInvalidation extends BatchIdentifierInvalidation {
    barcode_to_universal_batch_identifer: Record<string, string>;
    // normalized barcodes
    barcodes: Record<string, string>;
}

export interface QueryBatchesResult {
    batches: Batch[];
    compounds?: CompoundDetail[];
}

export interface QueryIdentifiersResult {
    batches: Batch[];
    compounds: CompoundDetail[];
}

export interface VolumeCheckRow {
    barcode: string;
    kind: string;
    description: string;
    created_by: string;
    created_on: DateLike;
}

export interface DisposeCheckRow {
    barcode: string;
    volume: number;
}

export interface SolubilizeVialRacksInput {
    rack_barcode: string;
    rack_well: string;

    barcode: string;
    concentration: number | string;
    solvent: string;
}

export interface SolubilizeVialRacksOutput {
    errors: string[];
    solubilized_vials: Vial[];
}

export const ECMApi = {
    listVials: async (): Promise<ReactTableModel<Vial>> => {
        const { data } = await api.client.get('ecm/vials', { responseType: 'arraybuffer' });
        const decoded = decodeEntosMsgpack(data, { eoi: 'strip' });
        const table = new ReactTableModel(decoded, Vial);
        formatVialsTableColumns(table);
        return table;
    },
    query: async (options: {
        identifiers: string[];
        query_plates: boolean;
        exclude_disposed_plates?: boolean;
        skip_not_found?: boolean;
    }): Promise<ECMQueryResult> => {
        const { data } = await api.client.post('ecm', options, { responseType: 'arraybuffer' });
        const decoded = decodeEntosMsgpack(data, { eoi: 'strip' });

        const results = new ReactTableModel<ECMSearchResult>(decoded.results_df, ECMSearchResult);
        formatVialsTableColumns(results, true);
        const compounds = createCompoundArray(decoded.compounds_df);
        const batches = createBatchArray(decoded.batches_df);

        return {
            results,
            compounds: Object.fromEntries(compounds.map((x) => [x.id, x])),
            batches: Object.fromEntries(batches.map((x) => [x.id, x])),
            invalid_identifiers: decoded.invalid_identifiers,
            not_found_vial_identifiers: decoded.not_found_vial_identifiers,
        };
    },
    vialEvents: async (id: number): Promise<{ events: VialEvent[] }> => {
        const { data } = await api.client.get(`ecm/vials/${id}/events`, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data, { eoi: 'strip' });
    },
    listPlates: async (): Promise<ReactTableModel<PlateRow>> => {
        const { data } = await api.client.get('ecm/plates', { responseType: 'arraybuffer' });
        const decoded = decodeEntosMsgpack(new Uint8Array(data), { eoi: 'strip' });
        const ret = new ReactTableModel(decoded, PlateRow);
        formatPlatesTableColumns(ret);
        return ret;
    },
    filterPlates: async (options: { id: number[] }): Promise<ReactTableModel<PlateRow>> => {
        const { data } = await api.client.post('ecm/plates/filter', options, { responseType: 'arraybuffer' });
        const decoded = decodeEntosMsgpack(data, { eoi: 'strip' });
        const ret = new ReactTableModel(decoded, PlateRow);
        formatPlatesTableColumns(ret);
        return ret;
    },
    getPlateDetails: async (id: number): Promise<PlateDetails> => {
        const { data } = await api.client.get(`ecm/plates/${id}`, { responseType: 'arraybuffer' });
        const decoded = decodeEntosMsgpack(new Uint8Array(data), { eoi: 'strip' });
        const ret: PlateDetails = {
            plate: decoded.plate,
            batches: new ReactTableModel(decoded.batches_df),
            compounds: columnDataTableStore<CompoundFromDF>(decoded.compounds_df).toObjects(),
            experiment: decoded.experiment,
        };
        return ret;
    },
    queryPlates: async (options: { barcodes?: string[]; ids?: number[] }): Promise<Plate[]> => {
        const { data } = await api.client.post('ecm/plates', options, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data, { eoi: 'strip' });
    },
    createPlate: async (plate: PlateCreate): Promise<Plate> => {
        const { data } = await api.client.post('ecm/plates', { plate }, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data, { eoi: 'strip' });
    },
    preparePlateUpload: async (
        file: File,
        overrides?: { 'request identifier'?: string }
    ): Promise<PreparePlateUploadData> => {
        const formData = new FormData();
        formData.append('file', file);
        if (overrides?.['request identifier']) {
            formData.append('overrides', JSON.stringify(overrides));
        }
        const { data } = await api.client.post('ecm/plates/prepare-upload', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data, { eoi: 'hex' });
    },
    plateEvents: async (id: number): Promise<{ events: PlateEvent[] }> => {
        const { data } = await api.client.get(`ecm/plates/${id}/events`, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data, { eoi: 'strip' });
    },
    uploadPlates: async (data: PreparePlateUploadData) => {
        await api.client.post(`ecm/plates/upload`, { data }, { responseType: 'arraybuffer' });
    },
    vialsNotifySlack: async (options: { barcodes: string[]; note: string }) => {
        await api.client.post(`ecm/vials/notify-slack`, options, { responseType: 'arraybuffer' });
    },
    virscidianPlateExportCSV: async (
        id: number,
        options: VirscidianExportOptions
    ): Promise<{ csv: string; warnings: string[] }> => {
        const { data } = await api.client.post(
            `ecm/plates/${id}/virscidian`,
            { options, kind: 'csv' },
            { responseType: 'arraybuffer' }
        );
        return decodeEntosMsgpack(data);
    },
    virscidianPlateExportJSON: async (
        id: number,
        options: VirscidianExportOptions
    ): Promise<{ submission: any; warnings: string[] }> => {
        const { data } = await api.client.post(
            `ecm/plates/${id}/virscidian`,
            { options, kind: 'json' },
            { responseType: 'arraybuffer' }
        );
        return decodeEntosMsgpack(data);
    },
    freebaseSDFPlateExport: async (id: number): Promise<Uint8Array> => {
        const { data } = await api.client.get(`ecm/plates/${id}/freebase-sdf`, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data).data;
    },
    invalidateBatchIdentifiers: async (identifiers: string[]): Promise<BatchIdentifierInvalidation> => {
        const { data } = await api.client.post(
            `ecm/invalidate-batch-identifiers`,
            { identifiers },
            { responseType: 'arraybuffer' }
        );
        return decodeEntosMsgpack(data);
    },
    invalidateIdentifiers: async (identifiers: string[]): Promise<IdentifierInvalidation> => {
        const { data } = await api.client.post(
            `ecm/invalidate-identifiers`,
            { identifiers },
            { responseType: 'arraybuffer' }
        );
        return decodeEntosMsgpack(data);
    },
    queryIdentifiers: async (options: { identifiers?: string[] }): Promise<QueryIdentifiersResult> => {
        let { data } = await api.client.post(`ecm/query-identifiers`, options, { responseType: 'arraybuffer' });
        data = decodeEntosMsgpack(data);
        const batches = data.batches ? columnDataTableStore(data.batches).toObjects().map(batchFromDFToBatch) : [];
        const compounds = data.compounds
            ? columnDataTableStore(data.compounds).toObjects().map(compoundFromDFToCompound)
            : [];
        return { batches, compounds };
    },
    queryBatches: async (options: {
        identifiers?: string[];
        ids?: number[];
        query_all?: boolean;
        query_compounds?: boolean;
    }): Promise<QueryBatchesResult> => {
        let { data } = await api.client.post(`ecm/query-batches`, options, { responseType: 'arraybuffer' });
        data = decodeEntosMsgpack(data);

        const batches = data.batches ? columnDataTableStore(data.batches).toObjects().map(batchFromDFToBatch) : [];
        const compounds = !data.compounds
            ? undefined
            : columnDataTableStore(data.compounds).toObjects().map(compoundFromDFToCompound);

        return { batches, compounds };
    },
    querySubstances: async (options: { ids: number[] }): Promise<Substance[]> => {
        const { data } = await api.client.post(`ecm/query-substances`, options, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data, { eoi: 'strip' });
    },
    queryHolders: async (options: { barcodes: string[] }): Promise<{ vials: Vial[]; plates: Plate[] }> => {
        const { data } = await api.client.post(`ecm/query-holders`, options, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data, { eoi: 'hex' });
    },
    validatePlateBarcodes: async (options: { barcodes: string[] }): Promise<void> => {
        await api.client.post(`ecm/validate-plate-barcodes`, options, { responseType: 'arraybuffer' });
    },
    registerCompounds: async (options: { smiles: string[]; project: string }): Promise<CompoundDetail[]> => {
        const { data } = await api.client.post(`ecm/register-compounds`, options, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data, { eoi: 'strip' });
    },
    disposeLabware: async (barcodes: string[]): Promise<void> => {
        await api.client.post(
            `ecm/dispose-labware`,
            { barcodes },
            {
                responseType: 'arraybuffer',
            }
        );
    },
    volumeCheck: async (): Promise<DataTableStore<VolumeCheckRow>> => {
        const data = await api.getMsgpack(`ecm/tube-volume-check`);
        return columnDataTableStore(data);
    },
    disposeCheck: async (): Promise<DataTableStore<DisposeCheckRow>> => {
        const data = await api.getMsgpack(`ecm/tube-dispose-check`);
        return columnDataTableStore(data);
    },
    solubilizeVialRacks: async (options: {
        inputs: SolubilizeVialRacksInput[];
        event_context?: string;
        allow_update?: boolean;
    }): Promise<SolubilizeVialRacksOutput> => {
        const { data } = await api.client.post(`ecm/solubilize-vial-racks`, options, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data, { eoi: 'strip' });
    },
    parseRacks: async (files: File[]): Promise<ECMVialRack[]> => {
        const formData = new FormData();
        files.forEach((file) => formData.append('files', file));

        const { data } = await api.client.post('ecm/parse-racks', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data);
    },
};
