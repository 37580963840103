import { isBlank } from '../../../lib/util/misc';
import { ColumnsFor, determineColumnType } from '../column';
import { ColumnTableData, DataTableStore, MultiSortColumn } from './base';

export function objectsToColumnTableData<T>(xs: ArrayLike<T>, columns: (keyof T)[]): ColumnTableData<T> {
    const data: any[][] = [];
    const index: number[] = [];

    for (const _ of columns) {
        data.push([]);
    }

    for (let i = 0; i < xs.length; i++) {
        index.push(i);
        const o = xs[i];
        for (let cI = 0; cI < columns.length; cI++) {
            data[cI].push(o ? o[columns[cI]] : null);
        }
    }

    return { columns, data, index };
}

export function columnDataTableStore<T = any>(table: ColumnTableData<T>): DataTableStore<T> {
    return new ColumnDataTableStore(table);
}

export function columnDataTableStoreFromObjects<T = any>(objs: ArrayLike<T>, columns: (keyof T)[]): DataTableStore<T> {
    const data = objectsToColumnTableData(objs, columns);
    return new ColumnDataTableStore(data);
}

export function determineColumnsForDataTableStore<T extends {}>(table: DataTableStore<T>) {
    const schema: ColumnsFor<T> = {};
    for (const col of table.columnNames) {
        (schema as any)[String(col)] = determineColumnType(table.getColumnValues(col));
    }
    return schema;
}

export class ColumnDataTableStore<T = any> implements DataTableStore<T> {
    private columnData = new Map<keyof T, any[]>();
    private valueIndices = new Map<keyof T, Map<any, number>>();
    private columns: (keyof T)[];

    version = 0;
    rowCount = 0;

    private getValueIndex(name: keyof T) {
        let index = this.valueIndices.get(name);
        if (index) return index;
        index = new Map();
        const values = this.columnData.get(name);
        if (!values) return undefined;
        for (let i = 0; i < values.length; i++) {
            index.set(values[i], i);
        }
        return index;
    }

    get columnNames() {
        return this.columns;
    }

    getValue<K extends keyof T>(columnName: K, rowIndex: number): T[K] {
        return this.columnData.get(columnName)![rowIndex];
    }

    tryGetValue<K extends keyof T>(columnName: K, rowIndex: number): T[K] | undefined {
        return this.columnData.get(columnName)?.[rowIndex];
    }

    setValue<K extends keyof T>(columnName: K, rowIndex: number, value: T[K]): void {
        const col = this.columnData.get(columnName)!;
        const oldValue = col[rowIndex];
        col[rowIndex] = value;
        const index = this.valueIndices.get(columnName);
        if (index && oldValue !== value) {
            index.delete(oldValue);
            index.set(value, rowIndex);
        }
        this.version++;
    }

    hasColumn(columnName: keyof T): boolean {
        return this.columnData.has(columnName);
    }

    filter<K extends keyof T>(
        columnName: K,
        test: (v: T[K], value: any) => boolean,
        value: any,
        indices: number[]
    ): number[] {
        const arr = this.columnData.get(columnName)!;
        const ret: number[] = [];
        for (const i of indices) {
            if (test(arr[i], value)) ret.push(i);
        }
        return ret;
    }

    filterAny<K extends keyof T>(
        tests: [K, (v: T[K], value: any) => boolean][],
        value: any,
        indices: number[]
    ): number[] {
        const cols = tests.map((c) => [this.columnData.get(c[0])!, c[1]] as const);
        const ret: number[] = [];
        for (const i of indices) {
            for (const col of cols) {
                if (col[1](col[0][i], value)) {
                    ret.push(i);
                    break;
                }
            }
        }
        return ret;
    }

    sort<K extends keyof T>(
        columnName: K,
        compare: (a: T[K], b: T[K]) => number,
        desc: boolean,
        indices: number[]
    ): void {
        const arr = this.columnData.get(columnName)!;
        const sign = desc ? -1 : 1;

        indices.sort((a, b) => {
            const result = compare(arr[a], arr[b]);
            // only swap the sign of the result if we are comparing non-blank things
            // that way, blanks will always go to the bottom regardles of desc
            const cmp = isBlank(arr[a]) || isBlank(arr[b]) ? result : sign * result;
            // compare indices if equal so the sort is stable
            if (!cmp) return a - b;
            return cmp;
        });
    }

    multiSort<K extends keyof T>(columns: MultiSortColumn<T, K>[], indices: number[]): void {
        const count = columns.length;

        if (count === 0) {
            return;
        }
        if (count === 1) {
            this.sort(columns[0][0], columns[0][1], columns[0][2], indices);
            return;
        }

        const arrs = columns.map((c) => this.columnData.get(c[0])!);
        const compares = columns.map((c) => c[1]);
        const signs = columns.map((c) => (c[2] ? -1 : 1));

        indices.sort((a, b) => {
            for (let i = 0; i < count; i++) {
                const arr = arrs[i];
                const compare = compares[i];

                const result = compare(arr[a], arr[b]);
                // only swap the sign of the result if we are comparing non-blank things
                // that way, blanks will always go to the bottom regardles of desc
                const cmp = isBlank(arr[a]) || isBlank(arr[b]) ? result : signs[i] * result;
                if (cmp) return cmp;
            }
            return a - b;
        });
    }

    getRow(rowIndex: number): T {
        const ret = Object.create(null);
        for (const c of this.columns) {
            ret[c] = this.columnData.get(c)![rowIndex];
        }
        return ret;
    }

    getColumnValues<K extends keyof T>(columnName: K, indices?: number[]): readonly T[K][] {
        if (indices) {
            const ret: any[] = [];
            const col = this.columnData.get(columnName)!;
            for (const i of indices) ret.push(col[i]);
            return ret;
        }
        return this.columnData.get(columnName) ?? [];
    }

    findValueIndex<K extends keyof T>(columnName: K, value: T[K]): number {
        const valueIndex = this.getValueIndex(columnName);
        if (valueIndex) return valueIndex.get(value) ?? -1;
        return -1;
    }

    insertRow(row: T, index?: number | undefined): void {
        this.valueIndices.clear();
        const at = index ?? this.rowCount;
        for (const c of this.columns) {
            this.columnData.get(c)!.splice(at, 0, row[c]);
        }
        this.rowCount++;
        this.version++;
    }

    updateRow(row: Partial<T>, index: number, options?: { type?: 'partial' | 'full' }): void {
        this.valueIndices.clear();
        for (const c of this.columns) {
            const data = this.columnData.get(c)!;
            const updated = [...data];
            updated[index] = options?.type === 'partial' ? row[c] ?? data[index] : row[c];
            this.columnData.set(c, updated);
            this.valueIndices.delete(c);
        }
        this.version++;
    }

    deleteRow(index: number): void {
        this.valueIndices.clear();
        for (const c of this.columns) {
            this.columnData.get(c)!.splice(index, 1);
        }
        if (this.rowCount) this.rowCount--;
        this.version++;
    }

    addOrUpdateColumn<K extends keyof T>(columnName: K, values: ArrayLike<T[K]>): void {
        if (values.length !== this.rowCount) {
            throw new Error('values must have the same number of rows as the table.');
        }
        if (!this.columnData.has(columnName)) this.columns.push(columnName);
        this.valueIndices.delete(columnName);
        this.columnData.set(columnName, values as any);
        this.version++;
    }

    removeColumn<K extends keyof T>(columnName: K): void {
        if (!this.columnData.has(columnName)) {
            return;
        }

        this.columns = this.columns.filter((c) => c !== columnName);
        this.valueIndices.delete(columnName);
        this.columnData.delete(columnName);
        this.version++;
    }

    toColumnTableData(): ColumnTableData<T, number, keyof T> {
        return {
            index: new Array(this.rowCount).fill(0).map((_, i) => i),
            columns: [...this.columns],
            data: this.columns.map((c) => [...this.columnData.get(c)!]),
        };
    }

    filterIndices(filter: (table: DataTableStore<T>, index: number) => boolean): number[] {
        const ret: number[] = [];
        for (let rowIdx = 0; rowIdx < this.rowCount; rowIdx++) {
            if (filter(this, rowIdx)) {
                ret.push(rowIdx);
            }
        }
        return ret;
    }

    toObjects(options?: {
        indices?: ArrayLike<number>;
        filter?: (table: DataTableStore<T>, index: number) => boolean;
        columns?: (keyof T)[];
    }): T[] {
        const ret: T[] = [];
        const filter = options?.filter;
        const columns = options?.columns ?? this.columns;

        if (options?.indices) {
            for (let i = 0, _i = options.indices.length; i < _i; i++) {
                const rowIdx = options.indices[i];
                if (filter && !filter(this, rowIdx)) {
                    continue; // eslint-disable-line
                }
                const obj = Object.create(null);
                for (const c of columns) {
                    obj[c] = this.getValue(c, rowIdx);
                }
                ret.push(obj);
            }
        } else {
            for (let rowIdx = 0; rowIdx < this.rowCount; rowIdx++) {
                if (filter && !filter(this, rowIdx)) {
                    continue; // eslint-disable-line
                }
                const obj = Object.create(null);
                for (const c of columns) {
                    obj[c] = this.getValue(c, rowIdx);
                }
                ret.push(obj);
            }
        }

        return ret;
    }

    setRows(rows: ArrayLike<T>): void {
        const data = objectsToColumnTableData(rows, this.columns);
        this.setSnapshot(data);
    }

    clear() {
        this.valueIndices.clear();
        this.columnData.clear();
        this.version++;
        this.rowCount = 0;
    }

    getSnapshot(): any {
        return this.toColumnTableData();
    }

    setSnapshot(table: ColumnTableData<T>) {
        this.valueIndices.clear();
        this.columnData.clear();
        this.version++;

        for (let i = 0; i < table.columns.length; i++) {
            this.columnData.set(table.columns[i], table.data[i].slice());
        }
        this.columns = [...table.columns];
        this.rowCount = table.data[0] ? table.data[0].length : 0;
    }

    constructor(table: ColumnTableData<T>) {
        for (let i = 0; i < table.columns.length; i++) {
            this.columnData.set(table.columns[i], table.data[i]);
        }
        this.columns = table.columns;
        this.rowCount = table.data[0] ? table.data[0].length : 0;
    }
}
