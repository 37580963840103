import { faInfoCircle, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useRef, useEffect, ReactNode, CSSProperties, RefObject } from 'react';
import { Button, Form } from 'react-bootstrap';
import { CompactPicker } from 'react-color';
import { PopoverWrapper, TooltipWrapper } from './Tooltips';
import { IconButton } from './IconButton';

interface TextInputProps<T> {
    value: T;
    placeholder?: string;
    size?: 'sm' | 'lg';
    disabled?: boolean;
    className?: string;
    textarea?: boolean;
    numeric?: boolean;
    validation?: string;
    style?: CSSProperties;
    readOnly?: boolean;
    title?: string;
    formatValue?: (v: T) => string;
    tryUpdateValue?: (v: string) => T | undefined;
    setValue?: (v: T) => any;
    emptyOnBlur?: boolean;
    immediate?: boolean;
    autoFocus?: boolean;
    selectOnFocus?: boolean;
    isClearable?: boolean;
    onBlur?: () => void;
    onEnter?: () => void;
    rows?: number;
    setValueBeforeUnmount?: boolean;
    inputRef?: RefObject<HTMLElement>;
}

export function TextInput<T>({
    value,
    className,
    style,
    size,
    textarea,
    numeric,
    validation,
    formatValue,
    tryUpdateValue,
    readOnly,
    title,
    setValue,
    placeholder,
    disabled,
    emptyOnBlur,
    immediate,
    autoFocus,
    selectOnFocus,
    isClearable = false,
    onBlur,
    onEnter,
    rows = 3,
    setValueBeforeUnmount,
    inputRef,
}: TextInputProps<T>) {
    const formatted = formatValue?.(value) ?? `${value ?? ''}`;
    const [current, setCurrent] = useState(formatted);
    const changed = useRef<boolean>(false);
    const currentRef = useRef<string>('');

    currentRef.current = current;

    useEffect(() => {
        if (!setValueBeforeUnmount) return;
        return () => {
            if (changed.current) {
                updateValue(currentRef.current);
            }
        };
    }, [setValueBeforeUnmount]);

    useEffect(() => {
        changed.current = false;
        setCurrent(formatValue?.(value) ?? `${value ?? ''}`);
    }, [value]);

    const updateValue = (currentValue?: any) => {
        if (!tryUpdateValue) {
            setValue?.(currentValue as any);
            return;
        }

        const newValue = tryUpdateValue(currentValue);
        if (newValue !== undefined) {
            setValue?.(newValue as any);
            if (emptyOnBlur) {
                setCurrent('');
            } else {
                setCurrent(formatValue?.(value) ?? `${value ?? ''}`);
            }
        } else if (emptyOnBlur) {
            setCurrent('');
        } else {
            setCurrent(formatValue?.(value) ?? `${value ?? ''}`);
        }
    };

    return (
        <>
            <Form.Control
                className={className}
                as={textarea ? 'textarea' : undefined}
                value={changed.current ? current : formatted}
                size={size}
                style={style}
                placeholder={placeholder}
                disabled={disabled}
                rows={textarea ? rows : 1}
                autoFocus={autoFocus}
                onChange={(e) => {
                    changed.current = true;
                    setCurrent(e.target.value);
                    if (immediate) updateValue(e.target.value);
                }}
                onClick={(e) => e.stopPropagation()}
                isValid={validation !== undefined ? !validation : undefined}
                isInvalid={validation !== undefined ? !!validation : undefined}
                readOnly={readOnly}
                title={title}
                onFocus={(e) => {
                    if (selectOnFocus) e.target.select();
                }}
                onBlur={() => {
                    if (!changed.current) {
                        if (immediate) onBlur?.();
                        return;
                    }
                    changed.current = false;
                    if (tryUpdateValue?.(current) === null) {
                        setCurrent('');
                    }
                    updateValue(current);
                    onBlur?.();
                }}
                onKeyDown={(e) => {
                    const isEnter = e.key === 'Enter';
                    if ((textarea && isEnter && e.ctrlKey) || (!textarea && isEnter)) {
                        (e.target as HTMLInputElement).blur();
                        if (onEnter) {
                            setTimeout(onEnter, 0);
                        }
                    }
                }}
                type={numeric ? 'number' : 'text'}
                ref={inputRef as any}
            />
            {!!validation && <span className='invalid-feedback'>{validation}</span>}
            {isClearable && (
                <IconButton
                    icon={faX}
                    className='position-absolute end-0 fw-bold text-faint px-0 py-1'
                    style={{ transform: 'translateY(-100%) scale(0.75)', visibility: current ? 'visible' : 'hidden' }}
                    size='sm'
                    onClick={() => {
                        changed.current = true;
                        setCurrent('');
                        updateValue('');
                    }}
                />
            )}
        </>
    );
}

interface SimpleSelectOptionInputProps<T extends string | number> {
    value: T;
    size?: 'sm' | 'lg';
    disabled?: boolean;
    className?: string;
    validation?: string;
    allowEmpty?: boolean;
    emptyPlaceholder?: string;
    style?: CSSProperties;
    options: ReadonlyArray<readonly [value: T, label: string, optionLabel?: string]>;
    setValue: (v: T) => any;
    title?: string;
    useCurrentValueTitle?: boolean;
    invalidOptionLabel?: string;
}

export function SimpleSelectOptionInput<T extends string | number>({
    value,
    size,
    allowEmpty,
    emptyPlaceholder,
    style,
    disabled,
    className,
    validation,
    options,
    setValue,
    title,
    useCurrentValueTitle,
    invalidOptionLabel,
}: SimpleSelectOptionInputProps<T>) {
    const currentOption = value && options.find((o) => o[0] === value);
    const isInvalidValue = value && !currentOption;
    const currentTitle = useCurrentValueTitle ? currentOption?.[1] : undefined;
    return (
        <>
            <Form.Select
                value={`${value}`}
                size={size}
                className={isInvalidValue ? `${className} text-warning` : className}
                disabled={disabled}
                isValid={validation !== undefined ? !validation : undefined}
                isInvalid={validation !== undefined ? !!validation : undefined}
                style={style}
                onChange={(e) => {
                    if (allowEmpty && e.target.value === '') {
                        setValue('' as any);
                        return;
                    }
                    const opt = options.find(([v]) => `${v}` === e.target.value);
                    if (opt) setValue(opt[0]);
                }}
                title={title || currentTitle}
            >
                {allowEmpty && (
                    <option value='' style={{ backgroundColor: 'var(--bs-input-bg)' }}>
                        {emptyPlaceholder}
                    </option>
                )}
                {isInvalidValue && (
                    <option value={value} className='text-warning' style={{ backgroundColor: 'var(--bs-input-bg)' }}>
                        {invalidOptionLabel ?? `Invalid Value: ${value}`}
                    </option>
                )}
                {options.map(([v, label, optionLabel], i) => (
                    <option
                        key={i}
                        value={`${v}`}
                        style={{ color: 'var(--bs-body-color)', backgroundColor: 'var(--bs-input-bg)' }}
                    >
                        {v === value ? label : optionLabel ?? label}
                    </option>
                ))}
            </Form.Select>
            {!!validation && <span className='invalid-feedback'>{validation}</span>}
        </>
    );
}

interface LabeledInputProps {
    label: string;
    className?: string;
    children: ReactNode;
    tooltip?: ReactNode;
    labelClassName?: string;
    labelWidth?: string | number;
    labelAlign?: 'flex-start' | 'center';
}

export function LabeledInput({
    label,
    className,
    labelClassName,
    children,
    tooltip,
    labelWidth,
    labelAlign,
}: LabeledInputProps) {
    const labelStyle: CSSProperties = {};
    if (labelWidth) {
        labelStyle.width = labelWidth;
        labelStyle.minWidth = labelWidth;
        labelStyle.maxWidth = labelWidth;
    }
    if (labelAlign === 'flex-start') {
        labelStyle.marginTop = 6;
    }
    return (
        <div className={`hstack gap-2 ${className ?? ''}`} style={{ alignItems: labelAlign }}>
            {!!tooltip && (
                <TooltipWrapper tooltip={tooltip}>
                    {(props) => (
                        <Form.Label
                            className={`text-secondary m-0 ${labelClassName ?? ''}`}
                            style={labelStyle}
                            {...props}
                        >
                            {label}
                            <FontAwesomeIcon icon={faInfoCircle} className='ms-1' size='sm' />
                        </Form.Label>
                    )}
                </TooltipWrapper>
            )}
            {!tooltip && (
                <Form.Label className={`text-secondary m-0 ${labelClassName ?? ''}`} style={labelStyle}>
                    {label}
                </Form.Label>
            )}
            {children}
        </div>
    );
}

export function InlineLabeledInput({
    label,
    className,
    size = 'xs',
    children,
}: {
    label: string;
    className?: string;
    size?: 'sm' | 'xs';
    children: ReactNode;
}) {
    return (
        <div className={className}>
            <div className={`text-secondary ${size === 'sm' ? 'font-body-xsmall' : 'font-body-xxsmall'}`}>{label}</div>
            <div className='position-relative'>{children}</div>
        </div>
    );
}

interface SimpleColorPickerProps {
    className?: string;
    value: string; // hex string
    size?: number;
    disabled?: boolean;
    onChange: (v: string) => void;
}

export function SimpleColorPicker({ className = '', value, size = 18, onChange, disabled }: SimpleColorPickerProps) {
    const [current, setCurrent] = useState(value);

    useEffect(() => {
        if (current !== value) {
            setCurrent(value);
        }
    }, [value]);

    return (
        <PopoverWrapper
            popoverHeader={null}
            popoverBody={
                <div className='entos-compact-color-picker'>
                    <CompactPicker
                        color={current}
                        onChange={(c) => setCurrent(c.hex)}
                        onChangeComplete={(c) => {
                            onChange(c.hex);
                        }}
                    />
                </div>
            }
        >
            {(show) => (
                <Button
                    className={className}
                    variant='outline-primary'
                    size='sm'
                    onClick={show}
                    title='Pick color'
                    style={{
                        backgroundColor: current,
                        minWidth: size,
                        width: size,
                        minHeight: size,
                        height: size,
                    }}
                    disabled={disabled}
                />
            )}
        </PopoverWrapper>
    );
}
