/* eslint-disable jsx-a11y/click-events-have-key-events */
import { BehaviorSubject } from 'rxjs';
import { LabeledInput, TextInput } from '../../../components/common/Inputs';
import useBehavior from '../../../lib/hooks/useBehavior';
import { arrayEllipsis } from '../../../lib/util/misc';
import { roundValue } from '../../../lib/util/roundValues';
import { ECMApi } from '../../ECM/ecm-api';
import {
    HTEDEnumeration,
    HTEDReaction,
    HTEPProductWell,
    HTERAddReactant,
    HTERReactantInstance,
    HTERReactantNameT,
} from '../data-model';

export function toUnit(value: string | number | null, f: number, unit: string) {
    if (typeof value === 'string') return value;
    if (typeof value === 'number') return `${roundValue(5, value * f)}${unit}`;
    return '';
}

export function getReactionTotalVolumeInL(reaction: HTEDReaction, enumeration?: HTEDEnumeration) {
    const {
        template: { reaction: procedure },
    } = reaction;

    let productEq = Number.POSITIVE_INFINITY;

    let reactants = 0;
    let backfill = 0;
    for (const instr of procedure.instructions) {
        if (instr.kind === 'add') {
            reactants += getAddReactantVolumeInL(reaction, instr)[0];
            if (enumeration?.reactant_names.includes(instr.name) && instr.sample.equivalence) {
                productEq = Math.min(instr.sample.equivalence, productEq);
            }
        } else if (instr.kind === 'backfill') {
            backfill = (instr.volume as number) * 1e3;
        }
    }

    let baseproductConcentration: number | undefined;
    if (Number.isFinite(productEq) && reactants > 0) {
        const nmol = productEq * reaction.template.scale;
        baseproductConcentration = nmol / reactants;
    }

    const total = Math.max(reactants, backfill);
    const productConcentration = baseproductConcentration ? (reactants / total) * baseproductConcentration : undefined;

    return { reactants, total, backfill, productConcentration };
}

export function getAddReactantVolumeInL(
    reaction: HTEDReaction,
    instr: HTERAddReactant
): [final: number, solvent: number] {
    const {
        template: { reactants, scale },
    } = reaction;
    const rSample = reactants[instr.name]?.sample;

    // TODO: add support for ranges of volumes from reactant lists
    // const block = this.design.productBlocks.findBlock(product_block_id);
    // const list = block?.state.lists.value.find((l) => l.reactantName === instr.name);

    const conc = rSample?.concentration || instr.sample.concentration;
    const eq = rSample?.equivalence || instr.sample.equivalence;
    const neat = rSample?.neat_concentration || instr.sample.neat_concentration || conc;
    const vol = rSample?.volume || instr.sample.volume;

    if (typeof vol === 'number') {
        return [vol * 1e3, 0];
    }

    const finalConc = conc || neat;
    if (typeof eq !== 'number' || typeof finalConc !== 'number') {
        return [Number.NaN, 0];
    }

    const final = (eq * scale) / finalConc;
    const base = (eq * scale) / (neat as number)!;
    return [final, final - base];
}

export async function getReactantInstances(identifiers: string[]) {
    const validation = await ECMApi.invalidateIdentifiers(identifiers);
    if (validation.invalid.length) {
        throw new Error(`Invalid identifier(s): ${arrayEllipsis(validation.invalid)}`);
    }
    if (validation.non_existing.length) {
        throw new Error(`Non-existent: ${arrayEllipsis(validation.non_existing)}`);
    }

    const instances: HTERReactantInstance[] = [];
    for (const x of identifiers) {
        const universal = validation.all_identifiers[x]?.universal_identifier;
        const fromBarcode = validation.barcode_to_universal_batch_identifer[x];
        if (!universal && !fromBarcode) throw new Error(`Missing universal identifier for ${x}`);
        instances.push({ identifier: universal || fromBarcode, barcode: validation.barcodes[x] });
    }

    return instances;
}

export interface ReactionInfo {
    wellLabel: string;
    well: HTEPProductWell;
    reaction: HTEDReaction;
    smiles: string;
    mainReactants: HTERReactantNameT[];
    reagents: HTERReactantNameT[];
}

export function GetNameDialogContent({ stateSubject }: { stateSubject: BehaviorSubject<{ name: string }> }) {
    const current = useBehavior(stateSubject);

    return (
        <div className='vstack gap-2'>
            <LabeledInput label='Name' labelWidth={160}>
                <TextInput
                    value={current.name}
                    placeholder='Enter an unique name...'
                    setValue={(v) => stateSubject.next({ ...current, name: v.trim() })}
                    autoFocus
                    selectOnFocus
                    immediate
                />
            </LabeledInput>
        </div>
    );
}

export function UsesMarkdown() {
    return (
        <div className='font-body-xsmall text-secondary'>
            Uses Markdown. See a{' '}
            <a href='https://www.markdownguide.org/basic-syntax/' target='_blank' rel='noreferrer'>
                guide
            </a>
            . Ctrl+Enter to confirm.
        </div>
    );
}
