import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AssayInformation from './pages/Assays/AssayInformation';
import Assays from './pages/Assays/Assays';
import BoxInformation from './pages/Boxes/BoxInformation';
import Boxes from './pages/Boxes/Boxes';
import { ChemDrawPage } from './pages/ChemDraw';
import CompoundInformation from './pages/Compounds/CompoundInformation';
import Compounds from './pages/Compounds/Compounds';
import DatasetInformation from './pages/DatasetInformation/DatasetInformation';
import Datasets from './pages/Datasets';
import { DataVizRouter } from './pages/Dataviz';
import { ECMRouter } from './pages/ECM/ECM';
import { EnvisionBIORouter } from './pages/EnvisionBIO';
import { HTERouter } from './pages/HTE';
import { IconTestPage } from './pages/Tests/IconTestPage';
import { MatrixChartTestPage } from './pages/Tests/MatrixChartTestPage';
import { TableTestPage } from './pages/Tests/TableTestPage';
import { AnalyticalRouter } from './pages/Analytical';
import 'react-day-picker/dist/style.css';

export default function AuthenticatedApp() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Navigate to='/assays' />} />
                <Route path='/oauth/*' element={<Navigate to={localStorage.getItem('ad_redirect_path') || '/'} />} />
                <Route path='/datasets' element={<Datasets />} />
                <Route path='/datasets/:id' element={<DatasetInformation />} />
                <Route path='/assays' element={<Assays />} />
                <Route path='/assays/:id' element={<AssayInformation />} />
                <Route path='/chemdraw' element={<ChemDrawPage />} />
                <Route path='/compounds' element={<Compounds />} />
                <Route path='/compounds/:identifier' element={<CompoundInformation />} />
                <Route path='/ecm/*' element={<ECMRouter />} />
                <Route path='/hte/*' element={<HTERouter />} />
                <Route path='/foundry-boxes' element={<Boxes />} />
                <Route path='/foundry-boxes/:kind/:name' element={<BoxInformation />} />
                <Route path='/envision-bio/*' element={<EnvisionBIORouter />} />
                <Route path='/workspaces/*' element={<DataVizRouter />} />
                <Route path='/analytical/*' element={<AnalyticalRouter />} />

                <Route path='/tests/table' element={<TableTestPage />} />
                <Route path='/tests/matrix' element={<MatrixChartTestPage />} />
                <Route path='/tests/icons' element={<IconTestPage />} />
            </Routes>
        </BrowserRouter>
    );
}
