import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { ChemDrawModalService } from '../../../lib/services/chemdraw-modal';
import { AsyncMoleculeDrawing } from '../AsyncMoleculeDrawing';
import { iiChemDrawLogo } from '../Icons';
import { TextInput } from '../Inputs';

export function SubstructureSearchDescription() {
    return (
        <>
            <p className='font-body-small mb-1'>Substitution variables:</p>
            <li className='font-body-small mb-1'>A - atoms C, O, S, or N</li>
            <li className='font-body-small mb-1'>X - halogens</li>
            <li className='font-body-small mb-1'>R - R-group decomposition</li>
            <p className='text-secondary font-body-xsmall mb-4'>
                Use R for any additional R-groups on the substructure (ex: [R]C1=CC=C([R])C=C1). A substructure search
                with R-group(s) must be unique within the molecule, otherwise a random selection will be made. It is
                possible to combine A, X, R substitutions on a single search.
            </p>
        </>
    );
}

interface SubstructureSearchInputProps {
    smiles: string;
    setSmiles: (smiles: string) => void;
    onEnter?: (smiles?: string) => void;
    disabled?: boolean;
}

export function SubstructureSearchInput({
    smiles,
    setSmiles,
    disabled = false,
    onEnter,
}: SubstructureSearchInputProps) {
    return (
        <div className='entos-substructure-search-input'>
            <TextInput
                placeholder='Enter substructure SMILES'
                disabled={disabled}
                value={smiles}
                setValue={setSmiles}
            />
            <Button
                className='font-body-small'
                variant='link'
                onClick={() =>
                    ChemDrawModalService.show({
                        initialSMILES: smiles,
                        onSMILESDrawn: (smilesStr: string) => {
                            setSmiles(smilesStr);
                            onEnter?.(smilesStr);
                        },
                    })
                }
            >
                <FontAwesomeIcon className='pe-none' icon={iiChemDrawLogo} color='var(--bs-primary)' fixedWidth />
            </Button>
        </div>
    );
}

export function SubstructureSearchTable({
    substructures,
    onSetSmiles,
    onDelete,
}: {
    substructures: string[];
    onSetSmiles: (smiles: string, idx: number) => void;
    onDelete: (idx: number) => void;
}) {
    return (
        <>
            <table className='entos-offset-line-table w-100 my-2'>
                <thead>
                    <tr>
                        <td>Substructure</td>
                        <td>Preview</td>
                        <td />
                    </tr>
                </thead>
                <tbody>
                    {substructures.map((substructure, idx) => (
                        <SubstructureSearchRow
                            key={`substructure-${substructure}-${idx}`}
                            smiles={substructure}
                            onSetSmiles={(smiles: string) => onSetSmiles(smiles, idx)}
                            onDelete={() => onDelete(idx)}
                        />
                    ))}
                </tbody>
            </table>
            <div className='w-100 text-end'>
                <Button
                    type='button'
                    className='p-1'
                    title='Add Substructure'
                    onClick={() => onSetSmiles('', substructures.length)}
                >
                    <FontAwesomeIcon icon={faPlus} fixedWidth />
                </Button>
            </div>
        </>
    );
}

function SubstructureSearchRow({
    smiles,
    onSetSmiles,
    onDelete,
}: {
    smiles: string;
    onSetSmiles: (smi: string) => void;
    onDelete: () => void;
}) {
    return (
        <tr>
            <td>
                <SubstructureSearchInput smiles={smiles} setSmiles={onSetSmiles} />
            </td>
            <td className='d-flex'>
                <AsyncMoleculeDrawing smiles={smiles} height={40} autosize showChemDraw={false} showCopy={false} />
            </td>
            <td className='align-top'>
                <button
                    type='button'
                    title='Delete Substructure'
                    className='bg-transparent border-0 text-light'
                    onClick={onDelete}
                >
                    <FontAwesomeIcon icon={faTrash} color='var(--bs-secondary)' fixedWidth />
                </button>
            </td>
        </tr>
    );
}
