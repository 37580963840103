import { AccountInfo, InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import log from 'loglevel';
import { BehaviorSubject } from 'rxjs';
import api from '../../api';
import { reportErrorAsToast } from '../util/errors';
import { EcosystemService } from './ecosystem';
import { ToastService } from './toast';

export type AuthState =
    | { kind: 'not-authenticated' }
    | { kind: 'in-progress' }
    | { kind: 'authenticated' }
    | { kind: 'interaction-required' }
    | { kind: 'error'; error: any };

export interface AuthExpirationState {
    isExpired: boolean;
    minimized?: boolean;
}

const TOKEN_INVALIDATE_INTERVAL = 30 * 1000; // 30s

class _AuthService {
    instance: PublicClientApplication | undefined = undefined;

    scopes: string[] = [];

    readonly state = new BehaviorSubject<AuthState>({ kind: 'not-authenticated' });
    readonly expiration = new BehaviorSubject<AuthExpirationState | undefined>(undefined);
    readonly account = new BehaviorSubject<AccountInfo | undefined>(undefined);

    numAttempts = 0;

    get currentAccount() {
        return this.instance?.getActiveAccount();
    }

    get providerId() {
        return this.currentAccount?.idTokenClaims?.sub;
    }

    get isAuthenticated() {
        return this.state.value.kind === 'authenticated' && !this.expiration.value?.isExpired;
    }

    setCurrentAccount(account: AccountInfo | null) {
        this.instance?.setActiveAccount(account);
        if (this.account.value?.username !== account?.username) {
            this.account.next(account ?? undefined);
        }
    }

    private _initCalled = false;
    async init() {
        if (this._initCalled) {
            log.warn('Auth init called multiple times, this should not happen.');
            return;
        }
        this._initCalled = true;

        const handshakeData = await api.auth.info();
        this.instance = new PublicClientApplication({
            auth: {
                clientId: handshakeData.client_id || '',
                authority: `https://login.microsoftonline.com/${handshakeData.tenant_id}`,
                redirectUri: `${window.location.origin}/auth/redirect`,
                navigateToLoginRequestUrl: false,
            },
            cache: { cacheLocation: 'localStorage' },
            system: {
                // The default values are 10s, try icreasing to 20 to see if
                // this reduces number of failed logins.
                loadFrameTimeout: 20000,
                iframeHashTimeout: 20000,
                windowHashTimeout: 20000,
                redirectNavigationTimeout: 20000,
            },
        });
        this.scopes = [`api://${handshakeData.client_id}/things:do`];

        try {
            this.state.next({ kind: 'in-progress' });

            await this.instance.initialize();
            const result = await this.instance.handleRedirectPromise();

            if (result?.accessToken && result.account) {
                const isTokenValid = await tryValidateAndSetToken(result.accessToken);
                if (isTokenValid) {
                    this.setCurrentAccount(result.account);
                    this.state.next({ kind: 'authenticated' });
                } else {
                    this.state.next({ kind: 'error', error: 'Invalid Login Attempt' });
                }

                let redirect = sessionStorage.getItem('auth_redirect_path') || '/';
                sessionStorage.removeItem('auth_redirect_path');
                if (redirect.startsWith('/auth/redirect')) redirect = '/';
                window.history.replaceState({}, '', redirect);
            } else {
                AuthService.acquireAndTestTokenSilent();
            }
        } catch (err) {
            log.error(err);
            this.state.next({ kind: 'error', error: err });
        }
    }

    async tryLoginPopup(kind: 'change' | 'refresh' | 'login') {
        let result;
        try {
            result = await this.instance?.acquireTokenPopup({
                scopes: this.scopes,
                prompt: kind === 'change' ? 'select_account' : undefined,
            });
        } catch (err) {
            reportErrorAsToast('Auth', err);
            return;
        }

        if (!result?.account) {
            reportErrorAsToast('Auth', 'Failed');
            return;
        }

        try {
            const tokenValid = await tryValidateAndSetToken(result?.accessToken);
            if (tokenValid) {
                this.setCurrentAccount(result.account);
                this.expiration.next(undefined);
                if (this.state.value.kind !== 'authenticated') {
                    this.state.next({ kind: 'authenticated' });
                }
            }
        } catch (err) {
            reportErrorAsToast('Auth', 'Failed to validate token');
        }

        if (kind !== 'login') {
            ToastService.show({
                type: 'success',
                message: `Logged in as ${result.account.username}`,
                timeoutMs: 3500,
            });
        }
    }

    private async acquireAndTestTokenSilent() {
        if (!this.instance) {
            this.state.next({ kind: 'error', error: 'MSAL instance not available.' });
            return;
        }

        const redirectPath = `${window.location.pathname}${window.location.search}`;
        if (!redirectPath.startsWith('/auth/redirect')) sessionStorage.setItem('auth_redirect_path', redirectPath);
        else sessionStorage.removeItem('auth_redirect_path');

        // If we failed to auth, wait a little before trying again...
        if (this.numAttempts > 0) {
            await new Promise((res) => {
                setTimeout(res, 500);
            });
        }

        this.numAttempts++;
        if (this.numAttempts > 1) log.info(`Login attempt ${this.numAttempts}...`);

        try {
            const acquireState = await this.trySsoSilent();

            if (acquireState?.kind === 'authenticated') this.numAttempts = 0;
            if (acquireState) this.state.next(acquireState);
            if (acquireState?.kind === 'interaction-required') {
                // give the UI a little time to render (redirect stops the page rendering immediately)
                setTimeout(() => this.instance!.acquireTokenRedirect({ scopes: this.scopes }), 50);
            }
        } catch (err) {
            log.error(err);
            this.state.next({ kind: 'error', error: err });
        } finally {
            if (this.state.value.kind !== 'authenticated' && this.numAttempts) {
                log.warn(`Failed ${this.numAttempts} login attempts...`);
            }
        }
    }

    private async trySsoSilent(): Promise<AuthState | undefined> {
        if (!this.instance) return undefined;

        try {
            const { accessToken, account } = await this.instance.ssoSilent({ scopes: this.scopes });
            const isTokenValid = await tryValidateAndSetToken(accessToken);
            if (isTokenValid) {
                this.setCurrentAccount(account!);
            }
            return isTokenValid ? { kind: 'authenticated' } : { kind: 'not-authenticated' };
        } catch (err) {
            if (err instanceof InteractionRequiredAuthError) {
                return { kind: 'interaction-required' };
            }

            log.error('tryAcquireToken', err);
            return { kind: 'error', error: err };
        }
    }

    private _invalidateTimeout: any = undefined;
    private _currentInvalidate: Promise<boolean> | undefined = undefined;
    invalidateToken = (): Promise<boolean> => {
        if (this._currentInvalidate) return this._currentInvalidate;

        clearInterval(this._invalidateTimeout);
        this._invalidateTimeout = undefined;

        // eslint-disable-next-line no-async-promise-executor
        this._currentInvalidate = new Promise<boolean>(async (res) => {
            try {
                if (
                    !localStorage.getItem('ad_access_token') ||
                    this.state.value.kind !== 'authenticated' ||
                    this.expiration.value?.isExpired
                ) {
                    res(false);
                    return;
                }

                let isTokenValid = false;

                const cachedToken = localStorage.getItem('ad_access_token');
                if (cachedToken) {
                    isTokenValid = await api.auth.testToken(cachedToken);
                }

                if (!isTokenValid) {
                    localStorage.removeItem('ad_access_token');
                    isTokenValid = !!(await this.tryRefreshTokenSilent());
                    // No need to test testToken again since if something is wrong
                    // with the token, the next API call will fail anyway
                    // and the app will go to the "expired token" state
                }

                if (!isTokenValid && this.state.value.kind === 'authenticated') {
                    this.expiration.next({ ...this.expiration.value, isExpired: true });
                }

                res(isTokenValid);
            } catch (err) {
                log.error('Token invalidation', err);
                res(false);
            } finally {
                this._currentInvalidate = undefined;
                this._invalidateTimeout = setTimeout(this.invalidateToken, TOKEN_INVALIDATE_INTERVAL);
            }
        });
        return this._currentInvalidate;
    };

    private async tryRefreshTokenSilent(goToExpireStateOnFailure = false) {
        if (!this.instance || !this.currentAccount) {
            return undefined;
        }

        try {
            const { accessToken } = await this.instance!.acquireTokenSilent({
                scopes: this.scopes,
                account: this.currentAccount,
            });
            if (accessToken !== localStorage.getItem('ad_access_token')) {
                log.info('Access Token Refreshed');
                setToken(accessToken);
            }
            return accessToken;
        } catch (err) {
            if (!(err instanceof InteractionRequiredAuthError)) {
                log.error('Refresh token', err);
            }
            if (goToExpireStateOnFailure && this.state.value.kind === 'authenticated') {
                this.expiration.next({ ...this.expiration.value, isExpired: true });
            }
            return undefined;
        }
    }

    private _refreshPromise: Promise<string | undefined> | undefined = undefined;
    private get refreshTokenPromise() {
        // The purpose of this is not having too call
        // tryRefreshTokenSilent on every request by caching
        // the result for 5s

        if (this._refreshPromise) {
            return this._refreshPromise;
        }

        this._refreshPromise = new Promise((res) => {
            // This usually takes about 6-8ms and ~1s when the token has expired
            this.tryRefreshTokenSilent(true)
                .then(res)
                .finally(() =>
                    setTimeout(() => {
                        this._refreshPromise = undefined;
                    }, 5000)
                );
        });

        return this._refreshPromise;
    }

    constructor() {
        api.auth.unauthorized.subscribe(() => {
            localStorage.removeItem('ad_access_token');
            if (this.state.value.kind === 'authenticated') {
                this.expiration.next({ ...this.expiration.value, isExpired: true });
            }
        });

        // On 1st successful auth, init the ecosystem service
        let ecosystemInitialized = false;
        this.state.subscribe((state) => {
            if (state.kind === 'authenticated' && !ecosystemInitialized) {
                ecosystemInitialized = true;
                EcosystemService.init();
            }
        });

        this._invalidateTimeout = setTimeout(this.invalidateToken, TOKEN_INVALIDATE_INTERVAL);

        api.auth.tryAcquireToken = () => {
            const isTesting = typeof process !== 'undefined' && process.env.NODE_ENV === 'test';
            if (!this.isAuthenticated || isTesting) {
                return Promise.resolve(localStorage.getItem('ad_access_token') ?? undefined);
            }
            return this.refreshTokenPromise;
        };
    }
}

function setAuthCookie(token?: string) {
    const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    if (token) {
        document.cookie = `Authorization="Bearer ${token}";path=/;samesite=strict${isLocalhost ? '' : ';secure'}`;
    } else {
        document.cookie = 'Authorization='; // TODO: is this a correct way to remove the cookie?
    }
}

async function tryValidateAndSetToken(accessToken: string) {
    let isTokenValid = false;
    try {
        isTokenValid = await api.auth.testToken(accessToken);
    } catch (err) {
        log.error(err);
        return false;
    }
    if (isTokenValid) {
        setToken(accessToken);
    } else {
        clearToken();
    }
    return isTokenValid;
}

function setToken(accessToken: string) {
    localStorage.setItem('ad_access_token', accessToken);
    setAuthCookie(accessToken);
}

function clearToken() {
    localStorage.removeItem('ad_access_token');
    setAuthCookie();
}

export const AuthService = new _AuthService();
