/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
    faAdd,
    faBorderNone,
    faDroplet,
    faEquals,
    faFireBurner,
    faFlask,
    faFloppyDisk,
    faHourglassHalf,
    faInfoCircle,
    faListOl,
    faPlus,
    faQuestion,
    faScaleUnbalanced,
    faStarOfLife,
    faStopwatch,
    faTemperatureHigh,
    faVials,
    faWater,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { Badge } from 'react-bootstrap';
import { HTEDesignModel } from '.';
import { AsyncMoleculeDrawing } from '../../../components/common/AsyncMoleculeDrawing';
import { IconButton } from '../../../components/common/IconButton';
import { SimpleSelectOptionInput, TextInput } from '../../../components/common/Inputs';
import { ScrollBox } from '../../../components/common/ScrollBox';
import useBehavior from '../../../lib/hooks/useBehavior';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { roundValue } from '../../../lib/util/roundValues';
import { prefixedUnitValue } from '../../../lib/util/units';
import { asNumber } from '../../../lib/util/validators';
import { BatchLink } from '../../ECM/ecm-common';
import { HTESolvents } from '../../HTE/experiment-data';
import { SelectSolvent } from '../../HTE/steps/reactants-model';
import { HTEDReaction, HTERInstructionT, HTERReactantNameT } from '../data-model';
import { getAddReactantVolumeInL, getReactionTotalVolumeInL, toUnit } from '../utils';
import {
    CloneReactionButton,
    EditReactionInstructionButton,
    EditReactionTemplateButton,
    HTEReactionModel,
    MoveInstructionButton,
    RemoveInstructionButton,
    RemoveReactionButton,
} from './reactions';

export function HTEReactionsUI({ model }: { model: HTEDesignModel }) {
    return (
        <ScrollBox>
            <Reactions model={model} />
        </ScrollBox>
    );
}

function Reactions({ model }: { model: HTEDesignModel }) {
    const readOnly = model.isSnapshot;
    const reactions = useBehavior(model.reactions);

    return (
        <div className='vstack gap-2 me-3 mb-3'>
            {/* <Globals model={model} /> */}
            {reactions.map((p, i) => (
                <Reaction key={i} model={p} />
            ))}
            {!readOnly && (
                <div className='mt-2 text-center'>
                    <IconButton icon={faPlus} onClick={() => model.addReaction()}>
                        Add Reaction
                    </IconButton>
                </div>
            )}
        </div>
    );
}

function Reaction({ model }: { model: HTEReactionModel }) {
    useMountedModel(model);

    return (
        <div className='border rounded'>
            <ReactionHeader model={model} />
            <div className='d-flex position-relative' style={{ height: 230 }}>
                <ReactionInstructions model={model} />
            </div>
        </div>
    );
}

function ReactionHeader({ model }: { model: HTEReactionModel }) {
    const readOnly = model.design.isSnapshot;
    const reaction = useBehavior(model.state.current);
    const { name, template, product_block_id } = reaction;

    // TODO: improve once solvent enum gets updated in Foundry
    const solvent = HTESolvents.find((s) => s.toLowerCase() === template.solvent.toLowerCase());

    const productBlock = model.design.productBlocks.findBlock(product_block_id);
    const {
        reactants: reactantsVol,
        backfill: backfillVol,
        productConcentration,
    } = getReactionTotalVolumeInL(reaction, productBlock?.enumeration?.enumeration);

    const reactantsVolLabel = prefixedUnitValue(reactantsVol, 'L') || '?';
    const backfillVolLabel = prefixedUnitValue(backfillVol, 'L') || '?';
    const solventVolLabel = prefixedUnitValue(backfillVol - reactantsVol, 'L') || '?';
    const hasBackfill = backfillVol - reactantsVol > 0;
    const overflowing = backfillVol > 0 && reactantsVol > backfillVol;

    return (
        <div className={`hstack gap-2 rounded-top px-1${readOnly ? ' py-1' : ''} htew-panel-header`}>
            <div className='hstack gap-4'>
                <div>
                    {readOnly && <span className='ms-2 fw-bold'>{name}</span>}
                    {!readOnly && (
                        <TextInput
                            value={name}
                            setValue={(v) => model.state.current.next({ ...reaction, name: v.trim() })}
                            className='p-0 px-2 fw-bold htew-panel-control'
                            style={{ border: 'none', width: 180 }}
                        />
                    )}
                </div>
                <div>
                    <FontAwesomeIcon icon={faScaleUnbalanced} className='me-2' />
                    {readOnly && <span className='me-2'>{roundValue(3, template.scale * 1e9)}</span>}
                    {!readOnly && (
                        <TextInput
                            value={roundValue(3, template.scale * 1e9)}
                            tryUpdateValue={asNumber}
                            setValue={(v) => model.updateTemplateData({ ...template, scale: v / 1e9 })}
                            className='p-0 px-2 me-2 htew-panel-control'
                            style={{ border: 'none', width: 64, display: 'inline' }}
                        />
                    )}
                    nmol
                </div>
                <div>
                    <FontAwesomeIcon icon={faDroplet} className='me-2' />
                    {readOnly && <span className='me-2'>{solvent}</span>}
                    {!readOnly && (
                        <SelectSolvent
                            value={solvent}
                            setValue={(v) => model.updateTemplateData({ ...template, solvent: v })}
                            className='htew-panel-control'
                            style={{
                                border: 'none',
                                paddingTop: 0,
                                paddingBottom: 0,
                                display: 'inline',
                                width: 100,
                            }}
                        />
                    )}
                </div>
                <div>
                    <FontAwesomeIcon icon={faBorderNone} className='me-2' />
                    {readOnly && <span className='me-2'>{productBlock?.label ?? '-'}</span>}
                    {!readOnly && (
                        <SimpleSelectOptionInput
                            value={product_block_id ?? ''}
                            allowEmpty={!product_block_id}
                            emptyPlaceholder='Select reactions...'
                            options={model.design.productBlocks.blockOptions}
                            setValue={(v) => model.state.current.next({ ...reaction, product_block_id: v })}
                            className={!product_block_id ? 'text-warning htew-panel-control' : 'htew-panel-control'}
                            style={{
                                border: 'none',
                                paddingTop: 0,
                                paddingBottom: 0,
                                display: 'inline',
                                width: 'auto',
                            }}
                        />
                    )}
                </div>
                <div>
                    <FontAwesomeIcon icon={faFlask} className='me-2' />
                    <span
                        className={overflowing ? 'text-warning' : undefined}
                        title={
                            overflowing
                                ? `Reactant volume exceeds backfill volume (${backfillVolLabel})`
                                : 'Reactant volume'
                        }
                    >
                        {reactantsVolLabel}
                    </span>
                    {hasBackfill && (
                        <span title='Solvent volume' className='text-secondary'>
                            {' + '}
                            {solventVolLabel}
                        </span>
                    )}
                    {productConcentration && (
                        <span title='Product concentration' className='ms-1'>
                            @ {prefixedUnitValue(productConcentration, 'M')}
                        </span>
                    )}
                </div>
            </div>
            <div className='m-auto' />
            {!readOnly && (
                <div className='hstack gap-2'>
                    <EditReactionTemplateButton model={model} />
                    <CloneReactionButton model={model} />
                    <RemoveReactionButton model={model} />
                </div>
            )}
        </div>
    );
}

function ReactionInstructions({ model }: { model: HTEReactionModel }) {
    const reaction = useBehavior(model.state.current);
    const readOnly = model.design.isSnapshot;

    return (
        <div className='position-relative h-100 d-flex flex-column' style={{ flexGrow: 1, flexShrink: 1, minWidth: 0 }}>
            {/* <div className='fw-bold mb-1'>Instructions</div> */}
            <div className='w-100 d-flex flex-grow-1 p-2' style={{ overflow: 'hidden', overflowX: 'auto' }}>
                <div className='hstack h-100'>
                    {reaction.template.reaction.instructions.map((i, iI) => (
                        <ProcedureInstruction model={model} reaction={reaction} instr={i} key={iI} />
                    ))}
                    {!readOnly && (
                        <div className='vstack gap-2 justify-content-center'>
                            <EditReactionInstructionButton
                                model={model}
                                instruction={{ kind: 'add', name: '' as HTERReactantNameT, sample: {} }}
                            />
                            <EditReactionInstructionButton
                                model={model}
                                instruction={{ kind: 'pause', duration: '5 min' }}
                            />
                            <EditReactionInstructionButton
                                model={model}
                                instruction={{ kind: 'cook', duration: '8 hour', temperature: `${50 + 273.15} K` }}
                            />
                            {!reaction.template.reaction.instructions.find((i) => i.kind === 'backfill') && (
                                <EditReactionInstructionButton
                                    model={model}
                                    instruction={{ kind: 'backfill', volume: '20 uL' }}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function ProcedureInstruction({
    model,
    reaction,
    instr,
}: {
    model: HTEReactionModel;
    reaction: HTEDReaction;
    instr: HTERInstructionT;
}) {
    const readOnly = model.design.isSnapshot;

    const {
        template: { reactants },
        product_block_id,
    } = reaction;

    let actions: ReactNode = null;
    if (!readOnly) {
        actions = (
            <div className='vstack'>
                <EditReactionInstructionButton model={model} instruction={instr} />
                <RemoveInstructionButton model={model} instruction={instr} />
                <MoveInstructionButton model={model} instruction={instr} dir={-1} />
                <MoveInstructionButton model={model} instruction={instr} dir={1} />
            </div>
        );
    }

    if (instr.kind === 'add') {
        const reactant = reactants[instr.name];
        const identifier = reactant?.identifier;
        const sample = reactants[instr.name]?.sample;

        const list = model.design.productBlocks.findBlock(product_block_id)?.findList(instr.name);

        const conc = sample?.concentration || instr.sample.concentration;
        const eq = sample?.equivalence || instr.sample.equivalence;
        let use_overage = sample?.use_overage || instr.sample.use_overage;
        if (use_overage === 1.0) use_overage = undefined;
        const sol = sample?.neat_solvent || instr.sample.neat_solvent;
        const neat = sample?.neat_concentration || instr.sample.neat_concentration;

        const assets = model.design.model.assets.entities;

        const smiles = assets.getStructure(identifier);
        const normalizedIdentifier = assets.getIdentifier(identifier);

        const [finalVol, solventVol] = getAddReactantVolumeInL(reaction, instr);
        const addVolLabel = prefixedUnitValue(finalVol, 'L');
        const neatVolLabel = prefixedUnitValue(finalVol - solventVol, 'L');

        let thumbLabel;

        if (normalizedIdentifier) {
            thumbLabel = (
                <div className='font-body-xsmall' title={reactant.barcode}>
                    <BatchLink identifier={normalizedIdentifier} withQuery />
                </div>
            );
        } else if (list) {
            thumbLabel = (
                <div className='font-body-xsmall'>
                    <i>Enumerated ({list.store.rowCount})</i>
                </div>
            );
        } else {
            thumbLabel = (
                <div className='font-body-xsmall text-warning'>
                    <i>No source</i>
                </div>
            );
        }

        const thumbBase = smiles ? (
            <AsyncMoleculeDrawing
                autosize
                smiles={smiles}
                showChemDraw={false}
                drawer={model.design.model.drawer}
                paddingBottom={10}
                width='95%'
                height='80%'
            />
        ) : (
            <FontAwesomeIcon icon={identifier ? faAdd : list ? faListOl : faQuestion} fontSize={32} />
        );

        const thumb = (
            <>
                {thumbBase}
                <div className='text-center end-0 start-0 bottom-0 position-absolute'>{thumbLabel}</div>
            </>
        );

        return (
            <InstructionCard
                instruction={instr}
                model={model}
                thumb={thumb}
                actions={actions}
                stripe={normalizedIdentifier ? 'warning' : 'info'}
            >
                <div className='mb-2'>
                    <Badge bg={normalizedIdentifier ? 'warning' : 'info'}>{instr.name}</Badge>
                </div>
                <div className='font-body-xsmall text-center'>
                    {addVolLabel || '?'}
                    {conc ? ` @ ${toUnit(conc, 1e3, ' mM')}` : ''}
                    {!conc && neat ? ` @ ${toUnit(neat, 1e3, ' mM')}` : ''}
                </div>
                {solventVol > 0 && (
                    <div className='font-body-xsmall text-secondary' title='Neat'>
                        ({neatVolLabel} @ {toUnit(neat!, 1e3, ' mM')})
                    </div>
                )}
                {!!sol && (
                    <div className='font-body-xsmall' title='Neat solvent'>
                        <FontAwesomeIcon icon={faDroplet} className='me-1' size='sm' />
                        {sol}
                    </div>
                )}
                <div className='hstack gap-2 mt-1'>
                    <div className='m-auto' />
                    {!!eq && (
                        <div className='font-body-xsmall'>
                            <FontAwesomeIcon icon={faEquals} className='me-1' size='sm' />
                            {eq}
                        </div>
                    )}
                    {!!use_overage && (
                        <div className='font-body-xsmall' title='Use overage'>
                            <FontAwesomeIcon icon={faStarOfLife} className='me-1' size='sm' />
                            {roundValue(2, use_overage)}
                        </div>
                    )}
                    {!!instr.multi_aspiration_group && (
                        <div className='font-body-xsmall' title='Multi Aspiration'>
                            <FontAwesomeIcon icon={faVials} className='me-1' size='sm' />
                            {instr.multi_aspiration_group}
                        </div>
                    )}
                    {instr.conserve_volume && (
                        <div className='font-body-xsmall' title='Conserve Volume'>
                            <FontAwesomeIcon icon={faFloppyDisk} size='sm' />
                        </div>
                    )}
                    {!!instr.mosquito_copy_options && (
                        <div className='font-body-xsmall' title={`Copy options: ${instr.mosquito_copy_options}`}>
                            <FontAwesomeIcon icon={faInfoCircle} size='sm' />
                        </div>
                    )}
                    <div className='m-auto' />
                </div>
            </InstructionCard>
        );
    }

    if (instr.kind === 'pause') {
        return (
            <InstructionCard
                instruction={instr}
                model={model}
                thumb={<FontAwesomeIcon icon={faHourglassHalf} fontSize={32} />}
                actions={actions}
            >
                <div className='mb-2'>
                    <Badge bg='secondary'>Pause</Badge>
                </div>
                <div className='font-body-xsmall' title='Duration'>
                    <FontAwesomeIcon icon={faStopwatch} className='me-1' size='sm' />
                    {toUnit(instr.duration, 1, ' s')}
                </div>
            </InstructionCard>
        );
    }

    if (instr.kind === 'cook') {
        return (
            <InstructionCard
                instruction={instr}
                model={model}
                thumb={<FontAwesomeIcon icon={faFireBurner} fontSize={32} />}
                actions={actions}
            >
                <div className='mb-2'>
                    <Badge bg='secondary'>Cook</Badge>
                </div>
                <div className='font-body-xsmall' title='Duration'>
                    <FontAwesomeIcon icon={faStopwatch} className='me-1' size='sm' />
                    {toUnit(instr.duration, 1 / 3600, ' h')}
                </div>
                <div className='font-body-xsmall' title='Temperature'>
                    <FontAwesomeIcon icon={faTemperatureHigh} className='me-1' size='sm' />
                    {toUnit(
                        typeof instr.temperature === 'number' ? instr.temperature - 273.15 : instr.temperature,
                        1,
                        ' °C'
                    )}
                </div>
            </InstructionCard>
        );
    }

    if (instr.kind === 'backfill') {
        return (
            <InstructionCard
                instruction={instr}
                model={model}
                thumb={<FontAwesomeIcon icon={faWater} fontSize={32} />}
                actions={actions}
            >
                <div className='mb-2'>
                    <Badge bg='secondary'>Backfill</Badge>
                </div>
                <div className='font-body-xsmall text-secondary'>{toUnit(instr.volume, 1e9, ' μL')}</div>
            </InstructionCard>
        );
    }

    return null;
}

function InstructionCard({
    instruction,
    model,
    thumb,
    children,
    stripe = 'secondary',
    actions,
}: {
    instruction: HTERInstructionT;
    model: HTEReactionModel;
    thumb: ReactNode;
    children: ReactNode;
    stripe?: 'warning' | 'info' | 'secondary';
    actions?: ReactNode;
}) {
    const isSnapshot = model.design.isSnapshot;
    const current = useBehavior(model.design.state.currentInstruction);
    const selected = current?.reaction_id === model.reaction.id && instruction === current?.instruction;

    const width = 170;
    return (
        <div
            style={{
                width,
                cursor: isSnapshot ? 'pointer' : undefined,
                borderBottomColor: `rgba(var(--bs-${stripe}-rgb), 0.66)`,
            }}
            className={`rounded d-flex px-2 me-2 h-100 flex-column position-relative hted-instruction-card${
                selected ? ' hted-instruction-card-selected' : ''
            }`}
            onClick={isSnapshot ? () => model.toggleCurrentInstruction(instruction) : undefined}
            title={isSnapshot ? 'Click to select & highlight on product plate' : undefined}
        >
            <div
                style={{ flexBasis: width / 1.5, flexShrink: 0 }}
                className='d-flex align-items-center justify-content-center position-relative'
            >
                {thumb}
            </div>
            <div className='flex-grow-1 d-flex flex-column align-items-center justify-content-start pt-2'>
                {children}
            </div>
            {actions && <div className='hted-instruction-card-actions'>{actions}</div>}
        </div>
    );
}
