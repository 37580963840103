import { LabeledInput, SimpleSelectOptionInput, TextInput } from '../../../components/common/Inputs';
import { ScrollBox } from '../../../components/common/ScrollBox';
import useBehavior from '../../../lib/hooks/useBehavior';
import { formatWithUnit, parseWithUnit } from '../../../lib/util/units';
import { HTEDSettingsModel } from './settings';

export function HTEDSettingsUI({ model }: { model: HTEDSettingsModel }) {
    return (
        <ScrollBox className='pe-3 pt-4 pb-4'>
            <div className='vstack gap-4' style={{ width: 600, margin: '0 auto' }}>
                <Conditions model={model} />
                <Algorithm model={model} />
            </div>
        </ScrollBox>
    );
}

const LabelWidth = 180;

function Conditions({ model }: { model: HTEDSettingsModel }) {
    const readOnly = model.design.isSnapshot;
    const settings = useBehavior(model.state.globalConditions);
    const rpmToRadS = (2 * Math.PI) / 60;
    const { atmosphereOptions } = model.design.model.assets;

    return (
        <div className='vstack gap-2'>
            <h5>Global Conditions</h5>
            <LabeledInput label='Stir Rate' labelWidth={LabelWidth}>
                <TextInput
                    value={formatWithUnit(settings.stir_rate, 1 / rpmToRadS, 'rpm')}
                    tryUpdateValue={(v) => parseWithUnit(v, 'rpm')}
                    setValue={(v) => model.state.globalConditions.next({ ...settings, stir_rate: v })}
                    readOnly={readOnly}
                />
            </LabeledInput>
            <LabeledInput label='Temperature' labelWidth={LabelWidth}>
                <TextInput
                    value={formatWithUnit(settings.temperature, (v) => v - 273.15, 'celsius')}
                    tryUpdateValue={(v) => parseWithUnit(v, 'celsius')}
                    setValue={(v) => model.state.globalConditions.next({ ...settings, temperature: v })}
                    readOnly={readOnly}
                />
            </LabeledInput>
            <LabeledInput label='Duration' labelWidth={LabelWidth}>
                <TextInput
                    value={formatWithUnit(settings.duration, 1 / 3600, 'hours')}
                    tryUpdateValue={(v) => parseWithUnit(v, 'hours')}
                    setValue={(v) => model.state.globalConditions.next({ ...settings, duration: v })}
                    readOnly={readOnly}
                />
            </LabeledInput>
            <LabeledInput label='Nominal Volume' labelWidth={LabelWidth}>
                <TextInput
                    value={formatWithUnit(settings.nominal_volume, 1e9, 'ul')}
                    tryUpdateValue={(v) => parseWithUnit(v, 'ul')}
                    setValue={(v) => model.state.globalConditions.next({ ...settings, nominal_volume: v })}
                    readOnly={readOnly}
                />
            </LabeledInput>
            <LabeledInput label='Atmosphere' labelWidth={LabelWidth}>
                <SimpleSelectOptionInput
                    value={settings.atmosphere}
                    options={atmosphereOptions}
                    setValue={(v) => model.state.globalConditions.next({ ...settings, atmosphere: v })}
                    disabled={readOnly}
                />
            </LabeledInput>
        </div>
    );
}

function Algorithm({ model }: { model: HTEDSettingsModel }) {
    const readOnly = model.design.isSnapshot;
    const settings = useBehavior(model.state.layoutAlgorithm);

    return (
        <div className='vstack gap-2'>
            <h5>Layout Algorithm</h5>
            <LabeledInput label='Robot' labelWidth={LabelWidth}>
                <SimpleSelectOptionInput
                    value={settings.kind}
                    options={[['mosquito', 'Mosquito']]}
                    setValue={(v) => {}}
                    disabled={readOnly}
                />
            </LabeledInput>
            <LabeledInput label='Product Layout' labelWidth={LabelWidth}>
                <SimpleSelectOptionInput
                    value={settings.product_well_layout}
                    options={[
                        [384, '16x24 384 Well Plate'],
                        [1536, '32x48 1536 Well Plate'],
                    ]}
                    setValue={(v) => model.state.layoutAlgorithm.next({ ...settings, product_well_layout: v })}
                    disabled={readOnly}
                />
            </LabeledInput>
            <LabeledInput label='Source Layout' labelWidth={LabelWidth}>
                <SimpleSelectOptionInput
                    value={settings.source_well_layout}
                    options={[[384, '16x24 384 Well Plate']]}
                    setValue={(v) => model.state.layoutAlgorithm.next({ ...settings, source_well_layout: v })}
                    disabled={readOnly}
                />
            </LabeledInput>
            <LabeledInput label='Overage/pip. change' labelWidth={LabelWidth} tooltip='Overage per pipette change'>
                <TextInput
                    value={formatWithUnit(settings.overage_per_pipette_change, 1e12, 'nl')}
                    tryUpdateValue={(v) => parseWithUnit(v, 'nl')}
                    setValue={(v) => model.state.layoutAlgorithm.next({ ...settings, overage_per_pipette_change: v })}
                    readOnly={readOnly}
                />
            </LabeledInput>
        </div>
    );
}
