import Select, { createFilter } from 'react-select';
import { CustomSelectClassNames } from './selectStyles';

interface IProps {
    options: any;
    placeholder: string;
    onChange: any;
    value?: any;
}

export default function SingleSelect({ options, placeholder, onChange, value }: IProps) {
    return (
        <Select
            filterOption={createFilter({ ignoreAccents: false })}
            options={options}
            placeholder={placeholder}
            classNames={CustomSelectClassNames}
            onChange={onChange}
            value={value}
        />
    );
}
