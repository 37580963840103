import log from 'loglevel';
import { BehaviorSubject } from 'rxjs';
import { tryGetErrorMessage } from '../../../lib/util/errors';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { DataTableModel, DataTableStore } from '../../../components/DataTable';
import { CompoundAPI, SampleReview } from '../compound-api';

export type SampleUploadStep = 'settings' | 'review' | 'confirm';

const SAMPLE_REVIEW_COLUMNS: (keyof SampleReview)[] = [
    'batch',
    'supplier_id',
    'sanitized_supplier_id',
    'barcode',
    'amount',
    'amount_units',
    'error',
    'warning',
];

function partialToFullSampleReview(sampleReview: Partial<SampleReview>) {
    const fullSampleReview: Partial<SampleReview> = {};
    for (const col of SAMPLE_REVIEW_COLUMNS) {
        (fullSampleReview as any)[col] = sampleReview[col] ?? null;
    }
    return fullSampleReview as SampleReview;
}

export class SampleUploadModel extends ReactiveModel {
    state = {
        step: new BehaviorSubject<SampleUploadStep>('settings'),
        modalOpen: new BehaviorSubject<boolean>(false),
        isLoading: new BehaviorSubject<boolean>(false),
        error: new BehaviorSubject<string>(''),
        file: new BehaviorSubject<File | null>(null),
        sampleReviewStore: new BehaviorSubject<DataTableStore<SampleReview> | undefined>(undefined),
        settingsValid: new BehaviorSubject<boolean>(false),
    };

    async next() {
        const step = this.state.step.value;
        const settingsValid = this.state.settingsValid.value;
        if (step === 'settings' && settingsValid) {
            await this.loadReview();
        }
    }

    async upload(table: DataTableModel<SampleReview>) {
        await this.createSamples(table);
    }

    cancel() {
        this.state.modalOpen.next(false);
        this.state.step.next('settings');
        this.state.file.next(null);
        this.state.error.next('');
        this.state.sampleReviewStore.next(undefined);
    }

    async loadFromSignals(signalsSamples: DataTableStore<SampleReview>) {
        this.state.isLoading.next(true);
        this.state.modalOpen.next(true);
        try {
            await this.setStateFromSignals(signalsSamples);
        } catch (e) {
            this.state.error.next(`Error loading samples from Signals: ${tryGetErrorMessage(e)}`);
            log.error(e);
        } finally {
            this.state.isLoading.next(false);
        }
    }

    private async setStateFromSignals(signalsResult: DataTableStore<SampleReview>) {
        this.state.error.next('');
        const sampleReview = signalsResult.toObjects().map((br) => partialToFullSampleReview(br));
        const review = await CompoundAPI.sampleReReview(sampleReview);
        this.state.sampleReviewStore.next(review);
        this.state.step.next('review');
    }

    private async loadReview() {
        const file = this.state.file.value;
        if (!file) return;
        this.state.isLoading.next(true);
        try {
            const review = await CompoundAPI.sampleReview(file);
            this.state.sampleReviewStore.next(review);
            this.state.error.next('');
            this.state.step.next('review');
        } catch (e) {
            this.state.error.next(tryGetErrorMessage(e));
            log.error(e);
        } finally {
            this.state.isLoading.next(false);
        }
    }

    private async createSamples(table: DataTableModel<SampleReview>) {
        if (!table.store) return;
        this.state.isLoading.next(true);
        try {
            const indices = table.rows.filter((r) => !!table.selectedRows[r]);
            const result: boolean = await CompoundAPI.sampleCreate(table.store.toObjects({ indices }));
            if (result) {
                this.state.error.next('');
                this.cancel();
            } else {
                throw new Error('Failed to create samples.');
            }
        } catch (e) {
            this.state.error.next(tryGetErrorMessage(e));
            log.error(e);
        } finally {
            this.state.isLoading.next(false);
        }
    }

    private settingsValid(): boolean {
        return !!this.state.file.value;
    }

    constructor() {
        super();

        this.subscribe(this.state.file, () => {
            this.state.settingsValid.next(this.settingsValid());
        });
    }
}
