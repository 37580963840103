import log from 'loglevel';
import { DefaultFloatColumnFormatOptions, FloatColumnFormatOptions, formatFloat } from '../../components/DataTable';
import type { AssayDetail } from '../../pages/Assays/assay-api';
import { type DVScalar } from '../../pages/Dataviz/state/data-model';
import { type AssayProperty } from '../models/biology';
import { isBlank } from '../util/misc';
import { getDecimalPlaces, roundValueDigits } from '../util/roundValues';
import { potensToNM } from '../util/units';
import type {
    AssayValueType,
    AssayInequalityValue,
    AssayRangeValue,
    AssayGaussianUncertaintyValue,
    AssayUncertaintyValue,
} from './models';

// See UncertaintyValue class in Foundry Assay models.
export const DefaultConfidenceInterval = 1.0;
export const UQConfidenceIntervals = [0.5, 1.0, 1.5, 2.0, 2.5] as const;
export type UQConfidenceInterval = (typeof UQConfidenceIntervals)[number];
// const CONFIDENCE_VALUES = [
//     0.382924922548026, 0.682689492137086, 0.866385597462284, 0.954499736103642, 0.987580669348448,
// ];

export function isInequalityValue(v?: AssayValueType | DVScalar): v is AssayInequalityValue {
    return !!(v && typeof (v as any).value === 'number' && typeof (v as any).greater_than === 'boolean');
}

export function isRangeValue(v?: AssayValueType | DVScalar): v is AssayRangeValue {
    return !!(v && typeof (v as any).upper_limit === 'number' && typeof (v as any).lower_limit === 'number');
}

export function isGaussianUncertaintyValue(v?: AssayValueType | DVScalar): v is AssayGaussianUncertaintyValue {
    return !!(
        v &&
        typeof (v as any).value === 'number' &&
        typeof (v as any).upper_bound === 'number' &&
        typeof (v as any).lower_bound === 'number'
    );
}

export function isUncertaintyValue(v?: AssayValueType | DVScalar): v is AssayUncertaintyValue {
    return !!(
        v &&
        typeof (v as any).value === 'number' &&
        typeof (v as any).upper_bounds?.length === 'number' &&
        typeof (v as any).lower_bounds?.length === 'number'
    );
}

export function getGaussianError(v: AssayGaussianUncertaintyValue) {
    return (v.upper_bound - v.lower_bound) / (v.interval * 2);
}

export function getUncertaintyValueBounds(
    v: AssayUncertaintyValue,
    confidenceInterval: UQConfidenceInterval = DefaultConfidenceInterval
) {
    const intervalIdx = UQConfidenceIntervals.indexOf(confidenceInterval);
    const upperBound = v.upper_bounds[intervalIdx];
    const lowerBound = v.lower_bounds[intervalIdx];
    return [confidenceInterval, lowerBound, upperBound];
}

export function inequalityPotensToNM(value: AssayInequalityValue) {
    // potens -> nm changes sign
    return {
        greater_than: !value.greater_than,
        value: potensToNM(value.value, false),
    } satisfies AssayInequalityValue;
}

export function rangePotensToNM(value: AssayRangeValue) {
    // potens -> nm changes sign
    return {
        lower_limit: potensToNM(value.upper_limit, false),
        upper_limit: potensToNM(value.lower_limit, false),
    } satisfies AssayRangeValue;
}

export function gaussianPotensToNM(value: AssayGaussianUncertaintyValue): AssayGaussianUncertaintyValue {
    const nmValue = potensToNM(value.value, false);
    return {
        value: nmValue,
        // potens -> nm changes sign => upper becomes lower
        lower_bound: potensToNM(value.upper_bound, false),
        upper_bound: potensToNM(value.lower_bound, false),
        interval: value.interval,
    };
}

export function uncertaintyPotensToNM(value: AssayUncertaintyValue): AssayUncertaintyValue {
    const nmValue = potensToNM(value.value, false);
    return {
        value: nmValue,
        // potens -> nm changes sign => upper becomes lower
        lower_bounds: value.upper_bounds.map((b) => potensToNM(b, false)),
        upper_bounds: value.lower_bounds.map((b) => potensToNM(b, false)),
    };
}

export function assayValuePotensToNM(value?: AssayValueType) {
    if (isBlank(value)) return value;
    if (typeof value === 'boolean') return value;
    if (typeof value === 'number') return potensToNM(value, false);
    if (isUncertaintyValue(value)) return uncertaintyPotensToNM(value);
    if (isGaussianUncertaintyValue(value)) return gaussianPotensToNM(value);
    if (isInequalityValue(value)) return inequalityPotensToNM(value);
    if (isRangeValue(value)) return rangePotensToNM(value);
    log.warn(`assayValuePotensToNM: unexpected value type ${value}`);
    return value;
}

export interface AssayFormattingOptions {
    asNM?: boolean;
    formatting?: FloatColumnFormatOptions;
    confidenceInterval?: UQConfidenceInterval;
    hideUQ?: boolean;
}

function floatValueToString(value: number, options?: AssayFormattingOptions) {
    if (Number.isNaN(value)) return '';
    const format = options?.formatting ?? DefaultFloatColumnFormatOptions;
    const assayValue = options?.asNM ? potensToNM(value, false) : value;
    return `${formatFloat(assayValue, format)}`;
}

function inequalityValueToString(value: AssayInequalityValue, options?: AssayFormattingOptions) {
    const format = options?.formatting ?? DefaultFloatColumnFormatOptions;
    const assayValue = options?.asNM ? inequalityPotensToNM(value) : value;
    return `${assayValue.greater_than ? '>' : '<'}${formatFloat(assayValue.value, format)}`;
}

function rangeValueToString(value: AssayRangeValue, options?: AssayFormattingOptions) {
    const format = options?.formatting ?? DefaultFloatColumnFormatOptions;
    const assayValue = options?.asNM ? rangePotensToNM(value) : value;
    return `${formatFloat(assayValue.lower_limit, format)} \u2013 ${formatFloat(assayValue.upper_limit, format)}`;
}

function gaussianValueToString(value: AssayGaussianUncertaintyValue, options?: AssayFormattingOptions) {
    const format = options?.formatting ?? DefaultFloatColumnFormatOptions;
    const assayValue = options?.asNM ? gaussianPotensToNM(value) : value;
    const error = getGaussianError(assayValue);
    const formattedValue = formatFloat(assayValue.value, format);
    const decimalPlaces = getDecimalPlaces(formattedValue);
    const formattedError = formatFloat(error, { ...format, decimalPlaces });
    return `${formattedValue}\u00b1${formattedError}`;
}

function uncertaintyValueToString(value: AssayUncertaintyValue, options?: AssayFormattingOptions) {
    const format = options?.formatting ?? DefaultFloatColumnFormatOptions;
    const assayValue = options?.asNM ? uncertaintyPotensToNM(value) : value;
    const [interval, lowerBound, upperBound] = getUncertaintyValueBounds(
        assayValue,
        options?.confidenceInterval ?? DefaultConfidenceInterval
    );
    const formattedValue = formatFloat(assayValue.value, format);
    const decimalPlaces = getDecimalPlaces(formattedValue);
    const upperError = formatFloat(upperBound - assayValue.value, { ...format, decimalPlaces });
    const lowerError = formatFloat(lowerBound - assayValue.value, { ...format, decimalPlaces });
    return `${formattedValue}+${upperError}${lowerError} (${roundValueDigits(2, interval)}\u03C3)`;
}

export function assayValueTypeToString(value?: AssayValueType, options?: AssayFormattingOptions) {
    if (isBlank(value)) return '';
    if (typeof value === 'boolean') return `${value}`;
    if (typeof value === 'number') return floatValueToString(value, options);
    if (isInequalityValue(value)) return inequalityValueToString(value, options);
    if (isRangeValue(value)) return rangeValueToString(value, options);
    if (isGaussianUncertaintyValue(value)) return gaussianValueToString(value, options);
    if (isUncertaintyValue(value)) return uncertaintyValueToString(value, options);
    log.warn('assayValueTypeToString: unexpected value type');
    return '';
}

export function assayValueTypeToCsvString(value?: AssayValueType, options?: AssayFormattingOptions) {
    if (isBlank(value)) return '';
    if (typeof value === 'boolean') return `${value}`;
    if (typeof value === 'number') return floatValueToString(value, options);
    const guess = tryGetAssayValueGuess(value, false); // do not convert to NM here, floatValueToString will do that if necessary
    if (guess === undefined) return '';
    return floatValueToString(guess, options);
}

export function assayValueTypeCompare(a: AssayValueType, b: AssayValueType) {
    const aIsBlank = isBlank(a);
    const bIsBlank = isBlank(b);
    if (aIsBlank && bIsBlank) return 0;
    if (aIsBlank) return 1;
    if (bIsBlank) return -1;

    if (typeof a === 'boolean' && typeof b === 'boolean') return +a - +b;
    if (typeof a === 'number' && typeof b === 'number') return a - b;

    const aValue = tryGetAssayValueGuess(a);
    const bValue = tryGetAssayValueGuess(b);
    if (aValue === undefined && bValue === undefined) return 0;
    if (aValue === undefined) return 1;
    if (bValue === undefined) return -1;
    return aValue - bValue;
}

export function tryGetAssayValueGuess(v?: AssayValueType, asNM: boolean = false) {
    if (typeof v === 'number') {
        // Negative (IC50) values are used as "undefined" value
        // Coalesce NaNs to undefined for easier sort handling
        if (v < 0 || Number.isNaN(v)) return undefined;
        if (asNM) return potensToNM(v, false);
        return v;
    }
    if (typeof v === 'boolean') {
        return undefined;
    }
    if (isInequalityValue(v)) {
        // from Foundry's InequalityValue
        if (v.value < 0) {
            log.warn('Cannot use getNumericValue on ranges with negative limits');
            return undefined;
        }
        if (asNM) return potensToNM(v.value, false);
        return v.value;
    }
    if (isRangeValue(v)) {
        // from Foundry's RangeValue
        if (v.lower_limit < 0 || v.upper_limit < 0) {
            log.warn('Cannot use getNumericValue on ranges with negative limits');
            return undefined;
        }
        if (asNM) return Math.sqrt(potensToNM(v.lower_limit, false) * potensToNM(v.upper_limit, false));
        return Math.sqrt(v.lower_limit * v.upper_limit);
    }
    if (isGaussianUncertaintyValue(v) || isUncertaintyValue(v)) {
        // from Foundry's GaussianUncertaintyValue and UncertaintyValue
        if (asNM) return potensToNM(v.value, false);
        return v.value;
    }
    return undefined;
}

export function isCurveMeasurement(property: AssayProperty) {
    return (
        isMetabolicStabilityAssay(property) ||
        (['IC50', 'Kd', 'Ki', 'EC50'].includes(property.measurement) && property.kind !== 'pKa')
    );
}

export function isMetabolicStabilityAssay(property: AssayProperty) {
    return (
        property.category === 'ADME' &&
        property.kind === 'Metabolic stability' &&
        property.measurement === 'CL' &&
        property.environment === 'In vitro - microsomes'
    );
}

export function isComboCGIAssay(assay: AssayDetail) {
    return assay.details?.special_format === 'combo';
}
