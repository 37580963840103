import { faGrip, faListOl, faMagicWandSparkles, faPlus, faVials } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Button, Dropdown, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import {
    Column,
    ColumnsFor,
    DataTableControl,
    DataTableGlobalFilter,
    DataTableModel,
    DataTableStore,
    tableRowNavigation,
} from '../../components/DataTable';
import { PageTemplate } from '../../components/Layout/Layout';
import { ErrorMessage } from '../../components/common/Error';
import { LabeledInput, SimpleSelectOptionInput, TextInput } from '../../components/common/Inputs';
import Loading from '../../components/common/Loading';
import { useAsyncAction } from '../../lib/hooks/useAsyncAction';
import useBehavior from '../../lib/hooks/useBehavior';
import useBehaviorSubject from '../../lib/hooks/useBehaviorSubject';
import { EcosystemService } from '../../lib/services/ecosystem';
import { ToastService } from '../../lib/services/toast';
import { isBlank } from '../../lib/util/misc';
import { HTEApi, HTEExperimentRow } from './experiment-api';
import { HTEExperimentStatus, WellLayout, WellLayouts, createExperiment, getExperimentCreate } from './experiment-data';

const ExperimentTableSchema: ColumnsFor<HTEExperimentRow> = {
    id: Column.create({ ...Column.int(), width: 80 }),
    name: Column.create({ ...Column.str(), width: 300 }),
    project: Column.create({ ...Column.str(), width: 80 }),
    well_layout: Column.create({ ...Column.int(), width: 100, align: 'right' }),
    created_by: Column.create({ ...Column.str(), width: 200 }),
    created_on: Column.create({ ...Column.datetime({ format: 'date' }), width: 100 }),
    modified_on: Column.create({ ...Column.datetime({ format: 'full' }), width: 200 }),
    status: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        width: 150,
        render: ({ value }: { value: HTEExperimentStatus | undefined }) => {
            switch (value) {
                case 'Planning':
                case 'Editing':
                    return <Badge bg='warning'>{value}</Badge>;
                case 'Needs Signing':
                case 'Needs Countersigning':
                case 'Needs Amending':
                    return <Badge bg='danger'>{value}</Badge>;
                case 'Done':
                    return <Badge bg='success'>{value}</Badge>;
                default:
                    return isBlank(value) ? '' : value;
            }
        },
    }),
};

function createTable(experiments: DataTableStore<HTEExperimentRow>): DataTableModel<HTEExperimentRow> {
    const table = new DataTableModel(experiments, {
        columns: ExperimentTableSchema,
        hideNonSchemaColumns: true,
    });

    table.sortBy('modified_on', true);

    return table;
}

export function HTEOverview() {
    const [list, loadList] = useAsyncAction<DataTableStore<HTEExperimentRow>>();
    const table = useMemo(() => (list.result ? createTable(list.result) : undefined), [list.result]);
    const isCreateNewOpen = useBehaviorSubject(false);
    const navigate = useNavigate();

    useEffect(() => {
        loadList(HTEApi.list());
    }, []);

    return (
        <PageTemplate
            title='HTE'
            button={
                <Dropdown>
                    <Dropdown.Toggle variant='primary'>
                        <FontAwesomeIcon icon={faPlus} className='me-2' size='sm' />
                        Create New
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => isCreateNewOpen.next(true)}>
                            <FontAwesomeIcon icon={faGrip} className='me-2' size='sm' fixedWidth />
                            HTE 1
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => navigate('/hte/wizard')}>
                            <FontAwesomeIcon icon={faMagicWandSparkles} className='me-2' size='sm' fixedWidth />
                            HTE 2 Nanoscale
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => navigate('/hte/uscale')}>
                            <FontAwesomeIcon icon={faVials} className='me-2' size='sm' fixedWidth />
                            HTE 2 Microscale
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => navigate('/hte/enumeration')}>
                            <FontAwesomeIcon icon={faListOl} className='me-2' size='sm' fixedWidth />
                            Enumeration
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            }
        >
            <div className='position-relative h-100 d-flex flex-column overflow-hidden'>
                {table && <TableWrapper table={table} />}
                {list.isLoading && <Loading />}
                {list.error && <ErrorMessage header='Load Error' message='Failed to load the experiment list.' />}
            </div>
            <CreateNewModal isOpen={isCreateNewOpen} existing={list.result} />
        </PageTemplate>
    );
}

function TableWrapper({ table }: { table: DataTableModel<HTEExperimentRow> }) {
    const navigate = useNavigate();
    useBehavior(table.version);

    const goToExperiment = (rowIndex: number, tbl: DataTableModel<HTEExperimentRow>, e: React.MouseEvent) => {
        const status = tbl.store.getValue('status', rowIndex);
        const goToDetails = status !== 'Planning' && status !== 'Editing';
        const url = `/hte/${tbl.store.getValue('id', rowIndex)}/${goToDetails ? 'details' : 'design'}`;
        tableRowNavigation(e, url, navigate);
    };

    const tableTitle = (
        <div className='flex-0 mx-3 mt-3'>
            <h4>{table.rows.length} Experiments</h4>
        </div>
    );

    return (
        <div className='d-flex flex-column h-100'>
            <div>{tableTitle}</div>
            <div className='mx-3 mb-3 w-50'>
                <DataTableGlobalFilter table={table} />
            </div>
            <div className='position-relative flex-grow-1'>
                <DataTableControl
                    height='flex'
                    headerSize='sm'
                    table={table}
                    onRowDoubleClick={goToExperiment}
                    rowSelectionMode='single'
                />
            </div>
        </div>
    );
}

function CreateNewModal({
    isOpen,
    existing,
}: {
    isOpen: BehaviorSubject<boolean>;
    existing?: DataTableStore<HTEExperimentRow>;
}) {
    const open = useBehavior(isOpen);
    const projects = useBehavior(EcosystemService.environment)?.projects ?? [];
    const [library_name, setName] = useState<string>('');
    const [project, setProject] = useState<string>('');
    const [layout, setLayout] = useState<WellLayout | ''>('');
    const [createState, applyCreate] = useAsyncAction<number>({ rethrowError: true });
    const navigate = useNavigate();

    useEffect(() => {
        if (open) {
            setName('');
            setProject('');
            setLayout('');
            applyCreate(undefined);
        }
    }, [open]);

    const projectOptions = useMemo(() => projects.map((p) => [p, p] as [string, string]), [projects]);

    const nameExists = useMemo(() => {
        const normalized = library_name.trim().toLowerCase();
        const names = existing?.getColumnValues('name') ?? [];
        for (const name of names) {
            if (name === normalized) return true;
        }
        return false;
    }, [library_name]);

    if (!existing) return <Loading inline />;

    async function create() {
        try {
            const experiment = createExperiment({
                library_name: library_name.trim(),
                layout: layout as WellLayout,
                project,
            });
            const id = await applyCreate(
                HTEApi.create({
                    experiment,
                    info: getExperimentCreate(experiment, 'Planning'),
                })
            );
            if (id) {
                navigate(`${id}/design`);
            }
        } catch {
            ToastService.show({
                type: 'danger',
                message: 'Failed to create new experiment',
            });
        }
    }

    let nameValidation;
    if (library_name.trim().length === 0) {
        nameValidation = 'Enter name';
    } else if (nameExists) {
        nameValidation = 'A library with this name already exists';
    }

    const canSubmit = !nameValidation && !!project && !!layout;

    return (
        <Modal backdrop centered scrollable show={open} size='lg' onHide={() => isOpen.next(false)}>
            <Modal.Header closeButton>
                <h4>Create New Experiment</h4>
            </Modal.Header>
            <Modal.Body>
                <div className='hte-experiment-create-new-form vstack gap-2'>
                    <LabeledInput label='Library Name'>
                        <TextInput value={library_name} setValue={setName} validation={nameValidation} immediate />
                    </LabeledInput>
                    <LabeledInput label='Project'>
                        <SimpleSelectOptionInput
                            value={project}
                            allowEmpty
                            options={projectOptions}
                            setValue={(v) => setProject(v)}
                            validation={!project ? 'Select project' : undefined}
                        />
                    </LabeledInput>
                    <LabeledInput label='Well Layout'>
                        <SimpleSelectOptionInput
                            value={layout}
                            allowEmpty
                            options={WellLayouts}
                            setValue={(v) => setLayout(v as any)}
                            validation={!layout ? 'Select well layout' : undefined}
                        />
                    </LabeledInput>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='link' className='me-3' onClick={() => isOpen.next(false)}>
                    Cancel
                </Button>
                <Button variant='primary' onClick={() => create()} disabled={createState.isLoading || !canSubmit}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
