// source: Envision Bio
// https://github.com/EntosAI/envision-bio/blob/master/envision-core/src/hooks/use-async-action.ts
/* eslint-disable */
import { useCallback, useEffect, useRef, useState } from 'react';
import log from 'loglevel';

export type AsyncState<T> = {
    isLoading?: boolean;
    error?: any;
    result?: T;
};

export function useAsyncAction<R = any>(options?: { rethrowError?: boolean; doNotResetResult?: boolean }) {
    const [state, setState] = useState<AsyncState<R>>({});
    const context = useRef({ call: null as any, isMounted: true });

    context.current.isMounted = true;
    useEffect(() => {
        return () => {
            context.current.isMounted = false;
            context.current.call = null; // eslint-disable-line
        };
    }, []);

    const apply = useCallback(
        <T extends R>(promise: Promise<T> | undefined) => {
            if (!promise) {
                setState({});
                return;
            }

            if (options?.doNotResetResult) setState((prev) => ({ ...prev, error: undefined, isLoading: true }));
            else setState({ isLoading: true });

            async function _run() {
                try {
                    const result = await promise;
                    if (context.current.isMounted && context.current.call === _run) setState({ result });
                    return result;
                } catch (error) {
                    if (context.current.isMounted && context.current.call === _run) setState({ error });
                    if (options?.rethrowError) throw error;
                    log.error(error);
                } finally {
                    if (context.current.call === _run) context.current.call = null;
                }
            }

            context.current.call = _run;
            return _run();
        },
        [options]
    );

    return [state, apply] as const;
}
