import { Badge } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { Column, DataTableModel, DefaultRowHeight, ObjectDataTableStore } from '../../../components/DataTable';
import { SmilesColumn } from '../../../components/DataTable/common';
import { DialogService } from '../../../lib/services/dialog';
import { ToastService } from '../../../lib/services/toast';
import { ModelAction, ReactiveModel } from '../../../lib/util/reactive-model';
import { ECMApi } from '../../ECM/ecm-api';
import { BatchLink } from '../../ECM/ecm-common';
import { DiluteReagentResult, HTE2MSApi } from '../api';
import { HTEDReaction, HTEPProductSample, HTEPReagent, HTEPReagentKeyT } from '../data-model';
import { Formatters, ProductSampleUI, getProductSample } from '../utils';
import type { HTE2MSInventoryModel } from './model';

export interface DilutionOptions {
    barcode: string;
    concentration: string;
}

const EmptyInput: DilutionOptions = {
    barcode: '',
    concentration: '',
};

export interface DilutedReactionRow {
    reaction: HTEDReaction;
    old_sample: HTEPProductSample | undefined;
    new_sample: HTEPProductSample | undefined;
    scale: number | undefined;
    overflowing: boolean;
    changed: boolean;
}

export class HTE2MSDiluteModel extends ReactiveModel {
    private reactionStore = new ObjectDataTableStore<DilutedReactionRow, DilutedReactionRow>([
        { name: 'reaction', getter: (v) => v.reaction },
        { name: 'old_sample', getter: (v) => v.old_sample },
        { name: 'new_sample', getter: (v) => v.new_sample },
        { name: 'scale', getter: (v) => v.scale },
        { name: 'overflowing', getter: (v) => v.overflowing },
    ]);
    table: DataTableModel<DilutedReactionRow>;

    state = {
        input: new BehaviorSubject<DilutionOptions>(EmptyInput),
    };

    actions = {
        prepare: new ModelAction<{ input: DilutionOptions; reagent: HTEPReagent; data: DiluteReagentResult }>({
            onError: 'state',
        }),
    };

    reset() {
        this.actions.prepare.reset();
        this.reactionStore.setRows([]);
        this.table.dataChanged();
    }

    private async _prepare() {
        const input = this.state.input.value;
        const reagents = this.inventory.model.protocol.reagents.byKey;
        let reagent_key: HTEPReagentKeyT | undefined;

        for (const [key, inv] of Object.entries(this.inventory.model.inventory.data.liquid)) {
            if (!reagents.has(key)) continue;
            if (inv.source_barcode === input.barcode || inv.transfer_barcode === input.barcode) {
                reagent_key = key;
                break;
            }
        }

        if (!reagent_key) {
            throw new Error(`Reagent with barcode ${input.barcode} not found`);
        }

        const data = await HTE2MSApi.diluteReagent({
            library: this.inventory.model.toLibrary(),
            concentration: input.concentration,
            reagent_key,
        });

        return { input, reagent: reagents.get(reagent_key)!, data };
    }

    prepareDilution() {
        return this.actions.prepare.run(this._prepare());
    }

    private updateTable(result?: DiluteReagentResult) {
        if (!result) {
            this.reactionStore.setRows([]);
            this.table.dataChanged();
            return;
        }

        const rows: DilutedReactionRow[] = [];
        const reactionIds = new Set(result.reaction_ids);
        const dilutedIds = new Set(result.diluted_reaction_ids);
        const capacity = this.inventory.model.design.labware.product.volume;

        for (const r of result.library.design.reactions) {
            if (!reactionIds.has(r.id)) continue;

            const new_sample = getProductSample(r);
            const old_sample = this.inventory.model.protocol.data.product_samples[r.id];

            const overflowing = new_sample?.total_volume! > capacity || old_sample?.total_volume! > capacity;

            rows.push({
                reaction: r,
                changed: dilutedIds.has(r.id),
                old_sample: this.inventory.model.protocol.data.product_samples[r.id],
                new_sample,
                scale: r.scale,
                overflowing,
            });
        }

        this.reactionStore.setRows(rows);
        this.table.dataChanged();
    }

    private async _applyDilution() {
        const prepared = this.actions.prepare.currentResult;
        if (!prepared || this.inventory.model.readOnlyDesignAndProduction) return;

        this.reactionStore.setRows([]);
        this.table.dataChanged();

        // Update vial sample
        await ECMApi.solubilizeVialRacks({
            inputs: [
                {
                    barcode: prepared.input.barcode,
                    concentration: prepared.input.concentration,
                    solvent: prepared.reagent.solvent!,
                    rack_well: 'A1',
                    rack_barcode: 'ENR1',
                },
            ],
            event_context: this.inventory.model.libraryId,
            allow_update: true,
        });

        const assets = this.inventory.model.assets.inventory;
        await Promise.all([
            assets.syncHolders([prepared.input.barcode], true),
            assets.syncInventory([prepared.reagent.identifier], true),
        ]);
        await this.inventory.syncAssets();

        // Update library
        const currentReactions = this.inventory.model.design.all;
        const nextReactions = prepared.data.library.design.reactions.map(
            (r, i) => [currentReactions[i], r] as [HTEDReaction, HTEDReaction]
        );
        await this.inventory.model.design.modifyReactions(nextReactions, { cosmetic: true, doNotRequestBuild: true });
        this.inventory.state.inventory.next(prepared.data.library.inventory);
        this.inventory.model.events.requestBuild.next('protocol');

        ToastService.success('Dilution applied');
        this.state.input.next(EmptyInput);
        this.actions.prepare.reset();
    }

    applyDilution = () => {
        DialogService.open({
            type: 'generic',
            title: 'Apply Dilution',
            confirmButtonContent: 'Apply',
            wrapOk: true,
            content: ApplyDilutionDialogContent,
            onOk: () => this._applyDilution(),
        });
    };

    mount() {
        this.subscribe(this.actions.prepare.state, (s) =>
            this.updateTable(s.kind === 'result' ? s.result.data : undefined)
        );
    }

    constructor(public inventory: HTE2MSInventoryModel) {
        super();

        this.table = new DataTableModel<DilutedReactionRow>(this.reactionStore, {
            columns: {
                reaction: {
                    ...SmilesColumn(this.inventory.model.drawer, 2.5, {
                        width: 160,
                        getIdentifierElement: ({ rowIndex, showSMILES }) => {
                            const order = <b>[{rowIndex + 1}] </b>;
                            const reaction = this.reactionStore.getValue('reaction', rowIndex);
                            if (reaction.product_identifier) {
                                return (
                                    <span className={showSMILES ? 'font-body-xsmall' : undefined}>
                                        {order}
                                        <BatchLink
                                            identifier={this.inventory.model.assets.entities.getIdentifier(
                                                reaction.product_identifier
                                            )}
                                        />
                                    </span>
                                );
                            }
                            if (typeof reaction.product_enumeration?.substance_id === 'number') {
                                return (
                                    <span className={showSMILES ? 'font-body-xsmall' : undefined}>
                                        {order}
                                        <i className='text-info'>To be registered</i>
                                    </span>
                                );
                            }
                            return (
                                <span className={showSMILES ? 'font-body-xsmall' : undefined}>
                                    {order}Not enumerated
                                </span>
                            );
                        },
                        identifierPadding: 20,
                        getSMILES: (reaction) => this.inventory.model.assets.getStructure(reaction) ?? '',
                        autosize: true,
                        hideToggle: false,
                        disableChemDraw: true,
                        header: 'Product',
                    }),
                    disableGlobalFilter: true,
                } as any,
                old_sample: {
                    kind: 'generic',
                    format: (v) => '<unused>',
                    render: ({ value, table }) => {
                        if (!value) return null;

                        if (table.state.customState['show-smiles']) {
                            return (
                                <div className='hte2ms-table-scroll-cell'>
                                    <ProductSampleUI
                                        sample={value}
                                        wellVolume={this.inventory.model.design.labware.product.volume}
                                    />
                                </div>
                            );
                        }

                        return (
                            <ProductSampleUI
                                sample={value}
                                wellVolume={this.inventory.model.design.labware.product.volume}
                                inline
                            />
                        );
                    },
                    width: 120,
                    compare: false,
                    disableGlobalFilter: true,
                },
                new_sample: {
                    kind: 'generic',
                    format: (v) => '<unused>',
                    render: ({ value, table }) => {
                        if (!value) return null;

                        if (table.state.customState['show-smiles']) {
                            return (
                                <div className='hte2ms-table-scroll-cell'>
                                    <ProductSampleUI
                                        sample={value}
                                        wellVolume={this.inventory.model.design.labware.product.volume}
                                    />
                                </div>
                            );
                        }

                        return (
                            <ProductSampleUI
                                sample={value}
                                wellVolume={this.inventory.model.design.labware.product.volume}
                                inline
                            />
                        );
                    },
                    width: 120,
                    compare: false,
                    disableGlobalFilter: true,
                },
                scale: {
                    ...Column.float(),
                    header: 'Scale',
                    align: 'right',
                    render: ({ value }) => Formatters.rxnScale(value),
                    width: 120,
                },
                overflowing: {
                    ...Column.bool(),
                    width: 120,
                    render: ({ value }) => (value ? <Badge bg='danger'>Yes</Badge> : <Badge bg='success'>No</Badge>),
                },
            },
            hideNonSchemaColumns: true,
        });

        this.table.setCustomState({ 'show-smiles': true });
        this.table.setRowHeight(DefaultRowHeight * 2.5);
        this.table.setColumnStickiness('reaction', true);
    }
}

function ApplyDilutionDialogContent() {
    return (
        <>
            <div>Do you want to apply the dilution? This will:</div>
            <ul className='ps-3 mt-1'>
                <li>Update sample in the vial</li>
                <li>Update the concentration of all uses of the corresponding reagent</li>
            </ul>
        </>
    );
}
