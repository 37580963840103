import { saveAs } from 'file-saver';
import log from 'loglevel';
import { ReactNode } from 'react';
import { BehaviorSubject, Subject } from 'rxjs';
import { columnNameToHeader } from '../../api/data';
import { arrayToCsv } from '../../lib/util/arrayToCsv';
import { binarySearch } from '../../lib/util/binary-search';
import { normalizeFilename, objectIsEmpty } from '../../lib/util/misc';
import { ToastService } from '../../lib/services/toast';
import {
    Column,
    ColumnBase,
    ColumnFilters,
    ColumnFilterTypeMap,
    ColumnsFor,
    ColumnSorting,
    DefaultFloatColumnFormatOptions,
    FloatColumnFormatOptions,
    floatFormatColumnOptionsEqual,
} from './column';
import { DefaultDataTableFilterControls } from './Filters';
import { DataTableStore, MultiSortColumn } from './store';

export const DefaultColumnWidth = 120;
export const DefaultRowHeight = 38;

export interface DataTableSortBy {
    id: string;
    desc: boolean;
}

export interface DataTableFilter {
    id: string;
    value: any;
}

export interface DataTableSnapshot {
    state: DataTableState;
    data: any;
    selectedRows: Record<string | number, boolean>;
    pinnedRows?: number[];
}

export interface DataTableState<T extends object = any, Actions extends string = any> {
    sortBy: DataTableSortBy[];
    filters: DataTableFilter[];
    columns: (keyof T | Actions)[];
    // pinnedColumns: string[];
    showSelectedRowsOnly?: boolean;
    globalFilter?: string;
    stickyColumns: (keyof T | Actions)[];
    hiddenColumns: (keyof T | Actions)[];
    columnWidths: Record<keyof T | Actions, number>;
    columnFormatting: Partial<Record<keyof T, any>>;
    defaultFormatting: { [_ in Column['kind']]?: any };
    rowHeight: number;
    customState: Record<string, any>;
}

export interface ColumnInstance<Id = any> extends ColumnBase {
    id: Id;
    cell: (rowIndex: number, table: DataTableModel) => ReactNode;
    formatFn?: (v: any, options: any | undefined, table: DataTableModel, columnName: string) => string;
    csvHeaderFn?: (table: DataTableModel, options: { columnName: string }) => string;
    csvFormatFn?: (v: any, options: any | undefined, table: DataTableModel, columnName: string) => string;
    filterFn?: (v: any, test: any) => boolean;
    globalFilterFn?: (v: any, test: any) => boolean;
    compareFn?: (a: any, b: any) => number;
}

export interface DataTableRenderInfo {
    stickyColumns: ColumnInstance[];
    columns: ColumnInstance[];
    rowWidth: number;
}

export class DataTableModel<T extends {} = any, Actions extends string = any> {
    // to be incremented for any change in the table
    readonly version = new BehaviorSubject(0);

    events = {
        // allow listening for selection changes rather than guessing by checking the version
        selectionChanged: new Subject<void>(),
    };

    private _selectedRows: Readonly<Record<string | number, boolean>> = {};
    private _pinnedRows: number[] = [];
    private _state: Readonly<DataTableState<T, Actions>> = {
        sortBy: [],
        filters: [],
        columns: [],
        stickyColumns: [],
        hiddenColumns: [],
        columnFormatting: {},
        columnWidths: {} as any,
        defaultFormatting: {
            float: DefaultFloatColumnFormatOptions,
        },
        rowHeight: DefaultRowHeight,
        customState: {},
    };

    private filteredRows: number[] = [];
    private globalFilteredRows: number[] = [];
    private sortedRows: number[] = [];
    private finalRows: number[] = [];
    private _finalPinnedRows: { pinned: number[]; unpinned: number[] } = { pinned: [], unpinned: [] };

    public lastSelectedRowIndex?: number;

    public context: Record<string, any> = {};

    get preGlobalFilterRowCount() {
        return this.filteredRows.length;
    }

    get rows(): readonly number[] {
        return this.finalRows;
    }

    get finalPinnedRows() {
        return this._finalPinnedRows;
    }

    get allPinnedRows(): readonly number[] {
        return this._pinnedRows;
    }

    private _pinnedRowSet = new Set();
    get pinnedRowSet() {
        return this._pinnedRowSet;
    }

    get state(): Readonly<DataTableState<T, Actions>> {
        return this._state;
    }

    get selectedRows(): Readonly<Record<string, number>> {
        return this._selectedRows as any;
    }

    getSelectedRowIndices() {
        const indices: number[] = [];
        const { selectedRows } = this;
        for (let i = 0, _i = this.store.rowCount; i < _i; i++) {
            if (selectedRows[i]) indices.push(i);
        }
        return indices;
    }

    getSelectedAndFilteredRowIndices() {
        const indices: number[] = [];
        const { selectedRows } = this;
        for (const i of this.rows) {
            if (selectedRows[i]) indices.push(i);
        }
        return indices;
    }

    get currentVersion() {
        return this._version;
    }

    private _version = 0;
    private _renderInfoVersion = -1;
    private _renderInfo: DataTableRenderInfo = { columns: [], stickyColumns: [], rowWidth: 0 };

    get renderInfo() {
        if (this._renderInfoVersion === this._version) return this._renderInfo;
        const { hiddenColumns, columns, stickyColumns } = this.state;
        this._renderInfoVersion = this._version;
        this._renderInfo.columns = columns
            .filter((c) => !hiddenColumns.includes(c) && !stickyColumns.includes(c))
            .map((c) => this.columnMap.get(c)!);
        this._renderInfo.stickyColumns = stickyColumns
            .filter((c) => !hiddenColumns.includes(c))
            .map((c) => this.columnMap.get(c)!);
        this._renderInfo.rowWidth = columns.reduce(
            (a, b) => a + (hiddenColumns.includes(b) ? 0 : this.getColumnWidth(b)),
            0
        );
        return this._renderInfo;
    }

    getSnapshot(): DataTableSnapshot {
        return {
            state: this._state,
            data: this.store.getSnapshot(),
            selectedRows: this._selectedRows,
            pinnedRows: this._pinnedRows.length ? this._pinnedRows : undefined,
        };
    }

    setSnapshot(snapshot: DataTableSnapshot) {
        this._state = snapshot.state;
        this._selectedRows = snapshot.selectedRows;
        this._pinnedRows = snapshot.pinnedRows ?? [];
        this.store.setSnapshot(snapshot.data);
        this.dataChanged();
    }

    /* For better performance, prefer using specialized function (e.g. setColumnVisibility) */
    setFullState(newState: DataTableState<T, Actions>) {
        this._state = newState;
        this.dataChanged();
    }

    updated(options?: {
        clearRenderCache?: boolean;
        columnId?: keyof T | Actions;
        rowIndex?: number;
        silent?: boolean;
    }) {
        if (options?.clearRenderCache) {
            if (options.columnId) {
                if (typeof options.rowIndex === 'number') {
                    delete this.renderCache[options.columnId as any]?.[options.rowIndex];
                } else {
                    delete this.renderCache[options.columnId as any];
                }
            } else {
                this.renderCache = Object.create(null);
            }
        }
        this._version++;
        if (!options?.silent) this.version.next(this._version);
    }

    dataChanged(options?: { columnId?: Actions | keyof T; rowIndex?: number }) {
        if (options?.columnId) {
            const { filters, sortBy, globalFilter } = this.state;
            const column = this.columnMap.get(options?.columnId);

            const filterChanged = filters.some((f) => f.id === options?.columnId);
            const globalFilterChanged = filterChanged || (!column?.disableGlobalFilter && !!globalFilter);
            const sortChanged = globalFilterChanged || sortBy.some((f) => f.id === options?.columnId);

            if (filterChanged) this.filter();
            if (globalFilterChanged) this.globalFilter();
            if (sortChanged) {
                this.sort();
                this.finalizeRows();
            }
        } else {
            this.filter();
            this.globalFilter();
            this.sort();
            this.finalizeRows();
        }
        this.updated({ clearRenderCache: true, columnId: options?.columnId, rowIndex: options?.rowIndex });
    }

    private columnMap: Map<keyof T | Actions, ColumnInstance<keyof T | Actions>> = new Map();
    private renderCache: Record<any, Record<number, ReactNode>> = Object.create(null);

    allColumns: ColumnInstance<keyof T | Actions>[] = [];

    // The called of this is responsible to update the view manually
    setSelectedRowsOnlyUnsafe(selectedRowsOnly: boolean) {
        if (!!this._state.showSelectedRowsOnly !== selectedRowsOnly) {
            this._state = { ...this.state, showSelectedRowsOnly: selectedRowsOnly };
            return true;
        }
        return false;
    }

    setSelectedRowsOnly = (selectedRowsOnly: boolean) => {
        if (this.setSelectedRowsOnlyUnsafe(selectedRowsOnly)) {
            this.filter();
            this.globalFilter();
            this.finalizeRows();
            this.updated();
        }
    };

    setGlobalFilter(globalFilter: string) {
        const current = this.state.globalFilter;
        if ((!current && !globalFilter) || this.state.globalFilter === globalFilter) {
            return;
        }

        this._state = { ...this.state, globalFilter };
        this.globalFilter();
        this.finalizeRows();
        this.updated();
    }

    setFilter(column: keyof T, value: any) {
        const state = this.state;
        const index = state.filters.findIndex((c) => c.id === column);
        const filters = [...state.filters];

        const isUndefined = value === undefined || (Array.isArray(value) && value.every((v) => v === undefined));

        if (index >= 0) {
            if (isUndefined) filters.splice(index, 1);
            else filters[index] = { id: column as string, value };
        } else if (!isUndefined) {
            filters.push({ id: column as string, value });
        }
        this._state = { ...state, filters };
        this.lastSelectedRowIndex = undefined;
        this.filter();
        this.globalFilter();
        this.finalizeRows();
        this.updated();
    }

    setFiltersAndSortBy(filters: [column: keyof T, value: any][], sortBy: DataTableSortBy[]) {
        const newFilters: DataTableFilter[] = [];

        for (const [column, value] of filters) {
            const isUndefined = value === undefined || (Array.isArray(value) && value.every((v) => v === undefined));
            if (!isUndefined) {
                newFilters.push({ id: column as string, value });
            }
        }
        this._state = { ...this.state, filters: newFilters, sortBy };
        this.lastSelectedRowIndex = undefined;
        this.filter();
        this.globalFilter();
        this.sort();
        this.finalizeRows();
        this.updated();
    }

    getFilterValue(column: keyof T) {
        return this.state.filters.find((c) => c.id === column)?.value;
    }

    setCustomState(values: Record<string, any>, options?: { silent?: boolean; clearRenderCache?: boolean }) {
        this._state = {
            ...this.state,
            customState: { ...this.state.customState, ...values },
        };
        this.updated(options);
    }

    setRowHeight(rowHeight: number, options?: { silent?: boolean }) {
        this._state = { ...this.state, rowHeight };
        this.updated(options);
    }

    render(column: ColumnInstance, rowIndex: number) {
        let columnCache = this.renderCache[column.id];
        let cached = columnCache?.[rowIndex];
        if (cached) return cached;
        cached = column.cell(rowIndex, this);
        if (!columnCache) {
            columnCache = Object.create(null);
            this.renderCache[column.id] = columnCache;
        }
        columnCache[rowIndex] = cached;
        return cached;
    }

    getColumnInstance(name: keyof T | Actions) {
        return this.columnMap.get(name);
    }

    getColumnWidth(columnName: keyof T | Actions) {
        return this.state.columnWidths[columnName] ?? this.columnMap.get(columnName)!.width;
    }

    getColumnLabel(columnName: keyof T | Actions) {
        const name = String(columnName);
        return (
            this.columnMap.get(columnName)?.label ??
            `${name.charAt(0).toUpperCase()}${name.substring(1).replaceAll('_', ' ')}`
        );
    }

    getColumnSortBy(column: ColumnInstance) {
        const { sortBy } = this.state;
        const index = sortBy.findIndex((c) => c.id === column.id);
        if (index < 0) return undefined;
        return { index, by: sortBy[index] };
    }

    sortBy(column: keyof T, desc: boolean) {
        if (this.options.sortBy) {
            this.options.sortBy.update(column, desc);
            return;
        }

        const state = this.state;
        if (this.options.multisort) {
            const index = state.sortBy.findIndex((c) => c.id === column);
            const sortBy = [...state.sortBy];
            if (index >= 0) {
                sortBy[index] = { id: column as string, desc };
            } else {
                sortBy.push({ id: column as string, desc });
            }
            this._state = { ...state, sortBy };
        } else {
            this._state = { ...state, sortBy: [{ id: column as any, desc }] };
        }
        this.lastSelectedRowIndex = undefined;
        this.sort();
        this.finalizeRows();
        this.updated();
    }

    clearSort(column: keyof T) {
        if (this.options.sortBy) {
            this.options.sortBy.clear(column);
            return;
        }

        const state = this.state;
        this._state = { ...state, sortBy: state.sortBy.filter((c) => c.id !== column) };
        this.sort();
        this.finalizeRows();
        this.updated();
    }

    private finalizeRows() {
        if (this.globalFilteredRows.length === this.store.rowCount) {
            this.finalRows = this.sortedRows;
        } else {
            const set = new Set(this.globalFilteredRows);
            this.finalRows = this.sortedRows.filter(function _filter(this: Set<number>, v, _, __) {
                return this.has(v);
            }, set);
        }

        this.finalizePins();
    }

    private finalizePins() {
        const all = this._pinnedRows;

        if (all.length === 0) {
            this._pinnedRowSet.clear();
            this._finalPinnedRows = { pinned: [], unpinned: this.finalRows };
        } else {
            this._pinnedRowSet = new Set(all);
            const finalPinned = new Set(this.finalRows.filter((r) => this.pinnedRowSet.has(r)));
            this._finalPinnedRows = {
                pinned: all.filter((r) => finalPinned.has(r)),
                unpinned: this.finalRows.filter((r) => !this.pinnedRowSet.has(r)),
            };
        }
    }

    private filter() {
        const { filters, showSelectedRowsOnly: selectedRowsOnly } = this.state;

        let rows = new Array<number>(this.store.rowCount);
        for (let i = 0; i < this.store.rowCount; i++) rows[i] = i;

        if (selectedRowsOnly) {
            const selection = this._selectedRows;
            const selected: number[] = [];
            for (const r of rows) {
                if (selection[r]) selected.push(r);
            }
            rows = selected;
        }

        for (const f of filters) {
            if (f.value === undefined || f.value === null) {
                continue;
            }

            const col = this.columnMap.get(f.id as any)!;
            if (col.filterFn) {
                rows = this.store.filter(col.id as keyof T, col.filterFn, f.value, rows);
            }
        }

        this.filteredRows = rows;
        return rows;
    }

    private globalFilter() {
        const { globalFilter } = this.state;

        if (globalFilter) {
            const tests = this.allColumns
                .filter(
                    (c) =>
                        !c.disableGlobalFilter &&
                        (this.options.globalFilterHiddenColumns || !this.state.hiddenColumns.includes(c.id))
                )
                .map(
                    (c) =>
                        [c.id as keyof T, c.globalFilterFn ?? ColumnFilters.caseInsensitive] as [
                            keyof T,
                            (v: any, value: any) => boolean
                        ]
                );
            this.globalFilteredRows = this.store.filterAny(tests, globalFilter, this.filteredRows);
        } else {
            this.globalFilteredRows = this.filteredRows;
        }
        return this.globalFilteredRows;
    }

    private sort() {
        const { sortBy } = this.state;

        this.sortedRows = new Array<number>(this.store.rowCount);
        for (let i = 0; i < this.store.rowCount; i++) this.sortedRows[i] = i;

        if (sortBy.length === 0) return this.sortedRows;

        const cols: MultiSortColumn[] = [];
        for (const s of sortBy) {
            const col: keyof T = s.id as keyof T;
            const { compareFn } = this.getColumnInstance(col)!;
            if (compareFn) cols.push([col, compareFn, s.desc]);
        }

        if (cols.length === 0) return this.sortedRows;

        this.sortedRows = [...this.filteredRows];
        this.store.multiSort(cols, this.sortedRows);
        return this.sortedRows;
    }

    private normalizeColumnOrder(columns: (keyof T | Actions)[]) {
        const start: ColumnInstance[] = [];
        const end: ColumnInstance[] = [];

        const ret: (keyof T | Actions)[] = [];

        for (const c of columns) {
            if (!this.columnMap.has(c as any)) {
                log.warn(`normalizeColumnOrder: Column ${c as any} not found in table.`);
                continue;
            }
            const col = this.columnMap.get(c as any)!;
            if (col.position! >= 0) start.push(col);
            else if (col.position! < 0) end.push(col);
            else ret.push(c);
        }

        start.sort((a, b) => a.position! - b.position!);
        end.sort((a, b) => a.position! - b.position!);

        ret.unshift(...start.map((c) => c.id as any));
        ret.push(...end.map((c) => c.id as any));

        return ret;
    }

    setAllColumnsVisibility(visible: boolean) {
        const state = this.state;

        if (!visible) {
            this._state = {
                ...state,
                hiddenColumns: state.columns.filter((c) => !this.columnMap.get(c)?.alwaysVisible),
            };
        } else {
            this._state = { ...state, hiddenColumns: [] };
        }

        this.updated();
    }

    setColumnVisibility(
        col: keyof T | Actions,
        visible: boolean,
        options?: { updateOrder?: boolean; doNotUpdate?: boolean }
    ) {
        const state = this.state;
        let { columns, hiddenColumns } = state;

        if (visible) {
            if (!hiddenColumns.includes(col)) return;
            hiddenColumns = hiddenColumns.filter((c) => c !== col);

            if (options?.updateOrder) {
                columns = columns.filter((c) => c !== col);
                columns.push(col as any);
                columns = this.normalizeColumnOrder(columns);
            }
        } else {
            if (hiddenColumns.includes(col)) return;
            hiddenColumns = [...hiddenColumns, col];
        }

        this._state = { ...state, columns, hiddenColumns };
        if (!options?.doNotUpdate) this.updated();
    }

    setColumnStickiness(col: keyof T | Actions, sticky: boolean) {
        const state = this.state;
        let { stickyColumns } = state;

        if (stickyColumns.includes(col)) {
            if (sticky) return;
            stickyColumns = stickyColumns.filter((c) => c !== col);
        } else {
            if (!sticky) return;
            stickyColumns = [...stickyColumns, col];
        }

        this._state = { ...state, stickyColumns };
        this.updated();
    }

    updateColumnWidth(columnName: keyof T | Actions, value: { delta?: number; width?: number }) {
        const state = this.state;
        const { columnWidths } = state;
        const col = this.columnMap.get(columnName)!;

        const width =
            typeof value.delta === 'number'
                ? Math.max(40, (columnWidths[columnName] ?? col.width) + Math.min(value.delta, 300))
                : value.width;

        this._state = { ...state, columnWidths: { ...columnWidths, [columnName]: width } };
        this.updated();
    }

    getVisibleColumnValues(columnName: keyof T) {
        return this.store.getColumnValues(columnName, this.rows);
    }

    setColumnFormatting(columnName: keyof T, formatting: any) {
        const { columnFormatting } = this.state;
        columnFormatting[columnName] = formatting;
        this._state = { ...this.state, columnFormatting };
    }

    setColumnSchema(columnName: keyof T, schemaElement: Column) {
        this.addOrUpdateColumnSpec(columnName, schemaElement);
        if (schemaElement.defaultFormatting) {
            this._state = { ...this.state, columnFormatting: schemaElement.defaultFormatting };
        }
    }

    setHiddenColumns(hiddenColumns: (keyof T | Actions)[], updateVersion = false) {
        this._state = {
            ...this.state,
            hiddenColumns,
        };
    }

    get allSelected() {
        const sel = this._selectedRows;
        for (const r of this.rows) {
            if (!sel[r]) return false;
        }
        return true;
    }

    toggleSelectAll() {
        if (!this.rows.length) return;

        if (this.allSelected) {
            this.setSelection({});
        } else {
            this.setSelection(this.rows);
        }
    }

    setPinnedRows(rows: number[], pinColumnId: string, updateVersion = true) {
        this._pinnedRows = [...rows];
        this.finalizePins();
        this.updated({ clearRenderCache: true, columnId: pinColumnId as any, silent: !updateVersion });
    }

    setPinned(rowIndex: number, pinned: boolean, pinColumnId: string, updateVersion = true) {
        const idx = this._pinnedRows.indexOf(rowIndex);
        let changed = false;
        if (pinned && idx < 0) {
            changed = true;
            this._pinnedRows.push(rowIndex);
        } else if (!pinned && idx >= 0) {
            changed = true;
            this._pinnedRows.splice(idx, 1);
        }
        if (changed) {
            this.finalizePins();
            this.updated({ clearRenderCache: true, columnId: pinColumnId as any, rowIndex, silent: !updateVersion });
        }
    }

    setSelectedRange(rowIndex: number) {
        if (this.lastSelectedRowIndex === undefined) return;

        const newSelection = { ...this._selectedRows };
        newSelection[rowIndex] = true;
        let foundRange = false;

        const { pinned, unpinned } = this.finalPinnedRows;
        const N = pinned.length + unpinned.length;
        const nPinned = pinned.length;

        for (let i = 0; i < N; i++) {
            const currentRowIndex = i < nPinned ? pinned[i] : unpinned[i - nPinned];

            if (foundRange) {
                newSelection[currentRowIndex] = true;
            }
            if (currentRowIndex === rowIndex || currentRowIndex === this.lastSelectedRowIndex) {
                if (foundRange) break;
                else foundRange = true;
            }
        }

        this.setSelection(newSelection);
    }

    setSelected(rowIndex: number, selected: boolean, updateVersion = true) {
        const sel = this._selectedRows;
        if (!!sel[rowIndex] !== !!selected) {
            const newSel = { ...sel };
            if (!selected) delete newSel[rowIndex];
            else newSel[rowIndex] = true;
            this._selectedRows = newSel;

            if (this.state.showSelectedRowsOnly) {
                this.filter();
                this.globalFilter();
                this.finalizeRows();
            }

            this.lastSelectedRowIndex = selected ? rowIndex : undefined;
            this.events.selectionChanged.next();
            this.updated({ clearRenderCache: true, silent: !updateVersion });
        }
    }

    private selectionChanged(updateVersion: boolean) {
        if (this.state.showSelectedRowsOnly) {
            this.filter();
            this.globalFilter();
            this.finalizeRows();
        }
        this.events.selectionChanged.next();
        this.updated({ clearRenderCache: true, silent: !updateVersion });
    }

    invertSelection() {
        const newSelection: Record<number | string, boolean> = {};
        // only inverts on visible rows (invisible will remain unselected)
        for (const rowIdx of this.rows) {
            if (!this.selectedRows[rowIdx]) {
                newSelection[rowIdx] = true;
            }
        }
        this.setSelection(newSelection);
    }

    setSelection(selection: Record<number | string, boolean> | ArrayLike<number>, updateVersion = true): boolean {
        const oldSelection = this._selectedRows;
        const oldKeys = Object.keys(oldSelection);
        let newSelection: Record<string | number, boolean>;
        if (Array.isArray(selection)) {
            newSelection = Object.create(null);
            for (const idx of selection) newSelection[idx] = true;
        } else {
            newSelection = selection as any;
        }

        const newKeys = Object.keys(newSelection);

        if (!newKeys.length) {
            if (oldKeys.length) {
                this._selectedRows = newSelection;
                this.lastSelectedRowIndex = undefined;
                this.selectionChanged(updateVersion);
                return true;
            }
            return false;
        }

        if (oldKeys.length === newKeys.length) {
            oldKeys.sort();
            newKeys.sort();
            let allEqual = true;
            for (let i = 0; i < oldKeys.length; i++) {
                const k = oldKeys[i];
                if (k !== newKeys[i] || !!oldSelection[k as any] !== !!newSelection[k as any]) {
                    allEqual = false;
                    break;
                }
            }
            if (allEqual) return false;
        }
        this._selectedRows = newSelection;
        this.lastSelectedRowIndex = undefined;
        this.selectionChanged(updateVersion);
        return true;
    }

    setFloatFormatting(format: FloatColumnFormatOptions, options?: { columnNames: (keyof T)[] }) {
        const { columnFormatting, defaultFormatting } = this.state;
        let formattingChanged = false;
        const newFormatting = { ...columnFormatting };
        const cols = options?.columnNames ?? this.store.columnNames;
        for (const c of cols) {
            const col = this.getColumnInstance(c);
            if (col?.kind === 'float' || (col as any)?.defaultFormatting) {
                const f = columnFormatting[c] as FloatColumnFormatOptions;

                if (!floatFormatColumnOptionsEqual(f, format)) {
                    formattingChanged = true;
                    newFormatting[c] = format;
                }
            }
        }
        if (formattingChanged || !floatFormatColumnOptionsEqual(defaultFormatting.float, format)) {
            this._state = {
                ...this.state,
                columnFormatting: newFormatting,
                defaultFormatting: {
                    ...defaultFormatting,
                    float: format,
                },
            };
        }
        this.updated();
    }

    orderColumns(order: (keyof T | Actions)[]) {
        const indexed = new Map<string, number>(order.map((c, i) => [c as string, i]));
        for (const c of this.allColumns) {
            if (!indexed.has(c.id as string)) {
                indexed.set(c.id as string, indexed.size);
            }
        }
        const columns = Array.from(this.state.columns);
        columns.sort((a, b) => indexed.get(a as any)! - indexed.get(b as any)!);
        this._state = { ...this.state, columns };
        this.updated();
    }

    reorderColumns(ordering: [columnName: keyof T | Actions, index: number][]) {
        const columns = [...this.state.columns];

        for (const [columnName, order] of ordering) {
            const idx = columns.indexOf(columnName);
            if (idx < 0) continue;
            columns.splice(idx, 1);
            columns.splice(order, 0, columnName);
        }

        this._state = { ...this.state, columns };
        this.updated();
    }

    isRowVisible(rowIndex: number) {
        const rows = this.filteredRows;
        return binarySearch(rows, rowIndex, 0, rows.length) >= 0;
    }

    addActionColumns(actionColumns: ColumnInstance<Actions>[]) {
        const columns = [...this.state.columns];
        let columnsChanged = false;

        for (const column of actionColumns) {
            const col: ColumnInstance<Actions> = {
                ...column,
                disableGlobalFilter: !column?.globalFilterFn,
            };

            const existingIndex = this.allColumns.findIndex((c) => c.id === column.id);
            if (existingIndex >= 0) {
                this.allColumns[existingIndex] = col;
            } else {
                this.allColumns.push(col);
            }

            this.columnMap.set(column.id as any, col);

            if (!columns.includes(column.id)) {
                columns.push(column.id);
                columnsChanged = true;
            }
        }

        if (columnsChanged) {
            this._state = { ...this.state, columns: this.normalizeColumnOrder(columns) };
        }

        this.updated();
    }

    addOrUpdateColumns<V extends keyof T>(
        cols: [name: V, values: T[V][] | undefined, column?: Column][],
        options?: { doNotUpdate?: boolean; startIdx?: number }
    ) {
        const { columnFormatting, defaultFormatting } = this.state;
        const columns = [...this.state.columns];
        let columnsChanged = false;

        const newFormatting = { ...columnFormatting };
        let formattingChanged = false;

        let colIdx = options?.startIdx;

        for (const [name, values, column] of cols) {
            if (values) this.store.addOrUpdateColumn(name, values);
            if (column) {
                this.addOrUpdateColumnSpec(name, column);
                if (column.defaultFormatting) {
                    newFormatting[name] = defaultFormatting[column.kind] ?? column?.defaultFormatting;
                    formattingChanged = true;
                }
            }

            if (!columns.includes(name)) {
                if (typeof colIdx === 'number') {
                    columns.splice(colIdx++, 0, name);
                } else {
                    columns.push(name);
                }
                columnsChanged = true;
            }
        }

        if (formattingChanged || columnsChanged) {
            this._state = {
                ...this.state,
                columnFormatting: newFormatting,
                columns: columnsChanged ? this.normalizeColumnOrder(columns) : columns,
            };
        }

        if (!options?.doNotUpdate) {
            this.updated();
        }
    }

    removeColumn<V extends keyof T | Actions>(
        columnName: V,
        options?: { doNotUpdate?: boolean; ignoreStore?: boolean }
    ) {
        if (!this.getColumnInstance(columnName)) {
            log.warn(`Column '${columnName as any}' not found, skipping.`);
            return;
        }

        if (!options?.ignoreStore) this.store.removeColumn(columnName as keyof T);
        this.columnMap.delete(columnName);
        this.allColumns = this.allColumns.filter((c) => c.id !== columnName);
        const columns = this.state.columns.filter((c) => c !== columnName);
        const columnWidths = { ...this.state.columnWidths };
        delete columnWidths[columnName];
        const columnFormatting = { ...this.state.columnFormatting };
        delete (columnFormatting as any)[columnName];
        if (columns.length !== this.state.columns.length) {
            this._state = {
                ...this.state,
                columns,
                columnWidths,
                stickyColumns: this.state.stickyColumns.filter((c) => c !== columnName),
                filters: this.state.filters.filter((f) => f.id !== columnName),
                sortBy: this.state.sortBy.filter((f) => f.id !== columnName),
                columnFormatting,
            };
        }
        if (!options?.doNotUpdate) this.dataChanged();
    }

    removeColumns<V extends keyof T>(names: V[], options?: { doNotUpdate?: boolean; ignoreStore?: boolean }) {
        for (const name of names) {
            this.removeColumn(name, { doNotUpdate: true, ignoreStore: options?.ignoreStore });
        }
        if (!options?.doNotUpdate) this.dataChanged();
    }

    toCsvString(options?: {
        indices?: number[];
        ignoreSelection?: boolean;
        useRawValues?: boolean;
        // TODO: if we need more functionality to control the exported CSV columns,
        //       change the includeIfHidden field to columns instead
        includeIfHidden?: (string | number)[];
    }) {
        const { columnNames } = this.store;
        const { hiddenColumns, columns: allColumns, stickyColumns } = this.state;
        const columns = [...stickyColumns, ...allColumns.filter((c) => !stickyColumns.includes(c))]
            .filter(
                (c: any) =>
                    columnNames.includes(c) && (!hiddenColumns.includes(c) || options?.includeIfHidden?.includes(c))
            )
            .map((c) => this.columnMap.get(c)!);

        const rows: any[][] = [];

        rows.push(
            columns.map((c) => c.csvHeaderFn?.(this, { columnName: c.id as string }) ?? this.getColumnLabel(c.id))
        );

        const values = columns.map((c) => this.store.getColumnValues(c.id as any));
        const selection = this._selectedRows;
        const hasSelection = !objectIsEmpty(selection);

        const formatting = this.state.columnFormatting;
        const formatters = columns.map((col) => {
            if (options?.useRawValues) {
                return (v: any) => (Number.isNaN(v) ? '' : v);
            }

            const { formatFn, csvFormatFn } = col!;
            const format =
                csvFormatFn ??
                formatFn ??
                ((value: any, _: any, __: any, ___: any) => (value === null || value === undefined ? '' : value));

            const currentFormatting = formatting[col.id as keyof T];
            return (v: any) => format(v, currentFormatting, this, String(col.id));
        });

        for (const rowIndex of options?.indices ?? this.rows) {
            if (!options?.ignoreSelection && hasSelection && !selection[rowIndex]) {
                continue;
            }

            const row: any[] = [];
            for (let i = 0; i < columns.length; i++) {
                row.push(formatters[i](values[i][rowIndex]));
            }
            rows.push(row);
        }
        return arrayToCsv(rows);
    }

    private addOrUpdateColumnSpec(columnName: keyof T, column: Column) {
        const columnNameString = String(columnName);
        const colInstance: ColumnInstance<keyof T> = {
            ...column,
            id: columnNameString,
        } as any;

        if (!colInstance.header) {
            colInstance.header = columnNameToHeader(columnName as string);
        }

        const { compare, format, csvHeader, csvFormat, render, defaultFormatting, disableGlobalFilter, filterType } =
            column!;
        if (compare) {
            colInstance.compareFn = compare;
        } else if (compare !== false) {
            colInstance.compareFn = ColumnSorting.DefaultCompareWithBlanks;
        }

        colInstance.disableGlobalFilter = disableGlobalFilter;

        if (filterType !== false) {
            colInstance.filterFn = ColumnFilterTypeMap[filterType ?? 'includes'];

            if (!colInstance.filterControl) {
                colInstance.filterControl = DefaultDataTableFilterControls[filterType ?? 'includes'];
            }
        }

        if (column.filterFn) {
            colInstance.filterFn = column.filterFn(this);
        }

        if (column.globalFilterFn) {
            colInstance.globalFilterFn = column.globalFilterFn(this);
            colInstance.disableGlobalFilter = false;
        }

        colInstance.align = column.align;
        colInstance.width = column.width ?? DefaultColumnWidth;

        const formatFn =
            format ??
            ((value: any, _: any, __: any) => {
                if (value === null || value === undefined) return '';
                if (typeof value !== 'object') return `${value}`;
                log.warn('Cannot convert object to string.');
                return '';
            });

        colInstance.formatFn = formatFn;

        colInstance.csvFormatFn = csvFormat;
        colInstance.csvHeaderFn = typeof csvHeader === 'string' ? () => csvHeader : csvHeader;

        if (defaultFormatting && render) {
            colInstance.cell = (rowIndex) => {
                const value = this.store.getValue(columnName, rowIndex);
                return render({
                    value,
                    formatted: formatFn(value, this.state.columnFormatting[columnName], this, columnNameString),
                    rowIndex,
                    columnName: columnNameString,
                    table: this,
                });
            };
        } else if (defaultFormatting) {
            colInstance.cell = (rowIndex) =>
                formatFn(
                    this.store.getValue(columnName, rowIndex),
                    this.state.columnFormatting[columnName],
                    this,
                    columnNameString
                );
        } else if (render) {
            colInstance.cell = (rowIndex) => {
                const value = this.store.getValue(columnName, rowIndex);
                return render({
                    value,
                    formatted: formatFn(value, undefined, this, columnNameString),
                    rowIndex,
                    columnName: columnNameString,
                    table: this,
                });
            };
        } else {
            colInstance.cell = (rowIndex) =>
                formatFn(this.store.getValue(columnName, rowIndex), undefined, this, columnNameString);
        }

        const existingIndex = this.allColumns.findIndex((c) => c.id === columnName);
        if (existingIndex >= 0) {
            this.allColumns[existingIndex] = colInstance;
        } else {
            this.allColumns.push(colInstance);
        }

        this.columnMap.set(columnName, colInstance);

        return colInstance;
    }

    constructor(
        public store: DataTableStore<T>,
        private options: {
            columns: Partial<ColumnsFor<T>> | [colName: keyof T, column: Column][];
            hideNonSchemaColumns?: boolean;
            actions?: ColumnInstance<Actions>[];
            rowHeight?: number;
            customState?: Record<string, any>;
            initialState?: Partial<DataTableState<T, Actions>>;
            globalFilterHiddenColumns?: boolean;
            multisort?: boolean;
            sortBy?: {
                update: (column: keyof T, desc: boolean) => void;
                clear: (column: keyof T) => void;
            };
        }
    ) {
        const columnFormatting: DataTableState<T>['columnFormatting'] = {};
        const columns: (keyof T | Actions)[] = [];
        const hiddenColumns: (keyof T)[] = [];

        let columnNames: (keyof T)[] = [];
        let columnMap: Partial<ColumnsFor<T>> = {};

        if (Array.isArray(options.columns)) {
            // If options.columns is an array, create the columns in the order
            // specified by the array
            columnNames = options.columns.map((t) => t[0]).filter((c) => store.columnNames.includes(c));
            for (const [c, column] of options.columns) {
                columnMap[c] = column;
            }
        } else {
            columnNames = [...store.columnNames];
            columnMap = options.columns;
        }

        for (const c of columnNames) {
            this.addOrUpdateColumnSpec(c, columnMap[c] ?? Column.generic());
            const column = columnMap[c];
            if (column?.defaultFormatting) {
                columnFormatting[c] = column.defaultFormatting;
            }
            columns.push(c);
        }
        if (options.hideNonSchemaColumns) {
            for (const c of store.columnNames) {
                if (!columnMap[c]) hiddenColumns.push(c);
            }
        }

        this._state = { ...this.state, columnFormatting, columns, hiddenColumns, ...options.initialState };

        if (options.rowHeight) this.setRowHeight(options.rowHeight, { silent: true });
        if (options.customState) this.setCustomState(options.customState, { silent: true });
        if (options.actions) this.addActionColumns(options.actions);

        this.dataChanged();
    }
}

export function saveDataTableAsCSV(
    filename: string,
    table: DataTableModel,
    options?: { ignoreSelection?: boolean; useRawValues?: boolean; includeIfHidden?: (string | number)[] }
) {
    try {
        const { pinned, unpinned } = table.finalPinnedRows;
        const str = table.toCsvString({ ...options, indices: [...pinned, ...unpinned] });
        const blob = new Blob([str], { type: 'text/csv' });
        saveAs(blob, `${normalizeFilename(filename)}-${Date.now()}.csv`);
    } catch (err) {
        log.error(err);
        ToastService.show({
            type: 'danger',
            message: 'Error generating CSV file',
        });
    }
}
