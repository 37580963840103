import { faExclamationTriangle, faGrip, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { IconButton } from '../../../components/common/IconButton';
import { LabeledInput, SimpleSelectOptionInput, TextInput } from '../../../components/common/Inputs';
import useBehavior from '../../../lib/hooks/useBehavior';
import { DialogService } from '../../../lib/services/dialog';
import { formatWithUnit, parseWithUnit } from '../../../lib/util/units';
import { HTEDLabware, HTEDLabwareDefinition } from '../data-model';
import type { HTE2MSDesignModel } from './model';
import { InlineAlert } from '../../../components/common/Alert';

export function EditLabwareButton({ model }: { model: HTE2MSDesignModel }) {
    const show = () => {
        DialogService.open({
            type: 'generic',
            title: 'Edit Labware',
            confirmButtonContent: 'Apply',
            defaultState: model.state.labware.value,
            wrapOk: true,
            model,
            content: LabwareDialogContent,
            options: { hideFooter: model.model.readOnlyDesignAndProduction },
            onOk: (state: HTEDLabware) => model.updateLabware(state),
        });
    };

    return (
        <IconButton icon={faGrip} variant='link' size='sm' onClick={show}>
            Labware
        </IconButton>
    );
}

const LabelWidth = 140;

const LabwareOptions = [
    [24, '24 Well'],
    [96, '96 Well'],
] as [number, string][];

function EditLabware({
    model,
    labware,
    setValue,
    product,
}: {
    model: HTE2MSDesignModel;
    labware: HTEDLabwareDefinition;
    setValue: (p: keyof HTEDLabwareDefinition, v: any) => void;
    product?: boolean;
}) {
    const readOnly = model.model.readOnlyDesignAndProduction;

    return (
        <div className='vstack gap-1 font-body-small'>
            {!product && (
                <InlineAlert icon={faExclamationTriangle} variant='warning'>
                    Changing Labware ID field requires re-assigning it in <b>Reagents</b> and <b>Production</b> tabs.
                    All other fields can be adjusted in-place.
                </InlineAlert>
            )}
            {!product && (
                <LabeledInput label='ID' labelWidth={LabelWidth}>
                    <TextInput
                        value={labware.id}
                        setValue={(v) => setValue('id', v.trim())}
                        size='sm'
                        readOnly={readOnly}
                    />
                </LabeledInput>
            )}
            <LabeledInput label='Protocol Name' labelWidth={LabelWidth}>
                <TextInput
                    value={labware.protocol_name}
                    setValue={(v) => setValue('protocol_name', v.trim())}
                    size='sm'
                    readOnly={readOnly}
                />
            </LabeledInput>
            <LabeledInput label='Label' labelWidth={LabelWidth}>
                <TextInput
                    value={labware.label}
                    setValue={(v) => setValue('label', v.trim())}
                    size='sm'
                    readOnly={readOnly}
                />
            </LabeledInput>
            <LabeledInput label='Volume' labelWidth={LabelWidth}>
                <TextInput
                    value={formatWithUnit(labware.volume, 1e6, 'mL')}
                    tryUpdateValue={(v) => parseWithUnit(v, 'mL')}
                    setValue={(v) => setValue('volume', v)}
                    size='sm'
                    readOnly={readOnly}
                />
            </LabeledInput>
            {!product && (
                <LabeledInput label='Dead Volume' labelWidth={LabelWidth}>
                    <TextInput
                        value={formatWithUnit(labware.dead_volume, 1e9, 'uL')}
                        tryUpdateValue={(v) => parseWithUnit(v, 'uL')}
                        setValue={(v) => setValue('dead_volume', v)}
                        size='sm'
                        readOnly={readOnly}
                    />
                </LabeledInput>
            )}
            <LabeledInput
                label='No Transfer'
                labelWidth={LabelWidth}
                tooltip='Indicate whether the labware needs a transfer. For example, Verso tubes do not.'
            >
                <Form.Switch
                    checked={!!labware.no_transfer}
                    onChange={(e) => setValue('no_transfer', e.target.checked)}
                    disabled={readOnly}
                />
            </LabeledInput>
            {!product && (
                <LabeledInput label='Is Reservoir' labelWidth={LabelWidth}>
                    <Form.Switch
                        checked={!!labware.is_reservoir}
                        onChange={(e) => setValue('is_reservoir', e.target.checked)}
                        disabled={readOnly}
                    />
                </LabeledInput>
            )}
            <LabeledInput label='Vial Prefixes' labelWidth={LabelWidth} tooltip='Comma separated'>
                <TextInput
                    value={labware?.barcode_prefixes}
                    tryUpdateValue={parseBarcodePrefixes}
                    formatValue={(v) => v?.join(', ') ?? ''}
                    setValue={(v) => setValue('barcode_prefixes', v)}
                    size='sm'
                    readOnly={readOnly}
                />
            </LabeledInput>
            <LabeledInput
                label='Layout'
                labelWidth={LabelWidth}
                tooltip={product ? undefined : 'Use <empty> if not available. The ELN will handle it automatically.'}
            >
                <SimpleSelectOptionInput
                    value={labware.layout || ''}
                    allowEmpty={!product}
                    options={LabwareOptions as any}
                    setValue={(v) => setValue('layout', v || undefined)}
                    size='sm'
                    disabled={readOnly}
                />
            </LabeledInput>
        </div>
    );
}

function parseBarcodePrefixes(v: string) {
    return Array.from(
        new Set(
            v
                .split(',')
                .map((s) => s.trim())
                .filter((s) => s.length)
        )
    ).sort();
}

function LabwareDialogContent({
    stateSubject,
    model,
}: {
    model: HTE2MSDesignModel;
    stateSubject: BehaviorSubject<HTEDLabware>;
}) {
    const readOnly = model.model.readOnlyDesignAndProduction;
    const labware = useBehavior(stateSubject);
    const [current, setCurrent] = useState<'product' | number>('product');
    const labwareOptions = [
        ['product', 'Product'] as [any, string],
        ...labware.sources.map((src, i) => [i, src.label] as [any, string]),
    ];

    return (
        <div className='vstack gap-2'>
            <div className='hstack gap-2'>
                <SimpleSelectOptionInput
                    value={current}
                    options={labwareOptions}
                    setValue={(v) => setCurrent(v)}
                    size='sm'
                    className='flex-grow-1'
                    disabled={readOnly}
                />
                <IconButton
                    icon={faTrash}
                    variant='link'
                    size='sm'
                    onClick={() => {
                        setCurrent('product');
                        const sources = [...labware.sources];
                        sources.splice(current as number, 1);
                        stateSubject.next({ ...labware, sources });
                    }}
                    title='Remove'
                    disabled={current === 'product' || readOnly}
                />
                <IconButton
                    icon={faPlus}
                    variant='link'
                    size='sm'
                    onClick={() => {
                        setCurrent('product');
                        const sources: HTEDLabwareDefinition[] = [
                            ...labware.sources,
                            {
                                id: 'new-labware',
                                protocol_name: 'rack_layout_volume',
                                label: 'New Label',
                                volume: '10 mL' as any,
                                dead_volume: '500 uL' as any,
                                no_transfer: false,
                                is_reservoir: false,
                            },
                        ];
                        stateSubject.next({ ...labware, sources });
                        setCurrent(sources.length - 1);
                    }}
                    title='Add'
                    disabled={readOnly}
                />
            </div>
            {current === 'product' ? (
                <EditLabware
                    labware={labware.product}
                    setValue={(p, v) => stateSubject.next({ ...labware, product: { ...labware.product, [p]: v } })}
                    product
                    model={model}
                />
            ) : (
                <EditLabware
                    labware={labware.sources[current]}
                    setValue={(p, v) =>
                        stateSubject.next({
                            ...labware,
                            sources: labware.sources.map((s, i) => (current === i ? { ...s, [p]: v } : s)),
                        })
                    }
                    model={model}
                />
            )}
        </div>
    );
}
