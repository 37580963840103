import {
    faArrowRight,
    faExclamation,
    faFilter,
    faMagnifyingGlass,
    faPlus,
    faRemove,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useState } from 'react';
import { Button, ButtonGroup, Spinner } from 'react-bootstrap';
import Select, { createFilter } from 'react-select';
import Split from 'react-split-it';
import { BehaviorSubject } from 'rxjs';
import { ErrorMessage } from '../../../components/common/Error';
import { iiChemDrawLogo } from '../../../components/common/Icons';
import { SimpleSelectOptionInput, TextInput } from '../../../components/common/Inputs';
import Loading from '../../../components/common/Loading';
import { LogMessages } from '../../../components/common/Log';
import { CustomSelectClassNames, DefaultSelectStyles } from '../../../components/common/selectStyles';
import { PageTemplate } from '../../../components/Layout/Layout';
import Pane from '../../../components/Pane/Pane';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import { ChemDrawModalService } from '../../../lib/services/chemdraw-modal';
import { DialogService } from '../../../lib/services/dialog';
import { tryGetErrorMessage } from '../../../lib/util/errors';
import { DataTableControl } from '../../../components/DataTable';
import { FindSimilarCompoundsModal } from '../similar-compounds/FindSimilarCompounds';
import { EnumerationAPI } from './enumeration-api';
import { HTEEnumerationModel, ReactionOption } from './enumeration-model';

async function initModel() {
    const [reactionsInfo, filterInfo] = await Promise.all([
        EnumerationAPI.reactionsInfo(),
        EnumerationAPI.filterInfo(),
    ]);
    return new HTEEnumerationModel({ reactionsInfo, filterInfo });
}

export function HTEEnumerationUI() {
    const [_model, loadModel] = useAsyncAction<HTEEnumerationModel>();
    const model = _model.result;

    useEffect(() => {
        loadModel(initModel());
    }, []);

    useEffect(() => {
        if (!model) return;
        const m = model;
        return () => m.dispose();
    }, [model]);

    if (!model) {
        return (
            <PageTemplate
                title='HTE'
                breadcrumb={{
                    title: 'Enumeration',
                    href: '',
                }}
            >
                {_model.isLoading && <Loading />}
                {_model.error && <ErrorMessage header='Enumeration' message={tryGetErrorMessage(_model.error)} />}
            </PageTemplate>
        );
    }

    return (
        <PageTemplate
            title='HTE'
            breadcrumb={{
                title: 'Enumeration',
                href: '',
            }}
        >
            <div className='d-flex h-100'>
                {/* <Split direction='vertical' gutterSize={6} sizes={[0.82, 0.18]}> */}
                <Split direction='horizontal' gutterSize={6} sizes={[0.6, 0.4]}>
                    <div className='d-flex h-100 w-100 flex-column'>
                        <div className='flex-0 border-bottom'>
                            <Pane
                                title='Enumeration'
                                paddingIndex={2}
                                topRightContent={<EnumerateButton model={model} />}
                            >
                                <EnumerationUI model={model} />
                            </Pane>
                        </div>
                        <div className='flex-grow-1 position-relative'>
                            <Split direction='horizontal' gutterSize={6} sizes={[0.5, 0.5]}>
                                <Pane
                                    title='MSDs'
                                    topRightContent={<ManageReactants model={model} kind='msd' />}
                                    paddingIndex={2}
                                    contentPaddingIndex={0}
                                >
                                    <MSDsUI model={model} />
                                </Pane>
                                <Pane
                                    title='BBs'
                                    topRightContent={<ManageReactants model={model} kind='bb' />}
                                    paddingIndex={2}
                                    contentPaddingIndex={0}
                                >
                                    <BBsUI model={model} />
                                </Pane>
                            </Split>
                        </div>
                        <div className='flex-0 hte-enumeration-event-log border-top'>
                            <Pane title='Log' paddingIndex={2}>
                                <LogMessages log={model.log} />
                            </Pane>
                        </div>
                    </div>
                    <Split direction='vertical' sizes={[0.4, 0.6]}>
                        <Pane
                            title='Product Filters'
                            paddingIndex={2}
                            topRightContent={<FilterButtons model={model} />}
                        >
                            <ProductFilterUI model={model} />
                        </Pane>
                        <Pane
                            title={<ReactionsTitle model={model} />}
                            paddingIndex={2}
                            contentPaddingIndex={0}
                            topRightContent={<ReactionsButtons model={model} />}
                        >
                            <ReactionsWrapper model={model} />
                        </Pane>
                    </Split>
                </Split>
                {/* <Pane title='Log'>
                        <LogUI model={model} />
                    </Pane>
                </Split> */}
            </div>
            <FindSimilarCompoundsModal model={model.msdSimilarCompounds} />
            <FindSimilarCompoundsModal model={model.bbSimilarCompounds} />
        </PageTemplate>
    );
}

function MSDsUI({ model }: { model: HTEEnumerationModel }) {
    const table = model.msdTable;
    useBehavior(table.version);
    return <DataTableControl height='flex' table={table} headerSize='sm' />;
}

function BBsUI({ model }: { model: HTEEnumerationModel }) {
    const table = model.bbTable;
    useBehavior(table.version);
    return <DataTableControl height='flex' table={table} headerSize='sm' />;
}

function ManageReactants({ model, kind }: { model: HTEEnumerationModel; kind: 'msd' | 'bb' }) {
    const [addState, applyAdd] = useAsyncAction();

    const onClear = () => {
        DialogService.open({
            type: 'confirm',
            title: 'Remove All',
            text: 'Do you really want to remove all reactants?',
            onConfirm: () => model.clearReactants(kind),
        });
    };

    const onClickSearch = () => {
        if (kind === 'bb') {
            model.bbSimilarCompounds.state.showSimilarCompounds.next(true);
        } else if (kind === 'msd') {
            model.msdSimilarCompounds.state.showSimilarCompounds.next(true);
        }
    };

    const onClickAdd = () => {
        DialogService.open({
            type: 'generic',
            title: `Add ${kind === 'bb' ? 'BBs' : 'MSDs'}`,
            model,
            defaultState: { input: '' },
            content: AddReactantDialogControls,
            onOk: (state) => applyAdd(kind === 'msd' ? model.addMSDs(state.input) : model.addBBs(state.input)),
        });
    };
    return (
        <div className='hstack'>
            <Button size='sm' variant='link' onClick={onClickSearch}>
                {addState.isLoading && <Spinner animation='border' size='sm' role='status' className='me-2' />}
                {!addState.isLoading && (
                    <FontAwesomeIcon size='sm' className='text-primary me-2' icon={faMagnifyingGlass} />
                )}
                Search
            </Button>
            <Button size='sm' variant='link' onClick={onClickAdd}>
                {addState.isLoading && <Spinner animation='border' size='sm' role='status' className='me-2' />}
                {!addState.isLoading && <FontAwesomeIcon size='sm' className='text-primary me-2' icon={faPlus} />}
                Add
            </Button>
            <Button size='sm' variant='link' onClick={onClear}>
                <FontAwesomeIcon size='sm' className='text-secondary' icon={faTrash} />
            </Button>
        </div>
    );
}

function AddReactantDialogControls({ stateSubject }: { stateSubject: BehaviorSubject<{ input: string }> }) {
    const state = useBehavior(stateSubject);
    return (
        <div>
            <TextInput
                textarea
                value={state.input}
                setValue={(input) => stateSubject.next({ input })}
                placeholder='Enter a space-separated list of SMILES, barcodes, or batch/compound identifiers'
                autoFocus
            />
            <Button
                className='position-absolute px-1 py-0 font-body-small'
                style={{ right: '24px', top: '18px' }}
                variant='link'
                onClick={() =>
                    ChemDrawModalService.show({
                        onSMILESDrawn: (smilesStr: string) => {
                            stateSubject.next({ input: `${state.input}${state.input ? '\n' : ''}${smilesStr}` });
                        },
                    })
                }
            >
                <FontAwesomeIcon className='pe-none' icon={iiChemDrawLogo} color='var(--bs-primary)' fixedWidth />
            </Button>
        </div>
    );
}

function formatReactionOption({ label }: ReactionOption) {
    return <div>{label}</div>;
}

function EnumerationUI({ model }: { model: HTEEnumerationModel }) {
    const settings = useBehavior(model.state.settings);
    const [updateState, applyUpdate] = useAsyncAction();

    const reactionValue = model.reactionOptions.find((r) => r.value?.id === settings.reaction_chemistry_id);

    return (
        <div className='hte-enumeration-settings-form'>
            <div className='position-relative w-100 overflow-hidden'>
                <Select
                    options={model.reactionOptions}
                    value={reactionValue}
                    isSearchable
                    formatOptionLabel={formatReactionOption}
                    filterOption={createFilter({ ignoreAccents: false, stringify: (o) => o.label })}
                    menuPortalTarget={document.body}
                    menuPlacement='top'
                    placeholder='Select reaction chemistry...'
                    classNames={CustomSelectClassNames}
                    styles={DefaultSelectStyles}
                    onChange={(o) => {
                        model.state.settings.next({ ...settings, reaction_chemistry_id: o?.value?.id ?? '' });
                        if (o?.value?.id) applyUpdate(model.syncReactionSites());
                    }}
                    isDisabled={updateState.isLoading}
                />
                {updateState.isLoading && (
                    <Spinner
                        animation='border'
                        size='sm'
                        className='ms-2'
                        role='status'
                        style={{ position: 'absolute', top: 11, right: 32 }}
                    />
                )}
            </div>
        </div>
    );
}

function EnumerateButton({ model }: { model: HTEEnumerationModel }) {
    const settings = useBehavior(model.state.settings);
    const [state, apply] = useAsyncAction();
    const disabled = !settings.reaction_chemistry_id;

    return (
        <Button
            onClick={() => apply(model.applyEnumerate())}
            disabled={disabled || state.isLoading}
            size='sm'
            variant='primary'
        >
            Enumerate
            {state.isLoading && <Spinner animation='border' size='sm' className='me-2' role='status' />}
            {!state.isLoading && <FontAwesomeIcon size='sm' className='ms-2' icon={faArrowRight} />}
        </Button>
    );
}

function formatGroupName(name: string) {
    if (!name) return '';
    const fields = name.split('_');
    if (fields[0] === 'has') fields.shift();
    return fields.map((e) => `${e[0]?.toUpperCase() ?? ''}${e.substring(1)}`).join(' ');
}

function ProductFilterUI({ model }: { model: HTEEnumerationModel }) {
    const [groupName, setGroupName] = useState(model.filterInfo.filter_groups[0].name);
    const groupOptions = useMemo(
        () => [
            ...model.filterInfo.filter_groups.map(
                (g) => [g.name, `${g.name[0].toUpperCase()}${g.name.substring(1)}`] as [string, string]
            ),
            ['<custom>', 'Custom'] as [string, string],
        ],
        [model]
    );
    const settings = useBehavior(model.state.settings);

    const currentGroup = model.filterInfo.filter_groups.find((g) => g.name === groupName)?.filter_names;

    return (
        <div className='position-absolute' style={{ top: 0, left: 0, right: 0, bottom: 0 }}>
            <div className='d-inline-flex flex-column w-50 border-end border-1 pe-2 align-top h-100'>
                <div className='flex-grow-0'>
                    <b>Available</b>
                </div>
                <div className='flex-grow-0'>
                    <SimpleSelectOptionInput
                        options={groupOptions}
                        value={groupName}
                        setValue={(v) => setGroupName(v)}
                    />
                </div>
                <div className='flex-grow-1 position-relative mt-1'>
                    <div className='position-absolute' style={{ inset: 0, overflow: 'hidden', overflowY: 'auto' }}>
                        {currentGroup?.map((filterName) => {
                            if (settings.filters[filterName]) return null;
                            return (
                                <div key={filterName} className='py-1 border-bottom'>
                                    <Button
                                        onClick={() =>
                                            model.state.settings.next({
                                                ...settings,
                                                filters: { ...settings.filters, [filterName]: { name: filterName } },
                                            })
                                        }
                                        size='sm'
                                        variant='link'
                                    >
                                        <FontAwesomeIcon size='sm' className='text-success' icon={faPlus} />
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            model.state.settings.next({
                                                ...settings,
                                                filters: {
                                                    ...settings.filters,
                                                    [filterName]: { name: filterName, exclude: true },
                                                },
                                            })
                                        }
                                        size='sm'
                                        variant='link'
                                        className='me-2'
                                    >
                                        <FontAwesomeIcon size='sm' className='text-warning' icon={faExclamation} />
                                    </Button>
                                    {formatGroupName(filterName)}
                                </div>
                            );
                        })}
                        {groupName === '<custom>' && <ProductFilterCustom model={model} />}
                    </div>
                </div>
            </div>
            <div className='d-inline-flex flex-column w-50 ps-2 align-top h-100'>
                <div className='flex-grow-0'>
                    <b>Selected</b>
                </div>
                <div className='flex-grow-1 position-relative mt-1'>
                    <div className='position-absolute' style={{ inset: 0, overflow: 'hidden', overflowY: 'auto' }}>
                        {Object.entries(settings.filters).map(([filterName, props]) => (
                            <div key={filterName} className='py-1 border-bottom'>
                                <Button
                                    onClick={() => {
                                        const newFilters = { ...settings.filters };
                                        delete newFilters[filterName];
                                        model.state.settings.next({ ...settings, filters: newFilters });
                                    }}
                                    size='sm'
                                    variant='link'
                                    className='me-2'
                                >
                                    <FontAwesomeIcon size='sm' className='text-danger' icon={faRemove} />
                                </Button>
                                {props.exclude ? (
                                    <span className='text-warning'>No</span>
                                ) : (
                                    <span className='text-success'>Has</span>
                                )}{' '}
                                {formatGroupName(filterName)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

function ProductFilterCustom({ model }: { model: HTEEnumerationModel }) {
    const [value, setValue] = useState('');
    const [isSmarts, setIsSmarts] = useState(false);
    const settings = useBehavior(model.state.settings);

    return (
        <div>
            <ButtonGroup className='w-100 mb-2 mt-1' size='sm'>
                <Button variant={!isSmarts ? 'primary' : 'outline-primary'} onClick={() => setIsSmarts(false)}>
                    SMILES
                </Button>
                <Button variant={isSmarts ? 'primary' : 'outline-primary'} onClick={() => setIsSmarts(true)}>
                    SMARTS
                </Button>
            </ButtonGroup>
            <div className='position-relative'>
                <TextInput value={value} setValue={(v) => setValue(v.trim())} autoFocus />
                {!isSmarts && (
                    <Button
                        className='position-absolute px-1 py-0 font-body-small'
                        style={{ right: '4px', top: '8px' }}
                        variant='link'
                        onClick={() =>
                            ChemDrawModalService.show({
                                onSMILESDrawn: (smilesStr: string) => setValue(smilesStr),
                            })
                        }
                    >
                        <FontAwesomeIcon
                            className='pe-none'
                            icon={iiChemDrawLogo}
                            color='var(--bs-primary)'
                            fixedWidth
                        />
                    </Button>
                )}
            </div>
            <div className='d-flex justify-content-end'>
                <Button
                    onClick={() =>
                        model.state.settings.next({
                            ...settings,
                            filters: { ...settings.filters, [value]: { [isSmarts ? 'smarts' : 'smiles']: value } },
                        })
                    }
                    variant='link'
                >
                    <FontAwesomeIcon className='text-success' icon={faPlus} />
                </Button>
                <Button
                    onClick={() =>
                        model.state.settings.next({
                            ...settings,
                            filters: {
                                ...settings.filters,
                                [value]: { [isSmarts ? 'smarts' : 'smiles']: value, exclude: true },
                            },
                        })
                    }
                    variant='link'
                    className='me-2'
                >
                    <FontAwesomeIcon className='text-warning' icon={faExclamation} />
                </Button>
            </div>
        </div>
    );
}

function FilterButtons({ model }: { model: HTEEnumerationModel }) {
    const settings = useBehavior(model.state.settings);
    const [state, apply] = useAsyncAction();

    return (
        <div className='hstack'>
            <Button onClick={() => apply(model.applyFilter())} disabled={state.isLoading} size='sm' variant='link'>
                {state.isLoading && <Spinner animation='border' size='sm' className='me-2' role='status' />}
                {!state.isLoading && <FontAwesomeIcon size='sm' className='text-primary me-2' icon={faFilter} />}
                Apply
            </Button>
            <Button size='sm' variant='link' onClick={() => model.state.settings.next({ ...settings, filters: {} })}>
                <FontAwesomeIcon size='sm' className='text-secondary' icon={faTrash} />
            </Button>
        </div>
    );
}

// function LogUI({ model }: { model: HTEEnumerationModel }) {
//     return <div>
//         TODO
//     </div>
// }

function ReactionsWrapper({ model }: { model: HTEEnumerationModel }) {
    const table = model.reactionsTable;
    useBehavior(table.version);

    return <DataTableControl height='flex' table={table} headerSize='sm' />;
}

function ReactionsTitle({ model }: { model: HTEEnumerationModel }) {
    const stats = useBehavior(model.state.reactionStats);
    return (
        <div>
            Reactions
            <span className='ms-2 text-secondary' style={{ fontSize: '0.75rem' }}>
                {stats?.unique ?? 0} unique, {stats?.none ?? 0} without product, {stats?.nonUnique ?? 0} non-unique,{' '}
                {stats?.error ?? 0} error
            </span>
        </div>
    );
}

function ReactionsButtons({ model }: { model: HTEEnumerationModel }) {
    return (
        <div className='hstack'>
            <Button onClick={() => model.exportCSV()} size='sm' variant='link'>
                Export CSV
            </Button>
        </div>
    );
}
