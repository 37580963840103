import React, { ReactNode } from 'react';
import { type ModalProps } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';

interface CommonDialogProps {
    title: React.ReactNode;
    text?: string | ReactNode;
}

export interface CopyTextDialogProps extends CommonDialogProps {
    type: 'copy';
    toCopy: string;
    confirmText?: string;
}

export interface ConfirmDialogProps extends CommonDialogProps {
    type: 'confirm';
    confirmText?: string;
    onConfirm: () => void;
    modalOptions?: ModalProps;
}

export interface GenericDialogProps<T = any, S = any> extends CommonDialogProps {
    type: 'generic';
    confirmButtonContent?: ReactNode;
    onClose?: (state: S) => void;
    onOk?: (state: S, stateSubject: BehaviorSubject<S>) => any;
    doNotAutoClose?: boolean;
    // Wraps onOk in async action and displays loading indicator and errors
    wrapOk?: boolean;
    defaultState?: S;
    options?: {
        size?: 'xl' | 'sm' | 'lg';
        contentClassName?: string;
        hideFooter?: boolean;
        staticBackdrop?: boolean;
        showCancelButton?: boolean;
        okOnEnterKeyPress?: boolean;
    };
    modalOptions?: ModalProps;
    model?: T;
    // overrides CommonDialogProps.text
    content?: React.FC<{ model: T; stateSubject: BehaviorSubject<S>; close: () => void; ok: () => any }>;
    // overrides title
    header?: React.FC<{ model: T; stateSubject: BehaviorSubject<S>; close: () => void; ok: () => any }>;
    // overrides ok button
    footer?: React.FC<{ model: T; stateSubject: BehaviorSubject<S>; close: () => void; ok: () => any }>;
}

export type DialogProps = CopyTextDialogProps | ConfirmDialogProps | GenericDialogProps;

class _DialogService {
    state = new BehaviorSubject<DialogProps | null>(null);

    open(options: DialogProps) {
        if (options === this.state.value) return;
        this.state.next(options);
    }

    close() {
        this.state.next(null);
    }
}

export const DialogService = new _DialogService();
