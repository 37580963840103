import { faCheck, faInfoCircle, faQuestion, faSignature, faVial } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { useLocation, useParams } from 'react-router-dom';
import { AsyncMoleculeDrawing } from '../../../components/common/AsyncMoleculeDrawing';
import { ErrorMessage } from '../../../components/common/Error';
import { LabeledInput, SimpleSelectOptionInput, TextInput } from '../../../components/common/Inputs';
import Loading from '../../../components/common/Loading';
import { ScrollBox } from '../../../components/common/ScrollBox';
import { TooltipWrapper } from '../../../components/common/Tooltips';
import { DataTableControl, DataTableModel } from '../../../components/DataTable';
import { DownloadArtifactsCell } from '../../../components/DataTable/common';
import { PageTemplate } from '../../../components/Layout/Layout';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import { AuthService } from '../../../lib/services/auth';
import { AssayValueView } from '../../../lib/assays/display';
import { formatDatetime, parseFileSystemDate } from '../../../lib/util/dates';
import { objectIsEmpty } from '../../../lib/util/misc';
import { roundValue, roundValueDigits } from '../../../lib/util/roundValues';
import { trimValue } from '../../../lib/util/validators';
import { formatSampleContentInline } from '../../ECM/ecm-api';
import { ECMCurrentUser, ECMPlateWellContents } from '../../ECM/ecm-common';
import {
    EXPERIMENT_ACKNOWLEDGEMENT,
    formatHTEId,
    HTEAmendment,
    HTESignature,
    WellLayerType,
    WellLayouts,
} from '../experiment-data';
import { PlateVisual, PlateVisualModel } from '../plate/PlateVisual';
import { getFirstSelectedIndex, getWellIndexLabel } from '../plate/utils';
import { ReactantTypeBadge } from '../steps/common';
import { CompoundIdentifier } from '../steps/reagents-model';
import { HTEHistoryTable } from './history';
import { HTESnapshotAssayValue, HTESnapshotPlate, HTESnapshotReactant, HTESnapshotReaction } from './sign-data';
import { HTESignModel, HTESignState, HTESignStep } from './sign-model';

async function loadModel(id: number) {
    const model = new HTESignModel(id);
    await model.init();
    return model;
}

export function HTESignUI() {
    const { id } = useParams();
    const location = useLocation();
    const [_model, applyModel] = useAsyncAction<HTESignModel>();

    useEffect(() => {
        if (id && !Number.isNaN(+id)) applyModel(loadModel(+id));
    }, [id]);

    const model = _model.result;

    return (
        <PageTemplate
            title='HTE Sign'
            breadcrumb={{
                title: id ? `${formatHTEId(+id)}` : '',
                href: location.pathname,
            }}
        >
            <div className='d-flex h-100 w-100'>
                {_model.isLoading && (
                    <div className='w-100'>
                        <Loading />
                    </div>
                )}
                {_model.error && <ErrorMessage header='Experiment Load Error' message={_model.error} />}
                {model && <Steps model={model} />}
            </div>
        </PageTemplate>
    );
}

function StepButton({
    model,
    label,
    step,
    state,
}: {
    model: HTESignModel;
    label: string;
    step: HTESignStep;
    state: HTESignState;
}) {
    return (
        <Button
            onClick={() => state.step !== step && model.state.current.next({ ...state, step })}
            variant={state.step === step ? 'primary' : 'secondary'}
            size='sm'
        >
            {isReviewStep(step) && (
                <FontAwesomeIcon
                    icon={state.reviewed[step] ? faCheck : faQuestion}
                    className={`me-1 ${state.reviewed[step] ? '' : 'text-warning'}`}
                    fixedWidth
                />
            )}
            {step === 'sign' && <FontAwesomeIcon icon={faSignature} className='me-1' fixedWidth />}
            {label}
        </Button>
    );
}

function Steps({ model }: { model: HTESignModel }) {
    const state = useBehavior(model.state.current);
    const { step } = state;

    return (
        <>
            <div className='hte-sign-step-page'>
                {step === 'history' && <HistoryStep model={model} />}
                {step === 'settings' && <SettingsStep model={model} />}
                {step === 'procedure' && <ProcedureStep model={model} />}
                {step === 'observations' && <ObservationsStep model={model} />}
                {step === 'plates' && <PlatesStep model={model} />}
                {step === 'assays' && <AssaysStep model={model} />}
                {step === 'finalization' && <FinalizationStep model={model} />}
                {step === 'amendments' && <AmendmentsStep model={model} />}
                {step === 'sign' && <SignStep model={model} />}
            </div>

            {isReviewStep(step) && <StepReviewed model={model} step={step} />}

            <div className='hte-experiment-nav'>
                <div className='entos-pill-nav-buttons'>
                    <StepButton model={model} step='history' label='History' state={state} />
                    <StepButton model={model} step='settings' label='Settings' state={state} />
                    <StepButton model={model} step='procedure' label='Procedure' state={state} />
                    <StepButton model={model} step='observations' label='Observations' state={state} />
                    <StepButton model={model} step='plates' label='Plates' state={state} />
                    <StepButton model={model} step='assays' label='Assays' state={state} />
                    <StepButton model={model} step='finalization' label='Finalization' state={state} />
                    <StepButton model={model} step='amendments' label='Amendments' state={state} />
                    <StepButton model={model} step='sign' label='Sign' state={state} />
                </div>
            </div>
        </>
    );
}

function isReviewStep(step: HTESignStep) {
    return step !== 'history' && step !== 'sign';
}

function StepReviewed({ model, step }: { model: HTESignModel; step: HTESignStep }) {
    const state = useBehavior(model.state.current);

    return (
        <div
            className={`hte-sign-step-reviewed p-2 rounded border border-${
                state.reviewed[step] ? 'success' : 'warning'
            }`}
        >
            <Form.Check
                id='hte-step-reviewed'
                label='I have reviewed this step'
                checked={!!state.reviewed[step]}
                onChange={(e) =>
                    model.state.current.next({ ...state, reviewed: { ...state.reviewed, [step]: !!e.target.checked } })
                }
            />
        </div>
    );
}

function ReviewHeading({ title }: { title: string }) {
    return (
        <div className='insight-page-header p-4 m-auto mt-4 flex-grow-0'>
            <h2 className='m-0'>{title}</h2>
        </div>
    );
}

function HistoryStep({ model }: { model: HTESignModel }) {
    const review = useBehavior(model.state.review);

    return (
        <>
            <ReviewHeading title={`Review & Sign ${formatHTEId(model.id)}`} />
            <div style={{ width: 800 }} className='mt-2 m-auto'>
                {(!review?.signature || !review?.counter_signature) && (
                    <div className='fw-bold text-warning text-center'>
                        Carefully review the data of this experiment before (counter-)signing.
                    </div>
                )}
                {!!review?.signature && !!review?.counter_signature && (
                    <div className='fw-bold text-success text-center'>
                        Latest version has been reviewed and no further action is currently required.
                    </div>
                )}
                <div className='mt-1'>
                    {!!review?.signature && (
                        <>
                            <FontAwesomeIcon fixedWidth className='text-success' icon={faCheck} /> Latest version signed
                            by <b>{review.signature.full_name}</b> on{' '}
                            {formatDatetime(review.signature.signed_on, 'full')}
                        </>
                    )}
                    {!review?.signature && (
                        <>
                            <FontAwesomeIcon fixedWidth className='text-warning' icon={faSignature} /> Signature
                            required
                        </>
                    )}
                </div>
                <div>
                    {!!review?.counter_signature && (
                        <>
                            <FontAwesomeIcon fixedWidth className='text-success' icon={faCheck} /> Latest version
                            counter-signed by <b>{review.counter_signature.full_name}</b> on{' '}
                            {formatDatetime(review.counter_signature.signed_on, 'full')}
                        </>
                    )}
                    {!review?.counter_signature && (
                        <>
                            <FontAwesomeIcon fixedWidth className='text-warning' icon={faSignature} /> Counter-signature
                            required
                        </>
                    )}
                </div>
            </div>
            <h4 className='ms-4 mt-2'>Change History</h4>
            <div className='position-relative px-4 flex-grow-1 mt-2 mb-2'>
                <div className='h-100 position-relative'>
                    <HTEHistoryTable table={model.historyTable} />
                </div>
            </div>
        </>
    );
}

function ContentWrapper({
    children,
    style,
    className,
}: {
    children: ReactNode;
    style?: CSSProperties;
    className?: string;
}) {
    return (
        <div className='hte-sign-step-content' style={style}>
            <div className={`hte-sign-step-content-inner ${className ?? ''}`}>{children}</div>
        </div>
    );
}

function SettingsStep({ model }: { model: HTESignModel }) {
    return (
        <>
            <ReviewHeading title='Settings & Details' />
            <ContentWrapper>
                <SettingsValues model={model} />
            </ContentWrapper>
        </>
    );
}

function SettingsValues({ model }: { model: HTESignModel }) {
    const { settings, details } = model.data;

    return (
        <div className='vstack gap-2'>
            <div className='hte-experiment-form vstack gap-2'>
                <h5>Plate Details</h5>
                <LabeledInput label='Library Identifier'>{formatHTEId(model.id)}</LabeledInput>
                <LabeledInput label='Project'>{details.project}</LabeledInput>
                <LabeledInput label='Library Name'>
                    <TextInput value={details.library_name} readOnly />
                </LabeledInput>
                <LabeledInput label='Description' className='multiline-row'>
                    <TextInput value={details.description} textarea readOnly />
                </LabeledInput>
                <LabeledInput label='Well Layout'>
                    {WellLayouts.find((l) => l[0] === details.well_layout)?.[1]}
                </LabeledInput>
                <LabeledInput label='Labware'>
                    <TextInput value={details.labware} readOnly />
                </LabeledInput>
                {/* <LabeledInput label='Well Volume'>
                {details.well_volume}
            </LabeledInput> */}
            </div>

            <div className='hte-experiment-form vstack gap-2'>
                <h5>Experiment Details</h5>
                <LabeledInput label={`Stir Rate (${settings.stir_rate_unit})`}>
                    <TextInput value={settings.stir_rate} readOnly />
                </LabeledInput>
                {settings.temperature_unit.toLowerCase() === 'kelvin' && (
                    <LabeledInput label='Temperature (C)'>
                        <TextInput value={roundValue(3, settings.temperature - 273.15)} readOnly />
                    </LabeledInput>
                )}
                {settings.temperature_unit.toLowerCase() !== 'kelvin' && (
                    <LabeledInput label={`Temperature (${settings.temperature_unit})`}>
                        <TextInput value={roundValue(3, settings.temperature)} readOnly />
                    </LabeledInput>
                )}
                {settings.duration_unit.toLowerCase() === 'second' && (
                    <LabeledInput label='Duration (h)'>
                        <TextInput value={roundValue(2, settings.duration / 3600)} readOnly />
                    </LabeledInput>
                )}
                {settings.duration_unit.toLowerCase() !== 'second' && (
                    <LabeledInput label={`Duration (${settings.duration_unit})`}>
                        <TextInput value={roundValue(2, settings.duration)} readOnly />
                    </LabeledInput>
                )}
                <LabeledInput label='Atmosphere'>
                    <TextInput value={settings.atmosphere} readOnly />
                </LabeledInput>
                <LabeledInput label={`Nominal Volume (${settings.nominal_volume_unit})`}>
                    <TextInput value={roundValueDigits(3, settings.nominal_volume)} readOnly />
                </LabeledInput>
                <LabeledInput label={`Reaction Scale (${settings.reaction_scale_unit})`}>
                    <TextInput
                        value={
                            typeof settings.reaction_scale === 'number'
                                ? roundValueDigits(3, settings.reaction_scale)
                                : '?'
                        }
                        readOnly
                    />
                </LabeledInput>
                <LabeledInput label='Reaction Chemistry'>
                    <TextInput value={model.chemistry?.name ?? settings.reaction_chemistry} readOnly />
                </LabeledInput>
            </div>
        </div>
    );
}

function ProcedureStep({ model }: { model: HTESignModel }) {
    return (
        <>
            <ReviewHeading title='Procedure' />
            <ContentWrapper>
                {!model.data.procedure && <i>No procedure entered.</i>}
                {model.data.procedure && (
                    <div className='hte-details-markdown-wrapper'>
                        <ReactMarkdown>{model.data.procedure}</ReactMarkdown>
                    </div>
                )}
            </ContentWrapper>
        </>
    );
}

function ObservationsStep({ model }: { model: HTESignModel }) {
    return (
        <>
            <ReviewHeading title='Observations' />
            <ContentWrapper>
                {!model.data.observations && <i>No observations entered.</i>}
                {model.data.observations && (
                    <div className='hte-details-markdown-wrapper'>
                        <ReactMarkdown>{model.data.observations}</ReactMarkdown>
                    </div>
                )}
            </ContentWrapper>
        </>
    );
}

function PlatesStep({ model }: { model: HTESignModel }) {
    return (
        <>
            <ReviewHeading title='Plates' />
            <ContentWrapper>
                <h4>Crude Plate {model.data.plates.crude?.plate?.barcode ?? ''}</h4>
                {model.crudePlate && (
                    <PlateDetails model={model} plate={model.data.plates.crude!.plate!} visual={model.crudePlate} />
                )}
                {!model.crudePlate && <span className='text-secondary'>No crude plate</span>}

                <h4 className='mt-4'>Purified Plate {model.data.plates.purified?.plate?.barcode ?? ''}</h4>
                {model.purifiedPlate && (
                    <PlateDetails
                        model={model}
                        plate={model.data.plates.purified!.plate!}
                        visual={model.purifiedPlate}
                    />
                )}
                {!model.purifiedPlate && <span className='text-secondary'>No purified plate</span>}
            </ContentWrapper>
        </>
    );
}

function PlateDetails({
    model,
    plate,
    visual,
}: {
    model: HTESignModel;
    plate: HTESnapshotPlate;
    visual: PlateVisualModel;
}) {
    return (
        <div style={{ height: 300 }}>
            <div className='position-relative w-50 float-start h-100'>
                <PlateVisual model={visual} />
            </div>
            <div className='position-relative w-50 float-start h-100'>
                <PlateInfo model={model} plate={plate} visual={visual} />
            </div>
        </div>
    );
}

function PlateInfo({
    model,
    plate,
    visual,
}: {
    model: HTESignModel;
    plate: HTESnapshotPlate;
    visual: PlateVisualModel;
}) {
    const selection = useBehavior(visual.state.selection);
    const wI = getFirstSelectedIndex(selection);
    const label = getWellIndexLabel(plate.size, wI);
    const sample = plate.samples[wI];

    let inner;

    if (wI < 0) {
        inner = 'Nothing selected';
    } else if (!sample) {
        inner = (
            <>
                <b>({label})</b> Empty well
            </>
        );
    }

    if (inner) {
        return (
            <div className='position-absolute overflow-hidden ps-2' style={{ inset: 0 }}>
                {inner}
            </div>
        );
    }

    let smiles;
    let smilesError;
    try {
        smiles = model.getSMILES(sample!.batch_id);
    } catch (e) {
        smilesError = `${e}`;
    }

    const batch = model.assets.batches[sample?.batch_id!];
    const reaction = model.assets.reactions[sample?.batch_id!];

    return (
        <div className='ps-2 d-flex flex-column h-100 font-body-small'>
            <div className='flex-grow-0'>
                <b>({label})</b> <CompoundIdentifier value={batch.identifier ?? ''} />
                <br />
                <span className='font-body-xsmall'>
                    <ECMPlateWellContents sample={sample as any} noBrackets />
                </span>
            </div>
            <div className='flex-grow-1 position-relative'>
                <ScrollBox>
                    <div className='flex-grow-0 position-relative' style={{ height: 120 }}>
                        {smiles && (
                            <AsyncMoleculeDrawing
                                drawer={model.drawer}
                                smiles={smiles}
                                height='100%'
                                width='100%'
                                showChemDraw={false}
                                showCopy={false}
                            />
                        )}
                        {smilesError && (
                            <div className='d-flex align-items-center justify-content-center w-100 h-100'>
                                {smilesError}
                            </div>
                        )}
                    </div>
                    {reaction && <ReactionInfo model={model} reaction={reaction} />}
                    <div className='mt-2 mb-1 fw-bold'>Assay Values</div>
                    <AssayValues values={model.assets.assayValues[sample?.batch_id!]} />
                </ScrollBox>
            </div>
        </div>
    );
}

function AssayValues({ values }: { values?: Record<string, HTESnapshotAssayValue[]> }) {
    if (!values || objectIsEmpty(values)) {
        return <span className='text-secondary font-body-xsmall'>No values found</span>;
    }

    const all = Array.from(Object.values(values)).flatMap((xs) => xs);
    return (
        <div className='vstack'>
            {all.map((v, i) => (
                <div key={i} className='d-flex align-items-center'>
                    <div className='me-2 d-inline-block' style={{ minWidth: 50 }}>
                        {v.assay_type}
                    </div>{' '}
                    <AssayValueView value={v.value} /> <DownloadArtifactsCell artifacts={v.pdf_attachments} size='sm' />
                </div>
            ))}
        </div>
    );
}

function ReactionInfo({ model, reaction }: { model: HTESignModel; reaction: HTESnapshotReaction }) {
    const renderType = (
        type: WellLayerType,
        width: 'w-50' | 'w-100',
        _samples: HTESnapshotReactant | undefined | null | HTESnapshotReactant[]
    ) => {
        if (type === 'unknown') return null;

        const samples = Array.isArray(_samples) ? _samples : _samples ? [_samples] : [];
        return (
            <div className={`hstack gap-2 ${width ?? ''}`} key={type}>
                <ReactantTypeBadge type={type as any} style={{ width: 50, minWidth: 50 }} />
                {!samples.length && <span className='text-secondary'>-</span>}
                {samples.length === 1 && (
                    <>
                        <CompoundIdentifier value={model.assets.batches[samples[0].batch_id]?.identifier} />
                        <TooltipWrapper tooltip={formatSampleContentInline(samples[0])}>
                            {(props) => <FontAwesomeIcon size='sm' icon={faVial} {...props} />}
                        </TooltipWrapper>
                    </>
                )}
                {samples.length! > 1 && (
                    <>
                        {samples.map((sample, i) => (
                            <React.Fragment key={i}>
                                <CompoundIdentifier value={model.assets.batches[sample.batch_id]?.identifier} key={i} />
                                <TooltipWrapper tooltip={formatSampleContentInline(sample)}>
                                    {(props) => <FontAwesomeIcon size='sm' icon={faVial} {...props} />}
                                </TooltipWrapper>
                            </React.Fragment>
                        ))}
                    </>
                )}
            </div>
        );
    };

    return (
        <div className='vstack gap-1 hte-experiment-product-plate-step-well-summary font-body-xsmall'>
            <div className='hstack'>
                {renderType('msd', 'w-50', reaction?.msd_sample)}
                {renderType('bb', 'w-50', reaction?.bb_sample)}
            </div>
            <div className='hstack gap-2'>
                <ReactantTypeBadge type='reagent' style={{ width: 50, minWidth: 50 }} />
                <div>
                    {reaction.reactants.map((sample, i) => (
                        <div key={i} className='d-inline-block me-2'>
                            <CompoundIdentifier value={model.assets.batches[sample.batch_id]?.identifier} key={i} />
                            <TooltipWrapper tooltip={formatSampleContentInline(sample)}>
                                {(props) => <FontAwesomeIcon size='sm' icon={faVial} {...props} className='ms-1' />}
                            </TooltipWrapper>
                        </div>
                    ))}
                    {reaction.reactants.length === 0 && <span className='text-secondary'>-</span>}
                </div>
            </div>
        </div>
    );
}

function AssaysStep({ model }: { model: HTESignModel }) {
    const tableKind = useBehavior(model.state.table);
    return (
        <>
            <ReviewHeading title='Assays' />
            <ContentWrapper className='h-100'>
                <div className='d-flex h-100 flex-column'>
                    <SimpleSelectOptionInput
                        className='mb-2'
                        value={tableKind}
                        options={[
                            ['crude', 'Crude Plate'],
                            ['purified', 'Purified Plate'],
                        ]}
                        setValue={(v) => model.state.table.next(v)}
                    />
                    <div className='flex-grow-1 position-relative'>
                        <AssaysTable table={tableKind === 'crude' ? model.crudeTable : model.purifiedTable} />
                    </div>
                </div>
            </ContentWrapper>
        </>
    );
}

function AssaysTable({ table }: { table?: DataTableModel }) {
    useBehavior(table?.version);
    if (!table) {
        return <span className='text-secondary'>No data available</span>;
    }
    return <DataTableControl height='flex' table={table} />;
}

function FinalizationStep({ model }: { model: HTESignModel }) {
    const { finalization } = model.data;
    const { experiment } = model;

    return (
        <>
            <ReviewHeading title='Finalization' />
            <ContentWrapper>
                {!finalization && <i>Experiment not finalized.</i>}
                {finalization && (
                    <div className='hte-experiment-form vstack gap-2 position-relative'>
                        <LabeledInput label='Finalized by'>
                            {finalization.full_name}{' '}
                            <span className='text-secondary'>({finalization.finalized_by})</span>
                        </LabeledInput>
                        <LabeledInput label='Comment'>
                            <span className='text-secondary'>{finalization.comment ?? ''}</span>
                        </LabeledInput>
                        <LabeledInput key='executed-on' label='Executed On'>
                            {experiment.executed_on
                                ? parseFileSystemDate(experiment.executed_on).toDateString()
                                : 'n/a'}
                        </LabeledInput>
                        <LabeledInput key='plate' label='Crude Plate Barcode'>
                            {model.data.plates.crude?.plate?.barcode ?? '-'}
                        </LabeledInput>
                        <LabeledInput label='Date'>
                            {parseFileSystemDate(finalization.finalized_on).toLocaleDateString()}
                        </LabeledInput>
                    </div>
                )}
            </ContentWrapper>
        </>
    );
}

function AmendmentsStep({ model }: { model: HTESignModel }) {
    const { amendments } = model.data;

    return (
        <>
            <ReviewHeading title='Amendments' />
            <ContentWrapper className='vstack gap-1'>
                {!amendments.length && <i>No amendments.</i>}
                {amendments.map((a) => (
                    <AmendmentUI key={a.id} amendment={a} />
                ))}
            </ContentWrapper>
        </>
    );
}

function AmendmentUI({ amendment }: { amendment: HTEAmendment }) {
    const artifacts = Object.fromEntries(amendment.artifacts.map((a) => [a.name, a]));
    return (
        <div className='pb-1 border-bottom'>
            <div>
                <b>{amendment.amended_by}</b> on {formatDatetime(amendment.amended_on, 'full')}
            </div>
            <div>
                <b>Comment:</b> {amendment.comment || <span className='text-secondary'>none</span>}
            </div>
            <div>
                <b>Artifacts:</b>{' '}
                {amendment.artifacts.length > 0 && <DownloadArtifactsCell artifacts={artifacts} size='lg' />}
                {amendment.artifacts.length === 0 && <span className='text-secondary'>none</span>}
            </div>
        </div>
    );
}

function SignStep({ model }: { model: HTESignModel }) {
    const username = useBehavior(AuthService.account)?.username.toLowerCase();
    const { signature, counter_signature } = useBehavior(model.state.review) ?? {};
    const { experiment } = model;

    const allReviewed = model.allStepsReviewed;
    const hasUserCreatedExperiment = username === experiment.created_by.toLowerCase();

    return (
        <>
            <ReviewHeading title='Sign' />
            <ContentWrapper>
                <div className='hte-experiment-form vstack gap-2'>
                    {!allReviewed && (
                        <Alert variant='dark'>
                            <FontAwesomeIcon icon={faInfoCircle} className='me-2' />
                            The experiment can only be (counter-)signed after all steps have been reviewed.
                        </Alert>
                    )}
                    {!signature && !hasUserCreatedExperiment && (
                        <Alert key='signature-warning' variant='dark'>
                            <FontAwesomeIcon icon={faInfoCircle} className='me-2' />
                            <span className='me-2'>
                                This experiment was created by {experiment.created_by} and only they can sign it
                            </span>
                            <ECMCurrentUser variant='outline-primary' title='Change user' />
                        </Alert>
                    )}
                    {!!signature && (
                        <>
                            <h5>Signature</h5>
                            <SignatureInfo signature={signature} />
                        </>
                    )}
                    {!signature && hasUserCreatedExperiment && allReviewed && (
                        <>
                            <h5>Sign the Experiment</h5>
                            <CreateSignature model={model} />
                        </>
                    )}
                    {counter_signature && (
                        <>
                            <h5>Counter Signature</h5>
                            <SignatureInfo signature={counter_signature} />
                        </>
                    )}
                    {signature && !counter_signature && allReviewed && (
                        <>
                            <h5>Counter Sign the Experiment</h5>
                            {hasUserCreatedExperiment && (
                                <Alert variant='dark'>
                                    <FontAwesomeIcon icon={faInfoCircle} className='me-2' />
                                    <span className='me-2'>
                                        Experiment must be counter signed by a different person
                                    </span>
                                    <ECMCurrentUser variant='outline-primary' title='Change user' />
                                </Alert>
                            )}
                            {!hasUserCreatedExperiment && <CreateSignature model={model} isCounter />}
                        </>
                    )}
                </div>
            </ContentWrapper>
        </>
    );
}

function SignatureInfo({ signature }: { signature: HTESignature }) {
    return (
        <div className='hte-experiment-form vstack gap-2 position-relative'>
            <LabeledInput label='Signed by'>
                {signature.full_name} <span className='text-secondary'>({signature.signed_by})</span>
            </LabeledInput>
            <LabeledInput label='Date'>{parseFileSystemDate(signature.signed_on).toLocaleDateString()}</LabeledInput>
            <LabeledInput label='Comment'>
                <span className='text-secondary'>{signature.comment || '«empty»'}</span>
            </LabeledInput>
        </div>
    );
}

interface SignatureData {
    full_name: string;
    comment: string;
    acknowledged_policy: boolean;
}

function validateSignatureData(data: SignatureData): { [K in keyof SignatureData]: string | undefined } {
    return {
        full_name:
            data.full_name.length > 0 && data.full_name.split(' ').length < 2
                ? 'Invalid Name'
                : data.full_name.length === 0
                ? 'Enter Name'
                : '',
        comment: undefined,
        acknowledged_policy: undefined,
    };
}

function CreateSignature({ model, isCounter }: { model: HTESignModel; isCounter?: boolean }) {
    const [data, setData] = useState<SignatureData>({ full_name: '', comment: '', acknowledged_policy: false });
    const [state, sign] = useAsyncAction();
    const account = useBehavior(AuthService.account);

    const validation = validateSignatureData(data);

    return (
        <>
            <LabeledInput label='Logged in User'>{account?.username}</LabeledInput>
            <LabeledInput label='Date'>{new Date().toLocaleDateString()}</LabeledInput>
            <LabeledInput label='Full Name'>
                <TextInput
                    value={data.full_name}
                    tryUpdateValue={trimValue}
                    placeholder={account?.name ?? 'Enter your full name to sign the experiment'}
                    setValue={(v) => setData({ ...data, full_name: v })}
                    validation={validation.full_name}
                    disabled={state.isLoading}
                />
            </LabeledInput>
            <LabeledInput label='Comment'>
                <TextInput
                    textarea
                    value={data.comment}
                    tryUpdateValue={trimValue}
                    placeholder='Enter any comments about the experiment'
                    setValue={(v) => setData({ ...data, comment: v })}
                    validation={validation.comment}
                    disabled={state.isLoading}
                />
            </LabeledInput>
            <LabeledInput label=''>
                <Form.Check
                    id={`hte-signature-${isCounter}`}
                    label={EXPERIMENT_ACKNOWLEDGEMENT}
                    type='checkbox'
                    checked={data.acknowledged_policy}
                    onChange={() => setData({ ...data, acknowledged_policy: !data.acknowledged_policy })}
                    disabled={state.isLoading}
                />
            </LabeledInput>
            <div className='d-flex justify-content-end'>
                <Button
                    disabled={
                        Object.values(validation).some((v) => !!v) || !data.acknowledged_policy || state.isLoading
                    }
                    onClick={() => sign(model.sign({ ...data, is_counter_signature: !!isCounter }))}
                >
                    {state.isLoading && <Spinner className='me-3' size='sm' animation='border' role='status' />}
                    {isCounter ? 'Counter-sign Experiment' : 'Sign Experiment'}
                </Button>
            </div>
        </>
    );
}
