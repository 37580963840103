/* eslint-disable react/function-component-definition */

import { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useThrottledValue } from '../../lib/hooks/useThrottledValue';
import { arrayMinMax } from '../../lib/util/misc';
import { ColumnFilterType, ColumnFilterControl } from './column';
import { type DataTableModel } from './model';

export const DataTableValueColumnFilter: ColumnFilterControl = ({ table, columnName }) => {
    const [value, subject] = useThrottledValue(
        (v) => table.setFilter(columnName, v || undefined),
        table.getFilterValue(columnName) || '',
        [table, columnName],
        350
    );

    return (
        <Form.Control
            value={value ?? ''}
            onChange={(e) => subject.next(e.target.value || undefined)}
            placeholder={`Search ${table.preGlobalFilterRowCount} records...`}
        />
    );
};

export const DataTableBetweenColumnFilter: ColumnFilterControl = ({ table, columnName }) => {
    const [min, max] = useMemo(() => {
        const col = table.store.getColumnValues(columnName);
        const filtered = col.filter((v) => typeof v === 'number' || typeof v === 'boolean');
        return arrayMinMax(filtered);
    }, [table, table.store.version, columnName]);

    return (
        <BaseBetweenColumnFilter
            table={table}
            columnName={columnName}
            min={`${min.toFixed(2)}`}
            max={`${max.toFixed(2)}`}
        />
    );
};

export const BaseBetweenColumnFilter = ({
    table,
    columnName,
    min,
    max,
}: {
    table: DataTableModel;
    columnName: string;
    min: string;
    max: string;
}) => {
    const [value, subject] = useThrottledValue(
        (v) => table.setFilter(columnName, v),
        table.getFilterValue(columnName) ?? [undefined, undefined],
        [table, columnName],
        350
    );

    return (
        <div className='d-flex align-items-center'>
            <Form.Control
                value={value[0] ?? ''}
                type='number'
                step='0.01'
                onChange={(e) => subject.next([e.target.value ? +e.target.value : undefined, value[1]])}
                placeholder={min}
                className='table-control-column-number-filter me-1'
            />
            to
            <Form.Control
                value={value[1] ?? ''}
                type='number'
                step='0.01'
                onChange={(e) => subject.next([value[0], e.target.value ? +e.target.value : undefined])}
                placeholder={max}
                className='table-control-column-number-filter ms-1'
            />
        </div>
    );
};

export const DefaultDataTableFilterControls: Record<ColumnFilterType, ColumnFilterControl> = {
    includes: DataTableValueColumnFilter,
    between: DataTableBetweenColumnFilter,
    boolean: DataTableValueColumnFilter,
};
