import React, { useEffect, useMemo } from 'react';
import { Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/common/Loading';
import {
    Column,
    ColumnSorting,
    ColumnsFor,
    DataTableControl,
    DataTableGlobalFilter,
    DataTableModel,
    DataTableStore,
    tableRowNavigation,
} from '../../components/DataTable';
import { PageTemplate } from '../../components/Layout/Layout';
import useBehavior from '../../lib/hooks/useBehavior';
import { AssayProperty } from '../../lib/models/biology';
import { EcosystemService } from '../../lib/services/ecosystem';
import { formatDateAsYYYY_MM_DD } from '../../lib/util/dates';
import { BoxIdentifier, BoxInfo, BoxStatus, BoxVersion, boxVersionCompare, boxVersionToString } from './box-api';

const { compareGeneric } = ColumnSorting;

const BoxTableSchema: ColumnsFor<BoxInfo> = {
    id: Column.create({
        kind: 'int',
        noHeaderTooltip: true,
        width: 80,
    }),
    kind: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        width: 175,
    }),
    shorthand: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        filterType: false,
        width: 300,
    }),
    name: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        width: 250,
    }),
    version: Column.create({
        kind: 'obj',
        noHeaderTooltip: true,
        width: 80,
        format: (value: BoxVersion) => boxVersionToString(value),
        compare: ColumnSorting.comparerWithBlanks(boxVersionCompare),
        render: ({ value }: { value: BoxVersion }) => boxVersionToString(value),
        disableGlobalFilter: true,
    }),
    created_on: Column.create({
        ...Column.datetime({ format: 'date' }),
        noHeaderTooltip: true,
        render: ({ value }) => formatDateAsYYYY_MM_DD(value),
    }),
    modified_on: Column.create({
        ...Column.datetime({ format: 'date' }),
        noHeaderTooltip: true,
        render: ({ value }) => (value ? formatDateAsYYYY_MM_DD(value) : ''),
    }),
    status: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        width: 80,
        render: ({ value }: { value: BoxStatus }) => {
            if (value === 'available') return <Badge bg='success'>{value}</Badge>;
            if (value === 'unavailable') return <Badge bg='danger'>{value}</Badge>;
            if (value === 'registering') return <Badge bg='warning'>{value}</Badge>;
            if (value === 'retired') return <Badge bg='secondary'>{value}</Badge>;
            return value;
        },
    }),
    property: Column.create({
        kind: 'obj',
        noHeaderTooltip: true,
        width: 90,
        format: (value: AssayProperty | undefined) => (value ? `${value.id}` : '-'),
        compare: ColumnSorting.comparerWithBlanks((a?: AssayProperty, b?: AssayProperty) =>
            compareGeneric(a?.id, b?.id)
        ),
        render: ({ value }: { value: AssayProperty | undefined }) => (value ? value.id : '-'),
        disableGlobalFilter: true,
    }),
};

function createTable(boxes: DataTableStore<BoxInfo>) {
    const table = new DataTableModel(boxes, {
        columns: BoxTableSchema,
        hideNonSchemaColumns: true,
    });
    table.sortBy('modified_on', true);

    return table;
}

function BoxCard({ count, text, countClassName }: { count: number; text: string; countClassName?: string }) {
    return (
        <div className='entos-box-statistic-card w-25 rounded text-center ps-3 py-2 me-2'>
            <h5 className={`fw-bold ${countClassName}`}>{count}</h5>
            <span className='font-body-small'>{text}</span>
        </div>
    );
}

function BoxStatistics({ data }: { data: BoxIdentifier[] }) {
    const uniqueBoxes = useMemo(() => new Set(data.map((b) => `${b.name}-${b.kind}`)).size, [data]);
    const availableBoxes = useMemo(() => data.filter((b) => b.status === 'available').length, [data]);
    const unavailableBoxes = useMemo(() => data.filter((b) => b.status !== 'available').length, [data]);

    return (
        <div className='d-flex justify-content-between mx-3 my-3'>
            <BoxCard count={data.length} text='Total Boxes' countClassName='text-yellow-accent' />
            <BoxCard count={uniqueBoxes} text='Unique Boxes' countClassName='text-blue-accent' />
            <BoxCard count={availableBoxes} text='Available Boxes' countClassName='text-green-accent' />
            <BoxCard count={unavailableBoxes} text='Unavailable Boxes' countClassName='text-red-accent' />
        </div>
    );
}

export default function Boxes() {
    const latestBoxes = useBehavior(EcosystemService.latestBoxes);
    const table = useMemo(() => (latestBoxes ? createTable(latestBoxes) : undefined), [latestBoxes]);

    useEffect(() => {
        EcosystemService.syncLatestBoxes();
    }, []);

    return (
        <PageTemplate title='Boxes'>
            <div className='position-relative h-100 d-flex flex-column overflow-hidden'>
                {!table && <Loading />}
                {table && <TableWrapper table={table} />}
            </div>
        </PageTemplate>
    );
}

function TableWrapper({ table }: { table: DataTableModel<BoxInfo> }) {
    const navigate = useNavigate();
    useBehavior(table.version);

    const onRowDoubleClick = (rowIndex: number, tbl: DataTableModel<BoxInfo>, e: React.MouseEvent) => {
        if (!tbl) return;
        tableRowNavigation(
            e,
            `/foundry-boxes/${tbl.store.getValue('kind', rowIndex)}/${tbl.store.getValue('name', rowIndex)}`,
            navigate
        );
    };

    const tableTitle = <BoxStatistics data={table.store.toObjects() ?? []} />;

    return (
        <div className='d-flex flex-column h-100'>
            <div>{tableTitle}</div>
            <div className='mx-3 mb-3 w-50'>
                <DataTableGlobalFilter table={table} />
            </div>
            <div className='position-relative flex-grow-1'>
                <DataTableControl
                    height='flex'
                    table={table}
                    headerSize='sm'
                    onRowDoubleClick={onRowDoubleClick}
                    rowSelectionMode='single'
                />
            </div>
        </div>
    );
}
