import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Subject, debounceTime, merge, skip } from 'rxjs';
import { InlineAlert } from '../../components/common/Alert';
import useBehavior from '../../lib/hooks/useBehavior';
import { ClipboardService } from '../../lib/services/clipboard';
import { DialogService } from '../../lib/services/dialog';
import { ToastService } from '../../lib/services/toast';
import { AsyncMoleculeDrawer } from '../../lib/util/draw-molecules';
import { reportErrorAsToast, tryGetErrorMessage } from '../../lib/util/errors';
import { ModelAction, ReactiveModel } from '../../lib/util/reactive-model';
import { HTEExperimentInfo } from '../HTE/experiment-data';
import { HTE2MSApi } from './api';
import { HTE2MSAssets } from './assets';
import {
    HTE2MicroscaleLibrary,
    HTE2MicroscaleLibraryOptions,
    HTE2MicroscaleLibraryUIState,
    HTEDReactionIdT,
} from './data-model';
import { HTE2MSDesignModel } from './design/model';
import { HTE2MSInventoryModel } from './inventory/model';
import { HTE2MSProductionModel } from './production/model';
import { HTE2MSProtocolModel } from './protocol/model';
import { HTE2MSPostProductionModel } from './post-production/model';
import { capitalizeFirst } from '../../lib/util/misc';
import { HTE2MSFinalizeModel } from './finalize/model';
import { HTE2MSPromotionModel } from './promotion/model';

export type HTE2MSTab =
    | 'reactions'
    | 'procedure'
    | 'protocol'
    | 'reagents'
    | 'inventory'
    | 'production'
    | 'post-production'
    | 'promotion'
    | 'observations'
    | 'finalize';

export interface NewLibraryInfo {
    kind: 'new';
}

export interface DraftLibraryInfo {
    kind: 'draft';
    id: string;
    name: string;
}

export interface FoundryLibraryInfo {
    kind: 'foundry';
    experiment: HTEExperimentInfo;
    ui_state: HTE2MicroscaleLibraryUIState;
}

export type LibraryInfo = NewLibraryInfo | DraftLibraryInfo | FoundryLibraryInfo;

type BuildKind = 'protocol' | 'crude_plate';

export class HTE2MSModel extends ReactiveModel {
    static AppPrefix = 'hte/uscale';

    drawer = new AsyncMoleculeDrawer();
    assets = new HTE2MSAssets();

    design = new HTE2MSDesignModel(this);
    protocol = new HTE2MSProtocolModel(this);
    inventory = new HTE2MSInventoryModel(this);
    production = new HTE2MSProductionModel(this);
    postProduction = new HTE2MSPostProductionModel(this);
    promotion = new HTE2MSPromotionModel(this);
    finalization = new HTE2MSFinalizeModel(this);

    state = {
        tab: new BehaviorSubject<HTE2MSTab>('reactions'),
        info: new BehaviorSubject<LibraryInfo>({ kind: 'new' }),
        busy: new BehaviorSubject<boolean>(false),
        readOnlyDesignAndProduction: new BehaviorSubject<boolean>(false),
        fullReadOnly: new BehaviorSubject<boolean>(false),
    };

    events = {
        requestBuild: new Subject<BuildKind>(),
    };

    actions = {
        refreshInventory: new ModelAction({ onError: 'eat', applyResult: () => {} }),
    };

    get fullReadOnly() {
        return this.state.fullReadOnly.value;
    }

    get readOnlyDesignAndProduction() {
        return this.fullReadOnly || this.state.readOnlyDesignAndProduction.value;
    }

    get info() {
        return this.state.info.value;
    }

    get experiment() {
        return this.info.kind === 'foundry' ? this.info.experiment : undefined;
    }

    get libraryId() {
        if (this.info.kind === 'foundry') return `HTE${this.info.experiment.id}`;
        return 'HTEdraft';
    }

    get hideProduction() {
        return this.info.kind !== 'foundry' || !this.info.ui_state.workflow_state;
    }

    get hideDescriptions() {
        return this.info.kind !== 'foundry';
    }

    get last_modified_on() {
        return this.info.kind === 'foundry' ? this.info.experiment.modified_on : undefined;
    }

    get transferMode() {
        return !!this.options?.transferMode;
    }

    toLibrary(options?: { noCrudePlate?: boolean }): HTE2MicroscaleLibrary {
        return {
            kind: 'microscale_library',
            version: 1,
            design: this.design.toModel(),
            protocol: this.protocol.data,
            inventory: this.inventory.data,
            production: this.production.data,
            crude_plate: !options?.noCrudePlate ? this.production.crudePlate.data : undefined,
            purification: this.postProduction.purification.data,
            distribution: this.postProduction.distribution.data,
            ui_state: this.info.kind === 'foundry' ? this.info.ui_state : undefined,
        };
    }

    saveDraft = async (options?: { name?: string }) => {
        const { info } = this;
        const library = this.toLibrary();

        if (info.kind === 'new') {
            const name = options?.name || 'Untitled';
            const id = await HTE2MSApi.saveDraft({ name, library });
            this.state.info.next({ kind: 'draft', id, name });

            const url = `${window.location.origin}${HTE2MSModel.AppPrefix}/draft/${id}`;
            ClipboardService.copyText(url, 'Copy draft URL');
        } else if (info.kind === 'draft') {
            try {
                await HTE2MSApi.saveDraft({ id: info.id, name: info.name, library });
            } catch (err) {
                reportErrorAsToast('Save draft', err);
            }
        } else {
            throw new Error('Cannot save as draft');
        }

        ToastService.success('Draft saved');
    };

    updateFoundryInfo(info: Partial<FoundryLibraryInfo>) {
        if (this.info.kind !== 'foundry') throw new Error('Not a foundry experiment');
        this.state.info.next({ ...this.info, ...info });
    }

    saveToFoundry = async (options: HTE2MicroscaleLibraryOptions) => {
        const { info } = this;
        if (info.kind === 'foundry') throw new Error('Already saved to foundry');

        const experiment = await HTE2MSApi.createLibrary(options, this.toLibrary());
        this.state.info.next({ kind: 'foundry', experiment, ui_state: {} });

        ToastService.success('Saved to Foundry');
    };

    saveFoundryOptions = async (options: HTE2MicroscaleLibraryOptions) => {
        if (this.info.kind !== 'foundry') throw new Error('Not a foundry experiment');
        const experiment = await HTE2MSApi.updateOptions(this.info.experiment.id, {
            last_modified_on: this.last_modified_on!,
            options,
        });
        this.updateFoundryInfo({ experiment });
        ToastService.success('Options updated');
    };

    submitForProduction = async () => {
        if (this.info.kind !== 'foundry') throw new Error('Not a foundry experiment');
        const experiment = await HTE2MSApi.readyForProduction(this.info.experiment.id, this.last_modified_on!);
        try {
            await HTE2MSApi.notifySlack(this.experiment?.id!, 'submit');
        } catch (e) {
            reportErrorAsToast('Notify Slack', e);
        }
        this.updateFoundryInfo({ experiment, ui_state: { ...this.info.ui_state, workflow_state: 'production' } });
        ToastService.success('Submitted for Production');
    };

    submitForFinalization = async () => {
        if (this.info.kind !== 'foundry') throw new Error('Not a foundry experiment');
        const experiment = await HTE2MSApi.readyForFinalization(this.info.experiment.id, this.last_modified_on!);
        try {
            await HTE2MSApi.notifySlack(this.experiment?.id!, 'finalize');
        } catch (e) {
            reportErrorAsToast('Notify Slack', e);
        }
        this.updateFoundryInfo({
            experiment,
            ui_state: { ...this.info.ui_state, workflow_state: 'needs-finalization' },
        });
        ToastService.success('Submitted for Finalization');
    };

    showReactionDesign(ids: HTEDReactionIdT[], options?: { onlySelected?: boolean }) {
        this.design.selectReactionIds(ids, options);
        if (this.state.tab.value !== 'reactions') this.state.tab.next('reactions');
    }

    async build(kind: BuildKind) {
        if (this.readOnlyDesignAndProduction) return;

        try {
            this.state.busy.next(true);
            if (kind === 'protocol') this.protocol.state.status.next('pending');
            this.production.state.status.next('pending');

            const rebuild_protocol = kind === 'protocol';
            const library = this.toLibrary({ noCrudePlate: true });
            const result =
                this.info.kind === 'foundry'
                    ? await HTE2MSApi.build(this.info.experiment.id, {
                          last_modified_on: this.last_modified_on!,
                          library,
                          rebuild_protocol,
                      })
                    : await HTE2MSApi.buildDraft({ library, rebuild_protocol });

            if (result.experiment) {
                this.updateFoundryInfo({ experiment: result.experiment });
            }

            if (result.protocol) {
                this.protocol.setProtocol(result.protocol);
            }
            this.production.setCrudePlate(result.crude_plate);
        } finally {
            this.state.busy.next(false);
        }
    }

    async saveProcedure(kind: 'procedure' | 'observations', value: string) {
        if (!this.experiment) return;
        try {
            if ((this.experiment[kind] || '') === (value || '')) return;

            this.state.busy.next(true);
            const experiment = await HTE2MSApi.modifyProcedureObservations(this.experiment!.id, {
                last_modified_on: this.last_modified_on!,
                [kind]: value,
            });
            this.updateFoundryInfo({ experiment });
            ToastService.success(`${capitalizeFirst(kind)} saved`);
        } catch (err) {
            reportErrorAsToast('Save procedure', err);
        } finally {
            this.state.busy.next(false);
        }
    }

    confirmClone = () => {
        DialogService.open({
            type: 'generic',
            title: 'Clone Library',
            confirmButtonContent: 'Apply',
            wrapOk: true,
            options: { staticBackdrop: true },
            content: ConfirmCloneDialogContent,
            onOk: () => this.clone(),
        });
    };

    private clone() {
        this.inventory.clear();
        this.production.clear();
        this.state.info.next({ kind: 'new' });
        this.state.tab.next('reactions');
    }

    mount() {
        if (this.transferMode) return;

        let currentRequest: BuildKind | undefined;

        let autoBuilding = false;
        const autoBuild = async () => {
            if (autoBuilding || !currentRequest || this.readOnlyDesignAndProduction) return;
            const kind = currentRequest;
            currentRequest = undefined;
            try {
                autoBuilding = true;
                await this.build(kind);
                autoBuilding = false;
                autoBuild();
            } catch (err) {
                autoBuilding = false;
                currentRequest = undefined;
                console.error('Auto build failed', err);
                autoBuildFailed(this, kind, err);
            }
        };

        this.subscribe(this.events.requestBuild, (kind) => {
            if (this.readOnlyDesignAndProduction) return;

            this.state.busy.next(true);

            if (kind === 'protocol') {
                if (!autoBuilding) this.protocol.state.status.next('pending');
                currentRequest = 'protocol';
            } else if (!currentRequest) {
                currentRequest = 'crude_plate';
            }
            if (!autoBuilding) this.production.state.status.next('pending');
        });

        this.subscribe(this.events.requestBuild.pipe(debounceTime(1000)), () => {
            autoBuild();
        });

        this.subscribe(
            merge(this.inventory.state.inventory.pipe(skip(1)), this.production.state.production.pipe(skip(1))),
            (v) => {
                if (this.readOnlyDesignAndProduction) return;

                this.state.busy.next(true);
                if (!autoBuilding) this.production.state.status.next('pending');
            }
        );

        this.subscribe(
            merge(
                this.inventory.state.inventory.pipe(skip(1), debounceTime(500)),
                this.production.state.production.pipe(skip(1), debounceTime(150))
            ),
            () => {
                if (this.readOnlyDesignAndProduction) return;

                if (!currentRequest) {
                    currentRequest = 'crude_plate';
                    autoBuild();
                }
            }
        );

        // When protocol changes, reset the dilution helper UI
        this.subscribe(this.protocol.state.protocol, () => {
            this.inventory.dilute.reset();
        });

        this.subscribe(this.state.info, (info) => {
            if (info.kind !== 'foundry') {
                if (this.state.readOnlyDesignAndProduction.value) {
                    this.state.readOnlyDesignAndProduction.next(false);
                }
                if (this.state.fullReadOnly.value) {
                    this.state.fullReadOnly.next(false);
                }
                return;
            }

            const nextDesign =
                typeof info.experiment.crude_product_plate_id === 'number' || !!info.experiment.locked_on;
            if (this.state.readOnlyDesignAndProduction.value !== nextDesign) {
                this.state.readOnlyDesignAndProduction.next(nextDesign);
            }

            const nextFull = !!info.experiment.locked_on;
            if (this.state.fullReadOnly.value !== nextFull) {
                this.state.fullReadOnly.next(nextFull);
            }
        });

        // Reagents status needs to be globally updated after inventory changes
        this.subscribe(this.inventory.state.inventory.pipe(debounceTime(33)), () => {
            this.protocol.reagents.syncStatus();
        });
    }

    refreshInventory = () => this.actions.refreshInventory.run(this._refreshInventory());

    private async _refreshInventory() {
        await Promise.allSettled([
            this.design.syncAssets({ refresh: true }),
            this.inventory.syncAssets({ refresh: true }),
        ]);
        this.inventory.refresh();
    }

    async initDraft(id: string) {
        const draft = await HTE2MSApi.draft(id);
        await this.init(draft.library);
        this.state.info.next({ kind: 'draft', id, name: draft.name });
    }

    async initNew() {
        const library = await HTE2MSApi.default();
        await this.init(library);
    }

    async initFoundry(experiment: HTEExperimentInfo, library: HTE2MicroscaleLibrary) {
        await this.init(library);
        if (library.ui_state?.workflow_state === 'production') {
            this.state.tab.next('reagents');
        } else if (library.ui_state?.workflow_state === 'purification') {
            this.state.tab.next('post-production');
        } else if (library.ui_state?.workflow_state === 'needs-finalization') {
            this.state.tab.next('finalize');
        }
        this.state.info.next({ kind: 'foundry', experiment, ui_state: library.ui_state ?? {} });
    }

    private async init(library: HTE2MicroscaleLibrary) {
        await this.assets.init();
        await this.design.init(library.design);
        this.protocol.setProtocol(library.protocol);
        await this.inventory.init(library.inventory);
        this.production.init({
            production: library.production,
            crude_plate: library.crude_plate,
        });
        await this.postProduction.purification.init(library.purification ?? this.assets.defaults.default_purification);
        await this.postProduction.distribution.init(library.distribution ?? this.assets.defaults.default_distribution);
        this.postProduction.qc.setData(library.qc ?? this.assets.defaults.default_qc);
    }

    constructor(private options?: { transferMode?: boolean }) {
        super();
    }
}

type AutobuildFailedDialogState = { error: string };

function autoBuildFailed(model: HTE2MSModel, kind: BuildKind, error: any) {
    DialogService.open({
        type: 'generic',
        title: 'Background Build Failed',
        confirmButtonContent: 'Retry',
        wrapOk: true,
        options: { staticBackdrop: true },
        defaultState: { error: tryGetErrorMessage(error) } as AutobuildFailedDialogState,
        content: AutoBuildFailedDialogContent,
        onOk: async (_, subject: BehaviorSubject<AutobuildFailedDialogState>) => {
            try {
                await model.build(kind);
            } catch (err) {
                subject.next({ error: tryGetErrorMessage(err) });
                throw new Error(''); // will be shown in the dialog body
            }
        },
    });
}

function AutoBuildFailedDialogContent({ stateSubject }: { stateSubject: BehaviorSubject<AutobuildFailedDialogState> }) {
    const state = useBehavior(stateSubject);

    return (
        <div className='vstack gap-2'>
            <div className='font-body-small'>
                Background <b>Protocol</b>/<b>Production</b> build failed:
            </div>
            <InlineAlert variant='danger' icon={faExclamationCircle} className='font-body-small'>
                {state.error}
            </InlineAlert>
            <div className='font-body-small text-secondary'>
                If the error persists, you will have to reload the page and loose your latest edit.
            </div>
        </div>
    );
}

function ConfirmCloneDialogContent() {
    return (
        <div className='vstack gap-2'>
            <InlineAlert>
                This action will clear the current <b>Inventory</b>, <b>Production</b>, <b>Procedure</b>, and{' '}
                <b>Observation</b> data and allow saving the library as a separate draft or Foundry experiment.
            </InlineAlert>
        </div>
    );
}
