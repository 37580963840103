import log from 'loglevel';
import { ToastService } from '../services/toast';

export function tryParseAxiosError(error: any): string {
    let { message } = error;
    if (error.config?.responseType === 'arraybuffer' && error?.response?.data) {
        try {
            const enc = new TextDecoder();
            const arr = new Uint8Array(error.response.data);
            const data = enc.decode(arr);
            const json = JSON.parse(data);
            if (json.detail) message = json.detail;
        } catch (e) {
            log.error(e);
        }
    } else if (error.response?.data?.detail) {
        message = error.response?.data.detail;
    }

    return message;
}

function getErrorArray(err: any[]) {
    return err
        .map((e) => {
            if (e?.msg && Array.isArray(e?.loc)) {
                if (e.loc[0] === 'body' && e.loc.length > 1) return `${e.msg} [${e.loc.slice(1).join('.')}]`;
                return `${e.msg} [${e.loc.join('.')}]`;
            }
            return e.msg;
        })
        .filter((e) => !!e);
}

export function tryGetErrorMessage(err: any) {
    if (Array.isArray(err)) {
        return getErrorArray(err).join(', ');
    }
    if (err instanceof Error) {
        return err.message;
    }
    if (typeof err === 'string') {
        return err;
    }
    return 'Unknown error';
}

export function tryGetErrorMessageList(err: any) {
    if (Array.isArray(err)) {
        return getErrorArray(err);
    }
    if (err instanceof Error) {
        return [err.message];
    }
    if (typeof err === 'string') {
        return [err];
    }
    return ['Unknown error'];
}

export class NotAuthorizedError extends Error {
    constructor(message: string, public isExpiredToken: boolean) {
        super(message);
    }
}

export function isNotAuthorizedError(err: any): err is NotAuthorizedError {
    return err instanceof NotAuthorizedError;
}

export interface ReportErrorAsToastOptions {
    timeoutMs?: number;
    id?: string;
}
export function reportErrorAsToast(label: string, err: any, options?: ReportErrorAsToastOptions) {
    let msg = tryGetErrorMessage(err);
    log.error(err, msg);
    if (msg.length > 300) msg = `${msg.substring(0, 300)}...`;
    ToastService.show({
        id: options?.id,
        type: 'danger',
        message: `${label}: ${msg}`,
        timeoutMs: options?.timeoutMs ?? 30000,
    });
}
