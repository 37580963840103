export function mean(arr: number[]) {
    let sum = 0;
    let N = 0;
    for (let i = 0; i < arr.length; i++) {
        if (!Number.isNaN(arr[i])) {
            sum += arr[i];
            N += 1;
        }
    }
    if (N === 0) return undefined;
    return sum / N;
}

export function stdev(arr: number[]) {
    const arrMean = mean(arr);
    let v = 0;
    let N = 0;
    for (let i = 0; i < arr.length; i++) {
        if (!Number.isNaN(arr[i])) {
            v += Math.abs(arr[i] - arrMean!) ** 2;
            N += 1;
        }
    }
    if (N === 0) return undefined;
    return Math.sqrt(v / N);
}

export function isRelativelyClose(a: number, b: number, rtol: number = 0.01) {
    // based on numpy.isclose (https://numpy.org/doc/stable/reference/generated/numpy.isclose.html)
    return Math.abs(a - b) <= rtol * Math.abs(b);
}
