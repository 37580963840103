import { useEffect } from 'react';
import { skip, throttleTime } from 'rxjs';
import { arrayEqual } from '../util/misc';
import useBehavior from './useBehavior';
import useBehaviorSubject from './useBehaviorSubject';

export function useThrottledValue<T>(action: (v: T) => void, currentValue: T, keys: any[], timeout = 200) {
    const subject = useBehaviorSubject(currentValue);
    const value = useBehavior(subject);

    useEffect(() => {
        const sub = subject
            .pipe(skip(1), throttleTime(timeout, undefined, { leading: true, trailing: true }))
            .subscribe(action);

        return () => sub.unsubscribe();
    }, [...keys, timeout, subject]);

    useEffect(() => {
        const equal =
            Array.isArray(subject.value) && Array.isArray(currentValue)
                ? arrayEqual(subject.value, currentValue)
                : subject.value === currentValue;
        if (!equal) {
            subject.next(currentValue);
        }
    }, [subject, currentValue]);

    return [value, subject] as const;
}
