import { BehaviorSubject } from 'rxjs';
import api, { DescriptorAndSubstanceInfo } from '../../api';
import { BiologyAPI } from '../../api/biology';
import { Dataset, FoundryEnvironmentInfo, FoundryUser, ProviderId } from '../../api/data';
import { AssayAPI, AssayDetail } from '../../pages/Assays/assay-api';
import { ECMApi, PlateRow } from '../../pages/ECM/ecm-api';
import { DataTableStore } from '../../components/DataTable';
import { ReactTableModel } from '../../components/ReactTable/model';
import { debouncedRequestWrapper } from '../util/requests';
import { ToastService } from './toast';
import { AssayProperty, getProteinKeys, ProteinType } from '../models/biology';
import { OrderedMap, orderedMap } from '../util/ordered-map';
import { EBAPI } from '../../pages/EnvisionBIO/api';
import { reportErrorAsToast } from '../util/errors';
import { BoxAPI, BoxInfo } from '../../pages/Boxes/box-api';

function syncFn<T>(name: string, provider: () => Promise<T>, value: BehaviorSubject<T | undefined>) {
    return debouncedRequestWrapper({
        provider,
        apply: (v) => value.next(v),
        error: () => ToastService.show({ id: '_sync-error_', type: 'danger', message: `Error synchronizing ${name}.` }),
        debounceMs: 30000,
        retryOnError: { timeoutMs: 3000, timeoutIncrementMs: 500, maxRetries: 10 },
    });
}

export type FoundryEnvironmentName = 'dev' | 'stg' | 'prd';

export type UserMap = Map<ProviderId, FoundryUser>;

class _EcosystemService {
    readonly environment = new BehaviorSubject<FoundryEnvironmentInfo | undefined>(undefined);

    readonly datasets = new BehaviorSubject<DataTableStore<Dataset> | undefined>(undefined);
    readonly latestBoxes = new BehaviorSubject<DataTableStore<BoxInfo> | undefined>(undefined);
    readonly allBoxes = new BehaviorSubject<DataTableStore<BoxInfo> | undefined>(undefined);
    readonly assays = new BehaviorSubject<DataTableStore<AssayDetail> | undefined>(undefined);
    readonly descriptorsAndSubstanceInfo = new BehaviorSubject<DescriptorAndSubstanceInfo | undefined>(undefined);
    readonly plates = new BehaviorSubject<ReactTableModel<PlateRow> | undefined>(undefined);
    readonly proteins = new BehaviorSubject<OrderedMap<ProteinType> | undefined>(undefined);
    readonly properties = new BehaviorSubject<OrderedMap<AssayProperty> | undefined>(undefined);
    readonly users = new BehaviorSubject<FoundryUser[] | undefined>(undefined);

    readonly syncEnvironment = syncFn('environment', () => api.utils.getEnvironmentInfo(), this.environment);

    readonly syncDatasets = syncFn('datasets', () => api.datasets.list(), this.datasets);
    readonly syncLatestBoxes = syncFn('boxes', () => BoxAPI.list({ latest: true }), this.latestBoxes);
    readonly syncAllBoxes = syncFn('boxes', () => BoxAPI.list({ latest: false }), this.allBoxes);
    readonly syncAssays = syncFn('assays', () => AssayAPI.list(), this.assays);
    readonly syncDescriptorsAndSubstanceInfo = syncFn(
        'descriptorsAndSubstanceInfo',
        () => api.descriptors.list(),
        this.descriptorsAndSubstanceInfo
    );
    readonly syncPlates = syncFn('plates', () => ECMApi.listPlates(), this.plates);
    readonly syncProteins = syncFn(
        'proteins',
        async () => {
            const all = await BiologyAPI.getProteins();
            return orderedMap<ProteinType>(getProteinKeys).add(all);
        },
        this.proteins
    );
    readonly syncProperties = syncFn(
        'properties',
        async () => {
            const all = await EBAPI.properties();
            return orderedMap<AssayProperty>((p) => p.id).add(all);
        },
        this.properties
    );
    readonly syncUsers = syncFn('users', () => api.permissions.users(), this.users);

    getEnvironment(): Promise<FoundryEnvironmentInfo> {
        return EcosystemService.environment.value ?? (EcosystemService.syncEnvironment() as any);
    }

    getProteins(): Promise<OrderedMap<ProteinType>> {
        return EcosystemService.proteins.value ?? (EcosystemService.syncProteins() as any);
    }

    getProperties(): Promise<OrderedMap<AssayProperty>> {
        return EcosystemService.properties.value ?? (EcosystemService.syncProperties() as any);
    }

    getAssays(): Promise<DataTableStore<AssayDetail>> {
        return EcosystemService.assays.value ?? (EcosystemService.syncAssays() as any);
    }

    getPlates(): Promise<ReactTableModel<PlateRow>> {
        return EcosystemService.plates.value ?? (EcosystemService.syncPlates() as any);
    }

    getLatestBoxes(): Promise<ReactTableModel<BoxInfo>> {
        return EcosystemService.latestBoxes.value ?? (EcosystemService.syncLatestBoxes() as any);
    }

    getDescriptorsAndSubstanceInfo(): Promise<DescriptorAndSubstanceInfo> {
        return (
            EcosystemService.descriptorsAndSubstanceInfo.value ??
            (EcosystemService.syncDescriptorsAndSubstanceInfo() as any)
        );
    }

    getUsers(): Promise<FoundryUser[]> {
        return EcosystemService.users.value ?? (EcosystemService.syncUsers() as any);
    }

    async getUserMap() {
        const users = await this.getUsers();
        return new Map(users.filter((u) => !u.is_group && u.provider_id).map((u) => [u.provider_id!, u]));
    }

    // this is called by AuthService on successful auth.
    init() {
        this.syncEnvironment().catch((err) => {
            reportErrorAsToast('Ecosystem error', err);
        });
    }
}

export const EcosystemService = new _EcosystemService();
