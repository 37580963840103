import { useEffect, useMemo } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import Split from 'react-split-it';
import { Option } from '../../../components/common/formatSelectOptionLabel';
import { CustomSelectClassNames, DefaultSelectStyles } from '../../../components/common/selectStyles';
import { TextInput } from '../../../components/common/Inputs';
import { Footer, PageTemplate } from '../../../components/Layout/Layout';
import useBehavior from '../../../lib/hooks/useBehavior';
import { DialogService } from '../../../lib/services/dialog';
import { EcosystemService } from '../../../lib/services/ecosystem';
import { SingleBatchUploadModel } from './batch-upload-model';
import { CompoundInfoPanel, SelectedCompoundHeader, SimilarCompounds } from './CompoundComparison';

export function SingleBatchSelection({ model }: { model: SingleBatchUploadModel }) {
    const step = useBehavior(model.state.step);
    const comparisonModel = model.compoundComparison;

    useEffect(() => {
        if (model) model.mount();
        return () => model.dispose();
    }, [model]);

    return (
        <PageTemplate
            title='Compounds'
            breadcrumb={{ title: 'Register new batch', href: '', icon: undefined }}
            withFooter
        >
            {comparisonModel && <CompoundComparison model={model} title='Register new batch' />}
            {step === 'selection' && <SelectionFooter model={model} />}
            {step === 'details' && <DetailsFooter model={model} />}
        </PageTemplate>
    );
}

function SelectionFooter({ model }: { model: SingleBatchUploadModel }) {
    return (
        <Footer>
            <div />
            <div>
                <Button variant='link' onClick={() => model.cancel()} size='sm' className='me-2'>
                    Cancel
                </Button>
                <Button variant='primary' onClick={() => model.next()} size='sm'>
                    Continue With Selected
                </Button>
            </div>
        </Footer>
    );
}

function ConfirmationContent({ model }: { model: SingleBatchUploadModel }) {
    const { compound_identifier } = useBehavior(model.state.batchReview);
    const shouldPromoteToENT = useBehavior(model.state.entosCompound);

    return (
        <>
            <p>Are you sure you want to add this batch to Foundry? This action cannot easily be undone.</p>
            <Form.Check
                type='switch'
                id='entos-compound-switch'
                label='Promote Compound to ENT'
                checked={shouldPromoteToENT}
                onChange={(e) => model.state.entosCompound.next(!!e.target.checked)}
                className='d-inline-block me-2'
                disabled={compound_identifier?.startsWith('ENT')}
            />
            {compound_identifier && !shouldPromoteToENT && (
                <Alert variant='warning' className='mt-3'>
                    This batch will be registered under the existing compound {compound_identifier}
                </Alert>
            )}
            {!compound_identifier && !shouldPromoteToENT && (
                <Alert variant='warning' className='mt-3'>
                    This batch will be registered under a new CMPD compound
                </Alert>
            )}
            {compound_identifier && shouldPromoteToENT && (
                <Alert variant='info' className='mt-3'>
                    This batch will be registered under the existing compound {compound_identifier}, and that compound
                    will be promoted to an ENT
                </Alert>
            )}
            {!compound_identifier && shouldPromoteToENT && (
                <Alert variant='info' className='mt-3'>
                    This batch will be registered under a new ENT compound
                </Alert>
            )}
        </>
    );
}

function DetailsFooter({ model }: { model: SingleBatchUploadModel }) {
    const isLoading = useBehavior(model.isLoading);

    function onNext() {
        DialogService.open({
            type: 'confirm',
            onConfirm,
            title: 'Add to Foundry',
            text: <ConfirmationContent model={model} />,
            confirmText: 'Add',
        });
    }

    async function onConfirm() {
        await model.upload();
    }

    return (
        <Footer>
            <div />
            <div>
                <Button variant='link' onClick={() => model.cancel()} size='sm' className='me-2'>
                    Cancel
                </Button>
                <Button
                    variant='outline-primary'
                    onClick={() => model.back()}
                    size='sm'
                    className='me-2'
                    disabled={isLoading}
                >
                    Back
                </Button>
                <Button variant='primary' onClick={() => onNext()} size='sm' disabled={isLoading}>
                    Add to Foundry
                </Button>
            </div>
        </Footer>
    );
}

function CompoundComparison({ model, title }: { model: SingleBatchUploadModel; title: string }) {
    return (
        <div className='position-relative h-100 d-flex flex-column'>
            <SelectedCompoundHeader model={model.compoundComparison} title={title} />
            <CompoundSideBySide model={model} />
        </div>
    );
}

function CompoundSideBySide({ model }: { model: SingleBatchUploadModel }) {
    const step = useBehavior(model.state.step);
    const compoundComparison = model.compoundComparison;
    return (
        <div className='position-relative flex-grow-1'>
            <div className='position-absolute top-0 left-0 h-100 w-100'>
                {step === 'selection' && (
                    <Split direction='horizontal' gutterSize={6} sizes={[0.3, 0.7]}>
                        <CompoundInfoPanel model={compoundComparison} />
                        <SimilarCompounds model={compoundComparison} />
                    </Split>
                )}
                {step === 'details' && (
                    <Split direction='horizontal' gutterSize={6} sizes={[0.3, 0.7]}>
                        <CompoundInfoPanel model={compoundComparison} showSelected />
                        <EnterBatchInformation model={model} />
                    </Split>
                )}
            </div>
        </div>
    );
}

function EnterBatchInformation({ model }: { model: SingleBatchUploadModel }) {
    const compound = useBehavior(model.compoundComparison.state.selected);
    const newCompound = !compound.identifier;
    const batchReview = useBehavior(model.state.batchReview);
    const reviewStatus = useBehavior(model.state.reviewStatus);
    const projects = useBehavior(EcosystemService.environment)?.projects;
    const options: Option[] = useMemo(() => {
        if (!projects) return [];
        return projects.map((value) => ({ label: value, value }));
    }, [projects]);

    function submittedPurityIsValid() {
        const submittedPurity = batchReview.submitted_purity;
        if (submittedPurity) {
            if (submittedPurity < 0 || submittedPurity > 100) return false;
            return true;
        }
        return true;
    }

    function submittedV3kNotationIsValid() {
        const ambiguousStereochemistryV3k = batchReview.ambiguous_stereochemistry_v3k;
        if (!ambiguousStereochemistryV3k) return true;
        return ambiguousStereochemistryV3k === '' || /(\|(.*:.*)*\|)$/.test(ambiguousStereochemistryV3k);
    }

    return (
        <div className='m-3'>
            <p className='fw-bold mb-2'>Enter Batch Information</p>
            <div className='d-flex align-items-center'>
                <span className='text-secondary me-2'>Project:</span>
                {!newCompound && <span className='fw-bold me-3'>{compound.project ?? '-'}</span>}
                {newCompound && (
                    <div className='w-25 me-3'>
                        <Select
                            options={options as any}
                            placeholder='Select project'
                            classNames={CustomSelectClassNames}
                            styles={DefaultSelectStyles}
                            menuPortalTarget={document.body}
                            menuPosition='fixed'
                            value={{ label: batchReview.project, value: batchReview.project }}
                            onChange={(option: unknown) =>
                                model.state.batchReview.next({ ...batchReview, project: (option as Option).value })
                            }
                        />
                    </div>
                )}
                <span className='text-secondary me-2'>Common name:</span>
                {!newCompound && <span className='fw-bold me-3'>{compound.common_name ?? '-'}</span>}
                {newCompound && (
                    <div className='w-50'>
                        <TextInput
                            placeholder='Enter common name (optional)'
                            value={batchReview.common_name ?? ''}
                            setValue={(v: string) => model.state.batchReview.next({ ...batchReview, common_name: v })}
                        />
                    </div>
                )}
            </div>
            <div className='d-flex'>
                <div className='flex-grow-1 me-3'>
                    <p className='fw-bold my-2'>Supplier Information</p>
                    <div className='d-flex mb-2'>
                        <span className='text-secondary me-2 w-25 text-right'>Submitted Purity:</span>
                        <div>
                            <TextInput
                                placeholder='Enter purity (0-100)'
                                value={`${batchReview.submitted_purity ?? ''}`}
                                setValue={(v: string) =>
                                    model.state.batchReview.next({ ...batchReview, submitted_purity: +v })
                                }
                                validation={
                                    submittedPurityIsValid() ? '' : 'Submitted purity must be between 0 and 100.'
                                }
                            />
                        </div>
                    </div>
                    <div className='d-flex mb-2'>
                        <span className='text-secondary me-2 w-25 text-right'>Supplier:</span>
                        <div>
                            <TextInput
                                placeholder='Enter supplier'
                                value={batchReview.supplier ?? ''}
                                setValue={(v: string) => model.state.batchReview.next({ ...batchReview, supplier: v })}
                            />
                        </div>
                    </div>
                    <div className='d-flex mb-2'>
                        <span className='text-secondary me-2 w-25 text-right'>Supplier ID:</span>
                        <div>
                            <TextInput
                                placeholder='Enter supplier ID'
                                value={batchReview.supplier_id ?? ''}
                                setValue={(v: string) =>
                                    model.state.batchReview.next({ ...batchReview, supplier_id: v })
                                }
                            />
                        </div>
                    </div>
                    <div className='d-flex mb-2'>
                        <span className='text-secondary me-2 w-25 text-right'>Supplier NB:</span>
                        <div>
                            <TextInput
                                placeholder='Enter supplier NB'
                                value={batchReview.supplier_notebook ?? ''}
                                setValue={(v: string) =>
                                    model.state.batchReview.next({ ...batchReview, supplier_notebook: v })
                                }
                            />
                        </div>
                    </div>
                    <div className='d-flex mb-2'>
                        <span className='text-secondary me-2 w-25 text-right'>Supplier NB page:</span>
                        <div>
                            <TextInput
                                placeholder='Enter supplier NB page'
                                value={batchReview.supplier_notebook_page ?? ''}
                                setValue={(v: string) =>
                                    model.state.batchReview.next({ ...batchReview, supplier_notebook_page: v })
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className='flex-grow-1'>
                    <p className='fw-bold my-2'>Stereochemistry Information</p>
                    <div className='d-flex mb-2'>
                        <span className='text-secondary me-2 w-25 text-right'>Label:</span>
                        <span>{compound.stereochemistry_label ?? '-'}</span>
                    </div>
                    <div className='d-flex mb-2'>
                        <span className='text-secondary me-2 w-25 text-right'>Enantiomeric ratio:</span>
                        <span>{compound.stereochemistry_enantiomeric_ratio ?? '-'}</span>
                    </div>
                    <div className='d-flex mb-2'>
                        <span className='text-secondary me-2 w-25 text-right'>Comment:</span>
                        {!newCompound && <span>{compound.stereochemistry_comment ?? '-'}</span>}
                        {newCompound && (
                            <div>
                                <TextInput
                                    placeholder='Enter stereochemistry comment'
                                    value={batchReview.stereochemistry_comment ?? ''}
                                    setValue={(v: string) =>
                                        model.state.batchReview.next({ ...batchReview, stereochemistry_comment: v })
                                    }
                                    textarea
                                />
                            </div>
                        )}
                    </div>
                    <div className='d-flex'>
                        <span className='text-secondary me-2 w-25 text-right'>
                            Ambiguous stereochemistry (v3k notation):
                        </span>
                        <div>
                            <TextInput
                                placeholder='Enter ambiguous stereochemistry'
                                value={batchReview.ambiguous_stereochemistry_v3k ?? ''}
                                setValue={(v: string) =>
                                    model.state.batchReview.next({
                                        ...batchReview,
                                        ambiguous_stereochemistry_v3k: v,
                                    })
                                }
                                validation={
                                    submittedV3kNotationIsValid() ? '' : 'Submitted value must be v3k notation.'
                                }
                                textarea
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {reviewStatus.inProgress && <Spinner animation='border' />}
                {!reviewStatus.inProgress && reviewStatus.error && <p className='text-danger'>{reviewStatus.error}</p>}
                {!reviewStatus.inProgress && reviewStatus.warning && (
                    <p className='text-warning'>{reviewStatus.warning}</p>
                )}
            </div>
        </div>
    );
}
