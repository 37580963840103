import {
    getReactantLayerType,
    Plate,
    Reactant,
    Well,
    WellLayerType,
    WellLayout,
    WellRect,
    WellSelection,
    PlateDimensions,
} from '../experiment-data';

export interface PlateCopy {
    selection: WellSelection;
    plate: Plate;
}

export interface PlateReactBounds {
    width: number;
    rowStart: number;
    rowEnd: number;
    colStart: number;
    colEnd: number;
}

export function getPlateRectBounds(layout: WellLayout, rect: WellRect): PlateReactBounds {
    const [a, b] = rect;
    const [w] = PlateDimensions[layout];

    const r0 = Math.floor(a / w);
    const r1 = Math.floor(b / w);
    const c0 = a % w;
    const c1 = b % w;

    return {
        width: w,
        rowStart: Math.min(r0, r1),
        rowEnd: Math.max(r0, r1),
        colStart: Math.min(c0, c1),
        colEnd: Math.max(c0, c1),
    };
}

export function selectionFromRect(layout: WellLayout, rect: WellRect | undefined, union?: WellSelection) {
    const ret = union ? [...union] : new Array<0 | 1>(layout).fill(0);
    if (!rect) return ret;
    const bounds = getPlateRectBounds(layout, rect);
    for (let i = bounds.rowStart; i <= bounds.rowEnd; i++) {
        for (let j = bounds.colStart; j <= bounds.colEnd; j++) {
            const index = i * bounds.width + j;
            ret[index] = 1;
        }
    }
    return ret;
}

export function isWellSelectionEmpty(selection?: WellSelection) {
    if (!selection) return true;
    for (const e of selection) {
        if (e !== 0) return false;
    }
    return true;
}

export function selectionSingleton(selection: WellSelection): number | -1 {
    let fst: number = -1;
    for (let i = 0; i < selection.length; i++) {
        if (selection[i]) {
            if (fst >= 0) return -1;
            fst = i;
        }
    }
    return fst;
}

export function getWellCoords(width: number, index: number): [row: number, col: number] {
    return [Math.floor(index / width), index % width];
}

export function getWellIndex(width: number, row: number, col: number): number {
    return width * row + col;
}

export function columnMajorIndexToRowMajorIndex(layout: WellLayout, index: number): number {
    const [w, h] = PlateDimensions[layout];
    const col = Math.floor(index / h);
    const row = index % h;
    return row * w + col;
}

export function rowMajorIndexToColumnMajorIndex(layout: WellLayout, index: number): number {
    const [w, h] = PlateDimensions[layout];
    const row = Math.floor(index / w);
    const col = index % w;
    return col * h + row;
}

export function forEachWellIndex(
    selection: WellSelection | undefined,
    action: (index: number, i: number, j: number) => void,
    options?: { direction?: 'row' | 'column' }
) {
    if (!selection) return;

    if (!options?.direction || options.direction === 'row') {
        const [w] = PlateDimensions[selection.length as WellLayout];
        for (let index = 0; index < selection.length; index++) {
            if (selection[index]) {
                const row = Math.floor(index / w);
                const col = index % w;
                action(index, row, col);
            }
        }
    } else {
        const [w, h] = PlateDimensions[selection.length as WellLayout];
        for (let cmIndex = 0; cmIndex < selection.length; cmIndex++) {
            const col = Math.floor(cmIndex / h);
            const row = cmIndex % h;
            const index = row * w + col;
            if (selection[index]) {
                action(index, row, col);
            }
        }
    }
}

export function forEachWell(
    plate: Plate,
    selection: WellSelection | undefined,
    action: (well: Well, index: number, row: number, col: number) => void
) {
    return forEachWellIndex(selection, (index, row, col) => action(plate.wells[index], index, row, col));
}

export function findWellLayer(well: Well, layer: WellLayerType, reactants: ReadonlyMap<string, Reactant>) {
    if (!well) return undefined;
    for (const identifier of well) {
        const r = reactants.get(identifier);
        if (r && getReactantLayerType(r) === layer) return r;
    }
    return undefined;
}

function addToWell(well: Well, reactant: Reactant, reactants: ReadonlyMap<string, Reactant>): Well | undefined {
    if (!well) return undefined;

    const index = well.indexOf(reactant.identifier);
    // already present
    if (index >= 0) return undefined;

    const layer = getReactantLayerType(reactant);
    if (layer === 'reagent') {
        // there can be more than 1 reagent
        return [...well, reactant.identifier];
    }

    for (let i = 0; i < well.length; i++) {
        const identifier = well[i];
        const r = reactants.get(identifier);
        if (r && getReactantLayerType(r) === layer) {
            const ret = [...well];
            ret[i] = reactant.identifier;
            return ret;
        }
    }

    return [...well, reactant.identifier];
}

function clearWellLayer(well: Well, layer: WellLayerType, reactants: ReadonlyMap<string, Reactant>): Well | undefined {
    if (!well) return undefined;

    const ret: Well = [];
    for (const identifier of well) {
        const r = reactants.get(identifier)!;
        if (getReactantLayerType(r) !== layer) ret.push(identifier);
    }

    return ret.length !== well.length ? ret : undefined;
}

function removeFromWell(well: Well, reactant: Reactant): Well | undefined {
    if (!well) return undefined;

    const index = well.indexOf(reactant.identifier);
    if (index < 0) return undefined;
    const ret: Well = [];
    for (const id of well) {
        if (reactant.identifier !== id) ret.push(id);
    }
    return ret;
}

export function updateWells(
    plate: Plate,
    selection: WellSelection,
    action: 'add' | 'remove' | 'clear' | 'clear-layer' | 'disable' | 'enable' | 'replace',
    options?: {
        reactant?: Reactant;
        layer?: WellLayerType;
        reactants?: ReadonlyMap<string, Reactant>;
        replaceMap?: ReadonlyMap<string, string | null>;
    }
) {
    if (isWellSelectionEmpty(selection)) return undefined;

    const newPlate: Plate = { ...plate, wells: [...plate.wells] };
    let modified = false;

    forEachWellIndex(selection, (index) => {
        let well = newPlate.wells[index];

        if (action === 'disable') {
            if (well) {
                newPlate.wells[index] = undefined;
                modified = true;
            }
            return;
        }
        if (action === 'enable') {
            if (!well) {
                newPlate.wells[index] = [];
                modified = true;
            }
            return;
        }

        if (!well) {
            // disabled well
            return;
        }

        if (action === 'clear') {
            if (well.length > 0) {
                newPlate.wells[index] = [];
                modified = true;
            }
            return;
        }

        if (action === 'clear-layer') {
            well = clearWellLayer(well, options!.layer!, options!.reactants!);
            if (well) {
                newPlate.wells[index] = well;
                modified = true;
            }
            return;
        }

        if (action === 'add') {
            well = addToWell(well, options!.reactant!, options!.reactants!);
            if (well) {
                newPlate.wells[index] = well;
                modified = true;
            }
        } else if (action === 'remove') {
            well = removeFromWell(well, options!.reactant!);
            if (well) {
                newPlate.wells[index] = well;
                modified = true;
            }
        } else if (action === 'replace') {
            if (!options?.replaceMap) return;

            const newWell: string[] = [];
            let changed = false;
            for (const r of well) {
                const t = options.replaceMap.get(r);
                if (t === null) {
                    changed = true;
                } else if (typeof t === 'string') {
                    changed = true;
                    newWell.push(t);
                } else {
                    newWell.push(r);
                }
            }
            if (changed) {
                newPlate.wells[index] = newWell;
                modified = true;
            }
        }
    });

    if (!modified) return undefined;
    return newPlate;
}

export function fillWells(
    plate: Plate,
    selection: WellSelection,
    reactants: Reactant[],
    direction: 'row' | 'col',
    reactantMap: ReadonlyMap<string, Reactant>
) {
    if (isWellSelectionEmpty(selection)) return undefined;

    const selectedIndices = new Set<number>();
    forEachWellIndex(selection, (i) => selectedIndices.add(i));

    const newPlate: Plate = { ...plate, wells: [...plate.wells] };
    let rI = 0;
    let modified = false;

    const [w, h] = PlateDimensions[plate.layout];

    if (direction === 'col') {
        for (let i = 0; i < h; i++) {
            for (let j = 0; j < w; j++) {
                const o = i * w + j;
                let well = newPlate.wells[o];
                if (selectedIndices.has(o) && well) {
                    const r = reactants[rI++];
                    well = addToWell(well, r, reactantMap);
                    if (well) {
                        newPlate.wells[o] = well;
                        modified = true;
                    }
                }
                if (rI >= reactants.length) break;
            }
            if (rI >= reactants.length) break;
        }
    } else {
        for (let j = 0; j < w; j++) {
            for (let i = 0; i < h; i++) {
                const o = i * w + j;
                let well = newPlate.wells[o];
                if (selectedIndices.has(o) && well) {
                    const r = reactants[rI++];
                    well = addToWell(well, r, reactantMap);
                    if (well) {
                        newPlate.wells[o] = well;
                        modified = true;
                    }
                }
                if (rI >= reactants.length) break;
            }
            if (rI >= reactants.length) break;
        }
    }

    if (!modified) return undefined;
    return newPlate;
}

export function plateRowLabel(row: number) {
    let r = row;
    let ret = '';
    const a = 'A'.charCodeAt(0);
    const z = 'Z'.charCodeAt(0);
    const delta = z - a + 1;
    while (r >= 0) {
        const v = r % delta;
        ret = String.fromCharCode(a + v) + ret;
        r -= v + delta;
    }
    return ret;
}

// matches code in Foundry for converting well locations to numbers
// _text_to_number converts A to 1, AA to 26, AAA to 676
const ALPHA = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const ALPHA_INDEX: Record<string, number> = {};
ALPHA.split('').forEach((c, i) => {
    ALPHA_INDEX[c] = i + 1;
});
const NALPHA = ALPHA.length;
export function plateRowLabelToIndex(label: string): number {
    if (!label) throw Error('Index can not be an empty string');

    let result = 0;
    const capitalized = label.toUpperCase();
    for (const char of capitalized) {
        result = result * NALPHA + ALPHA_INDEX[char];
    }
    return result;
}

export function getWellIndexLabel(layout: WellLayout, index: number) {
    if (index < 0) return '';
    const [w] = PlateDimensions[layout];
    return `${plateRowLabel(Math.floor(index / w))}${(index % w) + 1}`;
}

export function wellLabelToIndex(layout: WellLayout, label: string) {
    const match = label.match(/([a-zA-Z]+)(\d+)/);
    if (!match) throw new Error('Invalid well label');
    const row = plateRowLabelToIndex(match[1]);
    const col = +match[2];
    return getWellIndex(PlateDimensions[layout][0], row - 1, col - 1);
}

export function getPlateSelectionLabel(plate: Plate, selection: WellSelection) {
    if (isWellSelectionEmpty(selection)) return { label: '-', count: 0 };
    let min: number = plate.layout;
    let max = 0;
    let count = 0;

    forEachWellIndex(selection, (index) => {
        min = Math.min(index, min);
        max = Math.max(index, max);
        count++;
    });

    const label =
        min === max
            ? getWellIndexLabel(plate.layout, min)
            : `${getWellIndexLabel(plate.layout, min)}:${getWellIndexLabel(plate.layout, max)}`;

    return { label, count };
}

export function createCheckers(layout: WellLayout, selection: WellSelection, quadrant: number): WellSelection {
    const ret: WellSelection = new Array(layout).fill(0);
    const [w, h] = PlateDimensions[layout];

    const rowOffset = quadrant & 1; // eslint-disable-line
    const colOffset = (quadrant >> 1) & 1; // eslint-disable-line

    for (let i = rowOffset; i < h; i += 2) {
        for (let j = colOffset; j < w; j += 2) {
            const o = i * w + j;
            if (selection[o]) ret[o] = 1;
        }
    }

    return ret;
}

export function getFirstSelectedIndex(selection: WellSelection) {
    for (let i = 0; i < selection.length; i++) {
        if (selection[i]) {
            return i;
        }
    }
    return -1;
}

function replaceWell(src: Well, dest: Well, reactants: ReadonlyMap<string, Reactant>, layer?: WellLayerType) {
    if (!src || !layer) return src;

    const srcOfType: string[] = [];
    for (const identifier of src) {
        const r = reactants.get(identifier)!;
        if (getReactantLayerType(r) === layer) srcOfType.push(identifier);
    }

    if (!dest) {
        return srcOfType;
    }

    const target: Well = [];

    for (const identifier of dest) {
        const r = reactants.get(identifier)!;
        if (getReactantLayerType(r) !== layer) {
            target.push(identifier);
        }
    }
    target.push(...srcOfType);

    return target;
}

export function pastePlateSelection(
    copy: PlateCopy,
    plate: Plate,
    selection: WellSelection,
    reactants: ReadonlyMap<string, Reactant>,
    options?: { layer?: WellLayerType }
) {
    const fstDest = getFirstSelectedIndex(selection);
    const fstSrc = getFirstSelectedIndex(copy.selection);
    if (fstDest < 0 || fstSrc < 0) return undefined;

    const [width, height] = PlateDimensions[plate.layout];

    const [srcRow, srcCol] = getWellCoords(width, fstSrc);
    const [desrRow, destCol] = getWellCoords(width, fstDest);

    const dRow = desrRow - srcRow;
    const dCol = destCol - srcCol;

    const newPlate: Plate = { ...plate, wells: [...plate.wells] };

    const newSelection: WellSelection = new Array(plate.layout).fill(0);

    forEachWellIndex(copy.selection, (srcIndex, row, col) => {
        const tRow = row + dRow;
        const tCol = col + dCol;

        if (tRow >= height || tCol >= width) return;

        const destIndex = tRow * width + tCol;
        newPlate.wells[destIndex] = replaceWell(
            copy.plate.wells[srcIndex],
            plate.wells[destIndex],
            reactants,
            options?.layer
        );
        newSelection[destIndex] = 1;
    });

    return { plate: newPlate, selection: newSelection };
}
