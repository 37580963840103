export const K_BC_384_14c_11pt_dup_3ctrl_30uM_LAYOUT = `ID,Point,Row,Column,IsControl
diluent,0,1,1,True
1,1,1,2,False
1,2,1,3,False
1,3,1,4,False
1,4,1,5,False
1,5,1,6,False
1,6,1,7,False
1,7,1,8,False
1,8,1,9,False
1,9,1,10,False
1,10,1,11,False
1,11,1,12,False
1,1,1,13,False
1,2,1,14,False
1,3,1,15,False
1,4,1,16,False
1,5,1,17,False
1,6,1,18,False
1,7,1,19,False
1,8,1,20,False
1,9,1,21,False
1,10,1,22,False
1,11,1,23,False
Control1,2,1,24,True
diluent,0,2,1,True
2,1,2,2,False
2,2,2,3,False
2,3,2,4,False
2,4,2,5,False
2,5,2,6,False
2,6,2,7,False
2,7,2,8,False
2,8,2,9,False
2,9,2,10,False
2,10,2,11,False
2,11,2,12,False
2,1,2,13,False
2,2,2,14,False
2,3,2,15,False
2,4,2,16,False
2,5,2,17,False
2,6,2,18,False
2,7,2,19,False
2,8,2,20,False
2,9,2,21,False
2,10,2,22,False
2,11,2,23,False
Control1,2,2,24,True
diluent,0,3,1,True
3,1,3,2,False
3,2,3,3,False
3,3,3,4,False
3,4,3,5,False
3,5,3,6,False
3,6,3,7,False
3,7,3,8,False
3,8,3,9,False
3,9,3,10,False
3,10,3,11,False
3,11,3,12,False
3,1,3,13,False
3,2,3,14,False
3,3,3,15,False
3,4,3,16,False
3,5,3,17,False
3,6,3,18,False
3,7,3,19,False
3,8,3,20,False
3,9,3,21,False
3,10,3,22,False
3,11,3,23,False
Control1,2,3,24,True
diluent,0,4,1,True
4,1,4,2,False
4,2,4,3,False
4,3,4,4,False
4,4,4,5,False
4,5,4,6,False
4,6,4,7,False
4,7,4,8,False
4,8,4,9,False
4,9,4,10,False
4,10,4,11,False
4,11,4,12,False
4,1,4,13,False
4,2,4,14,False
4,3,4,15,False
4,4,4,16,False
4,5,4,17,False
4,6,4,18,False
4,7,4,19,False
4,8,4,20,False
4,9,4,21,False
4,10,4,22,False
4,11,4,23,False
Control1,2,4,24,True
diluent,0,5,1,True
5,1,5,2,False
5,2,5,3,False
5,3,5,4,False
5,4,5,5,False
5,5,5,6,False
5,6,5,7,False
5,7,5,8,False
5,8,5,9,False
5,9,5,10,False
5,10,5,11,False
5,11,5,12,False
5,1,5,13,False
5,2,5,14,False
5,3,5,15,False
5,4,5,16,False
5,5,5,17,False
5,6,5,18,False
5,7,5,19,False
5,8,5,20,False
5,9,5,21,False
5,10,5,22,False
5,11,5,23,False
Control1,2,5,24,True
diluent,0,6,1,True
6,1,6,2,False
6,2,6,3,False
6,3,6,4,False
6,4,6,5,False
6,5,6,6,False
6,6,6,7,False
6,7,6,8,False
6,8,6,9,False
6,9,6,10,False
6,10,6,11,False
6,11,6,12,False
6,1,6,13,False
6,2,6,14,False
6,3,6,15,False
6,4,6,16,False
6,5,6,17,False
6,6,6,18,False
6,7,6,19,False
6,8,6,20,False
6,9,6,21,False
6,10,6,22,False
6,11,6,23,False
Control1,2,6,24,True
diluent,0,7,1,True
7,1,7,2,False
7,2,7,3,False
7,3,7,4,False
7,4,7,5,False
7,5,7,6,False
7,6,7,7,False
7,7,7,8,False
7,8,7,9,False
7,9,7,10,False
7,10,7,11,False
7,11,7,12,False
7,1,7,13,False
7,2,7,14,False
7,3,7,15,False
7,4,7,16,False
7,5,7,17,False
7,6,7,18,False
7,7,7,19,False
7,8,7,20,False
7,9,7,21,False
7,10,7,22,False
7,11,7,23,False
Control1,2,7,24,True
diluent,0,8,1,True
8,1,8,2,False
8,2,8,3,False
8,3,8,4,False
8,4,8,5,False
8,5,8,6,False
8,6,8,7,False
8,7,8,8,False
8,8,8,9,False
8,9,8,10,False
8,10,8,11,False
8,11,8,12,False
8,1,8,13,False
8,2,8,14,False
8,3,8,15,False
8,4,8,16,False
8,5,8,17,False
8,6,8,18,False
8,7,8,19,False
8,8,8,20,False
8,9,8,21,False
8,10,8,22,False
8,11,8,23,False
Control1,2,8,24,True
Control1,2,9,1,True
9,1,9,2,False
9,2,9,3,False
9,3,9,4,False
9,4,9,5,False
9,5,9,6,False
9,6,9,7,False
9,7,9,8,False
9,8,9,9,False
9,9,9,10,False
9,10,9,11,False
9,11,9,12,False
9,1,9,13,False
9,2,9,14,False
9,3,9,15,False
9,4,9,16,False
9,5,9,17,False
9,6,9,18,False
9,7,9,19,False
9,8,9,20,False
9,9,9,21,False
9,10,9,22,False
9,11,9,23,False
diluent,0,9,24,True
Control1,2,10,1,True
10,1,10,2,False
10,2,10,3,False
10,3,10,4,False
10,4,10,5,False
10,5,10,6,False
10,6,10,7,False
10,7,10,8,False
10,8,10,9,False
10,9,10,10,False
10,10,10,11,False
10,11,10,12,False
10,1,10,13,False
10,2,10,14,False
10,3,10,15,False
10,4,10,16,False
10,5,10,17,False
10,6,10,18,False
10,7,10,19,False
10,8,10,20,False
10,9,10,21,False
10,10,10,22,False
10,11,10,23,False
diluent,0,10,24,True
Control1,2,11,1,True
11,1,11,2,False
11,2,11,3,False
11,3,11,4,False
11,4,11,5,False
11,5,11,6,False
11,6,11,7,False
11,7,11,8,False
11,8,11,9,False
11,9,11,10,False
11,10,11,11,False
11,11,11,12,False
11,1,11,13,False
11,2,11,14,False
11,3,11,15,False
11,4,11,16,False
11,5,11,17,False
11,6,11,18,False
11,7,11,19,False
11,8,11,20,False
11,9,11,21,False
11,10,11,22,False
11,11,11,23,False
diluent,0,11,24,True
Control1,2,12,1,True
12,1,12,2,False
12,2,12,3,False
12,3,12,4,False
12,4,12,5,False
12,5,12,6,False
12,6,12,7,False
12,7,12,8,False
12,8,12,9,False
12,9,12,10,False
12,10,12,11,False
12,11,12,12,False
12,1,12,13,False
12,2,12,14,False
12,3,12,15,False
12,4,12,16,False
12,5,12,17,False
12,6,12,18,False
12,7,12,19,False
12,8,12,20,False
12,9,12,21,False
12,10,12,22,False
12,11,12,23,False
diluent,0,12,24,True
Control1,2,13,1,True
13,1,13,2,False
13,2,13,3,False
13,3,13,4,False
13,4,13,5,False
13,5,13,6,False
13,6,13,7,False
13,7,13,8,False
13,8,13,9,False
13,9,13,10,False
13,10,13,11,False
13,11,13,12,False
13,1,13,13,False
13,2,13,14,False
13,3,13,15,False
13,4,13,16,False
13,5,13,17,False
13,6,13,18,False
13,7,13,19,False
13,8,13,20,False
13,9,13,21,False
13,10,13,22,False
13,11,13,23,False
diluent,0,13,24,True
Control1,2,14,1,True
14,1,14,2,False
14,2,14,3,False
14,3,14,4,False
14,4,14,5,False
14,5,14,6,False
14,6,14,7,False
14,7,14,8,False
14,8,14,9,False
14,9,14,10,False
14,10,14,11,False
14,11,14,12,False
14,1,14,13,False
14,2,14,14,False
14,3,14,15,False
14,4,14,16,False
14,5,14,17,False
14,6,14,18,False
14,7,14,19,False
14,8,14,20,False
14,9,14,21,False
14,10,14,22,False
14,11,14,23,False
diluent,0,14,24,True
Control1,2,15,1,True
Control2,1,15,2,True
Control2,2,15,3,True
Control2,3,15,4,True
Control2,4,15,5,True
Control2,5,15,6,True
Control2,6,15,7,True
Control2,7,15,8,True
Control2,8,15,9,True
Control1,9,15,10,True
Control1,10,15,11,True
Control1,11,15,12,True
Control2,1,15,13,True
Control2,2,15,14,True
Control2,3,15,15,True
Control2,4,15,16,True
Control2,5,15,17,True
Control2,6,15,18,True
Control2,7,15,19,True
Control2,8,15,20,True
Control1,9,15,21,True
Control1,10,15,22,True
Control1,11,15,23,True
diluent,0,15,24,True
Control1,2,16,1,True
Control3,1,16,2,True
Control3,2,16,3,True
Control3,3,16,4,True
Control3,4,16,5,True
Control3,5,16,6,True
Control3,6,16,7,True
Control3,7,16,8,True
Control3,8,16,9,True
Control2,9,16,10,True
Control2,10,16,11,True
Control2,11,16,12,True
Control3,1,16,13,True
Control3,2,16,14,True
Control3,3,16,15,True
Control3,4,16,16,True
Control3,5,16,17,True
Control3,6,16,18,True
Control3,7,16,19,True
Control3,8,16,20,True
Control2,9,16,21,True
Control2,10,16,22,True
Control2,11,16,23,True
diluent,0,16,24,True
`;
