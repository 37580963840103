import log from 'loglevel';
import { BehaviorSubject } from 'rxjs';
import api from '../../api';
import { AuthService } from '../services/auth';
import { ToastService } from '../services/toast';
import { isNotAuthorizedError } from './errors';

export interface DrawSmilesInput {
    smiles: string;
    autosize?: boolean;
    atom_highlights?: number[][];
    color_mode?: 'light' | 'dark';
    template_smiles?: string;
    as_image?: boolean | [number, number];
}

export interface DrawResult {
    kind: 'result' | 'error';
    content: string;
    image?: HTMLImageElement;
}

const DrawAPI = {
    smilesOrReactions: async (inputs: DrawSmilesInput[]): Promise<DrawResult[]> => {
        const { data } = await api.client.post('draw/smiles', { inputs });
        return data;
    },
};

/**
 * Draw a list of smiles. If the drawing fails (e.g. due to unparsable SMILES)
 * it will be missing from the result
 */
async function drawMoleculeListSvg(
    inputs: [key: string, input: DrawSmilesInput][],
    target: Record<string, DrawResult | undefined>
) {
    const toDraw: [string, DrawSmilesInput][] = [];
    for (const input of inputs) {
        if (!target[input[0]]) {
            toDraw.push(input);
        }
    }

    if (toDraw.length > 0) {
        const drawings = await DrawAPI.smilesOrReactions(toDraw.map((e) => e[1]));
        for (let i = 0; i < toDraw.length; i++) {
            if (drawings[i]) {
                const asImage = toDraw[i][1].as_image;
                if (asImage && drawings[i].kind === 'result') {
                    const withDimensions = Array.isArray(asImage);
                    try {
                        const width = withDimensions ? asImage[0] : undefined;
                        const height = withDimensions ? asImage[1] : undefined;
                        let img: HTMLImageElement;
                        if (typeof width === 'number' && typeof height === 'number') {
                            img = new Image(width, height);
                        } else {
                            img = new Image();
                        }
                        // eslint-disable-next-line no-await-in-loop
                        await new Promise((resolve, reject) => {
                            img.onload = resolve;
                            img.onerror = reject;
                            img.src = drawings[i].content;
                        });
                        // eslint-disable-next-line no-param-reassign
                        target[toDraw[i][0]] = { ...drawings[i], image: img };
                    } catch (e) {
                        log.error(e);
                    }
                } else {
                    // eslint-disable-next-line no-param-reassign
                    target[toDraw[i][0]] = drawings[i];
                }
            }
        }
    }
}

const _emptyHighlights: number[][] = [];
function getCacheKey({ smiles, autosize, atom_highlights, template_smiles }: DrawSmilesInput) {
    return `${smiles}|${!!autosize}|${(atom_highlights ?? _emptyHighlights).map((h) => h.join(',')).join(';')}|${
        template_smiles || '<no-templ>'
    }`;
}

export class AsyncMoleculeDrawer {
    private currentRequests = new Map<string, [input: DrawSmilesInput, refCount: number]>();

    private cache: Record<string, DrawResult | undefined> = {};
    private syncHandle: any = undefined;

    readonly version = new BehaviorSubject<number>(0);

    tryGetDrawing(input: DrawSmilesInput) {
        return this.cache[getCacheKey(input)];
    }

    request(input: DrawSmilesInput, timeout = 100) {
        const key = getCacheKey(input);
        if (this.cache[key]) return false;

        // No need to reset sync timeout if the request is already present
        if (this.currentRequests.has(key)) {
            this.currentRequests.get(key)![1]++;
            return true;
        }

        this.currentRequests.set(key, [input, 1]);
        if (this.syncHandle !== undefined) {
            clearTimeout(this.syncHandle);
        }
        this.syncHandle = setTimeout(this.sync, timeout);
        return true;
    }

    cancelRequest(input: DrawSmilesInput) {
        const key = getCacheKey(input);
        const current = this.currentRequests.get(key);
        if (current && current[1] > 1) {
            this.currentRequests.get(key)![1]++;
        } else {
            this.currentRequests.delete(getCacheKey(input));
        }
    }

    private sync = async () => {
        this.syncHandle = undefined;

        if (!AuthService.isAuthenticated) {
            this.syncHandle = setTimeout(this.sync, 2500);
            return;
        }

        if (this.currentRequests.size > 0) {
            const inputs = Array.from(this.currentRequests.entries());
            this.currentRequests.clear();
            try {
                await drawMoleculeListSvg(
                    inputs.map((inp) => [inp[0], inp[1][0]]),
                    this.cache
                );
                this.version.next(this.version.value + 1);
            } catch (e) {
                if (!isNotAuthorizedError(e)) {
                    log.error(e);
                    ToastService.show({
                        id: '__draw_molecules_error__',
                        type: 'danger',
                        message: 'Failed to draw structure(s)',
                    });
                } else {
                    for (const [k, v] of inputs) {
                        this.currentRequests.set(k, v);
                    }
                    if (!this.syncHandle) {
                        this.syncHandle = setTimeout(this.sync, 2500);
                    }
                }
            }
        }
    };
}
