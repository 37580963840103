import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';
import { BehaviorSubject, Subscription, skip, throttleTime } from 'rxjs';
import { AsyncButton } from '../../components/common/AsyncButton';
import { LabeledInput, SimpleSelectOptionInput, TextInput } from '../../components/common/Inputs';
import useBehavior from '../../lib/hooks/useBehavior';
import { DialogService } from '../../lib/services/dialog';
import { ToastService } from '../../lib/services/toast';
import { AsyncMoleculeDrawer } from '../../lib/util/draw-molecules';
import { reportErrorAsToast } from '../../lib/util/errors';
import { ModelAction, ReactiveModel, useModelAction } from '../../lib/util/reactive-model';
import { HTEApi } from '../HTE/experiment-api';
import { HTEExperimentInfo } from '../HTE/experiment-data';
import { HTE2Api } from './api';
import { HTEDAssets } from './assets';
import { HTEDesign, HTEWizardData, HTEWizardOptions, HTEWizardUserState } from './data-model';
import { HTEDesignModel } from './design';
import { HTEMosquitoExecutionModel } from './execution/mosquito';
import { HTEMosquitoProtocolModel } from './protocol/mosquito';
import { AuthService } from '../../lib/services/auth';
import { AsyncQueue } from '../../lib/util/async-queue';
import { HTEFinalizeModel } from './finalize';
import { arrayEllipsis } from '../../lib/util/misc';

export type HTEWTab = 'design' | 'inventory' | 'execution' | 'finalize';

export type HTEDataSource =
    | { kind: 'foundry'; info: HTEExperimentInfo; userState?: HTEWizardUserState; version: number }
    | { kind: 'draft'; id: string; userState?: HTEWizardUserState }
    | { kind: 'new' };

const CheckStateInterval = 10000;

interface SaveOptions {
    editedOptions?: HTEWizardOptions;
    updateStatus?: HTEWizardUserState['status'] | 'unset';
}

export class HTEWModel extends ReactiveModel {
    drawer = new AsyncMoleculeDrawer();
    assets = new HTEDAssets();

    state = {
        design: new BehaviorSubject<HTEDesignModel>(undefined as any),
        protocol: new BehaviorSubject<HTEMosquitoProtocolModel | undefined>(undefined),
        execution: new BehaviorSubject<HTEMosquitoExecutionModel | undefined>(undefined),
        dataSource: new BehaviorSubject<HTEDataSource>({ kind: 'new' }),
        options: new BehaviorSubject<HTEWizardOptions | undefined>(undefined),
        tab: new BehaviorSubject<HTEWTab>('design'),
        isEditing: new BehaviorSubject<boolean>(false),
    };

    finalization = new HTEFinalizeModel(this);

    get dataSource() {
        return this.state.dataSource.value;
    }

    actions = {
        submit: new ModelAction({ onError: 'toast' }),
        save: new ModelAction({ onError: 'toast', toastErrorLabel: 'Save', errorToastOptions: { id: 'hte-save' } }),
    };

    get foundryInfo() {
        return this.dataSource.kind === 'foundry' ? this.dataSource.info : undefined;
    }

    get hteId() {
        return `HTE${this.foundryInfo?.id ?? ''}`;
    }

    get foundryStateVersion() {
        return this.dataSource.kind === 'foundry' ? this.dataSource.version : undefined;
    }

    get userState() {
        return this.dataSource.kind !== 'new' ? this.dataSource.userState : undefined;
    }

    get inProgress() {
        if (
            this.dataSource.kind === 'foundry' &&
            (this.dataSource.info.locked_on || this.dataSource.info.status !== 'Planning')
        )
            return false;
        return this.userState?.status === 'in-progress';
    }

    get canEdit() {
        return !this.isLocked && !this.needsFinalization;
    }

    get isLocked() {
        return !!this.foundryInfo?.locked_on;
    }

    get isSubmitting() {
        return this.actions.submit.state.value.kind === 'loading';
    }

    get design() {
        return this.state.design.value;
    }

    get isSaving() {
        return this.actions.save.state.value.kind === 'loading';
    }

    get saveEnabled() {
        return !this.isLocked && this.userState?.status !== 'needs-finalization';
    }

    get needsFinalization() {
        return !this.foundryInfo?.finalization && !this.isLocked && this.userState?.status === 'needs-finalization';
    }

    mount() {
        let executionAutoSave: Subscription | undefined;
        this.subscribe(this.state.execution, (execution) => {
            executionAutoSave?.unsubscribe();
            executionAutoSave = execution?.state.data
                ?.pipe(skip(1), throttleTime(1000, undefined, { leading: false, trailing: true }))
                .subscribe(() => this.autoSaveExecution());
        });
    }

    private scheduleCheckState() {
        clearInterval(this.checkStateTimeout);
        this.checkStateTimeout = setTimeout(this.checkState, CheckStateInterval);
    }

    private get checkStateReschedule() {
        const { foundryInfo } = this;

        return (
            this.isSaving ||
            !AuthService.isAuthenticated ||
            !foundryInfo ||
            this.isLocked ||
            this.isSubmitting ||
            this.userState?.status === 'needs-finalization'
        );
    }

    private checkStateTimeout: any = undefined;
    private isOutdated = false;
    checkState = async () => {
        if (this.isOutdated) return;

        const { foundryInfo } = this;
        if (this.checkStateReschedule || !foundryInfo) {
            this.scheduleCheckState();
            return;
        }

        const version = this.foundryStateVersion;

        try {
            const ok = await HTEApi.checkDataState(foundryInfo.id, {
                last_modified_on: foundryInfo.modified_on,
            });
            if (this.checkStateReschedule || version !== this.foundryStateVersion) {
                this.scheduleCheckState();
                return;
            }

            if (!ok) {
                this.isOutdated = true;
                ToastService.show({
                    id: '__check_hte_data_state__',
                    type: 'warning',
                    message:
                        'Page refresh required, application state out of date.\nSomeone else might have modified it or you have multiple tabs open.',
                    timeoutMs: false,
                });
            } else {
                this.scheduleCheckState();
            }
        } catch (e) {
            reportErrorAsToast('Check State', e);
            this.scheduleCheckState();
        }
    };

    private foundryInfoToOptions(info: HTEExperimentInfo): HTEWizardOptions {
        return {
            name: info.name,
            project: info.project,
            hidden: info.hidden,
            description: info.description,
        };
    }

    private saveQueue = new AsyncQueue({ singleItem: true });

    autoSaveExecution() {
        const src = this.dataSource;
        if (this.isSaving || !AuthService.isAuthenticated || src.kind !== 'foundry' || !this.inProgress) return;
        return this.saveQueue.execute(() =>
            this.actions.save.run(this._autoSaveExecution(), { toastErrorLabel: 'Auto-save' })
        );
    }

    private async _autoSaveExecution() {
        const currentInfo = this.dataSource.kind === 'foundry' ? this.dataSource.info : undefined;
        const execution = this.state.execution.value?.data;
        if (!currentInfo || !execution || this.isLocked) return;

        const info = await HTE2Api.saveExecution(currentInfo, execution);
        this.updateInfo(info, this.userState);

        ToastService.show({
            type: 'success',
            message: 'Saved',
            timeoutMs: 1500,
        });
    }

    updateInfo(info: HTEExperimentInfo, userState?: HTEWizardUserState) {
        const version = this.dataSource.kind === 'foundry' ? this.dataSource.version + 1 : 0;
        this.state.dataSource.next({ kind: 'foundry', info, version, userState: userState ?? this.userState });
        this.state.options.next(this.foundryInfoToOptions(info));
    }

    save(options: SaveOptions = {}) {
        return this.saveQueue.execute(() => this._save(options));
    }

    private async _save({ editedOptions, updateStatus }: SaveOptions) {
        if (this.isLocked) {
            throw new Error('Locked experiments can no longer be saved from this UI');
        }

        const options = { ...this.state.options.value, ...editedOptions };
        if (!options?.name || !options?.project) {
            throw new Error('Name and project must be set');
        }
        const currentInfo = this.dataSource.kind === 'foundry' ? this.dataSource.info : undefined;
        const userState = this.dataSource.kind !== 'new' ? this.dataSource.userState : undefined;
        const user_state: HTEWizardUserState = {
            ...userState,
            status: updateStatus === 'unset' ? undefined : updateStatus ?? userState?.status,
        };

        const info = await HTE2Api.save(currentInfo, {
            options,
            design: this.design.getDesign(),
            protocol: this.state.protocol.value?.protocolData,
            execution: this.state.execution.value?.data,
            user_state,
        });
        this.updateInfo(info, user_state);

        ToastService.show({
            type: 'success',
            message: currentInfo ? 'Saved' : `Saved as HTE${info.id}`,
            timeoutMs: currentInfo ? 1500 : 5000,
        });
    }

    private syncIdentifiers(design: HTEDesign) {
        const identifiers = new Set<string>();
        for (const r of design.reactions) {
            for (const ri of Object.values(r.template.reactants)) {
                identifiers.add(ri.identifier);
            }
        }
        for (const pb of design.product_blocks) {
            for (const rl of pb.reactant_lists) {
                for (const ri of rl.instances) {
                    identifiers.add(ri.identifier);
                }
            }
            if (pb.enumeration) {
                for (const p of pb.enumeration.products) {
                    identifiers.add(p[1]);
                }
            }
        }
        return this.assets.entities.syncIdentifiers(Array.from(identifiers));
    }

    async init(data: HTEWizardData, options?: { info?: HTEExperimentInfo; draftId?: string }) {
        await this.assets.init();
        await this.syncIdentifiers(data.design);
        if (data.protocol) {
            await this.syncIdentifiers(data.protocol.design);
        }

        // must be set before creating a design model
        if (options?.info) {
            this.updateInfo(options.info, data.user_state);
        } else {
            if (options?.draftId) {
                this.state.dataSource.next({ kind: 'draft', id: options.draftId, userState: data.user_state });
            } else {
                this.state.dataSource.next({ kind: 'new' });
            }

            this.state.options.next(data.options);
        }

        this.state.design.next(new HTEDesignModel(this, { design: data.design }));

        if (data.protocol) {
            this.state.protocol.next(new HTEMosquitoProtocolModel(this, { protocol: data.protocol }));
            this.state.execution.next(
                new HTEMosquitoExecutionModel(this, { protocol: data.protocol, execution: data.execution })
            );
        } else {
            this.state.protocol.next(undefined);
            this.state.execution.next(undefined);
        }

        if (this.inProgress) {
            this.state.tab.next('execution');
        } else if (this.userState?.status === 'needs-finalization' && !this.foundryInfo?.finalization) {
            this.state.tab.next('finalize');
        } else {
            this.state.tab.next('design');
        }
        this.state.isEditing.next(!data.protocol);

        this.scheduleCheckState();
    }

    dispose() {
        clearInterval(this.checkStateTimeout);
        super.dispose();
    }

    useAsTemplate() {
        this.state.execution.next(undefined);
        this.state.dataSource.next({ kind: 'new' });
        this.state.options.next({ ...this.state.options.value, name: undefined, hidden: false });

        ToastService.show({
            type: 'warning',
            // TODO: iterate on UX for the the batches in enumeration if reusing a template
            message:
                'Converted to Template\nIf the enumeration contains batch identifiers, a different one should be used instead.',
            timeoutMs: 10000,
        });
    }

    async loadExample() {
        try {
            const protocol = await HTE2Api.example();
            await this.init({ design: protocol.design, protocol });
        } catch (e) {
            reportErrorAsToast('Load Example', e);
        }
    }

    edit(isEditing = true) {
        const protocol = this.state.protocol.value?.protocolData;
        if (!protocol) return;

        this.state.design.next(new HTEDesignModel(this, { design: protocol.design }));
        this.state.isEditing.next(isEditing);
    }

    async build() {
        try {
            const previousProcedure = this.state.protocol.value?.state.procedure.value;
            const protocol = await HTE2Api.build(this.design.getDesign());
            await this.syncIdentifiers(protocol.design);
            if (!protocol.procedure && previousProcedure) {
                protocol.procedure = previousProcedure;
            }

            this.state.protocol.next(new HTEMosquitoProtocolModel(this, { protocol }));
            this.state.execution.next(new HTEMosquitoExecutionModel(this, { protocol }));
            this.state.isEditing.next(false);
            ToastService.show({
                type: 'success',
                message: 'Design built',
                timeoutMs: 1500,
            });
        } catch (e) {
            reportErrorAsToast('Build', e);
        }
    }

    checkCanSubmit() {
        const { dataSource } = this;
        let error: string = '';
        if (!this.state.protocol.value) {
            error = 'No protocol to submit';
        }
        if (dataSource.kind !== 'foundry') {
            error = 'Experiment must be saved to Foundry before submitting for execution';
        } else if (dataSource.info.locked_on) {
            error = 'Experiment is locked';
        } else if (this.inProgress) {
            error = 'Experiment has already been submitted';
        }

        const unassigned = this.state.execution.value?.inventory.getUnassignedItems();
        if (unassigned?.length) {
            error = `All inventory entries must be either batches or have a vial barcode assigned.\nPlease assign barcodes for ${arrayEllipsis(
                unassigned.map((i) => i.identifier),
                { maxLength: 3 }
            )}`;
        }

        if (error) {
            ToastService.show({
                id: 'checkCanSubmit',
                type: 'warning',
                message: error,
                timeoutMs: 5000,
            });
            return false;
        }
        return true;
    }

    async makeEditable() {
        await this.actions.save.run(this.save({ updateStatus: 'unset' }), { onError: 'eat', rethrowError: true });
        ToastService.show({
            type: 'success',
            message: 'Experiment editable.\nAfter making the required changes, please submit it for execution again.',
            timeoutMs: 10000,
        });
    }

    async submitForExecution() {
        await this.actions.save.run(this.save({ updateStatus: 'in-progress' }), { onError: 'eat', rethrowError: true });
        ToastService.show({
            type: 'success',
            message:
                'Protocol submitted for execution.\nCompound management will take it from here and notify you when the experiment is ready for finalization.',
            timeoutMs: 10000,
        });

        try {
            await HTE2Api.notifySlack(this.foundryInfo!, 'submit');
        } catch (e) {
            reportErrorAsToast('Notify Slack', e);
        }
    }

    async readyToFinalize() {
        await this.save();
        const { experiment: info, user_state } = await HTE2Api.prepareForFinalization(this.foundryInfo!);
        this.updateInfo(info, user_state);

        ToastService.show({
            type: 'success',
            message: 'The experiment is now ready for finalization',
            timeoutMs: 1500,
        });

        try {
            await HTE2Api.notifySlack(this.foundryInfo!, 'finalize');
        } catch (e) {
            reportErrorAsToast('Notify Slack', e);
        }

        this.state.tab.next('finalize');
    }
}

export function saveExperimentDialog(model: HTEWModel, isEdit?: boolean) {
    DialogService.open({
        type: 'generic',
        title: isEdit ? 'Edit Experiment' : 'Save Experiment to Foundry',
        confirmButtonContent: 'Save',
        model,
        defaultState: { ...model.state.options.value },
        wrapOk: true,
        content: SaveDialogContent,
        onOk: (options: HTEWizardOptions) =>
            model.actions.save.run(model.save({ editedOptions: options }), { onError: 'eat', rethrowError: true }),
    });
}

export function SaveExperimentButton({ model }: { model: HTEWModel }) {
    const state = useModelAction(model.actions.save);

    const save = () => {
        if (model.dataSource.kind === 'foundry') model.actions.save.run(model.save());
        else saveExperimentDialog(model);
    };

    return (
        <AsyncButton
            onClick={save}
            icon={faSave}
            title='Save to Foundry'
            state={{ isLoading: state.kind === 'loading' }}
        >
            Save
        </AsyncButton>
    );
}

function SaveDialogContent({
    model,
    stateSubject,
}: {
    model: HTEWModel;
    stateSubject: BehaviorSubject<HTEWizardOptions>;
}) {
    const options = useBehavior(stateSubject);
    const projects = model.assets.projectOptions;

    return (
        <div className='vstack gap-2'>
            <LabeledInput label='Name' labelWidth={160}>
                <TextInput
                    value={options?.name ?? ''}
                    setValue={(v) => stateSubject.next({ ...options, name: v.trim() })}
                />
            </LabeledInput>
            <LabeledInput label='Project' labelWidth={160}>
                <SimpleSelectOptionInput
                    allowEmpty
                    value={options?.project ?? ''}
                    options={projects}
                    setValue={(v) => stateSubject.next({ ...options, project: v })}
                />
            </LabeledInput>
            <LabeledInput label='Description' labelWidth={160} labelAlign='flex-start'>
                <TextInput
                    value={options?.description ?? ''}
                    textarea
                    rows={3}
                    setValue={(v) => stateSubject.next({ ...options, description: v.trim() })}
                />
            </LabeledInput>
            <LabeledInput label='Hidden' labelWidth={160} tooltip='Hide the experiment from the overview list'>
                <Form.Switch
                    checked={!!options?.hidden}
                    onChange={(e) => stateSubject.next({ ...options, hidden: e.target.checked })}
                />
            </LabeledInput>
        </div>
    );
}
