import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { AsyncMoleculeDrawing } from '../../../components/common/AsyncMoleculeDrawing';
import { Option } from '../../../components/common/formatSelectOptionLabel';
import { LabeledInput, SimpleSelectOptionInput, TextInput } from '../../../components/common/Inputs';
import Loading from '../../../components/common/Loading';
import { CustomSelectClassNames, DefaultSelectStyles } from '../../../components/common/selectStyles';
import { SubstructureSearchInput } from '../../../components/common/SubstructureSearch';
import useBehavior from '../../../lib/hooks/useBehavior';
import { tryGetErrorMessage } from '../../../lib/util/errors';
import { DataTableControl, DataTableModel } from '../../../components/DataTable';
import { BatchLink } from '../../ECM/ecm-common';
import {
    CompoundDetailWithFilters,
    propertiesNames,
    PropertyFilter,
    propertyNameToTitle,
} from '../enumeration/enumeration-api';
import { CompoundBatchMap, SimilarCompoundsService } from './similar-compounds';

export function FindSimilarCompoundsModal({ model }: { model: SimilarCompoundsService }) {
    const step = useBehavior(model.state.step);
    const show = useBehavior(model.state.showSimilarCompounds);
    const substructure = useBehavior(model.state.substructure);
    const properties = useBehavior(model.state.properties);
    const { isLoading, error } = useBehavior(model.state.status);
    const table = useBehavior(model.state.results);
    useBehavior(table?.version);

    useEffect(() => {
        model.state.status.next({ isLoading: false, error: undefined });
    }, [show]);

    return (
        <Modal
            backdrop
            centered
            scrollable
            size={step !== 'input' ? 'xl' : undefined}
            show={show}
            onHide={() => model.cancel()}
            contentClassName={step !== 'input' ? 'h-100' : ''}
        >
            <Modal.Header closeButton>
                <h4>Find similar compounds</h4>
                {table && step === 'compounds' && <SelectedInfo table={table} />}
                {table && step === 'batches' && (
                    <span className='text-secondary ms-2'>Select one or more batches for each compound</span>
                )}
            </Modal.Header>
            <Modal.Body>
                {isLoading && <Loading />}
                {!isLoading && <Content model={model} />}
                {error && <p className='text-danger'>{tryGetErrorMessage(error)}</p>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='link' onClick={() => model.cancel()}>
                    Cancel
                </Button>
                {table && step === 'compounds' && (
                    <>
                        <Button variant='link' onClick={() => model.back()}>
                            Back
                        </Button>
                        <Button
                            variant='primary'
                            onClick={() => model.next()}
                            disabled={Object.keys(table.selectedRows).length === 0}
                        >
                            {model.options?.compoundsOnly ? 'Submit' : 'Next'}
                        </Button>
                    </>
                )}
                {step === 'batches' && (
                    <Button variant='primary' onClick={() => model.submit()}>
                        Submit
                    </Button>
                )}
                {step === 'input' && (
                    <Button
                        variant='primary'
                        onClick={() => model.findSimilarCompounds()}
                        disabled={(!substructure && !properties.length) || isLoading}
                    >
                        Submit
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

function SelectedInfo({ table }: { table: DataTableModel<CompoundDetailWithFilters> }) {
    useBehavior(table.version);

    return (
        <div className='text-secondary ms-2'>
            {`${Object.keys(table.selectedRows).length} selected of ${table.rows.length} results`}
        </div>
    );
}

function Content({ model }: { model: SimilarCompoundsService }) {
    const step = useBehavior(model.state.step);
    const substructure = useBehavior(model.state.substructure);
    const { isLoading } = useBehavior(model.state.status);
    const properties = useBehavior(model.state.properties);
    const names = properties.map((p) => p.name);
    const table = useBehavior(model.state.results);

    if (table && step === 'compounds') return <Results table={table} />;
    if (step === 'batches') return <SelectBatchesOrBarcodes model={model} />;

    return (
        <div className='m-auto'>
            <SubstructureSearchInput
                smiles={substructure}
                setSmiles={(smiles: string) => model.state.substructure.next(smiles)}
                disabled={isLoading}
            />
            <LabeledInput label='Add Filter' labelWidth={100} className='mt-2'>
                <SimpleSelectOptionInput
                    allowEmpty
                    value=''
                    size='sm'
                    options={propertiesNames.filter((n) => !names.includes(n)).map((n) => [n, propertyNameToTitle(n)])}
                    setValue={(value: string) =>
                        model.state.properties.next([...properties, { name: value } as PropertyFilter])
                    }
                />
            </LabeledInput>
            {properties.map((p, idx) => (
                <PropertyFilterRow key={p.name} model={model} property={p} idx={idx} />
            ))}
        </div>
    );
}

function PropertyFilterRow({
    model,
    property,
    idx,
}: {
    model: SimilarCompoundsService;
    property: PropertyFilter;
    idx: number;
}) {
    const properties = useBehavior(model.state.properties);

    function removeFilter() {
        model.state.properties.next(properties.filter((p) => p.name !== property.name));
    }

    function setValue(field: keyof PropertyFilter, v: number | undefined) {
        const newState = [...properties];
        // @ts-ignore
        newState[idx][field] = v;
        model.state.properties.next(newState);
    }

    return (
        <div>
            <div className='d-flex align-items-center'>
                <div className='w-25'>{propertyNameToTitle(property.name)}</div>
                <TextInput
                    className='w-25'
                    placeholder='min'
                    size='sm'
                    value={property.min_value}
                    numeric
                    setValue={(v) => setValue('min_value', v)}
                />
                <TextInput
                    className='w-25'
                    placeholder='exact'
                    size='sm'
                    value={property.exact_value}
                    numeric
                    setValue={(v) => setValue('exact_value', v)}
                />
                <TextInput
                    className='w-25'
                    placeholder='max'
                    size='sm'
                    value={property.max_value}
                    numeric
                    setValue={(v) => setValue('max_value', v)}
                />
                <Button variant='link' title='Remove filter' onClick={() => removeFilter()}>
                    <FontAwesomeIcon icon={faTimes} fixedWidth />
                </Button>
            </div>
        </div>
    );
}

function Results({ table }: { table: DataTableModel<CompoundDetailWithFilters> }) {
    useBehavior(table.version);

    return (
        <div>
            <DataTableControl height='flex' table={table} headerSize='sm' rowSelectionMode='multi' />
        </div>
    );
}

function SelectBatchesOrBarcodes({ model }: { model: SimilarCompoundsService }) {
    const compoundSelection = useBehavior(model.state.batchSelection);

    if (!compoundSelection) return null;

    return (
        <div>
            {Object.values(compoundSelection.selectedIndices).map((idx) => (
                <CompoundRow key={idx} model={model} rowIndex={idx} selection={compoundSelection} />
            ))}
        </div>
    );
}

function CompoundRow({
    model,
    rowIndex,
    selection,
}: {
    model: SimilarCompoundsService;
    rowIndex: number;
    selection: CompoundBatchMap;
}) {
    const table = model.state.results.value;
    const compoundId = table?.store.tryGetValue('id', rowIndex);
    const options = useMemo(
        () =>
            typeof compoundId === 'number'
                ? (selection.groupedBatches.get(compoundId) ?? []).map(({ identifier }) => ({
                      label: identifier,
                      value: identifier,
                  }))
                : [],
        [selection, compoundId]
    );

    if (!table || typeof compoundId !== 'number') return null;

    return (
        <div className='border-bottom py-2 d-flex align-items-center'>
            <div>
                <AsyncMoleculeDrawing
                    smiles={table.store.getValue('structure', rowIndex) as any}
                    height={100}
                    width={160}
                    autosize
                />
            </div>
            <div className='mx-4' style={{ width: 150 }}>
                <BatchLink identifier={table.store.tryGetValue('identifier', rowIndex) ?? ''} />
            </div>
            <div>
                <Select
                    options={options as any}
                    placeholder='Optionally select one or more batch identifier(s) to add'
                    classNames={CustomSelectClassNames}
                    styles={DefaultSelectStyles}
                    menuPortalTarget={document.body}
                    menuPosition='fixed'
                    isSearchable={false}
                    isMulti
                    closeMenuOnSelect={false}
                    defaultValue={model.state.selections.value[compoundId]
                        ?.map((identifier) => options.find((o) => o.value === identifier))
                        .filter((o) => !!o)}
                    onChange={(option: unknown) => model.onSelect(compoundId, option as Option[])}
                />
            </div>
        </div>
    );
}
