import api from '../../api';
import { decodeEntosMsgpack } from '../../lib/util/serialization';
import { HTEExperimentInfo } from '../HTE/experiment-data';
import {
    HTEDProductBlock,
    HTEDReactionTemplate,
    HTEDesign,
    HTEEVialRack,
    HTEExecution,
    HTEFinalizationData,
    HTEPRobotProtocolT,
    HTEWizardData,
    HTEWizardUserState,
} from './data-model';

export interface EnumEntry {
    value: string;
    description: string;
}

export const HTE2Api = {
    save: async (info: HTEExperimentInfo | undefined, data: HTEWizardData): Promise<HTEExperimentInfo> => {
        let result;
        if (info) {
            result = await api.client.post(
                `hte/wizard/${info.id}`,
                { data, last_modified_on: info.modified_on },
                {
                    responseType: 'arraybuffer',
                }
            );
        } else {
            result = await api.client.post(
                'hte/wizard',
                { data },
                {
                    responseType: 'arraybuffer',
                }
            );
        }
        return decodeEntosMsgpack(result.data);
    },
    saveExecution: async (info: HTEExperimentInfo, data: HTEExecution): Promise<HTEExperimentInfo> => {
        const result = await api.client.post(
            `hte/wizard/${info.id}/execution`,
            { data, last_modified_on: info.modified_on },
            {
                responseType: 'arraybuffer',
            }
        );
        return decodeEntosMsgpack(result.data);
    },
    notifySlack: async (info: HTEExperimentInfo, kind: 'submit' | 'finalize' | 'done'): Promise<boolean> => {
        const { data } = await api.client.post(
            `hte/wizard/${info.id}/notify-slack`,
            { kind },
            { responseType: 'arraybuffer' }
        );
        return decodeEntosMsgpack(data);
    },
    prepareForFinalization: async (
        info: HTEExperimentInfo
    ): Promise<{ experiment: HTEExperimentInfo; user_state: HTEWizardUserState }> => {
        const { data } = await api.client.post(
            `hte/wizard/${info.id}/prepare-for-finalization`,
            { last_modified_on: info.modified_on },
            { responseType: 'arraybuffer' }
        );
        return decodeEntosMsgpack(data);
    },
    finalize: async (
        info: HTEExperimentInfo,
        options: { finalization: HTEFinalizationData }
    ): Promise<HTEExperimentInfo> => {
        const { data } = await api.client.post(
            `hte/wizard/${info.id}/finalize`,
            { ...options, last_modified_on: info.modified_on },
            { responseType: 'arraybuffer' }
        );
        return decodeEntosMsgpack(data);
    },
    triggerSnapshot: async (info: HTEExperimentInfo): Promise<boolean> => {
        const { data } = await api.client.post(
            `hte/wizard/${info.id}/trigger-snapshot`,
            {},
            { responseType: 'arraybuffer' }
        );
        return decodeEntosMsgpack(data);
    },
    default: async (): Promise<{ design: HTEDesign }> => {
        const { data } = await api.client.get('hte/wizard/default', { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
    assets: async (): Promise<{ atmospheres: EnumEntry[] }> => {
        const { data } = await api.client.get('hte/wizard/assets', { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
    draft: async (id: string): Promise<HTEWizardData> => {
        const { data } = await api.client.get(`hte/wizard/draft/${id}`, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
    example: async (): Promise<HTEPRobotProtocolT> => {
        const { data } = await api.client.get('hte/wizard/example', { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
    build: async (design: HTEDesign): Promise<HTEPRobotProtocolT> => {
        const { data } = await api.client.post('hte/wizard/build', { design }, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
    registerCompounds: async (options: { smiles: string[]; project: string }): Promise<string[]> => {
        const { data } = await api.client.post('hte/wizard/register-compounds', options, {
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data);
    },
    validate: async (options: { kind: 'reaction-template'; data: HTEDReactionTemplate }): Promise<any> => {
        const { data } = await api.client.post('hte/wizard/validate', options, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
    parseRacks: async (file: File): Promise<Record<string, HTEEVialRack>> => {
        const formData = new FormData();
        formData.append('rack', file);
        const { data } = await api.client.post('hte/wizard/parse-rack', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data);
    },
    parseProductBlock: async (file: File): Promise<HTEDProductBlock> => {
        const formData = new FormData();
        formData.append('file', file);
        const { data } = await api.client.post('hte/wizard/parse-product-block', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data);
    },
};
