import { useEffect } from 'react';
import { ReactiveModel } from '../util/reactive-model';
import { useAsyncAction } from './useAsyncAction';
import useMountedModel from './useMountedModel';

export default function useAsyncModel<T extends ReactiveModel, Args extends any[]>(
    create: (..._args: Args) => Promise<T>,
    ...args: Args
) {
    const [state, apply] = useAsyncAction<T>();

    useEffect(() => {
        // eslint-disable-next-line prefer-spread
        apply(create.apply(null, args));
    }, args);

    const model = useMountedModel(state.result);
    return [model, state] as const;
}
