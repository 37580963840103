import { Layout, PlotData } from 'plotly.js-dist';

export interface PlotlyFigure {
    data: Partial<PlotData>[];
    layout: Partial<Layout>;
}

export type PlotlyPointClickEventData = { curveNumber: number; pointIndex: number } | undefined;

export const DefaultFigureLayout: PlotlyFigure['layout'] = {
    autosize: true,
    title: '',
    uirevision: 'true',
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    xaxis: {
        title: '',
        gridcolor: '#444',
        zerolinecolor: '#666',
    },
    yaxis: {
        title: '',
        gridcolor: '#444',
        zerolinecolor: '#666',
    },
};
