import classNames from 'classnames';
import React, { CSSProperties, useCallback, useContext, useRef } from 'react';
import { HeaderGroup, Row } from 'react-table';
import { FixedSizeList, FixedSizeListProps } from 'react-window';
import { RowSelectionMode, ReactTableHeader, ReactTableHeaderSize } from '../../components/ReactTable/Controls';

const StickyHeaderContext = React.createContext<() => React.ReactNode | undefined>(() => undefined);

export function StickyHeaderList({
    renderer,
    header,
    ...rest
}: {
    renderer: (props: any) => React.ReactNode;
    header: () => React.ReactNode;
} & Omit<FixedSizeListProps, 'children'>) {
    return (
        <StickyHeaderContext.Provider value={header}>
            <FixedSizeList {...rest}>{renderer as any}</FixedSizeList>
        </StickyHeaderContext.Provider>
    );
}

export const innerElementType = React.forwardRef<HTMLDivElement, any>(({ children, ...rest }, ref) => {
    const header = useContext(StickyHeaderContext);
    return (
        <>
            {header()}
            <div ref={ref} {...rest}>
                {children}
            </div>
        </>
    );
});

interface TableProps<T extends object> {
    headerGroups: HeaderGroup<T>[];
    headerSize?: ReactTableHeaderSize;
    rows: Row<T>[];
    selectedRows: Record<string | number, boolean>;
    prepareRow: (row: Row<T>) => void;
    showColumnFilters?: boolean;
    rowSelectionMode?: RowSelectionMode;
    onRowDoubleClick?: (e: React.MouseEvent, row: Row<T>) => void;
    onRowMouseEnter?: (row: Row<T>) => void;
    onRowMouseLeave?: (row: Row<T>) => void;
    lineHeight: number;
    height: number;
}

// TODO: find a better way to do this
const FlexCells = new Set([
    'structure',
    'action',
    'group_name',
    'equivalence',
    'concentration',
    'overage',
    'type',
    'reaction',
    'reaction_plated',
    'amount_total',
    'volume_total',
    'solvent',
    'density',
]);

export function Table<T extends object>({
    headerGroups,
    headerSize = 'sm',
    rows,
    selectedRows,
    prepareRow,
    showColumnFilters = false,
    rowSelectionMode = 'none',
    onRowDoubleClick,
    onRowMouseEnter,
    onRowMouseLeave,
    lineHeight,
    height,
}: TableProps<T>) {
    // const onCheck = getToggleCheckedFn(table, rowSelectionMode);
    // these pixel values must correspond with class styles in table.scss: entos-sm-header-row and entos-lg-header-row
    const headerHeight = headerSize === 'lg' ? 80 : 45;
    const tableHeight = height;
    const flexCell: { style: CSSProperties } = {
        style: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: lineHeight },
    };
    const textCell: { style: CSSProperties } = { style: { lineHeight: `${lineHeight}px`, height: lineHeight } };

    const RenderRow = useCallback(
        ({ index, style }: any) => {
            const row = rows[index];
            prepareRow(row);
            const props = row.getRowProps({ style });

            const st = {
                ...props.style,
                minWidth: 'calc(100% - 2rem)',
                top: style.top + headerHeight,
            };
            delete st.width;

            return (
                <div
                    {...props}
                    style={st}
                    className={classNames('entos-dataviz-table-row', {
                        selected: rowSelectionMode === 'single' && !!selectedRows[row.index],
                    })}
                    role='none'
                    onDoubleClick={(e) => onRowDoubleClick?.(e, row)}
                    onMouseEnter={() => onRowMouseEnter?.(row)}
                    onMouseLeave={() => onRowMouseLeave?.(row)}
                >
                    {row.cells.map((cell) => (
                        <div
                            {...cell.getCellProps(FlexCells.has(cell.column.id) ? flexCell : textCell)}
                            className='entos-dataviz-table-cell'
                        >
                            {cell.render('Cell')}
                        </div>
                    ))}
                </div>
            );
        },
        [rows, selectedRows]
    );

    const header = (
        <ReactTableHeader headerGroups={headerGroups} headerSize={headerSize} showColumnFilters={showColumnFilters} />
    );

    const currentHeader = useRef<any>(header);
    currentHeader.current = header;
    const renderHeader = useCallback(() => currentHeader.current, []);

    return (
        <StickyHeaderList
            height={tableHeight}
            innerElementType={innerElementType}
            itemData={header}
            itemCount={rows.length}
            itemSize={lineHeight}
            overscanCount={10}
            renderer={RenderRow}
            header={renderHeader}
            width='100%'
        />
    );
}
