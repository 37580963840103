import { BehaviorSubject } from 'rxjs';
import { memoizeLatest } from '../../../lib/util/misc';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { SampleContents } from '../../Compounds/compound-api';
import {
    HTEDReactionIdT,
    HTEInventory,
    HTEPMessage,
    HTEPReagent,
    HTEPReagentKeyT,
    HTEProtocol,
    UniversalIdentifierT,
} from '../data-model';
import { ReagentInfo, getFinalRequired, getReagentInfo } from '../utils/inventory';
import { WorkflowStatus } from '../utils/workflow-status';
import { HTE2MSLiquidReagentsModel } from './liquid-reagents';
import type { HTE2MSProtocolModel } from './model';
import { HTE2MSDryReagentsModel } from './dry-reagents';
import { HTE2MSSolutionReagentsModel } from './solution-reagents';

export interface ReagentRow extends HTEPReagent {
    batch_identifier?: UniversalIdentifierT;
    worklist_title: string;
    amount_g: number;
    reactant_kinds?: string[];
    source_barcode?: string;
    transfer_barcode?: string;
    requested_sample?: SampleContents;
    validation?: [kind: 'info' | 'danger' | 'warning' | 'success', message: string];
}

export interface ProtocolMessageGroup {
    message: string;
    reactionIds: HTEDReactionIdT[];
    group: HTEPMessage[];
}

export class HTE2MSReagentsModel extends ReactiveModel {
    liquid = new HTE2MSLiquidReagentsModel(this);
    dry = new HTE2MSDryReagentsModel(this);
    solution = new HTE2MSSolutionReagentsModel(this);

    state = {
        status: new BehaviorSubject<WorkflowStatus>('blank'),
        kind: new BehaviorSubject<'dry' | 'liquid' | 'solution'>('dry'),
    };

    getInfo(reagent: HTEPReagent) {
        return this.info.get(reagent);
    }

    private _all: HTEPReagent[] = [];
    get all() {
        return this._all;
    }

    private _byKey = memoizeLatest((reagents: HTEPReagent[]) => new Map(reagents.map((r) => [r.key, r])));
    get byKey() {
        return this._byKey(this.all);
    }

    getByKey(key: HTEPReagentKeyT) {
        return this.byKey.get(key);
    }

    private _instructionIdToUse = memoizeLatest(
        (reagents: HTEPReagent[]) => new Map(reagents.flatMap((r) => r.uses.map((u) => [u.instruction_id, u])))
    );
    get instructionIdToUse() {
        return this._instructionIdToUse(this.all);
    }

    private _finalRequired = memoizeLatest((rows: HTEPReagent[], inventory: HTEInventory) => {
        const ret = new Map<HTEPReagent, { amount_g?: number; volume_l?: number } | undefined>();
        for (const r of rows) {
            const info = this.info.get(r);
            if (!info) continue;
            ret.set(r, getFinalRequired(this.protocol.model, r, inventory, info));
        }
        return ret;
    });
    get finalRequired() {
        return this._finalRequired(this.all, this.inventory);
    }

    get inventory() {
        return this.protocol.model.inventory.data;
    }

    private _reagentInfo = memoizeLatest((rows: HTEPReagent[], inventory: HTEInventory) => {
        const ret = new Map<HTEPReagent, ReagentInfo>();
        for (const r of rows) {
            ret.set(r, this.calculateInfo(r));
        }
        return ret;
    });
    private get info() {
        return this._reagentInfo(this.all, this.inventory);
    }

    private calculateInfo(reagent: HTEPReagent) {
        const addMap = this.protocol.model.design.addInstructionMap;
        const kinds = new Set<string>();
        for (const use of reagent.uses) {
            const add = addMap.get(use.instruction_id);
            if (add) kinds.add(add.reactant_kind);
        }
        const info = getReagentInfo(this.protocol.model, reagent, Array.from(kinds).sort());
        return info;
    }

    update(data: HTEProtocol) {
        this._all = data.reagents;
        this.liquid.update(data);
        this.dry.update(data);
        this.solution.update(data);
        this.syncStatus();

        if (this.dry.all.length === 0 && this.liquid.all.length !== 0) {
            this.state.kind.next('liquid');
        }
    }

    syncStatus() {
        let hasWarning = false;
        let hasInfo = false;

        const liquidValidation = this.liquid.validation;
        for (const liquid of this.liquid.all) {
            const validation = liquidValidation.get(liquid);
            if (!validation) continue;
            const [kind] = validation;

            if (kind === 'danger') {
                this.state.status.next('danger');
                return;
            }
            if (kind === 'warning') {
                hasWarning = true;
            } else if (kind === 'info') {
                hasInfo = true;
            }
        }
        const dryValidation = this.dry.validation;
        for (const dry of this.dry.all) {
            const validation = dryValidation.get(dry);
            if (!validation) continue;
            const [kind] = validation;

            if (kind === 'danger') {
                this.state.status.next('danger');
                return;
            }
            if (kind === 'warning') {
                hasWarning = true;
            } else if (kind === 'info') {
                hasInfo = true;
            }
        }
        if (hasWarning) {
            this.state.status.next('warning');
        } else if (hasInfo) {
            this.state.status.next('info');
        } else if (this.all.length > 0) {
            this.state.status.next('success');
        } else {
            this.state.status.next('blank');
        }
    }

    constructor(public protocol: HTE2MSProtocolModel) {
        super();
    }
}
