import { faGrip } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { HTEDesignModel, HTEDesignTab } from '.';
import { AsyncActionButton } from '../../../components/common/AsyncButton';
import { PillNav, PillNavStep } from '../../../components/common/Nav';
import useBehavior from '../../../lib/hooks/useBehavior';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { HTEWModel } from '../model';
import { ProductBlocks } from './product-blocks-ui';
import { HTEReactionsUI } from './reactions-ui';
import { HTEDSettingsUI } from './settings-ui';

export function HTEDesignUI({ model }: { model: HTEWModel }) {
    const design = useBehavior(model.state.design);
    useMountedModel(design);

    return <Tabs model={design} />;
}

const NavTabs: PillNavStep<HTEDesignTab>[] = [
    { name: 'reactions', label: 'Reactions' },
    { name: 'product-blocks', label: 'Reactants' },
    { name: 'settings', label: 'Settings' },
];

function Tabs({ model }: { model: HTEDesignModel }) {
    const tab = useBehavior(model.state.tab);

    return (
        <div className='d-flex flex-column h-100 ps-2'>
            <div className='hstack gap-2 me-3 pt-2 mb-2'>
                <PillNav steps={NavTabs} currentStep={tab} onSetStep={(s) => model.state.tab.next(s)} />
                <div className='m-auto' />
                {!!model.model.state.protocol.value && (
                    <Button variant='outline-primary' size='sm' onClick={() => model.model.edit(false)}>
                        Cancel
                    </Button>
                )}
                <AsyncActionButton action={() => model.model.build()} variant='primary' size='sm' icon={faGrip}>
                    Build
                </AsyncActionButton>
            </div>
            <div className='flex-grow-1 position-relative'>
                {tab === 'settings' && <HTEDSettingsUI model={model.settings} />}
                {tab === 'product-blocks' && <ProductBlocks model={model.productBlocks} />}
                {tab === 'reactions' && <HTEReactionsUI model={model} />}
            </div>
        </div>
    );
}
