import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, ReactNode, useId, useState } from 'react';
import { Overlay, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { OverlayTriggerRenderProps } from 'react-bootstrap/esm/OverlayTrigger';
import { Placement } from 'react-bootstrap/esm/types';

export function InfoTooltip({
    tooltip,
    placement,
    size,
    className = '',
    buttonClassName,
}: {
    tooltip: string;
    placement?: Placement;
    size?: 'sm' | 'lg';
    className?: string;
    buttonClassName?: string;
}) {
    return (
        <div className='d-inline-flex align-items-center'>
            <OverlayTrigger
                placement={placement ?? 'auto'}
                overlay={<Tooltip>{tooltip}</Tooltip>}
                delay={{ show: 500, hide: 0 }}
            >
                {(props) => (
                    <button type='button' className={`bg-transparent border-0 ${buttonClassName ?? ''}`} {...props}>
                        <FontAwesomeIcon icon={faInfoCircle} fixedWidth size={size} className={className} />
                    </button>
                )}
            </OverlayTrigger>
        </div>
    );
}

export function TooltipWrapper({
    tooltip,
    showDelay = 500,
    placement,
    children,
}: {
    tooltip: ReactNode;
    showDelay?: number;
    placement?: Placement;
    children: (props: OverlayTriggerRenderProps) => ReactNode;
}) {
    return (
        <div className='d-inline-flex align-items-center'>
            <OverlayTrigger
                placement={placement ?? 'auto'}
                overlay={<Tooltip>{tooltip}</Tooltip>}
                delay={{ show: showDelay, hide: 0 }}
            >
                {children}
            </OverlayTrigger>
        </div>
    );
}

export function PopoverWrapper({
    children,
    popoverHeader,
    popoverBody,
    containerPadding,
    inBody,
    placement = 'auto',
    popperConfig = {},
}: {
    children: (show: MouseEventHandler<HTMLElement>) => ReactNode;
    popoverHeader?: ReactNode;
    popoverBody: ReactNode;
    containerPadding?: number;
    inBody?: boolean;
    placement?: Placement;
    popperConfig?: object;
}) {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState<EventTarget | null>(null);
    const id = useId();

    function handleClick(e: MouseEvent) {
        setShow(true);
        setTarget(e.target);
    }

    return (
        <>
            {children(handleClick as any)}
            <Overlay
                show={show}
                target={target as any}
                placement={placement}
                container={inBody ? document.body : undefined}
                containerPadding={containerPadding ?? 20}
                rootClose
                flip
                onHide={() => setShow(false)}
                popperConfig={popperConfig}
            >
                <Popover id={id}>
                    {!!popoverHeader && <Popover.Header>{popoverHeader}</Popover.Header>}
                    <Popover.Body>{popoverBody}</Popover.Body>
                </Popover>
            </Overlay>
        </>
    );
}
