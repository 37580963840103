import { faExclamationCircle, faGun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { PageTemplate } from '../../components/Layout/Layout';
import { AsyncActionButton } from '../../components/common/AsyncButton';
import { ErrorMessage } from '../../components/common/Error';
import { TextInput } from '../../components/common/Inputs';
import Loading from '../../components/common/Loading';
import { useAsyncAction } from '../../lib/hooks/useAsyncAction';
import useBehaviorSubject from '../../lib/hooks/useBehaviorSubject';
import { ToastService } from '../../lib/services/toast';
import { reportErrorAsToast } from '../../lib/util/errors';
import { HTEApi, HTEDesignInfo } from './experiment-api';
import { formatHTEId } from './experiment-data';
import { ScrollBox } from '../../components/common/ScrollBox';

export function HTEGeneralManageUI() {
    const { id } = useParams();
    const status = useBehaviorSubject<ReactNode>('');
    const action = useAsyncAction<HTEDesignInfo>();
    const [design, loadDesign] = action;

    useEffect(() => {
        if (!id) return;
        loadDesign(HTEApi.get(+id));
    }, [id]);

    return (
        <PageTemplate
            title='HTE'
            breadcrumb={[
                { title: 'Manage', href: '#' },
                typeof id === 'string'
                    ? { title: formatHTEId(id as any), href: window.location.pathname }
                    : { title: 'Loading...', href: '#' },
            ]}
        >
            {design.isLoading && <Loading message={status} />}
            {design.error && <ErrorMessage header='Load Error' message={design.error} />}
            {design.result && (
                <ScrollBox>
                    <div className='vstack'>
                        <AssignPurifiedPlateUI design={design.result} />
                        <TriggerSnapshotUI design={design.result} />
                    </div>
                </ScrollBox>
            )}
        </PageTemplate>
    );
}

function AssignPurifiedPlateUI({ design }: { design: HTEDesignInfo }) {
    const [barcode, setBarcode] = useState(design.purified_plate?.barcode ?? '');

    const onClick = async () => {
        try {
            if (barcode.length === 0) return;

            await HTEApi.uploadPurifiedProductPlateBarcode(design.experiment.id, barcode, { unlock_if_locked: true });

            ToastService.show({
                type: 'success',
                message: 'Barcode assigned',
                timeoutMs: 2500,
            });
        } catch (e) {
            reportErrorAsToast('Assign Barcode', e);
        }
    };

    return (
        <div className='vstack gap-2 mx-auto mt-4' style={{ width: 800 }}>
            <h5>Purified Plate Barcode</h5>

            {design.experiment.locked_on && (
                <Alert variant='warning'>
                    <div className='hstack gap-2'>
                        <FontAwesomeIcon icon={faExclamationCircle} className='mx-1' />
                        <span>
                            This experiment is currently locked, and will be temporarily unlocked to assign the barcode.
                        </span>
                    </div>
                </Alert>
            )}

            <div className='hstack gap-2'>
                <TextInput
                    value={barcode}
                    setValue={(v) => setBarcode(v.trim())}
                    validation={barcode.length === 0 ? 'Enter plate barcode' : ''}
                    immediate
                    className='flex-grow-1'
                />
                <AsyncActionButton action={() => onClick()}>Assign</AsyncActionButton>
            </div>
        </div>
    );
}

async function triggerSnapshot(id: number) {
    try {
        await HTEApi.triggerSnapshot(id);

        ToastService.success('Snapshot triggered', { timeoutMs: 5000 });
    } catch (e) {
        reportErrorAsToast('Trigger Snapshot', e);
    }
}

function TriggerSnapshotUI({ design }: { design: HTEDesignInfo }) {
    return (
        <div className='vstack gap-2 mx-auto mt-4' style={{ width: 800 }}>
            <h5>Snapshots</h5>
            <AsyncActionButton action={() => triggerSnapshot(design.experiment.id)} icon={faGun}>
                Trigger Snapshot Workflow
            </AsyncActionButton>
        </div>
    );
}
