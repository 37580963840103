import Select from 'react-select';
import useBehavior from '../../lib/hooks/useBehavior';
import { EcosystemService } from '../../lib/services/ecosystem';
import { CustomSelectClassNames } from './selectStyles';

export function ProjectSelect({
    value,
    setValue,
    placeholder = 'All projects',
    disabled,
}: {
    value: string | undefined;
    setValue: (proj: string | undefined) => any;
    placeholder?: string;
    disabled?: boolean;
}) {
    const projects = useBehavior(EcosystemService.environment)?.projects ?? [];
    const options = projects.map((proj) => ({ value: proj, label: proj }));
    const current = options.find((proj) => proj.value === value);

    return (
        <Select
            options={options as any}
            placeholder={placeholder}
            classNames={CustomSelectClassNames}
            menuPosition='fixed'
            isSearchable
            isClearable
            value={current}
            onChange={(option: any) => setValue(option?.value ?? undefined)}
            isDisabled={disabled}
        />
    );
}
