import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import { InfoTooltip } from '../common/Tooltips';

const DEFAULT_PADDING_INDEX = 3;

interface PaneProps {
    title: ReactNode;
    tooltip?: string;
    onClickSettings?: Function;
    className?: string;
    paneContentClassName?: string;
    headerInfo?: React.ReactNode;
    topRightContent?: React.ReactNode;
    children: React.ReactNode | React.ReactNode[];
    paddingIndex?: number;
    topPaddingIndex?: number;
    contentPaddingIndex?: number;
}

export default function Pane({
    title,
    tooltip,
    headerInfo,
    topRightContent,
    onClickSettings,
    children,
    className = '',
    paneContentClassName = '',
    paddingIndex = DEFAULT_PADDING_INDEX,
    topPaddingIndex,
    contentPaddingIndex,
}: PaneProps) {
    return (
        <PaneInner className={className}>
            <PaneHeader paddingIndex={paddingIndex} topPaddingIndex={topPaddingIndex}>
                {!!title && (
                    <div className='d-flex flex-grow-1'>
                        <PaneTitle title={title} />
                        {tooltip && <InfoTooltip tooltip={tooltip} />}
                        {headerInfo}
                    </div>
                )}
                <div />
                <div className='d-flex'>
                    {topRightContent}
                    {onClickSettings && (
                        <button
                            type='button'
                            className='bg-transparent border-0'
                            title='Settings'
                            onClick={() => onClickSettings()}
                        >
                            <FontAwesomeIcon icon={faCog} fixedWidth />
                        </button>
                    )}
                </div>
            </PaneHeader>
            <PaneContent
                className={paneContentClassName}
                paddingIndex={paddingIndex}
                contentPaddingIndex={contentPaddingIndex}
            >
                {children}
            </PaneContent>
        </PaneInner>
    );
}

export function PaneInner({ className = '', children }: { className?: string; children: ReactNode }) {
    return <div className={`pane-inner ${className}`}>{children}</div>;
}

export function PaneHeader({
    paddingIndex = DEFAULT_PADDING_INDEX,
    topPaddingIndex,
    children,
}: {
    paddingIndex?: number;
    topPaddingIndex?: number;
    children: ReactNode;
}) {
    return (
        <div
            className={`flex-grow-0 d-flex justify-content-between px-${paddingIndex} pt-${
                topPaddingIndex ?? paddingIndex
            }`}
        >
            {children}
        </div>
    );
}

export function PaneTitle({ title }: { title: ReactNode }) {
    return <h6 className='d-flex align-items-center m-0 fw-bold'>{title}</h6>;
}

export function PaneContent({
    className = '',
    paddingIndex = DEFAULT_PADDING_INDEX,
    contentPaddingIndex,
    children,
}: {
    className?: string;
    paddingIndex?: number;
    contentPaddingIndex?: number;
    children: ReactNode;
}) {
    return (
        <div
            className={`pane-content position-relative flex-grow-1 m-${
                contentPaddingIndex ?? paddingIndex
            } mt-${paddingIndex} ${className}`}
        >
            {children}
        </div>
    );
}
