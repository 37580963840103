import { CompoundAssets, VialInventoryAssets } from '../../lib/services/assets';
import { EcosystemService } from '../../lib/services/ecosystem';
import {
    EnumerationAPI,
    HTEEnumerationReactionInfoEntry,
    SandwichEnumerationResult,
} from '../HTE/enumeration/enumeration-api';
import { HTEDReaction } from './data-model';
import { HTE2MSApi, MicroscaleAssets } from './api';
import { ECMApi, Plate, PlateRow } from '../ECM/ecm-api';

export interface HTE2MSReactionChemistryOption {
    label: string;
    value?: HTEEnumerationReactionInfoEntry;
}

export class HTE2MSAssets {
    entities = new CompoundAssets();
    inventory = new VialInventoryAssets(this.entities);

    reactionChemistryOptions: HTE2MSReactionChemistryOption[] = [];
    private reactionChemistryMap: Map<string | undefined, HTE2MSReactionChemistryOption> = new Map();
    projectOptions: [string, string][] = [];

    enumerationCache = new Map<string, SandwichEnumerationResult['enumeration'][number]>();

    atmosphereOptions: [string, string][] = [];
    defaults: MicroscaleAssets = undefined as any;

    plateInfoById = new Map<number, PlateRow>();

    plateDataById = new Map<number, Plate>();
    plateDataByBarcode = new Map<string, Plate>();

    getReactionChemistryOption(id: string) {
        return this.reactionChemistryMap.get(id);
    }

    getStructure(reaction?: HTEDReaction) {
        if (!reaction) return;
        const { product_identifier, product_enumeration } = reaction;
        if (product_identifier) {
            return this.entities.getStructure(product_identifier);
        }
        if (typeof product_enumeration?.substance_id === 'number') {
            return this.entities.substancesById.get(product_enumeration?.substance_id)?.smiles;
        }
    }

    async syncPlateInfo(ids: number[]) {
        const missing = ids.filter((id) => !this.plateInfoById.has(id));
        if (!missing.length) return;
        const plates = await ECMApi.filterPlates({ id: missing });
        for (const plate of plates.toObjects()) this.plateInfoById.set(plate.id, plate);
    }

    async syncPlateData(options: { ids?: number[]; barcodes?: string[] }) {
        const missingIds = options.ids?.length ? options.ids.filter((id) => !this.plateDataById.has(id)) : [];
        const missingBarcodes = options.barcodes?.length
            ? options.barcodes.filter((barcode) => !this.plateDataByBarcode.has(barcode))
            : [];
        if (!missingIds.length && !missingBarcodes.length) return;
        const plates = await ECMApi.queryPlates({ ids: missingIds, barcodes: missingBarcodes });
        const batchIds = new Set<number>();
        for (const plate of plates) {
            this.plateDataById.set(plate.id, plate);
            this.plateDataByBarcode.set(plate.barcode, plate);
            for (const s of plate.samples) {
                if (typeof s?.barcode === 'number') batchIds.add(s.batch_id);
            }
        }

        await this.entities.syncBatchIds(Array.from(batchIds));
    }

    async init() {
        const [reactions, env, hteAssets] = await Promise.all([
            EnumerationAPI.reactionsInfo(),
            EcosystemService.getEnvironment(),
            HTE2MSApi.assets(),
        ]);

        this.reactionChemistryOptions = reactions.map((r) => ({
            label: `${r.name} (${r.kind})`,
            value: r,
        }));
        this.reactionChemistryMap = new Map(this.reactionChemistryOptions.map((r) => [r.value?.id, r]));

        this.projectOptions = env.projects.map((p) => [p, p]);
        this.atmosphereOptions = hteAssets.atmospheres.map((e) => [e.value, `${e.value}: ${e.description}`]);
        this.defaults = hteAssets;
    }
}
