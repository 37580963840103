import Select, { createFilter } from 'react-select';
import { LabeledInput, SimpleSelectOptionInput, TextInput } from '../../../components/common/Inputs';
import { CustomSelectClassNames, DefaultSelectStyles } from '../../../components/common/selectStyles';
import useBehavior from '../../../lib/hooks/useBehavior';
import { roundValue } from '../../../lib/util/roundValues';
import { asNumber, trimValue } from '../../../lib/util/validators';
import { ReactionOption } from '../enumeration/enumeration-model';
import {
    Atmospheres,
    ExperimentSettings,
    PlateDetails,
    WellLayouts,
    PlateInfo,
    formatUnitPrefix,
    formatHTEId,
    formatRPM,
    rpmToRadS,
} from '../experiment-data';
import { HTEExperimentModel, validateDetails, validateSettings } from '../experiment-model';

export function SettingsStep({ model }: { model: HTEExperimentModel }) {
    return (
        <div className='hte-experiment-settings-step mt-3 mb-3'>
            <div className='insight-page-header p-4 m-auto'>
                <h2>
                    Settings
                    <br />
                </h2>
                <span className='text-secondary'>Set the following global options for your experiment</span>
            </div>

            <div className='insight-page-content p-4 m-auto'>
                <PlateDetailsView model={model} />
            </div>
        </div>
    );
}

function PlateDetailsView({ model }: { model: HTEExperimentModel }) {
    const isLocked = useBehavior(model.state.isLocked);
    const details = useBehavior(model.state.details);
    const settings = useBehavior(model.state.settings);

    const setDetail = <K extends keyof PlateDetails>(k: K, v: PlateDetails[K]) => {
        model.state.details.next({ ...details, [k]: v });
    };

    const setSetting = <K extends keyof ExperimentSettings>(k: K, v: ExperimentSettings[K]) => {
        model.state.settings.next({ ...settings, [k]: v });
    };

    const detailsValidation = validateDetails(details);
    const settingsValidation = validateSettings(settings);

    const scale = PlateInfo[details.well_layout];

    const labwareInvalid = !scale.labware[settings.labware];
    const [minVolume, maxVolume] = scale.labware[settings.labware]?.wellVolumeRange ?? [-Infinity, Infinity];

    const LabwareOptions = Object.entries(scale.labware).map(([n, e]) => [n, e.label] as [string, string]);
    const reactionValue: ReactionOption = model.enumeration.reactionOptions.find(
        (r) => r.value?.id === settings.reaction_chemistry
    ) ?? { label: settings.reaction_chemistry, value: undefined };

    return (
        <div className='vstack gap-2'>
            <div className='hte-experiment-form vstack gap-2'>
                <h5>Plate Details</h5>
                {labwareInvalid && (
                    <p className='text-danger'>
                        Labware setting {settings.labware} is not a valid choice for well layout {details.well_layout}.
                    </p>
                )}
                <LabeledInput label='Library Identifier'>{formatHTEId(model.id)}</LabeledInput>
                <LabeledInput label='Project'>{details.project}</LabeledInput>
                <LabeledInput label='Library Name'>
                    <TextInput
                        value={details.library_name}
                        tryUpdateValue={trimValue}
                        setValue={(v) => setDetail('library_name', v)}
                        validation={detailsValidation.library_name}
                        readOnly={isLocked}
                    />
                </LabeledInput>
                <LabeledInput label='Description' className='multiline-row'>
                    <TextInput
                        value={details.description}
                        textarea
                        tryUpdateValue={trimValue}
                        setValue={(v) => setDetail('description', v)}
                        validation={detailsValidation.description}
                        readOnly={isLocked}
                    />
                </LabeledInput>
                <LabeledInput label='Well Layout'>
                    {WellLayouts.find((l) => l[0] === details.well_layout)?.[1]}
                </LabeledInput>
                <LabeledInput label='Labware'>
                    <SimpleSelectOptionInput
                        value={settings.labware}
                        options={LabwareOptions}
                        setValue={(v) => setSetting('labware', v)}
                        validation={settingsValidation.labware}
                        disabled={isLocked}
                    />
                </LabeledInput>
                <LabeledInput label='Well Volume'>
                    {minVolume > 0 && (
                        <>
                            {roundValue(1, minVolume / scale.volume)} {formatUnitPrefix(scale.volume)}L{' — '}
                        </>
                    )}
                    {labwareInvalid && <span className='text-danger me-1'>Well volume invalid</span>}
                    {roundValue(1, maxVolume / scale.volume)} {formatUnitPrefix(scale.volume)}L
                </LabeledInput>
            </div>

            <div className='hte-experiment-form vstack gap-2'>
                <h5>Experiment Details</h5>
                <LabeledInput label='Stir Rate (rpm)'>
                    <TextInput
                        value={settings.stir_rate}
                        formatValue={formatRPM}
                        tryUpdateValue={asNumber}
                        setValue={(v) => setSetting('stir_rate', v * rpmToRadS)}
                        validation={settingsValidation.stir_rate}
                        readOnly={isLocked}
                    />
                </LabeledInput>
                <LabeledInput label='Temperature (C)'>
                    <TextInput
                        value={roundValue(3, settings.temperature - 273.15)}
                        tryUpdateValue={asNumber}
                        setValue={(v) => setSetting('temperature', v + 273.15)}
                        validation={settingsValidation.temperature}
                        readOnly={isLocked}
                    />
                </LabeledInput>
                <LabeledInput label='Duration (h)'>
                    <TextInput
                        value={roundValue(3, settings.duration / 3600)}
                        tryUpdateValue={asNumber}
                        setValue={(v) => setSetting('duration', v * 3600)}
                        validation={settingsValidation.duration}
                        readOnly={isLocked}
                    />
                </LabeledInput>
                <LabeledInput label='Atmosphere'>
                    <SimpleSelectOptionInput
                        value={settings.atmosphere}
                        options={Atmospheres}
                        setValue={(v) => setSetting('atmosphere', v)}
                        validation={settingsValidation.atmosphere}
                        disabled={isLocked}
                    />
                </LabeledInput>
                <LabeledInput label={`Nominal Volume (${formatUnitPrefix(scale.volume)}L)`}>
                    <TextInput
                        value={settings.nominal_volume}
                        tryUpdateValue={asNumber}
                        formatValue={(v) => roundValue(3, (v / scale.volume) * 1000).toString()}
                        setValue={(v) => setSetting('nominal_volume', v * scale.volume * 1e-3)} // in m**3
                        validation={settingsValidation.nominal_volume}
                        readOnly={isLocked}
                    />
                </LabeledInput>
                <LabeledInput label={`Reaction Scale (${formatUnitPrefix(scale.volume)}mol)`}>
                    <TextInput
                        value={settings.reaction_scale}
                        tryUpdateValue={asNumber}
                        formatValue={(v) => roundValue(3, v / scale.volume).toString()}
                        setValue={(v) => setSetting('reaction_scale', v * scale.volume)}
                        validation={settingsValidation.reaction_scale}
                        readOnly={isLocked}
                    />
                </LabeledInput>
                <LabeledInput label='Reaction Chemistry'>
                    <div style={{ width: 400 }}>
                        <Select
                            options={model.enumeration.reactionOptions}
                            value={reactionValue}
                            isSearchable
                            formatOptionLabel={formatReactionOption}
                            filterOption={createFilter({ ignoreAccents: false, stringify: (o) => o.label })}
                            menuPortalTarget={document.body}
                            menuPlacement='top'
                            placeholder='Select reaction chemistry...'
                            classNames={CustomSelectClassNames}
                            styles={DefaultSelectStyles}
                            onChange={(o) => setSetting('reaction_chemistry', o?.value?.id ?? 'Undefined')}
                            isDisabled={isLocked}
                        />
                    </div>
                </LabeledInput>
            </div>
        </div>
    );
}

function formatReactionOption({ label }: ReactionOption) {
    return <div>{label}</div>;
}
