import { BehaviorSubject, throttleTime } from 'rxjs';
import { AsyncMoleculeDrawer } from '../../lib/util/draw-molecules';
import { ReactiveModel } from '../../lib/util/reactive-model';
import { nonEmpty } from '../../lib/util/validators';
import {
    createEmptyPlate,
    ExperimentSettings,
    getExperimentCreate,
    HTEEntityWrapper,
    HTEDesign,
    HTEEnumerationInfo,
    HTEExperiment,
    HTEExperimentInfo,
    Plate,
    PlateDetails,
    PlateInfo,
    Reactant,
    Reaction,
} from './experiment-data';
import { updateWells } from './plate/utils';
import { ReagentsModel } from './steps/reagents-model';
import { ProductPlateModel } from './steps/product-plate-model';
import { ReactantsModel } from './steps/reactants-model';
import { ReactionsModel } from './steps/reactions-model';
import { HTEApi, HTEDesignInfo } from './experiment-api';
import { ToastService } from '../../lib/services/toast';
import { ExperimentBatchManager } from './batch-manager';
import { FinalizeModel } from './steps/finalize-model';
import { EnumerationModel } from './steps/enumeration-model';
import { HTEEnumerationReactionInfoEntry } from './enumeration/enumeration-api';
import { isNotAuthorizedError, reportErrorAsToast } from '../../lib/util/errors';
import { AsyncQueue } from '../../lib/util/async-queue';
import { HTEECMModel } from './steps/ecm-model';
import { AuthService } from '../../lib/services/auth';

export type HTEExperimentStep =
    | 'settings'
    | 'enumeration'
    | 'reactions'
    | 'reagents'
    | 'reactants'
    | 'product_plate'
    | 'ecm'
    | 'procedure'
    | 'observations'
    | 'finalize';

interface SaveState extends HTEExperiment {}

export class HTEExperimentModel extends ReactiveModel {
    state = {
        details: undefined as any as BehaviorSubject<PlateDetails>,
        settings: undefined as any as BehaviorSubject<ExperimentSettings>,
        design: undefined as any as BehaviorSubject<HTEDesign>,
        info: undefined as any as BehaviorSubject<HTEExperimentInfo>,
        procedure: undefined as any as BehaviorSubject<string | undefined>,
        observations: undefined as any as BehaviorSubject<string | undefined>,
        enumeration: undefined as any as BehaviorSubject<HTEEnumerationInfo | undefined>,
        history: new BehaviorSubject<{ stack: HTEDesign[]; cursor: number }>({
            stack: [],
            cursor: -1,
        }),
        step: new BehaviorSubject<HTEExperimentStep>('settings'),
        lastSavedState: undefined as any as BehaviorSubject<SaveState>,
        isSaving: new BehaviorSubject<boolean>(false),
        changed: new BehaviorSubject<number>(0),
        isLocked: new BehaviorSubject<boolean>(false),
    };

    get history() {
        return this.state.history.value;
    }

    get design() {
        return this.state.design.value;
    }

    get enumerationInfo() {
        return this.state.enumeration.value;
    }

    get layout() {
        return this.design.plate.layout;
    }

    get reactionScale() {
        return this.state.settings.value.reaction_scale;
    }

    get isLocked() {
        return this.state.isLocked.value;
    }

    get stateInfo() {
        return this.state.info.value;
    }

    readonly batches: ExperimentBatchManager;

    readonly enumeration: EnumerationModel;
    readonly reactions: ReactionsModel;
    readonly reagents: ReagentsModel;
    readonly reactants: ReactantsModel;
    readonly productPlate: ProductPlateModel;
    readonly ecm: HTEECMModel;
    readonly finalize: FinalizeModel;

    drawer = new AsyncMoleculeDrawer();

    info = {
        projects: [['placeholder', 'Placeholder Project']] as const,
    };

    undo() {
        const { history } = this;
        if (history.cursor > 0) {
            this.state.history.next({ ...history, cursor: history.cursor - 1 });
            this.state.design.next(history.stack[history.cursor - 1]);
        }
    }

    redo() {
        const { history } = this;
        if (history.cursor < history.stack.length - 1) {
            this.state.history.next({ ...history, cursor: history.cursor + 1 });
            this.state.design.next(history.stack[history.cursor + 1]);
        }
    }

    clearHistory() {
        this.state.history.next({ stack: [], cursor: -1 });
    }

    get hasUnsavedChanges() {
        const lastSaved = this.state.lastSavedState.value;
        return (
            lastSaved.design !== this.design ||
            lastSaved.details !== this.state.details.value ||
            lastSaved.settings !== this.state.settings.value ||
            lastSaved.procedure !== this.state.procedure.value ||
            lastSaved.observations !== this.state.observations.value ||
            lastSaved.enumeration !== this.state.enumeration.value
        );
    }

    private get newSaveState(): SaveState {
        return {
            design: this.design,
            details: this.state.details.value,
            settings: this.state.settings.value,
            procedure: this.state.procedure.value,
            observations: this.state.observations.value,
            enumeration: this.state.enumeration.value,
        };
    }

    private checkDataInterval: any = undefined;
    private async checkDataState() {
        if (this.state.isSaving.value || !AuthService.isAuthenticated) return;

        try {
            const isOk = await HTEApi.checkDataState(this.id, {
                last_modified_on: this.state.info.value.modified_on,
            });
            if (!isOk && this.checkDataInterval !== undefined) {
                // TODO: this might be handled more gracefully (e.g. do the state sync automatically)
                //       but that would require a lot more work.
                ToastService.show({
                    id: '__check_hte_data_state__',
                    type: 'warning',
                    message:
                        'Experiment data out of date.\nSomeone else might have modified it or you have multiple tabs open.\nPage refresh required.',
                    timeoutMs: false,
                });
            }
        } catch (err) {
            if (!isNotAuthorizedError(err)) {
                reportErrorAsToast('Data Integrity Check', err, { id: '__check_hte_data_state__' });
            }
        }
    }

    private saveQueue = new AsyncQueue({ singleItem: true });
    async save(force = false) {
        if (!AuthService.isAuthenticated) return;

        try {
            if (force) await this._save(force);
            else await this.saveQueue.execute(() => this._save());
        } catch (err) {
            if (err !== AsyncQueue.CancelError && !isNotAuthorizedError(err)) {
                reportErrorAsToast('Save', err, { id: '__save_hte_data__' });
            }
        }
    }

    private saveInterval: any = undefined;
    private async _save(force = false) {
        try {
            if (!this.hasUnsavedChanges && !force) {
                return;
            }

            const saveState = this.newSaveState;
            this.state.isSaving.next(true);
            const info = await HTEApi.save(this.id, {
                experiment: saveState,
                info: getExperimentCreate(saveState, this.stateInfo.status),
                procedure: saveState.procedure,
                observations: saveState.observations,
                last_modified_on: this.stateInfo.modified_on,
            });
            this.state.lastSavedState.next(saveState);
            this.state.info.next(info);
        } finally {
            this.state.isSaving.next(false);
        }
    }

    setReactions(reactions: Reaction[]) {
        const current = this.state.design.value;
        this.state.design.next({
            ...current,
            reactions,
        });
    }

    setReactants(reactants: Reactant[]) {
        const current = this.state.design.value;
        this.state.design.next({
            ...current,
            reactants,
        });
    }

    removeReactant(id: string) {
        const current = this.state.design.value;
        const reactant = this.reactants.getReactant(id);
        const newPlate = updateWells(current.plate, new Array<0 | 1>(current.plate.layout).fill(1), 'remove', {
            reactant,
        });
        this.state.design.next({
            ...current,
            plate: newPlate ?? current.plate,
            reactants: current.reactants.filter((v) => v.identifier !== id),
        });
    }

    resetReactionsAndReactants(reactants: Reactant[], reactions: Reaction[], plate: Plate | undefined) {
        const current = this.state.design.value;
        const settings = this.state.settings.value;
        this.state.design.next({
            ...current,
            reactants,
            reactions,
            plate:
                plate ??
                createEmptyPlate(
                    current.plate.layout,
                    PlateInfo[current.plate.layout].labware[settings.labware].wellVolumeRange
                ),
        });
    }

    addReactants(reactants: Reactant[]) {
        const current = this.state.design.value;
        this.state.design.next({
            ...current,
            reactants: [...current.reactants, ...reactants],
        });
    }

    setPlate(plate: Plate) {
        const current = this.state.design.value;
        this.state.design.next({
            ...current,
            plate,
        });
    }

    dispose() {
        super.dispose();
        this.reactants.dispose();
        this.productPlate.dispose();
        this.clearSaveInterval();
    }

    setSaveInterval() {
        const info = this.state.info.value;
        if (info.status === 'Planning' || info.status === 'Editing') {
            this.saveInterval = setInterval(() => this.save(), 30 * 1000);
            this.checkDataInterval = setInterval(() => this.checkDataState(), 10 * 1000);
        }
    }

    clearSaveInterval() {
        if (this.saveInterval !== undefined) {
            clearInterval(this.saveInterval);
            this.saveInterval = undefined;
        }
        if (this.checkDataInterval !== undefined) {
            clearInterval(this.checkDataInterval);
            this.checkDataInterval = undefined;
        }
    }

    lockOnAway() {
        if (this.stateInfo.status === 'Editing') {
            this.finalize.lock();
        }
    }

    private changed() {
        this.state.changed.next(this.state.changed.value + 1);
    }

    constructor(
        public id: number,
        options: {
            experiment: HTEExperiment;
            design_info: HTEDesignInfo;
            reagents: HTEEntityWrapper[];
            batches: ExperimentBatchManager;
            chemistry: HTEEnumerationReactionInfoEntry[];
        }
    ) {
        super();

        const { experiment, design_info } = options;

        // Workaround for incorrect labware (before labware was assigned by plate size)
        const { labware } = PlateInfo[design_info.experiment.well_layout];
        if (!labware[experiment.settings.labware]) {
            experiment.settings.labware = labware[design_info.experiment.labware]
                ? design_info.experiment.labware
                : Object.keys(labware)[0];
            const well_volume_range = labware[experiment.settings.labware].wellVolumeRange;
            experiment.design.plate.well_volume_range = well_volume_range;
        }

        this.state.info = new BehaviorSubject(design_info.experiment);
        if (design_info.experiment.status === 'Planning') {
            this.state.details = new BehaviorSubject(experiment.details);
            this.state.settings = new BehaviorSubject(experiment.settings);
        } else {
            // After experiment finalization, we will no longer save changes to the
            // design_user_state JSON, meaning the details & settings could no longer match,
            // so we should reference them from the HTEExperiment model itself and not
            // the designer user state JSON.
            this.state.details = new BehaviorSubject({
                library_name: design_info.experiment.name,
                project: design_info.experiment.project,
                description: design_info.experiment.description,
                well_layout: design_info.experiment.well_layout,
            } as PlateDetails);
            this.state.settings = new BehaviorSubject({
                stir_rate: design_info.experiment.conditions.stir_rate,
                temperature: design_info.experiment.conditions.temperature,
                atmosphere: design_info.experiment.conditions.atmosphere,
                nominal_volume: design_info.experiment.conditions.nominal_volume,
                duration: design_info.experiment.conditions.duration,
                // NOTE: reaction_scale is only stored in the experiment settings
                reaction_scale: experiment.settings.reaction_scale,
                reaction_chemistry: design_info.experiment.reaction_chemistry,
                labware: design_info.experiment.labware,
            } as ExperimentSettings);
        }

        this.state.design = new BehaviorSubject(experiment.design);
        this.state.enumeration = new BehaviorSubject(experiment.enumeration);
        this.state.procedure = new BehaviorSubject(design_info.experiment.procedure ?? experiment.procedure);
        this.state.observations = new BehaviorSubject(design_info.experiment.observations ?? experiment.observations);
        this.state.lastSavedState = new BehaviorSubject(this.newSaveState);

        this.batches = options.batches;
        this.reagents = new ReagentsModel(this, options.reagents);
        this.reactants = new ReactantsModel(this);
        this.reactions = new ReactionsModel(this);
        this.enumeration = new EnumerationModel(this, options.chemistry);
        this.productPlate = new ProductPlateModel(this);
        this.ecm = new HTEECMModel(this);
        this.finalize = new FinalizeModel(this, design_info);

        this.subscribe(this.state.design, (design) => {
            this.changed();
            const { history } = this;
            if (!history.stack.includes(design)) {
                const offset = history.stack.length > 50 ? 1 : 0;
                this.state.history.next({
                    stack: [...history.stack.slice(offset, history.cursor + 1), design],
                    cursor: history.cursor + 1 - offset,
                });
            }
        });

        this.subscribe(this.state.details, () => this.changed());
        this.subscribe(this.state.settings, (current) => {
            const { design } = this;
            const well_volume_range = PlateInfo[design.plate.layout].labware[current.labware].wellVolumeRange;
            if (
                design.plate.well_volume_range[0] !== well_volume_range[0] ||
                design.plate.well_volume_range[1] !== well_volume_range[1]
            ) {
                this.state.design.next({
                    ...design,
                    plate: {
                        ...design.plate,
                        well_volume_range,
                    },
                });
                // changed is called by design subscription
            } else {
                this.changed();
            }
        });
        this.subscribe(this.state.procedure.pipe(throttleTime(500, undefined, { trailing: true })), () =>
            this.changed()
        );
        this.subscribe(this.state.observations.pipe(throttleTime(500, undefined, { trailing: true })), () =>
            this.changed()
        );
        this.subscribe(this.state.enumeration.pipe(throttleTime(500, undefined, { trailing: true })), () =>
            this.changed()
        );

        this.subscribe(this.state.info, (info) => {
            this.state.isLocked.next(!!info.locked_on && info.status !== 'Editing');
        });

        this.subscribe(this.state.isLocked, (isLocked) => {
            if (isLocked) {
                clearInterval(this.saveInterval);
                this.saveInterval = undefined;
            } else {
                this.saveInterval = setInterval(() => this.save(), 30 * 1000);
            }
        });

        this.setSaveInterval();
    }
}

export function validateDetails(details: PlateDetails): Record<keyof PlateDetails, string> {
    return {
        library_name: nonEmpty(details.library_name),
        description: '',
        project: !details.project ? 'Select a project' : '',
        well_layout: '',
    };
}

export function validateSettings(settings: ExperimentSettings): Record<keyof ExperimentSettings, string> {
    return {
        temperature: '',
        stir_rate: '',
        atmosphere: '',
        nominal_volume: '',
        reaction_scale: '',
        duration: '',
        labware: '',
        reaction_chemistry: !settings.reaction_chemistry ? 'Select reaction chemistry' : '',
    };
}
