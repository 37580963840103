import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import saveAs from 'file-saver';
import { Button } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { AsyncMoleculeDrawing, DrawMoleculeError } from '../../../components/common/AsyncMoleculeDrawing';
import { TextInput } from '../../../components/common/Inputs';
import { LogModel } from '../../../components/common/Log';
import useBehavior from '../../../lib/hooks/useBehavior';
import { DialogService } from '../../../lib/services/dialog';
import { AsyncMoleculeDrawer, DrawResult } from '../../../lib/util/draw-molecules';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { objectIsEmpty, splitInput } from '../../../lib/util/misc';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import {
    Column,
    ColumnInstance,
    ColumnsFor,
    columnDataTableStoreFromObjects,
    DefaultRowHeight,
    DataTableModel,
} from '../../../components/DataTable';
import { Batch, CompoundDetail } from '../../Compounds/compound-api';
import { Vial } from '../../ECM/ecm-api';
import { SimilarCompoundsService } from '../similar-compounds/similar-compounds';
import { CompoundIdentifier } from '../steps/reagents-model';
import {
    EnumerationAPI,
    HTEEnumerationApiReactionSites,
    HTEEnumerationFilterInfo,
    HTEEnumerationReactionInfoEntry,
} from './enumeration-api';
import { createReactionsCSV } from './enumeration-export';

export interface ReactantBase {
    smiles: string;
    order: number;
    sites?: HTEEnumerationApiReactionSites;
    current_site_index?: number;
    batch?: Batch;
    compound?: CompoundDetail;
    vial?: Vial;
}

const ReactantBaseColumns: (keyof ReactantBase)[] = ['order', 'sites', 'batch', 'compound', 'vial', 'smiles'];

export interface HTEEnumerationMSD extends ReactantBase {}

const MSDTableColumns: (keyof HTEEnumerationMSD)[] = [...ReactantBaseColumns];

export interface HTEEnumerationBB extends ReactantBase {
    bb_group: string;
}

const BBTableColumns: (keyof HTEEnumerationBB)[] = [
    ...ReactantBaseColumns.slice(0, 2),
    'bb_group',
    ...ReactantBaseColumns.slice(2),
];

export interface HTEEnumerationReaction {
    msd: HTEEnumerationMSD;
    bb: HTEEnumerationBB;
    products: string[];
    filtered_products: string[];
    current_product: string | undefined;
    error?: string;
}

export interface HTEEnumerationReactionStats {
    unique: number;
    none: number;
    nonUnique: number;
    error: number;
}

const ReactionTableColumns: (keyof HTEEnumerationReaction)[] = [
    'msd',
    'bb',
    'products',
    'filtered_products',
    'current_product',
    'error',
];

interface HTEEnumerationSettings {
    reaction_chemistry_id: string;
    filters: Record<string, { name?: string; exclude?: boolean; smiles?: string; smarts?: string }>;
}

const DebugMSDs: HTEEnumerationMSD[] = [
    { smiles: 'C(=O)O', order: 1 },
    { smiles: 'c1ccccc1C(O)=O', order: 2 },
    { smiles: 'OC(=O)CCC(=O)O', order: 3 },
];

const DebugBBs: HTEEnumerationBB[] = [
    { smiles: 'CN(C)', bb_group: '1', order: 1 },
    { smiles: 'NCCOCN', bb_group: '1', order: 2 },
    { smiles: 'n1ccccc1N', bb_group: '1', order: 3 },
];

const DebugSettings: HTEEnumerationSettings = {
    reaction_chemistry_id: '',
    filters: {},
};

export interface ReactionOption {
    label: string;
    value?: HTEEnumerationReactionInfoEntry;
}

export class HTEEnumerationModel extends ReactiveModel {
    readonly drawer = new AsyncMoleculeDrawer();
    readonly reactionOptions: ReactionOption[];
    readonly reactionsTable: DataTableModel<HTEEnumerationReaction>;
    readonly msdTable: DataTableModel<HTEEnumerationMSD>;
    readonly bbTable: DataTableModel<HTEEnumerationBB>;
    readonly msdSimilarCompounds: SimilarCompoundsService = new SimilarCompoundsService((input: string[]) =>
        this.addMSDs(input.join(','))
    );
    readonly bbSimilarCompounds: SimilarCompoundsService = new SimilarCompoundsService((input: string[]) =>
        this.addBBs(input.join(','))
    );

    private currentReactions: HTEEnumerationReaction[] = [];

    log = new LogModel();

    state = {
        reactions: new BehaviorSubject<HTEEnumerationReaction[]>([]),
        reactionStats: new BehaviorSubject<HTEEnumerationReactionStats | undefined>(undefined),
        settings: new BehaviorSubject<HTEEnumerationSettings>(DebugSettings),
    };

    get filterInfo() {
        return this.options.filterInfo;
    }

    async syncReactionSites() {
        try {
            const reaction_id = this.state.settings.value.reaction_chemistry_id;
            const msds = this.msdTable.store.getColumnValues('smiles').map((smiles) => ({ smiles }));
            const bbs = this.bbTable.store.getColumnValues('smiles').map((smiles) => ({ smiles }));

            const [msdSites, bbSites] = await Promise.all([
                EnumerationAPI.reactionSites({ reaction_id, inputs: msds }),
                EnumerationAPI.reactionSites({ reaction_id, inputs: bbs }),
            ]);

            this.msdTable.store.addOrUpdateColumn('sites', msdSites);
            this.msdTable.store.addOrUpdateColumn(
                'current_site_index',
                msdSites.map((s) => undefined)
            );

            this.bbTable.store.addOrUpdateColumn('sites', bbSites);
            this.bbTable.store.addOrUpdateColumn(
                'current_site_index',
                bbSites.map((s) => undefined)
            );

            this.msdTable.dataChanged();
            this.bbTable.dataChanged();
        } catch (err) {
            reportErrorAsToast('Update Reaction', err);
        }
    }

    exportCSV() {
        const csv = createReactionsCSV(this.reactionsTable.store.toObjects());
        saveAs(new Blob([csv], { type: 'text/csv' }), `reactions-${Date.now()}.csv`);
    }

    clearReactants(kind: 'msd' | 'bb') {
        if (kind === 'msd') {
            this.msdTable.store.clear();
            this.msdTable.dataChanged();
        }
        if (kind === 'bb') {
            this.bbTable.store.clear();
            this.bbTable.dataChanged();
        }
    }

    async addMSDs(input: string) {
        try {
            const reactants = await this.parseReactants(input);
            let order = this.msdTable.store.rowCount;
            for (const r of reactants) {
                this.msdTable.store.insertRow({ ...r, order: ++order });
            }
            this.msdTable.dataChanged();
        } catch (err) {
            reportErrorAsToast('Add MDSs', err);
        }
    }

    async addBBs(input: string) {
        try {
            const reactants = await this.parseReactants(input);
            let order = this.bbTable.store.rowCount;
            for (const r of reactants) {
                this.bbTable.store.insertRow({ ...r, order: ++order, bb_group: '1' });
            }
            this.bbTable.dataChanged();
        } catch (err) {
            reportErrorAsToast('Add MDSs', err);
        }
    }

    private parseReactants(input: string) {
        const identifiers = splitInput(input);
        return EnumerationAPI.loadReactants({ identifiers });
    }

    async applyEnumerate() {
        const reactions = await this.enumerate();
        const filtered = reactions ? await this.filter(reactions) : undefined;
        this.currentReactions = filtered ?? [];
        this.updateTable();
        const stats = this.updateStats(filtered);

        if (filtered) {
            this.log.message(
                'success',
                `Enumerate + Filter: ${stats?.unique ?? 0} unique, ${stats?.none ?? 0} without product, ${
                    stats?.nonUnique ?? 0
                } non-unique, ${stats?.error ?? 0} error`
            );
        }
    }

    async applyFilter() {
        const filtered = await this.filter(this.currentReactions);
        this.currentReactions = filtered ?? [];
        this.updateTable();
        const stats = this.updateStats(filtered);

        if (filtered) {
            this.log.message(
                'success',
                `Filter: ${stats?.unique ?? 0} unique, ${stats?.none ?? 0} without product, ${
                    stats?.nonUnique ?? 0
                } non-unique, ${stats?.error ?? 0} error`
            );
        }
    }

    private updateTable() {
        this.reactionsTable.store.setRows(this.currentReactions);
        this.reactionsTable.dataChanged();
    }

    private async enumerate() {
        try {
            const msds = this.msdTable.store.toObjects();
            const bbs = this.bbTable.store.toObjects();

            const msdMap = new Map(msds.map((r) => [r.order, r]));
            const bbMap = new Map(bbs.map((r) => [r.order, r]));

            const enumeration = await EnumerationAPI.enumerate({
                reaction_id: this.state.settings.value.reaction_chemistry_id,
                msds_inputs: msds.map((r) => ({
                    order: r.order,
                    smiles: r.smiles,
                    included_sites:
                        typeof r.current_site_index === 'number'
                            ? [r.sites!.possible_reaction_sites[r.current_site_index].reaction_site]
                            : undefined,
                })),
                bbs_inputs: bbs.map((r) => ({
                    order: r.order,
                    smiles: r.smiles,
                    included_sites:
                        typeof r.current_site_index === 'number'
                            ? [r.sites!.possible_reaction_sites[r.current_site_index].reaction_site]
                            : undefined,
                })),
            });

            return enumeration.map<HTEEnumerationReaction>(([m, b, p]) => ({
                msd: msdMap.get(m)!,
                bb: bbMap.get(b)!,
                products: p.products,
                filtered_products: p.products,
                current_product: p.products.length === 1 ? p.products[0] : undefined,
                error: p.error?.join('; '),
            }));
        } catch (err) {
            reportErrorAsToast('Enumerate', err);
        }
    }

    private async filter(reactions: HTEEnumerationReaction[]) {
        try {
            const structures = reactions.flatMap((r) => r.products);
            const { filtered_list } = !objectIsEmpty(this.state.settings.value.filters)
                ? await EnumerationAPI.filterProducts({
                      structures,
                      filters: Array.from(Object.values(this.state.settings.value.filters)),
                  })
                : { filtered_list: structures };

            const filteredSet = new Set(filtered_list);
            return reactions.map((r) => {
                const filtered_products = r.products.filter((p) => filteredSet.has(p));
                return {
                    ...r,
                    filtered_products,
                    current_product: filtered_products.length === 1 ? filtered_products[0] : undefined,
                };
            });
        } catch (err) {
            reportErrorAsToast('Filter', err);
        }
    }

    private updateStats(reactions?: HTEEnumerationReaction[]) {
        const stats: HTEEnumerationReactionStats = { none: 0, nonUnique: 0, unique: 0, error: 0 };
        if (reactions) {
            for (const { filtered_products, error } of reactions) {
                if (error) stats.error++;
                else if (filtered_products.length === 0) stats.none++;
                else if (filtered_products.length === 1) stats.unique++;
                else stats.nonUnique++;
            }
        }
        this.state.reactionStats.next(stats);
        return stats;
    }

    mount() {}

    readonly reactionColumns: ColumnsFor<HTEEnumerationReaction> = {
        msd: Column.create<HTEEnumerationMSD>({
            kind: 'str',
            label: 'MSD',
            header: 'MSD',
            format: (v) => v.smiles,
            render: ({ value }) => (
                <div className='position-relative w-100 h-100'>
                    <div style={{ position: 'absolute', left: 8, top: 8 }} className='text-secondary'>
                        #{value.order}
                    </div>
                    <AsyncMoleculeDrawing smiles={value.smiles} height='100%' width='100%' drawer={this.drawer} />
                </div>
            ),
            compare: false,
        }),
        bb: Column.create<HTEEnumerationBB>({
            kind: 'str',
            label: 'BB',
            header: 'BB',
            format: (v) => v.smiles,
            render: ({ value }) => (
                <div className='position-relative w-100 h-100'>
                    <div style={{ position: 'absolute', left: 8, top: 8 }} className='text-secondary'>
                        #{value.order}
                    </div>
                    <AsyncMoleculeDrawing smiles={value.smiles} height='100%' width='100%' drawer={this.drawer} />
                </div>
            ),
            compare: false,
        }),
        filtered_products: Column.create<string[]>({
            kind: 'int',
            label: 'Product Count (filtered / total)',
            header: '#',
            filterType: false,
            width: 60,
            align: 'center',
            compare: (a, b) => a.length - b.length,
            format: (value) => value.length.toString(),
            render: ({ value, rowIndex, table }) => (
                <>
                    {value.length} / {table.store.getValue('products', rowIndex).length}
                </>
            ),
        }),
        current_product: Column.create<string | undefined>({
            kind: 'str',
            label: 'Product',
            header: 'Product',
            render: ({ value, table, rowIndex }) => (
                <ProductSelect
                    reaction={table.store.getRow(rowIndex)}
                    current={value}
                    table={table}
                    rowIndex={rowIndex}
                    drawer={this.drawer}
                />
            ),
            compare: false,
        }),
        error: {
            ...Column.str(),
            width: 200,
            render: ({ value }) => (
                <span className='text-warning' style={{ whiteSpace: 'break-spaces' }}>
                    {value}
                </span>
            ),
        },
    };

    private DeleteReactantAction: ColumnInstance = {
        id: 'delete-action',
        width: 40,
        position: 0,
        alwaysVisible: true,
        noHeaderTooltip: true,
        // noResize: true,
        cell: (rowIndex, table: DataTableModel<ReactantBase>) => (
            <Button
                size='sm'
                variant='outline'
                onClick={(e) => {
                    e.stopPropagation();
                    const rowOrder = table.store.getValue('order', rowIndex);
                    for (let i = 0; i < table.store.rowCount; i++) {
                        const order = table.store.getValue('order', i);
                        if (order > rowOrder) {
                            table.store.setValue('order', i, order - 1);
                        }
                    }
                    table.store.deleteRow(rowIndex);
                    table.dataChanged();
                }}
            >
                <FontAwesomeIcon size='sm' className='text-danger' icon={faRemove} />
            </Button>
        ),
    };

    private commonReactantColumns: ColumnsFor<ReactantBase> = {
        order: { ...Column.int(), header: '#', width: 40, align: 'center', compare: false },
        sites: Column.create<HTEEnumerationApiReactionSites | undefined>({
            kind: 'str',
            header: 'Reaction Site',
            format: (v) => v?.smiles ?? '',
            width: 180,
            render: ({ value, table, rowIndex }) => {
                if (!value) {
                    return (
                        <AsyncMoleculeDrawing
                            smiles={table.store.getValue('smiles', rowIndex)}
                            height='100%'
                            width='100%'
                            drawer={this.drawer}
                            autosize
                        />
                    );
                }
                return (
                    <SiteSelect
                        sites={value}
                        table={table}
                        current_site_index={table.store.getValue('current_site_index', rowIndex)}
                        rowIndex={rowIndex}
                    />
                );
            },
            compare: false,
        }),
        compound: Column.create({
            kind: 'str',
            header: 'Compound Identifier',
            format: (v) => v?.identifier ?? '',
            width: 180,
            render: ({ formatted }) => <CompoundIdentifier value={formatted} />,
            compare: false,
        }),
        batch: Column.create({
            kind: 'str',
            header: 'Batch Identifier',
            format: (v) => v?.identifier ?? '',
            width: 180,
            render: ({ formatted }) => <CompoundIdentifier value={formatted} />,
            compare: false,
        }),
        vial: Column.create({
            kind: 'str',
            header: 'Barcode',
            format: (v) => v?.barcode ?? '',
            width: 180,
            render: ({ formatted }) => <CompoundIdentifier value={formatted} />,
            compare: false,
        }),
    };

    readonly msdColumns: ColumnsFor<HTEEnumerationMSD> = {
        ...this.commonReactantColumns,
    };

    readonly bbColumns: ColumnsFor<HTEEnumerationBB> = {
        ...(this.commonReactantColumns as ColumnsFor<HTEEnumerationBB>),
        bb_group: Column.create({
            kind: 'str',
            header: 'Group',
            label: 'Group',
            render: ({ value, rowIndex, table }) => (
                <TextInput
                    style={{ padding: 0, background: 'transparent', maxWidth: 100 }}
                    value={value}
                    setValue={(val) => {
                        table.store.setValue('bb_group', rowIndex, val);
                        table.dataChanged();
                    }}
                />
            ),
            noHeaderTooltip: true,
            width: 120,
        }),
    };

    constructor(
        private options: { reactionsInfo: HTEEnumerationReactionInfoEntry[]; filterInfo: HTEEnumerationFilterInfo }
    ) {
        super();

        const groupedReactions = new Set(options.filterInfo.filter_groups.flatMap((g) => g.filter_names));
        const otherFilters = this.filterInfo.named_filters.filter((f) => !groupedReactions.has(f));
        if (otherFilters.length > 0) {
            options.filterInfo.filter_groups.push({ name: 'other', filter_names: otherFilters });
        }

        this.reactionOptions = options.reactionsInfo.map((r) => ({
            label: `${r.name} (${r.kind})`,
            value: r,
        }));

        this.reactionsTable = new DataTableModel<HTEEnumerationReaction>(
            columnDataTableStoreFromObjects<HTEEnumerationReaction>([], ReactionTableColumns),
            {
                columns: this.reactionColumns,
                hideNonSchemaColumns: true,
                rowHeight: DefaultRowHeight * 2.5,
            }
        );

        const debug = window.location.hostname === 'localhost';

        this.msdTable = new DataTableModel<HTEEnumerationMSD>(
            columnDataTableStoreFromObjects<HTEEnumerationMSD>(debug ? DebugMSDs : [], MSDTableColumns),
            {
                columns: this.msdColumns,
                hideNonSchemaColumns: true,
                rowHeight: DefaultRowHeight * 2.5,
                customState: { 'show-smiles': true },
                actions: [this.DeleteReactantAction],
            }
        );

        this.bbTable = new DataTableModel<HTEEnumerationBB>(
            columnDataTableStoreFromObjects<HTEEnumerationBB>(debug ? DebugBBs : [], BBTableColumns),
            {
                columns: this.bbColumns,
                hideNonSchemaColumns: true,
                rowHeight: DefaultRowHeight * 2.5,
                customState: { 'show-smiles': true },
                actions: [this.DeleteReactantAction],
            }
        );

        this.log.message('info', 'Welcome to the Enumeration tool!');
    }
}

function ProductSelect({
    reaction,
    current,
    table,
    rowIndex,
    drawer,
}: {
    reaction: HTEEnumerationReaction;
    current?: string;
    table: DataTableModel;
    rowIndex: number;
    drawer: AsyncMoleculeDrawer;
}) {
    if (reaction.filtered_products.length === 0 && reaction.products.length === 0) return <span>-</span>;

    if (reaction.products.length === 1)
        return <AsyncMoleculeDrawing smiles={reaction.products[0]} height='100%' width='100%' drawer={drawer} />;

    const currentUI = !current ? (
        'Select'
    ) : (
        <AsyncMoleculeDrawing smiles={current} height='100%' width='100%' drawer={drawer} />
    );

    const select = () => {
        DialogService.open({
            type: 'generic',
            content: ProductSelectDialogContent,
            model: { reaction, drawer },
            defaultState: reaction.current_product,
            title: 'Select Product',
            onOk: (state) => {
                table.store.setValue('current_product', rowIndex, state);
                table.dataChanged();
            },
        });
    };

    return (
        <Button variant='link' className='w-100 h-100' onClick={select}>
            {currentUI}
        </Button>
    );
}

function ProductSelectDialogContent({
    stateSubject,
    model: { reaction, drawer },
}: {
    stateSubject: BehaviorSubject<string>;
    model: {
        reaction: HTEEnumerationReaction;
        drawer: AsyncMoleculeDrawer;
    };
}) {
    const current = useBehavior(stateSubject);

    return (
        <div>
            {reaction.products.map((p, i) => (
                <Button
                    variant={
                        current === p
                            ? 'outline-primary'
                            : reaction.filtered_products.includes(p)
                            ? 'outline-success'
                            : 'link'
                    }
                    onClick={() => stateSubject.next(p)}
                    key={i}
                    style={{ width: 160, height: 120, borderWidth: current === p ? 4 : 1 }}
                    className='me-2 mb-2'
                >
                    <AsyncMoleculeDrawing
                        smiles={p}
                        height='100%'
                        width='100%'
                        drawer={drawer}
                        autosize
                        showChemDraw={false}
                        showCopy={false}
                    />
                </Button>
            ))}
        </div>
    );
}

function SiteSelect({
    sites,
    current_site_index,
    table,
    rowIndex,
}: {
    sites: HTEEnumerationApiReactionSites;
    current_site_index?: number;
    table: DataTableModel;
    rowIndex: number;
}) {
    const select = () => {
        DialogService.open({
            type: 'generic',
            content: SiteSelectDialogContent,
            model: { sites },
            defaultState: current_site_index,
            title: 'Select Reaction Site',
            onOk: (state) => {
                table.store.setValue('current_site_index', rowIndex, state);
                table.dataChanged();
            },
        });
    };

    const drawResult =
        typeof current_site_index === 'number'
            ? sites.possible_reaction_sites[current_site_index].drawn_molecule
            : sites.all_sites_drawing!;

    return (
        <Button variant='link' className='w-100 h-100 position-relative' onClick={select}>
            {renderMoleculeDrawing(drawResult)}
            {sites.possible_reaction_sites.length > 1 && typeof current_site_index === 'number' && (
                <div style={{ position: 'absolute', left: 8, top: 8, fontSize: '0.75rem' }} className='text-secondary'>
                    {current_site_index + 1}/{sites.possible_reaction_sites.length}
                </div>
            )}
        </Button>
    );
}

function renderMoleculeDrawing(drawResult: DrawResult) {
    if (drawResult.kind === 'error') {
        return <DrawMoleculeError drawResult={drawResult} />;
    }
    return <img height='100%' width='100%' alt='' src={drawResult.content} />;
}

function SiteSelectDialogContent({
    stateSubject,
    model: { sites },
}: {
    stateSubject: BehaviorSubject<number | undefined>;
    model: {
        sites: HTEEnumerationApiReactionSites;
    };
}) {
    const current = useBehavior(stateSubject);

    return (
        <div>
            {sites.possible_reaction_sites.length > 1 &&
                sites.possible_reaction_sites.map((site, i) => (
                    <Button
                        variant={i === current ? 'outline-primary' : 'link'}
                        onClick={() => stateSubject.next(i)}
                        key={i}
                        style={{ width: 160, height: 120, borderWidth: i === current ? 4 : 1 }}
                        className='me-2 mb-2'
                    >
                        {renderMoleculeDrawing(site.drawn_molecule)}
                    </Button>
                ))}
            <Button
                variant={typeof current !== 'number' ? 'outline-primary' : 'link'}
                onClick={() => stateSubject.next(undefined)}
                style={{ width: 160, height: 120, borderWidth: typeof current !== 'number' ? 4 : 1 }}
                className='me-2 mb-2'
            >
                {renderMoleculeDrawing(sites.all_sites_drawing)}
            </Button>
        </div>
    );
}
