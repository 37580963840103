import api from '../../api';
import { ColumnTableData, columnDataTableStore, DataTableStore } from '../../components/DataTable';
import type { AssayUncertaintyValue, AssayValueCreate, AssayValueGraph, AssayValueType } from '../../lib/assays/models';
import type { ArtifactDB } from '../../lib/models/artifacts';
import type { AssayProperty } from '../../lib/models/biology';
import { decodeEntosMsgpack } from '../../lib/util/serialization';

export function getAssayValueRetiredComment(assayValue: AssayValueCreate, isSelected: boolean) {
    if (assayValue.value_details.source === 'not_determined') return 'Assay value could not be determined during QC.';
    if (typeof assayValue.value === 'number' && assayValue.value < 0)
        return 'Assay value could not be determined from selected method during QC.';
    if (!isSelected) return 'Retired by user during QC.';
    return '';
}

export function getAssayLink(assayId: number, batchIdentifier: string) {
    return batchIdentifier && Number.isFinite(assayId)
        ? `/assays/${assayId}?compound-identifier=${batchIdentifier.split(' ', 1)[0]}`
        : '';
}

export function getLinkToAssayFromCompound(batchIdentifier: string, assayShorthand: string) {
    const assayId = getAssayIdFromShorthand(assayShorthand);
    return getAssayLink(assayId, batchIdentifier);
}

export function getAssayIdFromShorthand(shorthand: string): number {
    if (!shorthand.startsWith('A(')) return NaN;
    return +shorthand.substring(2, shorthand.indexOf(','));
}

export interface AssayValueTableBase {
    smiles: string;
    batch_identifier: string;
    supplier_id?: string | null;
    retired_comment: string;
    performed_on: number | string | Date;
    uploaded_on?: number | string | Date;
}

export interface AssayValueDetail extends AssayValueTableBase {
    id?: number;

    fit_source: string;
    obs_min: number;
    obs_max: number;
    r2?: number;
    min?: number | AssayUncertaintyValue;
    max?: number | AssayUncertaintyValue;
    slope?: number | AssayUncertaintyValue;
    artifacts?: Record<string, ArtifactDB>;
    y_values?: number[];
    y_std_dev?: number;
    ligand_efficiency?: AssayValueType;
    scaled_clearance?: AssayValueType;
    [value: string]: any;
}

export interface AssayValueNonCurveReview extends AssayValueTableBase {
    [value: string]: any;
}

export interface AssayDetail {
    id: number;
    created_on: string;
    description: string;
    kind: 'Computation' | 'Literature' | 'Experiment';
    source: string;
    property: AssayProperty;
    property_shorthand?: string;
    measurement?: string;
    shorthand?: string;
    common_unit_shorthand?: string;
    value_count?: number;
    details?: Record<string, any>;
    hidden?: boolean;
}

export interface AssayFitInfo {
    half_life?: number;
    auc?: number;
    r2?: number;
    min?: number | AssayUncertaintyValue;
    max?: number | AssayUncertaintyValue;
    obs_min?: number;
    obs_max?: number;
    slope?: number | AssayUncertaintyValue;
    ic75?: AssayValueType;
    ic90?: AssayValueType;
}

export interface AssayDataPoints {
    info: AssayDetail;
    values: AssayValueCreate[];
    fit_info?: AssayFitInfo[];
    [extra: string]: any;
}

export interface AssayValueReviewUploadResult {
    type: 'bayes' | 'standard';
}

export interface BayesUploadResult extends AssayValueReviewUploadResult {
    type: 'bayes';
    computation_id: string;
}

export interface StandardUploadResult extends AssayValueReviewUploadResult {
    type: 'standard';
    data: AssayDataPoints[];
}

export interface AssayValueDetailResponse {
    dataframe: ColumnTableData<AssayValueDetail>;
    values: AssayValueCreate[];
    units: Record<string, string>;
}

export interface DataQuery {
    project?: string | null;
    date?: Date[] | null;
    assays: number[];
}

export const AssayAPI = {
    list: async (): Promise<DataTableStore<AssayDetail>> => {
        const data = await api.getMsgpack('assays');
        return columnDataTableStore(data);
    },
    get: async (id: number): Promise<AssayDetail> => {
        const { data } = await api.client.get(`assays/${id}`);
        return data;
    },
    getValuesFull: (id: number): Promise<AssayValueDetailResponse> => api.getMsgpack(`assays/${id}/values_full`),
    refit: (data: { assay_values: AssayValueCreate[] }): Promise<AssayValueCreate[]> =>
        api.postMsgpack('assays/refit', data, { decode: { eoi: 'strip' } }),
    finalizeUpload: (data: { assay_values: AssayValueCreate[] }): Promise<AssayValueCreate[]> =>
        api.postMsgpack('assays/finalize-upload', data),
    review: async (
        files: File[],
        vendor: string | null,
        performQC: boolean
    ): Promise<BayesUploadResult | StandardUploadResult> => {
        const formData = new FormData();
        files.forEach((file) => formData.append('files', file));
        if (vendor) {
            formData.set('vendor', vendor);
        }
        formData.set('performQC', `${performQC}`);

        const { data } = await api.client.post('assays/assay_values/review-with-bayes', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(new Uint8Array(data), { eoi: 'strip' });
    },
    create: async (data: AssayDataPoints): Promise<boolean> => {
        const res = await api.client.post('assays/assay_values/create', data, {
            responseType: 'arraybuffer',
        });
        return res.status === 200;
    },
    getHistoricValues: async (
        assay_ids: number[],
        batch_identifiers: string[]
    ): Promise<DataTableStore<{ identifier: string; [assay_id: number]: AssayValueType }>> => {
        const data = await api.postMsgpack(
            'assays/get_historic_values',
            { assay_ids, batch_identifiers },
            {
                decode: { eoi: 'strip' },
            }
        );
        return columnDataTableStore(data);
    },
    getFitInfo: (
        assay_values: Record<number, AssayValueCreate[]>
    ): Promise<{ fit_info: Record<number, Record<string, AssayFitInfo>> }> =>
        api.postMsgpack('assays/assay_values/fit_info', assay_values, {
            decode: { eoi: 'strip' },
        }),
    calculateDynamicAUC: (graph: AssayValueGraph): Promise<AssayValueType> =>
        api.postMsgpack('assays/assay_values/dynamic_auc', graph, { decode: { eoi: 'strip' } }),
};
