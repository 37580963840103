import { ArtifactDB } from '../../lib/models/artifacts';
import { DateLike } from '../../lib/util/dates';
import { roundValue } from '../../lib/util/roundValues';

// TODO: Move layout-related types to lib/models
export const WellLayouts = [
    [24, '4x6 24 Well Plate'],
    [96, '8x12 96 Well Plate'],
    [384, '16x24 384 Well Plate'],
    [1536, '32x48 1536 Well Plate'],
] as const;
export type WellLayout = (typeof WellLayouts)[number][0];

export const ExtendedWellLayouts = [
    [1, '1x1 (1 well)'],
    [4, '1x4 (4 wells)'],
    [6, '2x3 (6 wells)'],
    [12, '3x4 (12 wells)'],
    [24, '4x6 (24 wells)'],
    [54, '6x9 (54 wells)'],
    [96, '8x12 (96 wells)'],
    [384, '16x24 (384 wells)'],
    [1536, '32x48 (1536 wells)'],
] as const;
export type ExtendedWellLayout = (typeof ExtendedWellLayouts)[number][0];

export const PlateDimensions: Record<ExtendedWellLayout | WellLayout, [w: number, h: number]> = {
    1: [1, 1],
    4: [4, 1],
    6: [3, 2],
    12: [4, 3],
    24: [6, 4],
    54: [9, 6],
    96: [12, 8],
    384: [24, 16],
    1536: [48, 32],
};

export const Atmospheres = [
    ['N2', 'N2: Inert atmosphere in a glovebox'],
    ['Normal', 'Normal: Open, humid atmosphere'],
] as const;
export type Atmosphere = (typeof Atmospheres)[number][0];

export interface PlateDetails {
    library_name: string;
    project: string;
    description: string;
    well_layout: WellLayout;
}

export interface ReactionConditions {
    stir_rate: number; // rad/s
    temperature: number; // K
    atmosphere: Atmosphere;
    nominal_volume: number; // in m**3
    duration: number;
}

export interface ExperimentSettings extends ReactionConditions {
    reaction_scale: number;
    reaction_chemistry: string;
    labware: string;
}

export const ReagentTypes = [
    ['reagent', 'Reagent'],
    ['catalyst', 'Catalyst'],
    ['solvent', 'Solvent'],
] as const;
export type ReagentType = (typeof ReagentTypes)[number][0];

export type ReactantType = 'msd' | 'bb' | 'reagent' | 'unknown';

export function getReactantIdentifierType(identifier: string): ReactantType {
    if (identifier.startsWith('MSD')) return 'msd';
    if (identifier.startsWith('BB')) return 'bb';
    if (identifier.startsWith('RGN')) return 'reagent';
    return 'unknown';
}

export interface HTEEntityWrapper {
    common_name?: string;
    identifier: string;
    universal_identifier?: string;
    barcode?: string;

    reagent_type?: ReagentType;
}

export interface Reactant {
    identifier: string;
    barcode?: string;
    transfer_barcode?: string;
    molecular_weight?: number;

    type: ReactantType;

    group_name?: string;
    reagent_type?: ReagentType;

    defaults: {
        equivalence: number;
        concentration: number | null;
        overage: number;
    };

    equivalence: number;
    concentration: number | null;
    overage: number;
    stock_amount: number | null;
    stock_volume: number | null;

    density?: number | null; // g/L
    solvent?: HTESolvent | null;

    msd_order?: number;
    bb_order?: number;

    enumeration?: { reaction_id: string; reaction_sites: number[][]; site_index?: number };
}

export interface Reaction {
    msd_identifier: string;
    bb_identifier: string;

    product_identifier: string;
    product_molecular_weight: number | undefined;
    well_index?: number;
    enumeration?: { reaction_id: string; msd_site?: number[]; bb_site?: number[] };
}

export function getReactantLayerType(r: Reactant): WellLayerType {
    return r.type === 'reagent' ? r.reagent_type ?? 'reagent' : r.type;
}

export type WellLayerType = ReactantType | ReagentType;
export type Well = string[] | undefined;
export type WellCoordinate = number;
export type WellRect = [start: WellCoordinate, end: WellCoordinate];
export type WellSelection = (0 | 1)[];

export type WellAction = 'add' | 'remove' | 'clear';

export interface Plate {
    layout: WellLayout;
    wells: Well[];
    well_volume_range: [min: number, max: number];
}

export interface HTEFinalize {
    crude_plate_barcode: string;
}

export interface HTEDesign {
    reactants: Reactant[];
    reactions: Reaction[];
    plate: Plate;
}

export interface HTEEnumerationInfo {
    reaction_id?: string;
}

export interface HTEExperiment {
    details: PlateDetails;
    settings: ExperimentSettings;
    design: HTEDesign;
    procedure?: string;
    observations?: string;
    enumeration?: HTEEnumerationInfo;
}

export const HTEExperimentStatuses = [
    ['Planning', 'Experiment in planning phase'],
    // ['In Progress', 'Experiment in execution phase'], // to be deprecated
    ['Done', 'Experiment finished'],
    // ['Locked', 'Experiment is locked'], // to be deprecated
    ['Needs Signing', 'Experiment is locked and needs signing'],
    ['Needs Countersigning', 'Experiment is locked and needs countersigning'],
    ['Editing', 'Experiment is unlocked and in the edit mode'],
    ['Needs Amending', 'A change has been made since the last time the experiment was countersigned or amended'],
] as const;
export type HTEExperimentStatus = (typeof HTEExperimentStatuses)[number][0];

export interface HTEExperimentCreate {
    name: string;
    project: string;
    description: string;
    well_layout: WellLayout;
    conditions: ReactionConditions;
    status: HTEExperimentStatus;
    reaction_chemistry: string;
    labware: string;
    hidden?: boolean;
}

export interface HTESignature {
    full_name: string;
    comment?: string;
    acknowledged_policy: boolean;
    signed_by: string;
    signed_on: DateLike;
}

export const EXPERIMENT_ACKNOWLEDGEMENT =
    'I understand I am about to provide my electronic signature to this record. I confirm I have performed the work described here. I understand that electronic signatures are legally binding and have the same meaning as handwritten signatures.';

export interface HTEFinalization {
    full_name: string;
    acknowledged_policy: boolean;
    comment?: string;
    finalized_by: string;
    finalized_on: DateLike;
}

export interface HTEExperimentInfo extends HTEExperimentCreate {
    id: number;
    created_by: string;
    created_on: DateLike;
    executed_on?: DateLike;
    modified_on: DateLike;
    locked_on?: DateLike;
    crude_product_plate_id?: number;
    purified_product_plate_id?: number;
    procedure?: string;
    observations?: string;
    finalization?: HTEFinalization;

    design_user_state?: { version: number; data: Uint8Array };
    plate_ids: number[];
}

export function getExperimentCreate(experiment: HTEExperiment, status: HTEExperimentStatus): HTEExperimentCreate {
    return {
        name: experiment.details.library_name,
        project: experiment.details.project,
        description: experiment.details.description,
        well_layout: experiment.details.well_layout,
        conditions: {
            atmosphere: experiment.settings.atmosphere,
            duration: experiment.settings.duration,
            nominal_volume: experiment.settings.nominal_volume,
            stir_rate: experiment.settings.stir_rate,
            temperature: experiment.settings.temperature,
        },
        status,
        reaction_chemistry: experiment.settings.reaction_chemistry,
        labware: experiment.settings.labware,
    };
}

export function createEmptyPlate(layout: WellLayout, well_volume_range: [number, number]): Plate {
    const wells = [] as Well[];
    for (let i = 0; i < layout; i++) {
        wells[i] = [];
    }

    return {
        layout,
        wells,
        well_volume_range,
    };
}

export function createExperiment(options: {
    library_name: string;
    layout: WellLayout;
    project: string;
}): HTEExperiment {
    const nominal_volume_l = options.layout <= 96 ? 800e-6 : 24e-6;

    const { labware, volume: reaction_scale } = PlateInfo[options.layout];
    const labwareName = Object.keys(labware)[0];

    return {
        details: {
            library_name: options.library_name,
            description: '',
            project: options.project,
            well_layout: options.layout,
        },
        settings: {
            stir_rate: (1000 * 2 * Math.PI) / 60, // '1000 rpm',
            temperature: 293.16,
            atmosphere: 'N2',
            nominal_volume: nominal_volume_l * 1e-3, // to m**3
            duration: 1,
            reaction_scale,
            reaction_chemistry: 'Unknown',
            labware: labwareName,
        },
        design: {
            plate: createEmptyPlate(options.layout, labware[labwareName].wellVolumeRange),
            reactants: [],
            reactions: [],
        },
    };
}

interface LabwareInfo {
    label: string;
    wellVolumeRange: [number, number];
}

interface _PlateInfo {
    amount: number;
    volume: number;
    totalAmount: number;
    totalVolume: number;
    labware: Record<string, LabwareInfo>;
}

export const PlateInfo: Record<WellLayout, _PlateInfo> = {
    24: {
        amount: 1e-3,
        volume: 1e-3,
        totalAmount: 1e-3,
        totalVolume: 1e-3,
        labware: {
            hamilton_rack: { label: 'Hamiton Rack with One Dram Vials', wellVolumeRange: [0, 3.6e-3] },
        },
    },
    96: {
        amount: 1e-3,
        volume: 1e-6,
        totalAmount: 1e-3,
        totalVolume: 1e-6,
        labware: {
            paradox_rack: { label: 'Paradox Rack + Glass reaction Tubes', wellVolumeRange: [0, 1e-3] },
        },
    },
    384: {
        amount: 1e-9,
        volume: 1e-9,
        totalAmount: 1e-6,
        totalVolume: 1e-6,
        labware: {
            lvsd_mosquito: { label: '384 LVSD Mosquito', wellVolumeRange: [0.15e-6, 60e-6] },
            labcyte_lp200: { label: 'Labcyte LP200', wellVolumeRange: [3e-6, 12e-6] },
        },
    },
    1536: {
        amount: 1e-9,
        volume: 1e-9,
        totalAmount: 1e-6,
        totalVolume: 1e-6,
        labware: {
            labcyte_lp400: { label: 'Labcyte LP400', wellVolumeRange: [1e-6, 5.5e-6] },
        },
    },
};

export function formatUnitPrefix(factor: number, pretty = true) {
    if (factor === 1e-3) return 'm';
    if (factor === 1e-6) return pretty ? 'μ' : 'u';
    if (factor === 1e-9) return 'n';
    return `e${Math.round(Math.log10(factor))}`;
}

export const HTESolvents = [
    'Acetone',
    'CPME',
    'Cyclohexane',
    'DCE',
    'DCM',
    'Diethyl ether',
    'Dioxane',
    'DMA',
    'DME',
    'DMF',
    'DMSO',
    'Ethanol',
    'EtOAc',
    'Heptane',
    'IPrOH',
    'MeCN',
    'MeOH',
    '2-MeTHF',
    'MTBE',
    'NMP',
    'PhCF3',
    'PhOMe',
    'Pyridine',
    'tBuOH',
    'THF',
    'Toluene',
    'Water',
] as const;
export type HTESolvent = (typeof HTESolvents)[number];

export const _solventSet = new Set(HTESolvents);
export function isHTESolvent(r: Reactant | string) {
    return _solventSet.has((typeof r === 'string' ? r : r.identifier) as HTESolvent);
}

export function formatHTEId(id: number) {
    return `HTE${id}`;
}

export const rpmToRadS = (2 * Math.PI) / 60;
export function formatRPM(v: number) {
    return roundValue(2, v / rpmToRadS).toString();
}

export interface ReactionComponentSample {
    batch_id: number;
    parent_sample_id?: number;
    solute_mass: number | string;
    solvent: HTESolvent;
    solvent_volume: number | string; // m**3
    concentration: number | string;
    reactant_type: string;
    reactant_order?: number;
}

export interface FoundryReactionCreate {
    batch_id: number;
    conditions: ReactionConditions;
    reactants: ReactionComponentSample[];
    // TODO: (separate PR) remove these 2 _id fields once Foundry has been updated.
    msd_batch_id?: number;
    bb_batch_id?: number;
    msd_sample?: ReactionComponentSample;
    bb_sample?: ReactionComponentSample;
    reaction_chemistry?: string;
}

export interface FoundryReaction extends FoundryReactionCreate {
    id: number;
    uploaded_by: string;
    uploaded_on: DateLike;
}

export interface HTEAmendment {
    hte_experiment_id: number;
    comment: string;
    artifacts: ArtifactDB[];

    id: number;
    amended_by: string;
    amended_on: DateLike;
}
