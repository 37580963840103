import { faCheck, faExclamation, faRobot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import saveAs from 'file-saver';
import { ReactNode } from 'react';
import { Badge, Popover } from 'react-bootstrap';
import { Column, ColumnFilters, DataTableModel, ObjectDataTableStore } from '../../../components/DataTable';
import { TableCellPopover } from '../../../components/DataTable/common';
import { ClipboardService } from '../../../lib/services/clipboard';
import { ToastService } from '../../../lib/services/toast';
import { formatDatetime } from '../../../lib/util/dates';
import { ModelAction, ReactiveModel } from '../../../lib/util/reactive-model';
import { BatchLink } from '../../ECM/ecm-common';
import {
    HTEDDistributedCompound,
    HTEDistributionEntry,
    HTEFinalQCEntry,
    HTEPFraction,
    HTEPPooling,
} from '../data-model';
import type { HTE2MSPostProductionModel } from './model';

interface CompoundRowDistribution {
    distribution: HTEDistributionEntry;
    compound: HTEDDistributedCompound;
    liquid_barcode?: string;
    dry_barcode?: string;
    registered: boolean;
}

export interface CompoundRow {
    identifier_search: string;
    crude_batch_identifier: string;
    purified_batch_identifier?: string;
    pooling?: {
        crude_batch_id: number;
        purified_batch_id?: number;
        pooling: HTEPPooling;
        fraction: HTEPFraction;
        barcodes?: string[];
    };
    distribution?: CompoundRowDistribution;
    extra_distributions?: CompoundRowDistribution[];
    qc?: { barcode: string; qc: HTEFinalQCEntry; done: boolean }[];
}

export class HTE2MSPostProductionSummaryModel extends ReactiveModel {
    store = new ObjectDataTableStore<CompoundRow, CompoundRow>([
        {
            name: 'identifier_search',
            getter: (v) => v.identifier_search,
        },
        {
            name: 'crude_batch_identifier',
            getter: (v) => v.crude_batch_identifier,
        },
        {
            name: 'purified_batch_identifier',
            getter: (v) => v.purified_batch_identifier,
        },
        {
            name: 'pooling',
            getter: (v) => v.pooling,
        },
        {
            name: 'distribution',
            getter: (v) => v.distribution,
        },
        {
            name: 'extra_distributions',
            getter: (v) => v.extra_distributions,
        },
        {
            name: 'qc',
            getter: (v) => v.qc,
        },
    ]);

    table: DataTableModel<CompoundRow>;

    actions = {
        sync: new ModelAction<CompoundRow[] | undefined>({
            onError: 'toast',
            toastErrorLabel: 'Sync',
            applyResult: (r) => {
                if (!r) return;

                this.store.setRows(r);
                this.table.dataChanged();
            },
        }),
    };

    get mainModel() {
        return this.postProduction.model;
    }

    get assets() {
        return this.mainModel.assets;
    }

    exportQCBarcodes(how: 'copy' | 'save') {
        const barcodes = this.store.rawRows
            .filter((c) => c.distribution?.liquid_barcode && !c.qc?.length)
            .map((c) => c.distribution?.liquid_barcode)
            .join('\n');
        if (!barcodes) {
            return ToastService.warning('No barcodes to copy');
        }
        if (how === 'copy') {
            ClipboardService.copyText(barcodes, 'Copy Barcodes');
        } else {
            saveAs(new Blob([barcodes], { type: 'text/csv' }), `${this.mainModel.libraryId}-qc-list-${Date.now()}.csv`);
        }
    }

    private async syncData(): Promise<CompoundRow[] | undefined> {
        if (!this.mainModel.experiment?.crude_product_plate_id) return;

        await this.mainModel.assets.syncPlateData({ ids: [this.mainModel.experiment.crude_product_plate_id] });
        const crudePlate = this.mainModel.assets.plateDataById.get(this.mainModel.experiment.crude_product_plate_id);
        if (!crudePlate) {
            throw new Error('Failed to load crude plate data');
        }

        const poolings: CompoundRow['pooling'][] = [];
        const distributions: Map<number, CompoundRowDistribution> = new Map();
        const extraDistributions: Map<number, CompoundRowDistribution[]> = new Map();
        const qcs: Map<number, CompoundRow['qc']> = new Map();

        const purificationData = this.postProduction.purification.data;
        for (const pooling of purificationData.poolings) {
            for (const fraction of pooling.fractions) {
                const batch = this.mainModel.assets.entities.getBatch(fraction.batch_identifier);
                if (!batch) throw new Error(`Batch not found: ${fraction.batch_identifier}`);

                const barcodes = purificationData.states[pooling.id]?.pooled_fraction_barcodes[fraction.id];
                const purified_batch_id = this.assets.inventory.getVialSample(barcodes?.[0])?.batch_id;

                poolings.push({
                    crude_batch_id: batch.id,
                    purified_batch_id,
                    pooling,
                    fraction,
                    barcodes,
                });
            }
        }

        const distributionData = this.postProduction.distribution.data;
        for (const distribution of distributionData.distributions) {
            for (const compound of distribution.compounds) {
                const distr: CompoundRowDistribution = {
                    compound,
                    distribution,
                    liquid_barcode: distribution.liquid_barcodes[compound.id],
                    dry_barcode: distribution.dry_barcodes[compound.id],
                    registered: !!distributionData.registered[distribution.id],
                };

                if (distributions.has(compound.batch_id)) {
                    if (!extraDistributions.has(compound.batch_id)) {
                        extraDistributions.set(compound.batch_id, []);
                    }
                    extraDistributions.get(compound.batch_id)!.push(distr);
                } else {
                    distributions.set(compound.batch_id, distr);
                }
            }
        }

        const qcData = this.postProduction.qc.data;
        for (const qc of qcData.finalqcs) {
            for (const sample of qc.scripts.samples) {
                const batchId = this.mainModel.assets.inventory.getVialBatch(sample.barcode)?.id;
                if (typeof batchId !== 'number') throw new Error(`Batch not found for sample: ${sample.barcode}`);

                if (!qcs.has(batchId)) qcs.set(batchId, []);
                qcs.get(batchId)!.push({
                    qc,
                    barcode: sample.barcode,
                    done: !!qcData.registered_finalqcs[qc.id],
                });
            }
        }

        const rows: CompoundRow[] = [];
        const purifiedCrudeBatchIds = new Set<number>();

        for (const pooling of poolings) {
            const crudeBatch = this.assets.entities.getBatch(pooling!.crude_batch_id!);
            const purifiedBatch = this.assets.entities.getBatch(pooling!.purified_batch_id!);
            const identifier_search = `${
                this.assets.entities.getIdentifiersLookup(crudeBatch?.universal_identifier) ?? ''
            }\n${this.assets.entities.getIdentifiersLookup(purifiedBatch?.universal_identifier) ?? ''}`;
            rows.push({
                identifier_search,
                crude_batch_identifier: this.assets.entities.getBatch(pooling!.crude_batch_id)?.universal_identifier!,
                purified_batch_identifier: purifiedBatch?.universal_identifier,
                pooling,
                distribution: distributions.get(pooling!.purified_batch_id!),
                extra_distributions: extraDistributions.get(pooling!.purified_batch_id!),
                qc: qcs.get(pooling!.purified_batch_id!),
            });
            purifiedCrudeBatchIds.add(pooling!.crude_batch_id);
        }

        for (const sample of crudePlate.samples) {
            if (!sample || purifiedCrudeBatchIds.has(sample.batch_id)) continue;
            const crudeBatch = this.assets.entities.getBatch(sample.batch_id);

            rows.push({
                identifier_search: this.assets.entities.getIdentifiersLookup(crudeBatch?.universal_identifier),
                crude_batch_identifier: crudeBatch?.universal_identifier!,
            });
        }

        return rows;
    }

    mount() {
        this.actions.sync.run(this.syncData());
    }

    constructor(public postProduction: HTE2MSPostProductionModel) {
        super();

        this.table = new DataTableModel<CompoundRow>(this.store, {
            columns: {
                identifier_search: {
                    ...Column.str(),
                    render: ({ value }) => value,
                },
                crude_batch_identifier: {
                    ...Column.str(),
                    header: 'Crude Identifier',
                    render: ({ value }) => (
                        <BatchLink identifier={this.assets.entities.getIdentifier(value)} withQuery />
                    ),
                    width: 180,
                },
                purified_batch_identifier: {
                    ...Column.str(),
                    header: 'Purified Identifier',
                    render: ({ value }) => {
                        if (!value) return null;
                        const forwarded = this.assets.entities.getBatch(value!)?.forward;
                        return (
                            <>
                                {forwarded && (
                                    <Badge bg='info' className='me-1 font-body-xsmall px-2 py-0 fw-bold'>
                                        FWD
                                    </Badge>
                                )}
                                <BatchLink identifier={this.assets.entities.getIdentifier(value)} withQuery />
                            </>
                        );
                    },
                    width: 180,
                },
                pooling: {
                    kind: 'generic',
                    header: 'Pooling',
                    format: (v) => '<unused>',
                    render: ({ value }) => {
                        if (!value) return null;

                        return (
                            <>
                                <FontAwesomeIcon
                                    icon={value.barcodes ? faCheck : faRobot}
                                    size='xs'
                                    className={value.barcodes ? 'text-success me-2' : 'text-warning me-2'}
                                    fixedWidth
                                />
                                {formatDatetime(value.pooling.timestamp, 'full')}
                                {value.barcodes ? ': ' : ''}
                                {value.barcodes?.join(', ')}
                            </>
                        );
                    },
                    width: 300,
                    compare: false,
                    globalFilterFn: () => (v, test) => ColumnFilters.caseInsensitiveArray(v?.barcodes, test),
                },
                distribution: {
                    kind: 'generic',
                    header: 'Distribution',
                    format: (v) => '<unused>',
                    render: ({ value, rowIndex }) => {
                        if (!value) return null;

                        const extras = this.store.getValue('extra_distributions', rowIndex);
                        let extraInfo: ReactNode | undefined;
                        if (extras?.length) {
                            extraInfo = (
                                <div className='hte2ms-postprd-checklist-extra-cell'>
                                    <TableCellPopover
                                        inBody
                                        id={`${rowIndex}`}
                                        buttonClassName='entos-averaged-assay-value'
                                        popoverHeader={<Popover.Header>Extras</Popover.Header>}
                                        buttonContent={
                                            <span className='text-warning'>
                                                <FontAwesomeIcon icon={faExclamation} size='xs' fixedWidth />
                                                {extras.length} extra
                                            </span>
                                        }
                                        popoverBody={
                                            <Popover.Body>
                                                <div className='vstack'>
                                                    {extras.map((extra, i) => (
                                                        <div key={i}>
                                                            <FontAwesomeIcon
                                                                icon={extra.registered ? faCheck : faRobot}
                                                                size='xs'
                                                                className={
                                                                    extra.registered
                                                                        ? 'text-success me-2'
                                                                        : 'text-warning me-2'
                                                                }
                                                                fixedWidth
                                                            />
                                                            {formatDatetime(extra.distribution.timestamp, 'full')}
                                                            {extra.liquid_barcode ? ': ' : ''}
                                                            {extra.liquid_barcode}
                                                            {extra.dry_barcode ? ' + ' : ''}
                                                            {extra.dry_barcode}
                                                        </div>
                                                    ))}
                                                </div>
                                            </Popover.Body>
                                        }
                                    />
                                </div>
                            );
                        }

                        return (
                            <>
                                <FontAwesomeIcon
                                    icon={value.registered ? faCheck : faRobot}
                                    size='xs'
                                    className={value.registered ? 'text-success me-2' : 'text-warning me-2'}
                                    fixedWidth
                                />
                                {formatDatetime(value.distribution.timestamp, 'full')}
                                {value.liquid_barcode ? ': ' : ''}
                                {value.liquid_barcode}
                                {value.dry_barcode ? ' + ' : ''}
                                {value.dry_barcode}
                                {extraInfo}
                            </>
                        );
                    },
                    width: 400,
                    compare: false,
                    globalFilterFn: () => (v, test) => ColumnFilters.caseInsensitive(v?.liquid_barcode, test),
                },
                qc: {
                    kind: 'generic',
                    header: 'FinalQC',
                    format: (v) => '<unused>',
                    render: ({ value: qcs, rowIndex }) => {
                        const value = qcs?.[0];
                        if (!value) return null;

                        let extra: ReactNode | undefined;

                        if (qcs.length > 1) {
                            extra = (
                                <div className='hte2ms-postprd-checklist-extra-cell'>
                                    <TableCellPopover
                                        inBody
                                        id={`${rowIndex}`}
                                        buttonClassName='entos-averaged-assay-value'
                                        popoverHeader={<Popover.Header>FinalQC</Popover.Header>}
                                        buttonContent={<span className='text-info'>({qcs.length} total)</span>}
                                        popoverBody={
                                            <Popover.Body>
                                                <div className='vstack'>
                                                    {qcs.map((qc, i) => (
                                                        <div>
                                                            <FontAwesomeIcon
                                                                icon={qc.done ? faCheck : faRobot}
                                                                size='xs'
                                                                className={
                                                                    qc.done ? 'text-success me-2' : 'text-warning me-2'
                                                                }
                                                                fixedWidth
                                                            />
                                                            {formatDatetime(qc.qc.timestamp, 'full')}
                                                            {qc.barcode ? ': ' : ''}
                                                            {qc.barcode}
                                                        </div>
                                                    ))}
                                                </div>
                                            </Popover.Body>
                                        }
                                    />
                                </div>
                            );
                        }

                        return (
                            <>
                                <FontAwesomeIcon
                                    icon={value.done ? faCheck : faRobot}
                                    size='xs'
                                    className={value.done ? 'text-success me-2' : 'text-warning me-2'}
                                    fixedWidth
                                />
                                {formatDatetime(value.qc.timestamp, 'full')}
                                {value.barcode ? ': ' : ''}
                                {value.barcode}
                                {extra}
                            </>
                        );
                    },
                    width: 340,
                    compare: false,
                    disableGlobalFilter: true,
                },
            },
            globalFilterHiddenColumns: true,
            hideNonSchemaColumns: true,
        });

        this.table.setColumnVisibility('identifier_search', false);
    }
}
