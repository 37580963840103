import { MutableRefObject, ReactNode, useCallback, useMemo, useState } from 'react';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import { BehaviorSubject, Subject } from 'rxjs';
import { LabeledInput } from '../../../components/common/Inputs';
import { LogModel } from '../../../components/common/Log';
import { PropertyNameValue } from '../../../components/common/PropertyNameValue';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import { ToastService } from '../../../lib/services/toast';
import { reportErrorAsToast, tryGetErrorMessage } from '../../../lib/util/errors';
import { formatUnit } from '../../../lib/util/units';
import { formatSampleContentInline, SampleCreate, Vial } from '../ecm-api';
import { ECMCommonUploadInfo, ECMPageTemplate, ECMWorkflowMode, ECMWorkflowModeSelect } from '../ecm-common';
import {
    ecmAPITemplate,
    ECMBatchWorkflowBase,
    ECMBatchWorkflowWrapper,
    ecmGatherMessages,
    ECMManualWorkflowBase,
    ECMManualWorkflowInputHelper,
    ECMManualWorkflowStatus,
    ECMManualWorkflowWrapper,
    ecmWorkflowEntrySummary,
    uploadECMCSV,
} from './common';

export interface PrepareTransferInput {
    row_index?: number;
    source_barcode?: string;
    target_barcode?: string;
    current_mass?: number;
    current_mass_unit?: string;
    amount?: number;
    amount_unit?: string;
    volume?: number;
    volume_unit?: string;
}

interface PrepareTransferEntry {
    input: PrepareTransferInput;

    errors: string[];
    warnings: string[];

    source_vial?: Vial;
    source_barcode?: string;
    source_sample?: SampleCreate;
    target_vial?: Vial;
    target_barcode?: string;
    target_sample?: SampleCreate;
}

type PrepareTransferEntries = PrepareTransferEntry[];

const TransferAPI = ecmAPITemplate<PrepareTransferInput[], PrepareTransferEntries>('vials/transfer');

interface ManualVialTransferWorkflowInput {
    source_barcode: string;
    target_barcode: string;
    current_mass: string;
    amount: string;
    volume: string;
}

const EmptyInput: ManualVialTransferWorkflowInput = {
    source_barcode: '',
    target_barcode: '',
    current_mass: '',
    amount: '',
    volume: '',
};

type TransferKind = 'dry-weigh' | 'dry' | 'wet';

export class ManualVialTransferWorkflow implements ECMManualWorkflowBase<PrepareTransferEntry> {
    inputs = {
        source_barcode: { current: null } as MutableRefObject<HTMLInputElement | null>,
        target_barcode: { current: null } as MutableRefObject<HTMLInputElement | null>,
        current_mass: { current: null } as MutableRefObject<HTMLInputElement | null>,
        amount: { current: null } as MutableRefObject<HTMLInputElement | null>,
        volume: { current: null } as MutableRefObject<HTMLInputElement | null>,
    };

    state = {
        input: new BehaviorSubject<ManualVialTransferWorkflowInput>(EmptyInput),
        kind: new BehaviorSubject<TransferKind>('dry-weigh'),
        entry: new BehaviorSubject<PrepareTransferEntry | undefined>(undefined),
        currentTransferAmount: new BehaviorSubject<number | undefined>(undefined),
        autosubmit: new BehaviorSubject<boolean>(false),
        preparing: new BehaviorSubject<boolean>(false),
        submitting: new BehaviorSubject<boolean>(false),
    };

    events = {
        submitted: new Subject<PrepareTransferInput>(),
    };

    log = new LogModel();
    noAutoSubmit = true;

    get autosubmit() {
        return this.state.autosubmit.value;
    }

    get isInputValid(): boolean {
        // TODO: this could be improved
        const input = this.state.input.value;
        return (
            input.source_barcode.length > 0 &&
            (input.current_mass.length > 0 || input.amount.length > 0 || input.volume.length > 0)
        );
    }

    syncCurrentAmount() {
        const entry = this.state.entry.value;
        const currentMass = +this.state.input.value.current_mass;
        if (!entry || !entry.target_vial || entry.target_vial.sample?.solvent_volume || Number.isNaN(currentMass)) {
            this.state.currentTransferAmount.next(undefined);
            return;
        }

        const transferAmount =
            currentMass / 1e3 - entry.target_vial.tare_mass - (entry.target_vial.sample?.solute_mass ?? 0);
        this.state.currentTransferAmount.next(transferAmount);
    }

    getTransferAmountAndVolume(entry: PrepareTransferEntry, currentTransferAmount?: number) {
        const transferAmount =
            currentTransferAmount ??
            (entry.target_sample
                ? ((entry.target_sample?.solute_mass as number) ?? 0) - (entry.target_vial?.sample?.solute_mass ?? 0)
                : (entry.source_vial?.sample?.solute_mass ?? 0) - ((entry.source_sample?.solute_mass as number) ?? 0));

        const transferVolume = entry.target_sample
            ? ((entry.target_sample?.solvent_volume as number) ?? 0) - (entry.target_vial?.sample?.solvent_volume ?? 0)
            : (entry.source_vial?.sample?.solvent_volume ?? 0) - ((entry.source_sample?.solvent_volume as number) ?? 0);

        return { transferAmount, transferVolume };
    }

    private prepareKey = 0;

    private getPrepareInput(): PrepareTransferInput {
        const kind = this.state.kind.value;
        const input = this.state.input.value;

        if (kind === 'dry-weigh') {
            return {
                source_barcode: input.source_barcode,
                target_barcode: input.target_barcode,
                current_mass: +input.current_mass,
                current_mass_unit: 'mg',
            };
        }

        if (kind === 'dry') {
            return {
                source_barcode: input.source_barcode,
                target_barcode: input.target_barcode,
                amount: +input.amount,
                amount_unit: 'mg',
            };
        }

        return {
            source_barcode: input.source_barcode,
            target_barcode: input.target_barcode,
            volume: +input.volume,
            volume_unit: 'uL',
        };
    }

    async prepare() {
        ToastService.remove('warn-autosubmit');

        const key = ++this.prepareKey;

        try {
            if (!this.isInputValid) {
                return;
            }

            this.state.preparing.next(true);
            const entries = await TransferAPI.prepare([this.getPrepareInput()]);

            if (key !== this.prepareKey) {
                return;
            }

            const entry = entries[0];
            this.state.entry.next(entry);
            this.state.currentTransferAmount.next(undefined);

            if (!entry) return;

            if (!entry.source_barcode) {
                requestAnimationFrame(() => this.inputs.source_barcode.current?.focus());
            }
        } catch (err) {
            if (key !== this.prepareKey) {
                return;
            }

            reportErrorAsToast('Transfer', err);
            this.state.entry.next(undefined);
        } finally {
            if (key === this.prepareKey) {
                this.state.preparing.next(false);
            }
        }
    }

    async submit() {
        const entry = this.state.entry.value;
        if (!entry) return;

        try {
            this.state.submitting.next(true);

            await TransferAPI.upload([entry]);
            // NOTE: For testing to fake upload
            // await new Promise((res) => {
            //     setTimeout(res, 500);
            // });

            const { transferAmount, transferVolume } = this.getTransferAmountAndVolume(entry);
            const amounts: string[] = [];
            if (transferAmount) amounts.push(formatUnit(transferAmount, 'g', 'm'));
            if (transferVolume) amounts.push(formatUnit(transferVolume * 1e3, 'L', 'u'));

            this.log.message(
                'success',
                `Transfered ${amounts.join('/')} from ${entry.source_barcode}${
                    entry.target_barcode ? ` to ${entry.target_barcode}` : ''
                }.`
            );
            this.state.entry.next(undefined);

            if (!this.options?.doNotAutoClear) {
                this.clear();
            }

            requestAnimationFrame(() => {
                this.events.submitted.next(entry);
            });
        } catch (err) {
            this.log.message('danger', `Error: ${tryGetErrorMessage(err)}`);
        } finally {
            this.state.submitting.next(false);
        }
    }

    clear(keepSourceBarcode = false) {
        if (keepSourceBarcode) {
            const { source_barcode } = this.state.input.value;
            this.state.input.next({
                ...EmptyInput,
                source_barcode,
            });
            requestAnimationFrame(() => {
                this.inputs.target_barcode.current?.focus();
            });
        } else {
            this.state.input.next(EmptyInput);
            requestAnimationFrame(() => {
                this.inputs.source_barcode.current?.focus();
            });
        }
    }

    constructor(public options?: { doNotAutoClear?: boolean }) {}
}

class BatchVialTransferWorkflow implements ECMBatchWorkflowBase<PrepareTransferEntries> {
    state = {
        submitting: new BehaviorSubject<boolean>(false),
    };
    entries: PrepareTransferEntries;
    summary: ECMBatchWorkflowBase['summary'];
    messages: ECMBatchWorkflowBase['messages'];

    async submit() {
        if (this.summary.numErrors > 0 || !this.entries.length) {
            return;
        }

        try {
            this.state.submitting.next(true);

            await TransferAPI.upload(this.entries);
            // NOTE: For testing to fake upload
            // await new Promise((res) => {
            //     setTimeout(res, 500);
            // });

            this.fileSubject.next(null);
            ToastService.show({
                type: 'success',
                message: `Successfully updated ${this.entries.length} vial${
                    this.entries.length === 1 ? '' : 's'
                } in Foundry.`,
            });
        } catch (err) {
            reportErrorAsToast('Transfer', err);
        } finally {
            this.state.submitting.next(false);
        }
    }

    constructor(
        public files: [filename: string, entries: PrepareTransferEntries][],
        private fileSubject: BehaviorSubject<File[] | null>
    ) {
        this.entries = files.flatMap((f) => f[1]);
        this.summary = ecmWorkflowEntrySummary(this.entries);
        this.messages = ecmGatherMessages(files);
    }
}

export function ECMTransfer() {
    const [mode, setMode] = useState<ECMWorkflowMode>('manual');

    return (
        <ECMPageTemplate page='transfer' withFooter>
            <ECMWorkflowModeSelect mode={mode} setMode={setMode} />
            {mode === 'manual' && <ManualRoot />}
            {mode === 'batch' && <BatchRoot />}
        </ECMPageTemplate>
    );
}

const ManualModel = new ManualVialTransferWorkflow();

function ManualRoot() {
    const model = ManualModel;

    return (
        <ECMManualWorkflowWrapper
            model={model}
            input={<TransferManualInput model={model} />}
            status={<TransferManualStatus model={model} />}
        />
    );
}

export function TransferManualInput({
    model,
    extra,
    footer,
    size,
}: {
    model: ManualVialTransferWorkflow;
    extra?: ReactNode;
    footer?: ReactNode;
    size?: 'sm';
}) {
    const preparing = useBehavior(model.state.preparing);
    const submitting = useBehavior(model.state.submitting);
    const disabled = preparing || submitting;
    const kind = useBehavior(model.state.kind);

    const input = useBehavior(model.state.input);

    return (
        <ECMManualWorkflowInputHelper
            size={size}
            model={model}
            disabled={disabled}
            preparing={preparing}
            footer={footer}
        >
            <LabeledInput label='Kind' className='ecm-manual-inputs-row'>
                <ButtonGroup className='w-100' size={size}>
                    <Button
                        variant={kind === 'dry-weigh' ? 'primary' : 'outline-primary'}
                        onClick={() => {
                            model.state.kind.next('dry-weigh');
                            model.inputs.source_barcode.current?.focus();
                        }}
                    >
                        Weigh
                    </Button>
                    <Button
                        variant={kind === 'dry' ? 'primary' : 'outline-primary'}
                        onClick={() => {
                            model.state.kind.next('dry');
                            model.inputs.source_barcode.current?.focus();
                        }}
                    >
                        Amount
                    </Button>
                    <Button
                        variant={kind === 'wet' ? 'primary' : 'outline-primary'}
                        onClick={() => {
                            model.state.kind.next('wet');
                            model.inputs.source_barcode.current?.focus();
                        }}
                    >
                        Wet
                    </Button>
                </ButtonGroup>
            </LabeledInput>
            <LabeledInput label='Source Barcode' className='ecm-manual-inputs-row'>
                <Form.Control
                    value={input.source_barcode}
                    ref={model.inputs.source_barcode}
                    onChange={(e) => model.state.input.next({ ...input, source_barcode: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.source_barcode) model.inputs.target_barcode.current?.focus();
                    }}
                    disabled={disabled}
                    size={size}
                    autoFocus
                />
            </LabeledInput>
            <LabeledInput
                label='Target Barcode'
                tooltip={kind !== 'dry-weigh' ? 'Optional' : undefined}
                className='ecm-manual-inputs-row'
            >
                <Form.Control
                    value={input.target_barcode}
                    ref={model.inputs.target_barcode}
                    onChange={(e) => model.state.input.next({ ...input, target_barcode: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            if (kind === 'dry-weigh' && input.target_barcode)
                                model.inputs.current_mass.current?.focus();
                            if (kind === 'dry') model.inputs.amount.current?.focus();
                            if (kind === 'wet') model.inputs.volume.current?.focus();
                        }
                    }}
                    size={size}
                    disabled={disabled}
                />
            </LabeledInput>
            {kind === 'dry-weigh' && (
                <LabeledInput label='Current Mass (mg)' className='ecm-manual-inputs-row'>
                    <Form.Control
                        value={input.current_mass}
                        ref={model.inputs.current_mass}
                        onChange={(e) => model.state.input.next({ ...input, current_mass: e.target.value })}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && input.current_mass) {
                                model.prepare();
                                model.syncCurrentAmount();
                                model.inputs.current_mass.current?.select();
                            }
                        }}
                        size={size}
                    />
                </LabeledInput>
            )}
            {kind === 'dry' && (
                <LabeledInput label='Amount (mg)' className='ecm-manual-inputs-row'>
                    <Form.Control
                        value={input.amount}
                        ref={model.inputs.amount}
                        onChange={(e) => model.state.input.next({ ...input, amount: e.target.value })}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && input.amount) {
                                model.prepare();
                            }
                        }}
                        size={size}
                    />
                </LabeledInput>
            )}
            {kind === 'wet' && (
                <LabeledInput label='Volume (μL)' className='ecm-manual-inputs-row'>
                    <Form.Control
                        value={input.volume}
                        ref={model.inputs.volume}
                        onChange={(e) => model.state.input.next({ ...input, volume: e.target.value })}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && input.volume) {
                                model.prepare();
                            }
                        }}
                        size={size}
                    />
                </LabeledInput>
            )}
            {extra}
        </ECMManualWorkflowInputHelper>
    );
}

export function TransferManualStatus({
    model,
    showSubmit,
}: {
    model: ManualVialTransferWorkflow;
    showSubmit?: boolean;
}) {
    const currentTransferAmount = useBehavior(model.state.currentTransferAmount);

    return (
        <ECMManualWorkflowStatus model={model} showSubmit={showSubmit}>
            {(entry) => {
                const { transferAmount, transferVolume } = model.getTransferAmountAndVolume(
                    entry,
                    currentTransferAmount
                );

                return (
                    <>
                        <PropertyNameValue
                            field='Source Barcode'
                            value={entry.source_barcode ?? entry.input.source_barcode}
                        />
                        <PropertyNameValue
                            field='Source Sample'
                            value={
                                <>
                                    {entry.source_vial?.sample
                                        ? formatSampleContentInline(entry.source_vial.sample)
                                        : 'n/a'}
                                    <span className='mx-1 text-secondary'>→</span>
                                    {entry.source_sample ? formatSampleContentInline(entry.source_sample) : 'n/a'}
                                </>
                            }
                        />
                        {entry.input.target_barcode && (
                            <>
                                <PropertyNameValue
                                    field='Target Barcode'
                                    value={entry.target_barcode ?? entry.input.target_barcode}
                                />
                                <PropertyNameValue
                                    field='Target Tare'
                                    value={
                                        entry.target_vial ? formatUnit(entry.target_vial.tare_mass, 'g', 'm') : 'n/a'
                                    }
                                />
                                <PropertyNameValue
                                    field='Target Sample'
                                    value={
                                        <>
                                            {entry.target_vial && entry.target_vial.sample
                                                ? formatSampleContentInline(entry.target_vial.sample)
                                                : ''}
                                            <span className='mx-1 text-secondary'>→</span>
                                            {entry.target_sample
                                                ? formatSampleContentInline(entry.target_sample)
                                                : 'n/a'}
                                        </>
                                    }
                                />
                            </>
                        )}
                        <div className='mt-2' />
                        <PropertyNameValue
                            field='Transfer Amount'
                            value={entry.source_sample ? formatUnit(transferAmount, 'g', 'm') : 'n/a'}
                        />
                        {typeof entry.source_vial?.sample?.solvent_volume === 'number' && (
                            <PropertyNameValue
                                field='Transfer Volume'
                                value={entry.source_sample ? formatUnit(transferVolume * 1e3, 'L', 'u') : 'n/a'}
                            />
                        )}
                    </>
                );
            }}
        </ECMManualWorkflowStatus>
    );
}

async function uploadCSV(files: File[] | null, fileSubject: BehaviorSubject<File[] | null>) {
    return uploadECMCSV(TransferAPI, BatchVialTransferWorkflow, files, fileSubject);
}

function BatchRoot() {
    const [model, loadModel] = useAsyncAction<BatchVialTransferWorkflow | undefined>();

    const upload = useCallback(
        (files: File[] | null, fileSubject: BehaviorSubject<File[] | null>) => loadModel(uploadCSV(files, fileSubject)),
        [loadModel]
    );
    const info = useMemo(() => <BatchUploadInfo />, []);

    return <ECMBatchWorkflowWrapper model={model} upload={upload} info={info} />;
}

function BatchUploadInfo() {
    return (
        <>
            <ECMCommonUploadInfo
                title='Dry Weigh CSV/XLS Upload'
                required={['Source Barcode', 'Target Barcode', 'Current Mass']}
                optional={['Current Mass Unit (=mg)']}
            />
            <ECMCommonUploadInfo
                title='Dry CSV/XLS Upload'
                required={['Source Barcode', 'Amount']}
                optional={['Target Barcode', 'Amount Unit (=mg)']}
            />
            <ECMCommonUploadInfo
                title='Wet CSV/XLS Upload'
                required={['Source Barcode', 'Volume']}
                optional={['Target Barcode', 'Volume Unit (=uL)']}
            />
        </>
    );
}
