import { groupBy, groupByPreserveOrder } from '../../../lib/util/misc';
import { isTubeBarcode } from '../../ECM/ecm-api';
import type { PicklistRow } from './utils';

// Example OT-2 protocols, custom labware, and links to docs are available at https://entos.atlassian.net/wiki/spaces/HP/pages/2491482210/HTE+ELN+2.0+Notes

export function buildOT2Protocol(hteId: string, rows: PicklistRow[]) {
    const tipRackCount = Math.ceil(rows.length / 96);
    const tiprackName = 'opentrons_96_tiprack_300ul';
    const tipRacks = Array.from({ length: tipRackCount }, (_, i) => `  ("${tiprackName}", ${i + 1}),`).join('\n');

    const racks = Array.from(groupBy(rows, (r) => r.source_barcode!).values());
    racks.sort((a, b) => {
        if (a.length !== b.length) return b.length - a.length;
        return a[0].source_barcode! < b[0].source_barcode! ? -1 : 1;
    });

    const plates = Array.from(groupBy(rows, (r) => r.target_barcode!).values());
    plates.sort((a, b) => {
        if (a.length !== b.length) return b.length - a.length;
        return a[0].target_barcode! < b[0].target_barcode! ? -1 : 1;
    });

    let labwareOffset = tipRackCount;
    const labware: string[] = [];
    for (const rack of racks) {
        const isTubeRack = rack.some((r) => isTubeBarcode(r.vial_barcode!));
        labware.push(
            `  "${rack[0].source_barcode!}": (${
                isTubeRack ? '"hamilton_96_tuberack_580ul"' : '"thomson_24_tuberack_3600ul"'
            }, ${++labwareOffset}),`
        );
    }

    for (const plate of plates) {
        labware.push(
            `  "${plate[0].target_barcode!}": ("spt_384_wellplate_60ul", ${++labwareOffset}),  # ${
                plate[0].source_label
            }`
        );
    }

    const distributeGroups = groupByPreserveOrder(rows, (r) => `${r.source_barcode!}:${r.source_well!}`);
    const distributes: { src: string; dest: string[] }[] = [];

    for (const g of distributeGroups) {
        distributes.push({
            src: `("${g[0].source_barcode!}", "${g[0].source_well!}")`,
            dest: g.map((r) => `("${r.target_barcode!}", "${r.target_well}", ${r.volume_ul})`),
        });
    }

    const formattedDistr = distributes.map(
        (d) => `  { 
    "src": ${d.src}, 
    "dest": [
${d.dest.map((e) => `      ${e}`).join(',\n')}
    ]
  },`
    );

    const protocol = OT2Template.replace('{{HTEID}}', hteId)
        .replace('{{tips}}', tipRacks)
        .replace('{{labware}}', labware.join('\n'))
        .replace('{{distributes}}', formattedDistr.join('\n'));

    return protocol;
}

const OT2Template = `from opentrons.protocol_api import ProtocolContext

metadata = {
  "protocolName": "HTE Build Source Plates",
  "author": "HTE Wizard",
  "source": "{{HTEID}}",
  "apiLevel": "2.13"
}

PARAMS = {
  "instrument": ("p300_single_gen2", "right"),  # kind, mount
  "default_speed": 400,  # comment out for built-in default
  "aspirate_rate": 50,  # comment out for built-in default
  "air_gap": 20,  # comment out or set to 0 to disable
  # overage can be either %, volume, or both
  # the volume is automatically capped at the max volume of the well
  "overage_percent": 0.05,  # 0.05 is 5% overage
  "overage_volume": 0,
}

TIPS = [
  # labware, position
{{tips}}
]

LABWARE = {
  # labware, position
{{labware}}
}


def run(protocol: ProtocolContext):
    plates = {
        barcode: protocol.load_labware(labware, slot).wells_by_name()
        for barcode, (labware, slot) in LABWARE.items()
    }

    tip_racks = [protocol.load_labware(t, p) for t, p in TIPS]
    instr, mount = PARAMS["instrument"]
    pipette = protocol.load_instrument(instr, mount=mount, tip_racks=tip_racks)

    if "aspirate_rate" in PARAMS:
        pipette.flow_rate.aspirate = PARAMS["aspirate_rate"]
    if "default_speed" in PARAMS:
        pipette.default_speed = PARAMS["default_speed"]

    overage_percent = 1 + PARAMS.get("overage_percent", 0.0)
    overage_volume = PARAMS.get("overage_volume", 0.0)
    air_gap = PARAMS.get("air_gap", None)

    for distr in DISTRIBUTES:
        src = plates[distr["src"][0]][distr["src"][1]]
        dest = [plates[d[0]][d[1]] for d in distr["dest"]]
        volumes = [
            min(round(overage_percent * d[2] + overage_volume, 2), w.max_volume)
            for w, d in zip(dest, distr["dest"])
        ]
        pipette.distribute(
            volumes,
            src,
            dest,
            air_gap=air_gap,
            blow_out=True,
            blowout_location="source well",
        )


DISTRIBUTES = [
{{distributes}}
]
`;
