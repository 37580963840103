import { ModeBarDefaultButtons } from 'plotly.js-dist';
import { BehaviorSubject } from 'rxjs';
import { DataTableModel } from '../../components/DataTable';
import { PaneContent, PaneHeader, PaneInner, PaneTitle } from '../../components/Pane/Pane';
import { PlotlyFigure } from '../../components/Plot';
import { Plot } from '../../components/Plot/Plot';
import type { AssayValueCreate } from '../../lib/assays/models';
import useBehavior from '../../lib/hooks/useBehavior';
import { CompoundIdentifier } from '../HTE/steps/reagents-model';
import { AssayValueDetail } from './assay-api';

interface AssayPlotPanelProps {
    valueColumn?: string;
    figureSubject: BehaviorSubject<PlotlyFigure>;
    measurement: string;
    table: DataTableModel<AssayValueDetail>;
    values: AssayValueCreate[];
    onSelection?: (selected: Plotly.PlotDatum[]) => void;
}

export default function AssayPlotPanel(props: AssayPlotPanelProps) {
    return (
        <PaneInner>
            <PaneHeader paddingIndex={2}>
                <PaneTitle title='Plot' />
            </PaneHeader>
            <PaneContent paddingIndex={0}>
                <div className='h-100 vstack gap-1'>
                    <AssayPlotPanelContent {...props} />
                </div>
            </PaneContent>
        </PaneInner>
    );
}

function AssayPlotPanelContent({
    valueColumn,
    figureSubject,
    measurement,
    table,
    values,
    onSelection,
}: AssayPlotPanelProps) {
    useBehavior(table.version);
    const figure = useBehavior(figureSubject);

    const selectedRows = table.selectedRows;
    const selectedValues = values.filter((_, idx) => !!selectedRows[idx]);
    const allBlank = selectedValues.every((v) => !v.graph);

    let message;
    if (allBlank && values.length > 0) {
        message = `Selected assay value${selectedValues.length !== 1 ? 's have ' : ' has '} no graph data.`;
    } else if (values.length === 0) {
        message = 'No assay values found.';
    }

    const modeBarButtons: ModeBarDefaultButtons[][] = [['toImage', 'zoomIn2d', 'zoomOut2d', 'resetScale2d']];
    if (selectedValues.length === 1 && table.store.hasColumn('auc')) {
        modeBarButtons[0].unshift('select2d');
    }

    return (
        <>
            <div className='flex-grow-1'>
                {message && <p className='ms-2 mt-2 text-secondary'>{message}</p>}
                {!message && <Plot figure={figure} modeBarButtons={modeBarButtons} onSelection={onSelection} />}
            </div>
            {values.length > 0 && (
                <AssayValueSummary table={table} measurement={measurement} valueColumn={valueColumn} />
            )}
        </>
    );
}

function formatLikeTableCell(
    columnName: keyof AssayValueDetail,
    rowIndex: number,
    table: DataTableModel<AssayValueDetail>
) {
    const col = table.getColumnInstance(columnName);
    if (col && !table.state.hiddenColumns.includes(columnName)) {
        const value = table.store.getValue(columnName, rowIndex);
        if (value !== undefined && !Number.isNaN(value)) {
            return col.cell(rowIndex, table);
        }
    }
    return '';
}

function AssayValueSummary({
    table,
    measurement,
    valueColumn,
}: {
    table: DataTableModel<AssayValueDetail>;
    measurement: string;
    valueColumn?: string;
}) {
    useBehavior(table.version);

    const selectedKeys = Object.keys(table.selectedRows);
    if (selectedKeys.length !== 1)
        return (
            <div className='w-100 py-1 text-center'>
                <span className='text-secondary me-2'>{selectedKeys.length} assay values selected</span>
            </div>
        );

    const selectedIdx = +selectedKeys[0];

    const columnNames = table.store.columnNames;
    const valueColumnName = valueColumn ?? columnNames.find((c) => c !== 'historic_value' && /value/.test(`${c}`));
    const value = formatLikeTableCell(valueColumnName as keyof AssayValueDetail, selectedIdx, table);
    const r2 = formatLikeTableCell('r2', selectedIdx, table);
    const r2Hidden = table.state.hiddenColumns.includes('r2');
    const min = formatLikeTableCell('min', selectedIdx, table);
    const max = formatLikeTableCell('max', selectedIdx, table);
    const slope = formatLikeTableCell('slope', selectedIdx, table);
    const obs_max = formatLikeTableCell('obs_max', selectedIdx, table);

    return (
        <div className='d-flex justify-content-center flex-grow-0 w-100 py-1 text-center text-uppercase'>
            <span className='me-2 text-secondary'>
                <CompoundIdentifier value={table.store.tryGetValue('batch_identifier', selectedIdx)} />
            </span>
            <span className='text-primary me-2'>
                {measurement}={value}
            </span>
            {r2 && !r2Hidden && (
                <span className='text-secondary'>
                    r<sup>2</sup>={r2}
                </span>
            )}
            {min && <span className='ms-2 text-secondary'>min={min}</span>}
            {max && <span className='ms-2 text-secondary'>max={max}</span>}
            {obs_max && <span className='ms-2 text-secondary'>obs. max={obs_max}</span>}
            {slope && <span className='ms-2 text-secondary'>slope={slope}</span>}
        </div>
    );
}
