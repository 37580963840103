/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { Link, NavLink, useLocation, matchPath, Location } from 'react-router-dom';
import { Breadcrumb, Button, Dropdown, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBoxOpen,
    faVials,
    faChartLine,
    faDatabase,
    faUserCircle,
    faGripHorizontal,
    faVialCircleCheck,
    faCube,
    faMinimize,
    faSun,
    faMoon,
    faShuffle,
    faArrowRightFromBracket,
    faList,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { IconDefinition, IconName } from '@fortawesome/fontawesome-svg-core';
import Toasts from '../common/Toast/Toast';
import { Dialogs } from '../common/Dialog';
import { iiChemDrawLogo, iiEntosMolecule } from '../common/Icons';
import useBehavior from '../../lib/hooks/useBehavior';
import { EcosystemService } from '../../lib/services/ecosystem';
import { ChemDrawModal } from '../common/SubstructureSearch/ChemDrawModal';
import logo from './logo.svg';
import { AuthService } from '../../lib/services/auth';
import { useAsyncAction } from '../../lib/hooks/useAsyncAction';
import { DialogService } from '../../lib/services/dialog';
import { IconButton } from '../common/IconButton';
import { getCurrentTheme } from '../theme';
import { iiAnalytical } from '../common/Icons/analytical';

export interface Page {
    title?: React.ReactNode;
    titleString?: string;
    href: string;
    icon?: React.ReactNode | IconDefinition;
    disableLink?: boolean;
    id?: string;
    apps?: {
        name: string;
        href: string;
        disabled?: boolean;
        icon?: IconDefinition;
    }[];
}

export const pages: Page[] = [
    {
        title: 'Workspaces',
        titleString: 'Workspaces',
        href: '/workspaces',
        icon: faChartLine,
        id: 'workspaces-link',
        apps: [
            {
                name: 'All Workspaces',
                href: '/workspaces/list',
                icon: faList,
            },
            {
                name: 'New Workspace',
                href: '/workspaces/new',
                icon: faPlus,
            },
        ],
    },
    {
        title: 'Compounds',
        href: '/compounds',
        icon: iiEntosMolecule,
        id: 'compound-registration-link',
    },
    {
        title: 'Assays',
        href: '/assays',
        icon: faVials,
        id: 'assay-page-link',
    },
    {
        title: 'HTE',
        href: '/hte',
        icon: faGripHorizontal,
        id: 'hte-page-link',
    },
    {
        title: 'Compound Management',
        href: '/ecm',
        icon: faVialCircleCheck,
        id: 'ecm-page-link',
        apps: [
            {
                name: 'Search',
                href: '/ecm/search',
            },
            {
                name: 'Workflows',
                href: '/ecm/workflows/tare',
            },
            {
                name: 'Requests',
                href: '/ecm/rs',
            },
            {
                name: 'ReagentDB',
                href: '/ecm/reagent-db',
            },
            {
                name: 'ChemCart Orders',
                href: '/ecm/orders/chemcart',
            },
        ],
    },
    {
        title: 'Analytical',
        href: '/analytical',
        icon: iiAnalytical,
        id: 'analytical-link',
    },
    {
        title: (
            <>
                Envision<sup className='fw-bold'>BIO</sup>
            </>
        ),
        titleString: 'EnvisionBIO',
        href: '/envision-bio',
        icon: faCube,
        id: 'envision-bio-link',
        apps: [
            {
                name: 'Pose Viewer',
                href: '/envision-bio/pose-viewer',
            },
            {
                name: 'Targets',
                href: '/envision-bio/targets',
            },
            {
                name: 'Viewer 3D',
                href: '/envision-bio/viewer3d',
            },
            {
                name: 'PoseANE',
                href: '/envision-bio/workflows/PoseANE',
            },
        ],
    },
    {
        title: 'Boxes',
        href: '/foundry-boxes',
        icon: faBoxOpen,
        id: 'box-page-link',
    },
    {
        title: 'Datasets',
        href: '/datasets',
        icon: faDatabase,
        id: 'datasets-page-link',
    },
    {
        title: 'ChemDraw',
        href: '/chemdraw',
        icon: iiChemDrawLogo,
        id: 'chemdraw-page-link',
    },
];

interface TopBarProps {
    titleIcon?: IconDefinition;
    title?: React.ReactNode;
    breadcrumb?: Page | Page[];
    button?: React.ReactNode;
}

function TopBar({ titleIcon, title, breadcrumb, button }: TopBarProps) {
    return (
        <div className='entos-top-bar d-flex justify-content-between align-items-center px-4'>
            <div className='flex-grow-1'>
                <HeaderBreadcrumbs titleIcon={titleIcon} title={title} breadcrumb={breadcrumb} />
            </div>
            <div className='d-flex align-items-center'>{button}</div>
        </div>
    );
}

interface BreadcrumbProps {
    titleIcon?: IconDefinition;
    title?: React.ReactNode;
    breadcrumb?: Page | Page[];
}

function renderBreadcrumb(page: Page, location: Location, key?: any) {
    return (
        <Breadcrumb.Item
            key={key}
            linkAs={Link}
            linkProps={{
                to: page.href,
                className: `entos-breadcrumb-${
                    matchPath({ path: page.href }, location.pathname) ? 'active' : 'inactive'
                }`,
                onClick: page.disableLink ? (e: React.MouseEvent) => e.preventDefault() : undefined,
            }}
        >
            {page.icon && <FontAwesomeIcon icon={page.icon as IconName} fixedWidth className='me-2' />}
            {page.title}
        </Breadcrumb.Item>
    );
}

function HeaderBreadcrumbs({ titleIcon, title, breadcrumb }: BreadcrumbProps) {
    const location = useLocation();
    const currentRoot = location.pathname.slice(1).split('/')[0];
    const page = pages.find((p) => p.href.slice(1) === currentRoot);

    // Not the best place to include this, but it's a one which has the
    // Page queried...
    useEffect(() => {
        let documentTitle = '';
        if (typeof page?.titleString === 'string') {
            documentTitle = page?.titleString;
        } else if (typeof page?.title === 'string') {
            documentTitle = page?.title;
        }
        document.title = `Insight ${documentTitle}`;
        return () => {
            document.title = 'Insight';
        };
    }, [page]);

    const breadcrumbs = Array.isArray(breadcrumb) ? breadcrumb : breadcrumb ? [breadcrumb] : undefined;

    return (
        <Breadcrumb className='mb-0' listProps={{ className: 'mb-0' }}>
            {page && renderBreadcrumb(page, location)}
            {!page && title && (
                <Breadcrumb.Item
                    linkAs={Link}
                    linkProps={{
                        to: '#',
                        className: 'entos-breadcrumb-active',
                    }}
                >
                    {titleIcon && <FontAwesomeIcon icon={titleIcon} fixedWidth className='me-2' />}
                    {title}
                </Breadcrumb.Item>
            )}
            {breadcrumbs?.map((b, i) => renderBreadcrumb(b, location, i))}
        </Breadcrumb>
    );
}

interface FooterProps {
    children: React.ReactNode;
}

export function Footer({ children }: FooterProps) {
    return <div className='entos-footer d-flex justify-content-between align-items-center px-4'>{children}</div>;
}

interface NavItemProps {
    page: Page;
}

function NavItem({ page }: NavItemProps) {
    const location = useLocation();

    let inner;
    if (!page.apps) {
        inner = (props: any) => (
            <NavLink to={page.href} className='entos-nav-link d-flex align-items-center' {...props}>
                <div className='flex-shrink-0 d-flex justify-content-center align-items-center'>
                    <FontAwesomeIcon className='pe-none' icon={page.icon as IconName} fixedWidth />
                </div>
            </NavLink>
        );
    } else {
        inner = (props: any) => (
            <Dropdown align='start' drop='end' className='entos-nav-dropdown'>
                <Dropdown.Toggle
                    variant='link'
                    className={`p-0 entos-nav-link entos-subnav-link d-flex align-items-center${
                        location.pathname.startsWith(`${page.href}/`) ? ' active' : ''
                    }`}
                    {...props}
                >
                    <div className='flex-shrink-0 d-flex justify-content-center align-items-center position-relative'>
                        <FontAwesomeIcon className='pe-none' icon={page.icon as IconName} fixedWidth />
                        <div
                            className={
                                location.pathname.startsWith(`${page.href}/`)
                                    ? 'entos-more-triangle entos-more-triangle-selected'
                                    : 'entos-more-triangle'
                            }
                        />
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Header className='fw-bold'>{page.title}</Dropdown.Header>
                    <Dropdown.Divider />
                    {page.apps?.map((app, i) => (
                        <Dropdown.Item key={i} to={app.href} as={NavLink} disabled={app.disabled}>
                            {app.icon && <FontAwesomeIcon icon={app.icon} size='sm' className='me-2' fixedWidth />}
                            {app.name}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    return (
        <OverlayTrigger placement='auto' overlay={<Tooltip>{page.title}</Tooltip>} delay={{ show: 500, hide: 0 }}>
            {inner}
        </OverlayTrigger>
    );
}

function SideBar() {
    const environment = useBehavior(EcosystemService.environment);
    const isLocal = window.location.hostname === 'localhost';

    return (
        <nav
            className={`entos-side-nav ${environment?.name} ${
                isLocal ? 'local' : ''
            } position-fixed top-0 start-0 d-flex flex-column justify-content-between min-vh-100`}
        >
            <div>
                <div className='entos-logo'>
                    <img src={logo} alt='Entos Logo' />
                </div>
                {pages.map((page, i) => (
                    <NavItem key={page.id ?? i} page={page} />
                ))}
            </div>
            <div>
                {environment && (isLocal || environment.name !== 'prd') && (
                    <div className='d-flex justify-content-center'>
                        <span className={`badge entos-${environment.name}-badge`}>
                            {environment.name?.toUpperCase()}
                        </span>
                    </div>
                )}
                <div className='entos-nav-link d-flex align-items-center'>
                    <AccountButton />
                </div>
            </div>
        </nav>
    );
}

function ThemeToggle() {
    const theme = getCurrentTheme();

    function toggleTheme() {
        DialogService.open({
            type: 'confirm',
            onConfirm: () => {
                localStorage.setItem('entos-insight-theme', theme === 'dark' ? 'light' : 'dark');
                // NOTE: in Bootstrap v6 there may be a way to update the theme on the fly
                // without a refresh
                // https://getbootstrap.com/docs/5.3/customize/color-modes/#javascript
                // But it is a little wonky at the moment
                window.location.reload();
            },
            title: 'Switch Theme',
            text: <p>Switching theme will reload the page. Do you want to continue?</p>,
            confirmText: 'OK',
        });
    }

    return (
        <Dropdown.Item onClick={toggleTheme} as={Button}>
            <FontAwesomeIcon icon={theme === 'dark' ? faSun : faMoon} fixedWidth className='me-1' />
            {`Switch to ${theme === 'dark' ? 'light' : 'dark'} theme`}
        </Dropdown.Item>
    );
}

function AccountButton() {
    const account = useBehavior(AuthService.account);
    const environment = useBehavior(EcosystemService.environment);
    const [state, applyChange] = useAsyncAction();

    return (
        <Dropdown align='end' drop='end' className='entos-nav-dropdown entos-account-settings'>
            <Dropdown.Toggle variant='link' className='entos-nav-link entos-subnav-link p-0 d-flex align-items-center'>
                <div className='flex-shrink-0 d-flex justify-content-center align-items-center'>
                    {!state.isLoading && <FontAwesomeIcon className='pe-none' icon={faUserCircle} fixedWidth />}
                    {state.isLoading && <Spinner animation='border' size='sm' role='status' />}
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Header className='fw-bold'>
                    Account Settings for {account ? account.name : 'Unknown User'}
                    <br />
                    Version: {getTagString()}
                    <br />
                    {!!environment?.foundry_version && `Foundry Version: ${environment.foundry_version}`}
                    <br />
                </Dropdown.Header>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => applyChange(AuthService.tryLoginPopup('change'))} as={Button}>
                    <FontAwesomeIcon icon={faShuffle} fixedWidth className='me-1' />
                    Switch Account
                </Dropdown.Item>
                <ThemeToggle />
                <Dropdown.Item disabled onClick={() => {}} as={Button}>
                    <FontAwesomeIcon icon={faArrowRightFromBracket} fixedWidth className='me-1' />
                    Logout
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

function getTagString() {
    if (process.env.REACT_APP_GIT_TAG && process.env.REACT_APP_GIT_TAG !== 'undefined') {
        return process.env.REACT_APP_GIT_TAG;
    }
    return process.env.REACT_APP_GIT_HASH || 'Unknown';
}

interface PageProps {
    // Fallback icon and title if not defined in the pages table
    titleIcon?: IconDefinition;
    title: React.ReactNode;
    breadcrumb?: Page | Page[];
    button?: React.ReactNode;
    footerContent?: React.ReactNode;
    withFooter?: boolean;
    children: React.ReactNode;
}

export function PageTemplate({
    titleIcon,
    title,
    breadcrumb,
    button,
    footerContent,
    withFooter = false,
    children,
}: PageProps) {
    return (
        <>
            <div className={`entos-content ${withFooter ? 'entos-with-footer' : ''}`}>{children}</div>
            <Toasts />
            <Dialogs />
            <ChemDrawModal />
            {footerContent && <Footer>{footerContent}</Footer>}
            <TopBar titleIcon={titleIcon} title={title} breadcrumb={breadcrumb} button={button} />
            <SideBar />
            <AuthOverlay />
        </>
    );
}

function AuthOverlay() {
    const expiration = useBehavior(AuthService.expiration);
    const [auth, applyAuth] = useAsyncAction();

    if (!expiration?.isExpired) return null;

    const relog = () => applyAuth(AuthService.tryLoginPopup('refresh'));

    return (
        <div className={`insight-expiration insight-expiration-${expiration?.minimized ? 'minimized' : 'full'}`}>
            <div className='insight-expiration-bg' />
            {!expiration?.minimized && (
                <div className='insight-expiration-minimize'>
                    <IconButton
                        icon={faMinimize}
                        className='text-secondary'
                        title='Minimize'
                        onClick={() => AuthService.expiration.next({ ...expiration!, minimized: true })}
                    />
                </div>
            )}
            <div className='text-center' style={{ zIndex: 1 }}>
                {!expiration?.minimized && <h1>Authentication Expired</h1>}
                <Button
                    variant={expiration.minimized ? 'outline-warning' : 'outline-primary'}
                    className='mx-2'
                    onClick={relog}
                    disabled={auth.isLoading}
                >
                    {auth.isLoading && <Spinner animation='border' size='sm' role='status' className='me-2' />}
                    Refresh Token
                </Button>
            </div>
        </div>
    );
}
