export const PPI_BC_384_8c_22pt_dup_1ctrl_30uM_LAYOUT = `ID,Point,Row,Column,IsControl
diluent,0,1,1,True
1,1,1,2,False
1,3,1,3,False
1,5,1,4,False
1,7,1,5,False
1,9,1,6,False
1,11,1,7,False
1,13,1,8,False
1,15,1,9,False
1,17,1,10,False
1,19,1,11,False
1,21,1,12,False
1,1,1,13,False
1,3,1,14,False
1,5,1,15,False
1,7,1,16,False
1,9,1,17,False
1,11,1,18,False
1,13,1,19,False
1,15,1,20,False
1,17,1,21,False
1,19,1,22,False
1,21,1,23,False
Control,1,1,24,True
diluent,0,2,1,True
1,2,2,2,False
1,4,2,3,False
1,6,2,4,False
1,8,2,5,False
1,10,2,6,False
1,12,2,7,False
1,14,2,8,False
1,16,2,9,False
1,18,2,10,False
1,20,2,11,False
1,22,2,12,False
1,2,2,13,False
1,4,2,14,False
1,6,2,15,False
1,8,2,16,False
1,10,2,17,False
1,12,2,18,False
1,14,2,19,False
1,16,2,20,False
1,18,2,21,False
1,20,2,22,False
1,22,2,23,False
Control,1,2,24,True
diluent,0,3,1,True
2,1,3,2,False
2,3,3,3,False
2,5,3,4,False
2,7,3,5,False
2,9,3,6,False
2,11,3,7,False
2,13,3,8,False
2,15,3,9,False
2,17,3,10,False
2,19,3,11,False
2,21,3,12,False
2,1,3,13,False
2,3,3,14,False
2,5,3,15,False
2,7,3,16,False
2,9,3,17,False
2,11,3,18,False
2,13,3,19,False
2,15,3,20,False
2,17,3,21,False
2,19,3,22,False
2,21,3,23,False
Control,1,3,24,True
diluent,0,4,1,True
2,2,4,2,False
2,4,4,3,False
2,6,4,4,False
2,8,4,5,False
2,10,4,6,False
2,12,4,7,False
2,14,4,8,False
2,16,4,9,False
2,18,4,10,False
2,20,4,11,False
2,22,4,12,False
2,2,4,13,False
2,4,4,14,False
2,6,4,15,False
2,8,4,16,False
2,10,4,17,False
2,12,4,18,False
2,14,4,19,False
2,16,4,20,False
2,18,4,21,False
2,20,4,22,False
2,22,4,23,False
Control,1,4,24,True
diluent,0,5,1,True
3,1,5,2,False
3,3,5,3,False
3,5,5,4,False
3,7,5,5,False
3,9,5,6,False
3,11,5,7,False
3,13,5,8,False
3,15,5,9,False
3,17,5,10,False
3,19,5,11,False
3,21,5,12,False
3,1,5,13,False
3,3,5,14,False
3,5,5,15,False
3,7,5,16,False
3,9,5,17,False
3,11,5,18,False
3,13,5,19,False
3,15,5,20,False
3,17,5,21,False
3,19,5,22,False
3,21,5,23,False
Control,1,5,24,True
diluent,0,6,1,True
3,2,6,2,False
3,4,6,3,False
3,6,6,4,False
3,8,6,5,False
3,10,6,6,False
3,12,6,7,False
3,14,6,8,False
3,16,6,9,False
3,18,6,10,False
3,20,6,11,False
3,22,6,12,False
3,2,6,13,False
3,4,6,14,False
3,6,6,15,False
3,8,6,16,False
3,10,6,17,False
3,12,6,18,False
3,14,6,19,False
3,16,6,20,False
3,18,6,21,False
3,20,6,22,False
3,22,6,23,False
Control,1,6,24,True
diluent,0,7,1,True
4,1,7,2,False
4,3,7,3,False
4,5,7,4,False
4,7,7,5,False
4,9,7,6,False
4,11,7,7,False
4,13,7,8,False
4,15,7,9,False
4,17,7,10,False
4,19,7,11,False
4,21,7,12,False
4,1,7,13,False
4,3,7,14,False
4,5,7,15,False
4,7,7,16,False
4,9,7,17,False
4,11,7,18,False
4,13,7,19,False
4,15,7,20,False
4,17,7,21,False
4,19,7,22,False
4,21,7,23,False
Control,1,7,24,True
diluent,0,8,1,True
4,2,8,2,False
4,4,8,3,False
4,6,8,4,False
4,8,8,5,False
4,10,8,6,False
4,12,8,7,False
4,14,8,8,False
4,16,8,9,False
4,18,8,10,False
4,20,8,11,False
4,22,8,12,False
4,2,8,13,False
4,4,8,14,False
4,6,8,15,False
4,8,8,16,False
4,10,8,17,False
4,12,8,18,False
4,14,8,19,False
4,16,8,20,False
4,18,8,21,False
4,20,8,22,False
4,22,8,23,False
Control,1,8,24,True
Control,1,9,1,True
5,1,9,2,False
5,3,9,3,False
5,5,9,4,False
5,7,9,5,False
5,9,9,6,False
5,11,9,7,False
5,13,9,8,False
5,15,9,9,False
5,17,9,10,False
5,19,9,11,False
5,21,9,12,False
5,1,9,13,False
5,3,9,14,False
5,5,9,15,False
5,7,9,16,False
5,9,9,17,False
5,11,9,18,False
5,13,9,19,False
5,15,9,20,False
5,17,9,21,False
5,19,9,22,False
5,21,9,23,False
diluent,0,9,24,True
Control,1,10,1,True
5,2,10,2,False
5,4,10,3,False
5,6,10,4,False
5,8,10,5,False
5,10,10,6,False
5,12,10,7,False
5,14,10,8,False
5,16,10,9,False
5,18,10,10,False
5,20,10,11,False
5,22,10,12,False
5,2,10,13,False
5,4,10,14,False
5,6,10,15,False
5,8,10,16,False
5,10,10,17,False
5,12,10,18,False
5,14,10,19,False
5,16,10,20,False
5,18,10,21,False
5,20,10,22,False
5,22,10,23,False
diluent,0,10,24,True
Control,1,11,1,True
6,1,11,2,False
6,3,11,3,False
6,5,11,4,False
6,7,11,5,False
6,9,11,6,False
6,11,11,7,False
6,13,11,8,False
6,15,11,9,False
6,17,11,10,False
6,19,11,11,False
6,21,11,12,False
6,1,11,13,False
6,3,11,14,False
6,5,11,15,False
6,7,11,16,False
6,9,11,17,False
6,11,11,18,False
6,13,11,19,False
6,15,11,20,False
6,17,11,21,False
6,19,11,22,False
6,21,11,23,False
diluent,0,11,24,True
Control,1,12,1,True
6,2,12,2,False
6,4,12,3,False
6,6,12,4,False
6,8,12,5,False
6,10,12,6,False
6,12,12,7,False
6,14,12,8,False
6,16,12,9,False
6,18,12,10,False
6,20,12,11,False
6,22,12,12,False
6,2,12,13,False
6,4,12,14,False
6,6,12,15,False
6,8,12,16,False
6,10,12,17,False
6,12,12,18,False
6,14,12,19,False
6,16,12,20,False
6,18,12,21,False
6,20,12,22,False
6,22,12,23,False
diluent,0,12,24,True
Control,1,13,1,True
7,1,13,2,False
7,3,13,3,False
7,5,13,4,False
7,7,13,5,False
7,9,13,6,False
7,11,13,7,False
7,13,13,8,False
7,15,13,9,False
7,17,13,10,False
7,19,13,11,False
7,21,13,12,False
7,1,13,13,False
7,3,13,14,False
7,5,13,15,False
7,7,13,16,False
7,9,13,17,False
7,11,13,18,False
7,13,13,19,False
7,15,13,20,False
7,17,13,21,False
7,19,13,22,False
7,21,13,23,False
diluent,0,13,24,True
Control,1,14,1,True
7,2,14,2,False
7,4,14,3,False
7,6,14,4,False
7,8,14,5,False
7,10,14,6,False
7,12,14,7,False
7,14,14,8,False
7,16,14,9,False
7,18,14,10,False
7,20,14,11,False
7,22,14,12,False
7,2,14,13,False
7,4,14,14,False
7,6,14,15,False
7,8,14,16,False
7,10,14,17,False
7,12,14,18,False
7,14,14,19,False
7,16,14,20,False
7,18,14,21,False
7,20,14,22,False
7,22,14,23,False
diluent,0,14,24,True
Control,1,15,1,True
8,1,15,2,False
8,3,15,3,False
8,5,15,4,False
8,7,15,5,False
8,9,15,6,False
8,11,15,7,False
8,13,15,8,False
8,15,15,9,False
8,17,15,10,False
8,19,15,11,False
8,21,15,12,False
8,1,15,13,False
8,3,15,14,False
8,5,15,15,False
8,7,15,16,False
8,9,15,17,False
8,11,15,18,False
8,13,15,19,False
8,15,15,20,False
8,17,15,21,False
8,19,15,22,False
8,21,15,23,False
diluent,0,15,24,True
Control,1,16,1,True
8,2,16,2,False
8,4,16,3,False
8,6,16,4,False
8,8,16,5,False
8,10,16,6,False
8,12,16,7,False
8,14,16,8,False
8,16,16,9,False
8,18,16,10,False
8,20,16,11,False
8,22,16,12,False
8,2,16,13,False
8,4,16,14,False
8,6,16,15,False
8,8,16,16,False
8,10,16,17,False
8,12,16,18,False
8,14,16,19,False
8,16,16,20,False
8,18,16,21,False
8,20,16,22,False
8,22,16,23,False
diluent,0,16,24,True
`;
