import {
    faAnglesUp,
    faDiceOne,
    faExclamationCircle,
    faExclamationTriangle,
    faFileExport,
    faFileImport,
    faPencil,
    faVialCircleCheck,
    faVials,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { Dropdown, Form, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Split from 'react-split-it';
import { BehaviorSubject } from 'rxjs';
import { DataTableControl, DataTableGlobalFilter, DataTableModel } from '../../../components/DataTable';
import { InlineAlert } from '../../../components/common/Alert';
import { AsyncActionButton } from '../../../components/common/AsyncButton';
import { errorRows } from '../../../components/common/Error';
import { UploadFileButton } from '../../../components/common/FileUpload/upload-button';
import { IconButton, IconDropdownButton } from '../../../components/common/IconButton';
import { LabeledInput, SimpleSelectOptionInput, TextInput } from '../../../components/common/Inputs';
import { PillNav, PillNavStep } from '../../../components/common/Nav';
import { ProtocolText } from '../../../components/common/ProtocolText';
import { ScrollBox } from '../../../components/common/ScrollBox';
import useBehavior from '../../../lib/hooks/useBehavior';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { DialogService } from '../../../lib/services/dialog';
import { useModelAction } from '../../../lib/util/reactive-model';
import { BatchLink } from '../../ECM/ecm-common';
import { TransferManualInput, TransferManualStatus } from '../../ECM/workflows/Transfer';
import { ECMManualWorkflowWrapper } from '../../ECM/workflows/common';
import { PlateVisual } from '../../HTE/plate/PlateVisual';
import { HTE2MSModel } from '../model';
import { Formatters, HTEPanel, RefreshInventoryButton } from '../utils';
import { HTE2MSDiluteModel } from './dilute';
import { DryTransfersModel } from './dry-transfers';
import { LiquidTransfersModel } from './liquid-transfers';
import { HTE2MSInventoryModel, InventoryTab } from './model';
import { HTE2MSSolubilizeModel } from './solubilize';

export function HTE2MSInventoryUI({ model }: { model: HTE2MSModel }) {
    const { inventory } = model;
    useMountedModel(inventory);
    useBehavior(model.state.readOnlyDesignAndProduction);

    return <InventoryTabsUI model={inventory} />;
}

const InventoryNavTabs: PillNavStep<InventoryTab>[] = [
    { name: 'dry', label: 'Dry Reagents' },
    { name: 'liquid', label: 'Liquid Reagents' },
    { name: 'reservoirs', label: 'Reservoirs' },
    { name: 'prepared-liquids', label: 'Prepared Liquids' },
];

const SolubulizeNavTabs: PillNavStep<InventoryTab>[] = [
    { name: 'solubilize', label: 'Solubilize' },
    { name: 'dilute', label: 'Dilute' },
];

function InventoryTabsUI({ model }: { model: HTE2MSInventoryModel }) {
    const tab = useBehavior(model.state.tab);

    return (
        <div className='d-flex flex-column h-100 ps-2'>
            <div className='hstack gap-2 me-2 pt-2 mb-2'>
                <PillNav
                    steps={InventoryNavTabs}
                    currentStep={tab}
                    onSetStep={(s) => model.state.tab.next(s)}
                    containerClassName='hte2ms-inline-tab-nav-buttons'
                />
                <PillNav
                    steps={SolubulizeNavTabs}
                    currentStep={tab}
                    onSetStep={(s) => model.state.tab.next(s)}
                    containerClassName='hte2ms-inline-tab-nav-buttons'
                />
                <div className='m-auto' />

                {typeof model.model.experiment?.id === 'number' && (
                    <Link to={`/hte/${model.model.experiment.id}/transfers`} className='btn btn-link btn-sm'>
                        <FontAwesomeIcon size='sm' className='me-2' fixedWidth icon={faVials} />
                        Concurrent Transfers
                    </Link>
                )}
                <IconDropdownButton icon={faFileExport} label='Export' size='sm' variant='link'>
                    <Dropdown.Item
                        title='Dispose labware referenced in Transfer Barcodes'
                        onClick={model.exportTransferBarcodes}
                    >
                        Dispose Transfer Labware (.csv)
                    </Dropdown.Item>
                </IconDropdownButton>
            </div>
            <div className='flex-grow-1 position-relative'>
                {tab === 'liquid' && <LiquidTransfersTab model={model} />}
                {tab === 'dry' && <DryTransfersTab model={model} />}
                {tab === 'prepared-liquids' && <PreparedLiquidsTab model={model} />}
                {tab === 'reservoirs' && <ReservoirsTab model={model} />}
                {tab === 'solubilize' && <SolubilizeTab model={model} />}
                {tab === 'dilute' && <DiluteTab model={model} />}
            </div>
        </div>
    );
}

export function LiquidTransfersTab({ model }: { model: HTE2MSInventoryModel }) {
    useMountedModel(model.liquidTransfers);
    const readOnly = model.model.readOnlyDesignAndProduction;

    const transfers = (
        <TransfersTable
            table={model.liquidTransfers.table}
            model={model.model}
            onRowClick={model.liquidTransfers.trySetCurrentTargetRowIndex}
        />
    );

    if (readOnly) return transfers;

    return (
        <Split direction='horizontal' gutterSize={6} sizes={[0.45, 0.55]}>
            <TransfersTable
                table={model.liquidTransfers.table}
                model={model.model}
                onRowClick={model.liquidTransfers.trySetCurrentTargetRowIndex}
            />
            <ECMManualWorkflowWrapper
                model={model.transfers}
                input={
                    <TransferManualInput
                        size='sm'
                        model={model.transfers}
                        extra={<TransferInfo model={model.liquidTransfers} />}
                        footer={<LocationPlate model={model.liquidTransfers} />}
                    />
                }
                status={<TransferManualStatus model={model.transfers} showSubmit />}
                inputsRatio={0.45}
                noFooter
            />
        </Split>
    );
}

export function DryTransfersTab({ model }: { model: HTE2MSInventoryModel }) {
    useMountedModel(model.dryTransfers);
    const readOnly = model.model.readOnlyDesignAndProduction;

    const transfers = (
        <TransfersTable
            model={model.model}
            table={model.dryTransfers.table}
            onRowClick={model.dryTransfers.trySetCurrentTargetRowIndex}
        />
    );

    if (readOnly) return transfers;

    return (
        <Split direction='horizontal' gutterSize={6} sizes={[0.45, 0.55]}>
            {transfers}
            <ECMManualWorkflowWrapper
                model={model.transfers}
                input={
                    <TransferManualInput
                        size='sm'
                        model={model.transfers}
                        extra={<TransferInfo model={model.dryTransfers} />}
                        footer={<LocationPlate model={model.dryTransfers} />}
                    />
                }
                status={<TransferManualStatus model={model.transfers} showSubmit />}
                inputsRatio={0.45}
                noFooter
            />
        </Split>
    );
}

function TransfersTable({
    table,
    actions,
    model,
    onRowClick,
}: {
    table: DataTableModel;
    actions?: ReactNode;
    model: HTE2MSModel;
    onRowClick?: (rowIndex: number) => any;
}) {
    useBehavior(table.version);

    return (
        <div className='d-flex flex-column h-100'>
            <div className='hstack gap-2 pb-2'>
                <div className='flex-grow-1'>
                    <DataTableGlobalFilter table={table} size='sm' />
                </div>
                <div className='flex-grow-1' />
                <RefreshInventoryButton model={model} />
                {actions}
            </div>
            <div className='flex-grow-1 position-relative'>
                <DataTableControl
                    table={table}
                    headerSize='xxsm'
                    height='flex'
                    rowSelectionMode='single'
                    className='hte2ms-reagent-table'
                    onRowClick={onRowClick}
                />
            </div>
        </div>
    );
}

function TransferInfo({ model }: { model: LiquidTransfersModel | DryTransfersModel }) {
    const targets = useBehavior(model.state.targets);
    const resolve = useModelAction(model.actions.resolveTargets);
    const applyTransfer = useModelAction(model.inventory.actions.applyTransfer);

    if (resolve.kind === 'loading' || applyTransfer.kind === 'loading') {
        return (
            <div style={{ height: 80 }} className='d-flex align-items-center justify-content-center text-secondary'>
                <Spinner animation='border' role='status' />
            </div>
        );
    }
    if (!targets) return null;

    if (targets.error) {
        return (
            <div style={{ height: 80 }} className='d-flex align-items-center justify-content-center text-secondary'>
                <LabeledInput label='' className='ecm-manual-inputs-row'>
                    <div className='text-danger'>{targets.error}</div>
                </LabeledInput>
            </div>
        );
    }

    const info = model.getCurrentTransferInfo();
    if (!info && !targets.options) return null;

    return (
        <div className='vstack mt-1 pt-1 border-top'>
            {targets.options && (
                <div className='hstack gap-1'>
                    <SimpleSelectOptionInput
                        className='flex-grow-1'
                        allowEmpty
                        options={targets.options}
                        value={targets.rowIndex!}
                        size='sm'
                        setValue={(v) => model.trySetCurrentTargetRowIndex(v)}
                    />
                    <AsyncActionButton
                        variant='outline-primary'
                        size='sm'
                        className='flex-shrink-0'
                        style={{ minWidth: 0 }}
                        action={model.useAsTransfer}
                        icon={faVialCircleCheck}
                        title='Assign current source barcode as target without transferring'
                    >
                        Apply
                    </AsyncActionButton>
                </div>
            )}
            <LabeledInput label='Identifier' className='ecm-manual-inputs-row mt-1'>
                {info?.reagent.identifier && <BatchLink identifier={info.reagent.identifier} withQuery />}
                {!info?.reagent.identifier && <span className='text-secondary ps-2'>-</span>}
            </LabeledInput>
            <LabeledInput label='Requested' className='ecm-manual-inputs-row'>
                <div style={{ whiteSpace: 'break-spaces' }}>
                    {info?.amount && <b style={{ whiteSpace: 'nowrap' }}>{info.amount}</b>}
                    {!info?.amount && <span className='text-secondary ps-2'>-</span>}
                    {model instanceof LiquidTransfersModel && (
                        <>
                            <span className='text-secondary'>{' / '}</span>
                            {info?.volume && <b style={{ whiteSpace: 'nowrap' }}>{info.volume}</b>}
                            {!info?.volume && <span className='text-secondary'>-</span>}
                            <span className='text-secondary'>{' / '}</span>
                            {info?.concentration && <b style={{ whiteSpace: 'nowrap' }}>{info.concentration}</b>}
                            {!info?.concentration && <span className='text-secondary'>-</span>}
                            <span className='text-secondary'>{' / '}</span>
                            {info?.solvent && <b style={{ whiteSpace: 'nowrap' }}>{info.solvent}</b>}
                            {!info?.solvent && <span className='text-secondary'>-</span>}
                        </>
                    )}
                </div>
            </LabeledInput>
            <LabeledInput label='Labware' className='ecm-manual-inputs-row'>
                {info?.labware && <b>{info.labware}</b>}
                {!info?.labware && <span className='text-secondary ps-2'>-</span>}
            </LabeledInput>
            <LabeledInput label='Location'>
                {!targets.plateLocation?.label && <span className='text-secondary ps-2'>-</span>}
                {!!targets.plateLocation?.label && (
                    <>
                        <b>{targets.plateLocation.label}</b>
                        {targets.plateLocation.count > 1 && (
                            <span className='text-secondary ps-1'>(and {targets.plateLocation.count - 1} more)</span>
                        )}
                    </>
                )}
            </LabeledInput>
            {!!info?.isTransferred && (
                <LabeledInput label='' className='mt-1'>
                    <span className='text-success fw-bold'>Already Transferred</span>
                </LabeledInput>
            )}
        </div>
    );
}

function LocationPlate({ model }: { model: LiquidTransfersModel | DryTransfersModel }) {
    const visual = useBehavior(model.inventory.state.locationPlate);
    const targets = useBehavior(model.state.targets);

    if (!targets?.plateLocation) return null;

    return (
        <>
            <div className='m-auto' />
            <div style={{ height: 160 }} className='w-100 px-4 mb-2'>
                <PlateVisual model={visual} />
            </div>
        </>
    );
}

function SolubilizeTab({ model }: { model: HTE2MSInventoryModel }) {
    useMountedModel(model.solubilize);

    return (
        <div className='d-flex flex-column h-100'>
            <div className='flex-grow-1 position-relative'>
                <div className='d-flex w-100 h-100'>
                    <div className='d-flex flex-grow-1 position-relative h-100'>
                        <SolubilizeProtocol model={model.solubilize} />
                    </div>
                    <div
                        className='flex-shrink-0 position-relative border-start border-top'
                        style={{ minWidth: 400, maxWidth: 400 }}
                    >
                        <HTEPanel
                            title='Solubilize'
                            controls={
                                <div className='hstack gap-2'>
                                    <OneOffSolubilize model={model.solubilize} />
                                    <UploadFileButton
                                        icon={faFileImport}
                                        title='Load Racks'
                                        className='px-0'
                                        onUpload={model.solubilize.uploadRacks}
                                        uploadLabel='Load racks to Solubilize (one or more .csv)'
                                        extensions={['.csv']}
                                        multiple
                                    />
                                    <ApplySolubilize model={model.solubilize} />
                                </div>
                            }
                        >
                            <SolubilizeControls model={model.solubilize} />
                        </HTEPanel>
                    </div>
                </div>
            </div>
        </div>
    );
}

function OneOffSolubilize({ model }: { model: HTE2MSSolubilizeModel }) {
    const open = () => {
        DialogService.open({
            type: 'generic',
            title: `One-off Solubilize`,
            confirmButtonContent: 'Load',
            model,
            defaultState: '',
            wrapOk: true,
            content: OneOffDialogContent,
            onOk: (barcode: string) => model.oneOff(barcode),
        });
    };

    return (
        <IconButton icon={faDiceOne} onClick={open} className='px-0'>
            One-off
        </IconButton>
    );
}

function OneOffDialogContent({ stateSubject }: { stateSubject: BehaviorSubject<string> }) {
    const value = useBehavior(stateSubject);
    return (
        <LabeledInput label='Barcode' labelWidth={120}>
            <TextInput autoFocus value={value} placeholder='Barcode...' setValue={(v) => stateSubject.next(v.trim())} />
        </LabeledInput>
    );
}

function ApplySolubilize({ model }: { model: HTE2MSSolubilizeModel }) {
    const info = useBehavior(model.state.info);

    const confirm = () => {
        DialogService.open({
            type: 'generic',
            title: `Apply Solubilization`,
            confirmButtonContent: 'Apply',
            model,
            defaultState: { allow_update: false },
            wrapOk: true,
            content: ApplySolubilizeDialogContent,
            onOk: (state: { allow_update: boolean }) => model.applySolubilize(state.allow_update),
        });
    };

    return (
        <IconButton
            icon={faAnglesUp}
            size='sm'
            variant='link'
            title='Update vial samples in Foundry'
            className='px-0'
            onClick={confirm}
            disabled={info.transfers.length === 0 || model.inventory.model.readOnlyDesignAndProduction}
        >
            Apply
        </IconButton>
    );
}

function ApplySolubilizeDialogContent({ stateSubject }: { stateSubject: BehaviorSubject<{ allow_update: boolean }> }) {
    const state = useBehavior(stateSubject);
    return (
        <div className='vstack gap-2'>
            <InlineAlert>
                <div>Update samples in Foundry with the corresponding solvent and concentration.</div>
                <div className='mt-2'>
                    If <b>Allow Update</b> is enabled, already solubilized samples can be adjusted.
                </div>
            </InlineAlert>
            <LabeledInput label='Allow Update' labelWidth={120}>
                <Form.Switch
                    checked={state.allow_update}
                    onChange={(e) => stateSubject.next({ ...state, allow_update: e.target.checked })}
                />
            </LabeledInput>
        </div>
    );
}

function SolubilizeControls({ model }: { model: HTE2MSSolubilizeModel }) {
    const info = useBehavior(model.state.info);
    const solubulization = useBehavior(model.state.solubilization);
    return (
        <ScrollBox>
            <div className='vstack gap-2 p-2 mb-2'>
                {!!solubulization?.errors.length && (
                    <InlineAlert variant='danger' icon={faExclamationCircle} className='font-body-small'>
                        Solubilization failed:
                        <ScrollBox maxHeight={200} className='mt-2'>
                            {solubulization.errors.map((s, i) => (
                                <div key={i} className='list-group-item'>
                                    {s}
                                </div>
                            ))}
                        </ScrollBox>
                    </InlineAlert>
                )}
                {info.not_found.length > 0 && (
                    <InlineAlert variant='warning' icon={faExclamationTriangle} className='font-body-small'>
                        Unable to match some labware in the uploaded racks to reagents. Make sure the correct{' '}
                        <b>Transfer Barcodes</b> are assigned in the <b>Transfers</b> tab. If you change the barcode
                        assignments in <b>Transfers</b> tab, you will need to re-upload the racks.
                        <ScrollBox maxHeight={200} className='mt-2'>
                            {info.not_found.map((s, i) => (
                                <div key={i} className='list-group-item'>
                                    {s}
                                </div>
                            ))}
                        </ScrollBox>
                    </InlineAlert>
                )}
                {info.racks.length === 0 && <span className='text-secondary'>No racks uploaded</span>}
                {info.solvent_locations.map((s, i) => (
                    <LabeledInput key={s.solvent} label={s.solvent} labelWidth={110}>
                        <div className='hstack gap-2 w-100'>
                            <TextInput
                                value={s.label}
                                placeholder='Label'
                                setValue={(v) =>
                                    model.state.info.next({
                                        ...info,
                                        solvent_locations: info.solvent_locations.map((x, j) =>
                                            i === j ? { ...x, label: v } : x
                                        ),
                                    })
                                }
                            />
                            <TextInput
                                value={s.well}
                                placeholder='Well'
                                setValue={(v) =>
                                    model.state.info.next({
                                        ...info,
                                        solvent_locations: info.solvent_locations.map((x, j) =>
                                            i === j ? { ...x, well: v } : x
                                        ),
                                    })
                                }
                            />
                        </div>
                    </LabeledInput>
                ))}
            </div>
        </ScrollBox>
    );
}

function SolubilizeProtocol({ model }: { model: HTE2MSSolubilizeModel }) {
    const transfers = useBehavior(model.state.picklist);
    return <ProtocolText value={transfers} className='m-2 ms-0 mt-0 flex-grow-1' save={model.save} />;
}

function PreparedLiquidsTab({ model }: { model: HTE2MSInventoryModel }) {
    useMountedModel(model.preparedLiquids);

    return (
        <div className='d-flex flex-column h-100'>
            <InlineAlert className='me-2 mb-2'>
                <b>Reagents</b> with <b>No Transfer</b> labware assigned (e.g., Verso tubes)
            </InlineAlert>
            <div className='flex-grow-1 position-relative'>
                <TransfersTable
                    model={model.model}
                    table={model.preparedLiquids.table}
                    actions={
                        <IconDropdownButton icon={faFileExport} label='Export' size='sm' variant='link'>
                            <Dropdown.Item onClick={() => model.preparedLiquids.exportBarcodes('copy')}>
                                Copy Barcodes
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => model.preparedLiquids.exportBarcodes('save')}>
                                Save Barcodes (.csv)
                            </Dropdown.Item>
                        </IconDropdownButton>
                    }
                />
            </div>
        </div>
    );
}

function ReservoirsTab({ model }: { model: HTE2MSInventoryModel }) {
    useMountedModel(model.reservoirs);

    return (
        <div className='d-flex flex-column h-100'>
            <InlineAlert className='me-2 mb-2'>
                <b>Reagents</b> with <b>Reservoir</b> labware assigned. Use the <b>Container</b> and <b>Well</b> columns
                to specify final location of the sample.
            </InlineAlert>
            <div className='flex-grow-1 position-relative'>
                <TransfersTable model={model.model} table={model.reservoirs.table} />
            </div>
        </div>
    );
}

function DiluteTab({ model }: { model: HTE2MSInventoryModel }) {
    useMountedModel(model.dilute);

    return (
        <div className='d-flex flex-column h-100'>
            <div className='flex-grow-1 position-relative'>
                <div className='d-flex w-100 h-100'>
                    <div className='d-flex flex-grow-1 position-relative h-100'>
                        <DiluteInfo model={model.dilute} />
                    </div>
                    <div
                        className='flex-shrink-0 position-relative border-start border-top ms-2'
                        style={{ minWidth: 400, maxWidth: 400 }}
                    >
                        <HTEPanel title='Dilute' controls={<ApplyDilutionButton model={model.dilute} />}>
                            <DiluteControls model={model.dilute} />
                        </HTEPanel>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ApplyDilutionButton({ model }: { model: HTE2MSDiluteModel }) {
    const prepared = useModelAction(model.actions.prepare);
    const busy = useBehavior(model.inventory.model.state.busy);

    return (
        <IconButton
            icon={faAnglesUp}
            size='sm'
            variant='link'
            title='Apply updates'
            className='px-0'
            onClick={model.applyDilution}
            disabled={prepared.kind !== 'result' || model.inventory.model.readOnlyDesignAndProduction || busy}
        >
            Apply
        </IconButton>
    );
}

function DiluteInfo({ model }: { model: HTE2MSDiluteModel }) {
    return <DilutedReactionsTable model={model} />;
}

function DilutedReactionsTable({ model }: { model: HTE2MSDiluteModel }) {
    useBehavior(model.table.version);

    return <DataTableControl table={model.table} height='flex' headerSize='xxsm' />;
}

function DiluteControls({ model }: { model: HTE2MSDiluteModel }) {
    const prepared = useModelAction(model.actions.prepare);
    const input = useBehavior(model.state.input);
    const busy = useBehavior(model.inventory.model.state.busy);

    const disabled = prepared.kind === 'loading';
    const labelWidth = 120;

    return (
        <div className='vstack gap-1 font-body-small m-2'>
            <LabeledInput label='Barcode' labelWidth={labelWidth}>
                <TextInput
                    autoFocus
                    value={input.barcode}
                    disabled={disabled}
                    size='sm'
                    placeholder='Barcode'
                    setValue={(v) => model.state.input.next({ ...input, barcode: v.trim() })}
                />
            </LabeledInput>
            <LabeledInput label='Concentration' labelWidth={labelWidth}>
                <TextInput
                    value={input.concentration}
                    size='sm'
                    placeholder='With unit, e.g., 150 mM'
                    setValue={(v) => model.state.input.next({ ...input, concentration: v.trim() })}
                />
            </LabeledInput>
            <div className='text-end'>
                <AsyncActionButton disabled={disabled || busy} action={() => model.prepareDilution()} size='sm'>
                    Prepare
                </AsyncActionButton>
            </div>

            {prepared.kind === 'error' && (
                <InlineAlert icon={faExclamationCircle} variant='danger' className='w-100 mt-3'>
                    {errorRows(prepared.error)}
                </InlineAlert>
            )}

            {prepared.kind === 'result' && (
                <>
                    <div className='my-2 border-top' />
                    <LabeledInput labelWidth={labelWidth} label='Identifier'>
                        <BatchLink
                            identifier={model.inventory.model.assets.entities.getIdentifier(
                                prepared.result.reagent.identifier
                            )}
                        />
                    </LabeledInput>
                    <LabeledInput labelWidth={labelWidth} label='Old Conc.'>
                        {Formatters.concentration(prepared.result.reagent.concentration)}
                    </LabeledInput>
                    <LabeledInput labelWidth={labelWidth} label='New Conc.'>
                        {Formatters.concentration(prepared.result.data.concentration)}
                    </LabeledInput>
                    <LabeledInput labelWidth={labelWidth} label='Used in'>
                        <div className='hstack w-100'>
                            <div>
                                {prepared.result.data.reaction_ids.length} reaction
                                {prepared.result.data.reaction_ids.length === 1 ? '' : 's'}
                            </div>
                            <div className='m-auto' />
                            <IconButton
                                icon={faPencil}
                                onClick={() =>
                                    model.inventory.model.showReactionDesign(prepared.result.data.reaction_ids, {
                                        onlySelected: true,
                                    })
                                }
                                className='py-0'
                            />
                        </div>
                    </LabeledInput>
                    <LabeledInput labelWidth={labelWidth} label='Changed'>
                        <div className='hstack w-100'>
                            <div>
                                {prepared.result.data.diluted_reaction_ids.length} reaction
                                {prepared.result.data.diluted_reaction_ids.length === 1 ? '' : 's'}
                            </div>
                            <div className='m-auto' />
                            <IconButton
                                icon={faPencil}
                                onClick={() =>
                                    model.inventory.model.showReactionDesign(
                                        prepared.result.data.diluted_reaction_ids,
                                        { onlySelected: true }
                                    )
                                }
                                className='py-0'
                            />
                        </div>
                    </LabeledInput>
                </>
            )}
        </div>
    );
}
