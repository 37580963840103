import * as d3 from 'd3-scale-chromatic';
import type { AssayValueCreate, AssayValueType } from '../../../lib/assays/models';
import { AssayValueTypeColumn } from '../../../lib/assays/table';
import { AsyncMoleculeDrawer } from '../../../lib/util/draw-molecules';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { stdev } from '../../../lib/util/math';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import {
    Column,
    ColumnTableData,
    columnDataTableStore,
    DataTableModel,
    DataTableStore,
    DefaultFloatColumnFormatOptions,
} from '../../../components/DataTable';
import { NumberListColumnSchema, SelectionColumn, SmilesColumn } from '../../../components/DataTable/common';
import { BatchLink } from '../../ECM/ecm-common';
import {
    AssayAPI,
    AssayDataPoints,
    AssayDetail,
    AssayValueNonCurveReview,
    getAssayValueRetiredComment,
} from '../assay-api';
import { ReviewData } from './bayes-api';
import { ToastService } from '../../../lib/services/toast';

const SmilesRowHeightFactor = 2;

function AssayValueNonCurveReviewTableSchema(
    drawer: AsyncMoleculeDrawer,
    assayList: string[]
): [colName: keyof AssayValueNonCurveReview, column: Column][] {
    return [
        [
            'batch_identifier',
            Column.create({
                kind: 'str',
                noHeaderTooltip: true,
                width: 175,
                render: ({ value }) => <BatchLink identifier={value} withQuery />,
            }),
        ],
        [
            'supplier_id',
            Column.create({
                kind: 'str',
                noHeaderTooltip: true,
            }),
        ],
        [
            'assay',
            Column.create({
                kind: 'str',
                width: 250,
                noHeaderTooltip: true,
                render: ({ value }) => {
                    const color = assayList.length > 1 ? d3.schemeCategory10[assayList.indexOf(value) % 10] : undefined;
                    return <span style={{ color }}>{value}</span>;
                },
            }),
        ],
        ['value', AssayValueTypeColumn({ columnName: 'Value' })],
        ['historic_value', AssayValueTypeColumn({ columnName: 'Historic Value' })],
        [
            'plate_barcode',
            Column.create({
                kind: 'str',
                noHeaderTooltip: true,
            }),
        ],
        [
            'min_well_location',
            Column.create({
                kind: 'str',
                noHeaderTooltip: true,
            }),
        ],
        ['y_values', NumberListColumnSchema({ defaultFormatting: DefaultFloatColumnFormatOptions })],
        [
            'y_std_dev',
            Column.create({
                ...Column.float({ defaultFormatting: { significantDigits: 3, scientific: false } }),
                noHeaderTooltip: true,
            }),
        ],
        [
            'performed_on',
            Column.create({
                ...Column.datetime({ format: 'date' }),
                noHeaderTooltip: true,
            }),
        ],
        [
            'retired_comment',
            Column.create({
                kind: 'str',
                noHeaderTooltip: true,
                width: 175,
            }),
        ],
        ['smiles', SmilesColumn(drawer, SmilesRowHeightFactor, { width: 150 })],
    ];
}

function createTable(assays: DataTableStore<AssayValueNonCurveReview>) {
    const drawer = new AsyncMoleculeDrawer();
    const assayList = Array.from(new Set(assays.getColumnValues('assay')));
    const table = new DataTableModel(assays, {
        columns: AssayValueNonCurveReviewTableSchema(drawer, assayList),
        actions: [SelectionColumn()],
        hideNonSchemaColumns: true,
        customState: { 'show-smiles': false },
    });

    const selectedRows = table.rows.filter((rowIdx) => !table.store.getValue('retired_comment', rowIdx));
    table.setSelection(selectedRows);

    table.sortBy('batch_identifier', false);

    table.setColumnStickiness('selection', true);

    return table;
}

export class AssayNonCurveReviewModel extends ReactiveModel {
    data: ReviewData;
    private batchToValueMap: Record<string, AssayValueCreate>;

    table: DataTableModel<AssayValueNonCurveReview>;

    async uploadValues() {
        try {
            const data = await this.finalizeValues();
            if (!data) return false;
            const results = await Promise.all(data.map((d) => AssayAPI.create(d)));
            const allSuccess = results.every((r) => r);
            return allSuccess;
        } catch (err) {
            reportErrorAsToast('Error uploading values', err);
            return false;
        }
    }

    async finalizeValues(): Promise<AssayDataPoints[]> {
        const assayIds: number[] = [];
        for (const d of this.data.uploadData) {
            for (let i = 0; i < d.values.length; i++) assayIds.push(d.info.id);
        }
        const updatedValues = this.data.uploadData.flatMap((d) => d.values);
        for (let i = 0; i < updatedValues.length; i++) {
            const value = updatedValues[i];
            const index = this.table.store.findValueIndex('batch_identifier', value.batch_identifier);
            const selected = !!this.table.selectedRows[index];
            value.details.insight_is_selected = selected;
            value.retired_message = this.table.store.getValue('retired_comment', index);
        }

        try {
            const finalizedValues = await AssayAPI.finalizeUpload({ assay_values: updatedValues });
            const data = this.data.uploadData.map((d) => ({
                info: d.info,
                values: finalizedValues.filter((_, idx) => assayIds[idx] === d.info.id),
            }));
            return data;
        } catch (err) {
            reportErrorAsToast('Error finalizing values', err);
            return [];
        }
    }

    private buildRowsForAssay(
        assay: AssayDetail,
        values: AssayValueCreate[],
        historicValues: DataTableStore<{ identifier: string; [assay_id: number]: AssayValueType }>
    ): AssayValueNonCurveReview[] {
        const result: AssayValueNonCurveReview[] = [];
        for (const v of values) {
            const rowIdx = historicValues.findValueIndex('identifier', v.batch_identifier.split('-')[0]);
            const batch = this.data.identifierToBatchMap[v.batch_identifier];

            if (!batch) {
                ToastService.error(`Batch not found for ${v.batch_identifier}`);
                continue;
            }

            const historicValue = historicValues.hasColumn(assay.id)
                ? historicValues.getValue(assay.id, rowIdx)
                : undefined;

            const row: AssayValueNonCurveReview = {
                smiles: batch.structure.smiles,
                batch_identifier: v.batch_identifier,
                supplier_id: batch.supplier_id,
                assay: assay.shorthand,
                value: v.value,
                historic_value: historicValue,
                ...getYValueData(assay, v),
                performed_on: v.performed_on,
                retired_comment: getAssayValueRetiredComment(v, true),
            };

            if (v.details.plate_barcode) {
                row.plate_barcode = v.details.plate_barcode;
            }

            if (v.details.min_well_location) {
                row.min_well_location = v.details.min_well_location;
            }

            result.push(row);
        }
        return result;
    }

    constructor(data: ReviewData) {
        super();

        this.data = data;
        this.batchToValueMap = {};
        for (const d of data.uploadData) {
            for (const v of d.values) {
                this.batchToValueMap[v.batch_identifier] = v;
            }
        }

        const toTable = data.uploadData.flatMap((d) => this.buildRowsForAssay(d.info, d.values, data.historicValues));
        const columns = toTable.length > 0 ? (Object.keys(toTable[0]) as (keyof AssayValueNonCurveReview)[]) : [];
        const tableData: ColumnTableData<AssayValueNonCurveReview> = {
            columns,
            index: toTable.map((_, i) => i),
            data: columns.map((c: keyof AssayValueNonCurveReview) =>
                toTable.map((v: Partial<AssayValueNonCurveReview>) => v[c])
            ),
        };

        this.table = createTable(columnDataTableStore(tableData));

        this.subscribe(this.table.version, () => {
            for (const rowIdx of this.table.rows) {
                if (this.table.selectedRows[rowIdx]) {
                    // Even if the value was initially determined to be auto-retireable,
                    // if the user re-selects it, we will clear the retired comment
                    // and allow the value to be uploaded
                    this.table.store.setValue('retired_comment', rowIdx, '');
                } else {
                    const identifier = this.table.store.getValue('batch_identifier', rowIdx);
                    const v = this.batchToValueMap[identifier];
                    if (!v) continue;
                    const comment = getAssayValueRetiredComment(v, false);
                    this.table.store.setValue('retired_comment', rowIdx, comment);
                }
            }
        });
    }
}

function getYValueData(assay: AssayDetail, v: AssayValueCreate) {
    const shouldShowYData = assay.property.kind === 'Inhibition' && assay.property.measurement === '%';
    if (!shouldShowYData) return undefined;
    const yValues = v.graph?.data.map((trace) => trace.y).flat(2) ?? [];
    if (yValues.length === 0) return undefined;
    const yStdev = stdev(yValues) as number;
    return {
        y_values: yValues,
        y_std_dev: yStdev,
    };
}
