import { BehaviorSubject, combineLatest, distinctUntilChanged, map, throttleTime } from 'rxjs';
import { Column, DataTableModel, ObjectDataTableStore } from '../../../components/DataTable';
import { isBatchEntity } from '../../../lib/services/assets';
import { ToastService } from '../../../lib/services/toast';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { isRelativelyClose } from '../../../lib/util/math';
import { ModelAction, ReactiveModel } from '../../../lib/util/reactive-model';
import { isVersoTubeBarcode } from '../../ECM/ecm-api';
import { PrepareTransferInput } from '../../ECM/workflows/Transfer';
import { HTEPReagent } from '../data-model';
import { LiquidReagentRow } from '../protocol/liquid-reagents';
import { Formatters } from '../utils';
import {
    SelectBarcodeCell,
    commonLiquidReagentsColumnSchema,
    renderBarcode,
    viewReagentDesignAction,
} from '../utils/reagent-table';
import type { CurrentTransferInfo, HTE2MSInventoryModel, TransferTargets } from './model';

export class LiquidTransfersModel extends ReactiveModel {
    store = new ObjectDataTableStore<LiquidReagentRow, HTEPReagent>([
        { name: 'identifier', getter: (v) => v.identifier },
        {
            name: 'amount_g',
            getter: (v) => this.reagentsModel.finalRequired.get(v)?.amount_g ?? this.reagentsModel.getInfo(v)?.amount_g,
        },
        {
            name: 'volume_l',
            getter: (v) => this.reagentsModel.finalRequired.get(v)?.volume_l ?? this.reagentsModel.getInfo(v)?.volume_l,
        },
        { name: 'solvent', getter: (v) => v.solvent },
        { name: 'requested_sample', getter: (v) => this.reagentsModel.getInfo(v)?.ecm_sample },
        { name: 'concentration', getter: (v) => v.concentration },
        { name: 'transfer_barcode', getter: (v) => this.inventory.getLiquid(v)?.transfer_barcode },
        { name: 'reactant_kinds', getter: (v) => this.reagentsModel.getInfo(v)?.reactant_kinds },
        { name: 'source_barcode', getter: (v) => this.inventory.getLiquid(v)?.source_barcode },
        { name: 'labware_id', getter: (v) => this.inventory.getLiquid(v)?.labware_id },
    ]);
    table: DataTableModel<LiquidReagentRow>;

    state = {
        targets: new BehaviorSubject<TransferTargets | undefined>(undefined),
    };

    actions = {
        resolveTargets: new ModelAction({
            onError: (err) => {
                this.state.targets.next(err);
                reportErrorAsToast('Resolve vial', err);
            },
            toastErrorLabel: 'Apply transfer',
            applyResult: (t) => this.state.targets.next(t),
        }),
    };

    get reagentsModel() {
        return this.inventory.model.protocol.reagents;
    }

    get mainModel() {
        return this.inventory.model;
    }

    private update() {
        const protocol = this.mainModel.protocol.data;
        this.store.setRows(
            protocol.reagents.filter((r) => {
                if (!r.solvent) return false;
                const src = this.inventory.data.liquid[r.key]?.source_barcode;
                return !isVersoTubeBarcode(src);
            })
        );
        this.table.dataChanged();
    }

    private async resolveTargets(barcode: string, refresh = true): Promise<TransferTargets | undefined> {
        if (!barcode) {
            return;
        }

        const vial = await this.mainModel.assets.inventory.getVial(barcode, refresh);
        if (!vial?.sample) return { source_barcode: barcode, error: 'Barcode not found' };

        const batch = this.mainModel.assets.entities.batchesById.get(vial.sample.batch_id!)!;
        if (!batch) return { source_barcode: barcode, error: 'Vial batch not found' };

        const isDry = typeof vial.sample.solvent_volume !== 'number';
        const reagents = this.store.rawRows;

        const options: [number, string][] = [];

        for (let i = 0; i < reagents.length; i++) {
            const r = reagents[i];

            const entity = this.mainModel.assets.entities.getEntity(r.identifier);
            if (!entity) continue;

            const isValidTarget = isBatchEntity(entity)
                ? entity.compound_id === batch.compound_id && (batch.salt || '') === (entity.salt || '')
                : entity?.id === batch.compound_id;

            if (!isValidTarget) continue;

            if (isDry) {
                const amount_g = this.store.getValue('amount_g', i);
                options.push([i, `${r.identifier}: ${Formatters.amount(amount_g)}`]);
                continue;
            }

            if (!isRelativelyClose(vial.sample.concentration!, r.concentration!, 0.025)) continue;

            const volume_l = this.store.getValue('volume_l', i);
            options.push([
                i,
                `${r.identifier}: ${Formatters.volumeInL(volume_l)} @ ${Formatters.concentration(r.concentration)}`,
            ]);
        }

        if (options.length === 0) {
            return { source_barcode: barcode, error: 'No matching reagents found', options };
        }

        const rowIndex = this.tryFindNextAvailableRow(options, barcode);
        return {
            source_barcode: barcode,
            options,
            rowIndex,
            plateLocation: this.inventory.syncLocationPlate(reagents[rowIndex!]),
        };
    }

    useAsTransfer = async () => {
        this.inventory.actions.applyTransfer.run(this.useAsTransfers());
    };

    private async useAsTransfers() {
        const target = this.state.targets.value;
        if (typeof target?.rowIndex !== 'number' || !target.source_barcode) return;

        const reagent = this.store.rawRows[target.rowIndex];

        if (this.mainModel.transferMode) {
            await this.inventory.updateTransferBarcode('liquid', reagent.key, target.source_barcode);
        } else {
            await this.inventory.update({ liquid: [[reagent, { transfer_barcode: target.source_barcode }]] });
        }
        this.table.dataChanged();

        let rowIndex: number | undefined;
        if (target.options) {
            rowIndex = this.tryFindNextAvailableRow(target.options!, target.source_barcode);
        }
        this.inventory.transfers.clear(rowIndex !== undefined);
        if (rowIndex !== undefined) {
            this.state.targets.next({
                ...target,
                rowIndex,
                plateLocation: this.inventory.syncLocationPlate(this.store.rawRows[rowIndex!]),
            });
        } else {
            this.state.targets.next(undefined);
        }

        ToastService.show({
            type: 'success',
            message: `Assigned ${target.source_barcode} to ${this.mainModel.assets.entities.getIdentifier(
                reagent.identifier
            )}`,
            timeoutMs: 1500,
        });
    }

    private tryFindNextAvailableRow(options: [rowIndex: number, label: string][], barcode: string) {
        let rowIndex: number | undefined;

        // Try finding exact match first
        for (const [idx] of options) {
            const r = this.store.rawRows[idx];
            const inv = this.inventory.data.liquid[r.key];
            if (inv?.source_barcode === barcode && !inv?.transfer_barcode) {
                return idx;
            }
            if (inv?.transfer_barcode === barcode) {
                return idx;
            }
        }
        if (rowIndex === undefined) {
            for (const [idx] of options) {
                const r = this.store.rawRows[idx];
                const inv = this.inventory.data.liquid[r.key];
                if (!inv?.transfer_barcode) {
                    return idx;
                }
            }
        }
    }

    private async applyTransfer(input: PrepareTransferInput) {
        if (!input.target_barcode) {
            ToastService.show({ type: 'danger', message: 'No target barcode provided', timeoutMs: 2500 });
            return this.inventory.transfers.clear();
        }

        const target = this.state.targets.value;
        if (target?.rowIndex === undefined) {
            ToastService.show({ type: 'danger', message: 'No target selected', timeoutMs: 2500 });
            return this.inventory.transfers.clear();
        }

        const reagent = this.store.rawRows[target.rowIndex];

        await this.mainModel.assets.inventory.syncInventory([reagent.identifier], true);
        if (this.mainModel.transferMode) {
            await this.inventory.updateTransferBarcode('liquid', reagent.key, input.target_barcode);
        } else {
            await this.inventory.update({ liquid: [[reagent, { transfer_barcode: input.target_barcode }]] });
        }

        // extra data changed to get the vial to show correctly
        this.table.dataChanged();

        let rowIndex: number | undefined;
        if (target.options) {
            rowIndex = this.tryFindNextAvailableRow(target.options!, target.source_barcode);
        }

        this.inventory.transfers.clear(rowIndex !== undefined);
        if (rowIndex !== undefined) {
            this.state.targets.next({
                ...target,
                rowIndex,
                plateLocation: this.inventory.syncLocationPlate(this.store.rawRows[rowIndex!]),
            });
        } else {
            this.state.targets.next(undefined);
        }

        ToastService.show({
            type: 'success',
            message: `Assigned ${input.target_barcode} to ${this.mainModel.assets.entities.getIdentifier(
                reagent.identifier
            )}`,
            timeoutMs: 1500,
        });
    }

    getCurrentTransferInfo(): CurrentTransferInfo | undefined {
        const transfer = this.state.targets.value;
        if (!transfer || typeof transfer.rowIndex !== 'number') return;

        const r = this.store.rawRows[transfer.rowIndex];
        if (!r) return;

        const labware_id = this.inventory.getLiquid(r)?.labware_id;
        const inv = this.inventory.data.liquid[r.key];
        return {
            reagent: r,
            amount: Formatters.amount(this.store.getValue('amount_g', transfer.rowIndex)),
            volume: Formatters.volumeInL(this.store.getValue('volume_l', transfer.rowIndex)),
            concentration: Formatters.concentration(r.concentration),
            solvent: r.solvent,
            labware: this.mainModel.design.labwareMap.get(labware_id!)?.label ?? labware_id,
            isTransferred: inv?.transfer_barcode === transfer.source_barcode,
        };
    }

    trySetCurrentTargetRowIndex = (rowIndex: number) => {
        const targets = this.state.targets.value;
        if (!targets) return;

        const contains = targets.options?.findIndex(([idx]) => idx === rowIndex) ?? -1;
        this.state.targets.next({
            ...targets,
            rowIndex: contains >= 0 ? rowIndex : undefined,
            plateLocation: contains >= 0 ? this.inventory.syncLocationPlate(this.store.rawRows[rowIndex!]) : undefined,
        });
    };

    mount() {
        this.update();
        this.subscribe(this.inventory.state.inventory, () => this.update());

        const transferInput = this.inventory.transfers.state.input.pipe(
            map((i) => i.source_barcode),
            throttleTime(400, undefined, { leading: false, trailing: true }),
            distinctUntilChanged()
        );

        this.subscribe(combineLatest([transferInput]), ([barcode]) =>
            this.actions.resolveTargets.run(this.resolveTargets(barcode.trim()))
        );

        this.subscribe(this.inventory.transfers.events.submitted, (entry) => {
            this.inventory.actions.applyTransfer.run(this.applyTransfer(entry));
        });

        this.subscribe(this.state.targets, (t) => {
            if (t?.rowIndex === undefined) this.table.setSelection([]);
            else this.table.setSelection([t.rowIndex]);
        });
    }

    constructor(public inventory: HTE2MSInventoryModel) {
        super();

        const columns = commonLiquidReagentsColumnSchema(inventory.model, this.store);
        const designAction = viewReagentDesignAction(inventory.model, this.store);

        this.table = new DataTableModel<LiquidReagentRow>(this.store, {
            columns: {
                identifier: columns.identifier,
                reactant_kinds: columns.reactant_kinds,
                requested_sample: columns.requested_sample,
                source_barcode: columns.source_barcode,
                transfer_barcode: {
                    ...Column.str(),
                    header: 'Transfer Barcode',
                    render: ({ value, rowIndex }) => {
                        const readOnly = this.mainModel.readOnlyDesignAndProduction;
                        if (readOnly || this.mainModel.transferMode) return renderBarcode(this.mainModel, value);

                        const reagent = this.store.rawRows[rowIndex];
                        const options = this.reagentsModel.getInfo(reagent)?.inventoryOptions;
                        const multiple = this.inventory.getBarcodeTransferTargetCount(value) > 1;

                        return (
                            <SelectBarcodeCell
                                model={this.inventory.model}
                                value={value}
                                options={options}
                                multipleError={multiple}
                                setValue={(v) =>
                                    this.inventory.update({ liquid: [[reagent, { transfer_barcode: v || undefined }]] })
                                }
                            />
                        );
                    },
                    width: 270,
                },
                labware_id: {
                    ...Column.str(),
                    header: 'Labware',
                    render: ({ value }) => this.mainModel.design.labwareMap.get(value!)?.label ?? value,
                    width: 220,
                },
            },
            hideNonSchemaColumns: true,
            actions: [designAction],
        });

        this.table.setColumnStickiness(designAction.id, true);
        this.table.setColumnStickiness('identifier', true);
    }
}
