import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';
import { tryGetErrorMessage } from '../../lib/util/errors';

interface IProps {
    header?: string;
    message: any;
    inline?: boolean;
}

export function errorRows(message: any) {
    const lines = tryGetErrorMessage(message).split('\n');
    const rows = [];
    for (let i = 0; i < lines.length - 1; i++) {
        rows.push(<Fragment key={i}>{lines[i]}</Fragment>);
        rows.push(<br key={`br${i}`} />);
    }
    if (lines.length > 0) {
        rows.push(<Fragment key='last'>{lines[lines.length - 1]}</Fragment>);
    }
    return rows;
}

export function ErrorMessage({ header, message, inline }: IProps) {
    const rows = errorRows(message);

    return (
        <div
            className={!inline ? 'w-full h-full flex justify-center items-center text-4xl m-4' : undefined}
            style={{ whiteSpace: 'pre' }}
        >
            {!inline && <h2>{header ?? 'Error'}</h2>}
            {inline && <h5>{header ?? 'Error'}</h5>}
            <div>{rows}</div>
        </div>
    );
}

export type ErrorWithMessage = { kind: 'error'; message: string };

export function isErrorWithMessage(value: unknown) {
    if (typeof value !== 'object' || value === null) return false;
    return (value as ErrorWithMessage).kind === 'error';
}

export function ErrorIconWithMessage({ value }: { value: ErrorWithMessage }) {
    return <FontAwesomeIcon icon={faExclamationCircle} size='sm' title={value.message} />;
}
