import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import Split from 'react-split-it';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import API from '../../api';
import { Dataset, DatasetPermission } from '../../api/data';
import { ErrorMessage } from '../../components/common/Error';
import Loading from '../../components/common/Loading';
import { PageTemplate } from '../../components/Layout/Layout';
import Pane from '../../components/Pane/Pane';
import { useAsyncAction } from '../../lib/hooks/useAsyncAction';
import { ReactTableModel } from '../../components/ReactTable/model';
import { formatDatetime } from '../../lib/util/dates';
import PermissionsModal from './PermissionsModal';

export default function DatasetInformation() {
    const { id } = useParams();
    const [datasetResult, loadDatasets] = useAsyncAction<Dataset>();
    const [permissionsResult, loadPermissions] = useAsyncAction<ReactTableModel<DatasetPermission>>();
    const [disablePermissions, setDisablePermissions] = useState<boolean>(true);
    const [modalOpen, setModalOpen] = useState(!id);
    const [tooltipText, setTooltipText] = useState<string>();

    function getTitle() {
        if (datasetResult.isLoading) return 'Loading...';
        if (datasetResult.error) return 'Dataset Load Error';
        if (datasetResult.result) return datasetResult.result.name;
        return '';
    }

    const location = useLocation();
    useEffect(() => {
        loadDatasets(API.datasets.get(+id!));
        loadPermissions(API.datasets.getPermissions(+id!));
    }, [id]);

    useEffect(() => {
        if (permissionsResult.result) {
            setDisablePermissions(false);
        } else if (permissionsResult.error) {
            setTooltipText('Only admins can edit dataset permissions.');
        }
    }, [permissionsResult.result, permissionsResult.error]);

    return (
        <PageTemplate
            title='Datasets'
            breadcrumb={{
                title: getTitle(),
                href: location.pathname,
            }}
        >
            <div>
                {(datasetResult.isLoading || permissionsResult.isLoading) && <Loading />}
                {datasetResult.error && (
                    <ErrorMessage
                        header='Dataset Load Error'
                        message="Foundry not available or dataset doesn't exist."
                    />
                )}
                {datasetResult.result && (
                    <div>
                        <div className='d-flex justify-content-end pe-4 mt-4 ps-4'>
                            <OverlayTrigger
                                placement='auto'
                                overlay={<Tooltip>{tooltipText}</Tooltip>}
                                delay={{ show: 500, hide: 0 }}
                            >
                                {(props) => (
                                    <Button
                                        variant='outline-primary'
                                        className='permission-lock-btn'
                                        onClick={() => setModalOpen(true)}
                                        disabled={disablePermissions}
                                        {...props}
                                    >
                                        <FontAwesomeIcon icon={faLock} fixedWidth />
                                    </Button>
                                )}
                            </OverlayTrigger>
                            {datasetResult.result.kind === 'substance' && (
                                <Link
                                    to={`/data-visualization/dataset/${datasetResult.result!.id}`}
                                    className='btn btn-primary ms-2'
                                >
                                    VISUALIZE DATA
                                </Link>
                            )}
                        </div>
                        <Split direction='vertical' gutterSize={6} sizes={[0.2, 0.4, 0.4]}>
                            <Split>
                                <Pane title='Dataset Metadata' tooltip='View dataset metadata'>
                                    <div style={{ padding: '16px' }}>
                                        <h6 className='metadata-info-title'>Dataset Name:</h6>
                                        <h6 className='metadata-info-text'>{datasetResult.result.name}</h6>
                                        <h6 className='metadata-info-title'>Dataset Kind:</h6>
                                        <h6 className='metadata-info-text'>{datasetResult.result.kind}</h6>
                                        <h6 className='metadata-info-title'>Descrption:</h6>
                                        <h6 className='metadata-info-text'>{datasetResult.result.description}</h6>
                                        <h6 className='metadata-info-title'>Created On:</h6>
                                        <h6 className='metadata-info-text'>
                                            {formatDatetime(datasetResult.result.created_on)}
                                        </h6>
                                        <h6 className='metadata-info-title'>Modified On:</h6>
                                        <h6 className='metadata-info-text'>
                                            {formatDatetime(datasetResult.result.modified_on)}
                                        </h6>
                                    </div>
                                </Pane>
                            </Split>
                        </Split>
                        <PermissionsModal
                            modalOpen={modalOpen}
                            setModalOpen={setModalOpen}
                            dataset={datasetResult.result!}
                            permissionsTable={permissionsResult.result}
                        />
                    </div>
                )}
            </div>
        </PageTemplate>
    );
}
