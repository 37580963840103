import { BehaviorSubject, combineLatest } from 'rxjs';
import { AuthService } from '../../../lib/services/auth';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { HTE2Api } from '../api';
import { HTEFinalizationData } from '../data-model';
import { type HTEWModel } from '../model';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { ToastService } from '../../../lib/services/toast';

export type CanFinalizeState =
    | { kind: 'not-ready' }
    | { kind: 'bad-user' }
    | { kind: 'invalid-input' }
    | { kind: 'already-finalized' }
    | { kind: 'yes' };

export class HTEFinalizeModel extends ReactiveModel {
    state = {
        data: new BehaviorSubject<HTEFinalizationData>({
            full_name: AuthService.currentAccount?.name ?? '',
            acknowledged_policy: false,
            comment: '',
        }),
        canFinalize: new BehaviorSubject<CanFinalizeState>({ kind: 'not-ready' }),
    };

    async finalize() {
        if (!this.state.canFinalize.value) return;

        const data = this.state.data.value;
        if (!data.full_name) {
            throw new Error('Full name is required.');
        }
        if (!data.acknowledged_policy) {
            throw new Error('You must acknowledge the policy.');
        }

        const info = await HTE2Api.finalize(this.model.foundryInfo!, {
            finalization: data,
        });
        this.model.updateInfo(info);

        ToastService.show({
            type: 'success',
            message: 'Finalized',
            timeoutMs: 1500,
        });

        try {
            await HTE2Api.notifySlack(this.model.foundryInfo!, 'done');
        } catch (e) {
            reportErrorAsToast('Notify Slack', e);
        }

        try {
            await HTE2Api.triggerSnapshot(this.model.foundryInfo!);
        } catch (e) {
            ToastService.show({
                type: 'danger',
                message: 'Failed to trigger signing snapshot creation, please contact someone to do this manually.',
                timeoutMs: false,
            });
        }
    }

    mount() {
        this.subscribe(this.model.state.dataSource, (dataSource) => {
            if (dataSource.kind !== 'foundry') return;

            if (dataSource.info.finalization) {
                this.state.data.next(dataSource.info.finalization);
            }
        });

        this.subscribe(
            combineLatest([AuthService.account, this.model.state.dataSource, this.state.data]),
            ([account, dataSrc, data]) => {
                let canFinalize: CanFinalizeState = { kind: 'yes' };

                if (dataSrc.kind !== 'foundry') {
                    canFinalize = { kind: 'not-ready' };
                } else if (!dataSrc.info.executed_on) {
                    canFinalize = { kind: 'not-ready' };
                } else if (dataSrc.info.finalization) {
                    canFinalize = { kind: 'already-finalized' };
                } else if (account?.username !== dataSrc.info.created_by) {
                    canFinalize = { kind: 'bad-user' };
                } else if (!data.full_name || !data.acknowledged_policy) {
                    canFinalize = { kind: 'invalid-input' };
                }

                this.state.canFinalize.next(canFinalize);
            }
        );
    }

    constructor(public model: HTEWModel) {
        super();
    }
}
