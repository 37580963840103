import { IconName, IconDefinition } from '@fortawesome/fontawesome-svg-core';

// This is a way to make a new icon to be treated like icons in the
// Font Awesome set - structure kind of copied from their icon definitions.
// see node_modules/@fortawesome/node_modules/@fortawesome/fontawesome-common-types/index.d.ts
// for the IconDefinition type definition

const iconName = 'ii-similar-compounds' as IconName;
const prefix = 'fas';
const width: number = 13;
const height: number = 8;
const aliases: string[] = [];
const unicode: string = '';
const svgPathData: string =
    'M3.87451 0.5C2.97941 0.5 2.12096 0.868749 1.48803 1.52513C0.855091 2.1815 0.499512 3.07174 0.499512 4H1.99951C1.99951 3.4843 2.19706 2.98972 2.54869 2.62507C2.90032 2.26042 3.37723 2.05556 3.87451 2.05556C4.37179 2.05556 4.84871 2.26042 5.20034 2.62507C5.55197 2.98972 5.74951 3.4843 5.74951 4C5.74951 4.45963 5.83681 4.91475 6.00642 5.33939C6.17603 5.76403 6.42463 6.14987 6.73803 6.47487C7.05142 6.79988 7.42348 7.05769 7.83296 7.23358C8.24243 7.40947 8.6813 7.5 9.12451 7.5C9.56772 7.5 10.0066 7.40947 10.4161 7.23358C10.8255 7.05769 11.1976 6.79988 11.511 6.47487C11.8244 6.14987 12.073 5.76403 12.2426 5.33939C12.4122 4.91475 12.4995 4.45963 12.4995 4H10.9995C10.9995 4.25535 10.951 4.5082 10.8568 4.74411C10.7626 4.98002 10.6244 5.19437 10.4503 5.37493C10.2762 5.55549 10.0695 5.69871 9.84204 5.79643C9.61456 5.89415 9.37074 5.94444 9.12451 5.94444C8.87828 5.94444 8.63447 5.89415 8.40698 5.79643C8.17949 5.69871 7.9728 5.55549 7.79869 5.37493C7.62458 5.19437 7.48647 4.98002 7.39224 4.74411C7.29801 4.5082 7.24951 4.25535 7.24951 4C7.24951 3.07174 6.89393 2.1815 6.261 1.52513C5.62806 0.868749 4.76962 0.5 3.87451 0.5Z';

export const iiSimilarCompounds: IconDefinition = {
    prefix,
    iconName,
    icon: [width, height, aliases, unicode, svgPathData],
};
