import log from 'loglevel';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { AuthService } from './lib/services/auth';
import reportWebVitals from './reportWebVitals';
import './scss/custom.scss';
import { ErrorMessage } from './components/common/Error';

const STRICT_MODE = false;

async function init() {
    const host = document.getElementById('root')!;
    try {
        await AuthService.init();
        const root = <App />;
        createRoot(host).render(STRICT_MODE ? <StrictMode>{root}</StrictMode> : root);
    } catch (err) {
        log.error(err);
        createRoot(host).render(
            <ErrorMessage
                header='Initialization Error'
                message='The Insight backend has crashed, is updating, or your internet connection is down. Please try again later.'
            />
        );
    }
}

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
