import {
    IconDefinition,
    faAdd,
    faArrowLeft,
    faArrowRight,
    faAt,
    faAtom,
    faCube,
    faDroplet,
    faEquals,
    faExclamationTriangle,
    faFan,
    faFireBurner,
    faFlask,
    faFolderOpen,
    faHand,
    faHourglassHalf,
    faI,
    faInfoCircle,
    faObjectGroup,
    faPencil,
    faRobot,
    faScaleUnbalanced,
    faSignature,
    faSquarePlus,
    faStarOfLife,
    faStopwatch,
    faTemperatureHigh,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { Button, ButtonGroup, Dropdown, Form } from 'react-bootstrap';
import Select, { createFilter } from 'react-select';
import { BehaviorSubject } from 'rxjs';
import api from '../../../api';
import { InlineAlert } from '../../../components/common/Alert';
import { AsyncButton } from '../../../components/common/AsyncButton';
import { AsyncMoleculeDrawing } from '../../../components/common/AsyncMoleculeDrawing';
import { IconButton, IconDropdownButton } from '../../../components/common/IconButton';
import { LabeledInput, TextInput } from '../../../components/common/Inputs';
import { ProjectSelect } from '../../../components/common/ProjectSelect';
import { TooltipWrapper } from '../../../components/common/Tooltips';
import { CustomSelectClassNames } from '../../../components/common/selectStyles';
import useBehavior from '../../../lib/hooks/useBehavior';
import { DialogService } from '../../../lib/services/dialog';
import { capitalizeFirst, isBlankOrEmpty, splitInput } from '../../../lib/util/misc';
import { roundValue } from '../../../lib/util/roundValues';
import { formatWithUnit, parseWithUnit } from '../../../lib/util/units';
import { asNumberOrNull } from '../../../lib/util/validators';
import { BatchLink } from '../../ECM/ecm-common';
import { SelectSolvent } from '../../HTE/steps/reactants-model';
import { HTE2MSApi } from '../api';
import { HTE2MSReactionChemistryOption } from '../assets';
import { HTEDReaction, HTERInstructionT, HTERReactantKindT, HTERReactantNameT, HTERSolutionIdT } from '../data-model';
import { Formatters, SelectReactantKind } from '../utils';
import type { HTE2MSDesignModel } from './model';
import { SingleFileUploadV2 } from '../../../components/common/FileUpload';

export class HTE2MSDesignReactionModel {
    instructionGroups: InstructionGroup[];
    instructionSolutionGroups: InstructionGroup[][];
    conditions: ReactionConditions = {};
    allConditions: { [K in keyof ReactionConditions]: any[] } = {};

    setConditions(conditions: ReactionConditions) {
        const update = this.reactions.map((r) => {
            const next: HTEDReaction = { ...r, template: { ...r.template } };
            if ('reaction_chemistry' in conditions) next.template.reaction_chemistry = conditions.reaction_chemistry;
            if ('scale' in conditions) next.scale = conditions.scale;
            if ('target_concentration' in conditions)
                next.template.target_concentration = conditions.target_concentration;
            if ('standard_concentration' in conditions)
                next.template.standard_concentration = conditions.standard_concentration;
            if ('solvent' in conditions) next.template.solvent = conditions.solvent;
            if ('group_name' in conditions) next.group_name = conditions.group_name;
            if ('project' in conditions) next.project = conditions.project;
            if ('well_label' in conditions) next.well_label = conditions.well_label;
            return [r, next];
        }) as [HTEDReaction, HTEDReaction][];
        return this.design.modifyReactions(update);
    }

    async add(instruction: HTERInstructionT) {
        let newInstruction = instruction;
        if (instruction.kind === 'add' && instruction.identifier) {
            const resolvedIdentifiers = await this.tryResolveIdentifiers([instruction.identifier]);
            const uid = resolvedIdentifiers[instruction.identifier];
            if (!uid) {
                throw new Error(`${instruction.identifier} is not a valid/existing compound or batch identifier.`);
            }
            newInstruction = { ...instruction, identifier: uid };
        }

        const update = this.reactions.map((r) => [
            r,
            { ...r, template: { ...r.template, instructions: [...r.template.instructions, newInstruction] } },
        ]) as [HTEDReaction, HTEDReaction][];
        return this.design.modifyReactions(update);
    }

    private async tryResolveIdentifiers(identifiers: string[]) {
        const assets = this.design.model.assets.entities;
        const toQuery: string[] = [];
        const result: Record<string, string> = {};
        const unique = Array.from(new Set(identifiers));

        for (const id of unique) {
            const universalIdentifier = assets.getEntityByNormalizedIdentifier(id)?.universal_identifier;
            if (!universalIdentifier) {
                toQuery.push(id);
            } else {
                result[id] = universalIdentifier;
            }
        }

        const resolved = await HTE2MSApi.resolveIdentifiers(toQuery);
        for (const [k, v] of Object.entries(resolved)) {
            result[k] = v;
        }

        const unresolved = unique.filter((id) => !result[id]);
        if (unresolved.length > 0) {
            throw new Error(
                `Failed to resolve ${unresolved.length} identifier${
                    unresolved.length === 1 ? '' : 's'
                }: ${unresolved.join(', ')}`
            );
        }

        await assets.syncIdentifiers(Array.from(Object.values(result)));

        return result;
    }

    async updateIdentifiers(group: InstructionGroup, identifiers: string) {
        let xs = splitInput(identifiers);
        if (xs.length === 1 && group.instructions.length !== 1) {
            xs = Array(group.instructions.length).fill(xs[0]);
        }
        if (group.instructions.length !== xs.length) {
            throw new Error('Number of identifiers must match the number of instructions');
        }

        const resolvedIdentifiers = await this.tryResolveIdentifiers(xs);
        const updates: [HTEDReaction, HTEDReaction][] = [];

        for (let i = 0; i < xs.length; i++) {
            const uid = resolvedIdentifiers[xs[i]];
            if (!uid) throw new Error(`${xs[i]} is not a valid/existing compound or batch identifier.`);
            const [r, instr] = group.instructions[i];
            const next = { ...instr, identifier: uid };
            updates.push([
                r,
                {
                    ...r,
                    template: {
                        ...r.template,
                        instructions: r.template.instructions.map((other) => (instr === other ? next : other)),
                    },
                },
            ]);
        }

        await this.design.modifyReactions(updates);
    }

    async assignIdentifierWells(group: InstructionGroup, input: string | File) {
        const table = await api.utils.parseTable<{
            'batch identifier': string;
            identifier: string;
            well: string;
        }>(typeof input === 'string' ? new File([input], 'input.csv') : input, { lowerCaseColumns: true });

        if (!table.hasColumn('identifier') && !table.hasColumn('batch identifier')) {
            throw new Error('Input has no "Identifier" or "Batch Identifier" column');
        }

        if (!table.hasColumn('well')) {
            throw new Error('Input has no "Well" column');
        }

        const identifiers = table.hasColumn('identifier')
            ? table.getColumnValues('identifier')
            : table.getColumnValues('batch identifier');
        const wells = table.getColumnValues('well');

        const resolvedIdentifiers = await this.tryResolveIdentifiers(identifiers as string[]);

        const { labelToReaction } = this.design.layout.info;

        const selectedReactions = new Map(group.instructions.map((g) => [g[0].id, g]));
        const updates: [HTEDReaction, HTEDReaction][] = [];

        for (let i = 0; i < identifiers.length; i++) {
            const identifier = identifiers[i];
            const well = wells[i];

            const rId = labelToReaction.get(well);
            if (!rId || !selectedReactions.has(rId)) {
                throw new Error(`Well ${well} is not associated to any selected reaction`);
            }

            const uid = resolvedIdentifiers[identifier];
            if (!uid) throw new Error(`${identifier} is not a valid/existing compound or batch identifier.`);

            const [r, instr] = selectedReactions.get(rId)!;

            const next = { ...instr, identifier: uid };
            updates.push([
                r,
                {
                    ...r,
                    template: {
                        ...r.template,
                        instructions: r.template.instructions.map((other) => (instr === other ? next : other)),
                    },
                },
            ]);
        }

        await this.design.modifyReactions(updates);
    }

    async update(group: InstructionGroup, _next: HTERInstructionT) {
        const next: HTERInstructionT = { ..._next };
        if (next.kind === 'add' && next.identifier) {
            const resolvedIdentifiers = await this.tryResolveIdentifiers([next.identifier]);
            next.identifier = resolvedIdentifiers[next.identifier];
        }

        const update = group.instructions.map(([r, i]) => {
            const instructions = r.template.instructions.map((prev) => (prev === i ? { ...prev, ...next } : prev));
            return [r, { ...r, template: { ...r.template, instructions } }];
        }) as [HTEDReaction, HTEDReaction][];
        await this.design.modifyReactions(update);
    }

    editSolutionDoseVolume(groups: InstructionGroup[]) {
        DialogService.open({
            type: 'generic',
            title: 'Edit Solution Dose Volume',
            confirmButtonContent: 'Apply',
            defaultState: { doseVolumeUL: undefined },
            wrapOk: true,
            content: EditSolutionDoseVolumeDialogContent,
            onOk: (state: { doseVolumeUL: number | undefined }) =>
                this.setSolutionDoseVolume(groups, state.doseVolumeUL),
        });
    }

    async setSolutionDoseVolume(groups: InstructionGroup[], doseVolumeUL: number | undefined) {
        if (!doseVolumeUL || !groups.length) return;

        const update: [HTEDReaction, HTEDReaction][] = [];
        const N = groups[0].instructions.length;
        for (let i = 0; i < N; i++) {
            let totalEquivalence = 0;
            const reaction = groups[0].instructions[i][0];
            for (const g of groups) {
                const instr = g.instructions[i][1];
                if (instr.kind !== 'add' || g.instructions[i][0] !== reaction)
                    throw new Error('Invalid selecion for this operation');
                if (!instr.equivalence) throw new Error('Add instruction must have equivalence');
                totalEquivalence += instr.equivalence;
            }

            const instructions = [...reaction.template.instructions];

            for (const g of groups) {
                const instr = g.instructions[i][1];
                if (instr.kind !== 'add') continue;
                const idx = instructions.indexOf(instr);
                if (idx < 0) throw new Error('Instruction not found');
                const doseVolume = (instr.equivalence! / totalEquivalence) * doseVolumeUL;
                instructions[idx] = {
                    ...instr,
                    dose_volume: `${roundValue(2, doseVolume)} uL`,
                    concentration: undefined,
                    neat_concentration: undefined,
                };
            }

            update.push([reaction, { ...reaction, template: { ...reaction.template, instructions } }]);
        }

        await this.design.modifyReactions(update);
    }

    move(group: InstructionGroup, delta: number) {
        const update = group.instructions.map(([r, i]) => {
            const instructions = [...r.template.instructions];
            const idx = instructions.indexOf(i);
            const target = idx + delta;
            if (target < 0 || target >= instructions.length) return [r, r];

            instructions.splice(idx, 1);
            instructions.splice(idx + delta, 0, i);
            return [r, { ...r, template: { ...r.template, instructions } }];
        }) as [HTEDReaction, HTEDReaction][];
        return this.design.modifyReactions(update, { cosmetic: true });
    }

    remove(group: InstructionGroup) {
        const update = group.instructions.map(([r, i]) => {
            const instructions = [...r.template.instructions];
            const idx = instructions.indexOf(i);
            instructions.splice(idx, 1);
            return [r, { ...r, template: { ...r.template, instructions } }];
        }) as [HTEDReaction, HTEDReaction][];
        return this.design.modifyReactions(update, { cosmetic: true });
    }

    constructor(public design: HTE2MSDesignModel, public reactions: HTEDReaction[]) {
        this.instructionGroups = groupInstructions(reactions);
        this.instructionSolutionGroups = [];

        let currentGroup: InstructionGroup[] = [];
        if (this.instructionGroups[0]) currentGroup.push(this.instructionGroups[0]);

        for (let i = 1; i < this.instructionGroups.length; i++) {
            const prev = this.instructionGroups[i - 1];
            const current = this.instructionGroups[i];

            if (
                prev.pivot.kind === 'add' &&
                current.pivot.kind === 'add' &&
                prev.pivot.solution_id &&
                prev.pivot.solution_id === current.pivot.solution_id
            ) {
                currentGroup.push(current);
            } else {
                this.instructionSolutionGroups.push(currentGroup);
                currentGroup = [current];
            }
        }

        if (currentGroup.length > 0) {
            this.instructionSolutionGroups.push(currentGroup);
        }

        const { all: allConditions, pivot: conditions } = determineConditions(reactions);
        this.conditions = conditions;
        this.allConditions = allConditions;
    }
}

interface ReactionConditions {
    reaction_chemistry?: string;
    target_concentration?: number;
    standard_concentration?: number;
    scale?: number;
    solvent?: string;
    group_name?: string;
    project?: string;
    well_label?: string;
}

function determineConditions(reactions: HTEDReaction[]) {
    const conditions = reactions.map(
        (r) =>
            ({
                reaction_chemistry: r.template.reaction_chemistry,
                target_concentration: r.template.target_concentration,
                standard_concentration: r.template.standard_concentration,
                scale: r.scale,
                solvent: r.template.solvent,
                group_name: r.group_name,
                project: r.project,
                well_label: r.well_label,
            } satisfies ReactionConditions)
    );

    return collectUniqueValues(conditions);
}

interface InstructionGroup {
    instructions: [HTEDReaction, HTERInstructionT][];
    instructionIndices: number[];
    pivot: HTERInstructionT;
    all: { [v: string]: any[] };
    indentifiers?: string[];
}

function groupInstructions(reactions: HTEDReaction[]): InstructionGroup[] {
    if (reactions.length === 0) return [];
    const groups: InstructionGroup[] = [];

    for (const r of reactions) {
        for (let iI = 0; iI < r.template.instructions.length; iI++) {
            const instr = r.template.instructions[iI];

            let added = false;
            for (const g of groups) {
                const pivot = g.instructions[0][1];
                if (pivot.kind !== instr.kind) continue;
                if (pivot.kind === 'add' && instr.kind === 'add') {
                    if (
                        instr.kind === 'add' &&
                        pivot.reactant_kind === instr.reactant_kind &&
                        (pivot.reactant_name || '') === (instr.reactant_name || '')
                    ) {
                        g.instructions.push([r, instr]);
                        if (!g.instructionIndices.includes(iI)) g.instructionIndices.push(iI);
                        added = true;
                        break;
                    }
                } else {
                    g.instructions.push([r, instr]);
                    if (!g.instructionIndices.includes(iI)) g.instructionIndices.push(iI);
                    added = true;
                    break;
                }
            }

            if (!added) {
                groups.push({
                    instructions: [[r, instr]],
                    pivot: undefined as any,
                    all: undefined as any,
                    instructionIndices: [iI],
                });
            }
        }
    }

    for (const group of groups) {
        group.instructionIndices.sort((a, b) => a - b);

        const { all, pivot } = collectUniqueValues(group.instructions.map((i) => i[1]) as HTERInstructionT[]);
        group.all = all;
        group.pivot = pivot as any;

        const uniqueIdentifiers = new Set(group.instructions.map(([, i]) => (i as any).identifier));
        uniqueIdentifiers.delete(undefined);
        group.indentifiers = Array.from(uniqueIdentifiers);
    }

    groups.sort((a, b) => {
        for (let i = 0; i < Math.min(a.instructionIndices.length, b.instructionIndices.length); i++) {
            const diff = a.instructionIndices[i] - b.instructionIndices[i];
            if (diff !== 0) return diff;
        }
        const len = a.instructionIndices.length - b.instructionIndices.length;
        if (len !== 0) return len;
        if (a.pivot.kind === b.pivot.kind) return 0;
        return a.pivot.kind < b.pivot.kind ? -1 : 1;
    });

    return groups;
}

function collectUniqueValues<T>(xs: T[]): { all: { [K in keyof T]?: T[K][] }; pivot: T } {
    const all: any = {};
    for (const x of xs) {
        for (const k in x) {
            if (!isBlankOrEmpty(x[k])) {
                if (!all[k]) all[k] = new Set([x[k]]);
                else if (!all[k].has(x[k])) all[k].add(x[k]);
            } else {
                all[`${k}--blank`] = true;
            }
        }
    }

    const pivot: any = {};

    // eslint-disable-next-line guard-for-in
    for (const k in all) {
        all[k] = Array.from(all[k]);
        if (all[k].length === 1 && !all[`${k}--blank`]) pivot[k] = all[k][0];
    }

    return { all, pivot };
}

export function HTE2MSReactionUI({ model }: { model: HTE2MSDesignModel }) {
    const reaction = useBehavior(model.state.reaction);

    if (model.all.length === 0) {
        return (
            <div className='p-2 d-flex align-items-center justify-content-center text-center w-100 h-100'>
                <span className='text-secondary font-body-small'>
                    Start by adding reactions using the <b>Add Reactions</b> button
                </span>
            </div>
        );
    }

    if (reaction.reactions.length === 0) {
        return (
            <div className='p-2 d-flex align-items-center justify-content-center text-center w-100 h-100'>
                <span className='text-secondary font-body-small'>Select one or more reactions to edit</span>
            </div>
        );
    }

    return (
        <div className='vstack h-100'>
            <ReactionOptions model={reaction} />
            <div className='d-flex position-relative flex-grow-1'>
                <ReactionInstructions model={reaction} />
            </div>
        </div>
    );
}

const ReactionFilter = createFilter({ ignoreAccents: false, stringify: (o: { label: string }) => o.label });
function formatReactionOption({ label }: { label: string }) {
    return <div>{label}</div>;
}

function ReactionOptions({ model }: { model: HTE2MSDesignReactionModel }) {
    const { conditions, allConditions, instructionGroups } = model;
    const readOnly = model.design.model.readOnlyDesignAndProduction;

    const edit = () => {
        DialogService.open({
            type: 'generic',
            title: 'Edit Reaction Conditions',
            confirmButtonContent: 'Apply',
            model: { model, reactions: model.design.state.reaction.value.reactions },
            defaultState: conditions,
            wrapOk: true,
            content: EditReactionConditionsDialogContent,
            onOk: (state: ReactionConditions) => model.setConditions(state),
        });
    };

    return (
        <div className='rounded hstack gap-1 px-2 py-1 mx-2 font-body-small hte2ms-reactions-edit-conditions flex-wrap'>
            <FontAwesomeIcon icon={faAtom} size='sm' fixedWidth title='Reaction Chemistry' />
            <b className='text-secondary'>Chem</b>
            <MultiValue
                pivot={conditions}
                all={allConditions}
                prop='reaction_chemistry'
                format={Formatters.chemistry}
                required
            />
            <FontAwesomeIcon
                icon={faAt}
                className='ms-1'
                size='sm'
                fixedWidth
                title='Target / Standard Concentration'
            />
            <b className='text-secondary' title='Target Concentration'>
                Conc
            </b>
            <MultiValue
                pivot={conditions}
                all={allConditions}
                prop='target_concentration'
                format={Formatters.concentration}
                unsetLabel='auto'
            />
            <span className='text-secondary'>/</span>
            <MultiValue
                pivot={conditions}
                all={allConditions}
                prop='standard_concentration'
                format={Formatters.concentration}
            />
            <FontAwesomeIcon icon={faScaleUnbalanced} className='ms-1' size='sm' fixedWidth title='Scale' />
            <b className='text-secondary'>Scale</b>
            <MultiValue pivot={conditions} all={allConditions} prop='scale' format={Formatters.rxnScale} required />
            <FontAwesomeIcon icon={faDroplet} className='ms-1' size='sm' fixedWidth title='Solvent' />
            <b className='text-secondary'>Solv</b>
            <MultiValue pivot={conditions} all={allConditions} prop='solvent' format={Formatters.identity} required />
            <FontAwesomeIcon icon={faObjectGroup} className='ms-1' size='sm' fixedWidth title='Group' />
            <b className='text-secondary'>Grp</b>
            <MultiValue pivot={conditions} all={allConditions} prop='group_name' format={Formatters.identity} />
            <FontAwesomeIcon icon={faFolderOpen} className='ms-1' size='sm' fixedWidth title='Project' />
            <b className='text-secondary'>Prj</b>
            <MultiValue pivot={conditions} all={allConditions} prop='project' format={Formatters.identity} required />
            <div className='m-auto' />
            {!readOnly && (
                <IconButton icon={faPencil} onClick={edit} className='py-0'>
                    Edit
                </IconButton>
            )}
            {!readOnly && (
                <IconDropdownButton icon={faRobot} label='Instructions' size='sm' className='py-0'>
                    <EditReactionInstructionButton model={model} group={DefaultInstructionGroup.add} dropdown />
                    {!instructionGroups.find((g) => g.instructions[0][1].kind === 'pause') && (
                        <EditReactionInstructionButton model={model} group={DefaultInstructionGroup.pause} dropdown />
                    )}
                    {!instructionGroups.find((g) => g.instructions[0][1].kind === 'cook') && (
                        <EditReactionInstructionButton model={model} group={DefaultInstructionGroup.cook} dropdown />
                    )}
                    <EditReactionInstructionButton model={model} group={DefaultInstructionGroup.evaporate} dropdown />
                </IconDropdownButton>
            )}
        </div>
    );
}

function EditReactionConditionsDialogContent({
    model: { model, reactions },
    stateSubject,
}: {
    model: { model: HTE2MSDesignReactionModel; reactions: HTEDReaction[] };
    stateSubject: BehaviorSubject<ReactionConditions>;
}) {
    const conditions = useBehavior(stateSubject);

    const chem = model.design.model.assets.reactionChemistryOptions.find(
        (o) => o.value?.id === conditions.reaction_chemistry
    );

    return (
        <div className='vstack gap-2'>
            <LabeledInput label='Rxn. Chemistry' labelWidth={LabelWidth}>
                <div className='w-100 position-relative'>
                    <Select
                        options={model.design.model.assets.reactionChemistryOptions}
                        value={chem}
                        isSearchable
                        formatOptionLabel={formatReactionOption}
                        filterOption={ReactionFilter}
                        placeholder='Select reaction chemistry'
                        classNames={CustomSelectClassNames}
                        onChange={
                            ((v: HTE2MSReactionChemistryOption) =>
                                setOptionalState(conditions, stateSubject, 'reaction_chemistry', v?.value?.id)) as any
                        }
                    />
                </div>
            </LabeledInput>
            <LabeledInput label='Target Conc.' labelWidth={LabelWidth}>
                <TextInput
                    value={formatWithUnit(conditions.target_concentration, 1e3, 'mM')}
                    tryUpdateValue={(v) => parseWithUnit(v, 'mM', true)}
                    setValue={(v) => setOptionalState(conditions, stateSubject, 'target_concentration', v)}
                />
            </LabeledInput>
            <LabeledInput label='Standard Conc.' labelWidth={LabelWidth}>
                <TextInput
                    value={formatWithUnit(conditions.standard_concentration, 1e3, 'mM')}
                    tryUpdateValue={(v) => parseWithUnit(v, 'mM', true)}
                    setValue={(v) => setOptionalState(conditions, stateSubject, 'standard_concentration', v)}
                />
            </LabeledInput>
            <LabeledInput label='Scale (μmol)' labelWidth={LabelWidth}>
                <TextInput
                    value={conditions.scale}
                    formatValue={(v) => (v ? `${roundValue(2, 1e6 * v)}` : '')}
                    tryUpdateValue={asNumberOrNull}
                    setValue={(v) => setOptionalState(conditions, stateSubject, 'scale', v ? v * 1e-6 : v)}
                />
            </LabeledInput>
            <LabeledInput label='Solvent' labelWidth={LabelWidth}>
                <SelectSolvent
                    allowEmpty
                    value={conditions.solvent as any}
                    setValue={(v) => setOptionalState(conditions, stateSubject, 'solvent', v || undefined)}
                />
            </LabeledInput>
            <LabeledInput label='Group' labelWidth={LabelWidth}>
                <TextInput
                    value={conditions.group_name ?? ''}
                    setValue={(v) => setOptionalState(conditions, stateSubject, 'group_name', v)}
                />
            </LabeledInput>
            <LabeledInput label='Project' labelWidth={LabelWidth}>
                <div className='w-100'>
                    <ProjectSelect
                        value={conditions.project as any}
                        placeholder='Select project...'
                        setValue={(v) => setOptionalState(conditions, stateSubject, 'project', v)}
                    />
                </div>
            </LabeledInput>
            {reactions.length === 1 && (
                <LabeledInput label='Well' labelWidth={LabelWidth}>
                    <TextInput
                        value={conditions.well_label ?? ''}
                        setValue={(v) =>
                            setOptionalState(
                                conditions,
                                stateSubject,
                                'well_label',
                                v?.toUpperCase().trim() || undefined
                            )
                        }
                    />
                </LabeledInput>
            )}
        </div>
    );
}

const DefaultInstructionGroup = {
    add: {
        instructions: [],
        instructionIndices: [],
        all: {},
        pivot: {
            kind: 'add',
            reactant_kind: 'unknown' as HTERReactantKindT,
        },
    } satisfies InstructionGroup,
    pause: {
        instructions: [],
        instructionIndices: [],
        all: {},
        pivot: { kind: 'pause', duration: '5 min' },
    } satisfies InstructionGroup,
    cook: {
        instructions: [],
        instructionIndices: [],
        all: {},
        pivot: { kind: 'cook', duration: '8 hour', temperature: '50 degC' },
    } satisfies InstructionGroup,
    evaporate: {
        instructions: [],
        instructionIndices: [],
        all: {},
        pivot: { kind: 'evaporate' },
    } satisfies InstructionGroup,
};

function ReactionInstructions({ model }: { model: HTE2MSDesignReactionModel }) {
    const { instructionSolutionGroups } = model;
    const readOnly = model.design.model.readOnlyDesignAndProduction;

    return (
        <div className='position-relative h-100 d-flex flex-column' style={{ flexGrow: 1, flexShrink: 1, minWidth: 0 }}>
            <div className='w-100 d-flex flex-grow-1 p-2' style={{ overflow: 'hidden', overflowX: 'auto' }}>
                <div className='hstack h-100 gap-2'>
                    {instructionSolutionGroups.map((sol, i) =>
                        sol.length > 1 ? (
                            <div className='h-100 hte2ms-solution-group' key={i}>
                                <div className='hstack h-100'>
                                    {sol.map((g, j) => (
                                        <ProcedureInstruction model={model} group={g} key={j} />
                                    ))}
                                </div>
                                {!readOnly && (
                                    <div className='hte2ms-solution-group-actions hstack'>
                                        <div className='m-auto' />
                                        <IconButton
                                            onClick={() => model.editSolutionDoseVolume(sol)}
                                            icon={faFlask}
                                            size='sm'
                                        >
                                            Dose Volume
                                        </IconButton>
                                        <div className='m-auto' />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <ProcedureInstruction model={model} group={sol[0]} key={i} />
                        )
                    )}
                </div>
            </div>
        </div>
    );
}

function ProcedureInstruction({ model, group }: { model: HTE2MSDesignReactionModel; group: InstructionGroup }) {
    const readOnly = model.design.model.readOnlyDesignAndProduction;

    const { pivot: instr, all } = group;

    let actions: ReactNode = null;
    if (!readOnly) {
        actions = (
            <div className='hstack'>
                <div className='m-auto' />
                {instr.kind === 'add' && <EditIdentifiersButton model={model} group={group} />}
                <EditReactionInstructionButton model={model} group={group} />
                <RemoveInstructionButton model={model} group={group} />
                <MoveInstructionButton model={model} group={group} dir={-1} />
                <MoveInstructionButton model={model} group={group} dir={1} />
                <div className='m-auto' />
            </div>
        );
    }

    const indices = <b>[{group.instructionIndices.map((idx) => idx + 1).join(', ')}]&nbsp;</b>;
    const counts = group.instructions.length > 1 ? ` ×${group.instructions.length}` : '';

    if (instr.kind === 'add') {
        const assets = model.design.model.assets.entities;

        const uniqueIdentifiers = group.indentifiers!;
        const identifier = uniqueIdentifiers.length === 1 ? uniqueIdentifiers[0] : undefined;
        const smiles = assets.getStructure(identifier!);
        const normalizedIdentifier = assets.getIdentifier(identifier!);
        const entityExists = !!assets.getEntity(identifier);

        let thumbLabel;

        if (normalizedIdentifier) {
            if (entityExists) {
                thumbLabel = (
                    <div className='font-body-xsmall'>
                        <BatchLink identifier={normalizedIdentifier} withQuery />
                    </div>
                );
            } else {
                thumbLabel = <div className='font-body-xsmall text-danger'>{normalizedIdentifier}</div>;
            }
        } else if (uniqueIdentifiers.length > 1) {
            thumbLabel = <div className='font-body-xsmall'>«{uniqueIdentifiers.length} identifiers»</div>;
        } else {
            thumbLabel = <div className='font-body-xsmall text-danger'>«unset identifier»</div>;
        }

        const thumbBase = smiles ? (
            <AsyncMoleculeDrawing
                autosize
                smiles={smiles}
                showChemDraw={false}
                showCopy={false}
                drawer={model.design.model.drawer}
                paddingBottom={8}
                width='95%'
                height='80%'
            />
        ) : (
            <FontAwesomeIcon icon={faAdd} fontSize={32} />
        );

        const thumb = (
            <>
                {thumbBase}
                <div className='text-center end-0 start-0 bottom-0 position-absolute'>{thumbLabel}</div>
            </>
        );

        const isSolid = !all.neat_concentration?.length && !all.concentration?.length && !all.dose_volume?.length;
        const volumeKind = all.dose_volume?.length
            ? ('volume' as const)
            : isSolid
            ? ('solid' as const)
            : ('conc' as const);
        const volumeIcon = volumeKind === 'volume' ? faFlask : volumeKind === 'conc' ? faAt : faCube;
        const manualHandling = all.manual_handling?.some((v) => v);

        return (
            <InstructionCard
                thumb={thumb}
                actions={actions}
                title={
                    <>
                        {indices}
                        {instr.reactant_kind ?? '«unset»'}
                        {counts}
                    </>
                }
                titleClass={`hte2ms-reactant-bg-${instr.reactant_kind?.toLowerCase()} hte2ms-instruction-bg-add`}
            >
                <div className='font-body-xsmall'>
                    <span title='Reactant Name'>
                        <FontAwesomeIcon icon={faSignature} className='me-2' size='sm' fixedWidth />
                        <MultiValue pivot={instr} prop='reactant_name' all={all} format={Formatters.identity} />
                    </span>
                    {!!all.solution_id?.length && (
                        <span title='Solution ID' className='ms-2'>
                            <FontAwesomeIcon icon={faSquarePlus} className='me-2' size='sm' fixedWidth />
                            <MultiValue pivot={instr} prop='solution_id' all={all} format={Formatters.identity} />
                        </span>
                    )}
                </div>
                <div className='font-body-xsmall' title='Concentration'>
                    <FontAwesomeIcon icon={volumeIcon} className='me-2' size='sm' fixedWidth />
                    {volumeKind === 'solid' && 'Dry'}
                    {all?.neat_concentration?.length && (
                        <span title='Neat'>
                            <MultiValue
                                pivot={instr}
                                prop='neat_concentration'
                                all={all}
                                format={Formatters.concentration}
                            />
                            <span className='mx-1'>→</span>
                        </span>
                    )}
                    {volumeKind === 'conc' && (
                        <MultiValue pivot={instr} prop='concentration' all={all} format={Formatters.concentration} />
                    )}
                    {volumeKind === 'volume' && (
                        <>
                            <MultiValue pivot={instr} prop='dose_volume' all={all} format={Formatters.siVolume} />
                            {instr.solution_id && (
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    size='sm'
                                    className='ms-1 text-secondary'
                                    title='Approximate fraction in solution, not actual dose volume of this reagent'
                                />
                            )}
                        </>
                    )}
                </div>
                {all?.neat_solvent?.length && (
                    <div className='font-body-xsmall' title='Neat solvent'>
                        <FontAwesomeIcon icon={faDroplet} className='me-2' size='sm' fixedWidth />
                        <MultiValue pivot={instr} prop='neat_solvent' all={all} format={Formatters.identity} />
                    </div>
                )}
                <div className='hstack gap-2'>
                    <div className='font-body-xsmall' title='Equivalence'>
                        <FontAwesomeIcon icon={faEquals} className='me-2' size='sm' fixedWidth />
                        <MultiValue pivot={instr} prop='equivalence' all={all} format={Formatters.identity} required />
                    </div>
                    {all?.use_overage?.length && (
                        <div className='font-body-xsmall' title='Use overage'>
                            <FontAwesomeIcon icon={faStarOfLife} className='me-2' size='sm' fixedWidth />
                            <MultiValue pivot={instr} prop='use_overage' all={all} format={Formatters.useOverage} />
                        </div>
                    )}
                    {manualHandling && <FontAwesomeIcon icon={faHand} size='xs' title='Handle manually' />}
                </div>
            </InstructionCard>
        );
    }

    if (instr.kind === 'pause') {
        return (
            <InstructionCard
                thumb={<FontAwesomeIcon icon={faHourglassHalf} fontSize={32} />}
                actions={actions}
                title={
                    <>
                        {indices}
                        Pause
                        {counts}
                    </>
                }
                titleClass='hte2ms-instruction-bg-pause'
            >
                <div className='font-body-xsmall' title='Duration'>
                    <FontAwesomeIcon icon={faStopwatch} className='me-2' size='sm' fixedWidth />
                    <MultiValue pivot={instr} prop='duration' all={all} format={Formatters.pauseDuration} required />
                </div>
            </InstructionCard>
        );
    }

    if (instr.kind === 'cook') {
        return (
            <InstructionCard
                thumb={<FontAwesomeIcon icon={faFireBurner} fontSize={32} />}
                actions={actions}
                title={
                    <>
                        {indices}
                        Cook
                        {counts}
                    </>
                }
                titleClass='hte2ms-instruction-bg-cook'
            >
                <div className='font-body-xsmall' title='Duration'>
                    <FontAwesomeIcon icon={faStopwatch} className='me-2' size='sm' fixedWidth />
                    <MultiValue pivot={instr} prop='duration' all={all} format={Formatters.cookDuration} required />
                </div>
                <div className='font-body-xsmall' title='Temperature'>
                    <FontAwesomeIcon icon={faTemperatureHigh} className='me-2' size='sm' fixedWidth />
                    <MultiValue
                        pivot={instr}
                        prop='temperature'
                        all={all}
                        format={Formatters.cookTemperature}
                        required
                    />
                </div>
            </InstructionCard>
        );
    }

    if (instr.kind === 'evaporate') {
        return (
            <InstructionCard
                thumb={<FontAwesomeIcon icon={faFan} fontSize={32} />}
                actions={actions}
                title={
                    <>
                        {indices}
                        Evaporate
                        {counts}
                    </>
                }
                titleClass='hte2ms-instruction-bg-evaporate'
            >
                <span className='text-secondary font-body-xsmall'>Evaporate</span>
            </InstructionCard>
        );
    }

    return null;
}

function MultiValue<T>({
    pivot,
    prop,
    all,
    format,
    required,
    unsetLabel,
}: {
    pivot: T;
    prop: keyof T;
    all: any;
    format: (v: any) => string;
    required?: boolean;
    unsetLabel?: string;
}) {
    const values = all[prop];
    if (values?.length > 0 && all[`${prop as any}--blank`]) {
        return (
            <TooltipWrapper tooltip={valuesTitle(values, format, true)}>
                {(props) => (
                    <span {...props} className='hte2ms-reactions-tooltip'>
                        «{values.length + 1}×»
                    </span>
                )}
            </TooltipWrapper>
        );
    }
    if (!values || values.length === 0) {
        return required ? (
            <span className='text-danger'>«required»</span>
        ) : (
            <span className='text-secondary'>«{unsetLabel ?? 'unset'}»</span>
        );
    }
    if (values.length === 1) return <span>{format(values[0])}</span>;
    return (
        <TooltipWrapper tooltip={valuesTitle(values, format, false)}>
            {(props) => (
                <span {...props} className='hte2ms-reactions-tooltip'>
                    «{values.length}×»
                </span>
            )}
        </TooltipWrapper>
    );
}

function valuesTitle(values: any[], format: (v: any) => any, hasUnset: boolean) {
    const ret: string[] = [];
    if (hasUnset) {
        ret.push('«unset»');
    }
    for (let i = 0; i < Math.min(5, values.length); i++) {
        ret.push(format(values[i]));
    }
    if (values.length > 5) ret.push('...');
    return (
        <div>
            <b>{values.length + (hasUnset ? 1 : 0)} values:</b>
            <div style={{ whiteSpace: 'pre' }}>{ret.join('\n')}</div>
        </div>
    );
}

function InstructionCard({
    title,
    titleClass,
    thumb,
    children,
    actions,
}: {
    title?: ReactNode;
    titleClass?: string;
    thumb: ReactNode;
    children: ReactNode;
    actions?: ReactNode;
}) {
    const width = 175;
    return (
        <div style={{ width }} className='d-flex px-2 h-100 flex-column position-relative hte2ms-instruction-card'>
            {title && (
                <div className={`hte2ms-instruction-card-header mb-1 font-body-xsmall ${titleClass ?? ''}`}>
                    {title}
                </div>
            )}
            <div
                style={{ flexBasis: width / 1.5, flexShrink: 0 }}
                className='d-flex align-items-center justify-content-center position-relative'
            >
                {thumb}
            </div>
            <div className='flex-grow-1 d-flex'>
                <div className='m-auto'>{children}</div>
            </div>
            {actions && <div className='hte2ms-instruction-card-actions'>{actions}</div>}
        </div>
    );
}

function EditReactionInstructionButton({
    model,
    group,
    dropdown,
}: {
    model: HTE2MSDesignReactionModel;
    group: InstructionGroup;
    dropdown?: boolean;
}) {
    const exists = group.instructions.length > 0;
    const { pivot } = group;

    const apply = () => {
        DialogService.open({
            type: 'generic',
            title: !exists ? `New ${capitalizeFirst(pivot.kind)} Instruction` : `Edit ${capitalizeFirst(pivot.kind)}`,
            confirmButtonContent: 'Apply',
            model,
            defaultState: pivot,
            wrapOk: true,
            content: EditInstructionDialogContent,
            onOk: (state: HTERInstructionT) => (exists ? model.update(group, state) : model.add(state)),
        });
    };

    let icon: IconDefinition = faPencil;
    if (!exists) {
        if (pivot.kind === 'add') icon = faAdd;
        else if (pivot.kind === 'pause') icon = faHourglassHalf;
        else if (pivot.kind === 'cook') icon = faFireBurner;
        else if (pivot.kind === 'evaporate') icon = faFan;
    }

    if (dropdown) {
        return (
            <Dropdown.Item onClick={apply}>
                {exists ? 'Edit' : 'New'} {capitalizeFirst(pivot.kind)}
            </Dropdown.Item>
        );
    }

    return (
        <AsyncButton
            onClick={apply}
            variant='link'
            icon={icon}
            size='sm'
            title={!exists ? `New ${pivot.kind}` : 'Edit'}
        />
    );
}

function EditIdentifiersButton({ model, group }: { model: HTE2MSDesignReactionModel; group: InstructionGroup }) {
    const apply = () => {
        DialogService.open({
            type: 'generic',
            title: 'Edit Identifiers',
            confirmButtonContent: 'Apply',
            model: group,
            defaultState: {
                kind: 'identifiers',
                identifiers: group.indentifiers?.filter((id) => id).join('\n') ?? '',
                wells: '',
                wells_file: undefined,
            } satisfies EditIdentifersOptions,
            wrapOk: true,
            content: EditIdentifiersDialogContent,
            onOk: (state: EditIdentifersOptions) =>
                state.kind === 'identifiers'
                    ? model.updateIdentifiers(group, state.identifiers)
                    : model.assignIdentifierWells(group, state.wells_file ?? state.wells),
        });
    };

    return <IconButton onClick={apply} variant='link' icon={faI} size='sm' title='Edit Identifiers' />;
}

interface EditIdentifersOptions {
    kind: 'identifiers' | 'wells';
    identifiers: string;
    wells: string;
    wells_file?: File;
}

function EditIdentifiersDialogContent({
    model,
    stateSubject,
}: {
    model: InstructionGroup;
    stateSubject: BehaviorSubject<EditIdentifersOptions>;
}) {
    const state = useBehavior(stateSubject);

    if (model.instructions.length === 1) {
        return (
            <TextInput
                value={state.identifiers}
                placeholder='Compound/Batch Identifier / Barcode / CAS Number'
                setValue={(v) => stateSubject.next({ ...state, identifiers: v.trim() })}
                autoFocus
            />
        );
    }

    const kind = (
        <ButtonGroup size='sm' className='mb-2'>
            <Button
                onClick={() => stateSubject.next({ ...state, kind: 'identifiers' })}
                variant={state.kind === 'identifiers' ? 'primary' : 'outline-primary'}
            >
                Identifiers
            </Button>
            <Button
                onClick={() => stateSubject.next({ ...state, kind: 'wells' })}
                variant={state.kind === 'wells' ? 'primary' : 'outline-primary'}
            >
                Wells
            </Button>
        </ButtonGroup>
    );

    if (state.kind === 'identifiers') {
        const split = splitInput(state.identifiers);
        return (
            <div className='vstack'>
                {kind}
                <InlineAlert iconTopLeft>
                    <div>
                        Input either {model.instructions.length} identifiers or a single one applied to each
                        instruction.
                    </div>
                    <div>
                        <b>Supported identifiers:</b> Compound/Batch Identifier, Barcode, CAS Number
                    </div>
                </InlineAlert>
                {model.indentifiers?.length !== 1 && model.indentifiers?.length !== model.instructions.length && (
                    <InlineAlert variant='warning' icon={faExclamationTriangle} className='mt-2'>
                        The current selection has {model.indentifiers?.length} distinct identifiers assigned, but{' '}
                        {model.instructions.length} instructions are selected. Consider refining your selection before
                        doing the update.
                    </InlineAlert>
                )}
                <TextInput
                    className='mt-2'
                    value={state.identifiers}
                    placeholder='Compound/Batch Identifier / Barcode / CAS Number'
                    setValue={(v) => stateSubject.next({ ...state, identifiers: v.trim() })}
                    textarea
                    rows={5}
                    immediate
                    autoFocus
                />
                <span className='text-secondary text-body-small text-end'>
                    {split.length} identifier{split.length === 1 ? '' : 's'} entered
                </span>
            </div>
        );
    }

    return (
        <div className='vstack'>
            {kind}
            <InlineAlert iconTopLeft>
                <div>
                    Input CSV with <code>Identifier</code> (or <code>Batch Identifier</code>) and <code>Well</code>{' '}
                    columns. The correspondings wells need to be selected for the assignment to be successful.
                </div>
                <div>
                    <b>Supported identifiers:</b> Compound/Batch Identifier, Barcode, CAS Number
                </div>
            </InlineAlert>

            {!state.wells_file && (
                <TextInput
                    className='mt-2'
                    value={state.wells}
                    placeholder='CSV with Identifier and Well columns'
                    setValue={(v) => stateSubject.next({ ...state, wells: v.replaceAll('\t', ',').trim() })}
                    textarea
                    rows={3}
                    autoFocus
                />
            )}

            <SingleFileUploadV2
                file={state.wells_file}
                onDrop={(acceptedFiles: any[]) =>
                    stateSubject.next({ ...state, wells_file: acceptedFiles.length ? acceptedFiles[0] : undefined })
                }
                label='CSV/XLS/XLSX file with well assignments...'
                inline
                extensions={['.csv', '.xls', '.xlsx']}
            />
        </div>
    );
}

const LabelWidth = 160;

function EditInstructionDialogContent({
    model,
    stateSubject,
}: {
    model: HTE2MSDesignReactionModel;
    stateSubject: BehaviorSubject<HTERInstructionT>;
}) {
    const instruction = useBehavior(stateSubject);

    if (instruction.kind === 'pause') {
        return (
            <div className='vstack gap-1 font-body-small'>
                <LabeledInput label='Duration' labelWidth={LabelWidth}>
                    <TextInput
                        value={formatWithUnit(instruction.duration, 1, 's')}
                        tryUpdateValue={(v) => parseWithUnit(v, 's')}
                        setValue={(v) => setOptionalState(instruction, stateSubject, 'duration', v)}
                        size='sm'
                        autoFocus
                    />
                </LabeledInput>
            </div>
        );
    }

    if (instruction.kind === 'cook') {
        return (
            <div className='vstack gap-1 font-body-small'>
                <LabeledInput label='Duration' labelWidth={LabelWidth}>
                    <TextInput
                        value={formatWithUnit(instruction.duration, 1 / 3600, 'hour')}
                        tryUpdateValue={(v) => parseWithUnit(v, 'hour')}
                        setValue={(v) => setOptionalState(instruction, stateSubject, 'duration', v)}
                        autoFocus
                        size='sm'
                    />
                </LabeledInput>
                <LabeledInput label='Temperature' labelWidth={LabelWidth}>
                    <TextInput
                        value={formatWithUnit(instruction.temperature, (v) => v - 273.15, 'celsius')}
                        tryUpdateValue={(v) => parseWithUnit(v, 'celsius')}
                        setValue={(v) => setOptionalState(instruction, stateSubject, 'temperature', v)}
                        size='sm'
                    />
                </LabeledInput>
            </div>
        );
    }

    if (instruction.kind === 'add') {
        const assets = model.design.model.assets.entities;

        return (
            <div className='vstack gap-1 font-body-small'>
                <LabeledInput label='Kind' labelWidth={LabelWidth}>
                    <SelectReactantKind
                        value={instruction.reactant_kind ?? ''}
                        setValue={(v) =>
                            setOptionalState(instruction, stateSubject, 'reactant_kind', v.trim() as HTERReactantKindT)
                        }
                        size='sm'
                    />
                </LabeledInput>
                <LabeledInput
                    label='Identifier'
                    labelWidth={LabelWidth}
                    tooltip='Compound/Batch Identifier / Barcode / CAS Number'
                >
                    <TextInput
                        value={instruction.identifier ?? ''}
                        formatValue={(v) => assets.getEntityByNormalizedIdentifier(v?.trim())?.identifier ?? v}
                        setValue={(v) => setOptionalState(instruction, stateSubject, 'identifier', v.trim())}
                        autoFocus
                        size='sm'
                    />
                </LabeledInput>
                <LabeledInput label='Name' labelWidth={LabelWidth} tooltip='Case sensitive'>
                    <TextInput
                        value={instruction.reactant_name ?? ''}
                        setValue={(v) =>
                            setOptionalState(instruction, stateSubject, 'reactant_name', v.trim() as HTERReactantNameT)
                        }
                        size='sm'
                    />
                </LabeledInput>
                <LabeledInput
                    label='Solution ID'
                    labelWidth={LabelWidth}
                    tooltip='Groups reactants with the same Solution ID, case sensitive'
                >
                    <TextInput
                        value={instruction.solution_id ?? ''}
                        setValue={(v) =>
                            setOptionalState(
                                instruction,
                                stateSubject,
                                'solution_id',
                                (v.trim() || undefined) as HTERSolutionIdT | undefined
                            )
                        }
                        size='sm'
                    />
                </LabeledInput>
                <LabeledInput label='Equivalence' labelWidth={LabelWidth}>
                    <TextInput
                        value={
                            typeof instruction.equivalence === 'number'
                                ? roundValue(3, instruction.equivalence)
                                : instruction.equivalence ?? ''
                        }
                        tryUpdateValue={asNumberOrNull}
                        setValue={(v) => setOptionalState(instruction, stateSubject, 'equivalence', v ? +v : undefined)}
                        size='sm'
                    />
                </LabeledInput>
                <LabeledInput label='Concentration' labelWidth={LabelWidth}>
                    <TextInput
                        value={formatWithUnit(instruction.concentration, 1e3, 'mM')}
                        tryUpdateValue={(v) => parseWithUnit(v, 'mM', true)}
                        setValue={(v) => setOptionalState(instruction, stateSubject, 'concentration', v)}
                        size='sm'
                    />
                </LabeledInput>
                <LabeledInput label='Neat Conc.' labelWidth={LabelWidth}>
                    <TextInput
                        value={formatWithUnit(instruction.neat_concentration, 1e3, 'mM')}
                        tryUpdateValue={(v) => parseWithUnit(v, 'mM', true)}
                        setValue={(v) => setOptionalState(instruction, stateSubject, 'neat_concentration', v)}
                        size='sm'
                    />
                </LabeledInput>
                <LabeledInput label='Dose Volume' labelWidth={LabelWidth}>
                    <TextInput
                        value={formatWithUnit(instruction.dose_volume, 1e9, 'uL')}
                        tryUpdateValue={(v) => parseWithUnit(v, 'uL', true)}
                        setValue={(v) => setOptionalState(instruction, stateSubject, 'dose_volume', v)}
                        size='sm'
                    />
                </LabeledInput>
                <LabeledInput label='Neat Solvent' labelWidth={LabelWidth}>
                    <SelectSolvent
                        allowEmpty
                        value={instruction.neat_solvent as any}
                        setValue={(v) => setOptionalState(instruction, stateSubject, 'neat_solvent', v || undefined)}
                        size='sm'
                    />
                </LabeledInput>
                <LabeledInput
                    label='Use Overage'
                    labelWidth={LabelWidth}
                    tooltip='Per use volume multiplier to put into source plate (e.g. 1.1 for 10% overage). Applied to volume required in the product plate.'
                >
                    <TextInput
                        value={
                            typeof instruction.use_overage === 'number'
                                ? roundValue(3, instruction.use_overage)
                                : instruction.use_overage ?? ''
                        }
                        tryUpdateValue={asNumberOrNull}
                        setValue={(v) => setOptionalState(instruction, stateSubject, 'use_overage', v ? +v : undefined)}
                        size='sm'
                    />
                </LabeledInput>
                <LabeledInput
                    label='Manual Handling'
                    labelWidth={LabelWidth}
                    tooltip='Do not require barcode assignment in Reagents tab and skip during robot protocol generation. Useful, for example, for representing intermediate products in multistep reactions.'
                >
                    <Form.Switch
                        checked={!!instruction.manual_handling}
                        onChange={(e) =>
                            setOptionalState(instruction, stateSubject, 'manual_handling', !!e.target.checked)
                        }
                    />
                </LabeledInput>
            </div>
        );
    }

    return null;
}

function setOptionalState<T>(current: T, subject: BehaviorSubject<any>, prop: keyof T, value: any) {
    if (!(prop in (current as any))) {
        // This is to prevent overwriting the current value if user focused an
        // input without modifying it's value (since 'setValue' is called onBlur in TextInput)
        if (isBlankOrEmpty(value)) return;
    }
    subject.next({ ...current, [prop]: value });
}

function MoveInstructionButton({
    model,
    group,
    dir,
}: {
    model: HTE2MSDesignReactionModel;
    group: InstructionGroup;
    dir: number;
}) {
    const apply = () => model.move(group, dir);

    const icon: IconDefinition = dir < 0 ? faArrowLeft : faArrowRight;
    return (
        <AsyncButton
            onClick={apply}
            variant='link'
            size='sm'
            icon={icon}
            title={dir < 0 ? 'Move left' : 'Move right'}
        />
    );
}

function RemoveInstructionButton({ model, group }: { model: HTE2MSDesignReactionModel; group: InstructionGroup }) {
    const apply = () => {
        const pivot = group.instructions[0][1];
        let label;
        if (pivot.kind === 'add') {
            label = `Add ${pivot.reactant_kind.toUpperCase()}`;
        } else {
            label = `${pivot.kind}`;
        }
        DialogService.open({
            type: 'generic',
            title: `Remove ${label} Instruction`,
            confirmButtonContent: 'Remove',
            model: group,
            defaultState: {},
            wrapOk: true,
            content: RemoveInstructionDialogContent,
            onOk: () => model.remove(group),
        });
    };
    return <AsyncButton onClick={apply} variant='link' size='sm' icon={faTrash} title='Remove' />;
}

function RemoveInstructionDialogContent() {
    return <p>Do you really want to remove this instruction?</p>;
}

function EditSolutionDoseVolumeDialogContent({
    stateSubject,
}: {
    stateSubject: BehaviorSubject<{ doseVolumeUL: number | null }>;
}) {
    const value = useBehavior(stateSubject);

    return (
        <LabeledInput label='Dose Volume (μL)' labelWidth={LabelWidth}>
            <TextInput
                value={value.doseVolumeUL}
                formatValue={(v) => (typeof v === 'number' ? `${roundValue(2, v)}` : '')}
                tryUpdateValue={asNumberOrNull}
                setValue={(v) => stateSubject.next({ doseVolumeUL: v })}
            />
        </LabeledInput>
    );
}
