import { BehaviorSubject, combineLatest } from 'rxjs';
import { AuthService } from '../../../lib/services/auth';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { HTE2MSApi } from '../api';
import { HTEFinalizationData } from '../data-model';
import { type HTE2MSModel } from '../model';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { ToastService } from '../../../lib/services/toast';

export type CanFinalizeState =
    | { kind: 'not-ready'; message?: string }
    | { kind: 'bad-user' }
    | { kind: 'invalid-input' }
    | { kind: 'already-finalized' }
    | { kind: 'yes' };

export class HTE2MSFinalizeModel extends ReactiveModel {
    state = {
        data: new BehaviorSubject<HTEFinalizationData>({
            full_name: AuthService.currentAccount?.name ?? '',
            acknowledged_policy: false,
            comment: '',
        }),
        canFinalize: new BehaviorSubject<CanFinalizeState>({ kind: 'not-ready' }),
    };

    async finalize() {
        if (!this.state.canFinalize.value) return;

        const data = this.state.data.value;
        if (!data.full_name) {
            throw new Error('Full name is required.');
        }
        if (!data.acknowledged_policy) {
            throw new Error('You must acknowledge the policy.');
        }

        const id = this.model.experiment?.id!;

        const experiment = await HTE2MSApi.finalize(id, {
            last_modified_on: this.model.last_modified_on!,
            finalization: data,
        });
        this.model.updateFoundryInfo({ experiment });

        ToastService.show({
            type: 'success',
            message: 'Finalized',
            timeoutMs: 1500,
        });

        try {
            await HTE2MSApi.notifySlack(id, 'done');
        } catch (e) {
            reportErrorAsToast('Notify Slack', e);
        }

        try {
            await HTE2MSApi.triggerSnapshot(id);
        } catch (e) {
            ToastService.show({
                type: 'danger',
                message: 'Failed to trigger signing snapshot creation, please contact someone to do this manually.',
                timeoutMs: false,
            });
        }
    }

    mount() {
        this.subscribe(this.model.state.info, (dataSource) => {
            if (dataSource.kind !== 'foundry') return;

            if (dataSource.experiment.finalization) {
                this.state.data.next(dataSource.experiment.finalization);
            }
        });

        this.subscribe(
            combineLatest([AuthService.account, this.model.state.info, this.state.data]),
            ([account, dataSrc, data]) => {
                let canFinalize: CanFinalizeState = { kind: 'yes' };

                if (dataSrc.kind !== 'foundry') {
                    canFinalize = { kind: 'not-ready', message: 'Not uploaded to Foundry' };
                } else if (!dataSrc.experiment.executed_on) {
                    canFinalize = { kind: 'not-ready', message: 'Not produced' };
                } else if (dataSrc.experiment.finalization) {
                    canFinalize = { kind: 'already-finalized' };
                } else if (account?.username !== dataSrc.experiment.created_by) {
                    canFinalize = { kind: 'bad-user' };
                } else if (!data.full_name || !data.acknowledged_policy) {
                    canFinalize = { kind: 'invalid-input' };
                }

                this.state.canFinalize.next(canFinalize);
            }
        );
    }

    constructor(public model: HTE2MSModel) {
        super();
    }
}
