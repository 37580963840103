import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BehaviorSubject } from 'rxjs';
import { Column, DataTableModel, ObjectDataTableStore } from '../../../components/DataTable';
import { SelectionColumn } from '../../../components/DataTable/common';
import { InlineAlert } from '../../../components/common/Alert';
import { IconButton } from '../../../components/common/IconButton';
import { LabeledInput, SimpleSelectOptionInput, TextInput } from '../../../components/common/Inputs';
import useBehavior from '../../../lib/hooks/useBehavior';
import { DialogService } from '../../../lib/services/dialog';
import { ToastService } from '../../../lib/services/toast';
import { memoizeLatest } from '../../../lib/util/misc';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { BatchLink } from '../../ECM/ecm-common';
import { HTEInventory, HTEISolution, HTEProtocol, HTEPSolution } from '../data-model';
import { Formatters } from '../utils';
import { getSolutionInfo, SolutionInfo } from '../utils/inventory';
import { ValidationMessageToIcon } from '../utils/reagent-table';
import { ReagentValidation, validateSolutionReagent } from '../utils/validation';
import type { HTE2MSReagentsModel } from './reagents';

export interface SolutionRow {
    worklist_title: string;
    amounts: SolutionInfo['amounts'] | undefined;
    volume_l: number | undefined;
    solvent: string | undefined;
    source?: string;
    validation?: [kind: 'info' | 'danger' | 'warning' | 'success', message: string];
    n_uses: number;
    labware_id?: string;
}

export class HTE2MSSolutionReagentsModel extends ReactiveModel {
    private store: ObjectDataTableStore<SolutionRow, HTEPSolution> = new ObjectDataTableStore<
        SolutionRow,
        HTEPSolution
    >([
        { name: 'amounts', getter: (v) => this.info.get(v)?.amounts },
        { name: 'volume_l', getter: (v) => this.info.get(v)?.volume_l },
        { name: 'validation', getter: (v) => this.validation.get(v) },
        {
            name: 'worklist_title',
            getter: (v) => this.reagents.protocol.worklistMap.get(v.worklist_key)?.title ?? v.worklist_key,
        },
        { name: 'solvent', getter: (v) => this.info.get(v)?.solvent },
        { name: 'n_uses', getter: (v) => v.uses.length },
        {
            name: 'source',
            getter: (v) => {
                const inv = this.inventory.getSolution(v);
                if (!inv) return undefined;
                if (inv.source_barcode) return inv.source_barcode;
                if (inv.location?.container_label || inv.location?.container_well) {
                    return `${inv.location.container_label || '?'}@${inv.location.container_well || '?'}`;
                }
                return undefined;
            },
        },
        { name: 'labware_id', getter: (v) => this.inventory.getSolution(v)?.labware_id },
    ]);
    table: DataTableModel<SolutionRow>;

    private _reagentInfo = memoizeLatest((rows: HTEPSolution[], inventory: HTEInventory) => {
        const ret = new Map<HTEPSolution, SolutionInfo>();
        for (const r of rows) {
            ret.set(r, getSolutionInfo(this.mainModel, r));
        }
        return ret;
    });
    private get info() {
        return this._reagentInfo(this.all, this.inventory.data);
    }

    private _byKey = memoizeLatest((solutions: HTEPSolution[]) => new Map(solutions.map((r) => [r.key, r])));
    get byKey() {
        return this._byKey(this.all);
    }

    get mainModel() {
        return this.reagents.protocol.model;
    }

    get all() {
        return this.store.rawRows;
    }

    get selectedAndFilteredRows() {
        let selectedRows = this.table.getSelectedAndFilteredRowIndices();
        if (!selectedRows.length) selectedRows = this.table.rows as any;
        return selectedRows.map((i) => this.all[i]);
    }

    private _solutionValidation = memoizeLatest((rows: HTEPSolution[], inventory: HTEInventory) => {
        const ret = new Map<HTEPSolution, ReagentValidation>();
        for (const r of rows) {
            ret.set(r, validateSolutionReagent(this.mainModel, r));
        }
        return ret;
    });
    get validation() {
        return this._solutionValidation(this.all, this.inventory.data);
    }

    get inventory() {
        return this.mainModel.inventory;
    }

    editSource(rowIndex: number) {
        const solution = this.all[rowIndex];
        DialogService.open({
            type: 'generic',
            title: 'Edit Source',
            confirmButtonContent: 'Apply',
            model: this,
            defaultState: this.inventory.getSolution(solution) ?? {},
            wrapOk: true,
            content: EditSourceDialogContent,
            onOk: (inv: Partial<HTEISolution>) => {
                const next = { ...inv };
                if (inv.location && !inv.location?.container_label && !inv.location?.container_well) {
                    delete next.location;
                }
                return this.inventory.update({ solutions: [[solution, next]] });
            },
        });
    }

    update(data: HTEProtocol) {
        this.store.setRows(data.solutions ?? []);
        this.table.dataChanged();
    }

    confirmClear = () => {
        DialogService.open({
            type: 'confirm',
            title: 'Clear Inventory and Labware',
            confirmText: 'Clear',
            text: (
                <>
                    <p>Are you sure you want to clear the inventory and labware assignments?</p>
                </>
            ),
            onConfirm: this.clear,
        });
    };

    private clear = () => {
        const updates: [HTEPSolution, Partial<HTEISolution>][] = [];
        for (const r of this.selectedAndFilteredRows) {
            const inv = this.inventory.getSolution(r);
            if (!inv) continue;
            updates.push([r, { source_barcode: undefined, location: undefined, labware_id: undefined }]);
        }
        this.inventory.update({ solutions: updates });
        ToastService.show({
            message: 'Inventory and Labware cleared',
            type: 'info',
            id: 'hte2ms-reagents-clear',
            timeoutMs: 2500,
        });
    };

    mount() {
        this.subscribe(this.inventory.state.inventory, () => this.table.dataChanged());
    }

    constructor(public reagents: HTE2MSReagentsModel) {
        super();

        this.table = new DataTableModel<SolutionRow>(this.store, {
            columns: {
                worklist_title: {
                    ...Column.str(),
                    header: 'Worklist',
                    render: ({ value }) => value,
                    width: 100,
                },
                amounts: {
                    kind: 'generic',
                    header: 'Amounts',
                    format: (v) => '<unused>',
                    render: ({ value }) => {
                        if (!value) return null;
                        const { entities } = this.mainModel.assets;
                        return (
                            <div className='hte2ms-table-scroll-cell'>
                                <div>
                                    {value.map((x, i) => (
                                        <div key={i} className='hstack' style={{ marginTop: 4 }}>
                                            <div
                                                style={{ fontSize: '9px' }}
                                                className={`hte2ms-instruction-badge hte2ms-instruction-bg-add me-1 hte2ms-reactant-bg-${x.reactant_kind}`}
                                            >
                                                {x.reactant_kind.toUpperCase()}
                                            </div>
                                            {Formatters.amount(x.amount_g)} of{' '}
                                            <BatchLink identifier={entities.getIdentifier(x.identifier)} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    },
                    width: 280,
                    compare: false,
                    disableGlobalFilter: true,
                },
                volume_l: {
                    ...Column.float(),
                    header: 'Volume',
                    align: 'right',
                    render: ({ value }) => Formatters.volumeInL(value),
                    width: 80,
                },
                solvent: {
                    ...Column.str(),
                    header: 'Solvent',
                    render: ({ value }) => value,
                    width: 80,
                },
                n_uses: {
                    ...Column.float(),
                    header: '#',
                    render: ({ value }) => value,
                    width: 40,
                },
                source: {
                    ...Column.str(),
                    header: 'Source',
                    render: ({ value, rowIndex }) => {
                        const readOnly = this.mainModel.readOnlyDesignAndProduction;
                        if (readOnly) return value;

                        return (
                            <>
                                {value}
                                <IconButton
                                    onClick={() => this.editSource(rowIndex)}
                                    icon={faPencil}
                                    className='ms-2 p-0'
                                    size='sm'
                                />
                            </>
                        );
                    },
                    width: 250,
                },
                labware_id: {
                    ...Column.str(),
                    header: 'Labware',
                    render: ({ value, rowIndex }) => {
                        const readOnly = this.mainModel.readOnlyDesignAndProduction;
                        if (readOnly) {
                            return this.mainModel.design.labwareMap.get(value!)?.label ?? value;
                        }
                        const solution = this.all[rowIndex];
                        return (
                            <SimpleSelectOptionInput
                                value={value || ''}
                                className='ps-1 font-body-xsmall hte2ms-table-options'
                                allowEmpty
                                options={this.reagents.protocol.model.design.labwareOptions}
                                setValue={(v) =>
                                    this.inventory.update({ solutions: [[solution, { labware_id: v || undefined }]] })
                                }
                                invalidOptionLabel={value ? `[Invalid] ${value}` : `[Empty]`}
                            />
                        );
                    },
                    width: 240,
                },
                validation: {
                    kind: 'generic',
                    header: 'Validation',
                    format: (v) => '<unused>',
                    render: ({ value }) => {
                        if (!value) return null;
                        return (
                            <span className={`text-${value[0]}`} style={{ whiteSpace: 'break-spaces' }}>
                                <FontAwesomeIcon
                                    size='sm'
                                    fixedWidth
                                    className='me-2'
                                    icon={ValidationMessageToIcon[value[0]]}
                                />
                                {value[1]}
                            </span>
                        );
                    },
                    width: 300,
                    compare: (a, b) => {
                        if (!a && !b) return 0;
                        if (a && !b) return -1;
                        if (!a && b) return 1;
                        if (a![1] === b![1]) return 0;
                        return a![1] < b![1] ? -1 : 1;
                    },
                    disableGlobalFilter: true,
                },
            },
            actions: [SelectionColumn({ width: 30 })],
            hideNonSchemaColumns: true,
        });

        this.table.setRowHeight(72);
        this.table.setColumnStickiness('selection', true);
        this.table.setColumnStickiness('amounts', true);
    }
}

const LabelWidth = 160;

function EditSourceDialogContent({ stateSubject }: { stateSubject: BehaviorSubject<HTEISolution> }) {
    const state = useBehavior(stateSubject);

    return (
        <div className='vstack gap-2'>
            <InlineAlert>
                Specify either barcode or reservoir location. If both are specified, the barcode will be used.
            </InlineAlert>
            <LabeledInput label='Barcode' labelWidth={LabelWidth}>
                <TextInput
                    value={state.source_barcode ?? ''}
                    setValue={(v) => stateSubject.next({ ...state, source_barcode: v?.trim() || undefined })}
                    autoFocus
                />
            </LabeledInput>
            <LabeledInput label='Reservoir Label' labelWidth={LabelWidth}>
                <TextInput
                    value={state.location?.container_label ?? ''}
                    setValue={(v) =>
                        stateSubject.next({
                            ...state,
                            location: { ...state.location, container_label: v?.trim() || undefined },
                        })
                    }
                />
            </LabeledInput>
            <LabeledInput label='Reservoir Well' labelWidth={LabelWidth}>
                <TextInput
                    value={state.location?.container_well ?? ''}
                    setValue={(v) =>
                        stateSubject.next({
                            ...state,
                            location: { ...state.location, container_well: v?.trim() || undefined },
                        })
                    }
                />
            </LabeledInput>
        </div>
    );
}
