import { IconDefinition, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'react-bootstrap';

export function InlineAlert({
    icon = faInfoCircle,
    variant = 'info',
    className,
    children,
    iconTopLeft,
}: {
    icon?: IconDefinition;
    variant?: string;
    className?: string;
    children: React.ReactNode;
    iconTopLeft?: boolean;
}) {
    return (
        <div className={className}>
            <Alert variant={variant} className='m-0 p-2'>
                <div className='hstack gap-2'>
                    <FontAwesomeIcon icon={icon} className={`mx-1 ${iconTopLeft ? 'align-self-start mt-2' : ''}`} />
                    <span>{children}</span>
                </div>
            </Alert>
        </div>
    );
}
