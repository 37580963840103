import saveAs from 'file-saver';
import { BehaviorSubject } from 'rxjs';
import { parseFileSystemDate, parseYYYY_MM_DDDate, jsonISODateStringifyReplacer } from '../../../lib/util/dates';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { SampleCreate } from '../../ECM/ecm-api';
import {
    HTEApi,
    HTEBatchRegistrationInput,
    HTEBatchRegistrationResult,
    HTEDesignInfo,
    HTEFinalizeFormOptions,
} from '../experiment-api';
import {
    formatHTEId,
    HTESolvent,
    isHTESolvent,
    ReactionComponentSample,
    FoundryReactionCreate,
    Reactant,
} from '../experiment-data';
import { type HTEExperimentModel } from '../experiment-model';
import { findWellLayer, getWellIndexLabel } from '../plate/utils';

export class FinalizeModel {
    state = {
        executedOn: new BehaviorSubject<string>(''),
        crudePlateBarcode: new BehaviorSubject<string>(''),
        purifiedPlateBarcode: new BehaviorSubject<string>(''),
        crudePlate: new BehaviorSubject<HTEDesignInfo['crude_plate']>(undefined),
    };

    async lock() {
        try {
            await this.experiment.save(true);
            const info = await HTEApi.lock(this.experiment.id);
            this.experiment.state.info.next(info);
            this.experiment.state.isLocked.next(true);
        } catch (err) {
            reportErrorAsToast('Error locking experiment', err);
        }
    }

    async unlock() {
        try {
            const info = await HTEApi.unlock(this.experiment.id);
            this.experiment.state.info.next(info);
            this.experiment.state.isLocked.next(false);
        } catch (err) {
            reportErrorAsToast('Error unlocking experiment', err);
        }
    }

    async uploadPurifiedProductPlateBarcode() {
        const barcode = this.state.purifiedPlateBarcode.value;
        if (!barcode) return;
        try {
            const { experiment, purified_plate } = await HTEApi.uploadPurifiedProductPlateBarcode(
                this.experiment.id,
                barcode
            );
            this.experiment.state.info.next(experiment);
            this.state.purifiedPlateBarcode.next(purified_plate.barcode);
        } catch (err) {
            reportErrorAsToast('Error uploading purified product plate barcode', err);
        }
    }

    async finalize(finalization_data: HTEFinalizeFormOptions, progress: BehaviorSubject<string>) {
        try {
            this.experiment.clearSaveInterval();
            await this._finalize(finalization_data, progress);
        } catch (err) {
            reportErrorAsToast('Error uploding crude plate', err);
        } finally {
            this.experiment.setSaveInterval();
        }
    }

    async saveJSON() {
        try {
            const info = this.experiment.state.info.value;
            const executed_on = info.executed_on
                ? parseFileSystemDate(info.executed_on)
                : parseYYYY_MM_DDDate(this.state.executedOn.value, 6);
            const crude_plate_barcode = this.state.crudePlate.value?.barcode ?? this.state.crudePlateBarcode.value;

            // The Save JSON functionality is only available after the experiment is signed
            // and available as a fallback if the built-in plate registration fails.
            // It is ok to register the batches here as they use supplier ids bound to this
            // experiment and this will not create duplicate batches.
            const batchRegistration = await getRegisteredExperimentBatches(this.experiment);

            const { samples, reactions } = prepareFinalizeData(this.experiment, batchRegistration);
            const experiment = {
                ...this.experiment.state.info.value,
                executed_on,
            };
            delete experiment.design_user_state;
            const data = {
                experiment,
                crude_plate: {
                    barcode: crude_plate_barcode,
                    size: this.experiment.design.plate.layout,
                    samples,
                    kind: 'product',
                    status: 'Active',
                },
                reactions,
            };
            const json = JSON.stringify(data, jsonISODateStringifyReplacer, 2);
            const blob = new Blob([json], { type: 'application/json' });
            saveAs(blob, `${formatHTEId(info.id)}-data-${Date.now()}.json`);
        } catch (err) {
            reportErrorAsToast('Error generating JSON', err);
        }
    }

    getUnassignedBatches() {
        const { plate } = this.experiment.design;
        const missing = new Set<string>();
        for (const w of plate.wells) {
            if (!w) continue;
            for (const r of w) {
                if (isHTESolvent(r)) continue;
                const batch = this.experiment.batches.getBatch(r);
                if (!batch) missing.add(r);
            }
        }
        return missing;
    }

    private async _finalize(finalization_data: HTEFinalizeFormOptions, progress: BehaviorSubject<string>) {
        // set executed_on to the end of the day in case the experiment was created the same day
        const executed_on = parseYYYY_MM_DDDate(this.state.executedOn.value, 23);
        if (!executed_on) throw new Error(`'${this.state.executedOn.value}' is not a valid date.`);

        // if executed on is in the future, set it to the current time instead
        // to avoid a Foundry error
        const now = new Date(Date.now());
        if (executed_on > now) {
            executed_on.setHours(now.getHours(), now.getMinutes(), now.getSeconds());
        }

        progress.next('Saving experiment...');
        await this.experiment.save();

        progress.next('Registering batches...');
        const batchRegistration = await getRegisteredExperimentBatches(this.experiment);

        const { plate } = this.experiment.design;
        const { samples, reactions } = prepareFinalizeData(this.experiment, batchRegistration);

        progress.next('Uploading plate...');

        const { experiment, crude_plate } = await HTEApi.finalize(this.experiment.id, {
            plate_barcode: this.state.crudePlateBarcode.value,
            well_layout: plate.layout,
            executed_on: executed_on.toISOString(),
            samples,
            reactions,
            finalization_data,
        });

        this.experiment.state.info.next(experiment);
        this.state.crudePlate.next(crude_plate);
        this.experiment.clearSaveInterval();
    }

    constructor(public experiment: HTEExperimentModel, designInfo: HTEDesignInfo) {
        if (designInfo.crude_plate?.barcode) {
            this.state.crudePlateBarcode.next(designInfo.crude_plate?.barcode);
        }
        if (designInfo.purified_plate?.barcode) {
            this.state.purifiedPlateBarcode.next(designInfo.purified_plate?.barcode);
        }
        this.state.crudePlate.next(designInfo.crude_plate);
    }
}

// This operation is idempotent because of the use of supplier IDs
export function getRegisteredExperimentBatches(experiment: HTEExperimentModel) {
    const batchCompounds = prepareBatchRegistrationData(experiment);
    if (batchCompounds.length === 0) return undefined;

    return HTEApi.registerBatches({
        compounds: batchCompounds,
        hte_id: experiment.id,
    });
}

function prepareBatchRegistrationData(experiment: HTEExperimentModel) {
    const { plate } = experiment.design;
    const compounds: HTEBatchRegistrationInput['compounds'] = [];

    for (let wI = 0; wI < plate.layout; wI++) {
        const well = plate.wells[wI];
        if (!well?.length) {
            continue;
        }

        const msd = findWellLayer(well, 'msd', experiment.reactants.map);
        const bb = findWellLayer(well, 'bb', experiment.reactants.map);
        const reaction = msd && bb ? experiment.reactions.findReaction(msd.identifier, bb.identifier, wI) : undefined;
        const compound = experiment.batches.getCompound(reaction?.product_identifier);
        if (compound) {
            compounds.push({
                well_index: wI,
                compound_id: compound.id,
                compound_identifier: compound.universal_identifier!,
                structure: compound.structure.smiles,
            });
        }
    }

    return compounds;
}

function prepareFinalizeData(
    experiment: HTEExperimentModel,
    batchRegistration: HTEBatchRegistrationResult | undefined
) {
    const { plate } = experiment.design;
    const samples: (SampleCreate | null)[] = [];
    const reactions: FoundryReactionCreate[] = [];
    const addedReactions = new Set<number>();

    const { reactionScale } = experiment;
    const { conditions } = experiment.state.info.value;
    const { reaction_chemistry } = experiment.state.settings.value;

    for (let wI = 0; wI < plate.layout; wI++) {
        const well = plate.wells[wI];
        if (!well?.length) {
            samples.push(null);
        } else {
            let solvent: HTESolvent = 'DMSO';
            let solvent_volume = 0;

            const reactants: ReactionComponentSample[] = [];

            for (const id of well) {
                if (isHTESolvent(id)) {
                    solvent = id as HTESolvent;
                    break;
                }
            }

            let msd: Reactant | undefined;
            let bb: Reactant | undefined;
            let msd_sample: ReactionComponentSample | undefined;
            let bb_sample: ReactionComponentSample | undefined;
            let nonSolventReagentCount = 0;
            for (const id of well) {
                const reactant = experiment.reactants.getReactant(id);
                const volume = experiment.reactants.calcRxnVolume(reactant) ?? 0;
                solvent_volume += volume ?? 0;
                if (isHTESolvent(id)) {
                    continue;
                } else {
                    const batch = experiment.batches.getBatch(reactant.identifier);
                    if (!batch) throw new Error(`Missing batch ${reactant.identifier}`);
                    nonSolventReagentCount++;
                    const reactant_type =
                        reactant.type === 'reagent' && reactant.reagent_type ? reactant.reagent_type : reactant.type;
                    const sample: ReactionComponentSample = {
                        batch_id: batch.id,
                        concentration: experiment.reactants.calcConcentration(reactant) ?? 1.0,
                        solute_mass: `${experiment.reactants.calcRxnAmount(reactant) ?? 0.0} g`,
                        solvent,
                        solvent_volume: `${volume} L`,
                        // TODO: figure out how to assign this field and if we need it
                        parent_sample_id: undefined,
                        reactant_type,
                    };

                    if (reactant.type !== 'bb' && reactant.type !== 'msd') {
                        reactants.push(sample);
                    } else if (reactant.type === 'bb') {
                        bb = reactant;
                        bb_sample = sample;
                    } else if (reactant.type === 'msd') {
                        msd = reactant;
                        msd_sample = sample;
                    }
                }
            }

            const wellLabel = getWellIndexLabel(plate.layout, wI);

            if (nonSolventReagentCount === 0) {
                // Handle empty wells
                if (well.length > 0) {
                    throw new Error(`Solvent-only wells are not supported (well ${wellLabel})`);
                }
                samples.push(null);
            } else if (nonSolventReagentCount === 1) {
                // Handle single reagent/control wells

                const id = well.filter((x) => !isHTESolvent(x))[0];
                const reactant = experiment.reactants.getReactant(id);
                const batch = experiment.batches.getBatch(reactant.identifier)!;

                samples.push({
                    batch_id: batch.id,
                    solute_mass: `${experiment.reactants.calcRxnAmount(reactant) ?? 0.0} g`,
                    solvent,
                    solvent_volume: `${experiment.reactants.calcRxnVolume(reactant) ?? 0} L`,
                });
            } else {
                if (!msd) throw new Error(`Undefined reaction MSD at well ${wellLabel}`);
                if (!bb) throw new Error(`Undefined reaction BB at well ${wellLabel}`);

                // Determine reaction components
                const reactionDesign =
                    msd && bb ? experiment.reactions.findReaction(msd.identifier, bb.identifier, wI) : undefined;
                const product =
                    experiment.batches.getBatch(reactionDesign?.product_identifier) ?? batchRegistration?.[wI];

                if (!product) throw new Error(`Undefined reaction product at well ${wellLabel}`);

                if (!addedReactions.has(product.id)) {
                    addedReactions.add(product.id);
                    reactions.push({
                        batch_id: product.id,
                        conditions,
                        reactants,
                        // TODO: (separate PR) remove these 2 _id fields once Foundry has been updated.
                        bb_batch_id: experiment.batches.getBatch(bb.identifier)?.id,
                        msd_batch_id: experiment.batches.getBatch(msd.identifier)?.id,
                        msd_sample,
                        bb_sample,
                        reaction_chemistry,
                    });
                } else {
                    throw new Error(`Batch '${product.identifier}' appears at least twice.`);
                }

                const mols = Math.min(reactionScale * msd.equivalence, reactionScale * bb.equivalence);
                const solute_mass = mols * product.formula_weight;

                samples.push({
                    batch_id: product.id,
                    solute_mass: !solute_mass ? null : `${solute_mass} g`,
                    solvent: solvent_volume ? solvent : null,
                    solvent_volume: solvent_volume ? `${solvent_volume} L` : null,
                });
            }
        }
    }

    return { samples, reactions };
}
