// Source: Envision https://github.com/EntosAI/envision/blob/master/envision/src/util/index.ts

export function roundValueDigits(digits: number, value: number) {
    const abs = Math.abs(value);
    if (abs === 0.0) return 0;

    const E = Math.floor(Math.log10(abs));
    return roundValue(digits - E - 1, value);
}

export function roundValue(ndecimals: number, value: number) {
    // TODO: possible optimization to cache powers of 10
    const f = 10 ** ndecimals;
    return Math.round(f * value) / f;
}

export function getDecimalPlaces(value: string) {
    if (Number.isNaN(parseFloat(value))) throw new Error(`Cannot parse value ${value} as a number.`);
    const indexOfDecimal = value.indexOf('.');
    const decimalPlaces = indexOfDecimal > -1 ? value.length - indexOfDecimal - 1 : 0;
    return decimalPlaces;
}
