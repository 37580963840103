import { BehaviorSubject } from 'rxjs';
import { Column, columnDataTableStore, DataTableModel } from '../../../components/DataTable';
import { DownloadArtifactsCell, SmilesColumn } from '../../../components/DataTable/common';
import { AssayValueView } from '../../../lib/assays/display';
import { interpolateHSLtoRGB, toRGBString } from '../../../lib/util/colors';
import { AsyncMoleculeDrawer } from '../../../lib/util/draw-molecules';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { groupBy } from '../../../lib/util/misc';
import { EnumerationAPI, HTEEnumerationReactionInfoEntry } from '../enumeration/enumeration-api';
import { HTEApi, HTEDesignInfo, HTESignOptions } from '../experiment-api';
import { HTESignature, PlateDimensions, WellLayout } from '../experiment-data';
import { PlateMaxColorHSL, PlateMinColorHSL, PlateVisualModel, PlateWellColoring } from '../plate/PlateVisual';
import { getWellCoords, getWellIndexLabel } from '../plate/utils';
import { CompoundIdentifier } from '../steps/reagents-model';
import { createHTEHistoryTable, HTEHistoryRow } from './history';
import {
    HTEHistoryEntry,
    HTEReview,
    HTESnapshotAssayValue,
    HTESnapshotBatch,
    HTESnapshotInstance,
    HTESnapshotPlate,
    HTESnapshotReaction,
} from './sign-data';

export type HTESignStep =
    | 'history'
    | 'settings'
    | 'plates'
    | 'assays'
    | 'procedure'
    | 'observations'
    | 'finalization'
    | 'amendments'
    | 'sign';

const CheckedReviewSteps: HTESignStep[] = [
    'settings',
    'plates',
    'assays',
    'procedure',
    'observations',
    'finalization',
    'amendments',
];

export interface HTESignState {
    step: HTESignStep;
    reviewed: { [S in HTESignStep]?: boolean };
}

export interface HTESignStatus {
    signature?: HTESignature;
    counter_signature?: HTESignature;
}

export class HTESignModel {
    drawer = new AsyncMoleculeDrawer();

    // @ts-ignore
    details: HTEDesignInfo;
    // @ts-ignore
    snapshot: HTESnapshotInstance;
    // @ts-ignore
    chemistry?: HTEEnumerationReactionInfoEntry;
    // @ts-ignore
    history: HTEHistoryEntry[];
    // @ts-ignore
    historyTable: DataTableModel<HTEHistoryRow>;
    // @ts-ignore
    crudePlate?: PlateVisualModel | undefined;
    // @ts-ignore
    purifiedPlate?: PlateVisualModel | undefined;

    // @ts-ignore
    assets: {
        batches: Record<number, HTESnapshotBatch>;
        reactions: Record<number, HTESnapshotReaction>;
        assayValues: Record<number, Record<string, HTESnapshotAssayValue[]>>;
    };

    // @ts-ignore
    crudeTable?: DataTableModel;
    // @ts-ignore
    purifiedTable?: DataTableModel;

    state = {
        current: new BehaviorSubject<HTESignState>({
            step: 'history',
            reviewed: {},
        }),
        table: new BehaviorSubject<'crude' | 'purified'>('crude'),
        review: new BehaviorSubject<HTEReview | undefined>(undefined),
    };

    get data() {
        return this.snapshot.snapshot_data!;
    }

    get experiment() {
        return this.details.experiment;
    }

    get allStepsReviewed() {
        const { reviewed } = this.state.current.value;
        return CheckedReviewSteps.every((s) => !!reviewed[s]);
    }

    async sign(signature: Partial<HTESignOptions>) {
        if (signature.is_counter_signature && typeof this.state.review.value?.id !== 'number') {
            reportErrorAsToast('Sign', 'Review data missing');
            return;
        }

        try {
            const options: HTESignOptions = {
                ...(signature as HTESignOptions),
                snapshot_id: this.snapshot.id,
                review_id: this.state.review.value?.id,
            };
            const review = await HTEApi.sign(options);
            this.state.review.next(review);

            const tableIdx = this.historyTable.store.findValueIndex('snapshot_id', review.snapshot.id);
            if (tableIdx >= 0) {
                this.historyTable.store.setValue('signature', tableIdx, review.signature);
                this.historyTable.store.setValue('counter_signature', tableIdx, review.counter_signature);
                this.historyTable.dataChanged();
            }
        } catch (err) {
            reportErrorAsToast('Sign', err);
        }
    }

    getSMILES(batchId: number) {
        const { batches, reactions } = this.assets;
        const reaction = reactions[batchId];
        if (reaction) {
            const msd = batches[reaction.msd_sample?.batch_id ?? reaction.msd_batch_id!];
            const bb = batches[reaction.bb_sample?.batch_id ?? reaction.bb_batch_id!];
            const reagents = reaction.reactants.map((r) => batches[r.batch_id]);
            const product = batches[reaction.batch_id];

            if (!msd) throw new Error('Missing MSD batch');
            if (!bb) throw new Error('Missing BB batch');
            if (!reagents.every((r) => !!r)) throw new Error('Missing reactant batch');
            if (!product) throw new Error('Missing product batch');

            return `${msd.structure.smiles}.${bb.structure.smiles}>${reagents
                .map((r) => r.structure.smiles)
                .join('.')}>${product.structure.smiles}`;
        }

        const batch = batches[batchId];
        if (!batch) throw new Error('Missing batch');
        return batch.structure.smiles;
    }

    async init() {
        const [details, snapshot, history, chemistry] = await Promise.all([
            HTEApi.details(this.id),
            HTEApi.latestSnapshot(this.id),
            HTEApi.history(this.id),
            EnumerationAPI.reactionsInfo(),
        ]);

        this.details = details;
        this.snapshot = snapshot;
        this.history = history;
        const [latestHistory, historyTable] = createHTEHistoryTable(history, this.id);
        this.historyTable = historyTable;

        const allBatches: HTESnapshotBatch[] = [
            ...(this.data.plates.crude?.all_batches ?? []),
            ...(this.data.plates.purified?.all_batches ?? []),
        ];
        const allAssayValues: HTESnapshotAssayValue[] = [
            ...(this.data.plates.crude?.all_assay_values ?? []),
            ...(this.data.plates.purified?.all_assay_values ?? []),
        ];

        const assayValuesByBatch = groupBy(allAssayValues, (v) => v.batch_id);
        const assayValues: HTESignModel['assets']['assayValues'] = {};

        for (const [batch_id, values] of Array.from(assayValuesByBatch.entries())) {
            const byType = Object.fromEntries(groupBy(values, (v) => v.assay_type).entries());
            assayValues[batch_id] = byType;
        }

        this.assets = {
            batches: Object.fromEntries(allBatches.map((b) => [b.id, b])),
            reactions: Object.fromEntries(this.data.plates.crude?.reactions.map((r) => [r.batch_id, r]) ?? []),
            assayValues,
        };

        this.crudePlate = createPlateModel(this.data.plates.crude?.plate);
        this.crudeTable = buildAssayTable({ model: this, plate: this.data.plates.crude?.plate });

        this.purifiedPlate = createPlateModel(this.data.plates.purified?.plate);
        this.purifiedTable = buildAssayTable({ model: this, plate: this.data.plates.purified?.plate });

        this.state.review.next(latestHistory.review);
        if (latestHistory.review?.signature && latestHistory.review?.counter_signature) {
            this.state.current.next({
                ...this.state.current.value,
                reviewed: Object.fromEntries(CheckedReviewSteps.map((s) => [s, true])),
            });
        }

        this.chemistry = chemistry.find((e) => e.id === this.data.settings.reaction_chemistry);
    }

    constructor(public id: number) {}
}

function createPlateModel(plate?: HTESnapshotPlate) {
    if (!plate) return undefined;

    const visual = new PlateVisualModel(plate.size as WellLayout, {
        disableMultiselect: true,
        singleSelect: true,
    });
    const colors = createPlateColors(plate);
    visual.state.colors.next(colors);
    return visual;
}

function createPlateColors(plate: HTESnapshotPlate) {
    const layout = plate.size;
    const colors: (string | string[])[] = [];

    const batchSampleKey = new Map<string, number>();
    for (let i = 0; i < layout; i++) {
        const sample = plate.samples[i];
        if (sample) {
            const key = `${sample.batch_id}#${sample.sample_number}`;
            if (!batchSampleKey.has(key)) {
                batchSampleKey.set(key, batchSampleKey.size);
            }
        }
    }

    const sizeDenom = batchSampleKey.size - 1 || 1;

    for (let i = 0; i < layout; i++) {
        const sample = plate.samples[i];
        if (sample) {
            const idx = batchSampleKey.get(`${sample.batch_id}#${sample.sample_number}`) || 0;
            const color = interpolateHSLtoRGB(PlateMinColorHSL, PlateMaxColorHSL, idx / sizeDenom);
            colors.push(toRGBString(color));
        } else {
            colors.push(PlateWellColoring.NoColor);
        }
    }

    return colors;
}

function buildAssayTable({ model, plate }: { model: HTESignModel; plate?: HTESnapshotPlate }) {
    if (!plate) return undefined;

    const { batches, assayValues } = model.assets;

    const assaySet = new Set<string>();
    for (const s of plate.samples) {
        const vs = assayValues[s?.batch_id!];
        if (!vs) continue;
        for (const t of Array.from(Object.keys(vs))) assaySet.add(t);
    }

    const assayTypes = Array.from(assaySet);
    assayTypes.sort();

    const columns = ['structure', 'identifier', 'well', ...assayTypes];

    const data: any[][] = columns.map((c) => []);
    const index: number[] = [];

    const wellIndices = [];
    for (let wI = 0; wI < plate.samples.length; wI++) wellIndices.push(wI);
    const [width] = PlateDimensions[plate.size];
    wellIndices.sort((a, b) => {
        const [rA, cA] = getWellCoords(width, a);
        const [rB, cB] = getWellCoords(width, b);
        if (cA !== cB) return cA - cB;
        return rA - rB;
    });

    let offset = 0;
    for (const wI of wellIndices) {
        const s = plate.samples[wI];
        const batch = batches[s?.batch_id!];
        if (!batch) continue;

        index.push(offset++);
        data[0].push(batch.structure.smiles);
        data[1].push(batch.identifier);
        data[2].push(getWellIndexLabel(plate.size, wI));

        for (let tI = 0; tI < assayTypes.length; tI++) {
            data[3 + tI].push(assayValues[s!.batch_id]?.[assayTypes[tI]]);
        }
    }

    const store = columnDataTableStore({ columns, data, index });
    const tableColumns: Record<string, Column> = {
        structure: SmilesColumn(model.drawer, 2.5, {
            width: 180,
            identifierPadding: 18,
            getIdentifierElement: ({ rowIndex, table }) => (
                <span className='font-body-xsmall'>
                    <CompoundIdentifier value={table.store.getValue('identifier', rowIndex)} />
                </span>
            ),
        }),
        well: { ...Column.str(), noHeaderTooltip: true, width: 60, compare: false },
    };
    for (const t of assayTypes) {
        tableColumns[t] = Column.create<HTESnapshotAssayValue[] | undefined>({
            kind: 'obj',
            disableGlobalFilter: true,
            compare: false,
            header: t,
            noHeaderTooltip: true,
            format: () => '<unused>',
            render: ({ value }) => (
                <>
                    {!value?.length && null}
                    {value?.map((v, i) => (
                        <div key={i}>
                            <AssayValueView value={v.value} />{' '}
                            <DownloadArtifactsCell size='sm' artifacts={v.pdf_attachments} />
                        </div>
                    ))}
                </>
            ),
            width: 200,
        });
    }

    const table = new DataTableModel(store, {
        columns: tableColumns,
        hideNonSchemaColumns: true,
    });
    table.setCustomState({ 'show-smiles': true });
    table.setRowHeight(100);

    return table;
}
