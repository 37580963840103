import { faCopy, faVialCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Split from 'react-split-it';
import { useBlockLayout, useSortBy, useTable } from 'react-table';
import { LabeledInput } from '../../../components/common/Inputs';
import Loading from '../../../components/common/Loading';
import Pane from '../../../components/Pane/Pane';
import { defaultColumn } from '../../../components/ReactTable/Controls';
import useBehavior from '../../../lib/hooks/useBehavior';
import { BatchLink } from '../../ECM/ecm-common';
import { ECMManualWorkflowWrapper } from '../../ECM/workflows/common';
import { TransferManualInput, TransferManualStatus } from '../../ECM/workflows/Transfer';
import { Reactant } from '../experiment-data';
import { HTEExperimentModel } from '../experiment-model';
import { HTEExperimentTable } from './common';

export function ECMStep({ model }: { model: HTEExperimentModel }) {
    const info = useBehavior(model.state.info);
    const isDisabled = info.status !== 'Planning';

    useEffect(() => {
        model.ecm.mount();
        return () => model.ecm.dispose();
    }, []);

    return (
        <div className='hte-experiment-ecm-step'>
            <div className='hte-experiment-ecm-content'>
                <Split direction='horizontal' gutterSize={6} sizes={[0.35, 0.65]}>
                    <Pane title='Reactants' paddingIndex={2} topRightContent={<ReactantsButtons model={model} />}>
                        <ReactantTable model={model} />
                    </Pane>
                    <Pane
                        title='Transfer'
                        paddingIndex={2}
                        topRightContent={
                            <Button size='sm' className='invisible'>
                                &nbsp;
                            </Button>
                        }
                    >
                        {!isDisabled && (
                            <ECMManualWorkflowWrapper
                                model={model.ecm.transfer}
                                input={
                                    <TransferManualInput
                                        model={model.ecm.transfer}
                                        extra={<TransferInfo model={model} />}
                                    />
                                }
                                status={<TransferManualStatus model={model.ecm.transfer} showSubmit />}
                                inputsRatio={0.45}
                            />
                        )}
                        {isDisabled && <div>Experiment Locked</div>}
                    </Pane>
                </Split>
            </div>
        </div>
    );
}

function ReactantsButtons({ model }: { model: HTEExperimentModel }) {
    const isLoading = useBehavior(model.ecm.state.isLoading);

    if (isLoading)
        return (
            <Button size='sm' className='invisible'>
                &nbsp;
            </Button>
        );

    return (
        <div className='hstack gap-2'>
            <Button onClick={() => model.ecm.copyBarcodes()} size='sm' variant='outline-primary' title='Copy barcodes'>
                <FontAwesomeIcon size='sm' className='me-2' icon={faCopy} />
                Copy Barcodes
            </Button>
            {!model.isLocked && (
                <Button
                    onClick={() => model.ecm.autoAssignBarcodes()}
                    size='sm'
                    variant='outline-primary'
                    title='Assign missing barcodes to a vial with the largest amount'
                >
                    <FontAwesomeIcon size='sm' className='me-2' icon={faVialCircleCheck} />
                    Assign Missing Barcodes
                </Button>
            )}
        </div>
    );
}

const emptyList: any[] = [];
function ReactantTable({ model }: { model: HTEExperimentModel }) {
    const isLoading = useBehavior(model.ecm.state.isLoading);
    const reactants = useBehavior(model.ecm.state.reactants);
    const table = useTable<Reactant>(
        {
            columns: model.ecm.columns,
            data: isLoading ? emptyList : reactants,
            defaultColumn,
        },
        useSortBy,
        useBlockLayout
    );

    return isLoading ? <Loading /> : <HTEExperimentTable table={table} lineHeight={38} noPadding />;
}

function TransferInfo({ model }: { model: HTEExperimentModel }) {
    const info = useBehavior(model.ecm.state.currentTransferInfo);

    return (
        <>
            {info?.transferred_barcode && (
                <LabeledInput label='' className='ecm-manual-inputs-row text-warning'>
                    Already transferred to {info.transferred_barcode}
                </LabeledInput>
            )}
            <LabeledInput label='Reactant' className='ecm-manual-inputs-row'>
                {info && <BatchLink identifier={info.reactant_identifier} withQuery />}
                {!info && <span className='text-secondary ps-2'>-</span>}
            </LabeledInput>
            <LabeledInput label='Sample Batch' className='ecm-manual-inputs-row'>
                {info && <BatchLink identifier={info.new_identifier} withQuery />}
                {!info && <span className='text-secondary ps-2'>-</span>}
            </LabeledInput>
            <LabeledInput label='Req. Amount' className='ecm-manual-inputs-row'>
                {info && <b>{info.formattedAmount}</b>}
                {!info && <span className='text-secondary ps-2'>-</span>}
            </LabeledInput>
            <LabeledInput label='Req. Volume' className='ecm-manual-inputs-row'>
                {info && <b>{info.formattedVolume}</b>}
                {!info && <span className='text-secondary ps-2'>-</span>}
            </LabeledInput>
            <LabeledInput label='Req. Concentration' className='ecm-manual-inputs-row'>
                {info && <b>{info.formattedConcentration}</b>}
                {!info && <span className='text-secondary ps-2'>-</span>}
            </LabeledInput>
        </>
    );
}
