import React from 'react';
import Pane from '../../components/Pane/Pane';
import { formatDictionary } from '../../components/common/formatDictionary';
import { PropertyNameValue } from '../../components/common/PropertyNameValue';
import { parseFileSystemDate } from '../../lib/util/dates';
import { AssayDetail } from './assay-api';
import { objectIsEmpty } from '../../lib/util/misc';

export function AssayDetailPane({ assay }: { assay: AssayDetail }) {
    return (
        <Pane title='Assay Information'>
            <AssayDetailContent assay={assay} />
        </Pane>
    );
}

function AssayDetailContent({ assay }: { assay: AssayDetail }) {
    return (
        <>
            <div className='mb-4 mt-n2 font-body-small'>
                <PropertyNameValue field='Shorthand' value={`${assay.shorthand}`} />
                <PropertyNameValue field='Created on' value={parseFileSystemDate(assay.created_on).toLocaleString()} />
            </div>
            <div className='mb-4'>
                <h6 className='m-0 mb-1'>Details</h6>
                <AssayDetails assay={assay} />
            </div>
            {assay.details && !objectIsEmpty(assay.details) && (
                <div className='mb-4'>
                    <h6 className='m-0 mb-1'>Metadata</h6>
                    <AssayMetadata assay={assay} />
                </div>
            )}
        </>
    );
}

export function MultiAssayDetailPane({ assays }: { assays: AssayDetail[] }) {
    return (
        <Pane title='Assays Information'>
            {assays.map((assay, idx) => (
                <React.Fragment key={assay.id}>
                    {idx > 0 && <hr />}
                    <AssayDetailContent assay={assay} />
                </React.Fragment>
            ))}
        </Pane>
    );
}

export function AssayDetails({ assay }: { assay: AssayDetail }) {
    return (
        <div className='font-body-small'>
            <PropertyNameValue field='Property' value={assay.property_shorthand ?? ''} />
            <PropertyNameValue field='Category' value={assay.property.category} />
            <PropertyNameValue field='Measurement' value={assay.property.measurement} />
            <PropertyNameValue field='Environment' value={assay.property.environment} />
            <PropertyNameValue field='Protein' value={assay.property.protein ? assay.property.protein.full_name : ''} />
            <PropertyNameValue field='Kind' value={assay.kind} />
            <PropertyNameValue field='Source' value={assay.source} />
            <PropertyNameValue field='Description' value={assay.description} />
        </div>
    );
}

export function AssayMetadata({ assay }: { assay: AssayDetail }) {
    return <div className='font-body-small'>{formatDictionary(assay.details)}</div>;
}
