import { BehaviorSubject, combineLatest, distinctUntilChanged, map, throttleTime } from 'rxjs';
import { DataTableModel, ObjectDataTableStore } from '../../../components/DataTable';
import { isBatchEntity } from '../../../lib/services/assets';
import { ToastService } from '../../../lib/services/toast';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { ModelAction, ReactiveModel } from '../../../lib/util/reactive-model';
import { PrepareTransferInput } from '../../ECM/workflows/Transfer';
import type { DryReagentRow } from '../protocol/dry-reagents';
import { ReagentRow } from '../protocol/reagents';
import { Formatters } from '../utils';
import { getDryUseAmount } from '../utils/inventory';
import { commonDryReagentsColumnSchema, viewDryReagentDesignAction } from '../utils/reagent-table';
import type { CurrentTransferInfo, HTE2MSInventoryModel, TransferTargets } from './model';
import { HTEPReagentUse } from '../data-model';

export class DryTransfersModel extends ReactiveModel {
    store = new ObjectDataTableStore<DryReagentRow, HTEPReagentUse>([
        { name: 'identifier', getter: (v) => this.reagentsModel.getByKey(v.reagent_key)?.identifier },
        { name: 'amount_g', getter: (v) => getDryUseAmount(this.mainModel, v) },
        { name: 'transfer_barcode', getter: (v) => this.inventory.getDryUse(v)?.transfer_barcode },
        { name: 'reactant_kinds', getter: (v) => this.reagentsModel.dry.getInfo(v)?.reactant_kinds },
        { name: 'source_barcode', getter: (v) => this.inventory.getDryUse(v)?.source_barcode },
    ]);
    table: DataTableModel<ReagentRow>;

    state = {
        targets: new BehaviorSubject<TransferTargets | undefined>(undefined),
    };

    actions = {
        resolveTargets: new ModelAction({
            onError: (err) => {
                this.state.targets.next(err);
                reportErrorAsToast('Resolve vial', err);
            },
            toastErrorLabel: 'Apply transfer',
            applyResult: (t) => this.state.targets.next(t),
        }),
    };

    get reagentsModel() {
        return this.inventory.model.protocol.reagents;
    }

    get mainModel() {
        return this.inventory.model;
    }

    private update() {
        const protocol = this.mainModel.protocol.data;
        const uses: HTEPReagentUse[] = [];

        for (const r of protocol.reagents) {
            if (r.solvent) continue;
            for (const u of r.uses) {
                uses.push(u);
            }
        }

        this.store.setRows(uses);
        this.table.dataChanged();
    }

    private async resolveTargets(barcode: string, refresh = true): Promise<TransferTargets | undefined> {
        if (!barcode) {
            return;
        }

        const vial = await this.mainModel.assets.inventory.getVial(barcode, refresh);
        if (!vial?.sample) return { source_barcode: barcode, error: 'Barcode not found' };

        const batch = this.mainModel.assets.entities.batchesById.get(vial.sample.batch_id!)!;
        if (!batch) return { source_barcode: barcode, error: 'Vial batch not found' };

        const isDry = typeof vial.sample.solvent_volume !== 'number';
        if (!isDry) return { source_barcode: barcode, error: 'Vial must contain dry sample' };

        const uses = this.store.rawRows;

        const options: [number, string][] = [];

        for (let i = 0; i < uses.length; i++) {
            const use = uses[i];

            const reagent = this.reagentsModel.getByKey(use.reagent_key);

            if (!reagent) {
                console.warn('Reagent', use.reagent_key, 'not found');
                continue;
            }
            const entity = this.mainModel.assets.entities.getEntity(reagent.identifier);
            if (!entity) continue;

            const isValidTarget = isBatchEntity(entity)
                ? entity.compound_id === batch.compound_id && (batch.salt || '') === (entity.salt || '')
                : entity?.id === batch.compound_id;

            if (!isValidTarget) continue;

            const amount_g = this.store.getValue('amount_g', i);
            options.push([i, `${reagent.identifier}: ${Formatters.amount(amount_g)}`]);
        }

        if (options.length === 0) {
            return { source_barcode: barcode, error: 'No matching reagents found', options };
        }

        const rowIndex = this.tryFindNextAvailableRow(options, barcode);
        return {
            source_barcode: barcode,
            options,
            rowIndex,
            plateLocation: this.inventory.syncLocationPlate(uses[rowIndex!]),
        };
    }

    useAsTransfer = async () => {
        this.inventory.actions.applyTransfer.run(this.useAsTransfers());
    };

    private async useAsTransfers() {
        const target = this.state.targets.value;
        if (typeof target?.rowIndex !== 'number' || !target.source_barcode) return;

        const use = this.store.rawRows[target.rowIndex];

        if (this.mainModel.transferMode) {
            await this.inventory.updateTransferBarcode('dry', use.instruction_id, target.source_barcode);
        } else {
            await this.inventory.update({ dry: [[use, { transfer_barcode: target.source_barcode }]] });
        }
        this.table.dataChanged();

        let rowIndex: number | undefined;
        if (target.options) {
            rowIndex = this.tryFindNextAvailableRow(target.options!, target.source_barcode);
        }
        this.inventory.transfers.clear(false);
        if (rowIndex !== undefined) {
            this.state.targets.next({
                ...target,
                rowIndex,
                plateLocation: this.inventory.syncLocationPlate(this.store.rawRows[rowIndex!]),
            });
        } else {
            this.state.targets.next(undefined);
        }

        const reagent = this.reagentsModel.getByKey(use.reagent_key);
        ToastService.show({
            type: 'success',
            message: `Assigned ${target.source_barcode} to ${this.mainModel.assets.entities.getIdentifier(
                reagent?.identifier!
            )}`,
            timeoutMs: 1500,
        });
    }

    private tryFindNextAvailableRow(options: [rowIndex: number, label: string][], barcode: string) {
        let rowIndex: number | undefined;

        // Try finding exact match first
        for (const [idx] of options) {
            const use = this.store.rawRows[idx];
            const inv = this.inventory.data.dry[use.instruction_id];
            if (inv?.source_barcode === barcode && !inv?.transfer_barcode) {
                return idx;
            }
            if (inv?.transfer_barcode === barcode) {
                return idx;
            }
        }
        if (rowIndex === undefined) {
            for (const [idx] of options) {
                const use = this.store.rawRows[idx];
                const dry = this.inventory.data.dry[use.instruction_id];
                if (!dry?.transfer_barcode) {
                    return idx;
                }
            }
        }
    }

    private async applyTransfer(input: PrepareTransferInput) {
        if (!input.target_barcode) {
            ToastService.show({ type: 'danger', message: 'No target barcode provided', timeoutMs: 2500 });
            return this.inventory.transfers.clear();
        }

        const target = this.state.targets.value;
        if (target?.rowIndex === undefined) {
            ToastService.show({ type: 'danger', message: 'No target selected', timeoutMs: 2500 });
            return this.inventory.transfers.clear();
        }

        const use = this.store.rawRows[target.rowIndex];
        const reagent = this.reagentsModel.getByKey(use.reagent_key);

        if (reagent) {
            await this.mainModel.assets.inventory.syncInventory([reagent?.identifier], true);
        }

        if (this.mainModel.transferMode) {
            await this.inventory.updateTransferBarcode('dry', use.instruction_id, input.target_barcode);
        } else {
            await this.inventory.update({ dry: [[use, { transfer_barcode: input.target_barcode }]] });
        }

        // extra data changed to get the vial to show correctly
        this.table.dataChanged();

        let rowIndex: number | undefined;
        if (target.options) {
            rowIndex = this.tryFindNextAvailableRow(target.options!, target.source_barcode);
        }

        this.inventory.transfers.clear(rowIndex !== undefined);
        if (rowIndex !== undefined) {
            this.state.targets.next({
                ...target,
                rowIndex,
                plateLocation: this.inventory.syncLocationPlate(this.store.rawRows[rowIndex!]),
            });
        } else {
            this.state.targets.next(undefined);
        }

        ToastService.show({
            type: 'success',
            message: `Assigned ${input.target_barcode} to ${this.mainModel.assets.entities.getIdentifier(
                reagent?.identifier!
            )}`,
            timeoutMs: 1500,
        });
    }

    getCurrentTransferInfo(): CurrentTransferInfo | undefined {
        const transfer = this.state.targets.value;
        if (!transfer || typeof transfer.rowIndex !== 'number') return;

        const use = this.store.rawRows[transfer.rowIndex];
        if (!use) return;

        const reagent = this.reagentsModel.getByKey(use.reagent_key);
        const inv = this.inventory.data.dry[use.instruction_id];

        if (!reagent) {
            console.warn('Reagent', use.reagent_key, 'not found');
            return;
        }
        return {
            reagent,
            amount: Formatters.amount(this.store.getValue('amount_g', transfer.rowIndex)),
            labware: this.mainModel.design.labware.product.label,
            isTransferred: inv?.transfer_barcode === transfer.source_barcode,
        };
    }

    trySetCurrentTargetRowIndex = (rowIndex: number) => {
        const targets = this.state.targets.value;
        if (!targets) return;

        const contains = targets.options?.findIndex(([idx]) => idx === rowIndex) ?? -1;
        this.state.targets.next({
            ...targets,
            rowIndex: contains >= 0 ? rowIndex : undefined,
            plateLocation: contains >= 0 ? this.inventory.syncLocationPlate(this.store.rawRows[rowIndex!]) : undefined,
        });
    };

    mount() {
        this.subscribe(this.inventory.state.inventory, () => this.update());

        const transferInput = this.inventory.transfers.state.input.pipe(
            map((i) => i.source_barcode),
            throttleTime(400, undefined, { leading: false, trailing: true }),
            distinctUntilChanged()
        );

        this.subscribe(combineLatest([transferInput]), ([barcode]) =>
            this.actions.resolveTargets.run(this.resolveTargets(barcode.trim()))
        );

        this.subscribe(this.inventory.transfers.events.submitted, (entry) => {
            this.inventory.actions.applyTransfer.run(this.applyTransfer(entry));
        });

        this.subscribe(this.state.targets, (t) => {
            if (t?.rowIndex === undefined) this.table.setSelection([]);
            else this.table.setSelection([t.rowIndex]);
        });
    }

    constructor(public inventory: HTE2MSInventoryModel) {
        super();

        const columns = commonDryReagentsColumnSchema(inventory.model, this.store);
        const designAction = viewDryReagentDesignAction(inventory.model, this.store);

        this.table = new DataTableModel<DryReagentRow>(this.store, {
            columns: {
                identifier: columns.identifier,
                reactant_kinds: columns.reactant_kinds,
                amount_g: columns.amount_g,
                source_barcode: columns.source_barcode,
                transfer_barcode: columns.transfer_barcode,
            },
            hideNonSchemaColumns: true,
            actions: [designAction],
        });

        this.table.setColumnStickiness(designAction.id, true);
        this.table.setColumnStickiness('identifier', true);
    }
}
