import { faCopy, faDownload } from '@fortawesome/free-solid-svg-icons';
import { ClipboardService } from '../../lib/services/clipboard';
import { IconButton } from './IconButton';
import { TextInput } from './Inputs';

export function ProtocolText({ save, value, className }: { save: () => void; value: string; className?: string }) {
    return (
        <div className={`position-relative ${className ?? ''}`}>
            <TextInput
                value={value}
                style={{ fontFamily: 'monospace', resize: 'none', whiteSpace: 'pre' }}
                className='w-100 h-100 font-body-xsmall insight-protocol-text'
                textarea
                readOnly
            />
            <div className='position-absolute top-0 end-0 hstack gap-2 m-2'>
                <IconButton
                    variant='primary'
                    onClick={() => ClipboardService.copyText(value, 'Copy', 'Copy')}
                    title='Copy'
                    size='sm'
                    icon={faCopy}
                >
                    Copy
                </IconButton>
                <IconButton variant='primary' onClick={save} size='sm' title='Save' icon={faDownload}>
                    Save
                </IconButton>
            </div>
        </div>
    );
}
