import { arrayToCsv, objectsToRowArrays } from '../../../lib/util/arrayToCsv';

const HTEEnumerationReactionCSVColumns = [
    'MSD SMILES',
    'MSD Batch Identifier',
    'MSD Compound Identifier',
    'MSD Barcode',
    'MSD Order',
    'BB SMILES',
    'BB Batch Identifier',
    'BB Compound Identifier',
    'BB Barcode',
    'BB Order',
    'BB Group',
    'Product SMILES',
    'Product Count',
    'Product Filtered Count',
    'Products',
    'Filtered Products',
    'Error',
] as const;

type HTEEnumerationReactionCSVRow = Record<(typeof HTEEnumerationReactionCSVColumns)[number], string | number>;

export function createReactionsCSV(reactions: import('./enumeration-model').HTEEnumerationReaction[]) {
    const rows: HTEEnumerationReactionCSVRow[] = [];

    for (const r of reactions) {
        rows.push({
            'MSD SMILES': r.msd.smiles,
            'MSD Batch Identifier': r.msd.batch?.identifier ?? '',
            'MSD Compound Identifier': r.msd.compound?.identifier ?? '',
            'MSD Barcode': r.msd.vial?.sample?.barcode ?? '',
            'MSD Order': r.msd.order,
            'BB SMILES': r.bb.smiles,
            'BB Batch Identifier': r.bb.batch?.identifier ?? '',
            'BB Compound Identifier': r.bb.compound?.identifier ?? '',
            'BB Barcode': r.bb.vial?.sample?.barcode ?? '',
            'BB Order': r.bb.order,
            'BB Group': r.bb.bb_group,
            'Product SMILES': r.current_product ?? '',
            'Product Count': r.products.length,
            'Product Filtered Count': r.products.length,
            Products: r.products.join(' | '),
            'Filtered Products': r.filtered_products.join(' | '),
            Error: r.error ?? '',
        });
    }

    const csvRows = objectsToRowArrays(rows, HTEEnumerationReactionCSVColumns);
    return arrayToCsv(csvRows);
}
