import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Badge, Button, ButtonGroup, Dropdown, Spinner } from 'react-bootstrap';
import Split from 'react-split-it';
import { TableInstance, useBlockLayout, useGlobalFilter, useSortBy, useTable } from 'react-table';
import { AsyncMoleculeDrawing } from '../../../components/common/AsyncMoleculeDrawing';
import { TextInput } from '../../../components/common/Inputs';
import { ReactTableGlobalFilter } from '../../../components/ReactTable/Filters';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import { darkenColor, toRGBString } from '../../../lib/util/colors';
import { getReactantLayerType, Reactant, WellLayerType } from '../experiment-data';
import { HTEExperimentModel } from '../experiment-model';
import { PlateVisual } from '../plate/PlateVisual';
import {
    getFirstSelectedIndex,
    getPlateSelectionLabel,
    isWellSelectionEmpty,
    selectionSingleton,
} from '../plate/utils';
import { HTEExperimentTable, ReactantTypeBadge } from './common';
import { formatWellVolume, summarizePlateSelection } from './product-plate-model';
import { ReactantsFilter, useFilterReactants } from './reactants-model';
import { CompoundIdentifier } from './reagents-model';

export function ProductPlateStep({ model }: { model: HTEExperimentModel }) {
    useEffect(() => {
        model.productPlate.mount();
        return () => model.productPlate.unmount();
    });

    return (
        <div className='hte-experiment-product-plate-step'>
            <Split direction='horizontal' sizes={[0.5, 0.5]}>
                <Split direction='vertical' sizes={[0.63, 0.37]}>
                    <PlatePane model={model} />
                    <WellDetailsPane model={model} />
                </Split>
                <TablePane model={model} />
            </Split>
        </div>
    );
}

function PlatePane({ model }: { model: HTEExperimentModel }) {
    return (
        <div className='hte-experiment-product-plate-step-plate-pane'>
            <div className='hte-experiment-product-plate-step-plate-pane-header p-4 pb-0 pe-3 hstack gap-2'>
                <h5>
                    Product Plate
                    <WellStats model={model} />
                </h5>
                <div className='m-auto' />
                <ExportPlate model={model} />
            </div>
            <div className='hte-experiment-product-plate-step-plate-pane-visual'>
                <PlateVisual model={model.productPlate.visual} />
            </div>
            <div className='hte-experiment-product-plate-step-plate-pane-tools p-2 ps-4 pe-3 pt-1'>
                <PlateTools model={model} />
            </div>
        </div>
    );
}

function ExportPlate({ model }: { model: HTEExperimentModel }) {
    const [exportState, applyExport] = useAsyncAction();

    return (
        <Dropdown>
            <Dropdown.Toggle variant='outline-primary' size='sm' disabled={exportState.isLoading}>
                {exportState.isLoading && <Spinner animation='border' size='sm' className='me-2' role='status' />}
                Export
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item onClick={() => model.productPlate.saveECMCSV()}>
                    Compound management (.csv)
                </Dropdown.Item>
                <Dropdown.Item onClick={() => applyExport(model.productPlate.saveAnalyticalCSV())}>
                    Analytical (.csv)
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

function PlateTools({ model }: { model: HTEExperimentModel }) {
    const info = useBehavior(model.state.info);
    const isDisabled = info.status !== 'Planning';

    useEffect(() => {
        if (isDisabled) return;

        const onCopy = (e: ClipboardEvent) => {
            const tagName = (e.target as Element)?.tagName.toUpperCase();
            if (tagName === 'INPUT' || tagName === 'TEXTAREA') return;
            e.preventDefault();
            model.productPlate.copy();
        };
        const onPaste = (e: ClipboardEvent) => {
            const tagName = (e.target as Element)?.tagName.toUpperCase();
            if (tagName === 'INPUT' || tagName === 'TEXTAREA') return;
            e.preventDefault();
            model.productPlate.paste();
        };
        const onCut = (e: ClipboardEvent) => {
            const tagName = (e.target as Element)?.tagName.toUpperCase();
            if (tagName === 'INPUT' || tagName === 'TEXTAREA') return;
            e.preventDefault();
            model.productPlate.clearCopy();
        };
        document.addEventListener('copy', onCopy);
        document.addEventListener('paste', onPaste);
        document.addEventListener('cut', onCut);

        return () => {
            document.removeEventListener('copy', onCopy);
            document.removeEventListener('paste', onPaste);
            document.removeEventListener('cut', onCut);
        };
    }, []);

    return (
        <div className='hstack gap-2 w-100'>
            <ButtonGroup>
                <Button
                    size='sm'
                    variant='outline-info'
                    onClick={() => model.productPlate.visual.checkerizeSelection(0)}
                >
                    1
                </Button>
                <Button
                    size='sm'
                    variant='outline-info'
                    onClick={() => model.productPlate.visual.checkerizeSelection(1)}
                >
                    2
                </Button>
                <Button
                    size='sm'
                    variant='outline-info'
                    onClick={() => model.productPlate.visual.checkerizeSelection(2)}
                >
                    3
                </Button>
                <Button
                    size='sm'
                    variant='outline-info'
                    onClick={() => model.productPlate.visual.checkerizeSelection(3)}
                >
                    4
                </Button>
                <Button
                    size='sm'
                    variant='outline-info'
                    onClick={() => model.productPlate.visual.uncheckerizeSelection()}
                >
                    NQ
                </Button>
            </ButtonGroup>
            <ButtonGroup>
                <DisableButton model={model} />
                <Button
                    size='sm'
                    variant='outline-danger'
                    onClick={() => model.productPlate.clearWells()}
                    disabled={isDisabled}
                >
                    Clear
                </Button>
            </ButtonGroup>
            <ButtonGroup>
                <Button
                    size='sm'
                    variant='outline-success'
                    onClick={() => model.productPlate.fillWells('row')}
                    disabled={isDisabled}
                >
                    Fill Rows
                </Button>
                <Button
                    size='sm'
                    variant='outline-success'
                    onClick={() => model.productPlate.fillWells('col')}
                    disabled={isDisabled}
                >
                    Fill Cols
                </Button>
            </ButtonGroup>
            <ButtonGroup>
                <Button
                    size='sm'
                    variant='outline-warning'
                    onClick={() => model.productPlate.copy()}
                    title='Ctrl/CMD + C'
                    disabled={isDisabled}
                >
                    Copy
                </Button>
                <Button
                    size='sm'
                    variant='outline-warning'
                    onClick={() => model.productPlate.paste()}
                    title='Ctrl/CMD + V'
                    disabled={isDisabled}
                >
                    Paste
                </Button>
                <Button
                    size='sm'
                    variant='outline-warning'
                    onClick={() => model.productPlate.clearCopy()}
                    title='Clear: Ctrl/CMD + X or Double Click'
                    disabled={isDisabled}
                >
                    X
                </Button>
            </ButtonGroup>
            <div className='m-auto' />
            <SelectionInfo model={model} />
        </div>
    );
}

function DisableButton({ model }: { model: HTEExperimentModel }) {
    const design = useBehavior(model.state.design);
    const selection = useBehavior(model.productPlate.visual.state.selection);
    const info = useBehavior(model.state.info);
    const isDisabled = info.status !== 'Planning';

    const isEmpty = isWellSelectionEmpty(selection);
    const first = getFirstSelectedIndex(selection);
    const action = first >= 0 && !!design.plate.wells[first];

    return (
        <Button
            size='sm'
            variant='outline-danger'
            onClick={() => model.productPlate.disableWells(action)}
            disabled={isDisabled}
        >
            {(isEmpty || action) && 'Disable'}
            {!isEmpty && !action && 'Enable'}
        </Button>
    );
}

function SelectionInfo({ model }: { model: HTEExperimentModel }) {
    const design = useBehavior(model.state.design);
    const selection = useBehavior(model.productPlate.visual.state.selection);

    let volumeLabel: string | undefined;
    const singleton = selectionSingleton(selection);
    if (singleton >= 0) {
        const stats = model.productPlate.state.stats.value;
        volumeLabel = formatWellVolume(model, design.plate, stats, singleton);
    }

    const { label, count } = getPlateSelectionLabel(design.plate, selection);
    return (
        <small>
            {label}
            {count > 1 && <span className='text-secondary'>, {count} wells</span>}
            {volumeLabel && <span className='text-secondary'>, {volumeLabel}</span>}
        </small>
    );
}

function WellDetailsPane({ model }: { model: HTEExperimentModel }) {
    return (
        <div className='p-4 pe-3 pt-2 hte-experiment-product-plate-step-details-wrapper'>
            <WellDetails model={model} />
        </div>
    );
}

function TablePane({ model }: { model: HTEExperimentModel }) {
    const globalFilter = useRef<string>('');
    const stats = useBehavior(model.productPlate.state.stats);
    const design = useBehavior(model.state.design);
    const reactantsFilter = useBehavior(model.productPlate.state.reactantsFilter);
    const reactants = useFilterReactants(design.reactants, reactantsFilter);
    // hack to redraw the table when number of uses changes without copying all the data
    const columns = useMemo(() => [...model.productPlate.columns], [stats]);
    const table = useTable<Reactant>(
        {
            columns,
            data: reactants,
            initialState: {
                globalFilter: globalFilter.current,
            },
        },
        useGlobalFilter,
        useSortBy,
        useBlockLayout
    );

    useEffect(() => {
        model.productPlate.state.currentReactants.next(table.flatRows.map((r) => r.original));
    }, [table.flatRows]);

    const setGlobalFilter = useCallback(
        (v: any) => {
            globalFilter.current = v;
            table.setGlobalFilter(v);
        },
        [table.setGlobalFilter]
    );

    return (
        <div className='hte-experiment-product-plate-step-table-pane'>
            <div className='ps-4 pe-4 pt-4 pb-2'>
                <TableHeader table={table} setGlobalFilter={setGlobalFilter} model={model} />
            </div>

            <div className='hte-experiment-product-plate-table-content'>
                <HTEExperimentTable
                    table={table}
                    onRowMouseEnter={(row) => model.productPlate.highlightCompound(row.original.identifier)}
                    onRowMouseLeave={() => model.productPlate.highlightCompound()}
                    lineHeight={60}
                />
            </div>
        </div>
    );
}

function TableHeader({
    table,
    setGlobalFilter,
    model,
}: {
    table: TableInstance<Reactant>;
    setGlobalFilter: (v: any) => any;
    model: HTEExperimentModel;
}) {
    return (
        <div className='vstack gap-1'>
            <ReactTableGlobalFilter
                preGlobalFilteredRows={table.preGlobalFilteredRows}
                globalFilter={table.state.globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
            <ReactantsFilter filter={model.productPlate.state.reactantsFilter} reactants={model.reactants} />
        </div>
    );
}

function WellStats({ model }: { model: HTEExperimentModel }) {
    const stats = useBehavior(model.productPlate.state.stats);

    return (
        <span className='ps-2 text-secondary hte-experiment-product-plate-step-well-stats'>
            {stats.numNonEmpty}/{model.design.plate.layout} wells filled, {stats.numDisabled} disabled,{' '}
            {stats.reactionCounts.size}/{model.design.reactions.length} products{' '}
            <OverflowColoringButton model={model} count={stats.numOverflowing} />
        </span>
    );
}

function WellDetails({ model }: { model: HTEExperimentModel }) {
    const selection = useBehavior(model.productPlate.visual.state.throttledSelection);
    useBehavior(model.state.design);
    const summary = summarizePlateSelection(model, selection);
    const info = useBehavior(model.state.info);
    const isDisabled = info.status !== 'Planning';

    const renderType = (type: WellLayerType, width = 'w-50') => {
        if (type === 'unknown') return null;

        const reactants = summary.reactantsByType.get(type);
        const color = model.reactants.bbGroupColorMap.get(reactants?.[0]?.group_name ?? '');
        return (
            <div className={`hstack gap-2 ${width ?? ''}`} key={type}>
                <ReactantTypeBadge type={type as any} />
                {!reactants?.length && <span className='text-secondary'>-</span>}
                {reactants?.length === 1 && (
                    <>
                        <CompoundIdentifier value={reactants[0].identifier} />
                        {getReactantLayerType(reactants[0]) === 'bb' && (
                            <TextInput
                                value={reactants[0].group_name}
                                className='hte-experiment-badge-like hte-experiment-badge-like-input'
                                readOnly
                                style={{
                                    background: color?.rgb,
                                    border: color ? `1px solid ${toRGBString(darkenColor(color.raw, 0.9))}` : undefined,
                                }}
                            />
                        )}
                    </>
                )}
                {reactants?.length! > 1 && summary.singleton < 0 && <>{reactants?.length} reactants</>}
                {reactants?.length! > 1 && summary.singleton >= 0 && (
                    <>
                        {reactants?.map((r) => (
                            <CompoundIdentifier value={r.identifier} key={r.identifier} />
                        ))}
                    </>
                )}
                {!!reactants?.length && (
                    <Button
                        variant='outline-danger'
                        size='sm'
                        onClick={() => model.productPlate.clearLayer(type)}
                        title='Remove'
                        disabled={isDisabled}
                    >
                        <FontAwesomeIcon icon={faClose} size='sm' className='ms-1 me-1' />
                    </Button>
                )}
            </div>
        );
    };

    return (
        <div className='vstack gap-1 hte-experiment-product-plate-step-well-summary'>
            <div className='hstack'>
                {renderType('msd')}
                {renderType('solvent')}
            </div>
            <div className='hstack'>
                {renderType('bb')}
                {renderType('catalyst')}
            </div>
            <div className='hstack'>
                {renderType('reagent', summary.productId ? undefined : 'w-100')}
                {summary.productId && (
                    <div className='hstack gap-2'>
                        <Badge className='hte-experiment-type-current-product hte-experiment-type-badge'>Product</Badge>
                        <CompoundIdentifier value={summary.productId} />
                    </div>
                )}
            </div>

            {summary.reactionSMILES && (
                <div className='hte-experiment-product-plate-step-reaction-drawing'>
                    <AsyncMoleculeDrawing smiles={summary.reactionSMILES} drawer={model.drawer} />
                </div>
            )}
            {summary.reactantSMILES && (
                <div className='hte-experiment-product-plate-step-reactant-drawing'>
                    <AsyncMoleculeDrawing smiles={summary.reactantSMILES} drawer={model.drawer} />
                </div>
            )}
        </div>
    );
}

function OverflowColoringButton({ model, count }: { model: HTEExperimentModel; count: number }) {
    const show = useBehavior(model.productPlate.state.showOverflowWells);

    if (!count) return null;

    return (
        <Button
            onClick={() => model.productPlate.state.showOverflowWells.next(!show)}
            variant={show ? 'danger' : 'outline-danger'}
            className='ms-2 hte-experiment-overflow-wells-button'
        >
            {count} overflowing volume
        </Button>
    );
}
