import { faCopy, faShuffle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import log from 'loglevel';
import { Column, Row } from 'react-table';
import { BehaviorSubject, combineLatest, distinctUntilKeyChanged } from 'rxjs';
import { SimpleSelectOptionInput } from '../../../components/common/Inputs';
import { ClipboardService } from '../../../lib/services/clipboard';
import { ToastService } from '../../../lib/services/toast';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { groupBy } from '../../../lib/util/misc';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { roundValue } from '../../../lib/util/roundValues';
import { Batch, CompoundDetail } from '../../Compounds/compound-api';
import { formatSampleContentInline, Vial } from '../../ECM/ecm-api';
import { OpenBatchIcon } from '../../ECM/ecm-common';
import { ManualVialTransferWorkflow } from '../../ECM/workflows/Transfer';
import { HTEApi } from '../experiment-api';
import { isHTESolvent, Reactant } from '../experiment-data';
import { type HTEExperimentModel } from '../experiment-model';
import { ReplaceReactantInfo } from './reactants-model';

export interface CurrentTransferInfo {
    reactant_identifier: string;
    new_identifier: string;
    transferred_barcode?: string;
    formattedAmount: string;
    formattedVolume: string;
    formattedConcentration: string;
}

export class HTEECMModel extends ReactiveModel {
    private batchIdToVials = new Map<number, Vial[]>();
    private compoundIdToBarcodeOptions = new Map<number, [string, string][]>();
    private vialsByBarcode = new Map<string, Vial>();
    public transfer = new ManualVialTransferWorkflow();

    readonly state = {
        isLoading: new BehaviorSubject<boolean>(false),
        reactants: new BehaviorSubject<Reactant[]>([]),
        currentTransferInfo: new BehaviorSubject<CurrentTransferInfo | undefined>(undefined),
    };

    private getPreferredIdentifier(r: Reactant, entity: Batch | CompoundDetail) {
        if (r.type === 'reagent') return entity.reagent_identifier ?? entity.identifier!;
        if (r.type === 'bb') return entity.bb_identifier ?? entity.identifier!;
        if (r.type === 'msd') return entity.msd_identifier ?? entity.identifier!;
        if (entity.entos_identifier) return entity.entos_identifier;
        return entity.identifier!;
    }

    private reactantBatchOptions = new Map<number, [string, string][]>();
    private getReactantOptions(r: Reactant) {
        const compound = this.experiment.batches.getCompound(r.identifier)!;

        if (this.reactantBatchOptions.has(compound.id)) {
            return this.reactantBatchOptions.get(compound.id)!;
        }

        const batches = this.experiment.batches.getBatchesFromCompoundId(compound.id);
        const options = [
            this.getPreferredIdentifier(r, compound),
            ...batches.map((b) => this.getPreferredIdentifier(r, b)),
        ].map((i) => [i, i] as [string, string]);

        options[0][1] = this.getAllIdentifiers(options[0][0]);
        this.reactantBatchOptions.set(compound.id, options);
        return options;
    }

    private getAllIdentifiers(identifier: string) {
        const entity = this.experiment.batches.getEntity(identifier);
        const all = [
            entity?.entos_identifier,
            entity?.universal_identifier,
            entity?.bb_identifier,
            entity?.msd_identifier,
            entity?.reagent_identifier,
        ].filter((i) => !!i && i !== identifier);
        if (!all) return identifier;
        return `${identifier} (${all.join('/')})`;
    }

    readonly columns: Column<Reactant>[] = [
        {
            Header: 'Identifier',
            id: 'identifier',
            accessor: (row) => row.identifier,
            Cell: ({ value, row }: { value: string; row: Row<Reactant> }) => {
                const options = this.getReactantOptions(row.original);
                const entity = this.experiment.batches.getEntity(value)!;
                const current = this.getPreferredIdentifier(row.original, entity);

                return (
                    <>
                        <SimpleSelectOptionInput
                            value={current}
                            options={options}
                            setValue={(newIdentifier) =>
                                this.experiment.reactants.replaceReactantEntities([
                                    {
                                        reactant: row.original,
                                        newIdentifier,
                                    },
                                ])
                            }
                            className='hte-experiment-select-batch'
                            title={value}
                            disabled={this.experiment.stateInfo.status !== 'Planning'}
                        />
                        <OpenBatchIcon identifier={value} className='ms-1' />
                    </>
                );
            },
            width: 220,
        },
        {
            Header: '',
            id: 'transfer',
            disableSortBy: true,
            accessor: (row) => row.transfer_barcode,
            width: 40,
            Cell: ({ value }: { value: string | undefined }) => {
                if (value)
                    return (
                        <FontAwesomeIcon icon={faShuffle} className='text-success' title={`Transferred to ${value}`} />
                    );
                return null;
            },
        },
        {
            Header: `Vial Sample`,
            id: 'sample',
            accessor: (row) => this.vialsByBarcode.get(row.barcode!),
            width: 200,
            Cell: ({ value }: { value: Vial | null }) => {
                if (!value?.sample) return null;
                return <span>{formatSampleContentInline(value.sample)}</span>;
            },
        },
        {
            Header: `mg req.`,
            id: 'Required amount (incl. overage)',
            accessor: (row) => this.experiment.reactants.calcTotalStockAmount(row),
            width: 100,
            Cell: ({ value, row }: { value: number | null; row: Row<Reactant> }) => {
                const vial = this.vialsByBarcode.get(row.original.barcode!);
                let cls: string;
                if (vial && roundValue(4, vial?.sample?.solute_mass ?? 0) >= roundValue(4, value ?? 0)) {
                    cls = 'text-success';
                } else if (!vial) {
                    cls = 'text-warning';
                } else {
                    cls = 'text-danger';
                }
                return <span className={cls}>{this.formatTotalAmountValue(value)}</span>;
            },
        },
        {
            Header: `μL req.`,
            id: 'Required volume (incl. overage)',
            accessor: (row) => this.experiment.reactants.calcTotalStockVolume(row),
            width: 100,
            Cell: ({ value, row }: { value: number | null; row: Row<Reactant> }) => {
                const vial = this.vialsByBarcode.get(row.original.barcode!);
                const isWet = typeof vial?.sample?.solvent_volume === 'number';
                let cls: string;
                if (isWet && roundValue(4, vial?.sample?.solvent_volume ?? 0) >= roundValue(4, value ?? 0)) {
                    cls = 'text-success';
                } else if (!vial || !isWet) {
                    cls = 'text-warning';
                } else {
                    cls = 'text-danger';
                }
                return <span className={cls}>{this.formatTotalVolumeValue(value)}</span>;
            },
        },
        {
            Header: `Conc. req.`,
            id: 'Required concentration',
            accessor: (row) => this.experiment.reactants.calcConcentration(row),
            width: 100,
            Cell: ({ value, row }: { value: number | null; row: Row<Reactant> }) => {
                const vial = this.vialsByBarcode.get(row.original.barcode!);
                const isWet = typeof vial?.sample?.solvent_volume === 'number';
                let cls: string;
                if (isWet && Math.abs((vial?.sample?.concentration ?? 0) - (value ?? 0)) < 5e-5) {
                    cls = 'text-success';
                } else if (!vial || !isWet) {
                    cls = 'text-warning';
                } else {
                    cls = 'text-danger';
                }
                return <span className={cls}>{this.formatConcentrationValue(value)}</span>;
            },
        },
        {
            Header: 'Barcode',
            id: 'barcode',
            accessor: (row) => row.barcode ?? '',
            Cell: ({ value, row }: { value: string; row: Row<Reactant> }) => (
                <SelectBarcode
                    options={
                        this.compoundIdToBarcodeOptions.get(
                            this.experiment.batches.getCompound(row.original.identifier)?.id!
                        ) ?? []
                    }
                    value={value}
                    setValue={(barcode) => this.updateReactantBarcode(row.original, barcode)}
                    disabled={this.experiment.stateInfo.status !== 'Planning'}
                />
            ),
            width: 260,
        },
    ];

    private updateReactantBarcode(reactant: Reactant, barcode: string | undefined, options?: { isTransfer?: boolean }) {
        if (!barcode) {
            this.experiment.reactants.editValue('barcode', reactant.identifier, undefined);
            return;
        }

        const batch = this.experiment.batches.getBatchFromId(this.vialsByBarcode.get(barcode)?.sample?.batch_id);
        if (!batch) {
            log.warn('Missing batch for Vial', barcode);
            return;
        }

        this.experiment.reactants.replaceReactantEntities([
            {
                reactant,
                newIdentifier: this.getPreferredIdentifier(reactant, batch)!,
                options: options?.isTransfer
                    ? {
                          barcode,
                          transfer_barcode: barcode,
                      }
                    : {
                          barcode,
                      },
            },
        ]);
    }

    private readonly formatTotalAmountValue = (v: number | null) =>
        typeof v === 'number' ? `${roundValue(1, v * 1e3).toString()} mg` : '-';
    private readonly formatTotalVolumeValue = (v: number | null) =>
        typeof v === 'number' ? `${roundValue(1, v * 1e6).toString()} μL` : '-';
    private readonly formatConcentrationValue = (v: number | null) =>
        typeof v === 'number' ? `${roundValue(1, v * 1e3).toString()} mM` : '-';

    private getBarcodeOptions(vials: Vial[]): [string, string][] {
        const sorted = [...vials];
        sorted.sort((a, b) => (b.sample?.solute_mass ?? 0) - (a.sample?.solute_mass ?? 0));
        return sorted.map(
            (v) =>
                [
                    v.barcode,
                    `${v.barcode} (${this.formatTotalAmountValue(v.sample?.solute_mass ?? 0)}) Batch #${
                        this.experiment.batches.getBatchFromId(v.sample?.batch_id)?.batch_number ?? '?'
                    }`,
                ] as [string, string]
        );
    }

    async syncVials() {
        try {
            this.state.isLoading.next(true);
            const reactants = this.getECMReactants();

            const batch_ids = new Set<number>();
            for (const r of reactants) {
                const compoundId = this.experiment.batches.getCompound(r.identifier)?.id;
                if (typeof compoundId !== 'number') continue;

                const batches = this.experiment.batches.getBatchesFromCompoundId(compoundId);
                for (const b of batches) batch_ids.add(b.id);
            }
            let vials = await HTEApi.loadVials({ batch_ids: Array.from(batch_ids) });
            vials = vials.filter(
                (v) => v.status !== 'Disposed' && !!this.experiment.batches.getBatchFromId(v.sample?.batch_id)
            );

            this.batchIdToVials = groupBy(vials, (v) => v.sample?.batch_id!);

            const compoundIdToVials = groupBy(
                vials,
                (v) => this.experiment.batches.getBatchFromId(v.sample?.batch_id)?.compound_id!
            );
            this.compoundIdToBarcodeOptions = new Map(
                Array.from(compoundIdToVials.entries()).map(([id, xs]) => [id, this.getBarcodeOptions(xs)])
            );
            this.vialsByBarcode = new Map<string, Vial>(vials.map((v) => [v.barcode, v]));
        } catch (err) {
            reportErrorAsToast('Sync Vials', err);
        } finally {
            this.state.isLoading.next(false);
        }
    }

    private getECMReactants() {
        return this.experiment.design.reactants.filter(
            (r) => this.experiment.productPlate.getNumberOfUses(r.identifier) > 0 && !isHTESolvent(r.identifier)
        );
    }

    private findReactantFromBarcode(barcode: string) {
        const vial = this.vialsByBarcode.get(barcode.toUpperCase().trim());
        const batch = this.experiment.batches.getBatchFromId(vial?.sample?.batch_id!);
        if (!batch || !vial) {
            this.state.currentTransferInfo.next(undefined);
            return undefined;
        }
        const batches = this.experiment.batches;
        const fromBatch = this.state.reactants.value.find((r) => {
            const rb = batches.getBatch(r.identifier);
            return rb ? rb.id === batch.id : false;
        });
        if (fromBatch) return fromBatch;

        // fallback to finding it by compound
        // doing this in separate loops so that batches are more specific
        return this.state.reactants.value.find((r) => {
            const rc = batches.getCompound(r.identifier);
            return rc ? rc.id === batch.compound_id : false;
        });
    }

    copyBarcodes() {
        const barcodes = this.state.reactants.value
            .filter((r) => r.barcode)
            .map((r) => r.barcode)
            .join('\n');
        ClipboardService.copyText(barcodes, 'Copy Vial Barcodes', 'Copy Barcodes');
    }

    autoAssignBarcodes() {
        const isNanoScale = this.experiment.design.plate.layout >= 384;
        const replacements: ReplaceReactantInfo[] = [];

        for (const reactant of this.state.reactants.value) {
            if (reactant.barcode) continue;

            const compoundId = this.experiment.batches.getCompound(reactant.identifier)?.id;
            if (typeof compoundId !== 'number') continue;

            const batches = this.experiment.batches.getBatchesFromCompoundId(compoundId);
            let availableVials = batches.flatMap((b) => this.batchIdToVials.get(b.id) ?? []);

            if (availableVials.length === 0) continue;

            if (isNanoScale) {
                const preferredVials = availableVials.filter(
                    (v) => v.barcode.startsWith('HG') || v.barcode.startsWith('HB')
                );
                if (preferredVials.length > 0) availableVials = preferredVials;
            } else {
                const preferredVials = availableVials.filter(
                    (v) => !v.barcode.startsWith('HG') && !v.barcode.startsWith('HB')
                );
                if (preferredVials.length > 0) availableVials = preferredVials;
            }

            let bestVial = availableVials[0];
            for (const v of availableVials) {
                if (v.sample?.solute_mass! > bestVial.sample?.solute_mass!) {
                    bestVial = v;
                }
            }

            replacements.push({
                reactant,
                newIdentifier: this.getPreferredIdentifier(
                    reactant,
                    this.experiment.batches.getBatchFromId(bestVial.sample?.batch_id)!
                ),
                options: {
                    barcode: bestVial.barcode,
                },
            });
        }
        this.experiment.reactants.replaceReactantEntities(replacements);
        requestAnimationFrame(() => this.experiment.save());
    }

    mount() {
        this.syncVials();

        this.subscribe(this.experiment.state.design.pipe(distinctUntilKeyChanged('reactants')), () => {
            this.state.reactants.next(this.getECMReactants());
        });

        this.subscribe(combineLatest([this.transfer.state.input, this.transfer.state.kind]), ([input, kind]) => {
            const reactant = this.findReactantFromBarcode(input.source_barcode);
            const vial = this.vialsByBarcode.get(input.source_barcode);
            const vialBatch = this.experiment.batches.getBatchFromId(vial?.sample?.batch_id);
            if (!reactant || !vialBatch) {
                this.state.currentTransferInfo.next(undefined);
                return;
            }

            const amount = this.experiment.reactants.calcTotalStockAmount(reactant, vialBatch.formula_weight);
            const volume = this.experiment.reactants.calcTotalStockVolume(reactant, vialBatch.formula_weight);
            const conc = this.experiment.reactants.calcConcentration(reactant, vialBatch.formula_weight);
            this.state.currentTransferInfo.next({
                reactant_identifier: reactant.identifier,
                new_identifier: this.getPreferredIdentifier(reactant, vialBatch),
                transferred_barcode: reactant.transfer_barcode,
                formattedAmount: this.formatTotalAmountValue(amount),
                formattedVolume: this.formatTotalVolumeValue(volume),
                formattedConcentration: this.formatConcentrationValue(conc),
            });
        });

        this.subscribe(this.transfer.events.submitted, async (entry) => {
            if (!entry.target_barcode) return;
            const reactant = this.findReactantFromBarcode(entry.source_barcode!);
            if (!reactant) return;

            try {
                this.transfer.state.submitting.next(true);

                const batch = this.experiment.batches.getBatchFromId(
                    this.vialsByBarcode.get(entry.source_barcode!)?.sample?.batch_id
                );
                if (typeof batch?.id !== 'number') return;

                const batch_ids = this.experiment.batches.getBatchesFromCompoundId(batch.compound_id).map((b) => b.id);
                const vials = await HTEApi.loadVials({ batch_ids });
                this.batchIdToVials.set(batch.id, vials);
                for (const v of vials) this.vialsByBarcode.set(v.barcode, v);
                this.compoundIdToBarcodeOptions.set(batch.compound_id, this.getBarcodeOptions(vials));

                this.updateReactantBarcode(reactant, entry.target_barcode, { isTransfer: true });

                requestAnimationFrame(() => this.experiment.save());
            } catch (err) {
                reportErrorAsToast('Update reactant', err);
            } finally {
                this.transfer.state.submitting.next(false);
            }
        });
    }

    constructor(public experiment: HTEExperimentModel) {
        super();
    }
}

export function SelectBarcode({
    value,
    setValue,
    options,
    disabled,
}: {
    value?: string;
    setValue: (v: string) => void;
    options: [string, string][];
    disabled?: boolean;
}) {
    return (
        <>
            <SimpleSelectOptionInput
                value={value ?? ''}
                options={options}
                setValue={setValue}
                allowEmpty
                className='hte-experiment-select-barcode'
                disabled={disabled}
                title={value}
            />
            <button
                type='button'
                title='Click to copy barcode'
                onClick={() => {
                    navigator.clipboard.writeText(value ?? '');
                    ToastService.show({
                        type: 'success',
                        message: 'Copied to Clipboard',
                        timeoutMs: 1500,
                    });
                }}
                className='bg-transparent border-0 hte-experiment-copy-barcode'
                disabled={!value}
            >
                <FontAwesomeIcon icon={faCopy} fixedWidth className='text-secondary' />
            </button>
        </>
    );
}
