import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useRef } from 'react';
import { Badge, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/common/Loading';
import { PageTemplate } from '../../components/Layout/Layout';
import useBehavior from '../../lib/hooks/useBehavior';
import { AssayProperty } from '../../lib/models/biology';
import { EcosystemService } from '../../lib/services/ecosystem';
import {
    Column,
    ColumnsFor,
    DataTableGlobalFilter,
    DataTableControl,
    DataTableModel,
    DataTableStore,
    tableRowNavigation,
    DefaultCompareWithBlanks,
} from '../../components/DataTable';
import { AssayDetail } from './assay-api';
import { AssayUploadModel } from './upload/assay-upload-model';
import { AssayCurveReview, AssayNonCurveReview } from './upload/AssayValueReview';
import { BayesComputationModal, UploadAssayModal } from './upload/Modals';

const AssayTableSchema: ColumnsFor<AssayDetail> = {
    id: Column.create({
        kind: 'int',
        noHeaderTooltip: true,
        width: 80,
    }),
    common_unit_shorthand: Column.create({
        kind: 'str',
        header: 'Shorthand',
        noHeaderTooltip: true,
        filterType: false,
        width: 460,
    }),
    property: Column.create({
        kind: 'obj',
        noHeaderTooltip: true,
        width: 90,
        format: (v: AssayProperty) => `${v.id}`,
        compare: (a: AssayProperty, b: AssayProperty) => DefaultCompareWithBlanks(a.id, b.id),
        render: ({ value }: { value: AssayProperty }) => value.id,
        disableGlobalFilter: true,
    }),
    measurement: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
    }),
    source: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
    }),
    value_count: Column.create({
        kind: 'int',
        noHeaderTooltip: true,
    }),
    description: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        width: 400,
    }),
    hidden: Column.create({
        kind: 'bool',
        noHeaderTooltip: true,
        width: 100,
        filterFn: (_) => (v, test) => {
            if (test === undefined || test === null || Number.isNaN(test)) return true;
            return v === test;
        },
    }),
};

function createTable(assays: DataTableStore<AssayDetail>) {
    const table = new DataTableModel(assays, {
        columns: AssayTableSchema,
        hideNonSchemaColumns: true,
    });

    table.setFilter('hidden', false);
    table.setHiddenColumns([...table.state.hiddenColumns, 'hidden']);

    return table;
}

export default function Assays() {
    const modelRef = useRef<AssayUploadModel>();
    if (!modelRef.current) {
        modelRef.current = new AssayUploadModel();
    }
    const model = modelRef.current;
    const assayUploadStep = useBehavior(model.state.step);

    useEffect(() => {
        if (model) model.mount();
        if (!model) return;
        return () => model.dispose();
    }, [model]);

    if (assayUploadStep !== 'unstarted') return <AssayUploadContent model={model} onCancel={() => model.cancel()} />;

    return <AssayList model={model} />;
}

function AssayList({ model }: { model: AssayUploadModel }) {
    const assays = useBehavior(EcosystemService.assays);
    const table = useMemo(() => (assays ? createTable(assays) : undefined), [assays]);

    useEffect(() => {
        EcosystemService.syncAssays();
    }, []);

    return (
        <PageTemplate title='Assays'>
            <div className='position-relative h-100 d-flex flex-column overflow-hidden'>
                {table && <AssayTableHeader model={model} table={table} />}
                <div className='flex-grow-1 position-relative'>
                    {!table && <Loading />}
                    {table && <TableWrapper table={table} />}
                </div>
            </div>
            <UploadAssayModal model={model} />
            <BayesComputationModal model={model} />
        </PageTemplate>
    );
}

function AssayTableHeader({ model, table }: { model: AssayUploadModel; table: DataTableModel<AssayDetail> }) {
    useBehavior(table.version);

    return (
        <>
            <div className='flex-0 mx-3 mt-3'>
                <h4>{!table ? '' : table.rows.length} Assays</h4>
            </div>
            <div className='d-flex justify-content-between mx-3 mb-3'>
                <div className='d-flex w-50 align-items-center'>
                    {table && (
                        <>
                            <div className='flex-grow-1'>
                                <DataTableGlobalFilter table={table} />
                            </div>
                            <div>
                                <Form.Check
                                    className='mx-2'
                                    id='toggle-hidden-assays'
                                    type='checkbox'
                                    label='Show hidden?'
                                    onChange={(evt) =>
                                        table.setFilter('hidden', evt.target.checked ? undefined : false)
                                    }
                                />
                            </div>
                        </>
                    )}
                </div>
                <div className='d-flex'>
                    <div className='me-1'>
                        <ComputationNotice model={model} />
                    </div>
                    <Button variant='primary' onClick={() => model.state.modal.next('upload')}>
                        Upload Assay Values
                    </Button>
                </div>
            </div>
        </>
    );
}

function TableWrapper({ table }: { table: DataTableModel<AssayDetail> }) {
    const navigate = useNavigate();
    useBehavior(table.version);

    const onRowDoubleClick = (rowIndex: number, tbl: DataTableModel<AssayDetail>, e: React.MouseEvent) => {
        if (!tbl) return;
        tableRowNavigation(e, `/assays/${tbl.store.getValue('id', rowIndex)}`, navigate);
    };

    return (
        <DataTableControl
            height='flex'
            table={table}
            headerSize='sm'
            onRowDoubleClick={onRowDoubleClick}
            rowSelectionMode='single'
        />
    );
}

function AssayUploadContent({ model, onCancel }: { model: AssayUploadModel; onCancel: () => void }) {
    const isCurveMeasurement = useBehavior(model.state.isCurveMeasurement);
    return (
        <PageTemplate
            title='Assays'
            breadcrumb={{ title: 'Review and Confirm Assay Upload', href: '', icon: undefined }}
        >
            <div className='container-with-header-and-footer'>
                {isCurveMeasurement && <AssayCurveReview model={model} onCancel={() => onCancel()} />}
                {!isCurveMeasurement && <AssayNonCurveReview model={model} onCancel={() => onCancel()} />}
            </div>
        </PageTemplate>
    );
}

function ComputationNotice({ model }: { model: AssayUploadModel }) {
    const computations = useBehavior(model.state.computations);
    const numCompleted = computations.filter(
        (c) => ['success', 'error', 'cancelled'].includes(c.status) && !c.metadata?.done
    ).length;

    return (
        <Button
            variant='link'
            title='Bayesian Computations'
            onClick={() => model.state.modal.next('bayes')}
            className='position-relative'
        >
            <FontAwesomeIcon icon={faBell} color='var(--bs-secondary-text)' fixedWidth />
            {numCompleted > 0 && (
                <sup>
                    <Badge bg='primary'>
                        <span className='text-white'>{numCompleted}</span>
                    </Badge>
                </sup>
            )}
        </Button>
    );
}
