import { isRelativelyClose } from '../../../lib/util/math';
import { SampleContents } from '../../Compounds/compound-api';
import {
    ECMSearchResult,
    formatSampleContentInSearch,
    isInventorySearchResult,
    isTubeBarcode,
} from '../../ECM/ecm-api';
import {
    HTEDLabwareDefinition,
    HTEInventory,
    HTEPReagent,
    HTEPReagentUse,
    HTEPSolution,
    HTEPSolutionUse,
    UniversalIdentifierT,
} from '../data-model';
import type { HTE2MSModel } from '../model';

interface ReagentSampleInfo {
    n_mols: number; // includes "per-use overage"
    amount_g: number | undefined;
    volume_l: number | undefined;
    reactant_kinds: string[];
}

interface ReagentInventoryInfo {
    best_barcode?: string;
    best_dry_source_barcode?: string;
    best_labware_id?: string;
    inventoryOptions: [string, string][];
    searchResults: ECMSearchResult[];
}

export type ReagentInfo = ReagentSampleInfo & ReagentInventoryInfo & { ecm_sample: SampleContents };

export interface SolutionInfo {
    amounts: { identifier: UniversalIdentifierT; reactant_kind: string; amount_g: number }[];
    volume_l: number | undefined;
    solvent: string | undefined;
}

export function getDryReagentBarcode(model: HTE2MSModel, use: HTEPReagentUse) {
    const dry_xfer = model.inventory.data.dry[use.instruction_id];
    return dry_xfer?.transfer_barcode || dry_xfer?.source_barcode;
}

export function getLiquidReagentBarcode(model: HTE2MSModel, r: HTEPReagent) {
    const inv = model.inventory.data.liquid[r.key];
    return inv?.transfer_barcode || inv?.source_barcode;
}

export function getDryUseAmount(model: HTE2MSModel, use: HTEPReagentUse) {
    const fw = getReagentFW(model, use);
    if (!fw) return undefined;
    return use.n_mols * use.overage * fw;
}

function isDryUse(x: any): x is HTEPReagentUse {
    return !!x && typeof (x as HTEPReagentUse).n_mols === 'number' && !!(x as HTEPReagentUse).instruction_id;
}

export function getReagentFW(model: HTE2MSModel, reagent: HTEPReagent | HTEPReagentUse) {
    const barcode = isDryUse(reagent) ? getDryReagentBarcode(model, reagent) : getLiquidReagentBarcode(model, reagent);
    if (barcode) {
        const vialBatch = model.assets.inventory.getVialBatch(barcode);
        return vialBatch?.formula_weight;
    }
    const identifier = isDryUse(reagent)
        ? model.protocol.reagents.getByKey(reagent.reagent_key)?.identifier!
        : reagent.identifier;
    return model.assets.entities.getFW(identifier);
}

export function getReagentInfo(model: HTE2MSModel, reagent: HTEPReagent, reactant_kinds: string[]): ReagentInfo {
    const sample = getSampleInfo(model, reagent, reactant_kinds);
    const inv = getInventoryInfo(model, reagent, sample);

    return {
        ...sample,
        ...inv,
        ecm_sample: {
            solvent: reagent.solvent,
            concentration: reagent.concentration,
            solvent_volume: typeof sample.volume_l === 'number' ? sample.volume_l * 1e-3 : undefined,
            solute_mass: sample.amount_g,
        },
    };
}

export function getSolutionInfo(model: HTE2MSModel, solution: HTEPSolution): SolutionInfo {
    const n_mols = solution.uses.reduce((acc, u) => acc + u.overage * u.n_mols, 0);
    const equivalence = solution.components.reduce((acc, c) => acc + c.equivalence, 0);

    const kinds = new Set<string>();
    for (const use of solution.uses) {
        const reaction = model.design.reactionMap.get(use.reaction_id);
        if (!reaction) continue;
        for (const instr of reaction.template.instructions) {
            if (instr.kind === 'add' && instr.solution_id === use.solution_id) kinds.add(instr.reactant_kind);
        }
    }

    let volume_l = 0;
    for (const c of solution.components) {
        if (!c.concentration) continue;
        volume_l += ((c.equivalence / equivalence) * n_mols) / c.concentration;
    }

    const inv = model.inventory.getSolution(solution);
    const deadVolume = model.design.labwareMap.get(inv?.labware_id!)?.dead_volume ?? 0;
    const totalVolume_l = volume_l + deadVolume * 1e3;

    return {
        amounts: solution.components.map((c) => ({
            identifier: c.identifier,
            reactant_kind: c.reactant_kind,
            amount_g:
                (totalVolume_l / (volume_l || 1)) *
                (c.equivalence / equivalence) *
                n_mols *
                model.assets.entities.getFW(c.identifier)!,
        })),
        volume_l: totalVolume_l,
        solvent: solution.components.find((c) => c.solvent)?.solvent ?? solution.solvent,
    };
}

export function getSolutionUseVolumeL(solution: HTEPSolution, use: HTEPSolutionUse) {
    const equivalence = solution.components.reduce((acc, c) => acc + c.equivalence, 0) || 1;
    let volume_l = 0;
    for (const c of solution.components) {
        if (!c.concentration) continue;
        volume_l += ((c.equivalence / equivalence) * use.n_mols) / c.concentration;
    }
    return volume_l;
}

function getSampleInfo(model: HTE2MSModel, reagent: HTEPReagent, reactant_kinds: string[]): ReagentSampleInfo {
    const n_mols = reagent.uses.reduce((acc, u) => acc + u.overage * u.n_mols, 0);
    const fw = getReagentFW(model, reagent);
    return {
        n_mols,
        amount_g: fw ? n_mols * fw : undefined,
        volume_l: reagent.concentration ? n_mols / reagent.concentration : undefined,
        reactant_kinds,
    };
}

const Tolerance = 0.025; // 2.5%

function getInventoryInfo(model: HTE2MSModel, reagent: HTEPReagent, info: ReagentSampleInfo): ReagentInventoryInfo {
    let searchResults =
        model.assets.inventory.getByIdentifier(reagent.identifier)?.filter((e) => isInventorySearchResult(e)) ?? [];
    const isBatch = reagent.identifier.includes('-');

    const srcBatch = isBatch ? model.assets.entities.getBatch(reagent.identifier) : undefined;
    if (isBatch && !srcBatch) {
        console.warn('Batch not found', reagent.identifier);
    }

    if (srcBatch) {
        // Filter down to batches with the same salt
        searchResults = searchResults.filter((e) => {
            const batch = model.assets.entities.batchesById.get(e.sample!.batch_id);
            if (!batch) {
                console.warn('Batch not found for inventory result', e);
                return false;
            }
            return (batch.salt || '') === (srcBatch.salt || '');
        });
    }

    const isLiquid = typeof reagent.concentration === 'number' && typeof reagent.solvent === 'string';

    const targetSolvent = reagent.solvent?.toUpperCase();
    const targetConc = reagent.concentration!;
    const solvent = isLiquid
        ? searchResults.filter(
              (e) =>
                  e.sample!.solvent?.toUpperCase() === targetSolvent &&
                  isRelativelyClose(e.sample?.concentration!, targetConc, Tolerance)
          )
        : [];
    const dry = searchResults.filter((e) => !e.sample!.solvent_volume);

    solvent.sort((a, b) => {
        const tubeA = isTubeBarcode(a.barcode);
        const tubeB = isTubeBarcode(b.barcode);
        if (tubeA !== tubeB) {
            return tubeA ? -1 : 1;
        }
        if (a.sample!.solvent_volume !== b.sample!.solvent_volume) {
            return b.sample!.solvent_volume! - a.sample!.solvent_volume!;
        }
        if (a.barcode === b.barcode) {
            return 0;
        }
        return a.barcode < b.barcode ? -1 : 1;
    });

    dry.sort((a, b) => {
        if (a.sample!.solute_mass !== b.sample!.solute_mass) {
            return b.sample!.solute_mass! - a.sample!.solute_mass!;
        }
        if (a.barcode === b.barcode) {
            return 0;
        }
        return a.barcode < b.barcode ? -1 : 1;
    });

    let best_labware: HTEDLabwareDefinition | undefined;

    const labware = [...model.design.labware.sources].sort((a, b) => a.volume - b.volume);
    if (isLiquid) {
        best_labware = labware[labware.length - 1];
        for (const w of labware) {
            if (1e-3 * info.volume_l! + w.dead_volume < (1 + Tolerance) * w.volume) {
                best_labware = w;
                break;
            }
        }
    }

    const final = isLiquid ? [...solvent, ...dry] : dry;
    const options = final.map(
        (r) => [r.barcode, `${r.barcode}: ${formatSampleContentInSearch(r.sample) || '«empty»'}`] as [string, string]
    );

    const best_barcode = isLiquid
        ? findBestWetInventory({ wet: solvent, dry, info, labware: best_labware })
        : findBestDryInventory(model, dry);

    if (best_barcode && best_labware?.barcode_prefixes?.length) {
        let isPrefixOk = false;
        for (const p of best_labware.barcode_prefixes) {
            if (best_barcode.startsWith(p)) {
                isPrefixOk = true;
                break;
            }
        }

        if (!isPrefixOk) {
            const idx = labware.indexOf(best_labware) + 1;
            if (idx < labware.length - 1) {
                best_labware = labware[idx];
            }
        }
    }

    return {
        best_barcode,
        best_dry_source_barcode: dry[0]?.barcode,
        best_labware_id: best_labware?.id,
        inventoryOptions: options,
        searchResults: final,
    };
}

function findBestDryInventory(model: HTE2MSModel, dry: ECMSearchResult[]) {
    const productPlatePrefixes = model.design.labware.product.barcode_prefixes;
    if (!productPlatePrefixes?.length) return dry[0]?.barcode;

    for (const inv of dry) {
        for (const p of productPlatePrefixes) {
            if (!inv.barcode.startsWith(p)) {
                return inv.barcode;
            }
        }
    }
}

function findBestWetInventory({
    wet,
    dry,
    info,
    labware,
}: {
    wet: ECMSearchResult[];
    dry: ECMSearchResult[];
    info: ReagentSampleInfo;
    labware?: HTEDLabwareDefinition;
}) {
    const req = 1e-3 * info.volume_l! + (labware?.dead_volume ?? 0);
    for (const inv of wet) {
        if ((1 + Tolerance) * inv.sample!.solvent_volume! > req) {
            return inv.barcode;
        }
    }
    return dry[0]?.barcode;
}

export function getFinalRequired(
    model: HTE2MSModel,
    reagent: HTEPReagent,
    inventory: HTEInventory,
    info: ReagentInfo
): { amount_g?: number; volume_l?: number } | undefined {
    const inv = inventory.liquid[reagent.key];
    const labware = model.design.labwareMap.get(inv?.labware_id!);

    if (!inv || !labware) {
        return undefined;
    }

    const isWet = typeof reagent.concentration === 'number' && typeof reagent.solvent === 'string';
    const volume_l = isWet ? info.volume_l! + (labware.dead_volume || 0) * 1e3 : undefined;

    const amount_g = isWet ? (info.amount_g! * volume_l!) / (info.volume_l! || 1) : info.amount_g!;

    return { amount_g, volume_l };
}
