import { arrayToCsv } from '../../../lib/util/arrayToCsv';
import { roundValue } from '../../../lib/util/roundValues';
import { HTEMaterializedTransfer } from '../data-model';
import type { HTE2MSModel } from '../model';

export function transfersToCSV(model: HTE2MSModel, transfers: HTEMaterializedTransfer[]) {
    const columns = [
        'Source Barcode',
        'Source Well',
        'Target Bacode',
        'Target Well',
        'Volume',
        'Volume Unit',
        'Concentration',
        'Concentration Unit',
        'Solvent',
        'Identifier',
        'Reactant Kinds',
        'Reference Barcode',
    ];

    const rows = transfers.map((a) => [
        a.source_barcode,
        a.source_well,
        a.target_barcode,
        a.target_well,
        `${roundValue(2, a.volume * 1e9)}`,
        'uL',
        a.concentration ? `${roundValue(3, a.concentration * 1e3)}` : '',
        a.concentration ? 'mM' : '',
        a.solvent,
        a.identifier ? model.assets.entities.getIdentifier(a.identifier) : '',
        a.reactant_kinds?.join('|') || '',
        a.reference_barcode || '',
    ]);

    return arrayToCsv([columns, ...rows]);
}
