import { arrayToCsv, objectsToRowArrays } from '../../../lib/util/arrayToCsv';
import { roundValue } from '../../../lib/util/roundValues';
import { getUnitFormatter } from '../../../lib/util/units';
import { DataTableStore } from '../../../components/DataTable';
import { Batch } from '../../Compounds/compound-api';
import { PlateDimensions } from '../../HTE/experiment-data';
import { getWellCoords, plateRowLabel } from '../../HTE/plate/utils';
import { Plate } from '../ecm-api';

const PlateMapCSVColumns = [
    'Library Identifier',
    'Plate Barcode',
    'Plate Size',
    'Plate Kind',
    'Well',
    'Row',
    'Column',
    'Amount',
    'Amount Unit',
    'Volume',
    'Volume Unit',
    'Concentration',
    'Concentration Unit',
    'Solvent',
    'Batch Identifier',
    'Formula Weight',
    'Purpose',
    'Description',
] as const;

type PlateMapCSVRow = Record<(typeof PlateMapCSVColumns)[number] | 'row' | 'col', string | number>;

function getPlateMapRows(plate: Plate, batches: DataTableStore<Batch>, options?: { libraryIdentifier?: string }) {
    const rows: PlateMapCSVRow[] = [];

    const [width] = PlateDimensions[plate.size];

    const { formatCSV: formatAmount, csvUnit: amountUnit } = getUnitFormatter('g', 'm');
    const { formatCSV: formatVolume, csvUnit: volumeUnit } = getUnitFormatter('L', 'u', { factor: 1e3 });
    const { formatCSV: formatConcentration, csvUnit: concentrationUnit } = getUnitFormatter('M', 'm');
    const libraryIdentifer = options?.libraryIdentifier ?? '';

    for (let wI = 0; wI < plate.size; wI++) {
        const sample = plate.samples[wI];
        if (!sample) continue;

        const [row, col] = getWellCoords(width, wI);
        const well = `${plateRowLabel(row)}${col + 1}`;

        const batchIdx = batches.findValueIndex('id', sample.batch_id);

        rows.push({
            'Library Identifier': libraryIdentifer,
            'Plate Barcode': plate.barcode,
            'Plate Size': plate.size,
            'Plate Kind': plate.kind,
            Well: well,
            Row: plateRowLabel(row),
            Column: `${col + 1}`,
            Amount: formatAmount(sample.solute_mass),
            'Amount Unit': amountUnit,
            Volume: formatVolume(sample.solvent_volume),
            'Volume Unit': volumeUnit,
            Concentration: formatConcentration(sample.concentration),
            'Concentration Unit': concentrationUnit,
            Solvent: sample.solvent ?? '',
            'Batch Identifier': batches.getValue('identifier', batchIdx) ?? '',
            'Formula Weight': roundValue(3, batches.getValue('formula_weight', batchIdx)) ?? '',
            Purpose: plate.purpose ?? '',
            Description: plate.description ?? '',
            row,
            col,
        });
    }

    rows.sort((a, b) => {
        if (a.col !== b.col) return (a.col as number) - (b.col as number);
        return (a.row as number) - (b.row as number);
    });

    return rows;
}

export function createPlatemapCSV(
    plates: { plate: Plate; batches: DataTableStore<Batch>; libraryIdentifier?: string }[]
) {
    const rows: PlateMapCSVRow[] = [];

    for (const { plate, batches, libraryIdentifier } of plates) {
        for (const r of getPlateMapRows(plate, batches, { libraryIdentifier })) {
            rows.push(r);
        }
    }
    const csvRows = objectsToRowArrays(rows, PlateMapCSVColumns);
    return arrayToCsv(csvRows);
}
