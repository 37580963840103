import { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { LabeledInput } from '../../../components/common/Inputs';
import { LogModel } from '../../../components/common/Log';
import { PropertyNameValue } from '../../../components/common/PropertyNameValue';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import { ToastService } from '../../../lib/services/toast';
import { reportErrorAsToast, tryGetErrorMessage } from '../../../lib/util/errors';
import { Batch, BatchIdentifiers } from '../../Compounds/compound-api';
import { SampleCreate, Vial, formatSampleContentInline } from '../ecm-api';
import { ECMCommonUploadInfo, ECMPageTemplate, ECMWorkflowMode, ECMWorkflowModeSelect } from '../ecm-common';
import {
    ECMBatchWorkflowBase,
    ECMBatchWorkflowWrapper,
    ECMManualWorkflowBase,
    ECMManualWorkflowInputHelper,
    ECMManualWorkflowStatus,
    ECMManualWorkflowWrapper,
    ecmAPITemplate,
    ecmGatherMessages,
    ecmWorkflowEntrySummary,
    uploadECMCSV,
} from './common';

interface PrepareAdjustInput {
    row_index?: number;
    barcode?: string;
    volume?: number;
    volume_unit?: string;
}

interface PrepareAdjustEntry {
    input: PrepareAdjustInput;

    errors: string[];
    warnings: string[];

    vial?: Vial;
    barcode?: string;
    batch?: Batch;
    batch_identifiers?: BatchIdentifiers;
    sample_create?: SampleCreate;
}

type PrepareAdjustEntries = PrepareAdjustEntry[];

export const AdjustAPI = ecmAPITemplate<PrepareAdjustInput[], PrepareAdjustEntries>('vials/adjust');

interface ManualVialAdjustWorkflowInput {
    barcode: string;
    volume: string;
}

const EmptyInput: ManualVialAdjustWorkflowInput = {
    barcode: '',
    volume: '',
};

class ManualVialAdjustWorkflow implements ECMManualWorkflowBase<PrepareAdjustEntry> {
    inputs = {
        barcode: { current: null } as MutableRefObject<HTMLInputElement | null>,
        volume: { current: null } as MutableRefObject<HTMLInputElement | null>,
    };

    state = {
        input: new BehaviorSubject<ManualVialAdjustWorkflowInput>(EmptyInput),
        entry: new BehaviorSubject<PrepareAdjustEntry | undefined>(undefined),
        autosubmit: new BehaviorSubject<boolean>(false),
        preparing: new BehaviorSubject<boolean>(false),
        submitting: new BehaviorSubject<boolean>(false),
    };

    log = new LogModel();

    get autosubmit() {
        return this.state.autosubmit.value;
    }

    get isInputValid(): boolean {
        // TODO: this could be improved
        const input = this.state.input.value;
        return input.barcode.length > 0 && input.volume.length > 0;
    }

    async prepare() {
        ToastService.remove('warn-autosubmit');

        if (!this.isInputValid) {
            return;
        }

        try {
            this.state.preparing.next(true);
            const input = this.state.input.value;
            const entries = await AdjustAPI.prepare([
                {
                    barcode: input.barcode,
                    volume: input.volume.trim() ? +input.volume : undefined,
                    volume_unit: 'uL',
                },
            ]);

            const entry = entries[0];
            this.state.entry.next(entry);
            if (!entry) return;

            if (!entry.barcode) {
                requestAnimationFrame(() => this.inputs.barcode.current?.focus());
                return;
            }

            if (this.autosubmit && entry.errors.length === 0 && entry.warnings.length === 0) {
                this.submit();
                return;
            }

            if (this.autosubmit && entry.errors.length === 0 && entry.warnings.length > 0) {
                ToastService.show({
                    id: 'warn-autosubmit',
                    type: 'warning',
                    message: 'Auto-submit paused due to a warning.\nPlease fix the issue or submit manually.',
                });
            }
        } catch (err) {
            reportErrorAsToast('Adjust', err);
            this.state.entry.next(undefined);
        } finally {
            this.state.preparing.next(false);
        }
    }

    async submit() {
        const entry = this.state.entry.value;
        if (!entry) return;

        try {
            this.state.submitting.next(true);

            await AdjustAPI.upload([entry]);
            // NOTE: For testing to fake upload
            // await new Promise((res) => {
            //     setTimeout(res, 500);
            // });

            this.log.message(
                'success',
                `Vial ${entry.barcode} sample adjusted to ${formatSampleContentInline(entry.sample_create!)}.`
            );
            this.state.input.next(EmptyInput);
            this.state.entry.next(undefined);
            requestAnimationFrame(() => this.inputs.barcode.current?.focus());
        } catch (err) {
            this.log.message('danger', `Vial ${entry?.barcode} error: ${tryGetErrorMessage(err)}`);
        } finally {
            this.state.submitting.next(false);
        }
    }
}

export class BatchVialAdjustWorkflow implements ECMBatchWorkflowBase<PrepareAdjustEntries> {
    state = {
        submitting: new BehaviorSubject<boolean>(false),
    };
    entries: PrepareAdjustEntries;
    summary: ECMBatchWorkflowBase['summary'];
    messages: ECMBatchWorkflowBase['messages'];

    async submit() {
        if (this.summary.numErrors > 0 || !this.entries.length) {
            return;
        }

        try {
            this.state.submitting.next(true);

            await AdjustAPI.upload(this.entries);
            // NOTE: For testing to fake upload
            // await new Promise((res) => {
            //     setTimeout(res, 500);
            // });

            this.fileSubject.next(null);
            ToastService.show({
                type: 'success',
                message: `Successfully updated ${this.entries.length} vial${
                    this.entries.length === 1 ? '' : 's'
                } in Foundry.`,
            });
            this.options?.onSubmit?.();
        } catch (err) {
            reportErrorAsToast('Adjust', err);
        } finally {
            this.state.submitting.next(false);
        }
    }

    constructor(
        public files: [filename: string, entries: PrepareAdjustEntries][],
        private fileSubject: BehaviorSubject<File[] | null>,
        private options?: {
            onSubmit?: () => void;
        }
    ) {
        this.entries = files.flatMap((f) => f[1]);
        this.summary = ecmWorkflowEntrySummary(this.entries);
        this.messages = ecmGatherMessages(files);
    }
}

export function ECMAdjust() {
    const [mode, setMode] = useState<ECMWorkflowMode>('manual');

    return (
        <ECMPageTemplate page='adjust' withFooter>
            <ECMWorkflowModeSelect mode={mode} setMode={setMode} />
            {mode === 'manual' && <ManualRoot />}
            {mode === 'batch' && <BatchRoot />}
        </ECMPageTemplate>
    );
}

const ManualModel = new ManualVialAdjustWorkflow();

function ManualRoot() {
    const model = ManualModel;

    return (
        <ECMManualWorkflowWrapper
            model={model}
            input={<ManualInput model={model} />}
            status={<ManualStatus model={model} />}
        />
    );
}

function ManualInput({ model }: { model: ManualVialAdjustWorkflow }) {
    const preparing = useBehavior(model.state.preparing);
    const submitting = useBehavior(model.state.submitting);
    const disabled = preparing || submitting;

    const input = useBehavior(model.state.input);

    return (
        <ECMManualWorkflowInputHelper model={model} disabled={disabled} preparing={preparing}>
            <LabeledInput label='Barcode' className='ecm-manual-inputs-row'>
                <Form.Control
                    value={input.barcode}
                    ref={model.inputs.barcode}
                    onChange={(e) => model.state.input.next({ ...input, barcode: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.barcode) model.inputs.volume.current?.focus();
                    }}
                    disabled={disabled}
                    autoFocus
                />
            </LabeledInput>
            <LabeledInput label='Volume (μL)' className='ecm-manual-inputs-row'>
                <Form.Control
                    value={input.volume}
                    ref={model.inputs.volume}
                    onChange={(e) => model.state.input.next({ ...input, volume: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.volume) model.prepare();
                    }}
                    disabled={disabled}
                />
            </LabeledInput>
        </ECMManualWorkflowInputHelper>
    );
}

function ManualStatus({ model }: { model: ManualVialAdjustWorkflow }) {
    return (
        <ECMManualWorkflowStatus model={model}>
            {(entry) => (
                <>
                    <PropertyNameValue field='Barcode' value={entry.barcode ?? entry.input.barcode} />
                    <PropertyNameValue field='Vial Status' value={entry.vial?.status ?? 'n/a'} />
                    {!!entry.vial?.sample && (
                        <PropertyNameValue field='Old Sample' value={formatSampleContentInline(entry.vial.sample!)} />
                    )}
                    <PropertyNameValue
                        field='New Sample'
                        value={entry.sample_create ? formatSampleContentInline(entry.sample_create) : 'n/a'}
                    />
                </>
            )}
        </ECMManualWorkflowStatus>
    );
}

async function uploadCSV(files: File[] | null, fileSubject: BehaviorSubject<File[] | null>) {
    return uploadECMCSV(AdjustAPI, BatchVialAdjustWorkflow, files, fileSubject);
}

function BatchRoot() {
    const [model, loadModel] = useAsyncAction<BatchVialAdjustWorkflow | undefined>();

    const upload = useCallback(
        (files: File[] | null, fileSubject: BehaviorSubject<File[] | null>) => loadModel(uploadCSV(files, fileSubject)),
        [loadModel]
    );
    const info = useMemo(() => <BatchUploadInfo />, []);

    return <ECMBatchWorkflowWrapper model={model} upload={upload} info={info} />;
}

function BatchUploadInfo() {
    return <ECMCommonUploadInfo required={['Barcode', 'Volume']} optional={['Volume Unit (=uL)']} />;
}
