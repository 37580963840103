import { BehaviorSubject, Observable, Subscription, skip } from 'rxjs';
import { ReportErrorAsToastOptions, reportErrorAsToast } from './errors';
import useBehavior from '../hooks/useBehavior';

export class ReactiveModel {
    private subs: Subscription[] = [];

    mount(...args: any[]): void {}

    dispose() {
        for (const s of this.subs) s.unsubscribe();
        this.subs = [];
    }

    subscribe<T>(obs: Observable<T>, f: (v: T) => any, options?: { skipFirst?: boolean }) {
        const sub = options?.skipFirst ? obs.pipe(skip(1)).subscribe(f) : obs.subscribe(f);
        this.subs.push(sub);
        return {
            unsubscribe: () => {
                sub.unsubscribe();
                this.subs = this.subs.filter((s) => s !== sub);
            },
        };
    }
}

export interface ModelActionOptions<R> {
    runningMessage?: string;
    toastErrorLabel?: string;
    errorToastOptions?: ReportErrorAsToastOptions;
    rethrowError?: boolean;
    onError?: 'state' | 'toast' | 'eat' | ((e: any) => void);
    applyResult?: (r: R) => void | Promise<void>;
}

export type ModelActionState<R> =
    | { kind: 'idle' }
    | { kind: 'loading'; message?: string; current?: number; max?: number }
    | { kind: 'error'; error: any }
    | { kind: 'result'; result: R };

export class ModelAction<R = any> {
    state = new BehaviorSubject<ModelActionState<R>>({ kind: 'idle' });

    get currentResult() {
        const state = this.state.value;
        return state.kind === 'result' ? state.result : undefined;
    }

    private currentRunId = 0;
    private async _run<T extends R>(
        runId: number,
        task: Promise<T>,
        options?: ModelActionOptions<R>
    ): Promise<T | undefined> {
        const opts = { ...this.options, ...options };
        this.state.next({ kind: 'loading', message: opts.runningMessage });
        try {
            const result = await task;
            if (runId !== this.currentRunId) return;

            if (opts.applyResult) {
                await opts.applyResult?.(result);
                if (runId !== this.currentRunId) return;
                this.state.next({ kind: 'idle' });
            } else {
                this.state.next({ kind: 'result', result });
            }
            return result;
        } catch (e) {
            if (runId !== this.currentRunId) return;

            if (!opts.onError || opts.onError === 'state') {
                this.state.next({ kind: 'error', error: e });
            } else if (opts.onError === 'eat') {
                this.state.next({ kind: 'idle' });
            } else if (opts.onError === 'toast') {
                this.state.next({ kind: 'idle' });
                reportErrorAsToast(opts.toastErrorLabel ?? 'Error', e, opts.errorToastOptions);
            } else {
                this.state.next({ kind: 'idle' });
                opts.onError?.(e);
            }

            if (options?.rethrowError) {
                throw e;
            }
        }
    }

    async run<T extends R>(task: Promise<T>, options?: ModelActionOptions<R>): Promise<T | undefined> {
        return this._run(++this.currentRunId, task, options);
    }

    runWithProgress<T extends R>(
        task: (setProgress: (message?: string, current?: number, max?: number) => void) => Promise<T>,
        options?: ModelActionOptions<T>
    ) {
        const _progress = (message?: string, current?: number, max?: number) => {
            this.state.next({ kind: 'loading', message, current, max });
        };
        return this.run(task(_progress), options as any);
    }

    private _runOnceSet = new Set<string>();
    async runOnce<T extends R>(key: string, task: () => Promise<T>, options?: ModelActionOptions<R>) {
        if (this._runOnceSet.has(key)) {
            return;
        }

        try {
            this._runOnceSet.add(key);
            await this.run(task(), { ...options, rethrowError: true });
        } catch {
            this._runOnceSet.delete(key);
        }
    }

    reset() {
        this.state.next({ kind: 'idle' });
    }

    constructor(private options: ModelActionOptions<R> = {}) {}
}

export function useModelAction<R>(action: ModelAction<R>): ModelActionState<R>;
export function useModelAction<R>(action: ModelAction<R> | undefined): ModelActionState<R> | undefined;
export function useModelAction<R>(action: ModelAction<R> | undefined): ModelActionState<R> | undefined {
    return useBehavior(action?.state);
}
