import { BehaviorSubject, filter, map, merge } from 'rxjs';
import { type HTEMosquitoProtocolModel } from './mosquito';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { roundValue } from '../../../lib/util/roundValues';
import { PlateVisualModel } from '../../HTE/plate/PlateVisual';
import { getFirstSelectedIndex, getWellIndexLabel } from '../../HTE/plate/utils';
import { HTEDesign, HTEPMoquitoInstructionT, HTEPMosquitoProductPlate, HTEPSourcePlate } from '../data-model';
import {
    PlateColorContext,
    colorPlate,
    productPlateColorContext,
    reactantNameColorContext,
    sourcePlateColorContext,
} from './plate-coloring';
import { ReactionInfo } from '../utils';

export class HTEPPlatesModel extends ReactiveModel {
    design: HTEDesign;
    protocol: HTEPMosquitoProductPlate;

    productPlate: PlateVisualModel;
    sourcePlates: PlateVisualModel[] = [];
    sourcePlateData: HTEPSourcePlate[] = [];
    solventPlates: HTEPSourcePlate[] = [];
    sourcePlateMap: Record<string, HTEPSourcePlate> = {};
    plateModels: Record<string, PlateVisualModel> = {};

    reactantNames: string[];
    instructionGroups: [string, HTEPMoquitoInstructionT[]][];

    state = {
        sourceWell: new BehaviorSubject<
            { well: string; identifier?: string | null; sample?: string | null; reactantNames?: string } | undefined
        >(undefined),
        reaction: new BehaviorSubject<ReactionInfo | undefined>(undefined),
    };

    select = () => {
        if (this.model.state.plate.value !== this) this.model.state.plate.next(this);
    };

    mount(): void {
        this.subscribe(this.productPlate.state.selection, (sel) => {
            const idx = getFirstSelectedIndex(sel);
            const well = this.protocol.product_plate.wells[idx];
            if (!well) return this.state.reaction.next(undefined);
            const label = getWellIndexLabel(this.productPlate.layout, idx);
            this.state.reaction.next(this.model.design.getReaction(well, label));
        });

        let clearing = false;
        const sources = this.sourcePlates.map((p, i) =>
            p.state.selection.pipe(
                map((sel) => {
                    if (clearing) return undefined;

                    const idx = getFirstSelectedIndex(sel);
                    const plate = this.sourcePlateData[i];
                    const label = getWellIndexLabel(plate.layout, idx);
                    return [i, label, plate.wells[idx]] as const;
                })
            )
        );

        this.subscribe(merge(...sources).pipe(filter((w) => !!w)), (w) => {
            const [pI, wl, well] = w!;

            clearing = true;
            for (let i = 0; i < this.sourcePlates.length; i++) {
                if (i !== pI) {
                    this.sourcePlates[i].clearSelection();
                }
            }
            clearing = false;

            if (!wl) return this.state.sourceWell.next(undefined);
            if (!well) return this.state.sourceWell.next({ well: wl });
            const conc = well.concentration ? `, ${roundValue(3, well.concentration)} M` : '';
            const sample = `${well.solvent}${conc}, ${roundValue(3, (well.volume ?? 0) * 1e9)} μL`;
            this.state.sourceWell.next({
                well: wl,
                identifier: well.identifier,
                sample,
                reactantNames: well.reactant_names?.join(', '),
            });
        });

        if (this.options?.customColoring) {
            return;
        }

        const colorContexts = new Map<string, [PlateColorContext, PlateColorContext]>();
        this.subscribe(this.model.design.state.currentInstruction, (curr) => {
            let colorContext: [PlateColorContext, PlateColorContext] | undefined;
            if (curr) {
                const reaction = this.model.design.findReaction(curr.reaction_id)?.reaction;
                const reactant_name = curr.instruction.kind === 'add' ? curr.instruction.name : undefined;
                const instructionIndex = reaction?.template.reaction.instructions.findIndex(
                    (r) => r === curr.instruction
                );
                const key = `${curr.reaction_id}:${curr.instruction.kind}:${reactant_name}:${instructionIndex}`;

                if (colorContexts.has(key)) {
                    colorContext = colorContexts.get(key)!;
                } else if (reactant_name) {
                    const productBlock = this.model.protocol.design.product_blocks?.find(
                        (b) => b.id === reaction?.product_block_id
                    );
                    const singleIdentifier = reaction?.template.reactants[reactant_name]?.identifier;
                    const list = productBlock?.reactant_lists.find((l) => l.reactant_name === reactant_name);

                    const identifierSet = singleIdentifier
                        ? new Set([singleIdentifier])
                        : new Set(list?.instances.map((i) => i.identifier) ?? []);

                    const ctx = reactantNameColorContext(this.protocol.product_plate, this.sourcePlateData, {
                        reaction_id: curr.reaction_id,
                        reactant_name,
                        identifierSet,
                        solvent: reaction?.template.solvent,
                    });
                    colorContext = [ctx, ctx];
                } else if (curr.instruction.kind === 'backfill') {
                    const ctx = reactantNameColorContext(this.protocol.product_plate, this.sourcePlateData, {
                        reaction_id: curr.reaction_id,
                        solvent: this.model.design.findReaction(curr.reaction_id)?.reaction.template.solvent,
                    });
                    colorContext = [ctx, ctx];
                } else {
                    colorContext = [
                        productPlateColorContext(this.protocol.product_plate, { reaction_id: curr.reaction_id }),
                        sourcePlateColorContext(this.sourcePlateData),
                    ];
                }

                colorContexts.set(key, colorContext);
            }

            if (!colorContext) {
                if (colorContexts.has('default')) {
                    colorContext = colorContexts.get('default')!;
                } else {
                    colorContext = [
                        productPlateColorContext(this.protocol.product_plate),
                        sourcePlateColorContext(this.sourcePlateData),
                    ];
                    colorContexts.set('default', colorContext);
                }
            }

            colorPlate(this.productPlate, this.protocol.product_plate, colorContext[0]);
            this.sourcePlates.forEach((p, i) => colorPlate(p, this.sourcePlateData[i], colorContext![1]));
        });
    }

    clearHighlights() {
        this.productPlate.state.highlight.next([]);
        for (const p of this.sourcePlates) p.state.highlight.next([]);
    }

    highlightGroup(instructions: HTEPMoquitoInstructionT[]) {
        this.clearHighlights();

        const indices = new Map<string, Set<number>>();

        const extend = (id: string, xs: number[]) => {
            let set = indices.get(id);
            if (!set) {
                set = new Set();
                indices.set(id, set);
            }

            for (const x of xs) set.add(x);
        };

        for (const i of instructions) {
            if (i.kind === 'copy') {
                const source = this.plateModels[i.source_plate_id];
                const target = this.plateModels[i.target_plate_id];
                const stride = Math.ceil(target.layout / source.layout / 2);

                const src = source.toIndices({ type: 'col', index: i.source_column });
                const tar = target.toIndices({
                    type: 'col',
                    index: i.target_column,
                    offset: i.target_row_offset,
                    stride,
                });

                extend(i.source_plate_id, src);
                extend(i.target_plate_id, tar);
            } else if (i.kind === 'multi-aspirate') {
                const source = this.plateModels[i.source_plate_id];
                const src = source.toIndices({ type: 'col', index: i.source_column });
                extend(i.source_plate_id, src);
            }
        }

        for (const [id, xs] of Array.from(indices.entries())) {
            const idx = Array.from(xs).sort((a, b) => a - b);
            this.plateModels[id].state.highlight.next(idx);
        }
    }

    highlight(instruction?: HTEPMoquitoInstructionT) {
        this.clearHighlights();
        if (instruction?.kind === 'copy') {
            const source = this.plateModels[instruction.source_plate_id];
            const target = this.plateModels[instruction.target_plate_id];

            const stride = Math.ceil(target.layout / source.layout / 2);

            source.highlight({ type: 'col', index: instruction.source_column });
            target.highlight({
                type: 'col',
                index: instruction.target_column,
                offset: instruction.target_row_offset,
                stride,
            });
        } else if (instruction?.kind === 'multi-aspirate') {
            const source = this.plateModels[instruction.source_plate_id];
            source.highlight({ type: 'col', index: instruction.source_column });
        }
    }

    constructor(
        public model: HTEMosquitoProtocolModel,
        { design, protocol }: { design: HTEDesign; protocol: HTEPMosquitoProductPlate },
        private options?: { customColoring?: boolean }
    ) {
        super();

        this.design = design;
        this.protocol = protocol;

        this.instructionGroups = groupInstructions(protocol.runs.flatMap((r) => r.instructions));
        const productMap = new Map(design.product_blocks.map((b) => [b.id, b]));
        const reactantNames = Array.from(
            new Set(
                design.reactions.flatMap((e) => productMap.get(e.product_block_id!)?.enumeration?.reactant_names ?? [])
            )
        );
        reactantNames.sort((a, b) => (a < b ? -1 : 1));
        this.reactantNames = reactantNames;

        this.productPlate = new PlateVisualModel(this.protocol.product_plate.layout, { fontScale: 0.5 });

        this.plateModels[this.protocol.product_plate.id] = this.productPlate;

        for (const run of this.protocol.runs) {
            for (const p of [...run.source_plates, ...run.solvent_plates]) {
                const plate = new PlateVisualModel(p.layout, { fontScale: 0.5 });
                this.plateModels[p.id] = plate;
                this.sourcePlateData.push(p);
                this.sourcePlateMap[p.id] = p;
                this.sourcePlates.push(plate);
            }
        }

        this.solventPlates = this.protocol.runs.flatMap((r) => r.solvent_plates);
    }
}

function groupInstructions(instructions: HTEPMoquitoInstructionT[]) {
    const groups: [string, HTEPMoquitoInstructionT[]][] = [];
    let currentHeader = '';
    let current: HTEPMoquitoInstructionT[] = [];
    for (const i of instructions) {
        if (i.kind === 'comment') {
            if (currentHeader) groups.push([currentHeader, current]);
            currentHeader = i.comment;
            current = [];
        } else if (i.kind === 'worklist') {
            if (currentHeader || current.length > 0) groups.push([currentHeader, current]);
            groups.push(['Worklist', [i]]);
            currentHeader = '';
            current = [];
        } else {
            current.push(i);
        }
    }
    if (current.length > 0) groups.push([currentHeader, current]);
    return groups;
}
