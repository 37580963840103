import { Column, DataTableModel, ObjectDataTableStore } from '../../../components/DataTable';
import { TextInput } from '../../../components/common/Inputs';
import { ClipboardService } from '../../../lib/services/clipboard';
import { ToastService } from '../../../lib/services/toast';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { HTEPReagent } from '../data-model';
import { LiquidReagentRow } from '../protocol/liquid-reagents';
import { commonLiquidReagentsColumnSchema, viewReagentDesignAction } from '../utils/reagent-table';
import type { HTE2MSInventoryModel } from './model';

export interface ReservoirsRow extends LiquidReagentRow {
    container_label?: string;
    container_well?: string;
}

export class ReservoirsModel extends ReactiveModel {
    store = new ObjectDataTableStore<ReservoirsRow, HTEPReagent>([
        { name: 'identifier', getter: (v) => v.identifier },
        { name: 'reactant_kinds', getter: (v) => this.reagentsModel.getInfo(v)?.reactant_kinds },
        {
            name: 'volume_l',
            getter: (v) => this.reagentsModel.finalRequired.get(v)?.volume_l ?? this.reagentsModel.getInfo(v)?.volume_l,
        },
        { name: 'concentration', getter: (v) => v.concentration },
        { name: 'solvent', getter: (v) => v.solvent },
        { name: 'container_label', getter: (v) => this.inventory.getReservoirLocation(v)?.container_label },
        { name: 'container_well', getter: (v) => this.inventory.getReservoirLocation(v)?.container_well },
        { name: 'source_barcode', getter: (v) => this.inventory.getLiquid(v)?.source_barcode },
        { name: 'transfer_barcode', getter: (v) => this.inventory.getLiquid(v)?.transfer_barcode },
        { name: 'labware_id', getter: (v) => this.inventory.getLiquid(v)?.labware_id },
    ]);
    table: DataTableModel<ReservoirsRow>;

    get reagentsModel() {
        return this.inventory.model.protocol.reagents;
    }

    get mainModel() {
        return this.inventory.model;
    }

    private update() {
        const protocol = this.mainModel.protocol.data;
        this.store.setRows(
            protocol.reagents.filter((r) => {
                if (!r.solvent) return false;
                const labware_id = this.inventory.data.liquid[r.key]?.labware_id;
                const labware = this.mainModel.design.labwareMap.get(labware_id!);
                return !!labware?.is_reservoir;
            })
        );
        this.table.dataChanged();
    }

    mount() {
        this.update();
        this.subscribe(this.inventory.state.inventory, () => this.update());
    }

    copyBarcodes = () => {
        const barcodes = this.table.rows
            .map((r) => this.store.getValue('source_barcode', r))
            .filter((r) => r)
            .join('\n');
        if (!barcodes) {
            return ToastService.show({
                message: 'No barcodes to copy',
                type: 'warning',
            });
        }
        ClipboardService.copyText(barcodes, 'Copy Barcodes');
    };

    constructor(public inventory: HTE2MSInventoryModel) {
        super();

        const columns = commonLiquidReagentsColumnSchema(inventory.model, this.store);
        const designAction = viewReagentDesignAction(inventory.model, this.store);

        this.table = new DataTableModel<ReservoirsRow>(this.store, {
            columns: {
                identifier: columns.identifier,
                reactant_kinds: columns.reactant_kinds,
                volume_l: columns.volume_l,
                concentration: columns.concentration,
                solvent: columns.solvent,
                source_barcode: columns.source_barcode,
                transfer_barcode: columns.transfer_barcode,
                container_label: {
                    ...Column.str(),
                    header: 'Container',
                    render: ({ value, rowIndex }) => {
                        const readOnly = this.mainModel.readOnlyDesignAndProduction;
                        if (readOnly) return value;

                        const r = this.store.rawRows[rowIndex];
                        const multiple = inventory.getLocationTargetCount(this.inventory.getReservoirLocation(r)) > 1;
                        return (
                            <TextInput
                                value={value}
                                className={`font-body-small hte2ms-container-table-input${
                                    multiple ? ' text-danger' : ''
                                }`}
                                title={multiple ? 'Assigned to multiple reagents' : undefined}
                                setValue={(v) =>
                                    this.inventory.update({
                                        reservoir_locations: [
                                            [this.store.rawRows[rowIndex], { container_label: v?.trim() }],
                                        ],
                                    })
                                }
                            />
                        );
                    },
                    width: 140,
                },
                container_well: {
                    ...Column.str(),
                    header: 'Well',
                    render: ({ value, rowIndex }) => {
                        const readOnly = this.mainModel.readOnlyDesignAndProduction;
                        if (readOnly) return value;

                        const r = this.store.rawRows[rowIndex];
                        const multiple = inventory.getLocationTargetCount(this.inventory.getReservoirLocation(r)) > 1;
                        return (
                            <TextInput
                                value={value}
                                className={`font-body-small hte2ms-container-table-input${
                                    multiple ? ' text-danger' : ''
                                }`}
                                title={multiple ? 'Assigned to multiple reagents' : undefined}
                                setValue={(v) =>
                                    this.inventory.update({
                                        reservoir_locations: [
                                            [this.store.rawRows[rowIndex], { container_well: v?.trim() }],
                                        ],
                                    })
                                }
                            />
                        );
                    },
                    width: 140,
                },
                labware_id: {
                    ...Column.str(),
                    header: 'Labware',
                    render: ({ value }) => {
                        const labware = this.mainModel.design.labwareMap.get(value!);
                        return labware?.label;
                    },
                    width: 200,
                },
            },
            hideNonSchemaColumns: true,
            actions: [designAction],
        });

        this.table.setColumnStickiness(designAction.id, true);
        this.table.setColumnStickiness('identifier', true);
    }
}
