import { ReadClipboardFallback } from '../../components/common/Dialog';
import { DialogService } from './dialog';
import { ToastService } from './toast';

class _ClipboardService {
    async copyText(toCopy: string, copyDialogTitle: string, confirmText?: string) {
        try {
            // NOTE: uncomment to test UI for when clipboard access is not available.
            // throw new Error();
            await navigator.clipboard.writeText(toCopy);
            ToastService.show({
                type: 'success',
                message: 'Copied to Clipboard',
                timeoutMs: 3000,
            });
        } catch {
            DialogService.open({
                type: 'copy',
                title: copyDialogTitle,
                toCopy,
                confirmText,
            });
        }
    }

    // NOTE: clipboard.readText has limited browser availability
    // see https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/readText
    // It is supported in Safari and Chrome (with user confirmation), but in Firefox it must be enabled
    // by going to about:config and setting dom.events.asyncClipboard.readText to "true"
    async readClipboard(fallbackDialogTitle: string, onSetText: (text: string) => void) {
        try {
            // NOTE: uncomment to test UI for when clipboard read access has been denied.
            // throw new Error();
            const text = await navigator.clipboard.readText();
            onSetText(text);
        } catch {
            DialogService.open({
                type: 'generic',
                title: fallbackDialogTitle,
                content: ReadClipboardFallback,
                defaultState: '',
                onOk: (state: string) => {
                    onSetText(state);
                },
            });
        }
    }
}

export const ClipboardService = new _ClipboardService();
