import * as d3colors from 'd3-scale-chromatic';
import { BehaviorSubject } from 'rxjs';
import { saveAs } from 'file-saver';
import { AsyncQueue } from '../../../lib/util/async-queue';
import { reportErrorAsToast, tryGetErrorMessage } from '../../../lib/util/errors';
import { arrayMapAdd, memoizeLatest } from '../../../lib/util/misc';
import { ModelAction, ReactiveModel } from '../../../lib/util/reactive-model';
import { ManualVialTransferWorkflow } from '../../ECM/workflows/Transfer';
import {
    HTEDReactionIdT,
    HTEIDry,
    HTEISampleLocation,
    HTEInventory,
    HTEILiquid,
    HTEPMessage,
    HTEPReagent,
    HTEPReagentUse,
    HTERInstructionIdT,
    HTEPReagentKeyT,
    HTEPSolution,
    HTEISolution,
} from '../data-model';
import type { HTE2MSModel } from '../model';
import { DryTransfersModel } from './dry-transfers';
import { PreparedLiquidsModel } from './prepared-liquids';
import { ReservoirsModel } from './reservoirs';
import { HTE2MSSolubilizeModel } from './solubilize';
import { LiquidTransfersModel } from './liquid-transfers';
import { ToastService } from '../../../lib/services/toast';
import { arrayToCsv } from '../../../lib/util/arrayToCsv';
import { HTE2MSDiluteModel } from './dilute';
import { HTE2MSApi } from '../api';
import { DialogService } from '../../../lib/services/dialog';
import { PlateVisualColors, PlateVisualModel, PlateWellColoring } from '../../HTE/plate/PlateVisual';
import { getWellIndexLabel } from '../../HTE/plate/utils';

export interface ProtocolMessageGroup {
    message: string;
    reactionIds: HTEDReactionIdT[];
    group: HTEPMessage[];
}

export type InventoryTab = 'prepared-liquids' | 'liquid' | 'reservoirs' | 'dry' | 'solubilize' | 'dilute';

const EmptyInventory: HTEInventory = {
    liquid: {},
    dry: {},
    reservoir_locations: {},
};

export class HTE2MSInventoryModel extends ReactiveModel {
    state = {
        tab: new BehaviorSubject<InventoryTab>('dry'),
        inventory: new BehaviorSubject<HTEInventory>(EmptyInventory),
        locationPlate: new BehaviorSubject(new PlateVisualModel(96)),
    };

    get data() {
        return this.state.inventory.value;
    }

    get locationPlate() {
        return this.state.locationPlate.value;
    }

    getLiquid(reagent: HTEPReagent): HTEILiquid | undefined {
        return this.data.liquid[reagent.key];
    }

    getSolution(solution: HTEPSolution): HTEISolution | undefined {
        return this.data.solutions?.[solution.key];
    }

    getReservoirLocation(reagent: HTEPReagent): HTEISampleLocation | undefined {
        return this.data.reservoir_locations[reagent.key];
    }

    getDryUse(use: HTEPReagentUse): HTEIDry | undefined {
        return this.data.dry[use.instruction_id];
    }

    getLabware(reagent: HTEPReagent) {
        if (!reagent.solvent) return this.model.design.labware.product;
        return this.model.design.labwareMap.get(this.getLiquid(reagent)?.labware_id!);
    }

    transfers = new ManualVialTransferWorkflow({ doNotAutoClear: true });

    liquidTransfers = new LiquidTransfersModel(this);
    dryTransfers = new DryTransfersModel(this);
    preparedLiquids = new PreparedLiquidsModel(this);
    reservoirs = new ReservoirsModel(this);
    solubilize = new HTE2MSSolubilizeModel(this);
    dilute = new HTE2MSDiluteModel(this);

    actions = {
        applyTransfer: new ModelAction({ onError: 'toast', toastErrorLabel: 'Apply transfer' }),
    };

    private _transferBarcodeMap = memoizeLatest((reagents: HTEPReagent[], inventory: HTEInventory) => {
        const ret = new Map<string, (HTEDReactionIdT | HTERInstructionIdT)[]>();
        for (const r of reagents) {
            const target = inventory.liquid[r.key]?.transfer_barcode;
            if (target) {
                if (!ret.has(target)) ret.set(target, []);
                ret.get(target)!.push(r.key);
            }
            for (const u of r.uses) {
                const dryTarget = inventory.dry[u.instruction_id]?.transfer_barcode;
                if (dryTarget) {
                    if (!ret.has(dryTarget)) ret.set(dryTarget, []);
                    ret.get(dryTarget)!.push(u.instruction_id);
                }
            }
        }
        return ret;
    });
    private get transferTargetsByBarcode() {
        return this._transferBarcodeMap(this.model.protocol.reagents.all, this.data);
    }

    getBarcodeTransferTargetCount(barcode?: string) {
        return this.transferTargetsByBarcode.get(barcode!)?.length ?? 0;
    }

    private _locationMap = memoizeLatest((reagents: HTEPReagent[], inventory: HTEInventory) => {
        const ret = new Map<string, string[]>();
        for (const r of reagents) {
            const loc = inventory.reservoir_locations[r.key];
            if (!loc?.container_label || !loc?.container_well) continue;
            arrayMapAdd(ret, `${loc.container_label}:${loc.container_well}`, r.key);
        }
        return ret;
    });
    private get reservoirLocationMap() {
        return this._locationMap(this.model.protocol.reagents.all, this.data);
    }

    getLocationTargetCount(loc?: HTEISampleLocation) {
        if (!loc || !loc.container_label || !loc.container_well) return 0;
        return this.reservoirLocationMap.get(`${loc.container_label}:${loc.container_well}`)?.length ?? 0;
    }

    async updateTransferBarcode(
        kind: 'liquid' | 'dry',
        key: HTEPReagentKeyT | HTERInstructionIdT,
        transfer_barcode: string,
        reraise?: boolean
    ) {
        try {
            const result = await HTE2MSApi.updateTransferBarcode(this.model.experiment?.id!, {
                kind,
                key,
                transfer_barcode,
            });
            this.state.inventory.next(result.inventory);
            await this.syncAssets();
            return true;
        } catch (err) {
            DialogService.open({
                type: 'generic',
                title: 'Apply Transfer Barcode Error',
                confirmButtonContent: 'Retry',
                model: tryGetErrorMessage(err),
                wrapOk: true,
                content: ApplyTransferBarcodeErrorDialogContent,
                options: { staticBackdrop: true },
                doNotAutoClose: true,
                onOk: async () => {
                    if (await this.updateTransferBarcode(kind, key, transfer_barcode, true)) {
                        DialogService.close();
                    }
                },
            });
            return false;
        }
    }

    update({
        liquid,
        solutions,
        dry,
        reservoir_locations,
    }: {
        liquid?: [reagent: HTEPReagent, update: Partial<HTEILiquid>][];
        solutions?: [solution: HTEPSolution, update: Partial<HTEISolution>][];
        dry?: [use: HTEPReagentUse, update: Partial<HTEIDry>][];
        reservoir_locations?: [reagent: HTEPReagent, update: Partial<HTEISampleLocation>][];
    }) {
        if (!liquid && !dry && !solutions && !reservoir_locations) return;

        const next: HTEInventory = {
            ...this.data,
            liquid: liquid ? { ...this.data.liquid } : this.data.liquid,
            solutions: solutions ? { ...this.data.solutions } : this.data.solutions ?? {},
            reservoir_locations: reservoir_locations
                ? { ...this.data.reservoir_locations }
                : this.data.reservoir_locations,
            dry: dry ? { ...this.data.dry } : this.data.dry,
        };

        if (liquid) {
            for (const [reagent, update] of liquid) {
                next.liquid[reagent.key] = { ...next.liquid[reagent.key], ...update };
            }
        }
        if (solutions) {
            for (const [s, update] of solutions) {
                next.solutions![s.key] = { ...next.solutions?.[s.key], ...update };
            }
        }
        if (dry) {
            for (const [use, update] of dry) {
                next.dry[use.instruction_id] = { ...next.dry[use.instruction_id], ...update };
            }
        }
        if (reservoir_locations) {
            for (const [reagent, update] of reservoir_locations) {
                next.reservoir_locations[reagent.key] = { ...next.reservoir_locations[reagent.key], ...update };
            }
        }
        this.state.inventory.next(next);
        return this.syncAssets();
    }

    refresh() {
        this.state.inventory.next({ ...this.data });
    }

    clear() {
        this.state.inventory.next(EmptyInventory);
    }

    async init(data: HTEInventory) {
        this.state.inventory.next(data);
        await this.syncAssets();
    }

    private syncQueue = new AsyncQueue({ singleItem: true });
    async syncAssets(options?: { refresh?: boolean }) {
        return this.syncQueue.execute(() => this._syncAssets(this.data, options?.refresh));
    }

    exportTransferBarcodes = () => {
        const barcodes = new Set<string>();

        for (const liquid of this.model.protocol.reagents.liquid.all) {
            const inv = this.getLiquid(liquid);
            if (inv?.transfer_barcode) barcodes.add(inv.transfer_barcode);
        }

        for (const dry of this.model.protocol.reagents.dry.all) {
            const inv = this.getDryUse(dry);
            if (inv?.transfer_barcode) barcodes.add(inv.transfer_barcode);
        }

        if (!barcodes.size) {
            return ToastService.info('No barcodes to export');
        }

        const rows = [['Barcode', 'Action']];
        for (const barcode of Array.from(barcodes)) {
            rows.push([barcode, 'Dispose']);
        }

        const csv = arrayToCsv(rows);
        saveAs(new Blob([csv], { type: 'text/csv' }), `${this.model.libraryId}-dispose-transfer-barcodes.csv`);
    };

    private async _syncAssets(data: HTEInventory, refresh = false) {
        try {
            const barcodes = new Set<string>();
            for (const inv of Object.values(data.liquid)) {
                if (inv.source_barcode) barcodes.add(inv.source_barcode);
                if (inv.transfer_barcode) barcodes.add(inv.transfer_barcode);
            }
            for (const inv of Object.values(data.dry)) {
                if (inv.transfer_barcode) barcodes.add(inv.transfer_barcode);
            }
            await this.model.assets.inventory.syncHolders(Array.from(barcodes), refresh);
        } catch (err) {
            reportErrorAsToast('Sync Inventory Assets', err, { id: 'sync-inventory' });
        }
    }

    syncLocationPlate(reagent: HTEPReagent | HTEPReagentUse | undefined) {
        if (!reagent) return;

        const uses = 'uses' in reagent ? reagent.uses : [reagent];
        const rxnLayout = this.model.design.layout.info;
        const indices = uses
            .map((use) => rxnLayout.reactionToWell.get(use.reaction_id))
            .filter((x) => x !== undefined) as number[];
        indices.sort((a, b) => a - b);

        const layout = this.model.design.labware.product.layout ?? 96;
        if (this.locationPlate.layout !== layout) {
            this.state.locationPlate.next(new PlateVisualModel(layout));
        }

        const colors: PlateVisualColors = new Array(this.locationPlate.layout).fill(PlateWellColoring.NoColor);
        for (const index of indices) {
            colors[index] = d3colors.interpolateWarm(index / (layout - 1));
        }
        this.locationPlate.state.colors.next(colors);

        const fst = indices[0];
        if (typeof fst === 'number') return { label: getWellIndexLabel(layout, fst), count: indices.length };
        return { label: undefined, count: 0 };
    }

    constructor(public model: HTE2MSModel) {
        super();
    }
}

export interface TransferTargets {
    source_barcode: string;
    error?: string;
    rowIndex?: number;
    plateLocation?: { label?: string; count: number };
    options?: [rowIndex: number, label: string][];
}

export interface CurrentTransferInfo {
    reagent: HTEPReagent;
    amount?: string;
    volume?: string;
    concentration?: string;
    solvent?: string;
    labware?: string;
    isTransferred?: boolean;
}

function ApplyTransferBarcodeErrorDialogContent({ model }: { model: string }) {
    return (
        <div>
            The vial transfer has been applied successfully, however, an error occurred while updating the barcode in
            the ELN state:
            <p className='text-danger mt-2'>{model}</p>
        </div>
    );
}
