import { roundValue, roundValueDigits } from './roundValues';

type PrefixInfo = { f: number; nicePrefix: string; csvPrefix: string; round: number };
const noPrefix: PrefixInfo = { f: 1, nicePrefix: '', csvPrefix: '', round: 3 };
const unitPrefixes: PrefixInfo[] = [
    { f: 1e-9, nicePrefix: 'n', csvPrefix: 'n', round: 0 },
    { f: 1e-6, nicePrefix: 'μ', csvPrefix: 'u', round: 1 },
    { f: 1e-3, nicePrefix: 'm', csvPrefix: 'm', round: 2 },
    noPrefix,
];

const prefixMap = new Map<string, PrefixInfo>(unitPrefixes.map((p) => [p.csvPrefix, p]));

export function formatUnit(value: number | undefined | string | null, unit: string, prefix: 'm' | 'u' | 'n' | '') {
    if (value === undefined || value === null) return '';
    if (typeof value !== 'number') return value;
    if (Number.isNaN(value)) return '';

    const info = prefixMap.get(prefix) ?? noPrefix;
    let v = value / info.f;

    if (Math.abs(v) < 1) v = roundValueDigits(3, v);
    else v = roundValue(info.round, v);

    return `${v} ${info.nicePrefix}${unit}`;
}

export function prefixedUnitValue(
    value: number | undefined | string | null,
    unit: string,
    options?: { fixed?: boolean; factor?: number; noSpace?: boolean; csvPrefix?: boolean; strict?: boolean }
) {
    if (value === undefined || value === null) return '';
    if (typeof value !== 'number') return value;
    if (Number.isNaN(value)) return '';

    const f = options?.factor ?? 1;
    const pI = options?.strict ? getStrictPrefixIndex(f * value) : getPrefixIndex(f * value);

    const v = options?.fixed
        ? ((f * value) / unitPrefixes[pI].f).toFixed(0)
        : roundValueDigits(4, (f * value) / unitPrefixes[pI].f);

    const prefix = options?.csvPrefix ? unitPrefixes[pI].csvPrefix : unitPrefixes[pI].nicePrefix;
    return `${v}${options?.noSpace ? '' : ' '}${prefix}${unit}`;
}

export function determineArrayUnitPrefix(
    xs: readonly (number | null | undefined)[],
    unit: string,
    options?: { digits?: number; factor?: number }
) {
    let pI = 0;
    let allUndefined = true;
    const factor = options?.factor ?? 1;

    for (const v of xs) {
        if (!v) continue;
        allUndefined = false;
        pI = Math.max(pI, getPrefixIndex(factor * v));
    }

    const { csvPrefix } = allUndefined ? noPrefix : unitPrefixes[pI];
    return getUnitFormatter(unit, csvPrefix as any, options);
}

export function getUnitFormatter(
    unit: string,
    prefix: 'm' | 'u' | 'n' | '',
    options?: { digits?: number; factor?: number }
) {
    const { f, nicePrefix, csvPrefix } = prefixMap.get(prefix) ?? noPrefix;
    const digits = options?.digits ?? 4;
    const factor = (options?.factor ?? 1) / f;
    return {
        f,
        formatValue: (v?: number | null) =>
            v ? roundValueDigits(digits, factor * v) : typeof v === 'number' ? 0 : Number.NaN,
        formatCSV: (v?: number | null) => (v ? roundValueDigits(digits, factor * v) : typeof v === 'number' ? '0' : ''),
        niceUnit: `${nicePrefix}${unit}`,
        csvUnit: `${csvPrefix}${unit}`,
    };
}

function getPrefixIndex(value: number) {
    let prefix = 1;
    for (; prefix < unitPrefixes.length; prefix++) {
        // NOTE: The <9.99 check improves formatting where
        //       say 1.234g would be displayed as 1234mg.
        if (value < 9.99 * unitPrefixes[prefix].f) break;
    }
    return prefix - 1;
}

function getStrictPrefixIndex(value: number) {
    let prefix = 1;
    for (; prefix < unitPrefixes.length; prefix++) {
        if (value < unitPrefixes[prefix].f) break;
    }
    return prefix - 1;
}

export function formatWithUnit(
    value: number | string | null | undefined,
    factor: number | ((v: number) => number),
    unit: string
) {
    if (typeof value === 'number')
        return `${roundValue(3, typeof factor === 'number' ? value * factor : factor(value))} ${unit}`;
    if (typeof value === 'string') return value.trim();
    return '';
}

export function parseWithUnit(value: string, unit: string, allowUndefined = false) {
    if (allowUndefined && !value.trim()) return null as any as undefined;
    const v = +value;
    if (Number.isFinite(v)) return `${v} ${unit}`;
    return value.trim();
}

export function potensToNM(potens: number, round: boolean) {
    const ret = 10 ** -potens * 1e9;
    return round ? roundNM(ret) : ret;
}

export function roundNM(value: number) {
    let nDigits = 0;
    if (value < 1) nDigits = 3;
    else if (value < 10) nDigits = 2;
    else if (value < 100) nDigits = 1;
    return roundValue(nDigits, value);
}
