import { arrayToCsv } from '../../../lib/util/arrayToCsv';
import { roundValue } from '../../../lib/util/roundValues';
import { formatHTEId, formatUnitPrefix, getReactantLayerType, PlateDimensions, Reactant } from '../experiment-data';
import { getWellCoords, plateRowLabel } from './utils';
import { type HTEExperimentModel } from '../experiment-model';

const ProductPlateCSVColumns = [
    'library_identifier',
    'barcode',
    'batch_identifier',
    'formula_weight',
    'amount_needed',
    'amount_unit',
    'concentration',
    'concentration_unit',
    'substance_type',
    'crude_barcode',
    'crude_well',
] as const;

type ProductPlateCSVRow = Record<(typeof ProductPlateCSVColumns)[number] | 'row' | 'col', string | number>;

export function productPlateToECMCSV(experiment: HTEExperimentModel) {
    const rows: ProductPlateCSVRow[] = [];

    const { reactants } = experiment;
    const { plate } = experiment.design;
    const library_identifier = formatHTEId(experiment.id);

    const [width] = PlateDimensions[plate.layout];

    const amount_unit = `${formatUnitPrefix(reactants.scaling.volume, false)}l`;

    for (let wI = 0; wI < plate.layout; wI++) {
        const well = plate.wells[wI];
        if (!well) continue;

        const [row, col] = getWellCoords(width, wI);
        const crude_well = `${plateRowLabel(row)}${col + 1}`;

        let msd: Reactant | undefined;
        let bb: Reactant | undefined;

        for (const identifier of well) {
            const r = reactants.getReactant(identifier);

            if (r.type === 'msd') msd = r;
            if (r.type === 'bb') bb = r;

            const concentration = reactants.calcConcentration(r);
            const amount_needed = reactants.calcRxnVolume(r);

            rows.push({
                library_identifier,
                barcode: r.barcode ?? '',
                batch_identifier: r.identifier,
                formula_weight:
                    typeof r.molecular_weight === 'number' ? roundValue(3, r.molecular_weight).toString() : '',
                amount_needed:
                    amount_needed !== null ? roundValue(3, amount_needed / reactants.scaling.volume).toString() : '',
                amount_unit,
                concentration: typeof concentration === 'number' ? roundValue(5, concentration).toString() : '',
                concentration_unit: 'M',
                substance_type: getReactantLayerType(r),
                crude_barcode: '',
                crude_well,
                row,
                col,
            });
        }

        if (msd && bb) {
            const reaction = experiment.reactions.findReaction(msd.identifier, bb.identifier, wI);

            if (reaction) {
                rows.push({
                    library_identifier,
                    barcode: '',
                    batch_identifier: reaction.product_identifier,
                    formula_weight: reaction.product_molecular_weight
                        ? roundValue(3, reaction.product_molecular_weight).toString()
                        : '',
                    amount_needed: '',
                    amount_unit: '',
                    concentration: '',
                    concentration_unit: '',
                    substance_type: 'product',
                    crude_barcode: '',
                    crude_well,
                    row,
                    col,
                });
            }
        }
    }

    rows.sort((a, b) => {
        if (a.substance_type !== b.substance_type) {
            return a.substance_type < b.substance_type ? -1 : 1;
        }
        if (a.col !== b.col) return (a.col as number) - (b.col as number);
        return (a.row as number) - (b.row as number);
    });

    const csvRows: (string | number)[][] = [ProductPlateCSVColumns as any];
    for (const r of rows) {
        const row = [];
        for (const c of ProductPlateCSVColumns) row.push(r[c]);
        csvRows.push(row);
    }

    return arrayToCsv(csvRows);
}
