import { CompoundAssets } from '../../lib/services/assets';
import { EcosystemService } from '../../lib/services/ecosystem';
import { ECMApi, ECMSearchResult, Vial, formatSampleContentInSearch, isInventorySearchResult } from '../ECM/ecm-api';
import { requestedInventoryComparer } from '../ECM/requests/request/batches';
import { EnumerationAPI, HTEEnumerationReactionInfoEntry } from '../HTE/enumeration/enumeration-api';
import { HTE2Api } from './api';
import { HTEPInventoryItem } from './data-model';

export interface HTEDReactionChemistryOption {
    label: string;
    value?: HTEEnumerationReactionInfoEntry;
}

export class HTEDAssets {
    entities = new CompoundAssets();

    private inventory = new Map<string, { results: ECMSearchResult[]; options: [string, string, string][] }>();
    inventoryByBarcode = new Map<string, ECMSearchResult>();
    vials = new Map<string, Vial>();

    reactionChemistryOptions: HTEDReactionChemistryOption[] = [];
    private reactionChemistryMap: Map<string | undefined, HTEDReactionChemistryOption> = new Map();
    projectOptions: [string, string][] = [];

    atmosphereOptions: [string, string][] = [];

    getReactionChemistryOption(id: string) {
        return this.reactionChemistryMap.get(id);
    }

    async init() {
        const [reactions, env, hteAssets] = await Promise.all([
            EnumerationAPI.reactionsInfo(),
            EcosystemService.getEnvironment(),
            HTE2Api.assets(),
        ]);

        this.reactionChemistryOptions = reactions.map((r) => ({
            label: `${r.name} (${r.kind})`,
            value: r,
        }));
        this.reactionChemistryMap = new Map(this.reactionChemistryOptions.map((r) => [r.value?.id, r]));

        this.projectOptions = env.projects.map((p) => [p, p]);
        this.atmosphereOptions = hteAssets.atmospheres.map((e) => [e.value, `${e.value}: ${e.description}`]);
    }

    private getInventoryKey(e: { identifier?: string; solvent: string }) {
        return `${e.identifier?.split('-', 1)[0] ?? ''}-${e.solvent}`;
    }

    getInventory(e: { identifier?: string; solvent: string }) {
        return this.inventory.get(this.getInventoryKey(e));
    }

    async syncInventory(entries: HTEPInventoryItem[], refresh = false) {
        const missing = refresh
            ? entries.filter((e) => e.identifier)
            : entries.filter((e) => e.identifier && !this.inventory.has(this.getInventoryKey(e)));
        if (missing.length === 0) return;

        const toQuery = missing.map((e) => e.identifier!.split('-', 1)[0]);
        const query = await ECMApi.query({ identifiers: toQuery, query_plates: false });
        const results = query.results.toObjects();
        const map = new Map<string, ECMSearchResult[]>();

        const addToMap = (id: string, r: ECMSearchResult) => {
            if (!map.has(id)) {
                map.set(id, [r]);
            } else {
                map.get(id)!.push(r);
            }
        };

        for (const r of results) {
            this.inventoryByBarcode.set(r.barcode, r);
            if (!r.sample) continue;

            const batch = query.batches[r.sample.batch_id];
            if (!this.entities.batchesById.has(batch.id)) {
                this.entities.batchesById.set(batch.id, batch);
            }

            const compound = query.compounds[batch.compound_id];
            addToMap(compound.universal_identifier!, r);
        }

        for (const e of missing) {
            this.inventory.set(this.getInventoryKey(e), { results: [], options: [] });
        }

        for (const e of missing) {
            const rs = map.get(e.identifier!.split('-', 1)[0]);
            if (!rs?.length) continue;

            const xs = this.inventory.get(this.getInventoryKey(e));
            if (!xs) continue;

            for (const r of rs) {
                if (!r || !isInventorySearchResult(r)) continue;
                if (!xs.results.includes(r)) xs.results.push(r);
            }
        }

        for (const e of missing) {
            const xs = this.inventory.get(this.getInventoryKey(e));
            if (!xs?.results.length) continue;

            const comparer = requestedInventoryComparer({ volume_l: e.volume * 1e3, concentration_M: e.concentration });
            xs.results.sort(comparer);

            const solvent = e.solvent.toUpperCase();
            const correctSolvent = xs.results.filter((r) => r.sample?.solvent?.toUpperCase() === solvent);
            const incorrectSolvent = xs.results.filter((r) => r.sample?.solvent?.toUpperCase() !== solvent);

            const inventoryResults = [...correctSolvent, ...incorrectSolvent];

            const options = inventoryResults.map(
                (r) =>
                    [
                        r.barcode,
                        `${r.barcode}: ${formatSampleContentInSearch(r.sample)}`,
                        `${r.barcode}: ${formatSampleContentInSearch(
                            r.sample
                        )} (${this.entities.getIdentifierForBatchId(r.sample!.batch_id)})`,
                    ] as [string, string, string]
            );

            this.inventory.set(this.getInventoryKey(e), { results: inventoryResults, options });
        }
    }

    async syncHolders(barcodes: string[], refresh = false) {
        const missing = refresh ? barcodes : barcodes.filter((b) => !this.vials.has(b));
        if (!missing.length) return;

        const { vials } = await ECMApi.queryHolders({ barcodes: missing });
        const batchIdSet = new Set<number>();
        for (const v of vials) {
            this.vials.set(v.barcode, v);
            if (v.sample) batchIdSet.add(v.sample?.batch_id);
        }

        await this.entities.syncBatchIds(Array.from(batchIdSet));
    }

    async getVial(barcode: string, refresh = false) {
        await this.syncHolders([barcode], refresh);
        return this.vials.get(barcode);
    }
}
