import { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { LabeledInput } from '../../../components/common/Inputs';
import { LogModel } from '../../../components/common/Log';
import { PropertyNameValue } from '../../../components/common/PropertyNameValue';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import { ToastService } from '../../../lib/services/toast';
import { reportErrorAsToast, tryGetErrorMessage } from '../../../lib/util/errors';
import { roundValue } from '../../../lib/util/roundValues';
import { Vial } from '../ecm-api';
import { ECMCommonUploadInfo, ECMPageTemplate, ECMWorkflowMode, ECMWorkflowModeSelect } from '../ecm-common';
import {
    ecmAPITemplate,
    ECMBatchWorkflowBase,
    ECMBatchWorkflowWrapper,
    ecmGatherMessages,
    ECMManualWorkflowBase,
    ECMManualWorkflowInputHelper,
    ECMManualWorkflowStatus,
    ECMManualWorkflowWrapper,
    ecmWorkflowEntrySummary,
    uploadECMCSV,
} from './common';

interface PrepareTareInput {
    row_index?: number;
    barcode?: string;
    mass?: number;
    mass_unit?: string;
}

interface PrepareTareEntry {
    input: PrepareTareInput;

    errors: string[];
    warnings: string[];

    vial?: Vial;
    barcode?: string;
    tare_mass?: number;
}

type PrepareTareEntries = PrepareTareEntry[];

const TareAPI = ecmAPITemplate<PrepareTareInput[], PrepareTareEntries>('vials/tare');

interface ManualVialTareWorkflowInput {
    barcode: string;
    mass: string;
}

class ManualVialTareWorkflow implements ECMManualWorkflowBase<PrepareTareEntry> {
    inputs = {
        barcode: { current: null } as MutableRefObject<HTMLInputElement | null>,
        mass: { current: null } as MutableRefObject<HTMLInputElement | null>,
    };

    state = {
        input: new BehaviorSubject<ManualVialTareWorkflowInput>({ barcode: '', mass: '' }),
        entry: new BehaviorSubject<PrepareTareEntry | undefined>(undefined),
        autosubmit: new BehaviorSubject<boolean>(true),
        preparing: new BehaviorSubject<boolean>(false),
        submitting: new BehaviorSubject<boolean>(false),
    };

    log = new LogModel();

    get autosubmit() {
        return this.state.autosubmit.value;
    }

    get isInputValid(): boolean {
        // TODO: this could be improved
        const input = this.state.input.value;
        return input.barcode.length > 0 && input.mass.length > 0;
    }

    async prepare() {
        ToastService.remove('warn-autosubmit');

        if (!this.isInputValid) {
            return;
        }

        try {
            this.state.preparing.next(true);
            const input = this.state.input.value;
            const entries = await TareAPI.prepare([
                {
                    barcode: input.barcode,
                    mass: +input.mass,
                    mass_unit: 'mg',
                },
            ]);

            const entry = entries[0];
            this.state.entry.next(entry);
            if (!entry) return;

            if (!entry.barcode) {
                requestAnimationFrame(() => this.inputs.barcode.current?.focus());
                return;
            }
            if (typeof entry.tare_mass !== 'number') {
                requestAnimationFrame(() => this.inputs.mass.current?.focus());
                return;
            }

            if (this.autosubmit && entry.errors.length === 0 && entry.warnings.length === 0) {
                this.submit();
                return;
            }

            if (this.autosubmit && entry.errors.length === 0 && entry.warnings.length > 0) {
                ToastService.show({
                    id: 'warn-autosubmit',
                    type: 'warning',
                    message: 'Auto-submit paused due to a warning.\nPlease fix the issue or submit manually.',
                });
            }
        } catch (err) {
            reportErrorAsToast('Tare', err);
            this.state.entry.next(undefined);
        } finally {
            this.state.preparing.next(false);
        }
    }

    async submit() {
        const entry = this.state.entry.value;
        if (!entry) return;

        try {
            this.state.submitting.next(true);

            await TareAPI.upload([entry]);
            // NOTE: For testing to fake upload
            // await new Promise((res) => {
            //     setTimeout(res, 500);
            // });

            this.log.message(
                'success',
                `Vial ${entry.barcode} ${entry.vial ? 're-' : ''}tared to ${roundValue(0, entry.tare_mass! * 1000)} mg.`
            );
            this.state.input.next({ barcode: '', mass: '' });
            this.state.entry.next(undefined);
            requestAnimationFrame(() => this.inputs.barcode.current?.focus());
        } catch (err) {
            this.log.message('danger', `Vial ${entry?.barcode} error: ${tryGetErrorMessage(err)}`);
        } finally {
            this.state.submitting.next(false);
        }
    }
}

class BatchVialTareWorkflow implements ECMBatchWorkflowBase<PrepareTareEntries> {
    state = {
        submitting: new BehaviorSubject<boolean>(false),
    };
    entries: PrepareTareEntries;
    summary: ECMBatchWorkflowBase['summary'];
    messages: ECMBatchWorkflowBase['messages'];

    async submit() {
        if (this.summary.numErrors > 0 || !this.entries.length) {
            return;
        }

        try {
            this.state.submitting.next(true);

            await TareAPI.upload(this.entries);
            // NOTE: For testing to fake upload
            // await new Promise((res) => {
            //     setTimeout(res, 500);
            // });

            this.fileSubject.next(null);
            ToastService.show({
                type: 'success',
                message: `Successfully uploaded ${this.entries.length} vial${
                    this.entries.length === 1 ? '' : 's'
                } to Foundry.`,
            });
        } catch (err) {
            reportErrorAsToast('Tare', err);
        } finally {
            this.state.submitting.next(false);
        }
    }

    constructor(
        public files: [filename: string, entries: PrepareTareEntries][],
        private fileSubject: BehaviorSubject<File[] | null>
    ) {
        this.entries = files.flatMap((f) => f[1]);
        this.summary = ecmWorkflowEntrySummary(this.entries);
        this.messages = ecmGatherMessages(files);
    }
}

export function ECMTare() {
    const [mode, setMode] = useState<ECMWorkflowMode>('manual');

    return (
        <ECMPageTemplate page='tare' withFooter>
            <ECMWorkflowModeSelect mode={mode} setMode={setMode} />
            {mode === 'manual' && <ManualRoot />}
            {mode === 'batch' && <BatchRoot />}
        </ECMPageTemplate>
    );
}

const ManualModel = new ManualVialTareWorkflow();

function ManualRoot() {
    const model = ManualModel;

    return (
        <ECMManualWorkflowWrapper
            model={model}
            input={<ManualInput model={model} />}
            status={<ManualStatus model={model} />}
        />
    );
}

function ManualInput({ model }: { model: ManualVialTareWorkflow }) {
    const preparing = useBehavior(model.state.preparing);
    const submitting = useBehavior(model.state.submitting);
    const disabled = preparing || submitting;

    const input = useBehavior(model.state.input);

    return (
        <ECMManualWorkflowInputHelper model={model} disabled={disabled} preparing={preparing}>
            <LabeledInput label='Barcode' className='ecm-manual-inputs-row'>
                <Form.Control
                    value={input.barcode}
                    ref={model.inputs.barcode}
                    onChange={(e) => model.state.input.next({ ...input, barcode: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.barcode) model.inputs.mass.current?.focus();
                    }}
                    disabled={disabled}
                    autoFocus
                />
            </LabeledInput>
            <LabeledInput label='Mass (mg)' className='ecm-manual-inputs-row'>
                <Form.Control
                    value={input.mass}
                    ref={model.inputs.mass}
                    onChange={(e) => model.state.input.next({ ...input, mass: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.mass) model.prepare();
                    }}
                    disabled={disabled}
                />
            </LabeledInput>
        </ECMManualWorkflowInputHelper>
    );
}

function ManualStatus({ model }: { model: ManualVialTareWorkflow }) {
    return (
        <ECMManualWorkflowStatus model={model}>
            {(entry) => {
                const mass = entry.tare_mass ? `${roundValue(1, entry.tare_mass * 1000)} mg` : entry.input.mass;
                return (
                    <>
                        <PropertyNameValue field='Barcode' value={entry.barcode ?? entry.input.barcode} />
                        <PropertyNameValue field='Vial Status' value={entry.vial?.status ?? 'New'} />
                        <PropertyNameValue field='Tare Mass' value={mass} />
                    </>
                );
            }}
        </ECMManualWorkflowStatus>
    );
}

async function uploadCSV(files: File[] | null, fileSubject: BehaviorSubject<File[] | null>) {
    return uploadECMCSV(TareAPI, BatchVialTareWorkflow, files, fileSubject);
}

function BatchRoot() {
    const [model, loadModel] = useAsyncAction<BatchVialTareWorkflow | undefined>();

    const upload = useCallback(
        (files: File[] | null, fileSubject: BehaviorSubject<File[] | null>) => loadModel(uploadCSV(files, fileSubject)),
        [loadModel]
    );
    const info = useMemo(() => <BatchUploadInfo />, []);

    return <ECMBatchWorkflowWrapper model={model} upload={upload} info={info} />;
}

function BatchUploadInfo() {
    return <ECMCommonUploadInfo required={['Barcode', 'Mass']} optional={['Mass Unit (=mg)']} />;
}
