import log from 'loglevel';
import { unpack } from 'msgpackr';
import { EntosMsgpackNumpyArray, EntosMsgpackDataframe, EntosMsgpackDataframeIndex } from '../../api/base';
import { ColumnTableData } from '../../components/DataTable';

export interface DecodeOptions {
    useTypedArrays?: boolean;
    eoi?: 'keep' | 'hex' | 'strip';
    eoiPattern?: 'exact' | 'substr';
}

export function decodeEntosMsgpack(data: Uint8Array | ArrayBuffer, options?: DecodeOptions) {
    // If we use this for more complex objects, will need to recurse
    const obj = unpack(data instanceof Uint8Array ? data : new Uint8Array(data));
    return decode(obj, options ?? {});
}

function decode(obj: any, options: DecodeOptions) {
    if (obj === null || obj === undefined) return obj;
    const type = typeof obj;

    if (type === 'string' || type === 'number' || type === 'boolean' || obj instanceof Uint8Array) {
        return obj;
    }

    if (type === 'bigint') {
        // Convert the bigint number to a floating point which allows the rest of the code to work as expected
        // at the cost of reduced precision
        log.warn('BigInts are not supported. Converting to floating point.', obj);
        return Number(obj);
    }

    if (Array.isArray(obj)) {
        return decodeArray(obj, options);
    }

    if (type !== 'object') return obj;

    if (options.eoi === 'strip') {
        if (options.eoiPattern === 'substr') {
            for (const k of Object.keys(obj)) {
                if (k.includes('_eoi')) {
                    delete obj[k]; // eslint-disable-line
                }
            }
        }
        if (obj.eoi) {
            delete obj.eoi; // eslint-disable-line
        }
    } else if (options.eoi === 'hex') {
        if (options.eoiPattern === 'substr') {
            for (const k of Object.keys(obj)) {
                if (k.includes('_eoi')) {
                    obj[k] = eoiToHex(obj[k], k); // eslint-disable-line
                }
            }
        }
        if (obj.eoi) {
            obj.eoi = eoiToHex(obj.eoi, 'eoi'); // eslint-disable-line
        }
    }

    if (obj._ndarray_) return decodeEntosMsgpackNumpyArray(obj, options);
    if (obj._df_) return decodeEntosMsgpackDataframe(obj, options);

    const ret: any = Object.create(null);
    for (const k of Object.keys(obj)) {
        ret[k] = decode(obj[k], options);
    }
    return ret;
}

function decodeEntosMsgpackNumpyArray(array: EntosMsgpackNumpyArray, options: DecodeOptions): any[] {
    let endian: Endian = 'little';
    if (array.dtype[0] === '|') endian = NativeEndian;
    else if (array.dtype[0] === '>') endian = 'big';
    else endian = 'little';

    const dtype = /[<>|]/.test(array.dtype[0]) ? array.dtype.substring(1) : array.dtype;
    if (DTYPE_MAP[dtype]) {
        const Ctor = DTYPE_MAP[dtype];
        const data = normalizeByteOrder(array.data, endian, Ctor.BYTES_PER_ELEMENT);

        // np.dtype('b1') == bool ==> True
        if (dtype === 'b1') {
            const bools = new Array(data.length);
            for (let i = 0; i < data.length; i++) bools[i] = !!data[i];
            return bools;
        }

        const ret = createArray(data, Ctor);
        return options.useTypedArrays ? ret : Array.from(ret);
    }

    // 64-bit integers
    if (dtype === 'i8' || dtype === 'u8') {
        const data = normalizeByteOrder(array.data, endian, 8);
        return decodeInt64(data, dtype === 'i8', !!options.useTypedArrays);
    }

    // datetimes
    if (dtype[0] === 'M') {
        return decodeDatetime(array.data, dtype, endian);
    }

    // unicode strings
    if (dtype[0] === 'U') {
        const data = normalizeByteOrder(array.data, endian, 4);
        const view = createArray(data, Uint32Array);
        const width = +dtype.substring(1);
        return decodeUnicodeArray(view, width);
    }

    throw new Error(`Unsupported dtype: ${array.dtype}`);
}

export function eoiToHex(eoi: any, key: string) {
    if (key.includes('_eois')) {
        const ret = [];
        for (const e of eoi) {
            ret.push(_eoiToHex(e));
        }
        return ret;
    }
    return _eoiToHex(eoi);
}

const _eoiTemp: string[] = [];
function _eoiToHex(eoi: any) {
    if (!eoi || typeof eoi === 'string') return eoi;
    const l = eoi.length;
    if (!l) return eoi;

    _eoiTemp.length = 0;
    for (let i = 0; i < l; i++) {
        // eslint-disable-next-line no-bitwise
        const b = (+eoi[i] & 0xff).toString(16);
        _eoiTemp[i] = b.length === 2 ? b : `0${b}`;
    }
    return _eoiTemp.join('');
}

interface DecodedDataframeIndex {
    names: string[];
    data: any[];
}

function decodeEntosMsgpackDataframe(df: EntosMsgpackDataframe, options: DecodeOptions): ColumnTableData {
    let columns = decodeEntosMsgpackDataframeIndex(df.column_index, options).data;
    const index = decodeEntosMsgpackDataframeIndex(df.index, options).data;
    const data: any[][] = [];

    const eoiCols = new Set<number>();
    for (let i = 0; i < columns.length; i++) {
        const name = columns[i];
        if (name === 'eoi' || (options.eoiPattern === 'substr' && name.includes('_eoi'))) {
            eoiCols.add(i);
        }
    }

    if (options.eoi === 'strip' && eoiCols.size > 0) {
        columns = columns.filter((_, i) => !eoiCols.has(i));
        // eslint-disable-next-line no-param-reassign
        df.columns = df.columns.filter((_, i) => !eoiCols.has(i));
    }

    for (const col of df.columns) {
        if (Array.isArray(col)) data.push(decodeArray(col, options));
        else data.push(decodeEntosMsgpackNumpyArray(col, options));
    }

    if (eoiCols.size > 0 && options.eoi === 'hex') {
        for (const i of Array.from(eoiCols)) {
            const col = data[i];
            const columnName = columns[i];
            // Typed arrays can't contain EOIs
            if (Array.isArray(col)) {
                data[i] = col.map((v) => eoiToHex(v, columnName));
            }
        }
    }

    return { columns, index, data };
}

function decodeArray(array: any[], options: DecodeOptions) {
    const ret: any[] = new Array(array.length);
    for (let i = 0, _i = array.length; i < _i; i++) {
        ret[i] = decode(array[i], options);
    }
    return ret;
}

function decodeEntosMsgpackDataframeIndex(
    index: EntosMsgpackDataframeIndex,
    options: DecodeOptions
): DecodedDataframeIndex {
    if (Array.isArray(index.index)) {
        return { names: index.name, data: decodeArray(index.index, options) };
    }

    return {
        names: index.name,
        data: decodeEntosMsgpackNumpyArray(index.index, options),
    };
}

type Endian = 'little' | 'big';

const NativeEndian: Endian = new Uint16Array(new Uint8Array([0x12, 0x34]).buffer)[0] === 0x3412 ? 'little' : 'big';

type TypedArrayCtor = { BYTES_PER_ELEMENT: number; new (buffer: ArrayBuffer, byteOffset: number, length: number): any };

const DTYPE_MAP: { [t: string]: TypedArrayCtor } = {
    // Bytes (endian has no effet)
    b: Int8Array,
    B: Uint8Array,
    b1: Int8Array,
    B1: Uint8Array,
    u1: Uint8Array,
    i1: Int8Array,
    u2: Uint16Array,
    i2: Int16Array,
    u4: Uint32Array,
    i4: Int32Array,
    f4: Float32Array,
    f8: Float64Array,
};

function flipByteOrder(data: Uint8Array, byteCount: number) {
    const buffer = new ArrayBuffer(data.length);
    const ret = new Uint8Array(buffer);
    for (let i = 0, n = data.length; i < n; i += byteCount) {
        for (let j = 0; j < byteCount; j++) {
            ret[i + byteCount - j - 1] = data[i + j];
        }
    }
    return ret;
}

function normalizeByteOrder(data: Uint8Array, dataEndian: 'little' | 'big', byteCount: number) {
    return dataEndian === NativeEndian || byteCount === 1 ? data : flipByteOrder(data, byteCount);
}

function decodeInt64(buffer: Uint8Array, isSigned: boolean, useTypedArray: boolean): number[] {
    const view = createArray(buffer, Uint32Array);
    const length = view.length / 2;
    const ret = useTypedArray ? new Float64Array(length) : new Array<number>(length);
    let o = 0;
    const maxSafe = Number.MAX_SAFE_INTEGER;
    if (isSigned) {
        for (let i = 0; i < view.length; i += 2) {
            let low = view[i];
            let high = view[i + 1];
            let v: number;
            // eslint-disable-next-line
            if (high >> 31) {
                high = ~high; // eslint-disable-line
                low = ~low; // eslint-disable-line
                if (low < 0) low = 0xffffffff + low + 1;
                v = -(high ? high * 4294967296 + low : low) - 1;
                if (v < -maxSafe) {
                    throw new Error('64-bit int value out of range. Implement BigInt support.');
                }
            } else {
                v = high ? high * 4294967296 + low : low;
                if (v > maxSafe) {
                    throw new Error('64-bit int value out of range. Implement BigInt support.');
                }
            }
            ret[o++] = v;
        }
    } else {
        for (let i = 0; i < view.length; i += 2) {
            const low = view[i];
            const high = view[i + 1];
            ret[o++] = high ? high * 4294967296 + low : low;
        }
    }
    return ret as any;
}

const END_OF_EPOCH = 9223372036854;

function decodeDatetime(buffer: Uint8Array, dtype: string, endian: Endian) {
    let epochs: number[] | undefined;

    if (dtype.startsWith('M8')) {
        epochs = decodeInt64(normalizeByteOrder(buffer, endian, 8), false, false);
    } else if (dtype.startsWith('M4')) {
        epochs = createArray(normalizeByteOrder(buffer, endian, 4), Uint32Array);
    }

    if (!epochs) {
        throw new Error(`Unsupported datetime format: ${dtype}`);
    }

    let toMs = 1;
    if (dtype.endsWith('[s]')) toMs = 1e3;
    else if (dtype.endsWith('[us]')) toMs = 1e-3;
    else if (dtype.endsWith('[ns]')) toMs = 1e-6;
    else if (!dtype.endsWith('[ms]')) {
        throw new Error(`Unsupported datetime format: ${dtype}`);
    }

    const ret = new Array<Date | null>(epochs.length);
    for (let i = 0; i < epochs.length; i++) {
        const inMs = epochs[i] * toMs;
        // NOTE: This is somewhat ad-hoc hack, but seems to work.
        //       Should be good enough till 2200s.
        // Pandas encode NaT as a END_OF_EPOCH date time
        // https://pandas.pydata.org/docs/user_guide/missing_data.html#datetimes
        ret[i] = inMs >= END_OF_EPOCH ? null : new Date(inMs);
    }
    return ret;
}

function decodeUnicodeArray(view: Uint32Array, width: number): string[] {
    const buff = new Array<string>(width);
    const ret = new Array<string>((view.length / width) | 0); // eslint-disable-line
    buff.fill('');
    let o = 0;
    for (let i = 0, _i = view.length; i < _i; i += width) {
        for (let j = 0; j < width; j++) {
            const v = view[i + j];
            buff[j] = v ? String.fromCodePoint(v) : '';
        }
        ret[o++] = buff.join('');
    }
    return ret;
}

function createArray(buffer: Uint8Array, Ctor: TypedArrayCtor) {
    // The byte offset needs to start at a multiple of 4 in order to
    // create a typed view of it.
    if (buffer.byteOffset % Math.max(4, Ctor.BYTES_PER_ELEMENT) === 0) {
        return new Ctor(buffer.buffer, buffer.byteOffset, buffer.byteLength / Ctor.BYTES_PER_ELEMENT);
    }

    // If it doesn't, copy the data over to a new buffer.
    const copy = new Uint8Array(buffer.byteLength);
    copy.set(buffer, 0);
    return new Ctor(copy.buffer, copy.byteOffset, copy.byteLength / Ctor.BYTES_PER_ELEMENT);
}
