import { CSSProperties, useLayoutEffect, useRef, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { Row, TableInstance } from 'react-table';
import { WellLayerType } from '../experiment-data';
import { Table } from '../Table';

export function HTEExperimentTable<T extends object>({
    table,
    onRowMouseEnter,
    onRowMouseLeave,
    lineHeight = 80,
    noPadding,
}: {
    table: TableInstance<T>;
    onRowMouseEnter?: (row: Row<T>) => void;
    onRowMouseLeave?: (row: Row<T>) => void;
    lineHeight?: number;
    noPadding?: boolean;
}) {
    const [height, setHeight] = useState(600);
    const wrapper = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const target = wrapper.current!; // need to store the value due to how refs work.
        let frameHandle: any;
        const resizeObserver = new ResizeObserver(() => {
            if (frameHandle !== undefined) cancelAnimationFrame(frameHandle);
            frameHandle = requestAnimationFrame(() => setHeight(target.clientHeight));
        });
        resizeObserver.observe(target);

        setHeight(target.clientHeight);

        return () => {
            resizeObserver.unobserve(target);
        };
    }, []);

    return (
        <div ref={wrapper} className={`hte-experiment-table-wrapper${noPadding ? ' p-0' : ''}`}>
            <Table
                headerGroups={table.headerGroups}
                rows={table.rows}
                selectedRows={{}}
                prepareRow={table.prepareRow}
                lineHeight={lineHeight}
                height={height}
                onRowMouseEnter={onRowMouseEnter}
                onRowMouseLeave={onRowMouseLeave}
            />
        </div>
    );
}

export function ReactantTypeBadge({ type, style }: { type: WellLayerType; style?: CSSProperties }) {
    const label: string = type === 'bb' || type === 'msd' ? type.toUpperCase() : type;
    return (
        <Badge className={`hte-experiment-type-current-${type} hte-experiment-type-badge`} style={style}>
            {label}
        </Badge>
    );
}
