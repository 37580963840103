import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { CSSProperties, MouseEvent, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Spinner } from 'react-bootstrap';
import { useAsyncAction } from '../../lib/hooks/useAsyncAction';

export interface AsyncButtonProps {
    variant?: string;
    className?: string;
    icon?: IconDefinition;
    children?: ReactNode;
    disabled?: boolean;
    size?: 'sm';
    title?: string;
    style?: CSSProperties;
    iconSpacing?: number;
}

export function AsyncActionButton({
    action,
    children,
    ...props
}: AsyncButtonProps & {
    action: () => any;
}) {
    const [state, apply] = useAsyncAction();

    return (
        <AsyncButton onClick={() => apply(action())} state={state} {...props}>
            {children}
        </AsyncButton>
    );
}

export function AsyncButton({
    variant,
    className,
    icon,
    onClick,
    children,
    disabled,
    state,
    size,
    title,
    style,
    iconSpacing,
}: AsyncButtonProps & {
    state?: { isLoading?: boolean };
    onClick: (e: MouseEvent) => any;
}) {
    return (
        <Button
            className={className}
            variant={variant}
            disabled={disabled || state?.isLoading}
            onClick={onClick}
            size={size}
            title={title}
            style={style}
        >
            {state?.isLoading && (
                <Spinner
                    animation='border'
                    size='sm'
                    role='status'
                    className={children ? `me-${iconSpacing ?? 2}` : undefined}
                />
            )}
            {!state?.isLoading && icon && (
                <FontAwesomeIcon
                    icon={icon}
                    size='sm'
                    className={children ? `me-${iconSpacing ?? 2}` : undefined}
                    fixedWidth
                />
            )}
            {children}
        </Button>
    );
}
