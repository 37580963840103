import { faBug, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, ReactNode } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import Split from 'react-split-it';
import { BehaviorSubject } from 'rxjs';
import api from '../../../api';
import { EntrySummary, EntrySummaryComponent } from '../../../components/common/EntrySummary';
import { ErrorMessage } from '../../../components/common/Error';
import Loading from '../../../components/common/Loading';
import { LogModel } from '../../../components/common/Log';
import Pane from '../../../components/Pane/Pane';
import { AsyncState } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import useBehaviorSubject from '../../../lib/hooks/useBehaviorSubject';
import { DialogService } from '../../../lib/services/dialog';
import { objectIsEmpty } from '../../../lib/util/misc';
import { decodeEntosMsgpack } from '../../../lib/util/serialization';
import { ECMCommonUploadControls, ECMEventLog, ECMMessageList, ECMUploadCallback } from '../ecm-common';

export interface ECMWorkflowEntry {
    input: { row_index?: number };
    errors?: string[];
    warnings?: string[];
}

export interface ECMManualWorkflowBase<T extends ECMWorkflowEntry = any> {
    state: {
        entry: BehaviorSubject<T | undefined>;
        autosubmit: BehaviorSubject<boolean>;
        preparing: BehaviorSubject<boolean>;
        submitting: BehaviorSubject<boolean>;
    };

    log: LogModel;

    readonly noAutoSubmit?: boolean;
    readonly isInputValid: boolean;
    prepare(): Promise<void>;
    submit(): Promise<void>;
}

export interface ECMBatchWorkflowBase<T = any> {
    state: {
        submitting: BehaviorSubject<boolean>;
    };
    messages: {
        filename: string;
        errors: string[];
        warnings: string[];
    }[];
    summary: EntrySummary;
    entries: T;
    submit(): Promise<void>;
}

export function ecmAPITemplate<Inputs extends any[], Entries>(endpoint: string) {
    return {
        async prepare(inputs: Inputs): Promise<Entries> {
            const { data } = await api.client.post(
                `ecm/${endpoint}/prepare`,
                { inputs },
                {
                    responseType: 'arraybuffer',
                }
            );
            return decodeEntosMsgpack(data, { eoi: 'strip' });
        },
        async parseInput(file: File, overrides?: Record<string, any>): Promise<Inputs> {
            const formData = new FormData();
            formData.append('file', file);
            if (overrides && !objectIsEmpty(overrides)) {
                formData.append('overrides', JSON.stringify(overrides));
            }
            const { data } = await api.client.post(`ecm/${endpoint}/parse`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                responseType: 'arraybuffer',
            });
            return decodeEntosMsgpack(data, { eoi: 'strip' });
        },
        async upload(entries: Entries): Promise<void> {
            await api.client.post(
                `ecm/${endpoint}`,
                { entries },
                {
                    responseType: 'arraybuffer',
                }
            );
        },
    };
}

export function ecmWorkflowEntrySummary(entries?: ECMWorkflowEntry[]): EntrySummary {
    return {
        numReady: entries?.filter((e) => !e.warnings?.length && !e.errors?.length).length ?? 0,
        numErrors: entries?.filter((e) => e.errors?.length! > 0)?.length ?? 0,
        numWarnings: entries?.filter((e) => e.warnings?.length! > 0)?.length ?? 0,
    };
}

export function ecmGatherMessages(files: [filename: string, entries: ECMWorkflowEntry[]][]) {
    return files.map((file) => ({
        filename: file[0],
        errors: file[1].flatMap((e) => e.errors?.map((msg) => `[Row ${(e.input.row_index ?? 0) + 1}] ${msg}`) ?? []),
        warnings: file[1].flatMap(
            (e) => e.warnings?.map((msg) => `[Row ${(e.input.row_index ?? 0) + 1}] ${msg}`) ?? []
        ),
    }));
}

export function ECMManualWorkflowStatusHeader({
    submitting,
    preparing,
    entry,
}: {
    submitting: boolean;
    preparing: boolean;
    entry?: ECMWorkflowEntry;
}) {
    let statusClass = 'info';
    let statusText = submitting ? 'Submitting to Foundry...' : entry ? 'Ready to submit' : '';

    if (preparing) {
        statusClass = 'info';
        statusText = 'Updating...';
    } else if (entry?.errors?.length) {
        statusClass = 'danger';
        statusText = 'Error';
    } else if (entry?.warnings?.length) {
        statusClass = 'warning';
        statusText = 'Ready to submit — with warnings';
    }

    return (
        <span>
            {(preparing || submitting) && (
                <Spinner animation='border' size='sm' className='me-2 text-primary' role='status' />
            )}
            {!preparing && !submitting && !!entry && (
                <FontAwesomeIcon
                    icon={statusClass === 'info' ? faCheck : faBug}
                    size='sm'
                    className={`me-2 text-${statusClass}`}
                />
            )}
            Status{statusText ? ': ' : ''}
            <span className={`text-${statusClass}`}>{statusText}</span>
        </span>
    );
}

export function ECMManualWorkflowStatusMessages({ entry }: { entry: ECMWorkflowEntry }) {
    return (
        <>
            <div className='mt-2'>
                <h6 className='text-secondary mb-1'>Errors</h6>
                <ECMMessageList messages={entry.errors ?? []} kind='danger' />
            </div>
            <div className='mt-2'>
                <h6 className='text-secondary mb-1'>Warnings</h6>
                <ECMMessageList messages={entry.warnings ?? []} kind='warning' />
            </div>
        </>
    );
}

export function ECMManualWorkflowFooter({ model }: { model: ECMManualWorkflowBase }) {
    const preparing = useBehavior(model.state.preparing);
    const submitting = useBehavior(model.state.submitting);
    const entry = useBehavior(model.state.entry) as ECMWorkflowEntry | undefined;
    const disabled = preparing || submitting;

    const autoSubmit = useBehavior(model.state.autosubmit);

    return (
        <div className='entos-footer justify-content-between hstack gap-2 border-top'>
            <div className='m-auto' />
            {!model.noAutoSubmit && (
                <Form.Check
                    label='Auto-submit to Foundry'
                    id='manual-auto-submit'
                    checked={autoSubmit}
                    onChange={(e) => model.state.autosubmit.next(e.target.checked)}
                />
            )}
            <Button
                size='sm'
                disabled={disabled || !entry || !!entry.errors?.length}
                variant='primary'
                onClick={() => model.submit()}
            >
                {submitting && <Spinner animation='border' size='sm' className='me-2' role='status' />}
                Submit
            </Button>
        </div>
    );
}

function ECMManualSubmitButton({ model }: { model: ECMManualWorkflowBase }) {
    const preparing = useBehavior(model.state.preparing);
    const submitting = useBehavior(model.state.submitting);
    const entry = useBehavior(model.state.entry) as ECMWorkflowEntry | undefined;
    const disabled = preparing || submitting;

    return (
        <Button
            disabled={disabled || !entry || !!entry.errors?.length}
            variant='primary'
            onClick={() => model.submit()}
        >
            {submitting && <Spinner animation='border' size='sm' className='me-2' role='status' />}
            Submit
        </Button>
    );
}

export function ECMManualWorkflowStatus<T extends ECMWorkflowEntry>({
    model,
    children,
    showSubmit,
}: {
    model: ECMManualWorkflowBase<T>;
    children: (currentEntry: T) => ReactNode;
    showSubmit?: boolean;
}) {
    const entry = useBehavior(model.state.entry);
    const preparing = useBehavior(model.state.preparing);
    const submitting = useBehavior(model.state.submitting);

    const header = <ECMManualWorkflowStatusHeader submitting={submitting} preparing={preparing} entry={entry} />;

    return (
        <Pane title={header} paddingIndex={2} className='ecm-workflow-status-wrapper'>
            <div className='d-flex flex-column w-100 h-100'>
                {entry && (
                    <div className='flex-grow-1 position-relative'>
                        <div className='ecm-workflow-status-content'>
                            {children(entry)}
                            <ECMManualWorkflowStatusMessages entry={entry} />
                        </div>
                    </div>
                )}
                {!entry && (
                    <div className='flex-grow-1'>
                        <p className='text-secondary'>No active input</p>
                    </div>
                )}
                {showSubmit && (
                    <div className='flex-0 mt-2 d-flex justify-content-end'>
                        <ECMManualSubmitButton model={model} />
                    </div>
                )}
            </div>
        </Pane>
    );
}

export function ECMManualWorkflowWrapper({
    model,
    input,
    status,
    inputsRatio = 0.35,
    noFooter,
}: {
    model: ECMManualWorkflowBase;
    input: ReactNode;
    status: ReactNode;
    inputsRatio?: number;
    noFooter?: boolean;
}) {
    return (
        <>
            <Split direction='horizontal' sizes={[inputsRatio, 1 - inputsRatio]}>
                {input}
                <Split direction='vertical' sizes={[0.75, 0.25]}>
                    {status}
                    <ECMEventLog log={model.log} paddingIndex={2} />
                </Split>
            </Split>
            {!noFooter && <ECMManualWorkflowFooter model={model} />}
        </>
    );
}

export function ECMManualWorkflowInputHelper({
    model,
    children,
    disabled,
    preparing,
    size,
    footer,
}: {
    model: ECMManualWorkflowBase;
    children: ReactNode;
    disabled?: boolean;
    preparing: boolean;
    size?: 'sm';
    footer?: ReactNode;
}) {
    const autosubmit = useBehavior(model.state.autosubmit);
    return (
        <Pane title='Input' paddingIndex={2} paneContentClassName='d-flex flex-column'>
            <div className='ecm-manual-inputs'>{children}</div>
            <div className='d-flex flex-row-reverse mt-1'>
                <Button
                    onClick={() => model.prepare()}
                    disabled={disabled || !model || !model.isInputValid}
                    size={size}
                >
                    {preparing && <Spinner animation='border' size='sm' className='me-2' role='status' />}
                    {autosubmit ? 'Auto-submit' : 'Prepare'}
                </Button>
            </div>
            {footer}
        </Pane>
    );
}

export function ECMBatchWorkflowWrapper<T extends ECMBatchWorkflowBase>({
    model,
    upload,
    info,
    Overrides,
    ExportButton,
    defaultOverrides,
}: {
    model: AsyncState<T | undefined>;
    upload: ECMUploadCallback;
    info?: ReactNode;
    Overrides?: FC<{ overridesSubject: BehaviorSubject<Record<string, any>> }>;
    ExportButton?: FC<{ model: T }>;
    defaultOverrides?: Record<string, any>;
}) {
    const overridesSubject = useBehaviorSubject<Record<string, any>>(defaultOverrides ?? {});

    return (
        <div className='flex-grow-1 position-relative'>
            <div className='d-flex flex-column position-absolute' style={{ inset: 0 }}>
                <ECMCommonUploadControls upload={upload} overridesSubject={overridesSubject} showClear />
                <div className='flex-grow-1' style={{ overflow: 'hidden', overflowY: 'auto' }}>
                    {model.isLoading && <Loading />}
                    {model.error && <ErrorMessage header='Upload Error' message={`${model.error}`} />}
                    {model.result && <ECMBatchWorkflowSummary model={model.result} />}
                    {!model.result && !model.isLoading && !model.error && (
                        <>
                            {Overrides && <Overrides overridesSubject={overridesSubject} />}
                            {info}
                        </>
                    )}
                </div>
            </div>
            <ECMBatchWorkflowFooter model={model.result} ExportButton={ExportButton} />
        </div>
    );
}

export function ECMBatchWorkflowSummary({ model }: { model: ECMBatchWorkflowBase }) {
    return (
        <div className='pb-1'>
            <div className='hstack d-flex flex-direction-column align-items-center ecm-batch-workflow-header'>
                <h5 className='py-2'>
                    Upload Details ({model.entries.length} vial{model.entries.length === 1 ? '' : 's'})
                </h5>
            </div>

            <div className='d-flex ecm-batch-workflow-errors'>
                <div className='pe-3'>
                    <h6 className='text-secondary mb-1'>Errors</h6>
                    {model.messages.map((msg, i) => (
                        <div key={i} className='mb-2'>
                            <div className='fw-bold'>{msg.filename}</div>
                            <ECMMessageList messages={msg.errors} kind='danger' length={10} />
                        </div>
                    ))}
                </div>
                <div className='pe-3'>
                    <h6 className='text-secondary mb-1'>Warnings</h6>
                    {model.messages.map((msg, i) => (
                        <div key={i} className='mb-2'>
                            <div className='fw-bold'>{msg.filename}</div>
                            <ECMMessageList messages={msg.warnings} kind='warning' length={10} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

const MAX_SUBMIT_ENTRIES = 99999; // it's over 9000

export function ECMBatchWorkflowFooter({
    model,
    ExportButton,
}: {
    model?: ECMBatchWorkflowBase;
    ExportButton?: FC<{ model: any }>;
}) {
    const submitting = useBehavior(model?.state.submitting);

    const entries = model?.entries;
    const canSubmit = model?.summary.numErrors === 0 && !!entries?.length;

    const onSubmit = () => {
        const { numWarnings } = model!.summary;
        DialogService.open({
            type: 'confirm',
            onConfirm: () => model?.submit(),
            title: 'Confirm Upload',
            text: (
                <>
                    <p>Are you sure you want to upload the data to Foundry?</p>
                    {!!numWarnings && <p className='text-warning'>There are {numWarnings} warnings.</p>}
                </>
            ),
            confirmText: 'Upload',
        });
    };

    return (
        <div className='entos-footer justify-content-between hstack gap-2 border-top'>
            {!!entries && <EntrySummaryComponent summary={model.summary} kind='vial' />}
            <div className='m-auto' />
            {!!entries?.length && !canSubmit && (
                <span className='text-danger me-2'>
                    Fix all errors in the input CSV/XLS and re-upload it before submitting
                </span>
            )}
            {(entries?.length ?? 0) > MAX_SUBMIT_ENTRIES && canSubmit && (
                <span className='text-danger me-2'>
                    At most {MAX_SUBMIT_ENTRIES} entries can be submitted at a time due to current technical limitations
                </span>
            )}
            {!!model && !!ExportButton && <ExportButton model={model} />}
            {!!model && (
                <Button
                    size='sm'
                    disabled={!canSubmit || (entries?.length ?? 0) > MAX_SUBMIT_ENTRIES}
                    variant='primary'
                    onClick={onSubmit}
                >
                    {submitting && <Spinner animation='border' size='sm' className='me-2' role='status' />}
                    Submit
                </Button>
            )}
        </div>
    );
}

export async function uploadECMCSV<WF extends ECMBatchWorkflowBase>(
    API: ReturnType<typeof ecmAPITemplate>,
    Workflow: { new (groups: [string, any[]][], fs: BehaviorSubject<File[] | null>): WF },
    files: File[] | null,
    fileSubject: BehaviorSubject<File[] | null>,
    overridesSubject?: BehaviorSubject<Record<string, any>>
) {
    if (!files) return undefined;
    const inputs: any[] = await Promise.all(files.map((file) => API.parseInput(file, overridesSubject?.value)));
    const slices: [string, number, number][] = [];
    let offset = 0;
    for (let fI = 0; fI < files.length; fI++) {
        const filename = files[fI].name;
        slices.push([filename, offset, offset + inputs[fI].length]);
        offset += inputs[fI].length;
    }
    const prepared: any[] = (await API.prepare(inputs.flatMap((xs) => xs))) as any;
    const entryGroups: [string, any[]][] = slices.map((slice) => [slice[0], prepared.slice(slice[1], slice[2])]);

    return new Workflow(entryGroups, fileSubject);
}
