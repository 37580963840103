import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import Split from 'react-split-it';
import { CustomSelectClassNames, DefaultSelectStyles } from '../../components/common/selectStyles';
import Pane from '../../components/Pane/Pane';
import useBehavior from '../../lib/hooks/useBehavior';
import { DataTableControl, DataTableModel, DefaultRowHeight } from '../../components/DataTable';
import { DownloadCSVButton } from '../../components/DataTable/common';
import { Plot } from '../../components/Plot';
import { PhysicalChemProperties } from './compound-api';
import { PKModel, boundUnboundOptions, concentrationUnitOptions, yAxisOptions } from './pk-model';

function PlotOptions({ model }: { model: PKModel }) {
    const options = useBehavior(model.state.figureOptions);
    const { concentrationUnit, yAxisType } = options;

    return (
        <div className='d-flex my-2'>
            <div className='w-25 pe-2'>
                <Select
                    options={concentrationUnitOptions as any}
                    placeholder='Select a concentration unit'
                    classNames={CustomSelectClassNames}
                    styles={DefaultSelectStyles}
                    menuPortalTarget={document.body}
                    menuPosition='fixed'
                    value={concentrationUnitOptions.find((option) => option.value === concentrationUnit) as any}
                    onChange={(option: unknown) =>
                        model.state.figureOptions.next({
                            ...options,
                            concentrationUnit: (option as any).value,
                        })
                    }
                />
            </div>
            <div className='w-25 me-2'>
                <Select
                    options={yAxisOptions as any}
                    placeholder='Select a Y-Axis type'
                    classNames={CustomSelectClassNames}
                    styles={DefaultSelectStyles}
                    menuPortalTarget={document.body}
                    menuPosition='fixed'
                    value={yAxisOptions.find((option) => option.value === yAxisType) as any}
                    onChange={(option: unknown) =>
                        model.state.figureOptions.next({
                            ...options,
                            yAxisType: (option as any).value,
                        })
                    }
                />
            </div>
            <div className='w-25 me-2'>
                <BoundUnboundOptions model={model} />
            </div>
            <div className='w-25'>
                <PercentUnboundMethodOptions model={model} />
            </div>
        </div>
    );
}

function BoundUnboundOptions({ model }: { model: PKModel }) {
    const options = useBehavior(model.state.figureOptions);
    const { showBoundUnbound } = options;
    const canShowCorrectedCurve = useBehavior(model.state.canShowCorrectedCurve);
    const finalOptions = boundUnboundOptions.map((option) => ({
        ...option,
        isDisabled: !canShowCorrectedCurve && option.value !== 'bound',
    }));

    return (
        <Select
            options={finalOptions as any}
            placeholder='Show bound/unbound curves'
            classNames={CustomSelectClassNames}
            styles={DefaultSelectStyles}
            menuPortalTarget={document.body}
            menuPosition='fixed'
            value={boundUnboundOptions.find((option) => option.value === showBoundUnbound) as any}
            onChange={(option: unknown) =>
                model.state.figureOptions.next({
                    ...options,
                    showBoundUnbound: (option as any).value,
                })
            }
        />
    );
}

function PercentUnboundMethodOptions({ model }: { model: PKModel }) {
    const options = useBehavior(model.state.figureOptions);
    const { showBoundUnbound, preferredPercentUnboundMethod } = options;
    const methodOptions = model.percentUnboundMethods.map((m) => ({ value: m, label: m }));
    const isDisabled = showBoundUnbound === 'bound';

    return (
        <Select
            options={methodOptions as any}
            placeholder='Percent unbound method'
            className={isDisabled ? 'opacity-50' : ''}
            classNames={CustomSelectClassNames}
            styles={DefaultSelectStyles}
            menuPortalTarget={document.body}
            menuPosition='fixed'
            value={methodOptions.find((option) => option.value === preferredPercentUnboundMethod) as any}
            onChange={(option: unknown) =>
                model.state.figureOptions.next({
                    ...options,
                    preferredPercentUnboundMethod: (option as any).value,
                })
            }
            isDisabled={isDisabled}
        />
    );
}

function PKTablePane({ model }: { model: PKModel }) {
    useBehavior(model.table.version);

    return (
        <Pane
            title='PK Parameters'
            paddingIndex={0}
            topPaddingIndex={2}
            topRightContent={
                <DownloadCSVButton table={model.table} filename={`${model.compound.identifier}-PK`} ignoreSelection />
            }
        >
            <div className='position-relative h-100'>
                <div className='position-absolute w-100 h-100'>
                    <div className='position-relative pt-2'>
                        <PKTableWrapper model={model} />
                    </div>
                    <div className='position-relative mt-4'>
                        <p className='fw-bold'>Physical Chem Properties</p>
                        <PhysicalChemPropertiesTableWrapper table={model.physicalChemPropertiesTable} />
                    </div>
                </div>
            </div>
        </Pane>
    );
}

function PKTableWrapper({ model }: { model: PKModel }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const version = useBehavior(model.table.version);
    const figureOptions = useBehavior(model.state.figureOptions);
    const visibleIC50Traces = useBehavior(model.state.visibleIC50Traces);
    const height = 45 + model.table.store.rowCount * DefaultRowHeight;

    useEffect(() => {
        setSearchParams({
            batch: searchParams.get('batch') ?? '',
            tab: 'pk',
            state: JSON.stringify(model.getQueryState()),
        });
    }, [version, figureOptions, visibleIC50Traces]);

    return <DataTableControl height={height} headerSize='sm' table={model.table} rowSelectionMode='multi' />;
}

function PhysicalChemPropertiesTableWrapper({ table }: { table: DataTableModel<PhysicalChemProperties> }) {
    useBehavior(table.version);
    const height = 45 + table.store.rowCount * DefaultRowHeight;

    return <DataTableControl height={height} headerSize='sm' table={table} rowSelectionMode='none' />;
}

function PlotWrapper({ model }: { model: PKModel }) {
    const figure = useBehavior(model.state.figure);

    return (
        <Plot
            figure={figure}
            modeBarButtons={[['toImage', 'zoomIn2d', 'zoomOut2d', 'resetScale2d']]}
            handleLegendClick={model.onLegendClick}
        />
    );
}

export function PKPage({ model }: { model: PKModel }) {
    const [searchParams, _] = useSearchParams();

    const error = useBehavior(model.state.error);

    useEffect(() => {
        model.loadFromQueryState(JSON.parse(searchParams.get('state') ?? '{}'));
    }, []);

    if (error) {
        return (
            <div className='mt-2'>
                <p>{error}</p>
            </div>
        );
    }

    return (
        <Split direction='vertical' sizes={[0.5, 0.5]}>
            <div className='d-flex flex-column h-100 pb-2'>
                <PlotOptions model={model} />
                <PlotWrapper model={model} />
            </div>
            <PKTablePane model={model} />
        </Split>
    );
}
