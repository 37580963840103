import api from '../../../api';
import { DataTableStore, columnDataTableStoreFromObjects } from '../../../components/DataTable';
import { decodeEntosMsgpack } from '../../../lib/util/serialization';

export interface ChemcartOrderItems {
    order_id: number;
    order_item_id: number;
    number_ordered: number;
    product_name: string;
    supplier: string;
    cas?: string;
    catalog_number: string;
    size: string;
    units: string;
    price?: string;
    currency?: string;
    mdlnumber: string;
    status: string;
    ordered_by: string;
}

export const ChemCartAPI = {
    getChemcartOrder: async (chemart_order_id: number): Promise<DataTableStore<ChemcartOrderItems>> => {
        const { data } = await api.client.get(`chemcart/order/${chemart_order_id}`, {
            responseType: 'arraybuffer',
        });
        return columnDataTableStoreFromObjects(decodeEntosMsgpack(new Uint8Array(data)), [
            'order_id',
            'order_item_id',
            'supplier',
            'cas',
            'catalog_number',
            'number_ordered',
            'product_name',
            'size',
            'units',
            'price',
            'currency',
            'status',
            'ordered_by',
        ]);
    },
    getAllOrders: async (): Promise<DataTableStore<ChemcartOrderItems>> => {
        const { data } = await api.client.get(`chemcart/orders`, { responseType: 'arraybuffer' });

        return columnDataTableStoreFromObjects(decodeEntosMsgpack(new Uint8Array(data)), [
            'order_id',
            'order_item_id',
            'supplier',
            'cas',
            'catalog_number',
            'number_ordered',
            'product_name',
            'size',
            'units',
            'price',
            'currency',
            'status',
            'ordered_by',
        ]);
    },
};
