import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import Split from 'react-split-it';
import { Button, Form } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import useBehavior from '../../../lib/hooks/useBehavior';
import { HTEExperimentModel } from '../experiment-model';

export function ProcedureStep({
    model,
    type = 'procedure',
}: {
    model: HTEExperimentModel;
    type?: 'procedure' | 'observations';
}) {
    const isLocked = useBehavior(model.state.isLocked);
    const [editing, setEditing] = useState(!isLocked);

    return (
        <div className='hte-experiment-procedure-step'>
            <div className='procedure-header ps-4 pe-4 pt-4 pb-2'>
                <h2>
                    {type === 'procedure' && 'Procedure'}
                    {type === 'observations' && 'Observations'}
                    {!isLocked && (
                        <Button variant='outline-primary ms-2' size='sm' onClick={() => setEditing(!editing)}>
                            {!editing && (
                                <>
                                    <FontAwesomeIcon icon={faPenToSquare} /> Edit
                                </>
                            )}
                            {editing && 'Done Editing'}
                        </Button>
                    )}
                </h2>
            </div>

            <div className='hte-experiment-procedure-content'>
                {!editing && (
                    <div className='only-markdown m-auto pe-2'>
                        {type === 'procedure' && <ProcedureMarkdown model={model} />}
                        {type === 'observations' && <ObservationMarkdown model={model} />}
                    </div>
                )}

                {editing && (
                    <div className='edit-markdown'>
                        {type === 'procedure' && (
                            <Split>
                                <ProcedureEdit model={model} />
                                <div className='preview-markdown ps-2 pe-2'>
                                    <ProcedureMarkdown model={model} />
                                </div>
                            </Split>
                        )}
                        {type === 'observations' && (
                            <Split>
                                <ObservationEdit model={model} />
                                <div className='preview-markdown ps-2 pe-2'>
                                    <ObservationMarkdown model={model} />
                                </div>
                            </Split>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

function UsesMarkdown() {
    return (
        <div className='uses-markdown'>
            Uses Markdown. See a{' '}
            <a href='https://www.markdownguide.org/basic-syntax/' target='_blank' rel='noreferrer'>
                guide
            </a>
            .
        </div>
    );
}

function ProcedureEdit({ model }: { model: HTEExperimentModel }) {
    const procedure = useBehavior(model.state.procedure);

    return (
        <div className='w-100 h-100'>
            <Form.Control
                as='textarea'
                autoFocus
                value={procedure ?? ''}
                onChange={(e) => model.state.procedure.next(e.target.value)}
            />
            <UsesMarkdown />
        </div>
    );
}

function ProcedureMarkdown({ model }: { model: HTEExperimentModel }) {
    const procedure = useBehavior(model.state.procedure);

    if (!procedure) return <div className='text-secondary'>No procedure specified.</div>;

    return <ReactMarkdown>{procedure}</ReactMarkdown>;
}

function ObservationEdit({ model }: { model: HTEExperimentModel }) {
    const observations = useBehavior(model.state.observations);

    return (
        <div className='w-100 h-100'>
            <Form.Control
                as='textarea'
                autoFocus
                value={observations ?? ''}
                onChange={(e) => model.state.observations.next(e.target.value)}
            />
            <UsesMarkdown />
        </div>
    );
}

function ObservationMarkdown({ model }: { model: HTEExperimentModel }) {
    const observations = useBehavior(model.state.observations);

    if (!observations) return <div className='text-secondary'>No observations specified.</div>;

    return <ReactMarkdown>{observations}</ReactMarkdown>;
}
