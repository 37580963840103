import type { Chart } from 'chart.js';

interface PluginHandler {
    handler: (event: Event) => void;
    target: Node;
}

export class EventPlugin {
    public chart: Chart | null = null;
    private handlers: Record<string, PluginHandler> = {};

    hasHandler(type: string) {
        return !!this.handlers[type];
    }

    removeListeners() {
        this.removeHandler('mousedown');
        this.removeHandler('mousemove');
        this.removeHandler('mouseup');
        this.removeHandler('wheel');
        this.removeHandler('click');
        this.removeHandler('keydown');
    }

    addHandler(target: Node, type: string, handler: PluginHandler['handler']) {
        const oldHandler = this.handlers[type];
        if (oldHandler && oldHandler.target === target) return;

        this.removeHandler(type);
        this.handlers[type] = {
            handler: (event) => handler.call(this, event),
            target,
        };
        target.addEventListener(type, this.handlers[type].handler);
    }

    removeHandler(type: string) {
        const handler = this.handlers[type];
        if (handler?.target) {
            handler.target.removeEventListener(type, handler.handler);
            delete this.handlers[type];
        }
    }
}
