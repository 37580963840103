/* eslint-disable no-unsafe-optional-chaining */
import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { MouseEventHandler } from 'react';
import { BehaviorSubject } from 'rxjs';
import { type ECMRSRequestModel } from '.';
import {
    ColumnsFor,
    DataTableControl,
    DataTableModel,
    DefaultRowHeight,
    columnDataTableStoreFromObjects,
} from '../../../../components/DataTable';
import { SmilesColumn } from '../../../../components/DataTable/common';
import { AsyncButton } from '../../../../components/common/AsyncButton';
import { IconButton } from '../../../../components/common/IconButton';
import { ErrorMessage } from '../../../../components/common/Error';
import Loading from '../../../../components/common/Loading';
import { useAsyncAction } from '../../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../../lib/hooks/useBehavior';
import { DialogService } from '../../../../lib/services/dialog';
import { arrayToCsv, objectsToRowArrays } from '../../../../lib/util/arrayToCsv';
import { DateLike, formatDatetime } from '../../../../lib/util/dates';
import { tryGetErrorMessage } from '../../../../lib/util/errors';
import { arrayMapAdd, groupBy } from '../../../../lib/util/misc';
import { getUnitFormatter, prefixedUnitValue } from '../../../../lib/util/units';
import { Sample, SampleContents } from '../../../Compounds/compound-api';
import {
    ECMSearchResult,
    formatSampleContentInSearch,
    isInventorySearchResult,
    isLabwareAtEntosSite,
    isSampleEmpty,
    isTubeBarcode,
} from '../../ecm-api';
import { BatchLink } from '../../ecm-common';
import { ECMRequestData } from '../api';
import { ARPTemplateInfo, getARPDilutionCurveInfo } from '../common';
import {
    ARP_DEFAULT_NARP_DEAD_VOLUME_L,
    ECMARPTemplateControlKind,
    ECMControlBatch,
    ECMRequestedBatch,
    ECMRequestedSample,
} from '../data';
import { ECMRequestAssets } from './assets';

type ValidationEntry = ['info' | 'warning', string];

export interface ECMRequestedBatchRow {
    index: number;
    id: number;
    group_key: string;
    is_control: boolean;
    control_kind: ECMARPTemplateControlKind | undefined;
    identifiers: string;
    identifier: string;
    sample: ECMRequestedSample;
    vial_sample?: Sample;
    total: ECMRequestedSample;
    validation: ValidationEntry[];
    validation_lookup: string;
    comment: string;
    original: ECMRequestedBatch;
    concurrent: { sample: ECMRequestedSample; request_id: number }[];
    inventory: ECMSearchResult[];
    created_by: string;
    created_on: DateLike;
}

export function prepareRequestData(
    data: ECMRequestData,
    { assets, templateInfo }: { assets: ECMRequestAssets; templateInfo: ARPTemplateInfo | undefined }
) {
    data.request_batches.sort((a, b) => a.order - b.order);

    assets.requests.set(data.request.id, data.request);
    for (const r of data.related_requests) assets.requests.set(r.id, r);

    for (const b of data.request_batches) assets.requestedBatches.set(b.id, b);
    for (const b of data.related_batches) assets.requestedBatches.set(b.id, b);

    const { validation, grandTotals, controls, groupedByConcentrationAndSolvent } = validateBatches(data, {
        assets,
        templateInfo,
    });
    const relatedGroups = groupBy(data.related_batches, (b) => b.identifier);

    const getValidationLookup = (b: ECMRequestedBatch) => {
        const components = [];
        for (const v of validation.get(b.id) ?? []) {
            components.push(v[1]);
        }
        return components.join('\n');
    };

    const getControlKind = (index: number) => {
        if (index >= controls.current_identifiers.size) return undefined;
        return templateInfo?.controlKinds[controls.requestIndexToBucketIndex.get(index)!] ?? 'unknown';
    };

    const rows = data.request_batches.map(
        (b, i) =>
            ({
                index: i,
                id: b.id,
                group_key: getConcSolventKey(b),
                is_control: controls.current_identifiers.has(b.identifier),
                control_kind: getControlKind(i),
                identifiers: `${b.identifier}\n${assets.getIdentifier(b.identifier)}\n${b.barcode ?? ''}`,
                identifier: b.identifier,
                sample: b.sample,
                vial_sample: b.barcode
                    ? assets.inventory.get(b.identifier)?.find((r) => r.barcode === b.barcode)?.sample
                    : undefined,
                total: grandTotals.get(getConcSolventKey(b))!,
                validation: validation.get(b.id) ?? [],
                validation_lookup: getValidationLookup(b),
                comment: b.comment,
                concurrent: getConcurrentRequests(relatedGroups.get(b.identifier), assets),
                inventory:
                    assets.inventory
                        .get(b.identifier)
                        ?.filter((e) => {
                            if (!data.request.arp_conditions) return isInventorySearchResult(e);
                            // exclude vial stock from inventory for ARP requests
                            return isARPInventory(e);
                        })
                        ?.sort(requestedInventoryComparer(b.sample)) ?? [],
                created_by: b.created_by,
                created_on: b.created_on,
                original: b,
            } satisfies ECMRequestedBatchRow)
    );

    const inventory = getAllInventory(
        data,
        groupedByConcentrationAndSolvent,
        { grandTotals, controls: controls.bucket_identifiers },
        assets
    );

    return {
        rows,
        inventory: inventory.all,
        inventoryPerGroup: inventory.perGroup,
        missingInventory: inventory.missing,
    };
}

function isARPInventory(e: ECMSearchResult) {
    const is_nARP = e.well && e.status === 'Inventory' && e.plate_purpose === 'nARP';
    return (isInventorySearchResult(e) || is_nARP) && (e.well || isTubeBarcode(e.barcode) || !e.sample?.concentration);
}

function getConcSolventKey(b: ECMRequestedBatch) {
    return `${b.identifier}:${b.sample.concentration_M ?? ''}:${b.sample.solvent?.toLowerCase() ?? ''}`;
}

function numberOfUses(data: ECMRequestData, templateInfo: ARPTemplateInfo | undefined, controlIndex?: number) {
    const conditions = data.request.arp_conditions;
    if (!conditions) return 1;

    const nCopies = conditions.number_of_copies;
    let nReplicates = 1;
    if (templateInfo) {
        if (typeof controlIndex === 'number') nReplicates = templateInfo.nControlReplicates[controlIndex] ?? 1;
        else nReplicates = templateInfo.nReplicates;
    } else if (typeof controlIndex !== 'number') {
        nReplicates = conditions.number_of_replicates;
    }

    const plateCount = calculatePlateCount(data, templateInfo);
    const nPlates = plateCount ? plateCount.numPlates || 1 : 1;

    if (typeof controlIndex === 'number') {
        return nCopies * nReplicates * nPlates;
    }

    return nCopies * nReplicates;
}

export function calculatePlateCount(data: ECMRequestData, templateInfo?: ARPTemplateInfo) {
    if (data.request.kind !== 'arp') return null;

    let count = 0;
    for (const b of data.request_batches) {
        if (!b.is_rejected) count++;
    }

    const conditions = data.request.arp_conditions!;
    const controlBatchCount = conditions?.control_batches.filter((c) => !!c.identifier).length;
    count -= controlBatchCount;

    const plateCapacity = templateInfo?.nSamples;
    if (!plateCapacity) return null;

    const numPlates = Math.ceil(count / plateCapacity);
    const numRemaining = count ? plateCapacity * numPlates - count : plateCapacity;
    return { numPlates, numRemaining };
}

function calculateGrandTotals(
    data: ECMRequestData,
    {
        templateInfo,
        totalsByConc,
        controls,
        concSolventGroups,
    }: {
        templateInfo?: ARPTemplateInfo;
        totalsByConc: Map<string, ECMRequestedSample>;
        controls: ECMRSControlsInfo;
        concSolventGroups: Map<string, ECMRequestedBatch[]>;
    }
) {
    const grandTotals = new Map<string, ECMRequestedSample>();

    const conditions = data.request.arp_conditions;
    if (!conditions?.dilution_curve || !conditions?.template) {
        for (const b of data.request_batches) {
            const key = getConcSolventKey(b);
            const total = totalsByConc.get(key)!;
            grandTotals.set(key, total);
        }
        return grandTotals;
    }

    // With dilution curve and template available, we can
    // calculate a much more accurate usage estimate
    const nUsesDefault = numberOfUses(data, templateInfo);
    const mainCurveInfo = getARPDilutionCurveInfo(conditions.dilution_curve);

    for (let index = 0; index < data.request_batches.length; index++) {
        const b = data.request_batches[index];

        let curveInfo = mainCurveInfo;

        let nUses = nUsesDefault;
        if (index < controls.bucket_identifiers.size) {
            const controlIndex = controls.requestIndexToBucketIndex.get(index)!;
            nUses = numberOfUses(data, templateInfo, controlIndex);
            const controlCurve = conditions.control_batches[controlIndex]?.dilution_curve;
            if (controlCurve) {
                curveInfo = getARPDilutionCurveInfo(controlCurve);
            }
        }

        const key = getConcSolventKey(b);
        const baseTotal = totalsByConc.get(key)!;

        let nDuplicates = 0;
        for (const d of concSolventGroups.get(key)!) {
            if (!d.is_rejected) nDuplicates++;
        }

        grandTotals.set(key, {
            volume_l: nDuplicates > 0 ? curveInfo.intermediateUsageL + nUses * nDuplicates * curveInfo.arpUsageL : 0,
            concentration_M: baseTotal.concentration_M,
        });
    }

    return grandTotals;
}

function getAllInventory(
    data: ECMRequestData,
    groupedByConcentration: Map<string, ECMRequestedBatch[]>,
    { grandTotals, controls }: { grandTotals: Map<string, ECMRequestedSample>; controls: Set<string> },
    assets: ECMRequestAssets
) {
    const all: ECMSearchResult[] = [];
    const perGroup: Map<string, ECMSearchResult[]> = new Map();
    const missing: string[] = [];
    for (const [groupKey, batches] of Array.from(groupedByConcentration.entries())) {
        const { identifier } = batches[0];

        const inventory = assets.inventory.get(identifier);
        if (!inventory) {
            missing.push(identifier);
            continue;
        }

        const batch = assets.entities.get(identifier);
        if (!batch) {
            missing.push(identifier);
            console.warn('Missing batch, likely deleted from Foundry', identifier);
            continue;
        }

        const sample = grandTotals.get(groupKey) ?? batches[0].sample;
        const src = data.request.arp_conditions
            ? inventory.filter(isARPInventory)
            : inventory.filter(isInventorySearchResult);

        const sorted = src.sort(requestedInventoryComparer(sample));
        const total_requested: SampleContents = {
            solute_mass: sample.amount_g,
            concentration: sample.concentration_M,
            solvent: sample.solvent as any,
            solvent_volume: typeof sample.volume_l === 'number' ? sample.volume_l * 1e-3 : undefined,
        };
        const total_requested_with_mass: SampleContents = {
            solute_mass: getRequestedAmount(batch.formula_weight, sample),
            concentration: sample.concentration_M,
            solvent: sample.solvent as any,
            solvent_volume: typeof sample.volume_l === 'number' ? sample.volume_l * 1e-3 : undefined,
        };
        for (let i = 0; i < sorted.length; i++) {
            sorted[i] = {
                ...sorted[i],
                total_requested:
                    typeof sorted[i].sample?.solvent_volume === 'number' ? total_requested : total_requested_with_mass,
            };
        }

        assignBestInventory({
            sortedResults: sorted,
            identifier: assets.getIdentifier(batch.universal_identifier!),
            sample: total_requested_with_mass,
        });

        for (const e of sorted) all.push(e);
        perGroup.set(groupKey, sorted);
    }
    return { all, perGroup, missing };
}

function compareIsSearchResult(a: ECMSearchResult, b: ECMSearchResult) {
    const isA = isInventorySearchResult(a);
    const isB = isInventorySearchResult(b);
    if (isA && !isB) return -1;
    if (!isA && isB) return 1;
    return 0;
}

export function requestedInventoryComparer(sample: ECMRequestedSample) {
    const { concentration_M } = sample;

    if (!concentration_M) {
        return (a: ECMSearchResult, b: ECMSearchResult) => {
            const checkInv = compareIsSearchResult(a, b);
            if (checkInv) return checkInv;

            const cA = typeof a.sample?.concentration === 'number';
            const cB = typeof b.sample?.concentration === 'number';
            if (cA && cB) return b.sample?.solute_mass! - a.sample?.solute_mass!;
            if (cA) return 1;
            if (cB) return -1;
            return b.sample?.solute_mass! - a.sample?.solute_mass!;
        };
    }

    const baseSolvent = sample.solvent?.toLowerCase() ?? 'dmso';

    return (a: ECMSearchResult, b: ECMSearchResult) => {
        const checkInv = compareIsSearchResult(a, b);
        if (checkInv) return checkInv;

        const cA = typeof a.sample?.concentration === 'number';
        const cB = typeof b.sample?.concentration === 'number';
        if (cA && cB) {
            const solventA = baseSolvent === (a.sample!.solvent?.toLowerCase() ?? 'dmso');
            const solventB = baseSolvent === (b.sample!.solvent?.toLowerCase() ?? 'dmso');
            if (solventA && !solventB) return -1;
            if (!solventA && solventB) return 1;

            const tubeA = !a.well && isTubeBarcode(a.barcode);
            const tubeB = !b.well && isTubeBarcode(b.barcode);

            const concA = Math.abs(a.sample!.concentration! - concentration_M) < 1e-6;
            const concB = Math.abs(b.sample!.concentration! - concentration_M) < 1e-6;

            if (tubeA && !tubeB && concA) return -1;
            if (!tubeA && tubeB && concB) return 1;

            if (concA && concB) return b.sample?.solvent_volume! - a.sample?.solvent_volume!;

            const lessA = a.sample!.concentration! < concentration_M;
            const lessB = b.sample!.concentration! < concentration_M;
            if (lessA && lessB) return b.sample?.solvent_volume! - a.sample?.solvent_volume!;
            if (lessA) return 1;
            return -1;
        }
        if (cA) return -1;
        if (cB) return 1;
        return b.sample?.solute_mass! - a.sample?.solute_mass!;
    };
}

export interface ECMRSControlsInfo {
    bucket_identifiers: Set<string>;
    current_identifiers: Set<string>;
    total_count: number;
    requestIndexToBucketIndex: Map<number, number>;
    bucketIndexToRequestIndex: Map<number, number>;
}

export function getNonSolventControlsMap(data: ECMRequestData): ECMRSControlsInfo {
    const conditions = data.request.arp_conditions;
    const requestIndexToBucketIndex = new Map<number, number>();

    if (!conditions)
        return {
            bucket_identifiers: new Set(),
            current_identifiers: new Set(),
            total_count: 0,
            requestIndexToBucketIndex,
            bucketIndexToRequestIndex: new Map(),
        };

    const indentifierIndices = conditions.control_batches
        .map((c, i) => [c, i] as [ECMControlBatch, number])
        .filter((c) => !!c[0].identifier)
        .map((c, i) => [c[1], i] as const);

    const bucket_identifiers = new Set(
        conditions.control_batches.filter((b) => !!b.identifier).map((b) => b.identifier!)
    );
    const current_identifiers = new Set<string>();
    for (let i = 0; i < data.request_batches.length; i++) {
        if (i < bucket_identifiers.size) {
            requestIndexToBucketIndex.set(i, indentifierIndices[i][0]);
            current_identifiers.add(data.request_batches[i].identifier);
        }
    }

    return {
        bucket_identifiers,
        current_identifiers,
        total_count: conditions.control_batches.length,
        requestIndexToBucketIndex,
        bucketIndexToRequestIndex: new Map(indentifierIndices),
    };
}

// mutates the sortedResults array
export function assignBestInventory({
    sortedResults,
    identifier,
    sample,
}: {
    sortedResults: ECMSearchResult[];
    identifier: string;
    sample: SampleContents;
}) {
    if (typeof sample.solute_mass !== 'number') throw new Error('Invalid sample, must define solute_mass');

    const bestInventory = findBestInventory(sortedResults, sample);
    const index = sortedResults.findIndex((r) => r === bestInventory);

    if (index >= 0) {
        // eslint-disable-next-line no-param-reassign
        sortedResults[index].wizard = true;
    } else {
        sortedResults.push({
            schema_name: 'vial',
            wizard: true,
            id: -1,
            barcode: '',
            batch_identifier: identifier,
            batch_purity: 0,
            created_by: '',
            created_on: new Date(),
            sample: undefined,
            location: '' as any,
            well: '',
            modified_on: new Date(),
            plate_purpose: '',
            plate_description: '',
            status: '' as any,
            tare_mass: 0,
            project: '',
            total_requested: sample,
        });
    }
}

function getRequestedAmount(fw: number, sample: ECMRequestedSample) {
    if (typeof sample.amount_g === 'number') return sample.amount_g;
    return sample.volume_l! * sample.concentration_M! * fw;
}

function findBestInventory(sortedResults: ECMSearchResult[], sample: SampleContents) {
    const inventoryResults = sortedResults.filter((r) => isInventorySearchResult(r));

    if (typeof sample.concentration === 'number' && typeof sample.solvent_volume === 'number') {
        return findBestWetInventory(inventoryResults, sample);
    }
    if (typeof sample.solute_mass === 'number') {
        return findBestDryInventory(inventoryResults, sample);
    }
    return undefined;
}

function findBestWetInventory(sortedResults: ECMSearchResult[], sample: SampleContents) {
    const volume = sample.solvent_volume!;
    // const conc = sample.concentration!;
    const solvent = sample.solvent?.toLowerCase() ?? 'dmso';

    const amountTolerance = 0.01; // 1% tolerance
    const concTolerance = 0.05; // 5% tolerance

    const satisfyWet = (r: ECMSearchResult, maxConcTolerance?: number) => {
        if (!r.sample) return false;
        if (typeof r.sample.solvent_volume !== 'number') return false;
        if ((r.sample.solvent?.toLowerCase() ?? 'dmso') !== solvent) return false;
        if (r.sample.solvent_volume < volume * (1 - amountTolerance)) return false;
        const min = sample.concentration! * (1 - concTolerance);
        if (r.sample.concentration! < min) return false;
        if (typeof maxConcTolerance !== 'number') return true;
        const max = sample.concentration! * (1 + concTolerance + maxConcTolerance);
        return r.sample!.concentration! >= min && r.sample!.concentration! <= max;
    };

    // 1: find best tube by exact concentration
    for (const r of sortedResults) {
        if (r.well || !isTubeBarcode(r.barcode)) continue;
        if (satisfyWet(r, 0)) return r;
    }

    // 2: find best tube with greater concentration
    // tubes are sorted by concentration, so return the first one
    // that matches the conditions
    for (const r of sortedResults) {
        if (r.well || !isTubeBarcode(r.barcode)) continue;
        if (satisfyWet(r)) return r;
    }

    // 3: find best dry tube
    // This step is separate from non-Tube vials for ARP automation purposes
    for (const r of sortedResults) {
        if (!r.sample || r.well || !isTubeBarcode(r.barcode)) continue;
        if (typeof r.sample.solvent_volume === 'number' || typeof r.sample.solute_mass !== 'number') continue;
        if (r.sample.solute_mass < sample.solute_mass! * (1 - amountTolerance)) continue;

        return r;
    }

    // 4: find best dry vial
    for (const r of sortedResults) {
        if (!r.sample || r.well) continue;
        if (typeof r.sample.solvent_volume === 'number' || typeof r.sample.solute_mass !== 'number') continue;
        if (r.sample.solute_mass < sample.solute_mass! * (1 - amountTolerance)) continue;

        return r;
    }

    // 4: find best plate inventory
    for (const r of sortedResults) {
        if (!r.sample || !r.well) continue;
        if (satisfyWet(r, 0)) return r;
    }
}

function findBestDryInventory(sortedResults: ECMSearchResult[], sample: SampleContents) {
    const amountTolerance = 0.01; // 1% tolerance

    for (const r of sortedResults) {
        if (!r.sample || r.well) continue;
        if (typeof r.sample.solvent_volume === 'number' || typeof r.sample.solute_mass !== 'number') continue;
        if (r.sample.solute_mass < sample.solute_mass! * (1 - amountTolerance)) continue;

        return r;
    }
}

function getConcurrentRequests(related: ECMRequestedBatch[] | undefined, assets: ECMRequestAssets) {
    const ret: ECMRequestedBatchRow['concurrent'] = [];
    if (!related) return ret;

    for (const b of related) {
        const req = assets.requests.get(b.request_id);
        if (!req?.status || req.status === 'submitted' || req.status === 'closed') continue;
        ret.push({ sample: b.sample, request_id: b.request_id });
    }
    return ret;
}

const ECMRSBatchValidations = {
    duplicateBatch: 'Duplicate batch',
    duplicateCompound: 'Duplicate compound',
};

export function isDuplicateValidation(validation: ValidationEntry) {
    return (
        validation[1] === ECMRSBatchValidations.duplicateBatch ||
        validation[1] === ECMRSBatchValidations.duplicateCompound
    );
}

export function isWarningValidation(validation: ValidationEntry) {
    return validation[0] === 'warning';
}

function validateBatches(
    data: ECMRequestData,
    { assets, templateInfo }: { assets: ECMRequestAssets; templateInfo: ARPTemplateInfo | undefined }
) {
    const controls = getNonSolventControlsMap(data);
    const batches = data.request_batches;
    const validation = new Map<number, ValidationEntry[]>();
    const baseTotalsByConc = new Map<string, ECMRequestedSample>();
    const identifierGroups = groupBy(batches, (b) => b.identifier);
    const concSolventGroups = groupBy(batches, getConcSolventKey);

    const batchDuplicates = new Set<number>();

    // Validate duplicates & compute total samples
    identifierGroups.forEach((g) => {
        if (g.length <= 1) return;

        for (const b of g) {
            batchDuplicates.add(b.id);
            validation.set(b.id, [['info', ECMRSBatchValidations.duplicateBatch]]);
        }
    });

    concSolventGroups.forEach((g, key) => {
        const total: ECMRequestedSample = {
            amount_g: 0,
            volume_l: 0,
            solvent: g[0].sample.solvent,
            concentration_M: g[0].sample.concentration_M,
        };
        for (const b of g) {
            if (b.is_rejected) continue;

            total.amount_g! += b.sample.amount_g ?? 0;
            total.volume_l! += b.sample.volume_l ?? 0;
        }
        total.amount_g = total.amount_g || undefined;
        total.volume_l = total.volume_l || undefined;
        baseTotalsByConc.set(key, total);
    });

    const compoundGroups = groupBy(batches, (b) => assets.entities.get(b.identifier)?.compound_id);
    compoundGroups.forEach((g, key) => {
        if (g.length <= 1 || typeof key !== 'number') return;

        for (const b of g) {
            if (batchDuplicates.has(b.id)) continue;
            arrayMapAdd(validation, b.id, ['info', ECMRSBatchValidations.duplicateCompound]);
        }
    });

    const grandTotals = calculateGrandTotals(data, {
        templateInfo,
        totalsByConc: baseTotalsByConc,
        concSolventGroups,
        controls,
    });

    const nARPVolume = data.request.arp_conditions?.narp_volume_l;
    const nARPDeadVolume = data.request.arp_conditions?.narp_dead_volume_l ?? ARP_DEFAULT_NARP_DEAD_VOLUME_L;

    // Validate inventory
    for (const b of batches) {
        const inventory = assets.inventory.get(b.identifier)?.filter(isInventorySearchResult);

        if (typeof b.sample.volume_l === 'number' && nARPVolume) {
            const grandTotal = grandTotals.get(getConcSolventKey(b))!;
            if (grandTotal.volume_l! > nARPVolume - nARPDeadVolume) {
                const diff = grandTotal.volume_l! - (nARPVolume - nARPDeadVolume);
                arrayMapAdd(validation, b.id, [
                    'warning',
                    `Total volume exceeds available nARP volume by ${prefixedUnitValue(diff, 'L')}`,
                ]);
            }
        }

        if (!inventory?.length) {
            arrayMapAdd(validation, b.id, ['warning', 'No inventory found']);
        } else if (inventory.every((e) => isSampleEmpty(e.sample))) {
            arrayMapAdd(validation, b.id, ['info', 'Empty/unknown inventory']);
        } else if (typeof b.sample.volume_l === 'number') {
            const wetInventory = inventory.filter((e) => typeof e.sample?.solvent_volume === 'number');

            if (nARPVolume) {
                const grandTotal = grandTotals.get(getConcSolventKey(b))!;
                if (grandTotal.volume_l! > nARPVolume) {
                    arrayMapAdd(validation, b.id, ['warning', 'Total volume exceeds nARP volume']);
                }
            }

            if (wetInventory.length === 0) {
                arrayMapAdd(validation, b.id, ['warning', 'No wet inventory available']);
            }

            if (
                wetInventory.length > 0 &&
                wetInventory.every((e) => (e.sample?.solvent_volume ?? 0) * 1e3 < b.sample.volume_l!)
            ) {
                // TODO: shall we consider concentration as well?
                arrayMapAdd(validation, b.id, ['info', 'Possibly low wet inventory / will deplete stock']);
            }

            const concTolerance = 0.01; // 1% tolerance
            const conc = b.sample.concentration_M!;
            const solv = b.sample.solvent?.toLowerCase() ?? 'dmso';

            if (wetInventory.length > 0) {
                let hasConcentration = false;
                let hasMinConcentration = false;
                let minGreaterConc = Number.MAX_VALUE;
                let hasConcentrationVolume = false;
                let hasSolvent = false;

                for (const e of wetInventory) {
                    if ((e.sample?.solvent?.toLowerCase() ?? 'dmso') === solv) {
                        hasSolvent = true;
                    }

                    const min = e.sample!.concentration! * (1 - concTolerance);
                    const max = e.sample!.concentration! * (1 + concTolerance);
                    if (max >= conc) {
                        const ratio = conc / e.sample!.concentration!;
                        if (ratio * e.sample?.solvent_volume! * 1e3 >= b.sample.volume_l! * 0.99) {
                            hasMinConcentration = true;
                            minGreaterConc = Math.min(minGreaterConc, e.sample!.concentration!);
                        }
                    }
                    if (conc < min || conc > max) continue;

                    hasConcentration = true;
                    if (e.sample?.solvent_volume! * 1e3 >= b.sample.volume_l! * 0.99) {
                        hasConcentrationVolume = true;
                    }

                    if ((e.sample?.solvent?.toLowerCase() ?? 'dmso') === solv) {
                        hasSolvent = true;
                    }
                }

                if (!hasConcentrationVolume && hasConcentration) {
                    arrayMapAdd(validation, b.id, ['warning', 'Possible low inventory at requested concentration']);
                } else if (!hasConcentration) {
                    if (hasMinConcentration) {
                        arrayMapAdd(validation, b.id, [
                            'info',
                            `Closest available concentration @${prefixedUnitValue(minGreaterConc, 'M')}`,
                        ]);
                    } else {
                        arrayMapAdd(validation, b.id, ['warning', 'No inventory at requested concentration']);
                    }
                }

                if (!hasSolvent) {
                    arrayMapAdd(validation, b.id, ['warning', 'No inventory with requested solvent']);
                }
            }
        } else if (typeof b.sample.amount_g === 'number') {
            const dryInventory = inventory.filter((e) => typeof e.sample?.solvent_volume !== 'number');

            if (dryInventory.length === 0) {
                arrayMapAdd(validation, b.id, ['warning', 'No dry inventory available']);
            } else if (dryInventory.every((e) => e.sample?.solute_mass! < b.sample.amount_g!)) {
                arrayMapAdd(validation, b.id, ['info', 'Possibly low dry inventory / will deplete stock']);
            }
        }

        if (inventory?.length && !inventory.some(isLabwareAtEntosSite)) {
            arrayMapAdd(validation, b.id, ['info', 'Not available at Entos']);
        }
    }

    return { validation, grandTotals, controls, groupedByConcentrationAndSolvent: concSolventGroups };
}

const RequestedBatchesCSVColumns = [
    'Identifier',
    'Barcode',
    'Amount',
    'Amount Unit',
    'Volume',
    'Volume Unit',
    'Concentration',
    'Concentration Unit',
    'Solvent',
    'Is Control',
    'Comment',
    'Requested By',
    'Requested On',
] as const;

type RequestedBatchCSVRow = Record<(typeof RequestedBatchesCSVColumns)[number], string | number>;

export function requestBatchesToCSV(data: ECMRequestData, assets: ECMRequestAssets) {
    const rows: RequestedBatchCSVRow[] = [];

    const { formatCSV: formatAmount, csvUnit: amountUnit } = getUnitFormatter('g', 'm');
    const { formatCSV: formatVolume, csvUnit: volumeUnit } = getUnitFormatter('L', 'u');
    const { formatCSV: formatConcentration, csvUnit: concentrationUnit } = getUnitFormatter('M', 'm');

    const controls = getNonSolventControlsMap(data);

    let index = 0;
    for (const b of data.request_batches) {
        rows.push({
            Identifier: assets.getIdentifier(b.identifier),
            Barcode: b.barcode ?? '',
            Amount: formatAmount(b.sample.amount_g),
            'Amount Unit': amountUnit,
            Volume: formatVolume(b.sample.volume_l),
            'Volume Unit': volumeUnit,
            Concentration: formatConcentration(b.sample.concentration_M),
            'Concentration Unit': concentrationUnit,
            Solvent: b.sample.solvent ?? '',
            'Is Control': index < controls.bucket_identifiers.size ? 'Yes' : 'No',
            Comment: b.comment,
            'Requested By': assets.users.get(b.created_by)?.email ?? '?',
            'Requested On': formatDatetime(b.created_on, 'full'),
        });
        index++;
    }

    const csvRows = objectsToRowArrays(rows, RequestedBatchesCSVColumns);
    return arrayToCsv(csvRows);
}

interface ReplaceBatchRow {
    identifier: string;
    inventory: ECMSearchResult[];
}

class ReplaceBatchModel {
    state = {
        loading: new BehaviorSubject<{ isLoading: boolean; error?: string; empty?: boolean }>({ isLoading: true }),
    };

    table?: DataTableModel<ReplaceBatchRow> = undefined;

    ReplaceSchema: ColumnsFor<ReplaceBatchRow> = {
        identifier: {
            ...SmilesColumn(this.request.drawer, 2.5, {
                width: 160,
                getIdentifierElement: ({ rowIndex, table, showSMILES }) => (
                    <span className={showSMILES ? 'font-body-xsmall' : undefined}>
                        <BatchLink
                            identifier={this.request.assets.getIdentifier(table.store.getValue('identifier', rowIndex))}
                        />
                    </span>
                ),
                identifierPadding: 20,
                getSMILES: (identifier) => this.request.assets.getStructure(identifier) ?? '',
                hideToggle: true,
                disableChemDraw: true,
                header: 'Identifier',
            }),
            disableGlobalFilter: true,
        },
        inventory: {
            kind: 'generic',
            format: () => '<unused>',
            render: ({ value }) => (
                <div className='ecm-request-batch-scroll-cell'>
                    <div>
                        {value.map((s, i) => (
                            <div key={i}>
                                {formatSampleContentInSearch(s.sample)} in {s.barcode}
                                {s.well ? `@${s.well}` : undefined}
                            </div>
                        ))}
                        {!value.length && <span className='text-secondary'>-</span>}
                    </div>
                </div>
            ),
            width: 300,
            compare: false,
            disableGlobalFilter: true,
        },
    };

    async init(options?: { initialBatchIdentifier?: string }) {
        try {
            const batch = this.request.assets.entities.get(this.batch.identifier);
            if (!batch) return;

            const others = this.request.assets.batchesByCompoundId.get(batch.compound_id)!;

            if (others.length <= 1) {
                this.state.loading.next({ isLoading: false, empty: true });
                return;
            }

            await this.request.assets.syncInventory(others.map((b) => b.universal_identifier!));

            others.sort((a, b) => a.id - b.id);
            const comparer = requestedInventoryComparer(this.batch.sample);
            const rows: ReplaceBatchRow[] = others.map((r) => ({
                identifier: r.universal_identifier!,
                inventory: (
                    this.request.assets.inventory.get(r.universal_identifier!)?.filter(isInventorySearchResult) ?? []
                ).sort(comparer),
            }));
            const store = columnDataTableStoreFromObjects<ReplaceBatchRow>(rows, ['identifier', 'inventory']);
            this.table = new DataTableModel(store, {
                columns: this.ReplaceSchema,
                hideNonSchemaColumns: true,
            });
            this.table.setCustomState({ 'show-smiles': true });
            this.table.setRowHeight(DefaultRowHeight * 2.5);

            let rowIndex = -1;
            if (options?.initialBatchIdentifier) {
                rowIndex = rows.findIndex((r) => r.identifier === options.initialBatchIdentifier);
            }
            if (rowIndex < 0) {
                rowIndex = rows.findIndex((r) => r.identifier === this.batch.identifier);
            }
            if (rowIndex >= 0) this.table.setSelection([rowIndex], true);

            this.state.loading.next({ isLoading: false });
        } catch (err) {
            this.state.loading.next({ isLoading: false, error: tryGetErrorMessage(err) });
        }
    }

    get currentIdentifier() {
        if (!this.table) return undefined;

        const selection = Object.keys(this.table!.selectedRows)[0];
        if (!selection) return undefined;

        return this.table.store.getValue('identifier', +selection);
    }

    constructor(
        public request: ECMRSRequestModel,
        public batch: ECMRequestedBatch,
        options?: { initialBatchIdentifier?: string }
    ) {
        this.init(options);
    }
}

export function ReplaceBatchButton({
    batch,
    model,
    initialBatchIdentifier,
}: {
    batch: ECMRequestedBatch;
    model: ECMRSRequestModel;
    initialBatchIdentifier?: string;
}) {
    const onReplace: MouseEventHandler = (e) => {
        e.stopPropagation();

        if (batch.barcode) {
            DialogService.open({
                type: 'confirm',
                onConfirm: () => {},
                title: 'Replace',
                text: (
                    <>
                        <p>Cannot replace a batch with assigned barcode.</p>
                        {model.isManagement && (
                            <p>
                                Barcode can be unassigned in the <b>Transfer</b> tab.
                            </p>
                        )}
                    </>
                ),
                confirmText: 'OK',
            });
            return;
        }

        const replaceModel = new ReplaceBatchModel(model, batch, { initialBatchIdentifier });
        DialogService.open({
            type: 'generic',
            title: 'Replace Batch',
            model: replaceModel,
            defaultState: undefined,
            content: ReplaceBatchDialogContent,
            footer: ReplaceBatchDialogFooter,
            options: { size: 'lg' },
            doNotAutoClose: true,
            onOk: () => {
                const identifier = replaceModel.currentIdentifier;
                if (!identifier) return;
                return model.replaceBatch(batch.id, identifier);
            },
        });
    };

    return (
        <IconButton
            icon={faArrowRightArrowLeft}
            onClick={onReplace}
            title={batch.barcode ? 'Cannot replace when barcode is set' : 'Replace'}
        />
    );
}

function ReplaceBatchDialogContent({ model }: { model: ReplaceBatchModel }) {
    const { isLoading, error, empty } = useBehavior(model.state.loading);

    if (isLoading) return <Loading />;
    if (error) return <ErrorMessage message={error} inline />;
    if (empty) {
        return <div className='w-100 text-center text-secondary p-4'>No other batches found</div>;
    }
    if (!model.table) return null;

    return (
        <div className='ecm-requests-table-wrapper'>
            <ReplaceBatchTable model={model} />
        </div>
    );
}

function ReplaceBatchDialogFooter({
    model,
    ok,
    close,
}: {
    model: ReplaceBatchModel;
    ok: () => Promise<any>;
    close: () => void;
}) {
    const [replace, applyReplace] = useAsyncAction<any>({ rethrowError: true });
    const { empty, isLoading } = useBehavior(model.state.loading);
    useBehavior(model.table?.version);

    const onOk = async () => {
        try {
            await applyReplace(ok());
            close();
        } catch {
            // handled elsewhere
        }
    };

    const current = model.currentIdentifier;
    const canReplace = model.batch.identifier !== current && !empty && !isLoading;

    return (
        <div className='hstack gap-2'>
            {replace.error && <span className='text-danger'>{tryGetErrorMessage(replace.error)}</span>}
            <AsyncButton
                variant={canReplace ? 'primary' : 'outline-primary'}
                onClick={onOk}
                state={replace}
                disabled={!canReplace}
            >
                {(!current || !canReplace) && 'Replace'}
                {!!current && canReplace && `Replace with ${model.request.assets.getIdentifier(current)}`}
            </AsyncButton>
        </div>
    );
}

function ReplaceBatchTable({ model }: { model: ReplaceBatchModel }) {
    useBehavior(model.table!.version);

    return (
        <DataTableControl
            height={300}
            table={model.table!}
            rowSelectionMode='single'
            headerSize='sm'
            autoscrollToSelection
        />
    );
}
