import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisH, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties, MouseEvent, ReactNode } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { DropDirection } from 'react-bootstrap/esm/DropdownContext';
import { AlignType } from 'react-bootstrap/esm/types';

export function IconButton({
    onClick,
    onMouseEnter,
    onMouseLeave,
    icon,
    title,
    className,
    style,
    disabled,
    children,
    variant = 'link',
    size,
}: {
    onClick: (e: MouseEvent) => any;
    onMouseEnter?: (e: MouseEvent) => any;
    onMouseLeave?: (e: MouseEvent) => any;
    icon: IconProp;
    title?: string;
    className?: string;
    style?: CSSProperties;
    disabled?: boolean;
    children?: ReactNode;
    variant?: string;
    // null means "medium" since the BS button accepts sm/lg/undefined
    // undefined means "default" for the IconButton which is small
    size?: 'sm' | 'lg' | null;
}) {
    return (
        <Button
            size={size === null ? undefined : size ?? 'sm'}
            variant={variant}
            onClick={onClick}
            title={title}
            className={className}
            style={style}
            disabled={disabled}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <FontAwesomeIcon icon={icon} fixedWidth size='sm' className={children ? 'me-2' : undefined} />
            {children}
        </Button>
    );
}

export function IconDropdownButton({
    icon,
    title,
    label,
    children,
    variant = 'link',
    align = 'end',
    className,
    style,
    disabled,
    size,
}: {
    icon: IconProp;
    title?: string;
    label?: string;
    children?: ReactNode;
    align?: AlignType;
    className?: string;
    style?: CSSProperties;
    variant?: string;
    disabled?: boolean;
    size?: 'sm' | 'lg';
}) {
    return (
        <Dropdown align={align} className='d-inline'>
            <Dropdown.Toggle
                variant={variant}
                title={title}
                size={size}
                className={className}
                style={style}
                disabled={disabled}
            >
                <FontAwesomeIcon icon={icon} fixedWidth size={size} className={label ? 'me-2' : ''} />
                {label}
            </Dropdown.Toggle>

            <Dropdown.Menu>{children}</Dropdown.Menu>
        </Dropdown>
    );
}

export function ThreeDotsMenu({
    drop,
    disabled = false,
    children,
    size = 'sm',
    direction = 'vertical',
}: {
    drop: DropDirection;
    disabled?: boolean;
    children: ReactNode;
    size?: 'sm' | 'lg';
    direction?: 'vertical' | 'horizontal';
}) {
    return (
        <Dropdown className='d-inline-block' drop={drop}>
            <Dropdown.Toggle variant='link' size={size} className='entos-more-menu-toggle' disabled={disabled}>
                <FontAwesomeIcon
                    icon={direction === 'vertical' ? faEllipsisVertical : faEllipsisH}
                    fixedWidth
                    size='sm'
                />
            </Dropdown.Toggle>
            <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} renderOnMount className='font-body-small'>
                {children}
            </Dropdown.Menu>
        </Dropdown>
    );
}
