import log from 'loglevel';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from '../../../lib/services/toast';
import { executeConcurrentTasks } from '../../../lib/util/async-queue';
import { reportErrorAsToast, tryGetErrorMessage } from '../../../lib/util/errors';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { DataTableModel, DataTableStore } from '../../../components/DataTable';
import {
    BatchReview,
    CompoundAPI,
    CompoundComparison,
    CompoundSummary,
    SignalsResult,
    StereochemistryLabelEnum,
} from '../compound-api';

export type BatchUploadType = 'single' | 'multiple';

export type BatchUploadStep = 'settings' | 'review';

export type SingleBatchUploadStep = 'selection' | 'details';

export const INVALID_STEREOCHEMISTRY_LABEL = 'Not specified' as StereochemistryLabelEnum;

const BATCH_REVIEW_COLUMNS: (keyof BatchReview)[] = [
    'structure',
    'compound_identifier',
    'project',
    'supplier',
    'supplier_id',
    'supplier_notebook',
    'supplier_notebook_page',
    'submitted_purity',
    'stereochemistry_label',
    'stereochemistry_comment',
    'stereochemistry_enantiomeric_ratio',
    'ambiguous_stereochemistry_v3k',
    'common_name',
    'aliases',
    'error',
    'warning',
];

export class CompoundComparisonModel {
    state = {
        selected: new BehaviorSubject<CompoundSummary>(this.comparison.submitted),
    };

    // eslint-disable-next-line no-empty-function
    constructor(public readonly comparison: CompoundComparison) {}
}

export class MultipleBatchUploadModel {
    state = {
        editingRowIndex: new BehaviorSubject<number>(-1),
        editedRows: new BehaviorSubject<Record<string, boolean>>({}),
        entosCompound: new BehaviorSubject<boolean>(true),
    };

    get error() {
        return this.model.state.error;
    }

    get isLoading() {
        return this.model.state.isLoading;
    }

    async upload(table: DataTableModel<BatchReview>) {
        await this.createMultipleBatches(table);
    }

    async updateRow() {
        const store = this.batchReviewStore;
        const editingRowIndex = this.state.editingRowIndex.value;
        if (store && editingRowIndex > -1) {
            const compoundComparison = this.compoundComparisons.get(editingRowIndex);
            if (compoundComparison) {
                const selected = compoundComparison.state.selected.value;
                const batchReview = {
                    structure: selected.batch_smiles,
                    compound_identifier: selected.identifier,
                    stereochemistry_label: selected.stereochemistry_label,
                    stereochemistry_comment: selected.stereochemistry_comment,
                    stereochemistry_enantiomeric_ratio: selected.stereochemistry_enantiomeric_ratio ?? undefined,
                };
                store.updateRow(batchReview, editingRowIndex, { type: 'partial' });
                const row = store.getRow(editingRowIndex);
                try {
                    const result = await CompoundAPI.batchReReview([row]);
                    const batch = result.toObjects({ indices: [0] })[0];
                    store.updateRow(batch, editingRowIndex, { type: 'full' });
                } catch (err) {
                    reportErrorAsToast('Failed to review batches', err);
                }
            }
        }
        const editedRows = this.state.editedRows.value;
        this.state.editedRows.next({ ...editedRows, [editingRowIndex]: true });
    }

    cancel() {
        this.model.cancel();
    }

    private async createMultipleBatches(table: DataTableModel<BatchReview>) {
        if (!table.store) return;
        this.model.state.isLoading.next(true);
        this.model.state.error.next('');
        try {
            const indices = table.rows.filter((r) => !!table.selectedRows[r]);
            const result: boolean = await CompoundAPI.batchCreate({
                batches: table.store.toObjects({ indices }),
                entos_compound: this.state.entosCompound.value,
            });
            if (result) {
                await this.model.reportBatchesToSignals();
                this.model.state.error.next('');
                this.cancel();
            } else {
                throw new Error('Failed to create batches.');
            }
        } catch (e) {
            this.model.state.error.next(tryGetErrorMessage(e));
            log.error(e);
        } finally {
            this.model.state.isLoading.next(false);
        }
    }

    constructor(
        private model: BatchUploadModel,
        public batchReviewStore: DataTableStore<BatchReview>,
        public compoundComparisons: Map<number, CompoundComparisonModel>
    ) {
        const editedRows: Record<string, boolean> = {};
        compoundComparisons.forEach((value, key) => {
            if (value.comparison.similar.length > 0) editedRows[key] = false;
        });
        this.state.editedRows.next(editedRows);
    }
}

export class SingleBatchUploadModel extends ReactiveModel {
    public compoundComparison: CompoundComparisonModel;

    state = {
        step: new BehaviorSubject<SingleBatchUploadStep>('selection'),
        entosCompound: new BehaviorSubject<boolean>(true),
        batchReview: new BehaviorSubject<Partial<BatchReview>>({}),
        reviewStatus: new BehaviorSubject<{ error?: string; warning?: string; inProgress?: boolean }>({}),
    };

    get error() {
        return this.model.state.error;
    }

    get isLoading() {
        return this.model.state.isLoading;
    }

    async upload() {
        await this.createSingleBatch();
    }

    next() {
        const step = this.state.step.value;
        if (step === 'selection') {
            const selected = this.compoundComparison.state.selected.value;
            this.state.step.next('details');
            this.state.batchReview.next({
                structure: selected.batch_smiles ?? '',
                compound_identifier: selected.identifier,
                stereochemistry_label: selected.stereochemistry_label,
                stereochemistry_enantiomeric_ratio: selected.stereochemistry_enantiomeric_ratio ?? undefined,
                submitted_purity: this.signalsOutput?.submitted_purity ?? undefined,
                supplier: this.signalsOutput?.supplier ?? undefined,
                supplier_id: this.signalsOutput?.supplier_id ?? undefined,
                supplier_notebook: this.signalsOutput?.supplier_notebook ?? undefined,
                supplier_notebook_page: this.signalsOutput?.supplier_notebook_page ?? undefined,
            });
        }
    }

    back() {
        const step = this.state.step.value;
        if (step === 'details') {
            this.state.step.next('selection');
            this.state.batchReview.next({});
        }
    }

    cancel() {
        this.model.cancel();
    }

    private async createSingleBatch() {
        this.model.state.isLoading.next(true);
        this.model.state.error.next('');
        try {
            const fullBatchReview: BatchReview = partialToFullBatchReview(this.state.batchReview.value);
            const review = await CompoundAPI.batchReReview([fullBatchReview]);
            const batch = review.toObjects({ indices: [0] })[0];
            if (batch.error) {
                throw new Error(batch.error);
            }
            const result: boolean = await CompoundAPI.batchCreate({
                batches: [batch],
                entos_compound: this.state.entosCompound.value,
            });
            if (result) {
                await this.model.reportBatchesToSignals();
                this.model.state.error.next('');
                this.cancel();
            } else {
                throw new Error('Failed to create batch.');
            }
        } catch (e) {
            reportErrorAsToast('Failed to create batch', e);
            this.model.state.error.next(tryGetErrorMessage(e));
            log.error(e);
        } finally {
            this.model.state.isLoading.next(false);
        }
    }

    mount() {
        this.subscribe(this.state.batchReview, async () => {
            const step = this.state.step.value;
            if (step === 'details') {
                this.state.reviewStatus.next({
                    inProgress: true,
                });
                const fullBatchReview = partialToFullBatchReview(this.state.batchReview.value);
                try {
                    const review = await CompoundAPI.batchReReview([fullBatchReview]);
                    const batch = review.toObjects({ indices: [0] })[0];
                    this.state.reviewStatus.next({
                        error: batch.error,
                        warning: batch.warning,
                        inProgress: false,
                    });
                } catch (e) {
                    reportErrorAsToast('Failed to review', e);
                    this.state.reviewStatus.next({
                        inProgress: false,
                    });
                }
            }
        });
    }

    constructor(
        private model: BatchUploadModel,
        comparison: CompoundComparison,
        public signalsOutput?: Partial<BatchReview>
    ) {
        super();
        this.compoundComparison = new CompoundComparisonModel(comparison);
    }
}

function findSimilarCompoundsForRow(idx: number, store: DataTableStore<BatchReview>): Promise<CompoundComparison> {
    const smiles = store.getValue('structure', idx);
    const stereochemistryLabel = store.getValue('stereochemistry_label', idx);
    const enantiomericRatio = store.getValue('stereochemistry_enantiomeric_ratio', idx);
    return CompoundAPI.getSimilarCompounds(smiles, stereochemistryLabel, enantiomericRatio);
}

function partialToFullBatchReview(batchReview: Partial<BatchReview>) {
    const fullBatchReview: Partial<BatchReview> = {};
    for (const col of BATCH_REVIEW_COLUMNS) {
        (fullBatchReview as any)[col] = batchReview[col] ?? null;
    }
    return fullBatchReview as BatchReview;
}

export class BatchUploadModel extends ReactiveModel {
    state = {
        type: new BehaviorSubject<BatchUploadType>('multiple'),
        step: new BehaviorSubject<BatchUploadStep>('settings'),
        modalOpen: new BehaviorSubject<boolean>(false),
        isLoading: new BehaviorSubject<boolean>(false),
        error: new BehaviorSubject<string>(''),
        file: new BehaviorSubject<File | null>(null),
        smilesInput: new BehaviorSubject<string>(''),
        enantiomericRatio: new BehaviorSubject<string>(''),
        stereochemistryLabel: new BehaviorSubject<StereochemistryLabelEnum | undefined>(undefined),
        signalsOutput: new BehaviorSubject<Partial<BatchReview> | undefined>(undefined),
        settingsInvalid: new BehaviorSubject<boolean | string>(true),
        singleBatchUploadModel: new BehaviorSubject<SingleBatchUploadModel | null>(null),
        multipleBatchUploadModel: new BehaviorSubject<MultipleBatchUploadModel | null>(null),
    };

    private signalsEid: string = '';
    private signalsResult: SignalsResult | undefined;
    private readonly defaultState = Object.entries(this.state).map(([k, v]) => [k, v.value]);

    enantiomericRatioIsValid() {
        const enantiomericRatio = this.state.enantiomericRatio.value;
        return enantiomericRatio === '' || /(0-9)*:(0-9)*/.test(enantiomericRatio);
    }

    stereochemistryLabelIsValid() {
        const stereochemistryLabel = this.state.stereochemistryLabel.value;
        return !!stereochemistryLabel && stereochemistryLabel !== INVALID_STEREOCHEMISTRY_LABEL;
    }

    async next() {
        const type = this.state.type.value;
        if (type === 'multiple') {
            const file = this.state.file.value;
            if (!file) return;
            let review: DataTableStore<BatchReview> | undefined;
            try {
                review = await CompoundAPI.batchReview(file);
            } catch (err) {
                this.state.error.next(tryGetErrorMessage(err));
            }
            if (review) {
                await this.loadMultipleBatches(review);
            }
        } else {
            await this.loadSingleBatch();
        }
    }

    async reportBatchesToSignals() {
        if (this.signalsEid && this.signalsResult) {
            await CompoundAPI.sendIdentifiersToSignals(this.signalsEid, this.signalsResult.productIdMap);
        }
    }

    cancel() {
        this.compoundListModel.loadSamplesFromSignals();
        this.setDefaultState();
    }

    async loadFromSignals(eid: string, signalsResult: SignalsResult) {
        this.state.isLoading.next(true);
        this.state.modalOpen.next(true);
        this.signalsEid = eid;
        this.signalsResult = signalsResult;
        try {
            await this.setStateFromSignals(signalsResult.batches);
        } catch (e) {
            this.state.error.next(`Error loading batches from Signals: ${tryGetErrorMessage(e)}`);
            log.error(e);
        } finally {
            this.state.isLoading.next(false);
        }
    }

    private async setStateFromSignals(signalsResult: DataTableStore<BatchReview>) {
        this.state.error.next('');
        if (signalsResult.rowCount === 1) {
            const newBatch = signalsResult.toObjects({ indices: [0] })[0];
            this.state.type.next('single');
            this.state.smilesInput.next(newBatch.structure);
            this.state.stereochemistryLabel.next(newBatch.stereochemistry_label);
            this.state.signalsOutput.next(newBatch);
        } else {
            this.state.type.next('multiple');
            const batchReview = signalsResult.toObjects().map((br) => partialToFullBatchReview(br));
            const review = await CompoundAPI.batchReReview(batchReview);
            await this.loadMultipleBatches(review);
        }
    }

    private async loadSingleBatch() {
        this.state.isLoading.next(true);
        const smiles = this.state.smilesInput.value;
        try {
            const stereochemistryLabel = this.state.stereochemistryLabel.value ?? INVALID_STEREOCHEMISTRY_LABEL;
            const enantiomericRatio = this.state.enantiomericRatio.value;
            const similarCompounds = await CompoundAPI.getSimilarCompounds(
                smiles,
                stereochemistryLabel,
                enantiomericRatio
            );

            if (
                similarCompounds.submitted.identifier &&
                (similarCompounds.submitted.stereochemistry_enantiomeric_ratio ?? '') !== enantiomericRatio
            ) {
                ToastService.show({
                    type: 'warning',
                    message: (
                        <div>
                            The enantiomeric ratio entered ({enantiomericRatio ?? 'None'}) does not match the
                            enantiomeric ratio of the existing compound (
                            {similarCompounds.submitted.stereochemistry_enantiomeric_ratio ?? 'None'}).
                            <b>Your enantiomeric ratio entry will be overwritten</b> by the existing enantiomeric ratio
                            from the compound that you proceed with. If you have questions about this, contact #insight
                            on Slack.
                        </div>
                    ),
                });
            }

            this.state.singleBatchUploadModel.next(
                new SingleBatchUploadModel(this, similarCompounds, this.state.signalsOutput.value)
            );
            this.state.multipleBatchUploadModel.next(null);
            this.state.step.next('review');
        } catch (e) {
            this.state.error.next(tryGetErrorMessage(e));
            log.error(e);
        } finally {
            this.state.isLoading.next(false);
        }
    }

    private async loadMultipleBatches(review: DataTableStore<BatchReview>) {
        this.state.isLoading.next(true);
        try {
            const results: Map<number, CompoundComparisonModel> = new Map();
            const rowRange = [...Array(review.rowCount)].map((_, i) => i);
            await executeConcurrentTasks({
                tasks: rowRange.map((idx) => () => findSimilarCompoundsForRow(idx, review)),
                maxConcurrent: 4,
                onExecuted: (v: CompoundComparison, index: number) => {
                    // TODO (emma) is this actually what we want?
                    // double check what foundry-apps-assay-upload parse step is doing
                    if (v.similar.length > 0) results.set(index, new CompoundComparisonModel(v));
                },
            });
            this.state.multipleBatchUploadModel.next(new MultipleBatchUploadModel(this, review, results));
            this.state.singleBatchUploadModel.next(null);
            this.state.step.next('review');
        } catch (e) {
            this.state.error.next(tryGetErrorMessage(e));
            log.error(e);
        } finally {
            this.state.isLoading.next(false);
        }
    }

    private settingsInvalid(): boolean | string {
        const type = this.state.type.value;
        if (type === 'multiple') {
            if (this.state.file.value) return false;
            return 'No file selected';
        }
        if (!this.state.smilesInput.value) return 'No SMILES entered';
        if (!this.enantiomericRatioIsValid()) return 'Invalid enantiomeric ratio';
        if (!this.stereochemistryLabelIsValid()) return 'Please select a stereochemistry label';
        return false;
    }

    private setDefaultState() {
        for (const [k, v] of this.defaultState) {
            (this.state as any)[k as string].next(v);
        }
        this.signalsEid = '';
        this.signalsResult = undefined;
    }

    constructor(private compoundListModel: import('../compound-list-model').CompoundListModel) {
        super();

        this.subscribe(this.state.file, () => {
            this.state.settingsInvalid.next(this.settingsInvalid());
        });

        this.subscribe(this.state.type, () => {
            this.state.settingsInvalid.next(this.settingsInvalid());
        });

        this.subscribe(this.state.smilesInput, () => {
            this.state.settingsInvalid.next(this.settingsInvalid());
        });

        this.subscribe(this.state.stereochemistryLabel, () => {
            this.state.settingsInvalid.next(this.settingsInvalid());
        });

        this.subscribe(this.state.enantiomericRatio, () => {
            this.state.settingsInvalid.next(this.settingsInvalid());
        });
    }
}
