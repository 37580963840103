import { DateLike } from '../../lib/util/dates';
import { ExtendedWellLayout, WellLayout } from '../HTE/experiment-data';

export interface HTE2MicroscaleLibrary {
    kind: 'microscale_library';
    version: 1;

    design: HTEDesign;
    protocol: HTEProtocol;
    inventory: HTEInventory;
    production: HTEProduction;
    crude_plate?: HTEPCrudePlate;
    purification?: HTEPurification;
    distribution?: HTEDistribution;
    qc?: HTEQualityControl;

    ui_state?: HTE2MicroscaleLibraryUIState;
}

export interface HTE2LibraryDraft {
    name: string;
    library: HTE2MicroscaleLibrary;
}

export interface HTE2MicroscaleLibraryOptions {
    name: string;
    project?: string;
    hidden: boolean;
    description: string;
}

export interface HTE2MicroscaleLibraryUIState {
    workflow_state?: 'production' | 'purification' | 'needs-finalization';
}

export interface HTEFinalizationData {
    full_name: string;
    acknowledged_policy: boolean;
    comment?: string;
}

// **********************
//        TYPES
// **********************

export type UniversalIdentifierT = string;

// NOTE: { '_type': '...' } emulates a nominal type in TypeScript
//       experimenting with this just on this type for now
export type HTERReactantNameT = string;
export type HTERReactantKindT = string;
export type HTERInstructionIdT = string;
export type HTERSolutionIdT = string;

export type HTEDLabwareIdT = string;
export type HTEDReactionIdT = string;
export type HTEPReagentKeyT = string;
export type HTEPWorklistKeyT = string;
export type HTEWellLabelT = string;
export type HTEPRackLabelT = string;

export type HTEPFractionSampleIdT = string;
export type HTEPFractionIdT = string;
export type HTEPPoolingIdT = string;
export type HTEPSolutionKeyT = string;

export type HTEDistributionIdT = string;
export type HTEDistributedCompoundIdT = string;

export type HTEFinalQCIdT = string;

export const KnownReactantTypes = ['unknown', 'msd', 'bb', 'base', 'catalyst', 'coupling', 'acid', 'quench'] as const;

export interface HTEPlateLikeLabware {
    label?: string;
    name: string;
    layout: ExtendedWellLayout;
}

export interface HTEMaterializedTransfer {
    identifier?: UniversalIdentifierT;
    instruction_id?: HTERInstructionIdT;
    solvent: string;
    volume: number;
    concentration?: number;
    source_barcode: string;
    source_well: string;
    target_barcode: string;
    target_well: string;
    reference_barcode: string | undefined;
    reactant_kinds?: string[];
    comment?: string;
}

// **********************
//        DESIGN
// **********************

export interface HTEDConditions {
    stir_rate: number | string;
    temperature: number | string;
    duration: number | string;
    atmosphere: string;
    pressure: number | string | undefined | null;
}

export interface HTEDProductEnumeration {
    msd_substance_id: number;
    bb_substance_id: number;
    reaction_chemistry: string;
    errors?: string[];

    substance_id?: number;

    substance_ids?: number[];
}

export interface HTEDReaction {
    id: HTEDReactionIdT;
    template: HTEReactionTemplate;

    product_identifier?: string;
    product_enumeration?: HTEDProductEnumeration;
    project?: string;

    group_name?: string;
    scale?: number;
    well_label?: string;
}

export interface HTEDLabwareDefinition {
    id: HTEDLabwareIdT;
    protocol_name: string;
    label: string;
    volume: number;
    dead_volume: number;
    no_transfer: boolean;
    is_reservoir: boolean;
    layout?: WellLayout;
    barcode_prefixes?: string[];
}

export interface HTEDLabware {
    sources: HTEDLabwareDefinition[];
    product: HTEDLabwareDefinition;
}

export interface HTEDesign {
    reactions: HTEDReaction[];
    conditions: HTEDConditions;
    labware: HTEDLabware;
    notes?: string;
}

// **********************
//       REACTION
// **********************

export interface HTEReactionTemplate {
    solvent?: string;
    reaction_chemistry?: string;
    target_concentration?: number;
    standard_concentration?: number;
    instructions: HTERInstructionT[];
}

export interface HTERReactantSample {
    equivalence?: number;
    concentration?: number | null | string;
    neat_concentration?: number | null | string;
    dose_volume?: number | null | string;
    neat_solvent?: string;
    use_overage?: number;
}

export interface HTERAddReactant extends HTERReactantSample {
    kind: 'add';
    id?: HTERInstructionIdT;
    reactant_name?: HTERReactantNameT;
    reactant_kind: HTERReactantKindT;
    identifier?: string;
    solution_id?: HTERSolutionIdT;
    manual_handling?: boolean;
}

export interface HTERPause {
    kind: 'pause';
    duration: number | string;
}

export interface HTERCook {
    kind: 'cook';
    duration: number | string;
    temperature: number | string;
}

export interface HTEREvaporate {
    kind: 'evaporate';
}

export type HTERInstructionT = HTERAddReactant | HTERPause | HTERCook | HTEREvaporate;

// **********************
//       PROTOCOL
// **********************

export interface HTEPTransfer {
    kind: 'transfer';

    reagent_key: HTEPReagentKeyT;
    reaction_id: HTEDReactionIdT;
    instruction_id: HTERInstructionIdT;
    manual_handling?: boolean;
}

export interface HTEPSolutionTransfer {
    kind: 'solution-transfer';

    solution_key: HTEPSolutionKeyT;
    reaction_id: HTEDReactionIdT;
    solution_id: HTERSolutionIdT;
    manual_handling?: boolean;
}

export interface HTEPPause {
    kind: 'pause';
    duration: number;
}

export interface HTEPBackfill {
    kind: 'backfill';

    solvent: string;
    volume: number;

    reaction_id: HTEDReactionIdT;
}

export type HTEPInstructionT = HTEPTransfer | HTEPSolutionTransfer | HTEPPause | HTEPBackfill;

export interface HTEPWorklistGroup {
    title: string;
    instructions: HTEPInstructionT[];
}

export interface HTEPWorklist {
    key: HTEPWorklistKeyT;
    title: string;
    groups: HTEPWorklistGroup[];
}

export interface HTEPReagentUse {
    reagent_key: HTEPReagentKeyT;
    n_mols: number;
    overage: number;
    reaction_id: HTEDReactionIdT;
    instruction_id: HTERInstructionIdT;
}

export interface HTEPReagent {
    key: HTEPReagentKeyT;
    worklist_key: HTEPWorklistKeyT;
    identifier: UniversalIdentifierT;
    concentration?: number;
    solvent?: string;
    uses: HTEPReagentUse[];
}

export interface HTEPSolutionInstructionUse {
    n_mols: number;
    instruction_id: HTERInstructionIdT;
}

export interface HTEPSolutionUse {
    solution_key: HTEPSolutionKeyT;

    n_mols: number;
    overage: number;

    reaction_id: HTEDReactionIdT;
    solution_id: HTERSolutionIdT;
    instruction_uses: HTEPSolutionInstructionUse[];
}

export interface HTEPSolutionComponent {
    reactant_kind: HTERReactantKindT;
    identifier: UniversalIdentifierT;
    equivalence: number;
    solvent?: string;
    concentration?: number;
}

export interface HTEPSolution {
    key: HTEPSolutionKeyT;

    worklist_key: HTEPWorklistKeyT;
    solution_id: HTERSolutionIdT;
    components: HTEPSolutionComponent[];
    solvent?: string;
    concentration: number;
    uses: HTEPSolutionUse[];
}

export interface HTEPMessage {
    reaction_id?: HTEDReactionIdT;
    message: string;
}

export interface HTEPProductSample {
    standard_volume?: number;
    total_volume: number;
    concentration: number;
    extra_solvent_volume: number;
}

export interface HTEPPlateLayout {
    layout: ExtendedWellLayout;
    reaction_wells: Record<HTEDReactionIdT, number>;
}

export interface HTEProtocol {
    errors: HTEPMessage[];
    warnings: HTEPMessage[];
    worklists: HTEPWorklist[];
    product_samples: Record<HTEDReactionIdT, HTEPProductSample>;
    reaction_ids: HTEDReactionIdT[];
    plates?: HTEPPlateLayout[];
    reagents: HTEPReagent[];
    solutions?: HTEPSolution[];
}

// **********************
//       INVENTORY
// **********************

export interface HTEISampleLocation {
    container_label?: string;
    container_well?: string;
}

export interface HTEILiquid {
    source_barcode?: string;
    transfer_barcode?: string;
    labware_id?: HTEDLabwareIdT;
}

export interface HTEIDry {
    source_barcode?: string;
    transfer_barcode?: string;
}

export interface HTEISolution {
    source_barcode?: string;
    location?: HTEISampleLocation;
    labware_id?: HTEDLabwareIdT;
}

export interface HTEIVialRack {
    label?: HTEPRackLabelT;
    description?: string;
    labware_id?: HTEDLabwareIdT;
    position?: string;
    wells: Record<HTEWellLabelT, string>;
}

export interface HTEInventory {
    liquid: Record<HTEPReagentKeyT, HTEILiquid>;
    dry: Record<HTERInstructionIdT, HTEIDry>;
    solutions?: Record<HTEPSolutionKeyT, HTEISolution>;
    reservoir_locations: Record<string, HTEISampleLocation>;
}

// **********************
//       PRODUCTION
// **********************

export type HTEPOT2InstrumentKind = 'P300' | 'P1000' | 'P20Multi' | 'P300Multi';

export interface HTEPCompilerMessage {
    reagent_key?: HTEPReagentKeyT;
    solution_key?: HTEPSolutionKeyT;
    reaction_id?: HTEDReactionIdT;
    message: string;
    detail?: string;
}

export interface HTEPCompiledPicklist {
    title: string;
    transfers: HTEMaterializedTransfer[];
    pause_duration?: number;
}

export interface HTEPCompiledWorklist {
    key: HTEPWorklistKeyT;
    picklists: HTEPCompiledPicklist[];
    ot2_protocol?: string;

    errors: HTEPCompilerMessage[];
}

export interface HTEPCrudePlate {
    reaction_well_indices: Record<HTEDReactionIdT, number>;
    worklists: HTEPCompiledWorklist[];
    errors: HTEPCompilerMessage[];
    warnings: HTEPCompilerMessage[];
}

export interface HTEPTransferMethod {
    method?: 'distribute' | 'cherrypick';
    ot2_instrument?: HTEPOT2InstrumentKind;
    ot2_default_speed?: number;
    ot2_aspirate_rate?: number;
    ot2_air_gap?: number;
    ot2_dest_top_offset?: number;
    by_kind?: Record<HTERReactantKindT, Partial<HTEPTransferMethod>>;
}

export interface HTEPReservoirInfo {
    labware_id?: HTEDLabwareIdT;
    description?: string;
    position?: string;
}

export interface HTEPOT2InstrumentInfo {
    instrument?: HTEPOT2InstrumentKind;
    tip_rack_positions?: string[];
}

export interface HTEPWorklistOptions {
    source_racks: HTEIVialRack[];
    reservoirs: Record<string, HTEPReservoirInfo>;
    solvents: Record<string, HTEISampleLocation>;
    group_transfer_methods?: Record<number, HTEPTransferMethod>;
}

export interface HTEProduction {
    crude_rack: HTEIVialRack;
    worklist_options: Record<HTEPWorklistKeyT, HTEPWorklistOptions>;
    ot2_instruments: Record<'left' | 'right', HTEPOT2InstrumentInfo | undefined>;
    transfer_methods: Record<string, HTEPTransferMethod>;
}

// ************************
//       PURIFICATION
// ************************

export interface HTEPFractionSample {
    id: HTEPFractionSampleIdT;

    selected: boolean;
    volume: number;
    location: HTEISampleLocation;

    absolute_purity: number; // 0-1
    normalized_purity: number; // 0-1
}

export interface HTEPFraction {
    id: HTEPFractionIdT;
    number: number;

    analytical_sample_id?: string;

    batch_identifier: string;
    source_barcode?: string;
    source_location: HTEISampleLocation;

    salt_name?: string;
    salt_smiles?: string;

    samples: HTEPFractionSample[];
}

export interface HTEPPooling {
    id: HTEPPoolingIdT;

    timestamp: DateLike;
    fractions: HTEPFraction[];
}

export interface HTEPPoolingScriptOptions {
    fraction_labware: HTEPlateLikeLabware;
    pooled_labware: HTEPlateLikeLabware;

    // fraction_qc_labware: HTEPlateLikeLabware
    // pooled_qc_labware: HTEPlateLikeLabware

    max_pooled_volume: number | string;
    pooled_tolerance: number | string;
    tecan_piston_volume: number | string;
}

export interface HTEPPoolingState {
    script_options: HTEPPoolingScriptOptions;
    pooled_fraction_barcodes: Record<HTEPFractionIdT, string[]>;
    excluded_salts?: HTEPFractionIdT[];
    scripts?: HTEPoolingScripts;
    fraction_validation?: Record<HTEPFractionIdT, HTEPFractionValidation>;
}

export interface HTEPFractionValidation {
    kind: 'error' | 'warning';
    message: string;
}

export interface HTEPoolingScripts {
    fraction_sample_transfers: Record<HTEPFractionSampleIdT, HTEMaterializedTransfer[]>;
    fraction_tecan_worklist: string;
    fraction_order: [id: HTEPFractionIdT, vial_count: number][];

    // fraction_qc_transfers: Record<HTEPFractionSampleIdT, HTEMaterializedTransfer>
    // fraction_qc_tecan_worklists: Record<HTEPFractionSampleIdT, string>
    // pooled_qc_transfers: Record<HTEPFractionSampleIdT, HTEMaterializedTransfer>
    // pooled_qc_tecan_worklists: Record<HTEPFractionSampleIdT, string>
}

export interface HTEPurification {
    poolings: HTEPPooling[];
    states: Record<HTEPPoolingIdT, HTEPPoolingState>;
    salt_name_to_smiles: Record<string, string>;
}

// ************************
//       DISTRIBUTION
// ************************

export interface HTEDistributionInput {
    pooled_total_mass: Record<string, number>;
    pooled_racks: HTEIVialRack[];
    liquid_racks: HTEIVialRack[];
    dry_racks: HTEIVialRack[];
}

export interface HTEDistributionOptions {
    liquid_vial_volume: number | string;
    liquid_vial_concentration: number | string;
    dry_vial_volume: number | string;
    dry_vial_discard_volume: number | string;

    pooled_labware: HTEPlateLikeLabware;
    liquid_labware: HTEPlateLikeLabware;
    dry_labware: HTEPlateLikeLabware;
    solvent_labware: HTEPlateLikeLabware;

    tecan_piston_volume: number | string;
    max_solubilize_concentration: number | string;
}

export interface HTEDDistributedCompound {
    id: HTEDistributedCompoundIdT;
    batch_id: number;
    batch_identifier: string;
    total_amount: number;
    amounts: Record<string, number>;

    solubilize_concentration: number;
    solubilize_volume: number;
    liquid_transfer_volume: number;
    dry_volume: number;
    dry_transfer_volume?: number;
    expected_dry_stock_amount?: number;

    invalid_tares?: string[];

    pooled_on?: DateLike;
    errors?: string[];
}

export interface HTEDistributionScripts {
    solubilization_transfers: HTEMaterializedTransfer[];
    solubilization_tecan_worklist: string;

    distribution_transfers: Record<HTEDistributedCompoundIdT, HTEMaterializedTransfer[]>;
    distribution_backfills: Record<HTEDistributedCompoundIdT, HTEMaterializedTransfer>;
    distribution_tecan_worklist: string;
}

export interface HTEDistributionState {
    options: HTEDistributionOptions;
    compounds: HTEDDistributedCompound[];
    scripts?: HTEDistributionScripts;
    registered: boolean;
}

export interface HTEDistributionEntry {
    id: HTEDistributionIdT;
    timestamp: DateLike;

    input: HTEDistributionInput;
    options: HTEDistributionOptions;
    compounds: HTEDDistributedCompound[];
    scripts: HTEDistributionScripts;

    liquid_barcodes: Record<HTEDistributedCompoundIdT, string>;
    dry_barcodes: Record<HTEDistributedCompoundIdT, string>;
}

export interface HTEDistribution {
    distributions: HTEDistributionEntry[];
    registered: Record<HTEDistributionIdT, boolean>;
}

export interface HTEDValidationEntry {
    rack_label: string;
    well: string;
    barcode: string;
    pooled_on?: DateLike;
    errors: string[];
}

export interface HTEDValidationResult {
    entries: HTEDValidationEntry[];
}

// ************************
//           QC
// ************************

export interface HTEFinalQCInput {
    racks: HTEIVialRack[];
}

export interface HTEFinalQCOptions {
    source_labware: HTEPlateLikeLabware;
    target_labware: HTEPlateLikeLabware;
    echoms_target_labware: HTEPlateLikeLabware;
    solvent_labware: HTEPlateLikeLabware;

    target_barcode: string;
    echoms_target_barcode: string | undefined;

    assay_volume: number | string;
    assay_concentration: number | string;

    echoms_assay_volume: number | string;
    echoms_assay_concentration: number | string;

    tecan_piston_volume: number | string;
}

export interface HTEFinalQCSample {
    well_index: number;
    barcode: string;
    transfer_volume: number;
}

export interface HTEFinalQCScripts {
    samples: HTEFinalQCSample[];
    transfers: HTEMaterializedTransfer[];
    tecan_worklist: string;
}

export interface HTEFinalQCEntry {
    id: HTEFinalQCIdT;
    timestamp: DateLike;

    options: HTEFinalQCOptions;
    input: HTEFinalQCInput;
    scripts: HTEFinalQCScripts;
}

export interface HTEQualityControl {
    finalqcs: HTEFinalQCEntry[];
    registered_finalqcs: Record<HTEFinalQCIdT, boolean>;
}
