export const Z_CGI_96_2c_9pt_trip_T1 = `ID,Point,Row,Column,IsControl
1,1,2,2,False
1,2,2,3,False
1,3,2,4,False
1,4,2,5,False
1,5,2,6,False
1,6,2,7,False
1,7,2,8,False
1,8,2,9,False
1,9,2,10,False
1,1,3,2,False
1,2,3,3,False
1,3,3,4,False
1,4,3,5,False
1,5,3,6,False
1,6,3,7,False
1,7,3,8,False
1,8,3,9,False
1,9,3,10,False
1,1,4,2,False
1,2,4,3,False
1,3,4,4,False
1,4,4,5,False
1,5,4,6,False
1,6,4,7,False
1,7,4,8,False
1,8,4,9,False
1,9,4,10,False
2,1,5,2,False
2,2,5,3,False
2,3,5,4,False
2,4,5,5,False
2,5,5,6,False
2,6,5,7,False
2,7,5,8,False
2,8,5,9,False
2,9,5,10,False
2,1,6,2,False
2,2,6,3,False
2,3,6,4,False
2,4,6,5,False
2,5,6,6,False
2,6,6,7,False
2,7,6,8,False
2,8,6,9,False
2,9,6,10,False
2,1,7,2,False
2,2,7,3,False
2,3,7,4,False
2,4,7,5,False
2,5,7,6,False
2,6,7,7,False
2,7,7,8,False
2,8,7,9,False
2,9,7,10,False
`;
