import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export type DateLike = number | string | Date;

export function createLocalDayJS(yyyy_mm_dd_string: string) {
    return dayjs.tz(yyyy_mm_dd_string, dayjs.tz.guess());
}

export function createLocalDate(yyyy_mm_dd_string: string) {
    return createLocalDayJS(yyyy_mm_dd_string).toDate();
}

export function tryParseDate(v: unknown): Date | undefined {
    if (v instanceof Date) return v;
    if (typeof v === 'string' && isValidDate(v)) return parseFileSystemDate(v);
    return undefined;
}

// NOTE this does not work on any arbitrary string
// Ex. this function will say that the Pharmaron supplier ID "PH-Z-I-8" is a valid date
// If we need to parse date strings then we need to pick a specific format
// and can use dayjs to parse it https://day.js.org/docs/en/parse/string-format
function isValidDate(input: string): boolean {
    return !Number.isNaN(Date.parse(input));
}

export function asDate(dateInput: DateLike): Date {
    if (dateInput instanceof Date) return dateInput;
    return new Date(dateInput);
}

// adapted from: https://github.com/EntosAI/envision/blob/master/envision/src/models/filesystem.ts#L337
// This function works with the datetime format provided by Foundry and converts
// it to the local system time
export function parseFileSystemDate(dateInput: DateLike) {
    const date = asDate(dateInput);
    if (typeof dateInput === 'string') return new Date(+date - date.getTimezoneOffset() * 60 * 1000);
    return date;
}

export function formatDatetime(value: Date | number | string, format: 'date' | 'full' = 'date') {
    let date: Date | undefined;
    if (typeof value === 'number') date = new Date(value);
    else if (typeof value === 'string') date = parseFileSystemDate(value);
    else if (value instanceof Date) date = value;
    if (date !== undefined) {
        return format === 'date' ? formatDateAsYYYY_MM_DD(date) : date.toLocaleString();
    }
    return '';
}

const YYYY_MM_DD = /^(20[0-9]{2})\/([0-1]{0,1}[0-9])\/([0-3]{0,1}[0-9])$/;
export function parseYYYY_MM_DDDate(value: string, hours?: number) {
    const fields = value.replace(/-/g, '/').match(YYYY_MM_DD);
    if (fields?.length !== 4) return undefined;
    const yyyy = +fields[1];
    const mm = +fields[2] - 1;
    const dd = +fields[3];
    const date = new Date(yyyy, mm, dd, hours ?? 0);
    if (date.getFullYear() !== yyyy || date.getMonth() !== mm || date.getDate() !== dd) {
        return undefined;
    }
    return date;
}

export function jsonISODateStringifyReplacer(this: any, key: string, value: any) {
    if (this[key] instanceof Date) {
        return (this[key] as Date).toISOString().replace('Z', '');
    }

    return value;
}

export function formatSeconds(s: number, pad = false): string {
    if (s > 3600) {
        return `${Math.floor(s / 3600)}h${formatSeconds(s % 3600)}`;
    }
    if (s > 60) {
        return `${Math.floor(s / 60)}m${formatSeconds(s % 60)}`;
    }
    return `${Math.round(s)}s`;
}

export function formatDateAsYYYY_MM_DD(input: DateLike, options?: { full?: boolean }) {
    const date = new Date(input);
    const day = `${date.getDate()}`;
    const month = `${date.getMonth() + 1}`;
    const year = `${date.getFullYear()}`;
    const yyyy_mm_dd_string = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    if (options?.full) {
        const hours = `${date.getHours()}`;
        const minutes = `${date.getMinutes()}`;
        const seconds = `${date.getSeconds()}`;
        return `${yyyy_mm_dd_string} ${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
    }
    return yyyy_mm_dd_string;
}

export function getDateRange(dateFilter?: QueryDateRange): Date[] | undefined {
    if (!dateFilter) return undefined;

    let beginDate = dayjs().startOf('day'); // startOf('day') is midnight
    if (dateFilter === 'week') {
        beginDate = beginDate.subtract(7, 'day');
    } else if (dateFilter === 'month') {
        beginDate = beginDate.subtract(30, 'day');
    } else if (dateFilter === 'quarter') {
        beginDate = beginDate.subtract(90, 'day');
    } else if (dateFilter === 'year') {
        beginDate = beginDate.subtract(365, 'day');
    }

    // end date should be midnight tomorrow
    // so we can be sure we get anything that was performed today
    const endDate = dayjs().add(1, 'day').startOf('day');
    return [beginDate.toDate(), endDate.toDate()];
}

export type QueryDateRange = 'week' | 'month' | 'quarter' | 'year';
