/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faListOl } from '@fortawesome/free-solid-svg-icons';
import { ReactNode } from 'react';
import { Spinner } from 'react-bootstrap';
import Select, { createFilter } from 'react-select';
import { BehaviorSubject, Subject, throttleTime } from 'rxjs';
import { Column, DataTableControl, DataTableModel, ObjectDataTableStore } from '../../../components/DataTable';
import { AsyncMoleculeDrawing } from '../../../components/common/AsyncMoleculeDrawing';
import { IconButton } from '../../../components/common/IconButton';
import { LabeledInput, SimpleSelectOptionInput } from '../../../components/common/Inputs';
import Loading from '../../../components/common/Loading';
import { CustomSelectClassNames, DefaultSelectStyles } from '../../../components/common/selectStyles';
import useBehavior from '../../../lib/hooks/useBehavior';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { DialogService } from '../../../lib/services/dialog';
import { ToastService } from '../../../lib/services/toast';
import { AsyncQueue } from '../../../lib/util/async-queue';
import { ModelAction, ReactiveModel, useModelAction } from '../../../lib/util/reactive-model';
import { EnumerationAPI, HTEEnumerationApiReactionSites } from '../../HTE/enumeration/enumeration-api';
import { HTEDReactionChemistryOption } from '../assets';
import type { HTEDProductBlockModel, HTEDReactantListModel } from './product-blocks';
import { HTEDEnumeration, HTERReactantInstance, HTERReactantNameT } from '../data-model';
import { HTE2Api } from '../api';

class HTEDEnumerateModel {
    hadProject = false;
    reactantListOptions: [reactant: string, label: string][];

    state = {
        current: new BehaviorSubject<{
            msd: HTERReactantNameT;
            bb: HTERReactantNameT;
            chemistry?: HTEDReactionChemistryOption;
            kind: 'product' | 'sandwich';
        }>({
            msd: '' as any,
            bb: '' as any,
            kind: 'product',
        }),
    };

    get reactionOptions() {
        return this.block.products.design.model.assets.reactionChemistryOptions;
    }

    private async registerAndLoad(model: HTEDEnumerationSitesModel) {
        const { products } = model;

        if (products.length === 0) {
            throw new Error('No products found');
        }

        if (products.some((p) => !p.product)) {
            throw new Error('All combinations of reactants must have a product');
        }

        const compoundIdentifiers = await HTE2Api.registerCompounds({
            smiles: products.map((p) => p.product!),
            project: this.block.products.design.model.state.options.value?.project!,
        });

        await this.block.products.design.model.assets.entities.syncIdentifiers(compoundIdentifiers);

        const state = this.state.current.value;
        const enumeration: HTEDEnumeration = {
            label: 'Product',
            reaction_chemistry_id: state.chemistry!.value!.id,
            kind: state.kind,
            reactant_names: [state.msd, state.bb],
            products: products.map((c, i) => [
                [c.msd.reactant.identifier, c.bb.reactant.identifier],
                compoundIdentifiers[i],
            ]),
        };

        this.block.setEnumeration(enumeration);
    }

    async enumerate() {
        const state = this.state.current.value;
        const lists = this.block.state.lists.value;
        const msds = lists.find((l) => l.reactantName === state.msd);
        const bbs = lists.find((l) => l.reactantName === state.bb);

        if (!msds || !bbs) {
            throw new Error('Select MSD and BB');
        }

        if (!state.chemistry?.value?.id) {
            throw new Error('Select reaction chemistry');
        }

        const model = new HTEDEnumerationSitesModel(this, {
            msds,
            bbs,
            reaction_chemistry_id: state.chemistry?.value?.id,
        });

        requestAnimationFrame(() => {
            DialogService.open({
                type: 'generic',
                title: `Enumerate ${state.chemistry?.value?.name}`,
                model,
                wrapOk: true,
                options: { size: 'xl', staticBackdrop: true },
                content: EnumerationSitesDialogContent,
                confirmButtonContent: 'Register Products and Load',
                onOk: () => this.registerAndLoad(model),
            });
        });
    }

    constructor(public block: HTEDProductBlockModel) {
        const lists = block.state.lists.value;

        this.reactantListOptions = lists.map((list) => [list.reactantName, list.reactantName]);

        this.hadProject = !!block.products.design.model.state.options.value?.project;
        this.state.current.next({
            msd: lists[0]?.reactantName,
            bb: lists[1]?.reactantName,
            kind: lists[0]?.store.rowCount === lists[1]?.store.rowCount ? 'sandwich' : 'product',
        });
    }
}

interface EnumerationReactant {
    index: number;
    reactant: HTERReactantInstance;
    sites: HTEEnumerationApiReactionSites;
    current_site?: number;
}

interface EnumeratedProduct {
    msd: EnumerationReactant;
    bb: EnumerationReactant;
    product?: string;
    error?: string;
}

class HTEDEnumerationSitesModel extends ReactiveModel {
    private needsEnumeration = new Subject();

    msdTable: DataTableModel<EnumerationReactant>;
    bbTable: DataTableModel<EnumerationReactant>;
    productTable: DataTableModel<EnumeratedProduct>;

    actions = {
        sync: new ModelAction({ onError: 'toast', toastErrorLabel: 'Reaction Sites' }),
        enumerate: new ModelAction({ onError: 'state' }),
    };

    get products() {
        return (this.productTable.store as ObjectDataTableStore<EnumeratedProduct, EnumeratedProduct>).rawRows;
    }

    private async syncSites() {
        const reaction_id = this.options.reaction_chemistry_id;
        const msds = this.options.msds.store.rawRows;
        const bbs = this.options.bbs.store.rawRows;
        const { assets } = this.enumeration.block.products.design.model;

        const [msdSites, bbSites] = await Promise.all([
            EnumerationAPI.reactionSites({
                reaction_id,
                inputs: msds.map((m) => ({ smiles: assets.entities.getStructure(m.identifier)! })),
            }),
            EnumerationAPI.reactionSites({
                reaction_id,
                inputs: bbs.map((m) => ({ smiles: assets.entities.getStructure(m.identifier)! })),
            }),
        ]);

        this.msdTable.store.setRows(
            msdSites.map((s, i) => ({
                index: i,
                reactant: msds[i],
                sites: s,
                current_site: s.possible_reaction_sites.length > 0 ? 0 : undefined,
            }))
        );
        this.msdTable.dataChanged();

        this.bbTable.store.setRows(
            bbSites.map((s, i) => ({
                index: i,
                reactant: bbs[i],
                sites: s,
                current_site: s.possible_reaction_sites.length > 0 ? 0 : undefined,
            }))
        );
        this.bbTable.dataChanged();

        this.actions.enumerate.run(this.enumerationQueue.execute(() => this.enumerate()));
    }

    private async enumerate() {
        const msds = (this.msdTable.store as ObjectDataTableStore<EnumerationReactant, EnumerationReactant>).rawRows;
        const bbs = (this.bbTable.store as ObjectDataTableStore<EnumerationReactant, EnumerationReactant>).rawRows;

        if (
            msds.some((r) => typeof r.current_site !== 'number') ||
            bbs.some((r) => typeof r.current_site !== 'number')
        ) {
            throw new Error('All reactants must have a reaction site defined');
        }

        const enumeration = await EnumerationAPI.enumerate({
            reaction_id: this.options.reaction_chemistry_id,
            msds_inputs: msds.map((r) => ({
                order: r.index,
                smiles: r.sites.smiles,
                included_sites: [r.sites.possible_reaction_sites[r.current_site!].reaction_site],
            })),
            bbs_inputs: bbs.map((r) => ({
                order: r.index,
                smiles: r.sites.smiles,
                included_sites: [r.sites.possible_reaction_sites[r.current_site!].reaction_site],
            })),
        });

        const products = enumeration.map(
            (e) =>
                ({
                    msd: msds[e[0]],
                    bb: bbs[e[1]],
                    product: e[2].products[0],
                    error: e[2].error?.join(', '),
                } satisfies EnumeratedProduct)
        );

        this.productTable.store.setRows(products);
        this.productTable.dataChanged();

        const missingProduct = products.filter((p) => !p.product).length;
        if (missingProduct > 0) {
            ToastService.show({
                type: 'warning',
                message: `Missing ${missingProduct} product${missingProduct === 1 ? '' : 's'}`,
                timeoutMs: 3500,
                id: 'enumeration',
            });
        } else {
            ToastService.show({
                type: 'success',
                message: 'Enumerated',
                timeoutMs: 3500,
                id: 'enumeration',
            });
        }
    }

    private enumerationQueue = new AsyncQueue({ singleItem: true });

    mount() {
        this.subscribe(
            this.needsEnumeration.pipe(throttleTime(1500, undefined, { leading: false, trailing: true })),
            () => {
                this.actions.enumerate.run(this.enumerationQueue.execute(() => this.enumerate()));
            }
        );
    }

    constructor(
        public enumeration: HTEDEnumerateModel,
        public options: { msds: HTEDReactantListModel; bbs: HTEDReactantListModel; reaction_chemistry_id: string }
    ) {
        super();

        const { drawer } = this.enumeration.block.products.design.model;

        const msds = new ObjectDataTableStore<EnumerationReactant, EnumerationReactant>([
            { name: 'index', getter: (v) => v.index },
            { name: 'sites', getter: (v) => v.sites },
            { name: 'current_site', getter: (v) => v.current_site, setter: (v, s) => ({ ...v, current_site: s }) },
        ]);

        const bbs = new ObjectDataTableStore<EnumerationReactant, EnumerationReactant>([
            { name: 'index', getter: (v) => v.index },
            { name: 'sites', getter: (v) => v.sites },
            { name: 'current_site', getter: (v) => v.current_site, setter: (v, s) => ({ ...v, current_site: s }) },
        ]);

        const selectSite = (table: DataTableModel<EnumerationReactant>, rowIndex: number, site: number) => {
            const current = table.store.getValue('current_site', rowIndex);
            if (current === site) return;

            table.store.setValue('current_site', rowIndex, site);
            table.dataChanged();
            this.needsEnumeration.next(undefined);
        };

        const currentSiteColumn = {
            kind: 'obj',
            header: 'Reaction Site',
            filterType: false,
            disableGlobalFilter: true,
            compare: false,
            width: 240,
            format: (v) => '',
            render: ({ value, rowIndex, table }) => {
                const { possible_reaction_sites: sites } = table.store.getValue(
                    'sites',
                    rowIndex
                ) as HTEEnumerationApiReactionSites;

                if (sites.length === 0) return <span className='text-warning'>No Sites</span>;

                return (
                    <div className='d-flex'>
                        {sites.map((site, i) => {
                            const { content, kind } = site.drawn_molecule;
                            if (kind === 'error')
                                return (
                                    <span className='text-danger' key={i}>
                                        {content}
                                    </span>
                                );
                            const current = i === value;
                            return (
                                <div
                                    style={{ flexBasis: '50%', cursor: 'pointer' }}
                                    key={i}
                                    className={`d-flex align-items-center${
                                        current ? ' border border-success rounded' : ''
                                    }`}
                                    onClick={() => selectSite(table, rowIndex, i)}
                                >
                                    <img src={content} alt='' className='w-100' style={{ height: '90%' }} />
                                </div>
                            );
                        })}
                    </div>
                );
            },
        } satisfies Column<number | undefined>;

        const indexColumn = {
            ...Column.int(),
            header: '#',
            width: 40,
            noHeaderTooltip: true,
            noResize: true,
            compare: false,
            render: ({ value }) => <>{value + 1}</>,
        } satisfies Column<number>;

        this.msdTable = new DataTableModel<EnumerationReactant>(msds, {
            columns: {
                index: indexColumn,
                current_site: currentSiteColumn,
            },
            hideNonSchemaColumns: true,
            rowHeight: 90,
        });

        this.bbTable = new DataTableModel<EnumerationReactant>(bbs, {
            columns: {
                index: indexColumn,
                current_site: currentSiteColumn,
            },
            hideNonSchemaColumns: true,
            rowHeight: 90,
        });

        const products = new ObjectDataTableStore<EnumeratedProduct, EnumeratedProduct>([
            { name: 'product', getter: (v) => v.product },
            { name: 'msd', getter: (v) => v.msd },
            { name: 'bb', getter: (v) => v.bb },
            { name: 'error', getter: (v) => v.error },
        ]);

        const currentSite = (header: string) =>
            ({
                kind: 'obj',
                header,
                filterType: false,
                disableGlobalFilter: true,
                compare: false,
                width: 120,
                format: (v) => '',
                render: ({ value }) => {
                    const drawing = value.sites.possible_reaction_sites[value.current_site!].drawn_molecule;
                    if (drawing.kind === 'error') return <span className='text-danger'>{drawing.content}</span>;
                    return (
                        <>
                            <img src={drawing.content} alt='' className='w-100' style={{ height: '60%' }} />
                            <div className='position-absolute start-0 top-0 mt-1 ms-2 text-secondary'>
                                #{value.index + 1}
                            </div>
                        </>
                    );
                },
            } satisfies Column<EnumerationReactant>);

        this.productTable = new DataTableModel<EnumeratedProduct>(products, {
            columns: {
                product: {
                    kind: 'obj',
                    header: 'Product',
                    filterType: false,
                    disableGlobalFilter: true,
                    compare: false,
                    width: 180,
                    format: (v) => '',
                    render: ({ value, rowIndex }) => {
                        const error = products.getValue('error', rowIndex);
                        if (error) return <span className='text-danger'>{error}</span>;
                        if (!value) return <span className='text-warning'>No Product</span>;
                        return (
                            <AsyncMoleculeDrawing
                                drawer={drawer}
                                smiles={value}
                                height='90%'
                                width='100%'
                                autosize
                                showChemDraw={false}
                            />
                        );
                    },
                },
                msd: currentSite(options.msds.reactantName),
                bb: currentSite(options.bbs.reactantName),
            },
            hideNonSchemaColumns: true,
            rowHeight: 90,
        });

        this.actions.sync.run(this.syncSites(), { toastErrorLabel: 'Reaction Sites' });
    }
}

export function EnumerateButton({ model }: { model: HTEDProductBlockModel }) {
    const addBatch = () => {
        const enumeration = new HTEDEnumerateModel(model);
        DialogService.open({
            type: 'generic',
            title: 'Enumerate',
            model: enumeration,
            wrapOk: true,
            content: EnumerateDialogContent,
            confirmButtonContent: 'Next',
            onOk: () => enumeration.enumerate(),
        });
    };

    return (
        <IconButton onClick={addBatch} variant='link' icon={faListOl}>
            Enumerate
        </IconButton>
    );
}

const LabelWidth = 160;

function EnumerateDialogContent({ model }: { model: HTEDEnumerateModel }) {
    const current = useBehavior(model.state.current);
    const options = useBehavior(model.block.products.design.model.state.options);
    const projects = model.block.products.design.model.assets.projectOptions;

    return (
        <div className='vstack gap-2'>
            <LabeledInput label='MSD' labelWidth={LabelWidth}>
                <SimpleSelectOptionInput
                    options={model.reactantListOptions}
                    value={current.msd}
                    allowEmpty
                    setValue={(msd: any) => model.state.current.next({ ...current, msd })}
                />
            </LabeledInput>
            <LabeledInput label='BB' labelWidth={LabelWidth}>
                <SimpleSelectOptionInput
                    options={model.reactantListOptions}
                    value={current.bb}
                    allowEmpty
                    setValue={(bb: any) => model.state.current.next({ ...current, bb })}
                />
            </LabeledInput>
            <LabeledInput label='Rxn Chemistry' labelWidth={LabelWidth}>
                <SelectReactionChemistry
                    options={model.reactionOptions}
                    value={current.chemistry}
                    setValue={(o) => model.state.current.next({ ...current, chemistry: o as any })}
                />
            </LabeledInput>
            <LabeledInput label='Kind' labelWidth={LabelWidth}>
                <SimpleSelectOptionInput
                    options={[
                        // TODO: support sandwich later
                        // ['sandwich', 'Sandwich'],
                        ['product', 'Product'],
                    ]}
                    value={current.kind}
                    setValue={(kind) => model.state.current.next({ ...current, kind })}
                />
            </LabeledInput>
            {!model.hadProject && (
                <LabeledInput label='Project' labelWidth={LabelWidth} tooltip='Project to register new compounds under'>
                    <SimpleSelectOptionInput
                        allowEmpty
                        value={options?.project ?? ''}
                        options={projects}
                        setValue={(v) =>
                            model.block.products.design.model.state.options.next({ ...options, project: v })
                        }
                    />
                </LabeledInput>
            )}
        </div>
    );
}

const ReactionFilter = createFilter({ ignoreAccents: false, stringify: (o: { label: string }) => o.label });

function formatReactionOption({ label }: { label: string }) {
    return <div>{label}</div>;
}

export function SelectReactionChemistry({
    options,
    value,
    setValue,
}: {
    options: HTEDReactionChemistryOption[];
    value?: HTEDReactionChemistryOption;
    setValue: (option?: HTEDReactionChemistryOption) => void;
}) {
    return (
        <div className='w-100'>
            <Select
                options={options}
                value={value}
                isSearchable
                formatOptionLabel={formatReactionOption}
                filterOption={ReactionFilter}
                menuPortalTarget={document.body}
                menuPlacement='top'
                placeholder='Select reaction chemistry...'
                classNames={CustomSelectClassNames}
                styles={DefaultSelectStyles}
                onChange={setValue as any}
            />
        </div>
    );
}

function EnumerationSitesDialogContent({ model }: { model: HTEDEnumerationSitesModel }) {
    useMountedModel(model);

    return (
        <div className='d-flex flex-column' style={{ height: 600 }}>
            <div className='hstack gap-2 flex-grow-1 align-items-stretch'>
                <TablePanel header={model.options.msds.reactantName}>
                    <TableWrapper model={model} table={model.msdTable} />
                </TablePanel>
                <TablePanel header={model.options.bbs.reactantName}>
                    <TableWrapper model={model} table={model.bbTable} />
                </TablePanel>
                <TablePanel header={<ProductsHeader model={model} />}>
                    <ProductsWrapper model={model} />
                </TablePanel>
            </div>
        </div>
    );
}

function TableWrapper({ model, table }: { model: HTEDEnumerationSitesModel; table: DataTableModel }) {
    useBehavior(table.version);
    const state = useModelAction(model.actions.sync);

    if (state.kind === 'loading') {
        return (
            <div className='mt-2'>
                <Loading inline />
            </div>
        );
    }

    return <DataTableControl height='flex' table={table} headerSize='xsm' className='rounded-bottom' />;
}

function ProductsHeader({ model }: { model: HTEDEnumerationSitesModel }) {
    useBehavior(model.productTable.version);

    return <>Products ({model.productTable.store.rowCount})</>;
}

function ProductsWrapper({ model }: { model: HTEDEnumerationSitesModel }) {
    useBehavior(model.productTable.version);
    const state = useModelAction(model.actions.enumerate);

    if (state.kind === 'loading') {
        return (
            <div className='text-center mt-4'>
                <Spinner animation='border' role='status' />
            </div>
        );
    }

    if (state.kind === 'error') {
        return <div className='text-danger m-4'>{state.error.message}</div>;
    }

    return <DataTableControl height='flex' table={model.productTable} headerSize='xsm' className='rounded-bottom' />;
}

function TablePanel({ header, children }: { header: ReactNode; children: ReactNode }) {
    return (
        <div className='d-flex flex-column h-100 border rounded flex-grow-1'>
            <div className='rounded-top p-1 ps-2 font-body-small htew-panel-header'>
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{header}</div>
            </div>
            <div className='position-relative flex-grow-1'>{children}</div>
        </div>
    );
}
