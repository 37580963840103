import { BehaviorSubject } from 'rxjs';
import useBehavior from '../../lib/hooks/useBehavior';

export class LogModel {
    entries = new BehaviorSubject<[kind: string, msg: string, timestamp: Date][]>([]);

    message(kind: 'success' | 'warning' | 'danger' | 'info' | 'secondary' | 'body' | 'white', message: string) {
        const next = [[kind, message, new Date()], ...this.entries.value] as [
            kind: string,
            msg: string,
            timestamp: Date
        ][];
        if (this.options?.capacity && next.length > this.options.capacity) next.pop();
        this.entries.next(next);
    }

    constructor(private options?: { capacity?: number }) {}
}

export function LogMessages({ log }: { log: LogModel }) {
    const entries = useBehavior(log.entries);
    return (
        <ul className='insight-log-messages'>
            {entries.map((e, i) => (
                <li key={i} className={`text-${e[0]}`}>
                    [{e[2].toLocaleTimeString()}] {e[1]}
                </li>
            ))}
        </ul>
    );
}
