import { asArray } from './misc';

/* An (immutable) ordered "multi-key" map */
export class OrderedMap<T> {
    get size() {
        return this.keys.length;
    }

    get(k: number | string) {
        return this.data.get(k);
    }

    getAt(i: number) {
        return this.data.get(this.keys[i][0])!;
    }

    forEach(f: (x: T, i: number) => any) {
        let index = 0;
        for (const k of this.keys) {
            f(this.data.get(k[0])!, index++);
        }
    }

    filter(p: (x: T) => any): T[] {
        const ret: T[] = [];
        for (const k of this.keys) {
            const e = this.data.get(k[0])!;
            if (p(e)) ret.push(e);
        }
        return ret;
    }

    find(p: (x: T) => boolean): T | undefined {
        for (const k of this.keys) {
            const e = this.data.get(k[0])!;
            if (p(e)) return e;
        }
    }

    map<S>(f: (x: T) => S): S[] {
        const ret: S[] = [];
        for (const k of this.keys) {
            ret.push(f(this.data.get(k[0])!));
        }
        return ret;
    }

    toArray() {
        return this.map((x) => x);
    }

    add(xs: T | T[]) {
        const toAdd = asArray(xs);
        if (toAdd.length === 0) return this;

        const keys = this.mutable ? this.keys : [...this.keys];
        const data = this.mutable ? this.data : new Map(this.data);

        for (const x of toAdd) {
            const ks = asArray(this.getKeys(x));
            if (!data.has(ks[0])) {
                keys.push(ks);
            }
            for (const k of ks) {
                data.set(k, x);
            }
        }
        return this.mutable ? this : new OrderedMap(keys, data, this.getKeys, this.mutable);
    }

    reset(xs: T | T[]) {
        if (this.mutable) {
            this.keys = [];
            this.data = new Map();
            this.add(xs);
            return this;
        }
        const map = new OrderedMap([], new Map(), this.getKeys, false);
        return map.add(xs);
    }

    remove(xs: T | T[]): OrderedMap<T> {
        const keys = asArray(xs).flatMap((x) => asArray(this.getKeys(x)));
        return this.removeKeys(keys);
    }

    removeKeys(keysToRemove: (number | string)[]): OrderedMap<T> {
        let hasKey = false;
        for (const k of keysToRemove) {
            if (this.data.has(k)) {
                hasKey = true;
                break;
            }
        }
        if (!hasKey) return this;

        const removeSet = new Set(keysToRemove);
        const keys: ArrayMapKeys = [];
        const data = this.mutable ? this.data : new Map(this.data);

        for (const ks of this.keys) {
            let remove = false;

            for (const k of ks) {
                if (removeSet.has(k)) {
                    remove = true;
                    break;
                }
            }

            if (!remove) {
                keys.push(ks);
                continue;
            }

            for (const k of ks) {
                data.delete(k);
            }
        }

        if (this.mutable) {
            this.keys = keys;
            return this;
        }

        return new OrderedMap(keys, data, this.getKeys, this.mutable);
    }

    // TODO: add re-order/move-to option

    public constructor(
        private keys: ArrayMapKeys,
        private data: Map<number | string, T>,
        private getKeys: ArrayMapGetKeys<T>,
        private mutable: boolean
    ) {
        // NOTE: consider using immutablejs collections here
    }
}

type ArrayMapGetKeys<T> = (x: T) => string | number | (string | number)[];
type ArrayMapKeys = (string | number)[][];

export function orderedMap<T>(getKeys: ArrayMapGetKeys<T>, initial?: T[]) {
    const map = new OrderedMap<T>([], new Map(), getKeys, false);
    return initial?.length ? map.add(initial) : map;
}

export function identityOrderedMap<T extends string | number>(initial?: T[]) {
    return orderedMap<T>((x) => x, initial);
}

export function mutableOrderedMap<T>(getKeys: ArrayMapGetKeys<T>, initial?: T[]) {
    const map = new OrderedMap<T>([], new Map(), getKeys, true);
    if (initial) map.add(initial);
    return map;
}
