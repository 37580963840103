import {
    faCheck,
    faCopy,
    faExclamation,
    faExclamationTriangle,
    faStrikethrough,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Popover } from 'react-bootstrap';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, throttleTime } from 'rxjs';
import { Column, DataTableModel, ObjectDataTableStore } from '../../../components/DataTable';
import { TableCellPopover } from '../../../components/DataTable/common';
import { IconButton } from '../../../components/common/IconButton';
import { SimpleSelectOptionInput } from '../../../components/common/Inputs';
import { ToastService } from '../../../lib/services/toast';
import { AsyncQueue } from '../../../lib/util/async-queue';
import { tryGetErrorMessage } from '../../../lib/util/errors';
import { deepClone } from '../../../lib/util/misc';
import { ModelAction, ReactiveModel } from '../../../lib/util/reactive-model';
import { getUnitFormatter, prefixedUnitValue } from '../../../lib/util/units';
import { Batch, SampleContents } from '../../Compounds/compound-api';
import { ECMSearchResult, Vial, formatSampleContentInSearch, isTubeBarcode } from '../../ECM/ecm-api';
import { BatchLink } from '../../ECM/ecm-common';
import { assignBestInventory } from '../../ECM/requests/request/batches';
import { ECMSearchModel } from '../../ECM/workflows/Search';
import { ManualVialTransferWorkflow, PrepareTransferInput } from '../../ECM/workflows/Transfer';
import { HTEExecution, HTEPInventoryItem, HTEPRobotRunIdT, HTEProtocolIdT } from '../data-model';
import { type HTEMosquitoExecutionModel } from '../execution/mosquito';
import { CombiMap, buildCombiMaps } from '../utils/combi';
import { HTEValidationEntry, validateInventory, validateInventorySample } from '../utils/inventory';
import { ClipboardService } from '../../../lib/services/clipboard';

export type InventoryItem = [protocol_id: HTEProtocolIdT, run_id: HTEPRobotRunIdT, entry: HTEPInventoryItem];

export interface ProtocolInventoryRow {
    original: InventoryItem;
    excluded: boolean;
    identifier?: string;
    batch_identifier?: string;
    concentration?: number;
    solvent: string;
    volume: number;
    sample: string;
    source_barcode?: string;
    transfer_barcode?: string;
    reactant_names?: string[];
    reactant_name_filter: string;
    source_validation: HTEValidationEntry[];
    transfer_validation: HTEValidationEntry[];
}

export interface TransferTargets {
    isLoading?: boolean;
    error?: string;
    vial?: Vial;
    rowIndex?: number;
    options?: [rowIndex: number, label: string][];
}

export interface CurrentTransferInfo {
    identifier: string;
    validation?: HTEValidationEntry[];
    current_barcode?: string;
    formattedAmount?: string;
    formattedVolume?: string;
    formattedConcentration?: string;
}

export class HTEEInventoryModel extends ReactiveModel {
    store: ObjectDataTableStore<ProtocolInventoryRow, InventoryItem>;
    inventoryTable: DataTableModel<ProtocolInventoryRow>;
    transferTable: DataTableModel<ProtocolInventoryRow>;
    combiTable: DataTableModel<ProtocolInventoryRow>;
    transfer = new ManualVialTransferWorkflow({ doNotAutoClear: true });
    search = new ECMSearchModel({ hideNotifySlack: true, equalConcTest: true, allowWizard: true });

    state = {
        transferTargets: new BehaviorSubject<TransferTargets | undefined>(undefined),
        targetBarcode: new BehaviorSubject<string | undefined>(undefined),
        inventoryRefreshed: new BehaviorSubject<boolean>(false),
        showTubes: new BehaviorSubject<boolean>(false),
    };

    actions = {
        load: new ModelAction({
            applyResult: () => {
                this.inventoryTable.dataChanged();
            },
            onError: 'toast',
        }),
        applyTransfer: new ModelAction({ onError: 'toast', toastErrorLabel: 'Apply transfer' }),
    };

    get canEdit() {
        return this.execution.model.canEdit;
    }

    private resolveQueue = new AsyncQueue({ singleItem: true });
    private resolveTransferBarcode = (barcode: string, refresh = true) =>
        this.resolveQueue.execute(async () => {
            try {
                await this._resolveTransferTargets(barcode, refresh);
            } catch (e) {
                this.state.transferTargets.next({ error: tryGetErrorMessage(e) });
            }
        });

    private async _resolveTransferTargets(barcode: string, refresh: boolean) {
        if (!barcode) {
            return this.state.transferTargets.next(undefined);
        }

        const { assets } = this.execution.model;

        this.state.transferTargets.next({ isLoading: true });
        const vial = await assets.getVial(barcode, refresh);

        if (!vial) {
            return this.state.transferTargets.next({ error: 'No vial found' });
        }

        const sample = vial?.sample;
        if (!sample) {
            return this.state.transferTargets.next({ error: 'Vial has no sample' });
        }

        const vialBatch = assets.entities.batchesById.get(sample.batch_id);
        if (!vialBatch) {
            return this.state.transferTargets.next({ error: 'Vial batch not found' });
        }

        const options: [number, string][] = [];
        const getLabel = (rowIndex: number) =>
            `${this.store.getValue('identifier', rowIndex)}: ${this.store.getValue('sample', rowIndex)}`;

        let rowIndex = -1;
        let bestMatch = -1;
        for (const e of this.store.rawRows) {
            rowIndex++;
            if (!this.isVisible(e)) continue;
            const xferBarcode = this.getTransferBarcode(e);
            if (xferBarcode) continue;

            const srcBarcode = this.getSrcBarcode(e);
            const sameBarcode = srcBarcode === barcode;
            const isEligible = sameBarcode || assets.entities.getCompoundId(e[2].identifier!) === vialBatch.compound_id;
            if (isEligible) {
                options.push([rowIndex, getLabel(rowIndex)]);
            }
            if (sameBarcode && bestMatch < 0) {
                bestMatch = rowIndex;
            }
        }

        if (options.length === 0) {
            return this.state.transferTargets.next({ error: 'No target found' });
        }

        bestMatch = Math.max(bestMatch, options[0][0]);
        this.state.transferTargets.next({ vial, rowIndex: bestMatch, options });
    }

    getCurrentTransferInfo(): CurrentTransferInfo | undefined {
        const target = this.state.transferTargets.value;
        const targetBarcode = this.state.targetBarcode.value;

        if (!target || target.rowIndex === undefined || !target.vial || target.error) {
            return undefined;
        }

        const { assets } = this.execution.model;
        const e = this.store.rawRows[target.rowIndex][2];

        const sample = target.vial.sample;
        const fw = assets.entities.batchesById.get(sample?.batch_id!)?.formula_weight! as number;
        const amount_g = this.getAmountInG(e, fw);

        const { formatCSV: formatAmount, niceUnit: amountUnit } = getUnitFormatter('g', 'm');
        const { formatCSV: formatVolume, niceUnit: volumeUnit } = getUnitFormatter('L', 'u', { factor: 1e3 });
        const { formatCSV: formatConcentration, niceUnit: concentrationUnit } = getUnitFormatter('M', 'm');

        if (!targetBarcode || isTubeBarcode(targetBarcode)) {
            return {
                identifier: assets.entities.getIdentifier(e.identifier!),
                validation: validateInventorySample(e, target.vial?.sample, assets),
                formattedAmount: `${formatAmount(amount_g)} ${amountUnit}`,
                formattedConcentration: `${formatConcentration(e.concentration)} ${concentrationUnit}`,
                formattedVolume: `${formatVolume(e.volume)} ${volumeUnit}`,
            };
        }

        const TransferOverageM3 = 50e-6 * 1e-3; // 50 ul
        const overage_amount_g = this.getAmountInG(e, fw, TransferOverageM3);

        return {
            identifier: assets.entities.getIdentifier(e.identifier!),
            validation: validateInventorySample(e, target.vial?.sample, assets),
            formattedAmount: `${formatAmount(overage_amount_g)} (${formatAmount(amount_g)} + ${formatAmount(
                overage_amount_g - amount_g
            )}) ${amountUnit}`,
            formattedConcentration: `${formatConcentration(e.concentration)} ${concentrationUnit}`,
            formattedVolume: `${formatVolume(e.volume + TransferOverageM3)} (${formatVolume(e.volume)} + ${formatVolume(
                TransferOverageM3
            )}) ${volumeUnit}`,
        };
    }

    getEntries() {
        return this.store.rawRows.map((e) => e[2]);
    }

    refreshInventory = async () => {
        this.execution.model.assets.vials.clear();
        await this.actions.load.run(this.fetchInventory());
        this.syncSearch();
        this.state.inventoryRefreshed.next(true);
    };

    autoAssignInventory = () => {
        const execution: HTEExecution = deepClone(this.execution.data);

        const { assets } = this.execution.model;
        let nChanged = 0;

        for (const item of this.store.rawRows) {
            const barcode = this.getSrcBarcode(item);
            if (!barcode) {
                const inventory = assets.getInventory(item[2])?.results;
                if (inventory?.[0]) {
                    execution.products[item[0]].robot_runs[item[1]].barcodes.source_vials[item[2].id] =
                        inventory[0].barcode;
                    nChanged++;
                }
            }
        }

        if (!nChanged) {
            ToastService.show({
                type: 'info',
                message: 'No changes',
                timeoutMs: 1500,
            });
        } else {
            ToastService.show({
                type: 'info',
                message: `${nChanged} barcode${nChanged === 1 ? '' : 's'} assigned`,
                timeoutMs: 1500,
            });
            this.execution.state.data.next(execution);
        }
    };

    copySourceBarcodes = () => {
        const barcodes = this.store
            .getColumnValues('source_barcode')
            .filter((v) => !!v)
            .join('\n');
        ClipboardService.copyText(barcodes, 'Copy Source Barcodes', 'Copy Barcodes');
    };

    private async applyTransfer(input: PrepareTransferInput) {
        if (!input.target_barcode) return this.transfer.clear();

        const transfer = this.state.transferTargets.value;
        if (transfer?.rowIndex === undefined) return this.transfer.clear();

        const [pid, rid, entry] = this.store.rawRows[transfer.rowIndex];

        await this.execution.model.assets.syncInventory([entry], true);

        this.execution.updateBarcode(pid, rid, entry.id, input.target_barcode, 'transfer_vials');

        const nextOptions = transfer.options?.filter((v) => v[0] !== transfer.rowIndex);
        if (nextOptions?.length) {
            this.state.transferTargets.next({
                ...transfer,
                options: nextOptions,
                rowIndex: nextOptions[0][0],
            });
        } else {
            this.state.transferTargets.next(undefined);
        }

        this.transfer.clear(!!nextOptions?.length);

        ToastService.show({
            type: 'success',
            message: `Assigned ${input.target_barcode} to ${this.execution.model.assets.entities.getIdentifier(
                entry.identifier!
            )}`,
            timeoutMs: 1500,
        });
    }

    private getAmountInG(e: HTEPInventoryItem, fw: number, volumeOverage: number = 0) {
        return fw * e.concentration! * (e.volume + volumeOverage) * 1e3;
    }

    syncSearch() {
        const { assets } = this.execution.model;
        const searchResults: ECMSearchResult[] = [];
        const batches: Record<number, Batch> = {};
        const not_found: string[] = [];
        for (const e of this.store.rawRows) {
            if (!this.isInCurrentRun(e) || !e[2].identifier) continue;

            const identifier = assets.entities.getIdentifier(e[2].identifier!);

            let results = this.execution.model.assets.getInventory(e[2])?.results;
            if (!results) {
                not_found.push(identifier);
                continue;
            }

            results = results.map((r) => ({ ...r }));

            for (const r of results) {
                if (!r.sample) continue;

                const fw = assets.entities.batchesById.get(r.sample!.batch_id)?.formula_weight! as number;
                r.total_requested = {
                    solute_mass: this.getAmountInG(e[2], fw),
                    solvent: e[2].solvent,
                    solvent_volume: e[2].volume,
                    concentration: e[2].concentration,
                };
                batches[r.sample.batch_id] = assets.entities.batchesById.get(r.sample.batch_id)!;
            }

            const sample: SampleContents = {
                solute_mass: this.getAmountInG(e[2], assets.entities.getFW(e[2].identifier)!),
                solvent: e[2].solvent,
                solvent_volume: e[2].volume,
                concentration: e[2].concentration,
            };

            // this mutates the results array
            assignBestInventory({
                sortedResults: results,
                identifier,
                sample,
            });

            for (const r of results) {
                searchResults.push(r);
            }
        }

        this.search.setCustomResult(searchResults, { batches, not_found });
    }

    private async fetchInventory() {
        await this.execution.model.assets.syncInventory(this.getEntries(), true);
        this.transferTable.dataChanged();
    }

    private mountCount = 0;

    dispose(): void {
        this.mountCount = Math.max(0, this.mountCount - 1);
        if (!this.mountCount) super.dispose();
    }

    mount() {
        this.mountCount++;
        if (this.mountCount > 1) return;

        const transferInput = this.transfer.state.input.pipe(
            map((i) => i.source_barcode),
            throttleTime(400, undefined, { leading: false, trailing: true }),
            distinctUntilChanged()
        );

        this.subscribe(
            combineLatest([transferInput, this.state.inventoryRefreshed, this.execution.state.run]),
            ([barcode]) => this.resolveTransferBarcode(barcode)
        );

        const transferTarget = this.transfer.state.input.pipe(
            map((i) => i.target_barcode),
            throttleTime(400, undefined, { leading: false, trailing: true }),
            distinctUntilChanged()
        );

        this.subscribe(transferTarget, (barcode) => this.state.targetBarcode.next(barcode));

        this.subscribe(this.transfer.events.submitted, (entry) => {
            this.actions.applyTransfer.run(this.applyTransfer(entry));
        });

        this.subscribe(this.state.transferTargets, (t) => {
            if (t?.rowIndex !== undefined) {
                this.transferTable.setSelection([t.rowIndex]);
            } else {
                this.transferTable.setSelection([]);
            }
        });

        this.subscribe(this.execution.state.data, () => {
            this.inventoryTable.dataChanged();
        });

        // Apply transfer table filters
        this.subscribe(
            combineLatest([this.execution.state.data, this.execution.state.run, this.state.showTubes]),
            ([data, run, showTubes]) => {
                const { plate } = this.execution;
                this.transferTable.setFiltersAndSortBy(
                    [
                        [
                            'original',
                            (v: InventoryItem) =>
                                v[2].identifier &&
                                v[0] === plate?.id &&
                                v[1] === run?.id &&
                                !data.products[v[0]].excluded_inventory_ids.includes(v[2].id),
                        ],
                        ['source_barcode', (v?: string) => showTubes || !isTubeBarcode(v)],
                    ],
                    this.transferTable.state.sortBy
                );
                this.transferTable.dataChanged();
            }
        );

        // Apply combi table filters
        const singleIdentifiers = new Set(
            this.execution.protocol.design.reactions.flatMap((r) =>
                Array.from(Object.values(r.template.reactants)).map((i) => i.identifier)
            )
        );
        this.subscribe(combineLatest([this.execution.state.data, this.execution.state.run]), ([data, run]) => {
            const { plate } = this.execution;
            this.combiTable.setFiltersAndSortBy(
                [
                    [
                        'original',
                        (v: InventoryItem) =>
                            v[0] === plate?.id &&
                            v[1] === run?.id &&
                            (!v[2].identifier || singleIdentifiers.has(v[2].identifier)) &&
                            !data.products[v[0]].excluded_inventory_ids.includes(v[2].id),
                    ],
                ],
                this.combiTable.state.sortBy
            );
            this.combiTable.setSelection([], false);
            this.combiTable.dataChanged();
        });
    }

    getUnassignedItems() {
        const unassinged: HTEPInventoryItem[] = [];
        for (const e of this.store.rawRows) {
            if (!e[2].identifier || this.isExcluded(e)) continue;

            if (!this.getSrcBarcode(e) && !e[2].identifier.includes('-')) {
                unassinged.push(e[2]);
            }
        }

        return unassinged;
    }

    getCombiMaps(): CombiMap[] {
        const sel = this.combiTable.getSelectedRowIndices();
        if (sel.length === 0) return [];

        const { plate, run } = this.execution;
        if (!plate || !run) return [];

        const entry = this.store.rawRows[sel[0]];
        const barcode = this.getTransferBarcode(entry) || this.getSrcBarcode(entry);

        return buildCombiMaps({
            productPlate: plate?.product_plate,
            solventPlates: run.solvent_plates,
            barcode,
            item: entry[2],
        });
    }

    isInVerso(barcode?: string) {
        if (!barcode) return false;
        return this.execution.model.assets.inventoryByBarcode.get(barcode)?.location === 'verso';
    }

    private isInCurrentRun(e: InventoryItem) {
        if (!e[2].identifier) return false;
        const { plate, run, data } = this.execution;
        return (
            e[2].identifier &&
            e[0] === plate?.id &&
            e[1] === run?.id &&
            !data.products[e[0]].excluded_inventory_ids.includes(e[2].id)
        );
    }

    private isVisible(e: InventoryItem) {
        if (!this.isInCurrentRun(e)) return false;
        const showTubes = this.state.showTubes.value;
        if (showTubes) return true;
        return !isTubeBarcode(this.getSrcBarcode(e));
    }

    getSrcBarcode = (v: InventoryItem) =>
        this.execution.data.products[v[0]].robot_runs[v[1]].barcodes.source_vials[v[2].id];

    getTransferBarcode = (v: InventoryItem) =>
        this.execution.data.products[v[0]].robot_runs[v[1]].barcodes.transfer_vials[v[2].id];

    isExcluded = (v: InventoryItem) => this.execution.data.products[v[0]].excluded_inventory_ids.includes(v[2].id);

    constructor(public execution: HTEMosquitoExecutionModel) {
        super();

        const all = execution.protocol.product_plates.flatMap((p) =>
            p.runs.flatMap((r) => r.inventory.map((i) => [p.id, r.id, i] as InventoryItem))
        );

        const { assets } = execution.model;

        this.store = new ObjectDataTableStore<ProtocolInventoryRow, InventoryItem>(
            [
                { name: 'original', getter: (v) => v },
                { name: 'excluded', getter: this.isExcluded },
                { name: 'identifier', getter: (v) => assets.entities.getIdentifier(v[2].identifier!) },
                { name: 'reactant_names', getter: (v) => v[2].reactant_names },
                { name: 'reactant_name_filter', getter: (v) => v[2].reactant_names.join('\n') },
                { name: 'concentration', getter: (v) => v[2].concentration },
                { name: 'solvent', getter: (v) => v[2].solvent },
                { name: 'volume', getter: (v) => v[2].volume },
                {
                    name: 'sample',
                    getter: (v) =>
                        formatSampleContentInSearch({
                            concentration: v[2].concentration,
                            solvent_volume: v[2].volume,
                            solvent: v[2].solvent,
                        }),
                },
                {
                    name: 'batch_identifier',
                    getter: (v) => {
                        const barcode = this.getTransferBarcode(v) || this.getSrcBarcode(v);
                        if (!barcode) return undefined;
                        const batch_id = assets.inventoryByBarcode.get(barcode)?.sample?.batch_id;
                        const batch = assets.entities.batchesById.get(batch_id!);
                        return assets.entities.getIdentifier(batch?.universal_identifier!);
                    },
                },
                { name: 'source_barcode', getter: this.getSrcBarcode },
                {
                    name: 'source_validation',
                    getter: (v) => validateInventory(v[2], this.getSrcBarcode(v), this.execution.model.assets),
                },
                { name: 'transfer_barcode', getter: this.getTransferBarcode },
                {
                    name: 'transfer_validation',
                    getter: (v) => validateInventory(v[2], this.getTransferBarcode(v), this.execution.model.assets),
                },
            ],
            all
        );

        const selectBarcode = (rowIndex: number, barcode: string, kind: 'source_vials' | 'transfer_vials') => {
            const [pid, rid, entry] = all[rowIndex];
            execution.updateBarcode(pid, rid, entry.id, barcode, kind);
        };

        const toggleExcludeItem = (e: InventoryItem, current: boolean) => {
            execution.excludeInventoryItem(e[0], e[2].id, !current);
        };

        const barcodeSelect = (
            rowIndex: number,
            barcode: string | undefined,
            kind: 'source_vials' | 'transfer_vials',
            showBadge = true
        ) => {
            const entry = all[rowIndex][2];
            if (!entry.identifier) return null;
            const options = assets.getInventory(entry)?.options;
            const vial = assets.inventoryByBarcode.get(barcode!);
            let srcKind: string = 'none';

            if (this.isInVerso(barcode)) {
                srcKind = 'vrso'; // VRSO on purpose to keep it at 4 chars
            } else if (isTubeBarcode(barcode)) {
                srcKind = 'tube';
            } else if (barcode) {
                srcKind = 'vial';
            }

            const invalidOptionLabel = vial
                ? `${
                      vial.status === 'Disposed' ? '[Disposed] ' : '[Non-inv] '
                  }${barcode}: ${formatSampleContentInSearch(vial.sample)}`
                : undefined;

            return (
                <>
                    {showBadge && (
                        <Badge className={`me-1 htew-inventory-${srcKind}`} style={{ width: 44 }}>
                            {srcKind === 'none' ? '-' : srcKind.toUpperCase()}
                        </Badge>
                    )}
                    <SimpleSelectOptionInput
                        value={barcode ?? ''}
                        style={{ backgroundColor: 'transparent', borderWidth: 0, borderRadius: 0 }}
                        className='ps-1 font-body-xsmall'
                        allowEmpty
                        options={options ?? emptyOptions}
                        setValue={(v) => selectBarcode(rowIndex, v, kind)}
                        disabled={!this.canEdit}
                        invalidOptionLabel={invalidOptionLabel}
                    />
                    <button
                        type='button'
                        title='Click to copy barcode'
                        onClick={() => {
                            navigator.clipboard.writeText(barcode ?? '');
                            ToastService.show({
                                type: 'success',
                                message: 'Copied to Clipboard',
                                timeoutMs: 1500,
                            });
                        }}
                        className='bg-transparent border-0 ms-1 hte-experiment-copy-barcode'
                        disabled={!barcode}
                    >
                        <FontAwesomeIcon icon={faCopy} fixedWidth className='text-secondary' />
                    </button>
                </>
            );
        };

        const reactant_names: Column<string[] | undefined> = {
            kind: 'obj',
            header: 'Reactant',
            filterType: false,
            disableGlobalFilter: true,
            compare: (a, b) => (a?.[0]! <= b?.[0]! ? -1 : 1),
            width: 80,
            format: (v) => '',
            render: ({ value }) => (
                <div className='d-flex flex-nowrap'>
                    {value?.map((v) => (
                        <Badge key={v} bg='info' style={{ fontSize: 8 }} className='me-2'>
                            {v}
                        </Badge>
                    ))}
                </div>
            ),
        };

        const emptyOptions: any[] = [];

        this.inventoryTable = new DataTableModel<ProtocolInventoryRow>(this.store, {
            columns: {
                excluded: {
                    kind: 'obj',
                    header: <></>,
                    noHeaderTooltip: true,
                    filterType: false,
                    disableGlobalFilter: true,
                    compare: false,
                    noResize: true,
                    width: 36,
                    format: (v) => '',
                    render: ({ value, rowIndex }) => {
                        const e = this.store.rawRows[rowIndex];
                        if (!e[2].identifier) return null;
                        return (
                            <IconButton
                                disabled={!this.canEdit}
                                onClick={() => toggleExcludeItem(e, value)}
                                icon={faStrikethrough}
                                variant='link'
                                className={`text-${value ? 'danger' : 'secondary'} position-absolute start-0`}
                                title='Exclude inventory. All relevant reactions will be omitted from the plate maps.'
                            />
                        );
                    },
                },
                identifier: {
                    ...Column.str(),
                    header: 'Input identifier',
                    render: ({ value }) => (value ? <BatchLink identifier={value} withQuery /> : ''),
                    width: 160,
                },
                concentration: {
                    ...Column.float(),
                    header: 'Conc.',
                    width: 90,
                    render: ({ value }) => prefixedUnitValue(value, 'M'),
                },
                solvent: { ...Column.str(), width: 90 },
                volume: {
                    ...Column.float(),
                    header: 'Volume',
                    width: 90,
                    render: ({ value }) => prefixedUnitValue(value, 'L', { factor: 1e3 }),
                },
                batch_identifier: {
                    ...Column.str(),
                    render: ({ value }) => (value ? <BatchLink identifier={value} withQuery /> : ''),
                    width: 160,
                },
                source_barcode: {
                    ...Column.str(),
                    header: 'Source barcode',
                    width: 340,
                    render: ({ value: barcode, rowIndex }) => barcodeSelect(rowIndex, barcode, 'source_vials'),
                },
                transfer_barcode: {
                    ...Column.str(),
                    header: 'Transfer barcode',
                    width: 280,
                    render: ({ value }) => {
                        const vial = assets.inventoryByBarcode.get(value!);
                        if (!vial) return '';
                        return `${value}: ${formatSampleContentInSearch(vial.sample)}`;
                    },
                },
                reactant_names,
                source_validation: {
                    kind: 'obj',
                    header: 'Source validation',
                    filterType: false,
                    disableGlobalFilter: true,
                    compare: false,
                    width: 350,
                    format: (v) => '',
                    render: ({ value, rowIndex }) => this.validation(value, rowIndex),
                },
                transfer_validation: {
                    kind: 'obj',
                    header: 'Transfer validation',
                    filterType: false,
                    disableGlobalFilter: true,
                    compare: false,
                    width: 350,
                    format: (v) => '',
                    render: ({ value, rowIndex }) => {
                        const e = this.store.rawRows[rowIndex];
                        if (this.getTransferBarcode(e)) {
                            return this.validation(value, rowIndex);
                        }
                        return null;
                    },
                },
                reactant_name_filter: Column.str(),
            },
            hideNonSchemaColumns: true,
            globalFilterHiddenColumns: true,
        });

        this.inventoryTable.setColumnVisibility('reactant_name_filter', false);

        this.transferTable = new DataTableModel<ProtocolInventoryRow>(this.store, {
            columns: {
                original: {
                    kind: 'obj',
                    disableGlobalFilter: true,
                    compare: false,
                    filterFn: () => (x: any, test: (v?: any) => boolean) => test(x),
                },
                identifier: {
                    ...Column.str(),
                    render: ({ value }) => (value ? <BatchLink identifier={value} withQuery /> : ''),
                    width: 160,
                },
                sample: {
                    ...Column.str(),
                    header: 'Sample',
                    width: 140,
                    disableGlobalFilter: true,
                    compare: false,
                    render: ({ value }) => value,
                },
                reactant_names,
                transfer_barcode: {
                    ...Column.str(),
                    header: 'Barcode',
                    width: 280,
                    compare: false,
                    render: ({ value: barcode, rowIndex }) => barcodeSelect(rowIndex, barcode, 'transfer_vials', false),
                },
                transfer_validation: {
                    kind: 'obj',
                    header: 'Validation',
                    filterType: false,
                    disableGlobalFilter: true,
                    compare: false,
                    width: 350,
                    format: (v) => '',
                    render: ({ value, rowIndex }) => this.validation(value, rowIndex),
                },
                source_barcode: {
                    ...Column.str(),
                    header: <span title='Can be changed in the inventory tab'>Ref. Barcode</span>,
                    disableGlobalFilter: true,
                    compare: false,
                    width: 120,
                    filterFn: () => (x: any, test: (v?: any) => boolean) => test(x),
                },
                reactant_name_filter: Column.str(),
            },
            hideNonSchemaColumns: true,
            globalFilterHiddenColumns: true,
        });

        this.transferTable.setColumnVisibility('original', false);
        this.transferTable.setColumnVisibility('reactant_name_filter', false);

        this.combiTable = new DataTableModel<ProtocolInventoryRow>(this.store, {
            columns: {
                original: {
                    kind: 'obj',
                    disableGlobalFilter: true,
                    compare: false,
                    filterFn: () => (x: any, test: (v?: any) => boolean) => test(x),
                },
                identifier: {
                    ...Column.str(),
                    header: 'Input identifier',
                    render: ({ value }) => (value ? <BatchLink identifier={value} withQuery /> : ''),
                    width: 160,
                },
                concentration: {
                    ...Column.float(),
                    header: 'Conc.',
                    width: 90,
                    render: ({ value }) => prefixedUnitValue(value, 'M'),
                },
                solvent: { ...Column.str(), width: 90 },
                volume: {
                    ...Column.float(),
                    header: 'Tot. Volume',
                    width: 100,
                    render: ({ value }) => prefixedUnitValue(value, 'L', { factor: 1e3 }),
                },
                batch_identifier: {
                    ...Column.str(),
                    render: ({ value }) => (value ? <BatchLink identifier={value} withQuery /> : ''),
                    width: 160,
                },
                source_barcode: {
                    ...Column.str(),
                    header: 'Source vial',
                    width: 280,
                    render: ({ value }) => {
                        const vial = assets.inventoryByBarcode.get(value!);
                        if (!vial) return '';
                        return `${value}: ${formatSampleContentInSearch(vial.sample)}`;
                    },
                },
                transfer_barcode: {
                    ...Column.str(),
                    header: 'Transfer vial',
                    width: 280,
                    render: ({ value }) => {
                        const vial = assets.inventoryByBarcode.get(value!);
                        if (!vial) return '';
                        return `${value}: ${formatSampleContentInSearch(vial.sample)}`;
                    },
                },
                reactant_names,
            },
            hideNonSchemaColumns: true,
            globalFilterHiddenColumns: true,
        });
        this.combiTable.setColumnVisibility('original', false);

        this.refreshInventory();
    }

    private validation(validation: HTEValidationEntry[], rowIndex: number) {
        if (validation.length === 0) {
            return <FontAwesomeIcon icon={faCheck} size='sm' className='text-success' fixedWidth />;
        }
        if (validation.length === 1) {
            return validationEntry(validation[0]);
        }

        return (
            <TableCellPopover
                id={`validation-${rowIndex}`}
                buttonClassName='btn btn-link p-0 m-0 font-body-small text-wrap'
                buttonContent={validationEntry(validation[0], validation.length)}
                popoverHeader={<Popover.Header>Validation</Popover.Header>}
                popoverBody={
                    <Popover.Body>
                        <ul className='list-group' style={{ width: 360 }}>
                            {validation.map((e, i) => validationEntry(e, undefined, i))}
                        </ul>
                    </Popover.Body>
                }
                className='d-inline-block'
                inBody
            />
        );
    }
}

export function validationEntry(e: HTEValidationEntry, count?: number, key?: any) {
    return (
        <div
            className={e[0] === 'error' ? 'text-danger font-body-xsmall' : 'text-warning font-body-xsmall'}
            key={key}
            title={e[1]}
        >
            <FontAwesomeIcon
                icon={e[0] === 'error' ? faExclamationTriangle : faExclamation}
                className='me-2'
                fixedWidth
            />
            {e[1]}
            {!!count && ` (and ${count - 1} more)`}
        </div>
    );
}
