import api from '../../api';
import { DataTableStore, columnDataTableStore } from '../../components/DataTable';
import { type AssayProperty } from '../../lib/models/biology';
import type { AssayValueType } from '../../lib/assays/models';
import { type DateLike } from '../../lib/util/dates';
import { decodeEntosMsgpack } from '../../lib/util/serialization';

export interface BoxVersion {
    major: number;
    minor: number;
    patch: number;
}

export type BoxStatus = 'registering' | 'available' | 'unavailable' | 'retired';

export interface BoxIdentifierBase {
    name: string;
    kind: string;
    version: BoxVersion;
}

export interface BoxIdentifier extends BoxIdentifierBase {
    id: number;
    status: BoxStatus;
    shorthand: string;
}

export interface BoxInfo extends BoxIdentifier {
    property?: AssayProperty;
    created_on: DateLike;
    modified_on?: DateLike;
}

export interface BoxArtifact {
    folder_name: string;
    name: string;
    version: string;
}

export function boxIdentifierLabel(box: BoxIdentifier) {
    return box.shorthand ? `B(${box.id}, ${box.shorthand}, ${box.name})` : `B(${box.id}, ${box.name})`;
}

export interface BoxDetailsData {
    author: string;
    changelog: string;
    conda_environment: string;
    created_on: number;
    description: string;
    id: number;
    identifier: BoxIdentifier;
    inputs: any;
    results: any;
    metadata: Record<string, any>;
    modified_on: number;
    repository: string;
    sample_code: string;
    status: string;
    shorthand: string;
}

export interface BoxComputeResult {
    completed: { [key: string]: any }[]; // key is a SMILES string
    errored: { [key: string]: string }[];
    pending: string[];
}

export interface BoxTrainingDataRow {
    SMILES: string;
    w: number;
    y: AssayValueType;
    STANDARDIZED_SMILES?: string;
    split: 'train' | 'test' | 'valid';
    identifier?: string;
    index?: number;
}

export interface BoxTrainingDataResult {
    dataframe: DataTableStore<BoxTrainingDataRow>;
    bins: any[];
    units: string;
}

export function boxVersionToString(version: BoxVersion) {
    return `${version.major}.${version.minor}.${version.patch}`;
}

export function boxVersionCompare(a: BoxVersion, b: BoxVersion) {
    if (a.major !== b.major) return a.major - b.major;
    if (a.minor !== b.minor) return a.minor - b.minor;
    return a.patch - b.patch;
}

export const BoxAPI = {
    list: async ({ latest = true, kind, name }: { latest?: boolean; kind?: string; name?: string } = {}): Promise<
        DataTableStore<BoxInfo>
    > => {
        const { data } = await api.client.get('boxes', { params: { latest, kind, name }, responseType: 'arraybuffer' });
        return columnDataTableStore(decodeEntosMsgpack(new Uint8Array(data), { eoi: 'hex' }));
    },
    get: async (id: number): Promise<BoxDetailsData> => {
        const { data } = await api.client.get(`boxes/${id}`);
        return data;
    },
    compute: async (id: number, smiles: string[]): Promise<BoxComputeResult> => {
        const { data } = await api.client.post(`boxes/${id}/compute`, { smiles });
        return data;
    },
    query: async (kind: string, name: string): Promise<BoxDetailsData[]> => {
        const { data } = await api.client.post('boxes/query', { kind, name });
        return data;
    },
    downloadTrainingData: async (id: number): Promise<BoxTrainingDataResult> => {
        const { data } = await api.client.get(`boxes/${id}/download_training_data`, {
            responseType: 'arraybuffer',
        });
        const { dataframe, bins, units } = decodeEntosMsgpack(new Uint8Array(data));
        return { dataframe: columnDataTableStore(dataframe), bins, units };
    },
};
