import { useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAsyncAction } from '../../lib/hooks/useAsyncAction';
import { tryGetErrorMessage } from '../../lib/util/errors';
import { CompoundAPI } from './compound-api';

export function SearchByIdentifier() {
    const navigate = useNavigate();
    const inputRef = useRef<HTMLInputElement>();
    const [barcodeSearch, loadBarcodeSearch] = useAsyncAction<string>();

    async function handleSubmit() {
        if (inputRef.current) {
            const identifierText = inputRef.current.value;
            const compoundIdentifier = await loadBarcodeSearch(CompoundAPI.getCompoundFromIdentifier(identifierText));
            if (compoundIdentifier) navigate(`/compounds/${compoundIdentifier}`);
        }
    }

    return (
        <div className='entos-identifier-search flex-grow-1'>
            <div className='text-button-combo d-flex'>
                <Form.Control
                    ref={inputRef as any}
                    type='text'
                    placeholder='Search by compound/batch identifier, sample barcode, or supplier ID'
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') handleSubmit();
                    }}
                    onBlur={() => loadBarcodeSearch(undefined)}
                />
                <Button variant='primary' disabled={barcodeSearch.isLoading} onClick={() => handleSubmit()}>
                    Submit
                </Button>
            </div>
            {barcodeSearch.error && (
                <p className='position-absolute text-danger mt-2'>{tryGetErrorMessage(barcodeSearch.error)}</p>
            )}
        </div>
    );
}
