import { faBug, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { DataTableStore } from '../../../components/DataTable';
import { ErrorMessage } from '../../../components/common/Error';
import { SingleFileUpload } from '../../../components/common/FileUpload';
import { SimpleSelectOptionInput, TextInput } from '../../../components/common/Inputs';
import Loading from '../../../components/common/Loading';
import { PropertyNameValue } from '../../../components/common/PropertyNameValue';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import useBehaviorSubject from '../../../lib/hooks/useBehaviorSubject';
import { DialogService } from '../../../lib/services/dialog';
import { ToastService } from '../../../lib/services/toast';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { arrayEllipsis, capitalizeFirst } from '../../../lib/util/misc';
import { formatUnit } from '../../../lib/util/units';
import { HTEApi, HTEExperimentRow } from '../../HTE/experiment-api';
import { formatHTEId } from '../../HTE/experiment-data';
import { PlateVisual, PlateVisualModel } from '../../HTE/plate/PlateVisual';
import { getFirstSelectedIndex, getWellIndexLabel } from '../../HTE/plate/utils';
import { CompoundIdentifier } from '../../HTE/steps/reagents-model';
import { ECMApi, PlateKinds, PreparePlateUploadData, PreparePlateUploadEntry } from '../ecm-api';
import {
    ECMPageTemplate,
    ECMPlateWellContents,
    colorPlateByConcentrations,
    labelPlateByControlKinds,
} from '../ecm-common';

export function ECMPlateUpload() {
    return (
        <ECMPageTemplate page='plate-upload' withFooter>
            <PlateUploadrapper />
        </ECMPageTemplate>
    );
}

const PlatePurposes = ['Library stock', 'nARP', 'Intermediate', 'CGI', 'Biochemical', 'QCMS', 'MetID', 'MetStab'];

const PlatePurposeOptions = [['<custom>', '< Custom >'], ...PlatePurposes.map((o) => [o.toLowerCase(), o])];

export class ECMUploadPlateModel {
    readonly experimentOptions: [number, string][];

    state = {
        data: new BehaviorSubject<PreparePlateUploadData>(undefined as any),
        entries: new BehaviorSubject<UploadEntryWrapper[]>([]),
    };

    get data() {
        return this.state.data.value;
    }

    get entries() {
        return this.state.entries.value;
    }

    get experimentIdentifier() {
        const { experiment_id } = this.data;
        return this.experimentOptions.find((o) => o[0] === experiment_id)?.[1];
    }

    async submit() {
        const data: PreparePlateUploadData = {
            ...this.data,
            plates: this.entries.map((e) => e.data),
        };

        try {
            for (const e of this.entries) e.state.isUploading.next(true);
            await ECMApi.uploadPlates(data);
            ToastService.show({
                type: 'success',
                message: `${data.plates.length} plate${data.plates.length > 1 ? 's' : ''} uploaded`,
                timeoutMs: 2500,
            });
            this.fileSubject.next(null);
            this.options?.onSubmit?.(this.entries.map((e) => e.data));
        } catch (err) {
            reportErrorAsToast('Plate upload', err);
            return false;
        } finally {
            for (const e of this.entries) e.state.isUploading.next(false);
        }
    }

    updateExperiment(experiment_id?: number) {
        this.state.data.next({ ...this.data, experiment_id });
    }

    constructor(
        data: PreparePlateUploadData,
        experiments: DataTableStore<HTEExperimentRow>,
        private fileSubject: BehaviorSubject<File | null>,
        public options?: {
            onSubmit?: (xs: PreparePlateUploadEntry[]) => any;
        }
    ) {
        this.state.data.next(data);
        this.state.entries.next(data.plates.map((e, i) => new UploadEntryWrapper(i, e, this)));
        this.experimentOptions = experiments.toObjects().map((e) => [e.id, `${formatHTEId(e.id)}: ${e.name}`]);
        this.experimentOptions.sort((a, b) => a[0] - b[0]);
    }
}

class UploadEntryWrapper {
    state = {
        data: new BehaviorSubject<PreparePlateUploadEntry>(undefined as any),
        isUploading: new BehaviorSubject(false),
    };

    get data(): PreparePlateUploadEntry {
        return this.state.data.value;
    }

    // eslint-disable-next-line
    constructor(public key: number, data: PreparePlateUploadEntry, public model: ECMUploadPlateModel) {
        this.state.data.next(data);
    }
}

export async function ecmUploadPlateFile(
    file: File,
    fileSubject: BehaviorSubject<File | null>,
    options?: {
        request_identifier?: string;
        onSubmit?: (xs: PreparePlateUploadEntry[]) => any;
    }
) {
    try {
        const [upload, experiments] = await Promise.all([
            ECMApi.preparePlateUpload(file, { 'request identifier': options?.request_identifier }),
            HTEApi.list(),
        ]);
        return new ECMUploadPlateModel(upload, experiments, fileSubject, { onSubmit: options?.onSubmit });
    } catch (err) {
        reportErrorAsToast('Plate Upload', err);
    }
}

function PlateUploadrapper() {
    const [model, loadModel] = useAsyncAction<ECMUploadPlateModel>();

    return (
        <>
            <div className='d-flex flex-column h-100'>
                <UploadControls load={loadModel} />
                <div className='flex-grow-1' style={{ overflow: 'hidden', overflowY: 'auto' }}>
                    {model.isLoading && <Loading />}
                    {model.error && <ErrorMessage header='Error Uploading Plates' message={`${model.error}`} />}
                    {model.result && <ECMUploadPlateEntries model={model.result} />}
                    {!model.result && !model.isLoading && !model.error && <ECMUploadPlateInfo />}
                </div>
            </div>
            <ECMUploadPlateFooter model={model.result} />
        </>
    );
}

const BASIC_COLUMNS = [
    'Plate Barcode',
    'Plate Size',
    'Plate Kind',
    'Well',
    'Volume',
    'Volume Unit (=uL)',
    'Concentration',
    'Concentration Unit (=mM)',
    'Batch Identifier',
];

const TRANSFER_COLUMNS = ['Source Barcode', 'Source Well', 'Transfer Volume (optional)', 'Transfer Volume Unit (=uL)'];

const OPTIONAL_COLUMNS = [
    'Solvent (=DMSO)',
    'Control Kind (=positive/negative/reference/rest)',
    'Purpose',
    'Description',
    'Experiment Identifier',
];

export function ECMUploadPlateInfo({ noHeader }: { noHeader?: boolean }) {
    return (
        <div className={`ecm-plate-upload-info-panel m-auto mt-${noHeader ? '0' : '4'}`}>
            {!noHeader && <h5 className='mt-2'>Plate Upload</h5>}

            <dl className='row'>
                <dt className='col-sm-3'>Required columns</dt>
                <dd className='col-sm-9'>
                    <code className='code'>{BASIC_COLUMNS.join(', ')}</code>
                </dd>
                <dt className='col-sm-3'>Transfer columns</dt>
                <dd className='col-sm-9'>
                    <code className='code'>{TRANSFER_COLUMNS.join(', ')}</code>
                    <div className='text-secondary'>
                        If set, either <code className='code'>Transfer Volume</code> or{' '}
                        <code className='code'>Volume</code> will be transferred from the source vial/plate well.
                    </div>
                </dd>
                <dt className='col-sm-3'>Optional columns</dt>
                <dd className='col-sm-9'>
                    <code className='code'>{OPTIONAL_COLUMNS.join(', ')}</code>
                </dd>
            </dl>
        </div>
    );
}

function UploadControls({ load }: { load: (p: any) => any }) {
    const fileSubject = useBehaviorSubject<File | null>(null);
    const file = useBehavior(fileSubject);
    useEffect(() => {
        if (file) load(ecmUploadPlateFile(file, fileSubject));
        else load(undefined);
    }, [file, load]);

    return <ECMUploadPlateControls fileSubject={fileSubject} />;
}

export function ECMUploadPlateControls({ fileSubject }: { fileSubject: BehaviorSubject<File | null> }) {
    const file = useBehavior(fileSubject);

    return (
        <div className='hstack gap-2'>
            <div className='flex-grow-1' style={{ marginTop: '-0.5rem' }}>
                <SingleFileUpload
                    fileSubject={fileSubject}
                    label='Drop/Select CSV/XLS to Upload'
                    extensions={['.csv', '.xls', '.xlsx']}
                    inline
                />
            </div>
            {!!file && (
                <Button variant='outline' onClick={() => fileSubject.next(null)} style={{ marginTop: '-0.5rem' }}>
                    Clear File
                </Button>
            )}
        </div>
    );
}

export function ECMUploadPlateEntries({ model }: { model: ECMUploadPlateModel }) {
    const entries = useBehavior(model.state.entries);
    return (
        <div className='pb-1'>
            <div className='hstack d-flex flex-direction-column align-items-center ecm-plate-upload-header'>
                <h5 className='py-2'>
                    Upload Details ({entries.length} plate{entries.length !== 1 ? 's' : ''})
                </h5>
                <div className='m-auto' />

                <div style={{ width: 220 }}>
                    <SimpleSelectOptionInput
                        options={[
                            ['-', '< Update Experiment >'],
                            ['<none>', 'No Experiment'],
                            ...(model.experimentOptions as any),
                        ]}
                        value={'-' as string | number}
                        setValue={(v) => {
                            if (v === '<none>') model.updateExperiment();
                            else if (typeof v === 'number') model.updateExperiment(v);
                        }}
                        size='sm'
                    />
                </div>
            </div>
            {entries.map((e) => (
                <PlateEntry entry={e} key={e.key} />
            ))}
        </div>
    );
}

function PlateEntry({ entry }: { entry: UploadEntryWrapper }) {
    return (
        <div className='mt-2 border'>
            <PlateEntryHeader entry={entry} />
            <div className='d-flex'>
                <PlateEntryDetails entry={entry} />
                {!!entry.data.size && <PlateMap entry={entry} />}
            </div>
        </div>
    );
}

function PlateEntryHeader({ entry: { state, model } }: { entry: UploadEntryWrapper }) {
    useBehavior(model.state.data);

    const data = useBehavior(state.data);
    const isUploading = useBehavior(state.isUploading);

    let statusClass = 'success';

    if (data.errors.length) {
        statusClass = 'danger';
    } else if (data.warnings.length) {
        statusClass = 'warning';
    }

    const statusOffset = 42;

    return (
        <div className='p-2 ecm-plate-upload-card-header'>
            <div className='d-flex align-items-center'>
                <div className='ps-1' style={{ width: statusOffset }}>
                    {isUploading && (
                        <Spinner animation='border' size='sm' className='text-primary me-2' role='status' />
                    )}
                    {!isUploading && (
                        <FontAwesomeIcon
                            icon={statusClass === 'success' ? faCheck : faBug}
                            size='lg'
                            className={`me-2 text-${statusClass}`}
                        />
                    )}
                </div>
                <h6 className='m-0'>Plate {data.barcode || data.input_barcode}</h6>
            </div>
            <div style={{ marginLeft: statusOffset }}>
                <PropertyNameValue field='Size' value={data.size || 'unknown'} inline />
                <PropertyNameValue field='Sample Count' value={data.samples.filter((s) => !!s).length} inline />
                <PropertyNameValue field='Experiment' value={model.experimentIdentifier || '-'} inline />
                {data.request_identifier && (
                    <PropertyNameValue field='Request' value={data.request_identifier} inline />
                )}
                <PropertyNameValue
                    field={data.source_barcodes.length !== 1 ? 'Sources' : 'Source'}
                    value={arrayEllipsis(data.source_barcodes, { maxLength: 3 }) || '-'}
                    title={data.source_barcodes.join(', ')}
                    inline
                />
            </div>
        </div>
    );
}

function getPlatePurposeOption(purpose: string) {
    const normalized = purpose.toLowerCase().trim();
    return PlatePurposeOptions.find((p) => p[0] === normalized)?.[0] ?? '<custom>';
}

function getPlatePurposeLabel(purpose: string) {
    const normalized = purpose.toLowerCase().trim();
    const option = PlatePurposeOptions.find((p) => p[0] === normalized);
    return option?.[0] === '<custom>' ? '' : option?.[1];
}

function PlateEntryDetails({ entry }: { entry: UploadEntryWrapper }) {
    const data = useBehavior(entry.state.data);
    const purpose = getPlatePurposeOption(data.purpose ?? '');

    return (
        <div className={classNames('flex-grow-1 p-2', data.size ? 'border-end' : undefined)}>
            <div className='d-flex ecm-plate-upload-card-content' style={{ height: 250 }}>
                <div className='ecm-plate-upload-details pe-3'>
                    <h6 className='text-secondary mb-1'>Details</h6>
                    <div>
                        <small>Kind:</small>
                    </div>
                    <div className='hstack gap-2'>
                        <SimpleSelectOptionInput
                            options={PlateKinds.map((k) => [k, k])}
                            value={data.kind}
                            setValue={(kind) => entry.state.data.next({ ...entry.data, kind })}
                            size='sm'
                        />
                    </div>
                    <div>
                        <small>Purpose:</small>
                    </div>
                    <SimpleSelectOptionInput
                        options={PlatePurposeOptions as any}
                        value={purpose}
                        setValue={(p) => {
                            if (p === '<custom>') {
                                entry.state.data.next({ ...entry.data, purpose: '' });
                            } else {
                                entry.state.data.next({ ...entry.data, purpose: getPlatePurposeLabel(p) });
                            }
                        }}
                        size='sm'
                    />
                    {purpose === '<custom>' && (
                        <TextInput
                            placeholder='Enter custom purpose...'
                            value={data.purpose ?? ''}
                            setValue={(p) => entry.state.data.next({ ...entry.data, purpose: p.trim() })}
                            size='sm'
                        />
                    )}
                    <div>
                        <small>Description:</small>
                    </div>
                    <TextInput
                        placeholder='Enter optional description...'
                        value={data.description ?? ''}
                        setValue={(desc) => entry.state.data.next({ ...entry.data, description: desc.trim() })}
                        size='sm'
                    />
                </div>
                <div className='pe-3 d-flex flex-column h-100'>
                    <div className='d-flex flex-column overflow-hidden' style={{ flexBasis: '50%' }}>
                        <h6 className='text-secondary mb-1'>Errors ({data.errors.length})</h6>
                        <MessageList messages={data.errors} kind='danger' />
                    </div>
                    <div className='d-flex flex-column overflow-hidden' style={{ flexBasis: '50%' }}>
                        <h6 className='text-secondary mb-1 mt-2'>Warnings ({data.warnings.length})</h6>
                        <MessageList messages={data.warnings} kind='warning' />
                    </div>
                </div>
                <div className='d-flex flex-column h-100'>
                    <h6 className='text-secondary mb-1'>Transfers ({data.transfers.length})</h6>
                    <TransferList transfers={data.transfers} />
                </div>
            </div>
        </div>
    );
}

function MessageList({ messages, kind }: { messages: string[]; kind: 'danger' | 'warning' }) {
    return (
        <>
            {!messages.length && <div className='text-secondary'>None</div>}
            {messages.length > 0 && (
                <ul className={`text-${kind} ecm-plate-upload-message-list flex-grow-1`}>
                    {messages.map((e, i) => (
                        <li key={i}>{e}</li>
                    ))}
                </ul>
            )}
        </>
    );
}

function TransferList({ transfers }: { transfers: PreparePlateUploadEntry['transfers'] }) {
    return (
        <>
            {!transfers.length && <div className='text-secondary'>None</div>}
            {transfers.length > 0 && (
                <ul className='text-info ecm-plate-upload-message-list flex-grow-1'>
                    {transfers.map((e, i) => (
                        <li key={i}>
                            {e.barcode}
                            {e.well ? `@${e.well}` : ''}: {formatUnit(e.volume_l, 'L', 'u')}
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
}

export function ECMUploadPlateFooter({
    model,
    wrapperClassName = 'entos-footer',
}: {
    model?: ECMUploadPlateModel;
    wrapperClassName?: string;
}) {
    const [uploadState, upload] = useAsyncAction();
    const entries = useBehavior(model?.state.entries);

    const canSubmit = entries?.every((e) => !e.data.errors.length);

    const numReady = entries?.filter((e) => e.data.warnings.length === 0 && e.data.errors.length === 0).length ?? 0;
    const numErrors = entries?.filter((e) => e.data.errors.length > 0)?.length ?? 0;
    const numWarnings = entries?.filter((e) => e.data.warnings.length > 0)?.length ?? 0;

    const onSubmit = () => {
        DialogService.open({
            type: 'confirm',
            onConfirm: () => upload(model?.submit()),
            title: 'Confirm Upload',
            text: (
                <>
                    <p>Are you sure you want to upload the plate(s) to Foundry?</p>
                    <p className='text-warning'>This action cannot easily be undone/updated.</p>
                </>
            ),
            confirmText: 'Upload',
        });
    };

    return (
        <div className={`${wrapperClassName} justify-content-between hstack gap-2 border-top`}>
            {!!entries && (
                <div className='flex-grow-1 text-secondary'>
                    {`${numReady} plate${numReady !== 1 ? 's' : ''} ready`}
                    {numWarnings > 0 && (
                        <>
                            ,
                            <span className='text-warning text-bold'>
                                {` ${numWarnings} warning${numWarnings > 1 ? 's' : ''}`}
                            </span>
                        </>
                    )}
                    {numErrors > 0 && (
                        <>
                            ,
                            <span className='text-danger text-bold'>
                                {` ${numErrors} error${numErrors > 1 ? 's' : ''}`}
                            </span>
                        </>
                    )}
                </div>
            )}
            <div className='m-auto' />
            {!!entries?.length && !canSubmit && (
                <span className='text-danger me-2'>
                    Fix all errors in the input CSV/XLS and re-upload it before submitting
                </span>
            )}
            {!!model && (
                <Button size='sm' disabled={!canSubmit || uploadState.isLoading} variant='primary' onClick={onSubmit}>
                    {uploadState.isLoading && <Spinner animation='border' size='sm' className='me-2' role='status' />}
                    Submit
                </Button>
            )}
        </div>
    );
}

function PlateMap({ entry }: { entry: UploadEntryWrapper }) {
    const layout = entry.data.size!;
    const visual = useRef<PlateVisualModel>();
    if (!visual.current) {
        visual.current = new PlateVisualModel(layout, { singleSelect: true });
    }
    useEffect(() => {
        const colors = colorPlateByConcentrations(entry.data.samples);
        visual.current!.state.colors.next(colors);

        const labels = labelPlateByControlKinds(entry.data.control_kinds);
        if (labels) {
            visual.current!.state.labels.next(labels);
        }
    }, [entry]);

    return (
        <div
            className='d-flex flex-column position-relative p-2 pe-4'
            style={{ width: 340, minWidth: 340, height: 220 }}
        >
            <div className='flex-grow-1'>
                <PlateVisual model={visual.current} />
            </div>
            <div style={{ height: 40 }}>
                <PlateDetailsSelection entry={entry} visual={visual.current} />
            </div>
        </div>
    );
}

function PlateDetailsSelection({ entry, visual }: { entry: UploadEntryWrapper; visual: PlateVisualModel }) {
    const selection = useBehavior(visual.state.selection);
    const fst = getFirstSelectedIndex(selection);
    const sample = entry.data.samples[fst];
    const controlKind = entry.data.control_kinds?.[fst];
    const label = getWellIndexLabel(entry.data.size!, fst);
    const batchIdentifier = sample ? entry.data.well_batch_identifiers[fst] : undefined;

    if (fst < 0) {
        return (
            <div className='mt-1 ecm-plate-upload-plate-selection-info'>
                <span className='text-secondary'>(Nothing Selected)</span>
            </div>
        );
    }

    return (
        <div className='mt-1 ecm-plate-upload-plate-selection-info'>
            {!sample && <span className='text-secondary'>(Empty Well)</span>}
            {!!sample && (
                <>
                    ({label}) {batchIdentifier && <CompoundIdentifier value={batchIdentifier} />}
                    <ECMPlateWellContents sample={sample} />
                </>
            )}
            {controlKind ? (
                <>
                    <span className='text-secondary mx-1'>[</span>
                    {capitalizeFirst(controlKind)} control
                    <span className='text-secondary mx-1'>]</span>
                </>
            ) : undefined}
        </div>
    );
}
