import React, { ReactNode } from 'react';
import { Alert } from 'react-bootstrap';
import useBehavior from '../../../lib/hooks/useBehavior';
import { ToastService } from '../../../lib/services/toast';

export default function Toasts() {
    const messages = useBehavior(ToastService.messages);

    const format = (msg: ReactNode) => {
        if (typeof msg !== 'string') return msg;
        const lines = msg.split('\n');
        const ret = [];
        for (let i = 0; i < lines.length - 1; i++) {
            ret.push(<React.Fragment key={i}>{lines[i]}</React.Fragment>);
            ret.push(<br key={`br${i}`} />);
        }
        if (lines.length > 0) {
            ret.push(<React.Fragment key='last'>{lines[lines.length - 1]}</React.Fragment>);
        }
        return ret;
    };

    return (
        <div className='entos-toast-container position-fixed start-50'>
            {messages.map((msg) => (
                <Alert
                    key={msg.id}
                    variant={msg.type}
                    className='entos-toast mb-2'
                    show
                    onClose={() => ToastService.remove(msg.id)}
                    dismissible
                >
                    {format(msg.message)}
                </Alert>
            ))}
        </div>
    );
}
