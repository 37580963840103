import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PageTemplate } from '../../components/Layout/Layout';
import Loading from '../../components/common/Loading';
import { ANAPage, ANAPages } from './ana-common';

const MetIDRoot = lazy(() => import(/* webpackChunkName: "analytical" */ './workflows/met-id'));
const MetStabRoot = lazy(() => import(/* webpackChunkName: "analytical" */ './workflows/met-stab'));
const PrefectRoot = lazy(() => import(/* webpackChunkName: "analytical" */ './workflows/prefect'));
const PrefectLogsRoot = lazy(() => import(/* webpackChunkName: "analytical" */ './workflows/prefect-logs'));

function LoadingUI({ app }: { app: string }) {
    return (
        <PageTemplate title='Analytical' breadcrumb={{ title: app, href: '#' }}>
            <Loading />
        </PageTemplate>
    );
}

function App({ app, UI }: { app: string; UI: any }) {
    return (
        <Suspense fallback={<LoadingUI app={app} />}>
            <UI />
        </Suspense>
    );
}

const Roots: Record<ANAPage, any> = {
    metid: <App app='Metabolic ID' UI={MetIDRoot} />,
    metstab: <App app='Metatabolic Stability' UI={MetStabRoot} />,
    // NOTE: For Slack notification for the workflows to run, this needs to be in sync with
    //       app/util/analytical/workflows/prefect.ts
    'lcuv-qc-data': <App app='LCUV QC Data' UI={PrefectRoot} />,
};

const PrefectRoots = {
    logs: <App app='Workflow Logs' UI={PrefectLogsRoot} />,
};

export function AnalyticalRouter() {
    return (
        <Routes>
            <Route element={PrefectRoots.logs} path='/lcuv-qc-data/:flow_id' />
            {Object.entries(ANAPages).flatMap(([name, page]) => {
                const root = Roots[name as keyof typeof Roots];
                const routes = [<Route key={name} element={root} path={page.path} />];
                if ((page as any).supportsId) {
                    routes.push(<Route key={`${name}::id`} element={root} path={`${page.path}/:id`} />);
                }
                return routes;
            })}
            <Route element={Roots.metid} path='/' />
        </Routes>
    );
}
