import log from 'loglevel';
import { useEffect, useState } from 'react';
import { Column } from 'react-table';
import { Button, Modal } from 'react-bootstrap';
import API from '../../api';
import { columnNameToHeader, Dataset, DatasetPermission, FoundryUser, Permission } from '../../api/data';
import SingleSelect from '../../components/common/SingleSelect';
import { ReactTableModel } from '../../components/ReactTable/model';
import { ToastService } from '../../lib/services/toast';
import PermissionsTable from './PermissionTable';

interface ModalProps {
    modalOpen: boolean;
    setModalOpen: Function;
    dataset: Dataset;
    permissionsTable?: ReactTableModel<DatasetPermission>;
}

const viewOptions = [
    { label: 'Anyone can view this dataset, some can edit.', value: 'view' },
    { label: 'Only specific groups can view/edit', value: 'specific' },
];
const permissionOptions = [
    { label: 'Can Edit', value: 'WRITE' },
    { label: 'Admin', value: 'ADMIN' },
];

export default function PermissionsModal({ modalOpen, setModalOpen, dataset, permissionsTable }: ModalProps) {
    // Store initial state of permissions, this will be uploaded with each addition/deletion
    const [initialPermissionsState, setInitialPermissionsState] = useState<DatasetPermission[]>([]);
    const [currentPermissionLevel, setCurrentPermissionLevel] = useState<string>(dataset.private ? 'specific' : 'view');
    // Column and table data
    const [columns, setColumns] = useState<Array<Column<DatasetPermission>>>([]);
    const [permissions, setPermissions] = useState<DatasetPermission[]>([]);
    const [usersList, setUsersList] = useState<FoundryUser[]>([]);
    // Create states for each of the dropdowns
    const [permissionValue, setPermissionValue] = useState<{ label: string; value: string }>(permissionOptions[0]);
    const [usernameValue, setUsernameValue] = useState<{ label: string; value: string }>();
    // Set the default option of the first dropdown
    const [general, specific] = viewOptions;
    const [groupPermissionValue, setGroupPermissionValue] = useState<{ label: string; value: string }>(
        dataset.private ? specific : general
    );
    const [groupOptions, setGroupOptions] = useState<{ label: string; value: string }[]>([]);

    // Disabled Button States
    const [addButtonDisabled, setAddButtonDisabled] = useState<boolean>(true);
    const [applyButtonDisabled, setApplyButtonDisabled] = useState<boolean>(true);

    useEffect(() => {
        if (!permissionsTable) return;

        const table = permissionsTable;
        async function load() {
            const usersResult = await API.permissions.users();
            setUsersList(usersResult);
            const users = usersResult.map((user) => ({ label: user.display_name, value: user.uuid }));
            setGroupOptions(users);
            const permissionTableData = table.toObjects();
            const cols: Array<Column<DatasetPermission>> = table.allColumns.map((c) => ({
                Header: columnNameToHeader(c.id!),
                accessor: c.id as keyof DatasetPermission,
            }));
            setInitialPermissionsState(permissionTableData);
            setPermissions(permissionTableData);
            setColumns(cols);
        }
        load();
    }, [permissionsTable]);

    // If user/group dropdown or permission dropdown is empty, disable "ADD" button
    useEffect(() => {
        setAddButtonDisabled(usernameValue === undefined || permissionValue === undefined);
    }, [permissionValue, usernameValue]);

    // See if permissions arrays differ
    const permissionsDiffer = (newPermissions: DatasetPermission[], oldPermissions: DatasetPermission[]) => {
        const newPermissionSet = newPermissions.map((permission) =>
            JSON.stringify(permission, Object.keys(permission).sort())
        );
        const oldPermissionSet = new Set(
            oldPermissions.map((permission) => JSON.stringify(permission, Object.keys(permission).sort()))
        );
        const difference = new Set([...newPermissionSet].filter((x) => !oldPermissionSet.has(x)));
        return difference.size !== 0;
    };

    // If no new changes are detected, disable "APPLY" button
    useEffect(() => {
        if (permissions.length !== initialPermissionsState.length) {
            setApplyButtonDisabled(false);
        } else if (groupPermissionValue && currentPermissionLevel !== groupPermissionValue.value) {
            setApplyButtonDisabled(false);
        } else if (permissionsDiffer(permissions, initialPermissionsState)) {
            setApplyButtonDisabled(false);
        } else {
            setApplyButtonDisabled(true);
        }
    }, [groupPermissionValue, permissions, initialPermissionsState, currentPermissionLevel]);

    // Add table row when "ADD" button is clicked
    const addTableRow = () => {
        if (usernameValue) {
            const userToAdd = usersList.find((user: FoundryUser) => user.uuid === usernameValue.value);
            const joined = permissions.concat({
                permission: permissionValue.value as Permission,
                group_user_name: userToAdd!.username,
                is_group: userToAdd!.is_group,
            });
            setPermissions(joined);
        }
    };

    // Add/remove collaborators and change dataset to private/public when "APPLY" button is clicked
    const applyPermissions = async () => {
        if (groupPermissionValue && currentPermissionLevel !== groupPermissionValue.value) {
            try {
                if (groupPermissionValue.value === 'specific') {
                    await API.datasets.setVisibility(+dataset!.id, false);
                } else {
                    await API.datasets.setVisibility(+dataset!.id, true);
                }
                setCurrentPermissionLevel(groupPermissionValue.value);
            } catch (e) {
                log.error(e);
                ToastService.show({ type: 'danger', message: 'Failed to modify visibility.' });
            }
        }
        permissions.forEach(async (permission) => {
            const addFilter = initialPermissionsState.filter(
                (e: DatasetPermission) =>
                    e.group_user_name === permission.group_user_name && e.permission === permission.permission
            );
            if (addFilter.length === 0) {
                try {
                    await API.datasets.setPermissions(+dataset!.id, permission, true);
                } catch (e) {
                    log.error(e);
                    ToastService.show({ type: 'danger', message: 'Failed to modify permissions.' });
                }
            }
        });
        initialPermissionsState.forEach(async (permission: DatasetPermission) => {
            const removeFilter = permissions.filter(
                (e: DatasetPermission) =>
                    e.group_user_name === permission.group_user_name && e.permission === permission.permission
            );
            if (removeFilter.length === 0) {
                try {
                    await API.datasets.setPermissions(+dataset!.id, permission, false);
                } catch (e) {
                    log.error(e);
                    ToastService.show({ type: 'danger', message: 'Failed to modify permissions.' });
                }
            }
        });
        setInitialPermissionsState(permissions);
        setModalOpen(false);
    };

    return (
        <Modal backdrop centered scrollable show={modalOpen} onHide={() => setModalOpen(false)}>
            <Modal.Header closeButton>
                <h4>Update Permissions</h4>
            </Modal.Header>
            <Modal.Body>
                <SingleSelect
                    options={viewOptions}
                    placeholder=''
                    onChange={(evt: any) => {
                        setGroupPermissionValue(evt);
                    }}
                    value={groupPermissionValue}
                />
                <div className='flex mt-2'>
                    <div className='w-50 d-inline-block'>
                        <SingleSelect
                            options={groupOptions}
                            placeholder='Choose a username/group'
                            onChange={(evt: any) => {
                                setUsernameValue(evt);
                            }}
                        />
                    </div>
                    <div className='w-25 ml-2 d-inline-block px-2'>
                        <SingleSelect
                            options={permissionOptions}
                            placeholder=''
                            onChange={(evt: any) => {
                                setPermissionValue(evt);
                            }}
                            value={permissionValue}
                        />
                    </div>
                    <Button color='primary' onClick={addTableRow} disabled={addButtonDisabled} className='ml-2 w-25'>
                        ADD
                    </Button>
                </div>
                <PermissionsTable data={permissions} setData={setPermissions} columns={columns} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant='link' onClick={() => setModalOpen(false)}>
                    Cancel
                </Button>
                <Button variant='primary' onClick={applyPermissions} disabled={applyButtonDisabled}>
                    APPLY
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
