import { faEraser, faInfoCircle, faPlus, faRemove, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { type HTEDesignModel } from '.';
import { Column, DataTableModel, DefaultRowHeight, ObjectDataTableStore } from '../../../components/DataTable';
import { SmilesColumn } from '../../../components/DataTable/common';
import { SingleFileUploadV2 } from '../../../components/common/FileUpload';
import { IconButton } from '../../../components/common/IconButton';
import { LabeledInput, TextInput } from '../../../components/common/Inputs';
import useBehavior from '../../../lib/hooks/useBehavior';
import { DialogService } from '../../../lib/services/dialog';
import { splitInput } from '../../../lib/util/misc';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { guid4 } from '../../../lib/util/uuid';
import { BatchLink } from '../../ECM/ecm-common';
import { HTE2Api } from '../api';
import { HTEDReactionChemistryOption } from '../assets';
import {
    HTEDEnumeration,
    HTEDProductBlock,
    HTEDReactantList,
    HTERReactantInstance,
    HTERReactantNameT,
    HTERReactantSample,
} from '../data-model';
import { GetNameDialogContent, getReactantInstances } from '../utils';
import { SelectReactionChemistry } from './enumeration';

export class HTEDProductBlocksModel extends ReactiveModel {
    state = {
        blocks: new BehaviorSubject<HTEDProductBlockModel[]>([]),
        current: new BehaviorSubject<HTEDProductBlockModel | undefined>(undefined),
    };

    findBlock(id?: string) {
        return this.state.blocks.value.find((b) => b.id === id);
    }

    get blockOptions() {
        return this.state.blocks.value.map((b) => [b.id, b.label] as [string, string]);
    }

    addBlock(label: string) {
        if (!label) throw new Error('Please enter a name');
        const current = this.state.blocks.value.find((b) => b.label?.toLowerCase() === label.toLowerCase());
        if (current) throw new Error(`Reaction block with name ${label} already exists`);

        const newBlock = new HTEDProductBlockModel(this, { label });
        this.state.blocks.next([...this.state.blocks.value, newBlock]);
        this.state.current.next(newBlock);
    }

    addGeneratedBlock(block: HTEDProductBlock) {
        const newBlock = new HTEDProductBlockModel(this, { initial: block });
        this.state.blocks.next([...this.state.blocks.value, newBlock]);
        this.state.current.next(newBlock);
    }

    remove(block: HTEDProductBlockModel) {
        this.state.blocks.next(this.state.blocks.value.filter((b) => b !== block));
        if (this.state.current.value === block) {
            this.state.current.next(this.state.blocks.value[0]);
        }
        const withBlock = this.design.reactions.value.filter((r) => r.reaction.product_block_id === block.id);
        for (const r of withBlock) {
            r.state.current.next({ ...r.state.current.value, product_block_id: undefined });
        }
    }

    constructor(public design: HTEDesignModel, options?: { initial?: HTEDProductBlock[] }) {
        super();

        if (options?.initial) {
            const models = options.initial.map((l) => new HTEDProductBlockModel(this, { initial: l }));
            this.state.blocks.next(models);
            this.state.current.next(models[0]);
        } else {
            this.state.blocks.next([new HTEDProductBlockModel(this)]);
            this.state.current.next(this.state.blocks.value[0]);
        }
    }
}

export class HTEDProductBlockModel extends ReactiveModel {
    id: string;
    label: string | undefined = undefined;

    state = {
        lists: new BehaviorSubject<HTEDReactantListModel[]>([]),
        enumeration: new BehaviorSubject<HTEDEnumerationModel | undefined>(undefined),
    };

    get enumeration() {
        return this.state.enumeration.value;
    }

    async import(block: HTEDProductBlock, chemistry: HTEDReactionChemistryOption | undefined) {
        const identifiers = [
            ...block.reactant_lists.flatMap((r) => r.instances.map((i) => i.identifier)),
            ...(block.enumeration?.products.map((p) => p[1]) ?? []),
        ];
        await this.products.design.model.assets.entities.syncIdentifiers(identifiers);

        this.state.lists.next(block.reactant_lists.map((r) => new HTEDReactantListModel(this, { initial: r })));

        if (block.enumeration) {
            const enumeration: HTEDEnumeration = chemistry
                ? { ...block.enumeration, reaction_chemistry_id: chemistry.value?.id }
                : block.enumeration;
            this.state.enumeration.next(new HTEDEnumerationModel(this, enumeration));
        } else {
            this.state.enumeration.next(undefined);
        }
    }

    addList(reactantName: string) {
        if (!reactantName) throw new Error('Missing reactant name');
        const current = this.state.lists.value.find((l) => l.reactantName.toLowerCase() === reactantName.toLowerCase());
        if (current) throw new Error(`Reactant list with name ${reactantName} already exists`);
        this.state.lists.next([...this.state.lists.value, new HTEDReactantListModel(this, { reactantName })]);
    }

    findList(reactantName: string) {
        return this.state.lists.value.find((l) => l.reactantName === reactantName);
    }

    remove = () => this.products.remove(this);

    setEnumeration(enumeration?: HTEDEnumeration) {
        if (!enumeration) this.state.enumeration.next(undefined);
        else this.state.enumeration.next(new HTEDEnumerationModel(this, enumeration));
    }

    toBlock(): HTEDProductBlock {
        return {
            id: this.id,
            label: this.label,
            reactant_lists: this.state.lists.value.map((l) => ({
                reactant_name: l.reactantName,
                instances: [...l.store.rawRows],
            })),
            enumeration: this.state.enumeration.value?.enumeration,
        };
    }

    constructor(public products: HTEDProductBlocksModel, options?: { label?: string; initial?: HTEDProductBlock }) {
        super();

        this.id = options?.initial?.id ?? guid4();

        if (options?.initial) {
            this.label = options.initial.label;
            const models = options.initial.reactant_lists.map((l) => new HTEDReactantListModel(this, { initial: l }));
            this.state.lists.next(models);
            if (options.initial.enumeration) {
                this.state.enumeration.next(new HTEDEnumerationModel(this, options.initial.enumeration));
            }
        } else {
            this.label = options?.label ?? 'Reactions';
            this.addList('msd');
            this.addList('bb');
        }
    }
}

export interface ReactantInstanceView {
    identifier: string;
    sample?: HTERReactantSample;
    barcode?: string;
}

export class HTEDReactantListModel extends ReactiveModel {
    store: ObjectDataTableStore<ReactantInstanceView, HTERReactantInstance>;
    table: DataTableModel<ReactantInstanceView>;
    reactantName: HTERReactantNameT;

    get canRemove() {
        return this.reactantName !== 'msd' && this.reactantName !== 'bb';
    }

    get enumerationInput(): [string, string][] {
        const { entities } = this.block.products.design.model.assets;
        const instances = this.store.rawRows;

        return instances.map((i) => [i.identifier, entities.getStructure(i.identifier!)] as [string, string]);
    }

    getIdentifier(index: number) {
        return this.store.rawRows[index].identifier;
    }

    async addIdentifiers(identifiers: string | string[]) {
        const xs = Array.isArray(identifiers) ? identifiers : splitInput(identifiers).map((x) => x.toUpperCase());
        const instances = await getReactantInstances(xs);

        const {
            assets: { entities },
        } = this.block.products.design.model;
        await entities.syncIdentifiers(instances.map((i) => i.identifier!));

        this.store.appendRows(instances);
        this.table.dataChanged();
    }

    remove = () => {
        this.block.state.lists.next(this.block.state.lists.value.filter((l) => l !== this));
    };

    clear = () => {
        this.store.clear();
        this.table.dataChanged();
    };

    removeReacant(rowIndex: number) {
        this.store.deleteRow(rowIndex);
        this.table.dataChanged();
    }

    constructor(public block: HTEDProductBlockModel, options: { reactantName?: string; initial?: HTEDReactantList }) {
        super();

        this.reactantName = (options.initial?.reactant_name ?? options.reactantName ?? '-') as HTERReactantNameT;
        this.store = new ObjectDataTableStore<ReactantInstanceView, HTERReactantInstance>(
            [
                { name: 'identifier' },
                { name: 'barcode' },
                // TODO (later PR)
                // {
                //     name: 'sample',
                //     getter: (v) => v.sample,
                //     setter: (r, v) => ({ identifier: r.identifier, sample: v }),
                // },
            ],
            options.initial?.instances
        );

        const {
            drawer,
            assets: { entities },
        } = this.block.products.design.model;

        const readOnly = this.block.products.design.isSnapshot;

        this.table = new DataTableModel<ReactantInstanceView>(this.store, {
            columns: {
                identifier: SmilesColumn(drawer, 2.5, {
                    width: 150,
                    header: 'Identifier',
                    hideToggle: true,
                    autosize: true,
                    drawingHeight: '85%',
                    disableChemDraw: true,
                    identifierPadding: 14,
                    position: 1,
                    getIdentifierElement: ({ rowIndex }) => (
                        <>
                            {rowIndex + 1}:{' '}
                            <BatchLink
                                identifier={entities.getIdentifier(this.store.getValue('identifier', rowIndex))}
                            />
                        </>
                    ),
                    getSMILES: (v) => entities.getStructure(v) ?? '',
                }),
                barcode: {
                    ...Column.str(),
                    position: 2,
                },
            },
            actions: readOnly
                ? []
                : [
                      {
                          id: 'actions',
                          width: 28,
                          position: 0,
                          alwaysVisible: true,
                          noHeaderTooltip: true,
                          noResize: true,
                          cell: (rowIndex) => (
                              <div className='d-flex align-items-center position-absolute' style={{ inset: 0 }}>
                                  <IconButton
                                      icon={faRemove}
                                      className='text-danger p-1'
                                      variant='outline'
                                      onClick={() => {
                                          this.removeReacant(rowIndex);
                                      }}
                                  />
                              </div>
                          ),
                      },
                  ],
            rowHeight: DefaultRowHeight * 2.5,
            customState: { 'show-smiles': true },
        });
    }
}

type HTEDEnumerationEntry = {
    product: string;
    msd: string;
    bb: string;
};

export class HTEDEnumerationModel extends ReactiveModel {
    store: ObjectDataTableStore<HTEDEnumerationEntry, [string[], string]>;
    table: DataTableModel<HTEDEnumerationEntry>;

    remove = () => {
        this.block.setEnumeration();
    };

    constructor(public block: HTEDProductBlockModel, public enumeration: HTEDEnumeration) {
        super();

        this.store = new ObjectDataTableStore<HTEDEnumerationEntry, [string[], string]>(
            [
                { name: 'product', getter: (v) => v[1] },
                { name: 'msd', getter: (v) => v[0][0] },
                { name: 'bb', getter: (v) => v[0][1] },
            ],
            enumeration.products
        );

        const {
            drawer,
            assets: { entities },
        } = this.block.products.design.model;

        this.table = new DataTableModel<HTEDEnumerationEntry>(this.store, {
            columns: {
                product: SmilesColumn(drawer, 2.5, {
                    width: 140,
                    header: 'Product',
                    hideToggle: true,
                    disableChemDraw: true,
                    identifierPadding: 14,
                    autosize: true,
                    drawingHeight: '85%',
                    getIdentifierElement: ({ rowIndex }) => (
                        <BatchLink identifier={this.store.getValue('product', rowIndex)} />
                    ),
                    getSMILES: (v) => entities.getStructure(v) ?? '',
                }),
                msd: SmilesColumn(drawer, 2.5, {
                    width: 140,
                    header: enumeration.reactant_names[0] ?? '-',
                    hideToggle: true,
                    disableChemDraw: true,
                    identifierPadding: 14,
                    autosize: true,
                    drawingHeight: '85%',
                    getIdentifierElement: ({ rowIndex }) => (
                        <BatchLink identifier={this.store.getValue('msd', rowIndex)} />
                    ),
                    getSMILES: (v) => entities.getStructure(v) ?? '',
                }),
                bb: SmilesColumn(drawer, 2.5, {
                    width: 140,
                    header: enumeration.reactant_names[1] ?? '-',
                    hideToggle: true,
                    disableChemDraw: true,
                    identifierPadding: 14,
                    autosize: true,
                    drawingHeight: '85%',
                    getIdentifierElement: ({ rowIndex }) => (
                        <BatchLink identifier={this.store.getValue('bb', rowIndex)} />
                    ),
                    getSMILES: (v) => entities.getStructure(v) ?? '',
                }),
            },
            rowHeight: DefaultRowHeight * 2.5,
            customState: { 'show-smiles': true },
        });
    }
}

export function AddReactantsButton({ model }: { model: HTEDReactantListModel }) {
    const addBatch = () => {
        DialogService.open({
            type: 'generic',
            title: 'Add Reactants',
            model,
            defaultState: { identifiers: '' },
            wrapOk: true,
            content: AddIdentifiersDialogContent,
            onOk: (state: { identifiers: string }) => model.addIdentifiers(state.identifiers),
        });
    };

    return (
        <IconButton onClick={addBatch} variant='link' icon={faPlus}>
            Add
        </IconButton>
    );
}

function AddIdentifiersDialogContent({ stateSubject }: { stateSubject: BehaviorSubject<{ identifiers: string }> }) {
    const current = useBehavior(stateSubject);

    return (
        <div className='vstack gap-2'>
            <TextInput
                value={current.identifiers}
                textarea
                placeholder='Enter a list of batch identifiers or barcodes...'
                setValue={(v) => stateSubject.next({ ...current, identifiers: v.trim() })}
                autoFocus
                immediate
            />
        </div>
    );
}

export function RemoveBlockButton({ model }: { model: HTEDProductBlockModel }) {
    const remove = () => {
        DialogService.open({
            type: 'confirm',
            onConfirm: model.remove,
            title: 'Remove Reaction Block',
            text: <p>Are you sure you want to remove this reaction block?</p>,
            confirmText: 'Remove',
        });
    };

    return <IconButton onClick={remove} variant='link' icon={faTrash} title='Remove Reaction Block' />;
}

export function AddReactantListButton({ model }: { model: HTEDProductBlockModel }) {
    const addBatch = () => {
        DialogService.open({
            type: 'generic',
            title: 'Add Reactant List',
            confirmButtonContent: 'Add',
            model,
            defaultState: { name: '' },
            wrapOk: true,
            content: GetReactantListNameDialogContent,
            onOk: (state: { name: string }) => model.addList(state.name),
        });
    };

    return (
        <IconButton onClick={addBatch} variant='link' icon={faPlus} title='Add Reactant List'>
            Add List
        </IconButton>
    );
}

function GetReactantListNameDialogContent({ stateSubject }: { stateSubject: BehaviorSubject<{ name: string }> }) {
    const current = useBehavior(stateSubject);

    return (
        <div className='vstack gap-2'>
            <Alert variant='info' className='mb-2 p-2'>
                <div className='hstack gap-2'>
                    <FontAwesomeIcon icon={faInfoCircle} className='mx-1' />
                    <span className='font-body-small'>
                        Reactant list names are case sensitive and their name should match the <b>Add Instruction</b>{' '}
                        name in the <b>Reactions</b> tab
                    </span>
                </div>
            </Alert>
            <LabeledInput label='Name' labelWidth={160}>
                <TextInput
                    value={current.name}
                    placeholder='Enter an unique name...'
                    setValue={(v) => stateSubject.next({ ...current, name: v.trim() })}
                    autoFocus
                    selectOnFocus
                    immediate
                />
            </LabeledInput>
        </div>
    );
}

export function AddProductBlockButton({ model }: { model: HTEDProductBlocksModel }) {
    const addBatch = () => {
        DialogService.open({
            type: 'generic',
            title: 'Add Product Block',
            confirmButtonContent: 'Add',
            model,
            defaultState: { name: '' },
            wrapOk: true,
            content: GetNameDialogContent,
            onOk: (state: { name: string }) => model.addBlock(state.name),
        });
    };

    return <IconButton onClick={addBatch} variant='link' icon={faPlus} title='Add Product Block' />;
}

export function ImportProductBlockButton({ model }: { model: HTEDProductBlockModel }) {
    const addBatch = () => {
        DialogService.open({
            type: 'generic',
            title: 'Load Product Block',
            confirmButtonContent: 'Load',
            model,
            defaultState: {},
            wrapOk: true,
            content: LoadProductBlockContent,
            onOk: async ({
                file,
                chemistry,
            }: {
                file: File | undefined;
                chemistry: HTEDReactionChemistryOption | undefined;
            }) => {
                if (!file) return;
                const block = await HTE2Api.parseProductBlock(file);
                await model.import(block, chemistry);
            },
        });
    };

    return (
        <IconButton onClick={addBatch} variant='link' icon={faUpload} title='Add Reactant List'>
            Load CSV
        </IconButton>
    );
}

function LoadProductBlockContent({
    model,
    stateSubject,
}: {
    model: HTEDProductBlockModel;
    stateSubject: BehaviorSubject<{ file: File | undefined; chemistry: HTEDReactionChemistryOption | undefined }>;
}) {
    const state = useBehavior(stateSubject);
    const { file, chemistry } = state;
    const chemistryOptions = model.products.design.model.assets.reactionChemistryOptions;

    return (
        <div className='vstack gap-2 font-body-small'>
            <Alert variant='info' className='mb-2 p-2'>
                <div className='hstack gap-2'>
                    <FontAwesomeIcon icon={faInfoCircle} className='mx-1' />
                    <span className='font-body-small'>This will overwrite the current data</span>
                </div>
            </Alert>
            <SingleFileUploadV2
                file={file}
                onDrop={(f) => stateSubject.next({ ...state, file: f[0] })}
                extensions={['.csv', '.xlsx', '.xsl']}
                label={
                    <div>
                        <div>
                            Load a CSV/Excel file with <b>MSD, MSD Order, BB, BB Order, Product</b> columns:
                        </div>
                        <ul className='ps-4'>
                            <li>
                                <b>MSD/BB:</b> Compound identifiers, batch identifiers, or barcodes.
                            </li>
                            <li>
                                <b>MSD/BB Order:</b> Each unique reactant should get a separate index. Repeating
                                identifiers with different order are allowed.
                            </li>
                            <li>
                                <b>Product:</b> Compound or batch identifiers. Compound identifiers are preferred as the
                                runtime can handle the batch registration automatically (unique batch per well) and it
                                makes the product block reusable.
                            </li>
                        </ul>
                    </div>
                }
            />
            <LabeledInput label='Reaction Chemistry' labelWidth={160}>
                <SelectReactionChemistry
                    options={chemistryOptions}
                    value={chemistry}
                    setValue={(o) => stateSubject.next({ ...state, chemistry: o as any })}
                />
            </LabeledInput>
        </div>
    );
}

export function ClearReactantListButton({ model }: { model: HTEDReactantListModel }) {
    const remove = () => {
        DialogService.open({
            type: 'confirm',
            onConfirm: model.clear,
            title: 'Clear Reactant List',
            text: <p>Are you sure you want to clear this list?</p>,
            confirmText: 'Clear',
        });
    };

    return <IconButton onClick={remove} variant='link' icon={faEraser} title='Clear Reactant List' />;
}

export function RemoveReactantListButton({ model }: { model: HTEDReactantListModel }) {
    const remove = () => {
        DialogService.open({
            type: 'confirm',
            onConfirm: model.remove,
            title: 'Remove Reactant List',
            text: <p>Are you sure you want to remove this list?</p>,
            confirmText: 'Remove',
        });
    };

    return <IconButton onClick={remove} variant='link' icon={faTrash} title='Remove Reactant List' />;
}
