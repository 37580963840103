import Plotly, { PlotlyHTMLElement } from 'plotly.js-dist';
import { useEffect, useRef } from 'react';

export default function usePlotly(
    data: Partial<Plotly.PlotData>[],
    layout: Partial<Plotly.Layout>,
    config?: Partial<Plotly.Config>
) {
    const plotRef = useRef<PlotlyHTMLElement>(null);

    useEffect(() => {
        const target = plotRef.current!; // need to store the value due to how refs work.
        const resizeObserver = new ResizeObserver(() => {
            Plotly.relayout(target, {});
        });

        resizeObserver.observe(target);

        Plotly.newPlot(target, [], layout, { responsive: true });

        return () => {
            resizeObserver.unobserve(target);
        };
    }, []);

    useEffect(() => {
        Plotly.react(plotRef.current!, data, layout, config);

        if (layout.xaxis?.range) {
            (plotRef.current! as any)._fullLayout.xaxis._rangeInitial = layout.xaxis.range;
        }
        if (layout.yaxis?.range) {
            (plotRef.current! as any)._fullLayout.yaxis._rangeInitial = layout.yaxis.range;
        }
    }, [data, layout, config]);

    return plotRef;
}
