import { BehaviorSubject } from 'rxjs';
import { Option } from '../../components/common/formatSelectOptionLabel';
import { EcosystemService } from '../../lib/services/ecosystem';
import { reportErrorAsToast } from '../../lib/util/errors';
import { ReactiveModel } from '../../lib/util/reactive-model';
import { DataTableStore } from '../../components/DataTable';
import { CompoundAPI, CompoundQuery, BatchReview, SampleReview, CompoundFromDF } from './compound-api';
import { BatchUploadModel } from './upload/batch-upload-model';
import { SampleUploadModel } from './upload/sample-upload-model';

export class CompoundListModel extends ReactiveModel {
    public readonly batchUploadModel: BatchUploadModel = new BatchUploadModel(this);
    public readonly sampleUploadModel: SampleUploadModel = new SampleUploadModel();
    public projectOptions: Option[] = [];
    public readonly indexOptions: Option[] = [
        { label: 'ENT', value: 'ENT' },
        { label: 'CMPD', value: 'CMPD' },
        { label: 'MSD', value: 'MSD' },
        { label: 'BB', value: 'BB' },
        { label: 'RGN', value: 'RGN' },
    ];
    private signalsResult: { batches: DataTableStore<BatchReview>; samples: DataTableStore<SampleReview> } | undefined;

    state = {
        isLoading: new BehaviorSubject<boolean>(false),
        store: new BehaviorSubject<DataTableStore<CompoundFromDF> | undefined>(undefined),
        query: new BehaviorSubject<CompoundQuery>({ index: 'ENT' }),
    };

    changeQuery(kind: keyof CompoundQuery, value: any) {
        this.state.query.next({
            ...this.state.query.value,
            [kind]: value,
        });
        this.query();
    }

    async loadFromSignals(eid: string) {
        try {
            const signalsResult = await CompoundAPI.loadFromSignals(eid);
            this.signalsResult = signalsResult;
            if (signalsResult.batches.rowCount > 0) {
                // NOTE: if there are samples in this result as well, we will load them
                // after the batch upload flow is complete
                await this.batchUploadModel.loadFromSignals(eid, signalsResult);
            } else if (signalsResult.samples.rowCount > 0) {
                await this.sampleUploadModel.loadFromSignals(this.signalsResult.samples);
            }
        } catch (e) {
            reportErrorAsToast('Error loading from Signals', e);
        }
    }

    async loadSamplesFromSignals() {
        if (this.signalsResult && this.signalsResult.samples.rowCount > 0) {
            await this.sampleUploadModel.loadFromSignals(this.signalsResult.samples);
        }
    }

    private async query() {
        try {
            this.state.isLoading.next(true);
            const compoundQuery = this.state.query.value;
            const store = await CompoundAPI.list(compoundQuery);
            this.state.store.next(store);
        } catch (err) {
            reportErrorAsToast('Error querying Foundry', err);
        } finally {
            this.state.isLoading.next(false);
        }
    }

    mount() {
        this.subscribe(EcosystemService.environment, (env) => {
            if (env?.projects) {
                this.projectOptions = env.projects.map((p) => ({ label: p, value: p }));
            }
        });

        this.query();
    }
}
