export const K_BC_384_2c_11pt_sixteen_1ctrl = `ID,Point,Row,Column,IsControl
Control,1,1,1,True
1,1,1,2,False
1,2,1,3,False
1,3,1,4,False
1,4,1,5,False
1,5,1,6,False
1,6,1,7,False
1,7,1,8,False
1,8,1,9,False
1,9,1,10,False
1,10,1,11,False
1,11,1,12,False
1,1,1,13,False
1,2,1,14,False
1,3,1,15,False
1,4,1,16,False
1,5,1,17,False
1,6,1,18,False
1,7,1,19,False
1,8,1,20,False
1,9,1,21,False
1,10,1,22,False
1,11,1,23,False
DMSO,1,1,24,True
Control,1,2,1,True
1,1,2,2,False
1,2,2,3,False
1,3,2,4,False
1,4,2,5,False
1,5,2,6,False
1,6,2,7,False
1,7,2,8,False
1,8,2,9,False
1,9,2,10,False
1,10,2,11,False
1,11,2,12,False
1,1,2,13,False
1,2,2,14,False
1,3,2,15,False
1,4,2,16,False
1,5,2,17,False
1,6,2,18,False
1,7,2,19,False
1,8,2,20,False
1,9,2,21,False
1,10,2,22,False
1,11,2,23,False
DMSO,1,2,24,True
Control,1,3,1,True
2,1,3,2,False
2,2,3,3,False
2,3,3,4,False
2,4,3,5,False
2,5,3,6,False
2,6,3,7,False
2,7,3,8,False
2,8,3,9,False
2,9,3,10,False
2,10,3,11,False
2,11,3,12,False
2,1,3,13,False
2,2,3,14,False
2,3,3,15,False
2,4,3,16,False
2,5,3,17,False
2,6,3,18,False
2,7,3,19,False
2,8,3,20,False
2,9,3,21,False
2,10,3,22,False
2,11,3,23,False
DMSO,1,3,24,True
Control,1,4,1,True
2,1,4,2,False
2,2,4,3,False
2,3,4,4,False
2,4,4,5,False
2,5,4,6,False
2,6,4,7,False
2,7,4,8,False
2,8,4,9,False
2,9,4,10,False
2,10,4,11,False
2,11,4,12,False
2,1,4,13,False
2,2,4,14,False
2,3,4,15,False
2,4,4,16,False
2,5,4,17,False
2,6,4,18,False
2,7,4,19,False
2,8,4,20,False
2,9,4,21,False
2,10,4,22,False
2,11,4,23,False
DMSO,1,4,24,True
Control,1,5,1,True
1,1,5,2,False
1,2,5,3,False
1,3,5,4,False
1,4,5,5,False
1,5,5,6,False
1,6,5,7,False
1,7,5,8,False
1,8,5,9,False
1,9,5,10,False
1,10,5,11,False
1,11,5,12,False
1,1,5,13,False
1,2,5,14,False
1,3,5,15,False
1,4,5,16,False
1,5,5,17,False
1,6,5,18,False
1,7,5,19,False
1,8,5,20,False
1,9,5,21,False
1,10,5,22,False
1,11,5,23,False
DMSO,1,5,24,True
Control,1,6,1,True
1,1,6,2,False
1,2,6,3,False
1,3,6,4,False
1,4,6,5,False
1,5,6,6,False
1,6,6,7,False
1,7,6,8,False
1,8,6,9,False
1,9,6,10,False
1,10,6,11,False
1,11,6,12,False
1,1,6,13,False
1,2,6,14,False
1,3,6,15,False
1,4,6,16,False
1,5,6,17,False
1,6,6,18,False
1,7,6,19,False
1,8,6,20,False
1,9,6,21,False
1,10,6,22,False
1,11,6,23,False
DMSO,1,6,24,True
Control,1,7,1,True
2,1,7,2,False
2,2,7,3,False
2,3,7,4,False
2,4,7,5,False
2,5,7,6,False
2,6,7,7,False
2,7,7,8,False
2,8,7,9,False
2,9,7,10,False
2,10,7,11,False
2,11,7,12,False
2,1,7,13,False
2,2,7,14,False
2,3,7,15,False
2,4,7,16,False
2,5,7,17,False
2,6,7,18,False
2,7,7,19,False
2,8,7,20,False
2,9,7,21,False
2,10,7,22,False
2,11,7,23,False
DMSO,1,7,24,True
Control,1,8,1,True
2,1,8,2,False
2,2,8,3,False
2,3,8,4,False
2,4,8,5,False
2,5,8,6,False
2,6,8,7,False
2,7,8,8,False
2,8,8,9,False
2,9,8,10,False
2,10,8,11,False
2,11,8,12,False
2,1,8,13,False
2,2,8,14,False
2,3,8,15,False
2,4,8,16,False
2,5,8,17,False
2,6,8,18,False
2,7,8,19,False
2,8,8,20,False
2,9,8,21,False
2,10,8,22,False
2,11,8,23,False
DMSO,1,8,24,True
DMSO,1,9,1,True
1,1,9,2,False
1,2,9,3,False
1,3,9,4,False
1,4,9,5,False
1,5,9,6,False
1,6,9,7,False
1,7,9,8,False
1,8,9,9,False
1,9,9,10,False
1,10,9,11,False
1,11,9,12,False
1,1,9,13,False
1,2,9,14,False
1,3,9,15,False
1,4,9,16,False
1,5,9,17,False
1,6,9,18,False
1,7,9,19,False
1,8,9,20,False
1,9,9,21,False
1,10,9,22,False
1,11,9,23,False
Control,1,9,24,True
DMSO,1,10,1,True
1,1,10,2,False
1,2,10,3,False
1,3,10,4,False
1,4,10,5,False
1,5,10,6,False
1,6,10,7,False
1,7,10,8,False
1,8,10,9,False
1,9,10,10,False
1,10,10,11,False
1,11,10,12,False
1,1,10,13,False
1,2,10,14,False
1,3,10,15,False
1,4,10,16,False
1,5,10,17,False
1,6,10,18,False
1,7,10,19,False
1,8,10,20,False
1,9,10,21,False
1,10,10,22,False
1,11,10,23,False
Control,1,10,24,True
DMSO,1,11,1,True
2,1,11,2,False
2,2,11,3,False
2,3,11,4,False
2,4,11,5,False
2,5,11,6,False
2,6,11,7,False
2,7,11,8,False
2,8,11,9,False
2,9,11,10,False
2,10,11,11,False
2,11,11,12,False
2,1,11,13,False
2,2,11,14,False
2,3,11,15,False
2,4,11,16,False
2,5,11,17,False
2,6,11,18,False
2,7,11,19,False
2,8,11,20,False
2,9,11,21,False
2,10,11,22,False
2,11,11,23,False
Control,1,11,24,True
DMSO,1,12,1,True
2,1,12,2,False
2,2,12,3,False
2,3,12,4,False
2,4,12,5,False
2,5,12,6,False
2,6,12,7,False
2,7,12,8,False
2,8,12,9,False
2,9,12,10,False
2,10,12,11,False
2,11,12,12,False
2,1,12,13,False
2,2,12,14,False
2,3,12,15,False
2,4,12,16,False
2,5,12,17,False
2,6,12,18,False
2,7,12,19,False
2,8,12,20,False
2,9,12,21,False
2,10,12,22,False
2,11,12,23,False
Control,1,12,24,True
DMSO,1,13,1,True
1,1,13,2,False
1,2,13,3,False
1,3,13,4,False
1,4,13,5,False
1,5,13,6,False
1,6,13,7,False
1,7,13,8,False
1,8,13,9,False
1,9,13,10,False
1,10,13,11,False
1,11,13,12,False
1,1,13,13,False
1,2,13,14,False
1,3,13,15,False
1,4,13,16,False
1,5,13,17,False
1,6,13,18,False
1,7,13,19,False
1,8,13,20,False
1,9,13,21,False
1,10,13,22,False
1,11,13,23,False
Control,1,13,24,True
DMSO,1,14,1,True
1,1,14,2,False
1,2,14,3,False
1,3,14,4,False
1,4,14,5,False
1,5,14,6,False
1,6,14,7,False
1,7,14,8,False
1,8,14,9,False
1,9,14,10,False
1,10,14,11,False
1,11,14,12,False
1,1,14,13,False
1,2,14,14,False
1,3,14,15,False
1,4,14,16,False
1,5,14,17,False
1,6,14,18,False
1,7,14,19,False
1,8,14,20,False
1,9,14,21,False
1,10,14,22,False
1,11,14,23,False
Control,1,14,24,True
DMSO,1,15,1,True
2,1,15,2,False
2,2,15,3,False
2,3,15,4,False
2,4,15,5,False
2,5,15,6,False
2,6,15,7,False
2,7,15,8,False
2,8,15,9,False
2,9,15,10,False
2,10,15,11,False
2,11,15,12,False
2,1,15,13,False
2,2,15,14,False
2,3,15,15,False
2,4,15,16,False
2,5,15,17,False
2,6,15,18,False
2,7,15,19,False
2,8,15,20,False
2,9,15,21,False
2,10,15,22,False
2,11,15,23,False
Control,1,15,24,True
DMSO,1,16,1,True
2,1,16,2,False
2,2,16,3,False
2,3,16,4,False
2,4,16,5,False
2,5,16,6,False
2,6,16,7,False
2,7,16,8,False
2,8,16,9,False
2,9,16,10,False
2,10,16,11,False
2,11,16,12,False
2,1,16,13,False
2,2,16,14,False
2,3,16,15,False
2,4,16,16,False
2,5,16,17,False
2,6,16,18,False
2,7,16,19,False
2,8,16,20,False
2,9,16,21,False
2,10,16,22,False
2,11,16,23,False
Control,1,16,24,True
`;
