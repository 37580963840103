import * as d3 from 'd3-scale-chromatic';
import { BehaviorSubject } from 'rxjs';
import { DefaultFigureLayout, PlotlyFigure } from '../../../components/Plot';
import { AssayValueTypeColumn } from '../../../lib/assays/table';
import { AsyncMoleculeDrawer } from '../../../lib/util/draw-molecules';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import {
    Column,
    ColumnTableData,
    columnDataTableStore,
    DataTableModel,
    DataTableStore,
} from '../../../components/DataTable';
import { SmilesColumn } from '../../../components/DataTable/common';
import type { AssayValueCreate, AssayValueFitSource } from '../../../lib/assays/models';
import { assayValuePotensToNM, isComboCGIAssay, isMetabolicStabilityAssay } from '../../../lib/assays/util';
import { BatchLink } from '../../ECM/ecm-common';
import { AssayAPI, AssayFitInfo, AssayValueDetail } from '../assay-api';
import { updateAssayPlot } from '../assay-common';
import { AssayUploadBatchModel } from './assay-curve-qc-model';
import { AssayValueCreateBayesian, getBayesCurves, getCustomFits, ReviewData } from './bayes-api';

const SmilesRowHeightFactor = 2;

function AssayValueCurveReviewTableSchema(
    drawer: AsyncMoleculeDrawer,
    isComboCGI: boolean,
    assayList: string[]
): [colName: keyof AssayValueDetail, column: Column][] {
    return [
        [
            'batch_identifier',
            Column.create({
                kind: 'str',
                noHeaderTooltip: true,
                width: 175,
                render: ({ value }) => <BatchLink identifier={value} withQuery />,
            }),
        ],
        [
            'supplier_id',
            Column.create({
                kind: 'str',
                noHeaderTooltip: true,
            }),
        ],
        [
            'combo_batch_identifier',
            Column.create({
                kind: 'str',
                width: 175,
                noHeaderTooltip: true,
            }),
        ],
        [
            'assay',
            Column.create({
                kind: 'str',
                width: 250,
                noHeaderTooltip: true,
                render: ({ value }) => {
                    const color = assayList.length > 1 ? d3.schemeCategory10[assayList.indexOf(value) % 10] : undefined;
                    return <span style={{ color }}>{value}</span>;
                },
            }),
        ],
        [
            'ratio',
            Column.create({
                kind: 'float',
                noHeaderTooltip: true,
            }),
        ],
        [
            'plate_barcode',
            Column.create({
                kind: 'str',
                noHeaderTooltip: true,
            }),
        ],
        [
            'min_well_location',
            Column.create({
                kind: 'str',
                noHeaderTooltip: true,
            }),
        ],
        ['value (nM)', AssayValueTypeColumn({ columnName: 'value (nM)' })],
        ['value (uL/min/mg)', AssayValueTypeColumn({ columnName: 'value (uL/min/mg)' })],
        ['historic_value', AssayValueTypeColumn({ columnName: 'Historic Value' })],
        ['ic75', AssayValueTypeColumn({ columnName: `${isComboCGI ? 'EC' : 'IC'}75 (nM)` })],
        ['ic90', AssayValueTypeColumn({ columnName: `${isComboCGI ? 'EC' : 'IC'}90 (nM)` })],
        [
            'obs_min',
            Column.create({
                ...Column.float({ defaultFormatting: { significantDigits: 3, scientific: false } }),
                label: 'Obs. Min',
                noHeaderTooltip: true,
            }),
        ],
        [
            'obs_max',
            Column.create({
                ...Column.float({ defaultFormatting: { significantDigits: 3, scientific: false } }),
                label: 'Obs. Max',
                noHeaderTooltip: true,
            }),
        ],
        [
            'auc',
            Column.create({
                ...Column.float({ defaultFormatting: { significantDigits: 3, scientific: false } }),
                label: 'Normalized AUC',
                header: 'Normalized AUC',
                noHeaderTooltip: true,
            }),
        ],
        [
            'fit_source',
            Column.create({
                kind: 'str',
                noHeaderTooltip: true,
            }),
        ],
        [
            'half_life',
            Column.create({
                ...Column.float({ defaultFormatting: { significantDigits: 3, scientific: false } }),
                label: 'Half-life (min)',
                noHeaderTooltip: true,
            }),
        ],
        [
            'r2',
            Column.create({
                ...Column.float({ defaultFormatting: { significantDigits: 3, scientific: false } }),
                header: () => (
                    <>
                        Fit R<sup>2</sup>
                    </>
                ),
                noHeaderTooltip: true,
            }),
        ],
        ['slope', AssayValueTypeColumn({ columnName: 'Fit Slope' })],
        ['min', AssayValueTypeColumn({ columnName: 'Fit Min' })],
        ['max', AssayValueTypeColumn({ columnName: 'Fit Max' })],
        [
            'performed_on',
            Column.create({
                ...Column.datetime({ format: 'date' }),
                noHeaderTooltip: true,
            }),
        ],
        [
            'retired_comment',
            Column.create({
                kind: 'str',
                noHeaderTooltip: true,
                width: 175,
            }),
        ],
        ['smiles', SmilesColumn(drawer, SmilesRowHeightFactor, { width: 150 })],
    ];
}

function createTable(assays: DataTableStore<AssayValueDetail>, isComboCGI: boolean) {
    const drawer = new AsyncMoleculeDrawer();
    const assayList = Array.from(new Set(assays.getColumnValues('assay')));
    const table = new DataTableModel(assays, {
        columns: AssayValueCurveReviewTableSchema(drawer, isComboCGI, assayList),
        hideNonSchemaColumns: true,
        customState: { 'show-smiles': false },
    });

    table.setSelected(0, true);
    table.sortBy('batch_identifier', false);

    return table;
}

export class AssayCurveReviewModel extends ReactiveModel {
    values: Record<number, AssayValueCreate[]>;
    table: DataTableModel<AssayValueDetail> | undefined;
    private fitInfo: Record<number, Record<string, AssayFitInfo>> = {};

    readonly state = {
        figure: new BehaviorSubject<PlotlyFigure>({
            data: [],
            layout: DefaultFigureLayout,
        }),
    };

    async init() {
        const { fit_info } = await AssayAPI.getFitInfo(this.values);
        this.fitInfo = fit_info;
        this.table = this.buildTable();
    }

    private buildRow(batchModel: AssayUploadBatchModel): AssayValueDetail {
        const assay = batchModel.assay;
        const value = batchModel.assay_value;
        let type = 'not_determined' as AssayValueFitSource;
        const isMetabolicStability = isMetabolicStabilityAssay(assay.property);
        const isComboCGI = isComboCGIAssay(assay);
        const isIC50 = assay.property.measurement === 'IC50';
        const hasR2 = Object.values(this.fitInfo[assay.id]).some((f) => f?.r2 !== undefined);

        const rowIdx = this.data.historicValues.findValueIndex('identifier', value.batch_identifier.split('-')[0]);
        const historicValue = this.data.historicValues.hasColumn(assay.id)
            ? this.data.historicValues.getValue(assay.id, rowIdx)
            : undefined;
        const batch = this.data.identifierToBatchMap[value.batch_identifier];

        const valueSource = value.details.insight_value_source ?? value.value_details.source;
        if (type === 'not_determined') type = valueSource;
        const fitInfo = this.fitInfo[assay.id][value.batch_identifier];

        const row: AssayValueDetail = {
            smiles: batch.structure.smiles,
            batch_identifier: value.batch_identifier,
            supplier_id: batch.supplier_id,
            assay: assay.shorthand,
            fit_source: valueSource,
            obs_min: Number.NaN,
            obs_max: Number.NaN,
            performed_on: value.performed_on,
            retired_comment: batchModel.retiredComment,
        };

        if (value.details.plate_barcode) {
            row.plate_barcode = value.details.plate_barcode;
        }

        if (value.details.min_well_location) {
            row.min_well_location = value.details.min_well_location;
        }

        if (fitInfo) {
            row.half_life = fitInfo.half_life ?? Number.NaN;
            if (hasR2) row.r2 = fitInfo.r2 ?? Number.NaN;
            row.min = fitInfo.min ?? Number.NaN;
            row.max = fitInfo.max ?? Number.NaN;
            row.obs_min = fitInfo.obs_min ?? Number.NaN;
            row.obs_max = fitInfo.obs_max ?? Number.NaN;
            row.auc = fitInfo.auc ?? Number.NaN;
            row.slope = fitInfo.slope ?? Number.NaN;
        }

        if (isComboCGI) {
            row.combo_batch_identifier = value.details.combo_batch_identifier;
            row.ratio = value.details.ratio;
        } else {
            // it doesn't make sense to show the historic value
            // for combo CGI
            row.historic_value = historicValue;
        }

        if (isMetabolicStability) {
            row['value (uL/min/mg)'] = value.value;
        } else {
            row['value (nM)'] = batchModel.convertValueToNM(value.value);
        }

        if (isIC50) {
            row.ic75 = assayValuePotensToNM(fitInfo?.ic75);
            row.ic90 = assayValuePotensToNM(fitInfo?.ic90);
        }

        return row;
    }

    private buildTable() {
        const toTable = this.batches.map((b) => this.buildRow(b)).flat();

        const columns = toTable.length > 0 ? (Object.keys(toTable[0]) as (keyof AssayValueDetail)[]) : [];
        const tableData: ColumnTableData<AssayValueDetail> = {
            columns,
            index: toTable.map((_, i) => i),
            data: columns.map((c: keyof AssayValueDetail) => toTable.map((v: AssayValueDetail) => v[c])),
        };

        const isComboCGI = this.data.uploadData.length === 1 && isComboCGIAssay(this.data.uploadData[0].info);

        return createTable(columnDataTableStore(tableData), isComboCGI);
    }

    mount() {
        // if the set of selected rows changes in the table, update the plot
        this.subscribe(this.table!.version, () => {
            const selectedRowIndices = this.table!.getSelectedRowIndices();
            // in this table, only 1 row can be selected at a time
            const rowIdx = selectedRowIndices[0];
            const assayShorthand = this.table!.store.getValue('assay', rowIdx);
            const identifier = this.table!.store.getValue('batch_identifier', rowIdx);
            const dataPoints = this.data.uploadData.find((d) => d.info.shorthand === assayShorthand);
            if (dataPoints) {
                const assay = dataPoints.info;
                const selectedValue = this.values[assay.id].find((v) => v.batch_identifier === identifier);
                if (selectedValue) {
                    const bayesFits = this.data.bayes_results
                        ? this.data.bayes_results.filter((value) => {
                              const assayValue = (value as AssayValueCreateBayesian).assay_value;
                              return assayValue.batch_identifier === identifier && assayValue.assay_id === assay.id;
                          })
                        : [];
                    const bayesCurves = getBayesCurves(bayesFits);
                    updateAssayPlot(assay, [selectedValue], this.state.figure, {
                        showAlternateFit: false,
                        customFits: getCustomFits(bayesCurves),
                    });
                }
            }
        });
    }

    constructor(public batches: AssayUploadBatchModel[], private data: ReviewData) {
        super();

        this.values = {};

        for (const batchModel of this.batches) {
            if (!this.values[batchModel.assay.id]) {
                this.values[batchModel.assay.id] = [];
            }
            this.values[batchModel.assay.id].push(batchModel.normalizedData);
        }
    }
}
