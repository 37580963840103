import { Column, DataTableModel, ObjectDataTableColumn, ObjectDataTableStore } from '../../../components/DataTable';
import { DownloadArtifactsDropdown, SelectionColumn, SmilesColumn } from '../../../components/DataTable/common';
import { AssayValueView } from '../../../lib/assays/display';
import { AssayValueCreate } from '../../../lib/assays/models';
import { DialogService } from '../../../lib/services/dialog';
import { ToastService } from '../../../lib/services/toast';
import { formatDatetime } from '../../../lib/util/dates';
import { groupBy } from '../../../lib/util/misc';
import { ModelAction, ReactiveModel } from '../../../lib/util/reactive-model';
import { Batch } from '../../Compounds/compound-api';
import { BatchLink } from '../../ECM/ecm-common';
import { HTE2MSApi, HTEBatchPromotionData } from '../api';
import type { HTE2MSModel } from '../model';

export interface BatchRow {
    identifier: string;
    identifiers: string;
    original: Batch;
    [assay_id: number]: AssayValueCreate[] | undefined;
}

const EmptyData: HTEBatchPromotionData = {
    common_unit_assay_values: [],
    batches: [],
    compounds: [],
    assays: [],
};

const BaseColumns: ObjectDataTableColumn<BatchRow, Batch>[] = [
    { name: 'identifier', getter: (v) => '' },
    { name: 'identifiers', getter: (v) => '' },
    { name: 'original', getter: (v) => v },
];

export class HTE2MSPromotionModel extends ReactiveModel {
    // Assay id -> batch id -> assay values
    private assayValues = new Map<number, Map<number, AssayValueCreate[]>>();
    private rawData: HTEBatchPromotionData = EmptyData;

    purifiedStore = new ObjectDataTableStore<BatchRow, Batch>(BaseColumns);
    purifiedTable: DataTableModel<BatchRow>;
    promotedStore = new ObjectDataTableStore<BatchRow, Batch>(BaseColumns);
    promotedTable: DataTableModel<BatchRow>;

    actions = {
        syncData: new ModelAction<HTEBatchPromotionData | undefined>({
            onError: 'toast',
            toastErrorLabel: 'Sync Data',
            applyResult: (r) => this.updateData(r || EmptyData),
        }),
    };

    private updateData(data: HTEBatchPromotionData) {
        this.rawData = data;

        this.purifiedTable.setSelection({}, false);

        const allPurified = data.batches.filter((b) => b.kind === 'Purified');
        const purified = allPurified.filter((b) => !b.entos_identifier);
        const promoted = allPurified.filter((b) => !!b.entos_identifier);

        this.updateStoreColumns(this.purifiedStore, data);
        this.purifiedStore.setRows(purified);

        this.updateStoreColumns(this.promotedStore, data);
        this.promotedStore.setRows(promoted);

        const { entities } = this.model.assets;

        for (const cmpd of data.compounds) entities.add(cmpd, true);
        for (const batch of data.batches) entities.add(batch, true);

        const valuesByAssayId = groupBy(data.common_unit_assay_values, (v) => v.assay_id!);
        this.assayValues = new Map(
            Array.from(valuesByAssayId.entries()).map(([k, xs]) => [
                k!,
                groupBy(xs, (v) => entities.getBatch(v.batch_identifier)?.id!),
            ])
        );

        this.updateTableColumns(this.purifiedTable, data);
        this.purifiedTable.dataChanged();

        this.updateTableColumns(this.promotedTable, data);
        this.promotedTable.dataChanged();
    }

    private updateStoreColumns(store: ObjectDataTableStore<BatchRow, Batch>, data: HTEBatchPromotionData) {
        store.clearColumns();
        store.addColumn({ name: 'identifier', getter: (v) => v.entos_identifier ?? v.universal_identifier! });
        store.addColumn({
            name: 'identifiers',
            getter: (v) => `${v.entos_identifier || ''}\n${v.universal_identifier!}`,
        });
        store.addColumn({ name: 'original', getter: (v) => v });
        for (const assay of data.assays) {
            store.addColumn({
                name: assay.id.toString() as any,
                getter: (v) => this.assayValues.get(assay.id)?.get(v.id),
            });
        }
    }

    private updateTableColumns(table: DataTableModel<BatchRow>, data: HTEBatchPromotionData) {
        const old = table.allColumns.filter(
            (c) => c.id !== 'identifier' && c.id !== 'selection' && c.id !== 'identifiers'
        );
        table.removeColumns(
            old.map((c) => c.id),
            { doNotUpdate: true, ignoreStore: true }
        );
        table.addOrUpdateColumns(
            data.assays.map((assay) => [
                assay.id.toString() as any,
                undefined,
                {
                    kind: 'generic',
                    header: assay.common_unit_shorthand!,
                    format: (v) => '<unused>',
                    render: ({ value }: { value: AssayValueCreate[] }) => {
                        if (!value) return null;
                        return (
                            <div className='table-scroll-cell hte2ms-promotion-table-assay-cell'>
                                {value.map((v) => (
                                    <div key={v.id!}>
                                        <span title={`Performed on: ${formatDatetime(v.performed_on, 'full')}`}>
                                            <AssayValueView value={v.value} />
                                        </span>
                                        {v.artifacts && (
                                            <DownloadArtifactsDropdown size='sm' artifacts={v.artifacts} inline />
                                        )}
                                    </div>
                                ))}
                            </div>
                        );
                    },
                    width: 180,
                    compare: false,
                    disableGlobalFilter: true,
                },
            ]),
            { doNotUpdate: true }
        );
    }

    private async fetchData() {
        if (!this.model.experiment) return;
        const data = await HTE2MSApi.promotionData(this.model.experiment!.id);
        return data;
    }

    refreshData = async () => this.actions.syncData.run(this.fetchData());

    selectPurityFilter() {
        const assay = this.rawData.assays.find((a) => a.property_shorthand === 'Purity %');
        const values = this.assayValues.get(assay?.id!);
        if (!values) {
            ToastService.info('Nothing to select');
            this.promotedTable.setSelection({});
            return;
        }

        const thresholdPercent = 85;
        const selection: number[] = [];
        for (let i = 0; i < this.purifiedStore.rawRows.length; i++) {
            const bid = this.purifiedStore.rawRows[i].id;
            if (values.get(bid)?.some((v) => (v.value as any) >= thresholdPercent)) selection.push(i);
        }
        this.purifiedTable.setSelection(selection, true);

        ToastService.success(`Selected ${selection.length} batches with Purity % >= ${thresholdPercent}.`);
    }

    confirmPromote = () => {
        DialogService.open({
            type: 'generic',
            title: 'Promote Batches',
            confirmButtonContent: 'Apply',
            model: { count: this.purifiedTable.getSelectedRowIndices().length },
            wrapOk: true,
            content: ConfirmPromotionDialogContent,
            onOk: () => this._applyPromotion(),
        });
    };

    private async _applyPromotion() {
        const selected = this.purifiedTable.getSelectedRowIndices();
        if (!selected.length) {
            ToastService.info('No batches selected.');
            return;
        }

        const ids = selected.map((i) => this.purifiedStore.rawRows[i].id);
        const result = await HTE2MSApi.applyPromotion(ids);
        await this.refreshData();

        if (result.missing_purity_batch_identifiers.length > 0) {
            ToastService.warning(
                `Some batches are missing purity value: ${result.missing_purity_batch_identifiers.join(', ')}`,
                { timeoutMs: false }
            );
        }
        if (result.purity_errors.length > 0) {
            ToastService.error(
                `Failed to assign purity:\n${result.purity_errors.map((e) => ` - ${e[0]}: ${e[1]}`).join('\n')}`
            );
        }
        if (result.promotion_errors.length > 0) {
            ToastService.error(
                `Failed to promote:\n${result.promotion_errors.map((e) => ` - ${e[0]}: ${e[1]}`).join('\n')}`
            );
        }
        if (result.promoted_compounds.length > 0) {
            ToastService.success(
                `Promoted ${result.promoted_compounds.length} compound${
                    result.promoted_compounds.length === 1 ? '' : 's'
                }`
            );
        }
    }

    mount() {
        this.refreshData();
    }

    private initTable(store: ObjectDataTableStore<BatchRow, Batch>, options: { selection: boolean }) {
        const ret = new DataTableModel<BatchRow>(store, {
            columns: {
                identifiers: Column.str(),
                identifier: SmilesColumn(this.model.drawer, 2.5, {
                    width: 180,
                    identifierPadding: 18,
                    getSMILES: (v) => this.model.assets.entities.getStructure(v)!,
                    getIdentifierElement: ({ rowIndex, table }) => {
                        const batch = table.store.getValue('original', rowIndex) as Batch;
                        return (
                            <span className='font-body-xsmall'>
                                {batch.entos_identifier && (
                                    <>
                                        <BatchLink identifier={batch.entos_identifier} withQuery />
                                        <br />
                                        <BatchLink identifier={batch.universal_identifier} />
                                    </>
                                )}
                                {!batch.entos_identifier && (
                                    <BatchLink identifier={batch.universal_identifier} withQuery />
                                )}
                            </span>
                        );
                    },
                    compare: true,
                }),
            },
            actions: options.selection ? [SelectionColumn()] : undefined,
            hideNonSchemaColumns: true,
            globalFilterHiddenColumns: true,
        });

        ret.setColumnVisibility('identifiers', false);
        ret.setCustomState({ 'show-smiles': true });
        if (options.selection) ret.setColumnStickiness('selection', true);
        ret.setColumnStickiness('identifier', true);
        ret.setRowHeight(100);
        return ret;
    }

    constructor(public model: HTE2MSModel) {
        super();

        this.purifiedTable = this.initTable(this.purifiedStore, { selection: true });
        this.promotedTable = this.initTable(this.promotedStore, { selection: false });
    }
}

function ConfirmPromotionDialogContent({ model }: { model: { count: number } }) {
    return (
        <div>
            Do you want to promote the {model.count} selected batch{model.count === 1 ? '' : 'es'}?
        </div>
    );
}
