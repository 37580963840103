import {
    faCheck,
    faCopy,
    faExclamationCircle,
    faExclamationTriangle,
    faInfoCircle,
    faPencil,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formatters } from '.';
import { Column, ColumnInstance, ColumnsFor, ObjectDataTableStore } from '../../../components/DataTable';
import { IconButton } from '../../../components/common/IconButton';
import { SimpleSelectOptionInput } from '../../../components/common/Inputs';
import { ToastService } from '../../../lib/services/toast';
import { formatSampleContentInSearch } from '../../ECM/ecm-api';
import { BatchLink } from '../../ECM/ecm-common';
import { HTEPReagent, HTEPReagentUse } from '../data-model';
import type { HTE2MSModel } from '../model';
import type { DryReagentRow } from '../protocol/dry-reagents';
import type { ReagentRow } from '../protocol/reagents';
import type { LiquidReagentRow } from '../protocol/liquid-reagents';

export function viewReagentDesignAction<R extends ReagentRow>(
    model: HTE2MSModel,
    store: ObjectDataTableStore<R, HTEPReagent>
): ColumnInstance {
    return {
        id: 'design-action',
        width: 30,
        position: 0,
        alwaysVisible: true,
        noHeaderTooltip: true,
        align: 'center',
        noResize: true,
        cell: (rowIndex) => (
            <IconButton
                icon={faPencil}
                className='hte2ms-reagent-table-view-design-button'
                onClick={() => {
                    const uses = store.rawRows[rowIndex].uses;
                    model.showReactionDesign(
                        uses.map((u) => u.reaction_id),
                        { onlySelected: true }
                    );
                }}
                size='sm'
                variant='link'
                title='View Design'
            />
        ),
    };
}

export function viewDryReagentDesignAction(
    model: HTE2MSModel,
    store: ObjectDataTableStore<DryReagentRow, HTEPReagentUse>
): ColumnInstance {
    return {
        id: 'design-action',
        width: 30,
        position: 0,
        alwaysVisible: true,
        noHeaderTooltip: true,
        align: 'center',
        noResize: true,
        cell: (rowIndex) => (
            <IconButton
                icon={faPencil}
                className='hte2ms-reagent-table-view-design-button'
                onClick={() => {
                    const use = store.rawRows[rowIndex];
                    model.showReactionDesign([use.reaction_id], { onlySelected: true });
                }}
                size='sm'
                variant='link'
                title='View Design'
            />
        ),
    };
}

function commonReagentsColumnSchema(model: HTE2MSModel) {
    return {
        identifier: {
            ...Column.str(),
            header: 'Identifier',
            render: ({ value }) => {
                if (!model.assets.entities.getEntity(value)) {
                    return <span className='text-danger'>{value}</span>;
                }
                return <BatchLink identifier={model.assets.entities.getIdentifier(value)} withQuery />;
            },
            width: 150,
        },
        batch_identifier: {
            ...Column.str(),
            header: 'Batch Identifier',
            render: ({ value }) => {
                if (!value) return null;
                return <BatchLink identifier={model.assets.entities.getIdentifier(value)} withQuery />;
            },
            width: 150,
        },
        reactant_kinds: {
            kind: 'generic',
            header: 'Kind',
            format: (v) => '<unused>',
            render: ({ value }) => (
                <div className='hstack gap-2'>
                    {value?.map((kind) => (
                        <div
                            key={kind}
                            style={{ fontSize: '9px' }}
                            className={`hte2ms-instruction-badge hte2ms-instruction-bg-add hte2ms-reactant-bg-${kind}`}
                        >
                            {kind.toUpperCase()}
                        </div>
                    ))}
                </div>
            ),
            width: 90,
            compare: (a, b) => {
                if (a?.[0] === b?.[0]) return 0;
                return a?.[0]! < b?.[0]! ? -1 : 1;
            },
            globalFilterFn: () => (v, test) => {
                if (!v) return false;
                if (!test) return true;
                const filter = test.toLowerCase();
                for (const x of v) {
                    if (x.toLowerCase().includes(filter)) return true;
                }
                return false;
            },
            disableGlobalFilter: true,
        },
        worklist_title: {
            ...Column.str(),
            header: 'Worklist',
            render: ({ value }) => value,
            width: 100,
        },
        amount_g: {
            ...Column.float(),
            header: 'Amount',
            align: 'right',
            render: ({ value }) => Formatters.amount(value),
            width: 80,
        },
        validation: {
            kind: 'generic',
            header: 'Validation',
            format: (v) => '<unused>',
            render: ({ value }) => {
                if (!value) return null;
                return (
                    <span className={`text-${value[0]}`}>
                        <FontAwesomeIcon
                            size='sm'
                            fixedWidth
                            className='me-2'
                            icon={ValidationMessageToIcon[value[0]]}
                        />
                        {value[1]}
                    </span>
                );
            },
            width: 300,
            compare: (a, b) => {
                if (!a && !b) return 0;
                if (a && !b) return -1;
                if (!a && b) return 1;
                if (a![1] === b![1]) return 0;
                return a![1] < b![1] ? -1 : 1;
            },
            disableGlobalFilter: true,
        },
        source_barcode: {
            ...Column.str(),
            header: 'Source Barcode',
            render: ({ value }) => renderBarcode(model, value),
            width: 240,
        },
        requested_sample: {
            kind: 'generic',
            header: 'Requested Sample',
            format: (v) => '<unused>',
            render: ({ value }) => formatSampleContentInSearch(value, true),
            width: 240,
            compare: false,
            disableGlobalFilter: true,
        },
        transfer_barcode: {
            ...Column.str(),
            header: 'Transfer Barcode',
            render: ({ value }) => renderBarcode(model, value),
            width: 240,
        },
    } satisfies Partial<ColumnsFor<ReagentRow>>;
}

export function commonLiquidReagentsColumnSchema(
    model: HTE2MSModel,
    store: ObjectDataTableStore<LiquidReagentRow, HTEPReagent>
) {
    const base = {
        solvent: {
            ...Column.str(),
            header: 'Solvent',
            render: ({ value }) => value,
            width: 80,
        },
        concentration: {
            ...Column.float(),
            header: 'Conc.',
            align: 'right',
            render: ({ value }) => Formatters.concentration(value),
            width: 80,
        },
        volume_l: {
            ...Column.float(),
            header: 'Volume',
            align: 'right',
            render: ({ value }) => Formatters.volumeInL(value),
            width: 80,
        },
    } satisfies Partial<ColumnsFor<LiquidReagentRow>>;

    return {
        ...commonReagentsColumnSchema(model),
        ...base,
    } as unknown as ColumnsFor<LiquidReagentRow>;
}

export function commonDryReagentsColumnSchema(
    model: HTE2MSModel,
    store: ObjectDataTableStore<DryReagentRow, HTEPReagentUse>
) {
    const base = {
        transfer_barcode: {
            ...Column.str(),
            header: 'Transfer Barcode',
            render: ({ value, rowIndex }) => renderSelectDryUseBarcodeCell(model, store.rawRows[rowIndex], value),
            width: 250,
        },
    } satisfies Partial<ColumnsFor<DryReagentRow>>;

    return {
        ...commonReagentsColumnSchema(model),
        ...base,
    } as unknown as ColumnsFor<DryReagentRow>;
}

export const ValidationMessageToIcon = {
    info: faInfoCircle,
    danger: faExclamationCircle,
    warning: faExclamationTriangle,
    success: faCheck,
};

export function CopyVialBarcode({ barcode }: { barcode?: string }) {
    return (
        <button
            type='button'
            title='Click to copy barcode'
            onClick={() => {
                navigator.clipboard.writeText(barcode ?? '');
                ToastService.show({
                    type: 'success',
                    message: 'Copied to Clipboard',
                    timeoutMs: 1500,
                });
            }}
            className='hte2ms-reagent-table-copy-barcode bg-transparent border-0 p-0 hte-experiment-copy-barcode'
            disabled={!barcode}
        >
            <FontAwesomeIcon icon={faCopy} fixedWidth className='text-secondary' />
        </button>
    );
}

const EmptyOptions: any[] = [];
export function SelectBarcodeCell({
    model,
    value,
    options,
    setValue,
    disabled,
    multipleError,
}: {
    model: HTE2MSModel;
    value: string | undefined;
    options: [string, string][] | undefined;
    setValue: (v: string) => void;
    disabled?: boolean;
    multipleError?: boolean;
}) {
    const readOnly = model.readOnlyDesignAndProduction;
    if (readOnly) return <>{renderBarcode(model, value)}</>;

    const vial = model.assets.inventory.getExistingVial(value!);
    return (
        <>
            <SimpleSelectOptionInput
                value={value || ''}
                className={`ps-1 font-body-xsmall hte2ms-table-options${multipleError ? ' text-danger' : ''}`}
                title={multipleError ? 'Assigned to multiple reagents' : undefined}
                allowEmpty
                options={options ?? EmptyOptions}
                setValue={setValue}
                disabled={disabled}
                // disabled={!this.canEdit}
                invalidOptionLabel={
                    value
                        ? `[${vial?.status !== 'Inventory' ? vial?.status ?? 'Missing' : 'Invalid'}] ${value}: ${
                              formatSampleContentInSearch(vial?.sample) || 'n/a'
                          }`
                        : `[Empty]`
                }
            />
            <CopyVialBarcode barcode={value} />
        </>
    );
}

function renderSelectDryUseBarcodeCell(model: HTE2MSModel, use: HTEPReagentUse, barcode: string | undefined) {
    const readOnly = model.readOnlyDesignAndProduction;
    if (readOnly || model.transferMode) return renderBarcode(model, barcode);

    const reagent = model.protocol.reagents.getByKey(use.reagent_key);
    const options = model.protocol.reagents.getInfo(reagent!)?.inventoryOptions;
    const multiple = model.inventory.getBarcodeTransferTargetCount(barcode) > 1;

    return (
        <SelectBarcodeCell
            model={model}
            value={barcode}
            options={options}
            multipleError={multiple}
            setValue={(v) => model.inventory.update({ dry: [[use, { transfer_barcode: v || undefined }]] })}
        />
    );
}

export function renderBarcode(model: HTE2MSModel, barcode: string | undefined) {
    if (!barcode) return '';
    const sample =
        model.assets.inventory.getByBarcode(barcode!)?.sample ??
        model.assets.inventory.getExistingVial(barcode!)?.sample;
    if (!sample) return `${barcode} (n/a)`;
    return `${barcode}: ${formatSampleContentInSearch(sample)}`;
}
