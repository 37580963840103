export const Z_NanoBRET_384_14c_9pt_dup = `ID,Point,Row,Column,IsControl
1,1,2,2,False
1,2,2,3,False
1,3,2,4,False
1,4,2,5,False
1,5,2,6,False
1,6,2,7,False
1,7,2,8,False
1,8,2,9,False
1,9,2,10,False
8,1,2,13,False
8,2,2,14,False
8,3,2,15,False
8,4,2,16,False
8,5,2,17,False
8,6,2,18,False
8,7,2,19,False
8,8,2,20,False
8,9,2,21,False
1,1,3,2,False
1,2,3,3,False
1,3,3,4,False
1,4,3,5,False
1,5,3,6,False
1,6,3,7,False
1,7,3,8,False
1,8,3,9,False
1,9,3,10,False
8,1,3,13,False
8,2,3,14,False
8,3,3,15,False
8,4,3,16,False
8,5,3,17,False
8,6,3,18,False
8,7,3,19,False
8,8,3,20,False
8,9,3,21,False
2,1,4,2,False
2,2,4,3,False
2,3,4,4,False
2,4,4,5,False
2,5,4,6,False
2,6,4,7,False
2,7,4,8,False
2,8,4,9,False
2,9,4,10,False
9,1,4,13,False
9,2,4,14,False
9,3,4,15,False
9,4,4,16,False
9,5,4,17,False
9,6,4,18,False
9,7,4,19,False
9,8,4,20,False
9,9,4,21,False
2,1,5,2,False
2,2,5,3,False
2,3,5,4,False
2,4,5,5,False
2,5,5,6,False
2,6,5,7,False
2,7,5,8,False
2,8,5,9,False
2,9,5,10,False
9,1,5,13,False
9,2,5,14,False
9,3,5,15,False
9,4,5,16,False
9,5,5,17,False
9,6,5,18,False
9,7,5,19,False
9,8,5,20,False
9,9,5,21,False
3,1,6,2,False
3,2,6,3,False
3,3,6,4,False
3,4,6,5,False
3,5,6,6,False
3,6,6,7,False
3,7,6,8,False
3,8,6,9,False
3,9,6,10,False
10,1,6,13,False
10,2,6,14,False
10,3,6,15,False
10,4,6,16,False
10,5,6,17,False
10,6,6,18,False
10,7,6,19,False
10,8,6,20,False
10,9,6,21,False
3,1,7,2,False
3,2,7,3,False
3,3,7,4,False
3,4,7,5,False
3,5,7,6,False
3,6,7,7,False
3,7,7,8,False
3,8,7,9,False
3,9,7,10,False
10,1,7,13,False
10,2,7,14,False
10,3,7,15,False
10,4,7,16,False
10,5,7,17,False
10,6,7,18,False
10,7,7,19,False
10,8,7,20,False
10,9,7,21,False
4,1,8,2,False
4,2,8,3,False
4,3,8,4,False
4,4,8,5,False
4,5,8,6,False
4,6,8,7,False
4,7,8,8,False
4,8,8,9,False
4,9,8,10,False
11,1,8,13,False
11,2,8,14,False
11,3,8,15,False
11,4,8,16,False
11,5,8,17,False
11,6,8,18,False
11,7,8,19,False
11,8,8,20,False
11,9,8,21,False
4,1,9,2,False
4,2,9,3,False
4,3,9,4,False
4,4,9,5,False
4,5,9,6,False
4,6,9,7,False
4,7,9,8,False
4,8,9,9,False
4,9,9,10,False
11,1,9,13,False
11,2,9,14,False
11,3,9,15,False
11,4,9,16,False
11,5,9,17,False
11,6,9,18,False
11,7,9,19,False
11,8,9,20,False
11,9,9,21,False
5,1,10,2,False
5,2,10,3,False
5,3,10,4,False
5,4,10,5,False
5,5,10,6,False
5,6,10,7,False
5,7,10,8,False
5,8,10,9,False
5,9,10,10,False
12,1,10,13,False
12,2,10,14,False
12,3,10,15,False
12,4,10,16,False
12,5,10,17,False
12,6,10,18,False
12,7,10,19,False
12,8,10,20,False
12,9,10,21,False
5,1,11,2,False
5,2,11,3,False
5,3,11,4,False
5,4,11,5,False
5,5,11,6,False
5,6,11,7,False
5,7,11,8,False
5,8,11,9,False
5,9,11,10,False
12,1,11,13,False
12,2,11,14,False
12,3,11,15,False
12,4,11,16,False
12,5,11,17,False
12,6,11,18,False
12,7,11,19,False
12,8,11,20,False
12,9,11,21,False
6,1,12,2,False
6,2,12,3,False
6,3,12,4,False
6,4,12,5,False
6,5,12,6,False
6,6,12,7,False
6,7,12,8,False
6,8,12,9,False
6,9,12,10,False
13,1,12,13,False
13,2,12,14,False
13,3,12,15,False
13,4,12,16,False
13,5,12,17,False
13,6,12,18,False
13,7,12,19,False
13,8,12,20,False
13,9,12,21,False
6,1,13,2,False
6,2,13,3,False
6,3,13,4,False
6,4,13,5,False
6,5,13,6,False
6,6,13,7,False
6,7,13,8,False
6,8,13,9,False
6,9,13,10,False
13,1,13,13,False
13,2,13,14,False
13,3,13,15,False
13,4,13,16,False
13,5,13,17,False
13,6,13,18,False
13,7,13,19,False
13,8,13,20,False
13,9,13,21,False
7,1,14,2,False
7,2,14,3,False
7,3,14,4,False
7,4,14,5,False
7,5,14,6,False
7,6,14,7,False
7,7,14,8,False
7,8,14,9,False
7,9,14,10,False
14,1,14,13,False
14,2,14,14,False
14,3,14,15,False
14,4,14,16,False
14,5,14,17,False
14,6,14,18,False
14,7,14,19,False
14,8,14,20,False
14,9,14,21,False
7,1,15,2,False
7,2,15,3,False
7,3,15,4,False
7,4,15,5,False
7,5,15,6,False
7,6,15,7,False
7,7,15,8,False
7,8,15,9,False
7,9,15,10,False
14,1,15,13,False
14,2,15,14,False
14,3,15,15,False
14,4,15,16,False
14,5,15,17,False
14,6,15,18,False
14,7,15,19,False
14,8,15,20,False
14,9,15,21,False
`;
