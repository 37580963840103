import { faICursor, faImage, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useEffect, useState } from 'react';
import { Badge, Button, Dropdown } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { distinctUntilChanged } from 'rxjs';
import { PageTemplate } from '../../components/Layout/Layout';
import { ErrorMessage } from '../../components/common/Error';
import Loading from '../../components/common/Loading';
import { PillNavStep, TabNav } from '../../components/common/Nav';
import { AsyncState, useAsyncAction } from '../../lib/hooks/useAsyncAction';
import useBehavior from '../../lib/hooks/useBehavior';
import useMountedModel from '../../lib/hooks/useMountedModel';
import { EcosystemService } from '../../lib/services/ecosystem';
import { parseFileSystemDate } from '../../lib/util/dates';
import { useModelAction } from '../../lib/util/reactive-model';
import { HTE2Api } from './api';
import { HTEWizardData } from './data-model';
import { HTEDesignUI } from './design/design-ui';
import { HTEDExecutionUI } from './execution/execution-ui';
import { HTEFinalizeUI } from './finalize/finalize-ui';
import { HTEInventoryUI } from './inventory/inventory-ui';
import { HTEDataSource, HTEWModel, HTEWTab, SaveExperimentButton, saveExperimentDialog } from './model';
import { HTEProtocolUI } from './protocol/protocol-ui';
import { ThreeDotsMenu } from '../../components/common/IconButton';

async function createModel({ draftId }: { draftId?: string } = {}) {
    const model = new HTEWModel();

    let data: HTEWizardData;
    if (draftId) {
        data = await HTE2Api.draft(draftId);
    } else {
        const { design } = await HTE2Api.default();
        data = { design };
    }
    await model.init(data, { draftId });
    return model;
}

export function HTE2Root({ draft }: { draft?: boolean }) {
    const { id } = useParams();
    const [model, applyModel] = useAsyncAction<HTEWModel>();
    useEffect(() => {
        applyModel(createModel(draft ? { draftId: id } : undefined));
    }, [draft, id]);

    return <HTE2UI _model={model} />;
}

export function HTE2UI({ _model }: { _model: AsyncState<HTEWModel> }) {
    const model = useMountedModel(_model.result);
    useEffect(() => {
        const sub = model?.state.dataSource
            .pipe(
                distinctUntilChanged((a, b) => {
                    if (a.kind !== b.kind) return false;
                    if (a.kind === 'foundry' && b.kind === 'foundry') return a.info.id === b.info.id;
                    if (a.kind === 'draft' && b.kind === 'draft') return a.id === b.id;
                    return true;
                })
            )
            .subscribe((src) => {
                if (src.kind === 'foundry') {
                    window.history.replaceState({}, '', `/hte/${src.info.id}/design`);
                } else if (src.kind === 'draft') {
                    window.history.replaceState({}, '', `/hte/wizard/draft/${src.id}`);
                } else {
                    window.history.replaceState({}, '', `/hte/wizard`);
                }
            });
        return () => sub?.unsubscribe();
    }, [model]);

    return (
        <PageTemplate
            title='HTE Wizard'
            breadcrumb={
                _model.isLoading || !model
                    ? [{ href: window.location.pathname, title: 'Loading...' }]
                    : [{ href: window.location.pathname, title: <Breadcrumb model={model} /> }]
            }
            button={model ? <NavButtons model={model} /> : undefined}
        >
            {_model.isLoading && <Loading message='Yer a wizard, Harry.' />}
            {_model.error && <ErrorMessage message={_model.error} />}
            {model && <Layout model={model} />}
        </PageTemplate>
    );
}

function Breadcrumb({ model }: { model: HTEWModel }) {
    const src = useBehavior(model.state.dataSource);
    const options = useBehavior(model.state.options);

    return (
        <>
            {src.kind === 'new' && 'New Design'}
            {src.kind === 'foundry' && `HTE${src.info.id}: `}
            {src.kind !== 'new' && (options?.name ?? 'Untitled')}
            {options?.project && (
                <Badge bg='info' className='ms-2 font-body-xsmall px-1 py-0'>
                    {options?.project}
                </Badge>
            )}
            {src.kind === 'draft' && (
                <Badge bg='warning' className='ms-2 font-body-xsmall px-1 py-0'>
                    DRAFT
                </Badge>
            )}
            {options?.hidden && (
                <Badge bg='secondary' className='ms-2 font-body-xsmall px-1 py-0'>
                    HIDDEN
                </Badge>
            )}
            {model.inProgress && (
                <Badge bg='warning' className='ms-2 font-body-xsmall px-1 py-0'>
                    IN PROGRESS
                </Badge>
            )}
            {model.needsFinalization && (
                <Badge bg='warning' className='ms-2 font-body-xsmall px-1 py-0'>
                    NEEDS FINALIZATION
                </Badge>
            )}
        </>
    );
}

function NavButtons({ model }: { model: HTEWModel }) {
    const src = useBehavior(model.state.dataSource);
    const state = useModelAction(model.actions.save);

    return (
        <div className='hstack gap-2'>
            {model?.saveEnabled && state.kind !== 'loading' && src.kind === 'foundry' && (
                <span className='text-secondary me-2 font-body-small'>
                    Last saved
                    <LastSavedLabel src={src} />
                </span>
            )}
            {state.kind !== 'loading' && src.kind !== 'foundry' && (
                <span className='text-secondary me-2 font-body-small'>Not saved to Foundry</span>
            )}
            {state.kind === 'loading' && <span className='text-secondary me-2 font-body-small'>Saving...</span>}
            {model?.saveEnabled && <SaveExperimentButton model={model} />}
            <AdditionalMenu model={model} />
        </div>
    );
}

function getLastSavedLabel(src: HTEDataSource) {
    if (src.kind !== 'foundry') return '';
    const now = Date.now();
    const saved = parseFileSystemDate(src.info.modified_on);

    if (now - +saved < 1000 * 60) return ' <minute ago';
    if (now - +saved < 1000 * 60 * 5) return ' <5m ago';
    if (now - +saved < 1000 * 60 * 15) return ' <15m ago';
    return ` on ${saved.toLocaleString()}`;
}

function LastSavedLabel({ src }: { src: HTEDataSource }) {
    const [label, setLabel] = useState(getLastSavedLabel(src));

    useEffect(() => {
        let timeout: any;
        function updateLabel() {
            setLabel(getLastSavedLabel(src));
            timeout = setTimeout(updateLabel, 1000 * 60);
        }
        updateLabel();

        return () => clearTimeout(timeout);
    }, [src]);

    return <>{label}</>;
}

function AdditionalMenu({ model }: { model: HTEWModel }) {
    const src = useBehavior(model.state.dataSource);
    const env = useBehavior(EcosystemService.environment);

    const items: ReactNode[] = [];

    if (src.kind === 'foundry' && model.saveEnabled) {
        items.push(
            <Dropdown.Item
                key='edit'
                as={Button}
                className='font-body-small'
                onClick={() => saveExperimentDialog(model, true)}
            >
                <FontAwesomeIcon icon={faICursor} fixedWidth className='me-2' />
                Edit
            </Dropdown.Item>
        );
    }
    if (src.kind === 'foundry') {
        items.push(
            <Dropdown.Item key='template' as={Button} className='font-body-small' onClick={() => model.useAsTemplate()}>
                <FontAwesomeIcon icon={faImage} fixedWidth className='me-2' />
                Use as Template
            </Dropdown.Item>
        );
    }

    if (env?.name === 'dev') {
        if (src.kind === 'foundry') items.push(<Dropdown.Divider key='divider' />);
        items.push(
            <Dropdown.Item key='example' as={Button} className='font-body-small' onClick={() => model.loadExample()}>
                <FontAwesomeIcon icon={faMagnifyingGlass} fixedWidth className='me-2' />
                Load Example
            </Dropdown.Item>
        );
    }

    if (items.length === 0) return null;

    return (
        <ThreeDotsMenu drop='down' size='lg'>
            {items}
        </ThreeDotsMenu>
    );
}

const NavTabs: PillNavStep<HTEWTab>[] = [
    { name: 'design', label: 'Design' },
    { name: 'inventory', label: 'Inventory' },
    { name: 'execution', label: 'Execution' },
    { name: 'finalize', label: 'Finalize' },
];

const ExecutionNavTabs: PillNavStep<HTEWTab>[] = [
    { name: 'design', label: 'Design' },
    { name: 'inventory', label: 'Inventory' },
    { name: 'execution', label: 'Execution' },
    { name: 'finalize', label: 'Finalize' },
];

const LockedNavTabs: PillNavStep<HTEWTab>[] = [
    { name: 'design', label: 'Design' },
    { name: 'inventory', label: 'Inventory' },
    { name: 'finalize', label: 'Finalize' },
];

function Layout({ model }: { model: HTEWModel }) {
    const tab = useBehavior(model.state.tab);

    return (
        <>
            <Nav model={model} />
            <div className='position-absolute' style={{ inset: 0, top: 31 }}>
                {tab === 'design' && <DesignTab model={model} />}
                {tab === 'execution' && <HTEDExecutionUI model={model} />}
                {tab === 'finalize' && <HTEFinalizeUI model={model} />}
                {tab === 'inventory' && <HTEInventoryUI model={model} />}
            </div>
        </>
    );
}

function DesignTab({ model }: { model: HTEWModel }) {
    const isEditing = useBehavior(model.state.isEditing);
    return isEditing ? <HTEDesignUI model={model} /> : <HTEProtocolUI model={model} />;
}

function Nav({ model }: { model: HTEWModel }) {
    const tab = useBehavior(model.state.tab);
    useBehavior(model.state.dataSource);

    let tabs = NavTabs;
    if (model.inProgress || model.needsFinalization) {
        tabs = ExecutionNavTabs;
    } else if (model.isLocked) {
        tabs = LockedNavTabs;
    }

    return <TabNav steps={tabs} currentStep={tab} onSetStep={(s) => model.state.tab.next(s)} />;
}
