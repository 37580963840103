import {
    faArrowUpRightFromSquare,
    faFilter,
    faFolderOpen,
    faMagicWandSparkles,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import saveAs from 'file-saver';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Split from 'react-split-it';
import { useBlockLayout, useGlobalFilter, useRowSelect, useSortBy, useTable } from 'react-table';
import { BehaviorSubject, combineLatest, throttleTime } from 'rxjs';
import api from '../../../api';
import { SingleFileUpload } from '../../../components/common/FileUpload';
import { TextInput } from '../../../components/common/Inputs';
import Loading from '../../../components/common/Loading';
import { PropertyNameValue } from '../../../components/common/PropertyNameValue';
import { AutoScrollBox } from '../../../components/common/ScrollBox';
import Pane from '../../../components/Pane/Pane';
import { ReactTableModel, ReactTableRow } from '../../../components/ReactTable/model';
import ReactTableSchema from '../../../components/ReactTable/schema';
import { FlexibleVirtualizedTable } from '../../../components/ReactTable/VirtualizedTable';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import { DialogService } from '../../../lib/services/dialog';
import { ToastService } from '../../../lib/services/toast';
import { arrayToCsv } from '../../../lib/util/arrayToCsv';
import { formatDatetime } from '../../../lib/util/dates';
import { reportErrorAsToast, tryGetErrorMessage } from '../../../lib/util/errors';
import { splitInput } from '../../../lib/util/misc';
import { roundValue } from '../../../lib/util/roundValues';
import { formatUnit } from '../../../lib/util/units';
import {
    ECMApi,
    ECMQueryResult,
    formatLocation,
    isSampleEmpty,
    isTubeBarcode,
    PlateEvent,
    PlateLocation,
    ECMSearchResult,
    SmallVialLocation,
    VialEvent,
    formatVialsTableColumns,
    isInventorySearchResult,
} from '../ecm-api';
import { BatchLink, ECMPageTemplate } from '../ecm-common';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { Batch } from '../../Compounds/compound-api';
import { objectsToColumnTableData } from '../../../components/DataTable/store';

interface VialResults {
    kind: 'vials';
    result: ECMQueryResult;
    vials: ECMSearchResult[];
    filterCounts: SearchResultFilterCounts;
}
type CurrentResults = { kind: 'empty' } | VialResults;

type SearchResultFilterInfo = {
    key: string;
    label: ReactNode;
    title?: string;
    defaultSelected?: boolean;
    invertCount?: boolean;
    test: (r: ECMSearchResult) => boolean;
    hideIf?: (model: ECMSearchModel) => boolean;
};

const WizardFilter: SearchResultFilterInfo = {
    key: 'wizard',
    label: <FontAwesomeIcon icon={faMagicWandSparkles} size='sm' fontSize={8} />,
    invertCount: false,
    defaultSelected: false,
    hideIf: (m) => !m.options?.allowWizard,
    test: (v) => !!v.wizard,
};

const SearchResultsFilterGroups: SearchResultFilterInfo[][] = [
    [
        {
            key: 'vial',
            label: 'Vial',
            defaultSelected: true,
            test: (v) => !v.well?.length && !isTubeBarcode(v.barcode),
        },
        { key: 'tube', label: 'Tube', defaultSelected: true, test: (v) => !v.well?.length && isTubeBarcode(v.barcode) },
        { key: 'plate', label: 'Plate', defaultSelected: true, test: (v) => !!v.well?.length },
    ],
    [
        {
            key: 'in_inventory',
            label: 'In Inv.',
            defaultSelected: true,
            test: isInventorySearchResult,
        },
    ],
    [
        {
            key: 'dry',
            label: 'Dry',
            defaultSelected: true,
            test: (v) => !isSampleEmpty(v.sample) && !!v.sample && typeof v.sample.concentration !== 'number',
        },
        {
            key: 'wet',
            label: 'Wet',
            defaultSelected: true,
            test: (v) => !isSampleEmpty(v.sample) && !!v.sample && typeof v.sample.concentration === 'number',
        },
        { key: 'empty', label: 'Empty', test: (v) => isSampleEmpty(v.sample) },
    ],
    [
        {
            key: '10-mm',
            label: '10 mM',
            title: '10 mM concentration only',
            defaultSelected: false,
            test: (v) =>
                !isSampleEmpty(v.sample) &&
                (typeof v.sample?.concentration !== 'number' || Math.abs(v.sample.concentration - 10e-3) < 1e-5),
        },
        {
            key: 'equal-conc',
            label: '= mM',
            title: 'Result must have same concentration as requested sample',
            defaultSelected: false,
            hideIf: (m) => !m.options?.equalConcTest,
            test: (v) =>
                !isSampleEmpty(v.sample) &&
                typeof v.sample?.concentration === 'number' &&
                typeof v.total_requested?.concentration === 'number' &&
                Math.abs(v.sample.concentration - v.total_requested.concentration) < 1e-5,
        },
    ],
    [
        {
            key: 'not_disposed',
            label: 'Hide Disposed',
            invertCount: true,
            defaultSelected: true,
            test: (v) => v.status !== 'Disposed',
        },
    ],
    [WizardFilter],
];

const SearchResultsFilters = SearchResultsFilterGroups.flatMap((xs) => xs);

const SearchResultFilterMap = new Map<string, SearchResultFilterInfo>(SearchResultsFilters.map((f) => [f.key, f]));
const DefaultSearchResultFilterState: SearchResultFilterState = Object.fromEntries(
    SearchResultsFilters.map((f) => [f.key, !!f.defaultSelected])
);

type SearchResultFilterState = Record<string, boolean>;
type SearchResultFilterCounts = Record<string, number>;

export class ECMSearchModel extends ReactiveModel {
    state = {
        isBusy: new BehaviorSubject<boolean>(false),
        file: new BehaviorSubject<File | null>(null),
        results: new BehaviorSubject<CurrentResults>({ kind: 'empty' }),
        currentResult: new BehaviorSubject<ECMSearchResult | undefined>(undefined),
        input: new BehaviorSubject<string>(''),
        filters: new BehaviorSubject<SearchResultFilterState>(DefaultSearchResultFilterState),
        filteredVialCount: new BehaviorSubject(0),
        queryPlates: new BehaviorSubject(true),
    };

    currentVialsRows: ReactTableRow[] = [];

    private currentSearch?: string = undefined;

    private async syncSearch(values: string[], query_plates: boolean) {
        const nextSearch = `${values.join('|')}:${query_plates}`;
        if (this.currentSearch === nextSearch) {
            return;
        }
        if (values.length === 0) {
            this.currentSearch = undefined;
            return;
        }
        this.currentSearch = nextSearch;

        try {
            this.state.results.next({ kind: 'empty' });
            this.state.currentResult.next(undefined);
            this.state.isBusy.next(true);
            const result = await ECMApi.query({ identifiers: values, query_plates });
            if (this.currentSearch !== nextSearch) return;

            if (result.results.rows.length > 0) {
                // hide id column
                result.results.allColumns = result.results.allColumns.filter((c) => c.id !== 'id');
            }
            const vials = result.results.toObjects();

            const filtered = filterResults(
                vials,
                vials.map((_, i) => ({ original: { index: i } })),
                this.state.filters.value
            );
            if (filtered.length > 0) {
                result.results.setSelection([filtered[0].original.index]);
            } else {
                result.results.setSelection([]);
            }

            this.state.results.next({ kind: 'vials', result, vials, filterCounts: calcFilterCounts(vials) });
            this.state.currentResult.next(undefined);
        } catch (err) {
            reportErrorAsToast('Search', err);
        } finally {
            if (this.currentSearch === nextSearch) {
                this.state.isBusy.next(false);
                this.currentSearch = undefined;
            }
        }
    }

    setCustomResult(results: ECMSearchResult[], options: { batches: Record<number, Batch>; not_found: string[] }) {
        const filtered = filterResults(
            results,
            results.map((_, i) => ({ original: { index: i } })),
            this.state.filters.value
        );

        const tableData = objectsToColumnTableData(results, [
            'id',
            'barcode',
            'batch_identifier',
            'well',
            'status',
            'location',
            'sample',
            'total_requested',
            'batch_purity',
            'project',
            'tare_mass',
            'created_by',
            'created_on',
            'modified_on',
            'plate_purpose',
            'wizard',
        ]);
        const resultTable = new ReactTableModel(tableData, ECMSearchResult);
        formatVialsTableColumns(resultTable, true);

        if (filtered.length > 0) {
            resultTable.setSelection([filtered[0].original.index]);
        } else {
            resultTable.setSelection([]);
        }

        this.state.results.next({
            kind: 'vials',
            result: {
                batches: options.batches,
                compounds: {},
                invalid_identifiers: [],
                not_found_vial_identifiers: options.not_found,
                results: resultTable,
            },
            vials: results,
            filterCounts: calcFilterCounts(results),
        });
        this.state.currentResult.next(undefined);
    }

    private searchInput(input: string, queryPlates: boolean) {
        const barcodes = splitInput(input);

        const unique = Array.from(new Set(barcodes));
        unique.sort();

        this.syncSearch(unique, queryPlates);
    }

    saveCSV() {
        const result = this.state.results.value;
        if (result.kind !== 'vials') return;

        const csv = searchResultToCSV(result.result, this.currentVialsRows);
        saveAs(new Blob([csv], { type: 'text/csv' }), `ecm-search-${Date.now()}.csv`);
    }

    mount(): void {
        this.subscribe(
            combineLatest([this.state.input, this.state.queryPlates]).pipe(
                throttleTime(500, undefined, { trailing: true })
            ),
            ([input, queryPlates]) => this.searchInput(input, queryPlates)
        );

        this.subscribe(this.state.file, async (file) => {
            if (!file) return;
            const csv = await api.utils.parseTable(file, {
                schema: {
                    barcode: ReactTableSchema.str(),
                    identifier: ReactTableSchema.str(),
                    batch_identifier: ReactTableSchema.str(),
                    compound_identifier: ReactTableSchema.str(),
                },
                lowerCaseColumns: true,
            });
            const barcodes = csv
                .toObjects()
                .flatMap((r) => [r.barcode, r.batch_identifier, r.compound_identifier, r.identifier])
                .filter((b) => !!b)
                .join(' ');
            this.state.input.next(barcodes);
            this.state.file.next(null);
        });
    }

    constructor(
        public options?: {
            hideNotifySlack?: boolean;
            equalConcTest?: boolean;
            allowWizard?: boolean;
            hideNotFound?: boolean;
        }
    ) {
        super();
    }
}

const _Model = new ECMSearchModel();

export function ECMSearch() {
    const model = useMountedModel(_Model)!;

    return (
        <ECMPageTemplate page='search'>
            <SearchInput model={model} />
            <ECMSearchResultsUI model={model} />
        </ECMPageTemplate>
    );
}

function SearchInput({ model }: { model: ECMSearchModel }) {
    return (
        <div className='pb-1 ecm-search-bar'>
            <IdentifierInput model={model} />
            <InputFile model={model} />
            <QueryInPlates model={model} />
        </div>
    );
}

function IdentifierInput({ model }: { model: ECMSearchModel }) {
    const input = useBehavior(model.state.input);

    return (
        <div className='w-100 position-relative'>
            <Form.Control
                as='textarea'
                type='text'
                rows={2}
                placeholder='Scan or paste barcode(s) or batch/compound identifier(s); or drop CSV/XLS (barcode, batch_identifier, compound_identifier, identifier columns supported)'
                autoFocus
                value={input}
                onChange={(e) => model.state.input.next(e.target.value)}
                onFocus={(e) => e.currentTarget.select()}
                onDrop={(e) => {
                    e.preventDefault();

                    if (e.dataTransfer.items) {
                        for (let i = 0; i < e.dataTransfer.items.length; i++) {
                            if (e.dataTransfer.items[i].kind === 'file') {
                                const file = e.dataTransfer.items[i].getAsFile();
                                model.state.file.next(file);
                                break;
                            }
                        }
                    } else {
                        model.state.file.next(e.dataTransfer.files[0] ?? null);
                    }
                }}
            />
        </div>
    );
}

function InputFile({ model }: { model: ECMSearchModel }) {
    return (
        <div className='ecm-file-input'>
            <SingleFileUpload
                fileSubject={model.state.file}
                label={<FontAwesomeIcon icon={faFolderOpen} size='sm' />}
                extensions={['.csv', '.xls', '.xlsx']}
                inline
            />
        </div>
    );
}

function QueryInPlates({ model }: { model: ECMSearchModel }) {
    const queryPlates = useBehavior(model.state.queryPlates);
    return (
        <Form.Switch
            id='query-plates-switch'
            label='Plates'
            title='Search in Plates'
            className='ecm-search-query-plates'
            checked={queryPlates}
            onChange={(e) => model.state.queryPlates.next(e.target.checked)}
        />
    );
}

export function ECMSearchResultsUI({ model }: { model: ECMSearchModel }) {
    return (
        <div className='ecm-search-container'>
            <Split direction='vertical' sizes={[0.5, 0.5]}>
                <CurrentResultTableWrapper model={model} />
                <Split direction='horizontal' sizes={[0.66, 0.34]}>
                    <CurrentDetails model={model} />
                    <Pane title={<div className='ps-2'>Events</div>}>
                        <CurrentEvents model={model} />
                    </Pane>
                </Split>
            </Split>
        </div>
    );
}

function CurrentResultTableWrapper({ model }: { model: ECMSearchModel }) {
    const isBusy = useBehavior(model.state.isBusy);
    const results = useBehavior(model.state.results);

    let inner: ReactNode;
    let searchTitle: ReactNode = 'Results';
    if (isBusy) {
        inner = <Loading />;
    } else if (results.kind === 'empty') {
        inner = <div className='text-secondary mt-1'>No results</div>;
    } else {
        searchTitle = (
            <>
                Results
                <SearchResultsFilter model={model} results={results} />
            </>
        );
        inner = <VialsTable table={results.result.results} model={model} results={results} />;
    }

    const notFound =
        results.kind !== 'empty' && !isBusy
            ? [...results.result.invalid_identifiers, ...results.result.not_found_vial_identifiers]
            : [];

    const main = (
        <Pane title={searchTitle} headerInfo={<SearchResultsExport model={model} />}>
            {inner}
        </Pane>
    );

    if (model.options?.hideNotFound) return main;

    return (
        <Split direction='horizontal' sizes={[0.83, 0.17]}>
            {main}
            <Pane
                title={
                    <>
                        <div className='ps-2' style={{ lineHeight: '30px' }}>
                            Not Found
                        </div>
                        {!!notFound.length && (
                            <span className='ms-2 text-secondary fw-bold' style={{ fontSize: '0.75rem' }}>
                                {notFound.length}
                            </span>
                        )}
                    </>
                }
            >
                <div className='p-2 ecm-search-not-found-list'>
                    {notFound.map((v, i) => (
                        <div key={i}>{v}</div>
                    ))}
                </div>
            </Pane>
        </Split>
    );
}

function calcFilterCounts(vials: ECMSearchResult[]) {
    const counts = {} as any;
    for (const f of SearchResultsFilters) {
        const count = vials.filter(f.test).length;
        counts[f.key] = f.invertCount ? vials.length - count : count;
    }
    return counts;
}

function filterResults<Row extends { original: { index: number } }>(
    vials: ECMSearchResult[],
    rows: Row[],
    filters: SearchResultFilterState
) {
    if (filters[WizardFilter.key]) {
        const next = [];
        for (const row of rows) {
            if (WizardFilter.test(vials[row.original.index])) {
                next.push(row);
            }
        }
        return next;
    }

    let current = rows;

    for (const group of SearchResultsFilterGroups) {
        const enabledFilters = group.filter((f) => filters[f.key]);
        if (enabledFilters.length === 0) continue;

        const next = [];
        for (const row of current) {
            for (const filter of enabledFilters) {
                if (filter.test(vials[row.original.index])) {
                    next.push(row);
                    break;
                }
            }
        }
        current = next;
    }

    return current;
}

function SearchFilterButton({
    state,
    stateSubject,
    kind,
    results,
}: {
    state: SearchResultFilterState;
    stateSubject: BehaviorSubject<SearchResultFilterState>;
    kind: string;
    results: VialResults;
}) {
    const filter = SearchResultFilterMap.get(kind)!;
    return (
        <Button
            onClick={() => stateSubject.next({ ...state, [kind]: !state[kind] })}
            variant={state[kind] ? 'dark' : 'outline'}
            title={filter.title}
            className={
                state[kind]
                    ? `me-1 ecm-search-filter-type-current-${(kind ?? 'all').toLowerCase()}`
                    : `me-1 ecm-search-filter-type-${(kind ?? 'all').toLowerCase()}`
            }
        >
            {filter.label} <small>({results.filterCounts[kind]})</small>
        </Button>
    );
}

function SearchResultsFilter({ model, results }: { model: ECMSearchModel; results: VialResults }) {
    const state = useBehavior(model.state.filters);
    const filteredCount = useBehavior(model.state.filteredVialCount);

    return (
        <div className='ecm-search-filter ps-2'>
            <span className='me-2 text-secondary'>
                <span className='me-2'>
                    {filteredCount} of {results.vials.length} shown
                </span>
                <span>
                    <FontAwesomeIcon icon={faFilter} />
                </span>
            </span>
            {SearchResultsFilterGroups.map((g, i) => (
                <React.Fragment key={i}>
                    {g.map((k) =>
                        !k.hideIf?.(model) ? (
                            <SearchFilterButton
                                key={k.key}
                                state={state}
                                stateSubject={model.state.filters}
                                kind={k.key}
                                results={results}
                            />
                        ) : undefined
                    )}
                </React.Fragment>
            ))}
        </div>
    );
}

function VialsTable({
    table,
    model,
    results,
}: {
    table: ReactTableModel<ECMSearchResult>;
    model: ECMSearchModel;
    results: VialResults;
}) {
    const selection = useBehavior(table.selectedRowIndices);
    const filters = useBehavior(model.state.filters);
    const { headerGroups, flatRows, prepareRow } = useTable(
        {
            columns: table.allColumns as any,
            data: table.rows,
            initialState: {
                ...table.state.value,
                selectedRowIds: selection,
            },
        },
        useGlobalFilter,
        useSortBy,
        useRowSelect,
        useBlockLayout
    );

    useEffect(() => {
        const vial = table.toObjects({
            indices: Object.entries(selection)
                .filter(([_, v]) => v)
                .map(([id]) => +id),
        })[0];
        model.state.currentResult.next(vial);
    }, [selection]);

    const filteredRows = useMemo(() => filterResults(results.vials, flatRows as any, filters), [filters, flatRows]);
    // eslint-disable-next-line no-param-reassign
    model.currentVialsRows = filteredRows as any;

    useEffect(() => {
        model.state.filteredVialCount.next(filteredRows.length);
    }, [filteredRows.length]);

    return (
        <FlexibleVirtualizedTable
            headerGroups={headerGroups as any}
            rows={filteredRows as any}
            selectedRows={selection}
            prepareRow={prepareRow as any}
            rowSelectionMode='single'
            table={table}
        />
    );
}

function SearchResultsExport({ model }: { model: ECMSearchModel }) {
    const result = useBehavior(model.state.results);
    const [notifyState, applyNotify] = useAsyncAction({ rethrowError: true });

    const onSlack = () => {
        DialogService.open({
            type: 'generic',
            content: NotifyDialogContent,
            defaultState: { note: 'Batches received 🧪' },
            title: `Notify #insight-alerts with ${model.currentVialsRows.length} vial${
                model.currentVialsRows.length === 1 ? '' : 's'
            }`,
            confirmButtonContent: 'Notify',
            onOk: async (state) => {
                try {
                    if (result.kind === 'vials') {
                        const barcodes = model.currentVialsRows.map((r) => result.vials[r.original.index].barcode);
                        await applyNotify(ECMApi.vialsNotifySlack({ barcodes, note: state.note }));
                        ToastService.show({
                            type: 'success',
                            message: `Slack notified with ${barcodes.length} vial${
                                model.currentVialsRows.length === 1 ? '' : 's'
                            }`,
                            timeoutMs: 3000,
                        });
                    }
                } catch (err) {
                    reportErrorAsToast('Notify Slack', err);
                }
            },
        });
    };

    return (
        <div className='hstack flex-grow-1 mb-2'>
            <div className='m-auto' />
            <Button
                onClick={() => model.saveCSV()}
                variant='outline-primary'
                size='sm'
                className='ms-1 me-2'
                disabled={result.kind !== 'vials'}
            >
                Export CSV
            </Button>
            {!model.options?.hideNotifySlack && (
                <Button
                    onClick={onSlack}
                    variant='outline-primary'
                    size='sm'
                    className='ms-1 me-2'
                    disabled={result.kind !== 'vials' || notifyState.isLoading}
                >
                    {notifyState.isLoading && <Spinner animation='border' role='status' size='sm' className='me-2' />}
                    Notify Slack
                </Button>
            )}
        </div>
    );
}

function NotifyDialogContent({ stateSubject }: { stateSubject: BehaviorSubject<{ note: string }> }) {
    const state = useBehavior(stateSubject);
    return (
        <TextInput
            value={state.note}
            setValue={(v) => stateSubject.next({ note: v.trim() })}
            placeholder='Enter title...'
        />
    );
}

function CurrentDetails({ model }: { model: ECMSearchModel }) {
    const current = useBehavior(model.state.currentResult);
    const isPlate = !!current?.well?.length;

    return (
        <Pane
            title={`Details ${current ? `for ${current.barcode}` : ''} ${
                current && current.id < 0 ? '[Fake Vial]' : ''
            }`}
        >
            {!current && <div className='text-secondary'>Nothing selected</div>}
            {current && (
                <div className='d-flex'>
                    <div style={{ flexGrow: 0.5 }} className='vstack gap-1 mt-1'>
                        <h6>Sample {current.sample?.disposed_on ? '(disposed)' : ''}</h6>
                        <PropertyNameValue
                            field='Batch Identifier'
                            value={<BatchLink identifier={current.batch_identifier} withQuery />}
                        />
                        {isPlate && <PropertyNameValue field='Well' value={current.well} />}
                        <PropertyNameValue
                            field='Amount'
                            value={current.sample?.solute_mass ? formatUnit(current.sample.solute_mass, 'g', 'm') : '-'}
                        />
                        <PropertyNameValue
                            field='Volume'
                            value={
                                current.sample?.solvent_volume
                                    ? formatUnit(current.sample.solvent_volume * 1e3, 'L', 'u')
                                    : '-'
                            }
                        />
                        <PropertyNameValue
                            field='Concentration'
                            value={
                                current.sample?.concentration ? formatUnit(current.sample.concentration, 'M', 'm') : '-'
                            }
                        />
                        <PropertyNameValue field='Solvent' value={current.sample?.solvent ?? '-'} />
                    </div>
                    <div style={{ flexGrow: 0.5 }} className='vstack gap-1'>
                        <h6>
                            {isPlate ? 'Plate' : 'Vial'}
                            {isPlate && (
                                <Link to={`/ecm/plates/${current.id}`} target='_blank' rel='noopener noreferrer'>
                                    <FontAwesomeIcon size='sm' className='ms-2' icon={faArrowUpRightFromSquare} />
                                </Link>
                            )}
                        </h6>
                        <PropertyNameValue field='Location' value={formatLocation(current.location)} />
                        <PropertyNameValue field='Status' value={current.status} />
                        {isPlate && <PropertyNameValue field='Purpose' value={current.plate_purpose} />}
                        <PropertyNameValue field='Created By' value={current.created_by} />
                        <PropertyNameValue field='Date Created' value={formatDatetime(current.created_on, 'date')} />
                        <PropertyNameValue field='Date Modified' value={formatDatetime(current.modified_on, 'full')} />
                    </div>
                </div>
            )}
        </Pane>
    );
}

function CurrentEvents({ model }: { model: ECMSearchModel }) {
    const current = useBehavior(model.state.currentResult);
    const [events, loadEvents] = useAsyncAction<{ events: (VialEvent | PlateEvent)[] }>();

    useEffect(() => {
        const isPlate = !!current?.well?.length;
        if (isPlate) {
            loadEvents(ECMApi.plateEvents(current.id));
        } else if (current && current.id >= 0) {
            loadEvents(ECMApi.vialEvents(current.id));
        } else {
            loadEvents(undefined);
        }
    }, [current]);

    if (events.isLoading) return <Loading />;
    if (events.error) {
        return <div className='text-secondary ps-2'>Error: {tryGetErrorMessage(events.error)}</div>;
    }
    if (!events.result?.events.length) {
        return <div className='text-secondary ps-2'>No events</div>;
    }

    return (
        <AutoScrollBox scrollToTopKey={current}>
            <div className='vstack gap-2 p-2'>
                <div className='font-body-small'>
                    {events.result.events.map((e) => (
                        <div className='mb-2' key={e.id}>
                            <PropertyNameValue field='Type' value={e.kind} titleClassName='ecm-search-event-label' />
                            <PropertyNameValue
                                field='Details'
                                value={
                                    <>
                                        <div className='fw-normal'>
                                            {e.created_by}, {formatDatetime(e.created_on, 'full')}
                                        </div>
                                        {e.description && <div className='fw-normal'>{e.description}</div>}
                                    </>
                                }
                                titleClassName='ecm-search-event-label'
                            />
                        </div>
                    ))}
                </div>
            </div>
        </AutoScrollBox>
    );
}

const SearchCSVColumns = [
    'Barcode',
    'Plate Well',
    'Batch Identifier',
    'Compound Identifier',
    'Location',
    'Freezer',
    'Cabinet',
    'Shelf',
    'Rack',
    'Well',
    'Formula Weight',
    'Molecular Weight',
    'Amount',
    'Amount Unit',
    'Volume',
    'Volume Unit',
    'Solvent',
    'Concentration',
    'Concentration Unit',
    'Tare Mass',
    'Tare Mass Unit',
    'Requested Amount',
    'Requested Amount Unit',
    'Requested Volume',
    'Requested Volume Unit',
    'Requested Solvent',
    'Requested Concentration',
    'Requested Concentration Unit',
    'Batch Purity',
    'Status',
    'Project',
    'Plate Purpose',
] as const;

type SearchCSVRow = Record<(typeof SearchCSVColumns)[number], string | number>;

export function searchResultToCSV(result: ECMQueryResult, tableRows: ReactTableRow[]) {
    const rows: SearchCSVRow[] = [];

    const results = result.results.toObjects();
    let hasTotalRequested = false;

    for (const tableRow of tableRows) {
        const r = results[tableRow.original.index];
        const { sample, total_requested } = r;
        if (total_requested) hasTotalRequested = true;
        const batch = sample ? result.batches[sample.batch_id] : undefined;
        const compound = batch ? result.compounds[batch.compound_id] : undefined;
        rows.push({
            Barcode: r.barcode || '<not available>',
            'Batch Identifier': r.batch_identifier ?? '',
            'Compound Identifier': r.batch_identifier?.split('-')?.[0] ?? '',
            'Plate Well': r.well ?? '',
            Location: formatLocation(r.location),
            'Formula Weight': batch?.formula_weight ? roundValue(3, batch.formula_weight) : '',
            'Molecular Weight': compound?.molecular_weight ? roundValue(3, compound.molecular_weight) : '',
            Amount: sample?.solute_mass ? roundValue(3, sample.solute_mass * 1e3) : '',
            'Amount Unit': 'mg',
            Volume: sample?.solvent_volume ? roundValue(3, sample.solvent_volume * 1e9) : '',
            'Volume Unit': sample?.solvent_volume ? 'uL' : '',
            Concentration: sample?.concentration ? roundValue(3, 1000 * sample.concentration) : '',
            'Concentration Unit': sample?.concentration ? 'mM' : '',
            Solvent: sample?.solvent ?? '',
            'Tare Mass': typeof r.tare_mass === 'number' ? roundValue(3, r.tare_mass * 1e3) : '',
            'Tare Mass Unit': typeof r.tare_mass === 'number' ? 'mg' : '',
            'Requested Amount': total_requested?.solute_mass ? roundValue(3, total_requested.solute_mass * 1e3) : '',
            'Requested Amount Unit': total_requested?.solute_mass ? 'mg' : '',
            'Requested Volume': total_requested?.solvent_volume
                ? roundValue(3, total_requested.solvent_volume * 1e9)
                : '',
            'Requested Volume Unit': total_requested?.solvent_volume ? 'uL' : '',
            'Requested Concentration': total_requested?.concentration
                ? roundValue(3, 1000 * total_requested.concentration)
                : '',
            'Requested Concentration Unit': total_requested?.concentration ? 'mM' : '',
            'Requested Solvent': total_requested?.solvent ?? '',
            Freezer: (r.location as PlateLocation)?.freezer ?? '',
            Cabinet: (r.location as SmallVialLocation)?.cabinet ?? '',
            Shelf: (r.location as SmallVialLocation)?.shelf ?? '',
            Rack: (r.location as SmallVialLocation)?.rack ?? '',
            Well: (r.location as SmallVialLocation)?.well ?? '',
            'Batch Purity':
                typeof r.batch_purity === 'number' && Number.isFinite(r.batch_purity)
                    ? roundValue(3, r.batch_purity)
                    : '',
            Status: r.status,
            Project: r.project ?? '',
            'Plate Purpose': r.plate_purpose ?? '',
        });
    }

    const columns = hasTotalRequested ? SearchCSVColumns : SearchCSVColumns.filter((c) => !c.startsWith('Requested '));

    const csvRows: (string | number)[][] = [columns as any];
    for (const r of rows) {
        const row = [];
        for (const c of columns) row.push(r[c]);
        csvRows.push(row);
    }

    return arrayToCsv(csvRows);
}
