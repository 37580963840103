import { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { LabeledInput } from '../../../components/common/Inputs';
import { LogModel } from '../../../components/common/Log';
import { PropertyNameValue } from '../../../components/common/PropertyNameValue';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import { ToastService } from '../../../lib/services/toast';
import { reportErrorAsToast, tryGetErrorMessage } from '../../../lib/util/errors';
import { Batch, BatchIdentifiers } from '../../Compounds/compound-api';
import { HTESolvent } from '../../HTE/experiment-data';
import { SelectSolvent } from '../../HTE/steps/reactants-model';
import { formatSampleContentInline, SampleCreate, Vial } from '../ecm-api';
import { BatchLink, ECMCommonUploadInfo, ECMPageTemplate, ECMWorkflowMode, ECMWorkflowModeSelect } from '../ecm-common';
import {
    ecmAPITemplate,
    ECMBatchWorkflowBase,
    ECMBatchWorkflowWrapper,
    ecmGatherMessages,
    ECMManualWorkflowBase,
    ECMManualWorkflowInputHelper,
    ECMManualWorkflowStatus,
    ECMManualWorkflowWrapper,
    ecmWorkflowEntrySummary,
    uploadECMCSV,
} from './common';

interface PrepareFillInput {
    row_index?: number;
    barcode?: string;
    batch_identifier?: string;
    amount?: number;
    amount_unit?: string;
    volume?: number;
    volume_unit?: string;
    concentration?: number;
    concentration_unit?: string;
    solvent?: string;
}

interface PrepareFillEntry {
    input: PrepareFillInput;

    errors: string[];
    warnings: string[];

    vial?: Vial;
    barcode?: string;
    batch?: Batch;
    batch_identifiers?: BatchIdentifiers;
    sample_create?: SampleCreate;
}

type PrepareFillEntries = PrepareFillEntry[];

const FillAPI = ecmAPITemplate<PrepareFillInput[], PrepareFillEntries>('vials/fill');

interface ManualVialFillWorkflowInput {
    barcode: string;
    batch_identifier: string;
    amount: string;
    volume: string;
    concentration: string;
    solvent?: HTESolvent;
}

const EmptyInput: ManualVialFillWorkflowInput = {
    barcode: '',
    batch_identifier: '',
    amount: '',
    volume: '',
    concentration: '',
};

class ManualVialFillWorkflow implements ECMManualWorkflowBase<PrepareFillEntry> {
    inputs = {
        barcode: { current: null } as MutableRefObject<HTMLInputElement | null>,
        batch_identifier: { current: null } as MutableRefObject<HTMLInputElement | null>,
        amount: { current: null } as MutableRefObject<HTMLInputElement | null>,
        volume: { current: null } as MutableRefObject<HTMLInputElement | null>,
        concentration: { current: null } as MutableRefObject<HTMLInputElement | null>,
    };

    state = {
        input: new BehaviorSubject<ManualVialFillWorkflowInput>(EmptyInput),
        entry: new BehaviorSubject<PrepareFillEntry | undefined>(undefined),
        autosubmit: new BehaviorSubject<boolean>(false),
        preparing: new BehaviorSubject<boolean>(false),
        submitting: new BehaviorSubject<boolean>(false),
    };

    log = new LogModel();

    get autosubmit() {
        return this.state.autosubmit.value;
    }

    get isInputValid(): boolean {
        // TODO: this could be improved
        const input = this.state.input.value;
        return (
            input.barcode.length > 0 &&
            (input.amount.length > 0 || input.volume.length > 0 || input.concentration.length > 0)
        );
    }

    async prepare() {
        ToastService.remove('warn-autosubmit');

        if (!this.isInputValid) {
            return;
        }

        try {
            this.state.preparing.next(true);
            const input = this.state.input.value;
            const entries = await FillAPI.prepare([
                {
                    barcode: input.barcode,
                    batch_identifier: input.batch_identifier,
                    amount: input.amount.trim() ? +input.amount : undefined,
                    amount_unit: 'mg',
                    volume: input.volume.trim() ? +input.volume : undefined,
                    volume_unit: 'uL',
                    concentration: input.concentration.trim() ? +input.concentration : undefined,
                    concentration_unit: 'mM',
                    solvent: input.solvent || undefined,
                },
            ]);

            const entry = entries[0];
            this.state.entry.next(entry);
            if (!entry) return;

            if (!entry.barcode) {
                requestAnimationFrame(() => this.inputs.barcode.current?.focus());
                return;
            }
            if (!entry.batch) {
                requestAnimationFrame(() => this.inputs.batch_identifier.current?.focus());
                return;
            }

            if (this.autosubmit && entry.errors.length === 0 && entry.warnings.length === 0) {
                this.submit();
                return;
            }

            if (this.autosubmit && entry.errors.length === 0 && entry.warnings.length > 0) {
                ToastService.show({
                    id: 'warn-autosubmit',
                    type: 'warning',
                    message: 'Auto-submit paused due to a warning.\nPlease fix the issue or submit manually.',
                });
            }
        } catch (err) {
            reportErrorAsToast('Fill', err);
            this.state.entry.next(undefined);
        } finally {
            this.state.preparing.next(false);
        }
    }

    async submit() {
        const entry = this.state.entry.value;
        if (!entry) return;

        try {
            this.state.submitting.next(true);

            await FillAPI.upload([entry]);
            // NOTE: For testing to fake upload
            // await new Promise((res) => {
            //     setTimeout(res, 500);
            // });

            this.log.message(
                'success',
                `Vial ${entry.barcode} filled with ${
                    entry.batch_identifiers?.identifier
                } to ${formatSampleContentInline(entry.sample_create!)}.`
            );
            this.state.input.next(EmptyInput);
            this.state.entry.next(undefined);
            requestAnimationFrame(() => this.inputs.barcode.current?.focus());
        } catch (err) {
            this.log.message('danger', `Vial ${entry?.barcode} error: ${tryGetErrorMessage(err)}`);
        } finally {
            this.state.submitting.next(false);
        }
    }
}

class BatchVialFillWorkflow implements ECMBatchWorkflowBase<PrepareFillEntries> {
    state = {
        submitting: new BehaviorSubject<boolean>(false),
    };
    entries: PrepareFillEntries;
    summary: ECMBatchWorkflowBase['summary'];
    messages: ECMBatchWorkflowBase['messages'];

    async submit() {
        if (this.summary.numErrors > 0 || !this.entries.length) {
            return;
        }

        try {
            this.state.submitting.next(true);

            await FillAPI.upload(this.entries);
            // NOTE: For testing to fake upload
            // await new Promise((res) => {
            //     setTimeout(res, 500);
            // });

            this.fileSubject.next(null);
            ToastService.show({
                type: 'success',
                message: `Successfully updated ${this.entries.length} vial${
                    this.entries.length === 1 ? '' : 's'
                } in Foundry.`,
            });
        } catch (err) {
            reportErrorAsToast('Fill', err);
        } finally {
            this.state.submitting.next(false);
        }
    }

    constructor(
        public files: [filename: string, entries: PrepareFillEntries][],
        private fileSubject: BehaviorSubject<File[] | null>
    ) {
        this.entries = files.flatMap((f) => f[1]);
        this.summary = ecmWorkflowEntrySummary(this.entries);
        this.messages = ecmGatherMessages(files);
    }
}

export function ECMFill() {
    const [mode, setMode] = useState<ECMWorkflowMode>('manual');

    return (
        <ECMPageTemplate page='fill' withFooter>
            <ECMWorkflowModeSelect mode={mode} setMode={setMode} />
            {mode === 'manual' && <ManualRoot />}
            {mode === 'batch' && <BatchRoot />}
        </ECMPageTemplate>
    );
}

const ManualModel = new ManualVialFillWorkflow();

function ManualRoot() {
    const model = ManualModel;

    return (
        <ECMManualWorkflowWrapper
            model={model}
            input={<ManualInput model={model} />}
            status={<ManualStatus model={model} />}
        />
    );
}

function ManualInput({ model }: { model: ManualVialFillWorkflow }) {
    const preparing = useBehavior(model.state.preparing);
    const submitting = useBehavior(model.state.submitting);
    const disabled = preparing || submitting;

    const input = useBehavior(model.state.input);

    return (
        <ECMManualWorkflowInputHelper model={model} disabled={disabled} preparing={preparing}>
            <LabeledInput label='Barcode' className='ecm-manual-inputs-row'>
                <Form.Control
                    value={input.barcode}
                    ref={model.inputs.barcode}
                    onChange={(e) => model.state.input.next({ ...input, barcode: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.barcode) model.inputs.batch_identifier.current?.focus();
                    }}
                    disabled={disabled}
                    autoFocus
                />
            </LabeledInput>
            <LabeledInput label='Solvent' className='ecm-manual-inputs-row'>
                <SelectSolvent
                    value={input.solvent}
                    setValue={(solvent) => model.state.input.next({ ...input, solvent })}
                    allowEmpty
                />
            </LabeledInput>
            <LabeledInput label='Batch Ident.' className='ecm-manual-inputs-row'>
                <Form.Control
                    value={input.batch_identifier}
                    ref={model.inputs.batch_identifier}
                    onChange={(e) => model.state.input.next({ ...input, batch_identifier: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.barcode) model.inputs.amount.current?.focus();
                    }}
                    disabled={disabled}
                />
            </LabeledInput>
            <LabeledInput label='Amount (mg)' className='ecm-manual-inputs-row'>
                <Form.Control
                    value={input.amount}
                    ref={model.inputs.amount}
                    onChange={(e) => model.state.input.next({ ...input, amount: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.barcode) model.inputs.volume.current?.focus();
                    }}
                    disabled={disabled}
                />
            </LabeledInput>
            <LabeledInput label='Volume (μL)' className='ecm-manual-inputs-row'>
                <Form.Control
                    value={input.volume}
                    ref={model.inputs.volume}
                    onChange={(e) => model.state.input.next({ ...input, volume: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.barcode) model.inputs.concentration.current?.focus();
                    }}
                    disabled={disabled}
                />
            </LabeledInput>
            <LabeledInput label='Concentration (mM)' className='ecm-manual-inputs-row'>
                <Form.Control
                    value={input.concentration}
                    ref={model.inputs.concentration}
                    onChange={(e) => model.state.input.next({ ...input, concentration: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.amount) model.prepare();
                    }}
                    disabled={disabled}
                />
            </LabeledInput>
        </ECMManualWorkflowInputHelper>
    );
}

function ManualStatus({ model }: { model: ManualVialFillWorkflow }) {
    return (
        <ECMManualWorkflowStatus model={model}>
            {(entry) => (
                <>
                    <PropertyNameValue field='Barcode' value={entry.barcode ?? entry.input.barcode} />
                    <PropertyNameValue field='Vial Status' value={entry.vial?.status ?? 'n/a'} />
                    <PropertyNameValue
                        field='Batch Identifier'
                        value={
                            entry.batch_identifiers?.identifier ? (
                                <BatchLink identifier={entry.batch_identifiers?.identifier} withQuery />
                            ) : (
                                'n/a'
                            )
                        }
                    />
                    <PropertyNameValue
                        field='Action'
                        value={entry.vial ? (entry.vial?.sample ? 'Update Sample' : 'Create Sample') : 'n/a'}
                    />
                    {!!entry.vial?.sample && (
                        <PropertyNameValue field='Old Sample' value={formatSampleContentInline(entry.vial.sample!)} />
                    )}
                    <PropertyNameValue
                        field='New Sample'
                        value={entry.sample_create ? formatSampleContentInline(entry.sample_create) : 'n/a'}
                    />
                </>
            )}
        </ECMManualWorkflowStatus>
    );
}

async function uploadCSV(files: File[] | null, fileSubject: BehaviorSubject<File[] | null>) {
    return uploadECMCSV(FillAPI, BatchVialFillWorkflow, files, fileSubject);
}

function BatchRoot() {
    const [model, loadModel] = useAsyncAction<BatchVialFillWorkflow | undefined>();

    const upload = useCallback(
        (files: File[] | null, fileSubject: BehaviorSubject<File[] | null>) => loadModel(uploadCSV(files, fileSubject)),
        [loadModel]
    );
    const info = useMemo(() => <BatchUploadInfo />, []);

    return <ECMBatchWorkflowWrapper model={model} upload={upload} info={info} />;
}

function BatchUploadInfo() {
    return (
        <ECMCommonUploadInfo
            required={['Barcode', 'Batch Identifier', 'Amount', 'Volume', 'Concentration']}
            optional={['Solvent (=DMSO)', 'Amount Unit (=mg)', 'Volume Unit (=uL)', 'Concentration Unit (=mM)']}
        />
    );
}
