export const _384_384c_1pt = `ID,Point,Row,Column,IsControl
1,1,1,1,False
17,1,1,2,False
33,1,1,3,False
49,1,1,4,False
65,1,1,5,False
81,1,1,6,False
97,1,1,7,False
113,1,1,8,False
129,1,1,9,False
145,1,1,10,False
161,1,1,11,False
177,1,1,12,False
193,1,1,13,False
209,1,1,14,False
225,1,1,15,False
241,1,1,16,False
257,1,1,17,False
273,1,1,18,False
289,1,1,19,False
305,1,1,20,False
321,1,1,21,False
337,1,1,22,False
353,1,1,23,False
369,1,1,24,False
2,1,2,1,False
18,1,2,2,False
34,1,2,3,False
50,1,2,4,False
66,1,2,5,False
82,1,2,6,False
98,1,2,7,False
114,1,2,8,False
130,1,2,9,False
146,1,2,10,False
162,1,2,11,False
178,1,2,12,False
194,1,2,13,False
210,1,2,14,False
226,1,2,15,False
242,1,2,16,False
258,1,2,17,False
274,1,2,18,False
290,1,2,19,False
306,1,2,20,False
322,1,2,21,False
338,1,2,22,False
354,1,2,23,False
370,1,2,24,False
3,1,3,1,False
19,1,3,2,False
35,1,3,3,False
51,1,3,4,False
67,1,3,5,False
83,1,3,6,False
99,1,3,7,False
115,1,3,8,False
131,1,3,9,False
147,1,3,10,False
163,1,3,11,False
179,1,3,12,False
195,1,3,13,False
211,1,3,14,False
227,1,3,15,False
243,1,3,16,False
259,1,3,17,False
275,1,3,18,False
291,1,3,19,False
307,1,3,20,False
323,1,3,21,False
339,1,3,22,False
355,1,3,23,False
371,1,3,24,False
4,1,4,1,False
20,1,4,2,False
36,1,4,3,False
52,1,4,4,False
68,1,4,5,False
84,1,4,6,False
100,1,4,7,False
116,1,4,8,False
132,1,4,9,False
148,1,4,10,False
164,1,4,11,False
180,1,4,12,False
196,1,4,13,False
212,1,4,14,False
228,1,4,15,False
244,1,4,16,False
260,1,4,17,False
276,1,4,18,False
292,1,4,19,False
308,1,4,20,False
324,1,4,21,False
340,1,4,22,False
356,1,4,23,False
372,1,4,24,False
5,1,5,1,False
21,1,5,2,False
37,1,5,3,False
53,1,5,4,False
69,1,5,5,False
85,1,5,6,False
101,1,5,7,False
117,1,5,8,False
133,1,5,9,False
149,1,5,10,False
165,1,5,11,False
181,1,5,12,False
197,1,5,13,False
213,1,5,14,False
229,1,5,15,False
245,1,5,16,False
261,1,5,17,False
277,1,5,18,False
293,1,5,19,False
309,1,5,20,False
325,1,5,21,False
341,1,5,22,False
357,1,5,23,False
373,1,5,24,False
6,1,6,1,False
22,1,6,2,False
38,1,6,3,False
54,1,6,4,False
70,1,6,5,False
86,1,6,6,False
102,1,6,7,False
118,1,6,8,False
134,1,6,9,False
150,1,6,10,False
166,1,6,11,False
182,1,6,12,False
198,1,6,13,False
214,1,6,14,False
230,1,6,15,False
246,1,6,16,False
262,1,6,17,False
278,1,6,18,False
294,1,6,19,False
310,1,6,20,False
326,1,6,21,False
342,1,6,22,False
358,1,6,23,False
374,1,6,24,False
7,1,7,1,False
23,1,7,2,False
39,1,7,3,False
55,1,7,4,False
71,1,7,5,False
87,1,7,6,False
103,1,7,7,False
119,1,7,8,False
135,1,7,9,False
151,1,7,10,False
167,1,7,11,False
183,1,7,12,False
199,1,7,13,False
215,1,7,14,False
231,1,7,15,False
247,1,7,16,False
263,1,7,17,False
279,1,7,18,False
295,1,7,19,False
311,1,7,20,False
327,1,7,21,False
343,1,7,22,False
359,1,7,23,False
375,1,7,24,False
8,1,8,1,False
24,1,8,2,False
40,1,8,3,False
56,1,8,4,False
72,1,8,5,False
88,1,8,6,False
104,1,8,7,False
120,1,8,8,False
136,1,8,9,False
152,1,8,10,False
168,1,8,11,False
184,1,8,12,False
200,1,8,13,False
216,1,8,14,False
232,1,8,15,False
248,1,8,16,False
264,1,8,17,False
280,1,8,18,False
296,1,8,19,False
312,1,8,20,False
328,1,8,21,False
344,1,8,22,False
360,1,8,23,False
376,1,8,24,False
9,1,9,1,False
25,1,9,2,False
41,1,9,3,False
57,1,9,4,False
73,1,9,5,False
89,1,9,6,False
105,1,9,7,False
121,1,9,8,False
137,1,9,9,False
153,1,9,10,False
169,1,9,11,False
185,1,9,12,False
201,1,9,13,False
217,1,9,14,False
233,1,9,15,False
249,1,9,16,False
265,1,9,17,False
281,1,9,18,False
297,1,9,19,False
313,1,9,20,False
329,1,9,21,False
345,1,9,22,False
361,1,9,23,False
377,1,9,24,False
10,1,10,1,False
26,1,10,2,False
42,1,10,3,False
58,1,10,4,False
74,1,10,5,False
90,1,10,6,False
106,1,10,7,False
122,1,10,8,False
138,1,10,9,False
154,1,10,10,False
170,1,10,11,False
186,1,10,12,False
202,1,10,13,False
218,1,10,14,False
234,1,10,15,False
250,1,10,16,False
266,1,10,17,False
282,1,10,18,False
298,1,10,19,False
314,1,10,20,False
330,1,10,21,False
346,1,10,22,False
362,1,10,23,False
378,1,10,24,False
11,1,11,1,False
27,1,11,2,False
43,1,11,3,False
59,1,11,4,False
75,1,11,5,False
91,1,11,6,False
107,1,11,7,False
123,1,11,8,False
139,1,11,9,False
155,1,11,10,False
171,1,11,11,False
187,1,11,12,False
203,1,11,13,False
219,1,11,14,False
235,1,11,15,False
251,1,11,16,False
267,1,11,17,False
283,1,11,18,False
299,1,11,19,False
315,1,11,20,False
331,1,11,21,False
347,1,11,22,False
363,1,11,23,False
379,1,11,24,False
12,1,12,1,False
28,1,12,2,False
44,1,12,3,False
60,1,12,4,False
76,1,12,5,False
92,1,12,6,False
108,1,12,7,False
124,1,12,8,False
140,1,12,9,False
156,1,12,10,False
172,1,12,11,False
188,1,12,12,False
204,1,12,13,False
220,1,12,14,False
236,1,12,15,False
252,1,12,16,False
268,1,12,17,False
284,1,12,18,False
300,1,12,19,False
316,1,12,20,False
332,1,12,21,False
348,1,12,22,False
364,1,12,23,False
380,1,12,24,False
13,1,13,1,False
29,1,13,2,False
45,1,13,3,False
61,1,13,4,False
77,1,13,5,False
93,1,13,6,False
109,1,13,7,False
125,1,13,8,False
141,1,13,9,False
157,1,13,10,False
173,1,13,11,False
189,1,13,12,False
205,1,13,13,False
221,1,13,14,False
237,1,13,15,False
253,1,13,16,False
269,1,13,17,False
285,1,13,18,False
301,1,13,19,False
317,1,13,20,False
333,1,13,21,False
349,1,13,22,False
365,1,13,23,False
381,1,13,24,False
14,1,14,1,False
30,1,14,2,False
46,1,14,3,False
62,1,14,4,False
78,1,14,5,False
94,1,14,6,False
110,1,14,7,False
126,1,14,8,False
142,1,14,9,False
158,1,14,10,False
174,1,14,11,False
190,1,14,12,False
206,1,14,13,False
222,1,14,14,False
238,1,14,15,False
254,1,14,16,False
270,1,14,17,False
286,1,14,18,False
302,1,14,19,False
318,1,14,20,False
334,1,14,21,False
350,1,14,22,False
366,1,14,23,False
382,1,14,24,False
15,1,15,1,False
31,1,15,2,False
47,1,15,3,False
63,1,15,4,False
79,1,15,5,False
95,1,15,6,False
111,1,15,7,False
127,1,15,8,False
143,1,15,9,False
159,1,15,10,False
175,1,15,11,False
191,1,15,12,False
207,1,15,13,False
223,1,15,14,False
239,1,15,15,False
255,1,15,16,False
271,1,15,17,False
287,1,15,18,False
303,1,15,19,False
319,1,15,20,False
335,1,15,21,False
351,1,15,22,False
367,1,15,23,False
383,1,15,24,False
16,1,16,1,False
32,1,16,2,False
48,1,16,3,False
64,1,16,4,False
80,1,16,5,False
96,1,16,6,False
112,1,16,7,False
128,1,16,8,False
144,1,16,9,False
160,1,16,10,False
176,1,16,11,False
192,1,16,12,False
208,1,16,13,False
224,1,16,14,False
240,1,16,15,False
256,1,16,16,False
272,1,16,17,False
288,1,16,18,False
304,1,16,19,False
320,1,16,20,False
336,1,16,21,False
352,1,16,22,False
368,1,16,23,False
384,1,16,24,False
`;
