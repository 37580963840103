import { IconName, IconDefinition } from '@fortawesome/fontawesome-svg-core';

// This is a way to make a new icon to be treated like icons in the
// Font Awesome set - structure kind of copied from their icon definitions.
// see node_modules/@fortawesome/node_modules/@fortawesome/fontawesome-common-types/index.d.ts
// for the IconDefinition type definition

const iconName = 'ii-union' as IconName;
const prefix = 'fas';
const width: number = 16;
const height: number = 16;
const aliases: string[] = [];
const unicode: string = '';
const svgPathData: string = [
    'M 2,1 C 1.4536358,1 1,1.4536358 1,2 v 8 c 0,0.546363 0.4535875,1 1,1 h 3 v 3 c 0,0.546363 0.4535875,1 1,1 h 8 c 0.546411,0 1,-0.453589 1,-1 V 6 C 15,5.4535875 14.546363,5 14,5 H 11 V 2 C 11,1.4535875 10.546363,1 10,1 Z',
].join('');

export const iiUnion: IconDefinition = {
    prefix,
    iconName,
    icon: [width, height, aliases, unicode, svgPathData],
};
