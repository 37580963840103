import log from 'loglevel';

export interface DebouncedRequestWrapperParams<T> {
    provider: () => Promise<T>;
    apply: (v: T) => any;
    error?: (e: any) => any;
    debounceMs?: number;
    retryOnError?: { timeoutMs: number; timeoutIncrementMs?: number; maxRetries: number };
}

export function debouncedRequestWrapper<T>({
    provider,
    apply,
    error,
    debounceMs,
    retryOnError,
}: DebouncedRequestWrapperParams<T>): (options?: { force?: boolean }) => Promise<T> {
    const timeout = debounceMs ?? 5000;
    let isRunning = false;
    let lastApply = 0;
    let retryTimeoutHook: any = undefined; // eslint-disable-line
    let currentPromise: Promise<T> | undefined;
    let cancelToken: { cancelled: boolean } = { cancelled: false };

    return async function _apply(options?: { force?: boolean }, retryCount = 0) {
        if (retryTimeoutHook !== undefined) {
            clearTimeout(retryTimeoutHook);
            retryTimeoutHook = undefined;
        }

        if (isRunning || Date.now() - lastApply < timeout) {
            if (options?.force) {
                // If a previous request is still running, do not apply its result
                cancelToken.cancelled = true;
            } else {
                return currentPromise!;
            }
        }

        async function run(res: (v: T) => void, rej: (e: any) => void) {
            const cancel = cancelToken;

            try {
                isRunning = true;
                const v = await provider();
                lastApply = Date.now();
                if (!cancel.cancelled) apply(v);
                res(v);
            } catch (e) {
                lastApply = 0;
                log.error(e);
                if (!cancel.cancelled) error?.(e);
                if (!cancel.cancelled && retryOnError && retryCount < retryOnError.maxRetries) {
                    retryTimeoutHook = setTimeout(
                        () => _apply(options, retryCount + 1),
                        retryOnError.timeoutMs + (retryOnError.timeoutIncrementMs ?? 0) * retryCount
                    );
                }
                rej(e);
            } finally {
                isRunning = false;
            }
        }

        currentPromise = new Promise((res, rej) => {
            cancelToken = { cancelled: false };
            run(res, rej);
        });
        return currentPromise;
    };
}
