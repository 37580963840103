import { Batch, CompoundDetail } from '../Compounds/compound-api';
import { HTEApi } from './experiment-api';
import { isHTESolvent, Reactant, Reaction } from './experiment-data';

export class ExperimentBatchManager {
    private batches = new Map<string, Batch>();
    private batchesById = new Map<number, Batch>();
    private compoundsById = new Map<number, CompoundDetail>();
    private batchesByCompoundId = new Map<number, Batch[]>();
    private compounds = new Map<string, CompoundDetail>();

    async init(reactants: Reactant[], reactions: Reaction[]) {
        const identifiers = new Set<string>();

        for (const r of reactants) {
            if (!isHTESolvent(r)) {
                identifiers.add(r.identifier);
            }
        }
        for (const r of reactions) {
            identifiers.add(r.bb_identifier);
            identifiers.add(r.msd_identifier);
            identifiers.add(r.product_identifier);
        }

        const batchesInit = await HTEApi.batchesAndCompounds(Array.from(identifiers));

        this.addCompounds(batchesInit.compounds);
        this.addBatches(batchesInit.batches);

        return batchesInit;
    }

    addCompounds(compounds: CompoundDetail[]) {
        for (const c of compounds) {
            this.compoundsById.set(c.id, c);

            this.compounds.set(c.identifier!, c);
            if (c.entos_identifier) this.compounds.set(c.entos_identifier, c);
            if (c.bb_identifier) this.compounds.set(c.bb_identifier, c);
            if (c.msd_identifier) this.compounds.set(c.msd_identifier, c);
            if (c.reagent_identifier) this.compounds.set(c.reagent_identifier, c);
            if (c.universal_identifier) this.compounds.set(c.universal_identifier, c);
        }
    }

    addBatches(batches: Batch[]) {
        for (const b of batches) {
            this.batchesById.set(b.id, b);

            this.batches.set(b.identifier!, b);
            if (b.entos_identifier) this.batches.set(b.entos_identifier, b);
            if (b.bb_identifier) this.batches.set(b.bb_identifier, b);
            if (b.msd_identifier) this.batches.set(b.msd_identifier, b);
            if (b.reagent_identifier) this.batches.set(b.reagent_identifier, b);
            if (b.universal_identifier) this.batches.set(b.universal_identifier, b);

            let byCompound = this.batchesByCompoundId.get(b.compound_id);
            if (!byCompound) {
                byCompound = [];
                this.batchesByCompoundId.set(b.compound_id, byCompound);
            }
            if (!byCompound.find((x) => x.id === b.id)) {
                byCompound.push(b);
            }
        }
    }

    getSmiles(identifier: string | undefined) {
        if (!identifier) return undefined;
        return (this.batches.get(identifier) ?? this.compounds.get(identifier))?.structure.smiles;
    }

    getEntity(identifier: string | undefined): Batch | CompoundDetail | undefined {
        if (!identifier) return undefined;
        return this.batches.get(identifier) ?? this.compounds.get(identifier);
    }

    getEntityKey(identifier: string): string {
        const batch = this.batches.get(identifier);
        if (batch) return `batch-${batch.id}`;
        return `compound-${this.compounds.get(identifier)?.id}`;
    }

    getBatch(identifier: string | undefined): Batch | undefined {
        if (!identifier) return undefined;
        return this.batches.get(identifier);
    }

    getBatchFromId(id: number | undefined): Batch | undefined {
        if (typeof id !== 'number') return undefined;
        return this.batchesById.get(id);
    }

    private _emptyBatches: Batch[] = [];
    getBatchesFromCompoundId(compoundId: number) {
        return this.batchesByCompoundId.get(compoundId) ?? this._emptyBatches;
    }

    getCompound(identifier: string | undefined): CompoundDetail | undefined {
        if (!identifier) return undefined;
        const batch = this.batches.get(identifier);
        if (batch) return this.compoundsById.get(batch.compound_id);
        return this.compounds.get(identifier);
    }

    getCompoundFromId(id: number | undefined): CompoundDetail | undefined {
        if (id === undefined) return undefined;
        return this.compoundsById.get(id);
    }

    getMolecularWeight(identifier: string) {
        const batch = this.batches.get(identifier);
        if (batch) return batch.formula_weight;
        return this.compounds.get(identifier)?.molecular_weight;
    }
}
