import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Spinner } from 'react-bootstrap';
import { TableInstance, useBlockLayout, useGlobalFilter, useSortBy, useTable } from 'react-table';
import { ReactTableGlobalFilter } from '../../../components/ReactTable/Filters';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import { DialogService } from '../../../lib/services/dialog';
import { HTEEntityWrapper } from '../experiment-data';
import { HTEExperimentModel } from '../experiment-model';
import { HTEExperimentTable } from './common';
import { AddReagentDialogControls } from './reagents-model';

export function ReagentsStep({ model }: { model: HTEExperimentModel }) {
    // update when the design changes, e.g. a reactant is added
    useBehavior(model.state.design);
    const all = useBehavior(model.reagents.state.all);
    const table = useTable(
        {
            columns: model.reagents.columns,
            data: all,
        },
        useGlobalFilter,
        useSortBy,
        useBlockLayout
    );

    return (
        <div className='hte-experiment-compounds-step'>
            <div className='ps-4 pe-4 pt-4 pb-2'>
                <CompoundsHeader table={table} model={model} />
            </div>

            <div className='hte-experiment-compounds-content'>
                <HTEExperimentTable table={table} lineHeight={60} />
            </div>
        </div>
    );
}

function CompoundsHeader({ model, table }: { model: HTEExperimentModel; table: TableInstance<HTEEntityWrapper> }) {
    return (
        <div className='hstack gap-2'>
            {!model.isLocked && <AddReagentButton model={model} />}
            <ReactTableGlobalFilter
                preGlobalFilteredRows={table.preGlobalFilteredRows}
                globalFilter={table.state.globalFilter}
                setGlobalFilter={table.setGlobalFilter}
            />
        </div>
    );
}

function AddReagentButton({ model }: { model: HTEExperimentModel }) {
    const [applyState, apply] = useAsyncAction();

    const onClick = () => {
        DialogService.open({
            type: 'generic',
            title: `Add Reagent(s)`,
            model: {},
            defaultState: { input: '' },
            content: AddReagentDialogControls,
            onOk: (state) => apply(model.reagents.addReagents(state.input)),
        });
    };

    return (
        <Button variant='primary' onClick={onClick} style={{ width: 200 }} disabled={applyState.isLoading}>
            {applyState.isLoading && <Spinner animation='border' size='sm' className='me-2' role='status' />}
            {!applyState.isLoading && <FontAwesomeIcon className='me-2' icon={faPlus} />}
            Add Reagent(s)
        </Button>
    );
}
