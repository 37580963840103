// Source: Envision https://github.com/EntosAI/envision/blob/master/envision/src/util/array-to-csv.ts
// Note(dsehnal): chose to add own utility function because existing "RFC 4180"
// compliant packages all seemed like poor choices (https://www.npmjs.com/search?q=RFC%204180)

import { isBlank } from './misc';

export function arrayToCsv(rows: (string | number)[][], options?: { separator?: string }) {
    const quoteTest = /"|,|\r\n|\n|\r/;

    const lines: string[] = [];
    for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const rowData: any[] = [];

        for (let j = 0; j < row.length; j++) {
            const value = row[j];
            if (typeof value === 'string') {
                let e = value.replace(/"/g, '""');
                if (quoteTest.test(e)) {
                    e = `"${e}"`;
                }
                rowData.push(e);
            } else if (isBlank(value)) {
                rowData.push('');
            } else {
                rowData.push(value);
            }
        }

        lines.push(rowData.join(options?.separator ?? ','));
    }

    return lines.join('\r\n');
}

export function objectsToRowArrays<T extends { [K in string]: string | number }>(
    rows: T[],
    columns: readonly (keyof T)[]
) {
    const csvRows: (string | number)[][] = [columns as any];
    for (const r of rows) {
        const row = [];
        for (const c of columns) row.push(r[c]);
        csvRows.push(row);
    }
    return csvRows;
}
