import { faEdit, faFlask, faListCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import Split from 'react-split-it';
import { AsyncButton } from '../../../components/common/AsyncButton';
import { AsyncMoleculeDrawing } from '../../../components/common/AsyncMoleculeDrawing';
import { AutoSizeBox } from '../../../components/common/AutoSizeBox';
import { TextInput } from '../../../components/common/Inputs';
import { PillNav, PillNavStep } from '../../../components/common/Nav';
import { ScrollBox } from '../../../components/common/ScrollBox';
import useBehavior from '../../../lib/hooks/useBehavior';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { roundValue } from '../../../lib/util/roundValues';
import { prefixedUnitValue } from '../../../lib/util/units';
import { BatchLink } from '../../ECM/ecm-common';
import { PlateVisual } from '../../HTE/plate/PlateVisual';
import { HTEPMoquitoInstructionT, HTEPProduct, HTEPReactionSample } from '../data-model';
import { ProductBlocks } from '../design/product-blocks-ui';
import { HTEReactionsUI } from '../design/reactions-ui';
import { HTEDSettingsUI } from '../design/settings-ui';
import { HTEWModel } from '../model';
import { UsesMarkdown } from '../utils';
import { HTEMosquitoProtocolModel, HTEProtocolTab } from './mosquito';
import { HTEPPlatesModel } from './plates';

export function HTEProtocolUI({ model }: { model: HTEWModel }) {
    const protocol = useBehavior(model.state.protocol);
    useMountedModel(protocol);

    if (!protocol) {
        return <div className='m-2 text-secondary'>Design not built</div>;
    }

    return (
        <Split direction='vertical' gutterSize={6} sizes={[0.75, 0.25]}>
            <Split direction='horizontal' gutterSize={6} sizes={[0.33, 0.66]}>
                <Product model={protocol} />
                <Tabs model={protocol} />
            </Split>
            <Split direction='horizontal' gutterSize={6} sizes={[0.2, 0.8]}>
                <InstructionsPane model={protocol} />
                <SourcePlatesPane model={protocol} />
            </Split>
        </Split>
    );
}

const NavTabs1: PillNavStep<HTEProtocolTab>[] = [
    { name: 'reactions', label: 'Reactions' },
    { name: 'product-blocks', label: 'Reactants' },
    { name: 'settings', label: 'Settings' },
];

const NavTabs2: PillNavStep<HTEProtocolTab>[] = [{ name: 'procedure', label: 'Procedure' }];

function Tabs({ model }: { model: HTEMosquitoProtocolModel }) {
    const tab = useBehavior(model.state.tab);
    const parentModel = model.model;

    return (
        <div className='d-flex flex-column h-100 ps-2'>
            <div className='hstack gap-2 me-3 pt-2 mb-2'>
                <PillNav steps={NavTabs1} currentStep={tab} onSetStep={(s) => model.state.tab.next(s)} />
                <PillNav steps={NavTabs2} currentStep={tab} onSetStep={(s) => model.state.tab.next(s)} />
                <div className='m-auto' />
                {!parentModel.isLocked && !parentModel.inProgress && !parentModel.needsFinalization && (
                    <AsyncButton onClick={() => model.model.edit()} variant='outline-primary' size='sm' icon={faEdit}>
                        Edit
                    </AsyncButton>
                )}
                <AsyncButton
                    onClick={() => model.model.state.tab.next('inventory')}
                    variant='primary'
                    size='sm'
                    icon={faListCheck}
                >
                    Check Inventory
                </AsyncButton>
            </div>
            <div className='flex-grow-1 position-relative'>
                {tab === 'settings' && <HTEDSettingsUI model={model.design.settings} />}
                {tab === 'product-blocks' && <ProductBlocks model={model.design.productBlocks} />}
                {tab === 'reactions' && <HTEReactionsUI model={model.design} />}
                {tab === 'procedure' && <Procedure model={model} />}
            </div>
        </div>
    );
}

function Product({ model }: { model: HTEMosquitoProtocolModel }) {
    const plate = useBehavior(model.state.plate);
    if (!plate) return null;

    return (
        <Split direction='vertical' gutterSize={6} sizes={[0.5, 0.5]}>
            <ProductPlate model={plate} />
            <Reaction model={plate} />
        </Split>
    );
}

function ProductPlate({ model }: { model: HTEPPlatesModel }) {
    useMountedModel(model);
    if (!model) return null;

    return (
        <div className='d-flex flex-column h-100' style={{ flexBasis: 440 }}>
            <SelectProductPlate current={model} />
            <div className='position-relative flex-grow-1 pe-2 pb-2'>
                <PlateVisual model={model.productPlate} />
            </div>
        </div>
    );
}

function SelectProductPlate({ current }: { current: HTEPPlatesModel }) {
    return (
        <div className='hstack ms-2 my-2 gap-2'>
            {current.model.plates.map((p, i) => (
                <Button variant={p === current ? 'primary' : 'outline-primary'} key={i} onClick={p.select} size='sm'>
                    {p === current ? 'Product Plate ' : ''}
                    {i + 1}
                </Button>
            ))}
        </div>
    );
}

function formatSample(sample?: HTEPReactionSample) {
    if (!sample) return '-';

    let tooltip = `${prefixedUnitValue(sample.volume, 'L', { factor: 1e3 })} @ `;
    if (sample.neat_concentration !== sample.concentration) {
        tooltip += `${prefixedUnitValue(sample.neat_concentration, 'M')} -> ${prefixedUnitValue(
            sample.concentration,
            'M'
        )}`;
    } else {
        tooltip += `${prefixedUnitValue(sample.concentration, 'M')}`;
    }
    tooltip += ` in ${sample.solvent}`;

    return (
        <>
            <FontAwesomeIcon className='me-2 text-secondary' size='sm' icon={faFlask} title={tooltip} />
            <BatchLink identifier={sample.identifier} withQuery />
        </>
    );
}

function formatProduct(product: HTEPProduct | undefined, solvent: string) {
    if (!product) return '-';

    const tooltip = `${prefixedUnitValue(product.volume, 'L', { factor: 1e3 })} @ ${prefixedUnitValue(
        product.concentration,
        'M'
    )} in ${solvent}`;

    return (
        <>
            <FontAwesomeIcon className='me-2 text-secondary' size='sm' icon={faFlask} title={tooltip} />
            <BatchLink identifier={product.identifier} withQuery />
        </>
    );
}

function Reaction({ model }: { model: HTEPPlatesModel }) {
    const reaction = useBehavior(model.state.reaction);

    return (
        <div className='d-flex flex-column h-100' style={{ flexBasis: 440 }}>
            {reaction && (
                <h6 className='ms-2 mt-2'>
                    {reaction?.reaction.name} in {reaction.wellLabel}
                </h6>
            )}
            <div className='flex-grow-1 position-relative'>
                <ScrollBox>
                    {reaction && (
                        <div className='px-2 font-body-small d-flex flex-wrap justify-content-center'>
                            <div className='me-2 d-flex align-items-center'>
                                <Badge bg='primary' style={{ fontSize: 8 }} className='me-2'>
                                    SOLVENT
                                </Badge>
                                {reaction.well.solvent}
                            </div>
                            {reaction.mainReactants.map((r, i) => (
                                <div key={r} className='me-2 d-flex align-items-center'>
                                    <Badge bg='info' style={{ fontSize: 8 }} className='me-2'>
                                        {r}
                                    </Badge>
                                    {formatSample(reaction.well.samples[r])}
                                </div>
                            ))}
                            {reaction.reagents.map((r, i) => (
                                <div key={r} className='me-2 d-flex align-items-center'>
                                    <Badge bg='warning' style={{ fontSize: 8 }} className='me-2'>
                                        {r}
                                    </Badge>
                                    {formatSample(reaction.well.samples[r])}
                                </div>
                            ))}
                            <div className='me-2 d-flex align-items-center'>
                                <Badge bg='success' style={{ fontSize: 8 }} className='me-2'>
                                    PRODUCT
                                </Badge>
                                {formatProduct(reaction.well.product, reaction.well.solvent)}
                            </div>
                        </div>
                    )}
                    <div className='position-relative flex-grow-1 pe-2 d-flex align-items-center justify-content-center'>
                        {!reaction && (
                            <div className='mx-auto mt-2 text-secondary font-body-small'>No reaction selected</div>
                        )}
                        {reaction && (
                            <AsyncMoleculeDrawing
                                drawer={model.model.model.drawer}
                                width='100%'
                                height='100%'
                                smiles={reaction.smiles}
                                autosize
                                showChemDraw={false}
                            />
                        )}
                    </div>
                </ScrollBox>
            </div>
        </div>
    );
}

function SourcePlatesPane({ model }: { model: HTEMosquitoProtocolModel }) {
    const plate = useBehavior(model.state.plate);
    if (!plate) return null;

    return (
        <div className='d-flex flex-column h-100 pb-2'>
            <div className='hstack gap-2 ps-2 pt-2 align-items-end'>
                <h6 className='mb-0'>Source Plates</h6>
                <SourceWellLabel model={plate} />
            </div>
            <SourcePlates model={plate} />
        </div>
    );
}

function SourcePlates({ model }: { model: HTEPPlatesModel }) {
    return (
        <div className='position-relative flex-grow-1'>
            <AutoSizeBox>
                {(_, h) => (
                    <div className='d-flex h-100 pe-3'>
                        {model.sourcePlateData.map((p, i) => (
                            <div
                                key={p.id}
                                style={{ flexBasis: Math.min(Math.max(h * 1.6, 180), 600) }}
                                className='position-relative mb-1'
                            >
                                <PlateVisual model={model.sourcePlates[i]} />
                                <div className='htew-source-plate-tag'>
                                    #{p.tag! + 1}: {p.label ?? '-'}
                                </div>
                            </div>
                        ))}
                        <div
                            style={{ flexBasis: Math.min(Math.max(h * 1.6, 180), 600) }}
                            className='position-relative mb-1'
                        >
                            <SourcePlateStructure plate={model} />
                        </div>
                    </div>
                )}
            </AutoSizeBox>
        </div>
    );
}

function InstructionsPane({ model }: { model: HTEMosquitoProtocolModel }) {
    const plate = useBehavior(model.state.plate);
    if (!plate) return null;

    return (
        <div className='d-flex flex-column h-100'>
            <h6 className='ms-2 mt-2 mb-1'>Robot Instructions</h6>
            <InstructionGroups model={plate} />
        </div>
    );
}

function InstructionGroups({ model }: { model: HTEPPlatesModel }) {
    if (!model) return null;

    return (
        <div className='position-relative flex-grow-1'>
            <ScrollBox className='mb-2'>
                {model.instructionGroups.map((g, i) => (
                    <InstructionGroup group={g} protocol={model} key={i} />
                ))}
            </ScrollBox>
        </div>
    );
}

export function SourceWellLabel({ model }: { model: HTEPPlatesModel }) {
    const label = useBehavior(model.state.sourceWell);

    return (
        <div className='hstack gap-2 font-body-xsmall'>
            {!label && <span className='text-secondary'>No source well selected</span>}
            {label?.well && <span>({label.well}):</span>}
            {label?.identifier && (
                <div>
                    <BatchLink identifier={label.identifier} withQuery />
                </div>
            )}
            {label?.sample && <span>[ {label.sample} ]</span>}
            {label?.reactantNames && <span>for {label.reactantNames}</span>}
        </div>
    );
}

function SourcePlateStructure({ plate }: { plate: HTEPPlatesModel }) {
    const label = useBehavior(plate?.state.sourceWell);
    const smiles = label?.identifier && plate?.model.model.assets.entities.getStructure(label.identifier);

    if (!smiles) return null;

    return (
        <div className='d-flex font-body-xsmall align-items-center justify-content-center h-100 flex-column'>
            <AsyncMoleculeDrawing smiles={smiles} width='80%' height='80%' drawer={plate.model.model.drawer} autosize />
        </div>
    );
}

export function InstructionGroup({
    protocol,
    group,
}: {
    protocol: HTEPPlatesModel;
    group: [string, HTEPMoquitoInstructionT[]];
}) {
    const [expanded, setExpanded] = useState(false);

    return (
        <div>
            <Button
                variant='link'
                className='font-body-xsmall w-100 text-start py-0 px-2 hted-protocol-instruction-group-button'
                onMouseEnter={() => protocol.highlightGroup(group[1])}
                onMouseLeave={() => protocol.clearHighlights()}
                style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                onClick={() => setExpanded((e) => !e)}
            >
                {group[0]} {group[1].length > 1 ? `(${group[1].length})` : ''}
            </Button>
            {expanded &&
                group[1].map((i, iI) => (
                    <div
                        className='ms-3 font-body-xsmall'
                        key={iI}
                        onMouseEnter={() => protocol.highlight(i)}
                        onMouseLeave={() => protocol.clearHighlights()}
                    >
                        {`${iI + 1}`}:{' '}
                        {i.kind === 'worklist' && (
                            <>
                                <span className='fw-bold'>Worklist</span>
                                <div className='text-secondary ms-2' style={{ whiteSpace: 'pre' }}>
                                    {i.plates
                                        .map((p, pI) => `Plate ${pI + 1}: ${p ?? '-'} (${i.comments?.[pI] ?? '-'})`)
                                        .join('\n')}
                                </div>
                            </>
                        )}
                        {i.kind === 'change-pipettes' && (
                            <>
                                <span className='fw-bold'>Change Pipettes</span>
                            </>
                        )}
                        {i.kind === 'comment' && (
                            <>
                                <span className='fw-bold'>Comment</span>
                                <br />
                                <span className='text-secondary ms-2'>{i.comment}</span>
                            </>
                        )}
                        {i.kind === 'pause' && (
                            <>
                                <span className='fw-bold'>Pause</span>
                                <br />
                                <span className='text-secondary ms-2'>{roundValue(2, i.duration)}s</span>
                            </>
                        )}
                        {i.kind === 'copy' && (
                            <>
                                <span className='fw-bold'>Copy</span>
                                <br />
                                <span className='text-secondary ms-2'>
                                    {roundValue(0, i.volume * 1e12)}nL #
                                    {protocol.sourcePlateMap[i.source_plate_id].tag! + 1}:{i.source_column + 1} →{' '}
                                    {i.target_column + 1}(+{i.target_row_offset})
                                </span>
                            </>
                        )}
                        {i.kind === 'multi-aspirate' && (
                            <>
                                <span className='fw-bold'>Multi-aspirate</span>
                                <br />
                                <span className='text-secondary ms-2'>
                                    {roundValue(0, i.volume * 1e12)}nL #
                                    {protocol.sourcePlateMap[i.source_plate_id].tag! + 1}:{i.source_column + 1}
                                </span>
                            </>
                        )}
                    </div>
                ))}
        </div>
    );
}

function Procedure({ model }: { model: HTEMosquitoProtocolModel }) {
    const procedure = useBehavior(model.state.procedure);

    return (
        <div className='d-flex h-100'>
            {model.design.model.canEdit && (
                <div className='flex-grow-1 d-flex flex-column' style={{ flexBasis: '50%' }}>
                    <div className='flex-grow-1 position-relative'>
                        <TextInput
                            textarea
                            autoFocus
                            value={procedure ?? ''}
                            style={{ fontFamily: 'monospace', resize: 'none' }}
                            className='w-100 h-100 font-body-small'
                            setValue={(v) => model.state.procedure.next(v)}
                            readOnly={!model.design.model.canEdit}
                        />
                    </div>
                    <div className='pt-1 text-center'>
                        <UsesMarkdown />
                    </div>
                </div>
            )}
            <div className='px-2' style={{ flexBasis: '50%' }}>
                <ReactMarkdown>{procedure}</ReactMarkdown>
            </div>
        </div>
    );
}
