import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';
import log from 'loglevel';
import { Column, Row } from 'react-table';
import { distinctUntilKeyChanged } from 'rxjs';
import { AsyncMoleculeDrawing } from '../../../components/common/AsyncMoleculeDrawing';
import { TextInput } from '../../../components/common/Inputs';
import ReactTableSchema from '../../../components/ReactTable/schema';
import { ReactTableModel } from '../../../components/ReactTable/model';
import { ToastService } from '../../../lib/services/toast';
import { arrayToCsv, objectsToRowArrays } from '../../../lib/util/arrayToCsv';
import { darkenColor, toRGBString } from '../../../lib/util/colors';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { Batch, CompoundDetail } from '../../Compounds/compound-api';
import { HTEApi } from '../experiment-api';
import { formatHTEId, Reactant, ReactantType, Reaction, Well } from '../experiment-data';
import { getWellIndexLabel } from '../plate/utils';
import { isSolutableReactant, ReactantsModel } from './reactants-model';
import { CompoundIdentifier } from './reagents-model';

export class ReactionsModel extends ReactiveModel {
    private reactants: import('./reactants-model').ReactantsModel;

    private map: Map<string, Reaction[]> = new Map();

    get isEmpty() {
        return this.map.size === 0;
    }

    findReaction(msd_identifier: string, bb_identifier: string, well_index: number): Reaction | undefined {
        const key = `${msd_identifier}:${bb_identifier}`;
        const rxns = this.map.get(key);
        if (!rxns) return undefined;
        for (const r of rxns) {
            if (typeof r.well_index === 'number' && well_index === r.well_index) return r;
        }
        return rxns[0];
    }

    getReactionSMILES(reaction: Reaction, reagentCompounds?: string[]) {
        const reagents = reagentCompounds
            ? reagentCompounds
                  .map((r) => this.experiment.batches.getSmiles(r))
                  .filter((s) => !!s)
                  .join('.')
            : '';

        const msd = this.experiment.batches.getSmiles(reaction.msd_identifier);
        const bb = this.experiment.batches.getSmiles(reaction.bb_identifier);
        const product = this.experiment.batches.getSmiles(reaction.product_identifier);

        return `${msd}.${bb}>${reagents}>${product}`;
    }

    readonly columns: Column<Reaction>[] = [
        {
            Header: 'Reaction',
            id: 'reaction',
            width: 460,
            Cell: ({ row }: { row: Row<Reaction> }) => {
                const reaction = this.getReactionSMILES(row.original);
                return <AsyncMoleculeDrawing smiles={reaction} height={94} drawer={this.experiment.drawer} />;
            },
        },
        {
            Header: 'Plated',
            id: 'reaction_plated',
            disableGlobalFilter: true,
            accessor: (row) => this.experiment.productPlate.getReactionOccurenceCount(row),
            Cell: ({ value }: { value: number }) => {
                if (value > 1) {
                    return (
                        <>
                            <FontAwesomeIcon icon={faCheck} className='text-success me-1' />
                            <small className='text-success'>{value}x</small>
                        </>
                    );
                }
                return value > 0 ? <FontAwesomeIcon icon={faCheck} className='text-success' /> : null;
            },
            width: 80,
        },
        {
            Header: 'MSD Identifier',
            id: 'msd_identifier',
            accessor: (row) => row.msd_identifier,
            Cell: ({ value }: { value: string }) => <CompoundIdentifier value={value} />,
            width: 160,
        },
        {
            Header: 'BB Identifier',
            id: 'bb_identifier',
            accessor: (row) => row.bb_identifier,
            Cell: ({ value }: { value: string }) => <CompoundIdentifier value={value} />,
            width: 160,
        },
        {
            Header: 'Product Identifier',
            id: 'product_compound_id',
            accessor: 'product_identifier',
            Cell: ({ value }) => <CompoundIdentifier value={value} />,
            width: 200,
        },
        {
            Header: 'MW',
            id: 'mw',
            accessor: 'product_molecular_weight',
            Cell: ({ value }) => value?.toFixed(2) ?? ('-' as any),
            width: 140,
        },
        {
            Header: 'Group',
            id: 'group_name',
            accessor: (row) => this.reactants.getReactant(row.bb_identifier)?.group_name,
            Cell: ({ value }: { value?: string }) => (
                <>
                    {!!value && (
                        <TextInput
                            value={value}
                            className='hte-experiment-badge-like hte-experiment-badge-like-input readonly'
                            readOnly
                            style={{
                                background: this.reactants.bbGroupColorMap.get(value)?.rgb,
                                border: this.reactants.bbGroupColorMap.get(value)
                                    ? `1px solid ${toRGBString(
                                          darkenColor(this.reactants.bbGroupColorMap.get(value)!.raw, 0.9)
                                      )}`
                                    : undefined,
                            }}
                        />
                    )}
                    {!value && '-'}
                </>
            ),
            width: 140,
        },
        {
            Header: 'MSD Order',
            id: 'msd_order',
            accessor: (row) => this.reactants.getReactant(row.msd_identifier)?.msd_order ?? '',
            width: 110,
            Cell: ({ value }: { value: number }) => (Number.isNaN(value) ? '' : (value as any)),
            sortType: (a, b) =>
                ReactTableSchema.DefaultCompareWithBlanks(
                    this.reactants.getReactant(a.original.msd_identifier)?.msd_order ?? 0,
                    this.reactants.getReactant(b.original.msd_identifier)?.msd_order ?? 0
                ),
        },
        {
            Header: 'BB Order',
            id: 'bb_order',
            accessor: (row) => this.reactants.getReactant(row.bb_identifier)?.bb_order ?? '',
            width: 110,
            Cell: ({ value }: { value: number }) => (Number.isNaN(value) ? '' : (value as any)),
            sortType: (a, b) =>
                ReactTableSchema.DefaultCompareWithBlanks(
                    this.reactants.getReactant(a.original.bb_identifier)?.bb_order ?? 0,
                    this.reactants.getReactant(b.original.bb_identifier)?.bb_order ?? 0
                ),
        },
        {
            Header: 'Location',
            id: 'well_index',
            accessor: (row) =>
                typeof row.well_index === 'number'
                    ? getWellIndexLabel(this.experiment.design.plate.layout, row.well_index)
                    : '',
            width: 110,
            Cell: ({ value }: { value: any }) => value,
        },
    ];

    async addRandomReactions() {
        let csv;
        try {
            csv = await HTEApi.createTestCSV();
        } catch (err) {
            log.error(err);
            ToastService.show({
                type: 'danger',
                message: `Failed to generate random reactions`,
            });
            return;
        }
        const blob = new Blob([csv], { type: 'text/plain' });
        await this.upload(new File([blob], 'example-data.csv'));
    }

    async upload(file: File) {
        try {
            await this._upload(file);
        } catch (err) {
            reportErrorAsToast('Failed to upload products', err);
        }
    }

    exportReactionCSV() {
        const csv = createReactionsCSV(this.experiment.design.reactions, this, this.reactants);
        saveAs(new Blob([csv], { type: 'text/csv' }), `${formatHTEId(this.experiment.id)}-reactions-${Date.now()}.csv`);
    }

    private checkDuplicateColumns(table: ReactTableModel) {
        const cols = table.dataframe.columns.map((c) => c.toString());
        for (let i = 0; i < cols.length - 1; i++) {
            const a = cols[i];
            for (let j = i + 1; j < cols.length; j++) {
                const b = cols[j];
                if (a.startsWith(b) || b.startsWith(a)) {
                    // It's ok to show this as separate warning as ideally
                    // this should happen fairly rarely.
                    ToastService.show({
                        type: 'warning',
                        message: `Possibly duplicate columns '${a}' and '${b}'.`,
                        timeoutMs: 10000,
                    });
                }
            }
        }
    }

    private async _upload(file: File) {
        const { layout } = this.experiment.design.plate;
        const { table, batches, compounds } = await HTEApi.upload(file, layout);
        this.experiment.batches.addBatches(batches);
        this.experiment.batches.addCompounds(compounds);

        this.checkDuplicateColumns(table);

        const rows = table.toObjects();

        const addedReactants = new Set<string>();
        const newMsds: Reactant[] = [];
        const newBbs: Reactant[] = [];
        const reactions: Reaction[] = [];

        const wells: Well[] = new Array(layout);
        for (let i = 0; i < layout; i++) wells[i] = [];
        let hasNewWells = false;

        for (const row of rows) {
            const msd_identifier = row['msd identifier'];
            const bb_identifier = row['bb identifier'];
            const product_identifier = row['product identifier'];
            const bb_group = row['bb group'];
            const bb_barcode = row['bb barcode'];
            const msd_order = row['msd order'];
            const bb_order = row['bb order'];
            const msd_barcode = row['msd barcode'];
            const { insight_well_index } = row;

            if (!addedReactants.has(msd_identifier)) {
                const entity = this.experiment.batches.getEntity(msd_identifier);
                if (!entity) throw new Error(`MSD ${msd_identifier} not available.`);
                entity.identifier = msd_identifier;
                const msd = createReactantFromEntity({
                    entity,
                    identifier: msd_identifier,
                    barcode: msd_barcode,
                    type: 'msd',
                    msd_order,
                });
                addedReactants.add(msd_identifier);
                newMsds.push(msd);
            }

            if (!addedReactants.has(bb_identifier)) {
                const entity = this.experiment.batches.getEntity(bb_identifier);
                if (!entity) throw new Error(`BB batch ${bb_identifier} not available.`);
                entity.identifier = bb_identifier;
                const bb = createReactantFromEntity({
                    entity,
                    identifier: bb_identifier,
                    barcode: bb_barcode,
                    type: 'bb',
                    group: bb_group,
                    bb_order,
                });
                addedReactants.add(bb_identifier);
                newBbs.push(bb);
            }

            const productBatch = this.experiment.batches.getBatch(product_identifier);
            const productCompound = this.experiment.batches.getCompound(product_identifier);
            if (!productBatch && !productCompound)
                throw new Error(`Product compound/batch ${product_identifier} not available.`);

            reactions.push({
                msd_identifier,
                bb_identifier,
                product_molecular_weight: productBatch
                    ? productBatch.formula_weight
                    : productCompound?.molecular_weight,
                product_identifier,
                well_index: insight_well_index,
            });

            if (typeof insight_well_index === 'number') {
                wells[insight_well_index] = [msd_identifier, bb_identifier];
                hasNewWells = true;
            }
        }

        validateOrders(newMsds, 'msd_order');
        validateOrders(newBbs, 'bb_order');

        const existingReactants = this.experiment.design.reactants.filter((r) => r.type !== 'msd' && r.type !== 'bb');
        this.experiment.resetReactionsAndReactants(
            [...existingReactants, ...newMsds, ...newBbs],
            reactions,
            hasNewWells ? { ...this.experiment.design.plate, wells } : undefined
        );
        this.experiment.clearHistory();
        this.experiment.state.enumeration.next({});
        this.experiment.enumeration.state.reactions.next([]);
    }

    constructor(public experiment: import('../experiment-model').HTEExperimentModel) {
        super();

        this.reactants = experiment.reactants;

        this.subscribe(experiment.state.design.pipe(distinctUntilKeyChanged('reactions')), (design) => {
            this.map.clear();
            for (const r of design.reactions) {
                const key = `${r.msd_identifier}:${r.bb_identifier}`;
                if (this.map.has(key)) this.map.get(key)!.push(r);
                else this.map.set(key, [r]);
            }
        });
    }
}

function validateOrders(reactants: Reactant[], order: 'msd_order' | 'bb_order') {
    let defined = 0;
    for (const r of reactants) {
        if (typeof r[order] === 'number') defined++;
    }
    if (defined > 0 && defined < reactants.length) {
        throw new Error(`Either all or none ${order} must be defined.`);
    }
}

export function createReactantFromEntity({
    entity,
    identifier,
    barcode,
    type,
    group,
    msd_order,
    bb_order,
    enumeration,
}: {
    entity: Batch | CompoundDetail;
    identifier: string;
    barcode: string | undefined;
    type: ReactantType;
    group?: any;
    msd_order?: number | null;
    bb_order?: number | null;
    enumeration?: Reactant['enumeration'];
}): Reactant {
    const defaults: Reactant['defaults'] = {
        concentration: null,
        equivalence: 1.0,
        overage: 1.2,
    };

    const r: Reactant = {
        barcode: barcode ? barcode : undefined, // eslint-disable-line
        identifier,
        molecular_weight: (entity as Batch).formula_weight ?? (entity as CompoundDetail).molecular_weight,
        defaults,
        ...defaults,
        stock_amount: null,
        stock_volume: null,
        type,
        group_name: group?.toString(),
        msd_order: Number.isNaN(msd_order) ? undefined : msd_order!,
        bb_order: Number.isNaN(bb_order) ? undefined : bb_order!,
        enumeration,
    };

    if (isSolutableReactant(r)) {
        r.solvent = 'DMSO';
    }

    return r;
}

const HTEReactionCSVColumns = [
    'Reaction',
    'Plated',
    'MSD Identifier',
    'BB Identifier',
    'Product Identifier',
    'MW',
    'Group',
    'MSD Order',
    'BB Order',
    'Location',
] as const;

type HTEReactionCSVRow = Record<(typeof HTEReactionCSVColumns)[number], string | number>;

function createReactionsCSV(reactions: Reaction[], model: ReactionsModel, reactants: ReactantsModel) {
    const rows: HTEReactionCSVRow[] = [];
    const { experiment } = model;

    for (const r of reactions) {
        rows.push({
            Reaction: model.getReactionSMILES(r),
            Plated: experiment.productPlate.getReactionOccurenceCount(r),
            'MSD Identifier': r.msd_identifier,
            'BB Identifier': r.bb_identifier,
            'Product Identifier': r.product_identifier,
            MW: r.product_molecular_weight ?? '',
            Group: reactants.getReactant(r.bb_identifier)?.group_name ?? '',
            'MSD Order': reactants.getReactant(r.msd_identifier)?.msd_order ?? '',
            'BB Order': reactants.getReactant(r.bb_identifier)?.bb_order ?? '',
            Location:
                typeof r.well_index === 'number'
                    ? getWellIndexLabel(model.experiment.design.plate.layout, r.well_index)
                    : '',
        });
    }

    const csvRows = objectsToRowArrays(rows, HTEReactionCSVColumns);
    return arrayToCsv(csvRows);
}
