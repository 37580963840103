/* eslint-disable react/no-unused-prop-types */
import { faRemove, faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { CSSProperties, MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NavigateFunction } from 'react-router-dom';
import { FixedSizeList, FixedSizeListProps } from 'react-window';
import useBehavior from '../../lib/hooks/useBehavior';
import { useThrottledValue } from '../../lib/hooks/useThrottledValue';
import { AutoSizeBox } from '../common/AutoSizeBox';
import { IconButton } from '../common/IconButton';
import { ColumnInstance, DataTableModel } from './model';

export type DataTableHeaderSize = 'xxsm' | 'xsm' | 'sm' | 'md' | 'lg';
export type DataTableRowSelectionMode = 'none' | 'single' | 'multi';

const DataTableHeaderHeights: Record<DataTableHeaderSize, number> = {
    lg: 90,
    md: 68,
    sm: 45,
    xsm: 38,
    xxsm: 29,
};

interface DataTableProps<T extends {} = any> {
    height: number | 'flex';
    headerSize?: DataTableHeaderSize;
    showColumnFilters?: boolean;
    rowSelectionMode?: DataTableRowSelectionMode;
    onRowClick?: (rowIndex: number, table: DataTableModel<T>, e: React.MouseEvent) => void;
    onRowDoubleClick?: (rowIndex: number, table: DataTableModel<T>, e: React.MouseEvent) => void;
    onRowMouseEnter?: (rowIndex: number, table: DataTableModel<T>) => void;
    onRowMouseLeave?: (rowIndex: number, table: DataTableModel<T>) => void;
    getAdditionalRowClasses?: (rowIndex: number, table: DataTableModel<T>) => string;
    table: DataTableModel<T>;
    resizableColumns?: boolean;
    cellXPaddingIndex?: number;
    autoscrollToSelection?: boolean;
    className?: string;
}

export function DataTableControl<T extends {}>(props: DataTableProps<T>) {
    const {
        height,
        headerSize = 'sm',
        showColumnFilters = false,
        rowSelectionMode = 'none',
        table,
        resizableColumns = true,
        cellXPaddingIndex = 2,
        autoscrollToSelection = false,
        className,
        onRowClick,
    } = props;

    const lineHeight = table.state.rowHeight;
    const headerHeight = DataTableHeaderHeights[headerSize];
    const currentProps = useRef<DataTableProps<T>>(props);
    const listRef = useRef<FixedSizeList>(null);

    currentProps.current = props;

    function _onRowClick(e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>, index: number) {
        if (rowSelectionMode === 'single') {
            // if row selection mode is single, select the whole row when
            // clicking anywhere on it
            currentProps.current.table.setSelection({ [index]: true });
        }
        onRowClick?.(index, table, e as any);
    }

    function onRowKeyUp(e: React.KeyboardEvent<HTMLDivElement>, index: number) {
        if (e.key === 'Enter') {
            if (rowSelectionMode === 'single') {
                // if row selection mode is single, select the whole row when
                // clicking anywhere on it
                currentProps.current.table.setSelection({ [index]: true });
            }
        }
    }

    useEffect(() => {
        if (!autoscrollToSelection) return;

        const { selectedRows, rows, pinnedRowSet } = table;
        for (let i = 0; i < rows.length; i++) {
            if (selectedRows[rows[i]] && !pinnedRowSet.has(rows[i])) {
                const frame = requestAnimationFrame(() => {
                    listRef.current?.scrollToItem(i, 'smart');
                });
                return () => cancelAnimationFrame(frame);
            }
        }
    }, [table.version.value, autoscrollToSelection]);

    const RenderRow = useCallback(
        // eslint-disable-next-line react/no-unused-prop-types
        ({ index, style }: { index: number; style: CSSProperties }) => {
            const { pinned, unpinned } = table.finalPinnedRows;

            const rowIndex = index < pinned.length ? pinned[index] : unpinned[index - pinned.length];
            const { columns, stickyColumns, rowWidth } = table.renderInfo;

            const { getAdditionalRowClasses, onRowDoubleClick, onRowMouseEnter, onRowMouseLeave } =
                currentProps.current;
            const selectedRows = table.selectedRows;

            let columnLeft = 0;
            return (
                <div
                    key={rowIndex}
                    style={{
                        ...style,
                        top: (style.top as number) + headerHeight,
                        width: rowWidth,
                    }}
                    className={classNames('table-control-row', getAdditionalRowClasses?.(rowIndex, table), {
                        'table-control-row-selected': !!selectedRows[rowIndex],
                    })}
                    role='button'
                    tabIndex={0}
                    onClick={(e) => _onRowClick(e, rowIndex)}
                    onKeyUp={(e) => onRowKeyUp(e, rowIndex)}
                    onDoubleClick={onRowDoubleClick && ((e) => onRowDoubleClick!(rowIndex, table, e))}
                    onMouseEnter={onRowMouseEnter && (() => onRowMouseEnter!(rowIndex, table))}
                    onMouseLeave={onRowMouseLeave && (() => onRowMouseLeave!(rowIndex, table))}
                >
                    {stickyColumns.map((c, idx) => {
                        const width = table.getColumnWidth(c.id);
                        const rendered = table.render(c, rowIndex);
                        const ret = (
                            <div
                                key={c.id}
                                className={classNames(
                                    `table-control-row-cell px-${c.align === 'center' ? 0 : cellXPaddingIndex}`,
                                    {
                                        'table-column-resize-body': !c.noResize && idx !== stickyColumns.length - 1,
                                        'table-sticky-shadow': idx === stickyColumns.length - 1,
                                    }
                                )}
                                style={{
                                    width,
                                    height: style.height,
                                    position: 'sticky',
                                    minWidth: width,
                                    maxWidth: width,
                                    left: columnLeft,
                                    justifyContent: c.align,
                                    zIndex: 10000,
                                }}
                            >
                                {typeof rendered === 'string' ? <span title={rendered}>{rendered}</span> : rendered}
                            </div>
                        );
                        columnLeft += width;
                        return ret;
                    })}
                    {columns.map((c) => {
                        const width = table.getColumnWidth(c.id);
                        const rendered = table.render(c, rowIndex);
                        const ret = (
                            <div
                                key={c.id}
                                className={classNames(`table-control-row-cell px-${cellXPaddingIndex}`, {
                                    'table-column-resize-body': !c.noResize,
                                })}
                                style={{
                                    width,
                                    height: style.height,
                                    minWidth: width,
                                    maxWidth: width,
                                    left: columnLeft,
                                    justifyContent: c.align,
                                }}
                            >
                                {typeof rendered === 'string' ? <span title={rendered}>{rendered}</span> : rendered}
                            </div>
                        );
                        columnLeft += width;
                        return ret;
                    })}
                </div>
            );
        },
        [table, cellXPaddingIndex, headerHeight]
    );

    const header = (
        <TableHeader
            table={table}
            resizableColumns={resizableColumns}
            headerSize={headerSize}
            showColumnFilters={showColumnFilters}
        />
    );

    const currentHeader = useRef<any>(header);
    currentHeader.current = header;
    const renderHeader = useCallback(() => currentHeader.current, []);

    const tableInner = (_: number, innerHeight: number) => (
        <StickyHeaderList
            height={innerHeight}
            innerElementType={innerElementType}
            itemData={header}
            itemCount={table.rows.length}
            itemSize={lineHeight}
            overscanCount={3}
            renderer={RenderRow}
            header={renderHeader}
            width='100%'
            listRef={listRef}
        />
    );

    if (height === 'flex') {
        return (
            <AutoSizeBox className={`table-control table-control-flex-wrapper ${className ?? ''}`}>
                {tableInner}
            </AutoSizeBox>
        );
    }

    return <div className={`table-control table-control-wrapper ${className ?? ''}`}>{tableInner(0, height)}</div>;
}

const StickyHeaderContext = React.createContext<() => React.ReactNode | undefined>(() => undefined);

function StickyHeaderList({
    renderer,
    header,
    listRef,
    ...rest
}: {
    renderer: (props: any) => React.ReactNode;
    header: () => React.ReactNode;
    disableHScroll?: boolean;
    listRef: MutableRefObject<any>;
} & Omit<FixedSizeListProps, 'children'>) {
    return (
        <StickyHeaderContext.Provider value={header}>
            <FixedSizeList {...rest} ref={listRef}>
                {renderer as any}
            </FixedSizeList>
        </StickyHeaderContext.Provider>
    );
}

const innerElementType = React.forwardRef<HTMLDivElement, any>(({ children, ...rest }, ref) => {
    const header = useContext(StickyHeaderContext);
    return (
        <>
            {header()}
            <div ref={ref} {...rest} className='table-control-body'>
                {children}
            </div>
        </>
    );
});

function TableHeader({
    table,
    headerSize,
    showColumnFilters = false,
    resizableColumns,
}: {
    table: DataTableModel;
    headerSize: DataTableHeaderSize;
    showColumnFilters?: boolean;
    resizableColumns: boolean;
}) {
    const { columns, stickyColumns, rowWidth } = table.renderInfo;
    const height = DataTableHeaderHeights[headerSize];

    let stickyLeft = 0;
    return (
        <div className='table-control-header-row' style={{ width: rowWidth + (resizableColumns ? 30 : 0) }}>
            {stickyColumns.map((c, idx) => {
                const width = table.getColumnWidth(c.id);
                const Filter = c.filterControl;
                const ret = (
                    <div
                        className={classNames('table-control-header-row-cell table-sticky-header-cell px-1 py-1', {
                            'table-column-resize': !c.noResize && idx !== stickyColumns.length - 1,
                            'table-sticky-shadow': idx === stickyColumns.length - 1,
                            highlighted: table.state.customState['highlighted-columns']?.includes(c.id),
                        })}
                        style={{
                            height: height - 1,
                            position: 'sticky',
                            left: stickyLeft,
                            width,
                            minWidth: width,
                            maxWidth: width,
                            zIndex: 10000,
                        }}
                        key={c.id}
                    >
                        <ColumnHeader column={c} table={table} />
                        {resizableColumns && !c.noResize && <ColumnResizer column={c} table={table} />}
                        {showColumnFilters && Filter && <Filter columnName={c.id} table={table} />}
                    </div>
                );

                stickyLeft += width;

                return ret;
            })}
            {columns.map((c) => {
                const width = table.getColumnWidth(c.id);
                const Filter = c.filterControl;
                const ret = (
                    <div
                        className={classNames('table-control-header-row-cell px-1 py-1', {
                            'table-column-resize': !c.noResize,
                            highlighted: table.state.customState['highlighted-columns']?.includes(c.id),
                        })}
                        style={{
                            width,
                            minWidth: width,
                            maxWidth: width,
                            height,
                        }}
                        key={c.id}
                    >
                        {showColumnFilters && Filter && <Filter columnName={c.id} table={table} />}
                        <ColumnHeader column={c} table={table} />
                        {resizableColumns && !c.noResize && <ColumnResizer column={c} table={table} />}
                    </div>
                );
                stickyLeft += width;
                return ret;
            })}
        </div>
    );
}

function ColumnHeader({ column, table }: { column: ColumnInstance; table: DataTableModel }) {
    const renderedHeader =
        typeof column.header === 'function' ? column.header(table, { columnName: column.id }) : column.header;
    const sorting = table.getColumnSortBy(column);
    const multiSort = table.state.sortBy.length > 1;

    let columnIcon = faSort;
    if (sorting) columnIcon = sorting.by.desc ? faSortDown : faSortUp;

    const headerContent = (
        <div className={sorting ? 'text-primary' : ''}>
            {typeof column.header !== 'string' && renderedHeader}
            {typeof column.header === 'string' && (
                <span className='ps-1 overflow-hidden table-control-header-title'>{renderedHeader}</span>
            )}
        </div>
    );

    const multiSortLabel =
        !!sorting && multiSort ? (
            <div
                className='position-absolute text-primary'
                style={{ top: '0.25rem', right: '0.25rem', fontSize: '0.66rem' }}
            >
                {sorting.index + 1}
            </div>
        ) : undefined;

    const headerSortButton = (props: any) => (
        <button
            type='button'
            className={classNames('table-control-sort-button', { 'pe-3': !!column.compareFn })}
            onClick={() => {
                if (!column.compareFn) return;

                if (!sorting) table.sortBy(column.id, false);
                else if (!sorting.by.desc) table.sortBy(column.id, true);
                else table.clearSort(column.id);
            }}
            style={{
                cursor: !column.compareFn ? 'default' : undefined,
                textAlign: column.headerAlign,
            }}
            {...props}
        >
            {!column.sortButtonSeparate && headerContent}
            {!!column.compareFn && (
                <FontAwesomeIcon
                    className={`position-absolute ${sorting ? ' text-primary' : ''}`}
                    style={{ top: '50%', right: '0.25rem', transform: 'translateY(-50%)' }}
                    icon={columnIcon}
                />
            )}
        </button>
    );

    let headerControl: (props: any) => React.ReactNode;

    if (column.sortButtonSeparate) {
        headerControl = (props: any) => (
            <>
                <div className='d-flex'>
                    {headerContent}
                    {headerSortButton(props)}
                </div>
                {multiSortLabel}
            </>
        );
    } else if (multiSortLabel) {
        headerControl = (props: any) => (
            <>
                {headerSortButton(props)}
                {multiSortLabel}
            </>
        );
    } else {
        headerControl = headerSortButton;
    }

    if (column.noHeaderTooltip) return <>{headerControl({})}</>;

    return (
        <OverlayTrigger
            placement='auto'
            overlay={<Tooltip>{table.getColumnLabel(column.id)}</Tooltip>}
            delay={{ show: 500, hide: 0 }}
        >
            {headerControl}
        </OverlayTrigger>
    );
}

interface GlobalFilterProps {
    table: DataTableModel;
    disabled?: boolean;
    size?: 'sm' | 'lg';
    selectOnFocus?: boolean;
    blurOnEnter?: boolean;
    controlClassName?: string;
    controlStyle?: CSSProperties;
}

export function DataTableGlobalFilter({
    table,
    disabled,
    size,
    selectOnFocus,
    blurOnEnter,
    controlClassName,
    controlStyle,
}: GlobalFilterProps) {
    useBehavior(table.version);

    const [value, subject] = useThrottledValue(
        (v) => table.setGlobalFilter(v),
        table.state.globalFilter ?? '',
        [table],
        350
    );

    return (
        <div className='position-relative'>
            <Form.Control
                value={value}
                type='text'
                size={size}
                onChange={(e) => subject.next(e.target.value)}
                placeholder={`Filter ${table.preGlobalFilterRowCount} records...`}
                disabled={disabled}
                onFocus={(e) => {
                    if (!selectOnFocus) return;
                    e.target.select();
                }}
                onKeyDown={(e) => {
                    // This is to support being able to scan barcodes into the global filter
                    if (blurOnEnter && e.key === 'Enter') {
                        (e.target as HTMLInputElement).blur();
                    }
                    if (e.key === 'Escape') {
                        subject.next('');
                        (e.target as HTMLInputElement).blur();
                    }
                }}
                className={controlClassName}
                style={controlStyle}
            />
            <IconButton
                onClick={() => subject.next('')}
                title='Clear'
                icon={faRemove}
                style={{ visibility: value ? 'visible' : 'hidden' }}
                className='position-absolute text-danger h-100 end-0 top-0'
            />
        </div>
    );
}

function ColumnResizer({ column, table }: { column: ColumnInstance; table: DataTableModel }) {
    const [offset, setOffset] = useState<number | undefined>(undefined);
    const coords = useRef<[number, number]>([0, 0]);

    const mouseMove = useCallback(
        (e: MouseEvent) => {
            let delta = e.clientX - coords.current[0];
            const width = table.getColumnWidth(column.id);
            if (width + delta < 40) delta = -Math.max(0, width - 40);
            else if (delta > 300) delta = 300;
            setOffset(delta);
        },
        [column.id, table]
    );

    const mouseUp = useCallback(
        (e: MouseEvent) => {
            window.removeEventListener('mouseup', mouseUp);
            window.removeEventListener('mousemove', mouseMove);
            setOffset(undefined);
            table.updateColumnWidth(column.id, { delta: e.clientX - coords.current[0] });
        },
        [column.id, table]
    );

    return (
        <div
            onMouseDown={(e) => {
                if (e.button !== 0) return;

                coords.current = [e.clientX, e.clientY];
                window.addEventListener('mousemove', mouseMove);
                window.addEventListener('mouseup', mouseUp);
                setOffset(0);
            }}
            className={classNames('table-control-resizer', {
                'table-control-resizer-active': offset !== undefined,
            })}
            style={{
                right: -3 - (offset ?? 0),
            }}
        />
    );
}

export function tableRowNavigation(e: React.MouseEvent, url: string, navigate: NavigateFunction) {
    if (!url.startsWith('/')) throw new Error('Expected relative path starting with /');
    if (e.ctrlKey || e.metaKey) {
        window.open(`${window.location.origin}${url}`, '_blank');
    } else {
        navigate(url);
    }
}
