import * as d3colors from 'd3-scale-chromatic';
import { WellLayout } from '../../HTE/experiment-data';
import { PlateVisualColors, PlateWellColoring } from '../../HTE/plate/PlateVisual';
import { rowMajorIndexToColumnMajorIndex } from '../../HTE/plate/utils';
import { HTEDReaction, HTEDReactionIdT } from '../data-model';

export type PlateColoringOption =
    | { kind: 'location' }
    | { kind: 'add-instruction'; reactant_kind: string; reactant_name: string | undefined };

export interface PlateColoringOptions {
    definitions: Record<string, PlateColoringOption>;
    options: [key: string, label: string][];
}

export const DefaultPlateColoringOptions: PlateColoringOptions = {
    definitions: { location: { kind: 'location' } },
    options: [['location', 'Location']],
};

export function getPlateColoringOptions(reactions: HTEDReaction[]): PlateColoringOptions {
    const definitions: Record<string, PlateColoringOption> = {
        location: { kind: 'location' },
    };

    for (const reaction of reactions) {
        for (const instr of reaction.template.instructions) {
            if (instr.kind !== 'add') continue;

            const key = `add-${instr.reactant_kind}-${instr.reactant_name}`;
            if (definitions[key]) continue;
            definitions[key] = {
                kind: 'add-instruction',
                reactant_kind: instr.reactant_kind,
                reactant_name: instr.reactant_name,
            };
        }
    }

    const options: [key: string, label: string][] = [];
    for (const key of Object.keys(definitions)) {
        const def = definitions[key];
        if (def.kind === 'location') {
            options.push([key, 'Location']);
        } else if (!def.reactant_name) {
            options.push([key, `${def.reactant_kind.toUpperCase()}`]);
        } else {
            options.push([key, `${def.reactant_kind.toUpperCase()}:${def.reactant_name ?? 'No name'}`]);
        }
    }

    return { definitions, options };
}

export function getPlateColoring({
    reactions,
    layout,
    reactionToWell,
    option,
}: {
    reactions: HTEDReaction[];
    layout: WellLayout;
    reactionToWell: Map<HTEDReactionIdT, number>;
    option: PlateColoringOption;
}): PlateVisualColors {
    const colors: PlateVisualColors = new Array(layout).fill(PlateWellColoring.NoColor);
    const columnMajorIndex = new Map(
        reactions.map((r) => [r.id, rowMajorIndexToColumnMajorIndex(layout, reactionToWell.get(r.id) ?? 0)])
    );

    if (option.kind === 'add-instruction') {
        const identifierMap = new Map<HTEDReactionIdT, string | undefined>();
        const identifierIndex = new Map<string, number>();

        const sorted = [...reactions].sort((a, b) => columnMajorIndex.get(a.id)! - columnMajorIndex.get(b.id)!);
        const reactant_name = String(option.reactant_name);
        for (const r of sorted) {
            for (const instr of r.template.instructions) {
                if (instr.kind !== 'add') continue;
                if (instr.reactant_kind === option.reactant_kind && String(instr.reactant_name) === reactant_name) {
                    identifierMap.set(r.id, instr.identifier);
                    if (instr.identifier && !identifierIndex.has(instr.identifier)) {
                        identifierIndex.set(instr.identifier, identifierIndex.size);
                    }
                    break;
                }
            }
        }

        for (const r of reactions) {
            const index = reactionToWell.get(r.id)!;
            const identifier = identifierMap.get(r.id);
            if (identifier) {
                colors[index] = d3colors.interpolateWarm(
                    identifierIndex.get(identifier)! / (identifierIndex.size - 1 || 1)
                );
            } else {
                colors[index] = PlateWellColoring.NonEmptyColor;
            }
        }
    } else {
        for (const r of reactions) {
            colors[reactionToWell.get(r.id)!] = d3colors.interpolateWarm(columnMajorIndex.get(r.id)! / (layout - 1));
        }
    }

    return colors;
}
