import log from 'loglevel';
import { Batch, CompoundDetail } from '../../pages/Compounds/compound-api';

export function addCompoundEntityToMap(entity: Batch | CompoundDetail, map: Map<string, Batch | CompoundDetail>) {
    if (entity.identifier) {
        map.set(entity.identifier, entity);
        map.set(normalizeEntityIdentifier(entity.identifier), entity);
    } else {
        log.warn(`Entity ${entity.universal_identifier} is missing identifier field.`);
    }

    if (entity.bb_identifier) {
        map.set(entity.bb_identifier, entity);
        map.set(normalizeEntityIdentifier(entity.bb_identifier), entity);
    }
    if (entity.reagent_identifier) {
        map.set(entity.reagent_identifier, entity);
        map.set(normalizeEntityIdentifier(entity.reagent_identifier), entity);
    }
    if (entity.msd_identifier) {
        map.set(entity.msd_identifier, entity);
        map.set(normalizeEntityIdentifier(entity.msd_identifier), entity);
    }
    if (entity.entos_identifier) {
        map.set(entity.entos_identifier, entity);
        map.set(normalizeEntityIdentifier(entity.entos_identifier), entity);
    }
    if (entity.universal_identifier) {
        map.set(entity.universal_identifier, entity);
        map.set(normalizeEntityIdentifier(entity.universal_identifier), entity);
    }
}

const CompoundIdentRe = /([a-zA-Z]+)([0-9]+)/;
const BatchIdentRe = /([a-zA-Z]+)([0-9]+)-([0-9]+)/;

export function normalizeEntityIdentifier(ident: string): string {
    // Removes leading zeroes from identifiers, e.g. CMPD00001-022 -> CMPD1-22
    if (ident.indexOf('-') > 0) {
        const fields = BatchIdentRe.exec(ident);
        if (!fields) return ident;
        return `${fields[1].toUpperCase()}${+fields[2]}-${+fields[3]}`;
    }
    const fields = CompoundIdentRe.exec(ident);
    if (!fields) return ident;
    return `${fields[1].toUpperCase()}${+fields[2]}`;
}
