interface DownloadFileOptions {
    data?: Blob;
    filename?: string;
    url?: string;
}

export function downloadFile(options: DownloadFileOptions) {
    const { data, filename, url } = options;
    const a = document.createElement('a');
    a.target = '_blank';
    if (data) {
        a.href = window.URL.createObjectURL(data);
    } else if (url) {
        a.href = url;
    } else {
        throw new Error('downloadFile: Either a data blob or URL must be specified.');
    }
    a.download = filename ?? '';
    // NOTE: for debugging
    // a.style.position = 'absolute';
    // a.style.left = '10px';
    // a.style.top = '10px';
    // a.innerHTML = 'Generated Link'
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
