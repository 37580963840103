import log from 'loglevel';
import { Column, Filters, Row, SortingRule } from 'react-table';
import { BehaviorSubject } from 'rxjs';
import { saveAs } from 'file-saver';
import { columnNameToHeader } from '../../api/data';
import { ReactTableNumberRangeColumnFilter } from './Filters';
import { ToastService } from '../../lib/services/toast';
import { arrayToCsv } from '../../lib/util/arrayToCsv';
import { binarySearch } from '../../lib/util/binary-search';
import { formatDatetime } from '../../lib/util/dates';
import { objectIsEmpty, normalizeFilename } from '../../lib/util/misc';
import ReactTableSchema from './schema';

export const DefaultColumnWidth = 170;
export const SmilesColumnWidth = 350;

// NOTE: To be deprecated by ColumnTableData in the new representation
//       to seamlessly support RowTableData too.
export interface ReactTableData<T = any, I = number, C = keyof T> {
    columns: C[];
    index: I[];
    data: any[][];
}

export type ReactTableRow = Row<{ index: number }>;

export interface ReactTableState<T extends object = any> {
    sortBy: SortingRule<ReactTableData>[];
    filters: Filters<ReactTableData>;
    hiddenColumns: (keyof T)[];
    columnFormatting: Partial<Record<keyof T, any>>;
    defaultFormatting: { [_ in ReactTableSchema.Element['kind']]?: any };
}

export interface ReactTableColumnValueMap<T extends object = any> {
    <K extends keyof T>(name: K): T[K][];
}

export class ReactTableModel<T extends object = any, I = number> {
    // to be incremented for example when column formatting changes
    readonly version = new BehaviorSubject(0);
    readonly state = new BehaviorSubject<ReactTableState<T>>({
        sortBy: [],
        filters: [],
        hiddenColumns: [],
        columnFormatting: {},
        defaultFormatting: {
            float: ReactTableSchema.DefaultFloatFormatOptions,
        },
    });
    readonly selectedRowIndices = new BehaviorSubject<Record<number | string, boolean>>({});
    // sorted array of visible rows
    readonly visibleRows: BehaviorSubject<number[]>;

    modified(refreshColumns = true) {
        if (refreshColumns) this.allColumns = [...this.allColumns];
        this.version.next(this.version.value + 1);
    }

    private data: ReactTableData<T, I>;
    private columnIndex: Map<any, number>;
    private columnMap: Map<keyof T, any[]>;
    private columnValueIndices: Map<any, Map<any, number>> = new Map();

    readonly schema: ReactTableSchema.For<T>;
    allColumns: ReadonlyArray<Column<{ index: number }>> = [];
    readonly rows: { index: number }[];

    get dataframe(): ReactTableData<T, I> {
        return this.data;
    }

    getColumnIndex(name: keyof T) {
        return this.columnIndex.get(name) ?? -1;
    }

    getColumn(name: keyof T) {
        return this.allColumns[this.columnIndex.get(name)!];
    }

    getColumnNames() {
        return [...this.data.columns];
    }

    getColumnValues<N extends keyof T>(name: N): ReadonlyArray<T[N]> {
        return this.columnMap.get(name) ?? [];
    }

    getValue(columnName: keyof T, rowIndex: number) {
        return this.columnMap.get(columnName)?.[rowIndex];
    }

    getValueIndexMap(columnName: keyof T) {
        let index = this.columnValueIndices.get(columnName);
        if (!index) {
            index = new Map(this.columnMap.get(columnName)!.map((v, i) => [v, i]));
            this.columnValueIndices.set(columnName, index);
        }
        return index;
    }

    findValueIndex<V extends keyof T>(columnName: V, value: T[V]): number {
        return this.getValueIndexMap(columnName).get(value) ?? -1;
    }

    getSelectedColumnValues(columnName: keyof T, visibleOnly = false) {
        const sel = this.selectedRowIndices.value;
        const col = this.columnMap.get(columnName)!;

        const ret: any[] = [];
        if (visibleOnly) {
            for (const i of this.visibleRows.value) {
                if (sel[i]) ret.push(col[i]);
            }
        } else {
            for (let i = 0; i < this.rows.length; i++) {
                if (sel[i]) ret.push(col[i]);
            }
        }
        return ret;
    }

    getVisibleColumnValues(columnName: keyof T) {
        const col = this.columnMap.get(columnName)!;

        const ret: any[] = [];
        for (const i of this.visibleRows.value) {
            ret.push(col[i]);
        }
        return ret;
    }

    setColumnFormatting(columnName: keyof T, formatting: any) {
        const { columnFormatting } = this.state.value;
        columnFormatting[columnName] = formatting;
        this.state.next({ ...this.state.value, columnFormatting });
    }

    setColumnSchema(columnName: keyof T, schemaElement: ReactTableSchema.Element) {
        this.updateColumnSpec(columnName, schemaElement);
        if (schemaElement.defaultFormatting) {
            this.state.next({ ...this.state.value, columnFormatting: schemaElement.defaultFormatting });
        }
    }

    setHiddenColumns(hiddenColumns: (keyof T)[]) {
        // TODO: diff?
        this.state.next({
            ...this.state.value,
            hiddenColumns,
        });
    }

    setValue<V extends keyof T>(columnName: V, rowIndex: number, newValue: T[V]) {
        const col = this.columnMap.get(columnName)!;
        const old = col[rowIndex];
        const index = this.columnValueIndices.get(columnName);
        index?.delete(old);
        index?.set(newValue, rowIndex);
        this.columnMap.get(columnName)![rowIndex] = newValue;
    }

    setValues<V extends keyof T>(columnName: V, values: [row: number, value: T[V]][]) {
        const col = this.columnMap.get(columnName)!;
        const index = this.columnValueIndices.get(columnName);
        for (const [r, v] of values) {
            const old = col[r];
            index?.delete(old);
            col[r] = v;
            index?.set(v, r);
        }
    }

    setKeyedValues<V extends keyof T, K extends keyof T>(
        columnName: V,
        keyColumn: K,
        values: [key: T[K], value: T[V]][]
    ) {
        const col = this.columnMap.get(columnName)!;
        const index = this.columnValueIndices.get(columnName);
        const rowIndex = this.getValueIndexMap(keyColumn);
        for (const [key, v] of values) {
            const r = rowIndex.get(key);
            if (r !== undefined) {
                const old = col[r];
                index?.delete(old);
                col[r] = v;
                index?.set(v, r);
            }
        }
    }

    setSelection(selection: Record<number | string, boolean> | ArrayLike<number>): boolean {
        const oldSelection = this.selectedRowIndices.value;
        const oldKeys = Object.keys(oldSelection);
        let newSelection: ReactTableModel['selectedRowIndices']['value'];
        if (Array.isArray(selection)) {
            newSelection = Object.create(null);
            for (const idx of selection) newSelection[idx] = true;
        } else {
            newSelection = selection as any;
        }

        const newKeys = Object.keys(newSelection);

        if (!newKeys.length) {
            if (oldKeys.length) {
                this.selectedRowIndices.next(newSelection);
                return true;
            }
            return false;
        }

        if (oldKeys.length === newKeys.length) {
            oldKeys.sort();
            newKeys.sort();
            let allEqual = true;
            for (let i = 0; i < oldKeys.length; i++) {
                const k = oldKeys[i];
                if (k !== newKeys[i] || !!oldSelection[k as any] !== !!newSelection[k as any]) {
                    allEqual = false;
                    break;
                }
            }
            if (allEqual) return false;
        }
        this.selectedRowIndices.next(newSelection);
        return true;
    }

    setVisibleRows(rows: number[]) {
        rows.sort((a, b) => a - b);
        const old = this.visibleRows.value;
        if (old.length === rows.length) {
            let allEqual = true;
            for (let i = 0; i < rows.length; i++) {
                if (rows[i] !== old[i]) {
                    allEqual = false;
                    break;
                }
            }
            if (allEqual) return;
        }
        this.visibleRows.next(rows);
    }

    setFloatFormatting(format: ReactTableSchema.FloatFormatOptions) {
        const { columnFormatting, defaultFormatting } = this.state.value;
        let formattingChanged = false;
        const newFormatting = { ...columnFormatting };
        for (const c of this.data.columns) {
            const schema = this.schema[c];
            if (schema?.kind === 'float') {
                const f = columnFormatting[c] as ReactTableSchema.FloatFormatOptions;

                if (!ReactTableSchema.floatFormatOptionsEqual(f, format)) {
                    formattingChanged = true;
                    newFormatting[c] = format;
                }
            }
        }
        if (formattingChanged || !ReactTableSchema.floatFormatOptionsEqual(defaultFormatting.float, format)) {
            this.state.next({
                ...this.state.value,
                columnFormatting: newFormatting,
                defaultFormatting: {
                    ...defaultFormatting,
                    float: format,
                },
            });
        }
    }

    setColumnWidth(name: keyof T, width: number) {
        const col = this.getColumn(name);
        if (col) {
            col.width = width;
            col.maxWidth = width;
            col.minWidth = width;
        }
    }

    reorderColumns(order: (keyof T)[]) {
        const indexed = new Map<string, number>(order.map((c, i) => [c as string, i]));
        for (const c of this.allColumns) {
            if (!indexed.has(c.id!)) {
                indexed.set(c.id!, indexed.size);
            }
        }
        const columns = Array.from(this.allColumns);
        columns.sort((a, b) => indexed.get(a.id!)! - indexed.get(b.id!)!);
        this.allColumns = columns;
    }

    isRowVisible(rowIndex: number) {
        const rows = this.visibleRows.value;
        return binarySearch(rows, rowIndex, 0, rows.length) >= 0;
    }

    addColumn<V extends keyof T>(name: V, values: T[V][], schema?: ReactTableSchema.Element) {
        if (this.getColumn(name)) {
            log.warn(`Column '${name as any}' already added, skipping.`);
            return;
        }

        const { defaultFormatting } = this.state.value;

        this.data.columns.push(name);
        if (schema) this.schema[name] = schema;
        this.data.data.push(values);
        this.columnIndex.set(name, this.data.data.length - 1);
        this.columnMap.set(name, values);
        const ret = this.addColumnSpec(name);
        if (schema?.defaultFormatting) {
            const { columnFormatting } = this.state.value;
            const newFormatting = {
                ...columnFormatting,
                [name]: defaultFormatting[schema.kind] ?? schema.defaultFormatting,
            };
            this.state.next({ ...this.state.value, columnFormatting: newFormatting });
        }

        return ret;
    }

    addColumns<V extends keyof T>(
        cols: [name: V, values: T[V][], schema?: ReactTableSchema.Element][],
        options?: { updateData?: boolean }
    ) {
        const { columnFormatting, defaultFormatting } = this.state.value;
        const newFormatting = { ...columnFormatting };
        let formattingChanged = false;

        for (const [name, values, schema] of cols) {
            if (this.getColumn(name)) {
                if (options?.updateData) {
                    const idx = this.getColumnIndex(name);
                    const arr = this.data.data[idx];
                    for (let i = 0; i < arr.length; i++) {
                        arr[i] = values[i];
                    }
                    this.columnValueIndices.delete(name);
                    this.columnMap.set(name, values);
                } else {
                    log.warn(`Column '${name as any}' already added, skipping.`);
                }
            } else {
                this.data.columns.push(name);
                if (schema) this.schema[name] = schema;
                this.data.data.push(values);
                this.columnIndex.set(name, this.data.data.length - 1);
                this.columnMap.set(name, values);
                this.addColumnSpec(name);
                if (schema?.defaultFormatting) {
                    newFormatting[name] = defaultFormatting[schema.kind] ?? schema?.defaultFormatting;
                    formattingChanged = true;
                }
            }
        }

        if (formattingChanged) {
            this.state.next({ ...this.state.value, columnFormatting: newFormatting });
        }
    }

    removeColumn<V extends keyof T>(name: V) {
        if (!this.getColumn(name)) {
            log.warn(`Column '${name as any}' not found, skipping.`);
            return;
        }

        const idx = this.getColumnIndex(name);
        this.data.columns.splice(idx, 1);
        this.data.data.splice(idx, 1);
        this.columnIndex = new Map(this.data.columns.map((c, i) => [c, i]));
        this.columnMap.delete(name);
        delete this.schema[name];
        this.allColumns = this.allColumns.filter((_, i) => i !== idx);
    }

    removeColumns<V extends keyof T>(names: V[]) {
        for (const name of names) {
            if (!this.getColumn(name)) {
                log.warn(`Column '${name as any}' not found, skipping.`);
                continue; // eslint-disable-line
            }
            this.removeColumn(name);
        }
    }

    filterIndices(filter: (index: number, columns: ReactTableColumnValueMap<T>) => boolean): number[] {
        const ret: number[] = [];
        const { rows, columnMap } = this;
        const getColumn = columnMap.get.bind(columnMap);
        for (let rowIdx = 0; rowIdx < rows.length; rowIdx++) {
            if (filter(rowIdx, getColumn as any)) {
                ret.push(rowIdx);
            }
        }
        return ret;
    }

    toObjects(options?: {
        indices?: ArrayLike<number>;
        filter?: (index: number, columns: ReactTableColumnValueMap<T>) => boolean;
        columns?: (keyof T)[];
    }): T[] {
        const ret: T[] = [];
        const { index, columns: allColumns, data } = this.data;
        const getColumn = this.columnMap.get.bind(this.columnMap);

        let columns: [name: string, index: number][];
        if (!options?.columns) {
            columns = allColumns.map((c, i) => [c, i] as [string, number]);
        } else {
            columns = [];
            for (const col of options.columns) {
                const idx = allColumns.indexOf(col);
                if (idx >= 0) columns.push([col as any, idx]);
            }
        }

        if (options?.indices) {
            for (let i = 0, _i = options.indices.length; i < _i; i++) {
                const rowIdx = options.indices[i];
                if (options?.filter && !options.filter(rowIdx, getColumn as any)) {
                    continue; // eslint-disable-line
                }
                const obj = {} as any;
                for (let colIdx = 0; colIdx < columns.length; colIdx++) {
                    const col = columns[colIdx];
                    obj[col[0]] = data[col[1]][rowIdx];
                }
                ret.push(obj);
            }
        } else {
            for (let rowIdx = 0; rowIdx < index.length; rowIdx++) {
                if (options?.filter && !options.filter(rowIdx, getColumn as any)) {
                    continue; // eslint-disable-line
                }
                const obj = {} as any;
                for (let colIdx = 0; colIdx < columns.length; colIdx++) {
                    const col = columns[colIdx];
                    obj[col[0]] = data[col[1]][rowIdx];
                }
                ret.push(obj);
            }
        }
        return ret;
    }

    toCsvString(indices?: number[], options?: { ignoreSelection?: boolean; useRawValues?: boolean }) {
        const hidden = this.state.value.hiddenColumns;
        const cols = this.allColumns.filter((c) => hidden.indexOf(c.id as any) < 0);
        const rows: any[][] = [];
        rows.push(cols.map((c) => c.Header?.toString() ?? c.id ?? ''));

        const values = cols.map((c) => this.getColumnValues(c.id as any));
        const selection = this.selectedRowIndices.value;
        const hasSelection = !objectIsEmpty(selection);

        const formatting = this.state.value.columnFormatting;
        const formatters = cols.map((col) => {
            const name = col.id as keyof T;
            const schemaElement = this.schema?.[name];

            if (!schemaElement || options?.useRawValues) {
                return (v: any) => (Number.isNaN(v) ? '' : v);
            }

            const { format, defaultFormatting } = schemaElement!;
            return defaultFormatting ? (v: any) => format(v, formatting[name]) : (v: any) => format(v);
        });

        for (const rowIndex of indices ?? this.visibleRows.value) {
            if (!options?.ignoreSelection && hasSelection && !selection[rowIndex]) {
                continue; // eslint-disable-line
            }

            const row: any[] = [];
            for (let i = 0; i < cols.length; i++) {
                row.push(formatters[i](values[i][rowIndex]));
            }
            rows.push(row);
        }
        return arrayToCsv(rows);
    }

    private updateColumnSpec(name: keyof T, schemaElement: ReactTableSchema.Element) {
        const index = this.columnIndex.get(name)!;
        const arr = this.data.data[index];

        const col: Column<{ index: number }> = {
            Header: columnNameToHeader(name as string),
            id: name as any,
            accessor: (row: { index: number }) => arr[row.index],
        };

        const copyColumns = [...this.allColumns];
        copyColumns[index] = col;

        this.allColumns = copyColumns;

        this.schema[name] = schemaElement;

        const { compare, format, defaultFormatting } = schemaElement!;
        col.sortType = (a, b) => compare(arr[a.original.index], arr[b.original.index]);
        col.Cell = defaultFormatting
            ? ({ value }) => format(value, this.state.value.columnFormatting[name]) as any
            : ({ value }) => format(value) as any;

        if (ReactTableSchema.isNumericElement(schemaElement)) {
            col.filter = 'between';
            col.Filter = ReactTableNumberRangeColumnFilter as any; // TODO: make customizable
        }
        // TODO: add the filtering support to Schemas.
        if (schemaElement.kind === 'obj') {
            col.disableGlobalFilter = true;
        }
    }

    private addColumnSpec(name: keyof T) {
        const index = this.columnIndex.get(name)!;
        const arr = this.data.data[index];

        const col: Column<{ index: number }> = {
            Header: columnNameToHeader(name as string),
            id: name as any,
            accessor: (row: { index: number }) => arr[row.index],
        };

        const schemaElement = this.schema?.[name];
        if (schemaElement) {
            const { compare, format, defaultFormatting } = schemaElement!;
            col.sortType = (a, b) => compare(arr[a.original.index], arr[b.original.index]);
            col.Cell = defaultFormatting
                ? ({ value }) => format(value, this.state.value.columnFormatting[name]) as any
                : ({ value }) => format(value) as any;

            if (ReactTableSchema.isNumericElement(schemaElement)) {
                col.filter = 'between';
                col.Filter = ReactTableNumberRangeColumnFilter as any; // TODO: make customizable
            }
            // TODO: add the filtering support to Schemas.
            if (schemaElement.kind === 'obj') {
                col.disableGlobalFilter = true;
            }
        } else {
            col.Cell = getDefaultFormatter(name as string, arr) as any;
        }

        this.allColumns = [...this.allColumns, col];

        return col;
    }

    constructor(
        initialData: ReactTableData<T, I>,
        initialSchema?: ReactTableSchema.For<T>,
        options?: { doNotCreateColumns?: boolean }
    ) {
        this.data = initialData; // TODO: should this create a copy?
        this.schema = { ...initialSchema };

        const visibleRows = new Array(initialData.index.length);
        for (let i = 0; i < initialData.index.length; i++) visibleRows[i] = i;
        this.visibleRows = new BehaviorSubject(visibleRows);

        const columnFormatting: ReactTableState<T>['columnFormatting'] = {};
        if (options?.doNotCreateColumns) {
            this.columnIndex = new Map();
            this.columnMap = new Map();
        } else {
            this.columnIndex = new Map(this.data.columns.map((c, i) => [c, i]));
            this.columnMap = new Map(this.data.columns.map((c, i) => [c, this.data.data[i]]));

            for (const c of this.data.columns) {
                this.addColumnSpec(c);
                const schemaElement = this.schema?.[c];
                if (schemaElement?.defaultFormatting) {
                    columnFormatting[c] = schemaElement.defaultFormatting;
                }
            }
        }

        this.state.next({ ...this.state.value, columnFormatting });

        this.rows = new Array<{ index: number }>(this.data.index.length);
        for (let i = 0; i < this.rows.length; i++) {
            this.rows[i] = { index: i };
        }
    }
}

function getDefaultFormatter(name: string, data: any[]) {
    if (data[0] instanceof Date) {
        return ({ value }: any) => formatDatetime(value, 'date');
    }
    return ({ value }: any) => (value === null || value === undefined ? '' : String(value));
}

export function saveCSV(
    filename: string,
    table: ReactTableModel,
    flatRows: Row<{ index: number }>[],
    options?: { ignoreSelection?: boolean; useRawValues?: boolean }
) {
    try {
        const indices = flatRows.map((r) => r.original.index);
        const str = table.toCsvString(indices, options);
        const blob = new Blob([str], { type: 'text/csv' });
        saveAs(blob, `${normalizeFilename(filename)}-${Date.now()}.csv`);
    } catch (err) {
        log.error(err);
        ToastService.show({
            type: 'danger',
            message: 'Error generating CSV file',
        });
    }
}

export function determineSchemaForDataframe(dataframe: ReactTableData) {
    const schema: ReactTableSchema.For<Record<string, any>> = {};
    for (let i = 0; i < dataframe.columns.length; i++) {
        const col = dataframe.columns[i];
        schema[String(col)] = ReactTableSchema.determineSchema(dataframe.data[i]);
    }
    return schema;
}
