import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Alert, Badge, Form, Spinner, Tab, Tabs } from 'react-bootstrap';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import Split from 'react-split-it';
import { BehaviorSubject } from 'rxjs';
import {
    Column,
    ColumnsFor,
    DataTableControl,
    DataTableGlobalFilter,
    DataTableModel,
    DataTableStore,
} from '../../components/DataTable';
import { formatMolecularFormula } from '../../components/DataTable/common';
import { PageTemplate } from '../../components/Layout/Layout';
import Pane from '../../components/Pane/Pane';
import { AsyncMoleculeDrawing } from '../../components/common/AsyncMoleculeDrawing';
import { Card } from '../../components/common/Card/Card';
import { OverflowSmilesDisplay } from '../../components/common/CopySmilesButton';
import { ErrorMessage } from '../../components/common/Error';
import Loading from '../../components/common/Loading';
import { PropertyNameValue } from '../../components/common/PropertyNameValue';
import { InfoTooltip } from '../../components/common/Tooltips';
import { PotensNMToggle } from '../../components/common/UnitToggle';
import { AssayShorthandColumnName, AssayValueTypeColumn, PotensAsNmProp, isPotensColumn } from '../../lib/assays/table';
import { AsyncState, useAsyncAction } from '../../lib/hooks/useAsyncAction';
import useBehavior from '../../lib/hooks/useBehavior';
import useBehaviorSubject from '../../lib/hooks/useBehaviorSubject';
import useMountedModel from '../../lib/hooks/useMountedModel';
import { formatDateAsYYYY_MM_DD, parseFileSystemDate } from '../../lib/util/dates';
import { reportErrorAsToast } from '../../lib/util/errors';
import { arrayMapAdd } from '../../lib/util/misc';
import { prefixedUnitValue } from '../../lib/util/units';
import { ECMApi, ECMSearchResult, isLabwareAtEntosSite } from '../ECM/ecm-api';
import { SearchByIdentifier } from './Common';
import { PKPage } from './PKTab';
import {
    Batch,
    BatchAssayTable,
    BatchesForCompoundResult,
    CompoundAPI,
    CompoundDetail,
    CompoundSummary,
    PKDataWrapper,
} from './compound-api';
import { PKModel } from './pk-model';

function CompoundInformationPanel({ compound }: { compound: CompoundDetail }) {
    return (
        <div>
            <CompoundDetailsSection compound={compound} />
            <IdentifiersSection compound={compound} />
            <PropertiesSection compound={compound} />
            <StereochemistrySection compound={compound} />
        </div>
    );
}

export function CompoundDetailsSection({ compound }: { compound: Partial<CompoundDetail | CompoundSummary> }) {
    const smiles = (compound as CompoundSummary).batch_smiles ?? (compound as CompoundDetail).structure?.smiles;
    return (
        <div className='mb-4 mt-2 font-body-small'>
            <PropertyNameValue field='ENT Number' value={`${compound.identifier}`} />
            {compound.molecular_formula && (
                <PropertyNameValue
                    field='Molecular Formula'
                    value={formatMolecularFormula(compound.molecular_formula)}
                />
            )}
            {smiles && <PropertyNameValue field='SMILES' value={<OverflowSmilesDisplay smiles={smiles} />} />}
            <PropertyNameValue field='Created by' value={compound.created_by} />
            {compound.created_on && (
                <PropertyNameValue
                    field='Created on'
                    value={parseFileSystemDate(compound.created_on).toLocaleString()}
                />
            )}
            {compound.project && <PropertyNameValue field='Project' value={compound.project} />}
            {compound.common_name && <PropertyNameValue field='Common Name' value={`${compound.common_name}`} />}
            {compound.aliases && compound.aliases.length > 0 && (
                <PropertyNameValue field='Aliases' value={compound.aliases.join(', ')} />
            )}
        </div>
    );
}

function IdentifiersSection({ compound }: { compound: Partial<CompoundDetail> }) {
    return (
        <div className='mb-4'>
            <h6>Identifiers</h6>
            <div className='font-body-small'>
                {compound.entos_identifier && <PropertyNameValue field='ENT' value={`${compound.entos_identifier}`} />}
                {compound.universal_identifier && (
                    <PropertyNameValue field='Universal' value={`${compound.universal_identifier}`} />
                )}
            </div>
        </div>
    );
}

function PropertiesSection({ compound }: { compound: Partial<CompoundDetail> }) {
    return (
        <div className='mb-4'>
            <h6>Properties</h6>
            <div className='font-body-small'>
                {compound.molecular_weight && (
                    <PropertyNameValue field='Molecular weight' value={`${compound.molecular_weight.toFixed(2)}`} />
                )}
            </div>
        </div>
    );
}

export function StereochemistrySection({ compound }: { compound: Partial<CompoundDetail | CompoundSummary> }) {
    let enantiomericRatioStr = '';
    if (Array.isArray(compound.stereochemistry_enantiomeric_ratio)) {
        enantiomericRatioStr = compound.stereochemistry_enantiomeric_ratio.join(':');
    } else {
        enantiomericRatioStr = compound.stereochemistry_enantiomeric_ratio ?? '';
    }
    return (
        <div className='mb-4'>
            <h6>Stereochemistry</h6>
            <div className='font-body-small'>
                <PropertyNameValue field='Label' value={compound.stereochemistry_label} />
                {compound.stereochemistry_enantiomeric_ratio && (
                    <PropertyNameValue field='Enantiomeric Ratio' value={enantiomericRatioStr} />
                )}
                {compound.stereochemistry_comment && (
                    <PropertyNameValue field='Comment' value={compound.stereochemistry_comment} />
                )}
            </div>
        </div>
    );
}

function getTitle(compoundResult: AsyncState<CompoundDetail>) {
    if (compoundResult.isLoading) return 'Loading...';
    if (compoundResult.error) return 'Error loading compound';
    if (compoundResult.result) {
        return compoundResult.result.identifier ?? compoundResult.result.structure.smiles;
    }
    return '';
}

const CompoundAssaySchema: ColumnsFor<BatchAssayTable> = {
    [AssayShorthandColumnName]: Column.create({
        kind: 'str',
        noHeaderTooltip: true,
        label: 'Assay',
        header: 'Assay',
        width: 360,
        format: (value, _, table) =>
            value && table.state.customState[PotensAsNmProp] && isPotensColumn(value)
                ? value.replace(', p', ', ')
                : value,
        filterFn:
            (table) =>
            (
                v: string,
                { showLinkedFields, showHiddenAssays }: { showLinkedFields: boolean; showHiddenAssays: boolean }
            ) => {
                if (showLinkedFields && showHiddenAssays) return true;
                if (showLinkedFields) return !v.includes('Hidden');
                if (showHiddenAssays) return !v.includes(':');
                return !v.includes('Hidden') && !v.includes(':');
            },
    }),
};

function createTable(store: DataTableStore<BatchAssayTable>, allData: DataTableStore<any>) {
    const schemas: Record<string, Column> = {};

    for (const col of store.columnNames) {
        if (col !== AssayShorthandColumnName) {
            schemas[col] = {
                ...AssayValueTypeColumn({ columnName: col as string }),
                label: `${col}`,
                width: 160,
                noHeaderTooltip: true,
                header: (_, { columnName }) => {
                    const split = columnName.indexOf(' ');
                    if (split < 0) return <div>{columnName}</div>;
                    const batch = columnName.substring(0, split);
                    const kind = columnName.substring(split + 1);
                    return (
                        <>
                            <div>{batch}</div>
                            <div className='font-body-xsmall'>{kind}</div>
                        </>
                    );
                },
            };
        }
    }

    const table = new DataTableModel(store, {
        columns: {
            ...CompoundAssaySchema,
            ...schemas,
        },
        customState: {
            'show-assay-links': true,
        },
        rowHeight: 50,
    });

    table.setColumnStickiness(AssayShorthandColumnName, true);

    table.context['assay-data-store'] = allData; // eslint-disable-line no-param-reassign
    const allValueIndices = new Map<string, number[]>();
    for (const col of allData.columnNames) {
        const values = allData.getColumnValues(col);
        for (let i = 0; i < values.length; i++) {
            const value = values[i];
            if (allValueIndices.has(value)) {
                allValueIndices.get(value)!.push(i);
            } else {
                allValueIndices.set(value, [i]);
            }
        }
    }
    table.context['assay-data-store-value-indices'] = allValueIndices; // eslint-disable-line no-param-reassign

    // don't show LE or hidden assays by default
    table.setFilter(AssayShorthandColumnName, { showLinkedFields: false, showHiddenAssays: false });

    return table;
}

async function loadAssaysTable(compoundId: number) {
    const { imputed, all } = await CompoundAPI.getAssays(compoundId);
    return createTable(imputed, all);
}

export default function CompoundInformation() {
    const { identifier } = useParams();
    const location = useLocation();
    const [compoundResult, loadCompound] = useAsyncAction<CompoundDetail>();

    useEffect(() => {
        if (!identifier) return;
        loadCompound(CompoundAPI.get(identifier));
    }, [identifier]);

    return (
        <PageTemplate
            title='Compounds'
            breadcrumb={{
                title: getTitle(compoundResult),
                href: location.pathname,
            }}
            button={<SearchByIdentifier />}
        >
            <div className='d-flex h-100'>
                {compoundResult.isLoading && (
                    <div className='w-100'>
                        <Loading />
                    </div>
                )}
                {compoundResult.error && (
                    <ErrorMessage
                        header='Compound Load Error'
                        message="Foundry not available or compound doesn't exist."
                    />
                )}
                {compoundResult.result && (
                    <Split direction='horizontal' gutterSize={6} sizes={[0.25, 0.75]}>
                        <Split direction='vertical' gutterSize={6} sizes={[0.4, 0.6]}>
                            <Pane
                                title={`${compoundResult.result.identifier ?? compoundResult.result.structure.smiles}`}
                            >
                                <AsyncMoleculeDrawing
                                    smiles={compoundResult.result.structure.smiles}
                                    width='100%'
                                    autosize
                                />
                            </Pane>
                            <Pane title='Compound Information'>
                                <div>
                                    <CompoundInformationPanel compound={compoundResult.result} />
                                </div>
                            </Pane>
                        </Split>
                        <Pane title='' paneContentClassName='d-flex flex-column' topPaddingIndex={1}>
                            <CompoundDetailsTabSection compound={compoundResult.result} />
                        </Pane>
                    </Split>
                )}
            </div>
        </PageTemplate>
    );
}

type Inventory = Map<number, ECMSearchResult[]> | 'error' | 'loading';

async function loadInventory(compound: CompoundDetail, subject: BehaviorSubject<Inventory>) {
    try {
        const inventory = await ECMApi.query({ identifiers: [compound.identifier], query_plates: false });
        const rows = inventory.results.toObjects();
        const entries = new Map<number, ECMSearchResult[]>();
        for (const row of rows) {
            if (!row.batch_identifier) continue;
            const num = +row.batch_identifier.split('-')[1];
            arrayMapAdd(entries, num, row);
        }
        subject.next(entries);
    } catch (err) {
        reportErrorAsToast('Inventory search', err);
        subject.next('error');
    }
}

function CompoundDetailsTabSection({ compound }: { compound: CompoundDetail }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultTab = searchParams.get('tab');
    const highlightedBatch = searchParams.get('batch');

    const inventory = useBehaviorSubject<Inventory>('loading');
    const [batchesResult, loadBatches] = useAsyncAction<BatchesForCompoundResult>();
    const [assaysResult, loadAssays] = useAsyncAction<DataTableModel<BatchAssayTable>>();
    const [pkResult, loadPK] = useAsyncAction<PKDataWrapper>();
    const table = assaysResult.result;
    const numAssays =
        table?.store.getColumnValues(AssayShorthandColumnName).filter((c) => c.startsWith('A(')).length ?? 0;
    const pkModel = useMemo(
        () => (pkResult.result ? new PKModel(compound, pkResult.result) : null),
        [compound, pkResult.result]
    );
    const model = useMountedModel<PKModel>(pkModel);

    useEffect(() => {
        loadBatches(CompoundAPI.getBatches(compound.id));
        loadAssays(loadAssaysTable(compound.id));
        loadInventory(compound, inventory);
    }, [compound]);

    if (defaultTab === 'pk' && !pkResult.result && !pkResult.isLoading && !pkResult.error) {
        loadPK(CompoundAPI.getPKData(compound.id));
    }

    return (
        <Tabs
            defaultActiveKey={defaultTab ?? 'batches'}
            id='compound-detail-tabs'
            onSelect={(eventKey: string | null) =>
                setSearchParams({ batch: highlightedBatch ?? '', tab: eventKey ?? '' })
            }
        >
            <Tab
                title={`Batches ${batchesResult.result ? `(${batchesResult.result.batches.length})` : ''}`}
                eventKey='batches'
                className='my-3'
            >
                {batchesResult.isLoading && <Loading />}
                {batchesResult.error && <ErrorMessage header='Batch Load Error' message='Unable to load batches.' />}
                {batchesResult.result && (
                    <BatchList
                        batchResult={batchesResult.result}
                        inventory={inventory}
                        highlighted={highlightedBatch}
                    />
                )}
            </Tab>
            <Tab title={table ? `Assays (${numAssays})` : 'Assays'} eventKey='assays' className='h-100'>
                {assaysResult.isLoading && <Loading />}
                {assaysResult.error && <ErrorMessage header='Assay Load Error' message='Unable to load assays.' />}
                {table && <CompoundDetailsAssayTable table={table} />}
            </Tab>
            <Tab title='PK' eventKey='pk' className='h-100'>
                {pkResult.isLoading && <Loading />}
                {pkResult.error && (
                    <ErrorMessage header='PK Data Load Error' message={`Unable to load PK data.\n ${pkResult.error}`} />
                )}
                {pkResult.result && model && <PKPage model={model} />}
            </Tab>
        </Tabs>
    );
}

function BatchList({
    batchResult,
    inventory,
    highlighted,
}: {
    batchResult: BatchesForCompoundResult;
    inventory: BehaviorSubject<Inventory>;
    highlighted: string | null;
}) {
    const gostarBatches = batchResult.batches.filter((b) => b.supplier?.toUpperCase() === 'GOSTAR');
    const nonGostarBatches = batchResult.batches.filter((b) => b.supplier?.toUpperCase() !== 'GOSTAR');
    return (
        <>
            {gostarBatches.length > 0 && (
                <Alert variant='secondary'>
                    <FontAwesomeIcon icon={faInfoCircle} fixedWidth className='me-2' />
                    {gostarBatches.length} GOSTAR batch{gostarBatches.length === 1 ? '' : 'es'} not shown (
                    {gostarBatches.map((b) => b.identifier).join(', ')})
                </Alert>
            )}
            {nonGostarBatches.map((batch: Batch) => (
                <BatchCard
                    key={batch.identifier}
                    batch={batch}
                    inventory={inventory}
                    selected={batch.identifier === highlighted}
                    forwardedTo={batchResult.forwarded_batches[batch.forward?.forwarded_batch_id ?? -1]}
                />
            ))}
        </>
    );
}

function ToggleUnitsWrapper({ table }: { table: DataTableModel<BatchAssayTable> }) {
    useBehavior(table.version);
    const units = { use_potens: !table.state.customState[PotensAsNmProp] };

    return (
        <PotensNMToggle
            units={units}
            onToggle={(value: 'potens' | 'nM') =>
                table.setCustomState({ [PotensAsNmProp]: value === 'nM' }, { silent: false, clearRenderCache: true })
            }
        />
    );
}

function TableFilters({ table }: { table: DataTableModel<BatchAssayTable> }) {
    const [showLinkedFields, setShowLinkedFields] = useState(false);
    const [showHiddenAssays, setShowHiddenAssays] = useState(false);

    function onToggleLinkedFields(e: ChangeEvent<HTMLInputElement>) {
        table.setFilter(AssayShorthandColumnName, { showLinkedFields: e.target.checked, showHiddenAssays });
        setShowLinkedFields(e.target.checked);
    }

    function onToggleHiddenAssays(e: ChangeEvent<HTMLInputElement>) {
        table.setFilter(AssayShorthandColumnName, { showLinkedFields, showHiddenAssays: e.target.checked });
        setShowHiddenAssays(e.target.checked);
    }

    return (
        <div>
            <Form.Check
                checked={showLinkedFields}
                onChange={(e) => onToggleLinkedFields(e)}
                id='show-linked-fields'
                label='Show linked fields'
                className='d-inline-block ms-2 font-body-small'
            />
            <Form.Check
                checked={showHiddenAssays}
                onChange={(e) => onToggleHiddenAssays(e)}
                id='show-hidden-assays'
                label='Show hidden assays'
                className='d-inline-block ms-2 font-body-small'
            />
        </div>
    );
}

function CompoundDetailsAssayTable({ table }: { table: DataTableModel<BatchAssayTable> }) {
    useBehavior(table.version);
    if (table.store.rowCount === 0) return null;

    return (
        <div className='d-flex flex-column h-100'>
            <div className='py-2 hstack gap-2 flex-0'>
                <div>
                    <DataTableGlobalFilter table={table} />
                </div>
                <div>
                    <ToggleUnitsWrapper table={table} />
                </div>
                <TableFilters table={table} />
            </div>
            <div className='position-relative flex-grow-1'>
                <DataTableControl table={table} height='flex' headerSize='md' />
            </div>
        </div>
    );
}

function BatchCard({
    batch,
    selected,
    inventory,
    forwardedTo,
}: {
    batch: Batch;
    selected: boolean;
    inventory: BehaviorSubject<Inventory>;
    forwardedTo?: string;
}) {
    function getBadgeColor() {
        if (batch.label === 'Succeeded') return 'green-400';
        if (batch.label === 'Failed') return 'red-400';
        return 'secondary';
    }

    if (batch.forward) {
        const compoundIdentifier = forwardedTo?.split('-')[0];
        return (
            <Card title={batch.identifier} className='mb-2 card-forwarded'>
                <p>
                    This batch was forwarded to {forwardedTo} by {batch.forward.forwarded_by} on{' '}
                    {formatDateAsYYYY_MM_DD(batch.forward.forwarded_on)}.
                </p>
                <Link to={`/compounds/${compoundIdentifier}?batch=${forwardedTo}`}>Go to forwarded batch</Link>
            </Card>
        );
    }
    const smiles = batch.ambiguous_stereochemistry_v3k
        ? `${batch.structure.smiles} v3k:${batch.ambiguous_stereochemistry_v3k}`
        : batch.structure.smiles;
    return (
        <Card title={batch.identifier} className='mb-2' selected={selected}>
            <div className='d-flex' style={{ fontSize: '0.8rem' }}>
                <div className='flex-grow-1 w-25 me-2'>
                    <p className='fw-bold mb-1' style={{ fontSize: '1rem' }}>
                        Batch Information
                    </p>
                    <PropertyNameValue field='Kind' value={batch.kind} />
                    {batch.kind === 'Reaction mixture' && (
                        <PropertyNameValue
                            field='Label'
                            value={<Badge bg={getBadgeColor()}>{batch.label ?? '-'}</Badge>}
                        />
                    )}
                    <PropertyNameValue
                        field='Molecular Formula'
                        value={formatMolecularFormula(batch.molecular_formula)}
                    />
                    <PropertyNameValue
                        field='SMILES'
                        value={<OverflowSmilesDisplay smiles={batch.structure.smiles} />}
                    />
                    <PropertyNameValue field='Salt' value={batch.salt ? batch.salt.smiles : '-'} />
                    <PropertyNameValue field='CAS Numbers' value={batch.cas_numbers?.join(', ')} />
                    <PropertyNameValue field='Formula Weight' value={`${batch.formula_weight.toFixed(2)}`} />
                </div>
                <div className='flex-grow-1 w-25 me-2'>
                    <PropertyNameValue
                        field='Submitted Purity'
                        value={`${batch.submitted_purity?.toFixed(2) ?? '-'}`}
                    />
                    <PropertyNameValue field='Uploaded by' value={`${batch.uploaded_by}`} />
                    <PropertyNameValue
                        field='Uploaded on'
                        value={parseFileSystemDate(batch.uploaded_on).toLocaleString()}
                    />
                </div>
                <div className='flex-grow-1 w-25'>
                    <p className='fw-bold mb-1' style={{ fontSize: '1rem' }}>
                        Supplier Information
                    </p>
                    <PropertyNameValue field='Supplier' value={batch.supplier} />
                    <PropertyNameValue field='Supplier ID' value={batch.supplier_id} />
                    <PropertyNameValue field='Supplier NB' value={batch.supplier_notebook} />
                    <PropertyNameValue field='Supplier NB Page' value={batch.supplier_notebook_page} />
                </div>
                <div className='flex-grow-1 w-25'>
                    <AsyncMoleculeDrawing smiles={smiles} width='100%' autosize />
                </div>
            </div>
            <ECMSummary inventory={inventory} batchNumber={batch.batch_number} />
        </Card>
    );
}

function VialsAndPlatesTable({ results }: { results: ECMSearchResult[] }) {
    if (!results.length) return null;

    return (
        <div className='pt-2'>
            <div className='mb-1'>
                <span className='fw-bold'>Vials</span>
                <InfoTooltip tooltip='Active samples only; disposed samples not shown.' />
            </div>
            <table className='font-body-xsmall'>
                <thead className='text-secondary border-2 border-bottom border-start-0 border-top-0 border-end-0'>
                    <tr>
                        <td className='pe-4 py-1'>Barcode</td>
                        <td className='pe-4 py-1'>Amount</td>
                        <td className='pe-4 py-1'>Volume</td>
                        <td className='pe-4 py-1'>Concentration</td>
                        <td className='pe-4 py-1'>Solvent</td>
                        <td className='pe-4 py-1'>Site</td>
                        <td className='pe-4 py-1'>Uploaded By</td>
                        <td className='pe-4 py-1'>Uploaded On</td>
                    </tr>
                </thead>
                <tbody>
                    {results.map((v) => {
                        if (v.status === 'Disposed' || !v.sample) return null;

                        return (
                            <tr
                                key={`${v.sample.barcode}-${v.sample.batch_id}-${v.sample.sample_number}`}
                                className='border-1 border-bottom border-start-0 border-top-0 border-end-0'
                            >
                                <td className='pe-4 py-1'>{v.barcode}</td>
                                <td className='pe-4 py-1' style={{ textAlign: 'right' }}>
                                    {typeof v.sample.solute_mass === 'number'
                                        ? prefixedUnitValue(v.sample.solute_mass, 'g', { fixed: true })
                                        : ''}
                                </td>
                                <td className='pe-4 py-1' style={{ textAlign: 'right' }}>
                                    {typeof v.sample.solvent_volume === 'number'
                                        ? prefixedUnitValue(v.sample.solvent_volume * 1e3, 'L', { fixed: true })
                                        : ''}
                                </td>
                                <td className='pe-4 py-1' style={{ textAlign: 'right' }}>
                                    {prefixedUnitValue(v.sample.concentration, 'M', { fixed: true })}
                                </td>
                                <td className='pe-4 py-1'>{v.sample.solvent}</td>
                                <td className='pe-4 py-1'>{getSite(v) ?? ''}</td>
                                <td className='pe-4 py-1'>{v.sample.uploaded_by}</td>
                                <td className='pe-4 py-1'>
                                    {parseFileSystemDate(v.sample.uploaded_on).toLocaleString()}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

function sortedResults(vials: ECMSearchResult[] | undefined) {
    if (!vials) return [];
    const ret = [...vials];
    ret.sort((a, b) => {
        if (a.barcode && b.barcode) return a.barcode === b.barcode ? a.id - b.id : a.barcode < b.barcode ? -1 : 1;
        if (a.barcode) return -1;
        if (b.barcode) return 1;
        return a.id - b.id;
    });
    return ret;
}

// this is an initial simple implementation
// TODO: update once we know better what to do about Sites.
function getSite(vial: ECMSearchResult) {
    if (isLabwareAtEntosSite(vial)) return 'Entos';
    return '';
}

function ECMSummary({ inventory, batchNumber }: { inventory: BehaviorSubject<Inventory>; batchNumber: number }) {
    const data = useBehavior(inventory);
    if (data === 'loading') return <Spinner className='mt-2' animation='border' role='status' size='sm' />;

    if (data === 'error') {
        return <p className='mt-2 mx-3 text-danger'>Error fetching inventory.</p>;
    }

    const results = data.get(batchNumber) ?? [];
    return <VialsAndPlatesTable results={sortedResults(results)} />;
}
