import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loading from '../../components/common/Loading';
import { PageTemplate } from '../../components/Layout/Layout';
import useBehavior from '../../lib/hooks/useBehavior';
import { EcosystemService } from '../../lib/services/ecosystem';

const PoseViewerRoot = lazy(() => import(/* webpackChunkName: "envision-bio" */ './pose-viewer'));
const TargetsRoot = lazy(() => import(/* webpackChunkName: "envision-bio" */ './targets'));
const TargetViewRoot = lazy(() => import(/* webpackChunkName: "envision-bio" */ './targets/view'));
const Viewer3DRoot = lazy(() => import(/* webpackChunkName: "envision-bio" */ './viewer3d'));
const PoseANERoot = lazy(() => import(/* webpackChunkName: "envision-bio" */ './workflows/poseane'));

function LoadingUI({ app }: { app: string }) {
    return (
        <PageTemplate title='Envision BIO' breadcrumb={{ title: app, href: '#' }}>
            <Loading />
        </PageTemplate>
    );
}

function App({ app, UI, devOnly }: { app: string; UI: any; devOnly?: boolean }) {
    const env = useBehavior(devOnly ? EcosystemService.environment : undefined);

    if (devOnly) {
        if (!env) return <LoadingUI app={app} />;
        if (env.name === 'prd') {
            return (
                <PageTemplate title='Envision BIO' breadcrumb={{ title: app, href: '#' }}>
                    <div className='p-4'>
                        <p>This feature is currently not available in the Production Environment.</p>
                        <p>
                            Please use the{' '}
                            <a href='https://insight.dev.themachine.entos-foundry.ai/' target='_blank' rel='noreferrer'>
                                development version of Insight
                            </a>{' '}
                            to test it.
                        </p>
                    </div>
                </PageTemplate>
            );
        }
    }

    return (
        <Suspense fallback={<LoadingUI app={app} />}>
            <UI />
        </Suspense>
    );
}

const PoseViewer = <App app='Pose Viewer' UI={PoseViewerRoot} />;
const Targets = <App app='Targets' UI={TargetsRoot} />;
const TargetView = <App app='Target View' UI={TargetViewRoot} />;
const Viewer3D = <App app='Viewer 3D' UI={Viewer3DRoot} />;
const PoseANE = <App app='PoseANE' UI={PoseANERoot} />;

export function EnvisionBIORouter() {
    return (
        <Routes>
            <Route path='/pose-viewer/:state_id' element={PoseViewer} />
            <Route path='/pose-viewer' element={PoseViewer} />
            <Route path='/targets/view/:eoi' element={TargetView} />
            <Route path='/targets' element={Targets} />
            <Route path='/viewer3d' element={Viewer3D} />
            <Route path='/workflows/PoseANE/:id' element={PoseANE} />
            <Route path='/workflows/PoseANE' element={PoseANE} />
        </Routes>
    );
}
