import { Button, Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { AsyncMoleculeDrawing } from '../../../components/common/AsyncMoleculeDrawing';
import { SingleFileUpload } from '../../../components/common/FileUpload';
import { Option } from '../../../components/common/formatSelectOptionLabel';
import { TextInput } from '../../../components/common/Inputs';
import Loading from '../../../components/common/Loading';
import { CustomSelectClassNames, DefaultSelectStyles } from '../../../components/common/selectStyles';
import { SubstructureSearchInput } from '../../../components/common/SubstructureSearch';
import useBehavior from '../../../lib/hooks/useBehavior';
import { STEREOCHEMISTRY_LABELS, StereochemistryLabelEnum } from '../compound-api';
import { BatchUploadModel, INVALID_STEREOCHEMISTRY_LABEL } from './batch-upload-model';
import { SampleUploadModel } from './sample-upload-model';

const BATCH_SAMPLE_CONTENT = {
    batch: {
        title: 'Register new batch',
        loading: 'Looking for similar compounds...',
        loadError: 'Error uploading batch file',
        instructions:
            'Upload a file containing batches to add them to Entos Foundry. Supported file formats: .sdf, .csv, .xls, or .xslx.',
        extensions: ['.sdf', '.csv', '.xls', '.xlsx'],
    },
    sample: {
        title: 'Register new samples',
        loading: 'Loading samples...',
        loadError: 'Error uploading sample file',
        instructions:
            'Upload a file containing samples to add them to Entos Foundry. Supported file formats: .csv, .xls, or .xslx.',
        extensions: ['.csv', '.xls', '.xlsx'],
    },
};

export function UploadBatchesModal({ model }: { model: BatchUploadModel }) {
    const modalOpen = useBehavior(model.state.modalOpen);
    const isLoading = useBehavior(model.state.isLoading);
    const error = useBehavior(model.state.error);
    const settingsInvalid = useBehavior(model.state.settingsInvalid);
    const content = BATCH_SAMPLE_CONTENT.batch;

    return (
        <Modal backdrop centered scrollable show={modalOpen} onHide={() => model.cancel()}>
            <Modal.Header closeButton>
                <h4>Register new batch</h4>
            </Modal.Header>
            <Modal.Body>
                {isLoading && <Loading message={content.loading} />}
                {!isLoading && (
                    <>
                        <div className='mb-3'>{content.instructions}</div>
                        <BatchModalContent model={model} />
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                {error && <p className='text-danger'>{error}</p>}
                {typeof settingsInvalid === 'string' && <p className='text-danger'>{settingsInvalid}</p>}
                <Button variant='link' onClick={() => model.cancel()}>
                    Cancel
                </Button>
                <Button variant='primary' onClick={() => model.next()} disabled={!!settingsInvalid || isLoading}>
                    Next
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function BatchModalContent({ model }: { model: BatchUploadModel }) {
    const type = useBehavior(model.state.type);
    const smilesInput = useBehavior(model.state.smilesInput);
    const stereochemistryLabel = useBehavior(model.state.stereochemistryLabel);
    const enantiomericRatio = useBehavior(model.state.enantiomericRatio);
    const options: Option[] = STEREOCHEMISTRY_LABELS.filter((label) => label !== INVALID_STEREOCHEMISTRY_LABEL).map(
        (value) => ({
            label: value,
            value,
        })
    );
    const content = BATCH_SAMPLE_CONTENT.batch;

    return (
        <div>
            <Form.Check
                type='radio'
                className='mb-2'
                name='batches'
                label='Multiple batches'
                id='upload-batches-multiple'
                checked={type === 'multiple'}
                onChange={() => model.state.type.next('multiple')}
            />
            {type === 'multiple' && (
                <SingleFileUpload fileSubject={model.state.file} label='' extensions={content.extensions} />
            )}
            <Form.Check
                type='radio'
                className='mb-2'
                name='batches'
                label='Single batch'
                id='upload-batches-single'
                checked={type === 'single'}
                onChange={() => model.state.type.next('single')}
            />
            {type === 'single' && (
                <div className='ms-3'>
                    <SubstructureSearchInput
                        smiles={smilesInput}
                        setSmiles={(smiles: string) => model.state.smilesInput.next(smiles)}
                    />
                    <div className='d-flex mt-2'>
                        <div className='w-50'>
                            <Select
                                options={options as any}
                                placeholder='Select stereochemistry'
                                classNames={CustomSelectClassNames}
                                styles={DefaultSelectStyles}
                                menuPortalTarget={document.body}
                                menuPosition='fixed'
                                value={{ label: stereochemistryLabel, value: stereochemistryLabel }}
                                onChange={(option: unknown) => {
                                    const label = (option as Option).value as StereochemistryLabelEnum;
                                    model.state.stereochemistryLabel.next(label);
                                    // if the label is "Achiral", don't enter an enantiomeric ratio
                                    if (label === 'Achiral' && enantiomericRatio)
                                        model.state.enantiomericRatio.next('');
                                }}
                            />
                        </div>
                        <div className='w-50 ps-2'>
                            <TextInput
                                placeholder='Enter enantiomeric ratio'
                                value={enantiomericRatio}
                                setValue={(v: string) => model.state.enantiomericRatio.next(v)}
                                validation={model.enantiomericRatioIsValid() ? '' : 'x:y format expected'}
                                disabled={stereochemistryLabel === 'Achiral'}
                            />
                        </div>
                    </div>
                    <div
                        className='d-flex justify-content-center align-items-center mt-3 rounded'
                        style={{ height: '150px', backgroundColor: 'rgba(0,0,0,0.25)' }}
                    >
                        {smilesInput === '' && 'Add a compound above to show preview'}
                        {smilesInput !== '' && <AsyncMoleculeDrawing smiles={smilesInput} height='100%' autosize />}
                    </div>
                </div>
            )}
        </div>
    );
}

export function UploadSamplesModal({ model }: { model: SampleUploadModel }) {
    const modalOpen = useBehavior(model.state.modalOpen);
    const isLoading = useBehavior(model.state.isLoading);
    const error = useBehavior(model.state.error);
    const settingsValid = useBehavior(model.state.settingsValid);
    const content = BATCH_SAMPLE_CONTENT.sample;

    return (
        <Modal backdrop centered scrollable show={modalOpen} onHide={() => model.cancel()}>
            <Modal.Header closeButton>
                <h4>Register new samples</h4>
            </Modal.Header>
            <Modal.Body>
                {isLoading && <Loading message={content.loading} />}
                {!isLoading && (
                    <>
                        <div className='mb-3'>{content.instructions}</div>
                        <SingleFileUpload fileSubject={model.state.file} label='' extensions={content.extensions} />
                    </>
                )}
                {error && <p className='text-danger mt-2'>{error}</p>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='link' onClick={() => model.cancel()}>
                    Cancel
                </Button>
                <Button variant='primary' onClick={() => model.next()} disabled={!settingsValid || isLoading}>
                    Next
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
