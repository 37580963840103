import {
    faCheck,
    faExclamationTriangle,
    faInfoCircle,
    faMinus,
    faRightLeft,
    faTriangleExclamation,
    faX,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import saveAs from 'file-saver';
import { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { BehaviorSubject, combineLatest } from 'rxjs';
import {
    Column,
    ColumnFilters,
    DataTableModel,
    DefaultRowHeight,
    ObjectDataTableStore,
} from '../../../components/DataTable';
import { SmilesColumn } from '../../../components/DataTable/common';
import { InlineAlert } from '../../../components/common/Alert';
import { SingleFileUploadV2 } from '../../../components/common/FileUpload';
import { IconButton } from '../../../components/common/IconButton';
import { LabeledInput, TextInput } from '../../../components/common/Inputs';
import { AssayValueView } from '../../../lib/assays/display';
import useBehavior from '../../../lib/hooks/useBehavior';
import { DialogService } from '../../../lib/services/dialog';
import { EcosystemService } from '../../../lib/services/ecosystem';
import { ToastService } from '../../../lib/services/toast';
import { objectIsEmpty } from '../../../lib/util/misc';
import { ModelAction, ReactiveModel } from '../../../lib/util/reactive-model';
import {
    isScoreBoxComputeError,
    ScoreBoxSocketCompute,
    ScoreBoxComputeResult,
} from '../../../lib/util/score-box-compute';
import { formatWithUnit, parseWithUnit } from '../../../lib/util/units';
import { BatchLink } from '../../ECM/ecm-common';
import { HTE2MSApi } from '../api';
import { HTE2MSAssets } from '../assets';
import {
    HTEPFraction,
    HTEPFractionValidation,
    HTEPPoolingIdT,
    HTEPPoolingScriptOptions,
    HTEPurification,
} from '../data-model';
import { EditLabwareOption, Formatters } from '../utils';
import type { HTE2MSPostProductionModel } from './model';

export interface FractionRow {
    original: HTEPFraction;
    structure: string;
    identifier_search: string;
    crude_identifier: string;
    purified_identifier?: string;
    purified_barcodes?: string[];
    pooled_volume?: number;
    salt?: [name: string | undefined, smiles: string | undefined];
    pKa?: ScoreBoxComputeResult;
    salt_expressed?: boolean;
    average_purity?: number;
    n_samples?: number;
    asid?: string;
    validation?: HTEPFractionValidation;
}

const EmptyPurification: HTEPurification = {
    poolings: [],
    salt_name_to_smiles: {},
    states: {},
};

function getPurifiedUniversalIdentifier(
    assets: HTE2MSAssets,
    currentId: string | undefined,
    data: HTEPurification,
    v: HTEPFraction
) {
    if (!currentId) return;
    const barcode = data.states[currentId].pooled_fraction_barcodes[v.id]?.[0];
    const sample = assets.inventory.getVialSample(barcode);
    if (!sample) return;
    return assets.entities.getBatch(sample.batch_id)?.universal_identifier;
}

const PRD_pKaPredictionBoxName = 'propane-pka';

function getPKAPredictionBoxName() {
    // Propane pKa is only present in production
    return EcosystemService.environment.value?.name === 'prd' ? PRD_pKaPredictionBoxName : 'propane-test-logd';
}

export class HTE2MSPurificationModel extends ReactiveModel {
    store = new ObjectDataTableStore<FractionRow, HTEPFraction>([
        { name: 'original', getter: (v) => v },
        {
            name: 'identifier_search',
            getter: (v) => {
                const crudeBatch = this.assets.entities.getBatch(v.batch_identifier);
                const purifiedBatchIdent = getPurifiedUniversalIdentifier(this.assets, this.currentId, this.data, v);
                return `${this.assets.entities.getIdentifiersLookup(crudeBatch?.universal_identifier) ?? ''}\n${
                    this.assets.entities.getIdentifiersLookup(purifiedBatchIdent) ?? ''
                }`;
            },
        },
        {
            name: 'structure',
            getter: (v) => {
                const smiles = this.assets.entities.getStructure(v.batch_identifier);
                if (!smiles) return '';
                const salt = this.data.salt_name_to_smiles[v.salt_name!] ?? v.salt_smiles;
                if (!salt) return smiles;
                return `${smiles}.${salt}`;
            },
        },
        {
            name: 'salt',
            getter: (v) => {
                if (v.salt_name?.toLowerCase() === 'No target') return ['No target', undefined];
                return [v.salt_name || v.salt_smiles, this.data.salt_name_to_smiles[v.salt_name!] ?? v.salt_smiles];
            },
        },
        {
            name: 'pKa',
            getter: (v) => {
                const structure = this.assets.entities.getCompoundFromIdentifier(v.batch_identifier)?.structure.smiles;
                return this.pKaData[structure!];
            },
        },
        {
            name: 'salt_expressed',
            getter: (v) => {
                if (!this.currentId) return;
                return !this.data.states[this.currentId].excluded_salts?.includes(v.id);
            },
        },
        { name: 'crude_identifier', getter: (v) => v.batch_identifier },
        {
            name: 'purified_identifier',
            getter: (v) => getPurifiedUniversalIdentifier(this.assets, this.currentId, this.data, v),
        },
        {
            name: 'validation',
            getter: (v) => {
                if (!this.currentId) return;
                return this.data.states[this.currentId].fraction_validation?.[v.id];
            },
        },
        {
            name: 'asid',
            getter: (v) => v.analytical_sample_id,
        },
        {
            name: 'purified_barcodes',
            getter: (v) => {
                if (!this.currentId) return;
                return this.data.states[this.currentId].pooled_fraction_barcodes[v.id];
            },
        },
        {
            name: 'n_samples',
            getter: (v) => {
                let n = 0;
                for (const s of v.samples) {
                    if (s.selected) n++;
                }
                return n;
            },
        },
        {
            name: 'pooled_volume',
            getter: (v) => {
                let volume = 0;
                for (const s of v.samples) {
                    if (s.selected) volume += s.volume;
                }
                return volume;
            },
        },
        {
            name: 'average_purity',
            getter: (v) => {
                let purity = 0;
                let n = 0;
                for (const s of v.samples) {
                    if (s.selected) {
                        purity += s.absolute_purity;
                        n++;
                    }
                }
                return purity / n;
            },
        },
    ]);

    table: DataTableModel<FractionRow>;

    state = {
        data: new BehaviorSubject<HTEPurification>(EmptyPurification),
        pKaData: new BehaviorSubject<Record<string, ScoreBoxComputeResult>>({}),
        currentId: new BehaviorSubject<HTEPPoolingIdT | undefined>(undefined),
        addSalt: new BehaviorSubject<[name: string, smiles: string]>(['', '']),
    };

    actions = {
        addSalt: new ModelAction({ onError: 'toast', toastErrorLabel: 'Add Salt', applyResult: () => {} }),
    };

    pKaCompute = new ScoreBoxSocketCompute({
        name: getPKAPredictionBoxName(),
        onResult: (results) => {
            this.state.pKaData.next({ ...this.pKaData, ...results });
            this.table.dataChanged();
        },
    });

    get pKaData() {
        return this.state.pKaData.value;
    }

    get mainModel() {
        return this.postProduction.model;
    }

    get assets() {
        return this.mainModel.assets;
    }

    get currentId() {
        return this.state.currentId.value;
    }

    get current() {
        const id = this.state.currentId.value;
        for (const p of this.data.poolings) {
            if (p.id === id) return p;
        }
        return undefined;
    }

    get data() {
        return this.state.data.value;
    }

    setData(data: HTEPurification) {
        this.state.data.next(data);
    }

    async init(data: HTEPurification) {
        this.setData(data);
        await this.syncAssets(data);
    }

    confirmUploadBarcodes(id: HTEPPoolingIdT) {
        DialogService.open({
            type: 'generic',
            title: 'Upload Pooled Barcodes',
            confirmButtonContent: 'Apply',
            model: this,
            wrapOk: true,
            defaultState: {
                file: undefined,
            } satisfies UploadPoolingBarcodesState,
            content: UploadPoolingBarcodesDialogContent,
            onOk: (state: UploadPoolingBarcodesState) => this._applyUploadBarcodes(id, state),
        });
    }

    private async _applyUploadBarcodes(id: HTEPPoolingIdT, state: UploadPoolingBarcodesState) {
        if (!this.mainModel.experiment) return;

        if (!state.file) {
            throw new Error('No file provided');
        }

        const result = await HTE2MSApi.uploadPoolingBarcodes(
            this.mainModel.experiment?.id!,
            id,
            { file: state.file },
            {
                last_modified_on: this.mainModel.last_modified_on!,
            }
        );
        await this.syncAssets(result.purification, { pooling_id: id, refresh: true });

        this.mainModel.updateFoundryInfo({ experiment: result.experiment });
        this.setData(result.purification);

        ToastService.success('Barcodes uploaded');
    }

    confirmRegister(id: HTEPPoolingIdT) {
        DialogService.open({
            type: 'generic',
            title: 'Register Pooling',
            confirmButtonContent: 'Apply',
            model: this,
            wrapOk: true,
            content: RegisterPoolingDialogContent,
            onOk: () => this._applyRegister(id),
        });
    }

    private async _applyRegister(id: HTEPPoolingIdT) {
        if (!this.mainModel.experiment) return;

        await HTE2MSApi.registerPooling(this.mainModel.experiment?.id!, id, {
            last_modified_on: this.mainModel.last_modified_on!,
        });
        // Sync the assets to fetch the assigned barcodes
        await this.syncAssets(this.data, { pooling_id: id, refresh: true });
        // Shallow copy the data to trigger update
        this.state.data.next({ ...this.data });

        ToastService.success('Pooling registered');
    }

    uploadPooling = () => {
        DialogService.open({
            type: 'generic',
            title: 'Upload Pooling',
            confirmButtonContent: 'Upload',
            model: this,
            wrapOk: true,
            defaultState: {
                file: undefined,
                options: this.mainModel.assets.defaults.default_pooling_script_options,
            } satisfies UploadPoolingState,
            content: UploadPoolingDialogContent,
            onOk: (state: UploadPoolingState) => this._applyUpload(state),
        });
    };

    private async _applyUpload(state: UploadPoolingState) {
        if (!this.mainModel.experiment) return;

        if (!state.file) {
            throw new Error('No file provided');
        }

        const result = await HTE2MSApi.uploadPooling(
            this.mainModel.experiment?.id!,
            {
                file: state.file,
            },
            {
                options: state.options,
                last_modified_on: this.mainModel.last_modified_on!,
            }
        );

        this.mainModel.updateFoundryInfo({ experiment: result.experiment });
        this.setData(result.purification);
        this.state.currentId.next(result.purification.poolings[result.purification.poolings.length - 1]?.id);
    }

    confirmRemove(id: HTEPPoolingIdT) {
        DialogService.open({
            type: 'generic',
            title: 'Remove Pooling',
            confirmButtonContent: 'Remove',
            model: this,
            wrapOk: true,
            content: RemovePoolingDialogContent,
            onOk: () => this._applyRemove(id),
        });
    }

    private async _applyRemove(id: HTEPPoolingIdT) {
        if (!this.mainModel.experiment) return;

        const result = await HTE2MSApi.removePooling(this.mainModel.experiment?.id!, id, {
            last_modified_on: this.mainModel.last_modified_on!,
        });

        this.mainModel.updateFoundryInfo({ experiment: result.experiment });
        this.setData(result.purification);

        if (this.state.currentId.value === id) {
            this.state.currentId.next(undefined);
        }

        ToastService.info('Pooling removed');
    }

    async addSalt() {
        if (!this.mainModel.experiment) return;

        const name = this.state.addSalt.value[0];
        const smiles = this.state.addSalt.value[1];
        if (!name || !smiles) return;

        const salt_name_to_smiles = { ...this.data.salt_name_to_smiles };
        salt_name_to_smiles[name] = smiles;

        const result = await HTE2MSApi.setPurificationSalts(this.mainModel.experiment?.id!, {
            salt_name_to_smiles,
            last_modified_on: this.mainModel.last_modified_on!,
        });

        this.mainModel.updateFoundryInfo({ experiment: result.experiment });
        this.setData(result.purification);

        this.state.addSalt.next(['', '']);
        ToastService.info('Salt added');
    }

    confirmRemoveSalt(name: string) {
        DialogService.open({
            type: 'generic',
            title: 'Remove Salt',
            confirmButtonContent: 'Remove',
            model: name,
            wrapOk: true,
            content: RemoveSaltDialogContent,
            onOk: () => this._applyRemoveSalt(name),
        });
    }

    private async _applyRemoveSalt(name: string) {
        if (!this.mainModel.experiment) return;

        const salt_name_to_smiles = { ...this.data.salt_name_to_smiles };
        delete salt_name_to_smiles[name];

        const result = await HTE2MSApi.setPurificationSalts(this.mainModel.experiment?.id!, {
            salt_name_to_smiles,
            last_modified_on: this.mainModel.last_modified_on!,
        });

        this.mainModel.updateFoundryInfo({ experiment: result.experiment });
        this.setData(result.purification);

        ToastService.info('Salt removed');
    }

    async syncAssets(
        data: HTEPurification,
        options?: {
            pooling_id?: HTEPPoolingIdT;
            refresh?: boolean;
        }
    ) {
        const barcodes = new Set<string>();
        const batches = new Set<string>();
        for (const p of data.poolings) {
            if (options?.pooling_id && p.id !== options?.pooling_id) continue;

            for (const f of p.fractions) {
                batches.add(f.batch_identifier);
            }

            const pooled = data.states[p.id].pooled_fraction_barcodes;
            if (!pooled) continue;

            for (const xs of Array.from(Object.values(pooled))) {
                for (const x of xs) barcodes.add(x);
            }
        }

        await this.mainModel.assets.inventory.syncHolders(Array.from(barcodes), options?.refresh);
        await this.mainModel.assets.entities.syncIdentifiers(Array.from(batches));
    }

    isPoolingRegistered(id: HTEPPoolingIdT) {
        // Pooling is registered if all pooled fraction vials have a samples
        const state = this.data.states[id];
        if (!state?.pooled_fraction_barcodes || objectIsEmpty(state.pooled_fraction_barcodes)) return false;
        for (const xs of Object.values(state.pooled_fraction_barcodes)) {
            for (const barcode of xs) {
                const vial = this.assets.inventory.getExistingVial(barcode);
                if (!vial?.sample) return false;
            }
        }
        return true;
    }

    confirmUpdateSaltExpression(fraction: HTEPFraction, expressed: boolean) {
        DialogService.open({
            type: 'generic',
            title: 'Update Salt Inclusion',
            confirmButtonContent: 'Apply',
            model: expressed,
            wrapOk: true,
            options: { okOnEnterKeyPress: true },
            content: UpdateSaltExpressionDialogContent,
            onOk: () => this._applyUpdateSaltExpression(fraction, expressed),
        });
    }

    private async _applyUpdateSaltExpression(fraction: HTEPFraction, expressed: boolean) {
        if (!this.mainModel.experiment) return;

        const result = await HTE2MSApi.updateSaltExpression(this.mainModel.experiment?.id!, this.currentId!, {
            salt_exclusions: [[fraction.id, expressed]],
            last_modified_on: this.mainModel.last_modified_on!,
        });

        this.mainModel.updateFoundryInfo({ experiment: result.experiment });
        this.setData(result.purification);

        ToastService.info('Salt inclusion updated');
    }

    saveProtocol(options: { protocol: string }) {
        const filename = `${this.mainModel.libraryId}-pooling.gwl`;
        saveAs(new Blob([options.protocol], { type: 'text/plain' }), filename);
    }

    mount() {
        const update = combineLatest([this.state.data, this.state.currentId]);

        this.subscribe(update, () => {
            this.store.setRows(this.current?.fractions ?? []);
            this.table.dataChanged();
        });

        this.subscribe(this.state.currentId, () => {
            const { current } = this;
            if (!current) return;
            const smiles: string[] = [];
            for (const f of current.fractions) {
                const structure = this.assets.entities.getCompoundFromIdentifier(f.batch_identifier)?.structure.smiles;
                if (structure) smiles.push(structure);
            }
            this.pKaCompute.computeBoxValues(smiles);
        });
    }

    dispose() {
        super.dispose();
        this.pKaCompute.cancel();
    }

    constructor(public postProduction: HTE2MSPostProductionModel) {
        super();

        this.table = new DataTableModel<FractionRow>(this.store, {
            columns: {
                identifier_search: {
                    ...Column.str(),
                    render: ({ value }) => value,
                },
                structure: {
                    ...SmilesColumn(this.mainModel.drawer, 2.5, {
                        width: 160,
                        getIdentifierElement: ({ rowIndex, showSMILES }) => {
                            const identifier = this.assets.entities.getIdentifier(
                                this.store.getValue('crude_identifier', rowIndex)
                            );
                            return (
                                <span className={showSMILES ? 'font-body-xsmall' : undefined}>
                                    <BatchLink identifier={identifier} />
                                </span>
                            );
                        },
                        identifierPadding: 20,
                        getSMILES: (structure) => structure ?? '',
                        autosize: true,
                        hideToggle: false,
                        disableChemDraw: true,
                        header: 'Structure',
                    }),
                    disableGlobalFilter: true,
                },
                validation: {
                    kind: 'generic',
                    header: 'Validation',
                    format: (v) => '<unused>',
                    render: ({ value }) => {
                        if (!value) return null;
                        const kind = value?.kind === 'error' ? 'danger' : 'warning';
                        return (
                            <span className={`text-${kind}`}>
                                <FontAwesomeIcon
                                    size='sm'
                                    fixedWidth
                                    className='me-2'
                                    icon={kind === 'warning' ? faExclamationTriangle : faInfoCircle}
                                />
                                {value.message}
                            </span>
                        );
                    },
                    width: 300,
                    compare: (a, b) => {
                        if (!a && !b) return 0;
                        if (a && !b) return -1;
                        if (!a && b) return 1;
                        if (a!.message === b!.message) return 0;
                        return a!.message < b!.message ? -1 : 1;
                    },
                    disableGlobalFilter: true,
                },
                salt: {
                    kind: 'generic',
                    header: 'Salt',
                    format: (v) => '<unused>',
                    render: ({ value }) => {
                        if (!value) return null;
                        if (!value[0]) return value[1];
                        return <span title={value[1]}>{value[0]}</span>;
                    },
                    width: 100,
                    compare: false,
                    disableGlobalFilter: true,
                },
                pKa: {
                    kind: 'generic',
                    header: `cpKa (${PRD_pKaPredictionBoxName})`,
                    noHeaderTooltip: true,
                    format: (v) => '<unused>',
                    render: ({ value }) => {
                        if (!value) return <Spinner size='sm' animation='border' role='status' />;

                        if (isScoreBoxComputeError(value)) {
                            return (
                                <FontAwesomeIcon
                                    icon={faTriangleExclamation}
                                    className='text-danger'
                                    title={value.message}
                                />
                            );
                        }

                        return <AssayValueView value={value} />;
                    },
                    width: 120,
                    compare: false,
                    disableGlobalFilter: true,
                },
                salt_expressed: {
                    kind: 'generic',
                    header: 'Salt Included',
                    format: (v) => '<unused>',
                    render: ({ value, rowIndex }) => {
                        const salt = this.store.getValue('salt', rowIndex)?.[1];
                        if (!salt)
                            return <FontAwesomeIcon icon={faMinus} className='text-secondary' size='sm' fixedWidth />;
                        const fraction = this.store.getValue('original', rowIndex);
                        const registered = this.isPoolingRegistered(this.currentId!);
                        return (
                            <>
                                <FontAwesomeIcon
                                    icon={value ? faCheck : faX}
                                    className={value ? 'text-success me-1' : 'text-warning me-1'}
                                    size='sm'
                                    fixedWidth
                                />
                                {!registered && (
                                    <IconButton
                                        icon={faRightLeft}
                                        title='Change'
                                        size='sm'
                                        onClick={() => this.confirmUpdateSaltExpression(fraction, value!)}
                                    />
                                )}
                            </>
                        );
                    },
                    width: 100,
                    compare: false,
                    disableGlobalFilter: true,
                },
                asid: {
                    ...Column.str(),
                    header: 'ASID',
                    render: ({ value }) => value,
                    width: 180,
                },
                n_samples: {
                    ...Column.int(),
                    header: '# Samples',
                    align: 'right',
                    render: ({ value }) => value,
                    width: 80,
                },
                purified_identifier: {
                    ...Column.str(),
                    header: 'Pur. Identifier',
                    render: ({ value }) =>
                        value ? <BatchLink identifier={this.assets.entities.getIdentifier(value)} withQuery /> : null,
                    width: 180,
                },
                purified_barcodes: {
                    kind: 'generic',
                    header: 'Barcodes',
                    format: (v) => '<unused>',
                    render: ({ value }) => value?.join(', '),
                    width: 200,
                    compare: false,
                    globalFilterFn: () => ColumnFilters.caseInsensitiveArray,
                },
                pooled_volume: {
                    ...Column.float(),
                    header: 'Pooled Volume',
                    align: 'right',
                    render: ({ value }) => Formatters.siVolume(value),
                    width: 100,
                },
                average_purity: {
                    ...Column.float(),
                    header: 'Avg. Purity',
                    align: 'right',
                    render: ({ value }) => Formatters.percent(value),
                    width: 100,
                },
            },
            globalFilterHiddenColumns: true,
            hideNonSchemaColumns: true,
        });

        this.table.setCustomState({ 'show-smiles': true });
        this.table.setRowHeight(DefaultRowHeight * 2.5);
        this.table.setColumnVisibility('identifier_search', false);
    }
}

interface UploadPoolingState {
    file: File | undefined;
    options: HTEPPoolingScriptOptions;
}

interface UploadPoolingBarcodesState {
    file: File | undefined;
}

const LabelWidth = 160;

const REQUIRED_UPLOAD_COLUMNS =
    'Selection Status,Iambic Identifier,Target,COLLECT,VOLUME,ABS_PURITY,NORM_PURITY,Fraction Pool Number,Comment,SALT_MOD'.split(
        ','
    );
const OPTIONAL_UPLOAD_COLUMNS = 'BARCODE,Crude Position,Crude Plate Barcode,ASID,Salt SMILES'.split(',');

const UPLOAD_MARKDOWN = `
- Required columns:
    - ${REQUIRED_UPLOAD_COLUMNS.map((c) => `\`${c}\``).join(', ')}
- Optional columns:
    - ${OPTIONAL_UPLOAD_COLUMNS.map((c) => `\`${c}\``).join(', ')}
`;

function UploadPoolingDialogContent({ stateSubject }: { stateSubject: BehaviorSubject<UploadPoolingState> }) {
    const state = useBehavior(stateSubject);
    const [showOptions, setShowOptions] = useState(false);

    return (
        <div className='vstack gap-1 font-body-small'>
            <ReactMarkdown className='hte2ms-add-instructions font-body-small'>{UPLOAD_MARKDOWN}</ReactMarkdown>
            <LabeledInput label='Fractions' labelWidth={LabelWidth}>
                <div className='hte2ms-inline-upload-wrapper'>
                    <SingleFileUploadV2
                        file={state.file}
                        onDrop={(files) => stateSubject.next({ ...state, file: files[0] })}
                        label='Single .csv, .xls, .xlsx'
                        extensions={['.csv', '.xls', '.xlsx']}
                        inline
                    />
                </div>
            </LabeledInput>
            <Button
                variant='link'
                className='p-0 font-body-small fw-bold text-start'
                onClick={() => setShowOptions((v) => !v)}
            >
                {showOptions ? 'Hide' : 'Show'} Options
            </Button>

            {showOptions && (
                <div className='vstack gap-1'>
                    <LabeledInput label='Fraction Labware' labelWidth={LabelWidth}>
                        <EditLabwareOption
                            value={state.options.fraction_labware}
                            setValue={(l) =>
                                stateSubject.next({
                                    ...state,
                                    options: { ...state.options, fraction_labware: l },
                                })
                            }
                        />
                    </LabeledInput>
                    <LabeledInput label='Pooled Labware' labelWidth={LabelWidth}>
                        <EditLabwareOption
                            value={state.options.pooled_labware}
                            setValue={(l) =>
                                stateSubject.next({
                                    ...state,
                                    options: { ...state.options, pooled_labware: l },
                                })
                            }
                        />
                    </LabeledInput>
                    <LabeledInput label='Max Pooled Volume' labelWidth={LabelWidth}>
                        <TextInput
                            value={formatWithUnit(state.options.max_pooled_volume, 1e6, 'mL')}
                            size='sm'
                            tryUpdateValue={(v) => parseWithUnit(v, 'mL', true)}
                            setValue={(v) =>
                                stateSubject.next({ ...state, options: { ...state.options, max_pooled_volume: v } })
                            }
                        />
                    </LabeledInput>
                    <LabeledInput
                        label='Pooled Tolerance'
                        labelWidth={LabelWidth}
                        tooltip='If pooled vial volume would be exceed by value lower than this one, ignore the exta volume.'
                    >
                        <TextInput
                            value={formatWithUnit(state.options.pooled_tolerance, 1e6, 'mL')}
                            size='sm'
                            tryUpdateValue={(v) => parseWithUnit(v, 'mL', true)}
                            setValue={(v) =>
                                stateSubject.next({ ...state, options: { ...state.options, pooled_tolerance: v } })
                            }
                        />
                    </LabeledInput>
                    <LabeledInput label='Tecan Piston Volume' labelWidth={LabelWidth}>
                        <TextInput
                            value={formatWithUnit(state.options.tecan_piston_volume, 1e6, 'mL')}
                            size='sm'
                            tryUpdateValue={(v) => parseWithUnit(v, 'mL', true)}
                            setValue={(v) =>
                                stateSubject.next({ ...state, options: { ...state.options, tecan_piston_volume: v } })
                            }
                        />
                    </LabeledInput>
                </div>
            )}
        </div>
    );
}

function RemovePoolingDialogContent() {
    return <div>Are you sure you want to remove this pooling entry?</div>;
}

function UploadPoolingBarcodesDialogContent({ stateSubject }: { stateSubject: BehaviorSubject<UploadPoolingState> }) {
    const state = useBehavior(stateSubject);
    return (
        <div className='vstack gap-2 font-body-small'>
            <InlineAlert iconTopLeft>
                This action will associate <b>empty tared vial barcodes</b> with the pooled fractions without
                registering the purified batches. Before registration, it is possible to overwrite the barcodes by
                uploading a new file.
            </InlineAlert>

            <LabeledInput
                label='Barcode List'
                labelWidth={LabelWidth}
                tooltip='Pooled fraction vial barcodes, one per line'
            >
                <div className='hte2ms-inline-upload-wrapper'>
                    <SingleFileUploadV2
                        file={state.file}
                        onDrop={(files) => stateSubject.next({ ...state, file: files[0] })}
                        label='Upload Vial Barcodes (.csv, .txt)'
                        extensions={['.csv', '.txt']}
                        inline
                    />
                </div>
            </LabeledInput>
        </div>
    );
}

function RegisterPoolingDialogContent() {
    return (
        <div>
            <p>Do you want to register purified batches for this pooling?</p>

            <InlineAlert variant='warning' iconTopLeft className='font-body-small'>
                Please make sure the correct vial barcodes and salt expression have been uploaded as this action is not
                easily reversible
            </InlineAlert>
        </div>
    );
}

function RemoveSaltDialogContent({ model }: { model: string }) {
    return (
        <div>
            Are you sure you want to remove <b>{model}</b>?
        </div>
    );
}

function UpdateSaltExpressionDialogContent({ model }: { model: boolean }) {
    return <div>Do you want to {model ? 'exclude' : 'include'} this salt?</div>;
}
