import { faCopy, faPaste, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup } from 'react-bootstrap';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Subscription, throttleTime } from 'rxjs';
import { InlineAlert } from '../../../components/common/Alert';
import { IconButton } from '../../../components/common/IconButton';
import { SimpleSelectOptionInput } from '../../../components/common/Inputs';
import useBehavior from '../../../lib/hooks/useBehavior';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { DialogService } from '../../../lib/services/dialog';
import { ToastService } from '../../../lib/services/toast';
import { arrayEqual, memoizeLatest } from '../../../lib/util/misc';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { WellLayout, WellSelection } from '../../HTE/experiment-data';
import { PlateVisual, PlateVisualLabels, PlateVisualModel } from '../../HTE/plate/PlateVisual';
import {
    columnMajorIndexToRowMajorIndex,
    forEachWellIndex,
    getWellIndexLabel,
    rowMajorIndexToColumnMajorIndex,
    wellLabelToIndex,
} from '../../HTE/plate/utils';
import { HTEDReaction, HTEDReactionIdT } from '../data-model';
import {
    DefaultPlateColoringOptions,
    getPlateColoring,
    getPlateColoringOptions,
    PlateColoringOptions,
} from '../utils/plate-coloring';
import type { HTE2MSDesignModel } from './model';

export interface PlateLayoutInfo {
    layout: WellLayout;
    reactions: HTEDReaction[];
    wellToReaction: Map<number, HTEDReaction>;
    labelToReaction: Map<string, HTEDReactionIdT>;
    reactionToWell: Map<HTEDReactionIdT, number>;
}

const COPY_REACTIONS_KEY = 'hte2ms-copy-reactions';

export class HTE2MSPlateLayoutModel extends ReactiveModel {
    state = {
        visual: new BehaviorSubject(new PlateVisualModel(96)),
        coloring: new BehaviorSubject<[current: string, options: PlateColoringOptions]>([
            DefaultPlateColoringOptions.options[0][0],
            DefaultPlateColoringOptions,
        ]),
    };

    get visual() {
        return this.state.visual.value;
    }

    private _getInfo = memoizeLatest(buildLayout);
    get info() {
        return this._getInfo(this.design.labware.product.layout as WellLayout, this.design.all);
    }

    get emptySelectedWellLabels() {
        const { visual } = this;
        const selection = visual.state.selection.value;
        const wells: [number, string][] = [];
        const { wellToReaction } = this.info;
        forEachWellIndex(selection, (index) => {
            if (wellToReaction.has(index)) return;
            const colMajor = rowMajorIndexToColumnMajorIndex(visual.layout, index);
            const label = getWellIndexLabel(visual.layout, index);
            wells.push([colMajor, label]);
        });
        wells.sort(([a], [b]) => a - b);
        return wells.map(([, label]) => label);
    }

    get emptySelectionSize() {
        const { visual } = this;
        const selection = visual.state.selection.value;
        const { wellToReaction } = this.info;

        let size = 0;
        forEachWellIndex(selection, (index) => {
            if (wellToReaction.has(index)) return;
            size++;
        });
        return size;
    }

    highlightReactionIds(ids: HTEDReactionIdT[]) {
        const { reactionToWell } = this.info;
        const wells: number[] = [];
        for (const id of ids) {
            const well = reactionToWell.get(id);
            if (well !== undefined) wells.push(well);
        }
        this.state.visual.value.state.highlight.next(wells);
    }

    private copiedReactions: HTEDReaction[] | undefined = undefined;
    copySelectedReactions = () => {
        const { wellToReaction } = this.info;
        const reactions: HTEDReaction[] = [];
        forEachWellIndex(
            this.visual.selection,
            (index) => {
                if (!wellToReaction.has(index)) return;
                reactions.push(wellToReaction.get(index)!);
            },
            { direction: 'column' }
        );

        if (!reactions.length) {
            this.copiedReactions = undefined;
            try {
                localStorage.removeItem(COPY_REACTIONS_KEY);
            } catch {
                // ignore
            }
            return ToastService.warning('No selected reactions to copy');
        }

        try {
            // Try to use local storage to support cross-ELN copy-paste
            localStorage.setItem(COPY_REACTIONS_KEY, JSON.stringify(reactions));
            this.copiedReactions = undefined;
            ToastService.success('Reactions copied');
        } catch {
            // fallback to making this available only in the same tab
            this.copiedReactions = reactions;
            ToastService.success('Reactions copied (this tab only)');
        }
    };

    confirmPasteReactions = () => {
        let reactions = this.copiedReactions;
        try {
            const stored = localStorage.getItem(COPY_REACTIONS_KEY);
            if (stored) {
                reactions = JSON.parse(stored);
            }
        } catch {
            // ignore
        }

        if (!reactions?.length) {
            return ToastService.warning('No reactions to paste');
        }

        DialogService.open({
            type: 'generic',
            title: `Paste Reactions`,
            confirmButtonContent: 'Apply',
            model: {
                reactionCount: reactions.length,
                selectionCount: this.visual.selection.reduce((acc: number, v) => acc + (v ? 1 : 0), 0),
            },
            wrapOk: true,
            content: PasteReactionsDialogContent,
            onOk: () => this.applyPasteReactions(reactions!),
        });
    };

    private async applyPasteReactions(reactions: HTEDReaction[]) {
        let offset = 0;

        const { wellToReaction } = this.info;
        const updates: [HTEDReaction | undefined, HTEDReaction][] = [];
        forEachWellIndex(
            this.visual.selection,
            (index) => {
                const next: HTEDReaction = {
                    ...reactions[offset],
                    id: undefined as any, // modifyReactions will assign a new unique id
                    template: {
                        ...reactions[offset].template,
                        instructions: reactions[offset].template.instructions.map((instr) => ({
                            ...instr,
                            id: undefined as any, // modifyReactions will assign a new unique id
                        })),
                    },
                    well_label: getWellIndexLabel(this.visual.layout, index),
                };
                offset = (offset + 1) % reactions.length;
                updates.push([wellToReaction.get(index), next]);
            },
            { direction: 'column' }
        );

        await this.design.modifyReactions(updates);
        ToastService.success('Reactions pasted');
    }

    private syncLayout() {
        const { info } = this;
        const { layout, reactionToWell } = info;

        // Sync visual layout
        if (this.visual.layout !== layout) {
            this.state.visual.next(new PlateVisualModel(layout));
        }
        const { visual } = this;

        // Validation
        const labels: PlateVisualLabels = new Array(layout).fill(null);
        const { validations } = this.design;
        const labelColor = 'rgba(255, 255, 255, 0.75)';

        for (const [rid, idx] of Array.from(reactionToWell.entries())) {
            const val = validations.get(rid)?.[0];
            if (!val) continue;

            if (val.some((v) => v[0] === 'danger')) labels[idx] = { color: labelColor, text: '!' };
            else if (val.some((v) => v[0] === 'warning')) labels[idx] = { color: labelColor, text: '?' };
            else labels[idx] = { color: labelColor, text: '✓' };
        }

        // Update plate visual
        visual.state.labels.next(labels);

        const coloring = getPlateColoringOptions(this.design.all);
        const currentColoring = this.state.coloring.value;
        this.state.coloring.next([
            currentColoring[0] in coloring.definitions ? currentColoring[0] : coloring.options[0][0],
            coloring,
        ]);

        this.syncTableSelection(this.design.table.getSelectedRowIndices());
    }

    private syncColoring() {
        const currentColoring = this.state.coloring.value;
        const option = currentColoring[1].definitions[currentColoring[0]];
        const colors = getPlateColoring({
            reactions: this.design.all,
            layout: this.visual.layout,
            reactionToWell: this.info.reactionToWell,
            option,
        });
        this.visual.state.colors.next(colors);
    }

    private syncTableSelection(selection: number[]) {
        const { info } = this;
        const { layout, reactions, reactionToWell, wellToReaction } = info;
        const { visual } = this;

        const currentSelection = visual.state.selection.value;
        const wellSelection: WellSelection = new Array(layout).fill(0);
        for (const sI of selection) {
            const r = reactions[sI];
            if (reactionToWell.has(r.id)) {
                wellSelection[reactionToWell.get(r.id)!] = 1;
            }
        }
        for (let i = 0; i < layout; i++) {
            if (currentSelection[i] && !wellToReaction.has(i)) {
                wellSelection[i] = 1;
            }
        }

        if (!arrayEqual(currentSelection, wellSelection)) {
            visual.state.selection.next(wellSelection);
        }
    }

    private syncLayoutSelection = (selection: WellSelection) => {
        const { wellToReaction } = this.info;
        const ids: HTEDReactionIdT[] = [];
        forEachWellIndex(selection, (index) => {
            if (!wellToReaction.has(index)) return;
            ids.push(wellToReaction.get(index)!.id);
        });
        this.design.selectReactionIds(ids);
    };

    private selectionSub: Subscription | undefined = undefined;
    mount() {
        const layout = combineLatest([
            this.design.state.labware.pipe(
                map((l) => l.product.layout),
                distinctUntilChanged()
            ),
            this.design.reactionsChanged,
        ]).pipe(throttleTime(33, undefined, { leading: true, trailing: true }));

        this.subscribe(layout, () => this.syncLayout());
        this.subscribe(this.design.table.events.selectionChanged, () =>
            this.syncTableSelection(this.design.table.getSelectedRowIndices())
        );
        this.subscribe(this.state.visual, (visual) => {
            this.selectionSub?.unsubscribe();
            this.selectionSub = visual.state.selection
                .pipe(throttleTime(16, undefined, { leading: true, trailing: true }))
                .subscribe(this.syncLayoutSelection);
        });

        this.subscribe(this.state.coloring, () => this.syncColoring());
    }

    dispose(): void {
        super.dispose();
        this.selectionSub?.unsubscribe();
    }

    constructor(public design: HTE2MSDesignModel) {
        super();
    }
}

function buildLayout(layout: WellLayout, reactions: HTEDReaction[]): PlateLayoutInfo {
    const wellToReaction = new Map<number, HTEDReaction>();

    for (const reaction of reactions) {
        if (!reaction.well_label) continue;

        const idx = wellLabelToIndex(layout, reaction.well_label);
        if (idx < 0 || idx >= layout) continue;

        wellToReaction.set(idx, reaction);
    }

    for (const reaction of reactions) {
        if (reaction.well_label) continue;

        for (let i = 0; i < layout; i++) {
            const idx = columnMajorIndexToRowMajorIndex(layout, i);
            if (wellToReaction.has(idx)) continue;
            wellToReaction.set(idx, reaction);
            break;
        }
    }

    const reactionToWell = new Map<string, number>(
        Array.from(wellToReaction.entries()).map(([well, reaction]) => [reaction.id, well])
    );

    const labelToReaction = new Map<string, HTEDReactionIdT>(
        Array.from(wellToReaction.entries()).map(([well, reaction]) => [getWellIndexLabel(layout, well), reaction.id])
    );

    return { layout, reactions, wellToReaction, reactionToWell, labelToReaction };
}

export function PlateLayoutUI({ model }: { model: HTE2MSDesignModel }) {
    useMountedModel(model.layout);
    return (
        <div className='d-flex flex-column w-100 h-100'>
            <div className='hte2ms-reactions-header font-body-small px-2 hstack'>
                Layout
                <div className='m-auto' />
                <AddReactionsButton model={model.layout} />
            </div>
            <div className='hstack gap-1 mt-2 px-2'>
                <ButtonGroup>
                    <Button
                        size='sm'
                        variant='outline-secondary'
                        className='py-0 px-1'
                        onClick={() => model.layout.visual.checkerizeSelection(0)}
                    >
                        Q1
                    </Button>
                    <Button
                        size='sm'
                        variant='outline-secondary'
                        className='py-0 px-1'
                        onClick={() => model.layout.visual.checkerizeSelection(1)}
                    >
                        2
                    </Button>
                    <Button
                        size='sm'
                        variant='outline-secondary'
                        className='py-0 px-1'
                        onClick={() => model.layout.visual.checkerizeSelection(2)}
                    >
                        3
                    </Button>
                    <Button
                        size='sm'
                        variant='outline-secondary'
                        className='py-0 px-1'
                        onClick={() => model.layout.visual.checkerizeSelection(3)}
                    >
                        4
                    </Button>
                    <Button
                        size='sm'
                        variant='outline-secondary'
                        className='py-0 px-1'
                        onClick={() => model.layout.visual.uncheckerizeSelection()}
                    >
                        Full
                    </Button>
                </ButtonGroup>
                <ButtonGroup>
                    <Button
                        size='sm'
                        variant='outline-secondary'
                        className='py-0 px-1'
                        onClick={model.layout.copySelectedReactions}
                        title='Copy'
                    >
                        <FontAwesomeIcon icon={faCopy} size='xs' fixedWidth />
                    </Button>
                    <Button
                        size='sm'
                        variant='outline-secondary'
                        className='py-0 px-1'
                        onClick={model.layout.confirmPasteReactions}
                        title='Paste'
                    >
                        <FontAwesomeIcon icon={faPaste} size='xs' fixedWidth />
                    </Button>
                </ButtonGroup>
                <div className='m-auto' />
                <div className='fw-bold font-body-small text-secondary'>Color by:</div>
                <div style={{ width: 100 }}>
                    <SelectColoring model={model.layout} />
                </div>
            </div>
            <div className='flex-grow-1 position-relative vstack h-100'>
                <div className='position-relative pt-1 pb-2 pe-2 d-flex align-items-center h-100'>
                    <div className='position-relative h-100 w-100' style={{ maxHeight: 320 }}>
                        <PlateLayout model={model.layout} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function SelectColoring({ model }: { model: HTE2MSPlateLayoutModel }) {
    const coloring = useBehavior(model.state.coloring);

    return (
        <SimpleSelectOptionInput
            options={coloring[1].options}
            value={coloring[0]}
            setValue={(v) => model.state.coloring.next([v, coloring[1]])}
            size='sm'
        />
    );
}

function PlateLayout({ model }: { model: HTE2MSPlateLayoutModel }) {
    const visual = useBehavior(model.state.visual);
    return <PlateVisual model={visual} />;
}

function AddReactionsButton({ model }: { model: HTE2MSPlateLayoutModel }) {
    const visual = useBehavior(model.state.visual);
    useBehavior(visual.state.selection);
    const { emptySelectionSize } = model;
    return (
        <IconButton
            icon={faPlus}
            disabled={emptySelectionSize === 0}
            size='sm'
            onClick={() => model.design.addEmptyReactions({ fromLayout: true })}
            className='p-0 font-body-xsmall'
        >
            Add Reactions
        </IconButton>
    );
}

function PasteReactionsDialogContent({ model }: { model: { reactionCount: number; selectionCount: number } }) {
    return (
        <div className='font-body-small'>
            <p>
                Do you want to paste {model.reactionCount} reaction{model.reactionCount === 1 ? '' : 's'} to{' '}
                {model.selectionCount} selected well{model.selectionCount === 1 ? '' : 's'}?
            </p>

            <InlineAlert>
                Reactions will be pasted in column-major order and will overwrite any existing ones in the selected
                wells.
                <br />
                Reactions will automatically repeat if excess wells are selected.
            </InlineAlert>
        </div>
    );
}
