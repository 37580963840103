import { faArrowsToDot, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    BubbleController,
    CategoryScale,
    Chart,
    ChartConfiguration,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    ScatterController,
    TimeScale,
    Tooltip,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import { ReactNode, useEffect, useRef } from 'react';
import { Button, OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';
import useBehavior from '../../lib/hooks/useBehavior';
import { PLOT_COLORS } from '../../lib/util/plot';
import { IconButton } from '../common/IconButton';
import { iiLasso } from '../common/Icons';
import { ChartModel } from './model';

Chart.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    BubbleController,
    ScatterController,
    LineController,
    annotationPlugin
);
Chart.defaults.backgroundColor = PLOT_COLORS.pink;

export function ChartCanvas({ model, config }: { model: ChartModel; config: ChartConfiguration }) {
    const chartRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (chartRef.current) {
            model.mount(chartRef.current.getContext('2d'), config);
        }

        return () => model.dispose();
    }, []);

    return <canvas className='h-100' ref={chartRef} onDoubleClick={model.resetZoom} />;
}

type ChartAction = 'resetZoom' | 'export' | 'lasso';

export function ResizableChart({
    model,
    config,
    customTooltip,
    actions = ['resetZoom', 'export'],
    additionalActions,
}: {
    model: ChartModel;
    config: ChartConfiguration;
    customTooltip?: ReactNode;
    actions?: ChartAction[];
    additionalActions?: ReactNode;
}) {
    return (
        <div className='h-100 d-flex flex-column'>
            {actions.length > 0 && (
                <ChartActions model={model} actions={actions} additionalActions={additionalActions} />
            )}
            <div className='flex-grow-1 flex-shrink-1' style={{ minHeight: 0 }}>
                <ResizeWrapper model={model}>
                    <ChartCanvas model={model} config={config} />
                    {customTooltip}
                </ResizeWrapper>
            </div>
        </div>
    );
}

function ChartActions({
    model,
    actions,
    additionalActions,
}: {
    model: ChartModel;
    actions: ChartAction[];
    additionalActions?: ReactNode;
}) {
    const initialized = useBehavior(model.state.initialized);

    if (actions.length === 0) return null;

    return (
        <div>
            {actions.includes('resetZoom') && (
                <IconButton
                    onClick={model.resetZoom}
                    title='Reset Zoom'
                    icon={faArrowsToDot}
                    size='sm'
                    disabled={!initialized}
                    className='text-secondary'
                />
            )}
            {actions.includes('export') && (
                <IconButton
                    onClick={model.downloadImage}
                    title='Download as Image'
                    icon={faDownload}
                    size='sm'
                    disabled={!initialized}
                    className='text-secondary'
                />
            )}
            {actions.includes('lasso') && (
                <OverlayTrigger
                    placement='bottom'
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => (
                        <BootstrapTooltip id='lasso-tooltip' {...props}>
                            Shift+drag to select points
                        </BootstrapTooltip>
                    )}
                >
                    <Button variant='link'>
                        <FontAwesomeIcon icon={iiLasso} size='sm' fixedWidth className='text-secondary' />
                    </Button>
                </OverlayTrigger>
            )}
            {additionalActions}
        </div>
    );
}

function ResizeWrapper({ model, children }: { model: ChartModel; children: ReactNode }) {
    const wrapperRef = useRef<Element>(null);

    useEffect(() => {
        let resizeObserver: ResizeObserver | undefined;
        const target = wrapperRef.current;
        if (target) {
            model.resize();
            resizeObserver = new ResizeObserver(() => {
                model.resize();
            });

            resizeObserver.observe(target);
        }

        return () => {
            if (resizeObserver && target) resizeObserver.unobserve(target);
        };
    }, []);

    return (
        <div className='position-relative w-100 h-100' ref={wrapperRef as any}>
            {children}
        </div>
    );
}
