import { ChangeEvent, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { faCopy, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChemDraw } from '../components/ChemDraw';
import { PageTemplate } from '../components/Layout/Layout';
import { ChemDrawService } from '../lib/services/chemdraw';
import { ClipboardService } from '../lib/services/clipboard';
import { reportErrorAsToast } from '../lib/util/errors';
import { downloadFile } from '../lib/util/downloadFile';

export function ChemDrawPage() {
    const [smiles, setSmiles] = useState('');
    const [exported, setExported] = useState('');
    const [img, setImg] = useState('');

    async function loadSMILES(smi: string) {
        setSmiles(smi);
        setExported('');
        setImg('');
        if (!smi) {
            await ChemDrawService.clear();
            return;
        }
        try {
            await ChemDrawService.loadSMILES(smi);
        } catch (err) {
            reportErrorAsToast('Error loading SMILES', err);
        }
    }

    async function exportType(type: 'smiles' | 'mol' | 'mol-v3000') {
        try {
            const result = await ChemDrawService.export(type);
            setExported(result);
            setImg('');
        } catch (err) {
            reportErrorAsToast(`Error exporting ${type}:`, err);
        }
    }

    async function exportImage() {
        try {
            const result = await ChemDrawService.getImage();
            setExported('');
            setImg(result);
        } catch (err) {
            reportErrorAsToast('Error generating image:', err);
        }
    }

    function copyExported() {
        ClipboardService.copyText(exported, 'Copy SMILES');
    }

    return (
        <PageTemplate title='ChemDraw Test Page'>
            <div className='d-flex h-100'>
                <div className='h-100 overflow-auto p-4'>
                    <div className='m-auto' style={{ width: 800, height: 480 }}>
                        <ChemDraw />
                    </div>
                </div>
                <div className='position-relative py-4 pe-4 h-100 flex-grow-1 d-flex flex-column'>
                    <div className='mb-2'>
                        <Form.Control
                            type='text'
                            placeholder='Enter SMILES'
                            name='load-smiles-chemdraw'
                            value={smiles}
                            onChange={(evt: ChangeEvent) =>
                                loadSMILES((evt as ChangeEvent<HTMLInputElement>).target.value)
                            }
                        />
                    </div>
                    <div>
                        <Button className='me-2' onClick={() => exportType('smiles')}>
                            Get SMILES
                        </Button>
                        <Button className='me-2' onClick={() => exportImage()}>
                            Get Image
                        </Button>
                        <Button className='me-2' onClick={() => exportType('mol')}>
                            Get MOL
                        </Button>
                        <Button onClick={() => exportType('mol-v3000')}>Get MOLV3000</Button>
                    </div>
                    {exported && (
                        <div className='mt-2'>
                            <Button
                                variant='outline-secondary'
                                title='Click to copy'
                                onClick={() => copyExported()}
                                className='mb-2'
                            >
                                <span className='me-1'>Click to copy</span>
                                <FontAwesomeIcon icon={faCopy} fixedWidth />
                            </Button>
                        </div>
                    )}
                    {exported && (
                        <div className='mt-4 flex-grow-1 overflow-auto' style={{ maxWidth: 400 }}>
                            <pre>{exported}</pre>
                        </div>
                    )}
                    {img && (
                        <div className='mt-4 flex-grow-1 overflow-auto'>
                            <img src={img} alt='molecule' />
                            <div className='mt-2'>
                                <p>
                                    Right click on the image to save or copy, or
                                    <Button
                                        variant='outline-primary'
                                        size='sm'
                                        title='Download image'
                                        onClick={() =>
                                            downloadFile({
                                                url: img,
                                                filename: `insight-chemdraw-structure-${Date.now()}.png`,
                                            })
                                        }
                                        className='mx-1'
                                    >
                                        <span className='me-1'>Download</span>
                                        <FontAwesomeIcon icon={faDownload} fixedWidth />
                                    </Button>
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </PageTemplate>
    );
}
