// source: Envision
// https://github.com/EntosAI/envision/blob/master/envision/src/services/toast.ts
import React from 'react';
import { BehaviorSubject } from 'rxjs';

// Default toast TTL 15 seconds
const DEFAULT_TIMEOUT_MS = 15000;

export interface ToastOptions {
    id?: string;
    type: 'success' | 'info' | 'danger' | 'warning' | 'dark' | 'light' | 'primary';
    message: React.ReactNode;
    timeoutMs?: number | false;
}

export interface ToastMessage extends ToastOptions {
    id: string;
    timeoutHandle?: ReturnType<typeof setTimeout>;
}

class _ToastService {
    private currentId = 0;

    readonly messages = new BehaviorSubject<ToastMessage[]>([]);

    remove(id: string) {
        const idx = this.messages.value.findIndex((m) => m.id === id);
        if (idx < 0) return;

        const messages = [...this.messages.value];
        const msg = messages[idx];
        if (msg.timeoutHandle !== undefined) {
            clearTimeout(msg.timeoutHandle);
        }
        messages.splice(idx, 1);
        this.messages.next(messages);
    }

    show(message: ToastOptions): ToastMessage {
        if (message.id) this.remove(message.id);

        const id = message.id ?? `${this.currentId++}`;
        const msg: ToastMessage = {
            ...message,
            id,
            timeoutHandle:
                message.timeoutMs === false
                    ? undefined
                    : setTimeout(() => this.remove(id), message.timeoutMs ?? DEFAULT_TIMEOUT_MS),
        };
        this.messages.next([msg, ...this.messages.value]);
        return msg;
    }

    success(message: string, options?: { timeoutMs?: number | false; id?: string }) {
        this.show({
            type: 'success',
            message,
            timeoutMs: options?.timeoutMs ?? 3500,
            id: options?.id,
        });
    }

    error(message: string, options?: { timeoutMs?: number | false; id?: string }) {
        this.show({
            type: 'danger',
            message,
            timeoutMs: options?.timeoutMs ?? false,
            id: options?.id,
        });
    }

    warning(message: string, options?: { timeoutMs?: number | false; id?: string }) {
        this.show({
            type: 'warning',
            message,
            timeoutMs: options?.timeoutMs ?? 3500,
            id: options?.id,
        });
    }

    info(message: string, options?: { timeoutMs?: number | false; id?: string }) {
        this.show({
            type: 'info',
            message,
            timeoutMs: options?.timeoutMs ?? 3500,
            id: options?.id,
        });
    }

    // eslint-disable-next-line
    propagateErrorToToast =
        <T extends Array<any>, U>(fn: (...args: T) => Promise<U>, rethrow = true) =>
        async (...args: T) => {
            try {
                return await fn(...args);
            } catch (err) {
                this.show({
                    type: 'danger',
                    message: `${err}`,
                    timeoutMs: 10000,
                });
                // Re-throw in case we need downstream error logic
                if (rethrow) throw err;
                else console.error(err);
            }
        };

    // constructor() {
    //     for (let i = 0; i < 5; i++) {
    //         this.show({ type: i % 2 ? 'info' : 'info', message: `Test message ${i}.`, timeoutMs: 50000 });
    //     }
    // }
}

export const ToastService = new _ToastService();
