import { Dispatch, SetStateAction } from 'react';
import { Column, useTable } from 'react-table';
import { Button } from 'react-bootstrap';
import { DatasetPermission } from '../../api/data';

interface IProps {
    data: DatasetPermission[];
    columns: Array<Column<DatasetPermission>>;
    setData: Dispatch<SetStateAction<DatasetPermission[]>>;
}

// TODO: remove once/if this uses Table class
function sortTypes(row1: any, row2: any, columnName: string) {
    const _r1 = row1.values[columnName];
    const _r2 = row2.values[columnName];
    const r1 = typeof _r1 === 'string' ? _r1.toLowerCase() : _r1;
    const r2 = typeof _r2 === 'string' ? _r2.toLowerCase() : _r2;
    if (r1 < r2) return -1;
    if (r1 > r2) return 1;
    return 0;
}

export default function PermissionsTable({ data, columns, setData }: IProps) {
    const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable({
        columns,
        data,
        sortTypes: {
            // TODO: remove once/if this uses Table class
            alphanumeric: (row1, row2, columnName) => sortTypes(row1, row2, columnName),
        },
    });
    const removeTableRow = (rowId: string) => {
        const array = [...data];
        array.splice(+rowId, 1);
        setData(array);
    };
    return (
        <table {...getTableProps()} className='entos-table w-100 flex-grow-1 bg-transparent mt-2'>
            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} className='entos-table-header'>
                            <Button variant='outline-primary' onClick={() => removeTableRow(row.id)}>
                                X
                            </Button>
                            {row.cells.map((cell) => (
                                <td className='p-1' {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                </td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
