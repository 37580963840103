import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { ChemDrawService } from '../../lib/services/chemdraw';
import { ChemDrawModalService } from '../../lib/services/chemdraw-modal';
import { iiChemDrawLogo } from '../common/Icons';

export function ChemDraw({ initialSMILES }: { initialSMILES?: string }) {
    const [state, setState] = useState<[loading: boolean, error: string | undefined]>([true, undefined]);
    const target = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let mounted = true;
        setState([true, undefined]);
        const chemdraw = ChemDrawService.mount(target.current!, { initialSMILES });
        chemdraw.instance
            .then(() => mounted && setState([false, undefined]))
            .catch((err) => mounted && setState([false, `${err}`]));

        return () => {
            mounted = false;
            chemdraw.dispose();
        };
    }, []);

    return (
        <div ref={target} className='chemdraw-host'>
            {!!(state[0] || state[1]) && (
                <div className='w-100 h-100 d-flex align-items-center justify-content-center chemdraw-loading'>
                    {state[0] && (
                        <>
                            <Spinner className='me-4' animation='border' /> Loading ChemDraw...
                        </>
                    )}
                    {state[1] && `${state[1]}`}
                </div>
            )}
        </div>
    );
}

export function ChemDrawButton({
    className,
    style,
    onDrawn,
}: {
    className?: string;
    style?: CSSProperties;
    onDrawn: (smiles: string) => void;
}) {
    return (
        <Button
            className={className}
            style={style}
            variant='link'
            onClick={() =>
                ChemDrawModalService.show({
                    onSMILESDrawn: onDrawn,
                })
            }
        >
            <FontAwesomeIcon className='pe-none' icon={iiChemDrawLogo} color='var(--bs-primary)' fixedWidth />
        </Button>
    );
}
