import { faArrowUpRightFromSquare, faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HTEFinalizeModel } from '.';
import { LabeledInput, TextInput } from '../../../components/common/Inputs';
import { ScrollBox } from '../../../components/common/ScrollBox';
import useBehavior from '../../../lib/hooks/useBehavior';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { AuthService } from '../../../lib/services/auth';
import { parseFileSystemDate } from '../../../lib/util/dates';
import { EXPERIMENT_ACKNOWLEDGEMENT } from '../../HTE/experiment-data';
import { HTEWModel } from '../model';
import { useModelAction } from '../../../lib/util/reactive-model';
import { DialogService } from '../../../lib/services/dialog';
import { AsyncButton } from '../../../components/common/AsyncButton';

export function HTEFinalizeUI({ model }: { model: HTEWModel }) {
    return <Layout model={model.finalization} />;
}

function Layout({ model }: { model: HTEFinalizeModel }) {
    useMountedModel(model);
    const canFinalize = useBehavior(model.state.canFinalize);

    if (canFinalize.kind === 'not-ready') {
        return (
            <WithHeader model={model}>
                <span className='text-secondary fst-italic pb-2'>The experiment is not ready for finalization.</span>
            </WithHeader>
        );
    }

    if (canFinalize.kind === 'bad-user') {
        return (
            <WithHeader model={model}>
                <span className='text-secondary fst-italic pb-2'>
                    Only the user who created the experiment ({model.model.foundryInfo?.created_by ?? '?'}) can finalize
                    it.
                </span>
            </WithHeader>
        );
    }

    return (
        <ScrollBox className='pb-2'>
            <WithHeader model={model}>
                <Controls model={model} />
            </WithHeader>
        </ScrollBox>
    );
}

function WithHeader({ children, model }: { children: ReactNode; model: HTEFinalizeModel }) {
    return (
        <div className='vstack gap-2 mx-auto mt-4' style={{ width: 800 }}>
            <h2>Finalize Experiment</h2>
            <Headers model={model} />
            {children}
        </div>
    );
}

function Headers({ model }: { model: HTEFinalizeModel }) {
    const dataSource = useBehavior(model.model.state.dataSource);

    return (
        <>
            <span className='text-secondary fst-italic pb-2'>
                Upload a crude plate barcode to finalize this experiment and proceed to signing. Only experiment
                settings, procedure, and observations will be editable after finalization.
            </span>

            {dataSource.kind === 'foundry' && !!dataSource.info.executed_on && (
                <Alert variant='info'>
                    <div className='hstack gap-2'>
                        <FontAwesomeIcon icon={faInfoCircle} className='mx-1' />
                        <span>
                            You can review the <b>Product and Source Plate Maps</b>, <b>Reactions</b>, and{' '}
                            <b>Observations</b> in the{' '}
                            <Link
                                to={`/hte/${dataSource.info.id}/details`}
                                className='fw-bold'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                Details
                                <FontAwesomeIcon
                                    size='sm'
                                    className='ms-1 text-primary'
                                    icon={faArrowUpRightFromSquare}
                                />
                            </Link>{' '}
                            page for this experiment
                        </span>
                    </div>
                </Alert>
            )}
        </>
    );
}

const LabelWidth = 200;
function Controls({ model }: { model: HTEFinalizeModel }) {
    const account = useBehavior(AuthService.account);
    const dataSource = useBehavior(model.model.state.dataSource);
    const data = useBehavior(model.state.data);
    const canFinalize = useBehavior(model.state.canFinalize);

    if (dataSource.kind !== 'foundry') return <div className='mt-4'>Invalid state</div>;

    const alreadyFinalized = canFinalize.kind === 'already-finalized';

    return (
        <>
            <Alert variant='info'>
                <div className='hstack gap-2'>
                    <FontAwesomeIcon icon={faInfoCircle} className='mx-1' />
                    <span>
                        <b>Purified Product Plate Barcode</b> can be assigned{' '}
                        <Link
                            to={`/hte/${dataSource.info.id}/assign-purified-plate`}
                            className='fw-bold'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Here
                            <FontAwesomeIcon size='sm' className='ms-1 text-primary' icon={faArrowUpRightFromSquare} />
                        </Link>
                    </span>
                </div>
            </Alert>

            {alreadyFinalized && (
                <Alert variant='success'>
                    <div className='hstack gap-2'>
                        <FontAwesomeIcon icon={faCheck} className='mx-1' />
                        <span>
                            The experiment has been finalized on{' '}
                            {parseFileSystemDate(dataSource.info.locked_on!).toLocaleString()}
                        </span>
                    </div>
                </Alert>
            )}

            <LabeledInput label='Logged in User' labelWidth={LabelWidth}>
                {account?.username}
            </LabeledInput>
            <LabeledInput label='Executed On' labelWidth={LabelWidth}>
                {dataSource.info.executed_on
                    ? parseFileSystemDate(dataSource.info.executed_on).toLocaleDateString()
                    : 'n/a'}
            </LabeledInput>
            <LabeledInput label='Full Name' labelWidth={LabelWidth}>
                {alreadyFinalized && data.full_name}
                {!alreadyFinalized && (
                    <TextInput
                        value={data.full_name}
                        placeholder={account?.name ?? 'Enter your full name to finalize the experiment'}
                        setValue={(v) => model.state.data.next({ ...data, full_name: v.trim() })}
                    />
                )}
            </LabeledInput>
            <LabeledInput label='Comment' labelWidth={LabelWidth} labelAlign='flex-start'>
                {alreadyFinalized && (data.comment || <span className='text-seconday'>n/a</span>)}
                {!alreadyFinalized && (
                    <TextInput
                        textarea
                        value={data.comment ?? ''}
                        placeholder='Enter any comments about the experiment'
                        setValue={(v) => model.state.data.next({ ...data, comment: v.trim() })}
                    />
                )}
            </LabeledInput>
            <LabeledInput label='' labelWidth={LabelWidth}>
                <Form.Check
                    id='hte-signature-finalize'
                    label={EXPERIMENT_ACKNOWLEDGEMENT}
                    type='checkbox'
                    checked={data.acknowledged_policy}
                    disabled={alreadyFinalized}
                    onChange={(e) => model.state.data.next({ ...data, acknowledged_policy: e.target.checked })}
                />
            </LabeledInput>
            <div className='mb-4 text-end'>
                <FinalizeButton model={model.model} />
            </div>
        </>
    );
}

function FinalizeButton({ model }: { model: HTEWModel }) {
    const state = useModelAction(model.actions.submit);
    const canFinalize = useBehavior(model.finalization.state.canFinalize);

    const submit = () => {
        DialogService.open({
            type: 'generic',
            wrapOk: true,
            onOk: () => model.actions.submit.run(model.finalization.finalize(), { onError: 'eat', rethrowError: true }),
            title: 'Finalize Experiment',
            confirmButtonContent: 'Submit',
            content: FinalizeContents,
        });
    };

    return (
        <AsyncButton
            onClick={submit}
            state={{ isLoading: state.kind === 'loading' }}
            variant='primary'
            icon={faCheck}
            disabled={canFinalize.kind !== 'yes'}
        >
            Finalize
        </AsyncButton>
    );
}

function FinalizeContents() {
    return (
        <>
            <p>Do you want to finalize the experiment?</p>
            <p className='text-warning'>
                This will lock the experiment and no more edits to it will be possible except via amendments.
            </p>
        </>
    );
}
