import saveAs from 'file-saver';
import log from 'loglevel';
import { BehaviorSubject } from 'rxjs';
import { isCurveMeasurement } from '../../../lib/assays/util';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { WorkflowComputation } from '../../../lib/models/computation-data';
import { AuthService } from '../../../lib/services/auth';
import { EcosystemService } from '../../../lib/services/ecosystem';
import { isNotAuthorizedError, reportErrorAsToast } from '../../../lib/util/errors';
import { CompoundAPI, Batch } from '../../Compounds/compound-api';
import { AssayAPI, AssayDataPoints, StandardUploadResult } from '../assay-api';
import { AssayCurveQCModel } from './assay-curve-qc-model';
import { AssayCurveReviewModel } from './assay-curve-review-model';
import { AssayNonCurveReviewModel } from './assay-noncurve-review-model';
import { AssayValueCreateBayesian, BayesAPI, BayesWorkflowError, ReviewData } from './bayes-api';

type AssayUploadStep = 'unstarted' | 'curve-qc' | 'curve-review' | 'noncurve-review' | 'bayes';

type AssayUploadModal = 'upload' | 'bayes';

export const vendors = ['Pharmaron', 'RBC', 'Iambic Biology', 'Iambic JSON', 'Iambic Analytical', 'Beacon'] as const;
export type Vendor = (typeof vendors)[number];

const VENDOR_OPTIONS = {
    Pharmaron: {
        label: 'Pharmaron - please enter one Excel file containing summary and curve information.',
        extensions: ['.xls', '.xlsx'],
        numFiles: [1, 1],
    },
    RBC: {
        label: 'RBC - please enter two CSV files containing summary and curve information, and one source Excel file.',
        extensions: ['.csv', '.xls', '.xlsx'],
        numFiles: [3, 3],
    },
    'Iambic Biology': {
        label: 'In-house biology measurements - please enter 1 CLARIOstar luminescence .xlsx or .csv file and, if not run via ARPs upload a platemap .xml or .csv file.',
        extensions: ['.xml', '.xlsx', '.csv'],
        numFiles: [1, 10],
    },
    'Iambic JSON': {
        label: 'Iambic JSON - please enter one JSON file',
        extensions: ['.json'],
        numFiles: [1, 1],
    },
    'Iambic Analytical': {
        label: 'Iambic Analytical - parsing and review of metabolic stability. Please upload 1 .csv file, 1 .txt file, or 1 .xlsx file.',
        extensions: ['.csv', '.txt', '.xlsx'],
        numFiles: [1, 1],
    },
    Beacon: {
        label: 'Beacon - please upload one .csv and at most one Excel file.',
        extensions: ['.csv', '.xls', '.xlsx'],
        numFiles: [1, 2],
    },
};

interface VendorOption {
    value: Vendor;
    label: Vendor;
}

const FREQUENT_COMPUTATION_INTERVAL = 5000;
const INFREQUENT_COMPUTATION_INTERVAL = 30000;

export class AssayUploadModel extends ReactiveModel {
    public reviewData: ReviewData | undefined;
    private computationIntervalId: NodeJS.Timer | undefined; // eslint-disable-line

    public readonly options: VendorOption[] = vendors.map((v) => ({
        value: v,
        label: v,
    }));

    state = {
        step: new BehaviorSubject<AssayUploadStep>('unstarted'),
        modal: new BehaviorSubject<AssayUploadModal | null>(null),
        status: new BehaviorSubject<{ isLoading: boolean; error: any }>({
            isLoading: false,
            error: undefined,
        }),
        upload: new BehaviorSubject<{ vendorChoice: VendorOption | null; performQC: boolean }>({
            vendorChoice: null,
            performQC: true,
        }),
        files: new BehaviorSubject<File[]>([]),
        canUpload: new BehaviorSubject<boolean>(false),
        isCurveMeasurement: new BehaviorSubject<boolean>(false),
        newBayes: new BehaviorSubject<boolean>(false),
        computations: new BehaviorSubject<WorkflowComputation[]>([]),
        selectedComputation: new BehaviorSubject<WorkflowComputation | null>(null),
    };

    qcCurveModel: AssayCurveQCModel | undefined;
    curveReviewModel: AssayCurveReviewModel | undefined;
    nonCurveReviewModel: AssayNonCurveReviewModel | undefined;

    private getFilename() {
        if (this.qcCurveModel) return this.qcCurveModel.assays.map((a) => a.id).join('+');
        if (this.nonCurveReviewModel) return this.nonCurveReviewModel.data.uploadData.map((d) => d.id).join('+');
        return 'Unknown';
    }

    getJSON = async (): Promise<AssayDataPoints[] | undefined> => {
        if (!this.reviewData) return;
        const dataPoints = this.qcCurveModel?.dataPoints ?? this.nonCurveReviewModel?.data.uploadData;

        if (!dataPoints) {
            reportErrorAsToast('Could not generate JSON file', 'Nothing to download.');
            return;
        }

        try {
            const assayIds: number[] = [];
            for (const d of dataPoints) {
                for (let i = 0; i < d.values.length; i++) assayIds.push(d.info.id);
            }
            const finalizedValues = await AssayAPI.finalizeUpload({
                assay_values: dataPoints.flatMap((d) => d.values),
            });
            const data = dataPoints.map((d) => ({
                info: d.info,
                values: finalizedValues.filter((_, idx) => assayIds[idx] === d.info.id),
                foundry_env: EcosystemService.environment.value?.name,
            }));

            for (const d of data) {
                delete d.info.shorthand;
                delete d.info.property_shorthand;
            }

            return data;
        } catch (err) {
            reportErrorAsToast('Could not generate JSON file', err);
            return [];
        }
    };

    saveJSON = async () => {
        try {
            const data = await this.getJSON();
            // NOTE: for now, only saving JSON if there is a single assay
            // multi-assay JSON formatting not supported
            if (!data || data.length !== 1) return;
            const json = JSON.stringify(data[0], null, 2);
            const blob = new Blob([json], { type: 'application/json' });
            const shorthand = this.getFilename();
            saveAs(blob, `${shorthand}-upload-${Date.now()}.json`);
        } catch (err) {
            reportErrorAsToast('Could not generate JSON file', err);
        }
    };

    getCSV = async () => {
        // NOTE: not finalizing the data here like we do for downloading JSON files
        // as this CSV should look just like the table the user is viewing and
        // will not be used for later upload.
        if (!this.reviewData) return;
        const table = this.curveReviewModel?.table ?? this.nonCurveReviewModel?.table;

        if (!table) {
            reportErrorAsToast('Could not generate CSV file', 'Nothing to download.');
            return;
        }

        try {
            const csvString = table.toCsvString({ ignoreSelection: true });
            return csvString;
        } catch (err) {
            reportErrorAsToast('Could not generate CSV file', err);
        }
    };

    saveCSV = async () => {
        try {
            const csvString = await this.getCSV();
            if (!csvString) return;
            const blob = new Blob([csvString], { type: 'text/csv' });
            const shorthand = this.getFilename();
            saveAs(blob, `${shorthand}-upload-${Date.now()}.csv`);
        } catch (err) {
            reportErrorAsToast('Could not generate CSV file', err);
        }
    };

    cancel() {
        this.state.step.next('unstarted');
        this.state.modal.next(null);
        this.state.status.next({
            isLoading: false,
            error: undefined,
        });
        this.state.upload.next({
            vendorChoice: null,
            performQC: true,
        });
        this.state.files.next([]);
        this.state.canUpload.next(false);
        this.state.isCurveMeasurement.next(false);
        this.state.newBayes.next(false);
        this.state.selectedComputation.next(null);
        this.reviewData = undefined;
        this.qcCurveModel?.dispose();
        this.qcCurveModel = undefined;
        this.curveReviewModel?.dispose();
        this.curveReviewModel = undefined;
        this.nonCurveReviewModel?.dispose();
        this.nonCurveReviewModel = undefined;
    }

    getVendor() {
        const vendorChoice = this.state.upload.value.vendorChoice;
        if (!vendorChoice) return null;
        return VENDOR_OPTIONS[vendorChoice.value];
    }

    async syncComputations() {
        if (!AuthService.isAuthenticated) return;

        try {
            const computations = await BayesAPI.computations();
            this.state.computations.next(computations);
        } catch (err) {
            if (!isNotAuthorizedError(err)) {
                reportErrorAsToast('Bayes Computations', err, { id: '__bayes_computations__' });
            }
        }
    }

    async cancelComputation(id: string) {
        try {
            await BayesAPI.cancel(id);
            await this.syncComputations();
        } catch (e) {
            log.error(e);
            this.state.status.next({
                ...this.state.status.value,
                error: e,
            });
        }
    }

    async removeComputation(id: string) {
        try {
            await BayesAPI.remove(id);
            await this.syncComputations();
        } catch (e) {
            log.error(e);
            this.state.status.next({
                ...this.state.status.value,
                error: e,
            });
        }
    }

    async load() {
        if (!this.canUpload()) return;
        try {
            this.state.status.next({
                isLoading: true,
                error: undefined,
            });
            const { vendorChoice, performQC } = this.state.upload.value;
            const files = this.state.files.value;

            const result = await AssayAPI.review(files, vendorChoice!.value, performQC);

            if (result.type === 'bayes') {
                await this.initBayesianReview();
            } else if (result.type === 'standard') {
                await this.initStandardReview(result);
            }
            this.state.status.next({
                isLoading: false,
                error: undefined,
            });
        } catch (e) {
            log.error(e);
            this.state.status.next({
                isLoading: false,
                error: e,
            });
        }
    }

    async reviewBayes() {
        const selectedComputation = this.state.selectedComputation.value;
        if (!selectedComputation) return;
        try {
            const result = await BayesAPI.result(selectedComputation.id);
            result.assay.shorthand = result.assay_shorthand;
            result.assay.property_shorthand = result.assay_property_shorthand;
            await this.initReview([{ info: result.assay, values: result.values }], result.bayes_results);
            await this.beginReview();
            this.state.modal.next(null);
        } catch (e) {
            log.error(e);
            this.state.status.next({
                ...this.state.status.value,
                isLoading: false,
                error: e,
            });
        }
    }

    onUploadSuccess(navigate: (url: string) => void) {
        if (this.reviewData?.uploadData.length === 1) {
            navigate(`/assays/${this.reviewData.uploadData[0].info.id}`);
        } else {
            // NOTE: currently the assay uploading happens still at the /assays path
            // so we have to 'cancel' on successful upload to go back to list view
            this.cancel();
            navigate('/assays');
        }
    }

    private canUpload() {
        const { vendorChoice } = this.state.upload.value;
        const files = this.state.files.value;
        const { isLoading } = this.state.status.value;
        if (!vendorChoice || isLoading) return false;
        const vendorOption = VENDOR_OPTIONS[vendorChoice.value];
        if (!vendorOption) return false;
        const [minCount, maxCount] = vendorOption.numFiles;
        return files.length >= minCount && files.length <= maxCount;
    }

    private async initStandardReview(result: StandardUploadResult) {
        const data = result.data;
        if (!data) return;
        await this.initReview(data);
        await this.beginReview();
    }

    private async initBayesianReview() {
        await this.syncComputations();
        this.state.newBayes.next(true);
        this.state.modal.next('bayes');
    }

    private async initReview(
        uploadData: AssayDataPoints[],
        bayes_results?: (AssayValueCreateBayesian | BayesWorkflowError)[]
    ) {
        const showCurveQC = uploadData.every((d) => isCurveMeasurement(d.info.property));
        this.state.isCurveMeasurement.next(showCurveQC);
        const selectedComputation = this.state.selectedComputation.value;

        this.state.status.next({
            isLoading: true,
            error: undefined,
        });

        const identifierToBatchMap: Record<string, Batch> = {};
        const valuesFlat = uploadData.map((d) => d.values).flat();
        const batchIdentifiers = valuesFlat.map((v) => v.batch_identifier);
        const assayIds = uploadData.map((d) => d.info.id);
        const [{ batch_map, batch_identifier_to_id }, historicValues] = await Promise.all([
            CompoundAPI.getBatchesFromIdentifiers(batchIdentifiers),
            AssayAPI.getHistoricValues(assayIds, batchIdentifiers),
        ]);

        for (const value of valuesFlat) {
            identifierToBatchMap[value.batch_identifier] = batch_map[batch_identifier_to_id[value.batch_identifier]];
        }

        this.reviewData = {
            uploadData,
            identifierToBatchMap,
            computation_id: selectedComputation?.id ?? undefined,
            bayes_results,
            historicValues,
        };
        this.state.status.next({
            isLoading: false,
            error: undefined,
        });

        const missingBatches: string[] = [];
        for (const value of valuesFlat) {
            if (!identifierToBatchMap[value.batch_identifier]) {
                missingBatches.push(value.batch_identifier);
            }
        }
        if (missingBatches.length > 0) {
            const message = `Not all batches in this file are registered in Foundry.\n${missingBatches.join('\n')}`;
            this.state.status.next({
                ...this.state.status.value,
                error: message,
            });
        }
    }

    async reviewCurve() {
        if (!this.reviewData) return;
        if (this.curveReviewModel) this.curveReviewModel.dispose();
        if (!this.qcCurveModel) this.qcCurveModel = new AssayCurveQCModel(this.reviewData);
        this.curveReviewModel = new AssayCurveReviewModel(this.qcCurveModel.batches, this.reviewData);
        try {
            await this.curveReviewModel.init();
            this.curveReviewModel.mount();
            this.state.step.next('curve-review');
        } catch (err) {
            reportErrorAsToast('Begin Review', err);
        }
    }

    qcCurve() {
        if (!this.reviewData) return;
        if (!this.qcCurveModel) this.qcCurveModel = new AssayCurveQCModel(this.reviewData);
        this.state.step.next('curve-qc');
    }

    reviewNonCurve() {
        if (!this.reviewData) return;
        if (this.nonCurveReviewModel) this.nonCurveReviewModel.dispose();
        this.nonCurveReviewModel = new AssayNonCurveReviewModel(this.reviewData);
        this.state.step.next('noncurve-review');
    }

    private async beginReview() {
        if (!this.reviewData) return;
        if (this.state.isCurveMeasurement.value) {
            if (this.qcCurveModel) this.qcCurveModel.dispose();
            if (this.curveReviewModel) this.curveReviewModel.dispose();

            const { performQC } = this.state.upload.value;
            if (performQC) {
                this.qcCurve();
            } else {
                await this.reviewCurve();
            }
        } else {
            this.reviewNonCurve();
        }
    }

    mount() {
        this.computationIntervalId = setInterval(() => this.syncComputations(), INFREQUENT_COMPUTATION_INTERVAL);

        this.subscribe(this.state.modal, (modal) => {
            if (modal !== 'bayes') {
                this.state.newBayes.next(false);
                if (modal === null) {
                    this.state.status.next({
                        ...this.state.status.value,
                        error: undefined,
                    });
                }
            } else {
                if (this.computationIntervalId) clearInterval(this.computationIntervalId);
                this.computationIntervalId = setInterval(() => this.syncComputations(), FREQUENT_COMPUTATION_INTERVAL);
            }
        });
        this.subscribe(this.state.upload, () => this.state.canUpload.next(this.canUpload()));
        this.subscribe(this.state.files, () => this.state.canUpload.next(this.canUpload()));
        this.syncComputations();
    }

    dispose() {
        super.dispose();
        if (this.computationIntervalId) clearInterval(this.computationIntervalId);
        this.qcCurveModel?.dispose();
        this.curveReviewModel?.dispose();
        this.nonCurveReviewModel?.dispose();
    }

    constructor() {
        super();
    }
}
