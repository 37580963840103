import classNames from 'classnames';
import React, { CSSProperties, ReactNode, useCallback, useContext, useRef } from 'react';
import { HeaderGroup, Row } from 'react-table';
import { FixedSizeList, FixedSizeListProps } from 'react-window';
import { BehaviorSubject } from 'rxjs';
import useBehavior from '../../lib/hooks/useBehavior';
// import Schema from '../../lib/models/schema';
import { ReactTableModel, ReactTableRow } from './model';
import { AsyncMoleculeDrawer } from '../../lib/util/draw-molecules';
import { AsyncMoleculeDrawing } from '../common/AsyncMoleculeDrawing';
import { AutoSizeBox } from '../common/AutoSizeBox';
import { reactTableGetToggleCheckedFn, RowSelectionMode, ReactTableHeader, ReactTableHeaderSize } from './Controls';

const StickyHeaderContext = React.createContext<() => React.ReactNode | undefined>(() => undefined);

function StickyHeaderList({
    renderer,
    header,
    ...rest
}: {
    renderer: (props: any) => React.ReactNode;
    header: () => React.ReactNode;
} & Omit<FixedSizeListProps, 'children'>) {
    return (
        <StickyHeaderContext.Provider value={header}>
            <FixedSizeList {...rest}>{renderer as any}</FixedSizeList>
        </StickyHeaderContext.Provider>
    );
}

const innerElementType = React.forwardRef<HTMLDivElement, any>(({ children, ...rest }, ref) => {
    const header = useContext(StickyHeaderContext);
    return (
        <>
            {header()}
            <div ref={ref} {...rest}>
                {children}
            </div>
        </>
    );
});

interface TableProps {
    height?: number;
    headerGroups: HeaderGroup<{ index: number }>[];
    headerSize?: ReactTableHeaderSize;
    rows: Row<{ index: number }>[];
    selectedRows: Record<string | number, boolean>;
    hiddenColumns?: string[];
    prepareRow: (row: Row<{ index: number }>) => void;
    showColumnFilters?: boolean;
    rowSelectionMode?: RowSelectionMode;
    onRowDoubleClick?: (row: ReactTableRow) => void;
    onRowSelect?: (row: ReactTableRow) => void;
    onRowMouseEnter?: (row: ReactTableRow) => void;
    onRowMouseLeave?: (row: ReactTableRow) => void;
    getAdditionalRowClasses?: (row: ReactTableRow) => string;
    table: ReactTableModel<any>;
    smiles?: {
        // optional so that we can reuse this in datasets etc.
        columnId?: string; // defaults to 'smiles'
        showDrawings: BehaviorSubject<boolean>;
        moleculeDrawer?: AsyncMoleculeDrawer;
        rowSizeMultiplier?: number;
    };
    hideSmiles?: boolean;
}

export function VirtualizedReactTableControl({
    height,
    headerGroups,
    headerSize = 'sm',
    rows,
    selectedRows,
    hiddenColumns,
    prepareRow,
    showColumnFilters = false,
    rowSelectionMode = 'none',
    onRowDoubleClick = () => {},
    onRowSelect,
    onRowMouseEnter,
    onRowMouseLeave,
    getAdditionalRowClasses = () => '',
    table,
    smiles,
    hideSmiles,
}: TableProps) {
    const onCheck = reactTableGetToggleCheckedFn(table, rowSelectionMode);
    let showSMILESDrawings = !!useBehavior(smiles?.showDrawings);
    if (hideSmiles) showSMILESDrawings = false;
    const smilesColumn = smiles ? smiles.columnId ?? 'smiles' : '';
    const baseLineHeight = 38;
    const smilesSizeMultiplier = smiles?.rowSizeMultiplier ?? 2.5;
    const lineHeight = showSMILESDrawings ? Math.ceil(smilesSizeMultiplier * baseLineHeight) : baseLineHeight;
    // these pixel values must correspond with class styles in table.scss: entos-sm-header-row and entos-lg-header-row
    const headerHeight = headerSize === 'lg' ? 90 : 45;
    const tableHeight = height ?? 12 * baseLineHeight + headerHeight + 1;
    const flexCell: { style: CSSProperties } = {
        style: { display: 'flex', alignItems: 'center', height: lineHeight },
    };
    const smilesCell: { style: CSSProperties } = {
        style: { display: 'flex', justifyContent: 'center', height: lineHeight },
    };
    const textCell: { style: CSSProperties } = { style: { lineHeight: `${lineHeight}px`, height: lineHeight } };
    const numberStyle: { style: CSSProperties } = { style: { ...textCell.style, textAlign: 'right' } };

    function onRowClick(row: ReactTableRow) {
        // if row selection mode is single, select the whole row when
        // clicking anywhere on it
        if (rowSelectionMode === 'single') {
            const wasSelected = !!selectedRows[row.index];
            table.setSelection({ [row.index]: true });
            if (!wasSelected) setTimeout(() => onRowSelect?.(row), 0);
        }
    }

    function onRowKeyUp(e: React.KeyboardEvent<HTMLDivElement>, row: ReactTableRow) {
        if (e.key === 'Enter') {
            // TODO (emma) what should the keyboard controls to single/double click be?
            onRowClick(row);
        }
    }

    const RenderRow = useCallback(
        ({ index, style }: any) => {
            const row = rows[index];
            prepareRow(row);
            const props = row.getRowProps({ style });
            delete props.style!.width;
            props.style!.minWidth = '100%';
            props.style!.top = style.top + headerHeight;

            return (
                <div
                    {...props}
                    className={classNames('entos-dataviz-table-row', getAdditionalRowClasses(row), {
                        selected: !!selectedRows[row.index],
                    })}
                    role='button'
                    tabIndex={0}
                    onClick={() => onRowClick(row)}
                    onKeyUp={(e) => onRowKeyUp(e, row)}
                    onDoubleClick={() => onRowDoubleClick(row)}
                    onMouseEnter={onRowMouseEnter && (() => onRowMouseEnter!(row))}
                    onMouseLeave={onRowMouseLeave && (() => onRowMouseLeave!(row))}
                >
                    {row.cells.map((cell, i) => {
                        const isSmiles = smiles && cell.column.id === smilesColumn && showSMILESDrawings;
                        // TODO (emma) hack where it was requested specifically that MW column in the compounds table
                        // be right-aligned. My initial thought was to do this for all number values, but in doing that
                        // I thought the table looked too confusing with mixed text alignment. Perhaps we have cell divider
                        // borders? Will ask design, but for now restricting the change to just this one column.
                        const isMW = cell.column.id === 'molecular_weight';
                        let inner: ReactNode;
                        let cellStyle: any;
                        if (isSmiles) {
                            const smilesString = table.getValue(smilesColumn, row.original.index);
                            inner = (
                                <AsyncMoleculeDrawing
                                    drawer={smiles.moleculeDrawer}
                                    smiles={smilesString}
                                    height={lineHeight}
                                    data-index={row.original.index}
                                    onClick={onCheck as any}
                                />
                            );
                            cellStyle = smilesCell;
                        } else {
                            inner = cell.render('Cell');
                            if (i === 0) {
                                cellStyle = flexCell;
                            } else if (isMW) {
                                cellStyle = numberStyle;
                            } else {
                                cellStyle = textCell;
                            }
                        }

                        return (
                            <div {...cell.getCellProps(cellStyle)} className='entos-dataviz-table-cell'>
                                {inner}
                            </div>
                        );
                    })}
                </div>
            );
        },
        [rows, selectedRows, hiddenColumns, showSMILESDrawings]
    );

    const header = (
        <ReactTableHeader
            headerGroups={headerGroups}
            headerSize={headerSize}
            smiles={smiles}
            showColumnFilters={showColumnFilters}
        />
    );

    const currentHeader = useRef<any>(header);
    currentHeader.current = header;
    const renderHeader = useCallback(() => currentHeader.current, []);

    return (
        <StickyHeaderList
            height={tableHeight}
            innerElementType={innerElementType}
            itemData={header}
            itemCount={rows.length}
            itemSize={lineHeight}
            overscanCount={10}
            renderer={RenderRow}
            header={renderHeader}
            width='100%'
        />
    );
}

export function FlexibleVirtualizedTable(props: TableProps) {
    return (
        <AutoSizeBox className='flex-virtual-table-wrapper'>
            {(_, h) => <VirtualizedReactTableControl {...props} height={h} />}
        </AutoSizeBox>
    );
}
