import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import log from 'loglevel';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { AsyncState, useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import useBehaviorSubject from '../../../lib/hooks/useBehaviorSubject';
import {
    ConfirmDialogProps,
    CopyTextDialogProps,
    DialogService,
    GenericDialogProps,
} from '../../../lib/services/dialog';
import { ToastService } from '../../../lib/services/toast';
import { tryGetErrorMessageList } from '../../../lib/util/errors';
import { AsyncButton } from '../AsyncButton';
import { TextInput } from '../Inputs';

export function Dialogs() {
    const dialogState = useBehavior(DialogService.state);

    if (dialogState === null) return null;

    return (
        <div className='position-fixed start-50'>
            {dialogState.type === 'copy' && <CopyTextDialog state={dialogState} />}
            {dialogState.type === 'confirm' && <ConfirmDialog state={dialogState} />}
            {dialogState.type === 'generic' && <GenericDialog state={dialogState} />}
        </div>
    );
}

function CopyTextDialog({ state }: { state: CopyTextDialogProps }) {
    const [error, setError] = useState(false);
    const inputElem = useRef<HTMLInputElement | undefined>(undefined);

    const selectAll = () => {
        inputElem.current?.focus();
        inputElem.current?.setSelectionRange(0, inputElem.current?.value.length);
        if (inputElem.current) {
            inputElem.current.scrollTop = 0;
        }
    };

    async function handleClick() {
        if (!state.toCopy) return;
        try {
            // Uncomment the below line to test link copy failure
            // throw new Error();
            await navigator.clipboard.writeText(state.toCopy);
            ToastService.show({
                type: 'success',
                message: 'Copied to Clipboard',
                timeoutMs: 3000,
            });
            DialogService.close();
            setError(false);
        } catch (err) {
            log.error(err);
            setError(true);
            requestAnimationFrame(selectAll);
        }
    }

    useEffect(() => {
        requestAnimationFrame(selectAll);
    }, []);

    const isMultiline = state.toCopy.indexOf('\n') >= 0;

    return (
        <Modal backdrop centered show onHide={() => DialogService.close()}>
            <Modal.Header closeButton>
                <h4>{state.title}</h4>
            </Modal.Header>
            <Modal.Body>
                {state.text && <p>{state.text}</p>}
                {isMultiline && (
                    <textarea rows={3} className='form-control' readOnly ref={inputElem as any} value={state.toCopy} />
                )}
                {!isMultiline && (
                    <input className='form-control' type='text' readOnly ref={inputElem as any} value={state.toCopy} />
                )}
                {error && (
                    <p className='text-danger mt-1'>
                        Clipboard acccess denied. Please copy the text from the field above.
                    </p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type='button'
                    variant='primary'
                    title='Click to copy'
                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleClick()}
                    disabled={!state.toCopy || error}
                >
                    <FontAwesomeIcon icon={faCopy} fixedWidth /> {state.confirmText ?? 'Copy'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function ConfirmDialog({ state }: { state: ConfirmDialogProps }) {
    return (
        <Modal backdrop centered show onHide={() => DialogService.close()} {...state.modalOptions}>
            <Modal.Header closeButton>
                <h4>{state.title}</h4>
            </Modal.Header>
            <Modal.Body>{state.text && <div>{state.text}</div>}</Modal.Body>
            <Modal.Footer>
                <Button
                    type='button'
                    variant='primary'
                    title='Click to confirm'
                    onClick={() => {
                        DialogService.close();
                        state.onConfirm();
                    }}
                >
                    {state.confirmText ?? 'OK'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function GenericDialog({ state }: { state: GenericDialogProps }) {
    const stateSubject = useBehaviorSubject(state.defaultState);
    const [action, applyAction] = useAsyncAction({ rethrowError: true });
    const okActionRef = useRef<{ onOk: () => any; action: AsyncState<any> }>();
    useEffect(() => {
        applyAction(undefined);
    }, [state]);

    const onClose = () => {
        DialogService.close();
        state.onClose?.(stateSubject.value);
    };
    const onOk = async () => {
        if (!state.wrapOk) {
            if (!state.doNotAutoClose) DialogService.close();
            return state.onOk?.(stateSubject.value, stateSubject);
        }

        try {
            // wrap so that the callback is always async and the error catching works correctly
            // inside useAsyncAction
            const wrapped = async () => {
                await state.onOk?.(stateSubject.value, stateSubject);
            };
            await applyAction(wrapped());
            if (!state.doNotAutoClose) DialogService.close();
        } catch (e) {
            // do nothing
        }
    };
    okActionRef.current = { onOk, action };

    useEffect(() => {
        if (!state.options?.okOnEnterKeyPress) return;
        const handler = (e: KeyboardEvent) => {
            // Prevent double activation
            if (okActionRef.current?.action.isLoading) {
                return;
            }

            const tagName = (e.target as Element)?.tagName.toUpperCase();
            if (tagName === 'INPUT' || tagName === 'TEXTAREA') {
                return;
            }

            if (e.key === 'Enter') {
                e.preventDefault();
                okActionRef.current?.onOk();
            }
        };
        document.addEventListener('keydown', handler);
        return () => document.removeEventListener('keydown', handler);
    }, [state.options?.okOnEnterKeyPress]);

    const cancelButton = state.options?.showCancelButton ? (
        <Button type='button' variant='link' onClick={onClose}>
            Cancel
        </Button>
    ) : undefined;

    return (
        <Modal
            backdrop={action.isLoading || state.options?.staticBackdrop ? 'static' : true}
            centered
            show
            onHide={onClose}
            size={state.options?.size}
            contentClassName={state.options?.contentClassName}
            keyboard={!action.isLoading}
        >
            <Modal.Header closeButton={!action.isLoading}>
                {!!state.header && (
                    <state.header model={state.model} close={onClose} ok={onOk} stateSubject={stateSubject} />
                )}
                {!state.header && <h4>{state.title}</h4>}
            </Modal.Header>
            <Modal.Body>
                {!!state.content && (
                    <state.content model={state.model} close={onClose} ok={onOk} stateSubject={stateSubject} />
                )}
                {!state.content && state.text && <div>{state.text}</div>}
            </Modal.Body>
            {!state.options?.hideFooter && (
                <Modal.Footer>
                    {!!state.footer && (
                        <state.footer model={state.model} close={onClose} ok={onOk} stateSubject={stateSubject} />
                    )}
                    {!state.footer && !state.wrapOk && (
                        <div className='hstack gap-2 mx-0'>
                            {cancelButton}
                            <Button type='button' variant='primary' title='Click to confirm' onClick={onOk}>
                                {state.confirmButtonContent ?? 'OK'}
                            </Button>
                        </div>
                    )}
                    {!state.footer && state.wrapOk && (
                        <div className='hstack gap-2 mx-0'>
                            {action.error && (
                                <div className='text-danger entos-dialog-error font-body-small'>
                                    <DialogErrors error={action.error} />
                                </div>
                            )}
                            {cancelButton}
                            <AsyncButton variant='primary' title='Click to confirm' onClick={onOk} state={action}>
                                {state.confirmButtonContent ?? 'OK'}
                            </AsyncButton>
                        </div>
                    )}
                </Modal.Footer>
            )}
        </Modal>
    );
}

function DialogErrors({ error }: { error: any }) {
    const [showAll, setShowAll] = useState(false);
    const messages = tryGetErrorMessageList(error);

    if (!messages.length) return null;
    if (messages.length <= 1) return <span>{messages[0]}</span>;

    if (showAll) {
        return (
            <ul className='ps-4'>
                {messages.map((e, i) => (
                    <li key={i}>{e}</li>
                ))}
            </ul>
        );
    }

    return (
        <>
            <div>{messages[0]}</div>
            <Button size='sm' variant='link' className='p-0' onClick={() => setShowAll(true)}>
                Show all {messages.length} error messages
            </Button>
        </>
    );
}

export function ReadClipboardFallback({ stateSubject }: { stateSubject: BehaviorSubject<string> }) {
    return (
        <div className='hstack gap-2'>
            <div>Failed to read clipboard content. Please paste data in the text box to continue.</div>
            <TextInput
                placeholder='Paste here'
                value={stateSubject.value}
                setValue={(value: string) => stateSubject.next(value)}
                textarea
                autoFocus
            />
        </div>
    );
}
