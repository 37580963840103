import { ReactNode } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Split from 'react-split-it';
import { DataTableGlobalFilter, DataTableControl, DataTableModel } from '../../../components/DataTable';
import { SelectionInfo } from '../../../components/DataTable/common';
import Pane from '../../../components/Pane/Pane';
import useBehavior from '../../../lib/hooks/useBehavior';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { AssayValueDetail, AssayValueNonCurveReview } from '../assay-api';
import AssayPlotPanel from '../AssayPlotPanel';
import { MultiAssayDetailPane } from '../AssayDetailPanel';
import { AssayUploadModel } from './assay-upload-model';
import { AssayUpload } from './AssayUpload';
import { BayesAPI } from './bayes-api';
import { DialogService } from '../../../lib/services/dialog';

export function AssayCurveReview({ model, onCancel }: { model: AssayUploadModel; onCancel: () => void }) {
    const step = useBehavior(model.state.step);

    return (
        <>
            {step === 'curve-qc' && model.qcCurveModel && (
                <AssayUpload
                    uploadModel={model}
                    model={model.qcCurveModel}
                    onCancel={() => onCancel()}
                    onConfirm={() => model.reviewCurve()}
                />
            )}
            {step === 'curve-review' && (
                <AssayCurveValueReview model={model} onCancel={() => onCancel()} onBack={() => model.qcCurve()} />
            )}
        </>
    );
}

interface FooterProps {
    model: AssayUploadModel;
    retiredComments: readonly string[];
    onCancel: () => void;
    saveJSON: () => any;
    saveCSV: () => void;
    onBack?: () => void;
    onDiscard?: () => void;
    disablePublish?: boolean;
    assayCount: number;
}

function Footer({
    model,
    retiredComments,
    onCancel,
    onBack,
    onDiscard,
    saveJSON,
    saveCSV,
    disablePublish,
    assayCount,
}: FooterProps) {
    const retiredCount = retiredComments.filter((v) => !!v).length;
    const publishCount = retiredComments.length - retiredCount;
    const navigate = useNavigate();

    return (
        <div className='entos-footer hstack gap-2'>
            <div className='text-secondary'>
                {publishCount} assay values selected for publishing, {retiredCount} will be retired
            </div>
            <div className='m-auto' />
            <Button variant='link' onClick={() => onCancel()} size='sm'>
                Cancel
            </Button>
            {typeof onBack === 'function' && (
                <Button variant='outline-primary' onClick={() => onBack()} size='sm'>
                    Back
                </Button>
            )}
            {typeof onDiscard === 'function' && (
                <Button variant='link' onClick={onDiscard} size='sm'>
                    Discard Results
                </Button>
            )}
            <Dropdown>
                <Dropdown.Toggle variant='outline-primary' size='sm'>
                    Export
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item disabled={assayCount !== 1} onClick={saveJSON}>
                        JSON
                    </Dropdown.Item>
                    <Dropdown.Item onClick={saveCSV}>CSV</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Button
                variant='primary'
                onClick={() => openUploadModal(model, () => model.onUploadSuccess(navigate))}
                size='sm'
                disabled={disablePublish}
            >
                Publish
            </Button>
        </div>
    );
}

const getRetiredRowClasses = (rowIndex: number, table: DataTableModel<any>) => {
    if (!table.store.columnNames.includes('retired_comment')) return '';
    const retiredComment = table.store.getValue('retired_comment', rowIndex);
    if (retiredComment) return 'opacity-50';
    return '';
};

function AssayValueCurveReviewTablePane({
    table,
    valueColumnSelect,
}: {
    table: DataTableModel<AssayValueDetail>;
    valueColumnSelect?: ReactNode;
}) {
    useBehavior(table.version);

    const tableHeader = (
        <div className='flex-grow-1 d-flex align-items-center justify-content-between text-secondary ms-2'>
            <div className='d-flex align-items-center flex-grow-1'>
                <SelectionInfo table={table} />
                <div className='mx-2'>
                    <DataTableGlobalFilter table={table} />
                </div>
                {valueColumnSelect}
            </div>
        </div>
    );

    return (
        <Pane title='Table' headerInfo={tableHeader} contentPaddingIndex={0}>
            <DataTableControl
                height='flex'
                table={table}
                headerSize='sm'
                rowSelectionMode='single'
                getAdditionalRowClasses={getRetiredRowClasses}
            />
        </Pane>
    );
}

function UploadConfirmationContent({ model }: { model: AssayUploadModel }) {
    const count = model.qcCurveModel ? model.qcCurveModel.batches.length : model.nonCurveReviewModel!.table.rows.length;
    return <p>Are you sure you want to add {count} values to Entos Foundry? This action cannot easily be undone.</p>;
}

function openUploadModal(model: AssayUploadModel, onSuccess: () => void) {
    DialogService.open({
        type: 'generic',
        title: 'Publish Assay Values',
        confirmButtonContent: 'Publish',
        model,
        wrapOk: true,
        content: UploadConfirmationContent,
        onOk: async () => {
            let result = false;
            if (model.qcCurveModel) {
                result = await model.qcCurveModel!.uploadValues();
            } else if (model.nonCurveReviewModel) {
                result = await model.nonCurveReviewModel!.uploadValues();
            }
            if (result) {
                onSuccess();
            } else {
                throw new Error('Error uploading values');
            }
        },
    });
}

function AssayCurveValueReview({
    model,
    onCancel,
    onBack,
}: {
    model: AssayUploadModel;
    onCancel: () => void;
    onBack: () => void;
}) {
    const { performQC } = useBehavior(model.state.upload);
    const currentComputation = useBehavior(model.state.selectedComputation);
    const assays = model.qcCurveModel!.data.uploadData.map((d) => d.info);

    async function onDiscard() {
        if (!currentComputation) return;
        try {
            await BayesAPI.remove(currentComputation.id);
            model.syncComputations();
            model.cancel();
        } catch (e) {
            reportErrorAsToast('Error discarding Bayesian results:', e);
        }
    }

    // TODO emma measurement should be for value selected

    return (
        <div className='h-100'>
            {model.qcCurveModel && model.curveReviewModel && model.curveReviewModel.table && (
                <Split direction='horizontal' gutterSize={6} sizes={[0.25, 0.75]}>
                    <MultiAssayDetailPane assays={assays} />
                    <Split direction='vertical' gutterSize={6} sizes={[0.5, 0.5]}>
                        <AssayPlotPanel
                            figureSubject={model.curveReviewModel.state.figure}
                            measurement={assays[0].property.measurement}
                            table={model.curveReviewModel.table}
                            values={Object.values(model.curveReviewModel.values).flat()}
                        />
                        <AssayValueCurveReviewTablePane table={model.curveReviewModel.table} />
                    </Split>
                </Split>
            )}
            {model.curveReviewModel && model.curveReviewModel.table && (
                <Footer
                    model={model}
                    retiredComments={model.curveReviewModel.table.store.getColumnValues('retired_comment')}
                    onBack={performQC ? onBack : undefined}
                    onDiscard={currentComputation ? () => onDiscard() : undefined}
                    saveJSON={model.saveJSON}
                    saveCSV={model.saveCSV}
                    onCancel={onCancel}
                    assayCount={assays.length}
                />
            )}
        </div>
    );
}

function AssayValueNonCurveReviewTablePane({ table }: { table: DataTableModel<AssayValueNonCurveReview> }) {
    useBehavior(table.version);

    const tableHeader = (
        <div className='flex-grow-1 d-flex align-items-center justify-content-between text-secondary ms-2'>
            <div className='d-flex align-items-center flex-grow-1'>
                <SelectionInfo table={table} />
                <div className='mx-2'>
                    <DataTableGlobalFilter table={table} />
                </div>
            </div>
        </div>
    );

    return (
        <Pane title='Table' headerInfo={tableHeader} contentPaddingIndex={0}>
            <DataTableControl
                height='flex'
                table={table}
                headerSize='sm'
                rowSelectionMode='multi'
                getAdditionalRowClasses={getRetiredRowClasses}
            />
        </Pane>
    );
}

export function AssayNonCurveReview({ model, onCancel }: { model: AssayUploadModel; onCancel: () => void }) {
    const reviewModel = model.nonCurveReviewModel;
    useBehavior(reviewModel!.table.version);
    const assays = reviewModel!.data.uploadData.map((d) => d.info);

    return (
        <div className='h-100'>
            {reviewModel && (
                <>
                    <Split direction='horizontal' gutterSize={6} sizes={[0.25, 0.75]}>
                        <MultiAssayDetailPane assays={assays} />
                        <AssayValueNonCurveReviewTablePane table={reviewModel.table} />
                    </Split>
                    <Footer
                        model={model}
                        retiredComments={reviewModel.table.store.getColumnValues('retired_comment')}
                        saveJSON={model.saveJSON}
                        saveCSV={model.saveCSV}
                        onCancel={onCancel}
                        assayCount={assays.length}
                    />
                </>
            )}
        </div>
    );
}
