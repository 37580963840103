import { Spinner } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import useBehavior from '../../../lib/hooks/useBehavior';

export type WorkflowStatus = 'success' | 'warning' | 'danger' | 'info' | 'pending' | 'blank';

export function WorkflowStatusIndicator({ subject }: { subject?: BehaviorSubject<WorkflowStatus> }) {
    const status: WorkflowStatus = useBehavior(subject) ?? 'blank';

    if (status === 'pending') {
        return (
            <div className='hte2ms-workflow-status-pending'>
                <Spinner animation='grow' size='sm' />
            </div>
        );
    }

    return <div className={`hte2ms-workflow-status-${status}`} />;
}
