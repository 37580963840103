import api from '../../api';
import ReactTableSchema from '../../components/ReactTable/schema';
import { ReactTableModel } from '../../components/ReactTable/model';
import { type AssayValueType } from '../../lib/assays/models';
import { DateLike } from '../../lib/util/dates';
import { downloadFile } from '../../lib/util/downloadFile';
import { decodeEntosMsgpack } from '../../lib/util/serialization';
import { ColumnTableData, columnDataTableStore, DataTableStore } from '../../components/DataTable';
import { AssayDetail } from '../Assays/assay-api';
import { Batch, BatchFromDF, CompoundDetail, CompoundFromDF } from '../Compounds/compound-api';
import { Plate as ECMPlate, PlateRow, SampleCreate, Vial } from '../ECM/ecm-api';
import {
    HTEExperiment,
    HTEExperimentCreate,
    HTEExperimentInfo,
    FoundryReactionCreate,
    WellLayout,
    FoundryReaction,
    HTEExperimentStatus,
} from './experiment-data';
import { HTEHistoryEntry, HTEReview, HTESnapshotInstance } from './sign/sign-data';

export interface HTEExperimentRow {
    id: number;
    name: string;
    project: string;
    well_layout: number;
    created_by: string;
    created_on: DateLike;
    modified_on: DateLike;
    status?: HTEExperimentStatus;
}

export const HTEExperimentEntry = {
    id: ReactTableSchema.int(),
    name: ReactTableSchema.str(),
    project: ReactTableSchema.str(),
    well_layout: ReactTableSchema.int(),
    created_by: ReactTableSchema.str(),
    created_on: ReactTableSchema.datetime({ format: 'full' }),
    modified_on: ReactTableSchema.datetime({ format: 'full' }),
    status: ReactTableSchema.str(),
};
export type HTEExperimentEntry = ReactTableSchema.Type<typeof HTEExperimentEntry>;

export const HTEUploadProductEntry = {
    'msd identifier': ReactTableSchema.str(),
    'bb identifier': ReactTableSchema.str(),
    'product identifier': ReactTableSchema.str(),
    'bb group': ReactTableSchema.str(),
    'msd barcode': ReactTableSchema.str(),
    'bb barcode': ReactTableSchema.str(),
    'msd order': ReactTableSchema.optionalInt(),
    'bb order': ReactTableSchema.optionalInt(),
    insight_well_index: ReactTableSchema.optionalInt(),
};
export type HTEUploadProductEntry = ReactTableSchema.Type<typeof HTEUploadProductEntry>;

export interface HTEUploadResult {
    table: ReactTableModel<HTEUploadProductEntry>;
    batches: Batch[];
    compounds: CompoundDetail[];
}

export interface BatchIdentifiers {
    identifier: string;
    entos?: string | null;
    msd?: string | null;
    bb?: string | null;
    rgn?: string | null;
    universal?: string | null;
}

export interface HTEBatchesResult {
    batches: Batch[];
    compounds: CompoundDetail[];
}

export interface HTEDesignInfo {
    experiment: HTEExperimentInfo;
    crude_plate?: ECMPlate;
    purified_plate?: ECMPlate;
}

export interface HTECreateCrudePlateResult {
    experiment: HTEExperimentInfo;
    crude_plate: ECMPlate;
}

export interface HTEUploadPurifiedPlateResult {
    experiment: HTEExperimentInfo;
    purified_plate: ECMPlate;
}

export interface HTESignOptions {
    snapshot_id: number;
    review_id?: number;
    full_name: string;
    acknowledged_policy: boolean;
    comment?: string;
    is_counter_signature: boolean;
}

export interface HTEFinalizeFormOptions {
    full_name: string;
    acknowledged_policy: boolean;
    comment?: string;
}

export interface HTEFinalizeOptions {
    plate_barcode: string;
    well_layout: WellLayout;
    samples: (SampleCreate | null)[];
    reactions: FoundryReactionCreate[];
    executed_on: string; // ISO formatted date
    finalization_data: HTEFinalizeFormOptions;
}

export type AssayArtifactValue = {
    value: AssayValueType;
    'uv %'?: number | null;
    'tic %'?: number | null;
    artifacts?: Record<string, any>;
};
export type HTEDetailAssayValue = AssayValueType | AssayArtifactValue;

export interface HTEDetailsInfo {
    experiment: HTEExperimentInfo;
    plates: ColumnTableData<PlateRow>;
}

export interface HTEPlateDetailsData {
    plate: ECMPlate;
    batches: Batch[];
    compounds: CompoundDetail[];
    reactions: FoundryReaction[];
    assays: Record<number, AssayDetail>;
    assay_shorthands: Record<number, string>;
    artifact_assays: number[];
    assay_values: ColumnTableData<{ [assay_id: number]: HTEDetailAssayValue }, string>;
}

export interface HTEBatchRegistrationInput {
    compounds: { well_index: number; compound_id: number; compound_identifier: string; structure: string }[];
    hte_id: number;
}
export type HTEBatchRegistrationResult = Record<number, Batch>;

export interface HTEExperimentLoadReactantEntry {
    input_identifier?: string;
    pivot_identifier: string;
    batches: Batch[];
    compound: CompoundDetail;
    vial_barcode?: string;
    vials: Vial[];
}

export const HTEApi = {
    list: async (): Promise<DataTableStore<HTEExperimentRow>> => {
        const { data } = await api.client.get('hte', { responseType: 'arraybuffer', params: { as_df: true } });
        return columnDataTableStore(decodeEntosMsgpack(new Uint8Array(data), { eoi: 'strip' }));
    },
    list_experiments: async (): Promise<HTEExperimentInfo[]> => {
        const { data } = await api.client.get('hte', { responseType: 'arraybuffer', params: { as_df: false } });
        return decodeEntosMsgpack(data, { eoi: 'strip' });
    },
    filter: async (query: { plate_id?: number }): Promise<ReactTableModel<HTEExperimentEntry>> => {
        const { data } = await api.client.post('hte', query, { responseType: 'arraybuffer' });
        return new ReactTableModel(decodeEntosMsgpack(new Uint8Array(data), { eoi: 'strip' }), HTEExperimentEntry);
    },
    get: async (id: number): Promise<HTEDesignInfo> => {
        const { data } = await api.client.get(`hte/${id}`, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(new Uint8Array(data), { eoi: 'strip', useTypedArrays: true });
    },
    details: async (id: number): Promise<HTEDetailsInfo> => {
        const { data } = await api.client.get(`hte/${id}/details`, {
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data, { eoi: 'strip', useTypedArrays: false });
    },
    detailsPlate: async (options: {
        plate_id: number;
        query_assays?: boolean;
        query_compounds?: boolean;
    }): Promise<HTEPlateDetailsData> => {
        const { data } = await api.client.get(`hte/plate-details/${options.plate_id}`, {
            responseType: 'arraybuffer',
            params: { query_assays: options.query_assays, query_compounds: options.query_compounds },
        });
        const decoded = decodeEntosMsgpack(new Uint8Array(data), { eoi: 'strip', useTypedArrays: false });

        const batches = columnDataTableStore(decoded.batches as ColumnTableData<BatchFromDF>).toObjects();
        for (const r of batches) {
            r.structure = { smiles: r.structure as any } as any;
        }
        decoded.batches = batches;

        if (options.query_compounds) {
            const compounds = columnDataTableStore(decoded.compounds as ColumnTableData<CompoundFromDF>).toObjects();
            for (const c of compounds) {
                c.structure = { smiles: c.structure as any } as any;
            }
            decoded.compounds = compounds;
        } else {
            decoded.compounds = [];
        }

        return decoded;
    },
    create: async (options: { experiment: HTEExperiment; info: HTEExperimentCreate }): Promise<number> => {
        const { data } = await api.client.post(
            `hte`,
            { experiment: options.experiment, info: options.info },
            { responseType: 'arraybuffer' }
        );
        return decodeEntosMsgpack(new Uint8Array(data), { eoi: 'strip' });
    },
    sign: async (options: HTESignOptions): Promise<HTEReview> => {
        const { data } = await api.client.post(`hte/sign`, options, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(new Uint8Array(data), { eoi: 'strip' });
    },
    lock: async (id: number): Promise<HTEExperimentInfo> => {
        const { data } = await api.client.post(`hte/${id}/lock`, undefined, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(new Uint8Array(data), { eoi: 'strip' });
    },
    unlock: async (id: number): Promise<HTEExperimentInfo> => {
        const { data } = await api.client.post(`hte/${id}/unlock`, undefined, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(new Uint8Array(data), { eoi: 'strip' });
    },
    finalize: async (id: number, options: HTEFinalizeOptions): Promise<HTECreateCrudePlateResult> => {
        const { data } = await api.client.post(`hte/${id}/finalize`, options, {
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(new Uint8Array(data), { eoi: 'strip' });
    },
    uploadPurifiedProductPlateBarcode: async (
        id: number,
        barcode: string,
        options?: { unlock_if_locked?: boolean }
    ): Promise<HTEUploadPurifiedPlateResult> => {
        const { data } = await api.client.post(`hte/${id}/upload-purified-product-plate-barcode/${barcode}`, options, {
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(new Uint8Array(data), { eoi: 'strip' });
    },
    checkDataState: async (id: number, options: { last_modified_on: DateLike }): Promise<boolean> => {
        const { data } = await api.client.post(`hte/${id}/check-data-state`, options);
        return data;
    },
    save: async (
        id: number,
        options: {
            experiment: HTEExperiment;
            info: HTEExperimentCreate;
            procedure?: string;
            observations?: string;
            last_modified_on: DateLike;
        }
    ): Promise<HTEExperimentInfo> => {
        const { data } = await api.client.post(`hte/${id}`, options, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(new Uint8Array(data), { eoi: 'strip' });
    },
    batchesAndCompounds: async (identifiers: string[]): Promise<HTEBatchesResult> => {
        const { data } = await api.client.post('hte/batches', { identifiers }, { responseType: 'arraybuffer' });
        const decoded = decodeEntosMsgpack(new Uint8Array(data), { eoi: 'strip' });

        const batchesTable = new ReactTableModel(decoded.batches as any);
        const batches = batchesTable.toObjects() as Batch[];
        for (const r of batches) {
            r.structure = { smiles: r.structure as any } as any;
        }

        const compoundsTable = new ReactTableModel(decoded.compounds as any);
        const compounds = compoundsTable.toObjects() as CompoundDetail[];
        for (const c of compounds) {
            c.structure = { smiles: c.structure as any } as any;
        }

        return { batches, compounds };
    },
    upload: async (file: File, layout: WellLayout): Promise<HTEUploadResult> => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('layout', String(layout));
        const { data } = await api.client.post('hte/upload-products', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            responseType: 'arraybuffer',
        });
        const parsed = decodeEntosMsgpack(new Uint8Array(data), { eoi: 'strip' });
        return {
            table: new ReactTableModel(parsed.table, HTEUploadProductEntry),
            batches: parsed.batches,
            compounds: parsed.compounds,
        };
    },
    createTestCSV: async (): Promise<string> => {
        const { data } = await api.client.get(`hte/test-reactions-csv`);
        return data;
    },
    registerBatches: async (options: HTEBatchRegistrationInput): Promise<HTEBatchRegistrationResult> => {
        const { data } = await api.client.post('hte/register-batches', options, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data, { eoi: 'strip' });
    },
    registerCompounds: async (options: {
        smiles: string[];
        project: string;
    }): Promise<Record<string, CompoundDetail>> => {
        const { data } = await api.client.post('hte/register-compounds', options, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data, { eoi: 'strip' });
    },
    loadReactants: async (options: { identifiers: string[] }): Promise<HTEExperimentLoadReactantEntry[]> => {
        const { data } = await api.client.post(`hte/load-reactants`, options, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data, { eoi: 'strip' });
    },
    loadVials: async (options: { batch_ids: number[] }): Promise<Vial[]> => {
        const { data } = await api.client.post(`hte/load-vials`, options, { responseType: 'arraybuffer' });
        const df = decodeEntosMsgpack(new Uint8Array(data), { eoi: 'strip' });
        const store = columnDataTableStore(df);
        return store.toObjects();
    },
    history: async (id: number): Promise<HTEHistoryEntry[]> => {
        const { data } = await api.client.get(`hte/${id}/history`, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data, { eoi: 'hex', eoiPattern: 'substr' });
    },
    latestSnapshot: async (id: number): Promise<HTESnapshotInstance> => {
        const { data } = await api.client.get(`hte/${id}/latest-snapshot`, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data, { eoi: 'hex', eoiPattern: 'substr' });
    },
    downloadPDF: async (id: number, experiment_id: number): Promise<void> => {
        const { data } = await api.client.get(`hte/pdf/snapshot/${id}`, { responseType: 'blob' });
        downloadFile({ data, filename: `hte-${experiment_id}-report.zip` });
    },
    analyticalExportCSV: async (id: number): Promise<string> => {
        const { data } = await api.client.get(`hte/${id}/analytical-export`, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
    triggerSnapshot: (id: number): Promise<void> => api.postMsgpack(`hte/${id}/trigger-snapshot`, {}),
};
