import { faArrowUpRightFromSquare, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { InlineAlert } from '../../../components/common/Alert';
import { IconButton } from '../../../components/common/IconButton';
import { LabeledInput, TextInput } from '../../../components/common/Inputs';
import { ScrollBox } from '../../../components/common/ScrollBox';
import useBehavior from '../../../lib/hooks/useBehavior';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { AuthService } from '../../../lib/services/auth';
import { DialogService } from '../../../lib/services/dialog';
import { parseFileSystemDate } from '../../../lib/util/dates';
import { EXPERIMENT_ACKNOWLEDGEMENT } from '../../HTE/experiment-data';
import { HTE2MSModel } from '../model';
import { HTE2MSFinalizeModel } from './model';

export function HTE2MSFinalizeUI({ model }: { model: HTE2MSModel }) {
    return <Layout model={model.finalization} />;
}

function Layout({ model }: { model: HTE2MSFinalizeModel }) {
    useMountedModel(model);
    const canFinalize = useBehavior(model.state.canFinalize);

    if (canFinalize.kind === 'not-ready') {
        return (
            <WithHeader model={model}>
                <span className='text-secondary fst-italic pb-2'>
                    The experiment is not ready for finalization
                    {canFinalize.message ? `: ${canFinalize.message}.` : '.'}
                </span>
            </WithHeader>
        );
    }

    if (canFinalize.kind === 'bad-user') {
        return (
            <WithHeader model={model}>
                <span className='text-secondary fst-italic pb-2'>
                    Only the user who created the experiment ({model.model.experiment?.created_by ?? '?'}) can finalize
                    it.
                </span>
            </WithHeader>
        );
    }

    return (
        <ScrollBox className='pb-2'>
            <WithHeader model={model}>
                <Controls model={model} />
            </WithHeader>
        </ScrollBox>
    );
}

function WithHeader({ children, model }: { children: ReactNode; model: HTE2MSFinalizeModel }) {
    return (
        <div className='vstack gap-2 mx-auto mt-4' style={{ width: 800 }}>
            <h2>Finalize Experiment</h2>
            <Headers model={model} />
            {children}
        </div>
    );
}

function Headers({ model }: { model: HTE2MSFinalizeModel }) {
    const dataSource = useBehavior(model.model.state.info);

    return (
        <>
            <span className='text-secondary fst-italic pb-2'>
                Upload a crude plate barcode to finalize this experiment and proceed to signing. The experiment will no
                longer be editable after finalization.
            </span>

            {dataSource.kind === 'foundry' && !!dataSource.experiment.executed_on && (
                <InlineAlert className='mb-2'>
                    You can review the <b>Product and Source Plate Maps</b>, <b>Reactions</b>, and <b>Observations</b>{' '}
                    in the{' '}
                    <Link
                        to={`/hte/${dataSource.experiment.id}/details`}
                        className='fw-bold'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Details
                        <FontAwesomeIcon size='sm' className='ms-1 text-primary' icon={faArrowUpRightFromSquare} />
                    </Link>{' '}
                    page for this experiment
                </InlineAlert>
            )}
        </>
    );
}

const LabelWidth = 200;
function Controls({ model }: { model: HTE2MSFinalizeModel }) {
    const account = useBehavior(AuthService.account);
    const dataSource = useBehavior(model.model.state.info);
    const data = useBehavior(model.state.data);
    const canFinalize = useBehavior(model.state.canFinalize);

    if (dataSource.kind !== 'foundry') return <div className='mt-4'>Invalid state</div>;

    const alreadyFinalized = canFinalize.kind === 'already-finalized';

    return (
        <>
            {alreadyFinalized && (
                <InlineAlert icon={faCheck} variant='success' className='mb-2'>
                    The experiment has been finalized on{' '}
                    {parseFileSystemDate(dataSource.experiment.locked_on!).toLocaleString()}
                </InlineAlert>
            )}

            <LabeledInput label='Logged in User' labelWidth={LabelWidth}>
                {account?.username}
            </LabeledInput>
            <LabeledInput label='Executed On' labelWidth={LabelWidth}>
                {dataSource.experiment.executed_on
                    ? parseFileSystemDate(dataSource.experiment.executed_on).toLocaleDateString()
                    : 'n/a'}
            </LabeledInput>
            <LabeledInput label='Full Name' labelWidth={LabelWidth}>
                {alreadyFinalized && data.full_name}
                {!alreadyFinalized && (
                    <TextInput
                        value={data.full_name}
                        placeholder={account?.name ?? 'Enter your full name to finalize the experiment'}
                        setValue={(v) => model.state.data.next({ ...data, full_name: v.trim() })}
                    />
                )}
            </LabeledInput>
            <LabeledInput label='Comment' labelWidth={LabelWidth} labelAlign='flex-start'>
                {alreadyFinalized && (data.comment || <span className='text-seconday'>n/a</span>)}
                {!alreadyFinalized && (
                    <TextInput
                        textarea
                        value={data.comment ?? ''}
                        placeholder='Enter any comments about the experiment'
                        setValue={(v) => model.state.data.next({ ...data, comment: v.trim() })}
                    />
                )}
            </LabeledInput>
            <LabeledInput label='' labelWidth={LabelWidth}>
                <Form.Check
                    id='hte-signature-finalize'
                    label={EXPERIMENT_ACKNOWLEDGEMENT}
                    type='checkbox'
                    checked={data.acknowledged_policy}
                    disabled={alreadyFinalized}
                    onChange={(e) => model.state.data.next({ ...data, acknowledged_policy: e.target.checked })}
                />
            </LabeledInput>
            <div className='mb-4 text-end'>
                <FinalizeButton model={model.model} />
            </div>
        </>
    );
}

function FinalizeButton({ model }: { model: HTE2MSModel }) {
    const canFinalize = useBehavior(model.finalization.state.canFinalize);

    const submit = () => {
        DialogService.open({
            type: 'generic',
            wrapOk: true,
            onOk: () => model.finalization.finalize(),
            title: 'Finalize Experiment',
            confirmButtonContent: 'Submit',
            content: FinalizeContents,
        });
    };

    return (
        <IconButton onClick={submit} variant='primary' icon={faCheck} disabled={canFinalize.kind !== 'yes'}>
            Finalize
        </IconButton>
    );
}

function FinalizeContents() {
    return (
        <>
            <p>Do you want to finalize the experiment? This will:</p>
            <ul className='ps-4'>
                <li className='text-warning'>
                    Lock the experiment; future edits to it will only be possible via amendments
                </li>
                <li>Create a virtual purified plate</li>
                <li>
                    Assign <b>Submitted Purity</b> to batches that were not promoted
                </li>
            </ul>
        </>
    );
}
