import { ReactNode } from 'react';

export function PropertyNameValue({
    field,
    value,
    inline,
    title,
    titleClassName,
}: {
    field: string;
    value: string | ReactNode;
    inline?: boolean;
    title?: string;
    titleClassName?: string;
}) {
    return (
        <div className={inline ? 'me-4 d-inline-flex' : 'd-flex'}>
            <div
                className={`text-secondary text-end me-2 insight-property-name-label ${titleClassName ?? ''}`}
                title={title}
            >
                {field}:
            </div>
            <div className='text-body fw-bold text-break overflow-hidden'>{value}</div>
        </div>
    );
}
