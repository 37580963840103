import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useCallback, useMemo, useRef } from 'react';
import { DropzoneState, FileRejection, useDropzone } from 'react-dropzone';
import { BehaviorSubject } from 'rxjs';
import useBehavior from '../../../lib/hooks/useBehavior';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { reportErrorAsToast } from '../../../lib/util/errors';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import Loading from '../Loading';

// NOTE: using a custom file extension validator to accept/reject files
// react-dropzone has an 'accept' property which can be passed a MIMEType,
// which I thought would be nice, but .sdf files don't get recognized
// so I did this instead.
function getFileExtensionValidator(extensions: string[]) {
    return (file: File) => {
        const pieces = file.name.split('.');
        const extension = `.${pieces[pieces.length - 1].toLowerCase()}`;
        if (!extensions.includes(extension)) {
            return {
                code: 'invalid-extension',
                message: 'File type not accepted.',
            };
        }
        return null;
    };
}

interface MultiFileUploadProps {
    filesSubject: BehaviorSubject<File[]>;
    label: string;
    extensions: string[];
    numFiles: number;
    hideFilename?: boolean;
}

export function MultiFileUpload({ filesSubject, label, extensions, numFiles = 1, hideFilename }: MultiFileUploadProps) {
    const files = useBehavior(filesSubject);

    const onDrop = useCallback((acceptedFiles: any) => {
        if (acceptedFiles.length) {
            const totalNumFiles = acceptedFiles.length + filesSubject.value.length;
            if (totalNumFiles <= numFiles) {
                filesSubject.next([...filesSubject.value, ...acceptedFiles]);
            } else {
                filesSubject.next(acceptedFiles);
            }
        }
    }, []);

    const state = useDropzone({
        onDrop,
        validator: getFileExtensionValidator(extensions),
        maxFiles: Math.min(numFiles, 1000),
    });

    return (
        <div>
            <p>{label}</p>
            <FileDropArea state={state} />
            {!hideFilename && (
                <div className='text-primary f-6'>
                    {files.map((f) => (
                        <p key={f.name}>{f.name}</p>
                    ))}
                </div>
            )}
        </div>
    );
}

interface SingleFileUploadProps {
    fileSubject: BehaviorSubject<File | null>;
    label: ReactNode;
    extensions: string[];
    inline?: boolean;
    doNotShowFilename?: boolean;
    noPadding?: boolean;
}

export function SingleFileUpload({
    fileSubject,
    label,
    extensions,
    inline,
    doNotShowFilename,
    noPadding,
}: SingleFileUploadProps) {
    const file = useBehavior(fileSubject);

    const onDrop = useCallback((acceptedFiles: any) => {
        if (acceptedFiles.length) {
            fileSubject.next(acceptedFiles[0]);
        }
    }, []);

    const state = useDropzone({ onDrop, validator: getFileExtensionValidator(extensions), maxFiles: 1 });

    return (
        <div>
            {!inline && (typeof label === 'string' ? <p>{label}</p> : label)}
            <FileDropArea
                state={state}
                inline={inline}
                title={inline ? file?.name ?? label : undefined}
                noPadding={noPadding}
            />
            {!doNotShowFilename && !inline && <div className='text-primary f-6'>{file && file.name}</div>}
        </div>
    );
}

interface SingleFileUploadV2Props {
    file: File | undefined;
    onDrop: (acceptedFiles: any[]) => void;
    label: ReactNode;
    extensions: string[];
    inline?: boolean;
    doNotShowFilename?: boolean;
    noPadding?: boolean;
}

export function SingleFileUploadV2({
    file,
    onDrop,
    label,
    extensions,
    inline,
    noPadding,
    doNotShowFilename,
}: SingleFileUploadV2Props) {
    const state = useDropzone({ onDrop, validator: getFileExtensionValidator(extensions), maxFiles: 1 });

    return (
        <div>
            {!inline && (typeof label === 'string' ? <p>{label}</p> : label)}
            <FileDropArea
                state={state}
                inline={inline}
                title={inline ? file?.name ?? label : undefined}
                noPadding={noPadding}
            />
            {!doNotShowFilename && !inline && <div className='text-primary f-6'>{file && file.name}</div>}
        </div>
    );
}

interface SimpleMultiFileUploadProps {
    fileSubject: BehaviorSubject<File[] | null>;
    label: ReactNode;
    extensions: string[];
    inline?: boolean;
    doNotShowFilename?: boolean;
    noPadding?: boolean;
}

export function SimpleMultiFileUpload({
    fileSubject,
    label,
    extensions,
    inline,
    doNotShowFilename,
    noPadding,
}: SimpleMultiFileUploadProps) {
    const files = useBehavior(fileSubject);

    const onDrop = useCallback((acceptedFiles: any) => {
        fileSubject.next(acceptedFiles.length ? acceptedFiles : null);
    }, []);

    const state = useDropzone({ onDrop, validator: getFileExtensionValidator(extensions), multiple: true });

    let title: ReactNode;
    if (!files?.length || doNotShowFilename) {
        title = label;
    } else if (files.length === 1) {
        title = files[0].name;
    } else {
        title = `${files.length} files (${files
            .slice(0, 3)
            .map((f) => f.name)
            .join(', ')}${files.length > 3 ? ', ...' : ''})`;
    }

    return (
        <div>
            {!inline && (typeof label === 'string' ? <p>{label}</p> : label)}
            <FileDropArea state={state} inline={inline} title={inline ? title : undefined} noPadding={noPadding} />
            {!inline && !!files && <div className='text-primary f-6'>{title}</div>}
        </div>
    );
}

interface SimpleMultiFileUploadV2Props {
    files: File[];
    onDrop: (acceptedFiles: any[]) => void;
    label: ReactNode;
    extensions: string[];
    inline?: boolean;
    doNotShowFilename?: boolean;
    noPadding?: boolean;
}

export function SimpleMultiFileUploadV2({
    files,
    onDrop,
    label,
    extensions,
    inline,
    doNotShowFilename,
    noPadding,
}: SimpleMultiFileUploadV2Props) {
    const state = useDropzone({ onDrop, validator: getFileExtensionValidator(extensions), multiple: true });

    let title: ReactNode;
    if (!files?.length || doNotShowFilename) {
        title = label;
    } else if (files.length === 1) {
        title = files[0].name;
    } else {
        title = `${files.length} files (${files
            .slice(0, 3)
            .map((f) => f.name)
            .join(', ')}${files.length > 3 ? ', ...' : ''})`;
    }

    return (
        <div>
            {!inline && (typeof label === 'string' ? <p>{label}</p> : label)}
            <FileDropArea state={state} inline={inline} title={inline ? title : undefined} noPadding={noPadding} />
            {!inline && !!files && <div className='text-primary f-6'>{title}</div>}
        </div>
    );
}

const focusStyle = {
    border: '2px solid var(--bs-primary)',
};

const hoverStyle = {
    backgroundColor: 'rgba(255,255,255,0.1)',
};

function FileDropArea({
    state,
    inline,
    title,
    noPadding,
}: {
    state: DropzoneState;
    inline?: boolean;
    title?: ReactNode;
    noPadding?: boolean;
}) {
    const { getRootProps, getInputProps, isFocused, fileRejections, isDragActive } = state;

    const style = useMemo(
        () => ({
            ...(isFocused ? focusStyle : {}),
            ...(isDragActive ? hoverStyle : {}),
        }),
        [isFocused, isDragActive]
    );

    return (
        <>
            <div
                {...getRootProps({ style })}
                className={`${noPadding ? '' : 'my-2'} text-center entos-file-upload${inline ? '-inline' : ''}`}
            >
                <input {...getInputProps()} />
                {!inline && (
                    <div className='mb-3'>
                        <FontAwesomeIcon icon={faUpload} fixedWidth size='2x' color='var(--bs-primary)' />
                    </div>
                )}
                {title ?? 'Drag and Drop, or click to browse files'}
            </div>
            {fileRejections.length > 0 && (
                <div className='text-left text-danger'>
                    {fileRejections.map((f) => (
                        <FileRejectionLine key={f.file.name} fileRejection={f} />
                    ))}
                </div>
            )}
        </>
    );
}

function FileRejectionLine({ fileRejection }: { fileRejection: FileRejection }) {
    return (
        <div>
            {fileRejection.file.name} - {fileRejection.errors.map((e) => e.message).join(', ')}
        </div>
    );
}

export interface UploadFileActionProps {
    label: ReactNode;
    multiple?: boolean;
    extensions: string[];
    inline?: boolean;
    doNotShowFilename?: boolean;
    noPadding?: boolean;
    // eslint-disable-next-line react/no-unused-prop-types
    action: (files: File[]) => Promise<any>;
}

class UploadFileActionModel extends ReactiveModel {
    file = new BehaviorSubject<File | File[] | null>(null);
    isLoading = new BehaviorSubject(false);

    private async applyAction(file: File | File[] | null) {
        if (!file || !this.props) return;

        const files = Array.isArray(file) ? file : [file];
        if (!files.length) return;

        this.isLoading.next(true);
        try {
            await this.props.action(files);
        } catch (err) {
            reportErrorAsToast('Upload', err);
        } finally {
            this.isLoading.next(false);
            this.file.next(null);
        }
    }

    mount(): void {
        this.subscribe(this.file, (file) => this.applyAction(file));
    }

    props: UploadFileActionProps | undefined = undefined;
}

export function AsyncFileUploadHelper(props: UploadFileActionProps) {
    const _model = useRef<UploadFileActionModel>();
    if (!_model.current) _model.current = new UploadFileActionModel();
    const model = _model.current;
    model.props = props;
    useMountedModel(model);
    const isLoading = useBehavior(model.isLoading);

    const { multiple, label, extensions, inline, doNotShowFilename, noPadding } = props;

    let ctrl;
    if (multiple) {
        ctrl = (
            <SimpleMultiFileUpload
                fileSubject={model.file as any}
                label={label}
                extensions={extensions}
                inline={inline}
                doNotShowFilename={doNotShowFilename}
                noPadding={noPadding}
            />
        );
    } else {
        ctrl = (
            <SingleFileUpload
                fileSubject={model.file as any}
                label={label}
                extensions={extensions}
                inline={inline}
                doNotShowFilename={doNotShowFilename}
                noPadding={noPadding}
            />
        );
    }

    if (!isLoading) return ctrl;
    return (
        <div className='d-flex align-items-center justity-content-center position-relative'>
            <div style={{ visibility: 'hidden' }}>{ctrl}</div>
            <div className='position-absolute' style={{ inset: 0 }}>
                <Loading inline />
            </div>
        </div>
    );
}
