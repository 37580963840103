// adapted from https://codepen.io/njmcode/pen/NWdYBy

import { Color } from 'molstar/lib/mol-util/color';

export function rgb2hsl(color: number[]): number[] {
    const r = color[0] / 255;
    const g = color[1] / 255;
    const b = color[2] / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0;
    let s = 0;
    const l = (max + min) / 2;

    if (max !== min) {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
            default:
                break;
        }
        h /= 6;
    }

    return [h, s, l];
}

function hue2rgb(p: number, q: number, _t: number) {
    let t = _t;
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
}

export function hsl2rgb(color: number[], out: number[]) {
    let l = color[2];

    if (color[1] === 0) {
        l = Math.round(l * 255);
        out[0] = l; // eslint-disable-line
        out[1] = l; // eslint-disable-line
        out[2] = l; // eslint-disable-line
        return out;
    }
    const s = color[1];
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    const r = hue2rgb(p, q, color[0] + 1 / 3);
    const g = hue2rgb(p, q, color[0]);
    const b = hue2rgb(p, q, color[0] - 1 / 3);
    out[0] = Math.round(r * 255); // eslint-disable-line
    out[1] = Math.round(g * 255); // eslint-disable-line
    out[2] = Math.round(b * 255); // eslint-disable-line
    return out;
}

export function interpolateHSLtoRGB(hsl1: number[], hsl2: number[], factor: number = 0.5) {
    const ret = [...hsl1];
    const f = Math.max(Math.min(factor, 1), 0);
    for (let i = 0; i < 3; i++) {
        ret[i] += f * (hsl2[i] - hsl1[i]);
    }
    return hsl2rgb(ret, ret);
}

export function darkenColor(rgb: number[], f: number) {
    const hsl = rgb2hsl(rgb);
    hsl[2] *= f;
    return hsl2rgb(hsl, hsl);
}

export function createPalleteRGB(start: number[], end: number[], n: number) {
    const from = rgb2hsl(start);
    const to = rgb2hsl(end);
    const ret: number[][] = [];

    for (let i = 0; i < n; i++) {
        ret.push(interpolateHSLtoRGB(from, to, n === 1 ? 0.5 : i / (n - 1)));
    }

    return ret;
}

export function parseColor(color: string) {
    if (color[0] === '#') return Color.fromHexStyle(color);
    if (color.startsWith('rgb')) {
        const [r, g, b] = color
            .slice(color.indexOf('(') + 1, color.indexOf(')'))
            .split(',')
            .map((x) => +x.trim());
        return Color.fromRgb(r, g, b);
    }
    return Color(0x0);
}

export function toRGBString([r, g, b]: number[]) {
    return `rgb(${r}, ${g}, ${b})`;
}

const _paletteCache = new Map<string, { rgb: string; raw: number[] }[]>();
export function getCachedPallete(start: number[], end: number[], n: number) {
    const key = `${toRGBString(start)}:${toRGBString(end)}:${n}`;
    if (_paletteCache.has(key)) return _paletteCache.get(key)!;

    const pallete = createPalleteRGB(start, end, n).map((c) => ({ rgb: toRGBString(c), raw: c }));
    _paletteCache.set(key, pallete);
    return pallete;
}

export function colorToRgb(color: Color) {
    return Color.toRgb(color);
}

export function getLuminance(color: string) {
    const [r, g, b] = colorToRgb(parseColor(color));
    return 0.299 * (r / 255) + 0.587 * (g / 255) + 0.114 * (b / 255); // source: https://www.w3.org/TR/AERT/#color-contrast
}
