import saveAs from 'file-saver';
import { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { LabeledInput, TextInput } from '../../../components/common/Inputs';
import { LogModel } from '../../../components/common/Log';
import { PropertyNameValue } from '../../../components/common/PropertyNameValue';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import { ToastService } from '../../../lib/services/toast';
import { arrayToCsv } from '../../../lib/util/arrayToCsv';
import { reportErrorAsToast, tryGetErrorMessage } from '../../../lib/util/errors';
import { roundValue } from '../../../lib/util/roundValues';
import { formatUnit } from '../../../lib/util/units';
import { trimValue } from '../../../lib/util/validators';
import { Batch, BatchIdentifiers, Sample } from '../../Compounds/compound-api';
import { HTESolvent } from '../../HTE/experiment-data';
import { SelectSolvent } from '../../HTE/steps/reactants-model';
import { formatSampleContentInline, Vial } from '../ecm-api';
import { BatchLink, ECMCommonUploadInfo, ECMPageTemplate, ECMWorkflowMode, ECMWorkflowModeSelect } from '../ecm-common';
import {
    ecmAPITemplate,
    ECMBatchWorkflowBase,
    ECMBatchWorkflowWrapper,
    ecmGatherMessages,
    ECMManualWorkflowBase,
    ECMManualWorkflowInputHelper,
    ECMManualWorkflowStatus,
    ECMManualWorkflowWrapper,
    ecmWorkflowEntrySummary,
    uploadECMCSV,
} from './common';

interface PrepareSolubilizeInput {
    row_index?: number;
    barcode?: string;
    rack_barcode?: string;
    well?: string;
    concentration?: number;
    concentration_unit?: string;
    solvent?: string;
}

interface PrepareSolubilizeEntry {
    input: PrepareSolubilizeInput;

    errors: string[];
    warnings: string[];

    vial?: Vial;
    barcode?: string;
    batch?: Batch;
    batch_identifiers?: BatchIdentifiers;
    solubilized_sample?: Sample;
    rack_barcode?: string;
    well?: string;
}

type PrepareSolubilizeEntries = PrepareSolubilizeEntry[];

const SolubilizeAPI = ecmAPITemplate<PrepareSolubilizeInput[], PrepareSolubilizeEntries>('vials/solubilize');

interface ManualVialSolubilizeWorkflowInput {
    barcode: string;
    concentration: string;
    solvent?: HTESolvent;
}

const DefaultEmptyInput: ManualVialSolubilizeWorkflowInput = {
    barcode: '',
    concentration: '10',
    solvent: 'DMSO',
};

class ManualVialSolubilizeWorkflow implements ECMManualWorkflowBase<PrepareSolubilizeEntry> {
    inputs = {
        barcode: { current: null } as MutableRefObject<HTMLInputElement | null>,
        concentration: { current: null } as MutableRefObject<HTMLInputElement | null>,
    };

    state = {
        input: new BehaviorSubject<ManualVialSolubilizeWorkflowInput>(DefaultEmptyInput),
        entry: new BehaviorSubject<PrepareSolubilizeEntry | undefined>(undefined),
        autosubmit: new BehaviorSubject<boolean>(false),
        preparing: new BehaviorSubject<boolean>(false),
        submitting: new BehaviorSubject<boolean>(false),
    };

    log = new LogModel();

    get autosubmit() {
        return this.state.autosubmit.value;
    }

    get isInputValid(): boolean {
        // TODO: this could be improved
        const input = this.state.input.value;
        return input.barcode.length > 0 && input.concentration.length > 0;
    }

    async prepare() {
        ToastService.remove('warn-autosubmit');

        if (!this.isInputValid) {
            return;
        }

        try {
            this.state.preparing.next(true);
            const input = this.state.input.value;
            const entries = await SolubilizeAPI.prepare([
                {
                    barcode: input.barcode,
                    concentration: input.concentration.trim() ? +input.concentration : undefined,
                    concentration_unit: 'mM',
                    solvent: input.solvent || undefined,
                },
            ]);

            const entry = entries[0];
            this.state.entry.next(entry);
            if (!entry) return;

            if (this.autosubmit && entry.errors.length === 0 && entry.warnings.length === 0) {
                this.submit();
                return;
            }

            if (this.autosubmit && entry.errors.length === 0 && entry.warnings.length > 0) {
                ToastService.show({
                    id: 'warn-autosubmit',
                    type: 'warning',
                    message: 'Auto-submit paused due to a warning.\nPlease fix the issue or submit manually.',
                });
            }

            requestAnimationFrame(() => this.inputs.barcode.current?.focus());
        } catch (err) {
            reportErrorAsToast('Solubilize', err);
            this.state.entry.next(undefined);
        } finally {
            this.state.preparing.next(false);
        }
    }

    async submit() {
        const entry = this.state.entry.value;
        if (!entry) return;

        try {
            this.state.submitting.next(true);

            await SolubilizeAPI.upload([entry]);
            // NOTE: For testing to fake upload
            // await new Promise((res) => {
            //     setTimeout(res, 500);
            // });

            this.log.message(
                'success',
                `Vial ${entry.barcode} solubilized to ${formatSampleContentInline(entry.solubilized_sample!)}.`
            );
            this.state.input.next({ ...this.state.input.value, barcode: '' });
            this.state.entry.next(undefined);
            requestAnimationFrame(() => this.inputs.barcode.current?.focus());
        } catch (err) {
            this.log.message('danger', `Vial ${entry?.barcode} error: ${tryGetErrorMessage(err)}`);
        } finally {
            this.state.submitting.next(false);
        }
    }
}

class BatchVialSolubilizeWorkflow implements ECMBatchWorkflowBase<PrepareSolubilizeEntries> {
    state = {
        submitting: new BehaviorSubject<boolean>(false),
    };
    entries: PrepareSolubilizeEntries;
    summary: ECMBatchWorkflowBase['summary'];
    messages: ECMBatchWorkflowBase['messages'];

    async submit() {
        if (this.summary.numErrors > 0 || !this.entries.length) {
            return;
        }

        try {
            this.state.submitting.next(true);

            await SolubilizeAPI.upload(this.entries);
            // NOTE: For testing to fake upload
            // await new Promise((res) => {
            //     setTimeout(res, 500);
            // });

            this.fileSubject.next(null);
            ToastService.show({
                type: 'success',
                message: `Successfully updated ${this.entries.length} vial${
                    this.entries.length === 1 ? '' : 's'
                } in Foundry.`,
            });
        } catch (err) {
            reportErrorAsToast('Solubilize', err);
        } finally {
            this.state.submitting.next(false);
        }
    }

    saveCSV() {
        const csv = solubilizeToCSV(this.entries);
        saveAs(new Blob([csv], { type: 'text/csv' }), `ecm-solubilize-${Date.now()}.csv`);
    }

    constructor(
        public files: [filename: string, entries: PrepareSolubilizeEntries][],
        private fileSubject: BehaviorSubject<File[] | null>
    ) {
        this.entries = files.flatMap((f) => f[1]);
        this.summary = ecmWorkflowEntrySummary(this.entries);
        this.messages = ecmGatherMessages(files);
    }
}

export function ECMSolubilize() {
    const [mode, setMode] = useState<ECMWorkflowMode>('manual');

    return (
        <ECMPageTemplate page='solubilize' withFooter>
            <ECMWorkflowModeSelect mode={mode} setMode={setMode} />
            {mode === 'manual' && <ManualRoot />}
            {mode === 'batch' && <BatchRoot />}
        </ECMPageTemplate>
    );
}

const ManualModel = new ManualVialSolubilizeWorkflow();

function ManualRoot() {
    const model = ManualModel;

    return (
        <ECMManualWorkflowWrapper
            model={model}
            input={<ManualInput model={model} />}
            status={<ManualStatus model={model} />}
        />
    );
}

function ManualInput({ model }: { model: ManualVialSolubilizeWorkflow }) {
    const preparing = useBehavior(model.state.preparing);
    const submitting = useBehavior(model.state.submitting);
    const disabled = preparing || submitting;

    const input = useBehavior(model.state.input);

    return (
        <ECMManualWorkflowInputHelper model={model} disabled={disabled} preparing={preparing}>
            <LabeledInput label='Solvent' className='ecm-manual-inputs-row'>
                <SelectSolvent
                    value={input.solvent}
                    setValue={(solvent) => model.state.input.next({ ...input, solvent })}
                />
            </LabeledInput>
            <LabeledInput label='Concentration (mM)' className='ecm-manual-inputs-row'>
                <Form.Control
                    value={input.concentration}
                    ref={model.inputs.concentration}
                    onChange={(e) => model.state.input.next({ ...input, concentration: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.concentration) model.inputs.barcode.current?.focus();
                    }}
                    disabled={disabled}
                />
            </LabeledInput>
            <LabeledInput label='Barcode' className='ecm-manual-inputs-row'>
                <Form.Control
                    value={input.barcode}
                    ref={model.inputs.barcode}
                    onChange={(e) => model.state.input.next({ ...input, barcode: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && input.barcode) model.prepare();
                    }}
                    disabled={disabled}
                    autoFocus
                />
            </LabeledInput>
        </ECMManualWorkflowInputHelper>
    );
}

function ManualStatus({ model }: { model: ManualVialSolubilizeWorkflow }) {
    return (
        <ECMManualWorkflowStatus model={model}>
            {(entry) => (
                <>
                    <PropertyNameValue field='Barcode' value={entry.barcode ?? entry.input.barcode} />
                    <PropertyNameValue field='Vial Status' value={entry.vial?.status ?? 'n/a'} />
                    <PropertyNameValue
                        field='Batch Identifier'
                        value={
                            entry.batch_identifiers?.identifier ? (
                                <BatchLink identifier={entry.batch_identifiers?.identifier} withQuery />
                            ) : (
                                'n/a'
                            )
                        }
                    />
                    <PropertyNameValue
                        field='Old Sample'
                        value={entry.vial?.sample ? formatSampleContentInline(entry.vial.sample!) : 'n/a'}
                    />
                    <PropertyNameValue
                        field='New Sample'
                        value={entry.solubilized_sample ? formatSampleContentInline(entry.solubilized_sample) : 'n/a'}
                    />
                    <PropertyNameValue
                        field='Solvent to Add'
                        value={
                            entry.solubilized_sample
                                ? formatUnit(entry.solubilized_sample.solvent_volume! * 1e3, 'L', 'u')
                                : 'n/a'
                        }
                    />
                </>
            )}
        </ECMManualWorkflowStatus>
    );
}

async function uploadCSV(
    files: File[] | null,
    fileSubject: BehaviorSubject<File[] | null>,
    overridesSubject?: BehaviorSubject<Record<string, any>>
) {
    return uploadECMCSV(SolubilizeAPI, BatchVialSolubilizeWorkflow, files, fileSubject, overridesSubject);
}

function BatchRoot() {
    const [model, loadModel] = useAsyncAction<BatchVialSolubilizeWorkflow | undefined>();

    const upload = useCallback(
        (
            files: File[] | null,
            fileSubject: BehaviorSubject<File[] | null>,
            overridesSubject?: BehaviorSubject<Record<string, any>>
        ) => loadModel(uploadCSV(files, fileSubject, overridesSubject)),
        [loadModel]
    );
    const info = useMemo(() => <BatchUploadInfo />, []);

    return (
        <ECMBatchWorkflowWrapper
            model={model}
            upload={upload}
            info={info}
            Overrides={BatchUploadOverrides}
            ExportButton={BatchUploadExportButton}
        />
    );
}

function BatchUploadExportButton({ model }: { model: BatchVialSolubilizeWorkflow }) {
    return (
        <Button size='sm' variant='outline-primary' onClick={() => model.saveCSV()}>
            Export CSV
        </Button>
    );
}

function BatchUploadOverrides({ overridesSubject }: { overridesSubject: BehaviorSubject<Record<string, any>> }) {
    const overrides = useBehavior(overridesSubject);

    return (
        <div className='ecm-overrides-panel m-auto mt-4'>
            <h5>Global Solubilize Values</h5>
            <span className='text-secondary'>
                Any values uploaded from a file will be overwritten by the following entries.
            </span>
            <div className='ecm-manual-inputs mt-1'>
                <LabeledInput label='Solvent' className='ecm-manual-inputs-row' tooltip='Optional'>
                    <SelectSolvent
                        value={overrides.solvent}
                        setValue={(solvent) => overridesSubject.next({ ...overrides, solvent })}
                        allowEmpty
                    />
                </LabeledInput>
                <LabeledInput label='Concentration (mM)' className='ecm-manual-inputs-row' tooltip='Optional'>
                    <TextInput
                        value={overrides.Concentration ?? ''}
                        formatValue={trimValue}
                        setValue={(v) => {
                            const next = { ...overrides, Concentration: v || undefined };
                            if (!v) delete next.Concentration;
                            overridesSubject.next(next);
                        }}
                    />
                </LabeledInput>
                <LabeledInput label='Rack Barcode' className='ecm-manual-inputs-row' tooltip='Optional'>
                    <TextInput
                        value={overrides['Rack Barcode'] ?? ''}
                        formatValue={trimValue}
                        setValue={(v) => {
                            const next = { ...overrides, 'Rack Barcode': v || undefined };
                            if (!v) delete next['Rack Barcode'];
                            overridesSubject.next(next);
                        }}
                    />
                </LabeledInput>
            </div>
        </div>
    );
}

function BatchUploadInfo() {
    return (
        <ECMCommonUploadInfo
            required={['Barcode', 'Concentration']}
            optional={['Solvent (=DMSO)', 'Rack Barcode', 'Rack Well', 'Concentration Unit (=mM)']}
        />
    );
}

const SolubilizeCSVColumns = [
    'Rack Barcode',
    'Barcode',
    'Well',
    'Solvent To Add',
    'Solvent To Add Unit',
    'Batch Identifier',
] as const;

type SolubilizeCSVRow = Record<(typeof SolubilizeCSVColumns)[number], string | number>;

function solubilizeToCSV(entries: PrepareSolubilizeEntries) {
    const rows: SolubilizeCSVRow[] = [];

    for (const entry of entries) {
        if (!entry.barcode) continue;

        rows.push({
            'Rack Barcode': entry.rack_barcode ?? '',
            Barcode: entry.barcode ?? '',
            Well: entry.well ?? '',
            'Solvent To Add': entry.solubilized_sample?.solvent_volume
                ? roundValue(3, entry.solubilized_sample.solvent_volume * 1e9)
                : '',
            'Solvent To Add Unit': entry.solubilized_sample?.solvent_volume ? 'uL' : '',
            'Batch Identifier': entry.batch_identifiers?.identifier ?? '',
        });
    }

    const csvRows: (string | number)[][] = [SolubilizeCSVColumns as any];
    for (const r of rows) {
        const row = [];
        for (const c of SolubilizeCSVColumns) row.push(r[c]);
        csvRows.push(row);
    }

    return arrayToCsv(csvRows);
}
