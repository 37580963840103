import { useCallback, useRef } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { TableInstance, useBlockLayout, useFilters, useGlobalFilter, useSortBy, useTable } from 'react-table';
import { SingleFileUpload } from '../../../components/common/FileUpload';
import { defaultColumn } from '../../../components/ReactTable/Controls';
import { ReactTableGlobalFilter } from '../../../components/ReactTable/Filters';
import { useAsyncAction } from '../../../lib/hooks/useAsyncAction';
import useBehavior from '../../../lib/hooks/useBehavior';
import useBehaviorSubject from '../../../lib/hooks/useBehaviorSubject';
import { Reaction } from '../experiment-data';
import { HTEExperimentModel } from '../experiment-model';
import { HTEExperimentTable } from './common';

export function ReactionsStep({ model }: { model: HTEExperimentModel }) {
    // TODO: this doesn't need the global filter
    const globalFilter = useRef<string>('');
    const design = useBehavior(model.state.design);
    const table = useTable<Reaction>(
        {
            columns: model.reactions.columns,
            data: design.reactions,
            defaultColumn,
            initialState: {
                globalFilter: globalFilter.current,
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useBlockLayout
    );

    const setGlobalFilter = useCallback(
        (v: any) => {
            globalFilter.current = v;
            table.setGlobalFilter(v);
        },
        [table.setGlobalFilter]
    );

    return (
        <div className='hte-experiment-reactions-step'>
            {!model.reactions.isEmpty && (
                <div className='ps-4 pe-4 pt-4 pb-2'>
                    <ReactionsHeader table={table} setGlobalFilter={setGlobalFilter} model={model} />
                </div>
            )}

            <div className='hte-experiment-reactions-content'>
                {model.reactions.isEmpty && <UploadControl model={model} />}
                {!model.reactions.isEmpty && <HTEExperimentTable table={table} />}
            </div>
        </div>
    );
}

const BASIC_COLUMNS = [
    'MSD Identifier',
    'BB Identifier',
    'Product Identifier',
    'BB Group',
    'MSD Barcode',
    'BB Barcode',
];

const OPTIONAL_COLUMNS = ['MSD Order', 'BB Order', 'Well Location'];

function UploadInfo() {
    return (
        <div className='hte-experiment-reactions-upload-info m-auto mt-4'>
            <h5 className='mt-2'>Upload a CSV/XLS file with reactions</h5>
            <dl className='row'>
                <dt className='col-sm-3'>Required columns</dt>
                <dd className='col-sm-9'>
                    <code className='code'>{BASIC_COLUMNS.join(', ')}</code>
                </dd>
                <dt className='col-sm-3'>Optional columns</dt>
                <dd className='col-sm-9'>
                    <code className='code'>{OPTIONAL_COLUMNS.join(', ')}</code>
                </dd>
                <dt className='col-sm-3'>Notes</dt>
                <dd className='col-sm-9'>
                    <div>
                        <code className='code'>MSD/BB Identifier</code> must be a Batch Identifier
                    </div>
                    <div>
                        <code className='code'>Product Identifier</code> can be either a Compound or a Batch Identifier.
                        Batches will be created automatically during finalization if a Compound Identifier is specified.
                    </div>
                </dd>
            </dl>
        </div>
    );
}

function UploadControl({ model }: { model: HTEExperimentModel }) {
    const fileSubject = useBehaviorSubject<File | null>(null);
    const file = useBehavior(fileSubject);
    const [loadState, upload] = useAsyncAction();

    return (
        <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-center'>
            <SingleFileUpload fileSubject={fileSubject} extensions={['.csv', '.xls', '.xlsx']} label={<UploadInfo />} />
            <div className='mb-4'>
                <Button
                    onClick={() => file && upload(model.reactions.upload(file))}
                    disabled={!file || loadState.isLoading}
                >
                    {loadState.isLoading && <Spinner className='me-2' size='sm' animation='border' role='status' />}
                    Submit
                </Button>
            </div>
            <span className='text-secondary'>Development:</span>
            <Button
                variant='outline-secondary'
                onClick={() => upload(model.reactions.addRandomReactions())}
                className='mt-2'
                disabled={loadState.isLoading}
                title='Assumes at least some MSD/BB/ENT batches are registered.'
            >
                Create Random Reactions
            </Button>
        </div>
    );
}

function ReactionsHeader({
    table,
    setGlobalFilter,
    model,
}: {
    table: TableInstance<Reaction>;
    setGlobalFilter: (v: any) => any;
    model: HTEExperimentModel;
}) {
    return (
        <div className='hstack gap-2'>
            <div className='flex-grow-1'>
                <ReactTableGlobalFilter
                    preGlobalFilteredRows={table.preGlobalFilteredRows}
                    globalFilter={table.state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
            </div>
            <div>
                <Button
                    variant='outline-primary'
                    onClick={() => model.reactions.exportReactionCSV()}
                    disabled={table.rows.length === 0}
                >
                    Export CSV
                </Button>
            </div>
            {!model.isLocked && (
                <Button variant='outline-secondary' onClick={() => model.setReactions([])}>
                    Clear
                </Button>
            )}
        </div>
    );
}
