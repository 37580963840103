import api from '../../../api';
import { type DrawResult } from '../../../lib/util/draw-molecules';
import { decodeEntosMsgpack } from '../../../lib/util/serialization';
import { ColumnTableData, columnDataTableStore } from '../../../components/DataTable';
import { Batch, CompoundDetail, Substance } from '../../Compounds/compound-api';

export interface HTEEnumerationReactionInfoEntry {
    id: string;
    short_name: string;
    name: string;
    description?: string;
    kind: string;
    source?: string;
}

export interface HTEEnumerationLoadReactantEntry {
    smiles: string;
    order: number;
    batch?: Batch;
    compound?: CompoundDetail;
}

export interface HTEEnumerationFilterGroup {
    name: string;
    filter_names: string[];
}

export interface HTEEnumerationFilterInfo {
    named_filters: string[];
    filter_groups: HTEEnumerationFilterGroup[];
}

export interface HTEEnumerationApiReactantBase {
    smiles: string;
    order: number;
    excluded_sites?: number[][];
    included_sites?: number[][];
}

export interface HTEEnumerationApiReactionSites {
    smiles: string;
    all_sites_drawing: DrawResult;
    possible_reaction_sites: {
        reaction_site: number[];
        drawn_molecule: DrawResult;
    }[];
}

export const propertiesNames = ['num_h', 'num_acceptors', 'num_donors', 'molecular_weight'] as const;
export type PropertiesName = (typeof propertiesNames)[number];
export interface PropertyFilter {
    // NOTE: matches PROPERTIES_NAMES in autopopulate.py
    name: PropertiesName;
    min_value?: number;
    max_value?: number;
    exact_value?: number;
}
export function propertyNameToTitle(n: PropertiesName) {
    return n
        .split('_')
        .map((word: string) => `${word.charAt(0).toUpperCase()}${word.substring(1)}`)
        .join(' ');
}

export interface CompoundDetailWithFilters extends CompoundDetail {
    substance: string;
    substructure_matches: number[];
    num_h: number;
    num_acceptors: number;
    num_donors: number;
}

export interface SandwichEnumerationResult {
    enumeration: {
        reaction_id: string;
        msd_substance_id: number;
        bb_substance_id: number;
        product_substance_ids: number[];
        errors: string[];
    }[];
    product_substances: Substance[];
}

export const EnumerationAPI = {
    reactionsInfo: async (): Promise<HTEEnumerationReactionInfoEntry[]> => {
        const { data } = await api.client.get(`enumeration/reactions-info`, { responseType: 'arraybuffer' });
        const table = columnDataTableStore<HTEEnumerationReactionInfoEntry>(decodeEntosMsgpack(data));
        return table.toObjects();
    },
    filterInfo: async (): Promise<HTEEnumerationFilterInfo> => {
        const { data } = await api.client.get(`enumeration/filter-info`, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
    enumerate: async (options: {
        reaction_id: string;
        msds_inputs: HTEEnumerationApiReactantBase[];
        bbs_inputs: HTEEnumerationApiReactantBase[];
    }): Promise<[msd_order: number, bb_order: number, result: { products: string[]; error?: string[] }][]> => {
        const { data } = await api.client.post(`enumeration/enumerate`, options, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
    sandwich: async (options: {
        reaction_list: [id: string, msd_substance_id: number, bb_substance_id: number][];
    }): Promise<SandwichEnumerationResult> => {
        const { data } = await api.client.post(`enumeration/sandwich`, options, {
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data, { eoi: 'strip' });
    },
    loadReactants: async (options: { identifiers: string[] }): Promise<HTEEnumerationLoadReactantEntry[]> => {
        const { data } = await api.client.post(`enumeration/load-reactants`, options, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data, { eoi: 'strip' });
    },
    filterProducts: async (options: {
        structures: string[];
        filters: { exclude?: boolean; name?: string; smiles?: string; smarts?: string }[];
    }): Promise<{ filtered_list: string[] }> => {
        const { data } = await api.client.post(`enumeration/filter-products`, options, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
    reactionSites: async (options: {
        reaction_id: string;
        inputs: { smiles: string }[];
    }): Promise<HTEEnumerationApiReactionSites[]> => {
        const { data } = await api.client.post(`enumeration/reaction-sites`, options, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
    reactionSitesSimple: async (options: {
        reaction_id: string;
        inputs: { smiles: string }[];
    }): Promise<number[][][]> => {
        const { data } = await api.client.post(`enumeration/reaction-sites-simple`, options, {
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data);
    },
    findSimilarCompounds: async (options: {
        substructure?: string;
        properties: PropertyFilter[];
    }): Promise<ColumnTableData<CompoundDetailWithFilters>> => {
        const { data } = await api.client.post(`enumeration/find-similar-compounds`, options, {
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data);
    },
    compoundBatches: async (options: {
        compound_ids: readonly number[];
    }): Promise<ColumnTableData<{ compound_id: number; identifier: string }>> => {
        const { data } = await api.client.post(`enumeration/compound-batches`, options, {
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data);
    },
};
