export const RDBSimilarityOptions = [
    ['morgan_fp', 'Morgan'],
    ['morganbv_fp', 'Morgan BV'],
    ['featmorganbv_fp', 'Feat Morgan BV'],
] as const;

export type RDBSimilarityAlgorithm = (typeof RDBSimilarityOptions)[number][0];

export interface RDBCompoundInfo {
    id: number;
    standardized_smiles: string;
    standardized_inchi_key: string;
    molecular_weight: number;
    similarity_score?: number;
}

export interface RDBBatchInfo {
    id: number;
    compound_info_id: number;
    inchi_key: string;
    salt_smiles?: string;
    formula_weight: number;
    compound_info: RDBCompoundInfo;
}

export interface RDBInventoryItem {
    amount_g?: number;
    volume_l?: number;
    generic?: number;
    price: number;
    currency: string;
}

export interface RDBSupplierBatch {
    id: number;
    batch_info_id: number;
    supplier_name: string;
    catalog_name: string;
    source_supplier_id: string;
    purity?: string;
    chemical_name?: string;
    delivery_time?: string;
    availability?: string;
    cas?: string;
    mdl?: string;
    source_smiles: string;
    sds_url?: string;
    url?: string;
    inventory_items: RDBInventoryItem[];
}

export interface RDBSearchEntry {
    batch: RDBBatchInfo;
    suppliers: RDBSupplierBatch[];
    similarity_score?: number;
}
