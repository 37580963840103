import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as d3_scales from 'd3-scale-chromatic';
import { Color } from 'molstar/lib/mol-util/color';
import { Lab } from 'molstar/lib/mol-util/color/spaces/lab';
import { ReactNode, useMemo } from 'react';
import { Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Page } from '../../../components/Layout/Layout';
import { LabeledInput, SimpleSelectOptionInput, TextInput } from '../../../components/common/Inputs';
import useBehavior from '../../../lib/hooks/useBehavior';
import { parseColor, toRGBString } from '../../../lib/util/colors';
import { capitalizeFirst, groupBy } from '../../../lib/util/misc';
import { roundValue } from '../../../lib/util/roundValues';
import {
    PlateVisualColors,
    PlateVisual,
    PlateVisualLabels,
    PlateVisualModel,
    PlateWellColoring,
} from '../../HTE/plate/PlateVisual';
import { getFirstSelectedIndex, getWellIndexLabel } from '../../HTE/plate/utils';
import { ECMARPLayoutNames } from './arp-layouts';
import {
    ECMARPDilutionCurve,
    ECMARPDilutionFactor,
    ECMARPTemplate,
    ECMARPTemplateControlKind,
    ECMARPTemplateWell,
    ECMARPWellInstance,
    ECMRequestKind,
    ECMRequestStatus,
    ECMRequestedSample,
    ECMShippingInfo,
} from './data';

export const CompoundManagementFoundryGroup = 'foundry-compound-management';

export function resolveRSRoute(...path: (string | number | undefined)[]) {
    const route = path.filter((v) => v !== undefined && v !== '').join('/');
    if (!route) return '/ecm/rs';
    return `/ecm/rs/${route}`;
}

export function formatRequestId(id: number | string | undefined) {
    if (id === undefined) return '?';
    return `RQST${(id ?? '').toString().padStart(5, '0')}`;
}

export const RequestSystemPage: Page = {
    href: '/ecm/rs',
    title: 'Requests',
};

export const RequestManagementPage: Page = {
    href: resolveRSRoute('manage'),
    title: 'Manage',
};

const DilutionFactorOptions = [
    ECMARPDilutionFactor.half_log,
    ECMARPDilutionFactor.log,
    ECMARPDilutionFactor.one_to_three,
    ECMARPDilutionFactor.one_to_two,
].map((v) => [v, v] as const);
export function SelectDilution({
    value,
    setValue,
}: {
    value: ECMARPDilutionFactor;
    setValue: (v: ECMARPDilutionFactor) => any;
}) {
    return <SimpleSelectOptionInput value={value} options={DilutionFactorOptions} setValue={setValue} />;
}

// IMPORTANT: must contain either '96 well' or '384 well' in the name for the automation in ecm/requests/manage.py to work
export const PlateLabwareOptions = [
    '96 well Costar 3363 (250uL)',
    '96 well Costar 3361 (2mL)',
    '96 well Costar 3358 (1mL)',
    '384 well LP200 (10uL)',
    '384 well PP200 (60uL)',
    '384 well EchoMS PP2.0 (60uL)',
    '384 well Costar 3657',
].map((v) => [v, v] as const);

export const PlateLabwareOptionSet = new Set(PlateLabwareOptions.map((v) => v[0]));

// IMPORTANT: must contain 'vial' in the name for the automation in ecm/requests/manage.py to work
export const VialLabwareOptions = [
    'Clear high recovery vial (3.6mL)',
    'Amber vial (3.6mL)',
    'Small vial (1.5mL)',
    'Small v-bottom vial (0.2mL)',
].map((v) => [v, v] as const);

export const VialLabwareOptionSet = new Set(VialLabwareOptions.map((v) => v[0]));

export const WetLabwareOptions = [...PlateLabwareOptions, ...VialLabwareOptions];

export function SelectWetLabware({
    value,
    setValue,
    allowEmpty,
    disabled,
}: {
    value: string;
    setValue: (v: string) => any;
    allowEmpty?: boolean;
    disabled?: boolean;
}) {
    return (
        <SimpleSelectOptionInput
            value={value}
            options={WetLabwareOptions}
            setValue={setValue}
            allowEmpty={allowEmpty}
            disabled={disabled}
        />
    );
}

export function SelectDryLabware({
    value,
    setValue,
    allowEmpty,
    disabled,
}: {
    value: string;
    setValue: (v: string) => any;
    allowEmpty?: boolean;
    disabled?: boolean;
}) {
    return (
        <SimpleSelectOptionInput
            value={value}
            options={VialLabwareOptions}
            setValue={setValue}
            allowEmpty={allowEmpty}
            disabled={disabled}
        />
    );
}

export const ARPLabwareOptions = [
    '96 well Falcon 351177 (ARP)',
    '96 well Corning 3903 (ARP)',
    '384 well Corning 3764, black (ARP)',
    '384 well Corning 3765, white (ARP)',
    '384 well Greiner 781280 (ARP)',
    '384 well Labcyte LP0200 (ARP)',
    '384 well PE 6007680 (ARP)',
    '384 well PE 6008280 (ARP)',
    '384 well EchoMS PP2.0 (60uL) (ARP)',
    '1536 well Labcyte LP0400 (EchoMS Certified) (ARP)',
].map((v) => [v, v] as const);
export function SelectARPLabware({ value, setValue }: { value: string; setValue: (v: string) => any }) {
    return <SimpleSelectOptionInput value={value} options={ARPLabwareOptions} setValue={setValue} />;
}

// IMPORTANT: must be kept in sync with ecm/requests/manage.py
export const LabwareLayoutOptions = ['Top to Bottom', 'Left to Right'].map((v) => [v, v] as const);

export function SelectLabwareLayout({
    value,
    setValue,
    disabled,
}: {
    value: string;
    setValue: (v: string) => any;
    disabled?: boolean;
}) {
    return (
        <SimpleSelectOptionInput value={value} options={LabwareLayoutOptions} setValue={setValue} disabled={disabled} />
    );
}

const ARPLayoutOptions = ECMARPLayoutNames.map((v) => [v, v] as const);
export function SelectARPTemplate({ value, setValue }: { value: string; setValue: (v: string) => any }) {
    return <SimpleSelectOptionInput value={value} options={ARPLayoutOptions} setValue={setValue} />;
}

export function ECMRequestStatusBadge({ status, className }: { status: ECMRequestStatus; className?: string }) {
    return (
        <Badge className={`ecm-request-status-badge-${status.replace(' ', '_')} ${className ?? ''}`}>
            {capitalizeFirst(status)}
        </Badge>
    );
}

export function ECMRequestKindBadge({ kind, className }: { kind: ECMRequestKind | 'ship'; className?: string }) {
    return (
        <Badge className={`ecm-request-kind-badge-${kind.toLowerCase()} ${className ?? ''}`}>
            {kind.toUpperCase()}
        </Badge>
    );
}

export function ECMRequestShipBadge({ className }: { className?: string }) {
    return <Badge className={`ecm-request-ship-badge ${className ?? ''}`}>SHIP</Badge>;
}

export function requestHasShippingInfo(si?: ECMShippingInfo) {
    return !!si?.to || !!si?.comment || !!si?.recipient;
}

export function formatRequestedSample(s: ECMRequestedSample, short = false) {
    const components: string[] = [];
    if (typeof s.volume_l === 'number') {
        if (s.volume_l < 1e-6) components.push(`${roundValue(0, s.volume_l * 1e9)} nL`);
        else if (s.volume_l < 1e-3) components.push(`${roundValue(3, s.volume_l * 1e6)} μL`);
        else components.push(`${roundValue(3, s.volume_l * 1e3)} mL`);

        if (typeof s.concentration_M === 'number' && !short) {
            if (s.concentration_M < 1e-6) components.push(`@${roundValue(0, s.concentration_M * 1e9)} nM`);
            else if (s.concentration_M < 1e-3) components.push(`@${roundValue(3, s.concentration_M * 1e6)} μM`);
            else components.push(`@${roundValue(3, s.concentration_M * 1e3)} mM`);
        }
        if (typeof s.solvent === 'string' && s.solvent !== 'DMSO' && !short) {
            components.push(` ${s.solvent}`);
        }
    } else if (typeof s.amount_g === 'number') {
        if (s.amount_g < 1e-3) components.push(`${roundValue(3, s.amount_g * 1e6)} μg`);
        else components.push(`${roundValue(3, s.amount_g * 1e3)} mg`);
    } else if (typeof s.solvent === 'string') {
        components.push(s.solvent);
    } else {
        components.push('«empty»');
    }
    return components.join('');
}

export function ECMRequestLink({ id, management }: { id: number; management?: boolean }) {
    return (
        <span className='ecm-request-link'>
            {formatRequestId(id)}
            <Link
                to={resolveRSRoute('request', id, (management || undefined) && 'manage')}
                target='_blank'
                rel='noopener noreferrer'
            >
                <FontAwesomeIcon size='sm' icon={faArrowUpRightFromSquare} className='ms-1' />
            </Link>
        </span>
    );
}

export const DefaultShippingInfo: ECMShippingInfo = { to: '', via: '', comment: '', recipient: '', temperature: '' };

export function ShippingControls({
    current,
    next,
}: {
    current: ECMShippingInfo;
    next: (info: ECMShippingInfo) => any;
}) {
    const labelWidth = 160;
    return (
        <div className='vstack gap-2'>
            <LabeledInput label='Ship To' labelWidth={labelWidth}>
                <TextInput value={current.to} immediate setValue={(v) => next({ ...current, to: v.trim() })} />
            </LabeledInput>
            <LabeledInput label='Ship Via' labelWidth={labelWidth}>
                <SimpleSelectOptionInput
                    options={[
                        ['fedex', 'FEDEX'],
                        ['ait worldwide', 'AIT Worldwide'],
                    ]}
                    value={current.via}
                    allowEmpty
                    setValue={(v) => next({ ...current, via: v ?? '' })}
                />
            </LabeledInput>
            <LabeledInput label='Recipient' labelWidth={labelWidth}>
                <TextInput
                    value={current.recipient}
                    immediate
                    setValue={(v) => next({ ...current, recipient: v.trim() })}
                />
            </LabeledInput>
            <LabeledInput label='Temperature' labelWidth={labelWidth}>
                <SimpleSelectOptionInput
                    options={[
                        ['ambient', 'Ambient'],
                        ['-20C', '-20 °C'],
                        ['+4C', '+4 °C'],
                    ]}
                    value={current.temperature}
                    allowEmpty
                    setValue={(v) => next({ ...current, temperature: v })}
                />
            </LabeledInput>
            <LabeledInput label='Comment' labelWidth={labelWidth}>
                <TextInput
                    value={current.comment}
                    immediate
                    setValue={(v) => next({ ...current, comment: v.trim() })}
                />
            </LabeledInput>
        </div>
    );
}

export interface ARPDilutionCurveInfo {
    sample: ECMRequestedSample;
    intermediateUsageL: number;
    arpUsageL: number;
    maxSolventRatio: number;
    nIntermediates: number;
}

export function getARPDilutionCurveInfo(curve: ECMARPDilutionCurve): ARPDilutionCurveInfo {
    let usage = 0;
    let maxSolventRatio = 0;
    let nIntermediates = 0;
    let intermediateUsage = 0;
    let arpUsage = 0;

    const baseConc = curve.source_concentrations[0];
    for (const p of curve.points) {
        let v = 0;
        for (const t of p.transfers) {
            if (t.concentration === baseConc) {
                usage += t.volume;
                arpUsage += t.volume;
            }
            v += t.volume;
        }
        const solventRatio = v / curve.assay_volume;
        if (solventRatio > maxSolventRatio) maxSolventRatio = solventRatio;
    }
    for (const interm of curve.intermediates) {
        nIntermediates += interm.length;
        for (const p of interm) {
            for (const t of p.transfers) {
                if (t.concentration === baseConc) {
                    usage += t.volume;
                    intermediateUsage += t.volume;
                }
            }
        }
    }

    usage *= 1e3;

    const sample: ECMRequestedSample = {
        concentration_M: baseConc,
        volume_l: usage,
    };

    return {
        sample,
        maxSolventRatio,
        nIntermediates,
        intermediateUsageL: intermediateUsage * 1e3,
        arpUsageL: arpUsage * 1e3,
    };
}

export interface ARPTemplateInfo {
    nSamples: number;
    nReplicates: number;
    nControlReplicates: number[];
    nControls: number;
    controlKinds: ECMARPTemplateControlKind[];
}

export function getARPTemplateInfo(template: ECMARPTemplate): ARPTemplateInfo {
    const sampleIds = new Set(template.wells.filter((w) => !!w && !w.is_control).map((w) => w?.sample_index));
    const controlIds = new Set(template.wells.filter((w) => !!w && w.is_control).map((w) => w?.sample_index));

    const firstSamplePoints = template.wells.filter((w) => !!w && !w.is_control && w.sample_index === 0);
    let nReplicates = 0;
    groupBy(firstSamplePoints, (p) => p!.point_index).forEach((g) => {
        if (g.length > nReplicates) nReplicates = g.length;
    });

    const nControlReplicates: number[] = [];
    const controlKinds: ECMARPTemplateControlKind[] = [];
    for (let cI = 0; cI < controlIds.size; cI++) {
        const controlSamplePoints = template.wells.filter((w) => !!w && w.is_control && w.sample_index === cI);
        controlKinds[cI] = controlSamplePoints[0]?.control_kind ?? 'unknown';
        let nRepl = 0;
        groupBy(controlSamplePoints, (p) => p!.point_index).forEach((g) => {
            if (g.length > nRepl) nRepl = g.length;
        });
        nControlReplicates.push(nRepl);
    }

    return {
        nSamples: sampleIds.size,
        nControls: controlIds.size,
        nControlReplicates,
        nReplicates,
        controlKinds,
    };
}

function sortARPGroups(groups: [ECMARPTemplateWell, number][][]) {
    groups.sort((a, b) => {
        const x = a[0][0];
        const y = b[0][0];

        if (x.is_control && !y.is_control) return 1;
        if (!x.is_control && y.is_control) return -1;

        if (x.sample_index === y.sample_index) return x.point_index - y.point_index;
        return x.sample_index - y.sample_index;
    });
    return groups;
}

export function colorPlateByARPTemplate(
    plate: PlateVisualModel,
    template: ECMARPTemplate,
    options?: { wellMap?: (ECMARPWellInstance | null)[]; rejected_ids?: Set<number | undefined> }
) {
    const indexedWells = template.wells.map((w, i) => [w, i] as [ECMARPTemplateWell, number]);
    const sampleGroups = groupBy(
        indexedWells.filter((w) => !!w[0] && !w[0].is_control),
        (w) => w[0].sample_index
    );
    const controlGroups = groupBy(
        indexedWells.filter((w) => !!w[0] && w[0].is_control),
        (w) => w[0].sample_index
    );

    const colors: PlateVisualColors = new Array(template.well_layout).fill(PlateWellColoring.NoColor);
    const labels: PlateVisualLabels = new Array(template.well_layout).fill(null);

    const samples = sortARPGroups(Array.from(sampleGroups.values()));
    const controls = sortARPGroups(Array.from(controlGroups.values()));
    const labelColor = `rgb(255, 255, 255)`;
    const stripeColor = `rgb(33, 33, 33)`;

    let index = 0;
    for (const group of samples) {
        const color = d3_scales.interpolateWarm(index++ / (samples.length - 1 || 1));
        const lab = Lab.fromColor(Lab(), parseColor(color));

        let maxPoint = 0;
        for (const well of group) {
            if (well[0].point_index > maxPoint) maxPoint = well[0].point_index;
        }

        for (const [well, wI] of group) {
            const mapped = options?.wellMap?.[wI];
            if (options?.wellMap && !mapped) continue;

            lab[0] = 60 - 20 * (well.point_index / (maxPoint - 1 || 1));

            const base = toRGBString(Color.toArray(Lab.toColor(lab), [0, 0, 0], 0) as number[]);

            if (options?.rejected_ids?.has(mapped?.sample_id)) {
                colors[wI] = [base, base, stripeColor, base, base, stripeColor, base, base];
            } else {
                colors[wI] = base;
            }

            labels[wI] = { color: labelColor, text: `${well.sample_index + 1}-${well.point_index + 1}`, scale: 0.8 };
        }
    }

    index = 0;
    for (const group of controls) {
        const color = d3_scales.schemeTableau10[index++ % d3_scales.schemeTableau10.length];
        const lab = Lab.fromColor(Lab(), parseColor(color));

        let maxPoint = 0;
        for (const well of group) {
            if (well[0].point_index > maxPoint) maxPoint = well[0].point_index;
        }

        for (const [well, wI] of group) {
            lab[0] = 60 - 20 * (well.point_index / (maxPoint - 1 || 1));
            const base = toRGBString(Color.toArray(Lab.toColor(lab), [0, 0, 0], 0) as number[]);
            const label = `${well.sample_index + 1}-${well.point_index + 1}`;
            colors[wI] = [base, base, base, base, stripeColor];
            if (options?.wellMap && !options?.wellMap[wI]?.final_volume) {
                colors[wI] = [stripeColor, base, base, base, stripeColor];
            } else {
                colors[wI] = [stripeColor, base, base, base, base];
            }
            const scale = 2 / 3;
            if (well.control_kind === 'positive') {
                labels[wI] = { color: labelColor, text: `P${label}`, scale };
            } else if (well.control_kind === 'negative') {
                labels[wI] = { color: labelColor, text: `N${label}`, scale };
            } else if (well.control_kind === 'reference') {
                labels[wI] = { color: labelColor, text: `R${label}`, scale };
            } else if (well.control_kind === 'rest') {
                labels[wI] = { color: labelColor, text: `S${label}`, scale };
            } else {
                labels[wI] = { color: labelColor, text: `C${label}`, scale };
            }
        }
    }

    plate.state.colors.next(colors);
    plate.state.labels.next(labels);
}

export function arpTemplateVisualModel(template: ECMARPTemplate) {
    const ret = new PlateVisualModel(template.well_layout, { singleSelect: true, disableMultiselect: true });
    colorPlateByARPTemplate(ret, template);
    return ret;
}

export function ARPTemplateVisual({
    template,
    extra,
    model: _model,
    className,
}: {
    model?: PlateVisualModel;
    template: ECMARPTemplate;
    extra?: ReactNode;
    className?: string;
}) {
    const model = useMemo(() => _model ?? arpTemplateVisualModel(template), [_model, template]);

    return (
        <div className={`d-flex flex-column w-100 h-100 ${className}`}>
            <div className='flex-grow-1'>
                <PlateVisual model={model} />
            </div>
            <div className='flex-grow-0 font-body-small pt-1 pb-2 hstack ps-4'>
                {extra}
                <div className='m-auto' />
                <ARPPlateTemplateSelection model={model} template={template} />
            </div>
        </div>
    );
}

export function ARPPlateTemplateSelection({ model, template }: { model: PlateVisualModel; template: ECMARPTemplate }) {
    const selection = useBehavior(model.state.selection);
    const wI = getFirstSelectedIndex(selection);
    const label = getWellIndexLabel(model.layout, wI);

    let inner;
    if (wI < 0) {
        inner = 'Nothing selected';
    } else if (!template.wells[wI]) {
        inner = (
            <>
                <b>({label})</b> Empty well
            </>
        );
    } else {
        const well = template.wells[wI]!;
        inner = (
            <>
                <b>({label})</b>{' '}
                {well.is_control ? `${capitalizeFirst(well.control_kind ?? 'unknown')} Control` : 'Sample'}{' '}
                {well.sample_index + 1}-{well.point_index + 1}
            </>
        );
    }

    return <span>{inner}</span>;
}
