import { faCheckCircle, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { Button, Form, Modal, ProgressBar } from 'react-bootstrap';
import Select from 'react-select';
import { AsyncActionButton } from '../../../components/common/AsyncButton';
import { MultiFileUpload } from '../../../components/common/FileUpload';
import Loading from '../../../components/common/Loading';
import { CustomSelectClassNames, DefaultSelectStyles } from '../../../components/common/selectStyles';
import useBehavior from '../../../lib/hooks/useBehavior';
import { computationDurationString, WorkflowComputation } from '../../../lib/models/computation-data';
import { AssayUploadModel } from './assay-upload-model';

function getErrorMessage(error: any): string | React.ReactNode {
    if (error) {
        if (typeof error === 'string' || (error && typeof error.message === 'string')) {
            const message = error.message ? error.message : error;
            const lines = message.split('\n');
            return (
                <>
                    {lines.map((line: string, idx: number) => (
                        <React.Fragment key={`${idx}-${line}`}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}
                </>
            );
        }
        return 'File could not be uploaded.';
    }
    return '';
}

export function UploadAssayModal({ model }: { model: AssayUploadModel }) {
    const modal = useBehavior(model.state.modal);
    const { error, isLoading } = useBehavior(model.state.status);
    const { vendorChoice, performQC } = useBehavior(model.state.upload);
    const canUpload = useBehavior(model.state.canUpload);

    function cancel() {
        model.state.modal.next(null);
        model.cancel();
    }

    return (
        <Modal backdrop centered scrollable show={modal === 'upload'} onHide={() => cancel()}>
            <Modal.Header closeButton>
                <h4>Upload Assay Values</h4>
            </Modal.Header>
            <Modal.Body>
                {isLoading && <Loading message='Uploading file...' />}
                {!isLoading && (
                    <div className='my-2'>
                        <Select
                            options={model.options as any}
                            placeholder='Select a vendor'
                            classNames={CustomSelectClassNames}
                            styles={DefaultSelectStyles}
                            menuPortalTarget={document.body}
                            menuPosition='fixed'
                            value={vendorChoice}
                            onChange={(option: any) =>
                                model.state.upload.next({
                                    vendorChoice: option,
                                    performQC: option.value === 'Iambic Analytical' ? false : performQC,
                                })
                            }
                        />
                    </div>
                )}
                {!isLoading && vendorChoice && (
                    <MultiFileUpload
                        filesSubject={model.state.files}
                        label={model.getVendor()?.label ?? '-'}
                        extensions={model.getVendor()?.extensions ?? []}
                        numFiles={model.getVendor()?.numFiles[1] ?? 0}
                    />
                )}
                {!isLoading && vendorChoice?.value === 'Iambic JSON' && (
                    <Form.Check
                        type='checkbox'
                        label='Perform manual QC (Uncheck to skip directly to review)'
                        id='perform-qc-checkbox'
                        checked={performQC}
                        onChange={() => model.state.upload.next({ performQC: !performQC, vendorChoice })}
                    />
                )}
                {error && <p className='text-danger'>{getErrorMessage(error)}</p>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='link' className='me-3' onClick={() => cancel()}>
                    Cancel
                </Button>
                <Button variant='primary' onClick={() => model.load()} disabled={!canUpload}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function ComputationProgressBar({ model, computation }: { model: AssayUploadModel; computation: WorkflowComputation }) {
    useBehavior(model.state.computations);

    if (computation.status !== 'running') return null;

    return (
        <div className='d-flex w-100'>
            {computation.progress.map((p, i) => (
                <div key={i} className='flex-grow-1'>
                    <span>{p.desc}</span>
                    <ProgressBar now={(100 * p.n) / (p.total || 1)} striped />
                    <span>
                        {p.n}/{p.total} | {(p.display_t - p.start_t).toFixed(0)}s
                    </span>
                </div>
            ))}
            <Button
                title='Cancel'
                variant='link'
                size='sm'
                onClick={() => model.cancelComputation(computation.id)}
                className='cancel-computation-btn'
            >
                <FontAwesomeIcon icon={faTrash} fixedWidth />
            </Button>
        </div>
    );
}

function RemoveComputationButton({
    model,
    computation,
    color,
}: {
    model: AssayUploadModel;
    computation: WorkflowComputation;
    color: string;
}) {
    return (
        <Button
            className='remove-computation-btn'
            title='Remove Computation'
            color={color}
            variant='link'
            size='sm'
            onClick={() => model.removeComputation(computation.id)}
        >
            <FontAwesomeIcon icon={faTimes} color={color} fixedWidth />
        </Button>
    );
}

function ComputationListItem({ model, computation }: { model: AssayUploadModel; computation: WorkflowComputation }) {
    const selectedComputation = useBehavior(model.state.selectedComputation);

    async function quickLink() {
        model.state.selectedComputation.next(computation);
        await model.reviewBayes();
    }

    return (
        <li className='list-group-item'>
            <input
                className='list-group-item-radio'
                type='radio'
                id={computation.id}
                disabled={computation.status !== 'success' || !!computation.metadata?.done}
                checked={selectedComputation?.id === computation.id}
                onChange={() => model.state.selectedComputation.next(computation)}
            />
            <label htmlFor={computation.id}>
                <div className='d-flex justify-content-between'>
                    <AsyncActionButton
                        className='entos-computation-item-title'
                        action={quickLink}
                        disabled={computation.status !== 'success'}
                        variant='link'
                    >
                        {computation.metadata ? computation.metadata.filenames.join(', ') : '-'}
                    </AsyncActionButton>
                    <div className='font-body-xsmall text-end text-secondary'>
                        {computation.status === 'success' && `completed in ${computationDurationString(computation)}`}
                        {computation.status === 'error' && (
                            <div className='text-danger'>
                                {computation.status}
                                <RemoveComputationButton
                                    model={model}
                                    computation={computation}
                                    color='var(--bs-danger)'
                                />
                            </div>
                        )}
                        {!['success', 'error'].includes(computation.status) && computation.status}
                    </div>
                </div>
                <div>{computation.name}</div>
                {computation.status === 'error' && (
                    <div className='font-body-xsmall text-danger'>{computation.error_message}</div>
                )}
                {computation.metadata?.done && (
                    <div className='font-body-xsmall text-success text-end'>
                        Uploaded
                        <FontAwesomeIcon icon={faCheckCircle} size='sm' fixedWidth />
                    </div>
                )}
                <ComputationProgressBar model={model} computation={computation} />
            </label>
        </li>
    );
}

export function BayesComputationModal({ model }: { model: AssayUploadModel }) {
    const modal = useBehavior(model.state.modal);
    const { error } = useBehavior(model.state.status);
    const computations = useBehavior(model.state.computations);
    const newBayes = useBehavior(model.state.newBayes);
    const selectedComputation = useBehavior(model.state.selectedComputation);

    const sortedComputations = useMemo(() => computations.sort((a, b) => b.start_t - a.start_t), [computations]);

    return (
        <Modal backdrop centered scrollable size='lg' show={modal === 'bayes'} onHide={() => model.cancel()}>
            <Modal.Header closeButton>
                <h4>Bayesian uploads and results</h4>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-column'>
                    {newBayes && (
                        <p className='text-secondary'>
                            Your upload has been submitted for Bayesian fitting. You may close this popup and return at
                            a later time. Results will be accessible from the alert icon on the Assays page.
                        </p>
                    )}
                    <ul className='entos-computation-list list-group'>
                        {sortedComputations.map((comp) => (
                            <ComputationListItem key={comp.id} model={model} computation={comp} />
                        ))}
                    </ul>
                    {error && <p className='text-danger'>{getErrorMessage(error)}</p>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='link' className='me-3' onClick={() => model.cancel()}>
                    Cancel
                </Button>
                <AsyncActionButton
                    variant='primary'
                    action={async () => model.reviewBayes()}
                    disabled={!selectedComputation}
                >
                    Review
                </AsyncActionButton>
            </Modal.Footer>
        </Modal>
    );
}
