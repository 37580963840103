import saveAs from 'file-saver';
import { Column, DataTableModel, ObjectDataTableStore } from '../../../components/DataTable';
import { SelectionColumn } from '../../../components/DataTable/common';
import { SimpleSelectOptionInput } from '../../../components/common/Inputs';
import { ClipboardService } from '../../../lib/services/clipboard';
import { DialogService } from '../../../lib/services/dialog';
import { ToastService } from '../../../lib/services/toast';
import { memoizeLatest } from '../../../lib/util/misc';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { HTEILiquid, HTEInventory, HTEPReagent, HTEProtocol } from '../data-model';
import { getLiquidReagentBarcode } from '../utils/inventory';
import {
    SelectBarcodeCell,
    commonLiquidReagentsColumnSchema,
    renderBarcode,
    viewReagentDesignAction,
} from '../utils/reagent-table';
import { ReagentValidation, validateLiquidReagent } from '../utils/validation';
import type { HTE2MSReagentsModel, ReagentRow } from './reagents';
import { roundValue } from '../../../lib/util/roundValues';
import { arrayToCsv } from '../../../lib/util/arrayToCsv';
import { InlineAlert } from '../../../components/common/Alert';

export interface LiquidReagentRow extends ReagentRow {
    ratio?: number;
    n_uses: number;
    volume_l: number | undefined;
    labware_id?: string;
}

export class HTE2MSLiquidReagentsModel extends ReactiveModel {
    private store: ObjectDataTableStore<LiquidReagentRow, HTEPReagent> = new ObjectDataTableStore<
        LiquidReagentRow,
        HTEPReagent
    >([
        { name: 'identifier', getter: (v) => v.identifier },
        {
            name: 'batch_identifier',
            getter: (v) => {
                const barcode = getLiquidReagentBarcode(this.mainModel, v);
                if (barcode) {
                    const batch = this.reagents.protocol.model.assets.inventory.getVialBatch(barcode);
                    if (batch) return batch.universal_identifier!;
                }
            },
        },
        { name: 'validation', getter: (v) => this.validation.get(v) },
        { name: 'reactant_kinds', getter: (v) => this.getInfo(v)?.reactant_kinds },
        {
            name: 'worklist_title',
            getter: (v) => this.reagents.protocol.worklistMap.get(v.worklist_key)?.title ?? v.worklist_key,
        },
        { name: 'amount_g', getter: (v) => this.reagents.finalRequired.get(v)?.amount_g ?? this.getInfo(v)?.amount_g },
        { name: 'volume_l', getter: (v) => this.reagents.finalRequired.get(v)?.volume_l ?? this.getInfo(v)?.volume_l },
        { name: 'concentration', getter: (v) => v.concentration },
        { name: 'solvent', getter: (v) => v.solvent },
        { name: 'n_uses', getter: (v) => v.uses.length },
        { name: 'source_barcode', getter: (v) => this.inventory.getLiquid(v)?.source_barcode },
        { name: 'labware_id', getter: (v) => this.inventory.getLiquid(v)?.labware_id },
        { name: 'transfer_barcode', getter: (v) => this.inventory.getLiquid(v)?.transfer_barcode },
    ]);
    table: DataTableModel<LiquidReagentRow>;

    getInfo(reagent: HTEPReagent) {
        return this.reagents.getInfo(reagent);
    }

    get mainModel() {
        return this.reagents.protocol.model;
    }

    get all() {
        return this.store.rawRows;
    }

    get selectedAndFilteredRows() {
        let selectedRows = this.table.getSelectedAndFilteredRowIndices();
        if (!selectedRows.length) selectedRows = this.table.rows as any;
        return selectedRows.map((i) => this.all[i]);
    }

    private _reagentValidation = memoizeLatest((rows: HTEPReagent[], inventory: HTEInventory) => {
        const ret = new Map<HTEPReagent, ReagentValidation>();
        for (const r of rows) {
            ret.set(r, validateLiquidReagent(this.mainModel, r));
        }
        return ret;
    });
    get validation() {
        return this._reagentValidation(this.all, this.inventory.data);
    }

    get inventory() {
        return this.mainModel.inventory;
    }

    update(data: HTEProtocol) {
        this.store.setRows(data.reagents.filter((r) => r.solvent));
        this.table.dataChanged();
    }

    public autoAssign(options?: { dryOnly?: boolean }) {
        const updates: [HTEPReagent, Partial<HTEILiquid>][] = [];

        for (const r of this.selectedAndFilteredRows) {
            const inv = this.inventory.getLiquid(r);
            if (inv?.source_barcode) continue;

            const info = this.getInfo(r);
            if (!info) continue;
            const best = options?.dryOnly ? info?.best_dry_source_barcode : info?.best_barcode;
            if ((best || '') === (inv?.source_barcode || '')) continue;

            updates.push([r, { source_barcode: inv?.source_barcode || best }]);
        }

        if (updates.length) {
            this.inventory.update({ liquid: updates });
            ToastService.info(`Auto-assigned ${updates.length} barcode(s)`);
        } else {
            ToastService.info('Nothing changed');
        }
    }

    public autoAssignLabware() {
        const updates: [HTEPReagent, Partial<HTEILiquid>][] = [];

        for (const r of this.selectedAndFilteredRows) {
            const inv = this.inventory.getLiquid(r);
            const labware = this.mainModel.design.labwareMap.get(inv?.labware_id!);
            if (labware) continue;
            const info = this.getInfo(r);
            if (!info || (info.best_labware_id || '') === (inv?.labware_id || '')) continue;

            updates.push([r, { labware_id: info.best_labware_id }]);
        }

        if (updates.length) {
            this.inventory.update({ liquid: updates });
            ToastService.info(`Auto-assigned ${updates.length} labware`);
        } else {
            ToastService.info('Nothing changed');
        }
    }

    confirmClear = () => {
        DialogService.open({
            type: 'confirm',
            title: 'Clear Inventory and Labware',
            confirmText: 'Clear',
            text: (
                <>
                    <p>Are you sure you want to clear the inventory and labware assignments?</p>
                    <InlineAlert variant='warning'>
                        Reagents with <b>Transfer Barcode</b> assigned via the <b>Inventory</b> tab will be skipped
                    </InlineAlert>
                </>
            ),
            onConfirm: this.clear,
        });
    };

    private clear = () => {
        const updates: [HTEPReagent, Partial<HTEILiquid>][] = [];
        for (const r of this.selectedAndFilteredRows) {
            const inv = this.inventory.getLiquid(r);
            if (inv?.transfer_barcode) continue;
            if (inv?.source_barcode || inv?.labware_id) {
                updates.push([r, { source_barcode: undefined, labware_id: undefined }]);
            }
        }
        this.inventory.update({ liquid: updates });
        ToastService.show({
            message: 'Inventory and Labware cleared',
            type: 'info',
            id: 'hte2ms-reagents-clear',
            timeoutMs: 2500,
        });
    };

    exportBarcodes(how: 'copy' | 'save') {
        let selectedRows = this.table.getSelectedAndFilteredRowIndices();
        if (!selectedRows.length) selectedRows = this.table.rows as any;

        const barcodes = selectedRows
            .map((r) => this.store.getValue('source_barcode', r))
            .filter((r) => r)
            .join('\n');
        if (!barcodes) {
            return ToastService.warning('No barcodes to export');
        }

        if (how === 'copy') {
            ClipboardService.copyText(barcodes, 'Copy Barcodes');
        } else {
            saveAs(
                new Blob([barcodes], { type: 'text/csv' }),
                `${this.reagents.protocol.model.libraryId}-liquid-barcodes.csv`
            );
        }
    }

    devExportOrderList(how: 'copy' | 'save') {
        let selectedRows = this.table.getSelectedAndFilteredRowIndices();
        if (!selectedRows.length) selectedRows = this.table.rows as any;

        const rows: string[][] = [['Identifier', 'Amount', 'Amount Unit', 'Labware ID', 'Layout']];

        for (const r of selectedRows) {
            const amount = this.store.getValue('amount_g', r);
            const labware = this.store.getValue('labware_id', r);
            if (!amount || !labware) continue;
            const amount_mg = `${roundValue(2, 1e3 * amount)}`;
            const layout = this.mainModel.design.labwareMap.get(labware!)?.layout;
            rows.push([this.store.getValue('identifier', r) ?? '', amount_mg, 'mg', labware, `${layout ?? ''}`]);
        }

        const csv = arrayToCsv(rows);

        if (how === 'copy') {
            ClipboardService.copyText(csv, 'Copy Order list');
        } else {
            saveAs(new Blob([csv], { type: 'text/csv' }), `${this.reagents.protocol.model.libraryId}-order-list.csv`);
        }
    }

    mount() {
        this.subscribe(this.inventory.state.inventory, () => this.table.dataChanged());
    }

    constructor(public reagents: HTE2MSReagentsModel) {
        super();

        const columns = commonLiquidReagentsColumnSchema(reagents.protocol.model, this.store);
        const designAction = viewReagentDesignAction(reagents.protocol.model, this.store);

        this.table = new DataTableModel<LiquidReagentRow>(this.store, {
            columns: {
                identifier: columns.identifier,
                reactant_kinds: columns.reactant_kinds,
                worklist_title: columns.worklist_title,
                amount_g: columns.amount_g,
                volume_l: columns.volume_l,
                concentration: columns.concentration,
                solvent: columns.solvent,
                n_uses: {
                    ...Column.float(),
                    header: '#',
                    render: ({ value }) => value,
                    width: 40,
                },
                source_barcode: {
                    ...Column.str(),
                    header: 'Source Barcode',
                    render: ({ value, rowIndex }) => {
                        const readOnly = this.mainModel.readOnlyDesignAndProduction;
                        if (readOnly) return renderBarcode(this.mainModel, value);

                        const reagent = this.all[rowIndex];
                        const options = this.getInfo(reagent)?.inventoryOptions;
                        return (
                            <SelectBarcodeCell
                                model={this.inventory.model}
                                value={value}
                                options={options}
                                setValue={(v) =>
                                    this.inventory.update({ liquid: [[reagent, { source_barcode: v || undefined }]] })
                                }
                            />
                        );
                    },
                    width: 250,
                },
                labware_id: {
                    ...Column.str(),
                    header: 'Labware',
                    render: ({ value, rowIndex }) => {
                        const readOnly = this.mainModel.readOnlyDesignAndProduction;
                        if (readOnly) {
                            return this.mainModel.design.labwareMap.get(value!)?.label ?? value;
                        }
                        const reagent = this.all[rowIndex];
                        return (
                            <SimpleSelectOptionInput
                                value={value || ''}
                                className='ps-1 font-body-xsmall hte2ms-table-options'
                                allowEmpty
                                options={this.reagents.protocol.model.design.labwareOptions}
                                setValue={(v) =>
                                    this.inventory.update({ liquid: [[reagent, { labware_id: v || undefined }]] })
                                }
                                invalidOptionLabel={value ? `[Invalid] ${value}` : `[Empty]`}
                            />
                        );
                    },
                    width: 240,
                },
                batch_identifier: columns.batch_identifier,
                transfer_barcode: columns.transfer_barcode,
                validation: columns.validation,
            },
            actions: [designAction, SelectionColumn({ width: 30 })],
            hideNonSchemaColumns: true,
        });

        this.table.setColumnStickiness(designAction.id, true);
        this.table.setColumnStickiness('selection', true);
        this.table.setColumnStickiness('identifier', true);
    }
}
