/* eslint no-param-reassign: 0 */
import type { Chart, Plugin } from 'chart.js';
import { AnnotationElement, AnnotationOptions } from 'chartjs-plugin-annotation';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounce } from '../../util/misc';
import { EventPlugin } from './eventPlugin';

export const getDragPlugin = (model: DragPlugin): Plugin => ({
    id: 'dragger',
    start: (chart, _args, _options) => {
        model.chart = chart;
        model.addListeners();
    },
    beforeEvent(_chart: Chart, args: any, _options: Chart.ChartOptions) {
        if (model.state.dragging.value) {
            args.changed = true;
        }
    },
    stop: (_chart: Chart) => {
        model.removeListeners();
        model.chart = null;
    },
});

export const DefaultLabelAdjust: [number, number] = [60, -60];

export class DragPlugin extends EventPlugin {
    public element: AnnotationElement | null = null;
    public lastEvent: MouseEvent | null = null;
    public adjust: [number, number] = [...DefaultLabelAdjust];

    state = {
        dragging: new BehaviorSubject<boolean>(false),
    };

    events = {
        afterDrag: new Subject<AnnotationElement>(),
    };

    enter(element: AnnotationElement) {
        // stop if we are already dragging an element so we don't interfere with the current drag
        if (this.state.dragging.value) return;
        this.element = element;
        const options = element.options as AnnotationOptions<'point'>;
        this.adjust[0] = (options.xAdjust as number) ?? DefaultLabelAdjust[0];
        this.adjust[1] = (options.yAdjust as number) ?? DefaultLabelAdjust[1];
    }

    leave() {
        // stop if we are already dragging an element so we don't interfere with the current drag
        if (this.state.dragging.value) return;
        this.element = null;
    }

    addListeners() {
        if (!this.chart) return;
        const canvas = this.chart.canvas;

        this.addHandler(canvas, 'mousedown', this.mouseDown);
        this.addHandler(canvas.ownerDocument, 'mouseup', this.mouseUp);
    }

    drag(chart: Chart, moveX: number, moveY: number) {
        if (!this.element) return;

        // keep the label within the chart area
        if (this.element.x2 + moveX > chart.chartArea.left && this.element.x + moveX < chart.chartArea.right) {
            this.element.x += moveX;
            this.element.x2 += moveX;
            this.element.centerX += moveX;
            this.adjust[0] += moveX;
        }
        if (this.element.y2 + moveY > chart.chartArea.top && this.element.y + moveY < chart.chartArea.bottom) {
            this.element.y += moveY;
            this.element.y2 += moveY;
            this.element.centerY += moveY;
            this.adjust[1] += moveY;
        }
    }

    private mouseDown(event: Event) {
        if (!this.element) return;
        this.lastEvent = event as MouseEvent;
        this.state.dragging.next(true);

        if (this.chart) {
            const debouncedMouseMove = debounce((e: Event) => this.mouseMove(e as MouseEvent), 33);
            this.addHandler(this.chart.canvas, 'mousemove', debouncedMouseMove);
        }
    }

    private mouseMove(event: MouseEvent) {
        if (!this.lastEvent || !this.element || !this.chart) {
            return;
        }
        if (event.x === null || event.y === null || this.lastEvent.x === null || this.lastEvent.y === null) return;
        const moveX = event.x - this.lastEvent.x;
        const moveY = event.y - this.lastEvent.y;
        this.drag(this.chart, moveX, moveY);
        this.lastEvent = event;
    }

    private mouseUp(_event: Event) {
        if (!this.element) return;
        this.lastEvent = null;
        this.events.afterDrag.next(this.element);
        this.element = null;
        this.state.dragging.next(false);
        this.removeHandler('mousemove');
    }

    constructor() {
        super();
    }
}
