import api from '../../api';
import { ReactTableModel, ReactTableData } from '../../components/ReactTable/model';
import type { AssayValueGraph } from '../../lib/assays/models';
import { decodeEntosMsgpack } from '../../lib/util/serialization';
import { columnDataTableStore, DataTableStore } from '../../components/DataTable';
import { DateLike } from '../../lib/util/dates';

export interface Substance {
    id?: number;
    smiles: string;
}
interface CompoundBase {
    created_by: string;
    created_on: string | number | Date;
    entos_id?: number;
    common_name?: string;
    inchi_key?: string;
    aliases: string[];
    identifier: string;
    id: number;
    molecular_weight: number;
    molecular_formula: string;
    project?: string;
    stereochemistry_label: StereochemistryLabelEnum;
    stereochemistry_comment?: string;
    stereochemistry_enantiomeric_ratio?: number[];

    entos_identifier?: string;
    msd_identifier?: string;
    bb_identifier?: string;
    reagent_identifier?: string;
    universal_identifier?: string;
}

export interface CompoundDetail extends CompoundBase {
    structure: Substance;
}

export interface CompoundFromDF extends CompoundBase {
    structure: string;
    structure_id?: number;
}

export function compoundFromDFToCompound(compound: CompoundFromDF): CompoundDetail {
    return {
        ...(compound as any),
        structure: { smiles: compound.structure, id: compound.structure_id },
    };
}

export interface SampleContents {
    solute_mass?: number;
    solvent?: string; // TODO: use strong type?
    // Solvent volume is in m**3
    solvent_volume?: number;
    concentration?: number;
}

export interface Sample extends SampleContents {
    id: number;
    barcode: any;
    batch_id: number;
    sample_number: number;

    uploaded_by: string;
    uploaded_on: string;
    disposed_on?: Date | number | string;
}

export type BatchReactionMixtureLabel = 'Unlabeled' | 'Succeeded' | 'Failed';

export type BatchIdentifierNames =
    | 'identifier'
    | 'entos_identifier'
    | 'msd_identifier'
    | 'bb_identifier'
    | 'reagent_identifier'
    | 'universal_identifier';
export type BatchIdentifiers = { [Id in BatchIdentifierNames]?: string };

interface BatchBase {
    id: number;
    compound_id: number;
    supplier?: string | null;
    supplier_id?: string | null;
    supplier_notebook?: string | null;
    supplier_notebook_page?: string | null;
    submitted_purity?: number;
    batch_number: number;
    formula_weight: number;
    molecular_formula: string;
    uploaded_by: string;
    uploaded_on: string;
    kind?: 'Reaction mixture' | 'Purified';
    label?: BatchReactionMixtureLabel;

    identifier?: string;
    entos_identifier?: string;
    msd_identifier?: string;
    bb_identifier?: string;
    reagent_identifier?: string;
    universal_identifier?: string;

    cas_numbers?: string[];
    ambiguous_stereochemistry_v3k?: string | null;
}

interface BatchForward {
    forwarded_on: DateLike;
    forwarded_by: string;
    forwarded_batch_id: number;
}

export interface Batch extends BatchBase {
    structure: Substance;
    samples?: Sample[];
    salt?: Substance;
    forward?: BatchForward;
    ambiguous_stereochemistry_v3k?: string;
}

export interface BatchFromDF extends BatchBase {
    structure: string;
    salt?: string;
    structure_id?: number;
}

export function batchFromDFToBatch(batch: BatchFromDF): Batch {
    return {
        ...(batch as any),
        structure: { smiles: batch.structure, id: batch.structure_id },
    };
}

export interface BatchReview {
    structure: string;
    compound_identifier: string;
    project: string;
    supplier: string;
    supplier_id: string;
    supplier_notebook: string;
    supplier_notebook_page: string;
    submitted_purity: number;
    stereochemistry_label: StereochemistryLabelEnum;
    stereochemistry_comment: string;
    stereochemistry_enantiomeric_ratio?: string;
    ambiguous_stereochemistry_v3k?: string;
    error: string;
    warning: string;
    common_name?: string;
    aliases?: string[];
}

export interface SampleReview {
    batch: string;
    supplier_id: string;
    sanitized_supplier_id: string;
    barcode: string;
    amount: string;
    amount_units: string;
    error: string;
    warning: string;
}

export interface BatchAssayTable {
    batch_identifier: string;
    [n: string]: any;
}

export interface CompoundAssaysData {
    imputed: DataTableStore<BatchAssayTable>;
    all: DataTableStore;
}

export interface BatchIdentifierMapResponse {
    batch_map: Record<number, Batch>;
    batch_identifier_to_id: Record<string, number>;
}

export interface CompoundQuery {
    project?: string | null;
    index?: string | null;
    substructure?: string | null;
    substructure_exact?: boolean;
}

export interface SignalsResult {
    batches: DataTableStore<BatchReview>;
    samples: DataTableStore<SampleReview>;
    productIdMap: Record<string, string>;
}

export const STEREOCHEMISTRY_LABELS = [
    'Achiral',
    'Racemic mixture',
    'Scalemic mixture',
    'Enantiomeric mixture',
    'Enantiomerically pure',
    'Diasteriomeric mixture',
    'Diastereomerically pure',
    'Atropisomeric mixture',
    'Atropisomerically pure',
    'Stereoisomeric mixture',
    'Stereoisomerically pure',
    'Not specified',
] as const;
type StereochemistryLabels = typeof STEREOCHEMISTRY_LABELS;
export type StereochemistryLabelEnum = StereochemistryLabels[number];

export interface CompoundSummary {
    batch_smiles: string;
    formula_weight: number;
    molecular_formula: string;
    identifier?: string;
    project?: string;
    common_name?: string;
    aliases: string[];
    stereochemistry_label: StereochemistryLabelEnum;
    stereochemistry_enantiomeric_ratio?: string;
    stereochemistry_comment?: string;
    created_on: string | number | Date;
    created_by?: string;
    salt?: string;
}

export interface CompoundComparison {
    submitted: CompoundSummary;
    similar: CompoundSummary[];
}

export interface PKData {
    batch_identifier: string;
    group_name: string;
    species: string;
    strain: string;
    formulation: string;
    dosage: string;
    dosing_route: string;
    graph: AssayValueGraph | null;
    report: Record<string, any>;
    cassette_batches?: string[];
    study_protocol_number: string;
    [key: string]: any;
}

interface PKAssayDataTable {
    identifier: string;
    [key: string]: any;
}

export interface PhysicalChemProperties {
    batch_identifier: string;
    MW: number;
    TPSA: number;
    LogP: number;
}

export interface PercentUnbound {
    [key: string]: { value: number; assay_id: number; method?: PercentUnboundMethod; identifiers: string[] }[];
}

export interface PercentUnboundErrors {
    [key: string]: string;
}

export interface BatchesForCompoundResult {
    batches: Batch[];
    forwarded_batches: Record<number, string>;
}

export type PercentUnboundMethod = 'Dialysis' | 'Ultracentrifugation (UC)';

export interface PKDataWrapper {
    ic50: DataTableStore<PKAssayDataTable>;
    ic50_fbs: DataTableStore<PKAssayDataTable>;
    ic90: DataTableStore<PKAssayDataTable>;
    ic90_fbs: DataTableStore<PKAssayDataTable>;
    values: DataTableStore<PKData>;
    physical_chem_properties: DataTableStore<PhysicalChemProperties>;
    percent_unbound: PercentUnbound;
    percent_unbound_methods: PercentUnboundMethod[];
    percent_unbound_errors: PercentUnboundErrors;
}

export function splitMolecularFormula(formula: string): string[] | null {
    return formula.match(/[a-z]+|[^a-z]+/gi);
}

export function matchIdentifier(test: string, entity: Batch | CompoundDetail) {
    if (entity.identifier?.includes(test)) return true;
    if (entity.entos_identifier?.includes(test)) return true;
    if (entity.universal_identifier?.includes(test)) return true;
    if (entity.msd_identifier?.includes(test)) return true;
    if (entity.bb_identifier?.includes(test)) return true;
    if (entity.reagent_identifier?.includes(test)) return true;
    return false;
}

export function getPKKey(store: DataTableStore<PKData>, rowIndex: number) {
    const row = store.getRow(rowIndex);
    return `${row.group_name}, ${row.species}, ${row.strain}, ${row.dosing_route}, ${row.formulation}, ${row.dosage}, ${row.batch_identifier}`;
}

export function getRowIndexFromPKKey(store: DataTableStore<PKData>, pkKey: string) {
    for (let i = 0; i < store.rowCount; i++) {
        if (pkKey === getPKKey(store, i)) return i;
    }
    return -1;
}

export function createCompoundArray(data: ReactTableData) {
    const compoundsTable = new ReactTableModel(data);
    const compounds = compoundsTable.toObjects() as CompoundDetail[];
    for (const c of compounds) {
        c.structure = { smiles: c.structure as any, id: (c as any as CompoundFromDF).structure_id } as Substance;
    }
    return compounds;
}

export function createBatchArray(data: ReactTableData) {
    const batchesTable = new ReactTableModel(data);
    const batches = batchesTable.toObjects() as Batch[];
    for (const r of batches) {
        r.structure = { smiles: r.structure as any, id: (r as any as BatchFromDF).structure_id } as Substance;
    }
    return batches;
}

export const CompoundAPI = {
    list: async (query?: CompoundQuery): Promise<DataTableStore<CompoundFromDF>> => {
        const { data } = await api.client.get('compound-registration/compounds', {
            responseType: 'arraybuffer',
            params: {
                project: query?.project,
                index: query?.index,
                substructure: query?.substructure,
                substructure_exact: query?.substructure_exact,
            },
        });
        return columnDataTableStore(decodeEntosMsgpack(new Uint8Array(data)));
    },
    get: async (identifier: string): Promise<CompoundDetail> => {
        const { data } = await api.client.get(`compound-registration/compounds/${identifier}`, {
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(new Uint8Array(data), { eoi: 'strip' });
    },
    getCompoundFromIdentifier: async (identifier: string): Promise<string> => {
        // identifier can be a barcode, batch identifier, supplier id
        const { data } = await api.client.get(
            `compound-registration/compound_from_identifier/${encodeURIComponent(identifier)}`
        );
        return data;
    },
    getSimilarCompounds: async (
        smiles: string,
        stereochemistryLabel: StereochemistryLabelEnum,
        enantiomericRatio?: string
    ): Promise<CompoundComparison> => {
        const { data } = await api.client.get('compound-registration/similar_compounds', {
            params: {
                smiles,
                stereochemistry_label: stereochemistryLabel,
                enantiomeric_ratio: enantiomericRatio || undefined,
            },
        });
        return data;
    },
    getAssays: async (id: number): Promise<CompoundAssaysData> => {
        const { data } = await api.client.get(`compound-registration/compounds/${id}/assays`, {
            responseType: 'arraybuffer',
        });
        const decoded = decodeEntosMsgpack(data);
        return {
            imputed: columnDataTableStore<BatchAssayTable>(decoded.imputed),
            all: columnDataTableStore(decoded.all),
        };
    },
    getPKData: async (id: number): Promise<PKDataWrapper> => {
        const { data } = await api.client.get(`compound-registration/compounds/${id}/pk`, {
            responseType: 'arraybuffer',
        });
        const decoded = decodeEntosMsgpack(new Uint8Array(data));
        return {
            ...decoded,
            ic50: columnDataTableStore(decoded.ic50),
            ic50_fbs: columnDataTableStore(decoded.ic50_fbs),
            ic90: columnDataTableStore(decoded.ic90),
            ic90_fbs: columnDataTableStore(decoded.ic90_fbs),
            values: columnDataTableStore(decoded.values),
            physical_chem_properties: columnDataTableStore(decoded.physical_chem_properties),
            percent_unbound: decoded.percent_unbound,
        };
    },
    getBatches: async (id: number): Promise<BatchesForCompoundResult> => {
        const { data } = await api.client.get(`compound-registration/compounds/${id}/batches`, {
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(new Uint8Array(data), { eoi: 'strip' });
    },
    getBatchesFromIdentifiers: async (identifiers: string[]): Promise<BatchIdentifierMapResponse> => {
        const { data } = await api.client.post(
            `compound-registration/compounds/get_batches_from_identifiers`,
            identifiers,
            {
                responseType: 'arraybuffer',
            }
        );
        return decodeEntosMsgpack(new Uint8Array(data), { eoi: 'strip' });
    },
    batchReview: async (file: File): Promise<DataTableStore<BatchReview>> => {
        const formData = new FormData();
        formData.append('file', file);
        const { data } = await api.client.post('compound-registration/batch_upload/review', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            responseType: 'arraybuffer',
        });
        return columnDataTableStore(decodeEntosMsgpack(new Uint8Array(data)));
    },
    batchReReview: async (batches: BatchReview[]): Promise<DataTableStore<BatchReview>> => {
        const { data } = await api.client.post(
            'compound-registration/batch_upload/re_review',
            { batches },
            {
                responseType: 'arraybuffer',
            }
        );
        return columnDataTableStore(decodeEntosMsgpack(new Uint8Array(data)));
    },
    batchCreate: async (options: { batches: BatchReview[]; entos_compound: boolean }): Promise<boolean> => {
        const res = await api.client.post(
            'compound-registration/batch_upload/create',
            { batches: options.batches, entos_compound: options.entos_compound },
            {
                responseType: 'arraybuffer',
            }
        );
        return res.status === 200;
    },
    sampleReview: async (file: File): Promise<DataTableStore<SampleReview>> => {
        const formData = new FormData();
        formData.append('file', file);
        const { data } = await api.client.post('compound-registration/sample_upload/review', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            responseType: 'arraybuffer',
        });
        return columnDataTableStore(decodeEntosMsgpack(new Uint8Array(data)));
    },
    sampleReReview: async (samples: SampleReview[]): Promise<DataTableStore<SampleReview>> => {
        const { data } = await api.client.post('compound-registration/sample_upload/re_review', samples, {
            responseType: 'arraybuffer',
        });
        return columnDataTableStore(decodeEntosMsgpack(new Uint8Array(data)));
    },
    sampleCreate: async (samples: SampleReview[]): Promise<boolean> => {
        const res = await api.client.post(
            'compound-registration/sample_upload/create',
            { samples },
            {
                responseType: 'arraybuffer',
            }
        );
        return res.status === 200;
    },
    loadFromSignals: async (eid: string): Promise<SignalsResult> => {
        const { data } = await api.client.get(`compound-registration/signals/${eid}`, { responseType: 'arraybuffer' });
        const decoded = decodeEntosMsgpack(new Uint8Array(data));
        return {
            batches: columnDataTableStore(decoded.batches),
            samples: columnDataTableStore(decoded.samples),
            productIdMap: decoded.product_id_map,
        };
    },
    sendIdentifiersToSignals: async (eid: string, product_id_map: Record<string, string>): Promise<boolean> => {
        const res = await api.client.post(`compound-registration/signals`, {
            eid,
            product_id_map,
        });
        return res.status === 200;
    },
    querySubstances: async (smilesList: string[]): Promise<Record<string, string | undefined>> => {
        const { data } = await api.client.post(
            'compound-registration/query_substances',
            { smiles_list: smilesList },
            { responseType: 'arraybuffer' }
        );
        return decodeEntosMsgpack(new Uint8Array(data));
    },
};
