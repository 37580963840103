import { ChangeEvent, useId, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FilterValue, Row, useAsyncDebounce, UseFiltersColumnProps } from 'react-table';

export const globalFilterTypes = {
    text: (rows: any[], id: any, filterValue: any) =>
        rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
                ? String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase())
                : true;
        }),
};

interface GlobalFilterProps {
    preGlobalFilteredRows: Row<any>[];
    globalFilter: any;
    setGlobalFilter: (filterValue: FilterValue) => void;
    disabled?: boolean;
    size?: 'sm' | 'lg';
}

export function ReactTableGlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    disabled,
    size,
}: GlobalFilterProps) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((val) => {
        setGlobalFilter(val || undefined);
    }, 200);

    return (
        <Form.Control
            value={value || ''}
            type='text'
            size={size}
            onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
            }}
            placeholder={`Filter ${count} records...`}
            disabled={disabled}
        />
    );
}

interface ColumnFilter {
    column: UseFiltersColumnProps<Record<string, any>>;
}

export function ReactTableDefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }: ColumnFilter) {
    const count = preFilteredRows.length;

    return (
        <Form.Control
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
            placeholder={`Search ${count} records...`}
        />
    );
}

interface NumberColumnFilter {
    column: {
        filterValue: any[];
        preFilteredRows: Row<Record<string, any>>[];
        setFilter: Function;
        // this id field isn't part of UseFiltersColumnProps but is used in the
        // react-table filter example
        // https://codesandbox.io/s/github/tannerlinsley/react-table/tree/v7/examples/filtering?file=/src/App.js
        id: string | number;
    };
}

export function ReactTableNumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}: NumberColumnFilter) {
    const [min, max] = useMemo(() => {
        let minimum = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let maximum = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach((row) => {
            const v = row.values[id];
            if (!Number.isNaN(v)) {
                minimum = Number.isNaN(minimum) ? v : Math.min(v, minimum);
                maximum = Number.isNaN(maximum) ? v : Math.max(v, maximum);
            }
        });
        if (Number.isNaN(minimum)) minimum = 0;
        if (Number.isNaN(maximum)) maximum = minimum;
        return [minimum, maximum];
    }, [id, preFilteredRows]);

    return (
        <div className='d-flex align-items-center'>
            <Form.Control
                value={filterValue[0] || ''}
                type='number'
                step='0.01'
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [val ? +val : undefined, old[1]]);
                }}
                placeholder={min.toFixed(2)}
                className='entos-column-number-filter me-1'
            />
            to
            <Form.Control
                value={filterValue[1] || ''}
                type='number'
                step='0.01'
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [old[0], val ? +val : undefined]);
                }}
                placeholder={max.toFixed(2)}
                className='entos-column-number-filter ms-1'
            />
        </div>
    );
}

export function ReactTableSelectedColumnFilter({ column: { filterValue, setFilter } }: ColumnFilter) {
    const checkId = useId();

    function handleChange(evt: ChangeEvent<HTMLInputElement>) {
        const checked = !!evt.currentTarget.checked;
        if (checked) {
            setFilter(true);
        } else {
            setFilter(undefined);
        }
    }

    return (
        <div className='d-flex mt-2'>
            <Form.Check
                title='Filter on Selected'
                type='switch'
                id={checkId}
                checked={!!filterValue}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt)}
            />
        </div>
    );
}
