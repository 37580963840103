import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { Popover } from 'react-bootstrap';
import { BehaviorSubject } from 'rxjs';
import {
    Column,
    ColumnFilters,
    DataTableControl,
    DataTableGlobalFilter,
    DataTableModel,
    ObjectDataTableStore,
} from '../../../components/DataTable';
import { TableCellPopover } from '../../../components/DataTable/common';
import { SimpleMultiFileUploadV2 } from '../../../components/common/FileUpload';
import { LabeledInput } from '../../../components/common/Inputs';
import useBehavior from '../../../lib/hooks/useBehavior';
import { HTEDValidationEntry, HTEDValidationResult } from '../data-model';

export class HTE2MSDistributionValidationModel {
    store = new ObjectDataTableStore<HTEDValidationEntry, HTEDValidationEntry>([
        { name: 'barcode', getter: (v) => v.barcode },
        { name: 'rack_label', getter: (v) => v.rack_label },
        { name: 'well', getter: (v) => v.well },
        { name: 'pooled_on', getter: (v) => v.pooled_on },
        { name: 'errors', getter: (v) => v.errors },
    ]);

    table: DataTableModel<HTEDValidationEntry>;

    constructor(public validation: HTEDValidationResult) {
        this.store.setRows(validation.entries);
        this.table = new DataTableModel<HTEDValidationEntry>(this.store, {
            columns: {
                barcode: {
                    ...Column.str(),
                    render: ({ value }) => value,
                    width: 160,
                },
                rack_label: {
                    ...Column.str(),
                    header: 'Rack',
                    render: ({ value }) => value,
                    width: 120,
                },
                well: {
                    ...Column.str(),
                    render: ({ value }) => value,
                    width: 160,
                },
                pooled_on: {
                    ...Column.datetime({ format: 'full' }),
                    header: 'Pooled on',
                    width: 160,
                },
                errors: {
                    kind: 'generic',
                    header: 'Errors',
                    format: (v) => '<unused>',
                    render: ({ value, rowIndex }) => {
                        if (!value?.length) return null;

                        let extraErrors: ReactNode | undefined;
                        if (value.length > 1) {
                            extraErrors = (
                                <div className='hte2ms-postprd-checklist-extra-cell'>
                                    <TableCellPopover
                                        inBody
                                        id={`error-${rowIndex}`}
                                        buttonClassName='entos-averaged-assay-value'
                                        popoverHeader={<Popover.Header>Extras</Popover.Header>}
                                        buttonContent={
                                            <span className='text-danger'>(and {value.length - 1} more)</span>
                                        }
                                        popoverBody={
                                            <Popover.Body>
                                                <div className='vstack'>
                                                    {value.map((error, i) => (
                                                        <div key={i}>
                                                            <FontAwesomeIcon
                                                                icon={faExclamationCircle}
                                                                className='text-danger me-2'
                                                                size='xs'
                                                                fixedWidth
                                                            />
                                                            {error}
                                                        </div>
                                                    ))}
                                                </div>
                                            </Popover.Body>
                                        }
                                    />
                                </div>
                            );
                        }

                        return (
                            <>
                                <FontAwesomeIcon
                                    icon={faExclamationCircle}
                                    className='text-danger me-2'
                                    size='xs'
                                    fixedWidth
                                />
                                <span className='text-danger'>{value[0]}</span>
                                {extraErrors}
                            </>
                        );
                    },
                    width: 500,
                    compare: false,
                    globalFilterFn: () => ColumnFilters.caseInsensitiveArray,
                },
            },
            globalFilterHiddenColumns: true,
            hideNonSchemaColumns: true,
        });
    }
}

export interface AddDistributionValidationState {
    files: {
        pooled_racks_files: File[];
    };
}

const LabelWidth = 160;

export function DistributionValidationInputDialogContent({
    stateSubject,
}: {
    stateSubject: BehaviorSubject<AddDistributionValidationState>;
}) {
    const state = useBehavior(stateSubject);
    const updateFiles = (key: keyof AddDistributionValidationState['files'], files: File[]) =>
        stateSubject.next({ ...state, files: { ...state.files, [key]: files } });

    return (
        <div className='vstack gap-1 font-body-small'>
            <LabeledInput label='Pooled Rackscans' labelWidth={LabelWidth}>
                <div className='hte2ms-inline-upload-wrapper'>
                    <SimpleMultiFileUploadV2
                        files={state.files.pooled_racks_files}
                        onDrop={(files) => updateFiles('pooled_racks_files', files)}
                        label='One or more .csv, .xls, .xlsx'
                        extensions={['.csv', '.xls', '.xlsx']}
                        inline
                    />
                </div>
            </LabeledInput>
        </div>
    );
}

export function DistributionValidationDialogContent({ model }: { model: HTE2MSDistributionValidationModel }) {
    useBehavior(model.table.version);

    return (
        <div className='position-relative w-100 d-flex flex-column' style={{ height: 500 }}>
            <div className='pb-2 me-2'>
                <DataTableGlobalFilter table={model.table} size='sm' />
            </div>
            <div className='flex-grow-1 position-relative me-2'>
                <DataTableControl
                    table={model.table}
                    height='flex'
                    rowSelectionMode='single'
                    headerSize='xxsm'
                    className='hte2ms-hidden-button-table'
                />
            </div>
        </div>
    );
}
