import { arrayToCsv } from '../../../lib/util/arrayToCsv';
import { roundValue } from '../../../lib/util/roundValues';
import { formatHTEId, PlateDimensions, Reactant } from '../experiment-data';
import { type HTEExperimentModel } from '../experiment-model';
import { getRegisteredExperimentBatches } from '../steps/finalize-model';
import { getWellCoords, plateRowLabel } from './utils';

const ProductPlateCSVColumns = [
    'Library Identifier',
    'MSD Batch Identifier',
    'MSD MW',
    'MSD MF',
    'BB Batch Identifier',
    'BB MW',
    'BB MF',
    'Product Compound Identifier',
    'Product Batch Identifier',
    'Product MW',
    'Product MF',
    'Well',
] as const;

type ProductPlateCSVRow = Record<(typeof ProductPlateCSVColumns)[number] | 'row' | 'col', string | number>;

export async function productPlateToAnalyticalCSV(experiment: HTEExperimentModel) {
    const isFinalized = !!experiment.state.info.value.finalization;
    const productBatches = isFinalized ? await getRegisteredExperimentBatches(experiment) : undefined;

    const rows: ProductPlateCSVRow[] = [];

    const { reactants } = experiment;
    const { plate } = experiment.design;
    const library_identifier = formatHTEId(experiment.id);

    const [width] = PlateDimensions[plate.layout];

    for (let wI = 0; wI < plate.layout; wI++) {
        const well = plate.wells[wI];
        if (!well) continue;

        const [row, col] = getWellCoords(width, wI);
        const crude_well = `${plateRowLabel(row)}${col + 1}`;

        let msd: Reactant | undefined;
        let bb: Reactant | undefined;

        for (const identifier of well) {
            const r = reactants.getReactant(identifier);
            if (r.type === 'msd') msd = r;
            if (r.type === 'bb') bb = r;
        }

        if (!msd || !bb) continue;
        const reaction = experiment.reactions.findReaction(msd.identifier, bb.identifier, wI);
        if (!reaction) continue;

        const productBatch = experiment.batches.getBatch(reaction.product_identifier);
        const compound = productBatch
            ? experiment.batches.getCompoundFromId(productBatch.compound_id)
            : experiment.batches.getCompound(reaction.product_identifier);

        if (!compound) {
            throw new Error(`Missing compound data for ${reaction.product_identifier} at well ${well}`);
        }

        const msdCompound = experiment.batches.getCompoundFromId(
            experiment.batches.getBatch(msd.identifier)?.compound_id
        );
        if (!msdCompound) {
            throw new Error(`Missing MSD compound data for ${msd.identifier} at well ${well}`);
        }
        const bbCompound = experiment.batches.getCompoundFromId(
            experiment.batches.getBatch(bb.identifier)?.compound_id
        );
        if (!bbCompound) {
            throw new Error(`Missing BB compound data for ${bb.identifier} at well ${well}`);
        }

        const finalProductBatch = productBatch ?? productBatches?.[wI];
        rows.push({
            'Library Identifier': library_identifier,
            'MSD Batch Identifier': msd.identifier,
            'MSD MW': roundValue(3, msdCompound.molecular_weight).toString(),
            'MSD MF': msdCompound.molecular_formula,
            'BB Batch Identifier': bb.identifier,
            'BB MW': roundValue(3, bbCompound.molecular_weight).toString(),
            'BB MF': bbCompound.molecular_formula,
            'Product Compound Identifier': reaction.product_identifier.split('-')[0],
            'Product Batch Identifier': isFinalized ? finalProductBatch?.identifier ?? reaction.product_identifier : '',
            'Product MW': roundValue(3, compound.molecular_weight).toString(),
            'Product MF': compound.molecular_formula,
            Well: crude_well,
            row,
            col,
        });
    }

    rows.sort((a, b) => {
        if (a.col !== b.col) return (a.col as number) - (b.col as number);
        return (a.row as number) - (b.row as number);
    });

    const csvRows: (string | number)[][] = [ProductPlateCSVColumns as any];
    for (const r of rows) {
        const row = [];
        for (const c of ProductPlateCSVColumns) row.push(r[c]);
        csvRows.push(row);
    }

    return arrayToCsv(csvRows);
}
