import { BehaviorSubject } from 'rxjs';
import { DialogService } from '../../../lib/services/dialog';
import { ToastService } from '../../../lib/services/toast';
import { ModelAction, ReactiveModel } from '../../../lib/util/reactive-model';
import { HTE2MSApi } from '../api';
import type { HTE2MSModel } from '../model';
import { HTE2MSDistributionModel } from './distribution';
import { HTE2MSPurificationModel } from './purification';
import { HTE2MSQualityControlModel } from './qc';
import { HTE2MSPostProductionSummaryModel } from './summary';

export type PostProductionTab = 'general' | 'pooling' | 'distribution' | 'finalqc' | 'summary';

export class HTE2MSPostProductionModel extends ReactiveModel {
    state = {
        tab: new BehaviorSubject<PostProductionTab>('general'),
        addBarcode: new BehaviorSubject<string>(''),
    };

    actions = {
        syncPlates: new ModelAction({ onError: 'toast', toastErrorLabel: 'Sync Plates', applyResult: () => {} }),
        addPlate: new ModelAction({ onError: 'toast', toastErrorLabel: 'Add Plate', applyResult: () => {} }),
    };

    purification = new HTE2MSPurificationModel(this);
    distribution = new HTE2MSDistributionModel(this);
    qc = new HTE2MSQualityControlModel(this);
    summary = new HTE2MSPostProductionSummaryModel(this);

    private async remove(id: number) {
        const experiment = this.model.experiment;
        if (!experiment) return;

        const modified = await HTE2MSApi.modifyPlateIds(experiment.id, {
            last_modified_on: this.model.last_modified_on!,
            remove_id: id,
        });
        this.model.updateFoundryInfo({ experiment: modified });
    }

    confirmRemove(id: number) {
        DialogService.open({
            type: 'generic',
            title: 'Remove Plate',
            confirmButtonContent: 'Remove',
            model: this,
            wrapOk: true,
            content: RemovePlateDialogContent,
            onOk: () => this.remove(id),
        });
    }

    async addPlate() {
        const barcode = this.state.addBarcode.value;
        const experiment = this.model.experiment;
        if (!experiment || !barcode) return;

        const modified = await HTE2MSApi.modifyPlateIds(experiment.id, {
            last_modified_on: this.model.last_modified_on!,
            add_barcode: barcode,
        });
        this.model.updateFoundryInfo({ experiment: modified });
        this.state.addBarcode.next('');
        ToastService.success('Plate added');
    }

    mount() {
        this.subscribe(this.model.state.info, (info) => {
            if (info.kind !== 'foundry') return;
            const plateIds = [...info.experiment.plate_ids];
            if (typeof info.experiment.crude_product_plate_id === 'number')
                plateIds.push(info.experiment.crude_product_plate_id);
            this.actions.syncPlates.run(this.model.assets.syncPlateInfo(plateIds));
        });
    }

    constructor(public model: HTE2MSModel) {
        super();
    }
}

function RemovePlateDialogContent() {
    return <div>Are you sure you want to disassociate the plate with this experiment?</div>;
}
