import { ReactNode, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Split from 'react-split-it';
import { ErrorMessage } from '../../components/common/Error';
import Loading from '../../components/common/Loading';
import { PotensNMToggle } from '../../components/common/UnitToggle';
import { PageTemplate } from '../../components/Layout/Layout';
import { PotensAsNmProp } from '../../lib/assays/table';
import { useAsyncAction, AsyncState } from '../../lib/hooks/useAsyncAction';
import useBehavior from '../../lib/hooks/useBehavior';
import useBehaviorSubject from '../../lib/hooks/useBehaviorSubject';
import { AssayInformationModel, loadAssay } from './assay-info-model';
import AssayPlotPanel from './AssayPlotPanel';
import AssayDetailTable from './AssayDetailTable';
import { AssayDetailPane } from './AssayDetailPanel';

function getTitle(modelState: AsyncState<AssayInformationModel>) {
    if (modelState.isLoading) return 'Loading...';
    if (modelState.error) return 'Assay Load Error';
    if (modelState.result) return `${modelState.result.assay.shorthand}`;
    return '';
}

export default function AssayInformation() {
    const { id } = useParams();
    const location = useLocation();
    const loadingMessage = useBehaviorSubject<ReactNode>('Loading...');
    const [modelState, loadModel] = useAsyncAction<AssayInformationModel>();

    useEffect(() => {
        const { result } = modelState;
        return () => result?.dispose();
    }, [modelState.result]);

    useEffect(() => {
        loadModel(loadAssay(+id!, loadingMessage));
        return () => modelState.result?.dispose();
    }, [id]);

    const model = modelState.result;

    return (
        <PageTemplate
            title='Assays'
            breadcrumb={{
                title: getTitle(modelState),
                href: location.pathname,
            }}
        >
            <div className='h-100'>
                {modelState.isLoading && <Loading message={loadingMessage} />}
                {modelState.error && (
                    <ErrorMessage header='Assay Load Error' message='Foundry not available or assay does not exist.' />
                )}
                {model && (
                    <Split direction='horizontal' gutterSize={6} sizes={[0.25, 0.75]}>
                        <AssayDetailPane assay={model.assay} />
                        {!model.isCurveMeasurement && (
                            <AssayDetailTable
                                source={model.assay.source.toUpperCase()}
                                table={model.table}
                                rowSelectionMode='single'
                                downloadCSVAs={model.assay.shorthand}
                                headerContent={<ToggleUnitsWrapper model={model} />}
                            />
                        )}
                        {model.isCurveMeasurement && (
                            <Split direction='vertical' gutterSize={6} sizes={[0.5, 0.5]}>
                                <AssayPlotWrapper model={model} />
                                <AssayDetailTable
                                    source={model.assay.source.toUpperCase()}
                                    table={model.table}
                                    rowSelectionMode='multi'
                                    downloadCSVAs={model.assay.shorthand}
                                    headerContent={<ToggleUnitsWrapper model={model} />}
                                />
                            </Split>
                        )}
                    </Split>
                )}
            </div>
        </PageTemplate>
    );
}

function AssayPlotWrapper({ model }: { model: AssayInformationModel }) {
    const valueColumn = useBehavior(model.state.valueColumn);

    return (
        <AssayPlotPanel
            valueColumn={valueColumn}
            figureSubject={model.state.figure}
            measurement={model.assay.property.measurement}
            table={model.table}
            values={model.values}
            onSelection={model.onSelection}
        />
    );
}

function ToggleUnitsWrapper({ model }: { model: AssayInformationModel }) {
    useBehavior(model.table.version);
    if (model.assay.property.common_unit !== 'potens') return null;
    const units = { use_potens: !model.table.state.customState[PotensAsNmProp] };

    return (
        <PotensNMToggle
            units={units}
            onToggle={(value: 'potens' | 'nM') =>
                model.table.setCustomState(
                    { [PotensAsNmProp]: value === 'nM' },
                    { silent: false, clearRenderCache: true }
                )
            }
        />
    );
}
