import Plotly, { ModeBarDefaultButtons, PlotMouseEvent, PlotSelectionEvent } from 'plotly.js-dist';
import { useEffect, useMemo, useRef } from 'react';
import { BehaviorSubject, Subject } from 'rxjs';
import usePlotly from '../../lib/hooks/usePlotly';
import { PlotlyFigure, PlotlyPointClickEventData } from './model';

// NOTE: work in progress towards "common plot component"

interface PlotProps {
    figure: PlotlyFigure;
    hoverIndex?: BehaviorSubject<number | undefined>;
    selectedPoints?: BehaviorSubject<Plotly.PlotDatum[]>;
    clickIndex?: Subject<PlotlyPointClickEventData>;
    modeBarButtons?: ModeBarDefaultButtons[][];
    hideModebar?: boolean;
    onAutosize?: () => void;
    onZoom?: (xRange?: any[], yRange?: any[]) => void;
    handleLegendClick?: (evtData: any) => any;
    onSelection?: (selected: Plotly.PlotDatum[]) => void;
}

export function Plot({
    figure,
    hoverIndex,
    selectedPoints,
    modeBarButtons,
    clickIndex,
    hideModebar,
    onAutosize,
    onZoom,
    handleLegendClick,
    onSelection,
}: PlotProps) {
    const { data, layout } = figure;
    const config = useMemo(
        () =>
            ({
                displayModeBar: hideModebar ? false : 'hover',
                modeBarButtons,
                responsive: true,
            } as Partial<Plotly.Config>),
        [hideModebar, modeBarButtons]
    );
    const plotRef = usePlotly(data, layout, config);
    const events = useRef<{
        hover: any;
        unhover: any;
        select: any;
        deselect: any;
        click: any;
        legendclick: any;
        relayout: any;
    }>();

    function handleHover(event: PlotMouseEvent) {
        if (event.points.length > 0) {
            hoverIndex?.next(event.points[0].pointIndex);
        }
    }

    function handleUnhover() {
        hoverIndex?.next(undefined);
    }

    function handleSelection(event: PlotMouseEvent) {
        if (!event) return;
        selectedPoints?.next(event?.points ?? []);
        onSelection?.(event.points);
    }

    function handleDeselection() {
        selectedPoints?.next([]);
        onSelection?.([]);
    }

    function handleClick(event: PlotMouseEvent) {
        if (event.points[0]) {
            clickIndex?.next({
                pointIndex: event.points[0]?.pointIndex,
                curveNumber: event.points[0]?.curveNumber,
            });
        } else {
            clickIndex?.next(undefined);
        }
    }

    function handleRelayout(evtData: any) {
        if (typeof onAutosize === 'function' && evtData['xaxis.autorange']) {
            onAutosize();
        }
        if (typeof onZoom === 'function' && (evtData['xaxis.range[0]'] || evtData['yaxis.range[0]'])) {
            const xRange = evtData['xaxis.range[0]']
                ? [evtData['xaxis.range[0]'], evtData['xaxis.range[1]']]
                : undefined;
            const yRange = evtData['yaxis.range[0]']
                ? [evtData['yaxis.range[0]'], evtData['yaxis.range[1]']]
                : undefined;

            onZoom(xRange, yRange);
        }
    }

    events.current = {
        hover: handleHover,
        unhover: handleUnhover,
        select: handleSelection,
        deselect: handleDeselection,
        click: handleClick,
        legendclick: handleLegendClick,
        relayout: handleRelayout,
    };

    useEffect(() => {
        if (plotRef.current) {
            const target = plotRef.current; // need to store the value due to how refs work.

            // Need to call the "current" version of the event since
            // the hook would otherwise call the initial closure, which would
            // have out of data smileIndexMap.
            const onHover = (event: PlotMouseEvent) => events.current?.hover(event);
            const onUnhover = (event: PlotMouseEvent) => events.current?.unhover(event);
            const onSelected = (event: PlotSelectionEvent) => events.current?.select(event);
            const onDeselected = () => events.current?.deselect();
            const onClick = (event: PlotMouseEvent) => events.current?.click(event);
            const onLegendClick = (evtData: any) => events.current?.legendclick(evtData);
            const onRelayout = (evtData: any) => events.current?.relayout(evtData);

            target.on('plotly_hover', onHover);
            target.on('plotly_unhover', onUnhover);
            target.on('plotly_selected', onSelected);
            target.on('plotly_deselect', onDeselected);
            target.on('plotly_click', onClick);
            target.on('plotly_legendclick', onLegendClick);
            target.on('plotly_relayout', onRelayout);

            // const selSub = selectedIndices?.subscribe((points) => {
            //     try {
            //         Plotly.update(target, { selectedpoints: points.length > 0 ? [points] : [] }, {});
            //     } catch (e) {
            //         log.error(e);
            //     }
            // });

            return () => {
                target.removeEventListener?.('plotly_hover', onHover as any);
                target.removeEventListener?.('plotly_unhover', onUnhover as any);
                target.removeEventListener?.('plotly_selected', onSelected as any);
                target.removeEventListener?.('plotly_deselected', onDeselected as any);
                target.removeEventListener?.('plotly_click', onClick as any);
                target.removeEventListener?.('plotly_legendclick', onLegendClick as any);
                target.removeEventListener?.('plotly_relayout', onRelayout as any);
                // selSub?.unsubscribe();
            };
        }
    }, [plotRef.current]);

    return <div ref={plotRef as any} className='w-100 h-100 position-relative' />;
}
