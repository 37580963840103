/* eslint-disable object-shorthand */
import log from 'loglevel';
import { PlateDimensions, WellLayout } from '../../../HTE/experiment-data';
import { getWellIndex } from '../../../HTE/plate/utils';
import type { ECMARPTemplate, ECMARPTemplateWell } from '../data';
import { ANA_1532c_1pt } from './ANA_1532c_1pt';
import { ANA_1536_768c_1pt_4empty } from './ANA_1536_768c_1pt_4empty';
import { K_384_19c_7pt_dup_2ctrl } from './K_384_19c_7pt_dup_2ctrl';
import { K_384_42c_3pt_dup_2ctrl_7pt } from './K_384_42c_3pt_dup_2ctrl[7pt]';
import { K_BC_384_168c_1pt_dup_1ctrl_2pt } from './K_BC_384_168c_1pt_dup_1ctrl[2pt]';
import { K_BC_384_168c_1pt_dup_1ctrl_3pt_100uM_v2 } from './K_BC_384_168c_1pt_dup_1ctrl[3pt]_100uM_v2';
import { K_BC_384_16c_11pt_dup_1ctrl } from './K_BC_384_16c_11pt_dup_1ctrl';
import { K_BC_384_2c_11pt_sixteen_1ctrl } from './K_BC_384_2c_11pt_sixteen_1ctrl';
import { K_BC_384_56c_3pt_dup_2ctrl } from './K_BC_384_56c_3pt_dup_2ctrl';
import { K_BC_384_7c_11pt_quad_2ctrl_2pt } from './K_BC_384_7c_11pt_quad_2ctrl[2pt]';
import { K_BC_384_14c_11pt_dup_3ctrl_30uM_LAYOUT } from './K_BC_384_14c_11pt_dup_3ctrl_30uM_LAYOUT';
import { M_384_12c_10pt_dup_2ctrl } from './M_384_12c_10pt_dup_2ctrl';
import { M_384_13c_10pt_dup_1ctrl } from './M_384_13c_10pt_dup_1ctrl';
import { M_384_20c_7pt_dup_1ctrl } from './M_384_20c_7pt_dup_1ctrl';
import { PPI_BC_384_8c_22pt_dup_1ctrl_30uM_LAYOUT } from './PPI_BC_384_8c_22pt_dup_1ctrl_30uM_LAYOUT';
import { Z_384_12c_7pt_trip_2ctrl } from './Z_384_12c_7pt_trip_2ctrl';
import { Z_CGI_96_2c_9pt_trip_T1 } from './Z_CGI_96_2c_9pt_trip_T1';
import { Z_NanoBRET_384_14c_9pt_dup } from './Z_NanoBRET_384_14c_9pt_dup';
import { _384_384c_1pt } from './_384_384c_1pt';

export const ECMARPLayouts = {
    '384_384c_1pt': _384_384c_1pt,
    ANA_1532c_1pt: ANA_1532c_1pt,
    ANA_1536_768c_1pt_4empty: ANA_1536_768c_1pt_4empty,
    K_384_19c_7pt_dup_2ctrl: K_384_19c_7pt_dup_2ctrl,
    'K_384_42c_3pt_dup_2ctrl[7pt]': K_384_42c_3pt_dup_2ctrl_7pt,
    K_BC_384_2c_11pt_sixteen_1ctrl: K_BC_384_2c_11pt_sixteen_1ctrl,
    'K_BC_384_7c_11pt_quad_2ctrl[2pt]': K_BC_384_7c_11pt_quad_2ctrl_2pt,
    K_BC_384_14c_11pt_dup_3ctrl_30uM_LAYOUT: K_BC_384_14c_11pt_dup_3ctrl_30uM_LAYOUT,
    K_BC_384_16c_11pt_dup_1ctrl: K_BC_384_16c_11pt_dup_1ctrl,
    K_BC_384_56c_3pt_dup_2ctrl: K_BC_384_56c_3pt_dup_2ctrl,
    'K_BC_384_168c_1pt_dup_1ctrl[2pt]': K_BC_384_168c_1pt_dup_1ctrl_2pt,
    'K_BC_384_168c_1pt_dup_1ctrl[3pt]_100uM_v2': K_BC_384_168c_1pt_dup_1ctrl_3pt_100uM_v2,
    M_384_12c_10pt_dup_2ctrl: M_384_12c_10pt_dup_2ctrl,
    M_384_13c_10pt_dup_1ctrl: M_384_13c_10pt_dup_1ctrl,
    M_384_20c_7pt_dup_1ctrl: M_384_20c_7pt_dup_1ctrl,
    PPI_BC_384_8c_22pt_dup_1ctrl_30uM_LAYOUT: PPI_BC_384_8c_22pt_dup_1ctrl_30uM_LAYOUT,
    Z_384_12c_7pt_trip_2ctrl: Z_384_12c_7pt_trip_2ctrl,
    Z_CGI_96_2c_9pt_trip_T1: Z_CGI_96_2c_9pt_trip_T1,
    Z_NanoBRET_384_14c_9pt_dup: Z_NanoBRET_384_14c_9pt_dup,
};

export const ECMARPLayoutNames = Array.from(Object.keys(ECMARPLayouts));

interface ARPTemplateRow {
    id: string;
    point: number;
    row: number;
    col: number;
    isControl: boolean;
}

export function parseARPTemplate(layoutData: string): ECMARPTemplate {
    const csvRows = layoutData.split('\n');
    const rows: ARPTemplateRow[] = [];

    const uniqueBatches = new Set<string>();
    const batchTest = /^\d+$/;

    for (let i = 1; i < csvRows.length; i++) {
        const fields = csvRows[i].split(',');
        if (fields.length < 5) {
            if (csvRows[i]) log.warn('Bad plate layout row', csvRows[i]);
            continue;
        }

        if (batchTest.test(fields[0])) uniqueBatches.add(fields[0]);

        rows.push({
            id: fields[0],
            point: +fields[1] - 1,
            row: +fields[2] - 1,
            col: +fields[3] - 1,
            isControl: fields[4].trim() === 'True',
        });
    }

    let maxRow = 0;
    for (const e of rows) {
        if (e.row > maxRow) maxRow = e.col;
    }

    let layout: WellLayout = 24;
    if (maxRow >= 30) layout = 1536;
    else if (maxRow >= 14) layout = 384;
    else if (maxRow >= 6) layout = 96;

    const wells: (ECMARPTemplateWell | null)[] = [];
    for (let i = 0; i < layout; i++) wells.push(null);

    const [width] = PlateDimensions[layout];

    for (const well of rows) {
        const wI = getWellIndex(width, well.row, well.col);
        wells[wI] = {
            sample_index: +well.id.toLowerCase().replace('control', '') - 1,
            point_index: well.point,
            is_control: well.isControl,
            // NOTE: The frontend parsing of the templates will be dropped after CM updates buckets
            //       => only supporting unknown controls here
            control_kind: well.isControl ? 'unknown' : undefined,
        };
    }

    return { wells, well_layout: layout };
}
