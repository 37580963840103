import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export interface PillNavStep<S extends string = string> {
    name: S;
    label: string;
    icon?: IconDefinition;
    separator?: boolean;
}

export function PillNav<S extends string = string>({
    steps,
    currentStep,
    onSetStep,
    header,
    containerClassName,
}: {
    steps: PillNavStep<S>[];
    currentStep: S;
    onSetStep: (s: S) => void;
    header?: ReactNode;
    containerClassName?: string;
}) {
    return (
        <div className={containerClassName ?? 'entos-pill-nav-buttons'}>
            {header}
            {steps.map((s) => (
                <StepButton key={s.name} step={s} currentStep={currentStep} onSetStep={onSetStep} />
            ))}
        </div>
    );
}

export function TabNav<S extends string = string>({
    steps,
    currentStep,
    onSetStep,
}: {
    steps: PillNavStep<S>[];
    currentStep: S;
    onSetStep: (s: S) => void;
}) {
    return (
        <div className='px-2 entos-tab-nav-buttons font-body-xsmall'>
            {steps.map((s) => (
                <StepButton key={s.name} step={s} currentStep={currentStep} onSetStep={onSetStep} />
            ))}
        </div>
    );
}

function StepButton<S extends string = string>({
    step,
    currentStep,
    onSetStep,
}: {
    step: PillNavStep<S>;
    currentStep: S;
    onSetStep: (s: S) => void;
}) {
    return (
        <Button
            onClick={() => currentStep !== step.name && onSetStep(step.name)}
            variant={currentStep === step.name ? 'primary' : 'secondary'}
            className={step.separator ? 'ms-4' : undefined}
            size='sm'
            title={step.label}
        >
            {step.icon && currentStep === step.name && (
                <FontAwesomeIcon icon={step.icon} size='xs' className='me-1' fixedWidth />
            )}
            {step.label}
        </Button>
    );
}

export interface LinkPillNavStep<S extends string = string> extends PillNavStep<S> {
    link: string;
}

export function LinkPillNav<S extends string = string>({
    steps,
    currentStep,
}: {
    steps: LinkPillNavStep<S>[];
    currentStep: S;
}) {
    return (
        <div className='entos-pill-nav-buttons'>
            {steps.map((s) => (
                <LinkStepButton key={s.name} step={s} currentStep={currentStep} />
            ))}
        </div>
    );
}

function LinkStepButton<S extends string = string>({
    step,
    currentStep,
}: {
    step: LinkPillNavStep<S>;
    currentStep: S;
}) {
    return (
        <Link
            to={step.link}
            className={`btn btn-sm${step.separator ? ' ms-4' : ''} btn-${
                currentStep === step.name ? 'primary' : 'secondary'
            }`}
            title={step.label}
        >
            {step.icon && currentStep === step.name && (
                <FontAwesomeIcon icon={step.icon} size='xs' className='me-1' fixedWidth />
            )}
            {step.label}
        </Link>
    );
}
