import { useCallback, useEffect, useMemo, useRef } from 'react';
import { TableInstance, useBlockLayout, useGlobalFilter, useSortBy, useTable } from 'react-table';
import { ReactTableGlobalFilter } from '../../../components/ReactTable/Filters';
import useBehavior from '../../../lib/hooks/useBehavior';
import { Reactant } from '../experiment-data';
import { HTEExperimentModel } from '../experiment-model';
import { HTEExperimentTable } from './common';
import { ReactantsFilter, ReactantsModel, useFilterReactants } from './reactants-model';

export function ReactantsStep({ model }: { model: HTEExperimentModel }) {
    const globalFilter = useRef<string>('');
    const stats = useBehavior(model.productPlate.state.stats);
    const design = useBehavior(model.state.design);
    const reactantsFilter = useBehavior(model.reactants.state.reactantsFilter);
    const reactants = useFilterReactants(design.reactants, reactantsFilter);
    // hack to redraw the table when number of uses changes without copying all the data
    // it is required here for undo/redo to work correctly
    const columns = useMemo(() => [...model.reactants.columns], [stats]);
    const table = useTable<Reactant>(
        {
            columns,
            data: reactants,
            initialState: {
                globalFilter: globalFilter.current,
            },
        },
        useGlobalFilter,
        useSortBy,
        useBlockLayout
    );

    const setGlobalFilter = useCallback(
        (v: any) => {
            globalFilter.current = v;
            table.setGlobalFilter(v);
        },
        [table.setGlobalFilter]
    );

    useEffect(() => {
        // eslint-disable-next-line
        model.reactants.state.currentRows = table.flatRows;
    }, [model, table.flatRows]);

    return (
        <div className='hte-experiment-reactants-step'>
            <div className='ps-4 pe-4 pt-4 pb-2'>
                <ReactantsHeader table={table} setGlobalFilter={setGlobalFilter} model={model.reactants} />
            </div>

            <div className='hte-experiment-reactants-content'>
                <HTEExperimentTable table={table} lineHeight={60} />
            </div>
        </div>
    );
}

function ReactantsHeader({
    table,
    setGlobalFilter,
    model,
}: {
    table: TableInstance<Reactant>;
    setGlobalFilter: (v: any) => any;
    model: ReactantsModel;
}) {
    return (
        <div className='vstack gap-1'>
            <ReactTableGlobalFilter
                preGlobalFilteredRows={table.preGlobalFilteredRows}
                globalFilter={table.state.globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
            <ReactantsFilter filter={model.state.reactantsFilter} reactants={model} unsolvatedButton />
        </div>
    );
}
