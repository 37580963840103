import { faAngleDoubleUp, faRefresh, faWandSparkles } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'react-bootstrap';
import Split from 'react-split-it';
import { DataTableControl, DataTableGlobalFilter, DataTableModel } from '../../../components/DataTable';
import { AsyncButton } from '../../../components/common/AsyncButton';
import { IconButton, IconDropdownButton } from '../../../components/common/IconButton';
import useBehavior from '../../../lib/hooks/useBehavior';
import useMountedModel from '../../../lib/hooks/useMountedModel';
import { useModelAction } from '../../../lib/util/reactive-model';
import { HTE2MSModel } from '../model';
import { HTE2MSPromotionModel } from './model';
import { objectIsEmpty } from '../../../lib/util/misc';

export function HTE2MSPromotionUI({ model }: { model: HTE2MSModel }) {
    useMountedModel(model.promotion);

    return (
        <Split>
            <PurifiedTable model={model.promotion} />
            <PromotedTable model={model.promotion} />
        </Split>
    );
}

function RefreshDataButton({ model }: { model: HTE2MSPromotionModel }) {
    const status = useModelAction(model.actions.syncData);
    return (
        <AsyncButton
            onClick={model.refreshData}
            title='Refresh Promotion Data'
            icon={faRefresh}
            size='sm'
            variant='link'
            state={{ isLoading: status.kind === 'loading' }}
        >
            Refresh
        </AsyncButton>
    );
}

function PromoteButton({ model }: { model: HTE2MSPromotionModel }) {
    useBehavior(model.purifiedTable.version);

    return (
        <IconButton
            onClick={model.confirmPromote}
            icon={faAngleDoubleUp}
            size='sm'
            variant='link'
            disabled={objectIsEmpty(model.purifiedTable.selectedRows)}
        >
            Promote
        </IconButton>
    );
}

function PurifiedTable({ model }: { model: HTE2MSPromotionModel }) {
    return (
        <div className='d-flex flex-column h-100'>
            <div className='hstack gap-2 p-2 pe-0'>
                <div className='flex-grow-1'>
                    <DataTableGlobalFilter table={model.purifiedTable} size='sm' />
                </div>
                <RefreshDataButton model={model} />
                <IconDropdownButton icon={faWandSparkles} label='Automation' size='sm' variant='link'>
                    <Dropdown.Item onClick={() => model.selectPurityFilter()}>Select Purity % &gt;= 85</Dropdown.Item>
                </IconDropdownButton>
                <PromoteButton model={model} />
            </div>
            <div className='flex-grow-1 position-relative'>
                <TableWrapper table={model.purifiedTable} />
            </div>
        </div>
    );
}

function PromotedTable({ model }: { model: HTE2MSPromotionModel }) {
    return (
        <div className='d-flex flex-column h-100'>
            <div className='hstack gap-2 p-2'>
                <div className='flex-grow-1'>
                    <DataTableGlobalFilter table={model.promotedTable} size='sm' />
                </div>
            </div>
            <div className='flex-grow-1 position-relative'>
                <TableWrapper table={model.promotedTable} />
            </div>
        </div>
    );
}

function TableWrapper({ table }: { table: DataTableModel }) {
    useBehavior(table.version);

    return <DataTableControl table={table} height='flex' headerSize='xxsm' className='hte2ms-hidden-button-table' />;
}
