import { IconName, IconDefinition } from '@fortawesome/fontawesome-svg-core';

// This is a way to make a new icon to be treated like icons in the
// Font Awesome set - structure kind of copied from their icon definitions.
// see node_modules/@fortawesome/node_modules/@fortawesome/fontawesome-common-types/index.d.ts
// for the IconDefinition type definition

const iconName = 'ii-entos-molecule' as IconName;
const prefix = 'fas';
const width: number = 12.67;
const height: number = 12;
const aliases: string[] = [];
const unicode: string = '';
const svgPathData: string =
    'M2.84667 4.66667L4 2.66667H7.61333L8.38667 1.33333L8.33333 1C8.33333 0.734784 8.43869 0.48043 8.62623 0.292893C8.81376 0.105357 9.06812 0 9.33333 0C9.59855 0 9.8529 0.105357 10.0404 0.292893C10.228 0.48043 10.3333 0.734784 10.3333 1C10.3333 1.47333 10 1.87333 9.55333 1.97333L8.91333 3.08667L9.82 4.66667L10.3933 3.66667L10.3333 3.33333C10.3333 3.06812 10.4387 2.81376 10.6262 2.62623C10.8138 2.43869 11.0681 2.33333 11.3333 2.33333C11.5986 2.33333 11.8529 2.43869 12.0404 2.62623C12.228 2.81376 12.3333 3.06812 12.3333 3.33333C12.3333 3.80667 12 4.2 11.5667 4.30667L10.5933 6L11.7467 8C12.26 8.04667 12.6667 8.47333 12.6667 9C12.6667 9.26522 12.5613 9.51957 12.3738 9.70711C12.1862 9.89464 11.9319 10 11.6667 10C11.4015 10 11.1471 9.89464 10.9596 9.70711C10.772 9.51957 10.6667 9.26522 10.6667 9V8.82667L9.82 7.33333L8.91333 8.91333L9.55333 10.0267C10 10.1267 10.3333 10.5267 10.3333 11C10.3333 11.2652 10.228 11.5196 10.0404 11.7071C9.8529 11.8946 9.59855 12 9.33333 12C9.06812 12 8.81376 11.8946 8.62623 11.7071C8.43869 11.5196 8.33333 11.2652 8.33333 11L8.38667 10.6667L7.61333 9.33333H5.05333L4.28 10.6667L4.33333 11C4.33333 11.2652 4.22798 11.5196 4.04044 11.7071C3.8529 11.8946 3.59855 12 3.33333 12C3.06812 12 2.81376 11.8946 2.62623 11.7071C2.43869 11.5196 2.33333 11.2652 2.33333 11C2.33333 10.5267 2.66667 10.1267 3.11333 10.0267L3.75333 8.91333L0.92 4C0.406667 3.95333 0 3.52667 0 3C0 2.73478 0.105357 2.48043 0.292893 2.29289C0.48043 2.10536 0.734784 2 1 2C1.26522 2 1.51957 2.10536 1.70711 2.29289C1.89464 2.48043 2 2.73478 2 3C2 3.06 2 3.12 2 3.17333L2.84667 4.66667ZM4.76667 4L3.61333 6L4.76667 8H7.9L9.05333 6L7.9 4H4.76667Z';

export const iiEntosMolecule: IconDefinition = {
    prefix,
    iconName,
    icon: [width, height, aliases, unicode, svgPathData],
};
