import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import saveAs from 'file-saver';
import { ReactNode, useState } from 'react';
import { Button, Popover } from 'react-bootstrap';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { Column, ColumnFilters, DataTableModel, ObjectDataTableStore } from '../../../components/DataTable';
import { TableCellPopover } from '../../../components/DataTable/common';
import { InlineAlert } from '../../../components/common/Alert';
import { SimpleMultiFileUploadV2, SingleFileUploadV2 } from '../../../components/common/FileUpload';
import { LabeledInput, TextInput } from '../../../components/common/Inputs';
import useBehavior from '../../../lib/hooks/useBehavior';
import { ClipboardService } from '../../../lib/services/clipboard';
import { DialogService } from '../../../lib/services/dialog';
import { ToastService } from '../../../lib/services/toast';
import { DateLike } from '../../../lib/util/dates';
import { memoizeLatest } from '../../../lib/util/misc';
import { ReactiveModel } from '../../../lib/util/reactive-model';
import { formatWithUnit, parseWithUnit } from '../../../lib/util/units';
import { BatchLink } from '../../ECM/ecm-common';
import { HTE2MSApi } from '../api';
import {
    HTEDDistributedCompound,
    HTEDistribution,
    HTEDistributionEntry,
    HTEDistributionIdT,
    HTEDistributionOptions,
} from '../data-model';
import { EditLabwareOption, Formatters } from '../utils';
import {
    AddDistributionValidationState,
    DistributionValidationDialogContent,
    DistributionValidationInputDialogContent,
    HTE2MSDistributionValidationModel,
} from './distribution-validation';
import type { HTE2MSPostProductionModel } from './model';

export interface CompoundRow {
    original: HTEDDistributedCompound;
    identifier_search: string;
    batch_identifier: string;
    pooled_barcodes: string[];
    invalid_tares?: string[];
    liquid_barcode: string;
    dry_barcode: string | undefined;
    total_amount: number;
    solubilize_concentration: number;
    solubilize_volume: number;
    expected_powder_amount?: number;
    pooled_on?: DateLike;
    errors?: string[];
}

const EmptyDistribution: HTEDistribution = {
    distributions: [],
    registered: {},
};

export class HTE2MSDistributionModel extends ReactiveModel {
    store = new ObjectDataTableStore<CompoundRow, HTEDDistributedCompound>([
        {
            name: 'original',
            getter: (v) => v,
        },
        {
            name: 'identifier_search',
            getter: (v) => {
                const batch = this.assets.entities.getBatch(v.batch_id);
                return this.assets.entities.getIdentifiersLookup(batch?.universal_identifier);
            },
        },
        {
            name: 'batch_identifier',
            getter: (v) => v.batch_identifier,
        },
        {
            name: 'pooled_barcodes',
            getter: (v) => Object.keys(v.amounts),
        },
        {
            name: 'pooled_on',
            getter: (v) => v.pooled_on,
        },
        {
            name: 'invalid_tares',
            getter: (v) => v.invalid_tares,
        },
        {
            name: 'errors',
            getter: (v) => v.errors,
        },
        {
            name: 'liquid_barcode',
            getter: (v) => this.current?.liquid_barcodes[v.id] ?? '',
        },
        {
            name: 'dry_barcode',
            getter: (v) => this.current?.dry_barcodes[v.id] ?? '',
        },
        {
            name: 'total_amount',
            getter: (v) => v.total_amount,
        },
        {
            name: 'solubilize_concentration',
            getter: (v) => v.solubilize_concentration,
        },
        {
            name: 'solubilize_volume',
            getter: (v) => v.solubilize_volume,
        },
        {
            name: 'expected_powder_amount',
            getter: (v) => v.expected_dry_stock_amount,
        },
    ]);

    table: DataTableModel<CompoundRow>;

    state = {
        data: new BehaviorSubject<HTEDistribution>(EmptyDistribution),
        currentId: new BehaviorSubject<HTEDistributionIdT | undefined>(undefined),
    };

    get mainModel() {
        return this.postProduction.model;
    }

    get data() {
        return this.state.data.value;
    }

    get assets() {
        return this.mainModel.assets;
    }

    get currentId() {
        return this.state.currentId.value;
    }

    private _current = memoizeLatest((data: HTEDistribution, id: HTEDistributionIdT | undefined) => {
        if (!id) return undefined;
        for (const p of data.distributions) {
            if (p.id === id) return p;
        }
        return undefined;
    });

    get current() {
        return this._current(this.data, this.currentId);
    }

    private _pooledRackmap = memoizeLatest((data: HTEDistributionEntry | undefined) => {
        const ret = new Map<string, string>();
        if (!data) return ret;
        for (const rack of data.input.pooled_racks) {
            for (const [well, barcode] of Object.entries(rack.wells)) {
                ret.set(barcode, `${rack.label}:${well}`);
            }
        }
        return ret;
    });

    get pooledRackmap() {
        return this._pooledRackmap(this.current);
    }

    validateDistribution = () => {
        DialogService.open({
            type: 'generic',
            title: 'Validate Distribution',
            confirmButtonContent: 'Validate',
            model: this,
            wrapOk: true,
            defaultState: {
                files: {
                    pooled_racks_files: [],
                },
            } satisfies AddDistributionValidationState,
            content: DistributionValidationInputDialogContent,
            onOk: (state: AddDistributionValidationState) => this._applyValidate(state),
        });
    };

    private async _applyValidate(state: AddDistributionValidationState) {
        const result = await HTE2MSApi.validateDistribution(this.mainModel.experiment?.id!, {
            pooled_racks_files: state.files.pooled_racks_files,
        });

        const model = new HTE2MSDistributionValidationModel(result);
        setTimeout(() => {
            DialogService.open({
                type: 'generic',
                title: 'Distribution Validation',
                confirmButtonContent: 'Close',
                model,
                content: DistributionValidationDialogContent,
                options: { size: 'xl', staticBackdrop: true },
            });
        }, 33);
    }

    addDistibution = () => {
        DialogService.open({
            type: 'generic',
            title: 'Add Distribution',
            confirmButtonContent: 'Apply',
            model: this,
            wrapOk: true,
            defaultState: {
                files: {
                    pooled_racks_file: undefined,
                    pooled_total_mass_file: undefined,
                    dry_racks_files: [],
                    liquid_racks_files: [],
                },
                options: this.mainModel.assets.defaults.default_distribution_options,
            } satisfies AddDistributionState,
            content: AddDistributionDialogContent,
            onOk: (state: AddDistributionState) => this._applyAdd(state),
        });
    };

    private async _applyAdd(state: AddDistributionState) {
        if (!this.mainModel.experiment) return;

        if (!state.files.pooled_total_mass_file) {
            throw new Error('No pooled total mass file provided');
        }

        const result = await HTE2MSApi.addDistribution(
            this.mainModel.experiment?.id!,
            {
                pooled_racks_file: state.files.pooled_racks_file,
                pooled_total_mass_file: state.files.pooled_total_mass_file,
                dry_racks_files: state.files.dry_racks_files,
                liquid_racks_files: state.files.liquid_racks_files,
            },
            {
                options: state.options,
                last_modified_on: this.mainModel.last_modified_on!,
            }
        );

        this.mainModel.updateFoundryInfo({ experiment: result.experiment });
        const latest = result.distribution.distributions[result.distribution.distributions.length - 1];
        await this.mainModel.assets.entities.syncBatchIds(Array.from(new Set(latest.compounds.map((c) => c.batch_id))));
        this.setData(result.distribution);
        this.state.currentId.next(latest.id);
    }

    confirmRemove(id: HTEDistributionIdT) {
        DialogService.open({
            type: 'generic',
            title: 'Remove Distribution',
            confirmButtonContent: 'Remove',
            model: this,
            wrapOk: true,
            content: RemoveDistributionDialogContent,
            onOk: () => this._applyRemove(id),
        });
    }

    private async _applyRemove(id: HTEDistributionIdT) {
        if (!this.mainModel.experiment) return;

        const result = await HTE2MSApi.removeDistribution(this.mainModel.experiment?.id!, id, {
            last_modified_on: this.mainModel.last_modified_on!,
        });

        this.mainModel.updateFoundryInfo({ experiment: result.experiment });
        this.setData(result.distribution);

        if (this.state.currentId.value === id) {
            this.state.currentId.next(undefined);
        }

        ToastService.info('Distribution removed');
    }

    confirmRegister(id: HTEDistributionIdT) {
        DialogService.open({
            type: 'generic',
            title: 'Register Distribution',
            confirmButtonContent: 'Apply',
            model: this,
            wrapOk: true,
            content: ConfirmRegistrationDialogContent,
            onOk: () => this._applyRegister(id),
        });
    }

    private async _applyRegister(id: HTEDistributionIdT) {
        if (!this.mainModel.experiment) return;

        const result = await HTE2MSApi.registerDistribution(this.mainModel.experiment?.id!, id, {
            last_modified_on: this.mainModel.last_modified_on!,
        });

        this.mainModel.updateFoundryInfo({ experiment: result.experiment });
        this.setData(result.distribution);

        ToastService.success('Distribution registered');
    }

    exportLiquidBarcodes(how: 'copy' | 'save') {
        const { current } = this;
        if (!current) return;

        const barcodes = this.store.rawRows
            .map((c) => current.liquid_barcodes[c.id])
            .filter((x) => x)
            .join('\n');
        if (!barcodes) {
            return ToastService.warning('No barcodes to copy');
        }

        if (how === 'copy') {
            ClipboardService.copyText(barcodes, 'Copy Barcodes');
        } else {
            saveAs(
                new Blob([barcodes], { type: 'text/csv' }),
                `${this.mainModel.libraryId}-liquid_stock-${+current.timestamp}.csv`
            );
        }
    }

    saveProtocol(options: { protocol: string; name: string }) {
        const filename = `${this.mainModel.libraryId}-${options.name}.gwl`;
        saveAs(new Blob([options.protocol], { type: 'text/plain' }), filename);
    }

    setData(data: HTEDistribution) {
        this.state.data.next(data);
    }

    async init(data: HTEDistribution) {
        this.setData(data);
        await this.syncAssets(data);
    }

    async syncAssets(data: HTEDistribution) {
        const batchIds = data.distributions.flatMap((d) => d.compounds.map((c) => c.batch_id));
        await this.mainModel.assets.entities.syncBatchIds(Array.from(new Set(batchIds)));
    }

    mount() {
        const update = combineLatest([this.state.data, this.state.currentId]);

        this.subscribe(update, () => {
            this.store.setRows(this.current?.compounds ?? []);
            this.table.dataChanged();
        });
    }

    constructor(public postProduction: HTE2MSPostProductionModel) {
        super();

        this.table = new DataTableModel<CompoundRow>(this.store, {
            columns: {
                identifier_search: {
                    ...Column.str(),
                    render: ({ value }) => value,
                },
                batch_identifier: {
                    ...Column.str(),
                    header: 'Identifier',
                    render: ({ value }) => (
                        <BatchLink identifier={this.assets.entities.getIdentifier(value)} withQuery />
                    ),
                    width: 180,
                },
                pooled_barcodes: {
                    kind: 'generic',
                    header: 'Pooled Barcodes',
                    format: (v) => '<unused>',
                    render: ({ value }) => {
                        if (value.length) return value?.join(', ');
                        return <span className='text-warning'>No valid vials</span>;
                    },
                    width: 200,
                    compare: false,
                    globalFilterFn: () => ColumnFilters.caseInsensitiveArray,
                },
                invalid_tares: {
                    kind: 'generic',
                    header: 'Invalid Tares',
                    format: (v) => '<unused>',
                    render: ({ value }) => {
                        if (!value?.length) return null;
                        const map = this.pooledRackmap;
                        return (
                            <span className='text-warning'>
                                {value.map((v, i) => (
                                    <span key={i}>
                                        {v} ({map?.get(v) ?? '?'}){i < value.length - 1 ? ', ' : ''}
                                    </span>
                                ))}
                            </span>
                        );
                    },
                    width: 200,
                    compare: false,
                    globalFilterFn: () => ColumnFilters.caseInsensitiveArray,
                },
                pooled_on: {
                    ...Column.datetime({ format: 'full' }),
                    header: 'Pooled on',
                    width: 160,
                },
                liquid_barcode: {
                    ...Column.str(),
                    header: 'Liquid Barcode',
                    render: ({ value }) => value,
                    width: 120,
                },
                dry_barcode: {
                    ...Column.str(),
                    header: 'Dry Barcode',
                    render: ({ value }) => value,
                    width: 120,
                },
                total_amount: {
                    ...Column.float(),
                    header: 'Total Amount',
                    align: 'right',
                    render: ({ value }) => Formatters.amount(value),
                    width: 100,
                },
                solubilize_concentration: {
                    ...Column.float(),
                    header: 'Sol. Conc.',
                    align: 'right',
                    render: ({ value }) => Formatters.concentration(value),
                    width: 100,
                },
                solubilize_volume: {
                    ...Column.float(),
                    header: 'Sol. Volume',
                    align: 'right',
                    render: ({ value }) => Formatters.siVolume(value),
                    width: 100,
                },
                expected_powder_amount: {
                    ...Column.float(),
                    header: 'Exp. Powder Amount',
                    align: 'right',
                    render: ({ value }) => Formatters.amount(value),
                    width: 100,
                },
                errors: {
                    kind: 'generic',
                    header: 'Errors',
                    format: (v) => '<unused>',
                    render: ({ value, rowIndex }) => {
                        if (!value?.length) return null;

                        let extraErrors: ReactNode | undefined;
                        if (value.length > 1) {
                            extraErrors = (
                                <div className='hte2ms-postprd-checklist-extra-cell'>
                                    <TableCellPopover
                                        inBody
                                        id={`error-${rowIndex}`}
                                        buttonClassName='entos-averaged-assay-value'
                                        popoverHeader={<Popover.Header>Extras</Popover.Header>}
                                        buttonContent={
                                            <span className='text-danger'>(and {value.length - 1} more)</span>
                                        }
                                        popoverBody={
                                            <Popover.Body>
                                                <div className='vstack'>
                                                    {value.map((error, i) => (
                                                        <div key={i}>
                                                            <FontAwesomeIcon
                                                                icon={faExclamationCircle}
                                                                className='text-danger me-2'
                                                                size='xs'
                                                                fixedWidth
                                                            />
                                                            {error}
                                                        </div>
                                                    ))}
                                                </div>
                                            </Popover.Body>
                                        }
                                    />
                                </div>
                            );
                        }

                        return (
                            <>
                                <FontAwesomeIcon
                                    icon={faExclamationCircle}
                                    className='text-danger me-2'
                                    size='xs'
                                    fixedWidth
                                />
                                <span className='text-danger'>{value[0]}</span>
                                {extraErrors}
                            </>
                        );
                    },
                    width: 500,
                    compare: false,
                    globalFilterFn: () => ColumnFilters.caseInsensitiveArray,
                },
            },
            globalFilterHiddenColumns: true,
            hideNonSchemaColumns: true,
        });

        this.table.setColumnVisibility('identifier_search', false);
    }
}

interface AddDistributionState {
    files: {
        pooled_total_mass_file: File | undefined;
        pooled_racks_file: File | undefined;
        liquid_racks_files: File[];
        dry_racks_files: File[];
    };
    options: HTEDistributionOptions;
}

const LabelWidth = 160;

function AddDistributionDialogContent({ stateSubject }: { stateSubject: BehaviorSubject<AddDistributionState> }) {
    const state = useBehavior(stateSubject);
    const [showOptions, setShowOptions] = useState(false);

    const updateFiles = (key: keyof AddDistributionState['files'], files: File | File[] | undefined) =>
        stateSubject.next({ ...state, files: { ...state.files, [key]: files } });

    return (
        <div className='vstack gap-1 font-body-small'>
            <InlineAlert className='mb-1'>
                <b>TIP:</b> Select <b>Current Mass</b> and click <b>Apply</b> to show number of required tubes and
                vials.
            </InlineAlert>
            <LabeledInput label='Current Mass' labelWidth={LabelWidth}>
                <div className='hte2ms-inline-upload-wrapper'>
                    <SingleFileUploadV2
                        file={state.files.pooled_total_mass_file}
                        onDrop={(files) => updateFiles('pooled_total_mass_file', files[0])}
                        label='Single .csv, .xls, .xlsx'
                        extensions={['.csv', '.xls', '.xlsx']}
                        inline
                    />
                </div>
            </LabeledInput>
            <LabeledInput label='Pooled Rackscan' labelWidth={LabelWidth}>
                <div className='hte2ms-inline-upload-wrapper'>
                    <SingleFileUploadV2
                        file={state.files.pooled_racks_file}
                        onDrop={(files) => updateFiles('pooled_racks_file', files[0])}
                        label='Single .csv, .xls, .xlsx'
                        extensions={['.csv', '.xls', '.xlsx']}
                        inline
                    />
                </div>
            </LabeledInput>
            <LabeledInput label='Liquid Racks' labelWidth={LabelWidth}>
                <div className='hte2ms-inline-upload-wrapper'>
                    <SimpleMultiFileUploadV2
                        files={state.files.liquid_racks_files}
                        onDrop={(files) => updateFiles('liquid_racks_files', files)}
                        label='One or more .csv, .xls, .xlsx'
                        extensions={['.csv', '.xls', '.xlsx']}
                        inline
                    />
                </div>
            </LabeledInput>
            <LabeledInput label='Dry Racks' labelWidth={LabelWidth}>
                <div className='hte2ms-inline-upload-wrapper'>
                    <SimpleMultiFileUploadV2
                        files={state.files.dry_racks_files}
                        onDrop={(files) => updateFiles('dry_racks_files', files)}
                        label='One or more .csv, .xls, .xlsx'
                        extensions={['.csv', '.xls', '.xlsx']}
                        inline
                    />
                </div>
            </LabeledInput>

            <Button
                variant='link'
                className='p-0 font-body-small fw-bold text-start'
                onClick={() => setShowOptions((v) => !v)}
            >
                {showOptions ? 'Hide' : 'Show'} Options
            </Button>

            {showOptions && (
                <div className='vstack gap-1'>
                    <LabeledInput label='Pooled Labware' labelWidth={LabelWidth}>
                        <EditLabwareOption
                            value={state.options.pooled_labware}
                            setValue={(l) =>
                                stateSubject.next({
                                    ...state,
                                    options: { ...state.options, pooled_labware: l },
                                })
                            }
                        />
                    </LabeledInput>
                    <LabeledInput label='Liquid Labware' labelWidth={LabelWidth}>
                        <EditLabwareOption
                            value={state.options.liquid_labware}
                            setValue={(l) =>
                                stateSubject.next({
                                    ...state,
                                    options: { ...state.options, liquid_labware: l },
                                })
                            }
                        />
                    </LabeledInput>
                    <LabeledInput label='Dry Labware' labelWidth={LabelWidth}>
                        <EditLabwareOption
                            value={state.options.dry_labware}
                            setValue={(l) =>
                                stateSubject.next({
                                    ...state,
                                    options: { ...state.options, dry_labware: l },
                                })
                            }
                        />
                    </LabeledInput>
                    <LabeledInput label='Solvent Labware' labelWidth={LabelWidth}>
                        <EditLabwareOption
                            value={state.options.solvent_labware}
                            setValue={(l) =>
                                stateSubject.next({
                                    ...state,
                                    options: { ...state.options, solvent_labware: l },
                                })
                            }
                        />
                    </LabeledInput>
                    <LabeledInput label='Liquid Volume' labelWidth={LabelWidth}>
                        <TextInput
                            value={formatWithUnit(state.options.liquid_vial_volume, 1e6, 'mL')}
                            size='sm'
                            tryUpdateValue={(v) => parseWithUnit(v, 'mL', true)}
                            setValue={(v) =>
                                stateSubject.next({ ...state, options: { ...state.options, liquid_vial_volume: v } })
                            }
                        />
                    </LabeledInput>
                    <LabeledInput label='Liquid Concentration' labelWidth={LabelWidth}>
                        <TextInput
                            value={formatWithUnit(state.options.liquid_vial_concentration, 1e3, 'mM')}
                            size='sm'
                            tryUpdateValue={(v) => parseWithUnit(v, 'mM', true)}
                            setValue={(v) =>
                                stateSubject.next({
                                    ...state,
                                    options: { ...state.options, liquid_vial_concentration: v },
                                })
                            }
                        />
                    </LabeledInput>
                    <LabeledInput label='Max Dry Volume' labelWidth={LabelWidth}>
                        <TextInput
                            value={formatWithUnit(state.options.dry_vial_volume, 1e6, 'mL')}
                            size='sm'
                            tryUpdateValue={(v) => parseWithUnit(v, 'mL', true)}
                            setValue={(v) =>
                                stateSubject.next({ ...state, options: { ...state.options, dry_vial_volume: v } })
                            }
                        />
                    </LabeledInput>

                    <LabeledInput
                        label='Dry Discard Volume'
                        labelWidth={LabelWidth}
                        tooltip='Do not create dry vial if the transfer volume is below the specified threshold'
                    >
                        <TextInput
                            value={formatWithUnit(state.options.dry_vial_discard_volume, 1e6, 'mL')}
                            size='sm'
                            tryUpdateValue={(v) => parseWithUnit(v, 'mL', true)}
                            setValue={(v) =>
                                stateSubject.next({
                                    ...state,
                                    options: { ...state.options, dry_vial_discard_volume: v },
                                })
                            }
                        />
                    </LabeledInput>

                    <LabeledInput label='Max Solubilize Conc.' labelWidth={LabelWidth}>
                        <TextInput
                            value={formatWithUnit(state.options.max_solubilize_concentration, 1e3, 'mM')}
                            size='sm'
                            tryUpdateValue={(v) => parseWithUnit(v, 'mM', true)}
                            setValue={(v) =>
                                stateSubject.next({
                                    ...state,
                                    options: { ...state.options, max_solubilize_concentration: v },
                                })
                            }
                        />
                    </LabeledInput>
                    <LabeledInput label='Tecan Piston Volume' labelWidth={LabelWidth}>
                        <TextInput
                            value={formatWithUnit(state.options.tecan_piston_volume, 1e6, 'mL')}
                            size='sm'
                            tryUpdateValue={(v) => parseWithUnit(v, 'mL', true)}
                            setValue={(v) =>
                                stateSubject.next({ ...state, options: { ...state.options, tecan_piston_volume: v } })
                            }
                        />
                    </LabeledInput>
                </div>
            )}
        </div>
    );
}

function RemoveDistributionDialogContent() {
    return <div>Are you sure you want to remove this distribution entry?</div>;
}

function ConfirmRegistrationDialogContent() {
    return (
        <>
            <div>Do you want to register the selected distribution? This will:</div>
            <ul className='ps-3 mt-1'>
                <li>Receive and dispose pooled vials</li>
                <li>Register and update liquid stock vials</li>
                <li>Prepare dry stock vials for receiving</li>
                <li>Upload dry yield assay values for the distributed batches</li>
            </ul>
        </>
    );
}
