import api from '../../api';
import { AssayValueCreate } from '../../lib/assays/models';
import { EnumEntry } from '../../lib/models/common';
import { DateLike } from '../../lib/util/dates';
import { decodeEntosMsgpack } from '../../lib/util/serialization';
import { AssayDetail } from '../Assays/assay-api';
import { Batch, CompoundDetail, Substance } from '../Compounds/compound-api';
import { HTEExperimentInfo } from '../HTE/experiment-data';
import {
    HTE2LibraryDraft,
    HTE2MicroscaleLibrary,
    HTE2MicroscaleLibraryOptions,
    HTEDConditions,
    HTEDLabware,
    HTEDReaction,
    HTEDReactionIdT,
    HTEDValidationResult,
    HTEDesign,
    HTEDistribution,
    HTEDistributionOptions,
    HTEFinalQCIdT,
    HTEFinalQCOptions,
    HTEFinalizationData,
    HTEIVialRack,
    HTEInventory,
    HTEPCrudePlate,
    HTEPFractionIdT,
    HTEPPoolingIdT,
    HTEPPoolingScriptOptions,
    HTEPReagentKeyT,
    HTEProtocol,
    HTEPurification,
    HTEQualityControl,
    HTERInstructionIdT,
} from './data-model';

export type CompoundOrSubstanceResult =
    | { type: 'compound'; data: CompoundDetail }
    | { type: 'substance'; data: Substance };

export interface UploadCrudePlateResult {
    experiment?: HTEExperimentInfo;
    errors: UploadCrudePlateError[];
    plate_id?: number;
}

export interface UploadCrudePlateError {
    reaction_id?: HTEDReactionIdT;
    instruction_id?: HTERInstructionIdT;
    reagent_key?: HTEPReagentKeyT;
    message: string;
}

export interface BuildResult {
    experiment?: HTEExperimentInfo;
    protocol?: HTEProtocol;
    crude_plate: HTEPCrudePlate;
}

export interface DiluteReagentResult {
    library: HTE2MicroscaleLibrary;
    concentration: number;
    reaction_ids: HTEDReactionIdT[];
    diluted_reaction_ids: HTEDReactionIdT[];
    diluted_instruction_ids: HTERInstructionIdT[];
}

export interface UpdateInventoryResult {
    experiment: HTEExperimentInfo;
    inventory: HTEInventory;
}

export interface UpdatePurificationResult {
    experiment: HTEExperimentInfo;
    purification: HTEPurification;
}

export interface UpdateDistributionResult {
    experiment: HTEExperimentInfo;
    distribution: HTEDistribution;
}

export interface UpdateQCResult {
    experiment: HTEExperimentInfo;
    qc: HTEQualityControl;
}

export interface MicroscaleAssets {
    atmospheres: EnumEntry[];
    default_purification: HTEPurification;
    default_pooling_script_options: HTEPPoolingScriptOptions;
    default_distribution: HTEDistribution;
    default_distribution_options: HTEDistributionOptions;
    default_qc: HTEQualityControl;
    default_finalqc_options: HTEFinalQCOptions;
}

export interface HTEBatchPromotionData {
    assays: AssayDetail[];
    common_unit_assay_values: AssayValueCreate[];
    batches: Batch[];
    compounds: CompoundDetail[];
}

export interface HTEApplyPromotionResult {
    promoted_compounds: [before: string, after: string][];

    missing_purity_batch_identifiers: string[];
    purity_errors: [identifier: string, error: string][];
    promotion_errors: [identifier: string, error: string][];
}

export const HTE2MSApi = {
    draft: async (id: string): Promise<HTE2LibraryDraft> => {
        const { data } = await api.client.get(`hte/uscale/draft/${id}`, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
    saveDraft: async (options: { id?: string; name?: string; library: HTE2MicroscaleLibrary }): Promise<string> => {
        const { data } = await api.client.post(`hte/uscale/draft`, options, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
    createLibrary: async (
        options: HTE2MicroscaleLibraryOptions,
        library: HTE2MicroscaleLibrary
    ): Promise<HTEExperimentInfo> => {
        const { data } = await api.client.post(`hte/uscale`, { options, library }, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
    updateOptions: async (
        id: number,
        options: { last_modified_on: DateLike; options: HTE2MicroscaleLibraryOptions }
    ): Promise<HTEExperimentInfo> => {
        const { data } = await api.client.post(`hte/uscale/${id}/options`, options, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
    readyForProduction: async (id: number, last_modified_on: DateLike): Promise<HTEExperimentInfo> => {
        const { data } = await api.client.post(
            `hte/uscale/${id}/ready-for-production`,
            { last_modified_on },
            {
                responseType: 'arraybuffer',
            }
        );
        return decodeEntosMsgpack(data);
    },
    readyForFinalization: async (id: number, last_modified_on: DateLike): Promise<HTEExperimentInfo> => {
        const { data } = await api.client.post(
            `hte/uscale/${id}/ready-for-finalization`,
            { last_modified_on },
            {
                responseType: 'arraybuffer',
            }
        );
        return decodeEntosMsgpack(data);
    },
    default: async (): Promise<HTE2MicroscaleLibrary> => {
        const { data } = await api.client.get('hte/uscale/default', { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
    exampleDesign: async (): Promise<HTEDesign> => {
        const { data } = await api.client.get('hte/uscale/example-design', { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
    assets: async (): Promise<MicroscaleAssets> => {
        const { data } = await api.client.get('hte/uscale/assets', { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
    resolveIdentifiers: (identifiers: string[]): Promise<Record<string, string>> =>
        api.postMsgpack('hte/uscale/resolve-identifiers', { identifiers }),
    parseReactions: async (file: File): Promise<HTEDReaction[]> => {
        const formData = new FormData();
        formData.append('file', file);
        const { data } = await api.client.post('hte/uscale/parse-reactions', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data);
    },
    parseRacks: async (file: File): Promise<HTEIVialRack[]> => {
        const formData = new FormData();
        formData.append('file', file);
        const { data } = await api.client.post('hte/uscale/parse-racks', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data);
    },
    validateReactions: async (reactions: HTEDReaction[]): Promise<HTEDReaction[]> => {
        const { data } = await api.client.post(
            'hte/uscale/validate-reactions',
            { reactions },
            {
                responseType: 'arraybuffer',
            }
        );
        return decodeEntosMsgpack(data);
    },
    validateConditions: (conditions: HTEDConditions): Promise<HTEDConditions> =>
        api.postMsgpack('hte/uscale/validate-conditions', { conditions }),
    validateLabware: async (labware: HTEDLabware): Promise<HTEDLabware> => {
        const { data } = await api.client.post(
            'hte/uscale/validate-labware',
            { labware },
            {
                responseType: 'arraybuffer',
            }
        );
        return decodeEntosMsgpack(data);
    },
    queryCompoundOrRegisterSubstance: async (input: string): Promise<CompoundOrSubstanceResult> => {
        const { data } = await api.client.post(
            'hte/uscale/query-compound-or-register-substance',
            { input },
            {
                responseType: 'arraybuffer',
            }
        );
        return decodeEntosMsgpack(data, { eoi: 'strip' });
    },
    buildDraft: async (options: {
        library: HTE2MicroscaleLibrary;
        rebuild_protocol: boolean;
    }): Promise<BuildResult> => {
        const { data } = await api.client.post('hte/uscale/build-draft', options, {
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data);
    },
    build: async (
        id: number,
        options: { last_modified_on: DateLike; library: HTE2MicroscaleLibrary; rebuild_protocol: boolean }
    ): Promise<BuildResult> => {
        const { data } = await api.client.post(`hte/uscale/${id}/build`, options, {
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data);
    },
    uploadCrudePlate: async (
        id: number,
        options: { last_modified_on: DateLike; library: HTE2MicroscaleLibrary; plate_barcode: string }
    ): Promise<UploadCrudePlateResult> => {
        const { data } = await api.client.post(`hte/uscale/${id}/upload-crude-plate`, options, {
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data);
    },
    modifyPlateIds: async (
        id: number,
        options: { last_modified_on: DateLike; remove_id?: number; add_barcode?: string }
    ): Promise<HTEExperimentInfo> => {
        const { data } = await api.client.post(`hte/uscale/${id}/mofidy-plate-ids`, options, {
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data);
    },
    modifyProcedureObservations: async (
        id: number,
        options: { last_modified_on: DateLike; procedure?: string; observations?: string }
    ): Promise<HTEExperimentInfo> => {
        const { data } = await api.client.post(`hte/uscale/${id}/procedure-observations`, options, {
            responseType: 'arraybuffer',
        });
        return decodeEntosMsgpack(data);
    },
    updateTransferBarcode: (
        id: number,
        options: {
            kind: 'liquid' | 'dry';
            key: HTEPReagentKeyT | HTERInstructionIdT;
            transfer_barcode: string | undefined;
        }
    ): Promise<UpdateInventoryResult> => api.postMsgpack(`hte/uscale/${id}/inventory/update-transfer-barcode`, options),
    uploadPooling: (
        id: number,
        files: { file: File },
        options: {
            options: HTEPPoolingScriptOptions;
            last_modified_on: DateLike;
        }
    ): Promise<UpdatePurificationResult> => api.postMsgpackForm(`hte/uscale/${id}/pooling`, options, { files }),
    removePooling: (
        id: number,
        pooling_id: HTEPPoolingIdT,
        options: {
            last_modified_on: DateLike;
        }
    ): Promise<UpdatePurificationResult> => api.postMsgpack(`hte/uscale/${id}/pooling/${pooling_id}/remove`, options),
    uploadPoolingBarcodes: (
        id: number,
        pooling_id: HTEPPoolingIdT,
        files: { file: File },
        options: {
            last_modified_on: DateLike;
        }
    ): Promise<UpdatePurificationResult> =>
        api.postMsgpackForm(`hte/uscale/${id}/pooling/${pooling_id}/barcodes`, options, { files }),
    registerPooling: (
        id: number,
        pooling_id: HTEPPoolingIdT,
        options: {
            last_modified_on: DateLike;
        }
    ): Promise<void> => api.postMsgpack(`hte/uscale/${id}/pooling/${pooling_id}/register`, options),
    updateSaltExpression: (
        id: number,
        pooling_id: HTEPPoolingIdT,
        options: {
            salt_exclusions: [HTEPFractionIdT, boolean][];
            last_modified_on: DateLike;
        }
    ): Promise<UpdatePurificationResult> =>
        api.postMsgpack(`hte/uscale/${id}/pooling/${pooling_id}/update-salt-expression`, options),
    setPurificationSalts: (
        id: number,
        options: {
            salt_name_to_smiles: Record<string, string>;
            last_modified_on: DateLike;
        }
    ): Promise<UpdatePurificationResult> => api.postMsgpack(`hte/uscale/${id}/set-salts`, options),
    validateDistribution: (
        id: number,
        files: {
            pooled_racks_files: File[];
        }
    ): Promise<HTEDValidationResult> => api.postMsgpackForm(`hte/uscale/${id}/validate-distribution`, {}, { files }),
    addDistribution: (
        id: number,
        files: {
            pooled_total_mass_file: File;
            pooled_racks_file: File | undefined;
            liquid_racks_files: File[] | undefined;
            dry_racks_files: File[] | undefined;
        },
        options: {
            options: HTEDistributionOptions;
            last_modified_on: DateLike;
        }
    ): Promise<UpdateDistributionResult> => api.postMsgpackForm(`hte/uscale/${id}/distribution`, options, { files }),
    removeDistribution: (
        id: number,
        distribution_id: HTEPPoolingIdT,
        options: {
            last_modified_on: DateLike;
        }
    ): Promise<UpdateDistributionResult> =>
        api.postMsgpack(`hte/uscale/${id}/distribution/${distribution_id}/remove`, options),
    registerDistribution: (
        id: number,
        distribution_id: HTEPPoolingIdT,
        options: {
            last_modified_on: DateLike;
        }
    ): Promise<UpdateDistributionResult> =>
        api.postMsgpack(`hte/uscale/${id}/distribution/${distribution_id}/register`, options),
    addFinalQC: (
        id: number,
        files: {
            rack_files: File[];
        },
        options: {
            options: HTEFinalQCOptions;
            last_modified_on: DateLike;
        }
    ): Promise<UpdateQCResult> => api.postMsgpackForm(`hte/uscale/${id}/finalqc`, options, { files }),
    removeFinalQC: (
        id: number,
        finalqc_id: HTEFinalQCIdT,
        options: {
            last_modified_on: DateLike;
        }
    ): Promise<UpdateQCResult> => api.postMsgpack(`hte/uscale/${id}/finalqc/${finalqc_id}/remove`, options),
    registerFinalQC: (
        id: number,
        finalqc_id: HTEFinalQCIdT,
        options: {
            last_modified_on: DateLike;
        }
    ): Promise<UpdateQCResult> => api.postMsgpack(`hte/uscale/${id}/finalqc/${finalqc_id}/register`, options),
    notifySlack: async (
        id: number,
        kind: 'submit' | 'purify' | 'finalqc' | 'finalize' | 'done',
        options?: { lcms_barcode?: string; echoms_barcode?: string }
    ): Promise<boolean> => {
        const { data } = await api.client.post(
            `hte/uscale/${id}/notify-slack`,
            { kind, options },
            { responseType: 'arraybuffer' }
        );
        return decodeEntosMsgpack(data);
    },
    promotionData: (id: number): Promise<HTEBatchPromotionData> =>
        api.getMsgpack(`hte/uscale/${id}/promotion-data`, { decode: { eoi: 'hex' } }),
    applyPromotion: (batch_ids: number[]): Promise<HTEApplyPromotionResult> =>
        api.postMsgpack(`hte/uscale/apply-promotion`, { batch_ids }),
    finalize: async (
        id: number,
        options: { last_modified_on: DateLike; finalization: HTEFinalizationData }
    ): Promise<HTEExperimentInfo> => {
        const { data } = await api.client.post(`hte/uscale/${id}/finalize`, options, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
    triggerSnapshot: async (id: number): Promise<boolean> => {
        const { data } = await api.client.post(
            `hte/uscale/${id}/trigger-snapshot`,
            {},
            { responseType: 'arraybuffer' }
        );
        return decodeEntosMsgpack(data);
    },
    diluteReagent: async (options: {
        library: HTE2MicroscaleLibrary;
        reagent_key: HTEPReagentKeyT;
        concentration: string;
    }): Promise<DiluteReagentResult> => {
        const { data } = await api.client.post(`hte/uscale/dilute-reagent`, options, { responseType: 'arraybuffer' });
        return decodeEntosMsgpack(data);
    },
};
